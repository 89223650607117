import { Icon, Icons, useStyles, useTheme } from 'bold-ui'
import { TitleGroup } from 'components/TitleGroup'
import { Interpolation } from 'emotion'
import React, { CSSProperties } from 'react'

export interface PageHeaderProps {
  title: React.ReactNode
  subtitle?: React.ReactNode
  icon?: Icons
  action?: React.ReactNode
  style?: Interpolation
}

export function PageHeader(props: PageHeaderProps) {
  const { title, subtitle, icon, action, style } = props
  const { classes, css } = useStyles(createStyles)

  return (
    <div className={css(classes.container, style)}>
      {icon && (
        <>
          <Icon icon={icon} size={2} style={classes.icon} />
          <Gradient id='iconGradient' />
        </>
      )}

      <TitleGroup style={classes.titleGroup} title={title} subtitle={subtitle} />

      {action && <div className={css(classes.action)}>{action}</div>}
    </div>
  )
}

const createStyles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  } as CSSProperties,
  titleGroup: {
    flex: 1,
  } as CSSProperties,
  icon: {
    marginRight: '1rem',
    fill: 'url(#iconGradient)',
  } as CSSProperties,
  action: {
    justifySelf: 'flex-end',
    marginLeft: '1rem',
  } as CSSProperties,
})

const Gradient = ({ id }: { id: string }) => {
  const theme = useTheme()
  return (
    <svg width={0} height={0}>
      <defs>
        <linearGradient id={id}>
          <stop offset='5%' stopColor={theme.pallete.primary.c40} />
          <stop offset='95%' stopColor={theme.pallete.primary.c60} />
        </linearGradient>
      </defs>
    </svg>
  )
}
