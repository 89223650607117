import { HeadingSection } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import { useCreateCidadaoCadsusMutation, useSincronizaCidadaoCadsusPollingQuery } from 'graphql/hooks.generated'
import { CidadaoCadsus, NacionalidadeEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ResultSyncCadsusType } from '../types/CadsusModel'
import CidadaoFormModel from '../types/CidadaoFormModel'
import { convertCadsusToFormModel } from '../types/convertModelToInput'
import { useNeedLoginGovBrToCadsus } from '../useNeedLoginGovBrToCadsus'
import { CidadaoDadosForm } from './CidadaoDadosForm'
import { InfosExcluiveisCadsus } from './CidadaoEditView'
import { ModalStatusSincronizacaoCadsus } from './ModalStatusSincronizacaoCadsus'

let pollingCadsus = false

export interface CadsusAtmosphereResponse {
  uuid: string
}

export function CidadaoCreateView() {
  const [saveCadsus] = useCreateCidadaoCadsusMutation()
  const [sincCadsusState, setSincCadsusState] = useState<boolean>(false)
  const cidadaoCadsus = JSON.parse(sessionStorage.getItem('cidadaoCadsus')) as CidadaoCadsus
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false)
  const [cadsusSyncResult, setCadsusSyncResult] = useState<ResultSyncCadsusType>({ uuid: '' })
  const [uuidSincronizacao, setUuidSincronizacao] = useState<string>()
  const { data: sessao } = useSession()
  const { needLoginGovBr, hasAllConfigs } = useNeedLoginGovBrToCadsus()

  const [dadosExcluiveisState] = useState<InfosExcluiveisCadsus>({
    telefoneCelular: cidadaoCadsus?.telefoneCelular[0],
    telefoneContato: cidadaoCadsus?.telefoneContato[0],
    telefoneResidencial: cidadaoCadsus?.telefoneResidencial[0],
    nomeSocial: cidadaoCadsus?.nomeSocial,
  })
  const [hasCnsState, setHasCnsState] = useState(false)

  const atualizaResultadoCadsus = (data: ResultSyncCadsusType) => {
    pollingCadsus = false
    setCadsusSyncResult(data)
    setIsModalLoading(false)
  }
  const { refetch: refetchSincronizacaoPolling } = useSincronizaCidadaoCadsusPollingQuery({ skip: true })
  const handleRejection = useErrorHandler()

  useEffect(() => {
    let shouldUpdate = true
    const scheduleCadsusPolling = setInterval(() => {
      if (!!uuidSincronizacao && pollingCadsus && shouldUpdate) {
        shouldUpdate = false
        refetchSincronizacaoPolling({ uuid: uuidSincronizacao })
          .then(
            ({ data }) =>
              !!data.sincronizacaoCadsusPolling &&
              data.sincronizacaoCadsusPolling.uuid === uuidSincronizacao &&
              pollingCadsus &&
              atualizaResultadoCadsus(data.sincronizacaoCadsusPolling)
          )
          .finally(() => (shouldUpdate = true))
          .catch(handleRejection)
      }
    }, 1 * 1000)
    return () => clearInterval(scheduleCadsusPolling)
  }, [handleRejection, refetchSincronizacaoPolling, uuidSincronizacao])

  const syncCadsus = (cidadaoId: string, cns: string) => {
    setCadsusSyncResult(null)
    const hash = uuidv4()
    setUuidSincronizacao(hash)
    pollingCadsus = true
    saveCadsus({
      variables: {
        input: {
          cidadao: cidadaoId,
          telefoneCelularAntigo: dadosExcluiveisState.telefoneCelular,
          telefoneContatoAntigo: dadosExcluiveisState.telefoneContato,
          telefoneResidencialAntigo: dadosExcluiveisState.telefoneResidencial,
          nomeSocialAntigo: dadosExcluiveisState.nomeSocial,
          uuid: hash,
        },
      },
    })

    setIsModalLoading(true)
    setSincCadsusState(true)
    setHasCnsState(!!cns)
  }

  useAtmosphere<CadsusAtmosphereResponse>({
    topic: `cadsus-sincronizacao-completa/${sessao.profissional.usuario.id}`,
    onMessage: (responseBody) => {
      if (responseBody.uuid === uuidSincronizacao && pollingCadsus) {
        refetchSincronizacaoPolling({ uuid: uuidSincronizacao })
          .then(
            ({ data }) =>
              !!data.sincronizacaoCadsusPolling &&
              data.sincronizacaoCadsusPolling.uuid === uuidSincronizacao &&
              pollingCadsus &&
              atualizaResultadoCadsus(data.sincronizacaoCadsusPolling)
          )
          .catch(handleRejection)
      }
    },
  })

  const initialValues = !!cidadaoCadsus
    ? convertCadsusToFormModel(cidadaoCadsus)
    : ({
        nacionalidade: NacionalidadeEnum.BRASILEIRA,
        endereco: { pais: { id: '31', nome: 'BRASIL' } },
      } as CidadaoFormModel)

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Cadastro do cidadão' />
      <HeadingSection level={1} title='Cadastro do cidadão'>
        <CidadaoDadosForm
          initialValues={initialValues}
          sincronizarCadsus={syncCadsus}
          needLoginGovBr={needLoginGovBr}
          hasAllConfigs={hasAllConfigs}
        />
        <ModalStatusSincronizacaoCadsus
          loading={isModalLoading}
          result={cadsusSyncResult}
          isOpen={sincCadsusState}
          isEdit={false}
          sincronizarCadsus={syncCadsus}
          setSincModalState={setSincCadsusState}
          hasCns={hasCnsState}
        />
      </HeadingSection>
    </PageContent>
  )
}
