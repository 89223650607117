import { Tag, TagProps, useStyles } from 'bold-ui'
import * as React from 'react'

export interface FilterTagProps extends TagProps {}

export const FilterTag = (props: FilterTagProps) => {
  const { theme } = useStyles()
  const tagStyles = {
    color: theme.pallete.status.info.main,
    background: theme.pallete.status.info.background,
    border: `1px solid ${theme.pallete.status.info.main}`,
  }
  return (
    <Tag {...props} type='info' removable style={tagStyles}>
      {props.children}
    </Tag>
  )
}
