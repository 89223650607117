import { SelectField, SelectFieldProps } from 'components/form'
import { TipoPartoEnum } from 'graphql/types.generated'
import React from 'react'

export interface TipoPartoSelectFieldModel {
  value: TipoPartoEnum
  label: string
}

export interface TipoPartoSelectFieldProps
  extends Omit<SelectFieldProps<TipoPartoSelectFieldModel>, 'items' | 'itemToString'> {}

export default function TipoPartoSelectField(props: TipoPartoSelectFieldProps) {
  return (
    <SelectField<TipoPartoSelectFieldModel>
      label='Tipo de parto'
      items={items}
      itemToString={(i) => i?.label}
      {...props}
    />
  )
}

export const items: TipoPartoSelectFieldModel[] = [
  {
    value: TipoPartoEnum.NORMAL,
    label: 'Normal',
  },
  {
    value: TipoPartoEnum.FORCEPS,
    label: 'Fórceps',
  },
  {
    value: TipoPartoEnum.CESAREO,
    label: 'Cesáreo',
  },
]
