import { Cell, Grid, TextField } from 'bold-ui'
import React from 'react'

export interface HistoricoEvolucoesPeriodontaisHeaderProps {
  onChangeFilter(filter: string): void
}

export function HistoricoEvolucoesPeriodontaisHeader(props: HistoricoEvolucoesPeriodontaisHeaderProps) {
  const { onChangeFilter } = props

  return (
    <Grid alignItems='flex-start' gap={1}>
      <Cell md={7} lg={6}>
        <TextField
          placeholder='Pesquise por local, procedimento ou profissional'
          icon='zoomOutline'
          onChange={(e) => onChangeFilter(e.currentTarget.value)}
        />
      </Cell>
    </Grid>
  )
}
