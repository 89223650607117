/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow, Text, TextProps, Tooltip } from 'bold-ui'
import { useMemo } from 'react'

interface StatusSquareProps extends Omit<TextProps, 'color'> {
  color: string
  description: string
  squareStyle?: ExternalStyles
  descriptionAsTooltip?: boolean
}

export default function StatusSquare(props: StatusSquareProps) {
  const { color, description, squareStyle, descriptionAsTooltip, ...rest } = props
  const styles = useMemo(() => createStyles(color), [color])
  return (
    <Tooltip text={descriptionAsTooltip ? description : null}>
      <HFlow alignItems='center' hSpacing={0.5}>
        <div css={css(styles.square, squareStyle)}></div>
        {!descriptionAsTooltip && <Text {...rest}>{description}</Text>}
      </HFlow>
    </Tooltip>
  )
}

const createStyles = (color: string) => ({
  square: css`
    width: 1rem;
    height: 1rem;
    background-color: ${color};
    border-radius: 2px;
  `,
})
