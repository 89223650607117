import { EstadoNutricional } from 'types/enums'

import { ParametrosImc } from '../calculateEstadoNutricional'
import imcGestante from '../parametros/imcGestante.json'

interface SemanasParametrosImcGestante {
  [x: number]: ParametrosImc
}

export function getEstadoNutricionalGestante(imc: number, semanaGestacional: number) {
  const paramsSemana = getParametrosParaSemanaGestacional(semanaGestacional)

  if (imc >= paramsSemana.obesidade.minimo) {
    return EstadoNutricional.OBESIDADE.DANGER
  } else if (imc >= paramsSemana.sobrepeso.minimo) {
    return EstadoNutricional.SOBREPESO.WARNING
  } else if (imc >= paramsSemana.adequado.minimo) {
    return EstadoNutricional.ADEQUADO
  } else {
    return EstadoNutricional.BAIXO_PESO
  }
}

export function getParametrosParaSemanaGestacional(semana: number): ParametrosImc {
  return (imcGestante as SemanasParametrosImcGestante)[semana]
}
