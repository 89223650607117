import { Heading, VFlow } from 'bold-ui'
import { resolveName } from 'components/form/final-form/hooks/useField'
import React from 'react'
import { useField } from 'react-final-form'
import { MetaArray } from 'util/metaPath'
import { EditableList } from 'view/atendimentos/detail/components/EditableList'
import { ProcedimentoSigtapFieldModel } from 'view/atendimentos/detail/soap/finalizacao/components/ProcedimentoSigtapField'
import { ProcedimentoSigtapRow } from 'view/atendimentos/detail/soap/finalizacao/components/ProcedimentoSigtapRow'

interface ProcedimentoAdministrativoEscutaInicialFieldProps {
  name: MetaArray<ProcedimentoSigtapFieldModel>
  label?: string
}

export const ProcedimentoAdministrativoEscutaInicialField = (
  props: ProcedimentoAdministrativoEscutaInicialFieldProps
) => {
  const { name, label } = props

  const {
    input: { value },
  } = useField(resolveName(name), { subscription: { value: true } })

  return (
    value?.length > 0 && (
      <VFlow vSpacing={0.5}>
        {label && <Heading level={5}>{label}</Heading>}
        <EditableList>
          {value.map((item) => (
            <ProcedimentoSigtapRow key={item._id} model={item} hideRemoveButton />
          ))}
        </EditableList>
      </VFlow>
    )
  )
}
