import { Button, Cell, Grid, HeadingSection, HFlow, Icon, Theme, Tooltip, useStyles, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { Form, TextField } from 'components/form'
import { useAlterarDadosServidorInstalacaoMutation } from 'graphql/hooks.generated'
import { DadosServidoresQuery } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { urlToModel } from 'util/urlToModel'
import { createValidator, maxLength, required } from 'util/validation'

type DadosServidoresModel = DadosServidoresQuery['servidores']['dadosServidor']

export interface ConfiguracoesLinkInstalacaoBoxProps {
  initialValues: DadosServidoresModel
  refetch(): any
}

export const validate = createValidator<DadosServidoresModel>({
  nomeInstalacao: [required, maxLength(255)],
  linkInstalacao: [required, maxLength(255)],
})

export const ConfiguracoesDadosServidorInstalacaoBox = (props: ConfiguracoesLinkInstalacaoBoxProps) => {
  const [alterarEnderecoInstalacao] = useAlterarDadosServidorInstalacaoMutation({
    onCompleted: props.refetch,
  })

  const { classes } = useStyles(iconStyle)
  const alert = useAlert()

  const handleSubmit = (formValues: DadosServidoresModel) => {
    const linkInstalacao = urlToModel(formValues.linkInstalacao)

    return alterarEnderecoInstalacao({
      variables: {
        input: {
          nomeInstalacao: formValues.nomeInstalacao,
          linkInstalacao,
        },
      },
    }).then(() => {
      alert('success', 'Dados da instalação alterado com sucesso.')
    })
  }

  const renderForm = (formProps: FormRenderProps<DadosServidoresModel>) => {
    return (
      <VFlow>
        <Grid gap={1}>
          <Cell size={5}>
            <TextField label='Nome da instalação' required name='nomeInstalacao' clearable={false} />
          </Cell>
          <Cell size={7} />
          <Cell size={5}>
            <TextField label='Link da instalação' required name='linkInstalacao' clearable={false} />
          </Cell>
          <Cell size={7} />
          <Cell>
            <Button size='small' kind='primary' onClick={formProps.handleSubmit}>
              Salvar
            </Button>
          </Cell>
        </Grid>
      </VFlow>
    )
  }

  return (
    <Box>
      <HeadingSection
        title={
          <HFlow alignItems='center' hSpacing={0.5}>
            <span>Servidor da instalação e-SUS APS PEC</span>
            <Tooltip
              style={{ maxWidth: '595px' }}
              text='Para realizar a configuração informe o link ou IP do servidor da instalação (por exemplo: esusab.dominio.com). Caso a porta não seja especificada será utilizado o valor padrão do sistema (80).'
            >
              <Icon icon='infoCircleFilled' size={1} style={classes.icon} />
            </Tooltip>
          </HFlow>
        }
        level={3}
      >
        <Form<DadosServidoresModel>
          onSubmit={handleSubmit}
          render={renderForm}
          validate={validate}
          initialValues={props.initialValues}
        />
      </HeadingSection>
    </Box>
  )
}

const iconStyle = (theme: Theme) => ({
  icon: {
    '&:hover': {
      color: theme.pallete.primary.main,
    },
  } as React.CSSProperties,
})
