import { Button, Tooltip } from 'bold-ui'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ReactComponent as IconVisualizarDiscussaoCaso } from 'images/cuidado-compartilhado/icon-visualizar-discussao-caso.svg'
import React, { useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import { DiscussaoCuidadoCompartilhadoModal } from '../DiscussaoCuidadoCompartilhadoModal'

interface VisualizarDiscussaoCuidadoCompartilhadoActionProps {
  cuidadoCompartilhadoId: ID
  viaHistorico?: boolean
  viaFolhaRosto?: boolean
}

export function VisualizarDiscussaoCuidadoCompartilhadoAction(
  props: VisualizarDiscussaoCuidadoCompartilhadoActionProps
) {
  const { cuidadoCompartilhadoId, viaHistorico = false, viaFolhaRosto = false } = props
  const { analytics } = useFirebase()

  const [isModalOpen, setModalOpen] = useState(false)

  const handleClick = (e: React.MouseEvent) => {
    logEventVisualizar()
    e.preventDefault()
    e.stopPropagation()
    setModalOpen(true)
  }

  const handleClose = () => setModalOpen(false)

  const logEventVisualizar = () => {
    analytics.logEvent(
      viaHistorico
        ? 'visualizar_CC_via_historico'
        : viaFolhaRosto
        ? 'visualizar_CC_via_folhaRosto'
        : 'visualizar_CC_via_atend'
    )
  }

  const isFromDw = isUndefinedOrNull(cuidadoCompartilhadoId)
  const tooltipText = isFromDw
    ? 'Não é possível visualizar a discussão de fichas de outras instalações'
    : viaHistorico
    ? 'Visualizar discussão completa'
    : 'Visualizar discussão'

  return (
    <>
      <Tooltip text={tooltipText}>
        <Button size='small' skin='ghost' onClick={handleClick} disabled={isFromDw}>
          <IconVisualizarDiscussaoCaso />
        </Button>
      </Tooltip>
      {isModalOpen && (
        <DiscussaoCuidadoCompartilhadoModal cuidadoCompartilhadoId={cuidadoCompartilhadoId} handleClose={handleClose} />
      )}
    </>
  )
}
