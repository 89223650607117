import { SelectField } from 'components/form'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_PAGE_PARAM,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { EquipesAtencaoDomDocument } from 'graphql/hooks.generated'
import { EquipesAtencaoDomQuery, EquipesAtencaoDomQueryVariables } from 'graphql/types.generated'
import React from 'react'

export type ConfiguracoesADEquipeSelectFieldModel = Partial<EquipesAtencaoDomQuery['equipesAtencaoDom']['content'][0]>

export type EquipesAtencaoDomSelectFieldModel = Partial<EquipesAtencaoDomQuery['equipesAtencaoDom']['content'][0]>

export interface ConfiguracoesADEquipeSelectFieldProps
  extends AsyncSelectFieldProps<ConfiguracoesADEquipeSelectFieldModel> {
  dsTipoEquipe: string
  hasEquipesRelacionadas?: boolean
}

export function ConfiguracoesADEquipeSelectField(props: ConfiguracoesADEquipeSelectFieldProps) {
  const { dsTipoEquipe, hasEquipesRelacionadas, ...rest } = props

  const {
    selectProps: { loading, ...asyncProps },
  } = useAsyncQuerySelect<
    ConfiguracoesADEquipeSelectFieldModel,
    EquipesAtencaoDomQuery,
    EquipesAtencaoDomQueryVariables
  >({
    query: EquipesAtencaoDomDocument,
    extractItems: (data) => data?.equipesAtencaoDom?.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        descricaoTipoEquipe: dsTipoEquipe,
        hasEquipesRelacionadas,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          size: 5,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: ConfiguracoesADEquipeSelectFieldModel) => (item ? `${item?.nome} - ${item.ine}` : '')

  return <SelectField<ConfiguracoesADEquipeSelectFieldModel> itemToString={itemToString} {...asyncProps} {...rest} />
}
