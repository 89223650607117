import axios, { AxiosPromise } from 'axios'

export interface ImpressaoFichaSinanInput {
  idNotificacao: ID
  cidadaoId: number
  dataAtendimento: Instant
  atendimentoId: ID
}

export class FichaSinan {
  gerarFichaSinan(input: ImpressaoFichaSinanInput): AxiosPromise<Blob> {
    return axios.post('/api/atendimento/gerarFichaSinan', input, { responseType: 'blob' })
  }
}
