/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid } from 'bold-ui'
import { TextAreaField, TextField } from 'components/form'
import {
  ProcedimentoOdontoSelectField,
  ProcedimentoOdontoSelectModel,
} from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { ParteBucalEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

import { MAX_LENGTH_LOCAL_ODONTO, MAX_LENGTH_OBS_ODONTO } from '../../../model'
import { OutrosFormModel } from './OutrosForm'

interface OutrosFormFieldsProps {
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  procedimentosSelecionados: ProcedimentoOdontoSelectModel[]
  editing?: boolean
}

const path = metaPath<OutrosFormModel>()

export const OutrosFormFields = (props: OutrosFormFieldsProps) => {
  const { cidadaoDataNascimento, dataAtendimento, procedimentosSelecionados, editing } = props

  return (
    <Grid gap={1}>
      <Cell size={editing ? 12 : 6}>
        <TextField name={path.local} label='Local' maxLength={MAX_LENGTH_LOCAL_ODONTO} required />
      </Cell>

      <Cell size={editing ? 12 : 6}>
        <ProcedimentoOdontoSelectField
          name={path.procedimentos}
          label='Procedimentos (Evolução odontológica)'
          multiple
          dataNascimento={cidadaoDataNascimento}
          dataAtendimento={dataAtendimento}
          parteBucal={ParteBucalEnum.OUTROS}
          itemsDisabled={procedimentosSelecionados}
          required
        />
      </Cell>

      <Cell size={12}>
        <TextAreaField
          name={path.observacao}
          label='Observações'
          maxLength={MAX_LENGTH_OBS_ODONTO}
          placeholder='Insira informações adicionais sobre os procedimentos realizados.'
          style={css`
            resize: none;
          `}
        />
      </Cell>
    </Grid>
  )
}
