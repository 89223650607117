import { Button, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useExcluirConfigAtendimentoDomMutation } from 'graphql/hooks.generated'
import React from 'react'

import { ConfiguracaoAdApoioItemType } from './ConfiguracoesADApoioTable'

interface ConfiguracoesADButtonExcluirProps {
  configItem: ConfiguracaoAdApoioItemType
  reload(): Promise<any>
}
export default function ConfiguracoesADButtonExcluir(props: ConfiguracoesADButtonExcluirProps) {
  const { configItem, reload } = props
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const [excluirConfiguracao] = useExcluirConfigAtendimentoDomMutation()

  const handleExcluirClick = () => {
    return confirm({
      title: `Deseja excluir a relação entre ${configItem.identificadorNacionalEquipePai.nome} - ${configItem.identificadorNacionalEquipePai.ine} e ${configItem.identificadorNacionalEquipeFilho.nome} - ${configItem.identificadorNacionalEquipeFilho.ine}?`,
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () =>
        excluirConfiguracao({ variables: { idConfig: configItem.id } })
          .then(() => {
            alert(
              'success',
              `Relação entre ${configItem.identificadorNacionalEquipePai.nome} - ${configItem.identificadorNacionalEquipePai.ine} e ${configItem.identificadorNacionalEquipeFilho.nome} - ${configItem.identificadorNacionalEquipeFilho.ine} excluída com sucesso.`
            )
            reload()
          })
          .catch(handleRejection),
    })()
  }

  return (
    <Tooltip text='Excluir'>
      <Button onClick={handleExcluirClick} size='small' skin='ghost'>
        <Icon icon='trashOutline' />
      </Button>
    </Tooltip>
  )
}
