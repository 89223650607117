/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'
import { ParteBucalEnum } from 'graphql/types.generated'
import { Fragment } from 'react'

import { OdontogramaSetActiveRef } from '../odontograma-field/model'
import OdontogramaButtonWrapper from './components/OdontogramaButtonWrapper'
import Dente11 from './dentes/Dente11'
import Dente12 from './dentes/Dente12'
import Dente13 from './dentes/Dente13'
import Dente14 from './dentes/Dente14'
import Dente15 from './dentes/Dente15'
import Dente16 from './dentes/Dente16'
import Dente17 from './dentes/Dente17'
import Dente18 from './dentes/Dente18'
import Dente21 from './dentes/Dente21'
import Dente22 from './dentes/Dente22'
import Dente23 from './dentes/Dente23'
import Dente24 from './dentes/Dente24'
import Dente25 from './dentes/Dente25'
import Dente26 from './dentes/Dente26'
import Dente27 from './dentes/Dente27'
import Dente28 from './dentes/Dente28'
import Dente31 from './dentes/Dente31'
import Dente32 from './dentes/Dente32'
import Dente33 from './dentes/Dente33'
import Dente34 from './dentes/Dente34'
import Dente35 from './dentes/Dente35'
import Dente36 from './dentes/Dente36'
import Dente37 from './dentes/Dente37'
import Dente38 from './dentes/Dente38'
import Dente41 from './dentes/Dente41'
import Dente42 from './dentes/Dente42'
import Dente43 from './dentes/Dente43'
import Dente44 from './dentes/Dente44'
import Dente45 from './dentes/Dente45'
import Dente46 from './dentes/Dente46'
import Dente47 from './dentes/Dente47'
import Dente48 from './dentes/Dente48'
import { ArcadaModel } from './types/Arcada'

export interface ArcadasProps {
  value?: Record<ParteBucalEnum, ArcadaModel>
  activeItem?: ParteBucalEnum
  proteseTotalSuperior?: boolean
  proteseTotalInferior?: boolean
  disabled?: boolean
  onClick?(sextante: ParteBucalEnum, ref?: HTMLButtonElement): void
  setActiveRef: OdontogramaSetActiveRef
}

export function Arcadas(props: ArcadasProps) {
  const { value, activeItem, proteseTotalSuperior, proteseTotalInferior, disabled, onClick, setActiveRef } = props

  const denteMarginCss = css`
    margin: 0 0.25rem;
  `

  const getButtonWrapperProps = (arcada: ParteBucalEnum) => ({
    active: activeItem === arcada,
    disabled: disabled,
    value: value && value[arcada],
    setActiveRef,
    onClick: (ref) => onClick && onClick(arcada, ref),
  })

  const getDentesProps = (protese?: boolean) => ({
    disableStateEffects: true,
    protese: protese,
  })

  return (
    <VFlow vSpacing={0.5}>
      <OdontogramaButtonWrapper {...getButtonWrapperProps(ParteBucalEnum.ARCADA_SUPERIOR)}>
        <Fragment>
          <Dente18 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente17 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente16 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente15 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente14 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente13 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente12 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente11 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente21 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente22 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente23 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente24 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente25 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente26 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente27 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />

          <Dente28 style={denteMarginCss} {...getDentesProps(proteseTotalSuperior)} />
        </Fragment>
      </OdontogramaButtonWrapper>

      <OdontogramaButtonWrapper {...getButtonWrapperProps(ParteBucalEnum.ARCADA_INFERIOR)}>
        <Fragment>
          <Dente48 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente47 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente46 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente45 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente44 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente43 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente42 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente41 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente31 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente32 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente33 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente34 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente35 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente36 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente37 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />

          <Dente38 style={denteMarginCss} {...getDentesProps(proteseTotalInferior)} />
        </Fragment>
      </OdontogramaButtonWrapper>
    </VFlow>
  )
}
