import CheckPermission from 'components/auth/CheckPermission'
import React from 'react'

import { grupoCboOrientacoes } from '../../acessos'
import { OrientacaoFormModel } from '../types/OrientacoesModel'
import { OrientacoesForm } from './OrientacoesForm'

interface OrientacoesCadastroViewProps {
  onSubmit(formValues: OrientacaoFormModel): void
  onFocus(): void
}

export function OrientacoesCadastroView(props: OrientacoesCadastroViewProps) {
  const { onSubmit, onFocus } = props

  return (
    <CheckPermission permission={grupoCboOrientacoes.adicionar}>
      <OrientacoesForm onSubmit={onSubmit} onFocus={onFocus} showAlerts />
    </CheckPermission>
  )
}
