import { Cell, Grid, TextInput, VFlow } from 'bold-ui'
import React, { useState } from 'react'

import { RecursoList, RecursoListProps } from './RecursoList'

export interface RecursoTreeProps extends RecursoListProps {}

export const RecursoTree = (props: RecursoTreeProps) => {
  const [filter, setFilter] = useState<string>('')

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const value = e.target.value
      setFilter(value)
      props.root.filter(value)
    } else {
      setFilter('')
      props.root.filter('')
    }
  }

  return (
    <VFlow>
      <Grid>
        <Cell xs={12} md={5}>
          <TextInput
            value={filter}
            onChange={handleSearchChange}
            placeholder='Pesquise por recurso'
            icon='zoomOutline'
          />
        </Cell>
      </Grid>

      <RecursoList search={filter} {...props} />
    </VFlow>
  )
}
