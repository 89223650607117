import { Form, FormProps } from 'components/form'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { createValidator, maxLength, required } from 'util/validation'

import { MAX_LENGTH_LOCAL_ODONTO, MAX_LENGTH_OBS_ODONTO } from '../../../model'
import { EvolucaoDenteSupranumerarioModel } from './DentesSupranumerariosField'

interface DentesSupranumerariosFormProps extends Omit<FormProps<EvolucaoDenteSupranumerarioModel>, 'validate'> {}

const validator = createValidator<EvolucaoDenteSupranumerarioModel>({
  local: [required, maxLength(MAX_LENGTH_LOCAL_ODONTO)],
  procedimentos: [required],
  observacao: [maxLength(MAX_LENGTH_OBS_ODONTO)],
})

export const DentesSupranumerariosForm = (props: DentesSupranumerariosFormProps) => {
  const { onSubmit, ...rest } = props

  const { getServerTimeNow } = useServerTime()

  const handleSubmit = (values, formApi) => {
    onSubmit({ ...trimSupranumerariosObservacao(values), lastUpdate: getServerTimeNow().valueOf() }, formApi)
  }

  return <Form<EvolucaoDenteSupranumerarioModel> validate={validator} onSubmit={handleSubmit} {...rest} />
}

const trimSupranumerariosObservacao = (values: EvolucaoDenteSupranumerarioModel): EvolucaoDenteSupranumerarioModel => ({
  ...values,
  observacao: values?.observacao?.trim() ?? null,
})
