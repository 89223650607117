import { TableFooter, useTheme } from 'bold-ui'
import { PageInfo, PageParams } from 'graphql/types.generated'
import React from 'react'

interface UnificacaoBaseCadastrosDuplicadosCidadaoFooterProps {
  pageInfo: PageInfo
  onChange(values: PageParams): void
}

export function UnificacaoBaseCadastrosDuplicadosCidadaoFooter(
  props: UnificacaoBaseCadastrosDuplicadosCidadaoFooterProps
) {
  const theme = useTheme()

  const pageChange = (page: number) => {
    props.onChange({
      ...{ size: props.pageInfo.size },
      page,
    })
  }

  const sizeChange = (size: number) => {
    props.onChange({
      ...{ size: props.pageInfo.size },
      size,
      page: 0,
    })
  }

  return (
    <>
      <TableFooter
        style={{ backgroundColor: theme.pallete.surface.main }}
        totalElements={props.pageInfo?.totalElements}
        totalPages={props.pageInfo?.totalPages}
        pageSize={props.pageInfo?.size}
        page={props.pageInfo?.number}
        onPageChange={pageChange}
        onSizeChange={sizeChange}
      />
    </>
  )
}
