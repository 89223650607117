/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HFlow, Icon, Text, Tooltip, useTheme, VFlow } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import { format } from 'date-fns'

import { LembreteModel } from '../types/LembreteModel'

interface LembretesListCardProps {
  lembretes: LembreteModel[]
  mostrarAutor: boolean
}

export function LembretesListCard(props: LembretesListCardProps) {
  const theme = useTheme()
  const { lembretes, mostrarAutor } = props

  return (
    <VFlow vSpacing={0.5}>
      {lembretes.slice(0, 3).map((lembrete) => {
        const { _id: uuid, ultimoLembrete, isRegistradoAgora } = lembrete
        return (
          <HFlow hSpacing={0.5} alignItems='flex-start' key={uuid}>
            <li
              style={{
                marginLeft: '1.25rem',
              }}
            >
              <VFlow vSpacing={0}>
                <Ellipsis maxLines={3}>{ultimoLembrete.descricao.removeTags()}</Ellipsis>
                {mostrarAutor && (
                  <Text fontStyle='italic'>
                    {' '}
                    Por {ultimoLembrete.lotacao.profissional.nome.titleCase().truncate(16)} |{' '}
                    {ultimoLembrete.lotacao.cbo.nome.capitalize().truncate(26)} |{' '}
                    {format(ultimoLembrete.data, 'dd/MM/yyyy')}
                  </Text>
                )}
              </VFlow>
            </li>

            {isRegistradoAgora && (
              <Tooltip text='Registrado agora'>
                <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
              </Tooltip>
            )}
          </HFlow>
        )
      })}
    </VFlow>
  )
}
