import { AcessoCbo } from 'graphql/types.generated'

export const grupoCboAntecedentes = [
  AcessoCbo.MEDICO,
  AcessoCbo.CIRURGIAO_DENTISTA,
  AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
  AcessoCbo.ENFERMEIRO,
  AcessoCbo.TSB,
  AcessoCbo.NIVEL_MEDIO,
  AcessoCbo.PSICOLOGO,
  AcessoCbo.NUTRICIONISTA,
]
