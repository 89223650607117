/** @jsx jsx */
import { jsx } from '@emotion/core'
import useSession from 'components/auth/useSession'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { PecSwitch } from 'components/route'
import { MedicoesAtendimentosAnterioresQuery } from 'graphql/types.generated'
import { match, Redirect, Route } from 'react-router'
import { emptyArray } from 'util/array'
import { CidadaoMedicoes } from 'view/atendimentos/detail/soap/aside/medicoes/model'
import { grupoCboMedicoes } from 'view/atendimentos/detail/soap/objetivo/acessos'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { PeriodoGestacaoModel } from '../types/PeriodoGestacaoModel'
import {
  convertMedicaoAnterior,
  convertMedicaoAtendimento,
  convertMedicaoOutrosAtendimentos,
  convertMedicoesNoNascimento,
} from './convert'
import { HistoricoMedicoesAntropometriaView } from './HistoricoMedicoesAntropometriaView'
import { HistoricoMedicoesGlicemiaView } from './HistoricoMedicoesGlicemiaView'
import { EditableHistoricoMedicoesModalProps } from './HistoricoMedicoesModal'
import { HistoricoMedicoesPreNatalView } from './HistoricoMedicoesPreNatalView'
import { HistoricoMedicoesSinaisVitaisView } from './HistoricoMedicoesSinaisVitaisView'
import { MedicaoAnteriorFormModel } from './MedicaoAnteriorForm'

export interface EditableHistoricoMedicoesModalBodyProps extends EditableHistoricoMedicoesModalProps {
  initialRoute: string
  match: match
  medicoes: ReadonlyArray<MedicoesAtendimentosAnterioresQuery['medicoes'][number]>
  medicoesNoNascimento: MedicoesAtendimentosAnterioresQuery['medicoesNoNascimento']
  isAtendimentoObservacao: boolean
}

export function EditableHistoricoMedicoesModalBody({
  fieldName,
  prontuarioId,
  cidadao,
  dataAtendimento,
  initialRoute,
  match,
  medicoes = emptyArray,
  medicoesAtendimentoAtual,
  medicoesNoNascimento,
  preNatalAtendimentoAtual,
  dumAtendimentoAtual,
  resultadosExamesAtendimentoAtual,
  gestacoes,
  isAtendimentoObservacao,
}: EditableHistoricoMedicoesModalBodyProps) {
  const formField = usePecField({ name: fieldName })
  const { checkAuthorization } = useSession()
  const medicoesAnteriores: MedicaoAnteriorFormModel[] = formField.input.value ?? []

  const medicoesExibir: HistoricoMedicaoModel[] = [
    ...medicoesAnteriores.map(convertMedicaoAnterior),
    ...(medicoesAtendimentoAtual ? [convertMedicaoAtendimento(medicoesAtendimentoAtual, dataAtendimento)] : []),
    ...[...medicoes].sort((a, b) => b.dataMedicao - a.dataMedicao).map(convertMedicaoOutrosAtendimentos),
    ...(medicoesNoNascimento ? [convertMedicoesNoNascimento(medicoesNoNascimento, cidadao.dataNascimento)] : []),
  ]

  const onAddMedicaoAnterior = (medicao: MedicaoAnteriorFormModel) => {
    formField.input.onChange([medicao, ...formField.input.value])
    formField.input.onBlur()
  }

  const onRemoveMedicaoAnterior = (id: ID) => {
    formField.input.onChange([...formField.input.value?.filter((m: MedicaoAnteriorFormModel) => m.id !== id)])
    formField.input.onBlur()
  }

  return (
    <PecSwitch>
      <Redirect exact path={match.url} to={`${match.url}${initialRoute}`} />
      <Route
        path={`${match.url}/pre-natal`}
        render={(renderProps) => (
          <HistoricoMedicoesPreNatalView
            {...renderProps}
            prontuarioId={prontuarioId}
            preNatalAtendimentoAtual={preNatalAtendimentoAtual}
            resultadosExamesAtendimentoAtual={resultadosExamesAtendimentoAtual}
            dumAtendimentoAtual={dumAtendimentoAtual}
            dataAtendimento={dataAtendimento}
          />
        )}
      />
      <Route
        path={`${match.url}/antropometria`}
        render={(renderProps) => (
          <HistoricoMedicoesAntropometriaView
            {...renderProps}
            canEditOrAdd={checkAuthorization(grupoCboMedicoes.adicionar)}
            medicoes={medicoesExibir}
            cidadao={cidadao}
            gestacoes={gestacoes}
            dataAtendimento={dataAtendimento}
            isAtendimentoObservacao={isAtendimentoObservacao}
            onAddMedicaoAnterior={onAddMedicaoAnterior}
            onRemoveMedicaoAnterior={onRemoveMedicaoAnterior}
          />
        )}
      />
      <Route
        path={`${match.url}/sinaisVitais`}
        render={(renderProps) => (
          <HistoricoMedicoesSinaisVitaisView {...renderProps} medicoes={medicoesExibir} gestacoes={gestacoes} />
        )}
      />
      <Route
        path={`${match.url}/glicemia`}
        render={(renderProps) => (
          <HistoricoMedicoesGlicemiaView {...renderProps} medicoes={medicoesExibir} gestacoes={gestacoes} />
        )}
      />
    </PecSwitch>
  )
}

export interface ReadOnlyHistoricoMedicoesModalBodyProps {
  initialRoute: string
  match: match
  cidadao: CidadaoMedicoes
  prontuarioId: ID
  medicoes: ReadonlyArray<MedicoesAtendimentosAnterioresQuery['medicoes'][number]>
  medicoesNoNascimento: MedicoesAtendimentosAnterioresQuery['medicoesNoNascimento']
  gestacoes: ReadonlyArray<PeriodoGestacaoModel>
  isAtendimentoObservacao: boolean
}

export function ReadOnlyHistoricoMedicoesModalBody({
  initialRoute,
  match,
  cidadao,
  prontuarioId,
  medicoes = emptyArray,
  medicoesNoNascimento,
  isAtendimentoObservacao,
  gestacoes,
}: ReadOnlyHistoricoMedicoesModalBodyProps) {
  const medicoesExibir: HistoricoMedicaoModel[] = [
    ...[...medicoes].sort((a, b) => b.dataMedicao - a.dataMedicao).map(convertMedicaoOutrosAtendimentos),
    ...(medicoesNoNascimento ? [convertMedicoesNoNascimento(medicoesNoNascimento, cidadao.dataNascimento)] : []),
  ]

  return (
    <PecSwitch>
      <Redirect exact path={match.url} to={`${match.url}${initialRoute}`} />
      <Route
        path={`${match.url}/pre-natal`}
        render={(renderProps) => (
          <HistoricoMedicoesPreNatalView {...renderProps} prontuarioId={prontuarioId} readOnly />
        )}
      />
      <Route
        path={`${match.url}/antropometria`}
        render={(renderProps) => (
          <HistoricoMedicoesAntropometriaView
            {...renderProps}
            medicoes={medicoesExibir}
            cidadao={cidadao}
            gestacoes={gestacoes}
            canEditOrAdd={false}
            isAtendimentoObservacao={isAtendimentoObservacao}
          />
        )}
      />
      <Route
        path={`${match.url}/sinaisVitais`}
        render={(renderProps) => (
          <HistoricoMedicoesSinaisVitaisView {...renderProps} medicoes={medicoesExibir} gestacoes={gestacoes} />
        )}
      />
      <Route
        path={`${match.url}/glicemia`}
        render={(renderProps) => (
          <HistoricoMedicoesGlicemiaView {...renderProps} medicoes={medicoesExibir} gestacoes={gestacoes} />
        )}
      />
    </PecSwitch>
  )
}
