import { HFlow, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { capitalize, titleCase } from 'util/strConversor'

export default interface ProfissionalHeaderIdentifierDto {
  nomeProfissional: string
  lotacao: any
}

export interface ProfissionalHeaderIdentifierProps {
  profissional: ProfissionalHeaderIdentifierDto
}

export function ProfissionalHeaderIdentifier(props: ProfissionalHeaderIdentifierProps) {
  return (
    <VFlow vSpacing={0}>
      <Text fontSize={1} fontWeight='bold'>
        {titleCase(props.profissional.nomeProfissional)}
      </Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>Unidade de saúde</Text>
        <Text>{capitalize(props.profissional.lotacao.unidadeSaude.nome)}</Text>
      </HFlow>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>CBO</Text>
        <Text>
          {capitalize(props.profissional.lotacao.cbo.nome)} - {props.profissional.lotacao.cbo.cbo2002}
        </Text>
      </HFlow>
      <HFlow hSpacing={0.5}>
        <HLabel title='Equipe'>
          {props.profissional.lotacao.equipe
            ? capitalize(props.profissional.lotacao.equipe.nome) + ' - ' + props.profissional.lotacao.equipe.ine
            : 'Lotação sem equipe'}
        </HLabel>
      </HFlow>
    </VFlow>
  )
}
