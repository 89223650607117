/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import { gray, purple } from 'bold-ui/lib/styles/colors'
import preNatalImage from 'images/soap/ic_pre-natal.png'
import { useMemo } from 'react'

interface PreNatalObjetivoButtonProps {
  isSelecionado: boolean
  handleClick(): void
  tooltipText?: string
  disabled?: boolean
}

export function PreNatalObjetivoButton(props: PreNatalObjetivoButtonProps) {
  const { isSelecionado, handleClick, tooltipText, disabled } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(isSelecionado, theme), [isSelecionado, theme])

  return (
    <Tooltip text={tooltipText}>
      <Button size='medium' onClick={handleClick} style={styles.button} disabled={disabled}>
        <HFlow alignItems='center'>
          <img src={preNatalImage} alt='Pré-natal' />
          <Text fontWeight='bold' style={styles.text}>
            Pré-natal
          </Text>
        </HFlow>
      </Button>
    </Tooltip>
  )
}

const createStyles = (isSelecionado: boolean, theme: Theme) => ({
  button: css`
    display: flex;
    align-items: center;
    background-color: ${isSelecionado ? purple.c60 : gray.c100};
    border: 1px solid ${purple.c60};
    border-radius: 2px;
    &:not(:disabled):hover {
      background-color: ${isSelecionado ? purple.c50 : purple.c90};
      border-color: ${isSelecionado ? purple.c50 : purple.c60};
    }
  `,
  text: css`
    color: ${isSelecionado ? theme.pallete.gray.c100 : purple.c40};
  `,
})
