import { OperationVariables, QueryResult } from '@apollo/react-common'
import {
  LazyQueryHookOptions as OptionsType,
  QueryLazyOptions,
  useLazyQuery as useApolloLazyQuery,
} from '@apollo/react-hooks'
import { useErrorHandler } from 'components/error'
import { DocumentNode } from 'graphql'
import { useEffect } from 'react'

// Override useLazyQuery from @apollo/react-hooks to use custom defaults

export function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options: OptionsType<TData, TVariables> = {}
): [(options?: QueryLazyOptions<TVariables> | undefined) => void, QueryResult<TData, TVariables>] {
  const handleError = useErrorHandler()

  const [execute, data] = useApolloLazyQuery<TData, TVariables>(query, {
    fetchPolicy: 'network-only',
    ...options,
  })

  useEffect(() => {
    if (data.error) {
      handleError(data.error)
    }
  }, [data.error, handleError])

  return [execute, data]
}

export type LazyQueryHookOptions<TData = any, TVariables = OperationVariables> = OptionsType<TData, TVariables>
