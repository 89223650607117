/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Alert,
  Button,
  Cell,
  Grid,
  Heading,
  HFlow,
  InfoLabel,
  Modal,
  ModalBody,
  ModalFooter,
  Tag,
  Tooltip,
  VFlow,
} from 'bold-ui'
import { Cep, Telefone } from 'components/label'
import { LoadingIndicator } from 'components/loading'
import { useVisualizarCidadaosImovelQuery } from 'graphql/hooks.generated'
import { StatusFichaEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { humanizeDate } from 'util/date/humanize-date'
import {
  EditarAtualizarCidadaoCallback,
  EditarAtualizarImovelCallback,
  IMOVEIS_COM_RESPONSAVEL_TECNICO,
  localizacaoTipoRecord,
} from 'view/visualizacaoTerritorial/model'
import { getTipoImovelIcon } from 'view/visualizacaoTerritorial/utils/visualizacaoTerritorialUtils'

import { ResponsavelTecnicoImovelList } from '../list/ResponsavelTecnicoImovelList'

interface ModalOutrosImoveisProps {
  imovelId: ID
  isFichaNaoProcessadaAplicativo: boolean
  isModalOpen: boolean
  onCloseModal(): void
  onEditarAtualizarImovel: EditarAtualizarImovelCallback
  onEditarAtualizarCidadao: EditarAtualizarCidadaoCallback
}

export function ModalOutrosImoveis(props: ModalOutrosImoveisProps) {
  const { imovelId, isModalOpen, isFichaNaoProcessadaAplicativo, onCloseModal, onEditarAtualizarImovel } = props

  const { data, loading } = useVisualizarCidadaosImovelQuery({
    variables: {
      input: imovelId,
    },
    fetchPolicy: 'network-only',
  })

  const {
    id,
    tipoCdsImovelId,
    uuidDomicilio,
    bairro,
    cep,
    complemento,
    dataUltimaVisita,
    logradouro,
    responsavelTecnico,
    numero,
    telefoneContato,
    telefoneResidencial,
    ultimaFichaProcessada,
    ultimaFichaCadastrada,
    dataUltimaAtualizacao,
    tipoLogradouro,
  } = data?.imovel || {}

  const tagLocalizacao = localizacaoTipoRecord[ultimaFichaProcessada?.tipoLocalizacaoId]
  const { IconImovel, fill, tooltip } = getTipoImovelIcon(tipoCdsImovelId, ultimaFichaProcessada?.tipoDomicilioId)
  const styles = createStyles(fill)
  const isUltimaFichaCadastradaProcessada = ultimaFichaCadastrada?.isProcessado === StatusFichaEnum.ENVIADA

  return loading ? (
    <LoadingIndicator />
  ) : (
    <Modal
      style={css`
        width: 40rem;
      `}
      closeOnBackdropClick
      onClose={onCloseModal}
      open={isModalOpen}
    >
      <ModalBody>
        <HFlow alignItems='center' hSpacing={0.5}>
          <IconImovel css={styles.icon} />
          <VFlow vSpacing={0}>
            <Heading level={5}>{tooltip}</Heading>
            <Heading level={1} style={styles.breakText}>
              {`${tipoLogradouro.nome} ${logradouro}`.titleCase()} nº {numero ?? 'S/N'}
              {complemento ? ` | ${complemento.titleCase()}` : ''}
            </Heading>
          </VFlow>
        </HFlow>
        <HFlow css={styles.hflowItens}>
          {IMOVEIS_COM_RESPONSAVEL_TECNICO.includes(tipoCdsImovelId) && (
            <Fragment>
              {tagLocalizacao && <Tag style={styles.tag}>{tagLocalizacao}</Tag>}
              <Tag style={styles.tag}>
                {`Nº de moradores: ${ultimaFichaProcessada?.numeroMoradores ?? 'não informado'}`}
              </Tag>
              <Tooltip text='Tempo desde a última visita mais recente realizada para o imóvel em si, a familia ou qualquer um dos moradores.'>
                <Tag style={styles.tag}>
                  {`Última visita: ${dataUltimaVisita ? humanizeDate(dataUltimaVisita) : 'desconhecido'}`}
                </Tag>
              </Tooltip>
            </Fragment>
          )}
        </HFlow>
        <VFlow style={styles.vflowItens}>
          <Grid>
            <Cell size={3}>
              <InfoLabel title='CEP'>
                <Cep value={cep} />
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Bairro' childStyles={styles.breakText}>
                {bairro?.titleCase()}
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Telefone'>
                <Telefone value={telefoneContato ?? telefoneResidencial} />
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Última atualização'>
                {dataUltimaAtualizacao ? humanizeDate(dataUltimaAtualizacao) : 'desconhecida'}
              </InfoLabel>
            </Cell>
            {!isUltimaFichaCadastradaProcessada && (
              <Cell size={9}>
                <Alert type='info'> Existem fichas ainda não processadas para este imóvel</Alert>
              </Cell>
            )}
            {IMOVEIS_COM_RESPONSAVEL_TECNICO.includes(tipoCdsImovelId) && responsavelTecnico && (
              <Cell size={12}>
                <VFlow>
                  <ResponsavelTecnicoImovelList responsavelTecnico={responsavelTecnico} />
                </VFlow>
              </Cell>
            )}
          </Grid>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Tooltip
            text={
              isFichaNaoProcessadaAplicativo
                ? 'Ação bloqueada pois existe uma ficha ainda não processada vinda do aplicativo e-SUS Território.'
                : ''
            }
            placement='bottom-end'
          >
            <Button
              kind='normal'
              disabled={isFichaNaoProcessadaAplicativo}
              onClick={() =>
                onEditarAtualizarImovel(isUltimaFichaCadastradaProcessada, uuidDomicilio, ultimaFichaCadastrada?.id, id)
              }
            >
              {isUltimaFichaCadastradaProcessada ? 'Atualizar imóvel' : 'Editar imóvel'}
            </Button>
          </Tooltip>
          <Button title='Fechar' kind='primary' onClick={onCloseModal}>
            Fechar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const createStyles = (iconFill: string) => ({
  tag: css`
    margin-right: 0.25rem;
  `,
  icon: css`
    fill: ${iconFill};
    width: 2.5rem;
    height: 2.5rem;
  `,
  vflowItens: css`
    margin-top: 1rem;
  `,
  hflowItens: css`
    margin-top: 1rem;
  `,
  breakText: css`
    word-break: break-all;
  `,
})
