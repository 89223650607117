import { LoteImunobiologicoInput } from 'graphql/types.generated'

import { LoteImunobiologicoFormModel } from './insert/LoteImunologicoTooltipAddForm'

export default (model: LoteImunobiologicoFormModel): LoteImunobiologicoInput => ({
  id: model.id,
  idFabricante: model.fabricante.id,
  nomeFabricante: model.fabricante.nome,
  lote: model.lote,
  validade: model.validade,
  idImunobiologico: model.imunobiologico.id,
})
