/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormLabel, HFlow, Theme, useTheme } from 'bold-ui'
import { StatusAvaliadoEnum } from 'graphql/types.generated'
import { useEffect } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { AvaliacaoDesenvolvimentoModel } from 'view/atendimentos/detail/soap/objetivo/puericultura/model'
import { isPresenteComAtraso } from 'view/atendimentos/detail/soap/objetivo/puericultura/util'

import { IdadeField } from '.'

export interface AlcancadoComFieldProps {
  name: MetaPath<AvaliacaoDesenvolvimentoModel>
}

export function AlcancadoComField(props: AlcancadoComFieldProps) {
  const { name } = props
  const theme = useTheme()
  const classes = createStyles(theme)

  const {
    input: { value, onChange },
  } = useField<AvaliacaoDesenvolvimentoModel>(name.absolutePath())

  useEffect(() => {
    if (
      isPresenteComAtraso(value.alcancadoCom.anos, value.alcancadoCom.meses, value.faixaEtariaFim) &&
      value.statusButton === StatusAvaliadoEnum.PRESENTE
    ) {
      onChange({ ...value, statusButton: StatusAvaliadoEnum.PRESENTE_COM_ATRASO })
    } else if (
      !isPresenteComAtraso(value.alcancadoCom.anos, value.alcancadoCom.meses, value.faixaEtariaFim) &&
      value.statusButton === StatusAvaliadoEnum.PRESENTE_COM_ATRASO
    ) {
      onChange({ ...value, statusButton: StatusAvaliadoEnum.PRESENTE })
    }
  }, [onChange, value])

  return (
    <HFlow justifyContent='flex-end' alignItems='baseline' style={classes.cell}>
      <FormLabel label='Alcançado com' required />
      <IdadeField name={name.alcancadoCom} holderAnos='00' clearable={false} />
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  cell: css`
    margin-top: 0.5rem;
  `,
})
