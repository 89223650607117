import React from 'react'
import { masks, onlyNumbers } from 'util/mask'

import { MaskedField, MaskedFieldProps } from '../final-form/MaskedField'

export interface DeclaracaoObitoFieldProps extends MaskedFieldProps {}

export function DeclaracaoObitoField(props: DeclaracaoObitoFieldProps) {
  return <MaskedField mask={masks.numeroDeclaracaoObito} placeholder='00000000-0' parse={onlyNumbers} {...props} />
}
