import { Breadcrumb } from 'components/breadcrumb'
import { useRouter } from 'hooks/useRouter'
import React from 'react'

import { TransmissaoDetailRootView } from './TransmissaoDetailRootView'
import { TransmissaoTabRootView } from './TransmissaoTabRootView'

export function TransmissaoRootView() {
  const { match, history } = useRouter()
  const toRender = history.location.pathname.startsWith(`${match.url}/detail/`) ? (
    <TransmissaoDetailRootView />
  ) : (
    <TransmissaoTabRootView />
  )

  return (
    <>
      <Breadcrumb title='Transmissão de dados' />
      {toRender}
    </>
  )
}
