import { useCallback, useEffect, useRef } from 'react'

export const useLoginPopup = () => {
  const winRef = useRef<Window>(null)
  const timerRef = useRef<NodeJS.Timeout>(null)

  const openLoginPopup = useCallback((href: string) => {
    winRef.current = window.open(href, 'popup', 'menubar=1,resizable=1,width=850,height=650')
    return new Promise((resolve) => {
      timerRef.current = setInterval(() => {
        if (!winRef.current || winRef.current.closed) {
          timerRef.current && clearInterval(timerRef.current)
          resolve(false)
          return
        }

        let currentUrl: string
        try {
          currentUrl = winRef.current.location.href
        } catch {
          return
        }
        if (!currentUrl) {
          return
        }

        if (currentUrl.includes(winRef.current.location.origin)) {
          timerRef.current && clearInterval(timerRef.current)
          winRef.current.close()
          resolve(true)
          return
        }
      }, 1000)
    })
  }, [])

  //onDestroy
  useEffect(() => () => {
    timerRef.current && clearInterval(timerRef.current)
  })

  return { openLoginPopup }
}
