/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Icon } from 'bold-ui'

import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'

interface AcessarVideochamadaAgendamentoButtonProps {
  videochamadaUuid: string
  isDropdown?: boolean
}

export function AcessarVideochamadaAgendamentoButton(props: AcessarVideochamadaAgendamentoButtonProps) {
  const { videochamadaUuid, isDropdown = false } = props

  const onClick = () => {
    window.open(
      `/videochamada/${videochamadaUuid}`,
      'videochamada',
      `width=${window.screen.availWidth}, heigth=${window.screen.availHeight}, noreferrer`
    )
  }

  return (
    <MenuAcoesAgendamentoButton
      text='Entrar na videochamada'
      tooltip='Entrar na videochamada'
      isDropdown={isDropdown}
      onClick={onClick}
      icon={<Icon icon='videoOutline' />}
    />
  )
}
