import { Button, Cell, Grid, Heading, Text, VFlow } from 'bold-ui'
import { LogoutLinkWrapper } from 'components/auth/LogoutLinkWrapper'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { FormFooter } from 'components/form/FormFooter'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { SessaoDocument, useSelecionarAcessoMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'
import { notify } from 'util/multitab'

import { Acesso } from './Acesso'
import selecaoAcessoSortCompare from './SelecaoAcessoSorter'

export function SelecaoAcessoLoginView() {
  return <SelecaoAcessoView hasSairButton />
}

export interface SelecaoAcessoViewProps {
  hasSairButton?: boolean
}

export function SelecaoAcessoView(props: SelecaoAcessoViewProps) {
  const { hasSairButton } = props

  const { data } = useSession({ fetchPolicy: 'cache-only' })

  if (!data) {
    return null
  }

  return (
    <>
      <PageHeaderSection title={`Bem vindo, ${data.profissional.nome.titleCase()}`} />

      <PageContent>
        <VFlow vSpacing={1.5}>
          <Heading level={2}>Escolha um acesso para continuar</Heading>
          <Grid gap={5} wrap>
            <Cell xs={6}>
              <AcessosUsuarioLogado />
            </Cell>
          </Grid>

          {hasSairButton && (
            <FormFooter>
              <LogoutLinkWrapper>
                <Button>Sair</Button>
              </LogoutLinkWrapper>
            </FormFooter>
          )}
        </VFlow>
      </PageContent>
    </>
  )
}

export function AcessosUsuarioLogado() {
  const history = useHistory()

  const { data, resetApolloCache } = useSession({ fetchPolicy: 'network-only' })
  const errorHandler = useErrorHandler()

  const [selecionarAcessoMutation] = useSelecionarAcessoMutation()

  if (!data) {
    return null
  }

  const selecionarAcesso = (acessoId: ID) =>
    selecionarAcessoMutation({
      variables: { input: { id: acessoId } },
      refetchQueries: [{ query: SessaoDocument }],
    })
      .then(async () => {
        acessoId !== data.acesso?.id && (await resetApolloCache())
        history.replace('/')
        notify('PERFIL_SELECIONADO')
      })
      .catch(errorHandler)

  if ((data.profissional.acessos?.length ?? 0) === 0) {
    return (
      <VFlow>
        <Text>
          Você não possui nenhum acesso ativo. Entre em contato com o administrador do sistema para liberar o seu
          acesso.
        </Text>
        <LogoutLinkWrapper>
          <Button>Voltar</Button>
        </LogoutLinkWrapper>
      </VFlow>
    )
  }

  const acessos = [...data.profissional.acessos].sort(selecaoAcessoSortCompare)

  return (
    <VFlow>
      {acessos.map((acesso) => (
        <Acesso key={acesso.id} acesso={acesso} onClick={selecionarAcesso} />
      ))}
    </VFlow>
  )
}
