/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, FormControlProps, HFlow, VFlow } from 'bold-ui'

import { CheckboxField, CheckboxFieldProps, ErrorField } from '../final-form'
import { useRadioGroupColumns } from './RadioGroupField'

export type CheckboxOption<T> = { value: T; label: string | React.ReactNode }

export interface CheckboxGroupFieldProps<T extends string | number | string[]>
  extends Omit<FormControlProps, 'clearable'> {
  name: CheckboxFieldProps['name']
  options: CheckboxOption<T>[]
  lines?: number
  vSpacing?: number
  hSpacing?: number
  disabled?: boolean
  onChange?: CheckboxFieldProps['onChange']
  showErrorField?: boolean
}

export function CheckboxGroupField<T extends string | number | string[]>(props: CheckboxGroupFieldProps<T>) {
  const {
    name,
    options,
    lines = 1,
    vSpacing = 0.5,
    hSpacing,
    onChange,
    disabled,
    showErrorField = true,
    ...rest
  } = props

  const cols = useRadioGroupColumns(options, lines)

  return (
    <FormControl {...rest}>
      <VFlow vSpacing={0.5}>
        <HFlow
          hSpacing={hSpacing}
          style={css`
            margin-left: -0.25rem;
          `}
        >
          {cols.map((col, index) => (
            <VFlow vSpacing={vSpacing} key={index}>
              {col.map(({ value, label }) => (
                <CheckboxField
                  key={value.toString()}
                  name={name}
                  value={value}
                  label={label}
                  onChange={onChange}
                  disabled={disabled}
                />
              ))}
            </VFlow>
          ))}
        </HFlow>
        {showErrorField && <ErrorField name={name} />}
      </VFlow>
    </FormControl>
  )
}
