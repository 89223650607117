import { GarantiaAcessoItemDeleteInput } from 'graphql/types.generated'

import { GarantiaAcessoRemocaoModel } from '../model-garantiaAcesso'

export const convertModelToInput = (values: GarantiaAcessoRemocaoModel): GarantiaAcessoItemDeleteInput => ({
  garantiaAcessoId: values.garantiaAcessoId,
  motivoSaida: values.motivoSaida,
  tentativasContato: values.tentativasContato,
  observacaoSaida: values.observacaoSaida,
})
