/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow, Text } from 'bold-ui'

interface ObservacaoProps {
  observacao?: string
  italic?: boolean
  style?: ExternalStyles
}

export const Observacao = (props: ObservacaoProps) => {
  const { observacao, style, italic = true } = props
  if (!observacao) {
    return null
  }
  return (
    <HFlow
      hSpacing={0.2}
      style={css(
        css`
          padding: 0.5rem 0;
        `,
        style
      )}
    >
      <Text fontWeight='bold'>Observações: </Text>
      <Text
        fontStyle={italic && 'italic'}
        style={css`
          word-break: break-all;
        `}
      >
        {props.observacao}
      </Text>
    </HFlow>
  )
}
