import { RadioGroupField, RadioGroupFieldProps, RadioOption } from 'components/form'
import { ListaEsperaMotivoSaidaEnum } from 'graphql/types.generated'
import React from 'react'

import { motivoSaidaRecord } from '../model-garantiaAcesso'

interface MotivoSaidaRadioGroupFieldProps extends Omit<RadioGroupFieldProps<ListaEsperaMotivoSaidaEnum>, 'options'> {}

export function MotivoSaidaRadioGroupField(props: MotivoSaidaRadioGroupFieldProps) {
  return <RadioGroupField options={options} lines={3} {...props} />
}

const options: RadioOption<ListaEsperaMotivoSaidaEnum>[] = [
  {
    value: ListaEsperaMotivoSaidaEnum.ATENDIDO_OUTRO_ESTABELECIMENTO,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.ATENDIDO_OUTRO_ESTABELECIMENTO],
  },
  {
    value: ListaEsperaMotivoSaidaEnum.MUDOU_TERRITORIO,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.MUDOU_TERRITORIO],
  },
  {
    value: ListaEsperaMotivoSaidaEnum.ATENDIMENTO_AGENDADO,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.ATENDIMENTO_AGENDADO],
  },
  {
    value: ListaEsperaMotivoSaidaEnum.OUTROS,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.OUTROS],
  },
  {
    value: ListaEsperaMotivoSaidaEnum.NAO_RESPONDEU_TENTATIVAS_CONTATO,
    label: motivoSaidaRecord[ListaEsperaMotivoSaidaEnum.NAO_RESPONDEU_TENTATIVAS_CONTATO],
  },
]
