import { Link } from 'bold-ui'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const InformacoesParaContatoSection = () => {
  return (
    <TermosDeUsoHeadingSection title='9. Informações para contato' id='informacoes_para_contato'>
      <p>
        Em caso de dúvidas relacionadas ao Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS, entre em contato
        através dos nossos canais de atendimento:
      </p>
      <br />
      <p>
        Canal de Suporte e-SUS APS:{' '}
        <Link fontSize={0.875} href='https://esusaps.freshdesk.com/support/login' target='_blank'>
          https://esusaps.freshdesk.com/support/login
        </Link>
      </p>
    </TermosDeUsoHeadingSection>
  )
}
