import { createValidator, requiredRichText, richTextMaxLength } from 'util/validation'

import { PlanoFormModel } from '../PlanoForm'
import { intervencoesProcedimentosPlanoValidator } from './validatorProcedimentos'

export const planoObservacaoValidator = () =>
  createValidator<PlanoFormModel>({
    texto: [requiredRichText, richTextMaxLength(4000)],
    intervencoesProcedimentos: intervencoesProcedimentosPlanoValidator,
  })
