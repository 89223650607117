import { DropdownItem, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { DropdownButton, DropdownButtonProps } from 'components/dropdown/DropdownButton'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useAlterarAtivacaoAcessoMutation, useDeleteEstagioMutation } from 'graphql/hooks.generated'
import React from 'react'
import Permissions from 'types/Permissions'
import { EstagioModel } from 'view/profissional/detail/model'

export interface EstagioCardDropdownProps extends Partial<DropdownButtonProps> {
  estagio: EstagioModel
  refetch(): any
}

export function EstagioCardDropdown(props: EstagioCardDropdownProps) {
  const alert = useAlert()
  const { hasAuthorization } = useSession({ fetchPolicy: 'cache-only' })
  const handleRejection = useErrorHandler()

  const [deleteEstagio] = useDeleteEstagioMutation()
  const [alterarAtivacaoAcesso] = useAlterarAtivacaoAcessoMutation()

  const { estagio, refetch, ...rest } = props

  const { permissaoExclusao, permissaoAtivacaoInativacao } = estagio.actions

  const handleAlternarAtivacaoLotacao = () => alternarAtivacaoLotacao()

  const alternarAtivacaoLotacao = () => {
    return alterarAtivacaoAcesso({ variables: { id: estagio.id } })
      .then((ret) =>
        alert(
          'success',
          `Lotação de estágio ${ret?.data?.alterarAtivacaoAcesso ? 'ativada' : 'inativada'} com sucesso.`
        )
      )
      .finally(refetch())
      .catch(handleRejection)
  }

  const handleExcluirLotacao = confirm({
    title: 'Deseja excluir a lotação de estágio?',
    confirmLabel: 'Excluir',
    type: 'danger',
    onConfirm: () =>
      deleteEstagio({ variables: { estagioId: estagio.id } })
        .then(() => {
          alert('success', 'Lotação de estágio excluída com sucesso.')
          refetch()
        })
        .catch(handleRejection),
  })

  const items: React.ReactNode[] = []
  if (hasAuthorization(Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirLotacao)) {
    items.push(
      <Tooltip key='ativar/inativar' text={permissaoAtivacaoInativacao.hint}>
        <DropdownItem
          key='ativar/inativar'
          onClick={handleAlternarAtivacaoLotacao}
          disabled={!permissaoAtivacaoInativacao.enabled}
        >
          {estagio.ativo ? 'Inativar' : 'Ativar'}
        </DropdownItem>
      </Tooltip>
    )
    items.push(
      <Tooltip key='excluir' text={permissaoExclusao.hint}>
        <DropdownItem type='danger' onClick={handleExcluirLotacao} disabled={!permissaoExclusao.enabled}>
          Excluir
        </DropdownItem>
      </Tooltip>
    )
  }
  if (items.length === 0) {
    return null
  }

  return <DropdownButton {...rest}>{items}</DropdownButton>
}
