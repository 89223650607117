import { ClassificacaoRisco, TipoServico } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

import { MedicoesPanelModel } from '../components/MedicoesPanel/MedicoesPanel'
import { AgendarConsultaPanelModel } from '../detail/soap/finalizacao/components/AgendarConsultaPanel'
import {
  DesfechoAtendimentoFormModel,
  ManterCidadaoLista,
} from '../detail/soap/finalizacao/components/DesfechoAtendimentoPanel'
import { ProcedimentoSigtapFieldModel } from '../detail/soap/finalizacao/components/ProcedimentoSigtapField'
import { MotivoConsultaFormModel } from './motivo-consulta/MotivoConsultaForm'

export type TiposServicoEscutaInicial = Partial<TipoServico>

export interface EscutaInicialState {
  lastSaved?: Date
  motivoConsulta?: MotivoConsultaFormModel
  openedAccordions?: (string | number)[]
  medicoes?: MedicoesPanelModel
  desfecho?: DesfechoAtendimentoFormModel
  procedimentos?: ProcedimentoSigtapFieldModel[]
  procedimentosAdministrativos?: ProcedimentoSigtapFieldModel[]
  classificacaoRisco?: ClassificacaoRisco
  agendamentoConsulta?: AgendarConsultaPanelModel
  imprimirEscutaInicial?: boolean
}

export const name = metaPath<EscutaInicialState>()

export const initialValue = (
  tiposServico: TiposServicoEscutaInicial[],
  canCreateNovoAtendimento: boolean
): Partial<EscutaInicialState> => ({
  medicoes: {
    openedAccordions: ['medicoes'],
  },
  openedAccordions: ['D'],
  lastSaved: new Date(),
  desfecho: {
    manterCidadaoLista: canCreateNovoAtendimento ? ManterCidadaoLista.SIM : ManterCidadaoLista.NAO,
    atendimento: { tiposServico: tiposServico?.map((t) => t.id) },
  },
})
