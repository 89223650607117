import { Alert, Button, HFlow, Text, VFlow } from 'bold-ui'
import React, { useEffect } from 'react'
import { animated } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import { emptyArray } from 'util/array'
import { CidadaoListingFormModel } from 'view/cidadao/types/CidadaoPesquisarFormModel'

import UnificacaoCadastroSelectedListItem from './UnificacaoCadastroSelectedListItem'

interface UnificacaoCadastroSelectedListProps {
  cidadaosSelecionados: CidadaoListingFormModel[]
  cidadaoPrincipal: CidadaoListingFormModel
  setCidadaoPrincipal(cidadao: CidadaoListingFormModel): void
  selectCidadao(cidadao: CidadaoListingFormModel, checked: boolean): void
  handleClearSelecionados(): void
  handleOnClickUnificar(): void
}

export const UnificacaoCadastroSelectedList = (props: UnificacaoCadastroSelectedListProps) => {
  const {
    cidadaoPrincipal,
    cidadaosSelecionados = emptyArray,
    setCidadaoPrincipal,
    selectCidadao,
    handleClearSelecionados,
    handleOnClickUnificar,
  } = props

  const cidadaosComFicha = cidadaosSelecionados
    ?.filter((cidadao) => !!cidadao.uuidUltimaFicha)
    .map((cidadao) => cidadao.id)
  const existeSelecionadoComFicha = cidadaosComFicha?.length !== 0

  useEffect(() => {
    existeSelecionadoComFicha &&
      !cidadaosComFicha?.find((id) => cidadaoPrincipal?.id === id) &&
      setCidadaoPrincipal(undefined)
  }, [cidadaoPrincipal, cidadaosComFicha, existeSelecionadoComFicha, setCidadaoPrincipal])

  const handleOnClickPrincipal = (cidadao: CidadaoListingFormModel) => {
    setCidadaoPrincipal(cidadao)
  }

  const title =
    cidadaosSelecionados?.length < 2
      ? 'Selecione ao menos dois cidadãos'
      : !!!cidadaoPrincipal
      ? 'É necessário indicar o cidadão principal para unificar'
      : 'Unificar cadastros'

  return (
    <>
      <VFlow vSpacing={0.5}>
        {cidadaosSelecionados.length > 0 && (
          <Alert type='info' inline>
            O cadastro principal definirá os dados cadastrais como nome, data de nascimento e nome da mãe. A escolha do
            CNS é feita de forma automática e prioriza o CNS definitivo.
          </Alert>
        )}
        <Transition
          items={cidadaosSelecionados}
          keys={(cidadaoSelecionado) => cidadaoSelecionado.id}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {(cidadaoSelecionado) =>
            cidadaosSelecionados.length > 0 &&
            ((props) => (
              <animated.div style={props}>
                <UnificacaoCadastroSelectedListItem
                  cidadao={cidadaoSelecionado}
                  isPrincipal={cidadaoSelecionado?.id === cidadaoPrincipal?.id}
                  disableDefinirComoPrincipal={existeSelecionadoComFicha ? !cidadaoSelecionado.uuidUltimaFicha : false}
                  onClickPrincipal={handleOnClickPrincipal}
                  selectCidadao={selectCidadao}
                  definirButtonTitle={
                    !cidadaoSelecionado.uuidUltimaFicha && existeSelecionadoComFicha
                      ? 'Esse cadastro não pode ser selecionado pois não possui ficha de cadastro individual'
                      : undefined
                  }
                />
              </animated.div>
            ))
          }
        </Transition>
      </VFlow>
      {cidadaosSelecionados.length > 0 && (
        <HFlow justifyContent='flex-end'>
          <Button
            kind='normal'
            skin='outline'
            size='small'
            onClick={handleClearSelecionados}
            data-cy='LimparSelecaoButton'
          >
            Limpar seleção
          </Button>
          <Button
            kind='primary'
            skin='default'
            size='small'
            onClick={handleOnClickUnificar}
            disabled={cidadaosSelecionados?.length < 2 || !!!cidadaoPrincipal}
            title={title}
            data-cy='UnificarCadastroButton'
          >
            Unificar cadastros
          </Button>
        </HFlow>
      )}
      <Transition
        items={cidadaosSelecionados.length}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0 }}
      >
        {(cidadaoSelecionado) =>
          cidadaosSelecionados.length < 1 &&
          ((props) => (
            <animated.div style={props}>
              <Text fontSize={1}>Nenhum cadastro selecionado.</Text>
            </animated.div>
          ))
        }
      </Transition>
    </>
  )
}
