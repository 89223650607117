/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, DropdownItem, Grid, HFlow, Icon, PagedTable, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { DropdownButton } from 'components/dropdown'
import { CheckboxField, Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { HLabel } from 'components/HLabel'
import { Cnpj, Cpf } from 'components/label'
import { confirm } from 'components/modals/confirm'
import { TableBox, usePagedTableProps } from 'components/table'
import { useInativarCredencialRecebimentoExternoMutation } from 'graphql/hooks.generated'
import {
  CredenciaisRecebimentoExternoQuery,
  CredencialRecebimentoExternoQueryInput,
  PageParams,
  TipoPessoaEnum,
} from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

type CredenciaisRecebimentoExternoQueryModel = CredenciaisRecebimentoExternoQuery['credenciaisRecebimentoExterno']['content'][0]

interface CredenciaisRecebimentoExternoTableProps {
  data: CredenciaisRecebimentoExternoQuery
  loading: boolean
  setFilter: (filter: CredencialRecebimentoExternoQueryInput) => void
  refetch: () => void
}

export function CredenciaisRecebimentoExternoTable(props: CredenciaisRecebimentoExternoTableProps) {
  const { data, loading, refetch, setFilter } = props

  const [inativarCredencialRecebimentoExterno] = useInativarCredencialRecebimentoExternoMutation()

  const tableProps = usePagedTableProps<
    CredenciaisRecebimentoExternoQuery['credenciaisRecebimentoExterno'],
    { pageParams?: PageParams }
  >({
    loading,
    result: data?.credenciaisRecebimentoExterno,
    onChange: setFilter,
  })

  const onInativarCredencial = (item: CredenciaisRecebimentoExternoQueryModel) =>
    confirm({
      title: 'Deseja inativar a credencial?',
      body: <InativarCredencialModalBody item={item} />,
      type: 'danger',
      confirmLabel: 'Inativar',
      onConfirm: () =>
        inativarCredencialRecebimentoExterno({ variables: { id: item.id } }).then(() => {
          refetch()
        }),
    })()

  return (
    <TableBox header={<TableHeader onChange={setFilter} />}>
      <PagedTable<CredenciaisRecebimentoExternoQueryModel>
        {...tableProps}
        columns={[
          {
            name: 'data',
            header: 'Data',
            render: renderData,
            style: css`
              min-width: 6.5rem;
              max-width: 6.5rem;
              width: 6.5rem;
            `,
          },
          {
            name: 'responsavel',
            header: 'Responsável',
            render: renderResponsavel,
          },
          {
            name: 'nomeCredencial',
            header: 'Nome da credencial',
            render: (item) => <Text>{item.nomeCredencial}</Text>,
          },
          {
            name: 'email',
            header: 'E-mail',
            render: (item) => (
              <Text
                style={css`
                  word-break: break-word;
                  white-space: pre-line;
                `}
              >
                {item.email}
              </Text>
            ),
          },
          {
            name: 'status',
            header: '',
            render: renderStatus,
          },
          {
            name: 'actions',
            render: (item) => (
              <DropdownButton>
                <DropdownItem onClick={() => onInativarCredencial(item)} disabled={!item.ativo}>
                  Inativar
                </DropdownItem>
              </DropdownButton>
            ),
            align: 'right',
          },
        ]}
      />
    </TableBox>
  )
}

const filterPath = metaPath<CredencialRecebimentoExternoQueryInput>()

const TableHeader = ({ onChange }: { onChange: (values: CredencialRecebimentoExternoQueryInput) => void }) => {
  return (
    <Form
      render={() => (
        <form noValidate>
          <FormDebouncedValueSpy onChange={({ values }) => onChange(values)} />
          <Grid gap={1}>
            <Cell size={5} alignSelf='center'>
              <TextField
                name={filterPath.query}
                icon='zoomOutline'
                placeholder='Pesquise por responsável, CPF, CNPJ ou nome da credencial'
              />
            </Cell>
            <Cell size={7} alignSelf='center'>
              <CheckboxField name={filterPath.somenteAtivas} label='Exibir somente credenciais ativas' />
            </Cell>
          </Grid>
        </form>
      )}
    />
  )
}

const InativarCredencialModalBody = ({ item }: { item: CredenciaisRecebimentoExternoQueryModel }) => (
  <VFlow>
    <Text>
      Ao inativar a credencial a ação não poderá ser desfeita. Caso necessário, após a inativação gere uma nova
      credencial.
    </Text>
    <VFlow vSpacing={0}>
      <HLabel title='Nome da credencial'>{item.nomeCredencial}</HLabel>
      <HLabel title='Responsável'>{item.nomeResponsavel}</HLabel>
      {item.tipoPessoa === TipoPessoaEnum.FISICA ? (
        <HLabel title='CPF'>
          <Cpf value={item.cpfCnpj} />
        </HLabel>
      ) : (
        <HLabel title='CNPJ'>
          <Cnpj value={item.cpfCnpj} />
        </HLabel>
      )}
    </VFlow>
  </VFlow>
)

const renderData = (item: CredenciaisRecebimentoExternoQueryModel) => <DateTime value={item.dataCriacao} />

const renderResponsavel = (item: CredenciaisRecebimentoExternoQueryModel) => (
  <VFlow vSpacing={0} style={{ margin: '0.5rem 0' }}>
    <Text>{item.nomeResponsavel}</Text>
    {item.tipoPessoa === TipoPessoaEnum.FISICA ? (
      <HLabel title='CPF'>
        <Cpf value={item.cpfCnpj} />
      </HLabel>
    ) : (
      <HLabel title='CNPJ'>
        <Cnpj value={item.cpfCnpj} />
      </HLabel>
    )}
  </VFlow>
)

const renderStatus = ({ ativo }: CredenciaisRecebimentoExternoQueryModel) => (
  <HFlow hSpacing={0.25} alignItems='center'>
    <Icon size={1} fill={ativo ? 'success' : 'danger'} icon={ativo ? 'checkCircleOutline' : 'banOutline'} />
    <Text color={ativo ? 'success' : 'danger'}>{ativo ? 'Ativo' : 'Inativo'}</Text>
  </HFlow>
)
