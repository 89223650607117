import { EstabelecimentoSaudeEnum } from 'graphql/types.generated'

export const grupoEstabelecimentoEspecializadoCC = [
  EstabelecimentoSaudeEnum.CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_I,
  EstabelecimentoSaudeEnum.CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_II,
  EstabelecimentoSaudeEnum.CENTRO_DE_ESPECIALIDADES_ODONTOLOGICAS_CEO_III,
  EstabelecimentoSaudeEnum.PRONTO_ATENDIMENTO_GERAL,
  EstabelecimentoSaudeEnum.PRONTO_ATENDIMENTO_ESPECIALIZADO,
  EstabelecimentoSaudeEnum.UPA,
  EstabelecimentoSaudeEnum.POLICLINICA,
  EstabelecimentoSaudeEnum.CENTRO_DE_ATENCAO_PSICOSSOCIAL,
  EstabelecimentoSaudeEnum.TELESSAUDE,
  EstabelecimentoSaudeEnum.UNIDADE_DE_ATENCAO_EM_REGIME_RESIDENCIAL,
  EstabelecimentoSaudeEnum.HOSPITAL_GERAL,
  EstabelecimentoSaudeEnum.MEDIA_COMPLEXIDADE,
  EstabelecimentoSaudeEnum.UNIDADE_DE_APOIO_DIAGNOSE_E_TERAPIA_SADT_ISOLADO,
  EstabelecimentoSaudeEnum.FARMACIA,
  EstabelecimentoSaudeEnum.CENTRO_DE_PARTO_NORMAL_ISOLADO,
  EstabelecimentoSaudeEnum.HOSPITAL_DIA_ISOLADO,
  EstabelecimentoSaudeEnum.CENTRAL_DE_REGULACAO_DE_SERVICOS_DE_SAUDE,
  EstabelecimentoSaudeEnum.CENTRAL_DE_GESTAO_EM_SAUDE,
  EstabelecimentoSaudeEnum.PRONTO_ATENDIMENTO,
  EstabelecimentoSaudeEnum.CENTRAL_DE_REGULACAO_MEDICA_DAS_URGENCIAS,
  EstabelecimentoSaudeEnum.SERVICO_DE_ATENCAO_DOMICILIAR_ISOLADO_HOME_CARE,
  EstabelecimentoSaudeEnum.CENTRAL_DE_REGULACAO_DO_ACESSO,
  EstabelecimentoSaudeEnum.CENTRAL_DE_ABASTECIMENTO,
]
