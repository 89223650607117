import { Omit, Text, VFlow } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { SituacaoProblemaTag } from 'view/atendimentos/detail/components/SituacaoProblemaTag'
import { Problema } from 'view/atendimentos/detail/soap/aside/types/ProblemaModel'

import { ProblemaCondicaoSelectFieldItem } from './ProblemaCondicaoSelectFieldItem'

export interface ProblemaCondicaoSelectFieldProps extends Omit<SelectFieldProps<Problema>, 'items' | 'itemToString'> {
  problemas?: ProblemaCondicaoSelectFieldItem[]
  loading?: boolean
}

export function ProblemaCondicaoSelectField(props: ProblemaCondicaoSelectFieldProps) {
  const { problemas, loading, ...rest } = props

  const itemToString = (item: ProblemaCondicaoSelectFieldItem) =>
    item &&
    [
      item.ciap && `${item.ciap.descricao} (${item.ciap.codigo})`,
      item.cid10 && `${item.cid10.nome} (${item.cid10.codigo})`,
      item.descricaoOutro,
    ]
      .filter((e) => e)
      .join(' | ')

  const renderItem = (item: ProblemaCondicaoSelectFieldItem) => (
    <VFlow vSpacing={0}>
      {item.ciap && <HLabel title={item.ciap.descricao}>({item.ciap.codigo})</HLabel>}
      {item.cid10 && <HLabel title={item.cid10.nome}>({item.cid10.codigo})</HLabel>}
      <Text fontWeight='bold'>{item.descricaoOutro}</Text>
      <SituacaoProblemaTag situacao={item.situacao} />
    </VFlow>
  )

  const sortedItems = problemas?.sort((a, b) => itemToString(a).localeCompare(itemToString(b)))

  return (
    <SelectField<ProblemaCondicaoSelectFieldItem>
      itemToString={itemToString}
      renderItem={renderItem}
      items={sortedItems}
      loading={loading}
      {...rest}
    />
  )
}
