import { css } from '@emotion/core'
import { Cell, Grid, JustifyContent, Text } from 'bold-ui'
import React from 'react'

export interface MarcadoresConsumoListItemProps {
  label: string
  children: React.ReactNode
  justifyContent?: JustifyContent
  breakline?: boolean
}

export function MarcadoresConsumoAlimentarListItem(props: MarcadoresConsumoListItemProps) {
  const { label, children, justifyContent = 'space-between', breakline: breakLine = false } = props

  return (
    <Grid justifyContent={justifyContent}>
      <Cell size={breakLine ? 12 : null} style={styles.label}>
        {label}
      </Cell>
      <Cell size={breakLine ? 12 : null}>
        <Text>{children}</Text>
      </Cell>
    </Grid>
  )
}

const styles = {
  label: css`
    margin: auto 0;
    max-width: 60%;
  `,
}
