import { Icon, Text, Theme, Tooltip, useStyles } from 'bold-ui'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { Box } from 'components/Box'
import { PopperButton } from 'components/popper'
import React, { CSSProperties } from 'react'

import { HorusForm } from './HorusForm'
import { PermissionToEditHorus } from './HorusTable'

export interface HorusCadastroFormPopperProps {
  unidadeSaudeId: ID
  consultaHorusAtiva: boolean
  maxCincoUnidadeConsulta: boolean
  permissionToEditHorus: PermissionToEditHorus
  reload(): Promise<any>
}

export function HorusCadastroFormPopper(props: HorusCadastroFormPopperProps) {
  const { reload } = props
  const { classes } = useStyles(createStyles)

  const tooltipText = () => {
    if (props.maxCincoUnidadeConsulta) {
      return 'Não é possível adicionar mais de 5 unidades de saúde para consulta'
    }
    if (!props.consultaHorusAtiva) {
      return 'Conexão com Hórus está desabilitada na instalação'
    }
    if (!props.permissionToEditHorus.enabled) {
      return props.permissionToEditHorus.hint
    }

    return null
  }

  const renderPopper = ({ close }) => {
    return (
      <div className={classes.popper}>
        <Box>
          <HorusForm
            unidadeSaudeId={props.unidadeSaudeId}
            onSuccess={composeHandlers(reload, close)}
            onCancel={close}
          />
        </Box>
      </div>
    )
  }

  return (
    <Tooltip text={tooltipText()}>
      <PopperButton
        skin='outline'
        kind='primary'
        size='small'
        placement='bottom-end'
        disabled={!props.consultaHorusAtiva || props.maxCincoUnidadeConsulta || !props.permissionToEditHorus.enabled}
        renderPopper={renderPopper}
      >
        <Icon icon='plus' style={{ marginRight: '0.25rem' }} />
        <Text color='inherit'>Adicionar unidade para consulta</Text>
      </PopperButton>
    </Tooltip>
  )
}

const createStyles = (theme: Theme) => ({
  popper: {
    boxShadow: theme.shadows.outer['40'],
    width: '22rem',
    transform: 'translateX(1.1rem)',
    textAlign: 'left',
    marginTop: '0.5rem',

    [theme.breakpoints.down('lg')]: {
      marginTop: '0.25rem',
    },
  } as CSSProperties,
})
