import { msg } from '..'
import * as Util from '../Util'

const VALID_MICROAREA_REGEX = /^[0-9][0-9]$/

export function microarea(value: string) {
  if (!Util.isEmpty(value)) {
    if (value.length !== 2) return msg('length', value, 2)
    if ((!value.match(VALID_MICROAREA_REGEX) && value !== 'FA') || value === '00') return msg('microarea', value)
  }
  return null
}
