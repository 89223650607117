/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Checkbox, Grid, HFlow, Icon, SortableLabel, SortDirection, Text, Theme, useTheme } from 'bold-ui'
import { InfoIcon } from 'components/InfoIcon'
import { useMemo } from 'react'
import { isUndefinedOrNull } from 'util/checks'

export interface HistoricoAtendimentoTableHeaderProps {
  sort?: SortDirection
  onSortChange?(sort: SortDirection): void

  selectedCount: number
  atendimentosEnabledToPrintCount: number
  isAllPageItemsSelected: boolean
  selectPageToPrint(print: boolean): void
  printHistorico(): void
}

export default function HistoricoAtendimentoTableHeader(props: HistoricoAtendimentoTableHeaderProps) {
  const {
    sort,
    isAllPageItemsSelected,
    atendimentosEnabledToPrintCount,
    selectedCount,
    onSortChange,
    printHistorico,
    selectPageToPrint,
  } = props
  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const dataHeaderConfig = {
    name: 'data',
    header: 'Data',
  }

  return (
    <div css={classes.grid}>
      <Grid>
        <Cell size={1} style={css(classes.centerCell, classes.dataCell)}>
          <SortableLabel direction={sort} onChange={onSortChange}>
            <Text fontWeight='bold' style={classes.text}>
              {dataHeaderConfig.header}
            </Text>
          </SortableLabel>
        </Cell>
        <Cell size={8} style={classes.centerCell}>
          <HFlow>
            <Checkbox
              label={`Marcar todos da página ${
                !isUndefinedOrNull(atendimentosEnabledToPrintCount)
                  ? `(${'registro'.pluralizeAndConcatValue(atendimentosEnabledToPrintCount)})`
                  : ''
              }`}
              indeterminate={!!selectedCount && selectedCount !== atendimentosEnabledToPrintCount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectPageToPrint(e.target.checked)}
              checked={isAllPageItemsSelected}
              disabled={!atendimentosEnabledToPrintCount}
            />
            <div css={classes.helpIcon.wrapper}>
              <InfoIcon icon='infoCircleFilled' text={tooltipText} tooltipStyle={classes.helpIcon.tooltip} />
            </div>
          </HFlow>
        </Cell>
        <Cell size={3} style={classes.imprimirButtonCell}>
          <Button kind='primary' size='small' onClick={printHistorico} disabled={!selectedCount}>
            <Icon
              icon='printerOutline'
              style={css`
                margin-right: 0.5rem;
              `}
            />
            Imprimir selecionados
          </Button>
        </Cell>
      </Grid>
    </div>
  )
}

const tooltipText =
  'É possível alterar a quantidade de registros por página no final da listagem. Selecione registros de mais de uma página para imprimi-los em conjunto.'

const styles = (theme: Theme) => ({
  grid: css`
    padding: 1rem;
    border-bottom: 1px solid ${theme.pallete.gray.c80};
  `,
  centerCell: css`
    align-self: center;
  `,
  text: css`
    margin-right: 0.5rem;
  `,
  dataCell: css`
    text-align: center;
  `,
  imprimirButtonCell: css`
    display: inline-flex;
    justify-content: flex-end;
  `,
  helpIcon: {
    wrapper: css`
      align-items: center;
      margin-top: 0.25rem;
    `,
    tooltip: css`
      white-space: pre-wrap;
    `,
  },
})
