/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button } from 'bold-ui'
import { useHistory } from 'react-router'

import { govBrStyles } from './GovBrButton'
import { useLoginPopup } from './useLoginPopup'

interface InternalGovBrButtonProps {
  href: string
}

export const InternalGovBrButton = (props: InternalGovBrButtonProps) => {
  const { href } = props
  const history = useHistory()
  const { openLoginPopup } = useLoginPopup()

  const onClick = () => openLoginPopup(href).then((res) => res && history.go(0))

  return (
    <Button kind='normal' size='medium' css={styles.button} onClick={onClick}>
      Entrar com <b>gov.br</b>
    </Button>
  )
}

const styles = {
  button: css`
    ${govBrStyles.button};
    border: 0px;
    margin-top: 0.3rem;
  `,
}
