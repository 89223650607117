import { SelectField } from 'components/form'
import { TipoRegistroSelectFieldDocument } from 'graphql/hooks.generated'
import {
  TipoRegistroHistoricoClinico,
  TipoRegistroSelectFieldQuery,
  TipoRegistroSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type TipoRegistroSelectModel = TipoRegistroHistoricoClinico
type TipoRegistroSelectFieldProps = AsyncSelectFieldProps<TipoRegistroSelectModel>

interface TipoRegistroRecordModel {
  id: ID
  value: string
}

export function TipoRegistroSelectField(props: TipoRegistroSelectFieldProps) {
  const { selectProps } = useAsyncQuerySelect<
    TipoRegistroSelectModel,
    TipoRegistroSelectFieldQuery,
    TipoRegistroSelectFieldQueryVariables
  >({
    query: TipoRegistroSelectFieldDocument,
    extractItems: (data) => {
      return data?.tipoRegistroHistorico.filter((item) => item !== TipoRegistroHistoricoClinico.PRE_ATENDIMENTO)
    },
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
      },
    }),
  })

  return <SelectField<TipoRegistroSelectModel> itemToString={itemToString} {...selectProps} {...props} />
}

const itemToString = (item: TipoRegistroSelectModel) => {
  return tipoRegistroRecord[item]?.value
}

export const tipoRegistroRecord: Partial<Record<TipoRegistroHistoricoClinico, TipoRegistroRecordModel>> = {
  [TipoRegistroHistoricoClinico.ATENDIMENTO_DOMICILIAR]: {
    id: 0,
    value: 'Atendimento domiciliar',
  },
  [TipoRegistroHistoricoClinico.ATIVIDADE_COLETIVA]: {
    id: 1,
    value: 'Atividade coletiva',
  },
  [TipoRegistroHistoricoClinico.AVALIACAO_ELEGIBILIDADE]: {
    id: 2,
    value: 'Avaliação de elegibilidade',
  },
  [TipoRegistroHistoricoClinico.CONSULTA]: {
    id: 3,
    value: 'Consulta',
  },
  [TipoRegistroHistoricoClinico.ATENDIMENTO_ODONTOLOGICO]: {
    id: 4,
    value: 'Consulta odontológica',
  },
  [TipoRegistroHistoricoClinico.ESCUTA_INICIAL]: {
    id: 5,
    value: 'Escuta inicial / pré-atendimento',
  },
  [TipoRegistroHistoricoClinico.CONSUMO_ALIMENTAR]: {
    id: 6,
    value: 'Marcadores de consumo alimentar',
  },
  [TipoRegistroHistoricoClinico.PRE_NATAL]: {
    id: 7,
    value: 'Pré-Natal',
  },
  [TipoRegistroHistoricoClinico.ATENDIMENTO_PROCEDIMENTOS]: {
    id: 8,
    value: 'Procedimento',
  },
  [TipoRegistroHistoricoClinico.PUERICULTURA]: {
    id: 9,
    value: 'Puericultura',
  },
  [TipoRegistroHistoricoClinico.PUERPERIO]: {
    id: 10,
    value: 'Puerpério',
  },
  [TipoRegistroHistoricoClinico.ZIKA]: {
    id: 11,
    value: 'Síndrome neurológica por zika / microcefalia',
  },
  [TipoRegistroHistoricoClinico.VACINACAO]: {
    id: 12,
    value: 'Vacinação',
  },
  [TipoRegistroHistoricoClinico.VISITA_DOMICILIAR]: {
    id: 13,
    value: 'Visita domiciliar e territorial',
  },
  [TipoRegistroHistoricoClinico.OBSERVACAO]: {
    id: 14,
    value: 'Observação',
  },
  [TipoRegistroHistoricoClinico.CUIDADO_COMPARTILHADO]: {
    id: 15,
    value: 'Cuidado compartilhado',
  },
}
