/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Paper, Text, useTheme, VFlow } from 'bold-ui'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { calculateIdadeGestacional } from 'util/atendimento/gestante/calculateIdadeGestacional'

export function IgcPaper() {
  const {
    cidadao: { dataInicioGestacao },
  } = useAtendimentoContext()

  const theme = useTheme()

  if (!dataInicioGestacao) {
    return null
  }

  const {
    emSemanas: { semanas, dias },
    hasIdadeGestacional,
  } = calculateIdadeGestacional(dataInicioGestacao, Date.now())

  const fraseIgSemanasDias = hasIdadeGestacional
    ? `${'semana'.pluralizeAndConcatValue(semanas)} e ${'dia'.pluralizeAndConcatValue(dias)}`
    : null

  return (
    <Paper
      style={css`
        border: 0;
        background: ${theme.pallete.surface.background};
        padding: 0.5rem;
      `}
    >
      <VFlow vSpacing={0.25}>
        <Text fontWeight='bold'>IGC</Text>
        <Text>{fraseIgSemanasDias}</Text>
      </VFlow>
    </Paper>
  )
}
