/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { MonthPaginator, ReferenceMonth } from 'bold-ui'
import { dateFnsDefaultLocale } from 'components/agenda/agendaLocalizer'
import { format } from 'date-fns'

export interface CalendarAgendaLotacaoMonthPaginatorProps {
  referenceMonth: ReferenceMonth
  onChange(month: ReferenceMonth): void
  disabled?: boolean
}

export const CalendarAgendaLotacaoMonthPaginator: React.SFC<CalendarAgendaLotacaoMonthPaginatorProps> = (props) => {
  const { referenceMonth, onChange, disabled } = props
  const styles = createStyles()

  const onMonthPaginatorChange = (newValue: ReferenceMonth) => {
    return onChange(newValue)
  }

  return (
    <div
      css={styles.monthPaginator}
      style={disabled ? { pointerEvents: 'none', opacity: `${styles.disabled.opacity}` } : {}}
    >
      <MonthPaginator
        month={referenceMonth.month}
        year={referenceMonth.year}
        formatter={monthPaginatorFormatter}
        onChange={onMonthPaginatorChange}
        ghost={true}
      />
    </div>
  )
}

const monthPaginatorFormatter = (date: Date, _): string => format(date, 'MMMM', { locale: dateFnsDefaultLocale })

const createStyles = () => ({
  monthPaginator: () => css`
    min-width: 9.8rem;
    button {
      margin: auto -1em;
    }
    & > div > div > div {
      justify-content: space-between;
    }
  `,
  disabled: {
    opacity: 0.5,
  },
})

CalendarAgendaLotacaoMonthPaginator.defaultProps = { disabled: false }
