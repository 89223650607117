import { difference, intersection } from 'lodash'

import { DroppableOrigin } from '../dragndrop/model-dragndrop'
import { BoardField, FieldFiltersByKey, FieldValuesByKey } from './model-board'

interface InitialKeyPositionsAndValues<T> {
  initialRowKeys: Array<keyof T>
  initialColumnKeys: Array<keyof T>
  initialAvailableKeys: Array<keyof T>
  initialFiltersByKey: FieldFiltersByKey<T>
}

export function getInitialKeysAndFilters<T>(
  valuesByKey: FieldValuesByKey<T>,
  allFiltersByKey: FieldFiltersByKey<T>,
  initialFields?: Array<BoardField<T>>
): InitialKeyPositionsAndValues<T> {
  const rowKeys: Array<keyof T> = []
  const columnKeys: Array<keyof T> = []
  const filtersByKey = new Map(allFiltersByKey)

  initialFields?.forEach(({ key, origin, filters }) => {
    if (origin === DroppableOrigin.LINHA) rowKeys.push(key)
    if (origin === DroppableOrigin.COLUNA) columnKeys.push(key)

    if (filters.isNotEmpty()) {
      const validFilters = intersection(filters, valuesByKey.get(key))
      filtersByKey.set(key, new Set(validFilters))
    }
  })

  const availableKeys: Array<keyof T> = difference([...valuesByKey.keys()], [...rowKeys, ...columnKeys])

  return {
    initialRowKeys: rowKeys,
    initialColumnKeys: columnKeys,
    initialAvailableKeys: availableKeys,
    initialFiltersByKey: filtersByKey,
  }
}
