import moment from 'moment'
import { calculateAge } from 'util/date/calculateAge'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { createValidator, maxLength, range, required } from 'util/validation'

import { DataIdadeFieldModel } from '../components/DataIdadeField'
import { prenatalPartoNascimentoValidator } from '../objetivo/puericultura/validator'
import { AntecedentesFormModel } from './AntecedentesForm'
import { InformacoesObstetricasPanelModel } from './InformacoesObstetricasPanel'
import { AntecedentesFamiliaresCiapFormModel, CirurgiaInternacaoModel, PessoalAntecedentesModel } from './model'
import { PuericulturaPanelModel } from './PuericulturaPanel'

const puericulturaValidator = createValidator<PuericulturaPanelModel>({}, (formValues) =>
  prenatalPartoNascimentoValidator(formValues)
)

const informacoesObstetricasValidator = createValidator<InformacoesObstetricasPanelModel>({
  gestaPrevias: [maxLength(2)],
  abortos: [maxLength(2)],
  partos: [maxLength(2)],
  partosVaginais: [maxLength(2)],
  partosCesareas: [maxLength(2)],
  partosDomiciliares: [maxLength(2)],
  nascidosVivos: [maxLength(2)],
  natimortos: [maxLength(2)],
  recemNascidoAbaixo: [maxLength(2)],
  recemNascidoAcima: [maxLength(2)],
  filhosVivos: [maxLength(2)],
  obitoAntesPrimeiraSemana: [maxLength(2)],
  obitoAposPrimeiraSemana: [maxLength(2)],
})

const pessoalValidator = createValidator<PessoalAntecedentesModel>({
  puericultura: puericulturaValidator,
  informacoesObstetricas: informacoesObstetricasValidator,
  texto: [maxLength(2000)],
})

export const antecedentesValidator = createValidator<AntecedentesFormModel>({ pessoal: pessoalValidator })

export const hospitalarAntecedentesValidator = (dataNascimentoCidadao: LocalDate, dataAtendimento: Date) =>
  createValidator<CirurgiaInternacaoModel>({
    descricao: [required, maxLength(200)],
    observacao: [maxLength(200)],
    dataRealizada: dataProblemaValidator(dataNascimentoCidadao, dateAsYyyyMmDd(dataAtendimento)),
  })

export const familiarValidator = createValidator<AntecedentesFamiliaresCiapFormModel>({
  observacao: [maxLength(200)],
  ciap: [required],
})

const dataProblemaValidator = (dataNascimento: LocalDate, dataAtendimento: string) =>
  createValidator<DataIdadeFieldModel>({}, (formValues, errors) => {
    if (formValues) {
      const { years, months } = calculateAge(dataNascimento, dataAtendimento)

      errors.idade = range(0, 11)(formValues.idade?.meses) && 'Meses deve ter valor entre 0 e 11.'

      if (!errors.idade) errors.idade = range(0, 999)(formValues.idade?.anos) && 'Anos deve ter valor entre 0 e 999.'

      if (
        !errors.idade &&
        (formValues.idade?.anos > years ||
          (Number(formValues.idade?.anos) === years && Number(formValues.idade?.meses) > months))
      ) {
        errors.idade = 'Idade deve ser igual ou menor à idade atual.'
      }

      if (moment(formValues.data).isBefore(moment(dataNascimento))) {
        errors.data = 'Deve ser posterior ou igual à data de nascimento.'
      }

      if (moment(formValues.data).startOf('day').isAfter(moment(dataAtendimento))) {
        errors.data = 'Deve ser anterior ou igual à data do atendimento.'
      }
    }

    return errors
  })
