import { Theme, useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

export function SideMenuGroupTitle(props: React.LiHTMLAttributes<HTMLLIElement>) {
  const { classes } = useStyles(createStyles)
  return <li className={classes.groupTitle} {...props} />
}

const createStyles = (theme: Theme) => ({
  groupTitle: {
    ...theme.typography.variant('main'),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    background: theme.pallete.gray.c90,
    padding: '1rem 1rem 0.5rem 5rem',
  } as CSSProperties,
})
