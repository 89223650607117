/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, TableCell, TableRow, Text, Theme, useTheme } from 'bold-ui'
import { AlcancadoComField } from 'components/form/field/AlcancadoComField'
import { NeuroFaixaEtariaEnum, StatusAvaliadoEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { MetaArray } from 'util/metaPath'

import { AvaliacaoDesenvolvimentoModel } from '../model'
import StatusAvaliacaoTag from './StatusAvaliacaoTag'
import { SwitchButtonField } from './SwitchButtonField'

interface TableRowAtendimentoPuericulturaProps {
  name: MetaArray<AvaliacaoDesenvolvimentoModel>
  item: AvaliacaoDesenvolvimentoModel
  index: number
  usePrimaryColors: boolean
}

export default function TableRowAtendimentoPuericultura(props: TableRowAtendimentoPuericulturaProps) {
  const { item } = props
  const theme = useTheme()
  const classes = createStyles(theme)

  return (
    <TableRow key={item.id}>
      <TableCell style={classes.row}>
        {item.hint ? <MarcosDesenvolvimentoRow {...props} /> : <DesenvolvimentoCriancaRow {...props} />}
      </TableCell>
    </TableRow>
  )
}

const DesenvolvimentoCriancaRow = (props: TableRowAtendimentoPuericulturaProps) => {
  const { name, item, usePrimaryColors, index } = props

  return (
    <HFlow alignItems='center' justifyContent='space-between'>
      <HFlow hSpacing={0.25} alignItems='center' justifyContent='flex-start'>
        <Text fontWeight='bold'>{item.descricao}</Text>
        {item.dataAvaliacao && item.status && (
          <StatusAvaliacaoTag
            status={item.status}
            dataAvaliacao={item.dataAvaliacao}
            usePrimaryColors={usePrimaryColors}
          />
        )}
      </HFlow>
      <SwitchButtonField
        name={name.get(index).statusButton}
        usePrimaryColors={usePrimaryColors}
        checkable={!item.dataAvaliacao}
        disable={item.disableButton}
        infoDescription={item.hint}
      />
    </HFlow>
  )
}

const MarcosDesenvolvimentoRow = (props: TableRowAtendimentoPuericulturaProps) => {
  const { name, item, usePrimaryColors, index } = props

  return (
    <Fragment>
      <HFlow alignItems='center' justifyContent='space-between'>
        <Text fontWeight='bold'>{item.descricao}</Text>
        <SwitchButtonField
          name={name.get(index).statusButton}
          usePrimaryColors={usePrimaryColors}
          checkable={!item.dataAvaliacao}
          disable={item.disableButton}
          infoDescription={item.hint}
        />
      </HFlow>
      {showAlcancadoComField(item) && <AlcancadoComField name={name.get(index)} />}
      {item.dataAvaliacao && item.status && item.hint && (
        <StatusAvaliacaoTag
          status={item.status}
          dataAvaliacao={item.dataAvaliacao}
          usePrimaryColors={usePrimaryColors}
        />
      )}
    </Fragment>
  )
}
const createStyles = (theme: Theme) => ({
  row: css`
    padding: 0.75rem 1rem;
    border: 1px solid ${theme.pallete.gray.c60};
  `,
})

const showAlcancadoComField = (row: AvaliacaoDesenvolvimentoModel) =>
  (row.statusButton === StatusAvaliadoEnum.PRESENTE || row.statusButton === StatusAvaliadoEnum.PRESENTE_COM_ATRASO) &&
  row.hint &&
  row.faixaEtariaEnum !== NeuroFaixaEtariaEnum.PRIMEIROS_15_DIAS
