export type SideMenuItem = {
  title: string
  children: ReadonlyArray<{ title: string; to: string }>
}

export const SIDE_MENU_ITEMS: SideMenuItem[] = [
  {
    title: 'Termos de uso',
    children: [
      {
        title: 'Aceitação do Termo de Uso',
        to: '#aceitacao_do_termo_de_uso',
      },
      {
        title: 'Definições do Termo de Uso',
        to: '#definicoes_do_termo_de_uso',
      },
      {
        title: 'Arcabouço Legal',
        to: '#arcabouco_legal',
      },
      {
        title: 'Descrição do Serviço',
        to: '#descricao_do_serviço',
      },
      {
        title: 'Direitos do Usuário do Serviço',
        to: '#direitos_do_usuario_do_serviço',
      },
      {
        title: 'Responsabilidades do Usuário',
        to: '#responsabilidades_do_usuario',
      },
      {
        title: 'Responsabilidade da Administração Pública',
        to: '#responsabilidade_da_administracao_publica',
      },
      {
        title: 'Mudanças no Termo de Uso',
        to: '#mudancas_no_termo_de_uso',
      },
      {
        title: 'Informações para contato',
        to: '#informacoes_para_contato',
      },
      {
        title: 'Foro',
        to: '#foro',
      },
    ],
  },
  {
    title: 'Política de Privacidade',
    children: [
      {
        title: 'Definições',
        to: '#definicoes',
      },
      {
        title: 'Base Legal para Tratamento de Dados Pessoais',
        to: '#base_legal_para_tratamento_de_dados_pessoais',
      },
      {
        title: 'Controlador',
        to: '#controlador',
      },
      {
        title: 'Operador',
        to: '#operador',
      },
      {
        title: 'Encarregado',
        to: '#encarregado',
      },
      {
        title: 'Direitos do Titular de Dados Pessoais',
        to: '#direitos_do_titular_de_dados_pessoais',
      },
      {
        title: 'Quais Dados são Tratados',
        to: '#quais_dados_sao_tratados',
      },
      {
        title: 'Qual a Finalidade de Tratamento dos Dados',
        to: '#qual_a_finalidade_de_tratamento_dos_dados',
      },
      {
        title: 'Compartilhamento de Dados',
        to: '#compartilhamento_de_dados',
      },
      {
        title: 'Transferência Internacional de Dados',
        to: '#transferencia_internacional_de_dados',
      },
      {
        title: 'Segurança dos Dados',
        to: '#seguranca_dos_dados',
      },
      {
        title: 'Cookies',
        to: '#cookies',
      },
      {
        title: 'Mudanças na Política de Privacidade',
        to: '#mudancas_na_politica_de_privacidade',
      },
    ],
  },
]
