import { Text, VFlow } from 'bold-ui'
import { RichTextField } from 'components/form'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import {
  CondutaGroupEnum,
  CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER,
  DiscussaoCasoExecutanteFieldsModel,
  SugestaoAgendamentoGrupoCuidadoCompartilhadoFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CondutaGroupCuidadoCompartilhadoRadioFieldContainer } from './CondutaGroupCuidadoCompartilhadoRadioFieldContainer'

interface SugestaoAgendamentoGruposCuidadoCompartilhadoProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel>
}

export const SugestaoAgendamentoGrupoCuidadoCompartilhadoField = (
  props: SugestaoAgendamentoGruposCuidadoCompartilhadoProps
) => {
  const { path } = props
  const name = path.sugestaoAgendamentoGrupo

  return (
    <CondutaGroupCuidadoCompartilhadoRadioFieldContainer<SugestaoAgendamentoGrupoCuidadoCompartilhadoFieldsModel>
      name={name}
      pathCondutaGroup={path.condutaGroup}
      value={CondutaGroupEnum.SUGESTAO_AGENDAMENTO_GRUPO}
    >
      <VFlow>
        <Text>Essa conduta serve para sugerir a participação do cidadão para grupos de atividade coletiva.</Text>
        <RichTextField
          name={name.observacao}
          label='Observações'
          placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
          maxLength={1000}
          required
        />
      </VFlow>
    </CondutaGroupCuidadoCompartilhadoRadioFieldContainer>
  )
}
