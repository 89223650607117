/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel, Text, VFlow } from 'bold-ui'
import { AntecedentePuericulturaDto } from 'graphql/types.generated'
import { useMemo } from 'react'

import HistoricoDetailTable from '../../table/HistoricoDetailTable'

interface HistoricoAntecedentesPuericulturaTableBoxProps {
  puericultura: AntecedentePuericulturaDto
}

export function HistoricoAntecedentesPuericulturaTableBox(props: HistoricoAntecedentesPuericulturaTableBoxProps) {
  const { puericultura } = props

  const { hasTipoIdade, hasApgar, hasMedicoes } = useMemo(() => {
    return {
      hasTipoIdade:
        puericultura.tipoGravidez ||
        puericultura.tipoParto ||
        puericultura.idadeGestacionalSemanas ||
        puericultura.idadeGestacionalDias,
      hasApgar: puericultura.apgarUm || puericultura.apgarCinco || puericultura.apgarDez,
      hasMedicoes: puericultura.peso || puericultura.comprimento || puericultura.perimetroCefalico,
    }
  }, [
    puericultura.apgarCinco,
    puericultura.apgarDez,
    puericultura.apgarUm,
    puericultura.comprimento,
    puericultura.idadeGestacionalDias,
    puericultura.idadeGestacionalSemanas,
    puericultura.perimetroCefalico,
    puericultura.peso,
    puericultura.tipoGravidez,
    puericultura.tipoParto,
  ])

  return (
    <HistoricoDetailTable title='Puericultura' sectionStyle='AN'>
      <Grid>
        {hasTipoIdade && (
          <Cell size={3}>
            <VFlow vSpacing={0.5}>
              {puericultura.tipoGravidez && <InfoLabel title='Tipo de gravidez'>{puericultura.tipoGravidez}</InfoLabel>}
              {puericultura.tipoParto && <InfoLabel title='Tipo de parto'>{puericultura.tipoParto}</InfoLabel>}
              {(puericultura.idadeGestacionalSemanas || puericultura.idadeGestacionalDias) && (
                <InfoLabel title='Idade gestacional'>
                  {puericultura.idadeGestacionalSemanas} semanas
                  {puericultura.idadeGestacionalDias
                    ? ' e ' +
                      puericultura.idadeGestacionalDias +
                      (puericultura.idadeGestacionalDias === '1' ? ' dia' : ' dias')
                    : ''}
                </InfoLabel>
              )}
            </VFlow>
          </Cell>
        )}

        {hasApgar && (
          <Cell size={3}>
            <VFlow vSpacing={0}>
              <Text fontWeight='bold'>Apgar</Text>
              {puericultura.apgarUm && (
                <Text>
                  <strong>1º minuto:</strong> {puericultura.apgarUm}
                </Text>
              )}
              {puericultura.apgarCinco && (
                <Text>
                  <strong>5º minuto:</strong> {puericultura.apgarCinco}
                </Text>
              )}
              {puericultura.apgarDez && (
                <Text>
                  <strong>10º minuto:</strong> {puericultura.apgarDez}
                </Text>
              )}
            </VFlow>
          </Cell>
        )}

        {hasMedicoes && (
          <Cell size={3}>
            <VFlow vSpacing={0.5}>
              {puericultura.peso && <InfoLabel title='Peso'>{puericultura.peso.replace('.', ',')} kg</InfoLabel>}
              {puericultura.comprimento && (
                <InfoLabel title='Comprimento'>{puericultura.comprimento.replace('.', ',')} cm</InfoLabel>
              )}
              {puericultura.perimetroCefalico && (
                <InfoLabel title='Perímetro cefálico'>{puericultura.perimetroCefalico.replace('.', ',')} cm</InfoLabel>
              )}
            </VFlow>
          </Cell>
        )}
      </Grid>
    </HistoricoDetailTable>
  )
}
