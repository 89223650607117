import { Omit } from 'bold-ui'
import { UseFieldProps, usePecField } from 'components/form/final-form/hooks/useField'
import { getFieldError } from 'components/form/final-form/util'
import React from 'react'

import { LotacaoAgendaSelect, LotacaoAgendaSelectModel, LotacaoAgendaSelectProps } from './LotacaoAgendaSelect'

export type LotacaoAgendaSelectFieldModel = LotacaoAgendaSelectModel

type LotacaoAgendaSelectFieldProps = UseFieldProps<LotacaoAgendaSelectModel> &
  Omit<LotacaoAgendaSelectProps, 'name' | 'defaultValue'>

LotacaoAgendaSelectField.defaultProps = {
  loadItemsOnOpen: true,
} as Partial<LotacaoAgendaSelectFieldProps>

export function LotacaoAgendaSelectField(props: LotacaoAgendaSelectFieldProps) {
  const { input, meta, ...rest } = usePecField(props)

  return <LotacaoAgendaSelect error={getFieldError(meta)} {...input} {...rest} />
}
