import { Heading, Text, VFlow } from 'bold-ui'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { Fragment } from 'react'
import { stringGrouper } from 'util/string/string-grouper'

interface CondicoesVacinacaoProps {
  gestante: boolean
  puerpera: boolean
  viajante: boolean
  comunicanteHanseniase: boolean
}

export default function CondicoesVacinacao(props: CondicoesVacinacaoProps) {
  const condicoes = useMemo(() => mountCondicaoString(props), [props])

  return (
    <Fragment>
      {!isEmpty(condicoes) && (
        <VFlow vSpacing={0.5}>
          <Heading level={3}>Condições</Heading>
          <Text>{condicoes}</Text>
        </VFlow>
      )}
    </Fragment>
  )
}

const mountCondicaoString = (condicoes: CondicoesVacinacaoProps) => {
  const { gestante, puerpera, viajante, comunicanteHanseniase } = condicoes
  const cond: string[] = []

  if (gestante) {
    cond.push('Gestante')
  } else if (puerpera) {
    cond.push('Puérpera')
  }
  viajante && cond.push('Viajante')
  comunicanteHanseniase && cond.push('Comunicante de hanseníase')

  return stringGrouper(...cond)
}
