/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, Theme, useTheme } from 'bold-ui'
import { CheckboxField } from 'components/form'
import { css } from 'emotion'
import { TipoEncaminhamentoExternoEnum } from 'graphql/types.generated'
import { MetaArray } from 'util/metaPath'

export interface ChecklistEncaminhamentoProps {
  name: MetaArray<TipoEncaminhamentoExternoEnum>
}

export function ChecklistEncaminhamento(props: ChecklistEncaminhamentoProps) {
  const theme = useTheme()
  const classes = createStyles(theme)
  const { name } = props

  return (
    <Grid gap={1} gapVertical={0.5}>
      <Cell size={4}>
        <CheckboxField style={classes.checkbox} label='CAPS' name={name} value={TipoEncaminhamentoExternoEnum.CAPS} />
      </Cell>
      <Cell size={4}>
        <CheckboxField
          style={classes.checkbox}
          label='Urgência'
          name={name}
          value={TipoEncaminhamentoExternoEnum.URGENCIA}
        />
      </Cell>
      <Cell size={4}>
        <CheckboxField
          style={classes.checkbox}
          label='Intersetorial'
          name={name}
          value={TipoEncaminhamentoExternoEnum.INTERSETORIAL}
        />
      </Cell>
      <Cell size={4}>
        <CheckboxField
          style={classes.checkbox}
          label='Internação hospitalar'
          name={name}
          value={TipoEncaminhamentoExternoEnum.INTERNACAO_HOSPITALAR}
        />
      </Cell>
      <Cell size={4}>
        <CheckboxField
          style={classes.checkbox}
          label='Serviço de Atenção Domiciliar '
          name={name}
          value={TipoEncaminhamentoExternoEnum.SERVICO_ATENCAO_DOMICILIAR}
        />
      </Cell>
    </Grid>
  )
}

const createStyles = (theme: Theme) => ({
  checkbox: css`
    width: 100%;
    height: 100%;
    border: 1px solid ${theme.pallete.divider};
    font-weight: bold;
    padding: 0.4rem 0.7rem;
  `,
})
