import { DropdownDivider, DropdownItem, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton, DropdownLink } from 'components/dropdown'
import { confirm } from 'components/modals/confirm'
import { useAtivarPerfilMutation, useExcluirPerfilMutation, useInativarPerfilMutation } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import React from 'react'

import { PerfilItem } from './PerfilTable'

export interface PerfilTableDropdownProps {
  perfil: PerfilItem
  onUpdated?(): any
}

export function PerfilTableDropdown(props: PerfilTableDropdownProps) {
  const { perfil, onUpdated } = props
  const { match } = useRouter()
  const alert = useAlert()

  const [excluir] = useExcluirPerfilMutation({ variables: { id: perfil.id } })
  const [ativar] = useAtivarPerfilMutation({ variables: { id: perfil.id } })
  const [inativar] = useInativarPerfilMutation({ variables: { id: perfil.id } })

  const handleInativarClick = () => {
    confirm({
      confirmLabel: 'Inativar',
      body: 'Alguns profissionais podem perder o acesso ao sistema.',
      type: 'danger',
      title: `Deseja inativar o perfil ${perfil.nome}?`,
      onConfirm: () =>
        inativar({ variables: { id: perfil.id } }).then(() =>
          alert('success', `Perfil ${perfil.nome} inativado com sucesso.`)
        ),
    })()
  }

  const handleAtivarClick = () => {
    confirm({
      confirmLabel: 'Ativar',
      type: 'primary',
      title: `Deseja ativar o perfil ${perfil.nome}?`,
      onConfirm: () =>
        ativar({ variables: { id: perfil.id } }).then(() =>
          alert('success', `Perfil ${perfil.nome} ativado com sucesso.`)
        ),
    })()
  }

  const handleExcluirClick = (excluirPerfil) => {
    return confirm({
      title: `Deseja excluir o perfil ${perfil.nome}?`,
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () =>
        excluirPerfil().then(() => {
          alert('success', `Perfil excluído com sucesso.`)
          onUpdated()
        }),
    })
  }

  return (
    <Tooltip text='Mais opções'>
      <DropdownButton>
        <DropdownLink to={`${match.url}/${perfil.id}/copia`}>Copiar</DropdownLink>

        {perfil.ativo && <DropdownItem onClick={handleInativarClick}>Inativar</DropdownItem>}

        {!perfil.ativo && <DropdownItem onClick={handleAtivarClick}>Ativar</DropdownItem>}

        <DropdownDivider />

        <Tooltip text={perfil.actions.excluir.hint}>
          <DropdownItem type='danger' disabled={!perfil.actions.excluir.enabled} onClick={handleExcluirClick(excluir)}>
            Excluir
          </DropdownItem>
        </Tooltip>
      </DropdownButton>
    </Tooltip>
  )
}
