import { Cell, Grid } from 'bold-ui'
import { CheckboxField, Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { FormState } from 'final-form'
import { UnidadesSaudeQueryInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

export interface UnidadeSaudeTableFilterProps {
  initialValues?: UnidadesSaudeQueryInput
  onChange(values: UnidadesSaudeQueryInput): void
}

export class UnidadeSaudeTableFilter extends React.Component<UnidadeSaudeTableFilterProps> {
  render() {
    return (
      <Form<UnidadesSaudeQueryInput>
        onSubmit={this.props.onChange}
        render={this.renderForm}
        initialValues={this.props.initialValues}
      />
    )
  }

  private handleChange = (formState: FormState<UnidadesSaudeQueryInput>) => {
    this.props.onChange(formState.values)
  }

  private renderForm = (props: FormRenderProps) => {
    return (
      <>
        <FormDebouncedValueSpy onChange={this.handleChange} />

        <Grid alignItems='center' gap={1}>
          <Cell size={4} data-cy='UnidadeSaudeTableFilter.campoPesquisa'>
            <TextField name='query' placeholder='Pesquise pelo nome, bairro ou CNES' icon='zoomOutline' />
          </Cell>
          <Cell data-cy='UnidadeSaudeTableFilter.mostrarInativas'>
            <CheckboxField name='mostrarInativas' label='Mostrar unidades de saúde inativas' />
          </Cell>
        </Grid>
      </>
    )
  }
}
