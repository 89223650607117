import { MedicoesAtendimentosAnterioresQuery } from 'graphql/types.generated'
import moment from 'moment'
import { calcularIMC } from 'util/atendimento'
import { parseNumber } from 'util/number'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { MedicaoAnteriorFormModel } from './MedicaoAnteriorForm'

export function convertMedicaoAtendimento(
  medicaoAtendimento: MedicoesPanelModel,
  dataAtendimento: Instant
): HistoricoMedicaoModel {
  return {
    dataMedicao: dataAtendimento,
    valorPeso: medicaoAtendimento.peso && parseNumber(medicaoAtendimento.peso),
    valorAltura: medicaoAtendimento.altura && parseNumber(medicaoAtendimento.altura),
    valorPerimetroCefalico: medicaoAtendimento.perimetroCefalico && parseNumber(medicaoAtendimento.perimetroCefalico),
    valorCircunferenciaAbdominal:
      medicaoAtendimento.circunferenciaAbdominal && parseNumber(medicaoAtendimento.circunferenciaAbdominal),
    perimetroPanturrilha:
      medicaoAtendimento.perimetroPanturrilha && parseNumber(medicaoAtendimento.perimetroPanturrilha),
    valorImc: getIMCFromLocalizedNumbers(medicaoAtendimento),
    tipoGlicemia: medicaoAtendimento.tipoGlicemia,
    valorGlicemia: medicaoAtendimento.glicemia && parseNumber(medicaoAtendimento.glicemia),
    valorFrequenciaCardiaca:
      medicaoAtendimento.frequenciaCardiaca && parseNumber(medicaoAtendimento.frequenciaCardiaca),
    valorFrequenciaRespiratoria:
      medicaoAtendimento.frequenciaRespiratoria && parseNumber(medicaoAtendimento.frequenciaRespiratoria),
    valorPressaoArterial:
      medicaoAtendimento.pressaoArterialSistolica && medicaoAtendimento.pressaoArterialDiastolica
        ? `${medicaoAtendimento.pressaoArterialSistolica}/${medicaoAtendimento.pressaoArterialDiastolica}`
        : undefined,
    valorSaturacaoO2: medicaoAtendimento.saturacaoO2 && parseNumber(medicaoAtendimento.saturacaoO2),
    valorTemperatura: medicaoAtendimento.temperatura && parseNumber(medicaoAtendimento.temperatura),
    tipoMedicao: 'atual',
  }
}

export function convertMedicaoAnterior(medicaoAnterior: MedicaoAnteriorFormModel): HistoricoMedicaoModel {
  return {
    id: medicaoAnterior.id,
    dataMedicao: moment(medicaoAnterior.data),
    valorPeso: medicaoAnterior.peso && parseNumber(medicaoAnterior.peso),
    valorAltura: medicaoAnterior.altura && parseNumber(medicaoAnterior.altura),
    valorPerimetroCefalico: medicaoAnterior.perimetroCefalico && parseNumber(medicaoAnterior.perimetroCefalico),
    valorImc: getIMCFromLocalizedNumbers(medicaoAnterior),
    tipoMedicao: 'anterior',
  }
}

export function convertMedicaoOutrosAtendimentos(
  medicao: MedicoesAtendimentosAnterioresQuery['medicoes'][0]
): HistoricoMedicaoModel {
  return {
    ...medicao,
    valorAlturaUterina: Number(medicao.valorAlturaUterina),
    dataRegistro: medicao.atendimentoProfissional.iniciadoEm,
    nomeResponsavel: medicao.atendimentoProfissional.lotacao.profissional.nome,
  }
}

export function convertMedicoesNoNascimento(
  antecedente: MedicoesAtendimentosAnterioresQuery['medicoesNoNascimento'],
  nascimentoCidadao: LocalDate
): HistoricoMedicaoModel {
  return {
    dataMedicao: moment(nascimentoCidadao),
    valorPeso: antecedente.peso ? Number(antecedente.peso) : undefined,
    valorAltura: antecedente.altura ? Number(antecedente.altura) : undefined,
    valorPerimetroCefalico: antecedente.perimetroCefalico ? Number(antecedente.perimetroCefalico) : undefined,
    valorImc: getIMC(antecedente),
    tipoMedicao: 'antecedente',
    dataRegistro: antecedente.dataUltimaAtualizacao,
  }
}

const getIMCFromLocalizedNumbers = ({ peso, altura }: { peso?: string; altura?: string }): number =>
  (peso && altura && calcularIMC(parseNumber(peso), parseNumber(altura))) || undefined

const getIMC = ({ peso, altura }: { peso?: string; altura?: string }): number =>
  (peso && altura && calcularIMC(Number(peso), Number(altura))) || undefined
