import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { AtencaoDomiciliarCondutaDesfecho } from 'graphql/types.generated'
import React from 'react'

export interface AtencaoDomiciliarCondutaDesfechoSelectFieldProps
  extends Omit<SelectFieldProps<string>, 'items' | 'itemToString'> {}

export type AtencaoDomiciliarCondutaDesfechoModel = string

export function AtencaoDomiciliarCondutaDesfechoSelectField(props: AtencaoDomiciliarCondutaDesfechoSelectFieldProps) {
  const itemToString = (item: AtencaoDomiciliarCondutaDesfecho) => item && descriptions[item]

  return <SelectField<AtencaoDomiciliarCondutaDesfechoModel> items={items} itemToString={itemToString} {...props} />
}

export const destinoDescriptionByEnumType = (item: AtencaoDomiciliarCondutaDesfecho) => descriptions[item]

const items = [
  AtencaoDomiciliarCondutaDesfecho.PERMANENCIA,
  AtencaoDomiciliarCondutaDesfecho.ALTA_ADMINISTRATIVA,
  AtencaoDomiciliarCondutaDesfecho.ALTA_CLINICA,
  AtencaoDomiciliarCondutaDesfecho.OBITO,
  AtencaoDomiciliarCondutaDesfecho.ATENCAO_BASICA_AD1,
  AtencaoDomiciliarCondutaDesfecho.SERVICO_URGENCIA_EMERGENCIA,
  AtencaoDomiciliarCondutaDesfecho.SERVICO_INTERNACAO_HOSPITALAR,
]

export const descriptions = {
  PERMANENCIA: 'Permanência',
  ALTA_ADMINISTRATIVA: 'Alta administrativa',
  ALTA_CLINICA: 'Alta clínica',
  OBITO: 'Óbito',
  ATENCAO_BASICA_AD1: 'Atenção Primária (AD1)',
  SERVICO_URGENCIA_EMERGENCIA: 'Serviço de urgência e emergência',
  SERVICO_INTERNACAO_HOSPITALAR: 'Serviço de internação hospitalar',
}
