import { css } from '@emotion/core'
import { Cell, Grid, Heading, Text, VFlow } from 'bold-ui'
import React from 'react'
import { MetaArray } from 'util/metaPath'
import { EditableList, useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { AntecedentesFamiliaresCiapFormModel } from '../model'
import { AntecedenteCiapRow } from './AntecedenteCiapRow'
import { AntecedentesFamiliaresCiapForm } from './AntecedentesFamiliaresCiapForm'

interface AntecedentesFamiliaresCiapFieldProps {
  cidadao: CidadaoAtendimento
  name: MetaArray<AntecedentesFamiliaresCiapFormModel>
}

export function AntecedentesFamiliaresCiapField(props: AntecedentesFamiliaresCiapFieldProps) {
  const { cidadao, name } = props

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value },
  } = useEditableListField({ name })

  return (
    <VFlow vSpacing={1}>
      <Heading level={3}>Lista de problemas dos familiares</Heading>
      <AntecedentesFamiliaresCiapForm onSubmit={handleSubmit} formValues={value} cidadao={cidadao} />
      {value?.length > 0 && (
        <EditableList>
          <div>
            <Grid
              style={css`
                margin: 0;
                padding: 0 1rem;
              `}
            >
              <Cell size={4}>
                <Text fontWeight='bold'>CIAP 2</Text>
              </Cell>
            </Grid>
          </div>
          {value.map((item) => (
            <AntecedenteCiapRow key={item._id} model={item} onChange={handleRowChanged} onRemove={removeItem} />
          ))}
        </EditableList>
      )}
    </VFlow>
  )
}
