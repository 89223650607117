import {
  MedicamentoCatmatSelectField,
  MedicamentoCatmatSelectModel,
} from 'components/form/field/select/MedicamentoCatMatSelectField'
import {
  SubstanciasCbaraSelectField,
  SubstanciasCbaraSelectFieldModel,
} from 'components/form/field/select/SubstanciaAlergiaSelectField'
import { CategoriaSubstanciaAlergia } from 'graphql/types.generated'
import React, { useCallback } from 'react'
import { MetaRoot } from 'util/metaPath'
import {
  ImunobiologicoSelectField,
  ImunobiologicoSelectModel,
} from 'view/lote-imunobiologico/components/ImunobiologicoSelectField'

import { AlergiaReacaoModel } from './model'

interface SubstanciaEspecificaFieldProps {
  label: string
  meta: Pick<MetaRoot<AlergiaReacaoModel>, 'substanciaCbara' | 'substanciaImunobiologico' | 'substanciaMedicamento'>
  values: Pick<AlergiaReacaoModel, 'substanciaCbara' | 'categoriaSubstancia' | 'substanciaMedicamento'>
  allValues: AlergiaReacaoModel[]
  disabled: boolean
  required: boolean
}

const alergiaIsEqual = (
  idSubstanciaAtual: ID,
  categoriaAtual: CategoriaSubstanciaAlergia,
  itemExistente: AlergiaReacaoModel
) =>
  idSubstanciaAtual ===
    (itemExistente.substanciaCbara?.id ??
      itemExistente.substanciaImunobiologico?.id ??
      itemExistente.substanciaMedicamento?.medicamento?.id) && categoriaAtual === itemExistente.categoriaSubstancia

export function SubstanciaEspecificaField(props: SubstanciaEspecificaFieldProps) {
  const { label, meta: name, values, allValues, disabled, required = true } = props

  const isBiologico = values?.categoriaSubstancia === CategoriaSubstanciaAlergia.BIOLOGICO
  const isMedicamento = values?.categoriaSubstancia === CategoriaSubstanciaAlergia.MEDICAMENTO

  const substanciaIsInForm = useCallback(
    (item: ImunobiologicoSelectModel | SubstanciasCbaraSelectFieldModel | MedicamentoCatmatSelectModel) =>
      allValues && !!allValues?.find((o2) => alergiaIsEqual(item.id, values?.categoriaSubstancia, o2)),
    [allValues, values]
  )

  return (
    <>
      {isBiologico ? (
        <ImunobiologicoSelectField
          label={label}
          name={name.substanciaImunobiologico}
          required={required}
          disabled={disabled}
          itemIsEqual={substanciaIsInForm}
          semRegras
        />
      ) : isMedicamento ? (
        <MedicamentoCatmatSelectField
          name={name.substanciaMedicamento}
          label={label}
          required={required}
          disabled={disabled}
          itemIsEqual={substanciaIsInForm}
        />
      ) : (
        <SubstanciasCbaraSelectField
          label={label}
          name={name.substanciaCbara}
          categoria={values?.categoriaSubstancia}
          disabled={disabled}
          required={required}
          itemIsEqual={substanciaIsInForm}
        />
      )}
    </>
  )
}
