/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, Grid, TextField, VFlow } from 'bold-ui'
import { QRCode, QRCodeProps } from 'components/QRCode'
import copy from 'copy-to-clipboard'
import { useState } from 'react'

interface ProfissionalSolicitacaoRedefinicaoSenhaProps {
  url: string
  qrCode: QRCodeProps
  nomeProfissional: string
}

export const ProfissionalSolicitacaoRedefinicaoSenha = ({
  url,
  qrCode,
  nomeProfissional,
}: ProfissionalSolicitacaoRedefinicaoSenhaProps) => {
  const [showAlertLinkCopiado, setShowAlertLinkCopiado] = useState(false)

  const copyOnClick = () => {
    copy(url)
    setShowAlertLinkCopiado(true)
  }

  return (
    <VFlow>
      {showAlertLinkCopiado && (
        <Alert type='success' inline>
          URL para redefinição de senha copiada com sucesso.
        </Alert>
      )}

      <VFlow vSpacing={0.5}>
        <p>
          Para definir a nova senha envie o link abaixo para {nomeProfissional.titleCase()}, ou peça que aponte a câmera
          do seu celular para o QR Code.
        </p>
        <p>Se preferir, crie uma senha provisória que será alterada no primeiro acesso do profissional.</p>
      </VFlow>

      <Grid alignItems='center' gap={0.5}>
        <Cell size={10}>
          <TextField disabled value={url} />
        </Cell>
        <Cell size={2}>
          <Button size='small' type='button' kind='primary' onClick={copyOnClick}>
            Copiar
          </Button>
        </Cell>
      </Grid>

      <div css={styles.container}>
        <QRCode {...qrCode} />
      </div>
    </VFlow>
  )
}

const styles = {
  container: css`
    display: flex;
    justify-content: center;
  `,
}
