/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { FormPrompt } from 'components/form'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { dateAsYyyyMmDd } from 'util/date/formatDate'

import { OutrosFieldModel } from './OutrosField'
import { OutrosForm, OutrosFormModel } from './OutrosForm'
import { OutrosFormFields } from './OutrosFormFields'

interface EditarOutrosModalProps {
  evolucoesAtuais: OutrosFieldModel[]
  onSubmit: (values: OutrosFormModel) => void
  onClose(): void
}

export const EditarOutrosModal = (props: EditarOutrosModalProps) => {
  const { evolucoesAtuais, onSubmit, onClose } = props

  const { id } = useParams<{ id: string }>()

  const {
    cidadao: { dataNascimento },
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  const dataAtendimentoAsLocalDate = useMemo(() => dateAsYyyyMmDd(iniciadoEm), [iniciadoEm])

  const initialValues = evolucoesAtuais?.find((item) => item._id === id)

  const procedimentosSelecionados = evolucoesAtuais
    ?.flatMap((item) => item?.procedimentos)
    .filter((item) => !initialValues?.procedimentos?.map(({ id }) => id).includes(item.id))

  return (
    <OutrosForm
      procedimentosSelecionados={procedimentosSelecionados}
      onSubmit={onSubmit}
      onSubmitSucceeded={onClose}
      initialValues={initialValues}
      render={(formProps) => (
        <Modal style={styles.modal} onClose={onClose} open={true}>
          <ModalBody>
            <FormPrompt />
            <VFlow>
              <VFlow vSpacing={0}>
                <Heading level={1}>Edição de registros - Outros locais</Heading>
                <Text style={styles.local}>{initialValues?.local}</Text>
              </VFlow>

              <OutrosFormFields
                editing
                procedimentosSelecionados={procedimentosSelecionados}
                cidadaoDataNascimento={dataNascimento}
                dataAtendimento={dataAtendimentoAsLocalDate}
              />
            </VFlow>
          </ModalBody>

          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <FooterButton kind='normal' onClick={onClose}>
                Cancelar
              </FooterButton>

              <FooterButton kind='primary' onClick={formProps.handleSubmit}>
                Salvar
              </FooterButton>
            </HFlow>
          </ModalFooter>
        </Modal>
      )}
    />
  )
}

const styles = {
  modal: css`
    width: 40rem;
  `,
  local: css`
    word-break: break-word;
  `,
}
