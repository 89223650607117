/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { SubmitButton } from 'components/form'
import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { FormApi } from 'final-form'
import { Fragment } from 'react'
import { MetaArray } from 'util/metaPath'
import { EditableList, RowType, useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { OutrosForm } from './OutrosForm'
import { OutrosFormFields } from './OutrosFormFields'

export interface OutrosFieldModel extends RowType {
  local: string
  procedimentos: ProcedimentoOdontoSelectModel[]
  observacao?: string
  lastUpdate: Instant
}

export interface OutrosFieldProps {
  name: MetaArray<OutrosFieldModel>
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
}

export default function OutrosField(props: OutrosFieldProps) {
  const { name, cidadaoDataNascimento, dataAtendimento } = props

  const {
    handleSubmit,
    input: { value },
  } = useEditableListField<OutrosFieldModel>({
    name,
  })

  const procedimentosSelecionados = value ? value.flatMap((item: OutrosFieldModel) => item.procedimentos) : []

  const onCancel = (form: FormApi) => {
    form.reset()
    form.getRegisteredFields().forEach((field) => form.resetFieldState(field))
  }

  return (
    <Fragment>
      <EditableList>
        <div
          css={css`
            padding: 1rem;
          `}
        >
          <OutrosForm
            procedimentosSelecionados={procedimentosSelecionados}
            onSubmit={handleSubmit}
            render={(formProps) => (
              <Grid>
                <Cell size={12}>
                  <OutrosFormFields
                    procedimentosSelecionados={procedimentosSelecionados}
                    cidadaoDataNascimento={cidadaoDataNascimento}
                    dataAtendimento={dataAtendimento}
                  />
                </Cell>
                <Cell size={12}>
                  <HFlow justifyContent='flex-end'>
                    <Button size='small' onClick={() => onCancel(formProps.form)}>
                      Cancelar
                    </Button>

                    <SubmitButton size='small' kind='primary' handleSubmit={formProps.handleSubmit}>
                      Adicionar
                    </SubmitButton>
                  </HFlow>
                </Cell>
              </Grid>
            )}
          />
        </div>
      </EditableList>
    </Fragment>
  )
}
