/* eslint-disable no-extend-native */

import { lowers } from './exceptions/lowersExceptions'
import { uppers } from './exceptions/uppersExceptions'

declare global {
  interface String {
    /**
     * Function to truncate words after a maximum number of characters.
     *
     * @param maxCharacters number of characters to then truncate the word
     * @returns string
     */
    truncate(maxCharacters: number): string

    /**
     * Function to convert the first letter of each word to uppercase and the rest to lowercase
     * as long as it is not on the exceptions lists
     * Furthermore, it removes initial, final and extra whitespaces
     *
     * This method require these exceptions lists
     * @see uppersExceptions.ts
     * @see lowersExceptions.ts
     * @params string
     * @returns string
     * @example
     * string 'null' - returns null
     * string 'undefined' - returns null
     * string 'MARIA DA SILVA' - returns 'Maria da Silva'
     * string 'maria da silva' - returns 'Maria da Silva'
     * string 'mARia dA siLVA' - returns 'Maria da Silva'
     * string ' MARIA  DA  SILVA ' - returns 'Maria da Silva'
     */
    titleCase(): string

    /**
     * Function to convert the first letter to uppercase and the rest to lowercase
     * as long as it is not on the exceptions lists
     * Furthermore, it removes initial, final and extra whitespaces
     *
     * This method require these exceptions lists
     * @see uppersExceptions.ts
     * @see lowersExceptions.ts
     * @params string
     * @returns string
     * @example
     * string 'null' - returns null
     * string 'undefined' - returns null
     * string 'MARIA DA SILVA' - returns 'Maria da silva'
     * string 'maria da silva' - returns 'Maria da silva'
     * string 'mARia dA siLVA' - returns 'Maria da silva'
     * string ' MARIA  DA  SILVA ' - returns 'Maria da silva'
     */
    capitalize(): string

    /**
     * Function to remove html tags and replace
     * dangerous characters with html-safe characters
     *
     * @params string
     * @returns string
     * @example
     * string '<div><b>uma frase</b></div>' - returns 'uma frase'
     * string '<div><b>uma</b>frase</div>' - returns 'uma frase'
     */
    removeTags(): string

    /**
     * Removes non-breaking spaces
     * @return string without non-breaking space
     * @example
     * string '<p>uma frase&nbsp;</p>' - returns <p>uma frase</p>
     * string '<p>&nbsp;&nbsp;&nbsp;</p> - returns <p></p>
     */
    removeNonBreakingSpace(): string

    /**
     * Function to pluralize the text if the value module is greater than 1
     * and concatenate this value at the beginning of the text.
     *
     * @param value the number to be concatenated and used to check if the text will be pluralized
     * @returns string
     * @example
     * string 'semana', value = 2 - returns '2 semanas'
     * string 'semana', value = 1 - returns '1 semana'
     * string 'semana', value = 0 - returns '0 semana'
     * string 'semana', value = -1 - returns '-1 semana'
     * string 'semana', value = -2 - returns '-2 semanas'
     */
    pluralizeAndConcatValue(value: number): string

    /**
     * Function to remove additional, initial and final whitespaces
     *
     * @param string
     * @returns string
     * @example
     * string '  Maria  da  Silva  ' - returns 'Maria da Silva'
     */
    trimAll(): string

    /**
     * Function to remove the last occurrence of a given searchValue from a string
     *
     * @param searchValue string to be searched
     * @param replaceValue string to replace the last occurence of the searchValue
     * @returns string
     */
    replaceLastOccurrence(searchValue: string, replaceValue: string): string

    /**
     * Function to escape the special characters from regular expressions
     *
     * @param string
     * @returns string
     */
    escapeRegExp(): string
  }
}

String.prototype.truncate = function (maxCharacters: number): string {
  return String(this).length > maxCharacters ? `${String(this).substring(0, maxCharacters)}...` : String(this)
}

String.prototype.titleCase = function (): string {
  const input = String(this)

  if (input === null || input === undefined) {
    return null
  }

  const splitedInput: string[] = input.trim().split(' ')

  return splitedInput
    .reduce((prev, curr) => {
      prev = checkExceptions(prev.trim())
      return (
        `${prev} ` +
        checkExceptions(curr, (value: string) => {
          if (value.charAt(0) === '(') {
            return `(${value.charAt(1).toUpperCase() + value.slice(2).toLowerCase()}`
          } else {
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
          }
        })
      )
    }, '')
    .trimAll()
}

String.prototype.capitalize = function (): string {
  let input = String(this).trim()

  if (input === null || input === undefined) {
    return null
  }

  input = input.charAt(0).toUpperCase() + input.substr(1).toLowerCase()

  const splitedInput: string[] = input.split(' ')

  return splitedInput
    .reduce((prev, curr) => {
      prev = checkExceptions(prev.trim())
      return `${prev} ` + checkExceptions(curr)
    }, '')
    .trimAll()
}

String.prototype.removeTags = function (): string {
  const input = String(this)
  return input.replace(/<[^>]+>/g, ' ').trim()
}

String.prototype.removeNonBreakingSpace = function (): string {
  const input = String(this)
  return input.replace(/&nbsp;/g, '')
}

String.prototype.pluralizeAndConcatValue = function (value: number): string {
  const input = String(this)
  return `${value} ${input}${Math.abs(value) > 1 ? 's' : ''}`
}

String.prototype.trimAll = function (): string {
  return String(this).replace(/\s+/g, ' ').trim()
}

String.prototype.replaceLastOccurrence = function (searchValue: string, replaceValue: string): string {
  const input = String(this)
  var indexOf = input.lastIndexOf(searchValue)

  if (indexOf !== -1) {
    if (input.length >= indexOf) {
      return input.substring(0, indexOf++) + replaceValue + input.substring(indexOf)
    } else {
      return input.substring(0, indexOf++) + replaceValue
    }
  } else {
    return input
  }
}

String.prototype.escapeRegExp = function (): string {
  return String(this).replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const checkExceptions = (value: string, onFindNormalWord?: (word: string) => string): string => {
  if (lowers.includes(value.toLowerCase())) {
    return value.toLowerCase()
  }
  if (uppers.includes(value.toUpperCase())) {
    return value.toUpperCase()
  }
  return onFindNormalWord?.(value) ?? value
}
