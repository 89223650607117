import { FormApi } from 'final-form'
import { FieldMetaState } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { ErrorObject } from 'util/validation'

/**
 * Return the current field's active error.
 *
 * @param meta The react-final-form field meta state.
 */
export const getFieldError = (meta: FieldMetaState<any>): any => {
  return (meta.touched && meta.error) || (!meta.dirtySinceLastSubmit && meta.submitError)
}

/**
 * Return the current field's active error without check if it's touched or dirty.
 *
 * @param meta The react-final-form field meta state.
 */
export const getSimpleFieldError = (meta: FieldMetaState<any>): any => meta.error || meta.submitError

/**
 * Returns true if any field on @param formApi is marked as touched, otherwise, returns false
 *
 * @param formApi The FormApi from react-final-form.
 */
export const anyFieldIsTouched = (formApi: FormApi): boolean => {
  return (
    formApi
      .getRegisteredFields()
      .map((field) => formApi.getFieldState(field).touched)
      .filter((value) => value).length > 0
  )
}

/**
 * Returns the ErrorObject of the group fields by name
 *
 * @param name The MetaPath name of the group fields
 * @param formApi The FormApi from react-final-form.
 */

export const getFormGroupErrors = (
  name: MetaPath<any>,
  formApi: FormApi,
  ignoreTouchedAndDirty?: boolean
): ErrorObject<any> => {
  const absolutePath = name.absolutePath()

  if (!absolutePath) return null

  return Object.fromEntries(
    formApi
      .getRegisteredFields()
      .filter((fieldName) => fieldName.includes(absolutePath))
      .map((fieldName) => [
        fieldName.replace(`${absolutePath}.`, ''),
        ignoreTouchedAndDirty
          ? getSimpleFieldError(formApi.getFieldState(fieldName))
          : getFieldError(formApi.getFieldState(fieldName)),
      ])
  )
}
