import 'moment/locale/pt-br'

import axios from 'axios'
import { Grid, Input } from 'bold-ui'
import { alert } from 'components/alert'
import { handleError } from 'components/error'
import { Form } from 'components/form'
import { FormState } from 'final-form'
import moment from 'moment'
import qs from 'qs'

export * from './history'

export default () => {
  moment.updateLocale('pt-BR')

  axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { skipNulls: true, allowDots: true, encode: false, arrayFormat: 'repeat' })
  }

  Input.defaultProps.autoComplete = 'off'

  Form.defaultProps.onSubmitFailed = (state: FormState<any>, suppressNotificationError?: boolean) => {
    !suppressNotificationError &&
      state.hasValidationErrors &&
      alert('danger', 'Existem campos preenchidos de forma incorreta.')
  }

  Form.defaultProps.transformResult = (
    result,
    suppressNotificationError,
    suppressValidationError,
    supressFieldValidationNotificationError
  ) => {
    if (!result) {
      return result
    }

    if (isPromise(result)) {
      return result
        .then(() => Promise.resolve())
        .catch((error) =>
          handleError({
            error,
            suppressNotificationError,
            suppressValidationError,
            supressFieldValidationNotificationError,
          })
        )
    } else {
      return result
    }
  }

  Grid.defaultProps = {
    ...Grid.defaultProps,
    gap: {
      xs: 1,
      sm: 1,
      md: 1,
      lg: 2,
      xl: 2,
    },
  }
}

const isPromise = (arg: any): arg is Promise<any> => {
  return arg.catch !== undefined
}
