/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Icon, Text, Theme, useTheme } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { NeuroFaixaEtariaEnum } from 'graphql/types.generated'
import { Accordion, AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import AvaliacaoDesenvolvimentoTable from '../components/AvaliacaoDesenvolvimentoTable'
import { AvaliacaoDesenvolvimentoModel } from '../model'
import { MarcoStatusSectionAccordion } from './MarcoStatusSectionAccordion'

export interface MarcoFormSectionAccordionModel {
  marcosRecord: Record<NeuroFaixaEtariaEnum, AvaliacaoDesenvolvimentoModel[]>
}

interface MarcoFormSectionAccordionProps {
  name: MetaPath<MarcoFormSectionAccordionModel>
}

export function MarcoFormSectionAccordion(props: MarcoFormSectionAccordionProps) {
  const { name } = props
  const theme = useTheme()
  const classes = createStyles(theme)

  const {
    input: {
      value: { marcosRecord },
    },
  } = useField<MarcoFormSectionAccordionModel>(name.absolutePath())

  if (!marcosRecord) return null

  return (
    <Accordion allowZeroExpanded allowMultipleExpanded>
      <Grid>
        {Object.keys(marcosRecord)?.map((marcoSection: string, index: number) => (
          <Cell key={marcoSection} lg={6} md={12}>
            <AccordionItem uuid={`marco-${index}`}>
              <AccordionButton style={classes.button} wrapperStyle={classes.wrapperButton}>
                <HFlow justifyContent='space-between'>
                  <HFlow alignItems='center'>
                    <Icon icon='angleDown' size={1.5} />
                    <Text component='span' fontWeight='bold'>
                      {marcosRecord[marcoSection][0].descricaoFaixaEtaria}
                    </Text>
                  </HFlow>
                  <MarcoStatusSectionAccordion marcosDesenvolvimento={marcosRecord[marcoSection]} />
                </HFlow>
              </AccordionButton>
              <AccordionItemPanel>
                <AvaliacaoDesenvolvimentoTable name={name.marcosRecord[marcoSection]} />
              </AccordionItemPanel>
            </AccordionItem>
          </Cell>
        ))}
      </Grid>
    </Accordion>
  )
}
const createStyles = (theme: Theme) => ({
  button: css`
    padding: 0.75rem 1.5rem;
    border: 1px solid ${theme.pallete.gray.c60};
    background-color: ${theme.pallete.gray.c90};
    margin: 0;
    position: relative;
  `,
  wrapperButton: css`
    padding-bottom: 0;
  `,
})
