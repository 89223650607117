/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { AcompanhamentoIdosoCardQuery } from 'graphql/types.generated'

import { ContentCardSection } from '../components/ContentCardSection'

interface AcompanhamentoIdosoCardProps {
  data: AcompanhamentoIdosoCardQuery
  loading: boolean
  canRender: boolean
}

export function AcompanhamentoIdosoCard(props: AcompanhamentoIdosoCardProps) {
  const { data, loading, canRender } = props

  return (
    <ContentCardSection isLoading={loading} messageLoading='Carregando dados do idoso' canRender={canRender}>
      <VFlow vSpacing={0.1}>
        <Text fontWeight='bold'>Última avaliação multidimensional</Text>
        <DateTime format='DD/MM/YYYY' value={data?.ultimaAvaliacaoMultidimensional} />
      </VFlow>
    </ContentCardSection>
  )
}
