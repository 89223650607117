import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { createValidator, ErrorObject, required } from 'util/validation'
import { isEmpty } from 'util/validation/Util'

import { DesfechoAtendimentoFormModel, ManterCidadaoLista } from '../components/DesfechoAtendimentoPanel'
import { FinalizacaoAtendimentoFormModel } from '../FinalizacaoAtendimentoForm'
import { isCondutaRequired } from '../util'
import { agendarConsultaAvulsaValidator } from './validator-agendamento'
import { finalizacaoOdontoValidator } from './validator-odonto'

export const finalizacaoIndividualValidator = (
  isObservacao: boolean,
  hasEncaminhamentoExterno: boolean,
  getServerTimeNow: () => Date
) =>
  createValidator<FinalizacaoAtendimentoFormModel>(
    {
      tipoAtendimento: [required],
      agendamentoConsultas: agendarConsultaAvulsaValidator(getServerTimeNow),
    },
    (values: FinalizacaoAtendimentoFormModel, errors: ErrorObject<FinalizacaoAtendimentoFormModel>) => {
      const isRequired = isCondutaRequired({
        isObservacao,
        hasNotificacaoCasoSuspeito: !isEmpty(values.fichasNotificacaoCasoSuspeito),
        hasEncaminhamentoExterno,
        manterCidadaoNaLista: values.desfechoAtendimento?.manterCidadaoLista,
      })
      if (isRequired) {
        errors.conduta = required(values.conduta)
      }
      return errors
    }
  )

const desfechoProcedimentosValidator = (getServerTimeNow: () => Date) =>
  createValidator<FinalizacaoAtendimentoFormModel>({
    agendamentoConsultas: agendarConsultaAvulsaValidator(getServerTimeNow),
  })

const desfechoObservacaoValidator = (isAtendimentoObservacao: boolean, podeFinalizarAtendimentoObservacao: boolean) =>
  createValidator<FinalizacaoAtendimentoFormModel>(
    {},
    (values: FinalizacaoAtendimentoFormModel, errors: ErrorObject<FinalizacaoAtendimentoFormModel>) => {
      if (
        isAtendimentoObservacao &&
        !podeFinalizarAtendimentoObservacao &&
        values.desfechoAtendimento.manterCidadaoLista === ManterCidadaoLista.NAO
      ) {
        ;(errors.desfechoAtendimento as ErrorObject<DesfechoAtendimentoFormModel>).manterCidadaoLista =
          'Somente médicos e enfermeiros podem liberar o cidadão.'
      }

      if (
        isAtendimentoObservacao &&
        podeFinalizarAtendimentoObservacao &&
        values.desfechoAtendimento.manterCidadaoLista === ManterCidadaoLista.NAO
      ) {
        errors.conduta = required(values.conduta)
      }

      return errors
    }
  )

export const finalizacaoAtendimentoValidator = (
  tipoAtendProf: TipoAtendimentoProfissional,
  isDemandaEspontanea: boolean,
  hasEncaminhamentoExterno: boolean,
  isStartObservacao: boolean,
  isAtendimentoObservacao: boolean,
  isResponsavelAtendimentoObservacao: boolean,
  podeFinalizarAtendimentoObservacao: boolean,
  getServerTimeNow: () => Date
) => {
  switch (tipoAtendProf) {
    case TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO: {
      return finalizacaoOdontoValidator(isDemandaEspontanea, hasEncaminhamentoExterno, getServerTimeNow)
    }
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS: {
      return desfechoProcedimentosValidator(getServerTimeNow)
    }
    case TipoAtendimentoProfissional.OBSERVACAO: {
      if (isResponsavelAtendimentoObservacao) {
        return finalizacaoIndividualValidator(isAtendimentoObservacao, hasEncaminhamentoExterno, getServerTimeNow)
      }
      return desfechoObservacaoValidator(isAtendimentoObservacao, podeFinalizarAtendimentoObservacao)
    }
    default: {
      const isObservacao = isStartObservacao || isAtendimentoObservacao
      return finalizacaoIndividualValidator(isObservacao, hasEncaminhamentoExterno, getServerTimeNow)
    }
  }
}
