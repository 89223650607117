import { StatusDesenvolvimentoAlertProps } from '../components/StatusDesenvolvimentoAlert'
import { AvaliacaoDesenvolvimentoModel } from '../model'
import { allStatusPresent, avaliacoesListWithStatusNaoAvaliado, avaliacoesListWithStatusPresente } from '../util'
import { marcoMessages } from './messages'
import { AlertR1Props, messageR1, messageR2, messageR3 } from './regrasMensagensS1'
import { messageR4 } from './regrasMensagensS2'

export const showAlertS1 = (
  marcoFaixaEtariaAnterior: AvaliacaoDesenvolvimentoModel[],
  alteracoesFenotipicas: AvaliacaoDesenvolvimentoModel[],
  marcoFaixaEtariaAtual: AvaliacaoDesenvolvimentoModel[],
  alertR1Props: AlertR1Props
): StatusDesenvolvimentoAlertProps => {
  const messageStatusR1 = alertR1Props.medicaoPerimetroCefalico?.valorMedicao && messageR1(alertR1Props)?.trim()
  const messageStatusR2 = messageR2(alteracoesFenotipicas)?.trim()
  const messageStatusR3 = messageR3(marcoFaixaEtariaAnterior, marcoFaixaEtariaAtual)?.trim()

  if (messageStatusR1?.length || messageStatusR2?.length || messageStatusR3?.length)
    return {
      type: 'S1',
      title: 'Provável atraso no desenvolvimento',
      messages: [messageStatusR1, messageStatusR2, messageStatusR3].join(' ').trimAll(),
    }
}

export const showAlertR4 = (avaliacoes: AvaliacaoDesenvolvimentoModel[]): StatusDesenvolvimentoAlertProps => {
  const messageStatusR4 = messageR4(avaliacoes)

  if (messageStatusR4?.length) {
    return {
      type: 'S2',
      title: 'Alerta para o desenvolvimento',
      messages: messageStatusR4,
    }
  }
}

export const showAlertR5 = (
  avaliacoes: AvaliacaoDesenvolvimentoModel[],
  fatoresRisco: AvaliacaoDesenvolvimentoModel[]
): StatusDesenvolvimentoAlertProps => {
  const fatoresRiscoPresentesSize = avaliacoesListWithStatusPresente(fatoresRisco).length
  if (allStatusPresent(avaliacoes) && fatoresRiscoPresentesSize > 0) {
    const fatoresPresentesMessage = marcoMessages(fatoresRiscoPresentesSize).fatorPresente
    return {
      type: 'S2',
      title: 'Alerta para o desenvolvimento',
      messages: `${fatoresPresentesMessage}`,
    }
  }
}

export const showAlertR6 = (avaliacoes: AvaliacaoDesenvolvimentoModel[]): StatusDesenvolvimentoAlertProps => {
  if (allStatusPresent(avaliacoes))
    return {
      type: 'S3',
      title: 'Desenvolvimento normal',
    }
}

export const showAlertR7 = (avaliacoes: AvaliacaoDesenvolvimentoModel[]): StatusDesenvolvimentoAlertProps => {
  const marcosNaoAvaliados = avaliacoesListWithStatusNaoAvaliado(avaliacoes)
  if (marcosNaoAvaliados.length && marcosNaoAvaliados.length < avaliacoes.length) {
    const marcosNaoAvaliadosMessageR7 = marcoMessages(marcosNaoAvaliados.length).desenvolvimentoNaoAvaliadoR7

    return {
      type: 'S4',
      title: 'Carência de avaliações na faixa etária',
      messages: `${marcosNaoAvaliadosMessageR7}${avaliacoes[0].descricaoFaixaEtaria}.`,
    }
  }
}
