/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import { ContentCardSection } from '../components/ContentCardSection'
import { LembreteModel } from '../types/LembreteModel'
import { LembretesListCard } from './LembretesListCard'

export interface LembretesCardProps {
  paginatedItems: LembreteModel[]
  loading: boolean
  hasLembretes: boolean
  canRender: boolean
  mostrarAutor: boolean
}

export function LembretesCard({ paginatedItems, loading, hasLembretes, canRender, mostrarAutor }: LembretesCardProps) {
  const classes = styles()

  return (
    <ContentCardSection isLoading={loading} messageLoading='Carregando dados dos lembretes' canRender={canRender}>
      {hasLembretes && (
        <ul css={classes.ul}>
          <LembretesListCard lembretes={paginatedItems} mostrarAutor={mostrarAutor} />
        </ul>
      )}
    </ContentCardSection>
  )
}

const styles = () => ({
  ul: css`
    padding: 0rem;
    margin: 0rem;
  `,
})
