/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, Paper, Theme, useTheme, VFlow } from 'bold-ui'
import { CheckboxField, NumberField } from 'components/form'
import { useMemo } from 'react'
import { MetaPath } from 'util/metaPath'

import { AntecedentesFormSectionAccordion } from './AntecedentesFormSectionAccordion'

export interface InformacoesObstetricasPanelModel {
  openedAccordions?: string[]
  gestaPrevias?: string
  abortos?: string
  partoMenosDeUmAno?: boolean
  partos?: string
  partosVaginais?: string
  partosCesareas?: string
  partosDomiciliares?: string
  nascidosVivos?: string
  natimortos?: string
  recemNascidoAbaixo?: string
  recemNascidoAcima?: string
  filhosVivos?: string
  obitoAntesPrimeiraSemana?: string
  obitoAposPrimeiraSemana?: string
  dataAtualizacao?: Instant
}

export interface InformacoesObstetricasPanelProps {
  name: MetaPath<InformacoesObstetricasPanelModel>
  dataAtualizacao: Instant
}

export function InformacoesObstetricasPanel(props: InformacoesObstetricasPanelProps) {
  const { name, dataAtualizacao } = props
  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme), [theme])

  return (
    <AntecedentesFormSectionAccordion
      name={name.openedAccordions}
      title='Informações obstétricas'
      uuid='informacoesObstetricas'
      dataAtualizacao={dataAtualizacao}
    >
      <VFlow>
        <VFlow>
          <Grid gap={1}>
            <Cell size={3} xl={2}>
              <NumberField name={name.gestaPrevias} label='Gestas prévias' maxLength={2} />
            </Cell>
            <Cell size={3} xl={2}>
              <NumberField name={name.abortos} label='Abortos' maxLength={2} />
            </Cell>
            <Cell size={6} xl={5}>
              <CheckboxField
                name={name.partoMenosDeUmAno}
                label='Desfecho da última gestação aconteceu há menos de um ano'
                style={classes.checkboxMargin}
              />
            </Cell>
          </Grid>
        </VFlow>

        <VFlow vSpacing={0.5}>
          <Grid gap={1}>
            <Cell xs={3} xl={2} style={classes.partosFieldMargin}>
              <NumberField name={name.partos} label='Partos realizados' maxLength={2} />
            </Cell>
            <Cell xs={9} lg={7} flexGrow={1}>
              <Paper style={classes.paper}>
                <Grid gap={1}>
                  <Cell size={4}>
                    <NumberField name={name.partosVaginais} label='Vaginais' maxLength={2} />
                  </Cell>
                  <Cell size={4}>
                    <NumberField name={name.partosCesareas} label='Cesáreas' maxLength={2} />
                  </Cell>
                  <Cell size={4} style={classes.borderLeft}>
                    <NumberField
                      name={name.partosDomiciliares}
                      label={<span css={classes.noWrap}>Partos domiciliares</span>}
                      maxLength={2}
                    />
                  </Cell>
                </Grid>
              </Paper>
            </Cell>
            <Cell lg={1} xl={2} />
          </Grid>
        </VFlow>

        <VFlow vSpacing={0.5}>
          <Grid gap={1}>
            <Cell size={5}>
              <VFlow vSpacing={0.25}>
                <Heading level={4}>Nascidos</Heading>
                <Paper style={classes.paper}>
                  <Grid gap={1} alignItems='flex-end' justifyContent='flex-start'>
                    <Cell size={6}>
                      <NumberField name={name.nascidosVivos} label='Vivos' maxLength={2} />
                    </Cell>
                    <Cell size={6}>
                      <NumberField name={name.natimortos} label='Mortos' maxLength={2} />
                    </Cell>
                  </Grid>
                </Paper>
              </VFlow>
            </Cell>
            <Cell size={5}>
              <VFlow vSpacing={0.25}>
                <Heading level={4}>Recém-nascidos com</Heading>
                <Paper style={classes.paper}>
                  <Grid gap={1} alignItems='flex-end' justifyContent='flex-start'>
                    <Cell size={6}>
                      <NumberField
                        name={name.recemNascidoAbaixo}
                        label={<span css={classes.noWrap}>Menos de 2500g</span>}
                        maxLength={2}
                      />
                    </Cell>
                    <Cell size={6}>
                      <NumberField
                        name={name.recemNascidoAcima}
                        label={<span css={classes.noWrap}>Mais de 4500g</span>}
                        maxLength={2}
                      />
                    </Cell>
                  </Grid>
                </Paper>
              </VFlow>
            </Cell>
          </Grid>
        </VFlow>

        <Grid style={classes.border}>
          <Cell size={12}>
            <VFlow vSpacing={0.5}>
              <Heading level={4}>Nascidos vivos</Heading>
              <Grid gap={1}>
                <Cell size={3} xl={2}>
                  <NumberField name={name.filhosVivos} label='Vivem' maxLength={2} />
                </Cell>
                <Cell size={3} xl={2}>
                  <NumberField
                    name={name.obitoAntesPrimeiraSemana}
                    label={<span css={classes.noWrap}>Mortos na 1ª semana</span>}
                    maxLength={2}
                  />
                </Cell>
                <Cell size={3} xl={2}>
                  <NumberField
                    name={name.obitoAposPrimeiraSemana}
                    label={<span css={classes.noWrap}>Mortos depois da 1ª semana</span>}
                    maxLength={2}
                  />
                </Cell>
              </Grid>
            </VFlow>
          </Cell>
        </Grid>
      </VFlow>
    </AntecedentesFormSectionAccordion>
  )
}

const createStyles = (theme: Theme) => ({
  border: css`
    border-top: 0.063rem solid ${theme.pallete.gray.c80};
    padding-top: 0.3rem;
    margin-top: 0.1rem;
  `,
  borderLeft: css`
    border-left: 0.063rem solid ${theme.pallete.gray.c80};
    padding-left: 0.7rem;
  `,
  noWrap: css`
    white-space: nowrap;
  `,
  partosFieldMargin: css`
    margin-top: 0.7rem;
  `,
  checkboxMargin: css`
    margin-top: 1.7rem;
  `,
  paper: css`
    padding: 0.625rem;
    margin: 0.5rem, 0;
  `,
})
