import { Heading, VFlow } from 'bold-ui'
import React from 'react'

import { TransmissaoLinksDestinoQueryInput, TransmissaoLinksDestinoTable } from './TransmissaoLinksDestinoTable'

export interface TransmissaoLinksDestinoProps {
  initialFilter?: TransmissaoLinksDestinoQueryInput
}

export const TransmissaoLinksDestinoBox = (props: TransmissaoLinksDestinoProps) => {
  return (
    <VFlow vSpacing={0.5}>
      <Heading level={3}>Instalações de destino para envio online</Heading>

      <TransmissaoLinksDestinoTable />
    </VFlow>
  )
}
