/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text, Tooltip, useTheme } from 'bold-ui'
import { Fragment } from 'react'

export interface AtendimentoAtualStatusMarkerProps {
  isCriadoAvaliacao: boolean
  expanded?: boolean
}

export function AtendimentoAtualStatusMarker(props: AtendimentoAtualStatusMarkerProps) {
  const { isCriadoAvaliacao, expanded = false } = props

  const theme = useTheme()

  const text = isCriadoAvaliacao ? 'Avaliado agora' : 'Registrado agora'

  return (
    <HFlow alignItems='center' hSpacing={0.3}>
      {expanded ? (
        <Fragment>
          <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
          <Text
            style={css`
              color: ${theme.pallete.primary.c40};
            `}
          >
            {text}
          </Text>
        </Fragment>
      ) : (
        <Tooltip text={text}>
          <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
        </Tooltip>
      )}
    </HFlow>
  )
}
