import { Cell, Grid, Heading, useTheme } from 'bold-ui'
import { OpcaoTipoPerguntaEnum } from 'graphql/types.generated'
import React from 'react'
import { FormSpy } from 'react-final-form'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { MarcadoresConsumoAlimentarListItem } from '../MarcadoresConsumoAlimentarListItem'
import { MCAProps, MCASeisAVinteETresMesesPanelModel, mcaSeisAVinteETresMesesQuestions } from '../model'
import { createStyles } from '../utils'
import { ConsistenciaAlimentoRadioGroupField } from './ConsistenciaAlimentoRadioGroupField'
import { QuantidadeRefeicoesRadioGroupField } from './QuantidadeRefeicoesRadioGroupField'
import { SimNaoNaoSabeRadioGroupField } from './SimNaoNaoSabeRadioGroupField'

const filter = [
  'leiteMaterno',
  'fruta',
  'quantidadeRefeicoesFruta',
  'comidaDeSal',
  'quantidadeRefeicoesComidaDeSal',
  'consistenciaAlimentoComidaDeSal',
]
const filteredQuestions = Object.entries(mcaSeisAVinteETresMesesQuestions).filter(([key, _]) => !filter.includes(key))

export function MCASeisAVinteETresMeses(props: MCAProps<MCASeisAVinteETresMesesPanelModel>) {
  const { name } = props

  const theme = useTheme()
  const style = createStyles(theme)

  return (
    <>
      <FormSpy<SoapState> subscription={{ values: true }}>
        {({ values }) => {
          const mca: MCASeisAVinteETresMesesPanelModel = values?.objetivo?.marcadoresConsumoAlimentar
          return (
            <Grid>
              <Cell style={style.border} size={12}>
                <MarcadoresConsumoAlimentarListItem label={mcaSeisAVinteETresMesesQuestions.leiteMaterno}>
                  <SimNaoNaoSabeRadioGroupField name={name.leiteMaterno} showErrorField={false} />
                </MarcadoresConsumoAlimentarListItem>
              </Cell>

              <Cell style={style.border} size={12}>
                <MarcadoresConsumoAlimentarListItem label={mcaSeisAVinteETresMesesQuestions.fruta}>
                  <SimNaoNaoSabeRadioGroupField name={name.fruta} showErrorField={false} />
                </MarcadoresConsumoAlimentarListItem>
              </Cell>

              <Cell style={style.border} size={12}>
                <MarcadoresConsumoAlimentarListItem label={mcaSeisAVinteETresMesesQuestions.quantidadeRefeicoesFruta}>
                  <QuantidadeRefeicoesRadioGroupField
                    hSpacing={1.45}
                    name={name.quantidadeRefeicoesFruta}
                    showErrorField={false}
                    disabled={mca?.fruta !== OpcaoTipoPerguntaEnum.SIM}
                  />
                </MarcadoresConsumoAlimentarListItem>
              </Cell>

              <Cell style={style.border} size={12}>
                <MarcadoresConsumoAlimentarListItem label={mcaSeisAVinteETresMesesQuestions.comidaDeSal}>
                  <SimNaoNaoSabeRadioGroupField name={name.comidaDeSal} showErrorField={false} />
                </MarcadoresConsumoAlimentarListItem>
              </Cell>

              <Cell style={style.border} size={12}>
                <MarcadoresConsumoAlimentarListItem
                  label={mcaSeisAVinteETresMesesQuestions.quantidadeRefeicoesComidaDeSal}
                >
                  <QuantidadeRefeicoesRadioGroupField
                    hSpacing={1.45}
                    name={name.quantidadeRefeicoesComidaDeSal}
                    showErrorField={false}
                    disabled={mca?.comidaDeSal !== OpcaoTipoPerguntaEnum.SIM}
                  />
                </MarcadoresConsumoAlimentarListItem>
              </Cell>

              <Cell style={style.border} size={12}>
                <MarcadoresConsumoAlimentarListItem
                  justifyContent='flex-start'
                  breakline
                  label={mcaSeisAVinteETresMesesQuestions.consistenciaAlimentoComidaDeSal}
                >
                  <ConsistenciaAlimentoRadioGroupField
                    name={name.consistenciaAlimentoComidaDeSal}
                    showErrorField={false}
                    disabled={mca?.comidaDeSal !== OpcaoTipoPerguntaEnum.SIM}
                  />
                </MarcadoresConsumoAlimentarListItem>
              </Cell>
            </Grid>
          )
        }}
      </FormSpy>
      <Grid>
        <Cell size={12} style={style.sectionHeading}>
          <Heading level={6}>Ontem a criança consumiu:</Heading>
        </Cell>
        {filteredQuestions.map(([key, value]) => (
          <Cell key={key} size={12} style={style.border}>
            <MarcadoresConsumoAlimentarListItem label={value}>
              <SimNaoNaoSabeRadioGroupField name={name[key]} showErrorField={false} />
            </MarcadoresConsumoAlimentarListItem>
          </Cell>
        ))}
      </Grid>
    </>
  )
}
