import { ParteBucalEnum } from 'graphql/types.generated'
import { compact } from 'lodash'

export type PeriogramaCompletoMultipleBoolean = { d: boolean; v: boolean; m: boolean }

export type PeriogramaCompletoTableInputValue = string | PeriogramaCompletoMultipleBoolean

export type PeriogramaCompletoDenteValuesModel = {
  ausente?: boolean
  values: Partial<
    Record<PeriogramaCompletoFace, Partial<Record<PeriogramaCompletoCondicao, PeriogramaCompletoTableInputValue>>>
  >
}

export type PeriogramaCompletoTableModel = Partial<Record<ParteBucalEnum, PeriogramaCompletoDenteValuesModel>>

export type PeriogramaCompletoModel = { dados?: PeriogramaCompletoTableModel; observacao?: string }

export enum PeriogramaCompletoCondicao {
  PROF_SONDAGEM = 'PROF_SONDAGEM',
  RECESSAO = 'RECESSAO',
  PIP = 'PIP',
  PLACA = 'PLACA',
  SANG_SONDAGEM = 'SANG_SONDAGEM',
  MOBILIDADE = 'MOBILIDADE',
  LESAO_FURCA = 'LESAO_FURCA',
}

export enum PeriogramaCompletoFace {
  VESTIBULAR = 'VESTIBULAR',
  PALATINA = 'PALATINA',
  LINGUAL = 'LINGUAL',
}

export enum PeriogramaCompletoQuadrante {
  ARCADA_SUPERIOR_ESQUERDA = 'ARCADA_SUPERIOR_ESQUERDA',
  ARCADA_SUPERIOR_DIREITA = 'ARCADA_SUPERIOR_DIREITA',
  ARCADA_INFERIOR_ESQUERDA = 'ARCADA_INFERIOR_ESQUERDA',
  ARCADA_INFERIOR_DIREITA = 'ARCADA_INFERIOR_DIREITA',
}

export interface PeriogramaCompletoQuadranteModel {
  title: string
  faces: PeriogramaCompletoFace[]
  dentes: ParteBucalEnum[]
}

export interface PeriogramaCompletoCondicaoConfig {
  placeholder?: string
  mask?: RegExp
  maxLength?: number
}

const periogramaCompletoDentes: Partial<Record<
  ParteBucalEnum,
  {
    quadrante: PeriogramaCompletoQuadrante
    possuiLesaoDeFurca: boolean
  }
>> = {
  [ParteBucalEnum.DENTE_18]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_17]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_16]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_15]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_14]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_13]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_12]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_11]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_21]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_22]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_23]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_24]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_25]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_26]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_27]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_28]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_48]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_47]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_46]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_45]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_44]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_43]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_42]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_41]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_31]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_32]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_33]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_34]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_35]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA,
    possuiLesaoDeFurca: false,
  },
  [ParteBucalEnum.DENTE_36]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_37]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA,
    possuiLesaoDeFurca: true,
  },
  [ParteBucalEnum.DENTE_38]: {
    quadrante: PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA,
    possuiLesaoDeFurca: true,
  },
}

const dentesEntries = Object.entries(periogramaCompletoDentes)

const getDentesByQuadrante = (quadrante: PeriogramaCompletoQuadrante): ParteBucalEnum[] =>
  compact(
    dentesEntries.map(
      ([dente, entries]: [
        ParteBucalEnum,
        {
          quadrante: PeriogramaCompletoQuadrante
          faces: { VESTIBULAR: PeriogramaCompletoFace; INTERNA: PeriogramaCompletoFace }
          possuiLesaoDeFurca: boolean
        }
      ]) => entries.quadrante === quadrante && dente
    )
  )

const quadrantesPeriogramaCompleto: Record<PeriogramaCompletoQuadrante, PeriogramaCompletoQuadranteModel> = {
  ARCADA_SUPERIOR_DIREITA: {
    title: 'Arcada superior - Direita',
    faces: [PeriogramaCompletoFace.VESTIBULAR, PeriogramaCompletoFace.PALATINA],
    dentes: getDentesByQuadrante(PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA),
  },
  ARCADA_SUPERIOR_ESQUERDA: {
    title: 'Arcada superior - Esquerda',
    faces: [PeriogramaCompletoFace.VESTIBULAR, PeriogramaCompletoFace.PALATINA],
    dentes: getDentesByQuadrante(PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA),
  },
  ARCADA_INFERIOR_DIREITA: {
    title: 'Arcada inferior - Direita',
    faces: [PeriogramaCompletoFace.VESTIBULAR, PeriogramaCompletoFace.LINGUAL],
    dentes: getDentesByQuadrante(PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA),
  },
  ARCADA_INFERIOR_ESQUERDA: {
    title: 'Arcada inferior - Esquerda',
    faces: [PeriogramaCompletoFace.VESTIBULAR, PeriogramaCompletoFace.LINGUAL],
    dentes: getDentesByQuadrante(PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA),
  },
}

const facesPeriogramaCompleto: Record<
  PeriogramaCompletoFace,
  { title: string; placeholder: string; longPlaceholder: string; condicoes: PeriogramaCompletoCondicao[] }
> = {
  VESTIBULAR: {
    title: 'Vestibular',
    placeholder: 'd/v/m',
    longPlaceholder: 'Distal/Vestibular/Mesial',
    condicoes: [
      PeriogramaCompletoCondicao.PROF_SONDAGEM,
      PeriogramaCompletoCondicao.RECESSAO,
      PeriogramaCompletoCondicao.PIP,
      PeriogramaCompletoCondicao.PLACA,
      PeriogramaCompletoCondicao.SANG_SONDAGEM,
    ],
  },
  PALATINA: {
    title: 'Palatina',
    placeholder: 'd/p/m',
    longPlaceholder: 'Distal/Palatina/Mesial',
    condicoes: [
      PeriogramaCompletoCondicao.PROF_SONDAGEM,
      PeriogramaCompletoCondicao.RECESSAO,
      PeriogramaCompletoCondicao.PIP,
      PeriogramaCompletoCondicao.PLACA,
      PeriogramaCompletoCondicao.SANG_SONDAGEM,
      PeriogramaCompletoCondicao.MOBILIDADE,
      PeriogramaCompletoCondicao.LESAO_FURCA,
    ],
  },
  LINGUAL: {
    title: 'Lingual',
    placeholder: 'd/l/m',
    longPlaceholder: 'Distal/Lingual/Mesial',
    condicoes: [
      PeriogramaCompletoCondicao.PROF_SONDAGEM,
      PeriogramaCompletoCondicao.RECESSAO,
      PeriogramaCompletoCondicao.PIP,
      PeriogramaCompletoCondicao.PLACA,
      PeriogramaCompletoCondicao.SANG_SONDAGEM,
      PeriogramaCompletoCondicao.MOBILIDADE,
      PeriogramaCompletoCondicao.LESAO_FURCA,
    ],
  },
}

const condicoesPeriogramaCompleto: Record<
  PeriogramaCompletoCondicao,
  { title: string; longTitle?: string; placeholder?: string; mask?: RegExp; maxLength?: number }
> = {
  PROF_SONDAGEM: {
    title: 'Prof. de sond.',
    longTitle: 'Profundidade de sondagem',
    placeholder: '0/0/0',
    mask: new RegExp('^(\\d{1}|\\d{2})\\/(\\d{1}|\\d{2})\\/(\\d{1}|\\d{2})$'),
    maxLength: 8,
  },
  RECESSAO: {
    title: 'Recessão',
    placeholder: '0/0/0',
    mask: new RegExp('^\\-?(\\d{1}|\\d{2})\\/\\-?(\\d{1}|\\d{2})\\/\\-?(\\d{1}|\\d{2})$'),
    maxLength: 11,
  },
  PIP: {
    title: 'P.I.P.',
    longTitle: 'Perda de inserção clínica periodontal',
  },
  PLACA: {
    title: 'Placa',
  },
  SANG_SONDAGEM: {
    title: 'Sang. à sond.',
    longTitle: 'Sangramento à sondagem',
  },
  MOBILIDADE: {
    title: 'Mobilidade',
    placeholder: '0',
    mask: new RegExp(/\d/),
    maxLength: 1,
  },
  LESAO_FURCA: {
    title: 'Lesão de furca',
  },
}

const periogramaArcadas: Partial<Record<ParteBucalEnum, ParteBucalEnum[]>> = {
  [ParteBucalEnum.ARCADA_SUPERIOR]: [
    ParteBucalEnum.DENTE_18,
    ParteBucalEnum.DENTE_17,
    ParteBucalEnum.DENTE_16,
    ParteBucalEnum.DENTE_15,
    ParteBucalEnum.DENTE_14,
    ParteBucalEnum.DENTE_13,
    ParteBucalEnum.DENTE_12,
    ParteBucalEnum.DENTE_11,
    ParteBucalEnum.DENTE_21,
    ParteBucalEnum.DENTE_22,
    ParteBucalEnum.DENTE_23,
    ParteBucalEnum.DENTE_24,
    ParteBucalEnum.DENTE_25,
    ParteBucalEnum.DENTE_26,
    ParteBucalEnum.DENTE_27,
    ParteBucalEnum.DENTE_28,
  ],
  [ParteBucalEnum.ARCADA_INFERIOR]: [
    ParteBucalEnum.DENTE_48,
    ParteBucalEnum.DENTE_47,
    ParteBucalEnum.DENTE_46,
    ParteBucalEnum.DENTE_45,
    ParteBucalEnum.DENTE_44,
    ParteBucalEnum.DENTE_43,
    ParteBucalEnum.DENTE_42,
    ParteBucalEnum.DENTE_41,
    ParteBucalEnum.DENTE_31,
    ParteBucalEnum.DENTE_32,
    ParteBucalEnum.DENTE_33,
    ParteBucalEnum.DENTE_34,
    ParteBucalEnum.DENTE_35,
    ParteBucalEnum.DENTE_36,
    ParteBucalEnum.DENTE_37,
    ParteBucalEnum.DENTE_38,
  ],
}

const lesaoFurcaConfigs: { double: PeriogramaCompletoCondicaoConfig; triple: PeriogramaCompletoCondicaoConfig } = {
  double: {
    placeholder: '0/0',
    mask: new RegExp('^\\d{1}\\/\\d{1}$'),
    maxLength: 3,
  },
  triple: {
    placeholder: '0/0/0',
    mask: new RegExp('\\d{1}\\/\\d{1}\\/\\d{1}$'),
    maxLength: 5,
  },
}

const dentesComLesaoDeFurca: Partial<Record<ParteBucalEnum, PeriogramaCompletoCondicaoConfig>> = {
  DENTE_14: lesaoFurcaConfigs.double,
  DENTE_15: lesaoFurcaConfigs.double,
  DENTE_16: lesaoFurcaConfigs.triple,
  DENTE_17: lesaoFurcaConfigs.triple,
  DENTE_18: lesaoFurcaConfigs.triple,
  DENTE_24: lesaoFurcaConfigs.double,
  DENTE_25: lesaoFurcaConfigs.double,
  DENTE_26: lesaoFurcaConfigs.triple,
  DENTE_27: lesaoFurcaConfigs.triple,
  DENTE_28: lesaoFurcaConfigs.triple,
  DENTE_36: lesaoFurcaConfigs.double,
  DENTE_37: lesaoFurcaConfigs.double,
  DENTE_38: lesaoFurcaConfigs.double,
  DENTE_46: lesaoFurcaConfigs.double,
  DENTE_47: lesaoFurcaConfigs.double,
  DENTE_48: lesaoFurcaConfigs.double,
}

export function getPeriogramaCompletoQuadrantes() {
  return quadrantesPeriogramaCompleto
}

export function getPeriogramaCompletoQuadrante(quadranteEnum: PeriogramaCompletoQuadrante) {
  return quadrantesPeriogramaCompleto[quadranteEnum]
}

export function getPeriogramaCompletoDente(dente: ParteBucalEnum) {
  return periogramaCompletoDentes[dente]
}

/*
 * pega as faces do quadrante do dente
 */

export function getPeriogramaCompletoDenteFaces(
  dente: ParteBucalEnum
): { VESTIBULAR: PeriogramaCompletoFace; INTERNA: PeriogramaCompletoFace } {
  const { faces } = getPeriogramaCompletoQuadrante(periogramaCompletoDentes[dente].quadrante)

  return {
    VESTIBULAR: faces[0],
    INTERNA: faces[1],
  }
}

export function getPeriogramaCompletoFace(faceEnum: PeriogramaCompletoFace) {
  return facesPeriogramaCompleto[faceEnum]
}

export function getPeriogramaCompletoCondicao(condicaoEnum: PeriogramaCompletoCondicao) {
  return condicoesPeriogramaCompleto[condicaoEnum]
}

export function getPeriogramaCompletoCondicaoConfig(
  condicao: PeriogramaCompletoCondicao,
  dente: ParteBucalEnum
): PeriogramaCompletoCondicaoConfig {
  const { mask, maxLength, placeholder } =
    condicao === PeriogramaCompletoCondicao.LESAO_FURCA && possuiPeriogramaLesaoDeFurca(dente)
      ? getPeriogramaLesaoDeFurcaConfig(dente)
      : getPeriogramaCompletoCondicao(condicao)

  return { mask, maxLength, placeholder }
}

export function getPeriogramaCompletoCondicaoDefaultValue(condicao: PeriogramaCompletoCondicao, dente: ParteBucalEnum) {
  // usando `placeholder` para identificar codições que possuem `defaultValue`
  return getPeriogramaCompletoCondicaoConfig(condicao, dente).placeholder
}

export function getDentesFromPeriogramaCompleto(): Array<
  [ParteBucalEnum, Array<[PeriogramaCompletoFace, Array<PeriogramaCompletoCondicao>]>]
> {
  return Object.keys(periogramaCompletoDentes).map((denteEnum: ParteBucalEnum) => {
    const faces = getPeriogramaCompletoDenteFaces(denteEnum)

    return [
      denteEnum,
      [
        [faces.VESTIBULAR, getPeriogramaCompletoFace(faces.VESTIBULAR).condicoes],
        [faces.INTERNA, getPeriogramaCompletoFace(faces.INTERNA).condicoes],
      ],
    ]
  })
}

export function possuiPeriogramaLesaoDeFurca(dente: ParteBucalEnum) {
  return periogramaCompletoDentes[dente].possuiLesaoDeFurca
}

export function getPeriogramaLesaoDeFurcaConfig(dente: ParteBucalEnum) {
  return dentesComLesaoDeFurca[dente]
}

export function getPeriogramaArcadas() {
  return periogramaArcadas
}
