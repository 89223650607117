import axios, { AxiosPromise } from 'axios'

const API_ENDPOINT = '/api/exportar-cadastros-duplicados'
const EXPORTAR_CSV_ENDPOINT = `${API_ENDPOINT}/exportarCSV`

export class ExportarCadastrosDuplicados {
  exportarCSV(): AxiosPromise<Blob> {
    return axios.post(EXPORTAR_CSV_ENDPOINT, {
      responseType: 'blob',
    })
  }
}
