/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { HLabel } from 'components/HLabel'
import { ModalBody as ModalBodyPec } from 'components/modals/ModalBody'
import theme from 'config/theme'
import copy from 'copy-to-clipboard'
import { noop } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'

export type CredencialRecebimentoExternoUserPassword = {
  user: string
  password: string
}

interface CredencialRecebimentoExternoGeradaModalProps {
  isOpen: boolean
  userPassword: CredencialRecebimentoExternoUserPassword
  onClose: () => void
}

export function CredencialRecebimentoExternoGeradaModal(props: CredencialRecebimentoExternoGeradaModalProps) {
  const { isOpen, userPassword, onClose } = props

  if (isUndefinedOrNull(userPassword)) return null

  const { password, user } = userPassword

  const clipBoardText = `Usuário: ${user}\nSenha: ${password}`

  const handleCopy = () => {
    copy(clipBoardText)
  }

  return (
    <Modal open={isOpen} size='small' hasCloseIcon={false} closeOnBackdropClick={false} onClose={noop}>
      <ModalBody>
        <ModalBodyPec title='Credencial gerada' titleLevel={1} icon='exclamationTriangleOutline' type='alert'>
          <VFlow>
            <Text>
              As informações da credencial só serão exibidas uma vez e não será possível recuperá-las. Certifique-se de
              que ela estará segura. Em caso de perda da credencial, será necessário inativá-la e gerar outra.
            </Text>

            <div css={styles.userPasswordBox}>
              <HLabel title='Usuário:' style={styles.userPasswordItem}>
                {user}
              </HLabel>
              <HLabel title='Senha:' style={styles.userPasswordItem}>
                {password}
              </HLabel>
            </div>

            <HFlow justifyContent='flex-end'>
              <Button kind='primary' size='small' onClick={handleCopy}>
                Copiar
              </Button>
            </HFlow>
          </VFlow>
        </ModalBodyPec>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <FooterButton kind='primary' onClick={onClose}>
            Finalizar
          </FooterButton>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const styles = {
  userPasswordBox: css`
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: 1px;
    background: ${theme.pallete.gray.c90};
  `,
  userPasswordItem: css`
    padding: 0.375rem 0.5rem;
    border-bottom: 1px solid ${theme.pallete.gray.c80};

    &:last-child {
      border: 0;
    }
  `,
}
