import { Box } from 'components/Box'
import { BoxGroup } from 'components/BoxGroup'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

import { ConfiguracaoPerformanceModel, ConfiguracoesPerformanceForm } from './ConfiguracoesPerformanceForm'

export interface ConfiguracoesPerformanceProps {
  initialValues?: ConfiguracaoPerformanceModel
  valorPadrao?: boolean
  formProps?: FormRenderProps
}

interface ConfiguracoesPerformanceBoxProps {
  qtdRequisicoes: number
}

export const ConfiguracoesPerformanceBox = (props: ConfiguracoesPerformanceBoxProps) => {
  const { qtdRequisicoes } = props
  return (
    <Box>
      <BoxGroup>
        <ConfiguracoesPerformanceForm qtdRequisicoes={qtdRequisicoes} />
      </BoxGroup>
    </Box>
  )
}
