import { Omit } from 'bold-ui'
import { TipoAcesso } from 'graphql/types.generated'
import React from 'react'
import { tipoAcesso } from 'types/enums'

import { SelectField, SelectFieldProps } from '../../final-form/SelectField'

export interface TipoAcessoSelectFieldProps extends Omit<SelectFieldProps<TipoAcesso>, 'itemToString'> {}

export function TipoAcessoSelectField(props: TipoAcessoSelectFieldProps) {
  const itemToString = (item: TipoAcesso) => tipoAcesso[item]

  return <SelectField<TipoAcesso> itemToString={itemToString} {...props} />
}
