import { Button, Icon, Tooltip } from 'bold-ui'
import React, { Fragment } from 'react'

import { SubModalVacinacaoOptions } from '../VacinacaoModal'

export interface VacinacaoVisualizacaoButtons {
  idUnicoRegistro: ID
  idUnicoRegistroAprazamentoProxDose?: ID
  idUnicoRegistroAplicacaoProxDose?: ID
  tipoModal: SubModalVacinacaoOptions
  handleOnDelete(
    idUnicoRegistro: ID,
    tipoModal: SubModalVacinacaoOptions,
    idUnicoRegistroAprazamentoProxDose?: ID,
    idUnicoRegistroAplicacaoProxDose?: ID
  ): void
  handleOnEdit(idUnicoRegistro: ID, tipoModal: SubModalVacinacaoOptions): void
}

export const VacinacaoVisualizacaoButtons = (props: VacinacaoVisualizacaoButtons) => {
  const {
    idUnicoRegistro,
    idUnicoRegistroAprazamentoProxDose,
    tipoModal,
    handleOnDelete,
    handleOnEdit,
    idUnicoRegistroAplicacaoProxDose,
  } = props

  return (
    <Fragment>
      <Tooltip text='Editar'>
        <Button
          kind='normal'
          onClick={(e) => {
            e.stopPropagation()
            handleOnEdit(idUnicoRegistro, tipoModal)
          }}
          size='small'
          skin='ghost'
        >
          <Icon icon='penOutline' />
        </Button>
      </Tooltip>
      <Tooltip text='Excluir'>
        <Button
          kind='danger'
          onClick={(e) => {
            e.stopPropagation()
            handleOnDelete(
              idUnicoRegistro,
              tipoModal,
              idUnicoRegistroAprazamentoProxDose,
              idUnicoRegistroAplicacaoProxDose
            )
          }}
          size='small'
          skin='ghost'
        >
          <Icon icon='trashOutline' />
        </Button>
      </Tooltip>
    </Fragment>
  )
}
