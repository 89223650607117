import { DefaultItemType, Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import React from 'react'

export const items: DefaultItemType[] = [
  { value: 0.5 * 60, label: '30 minutos' },
  { value: 60, label: '1 hora (padrão)' },
  { value: 2 * 60, label: '2 horas' },
  { value: 4 * 60, label: '4 horas' },
  { value: 8 * 60, label: '8 horas' },
  { value: 168 * 60, label: 'Uma semana' },
  { value: 720 * 60, label: 'Um mês' },
]

export type IntervaloCadsusSelectModel = DefaultItemType

export function IntervaloCadsusSelectField(props: Omit<SelectFieldProps, 'items' | 'itemToString'>) {
  const itemToString = (item: DefaultItemType) => item && item.label

  return (
    <SelectField
      label='Desabilitar durante'
      items={items}
      itemToString={itemToString}
      clearable={false}
      defaultValue={items[1] as any}
      {...props}
    />
  )
}
