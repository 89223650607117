import useSession from 'components/auth/useSession'

import {
  CboAndPermissionAuthorization,
  isCboAndPermissionAuthorization,
  PermissionAuthorization,
} from './model-authorization'

export const useCheckPermissionAndCboAuthorization = () => {
  const { checkAuthorization } = useSession()

  return (cboAndResource: PermissionAuthorization | CboAndPermissionAuthorization) => {
    if (isCboAndPermissionAuthorization(cboAndResource))
      return (
        (!cboAndResource.hasAnyCbo || cboAndResource.hasAnyCbo.some(checkAuthorization)) &&
        (!cboAndResource.hasAnyPermission || cboAndResource.hasAnyPermission.some(checkAuthorization))
      )
    else return checkAuthorization(cboAndResource.permission)
  }
}
