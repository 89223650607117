import { HFlow, Tabs, Tag } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PecSwitch, PrivateRoute, TabLink } from 'components/route'
import { useUnidadeSaudeDetailQuery } from 'graphql/hooks.generated'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useRouter } from 'hooks/useRouter'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import Permissions from 'types/Permissions'
import { titleCase } from 'util/strConversor'
import { HorusListView } from 'view/horus/HorusListView'
import { ProfissionalTable } from 'view/profissional/list/ProfissionalTable'
import TipoServicoTable from 'view/unidade-saude/tipo-servico/list/TipoServicoTable'

import { EquipeListView } from '../../equipe/list/EquipeListView'
import { UnidadeSaudeInformacoesView } from './UnidadeSaudeInformacoesView'

export interface UnidadeSaudeDetailViewProps {
  unidadeSaudeId: ID
}

export function UnidadeSaudeDetailView(props: UnidadeSaudeDetailViewProps) {
  const { unidadeSaudeId } = props
  const { match } = useRouter()
  const { hasAuthorization } = useSession()
  const [isCEO, setIsCEO] = useState(false)

  const {
    data: { unidadeSaude },
  } = useUnidadeSaudeDetailQuery({
    variables: { id: unidadeSaudeId },
  })

  const renderInformacoes = () => <UnidadeSaudeInformacoesView unidadeSaude={unidadeSaude} />

  const renderEquipes = () => <EquipeListView unidadeSaudeId={unidadeSaude.id} />

  const renderProfissionais = () => {
    return <ProfissionalTable loadOnMount initialFilter={{ unidadeSaudeId }} />
  }

  const renderHorus = () => <HorusListView unidadeSaudeId={unidadeSaude.id} />

  const renderTiposServico = () => {
    return <TipoServicoTable unidadeSaudeId={unidadeSaudeId} />
  }

  useEffect(() => {
    setIsCEO(unidadeSaude?.tipoEstabelecimento === TipoEstabelecimentoEnum.CEO)
  }, [unidadeSaude])

  if (!unidadeSaude) {
    return null
  }

  return (
    <>
      <Breadcrumb title='Visualização' />

      <PageHeaderSection
        icon='hospital'
        title={
          unidadeSaude && (
            <HFlow alignItems='center'>
              <h1>{titleCase(unidadeSaude.nome)}</h1>
              {!unidadeSaude.ativo && <Tag style={{ fontSize: '0.75rem' }}>Inativa</Tag>}
            </HFlow>
          )
        }
        subtitle={unidadeSaude && 'CNES ' + unidadeSaude.cnes}
      >
        <Tabs>
          <TabLink to={`${match.url}/informacoes`}>Informações</TabLink>
          <TabLink to={`${match.url}/tiposServicos`}>Tipos de serviço</TabLink>
          {!isCEO && <TabLink to={`${match.url}/equipes`}>Equipes</TabLink>}
          <TabLink to={`${match.url}/profissionais`}>Profissionais</TabLink>
          <CheckPermission permission={Permissions.visualizarUnidadesDeSaude.configurarConsultaNoHorus}>
            <TabLink to={`${match.url}/horus`}>Hórus</TabLink>
          </CheckPermission>
        </Tabs>
      </PageHeaderSection>

      <PageContent>
        <PecSwitch>
          {hasAuthorization(Permissions.visualizarUnidadesDeSaude) && (
            <Redirect exact path={`${match.url}`} to={`${match.url}/informacoes`} />
          )}
          <PrivateRoute
            exact
            path={`${match.url}/informacoes`}
            render={renderInformacoes}
            permission={Permissions.visualizarUnidadesDeSaude}
          />
          <PrivateRoute
            exact
            path={`${match.url}/tiposServicos`}
            render={renderTiposServico}
            permission={Permissions.visualizarUnidadesDeSaude}
          />
          {!isCEO && (
            <PrivateRoute
              exact
              path={`${match.url}/equipes`}
              render={renderEquipes}
              permission={Permissions.visualizarUnidadesDeSaude}
            />
          )}
          <PrivateRoute
            exact
            path={`${match.url}/profissionais`}
            render={renderProfissionais}
            permission={Permissions.visualizarUnidadesDeSaude}
          />
          <PrivateRoute
            exact
            path={`${match.url}/horus`}
            render={renderHorus}
            permission={Permissions.visualizarUnidadesDeSaude.configurarConsultaNoHorus}
          />
        </PecSwitch>
      </PageContent>
    </>
  )
}
