/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { ExternalStyles, focusBoxShadow, FormControl, FormControlProps, HFlow, Theme } from 'bold-ui'
import { InputWrapper } from 'bold-ui/lib/components/TextField/InputWrapper'
import { useFormControl } from 'bold-ui/lib/hooks'
import { UseFieldProps, usePecField } from 'components/form/final-form/hooks/useField'
import theme from 'config/theme'
import { isObjectDeepEmpty } from 'util/object'

export interface ComposedFormControlProps<TFieldModel> extends FormControlProps, UseFieldProps<TFieldModel> {
  clearable: boolean
  style?: ExternalStyles
}

export function ComposedFormControl<TFieldModel>(props: ComposedFormControlProps<TFieldModel>) {
  const { children, input, meta, clearable, style, ...rest } = usePecField(props)

  const { getFormControlProps, getInputProps } = useFormControl(props)
  const inputProps = getInputProps()

  const handleClear = () => input.onChange(undefined)

  const clearVisible = clearable && !isObjectDeepEmpty(input?.value)

  return (
    <FormControl {...getFormControlProps()} error={meta.error} {...inputProps} {...rest}>
      <InputWrapper clearVisible={clearVisible} onClear={handleClear}>
        <FieldWrapper invalid={!!meta.error} clearVisible={clearVisible} css={style}>
          <HFlow alignItems='baseline' hSpacing={0.5}>
            {children}
          </HFlow>
        </FieldWrapper>
      </InputWrapper>
    </FormControl>
  )
}

ComposedFormControl.defaultProps = {
  clearable: false,
} as ComposedFormControlProps<any>

const FieldWrapper = styled.div<{ invalid: boolean; clearVisible: boolean }, Theme>`
  background-color: ${theme.pallete.surface.main};
  border: 1px solid ${({ invalid }) => (invalid ? theme.pallete.status.danger.main : theme.pallete.gray.c70)};
  border-radius: ${theme.radius.input}px;
  cursor: default;
  display: inline-block;
  transition: box-shadow 0.2s ease;
  padding: 0 0.5rem;
  padding-right: ${({ clearVisible }) => (clearVisible ? '2.5rem' : '0.5rem')};
  &:focus-within {
    box-shadow: ${focusBoxShadow(theme)};
    outline: none;
  }
  &:hover {
    border-color: ${theme.pallete.gray.c60};
  }
  ${({ invalid }) =>
    invalid &&
    `&:focus-within {
      :not(:disabled) {
        border: solid 1px ${theme.pallete.gray.c80};
        box-shadow: ${focusBoxShadow(theme, 'danger')};
      }
    }`}

  input,
  select,
  textarea {
    border-width: 0;
    padding-left: 0;
    padding-right: 0;
    &:focus {
      box-shadow: none !important;
    }
  }

  span {
    line-height: 2.4;
  }
`
