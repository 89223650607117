import { HeadingSection, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { useTransmissaoRecebimentoCnesDetailItemsQuery } from 'graphql/hooks.generated'
import { capitalize } from 'lodash'
import moment from 'moment'
import React from 'react'
import { useParams } from 'react-router'

import { TransmissaoRecebimentoCnesDetailTable } from './TransmissaoRecebimentoCnesDetailTable'

interface UrlParams {
  numCnes: string
  mesRecebimento: string
  anoRecebimento: string
}

export function TransmissaoRecebimentoCnesDetailView() {
  const params = useParams<UrlParams>()
  const { numCnes, mesRecebimento, anoRecebimento } = params

  const { data } = useTransmissaoRecebimentoCnesDetailItemsQuery({
    variables: {
      input: {
        numCnes,
        mesRecebimento: Number(params.mesRecebimento),
        anoRecebimento: Number(params.anoRecebimento),
      },
    },
  })

  if (!data || !data.transmissaoRecebimentoCnesDetailItems) {
    return null
  }

  return (
    <>
      <Breadcrumb title='Recebimento' to='/transmissao/recebimento/cnes' />
      <Breadcrumb title='Recebimento por CNES' />
      <PageContent type='filled'>
        <VFlow vSpacing={1}>
          <PageHeader
            title={
              data.transmissaoRecebimentoCnesDetailItems[0] &&
              data.transmissaoRecebimentoCnesDetailItems[0].nomeEstabelecimento &&
              data.transmissaoRecebimentoCnesDetailItems[0].nomeEstabelecimento !== ''
                ? data.transmissaoRecebimentoCnesDetailItems[0].nomeEstabelecimento
                : 'Nome desconhecido'
            }
            style={{ marginBottom: '2rem', marginTop: '1rem' }}
          />
          <HeadingSection level={4} title='CNES' vSpace={1} style={{ lineHeight: '1.3', marginBottom: '1rem' }}>
            {numCnes}
          </HeadingSection>
          <HeadingSection
            level={4}
            title='Mês de recebimento'
            vSpace={1}
            style={{ lineHeight: '1.3', marginBottom: '2rem' }}
          >
            {capitalize(moment(mesRecebimento + '/' + anoRecebimento, 'MM/YYYY').format('MMMM [de] YYYY'))}
          </HeadingSection>
          <HeadingSection level={2} title='Fichas'>
            <TransmissaoRecebimentoCnesDetailTable itens={data.transmissaoRecebimentoCnesDetailItems} />
          </HeadingSection>
        </VFlow>
      </PageContent>
    </>
  )
}
