import { DateRange } from 'bold-ui'
import { AgendamentoFragment, SituacaoAgendadoEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

import { LotacaoAgendaModel, ProfissionalAnfitriaoModel, ProfissionalParticipanteModel } from '../model-agenda'

export interface ResponsavelAgendamentoModel
  extends Pick<LotacaoAgendaModel, 'id' | 'ativo' | 'profissional' | 'cbo' | 'equipe'> {}

export interface JustificativaCancelamentoAgendadoModel {
  id: ID
  justificativa: string
  data: number
  responsavel?: ResponsavelAgendamentoModel
}

export interface AgendamentoCidadaoModel extends AgendamentoFragment {
  lotacaoAgendada: LotacaoAgendadaModel
  profissionalAnfitriao?: ProfissionalAnfitriaoModel
  profissionalParticipante?: ProfissionalParticipanteModel
  responsavelCriador?: ResponsavelAgendamentoModel
  justificativaCancelamento?: JustificativaCancelamentoAgendadoModel
}

export interface LotacaoAgendadaModel extends LotacaoAgendaModel {
  unidadeSaude: {
    id: ID
    nome?: string
  }
}

export interface AgendamentosCidadaoFilterPopperModel {
  periodo: DateRange
}

export interface AgendamentosCidadaoPartialFilterModel {
  nomeProfissional?: string
  isSomenteMeus?: boolean
  isAgendamentosAnteriores?: boolean
}

export type AgendamentosCidadaoFilterModel = AgendamentosCidadaoPartialFilterModel &
  AgendamentosCidadaoFilterPopperModel

export const situacoesExibirAgendamentosCidadao: SituacaoAgendadoEnum[] = [
  SituacaoAgendadoEnum.AGENDADO,
  SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO,
  SituacaoAgendadoEnum.CANCELADO,
  SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE,
  SituacaoAgendadoEnum.NAO_AGUARDOU,
  SituacaoAgendadoEnum.NAO_COMPARECEU,
]

export interface AgendamentoCidadaoPermissions {
  podeCancelarTodasEquipes: boolean
  podeCancelarAgendamento: boolean
}

export const metaAgendamentoCidadaoFilter = metaPath<AgendamentosCidadaoFilterModel>()
