import { Calculation } from 'final-form-calculate'

import { AgendamentosCidadaoFilterModel, metaAgendamentoCidadaoFilter } from '../model'

export const calculatorFiltroAgenda = (today: Date): Calculation[] => [
  {
    field: metaAgendamentoCidadaoFilter.isAgendamentosAnteriores.absolutePath(),
    updates: {
      [metaAgendamentoCidadaoFilter.periodo.absolutePath()]: (
        agendamentosAnteriores: Boolean,
        allValues: AgendamentosCidadaoFilterModel,
        prevValues: AgendamentosCidadaoFilterModel
      ) => {
        if (prevValues.isAgendamentosAnteriores && !agendamentosAnteriores && prevValues?.periodo?.startDate === null) {
          return { startDate: today, endDate: null }
        } else if (!prevValues.isAgendamentosAnteriores && agendamentosAnteriores) {
          return { startDate: null, endDate: allValues?.periodo?.endDate }
        }
        return allValues?.periodo
      },
    },
  },
  {
    field: metaAgendamentoCidadaoFilter.periodo.startDate.absolutePath(),
    updates: {
      [metaAgendamentoCidadaoFilter.isAgendamentosAnteriores.absolutePath()]: (
        dataInicio: Date,
        allValues: AgendamentosCidadaoFilterModel,
        prevValues: AgendamentosCidadaoFilterModel
      ) => {
        if (allValues?.isAgendamentosAnteriores && prevValues?.periodo?.startDate === null && dataInicio !== null) {
          return false
        }
        return allValues.isAgendamentosAnteriores
      },
    },
  },
]
