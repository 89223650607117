import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'

export interface StatusCadastroSelectModel {
  isCadastroCompleto: boolean
  nome: string
}

interface StatusCadastroSelectFieldProps
  extends Omit<SelectFieldProps<StatusCadastroSelectModel>, 'items' | 'itemToString'> {}

export function StatusCadastroSelectField(props: StatusCadastroSelectFieldProps) {
  const itemToString = (item: StatusCadastroSelectModel) => item && item.nome

  return <SelectField<StatusCadastroSelectModel> items={items} itemToString={itemToString} {...props} />
}

export const COMPLETO: StatusCadastroSelectModel = {
  isCadastroCompleto: true,
  nome: 'Completo',
}
export const INCOMPLETO: StatusCadastroSelectModel = {
  isCadastroCompleto: false,
  nome: 'Incompleto',
}

const items: StatusCadastroSelectModel[] = [COMPLETO, INCOMPLETO]
