import { useCallback } from 'react'

import {
  CboAndPermissionAuthorization,
  hasPermissionByTipoEstabelecimento,
  PermissionAuthorization,
  TipoEstabelecimentoAuthorization,
} from './model-authorization'
import { useCheckPermissionAndCboAuthorization } from './useCheckModuleAuthorization'
import useSession from './useSession'

type CheckPermissionProps = ((PermissionAuthorization | CboAndPermissionAuthorization) &
  TipoEstabelecimentoAuthorization) & {
  children: any
  acceptEstagio?: boolean
  onlyAtencaoPrimaria?: boolean
}

const CheckPermission = (props: CheckPermissionProps) => {
  const { acceptEstagio = true, onlyAtencaoPrimaria, children } = props
  const { isEstagio, isEstabelecimentoAtencaoPrimaria, hasTipoEstabelecimentoAuth } = useSession({
    fetchPolicy: 'cache-only',
  })
  const checkPermissionAndCboAuthorization = useCheckPermissionAndCboAuthorization()

  const hasAuthorization = useCallback(
    (props: CheckPermissionProps) => {
      if (!acceptEstagio && isEstagio) {
        return false
      } else if (hasPermissionByTipoEstabelecimento(props) && !hasTipoEstabelecimentoAuth(props.tiposEstabelecimento)) {
        return false
      } else if (onlyAtencaoPrimaria && !isEstabelecimentoAtencaoPrimaria) {
        return false
      } else {
        return checkPermissionAndCboAuthorization(props as PermissionAuthorization | CboAndPermissionAuthorization)
      }
    },
    [
      acceptEstagio,
      checkPermissionAndCboAuthorization,
      hasTipoEstabelecimentoAuth,
      isEstagio,
      onlyAtencaoPrimaria,
      isEstabelecimentoAtencaoPrimaria,
    ]
  )

  return hasAuthorization(props) ? children : null
}

export default CheckPermission
