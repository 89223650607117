import React from 'react'
import { TipoAtendimentoFooter } from 'view/atendimentos/model'

import { AtendimentoFooter } from '../AtendimentoFooter'
import { CidadaoDetailContent } from './CidadaoDetailContent'

interface CidadaoDetailViewProps {
  atendimentoId: ID
  cidadaoId: ID
  tipoAtendimentoProfissional?: TipoAtendimentoFooter
  isRegistroTardio?: boolean
  clearCache(updateState?: boolean): void
  callbackUrl?: string
  isAtendimentoObservacao?: boolean
  isObservacaoAndResponsavel?: boolean
}

export function CidadaoDetailView(props: CidadaoDetailViewProps) {
  const {
    atendimentoId,
    callbackUrl,
    cidadaoId,
    clearCache,
    tipoAtendimentoProfissional,
    isRegistroTardio = false,
    isAtendimentoObservacao = false,
    isObservacaoAndResponsavel = false,
  } = props

  return (
    <>
      <CidadaoDetailContent atendimentoId={atendimentoId} cidadaoId={cidadaoId} callbackUrl={callbackUrl} />
      <AtendimentoFooter
        atendimentoId={atendimentoId}
        tipoAtendimentoProfissional={tipoAtendimentoProfissional}
        clearCache={clearCache}
        isRegistroTardio={isRegistroTardio}
        isAtendimentoObservacao={isAtendimentoObservacao}
        isObservacaoAndResponsavel={isObservacaoAndResponsavel}
      />
    </>
  )
}
