/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { Text } from 'bold-ui'
import { green, orange, red } from 'bold-ui/lib/styles/colors'
import { IdadeFieldModel } from 'components/form'
import { StatusAvaliadoEnum } from 'graphql/types.generated'

import { alcancadoComToString } from '../util/convertToLabel'

interface StatusAvaliacaoAcompanhamentoTagProps {
  status: StatusAvaliadoEnum
  alcancadoCom: IdadeFieldModel
}

export default function StatusAvaliacaoAcompanhamentoTag(props: StatusAvaliacaoAcompanhamentoTagProps) {
  const { status, alcancadoCom } = props
  const classes = createTypeStyles()[status]

  return (
    <Text fontWeight='bold' style={classes.style}>
      {classes.title}
      {status === StatusAvaliadoEnum.PRESENTE_COM_ATRASO && (
        <Text fontWeight='normal' style={classes.style}>
          {alcancadoComToString(alcancadoCom)}
        </Text>
      )}
    </Text>
  )
}

const createTypeStyles = (): Record<StatusAvaliadoEnum, { title: string; style: SerializedStyles }> => ({
  PRESENTE: {
    title: 'Presente',
    style: css`
      color: ${green.c40};
    `,
  },
  PRESENTE_COM_ATRASO: {
    title: 'Presente com atraso',
    style: css`
      color: ${orange.c40};
    `,
  },
  AUSENTE: {
    title: 'Ausente',
    style: css`
      color: ${red.c40};
    `,
  },
  NAO_AVALIADO: {
    title: 'Não avaliado',
    style: css`
      font-weight: normal;
    `,
  },
})
