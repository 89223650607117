/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, Text, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { ReactComponent as LostConnectionImage } from 'images/videochamada/videochamada-perda-conexao.svg'

export function VideochamadaCanceladaView() {
  return (
    <PageContent type='filled' style={styles.container}>
      <Grid alignItems='center' justifyContent='space-between'>
        <Cell size={5}>
          <VFlow vSpacing={1}>
            <Heading level={1}>Esta chamada foi cancelada</Heading>
            <Text>
              O agendamento relacionado a esta Videochamada e-SUS APS foi cancelado pelo responsável, crie ou solicite
              um novo agendamento.
            </Text>
          </VFlow>
        </Cell>
        <Cell>
          <LostConnectionImage title='Tomada desconectada, representando que a chamada de vídeo foi interrompida.' />
        </Cell>
      </Grid>
    </PageContent>
  )
}

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1024px;
  `,
}
