import { Theme, useStyles } from 'bold-ui'
import { Interpolation } from 'emotion'
import React from 'react'
import { CSSProperties } from 'react'

export interface SelectedItemContainerProps {
  children?: React.ReactNode
  style?: Interpolation
}

export default function SelectedItemContainer(props: SelectedItemContainerProps) {
  const { children, style } = props
  const { classes, css } = useStyles(createStyles)

  return <div className={css(classes.list, style)}>{children}</div>
}

const createStyles = (theme: Theme) => ({
  list: {
    backgroundColor: theme.pallete.surface.main,
    border: `1px solid ${theme.pallete.divider}`,
    padding: '1rem',
    textAlign: 'left',
  } as CSSProperties,
})
