import { Grid } from 'bold-ui'
import { FilterItem } from 'components/filteritem'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { VisualizacaoMicroareasFilterModel } from 'view/visualizacaoTerritorial/model'

interface MicroareasFilterTagsProps {
  microareasFilter: VisualizacaoMicroareasFilterModel
}

export function MicroareasFilterTags(props: MicroareasFilterTagsProps) {
  const { microareasFilter } = props

  const isCadastroAtualizadoMenosDeUmAno = !isUndefinedOrNull(microareasFilter?.isCadastroAtualizadoMenosDeUmAno) && (
    <FilterItem
      label='Atualizado há:'
      value={microareasFilter.isCadastroAtualizadoMenosDeUmAno ? 'Menos de um ano' : 'Um ano ou mais'}
    />
  )

  const isCadastroCompleto = !isUndefinedOrNull(microareasFilter?.isCadastroCompleto) && (
    <FilterItem label='Status do cadastro:' value={microareasFilter.isCadastroCompleto ? 'Completo' : 'Incompleto'} />
  )

  const isImovelVisitadoMenosDeUmMes = !isUndefinedOrNull(microareasFilter?.isImovelVisitadoMenosDeUmMes) && (
    <FilterItem
      label='Última visita há:'
      value={microareasFilter.isImovelVisitadoMenosDeUmMes ? 'Menos de um mês' : 'Um mês ou mais'}
    />
  )

  return (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {isCadastroAtualizadoMenosDeUmAno}
      {isImovelVisitadoMenosDeUmMes}
      {isCadastroCompleto}
    </Grid>
  )
}
