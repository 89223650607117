import { ParteBucalEnum } from 'graphql/types.generated'
import { compact } from 'lodash'
import { formatNumber } from 'util/number'

import {
  getPeriogramaCompletoDenteFaces,
  PeriogramaCompletoCondicao,
  PeriogramaCompletoDenteValuesModel,
  PeriogramaCompletoFace,
  PeriogramaCompletoMultipleBoolean,
  PeriogramaCompletoTableInputValue,
  PeriogramaCompletoTableModel,
} from './model'

export type PipsModel = Partial<Record<ParteBucalEnum, Record<PeriogramaCompletoFace, string>>>

export interface PorcentagensModel {
  porcentagemPlaca: string
  porcentagemSang: string
}

export interface AllInfosPeriogramaCompleto extends PorcentagensModel {
  pips: PipsModel
}

export const calculaInfosPeriogramaCompleto = (
  values: PeriogramaCompletoTableModel,
  errors: PeriogramaCompletoTableModel = undefined
): AllInfosPeriogramaCompleto => {
  if (values) {
    let denominador = 192 // qtd dentes x qtd de sitios por dente (32 * 6)
    let numeradorPlaca = 0
    let numeradorSang = 0

    const todosPIPs = {}

    Object.entries(values).forEach(([dente, entries]: [ParteBucalEnum, PeriogramaCompletoDenteValuesModel]) => {
      if (entries.ausente) {
        denominador -= 6
      } else {
        const faces = getPeriogramaCompletoDenteFaces(dente)

        todosPIPs[dente] = {
          [faces.VESTIBULAR]: calculaPIPByDente(
            entries?.values?.VESTIBULAR?.PROF_SONDAGEM as string,
            entries?.values?.VESTIBULAR?.RECESSAO as string,
            errors?.[dente]?.values.VESTIBULAR
          ),
          [faces.INTERNA]: calculaPIPByDente(
            entries?.values?.[faces.INTERNA]?.PROF_SONDAGEM as string,
            entries?.values?.[faces.INTERNA]?.RECESSAO as string,
            errors?.[dente]?.values[faces.INTERNA]
          ),
        }

        const placaPorDenteVestibular = entries?.values?.VESTIBULAR?.PLACA as PeriogramaCompletoMultipleBoolean
        const placaPorDenteFaceInterna = entries?.values?.[faces.INTERNA]?.PLACA as PeriogramaCompletoMultipleBoolean
        const sangramentoPorDenteVestibular = entries?.values?.VESTIBULAR
          ?.SANG_SONDAGEM as PeriogramaCompletoMultipleBoolean
        const sangramentoPorDenteFaceInterna = entries?.values?.[faces.INTERNA]
          ?.SANG_SONDAGEM as PeriogramaCompletoMultipleBoolean

        const qtdSitiosComPlaca =
          (placaPorDenteVestibular ? compact(Object.values(placaPorDenteVestibular)).length : 0) +
          (placaPorDenteFaceInterna ? compact(Object.values(placaPorDenteFaceInterna)).length : 0)
        const qtdSitiosComSangramento =
          (sangramentoPorDenteVestibular ? compact(Object.values(sangramentoPorDenteVestibular)).length : 0) +
          (sangramentoPorDenteFaceInterna ? compact(Object.values(sangramentoPorDenteFaceInterna)).length : 0)

        numeradorPlaca += qtdSitiosComPlaca
        numeradorSang += qtdSitiosComSangramento
      }
    })

    return {
      pips: todosPIPs,
      porcentagemPlaca: calculaPorcentagem(numeradorPlaca, denominador),
      porcentagemSang: calculaPorcentagem(numeradorSang, denominador),
    }
  } else {
    return { pips: undefined, porcentagemPlaca: '0%', porcentagemSang: '0%' }
  }
}

export const calculaPipsPeriogramaCompleto = (
  values: PeriogramaCompletoTableModel,
  errors: PeriogramaCompletoTableModel = undefined
): PipsModel => {
  if (values) {
    const todosPIPs = {}

    Object.entries(values).forEach(([dente, entries]: [ParteBucalEnum, PeriogramaCompletoDenteValuesModel]) => {
      if (!entries.ausente) {
        const faces = getPeriogramaCompletoDenteFaces(dente)

        todosPIPs[dente] = {
          [faces.VESTIBULAR]: calculaPIPByDente(
            entries?.values?.VESTIBULAR?.PROF_SONDAGEM as string,
            entries?.values?.VESTIBULAR?.RECESSAO as string,
            errors?.[dente]?.values.VESTIBULAR
          ),
          [faces.INTERNA]: calculaPIPByDente(
            entries?.values?.[faces.INTERNA]?.PROF_SONDAGEM as string,
            entries?.values?.[faces.INTERNA]?.RECESSAO as string,
            errors?.[dente]?.values[faces.INTERNA]
          ),
        }
      }
    })

    return todosPIPs
  }
}

export const calculaPorcentagensPeriogramaCompleto = (values: PeriogramaCompletoTableModel): PorcentagensModel => {
  if (values) {
    let denominador = 192 // qtd dentes x qtd de sitios por dente (32 * 6)
    let numeradorPlaca = 0
    let numeradorSang = 0

    Object.entries(values).forEach(([dente, entries]: [ParteBucalEnum, PeriogramaCompletoDenteValuesModel]) => {
      if (entries.ausente) {
        denominador -= 6
      } else {
        const faces = getPeriogramaCompletoDenteFaces(dente)

        const placaPorDenteVestibular = entries?.values?.VESTIBULAR?.PLACA as PeriogramaCompletoMultipleBoolean
        const placaPorDenteFaceInterna = entries?.values?.[faces.INTERNA]?.PLACA as PeriogramaCompletoMultipleBoolean
        const sangramentoPorDenteVestibular = entries?.values?.VESTIBULAR
          ?.SANG_SONDAGEM as PeriogramaCompletoMultipleBoolean
        const sangramentoPorDenteFaceInterna = entries?.values?.[faces.INTERNA]
          ?.SANG_SONDAGEM as PeriogramaCompletoMultipleBoolean

        const qtdSitiosComPlaca =
          (placaPorDenteVestibular ? compact(Object.values(placaPorDenteVestibular)).length : 0) +
          (placaPorDenteFaceInterna ? compact(Object.values(placaPorDenteFaceInterna)).length : 0)
        const qtdSitiosComSangramento =
          (sangramentoPorDenteVestibular ? compact(Object.values(sangramentoPorDenteVestibular)).length : 0) +
          (sangramentoPorDenteFaceInterna ? compact(Object.values(sangramentoPorDenteFaceInterna)).length : 0)

        numeradorPlaca += qtdSitiosComPlaca
        numeradorSang += qtdSitiosComSangramento
      }
    })

    return {
      porcentagemPlaca: calculaPorcentagem(numeradorPlaca, denominador),
      porcentagemSang: calculaPorcentagem(numeradorSang, denominador),
    }
  } else {
    return {
      porcentagemPlaca: '0%',
      porcentagemSang: '0%',
    }
  }
}

const calculaPIPByDente = (
  profSondagem: string,
  recessao: string,
  error: Partial<Record<PeriogramaCompletoCondicao, PeriogramaCompletoTableInputValue>> = undefined
) => {
  if (!(error?.PROF_SONDAGEM || error?.RECESSAO) && profSondagem && recessao) {
    const separatedProfSond = profSondagem?.split('/')
    const separatedRecessao = recessao?.split('/')

    const recessaoSitio1 = Number(separatedProfSond[0]) + Number(separatedRecessao[0])
    const recessaoSitio2 = Number(separatedProfSond[1]) + Number(separatedRecessao[1])
    const recessaoSitio3 = Number(separatedProfSond[2]) + Number(separatedRecessao[2])

    if (!isNaN(recessaoSitio1) && !isNaN(recessaoSitio2) && !isNaN(recessaoSitio3)) {
      return `${recessaoSitio1}/${recessaoSitio2}/${recessaoSitio3}`
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

const calculaPorcentagem = (numerador: number, denominador: number) => {
  return denominador > 0 ? `${formatNumber((numerador / denominador) * 100, 0)}%` : '0%'
}
