import { StatusRevisaoAtendimento } from 'graphql/types.generated'

export function computeFinalizarAtendimentoButtonLabel({
  isEstagio,
  statusRevisaoAtendimento,
  tipoAtendimentoText,
  isAtendimentoObservacao = false,
  isFinalizandoObservacao = false,
  isStartingObservacao = false,
}: {
  isEstagio: boolean
  statusRevisaoAtendimento: StatusRevisaoAtendimento
  tipoAtendimentoText: string
  isAtendimentoObservacao?: boolean
  isFinalizandoObservacao?: boolean
  isStartingObservacao?: boolean
}): string {
  const emRevisao = statusRevisaoAtendimento === StatusRevisaoAtendimento.EM_REVISAO
  const textoAcao = isFinalizandoObservacao
    ? 'finalizar observação'
    : isStartingObservacao || isAtendimentoObservacao
    ? 'encaminhar para observação'
    : `finalizar atendimento${emRevisao ? '' : ' ' + tipoAtendimentoText}`

  return isEstagio ? 'Enviar para revisão' : `${emRevisao ? 'aprovar e ' : ''}${textoAcao}`.capitalize()
}
