import { Cbo, Profissional, VisibilidadeLembreteEnum } from 'graphql/types.generated'

export interface LembreteModel {
  /**
   * cache id
   */
  _id?: ID
  /**
   * database id
   */
  id: ID
  historicoLembrete?: LembreteEvolucaoModel[]
  ultimoLembrete?: Pick<LembreteEvolucaoModel, 'id' | 'ativo' | 'descricao' | 'data' | 'lotacao'>
  isRegistradoAgora?: boolean
  profissionalCriador?: Pick<Profissional, 'id'>
}

export interface LembreteEvolucaoModel {
  /**
   * database id
   */
  id: ID
  visibilidadeLembrete: VisibilidadeLembreteEnum
  ativo: boolean
  descricao: string
  data: Instant
  lotacao: Partial<Lotacao>
}

interface Lotacao {
  id: ID
  profissional: Partial<Profissional>
  cbo: Partial<Cbo>
}

export const visibilidadeLembrete: Record<VisibilidadeLembreteEnum, string> = {
  PUBLICO: 'Público',
  SOMENTE_EU: 'Privado',
}

export interface LembreteFilterModel {
  mostrarInativos: boolean
}
