import { VFlow } from 'bold-ui'
import { TipoMedicaoEnum } from 'graphql/types.generated'
import React from 'react'
import { PeriodoGestacaoModel } from 'view/atendimentos/detail/components/modals/types/PeriodoGestacaoModel'
import { CidadaoMedicoes } from 'view/atendimentos/detail/soap/aside/medicoes/model'

import { IconTextCard } from '../../components/IconTextCard'
import { GrupoMedicoesTipoModel, tipoMedicaoRecord } from '../../types/MedicaoModel'
import { MedicoesDescription } from './MedicoesDescription'

interface MedicoesListCardProps {
  medicoes: ReadonlyArray<GrupoMedicoesTipoModel>
  isListMedicaoAnterior?: boolean
  cidadao: CidadaoMedicoes
  gestacoes: ReadonlyArray<PeriodoGestacaoModel>
}

export const MedicoesListCard = (props: MedicoesListCardProps) => {
  const { medicoes, isListMedicaoAnterior, cidadao, gestacoes } = props

  return (
    <VFlow vSpacing={0.5}>
      {medicoes.map((medicao) => {
        const constMedicao = tipoMedicaoRecord[medicao.tipo]
        const isIMC = medicao.tipo === TipoMedicaoEnum.IMC
        return (
          <IconTextCard
            icon={constMedicao.icon}
            iconColor={constMedicao.colorScale}
            label={constMedicao.label}
            key={medicao.tipo}
            value={
              <MedicoesDescription
                values={medicao.medicoes}
                unidadeMedida={constMedicao.unidadeMedida}
                isMedicaoAnterior={isListMedicaoAnterior}
                isIMC={isIMC}
                cidadao={cidadao}
                gestacoes={gestacoes}
              />
            }
            labelValueDirection='column'
            isAlertPresentInLine={isIMC && medicao.medicoes.length === 1}
          />
        )
      })}
    </VFlow>
  )
}
