/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, Grid, Heading, HFlow, Icon, Theme, useTheme, VFlow } from 'bold-ui'
import { formatISO } from 'date-fns'
import { useAcompanhamentoPuericulturaCardQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment, useState } from 'react'

import { AcompanhamentoPuericulturaModal } from '../aside/acompanhamento-puericultura/AcompanhamentoPuericulturaModal'
import { convertAcompanhamentoPuericulturaToModel } from '../aside/acompanhamento-puericultura/convert'

export interface AcompanhamentoPuericulturaPanelProps {
  prontuarioId: ID
}

export const AcompanhamentoPuericulturaPanel = (props: AcompanhamentoPuericulturaPanelProps) => {
  const { prontuarioId } = props
  const theme = useTheme()
  const classes = createStyles(theme)
  const [openModal, setOpenModal] = useState(false)
  const {
    id: atendimentoId,
    cidadao,
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  const { data, loading } = useAcompanhamentoPuericulturaCardQuery({
    variables: { prontuarioId, dataReferencia: formatISO(iniciadoEm, { representation: 'date' }) },
  })

  return (
    <Fragment>
      <Grid style={classes.panel}>
        <Cell size={12}>
          <VFlow vSpacing={0.5}>
            <Heading level={4}>Puericultura</Heading>
            <Alert type='info' inline>
              Informações sobre pré-natal, parto e nascimento podem ser preenchidas até o cidadão completar 19 anos.
              Após essa idade, é possível apenas a visualização.
            </Alert>
            <Button kind='primary' size='small' onClick={() => setOpenModal(true)}>
              <HFlow hSpacing={0.5}>
                <Icon icon='zoomOutline' />
                Acompanhamento da criança
              </HFlow>
            </Button>
          </VFlow>
        </Cell>
      </Grid>
      {!loading && openModal && (
        <AcompanhamentoPuericulturaModal
          closeModal={() => setOpenModal(false)}
          isAntecedentes
          acompanhamentoPuericultura={convertAcompanhamentoPuericulturaToModel(data, cidadao.dataNascimento)}
          prontuarioId={prontuarioId}
          cidadao={cidadao}
          dataAtendimento={iniciadoEm.getTime()}
          atendimentoId={atendimentoId}
        />
      )}
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  panel: css`
    border-bottom: 0.063rem solid ${theme.pallete.gray.c80};
    padding-bottom: 0.5rem;
    margin-top: 0rem;
    margin-bottom: 0.3rem;
  `,
})
