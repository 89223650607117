/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HeadingSection, HFlow } from 'bold-ui'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import { css } from 'emotion'
import { ClassificacaoRiscoEncaminhamentoEnum } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'

import { ClassificacaoRiscoEncaminhamentoButton } from './ClassificacaoRiscoEncaminhamentoButton'

export interface ClassificacaoRiscoEncaminhamentoButtonsProps {
  name: MetaPath<ClassificacaoRiscoEncaminhamentoEnum>
}

export function ClassificacaoRiscoEncaminhamentoGroupButton(props: ClassificacaoRiscoEncaminhamentoButtonsProps) {
  const { name } = props

  return (
    <HeadingSection level={5} vSpace={8} title={<RequiredLabel label='Classificação de risco' />}>
      <HFlow style={classes.container}>
        <HFlow>
          <ClassificacaoRiscoEncaminhamentoButton name={name} value={ClassificacaoRiscoEncaminhamentoEnum.ELETIVO} />
          <ClassificacaoRiscoEncaminhamentoButton
            name={name}
            value={ClassificacaoRiscoEncaminhamentoEnum.PRIORITARIO}
          />
        </HFlow>
        <HFlow>
          <ClassificacaoRiscoEncaminhamentoButton name={name} value={ClassificacaoRiscoEncaminhamentoEnum.URGENCIA} />
          <ClassificacaoRiscoEncaminhamentoButton name={name} value={ClassificacaoRiscoEncaminhamentoEnum.EMERGENCIA} />
        </HFlow>
      </HFlow>
    </HeadingSection>
  )
}

const classes = {
  container: css`
    display: flex;
    flex-flow: row wrap;
  `,
}
