import { Antecedente } from 'graphql/types.generated'

import { FolhaRostoInformacoesObstetricasModel } from './model'

export const convertAntecedenteToFolhaRostoInformacoesObstetricasModel = (
  antecedente: Antecedente
): FolhaRostoInformacoesObstetricasModel => ({
  gestaPrevias: antecedente?.gestacoesPrevias,
  abortos: antecedente?.qtAbortos,
  partoMenosDeUmAno: antecedente?.partoMenosDeUmAno,
  partos: antecedente?.qtPartos,
  partosVaginais: antecedente?.partosVaginais,
  partosCesareas: antecedente?.qtCesareas,
  partosDomiciliares: antecedente?.partosDomiciliares,
  nascidosVivos: antecedente?.nascidosVivos,
  natimortos: antecedente?.natimortos,
  recemNascidoAbaixo: antecedente?.recemNascidosAbaixo,
  recemNascidoAcima: antecedente?.recemNascidosAcima,
  filhosVivos: antecedente?.filhosVivos,
  obitoAntesPrimeiraSemana: antecedente?.obitoAntesPrimeiraSemana,
  obitoAposPrimeiraSemana: antecedente?.obitoAposPrimeiraSemana,
})
