/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FaixaEtariaConsumoAlimentar, MarcadorConsumoAlimentar } from 'graphql/types.generated'

import { HistoricoMCADoisAnosOuMaisTableBox } from './HistoricoMCADoisAnosOuMaisTableBox'
import { HistoricoMCAMenorSeisMesesTableBox } from './HistoricoMCAMenorSeisMesesTableBox'
import { HistoricoMCASeisAVinteETresMesesTableBox } from './HistoricoMCASeisAVinteETresMesesTableBox'

interface HistoricoMCATableBoxProps {
  marcadorConsumoAlimentar: MarcadorConsumoAlimentar
}

const HistoricoMCATableBox = ({ marcadorConsumoAlimentar }: HistoricoMCATableBoxProps) => (
  <div>{getTableBox(marcadorConsumoAlimentar)}</div>
)

const getTableBox = (marcadorConsumoAlimentar: MarcadorConsumoAlimentar) => {
  switch (marcadorConsumoAlimentar.categoria) {
    case FaixaEtariaConsumoAlimentar.MENOR_SEIS_MESES:
      return <HistoricoMCAMenorSeisMesesTableBox marcadorConsumoAlimentar={marcadorConsumoAlimentar} />
    case FaixaEtariaConsumoAlimentar.MAIOR_SEIS_MESES_MENOR_DOIS_ANOS:
      return <HistoricoMCASeisAVinteETresMesesTableBox marcadorConsumoAlimentar={marcadorConsumoAlimentar} />
    case FaixaEtariaConsumoAlimentar.MAIOR_DOIS_ANOS:
      return <HistoricoMCADoisAnosOuMaisTableBox marcadorConsumoAlimentar={marcadorConsumoAlimentar} />
  }
}

export default HistoricoMCATableBox
