const plural = (size: number) => ({
  reflexosNeuro: `${size} reflexos neurológicos não foram atingidos nos 15 primeiros dias de vida`,
  reflexosAcompanhamento: `${size} reflexos neurológicos não foram atingidos`,
  reflexosAusenteR3: `${size} reflexos neurológicos não alcançados na faixa etária 15 primeiros dias.`,
  desenvolvimentoAusenteR3: `${size} marcos de desenvolvimento não alcançados`,
  desenvolvimentoPresenteComAtrasoR3: `${size} marcos de desenvolvimento alcançados com atraso na faixa etária`,
  desenvolvimentoAusenteR4: `${size} marcos de desenvolvimento não alcançados na faixa etária `,
  desenvolvimentoComAtrasoR4: `${size} marcos de desenvolvimento alcançados com atraso na faixa etária `,
  desenvolvimentoComAtrasoEAusenteR4: `${size} marcos de desenvolvimento não alcançados e `,
  desenvolvimentoNaoAvaliadoR7: `Para a definição do status de desenvolvimento, avalie os ${size} marcos de desenvolvimento restantes da faixa `,
  fatorPresente: `Desenvolvimento normal, porém a criança possui ${size} fatores de risco que podem interferir em seu desenvolvimento.`,
})

const singular = {
  reflexosNeuro: '1 reflexo neurológico não foi atingido nos 15 primeiros dias de vida',
  reflexosAcompanhamento: '1 reflexo neurológico não foi atingido',
  reflexosAusenteR3: '1 reflexo neurológico não alcançado na faixa etária 15 primeiros dias.',
  desenvolvimentoAusenteR3: '1 marco de desenvolvimento não alcançado',
  desenvolvimentoPresenteComAtrasoR3: '1 marco de desenvolvimento alcançado com atraso na faixa etária',
  desenvolvimentoAusenteR4: '1 marco de desenvolvimento não alcançado na faixa etária ',
  desenvolvimentoComAtrasoR4: '1 marco de desenvolvimento alcançado com atraso na faixa etária ',
  desenvolvimentoComAtrasoEAusenteR4: '1 marco de desenvolvimento não alcançado e ',
  desenvolvimentoNaoAvaliadoR7:
    'Para a definição do status de desenvolvimento, avalie o marco de desenvolvimento restante da faixa ',
  fatorPresente:
    'Desenvolvimento normal, porém a criança possui 1 fator de risco que pode interferir em seu desenvolvimento.',
}

export const marcoMessages = (sizeList: number) => (sizeList > 1 ? plural(sizeList) : singular)
