import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { isEmpty, isEqual } from 'lodash'
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import { GarantiaAcessoFilterModel } from '../model-garantiaAcesso'
import GarantiaAcessoTableFilterCeoView from './GarantiaAcessoTableFilterCeoView'
import GarantiaAcessoTableFilterView from './GarantiaAcessoTableFilterView'

interface GarantiaAcessoTableFilterProps {
  filter: GarantiaAcessoFilterModel
  setFilter: Dispatch<SetStateAction<GarantiaAcessoFilterModel>>
}

export default function GarantiaAcessoTableFilter(props: GarantiaAcessoTableFilterProps) {
  const { filter, setFilter } = props
  const {
    acesso: {
      unidadeSaude: { tipoEstabelecimento },
    },
  } = useAcessoLotacaoOrEstagio()
  const isCEO = tipoEstabelecimento === TipoEstabelecimentoEnum.CEO

  const shouldSetFilter = useCallback(
    (modifiedFilter: GarantiaAcessoFilterModel): boolean => {
      const onlyNomeCpfCnsHasChanged = isEqual(
        {
          ...filter,
          lotacoes: modifiedFilter.lotacoes,
          equipes: modifiedFilter.equipes,
          tiposAtendimento: modifiedFilter.tiposAtendimento,
          tiposServico: modifiedFilter.tiposServico,
          origem: modifiedFilter.origem,
          ordenacao: modifiedFilter.ordenacao,
          periodo: modifiedFilter.periodo,
          pageParams: modifiedFilter.pageParams,
        },
        filter
      )

      const isNomeCpfCnsInvalid =
        isEmpty(modifiedFilter.nomeCpfCns?.trim()) ||
        (modifiedFilter.nomeCpfCns?.trim().length < 3 && modifiedFilter.nomeCpfCns?.trim().length !== 0)

      return !(onlyNomeCpfCnsHasChanged && isNomeCpfCnsInvalid) || isUndefinedOrNull(modifiedFilter.nomeCpfCns)
    },
    [filter]
  )

  const onChangeFilter = useCallback(
    (filter: GarantiaAcessoFilterModel) => {
      shouldSetFilter(filter) &&
        setFilter((prevState) => ({
          ...prevState,
          nomeCpfCns: filter.nomeCpfCns || '',
          lotacoes: filter.lotacoes,
          equipes: filter.equipes,
          tiposAtendimento: filter.tiposAtendimento,
          tiposServico: filter.tiposServico,
          origem: filter.origem,
          ordenacao: filter.ordenacao,
          periodo: filter.periodo,
        }))
    },
    [setFilter, shouldSetFilter]
  )

  return isCEO ? (
    <GarantiaAcessoTableFilterCeoView filter={filter} onChange={onChangeFilter} />
  ) : (
    <GarantiaAcessoTableFilterView filter={filter} onChange={onChangeFilter} />
  )
}
