import useSession from 'components/auth/useSession'
import { endOfDay, startOfDay, subDays } from 'date-fns'
import { OrdemListaAtendimentoEnum, StatusAtendimento } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import Permissions from 'types/Permissions'
import { AtendimentosFilterModel } from 'view/atendimentos/list/filter/model'

import { ListaRegistroTardioFilterModel } from './ListaRegistroTardioFilter'

export interface AtendimentosRegistroTardioFilterModel extends Omit<AtendimentosFilterModel, 'filter'> {
  filter: ListaRegistroTardioFilterModel
}

export const REGISTRO_TARDIO_DEFAULT_DATE_DIFF = 7

export function useListaRegistroTardioFilterDefault(): AtendimentosRegistroTardioFilterModel {
  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow().getTime()

  const { hasAuthorization } = useSession()
  const lotacaoPodeAtender = hasAuthorization(Permissions.visualizarListaDeAtendimento.registrarAtendimento)
  return {
    filter: {
      periodo: {
        startDate: startOfDay(subDays(serverTime, REGISTRO_TARDIO_DEFAULT_DATE_DIFF)),
        endDate: endOfDay(serverTime),
      },
      ordem: OrdemListaAtendimentoEnum.ORDEM_CHEGADA_DECRESCENTE,
      statusAtendimento: [StatusAtendimento.AGUARDANDO_ATENDIMENTO, StatusAtendimento.EM_ATENDIMENTO],
      responsaveis: [],
      somenteMeusAtendimentos: lotacaoPodeAtender,
    },
    lastUpdate: serverTime,
    lastDailyUpdate: serverTime,
    pageParams: {},
  }
}
