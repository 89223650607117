import { MetaPath } from 'util/metaPath'

export interface TextFilterType {
  /**
   * Meta do campo que possui o dado pelo qual será feito a busca
   * @see metaPath.ts
   */
  filterField: MetaPath<string>
  /**
   * Lista com o Meta dos campos que serão utilizados para a realização da busca por texto
   * @see metaPath.ts
   */
  searchFields: MetaPath<string>[]
  removeTagsOnFilter?: boolean
}

export function isTextFilterType(value: FilterType): value is TextFilterType {
  return (value as TextFilterType).searchFields !== undefined
}

export type FilterType = TextFilterType

export type CustomFilterType<T> = (value: T) => boolean
