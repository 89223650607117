import { FechamentoAgendaInput } from 'graphql/types.generated'

import { FechamentoFormModel } from './model'

export default (model: FechamentoFormModel, idLotacao: ID): FechamentoAgendaInput =>
  model && {
    idLotacao: idLotacao,
    periodo: model.periodo,
    motivo: model.motivo,
    especifique: model.especifique,
  }
