/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Icon, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import theme from 'config/theme'
import { FormApi } from 'final-form'
import { useCallback, useState } from 'react'
import { emptyArray } from 'util/array'
import { MetaPath } from 'util/metaPath'
import { CiapCidPreNatal } from 'view/atendimentos/atendimento-individual/model'
import { Problema } from 'view/atendimentos/detail/soap/aside/types/ProblemaModel'
import { ProblemaCondicaoModel } from 'view/atendimentos/detail/soap/avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import {
  ProblemasCondicoesForm,
  ProblemasCondicoesFormProps,
} from 'view/atendimentos/detail/soap/avaliacao/components/problemas-condicoes/ProblemasCondicoesForm'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { useEditableListField } from '../../../EditableList'
import { grupoCboListaProblemasCondicoes } from '../acessos'
import { AccordionProblemasLPC } from './AccordionProblemasLPC'

interface ProblemasLPCFieldProps
  extends Pick<ProblemasCondicoesFormProps, 'problemasCondicoesAvaliados' | 'problemasCondicoesAnteriores'> {
  name: MetaPath<ProblemaCondicaoModel[]>
  prontuarioId: ID
  dataAtendimento: Instant
  fieldValuesConvertido: Problema[]
  problemasCidadao: Problema[]
  ciapCidPreNatal: CiapCidPreNatal
  somenteCiap: boolean
  cidadao: CidadaoAtendimento
  isAntecedentes: boolean
}

export const ProblemasLPCField = (props: ProblemasLPCFieldProps) => {
  const {
    name,
    prontuarioId,
    dataAtendimento,
    fieldValuesConvertido,
    ciapCidPreNatal,
    somenteCiap,
    cidadao,
    problemasCidadao,
    isAntecedentes,
    ...formProps
  } = props

  const [addingMode, setAddingMode] = useState(false)

  const {
    handleRowChanged,
    handleSubmit,
    removeItem,
    input: { value = emptyArray },
  } = useEditableListField({ name })

  const onSubmit = (values: ProblemaCondicaoModel, formApi: FormApi<ProblemaCondicaoModel>) => {
    handleSubmit({ ...values, isRegistradoAgora: true, isAntecedentes }, formApi)
    setAddingMode(false)
  }

  const findFormModel = useCallback(
    (problemaId: ID) => value.find((problema: ProblemaCondicaoModel) => problema._id === problemaId),
    [value]
  )

  return (
    <VFlow>
      <CheckPermission permission={grupoCboListaProblemasCondicoes.adicionar}>
        <VFlow>
          <Button kind='primary' onClick={() => setAddingMode((prevValue) => !prevValue)}>
            <Icon
              icon={addingMode ? 'angleUp' : 'angleDown'}
              style={css`
                margin-right: 0.25rem;
              `}
            />
            {addingMode ? 'Cancelar adição' : 'Adicionar problema/condição anterior'}
          </Button>

          {addingMode && (
            <VFlow>
              <ProblemasCondicoesForm
                isLPC
                isAntecedentes={isAntecedentes}
                prontuarioId={prontuarioId}
                ciapCidPreNatal={ciapCidPreNatal}
                dataAtendimento={dataAtendimento}
                problemasAtivosLatentes={problemasCidadao}
                style={{ dataIdadeField: styles.dataIdadeField }}
                onSubmit={onSubmit}
                {...formProps}
              />
            </VFlow>
          )}
        </VFlow>
      </CheckPermission>

      <AccordionProblemasLPC
        isAntecedentes={isAntecedentes}
        prontuarioId={prontuarioId}
        problemas={fieldValuesConvertido}
        problemasCidadao={problemasCidadao}
        dataAtendimento={dataAtendimento}
        somenteCiap={somenteCiap}
        cidadao={cidadao}
        findFormModel={findFormModel}
        onEdit={handleRowChanged}
        onRemove={removeItem}
        {...formProps}
      />
    </VFlow>
  )
}

const styles = {
  dataIdadeField: {
    hFlow: css`
      width: 100%;
      padding: 0.5rem;
      ${theme.breakpoints.down('lg')} {
        grid-gap: 0.25rem;
      }
    `,
    dateField: css`
      min-width: 8.5rem;
    `,
    idadeField: css`
      min-width: 10.25rem;
    `,
  },
}
