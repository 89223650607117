import { Tabs } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PecSwitch, PrivateRoute, TabLink } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { Redirect } from 'react-router'
import Permissions from 'types/Permissions'

import { TransmissaoConfiguracoesGeralView } from './components/configuracoes/TransmissaoConfiguracoesGeralView'
import { TransmissaoEnvioRoot } from './components/envio/TransmissaoEnvioRoot'
import { TransmissaoRecebimentoGeralView } from './components/recebimento/TransmissaoRecebimentoGeralView'

export function TransmissaoTabRootView() {
  const { match } = useRouter()
  const { hasAuthorization } = useSession()
  return (
    <>
      <PageHeaderSection title='Transmissão de dados'>
        <Tabs>
          {hasAuthorization(Permissions.transmissaoDeDados.visualizarEnvioDeDados) && (
            <TabLink to='/transmissao/envio'>Envio</TabLink>
          )}
          {hasAuthorization(Permissions.transmissaoDeDados.visualizarRecebimentoDeDados) && (
            <TabLink to={`/transmissao/recebimento`}>Recebimento</TabLink>
          )}
          {hasAuthorization(Permissions.transmissaoDeDados.configurarInstalacoesDeEnvio) && (
            <TabLink to={`/transmissao/configuracoes`}>Configurações</TabLink>
          )}
        </Tabs>
      </PageHeaderSection>
      <PageContent>
        <PecSwitch>
          {hasAuthorization(Permissions.transmissaoDeDados.visualizarEnvioDeDados) && (
            <Redirect exact path={`${match.url}`} to={`${match.url}/envio`} />
          )}
          {hasAuthorization(Permissions.transmissaoDeDados.visualizarRecebimentoDeDados) && (
            <Redirect exact path={`${match.url}`} to={`${match.url}/recebimento`} />
          )}
          {hasAuthorization(Permissions.transmissaoDeDados.configurarInstalacoesDeEnvio) && (
            <Redirect exact path={`${match.url}`} to={`${match.url}/configuracoes`} />
          )}

          <PrivateRoute
            exact
            path={`${match.url}/configuracoes`}
            permission={Permissions.transmissaoDeDados.configurarInstalacoesDeEnvio}
            component={TransmissaoConfiguracoesGeralView}
          />
          <PrivateRoute
            path={`${match.url}/envio`}
            permission={Permissions.transmissaoDeDados.visualizarEnvioDeDados}
            component={TransmissaoEnvioRoot}
          />
          <PrivateRoute
            path={`${match.url}/recebimento`}
            permission={Permissions.transmissaoDeDados.visualizarRecebimentoDeDados}
            component={TransmissaoRecebimentoGeralView}
          />
        </PecSwitch>
      </PageContent>
    </>
  )
}
