import { HFlow, Text, VFlow } from 'bold-ui'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import React from 'react'
import { CuidadoCompartilhadoEvolucaoRecordModel } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

const mapSubtitleGarantiaAcesso: Partial<Record<CondutaCuidadoCompartilhadoEnum, string>> = {
  AGENDADO_CONSULTA_CIDADAO_PRESENCIAL: 'Agendamento de consulta presencial',
  AGENDADO_INTERCONSULTA_COM_CIDADAO: 'Agendamento de consulta compartilhada presencial',
  AGENDADO_TELEINTERCONSULTA_COM_CIDADAO: 'Agendamento de consulta compartilhada via videochamada',
}

interface HistoricoCuidadoCompartilhadoGarantiaAcessoProps
  extends Pick<CuidadoCompartilhadoEvolucaoRecordModel, 'descricao' | 'enviarGarantiaAcesso'> {}

export function HistoricoCuidadoCompartilhadoGarantiaAcesso(props: HistoricoCuidadoCompartilhadoGarantiaAcessoProps) {
  const { enviarGarantiaAcesso, descricao } = props

  return (
    <VFlow vSpacing={0.25}>
      <HFlow hSpacing={0.25}>
        <Text fontWeight='bold'>Demanda: </Text>
        <Text>{mapSubtitleGarantiaAcesso[enviarGarantiaAcesso]}</Text>
      </HFlow>
      <HFlow hSpacing={0.25}>
        <Text fontWeight='bold'>Motivo: </Text>
        <Text dangerouslySetInnerHTML={{ __html: descricao }} />
      </HFlow>
    </VFlow>
  )
}
