import { useLayoutEffect, useState } from 'react'

export function useCalculaCabecalhoSideMenu(alturaCabecalhoToMenu: number, alturaCabecalho: number): boolean {
  const [isMinimizado, setIsMinimizado] = useState(false)

  useLayoutEffect(() => {
    const listener = () => {
      const cabecalhoMinimizado =
        window.scrollY >= alturaCabecalhoToMenu &&
        window.document.body.offsetHeight - window.innerHeight >= alturaCabecalho

      if (cabecalhoMinimizado && !isMinimizado) {
        setIsMinimizado(true)
      } else if (!cabecalhoMinimizado && isMinimizado) {
        setIsMinimizado(false)
      }
    }

    window.addEventListener('scroll', listener)

    return () => window.removeEventListener('scroll', listener)
  }, [alturaCabecalhoToMenu, alturaCabecalho, isMinimizado])
  return isMinimizado
}
