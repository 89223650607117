import { addMilliseconds, differenceInMilliseconds } from 'date-fns'
import gql from 'graphql-tag'
import { useQuery } from 'graphql/hooks'
import { noop } from 'lodash'
import React, { useCallback, useContext, useMemo, useState } from 'react'

const CLIENT_TZ_OFFSET = -new Date().getTimezoneOffset()

const SYSTEM_TIME_QUERY = gql`
  query GetServerTime {
    serverTime
    serverTimezoneOffset
  }
`

export interface ServerTimeResult {
  lastUpdate: Date
  timezoneOffset: number
  getServerTimeNow(): Date
  refetch(): void
}

interface ServerTimeContextProps {
  children: React.ReactNode
}

export const ServerTimeContext = React.createContext<ServerTimeResult>({
  lastUpdate: new Date(),
  timezoneOffset: CLIENT_TZ_OFFSET,
  getServerTimeNow: () => new Date(),
  refetch: noop,
})

export function ServerTimeProvider(props: ServerTimeContextProps) {
  const { children } = props

  const [lastServerTimeUpdate, setLastServerTimeUpdate] = useState(new Date())
  const { data, refetch } = useQuery(SYSTEM_TIME_QUERY, { onCompleted: () => setLastServerTimeUpdate(new Date()) })

  const getServerNow = useCallback(
    () =>
      data
        ? addMilliseconds(new Date(data.serverTime), differenceInMilliseconds(new Date(), lastServerTimeUpdate))
        : new Date(),
    [data, lastServerTimeUpdate]
  )

  const value = useMemo(
    () => ({
      refetch: refetch,
      lastUpdate: lastServerTimeUpdate,
      timezoneOffset: data?.serverTimezoneOffset ?? CLIENT_TZ_OFFSET,
      getServerTimeNow: getServerNow,
    }),
    [data, getServerNow, lastServerTimeUpdate, refetch]
  )

  return <ServerTimeContext.Provider value={value}>{data?.serverTime && children}</ServerTimeContext.Provider>
}

export const useServerTime = () => useContext(ServerTimeContext)
