/** @jsx jsx */
import { jsx } from '@emotion/core'
import { memo } from 'react'
import { HistoricoPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'

import HistoricoVacinacaoDw from './dw/HistoricoVacinacaoDw'
import HistoricoVacinacaoPec from './pec/HistoricoVacinacaoPec'

interface HistoricoVacinacaoPanelProps extends HistoricoPanelProps {}

function HistoricoVacinacaoPanel(props: HistoricoVacinacaoPanelProps) {
  const { isAtendRecente, idAtend, uuidRegistro, cpfOuCnsCidadao, idadeOrigemAtendimento } = props

  const atendimentoPecProcessado: boolean = !isAtendRecente && idAtend

  return isAtendRecente || atendimentoPecProcessado ? (
    <HistoricoVacinacaoPec idAtend={idAtend} idadeOrigemAtendimento={idadeOrigemAtendimento} />
  ) : (
    <HistoricoVacinacaoDw
      uuidRegistro={uuidRegistro}
      cpfOuCnsCidadao={cpfOuCnsCidadao}
      idadeOrigemAtendimento={idadeOrigemAtendimento}
    />
  )
}

export default memo(HistoricoVacinacaoPanel)
