import { HFlow, Icon, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { lotacaoAgendamentoToString } from 'view/agenda/util-agenda'

import { AgendamentoCidadaoModel } from '../../model'

interface AgendamentoCidadaoLotacaoAgendadaProps extends Pick<AgendamentoCidadaoModel, 'lotacaoAgendada'> {}

export function AgendamentoCidadaoLotacaoAgendada(props: AgendamentoCidadaoLotacaoAgendadaProps) {
  const { lotacaoAgendada } = props

  return (
    <VFlow vSpacing={0.5}>
      <HFlow hSpacing={0.5}>
        <Icon icon='userFilled' size={1} fill='primary' />
        <HLabel title='Profissional:'>
          {lotacaoAgendamentoToString({
            nomeProfissional: lotacaoAgendada?.profissional?.nome,
            nomeCbo: lotacaoAgendada?.cbo?.nome,
            ineEquipe: lotacaoAgendada?.equipe?.ine,
            nomeEquipe: lotacaoAgendada?.equipe?.nome,
          })}
        </HLabel>
      </HFlow>
      <HFlow hSpacing={0.5}>
        <Icon icon='hospital' size={1} fill='primary' />
        <Text fontWeight='bold'> {lotacaoAgendada.unidadeSaude.nome?.titleCase()}</Text>
      </HFlow>
    </VFlow>
  )
}
