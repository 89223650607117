/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Icon, Tag, Tooltip } from 'bold-ui'
import { noop } from 'lodash'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'
import { EditableRow } from 'view/atendimentos/detail/components/EditableList'

import { showCodigoProcedimento } from '../../objetivo/resultados-exames/util-resultadosExames'
import { ProcedimentoSigtapFieldModel } from './ProcedimentoSigtapField'

interface ProcedimentoSigtapRowProps {
  model: ProcedimentoSigtapFieldModel
  hideRemoveButton?: boolean
  onRemove?: (itemToRemove: ProcedimentoSigtapFieldModel) => void
}

export function ProcedimentoSigtapRow(props: ProcedimentoSigtapRowProps) {
  const { model, hideRemoveButton = false, onRemove = noop } = props

  const handleRemove = () => onRemove(model)

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(model)

  return (
    <EditableRow editing={false}>
      <Grid style={{ margin: 0 }}>
        <Cell size={hideRemoveButton ? 12 : 10} alignSelf='center'>
          {`${model.procedimento?.descricao} - ${showCodigoProcedimento(model.procedimento?.codigo)}`}
          {model.isAutomatico && <Tag style={style.tag}>Adicionado automaticamente</Tag>}
        </Cell>

        {!hideRemoveButton && (
          <Cell size={2} alignSelf='center' style={style.cell}>
            <Tooltip text={tooltipText}>
              <Button size='small' skin='ghost' onClick={handleRemove} disabled={isEditable}>
                <Icon icon='trashOutline' />
              </Button>
            </Tooltip>
          </Cell>
        )}
      </Grid>
    </EditableRow>
  )
}

const style = {
  tag: css`
    margin-left: 0.5rem;
  `,
  cell: css`
    padding: 0.25rem 0;
    text-align: right;
  `,
}
