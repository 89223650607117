import { HeadingSection, HFlow, Spinner, Text, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { useProfissionalAcessosQuery } from 'graphql/hooks.generated'
import React from 'react'
import selecaoAcessoSortCompare from 'view/login/SelecaoAcessoView/SelecaoAcessoSorter'

import { ProfissionalLotacoesEstagioSection } from './estagio-section/ProfissionalLotacoesEstagioSection'
import { ProfissionalLotacoesSection } from './lotacao-section'
import { EstagioModel, LotacaoModel } from './model'
import { ProfissionalOutrosAcessosSection } from './outros-acessos-section'

export interface ProfissionalAcessosViewProps {
  profissionalId: ID
}

export function ProfissionalAcessosView(props: ProfissionalAcessosViewProps) {
  const { profissionalId } = props

  const session = useSession()
  const {
    data: { profissional },
    loading,
    refetch: refechQuery,
  } = useProfissionalAcessosQuery({
    variables: { profissionalId },
  })

  const acessos = (profissional && profissional.acessos) || []

  const outrosAcessos = acessos
    .filter((acesso) => acesso.__typename !== 'Lotacao' && acesso.__typename !== 'Estagio')
    .sort(selecaoAcessoSortCompare)
  const lotacoes: LotacaoModel[] = acessos
    .filter((acesso) => acesso.__typename === 'Lotacao')
    .sort(selecaoAcessoSortCompare) as LotacaoModel[]
  const lotacoesEstagio: EstagioModel[] = acessos
    .filter((acesso) => acesso.__typename === 'Estagio')
    .sort(selecaoAcessoSortCompare) as EstagioModel[]

  const refetch =
    session.data.profissional.id === profissional?.id
      ? () => {
          refechQuery()
          session.refresh()
        }
      : refechQuery

  return (
    <HeadingSection level={2} title='Lotações e acessos'>
      {loading && (
        <HFlow hSpacing={0.5} alignItems='center'>
          <Spinner />
          <Text>Carregando</Text>
        </HFlow>
      )}
      {!loading && (
        <VFlow vSpacing={2}>
          <ProfissionalLotacoesSection lotacoes={lotacoes} possuiCns={!!profissional?.cns} refetch={refetch} />
          <ProfissionalLotacoesEstagioSection
            estagio={lotacoesEstagio}
            possuiCns={!!profissional?.cns}
            refetch={refetch}
          />
          <ProfissionalOutrosAcessosSection acessos={outrosAcessos} refetch={refetch} />
        </VFlow>
      )}
    </HeadingSection>
  )
}

export default ProfissionalAcessosView
