import CheckPermission from 'components/auth/CheckPermission'
import React from 'react'
import Permissions from 'types/Permissions'

import { UnificacaoBaseBox } from './UnificacaoBaseBox'

export const UnificacaoBaseView = () => {
  return (
    <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarConexaoComInternet}>
      <UnificacaoBaseBox />
    </CheckPermission>
  )
}
