import { Button, HFlow, Modal, ModalBody, ModalFooter, ModalProps } from 'bold-ui'
import { Form, FormRenderProps, SubmitButton } from 'components/form'
import { Decorator, ValidationErrors } from 'final-form'
import React from 'react'

import { ModalBody as ModalTitle, ModalBodyProps } from './ModalBody'

interface FormModalProps<T> extends ModalBodyProps, Omit<ModalProps, 'title' | 'onSubmit'> {
  renderBody(formProps: FormRenderProps<T>): React.ReactNode
  validate?(values: T): ValidationErrors | Promise<ValidationErrors>
  onSubmit(values: T): void
  decorators: Array<Decorator<T>>

  initialValues?: T

  onClose(): void

  buttonLabels: {
    confirm?: string
    cancel?: string
  }
}

export default function FormModal<T extends object>(props: FormModalProps<T>) {
  const {
    initialValues,
    buttonLabels,
    onSubmit,
    renderBody,
    validate,
    onClose,
    modalProps,
    modalTitleProps,
    decorators,
  } = resolveProps(props)

  const { confirm, cancel } = buttonLabels

  const renderForm = (formProps: FormRenderProps<T>) => {
    const { handleSubmit } = formProps
    const modalBody = renderBody(formProps)

    return (
      <Modal onClose={onClose} {...modalProps}>
        <ModalBody>
          <ModalTitle {...modalTitleProps}>{modalBody}</ModalTitle>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={onClose}>{cancel}</Button>
            <SubmitButton title={confirm} kind='primary' handleSubmit={handleSubmit}>
              {confirm}
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<T>
      render={renderForm}
      validate={validate}
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={decorators}
    />
  )
}

FormModal.defaultProps = {
  closeOnBackdropClick: false,
  size: 'small',
  buttonLabels: {
    confirm: 'Salvar',
    cancel: 'Cancelar',
  },
} as Partial<FormModalProps<any>>

const resolveProps = <T extends object>(props: FormModalProps<T>) => {
  const {
    initialValues,
    buttonLabels,
    onSubmit,
    renderBody,
    validate,
    onClose,
    open,
    size,
    children,
    containerRef,
    depthLevel,
    manageOverflow,
    closeOnBackdropClick,
    decorators,
    ...rest
  } = props

  return {
    initialValues,
    buttonLabels,
    onSubmit,
    renderBody,
    validate,
    onClose,
    decorators,
    modalProps: { open, size, children, containerRef, depthLevel, manageOverflow, closeOnBackdropClick },
    modalTitleProps: {
      ...rest,
    },
  }
}
