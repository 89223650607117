import { useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

export function SiteWrapper({ children }) {
  const { classes } = useStyles(createStyles)

  return <div className={classes.wrapper}>{children}</div>
}

const createStyles = () => ({
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  } as CSSProperties,
})
