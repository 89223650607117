import { partition } from 'lodash'

import { Aggregator, AggregatorEnum } from './model-aggregator'

export const CONTAGEM_AGGREGATOR: Aggregator = {
  id: AggregatorEnum.CONTAGEM,
  label: 'Contagem',
  value: undefined,
  keyDependent: false,
}
export const PERCENTUAL_AGGREGATOR: Aggregator = {
  id: AggregatorEnum.PERCENTUAL,
  label: 'Percentual',
  value: (values: number[], total: number): number => (values.reduce((prev, curr) => prev + curr, 0) * 100) / total,
  keyDependent: false,
  chain: [CONTAGEM_AGGREGATOR],
  suffix: '%',
}

export const AGGREGATORS: Aggregator[] = [
  CONTAGEM_AGGREGATOR,
  PERCENTUAL_AGGREGATOR,
  {
    id: AggregatorEnum.MEDIA,
    label: 'Média',
    value: (values: number[]): number => values.reduce((prev, curr) => prev + curr, 0) / values.length,
    keyDependent: true,
  },
  {
    id: AggregatorEnum.MAXIMO,
    label: 'Máximo',
    value: (values: number[]): number => values.reduce((prev, curr) => (prev > curr ? prev : curr)),
    keyDependent: true,
  },
  {
    id: AggregatorEnum.MINIMO,
    label: 'Mínimo',
    value: (values: number[]): number => values.reduce((prev, curr) => (prev < curr ? prev : curr)),
    keyDependent: true,
  },
]

export const [KEY_DEPENDENT_AGGREGATORS, KEY_NOT_DEPENDENT_AGGREGATORS] = partition(
  AGGREGATORS,
  (agg) => agg.keyDependent
)

export const aggregatorByIdOrDefault = (id?: AggregatorEnum): Aggregator =>
  AGGREGATORS.find((agg) => id && agg.id === id) ?? CONTAGEM_AGGREGATOR
