/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, InfoLabel, useTheme } from 'bold-ui'
import { MarcadorConsumoAlimentar, PerguntasQuestionarioEnum } from 'graphql/types.generated'
import { mcaSeisAVinteETresMesesQuestions } from 'view/atendimentos/detail/soap/objetivo/marcadores-consumo-alimentar/model'

import { convertOpcaoMCAEnumToString } from '../../../utils/utils-historico'
import HistoricoDetailTable from '../../table/HistoricoDetailTable'

interface HistoricoMCASeisAVinteETresMesesTableBoxProps {
  marcadorConsumoAlimentar: MarcadorConsumoAlimentar
}

export function HistoricoMCASeisAVinteETresMesesTableBox({
  marcadorConsumoAlimentar,
}: HistoricoMCASeisAVinteETresMesesTableBoxProps) {
  const respostasMCA = convertOpcaoMCAEnumToString(marcadorConsumoAlimentar)

  const theme = useTheme()

  const respostaSeSimQuantasVezesFruta = respostasMCA.get(PerguntasQuestionarioEnum.SE_SIM_QUANTAS_VEZES_FRUTA)

  const respostaSeSimQuantasVezesComidaSal = respostasMCA.get(PerguntasQuestionarioEnum.SE_SIM_QUANTAS_VEZES_COMIDA_SAL)

  const respostaSeSimConsistenciaComidaSal = respostasMCA.get(
    PerguntasQuestionarioEnum.SE_SIM_ESSA_COMIDA_FOI_OFERECIDA
  )

  return (
    <HistoricoDetailTable title='Marcadores de consumo alimentar' sectionStyle='O'>
      <Grid>
        <Cell size={12}>
          <Heading level={4}>Crianças com 6 a 23 meses</Heading>
        </Cell>

        <Cell size={12}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.leiteMaterno}>
            {respostasMCA.get(PerguntasQuestionarioEnum.CRIANCA_ONTEM_TOMOU_LEITE_PEITO_2)}
          </InfoLabel>
        </Cell>

        <Cell size={5}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.fruta}>
            {respostasMCA.get(PerguntasQuestionarioEnum.ONTEM_CRIANCA_COMEU_FRUTA_INTEIRA_PEDACO_AMASSADA)}
          </InfoLabel>
        </Cell>

        <Cell size={7} style={!respostaSeSimQuantasVezesFruta && { visibility: 'hidden' }}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.quantidadeRefeicoesFruta}>
            {respostaSeSimQuantasVezesFruta}
          </InfoLabel>
        </Cell>

        <Cell size={5}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.comidaDeSal}>
            {respostasMCA.get(PerguntasQuestionarioEnum.ONTEM_CRIANCA_COMEU_COMIDA_SAL)}
          </InfoLabel>
        </Cell>

        <Cell size={3} style={!respostaSeSimQuantasVezesComidaSal && { visibility: 'hidden' }}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.quantidadeRefeicoesComidaDeSal}>
            {respostaSeSimQuantasVezesComidaSal}
          </InfoLabel>
        </Cell>

        <Cell size={4} style={!respostaSeSimConsistenciaComidaSal && { visibility: 'hidden' }}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.consistenciaAlimentoComidaDeSal}>
            {respostaSeSimConsistenciaComidaSal}
          </InfoLabel>
        </Cell>

        <Cell
          size={12}
          style={css`
            border-bottom: 1px solid ${theme.pallete.divider};
          `}
        >
          <Heading level={4}>Ontem a criança consumiu</Heading>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.leiteDeVaca}>
            {respostasMCA.get(PerguntasQuestionarioEnum.OUTRO_LEITE_NAO_LEITE_PEITO)}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.figado}>
            {respostasMCA.get(PerguntasQuestionarioEnum.FIGADO)}
          </InfoLabel>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.mingau}>
            {respostasMCA.get(PerguntasQuestionarioEnum.MINGAU_COM_LEITE)}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.feijao}>
            {respostasMCA.get(PerguntasQuestionarioEnum.FEIJAO_6MESES_2ANOS)}
          </InfoLabel>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.iogurte}>
            {respostasMCA.get(PerguntasQuestionarioEnum.IOGURTE)}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.amidos}>
            {respostasMCA.get(PerguntasQuestionarioEnum.ARROZ_BATATA_INHAME_AIPIM)}
          </InfoLabel>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.legumes}>
            {respostasMCA.get(PerguntasQuestionarioEnum.LEGUMES)}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.embutidos}>
            {respostasMCA.get(PerguntasQuestionarioEnum.HAMBURGUER_E_OU_EMBUTIDOS_6MESES_2ANOS)}
          </InfoLabel>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.vegetais}>
            {respostasMCA.get(PerguntasQuestionarioEnum.VEGETAL_OU_FRUTA_ALARANJADA)}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.bebidasComAcucar}>
            {respostasMCA.get(PerguntasQuestionarioEnum.BEBIDAS_ADOCADAS_6MESES_2ANOS)}
          </InfoLabel>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.verduraDeFolha}>
            {respostasMCA.get(PerguntasQuestionarioEnum.VERDURA_FOLHA)}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.macarraoInstantaneo}>
            {respostasMCA.get(PerguntasQuestionarioEnum.MACARRAO_INSTANTANEO_SALGADINHOS_PACOTES_6MESES_2ANOS)}
          </InfoLabel>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.carne}>
            {respostasMCA.get(PerguntasQuestionarioEnum.CARNE)}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title={mcaSeisAVinteETresMesesQuestions.guloseimas}>
            {respostasMCA.get(PerguntasQuestionarioEnum.BISCOITO_RECHEADO_DOCES_GULOSEIMAS_6MESES_2ANOS)}
          </InfoLabel>
        </Cell>
      </Grid>
    </HistoricoDetailTable>
  )
}
