/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Checkbox, HFlow, TableCell, Text, useTheme } from 'bold-ui'
import { memo, useMemo } from 'react'

import {
  PeriogramaCompletoCondicao,
  PeriogramaCompletoMultipleBoolean,
  PeriogramaCompletoTableInputValue,
} from '../model'
import { periogramaCompletoTableStyles } from './PeriogramaCompletoTableView'

interface PeriogramaCompletoTableViewCellProps {
  condicao: PeriogramaCompletoCondicao
  ausente: boolean
  pip: string
  value: PeriogramaCompletoTableInputValue
}

function PeriogramaCompletoTableViewCell(props: PeriogramaCompletoTableViewCellProps) {
  const { condicao, ausente, pip, value } = props

  const theme = useTheme()
  const classes = useMemo(() => periogramaCompletoTableStyles(theme), [theme])

  return (
    <TableCell style={[classes.tableCell, classes.tableCellInput, ausente && classes.tableCellAusente]}>
      {condicao === PeriogramaCompletoCondicao.PIP && !ausente ? (
        <Text>{pip}</Text>
      ) : ausente ? (
        <Text color='inherit'>–</Text>
      ) : condicao === PeriogramaCompletoCondicao.PLACA || condicao === PeriogramaCompletoCondicao.SANG_SONDAGEM ? (
        <CheckboxGroup value={value as PeriogramaCompletoMultipleBoolean} />
      ) : (
        <Text>{value}</Text>
      )}
    </TableCell>
  )
}

export default memo(PeriogramaCompletoTableViewCell)

const CheckboxGroup = (props: { value: PeriogramaCompletoMultipleBoolean }) => (
  <HFlow
    hSpacing={0}
    justifyContent='center'
    alignItems='center'
    style={css`
      margin-left: -0.25rem;
      padding: 0 0.25rem;
    `}
  >
    <Checkbox checked={!!props?.value?.d} disabled />

    <Checkbox checked={!!props?.value?.v} disabled />

    <Checkbox checked={!!props?.value?.m} disabled />
  </HFlow>
)
