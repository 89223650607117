import { MutationFetchResult } from '@apollo/react-common'
import { Button, HFlow, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DateTime } from 'components/date'
import { useErrorHandler } from 'components/error'
import { useAtivarAgendaOnlineMutation, useGerarChaveAtivacaoAgendaOnlineMutation } from 'graphql/hooks.generated'
import { AtivacaoAgendaOnlineInput, GerarChaveAtivacaoAgendaOnlineMutation } from 'graphql/types.generated'
import React, { useState } from 'react'

import { ConfiguracaoAgendaOnline } from './ConfiguracaoAgendaOnlineBox'
import { ConfiguracaoAgendaOnlineChaveForm } from './ConfiguracaoAgendaOnlineChaveForm'

export interface ConfiguracaoAgendaOnlineDesabilitadoContentProps {
  agendaOnline: ConfiguracaoAgendaOnline
  refetch(): void
}

export const ConfiguracaoAgendaOnlineDesabilitadoContent = (
  props: ConfiguracaoAgendaOnlineDesabilitadoContentProps
) => {
  const { agendaOnline } = props
  const alert = useAlert()

  const [chave, setChave] = useState<string>(null)

  const handleRejection = useErrorHandler()
  const [gerarChave] = useGerarChaveAtivacaoAgendaOnlineMutation()
  const [ativarAgenda] = useAtivarAgendaOnlineMutation()

  const handleGerarChave = () => {
    return gerarChave()
      .then((res: MutationFetchResult<GerarChaveAtivacaoAgendaOnlineMutation>) => {
        setChave(res.data.gerarChaveAtivacaoAgendaOnline)
      })
      .catch(handleRejection)
  }

  const handleSubmit = (values: AtivacaoAgendaOnlineInput) => {
    return ativarAgenda({ variables: { ativacaoAgendaOnlineInput: values } }).then(() => {
      alert('success', 'Conexão com a agenda online foi habilitada com sucesso.')
      setChave(null)
      props.refetch()
    })
  }

  return (
    <VFlow>
      <HFlow alignItems='center' justifyContent='space-between'>
        <Button kind='primary' skin='outline' size='small' onClick={handleGerarChave}>
          Gerar chave
        </Button>
        {agendaOnline.detalhesAtivacao && (
          <Text tag='p'>
            Desabilitada
            {agendaOnline.detalhesAtivacao.profissional && (
              <Text> por {agendaOnline.detalhesAtivacao.profissional.nome}</Text>
            )}
            {!agendaOnline.detalhesAtivacao.profissional && <Text> automaticamente</Text>}
            &nbsp;em <DateTime value={agendaOnline.detalhesAtivacao.dataEvento} />.
          </Text>
        )}
      </HFlow>

      {chave && (
        <ConfiguracaoAgendaOnlineChaveForm initialValues={{ chave, contraChave: null }} onSubmit={handleSubmit} />
      )}
    </VFlow>
  )
}
