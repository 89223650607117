import React from 'react'

export function ContaGovBrConfiabilidadeInsuficienteMessage() {
  return (
    <>
      É necessário possuir conta Gov.br nível ouro ou prata para utilizar a assinatura eletrônica digital.{' '}
      <a href='https://confiabilidades.acesso.gov.br/' target='_blank' rel='noopener noreferrer'>
        Aumente o nível da sua conta
      </a>{' '}
      e tente novamente.
    </>
  )
}
