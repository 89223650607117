/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Modal, ModalFooter, ModalProps, VFlow } from 'bold-ui'

interface SolicitacaoEntrarVideochamadaConfirmModalProps extends Omit<ModalProps, 'open'> {
  nomeSolicitante: string
  onResponse(response: Boolean): void
}

export function SolicitacaoEntrarVideochamadaConfirmModal(props: SolicitacaoEntrarVideochamadaConfirmModalProps) {
  const { onResponse, nomeSolicitante, ...rest } = props

  const handleNegarClick = () => onResponse(false)
  const handlePermitirClick = () => onResponse(true)

  return (
    <Modal open size='small' closeOnBackdropClick={false} {...rest}>
      <HFlow style={styles.modalBody}>
        <Icon icon='questionMarkOutline' fill='secondary' size={3} />
        <VFlow>
          <Heading level={1}>{`Deseja permitir que ${nomeSolicitante} entre na chamada?`}</Heading>
        </VFlow>
      </HFlow>

      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button style={styles.button} onClick={handleNegarClick}>
            Negar
          </Button>
          <Button kind='primary' style={styles.button} onClick={handlePermitirClick}>
            Permitir
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const styles = {
  button: css`
    min-width: 9rem;
  `,
  modalBody: css`
    padding: 2.5rem 0.5rem 2rem 2.5rem;
  `,
}
