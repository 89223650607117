/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, Text, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import image from 'images/videochamada/videochamada-encerrada.png'

export function VideochamadaTimeOutView() {
  return (
    <PageContent type='filled' style={styles.container}>
      <Grid alignItems='center' justifyContent='space-between'>
        <Cell size={5}>
          <VFlow vSpacing={0}>
            <Heading level={1}>Tempo de chamada excedido</Heading>
            <Text>Atualmente, as Videochamadas e-SUS APS possuem duração máxima de 3 horas.</Text>
          </VFlow>
        </Cell>
        <Cell>
          <img
            width={528}
            height={390}
            src={image}
            alt='Duas pessoas com as mãos levantadas, representando que estão se despedindo em uma chamada de vídeo'
          />
        </Cell>
      </Grid>
    </PageContent>
  )
}

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1024px;
  `,
}
