import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import { confirm } from 'components/modals/confirm'
import { PageHeader } from 'components/PageHeader'
import { useCriarLotacaoMutation, useLotacaoCreateQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { titleCase } from 'util/strConversor'

import convertModelToInput from './convertModelToInput'
import { LotacaoForm, LotacaoFormModel } from './LotacaoForm'

interface UrlParams {
  profissionalId: string
}

export function LotacaoCreateView() {
  const alert = useAlert()
  const match = useRouteMatch<UrlParams>()
  const history = useHistory()
  const session = useSession()
  const handleRejection = useErrorHandler()
  const { analytics } = useFirebase()

  const {
    data: { profissional },
  } = useLotacaoCreateQuery({
    variables: { profissionalId: match.params.profissionalId },
  })

  const [salvar] = useCriarLotacaoMutation()

  if (!profissional) {
    return null
  }

  const handleSubmit = (values: LotacaoFormModel) => {
    const input = convertModelToInput(values)
    const perfis = values.perfis.map((p) => p.nome).join(', ')
    analytics.logEvent('cadastro_lotacao', { perfis_adicionados: perfis })

    const perfisAD = perfis.includes(' AD')
    const perfilCoordenadorAD = perfis.includes('COORDENADOR AD')

    if (perfisAD && !input.equipe && !perfilCoordenadorAD) {
      confirm({
        title: 'Esta lotação não está associada a nenhuma equipe de atenção domiciliar.',
        body:
          'Para que os recursos de atenção domiciliar sejam acessados é necessário que a lotação esteja vinculada a um perfil AD e uma equipe de atenção domiciliar.',
        type: 'danger',
        onConfirm: () => {
          handleSalvar(values)
        },
        onCancel: () => {
          history.push(`/profissional/${profissional.id}/lotacoes/create`)
        },
      })()
    } else {
      handleSalvar(values)
    }
  }

  const handleSalvar = (values: LotacaoFormModel) => {
    const input = convertModelToInput(values)
    salvar({ variables: { input } })
      .then(profissional.id === session.data.profissional.id && session.refresh) //Não funciona dentro do finally
      .then(handleSubmitSuccess)
      .catch(handleRejection)
  }

  const handleSubmitSuccess = () => {
    alert('success', 'Lotação cadastrada com sucesso.')
    history.push(`/profissional/${profissional.id}/dados`)
  }

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/profissional/${profissional.id}/dados`} />
      <Breadcrumb title='Cadastro de lotação' />

      <VFlow vSpacing={1.5}>
        <PageHeader title='Cadastro de lotação' subtitle={titleCase(profissional.nome)} />
        <VFlow>
          <LotacaoForm
            initialValues={{ profissional: profissional.id, ativo: true, atualizaPerfil: true } as LotacaoFormModel}
            onSubmit={handleSubmit}
          />
        </VFlow>
      </VFlow>
    </PageContent>
  )
}
