/** @jsx jsx */
import { QueryLazyOptions } from '@apollo/react-hooks'
import { css, jsx } from '@emotion/core'
import { Cell, colors, Grid, HFlow, Text, Tooltip } from 'bold-ui'
import { RegistrosDoseVacinacaoQueryVariables } from 'graphql/types.generated'
import { ImunobiologicoRowModel, statusDoseRecord } from 'view/atendimentos/atendimento-vacinacao/model'
import { covidImunosRecord } from 'view/lote-imunobiologico/model'

import { InfoCardModalVacinacao } from '../../vacinacao/VacinacaoCalendarioView'
import { DoseItem } from './DoseItem'

export interface ImunobiologicoRowProps {
  mostrarCalendarioCompleto: boolean
  model: ImunobiologicoRowModel
  isAcompanhamentoVacinal: boolean
  readOnly?: boolean
  outrosImunobiologicos?: boolean
  handleOnDoseClicked: (info: InfoCardModalVacinacao) => void
  registrosDoseLazyQuery: (options?: QueryLazyOptions<RegistrosDoseVacinacaoQueryVariables>) => void
}

export function ImunobiologicoRow(props: ImunobiologicoRowProps) {
  const {
    mostrarCalendarioCompleto,
    model,
    handleOnDoseClicked,
    registrosDoseLazyQuery,
    isAcompanhamentoVacinal,
    readOnly = false,
    outrosImunobiologicos = false,
  } = props
  const { nome, doses } = model
  const isCovid = !!covidImunosRecord[model.imunobiologicoId]

  return (
    <HFlow
      alignItems='center'
      style={css`
        background-color: ${isCovid ? colors.orange.c90 : colors.gray.c90};
        padding: 0.5rem 0rem;
        min-height: 5.25rem;
      `}
    >
      {/* TODO NP (#20013): Remover o word-break condicional substituindo pela solução decidida na issue */}
      <Text
        fontWeight='bold'
        style={css`
          margin: 0 1rem;
          width: 6.25rem;
          word-break: ${outrosImunobiologicos ? 'break-word' : 'normal'};
        `}
      >
        {nome}
      </Text>
      <Grid gap={0.5} gapVertical={0.5}>
        {doses.map((value) => {
          return (
            (value?.model?.mostrarNoCalendarioDoGrupo || mostrarCalendarioCompleto) && (
              <Tooltip
                text={
                  (readOnly || isAcompanhamentoVacinal) && value?.model?.dataAprazamento
                    ? 'Visualizar registro'
                    : value?.model?.dataAprazamento && !value?.model?.dataAplicacao
                    ? 'Aprazada - Visualizar ou realizar registro'
                    : readOnly
                    ? statusDoseRecord[value.model.status].hintProntuario
                    : isAcompanhamentoVacinal
                    ? statusDoseRecord[value.model.status].hintAcompanhamento
                    : statusDoseRecord[value.model.status].hintVacinacao
                }
                key={value.model.calendarioId + 'statusHint'}
              >
                <Cell
                  key={value.model.calendarioId}
                  xs={3}
                  style={css`
                    min-height: 4.25rem;
                    width: 9.625rem;
                  `}
                >
                  <DoseItem
                    {...value}
                    handleOnDoseClicked={handleOnDoseClicked}
                    registrosDoseLazyQuery={registrosDoseLazyQuery}
                    readOnly={readOnly}
                    isAcompanhamentoVacinal={isAcompanhamentoVacinal}
                  />
                </Cell>
              </Tooltip>
            )
          )
        })}
      </Grid>
    </HFlow>
  )
}
