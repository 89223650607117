import { FORM_ERROR } from 'final-form'
import {
  createValidator,
  ErrorObject,
  maxLength,
  required,
  richTextMaxLength,
  ValidatorFunction,
} from 'util/validation'

import { EncaminhamentoExternoEspecializadoModel } from '../model'

export function encaminhamentoExternoFormValidator(
  renderCiap: boolean,
  encaminhamentosRecentes: EncaminhamentoExternoEspecializadoModel[],
  encaminhamentoEditado?: EncaminhamentoExternoEspecializadoModel
): ValidatorFunction<EncaminhamentoExternoEspecializadoModel> {
  return createValidator<EncaminhamentoExternoEspecializadoModel>(
    {
      especialidadeSisreg: [required],
      classificacaoRiscoEncaminhamento: [required],
      motivoEncaminhamento: [richTextMaxLength(1000)],
      observacoes: [richTextMaxLength(300)],
      complemento: [maxLength(100)],
    },
    (values: EncaminhamentoExternoEspecializadoModel, errors: ErrorObject<EncaminhamentoExternoEspecializadoModel>) => {
      renderCiap
        ? (errors.ciapEncaminhamento = required(values.ciapEncaminhamento))
        : (errors.cidEncaminhamento = required(values.cidEncaminhamento))

      encaminhamentosRecentes?.forEach((encaminhamentoSalvo) => {
        if (encaminhamentoEditado !== encaminhamentoSalvo) {
          const ciapCidSalvo = renderCiap
            ? encaminhamentoSalvo.ciapEncaminhamento?.codigo
            : encaminhamentoSalvo.cidEncaminhamento?.codigo
          const ciapCidValues = renderCiap ? values.ciapEncaminhamento?.codigo : values.cidEncaminhamento?.codigo
          if (
            encaminhamentoSalvo.especialidadeSisreg?.id === values.especialidadeSisreg?.id &&
            ciapCidSalvo === ciapCidValues
          ) {
            errors[FORM_ERROR] = renderCiap
              ? 'Já existe um encaminhamento cadastrado para esta Especialidade e Hipótese / Diagnóstico (CIAP2).'
              : 'Já existe um encaminhamento cadastrado para esta Especialidade e Hipótese / Diagnóstico (CID10).'
          }
        }
      })

      return errors
    }
  )
}
