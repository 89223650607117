import styled from '@emotion/styled'
import { Button, ExternalStyles } from 'bold-ui'
import { SubmitButton, TextAreaField } from 'components/form'
import React from 'react'
import { Meta } from 'util/metaPath'

interface ObservacaoFormGridProps {
  name: Meta<String>
  maxLength?: number
  style?: ExternalStyles
  onCancel(): any
  onSubmit(): any
}

export const ObservacaoFormGrid = (props: ObservacaoFormGridProps) => {
  const { name, maxLength, onCancel, onSubmit, style } = props

  return (
    <FormGrid>
      <TextAreaField name={name} placeholder='Insira outras observações.' maxLength={maxLength ?? 200} style={style} />
      <Button size='small' onClick={onCancel}>
        Cancelar
      </Button>
      <SubmitButton size='small' handleSubmit={onSubmit}>
        Salvar
      </SubmitButton>
    </FormGrid>
  )
}

const FormGrid = styled.div`
  align-items: flex-start;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto auto;
  grid-gap: 1rem;
`
