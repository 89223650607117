import { Button, Heading, HFlow, isEqual, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { Form } from 'components/form'
import { FormPrompt } from 'components/form/FormPrompt'
import { confirm } from 'components/modals/confirm'
import clearTypename from 'graphql/clearTypename'
import { useConfiguracaoAgendaLotacaoQuery, useSalvarConfiguracaoAgendaLotacaoMutation } from 'graphql/hooks.generated'
import React, { useState } from 'react'
import { FormRenderProps, FormSpy } from 'react-final-form'
import { useHistory, useRouteMatch } from 'react-router'
import { Redirect } from 'react-router'
import {
  hasFinalDeSemanaPreenchidoChecker,
  hasPeriodosMais6HorasChecker,
  hasPeriodosMenos2HorasChecker,
} from 'view/configuracao-agenda/validator/base/ConfiguracaoHorarioAgendaHelper'
import { cfgHorarioAgendaValidator } from 'view/configuracao-agenda/validator/base/ConfiguracaoHorarioAgendaValidator'

import { ConfiguracaoAgendaFormType } from '../../configuracao-agenda/component/ConfiguracaoAgendaPanel'
import { ProfissionalHeaderIdentifier } from '../components/ProfissionalHeaderIdentifier'
import { ConfiguracaoAgendaLotacaoPanel } from './ConfiguracaoAgendaLotacaoPanel'

interface UrlParams {
  lotacaoId: string
  profissionalId: string
}

export function ConfiguracaoAgendaLotacaoView() {
  const history = useHistory()
  const match = useRouteMatch<UrlParams>()
  const alert = useAlert()

  const { data } = useConfiguracaoAgendaLotacaoQuery({
    variables: { lotacaoId: match.params.lotacaoId },
    fetchPolicy: 'network-only',
  })

  const [saveMutation] = useSalvarConfiguracaoAgendaLotacaoMutation()

  const [submitFailedStatus, setSubmitFailedStatus] = useState(false)

  if (
    !data ||
    !data.lotacao ||
    !data.conexao ||
    !data.duracaoAtendimentoPadraoMunicipio ||
    !data.configuracaoHorariosMunicipio
  ) {
    return null
  }

  if (!data.lotacao.actions.permissaoConfigurarAgenda.enabled) {
    return <Redirect to='/403' />
  }

  const { configuracaoHorariosMunicipio } = data
  const { lotacao } = data

  const isAgendaOnlineHabilitada = data.conexao.agendaOnline.ativa
  const nomeProfissional = lotacao.profissional.nome

  const handleCancelar = () => {
    history.goBack()
  }

  const submitForm = (form: ConfiguracaoAgendaFormType) => {
    return doSave(form)
  }

  const handleSubmit = (form: FormRenderProps<ConfiguracaoAgendaFormType>) => () => {
    const periodoMenos2Horas = hasPeriodosMenos2HorasChecker(form.values.configuracaoHorarioAgenda)
    const periodoMais6Horas = hasPeriodosMais6HorasChecker(form.values.configuracaoHorarioAgenda)
    const periodoNaoRecomendado = periodoMenos2Horas || periodoMais6Horas
    const agendamentoFuturo = data.lotacao.hasAgendamentoFuturo
    const agendaOnlineExcluida = isAgendaOnlineHabilitada && lotacao.hasConfiguracaoAgendaOnline

    const msgPeriodoNaoRecomendado =
      'Existem períodos configurados maiores que 6 horas ou menores que 2 horas. É recomendado que o período de trabalho dos profissionais seja menor que 6 horas e maior que 2 horas.'
    const msgAgendamentoFuturo =
      'Existem agendamentos futuros para este profissional. Caso existam agendamentos marcados para dias e/ou horários que não estão mais configurados, eles serão mantidos. Para visualizá-los acesse a agenda do profissional.'
    const msgAgendaOnlineExcluida =
      'A configuração de agenda online desta lotação será excluída e precisará ser reconfigurada.'

    const msgs = []
    periodoNaoRecomendado && msgs.push(msgPeriodoNaoRecomendado)
    agendamentoFuturo && msgs.push(msgAgendamentoFuturo)
    agendaOnlineExcluida && msgs.push(msgAgendaOnlineExcluida)

    if (msgs.length > 0)
      confirm({
        title: 'Deseja salvar a configuração da agenda do profissional?',
        body: <ConfirmacaoBodyItems msgs={msgs} />,
        cancelLabel: 'Cancelar',
        confirmLabel: 'Salvar',
        onConfirm: () => {
          return form.handleSubmit()
        },
      })()
    else {
      return form.handleSubmit()
    }
  }

  const doSave = (model: ConfiguracaoAgendaFormType) => {
    const variables = {
      configuracaoAgendaLotacaoInput: {
        lotacaoId: model.id,
        configuracaoHorarioAgenda: model.configuracaoHorarioAgenda,
      },
    }

    return saveMutation({ variables: clearTypename(variables) }).then((ret) => {
      alert('success', 'Configuração de agenda salva com sucesso.')
      setTimeout(() => {
        history.goBack()
      })

      return ret
    })
  }

  const submitFailed = () => {
    setSubmitFailedStatus(true)
  }

  let valueControl
  const WarningController = () => (
    <FormSpy
      subscription={{ values: true }}
      onChange={(state) => {
        if (!valueControl) {
          valueControl = state
        }

        if (!isEqual(valueControl, state)) {
          setSubmitFailedStatus(false)
        }

        valueControl = state
      }}
    />
  )

  const renderField = (formRenderProps: FormRenderProps<ConfiguracaoAgendaFormType>) => {
    return (
      <>
        <Breadcrumb title='Configuração de agenda' />
        <Modal open={true} onClose={handleCancelar} size='auto' closeOnBackdropClick={false}>
          <FormPrompt />
          <ModalBody>
            <VFlow>
              <Heading level={1}>
                <div>
                  {lotacao.hasConfiguracaoAgenda ? 'Editar configuração de agenda' : 'Criar configuração de agenda'}
                </div>
                <WarningController />
              </Heading>
              <ProfissionalHeaderIdentifier profissional={{ nomeProfissional, lotacao }} />
              <ConfiguracaoAgendaLotacaoPanel
                formProps={formRenderProps}
                submitFailedStatus={submitFailedStatus}
                configuracaoAgendaLotacao={{
                  id: lotacao.id,
                  configuracaoHorarioAgenda: lotacao.configuracaoAgenda.configuracaoHorarioAgenda,
                }}
                configuracaoAgendaMunicipio={configuracaoHorariosMunicipio}
              />
            </VFlow>
          </ModalBody>

          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <Button kind='normal' onClick={handleCancelar}>
                Cancelar
              </Button>
              <Button kind='primary' onClick={handleSubmit(formRenderProps)}>
                Salvar
              </Button>
            </HFlow>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  const configuracaoAgendaLotacao: ConfiguracaoAgendaFormType = {
    id: data.lotacao.id,
    configuracaoHorarioAgenda: data.lotacao.configuracaoAgenda.configuracaoHorarioAgenda,
    mostrarFinalDeSemana: hasFinalDeSemanaPreenchidoChecker(data.lotacao.configuracaoAgenda.configuracaoHorarioAgenda),
  }

  return (
    <Form<ConfiguracaoAgendaFormType>
      render={renderField}
      onSubmit={submitForm}
      initialValues={configuracaoAgendaLotacao}
      validate={cfgHorarioAgendaValidator}
      onSubmitFailed={submitFailed}
      suppressNotificationError
    />
  )
}

interface ConfirmacaoBodyItemsProps {
  msgs: string[]
}

function ConfirmacaoBodyItems(props: ConfirmacaoBodyItemsProps) {
  const { msgs } = props

  return msgs.length === 1 ? (
    <p>{msgs[0]}</p>
  ) : (
    <ul>
      {msgs.map((msg, index) => (
        <li style={{ margin: '10px' }} key={index}>
          {msg}
        </li>
      ))}
    </ul>
  )
}
