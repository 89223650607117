/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableHeader,
  TableLoadingRow,
  TablePlaceholderRow,
  TableRow,
} from 'bold-ui'
import { TableBox, usePagedTableProps } from 'components/table'
import theme from 'config/theme'
import { useHistoricoEvolucoesOdontoTableQuery } from 'graphql/hooks.generated'
import { EvolucoesProcedimentoOdontoInput, PageInfo, TipoParteBucalEnum } from 'graphql/types.generated'
import { useCallback, useMemo, useState } from 'react'

import { HistoricoEvolucoesOdontoModel } from '../../historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTable'
import { HistoricoEvolucoesPeriodontaisHeader } from './HistoricoEvolucoesPeriodontaisHeader'
import { HistoricoEvolucoesPeriodontaisTableRow } from './HistoricoEvolucoesPeriodontaisTableRow'

type HistoricoEvolucoesPeriodontaisModel = HistoricoEvolucoesOdontoModel

type EvolucoesOdontoTableModel = {
  content: HistoricoEvolucoesPeriodontaisModel
  pageInfo: PageInfo
}

interface HistoricoEvolucoesPeriodontaisTableProps {
  prontuarioId: ID
  showEmpty?: boolean
}

export default function HistoricoEvolucoesPeriodontaisTable(props: HistoricoEvolucoesPeriodontaisTableProps) {
  const { prontuarioId, showEmpty } = props

  const [filterState, setFilterState] = useState<EvolucoesProcedimentoOdontoInput>({
    tipoPartesBucais: [TipoParteBucalEnum.SEXTANTE],
    prontuarioId,
    pageParams: {
      size: 5,
    },
  })

  const {
    data: { evolucoesProcedimentoOdonto: evolucoesPeriodontia },
    loading,
  } = useHistoricoEvolucoesOdontoTableQuery({
    variables: { input: filterState },
  })

  const { rows, loading: isLoading, ...tableFooterProps } = usePagedTableProps({
    loading,
    result: evolucoesPeriodontia as EvolucoesOdontoTableModel,
    onChange: setFilterState,
  })

  const isNotEmpty = useMemo(() => rows?.length > 0, [rows])

  const onChangeFilter = useCallback((filter: string) => {
    setFilterState((state) => ({
      ...state,
      query: filter,
    }))
  }, [])

  return isNotEmpty || !!filterState.query || isLoading || showEmpty ? (
    <TableBox header={<HistoricoEvolucoesPeriodontaisHeader onChangeFilter={onChangeFilter} />}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Data dos procedimentos</TableHeader>
            <TableHeader>Local</TableHeader>
            <TableHeader>Procedimentos</TableHeader>
            <TableHeader>Profissional</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableLoadingRow colSpan={4} />
          ) : isNotEmpty ? (
            rows.map((row) => <HistoricoEvolucoesPeriodontaisTableRow item={row} key={row.id} />)
          ) : (
            <TablePlaceholderRow colSpan={4} />
          )}
        </TableBody>
      </Table>
      <TableFooter
        {...tableFooterProps}
        style={classes.tableFooter}
        pageSize={filterState.pageParams?.size}
        sizeOptions={[5, 15, 25, 50]}
      />
    </TableBox>
  ) : null
}

const classes = {
  emptyList: css`
    color: ${theme.pallete.gray.c40};
    text-align: center;
    font-style: italic;
  `,
  tableFooter: css`
    border-top: 0;
    background-color: ${theme.pallete.surface.main};
  `,
}
