/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Theme, Tooltip, useTheme } from 'bold-ui'
import { CpfField, Form, FormRenderProps, SubmitButton, TextField } from 'components/form'
import { CnpjField } from 'components/form/field/CnpjField'
import { TipoPessoaGroupField } from 'components/form/field/TipoPessoaRadioGroupField'
import { FormApi } from 'final-form'
import { useSalvarCredencialRecebimentoExternoMutation } from 'graphql/hooks.generated'
import { TipoPessoaEnum } from 'graphql/types.generated'
import { useMemo, useState } from 'react'
import { isHttpsConnection } from 'util/https'
import { metaPath } from 'util/metaPath'
import { cnpj, cpf, createValidator, email, maxLength, minLength, required, validate } from 'util/validation'

import {
  CredencialRecebimentoExternoGeradaModal,
  CredencialRecebimentoExternoUserPassword,
} from './CredencialRecebimentoExternoGeradaModal'

interface CredencialRecebimentoExternoFormModel {
  nomeResponsavel: string
  tipoPessoa: TipoPessoaEnum
  cpfCnpj: string
  email: string
  nomeCredencial: string
}

const path = metaPath<CredencialRecebimentoExternoFormModel>()

const validator = createValidator<CredencialRecebimentoExternoFormModel>(
  {
    nomeCredencial: [required, minLength(3), maxLength(100)],
    nomeResponsavel: [required, minLength(5), maxLength(100)],
    tipoPessoa: [required],
    cpfCnpj: [required],
    email: [required, email, minLength(6), maxLength(100)],
  },
  (values, errors) => {
    if (values.tipoPessoa === TipoPessoaEnum.FISICA) {
      errors.cpfCnpj = errors.cpfCnpj ?? validate(values.cpfCnpj, cpf)
    }
    if (values.tipoPessoa === TipoPessoaEnum.JURIDICA) {
      errors.cpfCnpj = errors.cpfCnpj ?? validate(values.cpfCnpj, cnpj)
    }

    return errors
  }
)

interface CredenciaisRecebimentoExternoFormProps {
  refetch: () => void
}

export function CredenciaisRecebimentoExternoForm({ refetch }: CredenciaisRecebimentoExternoFormProps) {
  const [save] = useSalvarCredencialRecebimentoExternoMutation()
  const theme = useTheme()
  const styles = createStyles(theme)

  const [isModelOpen, setIsModelOpen] = useState(false)
  const [userPassword, setUserPassword] = useState<CredencialRecebimentoExternoUserPassword>()

  const initialValues: Partial<CredencialRecebimentoExternoFormModel> = useMemo(
    () => ({ tipoPessoa: TipoPessoaEnum.FISICA }),
    []
  )

  const handleSubmit = (
    values: CredencialRecebimentoExternoFormModel,
    form: FormApi<CredencialRecebimentoExternoFormModel>
  ) =>
    isHttpsConnection &&
    save({
      variables: { input: values },
    }).then((payload) => {
      refetch()
      setUserPassword(payload.data.salvarCredencialRecebimentoExterno.userPassword)
      setIsModelOpen(true)
      setTimeout(() => {
        form.reset()
        form.getRegisteredFields().forEach((field) => form.resetFieldState(field))
      })
    })

  const handleCloseCredencialRecebimentoGeradaModal = () => {
    setIsModelOpen(false)
    setUserPassword(null)
  }

  const renderForm = (renderProps: FormRenderProps<Partial<CredencialRecebimentoExternoFormModel>>) => {
    const isPessoaFisica = renderProps.values.tipoPessoa === TipoPessoaEnum.FISICA

    const onReset = () => {
      renderProps.form.reset()
      renderProps.form.getRegisteredFields().forEach((field) => renderProps.form.resetFieldState(field))
    }

    return (
      <form noValidate>
        <Grid>
          <Cell size={12}>
            <TipoPessoaGroupField name={path.tipoPessoa} required />
          </Cell>
          <Cell size={5}>
            <TextField name={path.nomeResponsavel} label='Responsável' required />
          </Cell>
          <Cell size={7}>
            {isPessoaFisica ? (
              <CpfField name={path.cpfCnpj} label='CPF' required />
            ) : (
              <CnpjField name={path.cpfCnpj} label='CNPJ' required />
            )}
          </Cell>
          <Cell size={5}>
            <TextField name={path.email} label='E-mail' required />
          </Cell>
          <Cell size={7} alignSelf='flex-start'>
            <TextField name={path.nomeCredencial} label='Nome da credencial' required />
          </Cell>
          <Cell size={12}>
            <HFlow justifyContent='flex-end'>
              <Button size='small' onClick={onReset}>
                Limpar campos
              </Button>
              <Tooltip
                text={
                  !isHttpsConnection && 'Para gerar credencias é necessário que a instalação possua HTTPS configurado.'
                }
              >
                <SubmitButton size='small' handleSubmit={renderProps.handleSubmit} disabled={!isHttpsConnection}>
                  Gerar credencial
                </SubmitButton>
              </Tooltip>
            </HFlow>
          </Cell>
        </Grid>
      </form>
    )
  }

  return (
    <div css={styles.box}>
      <CredencialRecebimentoExternoGeradaModal
        userPassword={userPassword}
        isOpen={isModelOpen}
        onClose={handleCloseCredencialRecebimentoGeradaModal}
      />
      <Form initialValues={initialValues} render={renderForm} validate={validator} onSubmit={handleSubmit} />
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  box: css`
    padding: 1rem;
    border: 1px solid ${theme.pallete.divider};
  `,
})
