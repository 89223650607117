import { HFlow } from 'bold-ui'
import { CheckboxField, Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { FormState } from 'final-form'
import { LoteImunobiologicoQueryInput } from 'graphql/types.generated'
import React from 'react'

export interface LoteImunobiologicoTableFilterProps {
  initialValues?: LoteImunobiologicoQueryInput
  onChange(values: LoteImunobiologicoQueryInput): void
}

export function LoteImunobiologicoTableFilter(props: LoteImunobiologicoTableFilterProps) {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<LoteImunobiologicoQueryInput>) => onChange(formState.values)

  const renderForm = () => {
    return (
      <HFlow hSpacing={1}>
        <FormDebouncedValueSpy onChange={handleChange} />
        <TextField
          name='filtroImunoFabricante'
          placeholder='Pesquise por lote, imunobiológico ou fabricante'
          icon='zoomOutline'
          style={{ width: '22rem' }}
        />
        <CheckboxField name='mostrarLotesInativos' label='Mostrar lotes inativos' />
      </HFlow>
    )
  }

  return <Form<LoteImunobiologicoQueryInput> initialValues={initialValues} onSubmit={onChange} render={renderForm} />
}
