/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, hexToRGB, HFlow, Icon, Theme, useTheme } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { ReactNode } from 'react'
import { Accordion, AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'

interface TermosDeUsoAccordionProps {
  title: string
  children: ReactNode
  onAccordionClick(): void
}

export function TermosDeUsoAccordion(props: TermosDeUsoAccordionProps) {
  const { title, children, onAccordionClick } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Accordion allowZeroExpanded>
      <AccordionItem css={styles.accordionItem} onClick={onAccordionClick}>
        <AccordionButton style={styles.accordionButton} wrapperStyle={styles.accordionButtonWrapper}>
          <HFlow alignItems='center' justifyContent='space-between'>
            <HFlow alignItems='center'>
              <Icon icon='angleDown' />
              <Heading level={4}>{title}</Heading>
            </HFlow>
          </HFlow>
        </AccordionButton>
        <AccordionItemPanel css={styles.panel}>{children}</AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

const createStyles = (theme: Theme) => ({
  panel: css`
    text-align: justify;
    align-items: justify;
    justify-content: justify;
    border: 0;
    padding: 0.625rem;
    padding-left: 3.2rem;
    font-size: 0.875rem;
  `,
  accordionItem: css`
    border-bottom: 1px ${theme.pallete.divider} solid;
  `,
  accordionButtonWrapper: css`
    padding-bottom: 0;
  `,
  accordionButton: css`
    padding: 0.5rem 0.7rem;
    overflow: hidden;
    border-radius: 0;
    background-color: ${theme.pallete.gray.c100};
    margin: 0;
    margin-top: 0;

    &:hover {
      border: 1px ${theme.pallete.divider} solid;
      background-color: ${hexToRGB(theme.pallete.gray.c20, 0.1)};
    }

    &:focus {
      border-radius: 2px;
      position: relative;
      z-index: 1;
    }
  `,
})
