import { Heading, HFlow, Icon } from 'bold-ui'
import React from 'react'

interface CardHeadingProps {
  children: React.ReactNode
}

export function CardHeading({ children }: CardHeadingProps) {
  return (
    <HFlow hSpacing={0.5} alignItems='center'>
      <Icon icon='mapMarkerFilled' fill='primary' size={1} />
      <Heading level={3}>{children}</Heading>
    </HFlow>
  )
}
