/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, HFlow, Radio, RadioProps, Theme, Tooltip, useTheme } from 'bold-ui'
import { useMemo, useRef } from 'react'

import { UseFieldProps, usePecField } from '../final-form/hooks/useField'

type RadioWithDescField = UseFieldProps<any> & Omit<RadioProps, 'name'>

export interface RadioFieldContainerProps extends RadioWithDescField {
  children?: React.ReactNode
  tooltipText?: string
}

RadioFieldContainer.defaultProps = {
  label: '',
} as RadioFieldContainerProps

export function RadioFieldContainer(props: RadioFieldContainerProps) {
  const { input, meta, style, children, label, tooltipText, disabled, ...rest } = usePecField({
    type: 'radio',
    ...props,
  })

  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme, disabled), [disabled, theme])

  const radio = useRef<HTMLInputElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    if (!disabled) {
      radio.current.click()
      wrapper.current.focus()
    }
  }

  return (
    <Tooltip text={tooltipText}>
      <div onClick={handleClick} tabIndex={0} ref={wrapper} css={css(classes.wrapper, style)}>
        <HFlow hSpacing={0.5} alignItems='center'>
          <Radio {...input} {...rest} label={label} inputRef={radio} disabled={disabled} />
          {children}
        </HFlow>
      </div>
    </Tooltip>
  )
}

const createStyles = (theme: Theme, disabled: boolean) => ({
  wrapper: css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    padding: 1rem;
    border: 1px solid ${disabled ? theme.pallete.gray.c80 : theme.pallete.gray.c60};
    border-radius: 2px;
    background: ${disabled ? theme.pallete.gray.c90 : theme.pallete.surface.main};
    transition: all 0.2s ease;

    &:focus {
      ${!disabled &&
      `
      outline: none;
      box-shadow: ${focusBoxShadow(theme)};
      `};
    }
  `,
})
