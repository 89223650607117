import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { StatusVacinacaoEnum } from 'graphql/types.generated'
import React from 'react'

export interface StatusDoseImunoSelectModel {
  id: ID
  enum: StatusVacinacaoEnum
  descricao: string
}

interface StatusDoseImunoSelectFieldProps
  extends Omit<SelectFieldProps<StatusDoseImunoSelectModel>, 'items' | 'itemToString'> {
  items?: StatusDoseImunoSelectModel[]
  isTipoBuscaAtivaCovid: boolean
}

const itemToString = (item: StatusDoseImunoSelectModel) => item?.descricao

export function StatusDoseImunoSelectField(props: StatusDoseImunoSelectFieldProps) {
  const { name, items, isTipoBuscaAtivaCovid, ...rest } = props

  return (
    <SelectField<StatusDoseImunoSelectModel>
      name={name}
      items={Object.values(items)}
      itemToString={itemToString}
      required
      {...rest}
    />
  )
}

export const items: Record<StatusVacinacaoEnum, StatusDoseImunoSelectModel> = {
  [StatusVacinacaoEnum.ATRASADA]: {
    id: 1,
    enum: StatusVacinacaoEnum.ATRASADA,
    descricao: 'Atrasada',
  },
  [StatusVacinacaoEnum.NO_PRAZO]: {
    id: 2,
    enum: StatusVacinacaoEnum.NO_PRAZO,
    descricao: 'No prazo',
  },
  [StatusVacinacaoEnum.APLICADA]: {
    id: 3,
    enum: StatusVacinacaoEnum.APLICADA,
    descricao: 'Aplicada',
  },
}
