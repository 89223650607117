import { createValidator, required } from 'util/validation'

import { ModeloPersonalizadoRelatorioGerencialCampoModel } from './model-modeloPersonalizadoRelatorioGerencialForm'

export const modeloPersonalizadoRelatorioGerencialCamposFormValidator = (
  campos: ModeloPersonalizadoRelatorioGerencialCampoModel[]
) =>
  createValidator<ModeloPersonalizadoRelatorioGerencialCampoModel>(
    {
      key: [required],
    },
    (formValues, errors) => {
      if (formValues?.key && campos?.filter((item) => item.key === formValues.key).isNotEmpty()) {
        errors.key = 'Esse campo já foi inserido no formulário.'
      }

      return errors
    }
  )
