import { MutationFetchResult } from '@apollo/react-common'
import { DropdownItem, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useAlterarUnidadePadraoHorusMutation } from 'graphql/hooks.generated'
import React from 'react'

import { PermissionToEditHorus } from './HorusTable'

export interface HorusEditUnidadePadraoComponentProps {
  unidadeEditada: ID
  unidadeSaudeId: ID
  permissionToEditHorus: PermissionToEditHorus
  reload(): any
}

export const HorusEditUnidadePadraoComponent = (props: HorusEditUnidadePadraoComponentProps) => {
  const { unidadeSaudeId, unidadeEditada, permissionToEditHorus, reload } = props

  const [alterarUnidadePadraoHorusMutation] = useAlterarUnidadePadraoHorusMutation()
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const handleEdit = confirm({
    title: `Deseja definir esta unidade como prioritária para consulta?`,
    type: 'primary',
    onConfirm: () =>
      alterarUnidadePadraoHorusMutation({
        variables: { input: { unidadeSaudeId: unidadeSaudeId, unidadeConsultaId: unidadeEditada } },
      })
        .then((success: MutationFetchResult) => {
          alert('success', `Unidade ${success.data.alterarUnidadePadraoHorus.nome} foi definida como prioritária.`)
          reload()
        })
        .catch(handleRejection),
  })

  return (
    <Tooltip text='Mais opções'>
      <DropdownButton>
        <Tooltip text={permissionToEditHorus.hint}>
          <DropdownItem onClick={handleEdit} disabled={!permissionToEditHorus.enabled}>
            Definir como prioritária
          </DropdownItem>
        </Tooltip>
      </DropdownButton>
    </Tooltip>
  )
}
