import { Cell, Grid, Icon, Text } from 'bold-ui'
import { ButtonLink } from 'components/route'
import { VinculacaoServicosInput } from 'graphql/types.generated'
import React from 'react'
import { useRouteMatch } from 'react-router'

import { VinculacoesEstabelecimentosTableFilter } from './VinculacoesEstabelecimentosTableFilter'

interface VinculacoesEstabelecimentosTableHeaderProps {
  initialValues?: VinculacaoServicosInput
  onChange(filter: VinculacaoServicosInput): void
}

export function VinculacoesEstabelecimentosTableHeader(props: VinculacoesEstabelecimentosTableHeaderProps) {
  const { initialValues, onChange } = props
  const match = useRouteMatch()

  return (
    <Grid>
      <Cell size={6}>
        <VinculacoesEstabelecimentosTableFilter initialValues={initialValues} onChange={onChange} />
      </Cell>
      <Cell size={6} style={{ textAlign: 'right' }}>
        <ButtonLink to={`${match.url}/adicionar`} kind='primary' size='small'>
          <Icon icon='plus' style={{ marginRight: '0.25rem' }} />
          <Text color='inherit'>Adicionar vínculo</Text>
        </ButtonLink>
      </Cell>
    </Grid>
  )
}
