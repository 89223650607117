import { MonthField as BoldMonthField, MonthFieldProps as BoldMonthFieldProps, Omit, ReferenceMonth } from 'bold-ui'
import React from 'react'

import { UseFieldProps, usePecField } from '../hooks/useField'
import { getFieldError } from '../util'

export type MonthFieldProps = UseFieldProps<ReferenceMonth> & Omit<BoldMonthFieldProps, 'name'>

export function MonthField(props: MonthFieldProps) {
  const { input, meta, ...rest } = usePecField(props)

  return <BoldMonthField error={getFieldError(meta)} {...input} {...rest} />
}
