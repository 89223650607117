import { Button, Heading, HFlow, Icon, VFlow } from 'bold-ui'
import { FormFooter } from 'components/form'
import React from 'react'

export interface FinalizarInstalacaoViewProps {
  onVoltar(): void
  onFinalizar(): void
}

export const FinalizarInstalacaoView = (props: FinalizarInstalacaoViewProps) => {
  const { onFinalizar, onVoltar } = props

  return (
    <>
      <VFlow vSpacing={2} style={{ maxWidth: '36.5rem' }}>
        <Heading level={2}>
          Antes de começar a utilizar o e-SUS APS PEC recomendamos que você revise as configurações da instalação:
        </Heading>

        <div>
          <Heading level={3}>
            <HFlow hSpacing={0.5} alignItems='center'>
              <Icon icon='wifi' fill='primary' /> Conexão
            </HFlow>
          </Heading>
          <ul>
            <li>Revise as configurações de conexão com a Internet, CADSUS, Hórus e Agenda online;</li>
            <li>Por padrão a conexão com a Internet e CADSUS estão habilitadas na instalação.</li>
          </ul>
        </div>

        <div>
          <Heading level={3}>
            <HFlow hSpacing={0.5} alignItems='center'>
              <Icon icon='checkCircleOutline' fill='primary' /> Ativação do município
            </HFlow>
          </Heading>
          <ul>
            <li>Cadastre os municípios que irão utilizar esta instalação, com os seus respectivos responsáveis;</li>
            <li>
              O responsável municipal deve ativar a instalação no município para que outros usuários possam acessar o
              sistema.
            </li>
          </ul>
        </div>
      </VFlow>
      <FormFooter style={{ marginBottom: '1rem' }}>
        <Button onClick={onVoltar}>Voltar</Button>
        <Button kind='primary' onClick={onFinalizar}>
          Finalizar
        </Button>
      </FormFooter>
    </>
  )
}
