import { Cell, Grid, InfoLabel, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { EncaminhamentoExternoEspecializado } from 'graphql/types.generated'
import * as React from 'react'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'
import { classificacaoRiscoColors } from 'view/atendimentos/detail/soap/plano/encaminhamento-externo/ClassificacaoRiscoEncaminhamentoButton'

export interface HistoricoPlanoPecEncaminhamentosEspecializadosPanelProps {
  encaminhamentos: EncaminhamentoExternoEspecializado[]
}

export default function HistoricoPlanoPecEncaminhamentosEspecializadosPanel({
  encaminhamentos,
}: HistoricoPlanoPecEncaminhamentosEspecializadosPanelProps) {
  return (
    <HistoricoDetailTable title='Encaminhamentos para serviço especializado' hasLines>
      <VFlow>
        {encaminhamentos.map((item: EncaminhamentoExternoEspecializado) => {
          const classificacaoRiscoColor = classificacaoRiscoColors[item.classificacaoRiscoEncaminhamento]
          return (
            <Grid gap={2} key={item.id}>
              <Cell size={4}>
                <InfoLabel title='Especialidade'>
                  <Text>{item.especialidadeSisreg.descricao.toLowerCase().capitalize()}</Text>
                </InfoLabel>
              </Cell>
              <Cell size={4}>
                <InfoLabel title='Hipótese / Diagnóstico'>
                  <Text>{`${item.hipoteseDiagnosticoCid10.nome} - ${item.hipoteseDiagnosticoCid10.codigo}`}</Text>{' '}
                  (CID10)
                </InfoLabel>
              </Cell>
              <Cell size={4}>
                <InfoLabel title='Classificação de risco'>
                  <StatusSquare
                    description={classificacaoRiscoColor.descricao}
                    color={classificacaoRiscoColor.fontColor}
                  />
                </InfoLabel>
              </Cell>
              {item.complemento && (
                <Cell size={12}>
                  <HLabel title='Complemento:'>{item.complemento}</HLabel>
                </Cell>
              )}
              {item.motivoEncaminhamento?.removeTags() && (
                <Cell size={12}>
                  <HLabel title='Motivo do encaminhamento'>
                    <Text dangerouslySetInnerHTML={{ __html: item.motivoEncaminhamento }} />
                  </HLabel>
                </Cell>
              )}
              {item.observacoes?.removeTags() && (
                <Cell size={12}>
                  <HLabel title='Observações'>
                    <Text dangerouslySetInnerHTML={{ __html: item.observacoes }} />
                  </HLabel>
                </Cell>
              )}
            </Grid>
          )
        })}
      </VFlow>
    </HistoricoDetailTable>
  )
}
