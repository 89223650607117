/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, Theme, Tooltip } from 'bold-ui'
import theme from 'config/theme'
import { CSSProperties, useMemo } from 'react'

export interface CounterTooltipProps {
  value: number
  text: string
  position?: {
    top?: CSSProperties['top']
    left?: CSSProperties['left']
    right?: CSSProperties['right']
    bottom?: CSSProperties['bottom']
  }
  style?: ExternalStyles
}

export function CounterTooltip(props: CounterTooltipProps) {
  const { value, text, position, style } = props
  const classes = useMemo(() => createStyles(theme), [])

  return (
    value > 0 && (
      <Tooltip style={[classes.tooltip, style]} placement='top' text={text} transitionDelay={200}>
        <div css={[classes.counter, position]}>{value}</div>
      </Tooltip>
    )
  )
}

const createStyles = (theme: Theme) => ({
  counter: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    right: 0.125rem;
    background: ${theme.pallete.primary.c40};
    border: 1px solid ${theme.pallete.primary.c30};
    border-radius: 50%;
    box-shadow: ${theme.shadows.outer[40]};
    color: ${theme.pallete.gray.c100};
    font-size: 0.8125rem;
    font-weight: bold;
    cursor: default;
    z-index: 1;
  `,
  tooltip: css`
    margin-bottom: 0.5rem;
  `,
})
