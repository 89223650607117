/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SelectHelperMenuItem, Spinner, useLocale, useTheme } from 'bold-ui'
import { SelectField } from 'components/form'
import { CidadaoAtendimentoSelectFieldModel } from 'components/form/field'
import { CidadaoSelectFieldItem } from 'components/form/field/select/CidadaoSelectField/CidadaoSelectFieldItem'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_SIZE,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { CidadaoAtendimentoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CidadaoAtendimentoSelectFieldQuery,
  CidadaoAtendimentoSelectFieldQueryVariables,
} from 'graphql/types.generated'

export interface CidadaoAtendimentoAdSelectFieldProps
  extends AsyncSelectFieldProps<CidadaoAtendimentoSelectFieldModel> {
  ativo: boolean
  obito: boolean
  equipeResponsavelId: ID
  retrieveContato?: boolean
}

export function CidadaoAtendimentoAdSelectField(props: CidadaoAtendimentoAdSelectFieldProps) {
  const { ativo, obito, equipeResponsavelId, retrieveContato = false, ...rest } = props
  const theme = useTheme()

  const locale = useLocale()

  const { skipping, selectProps } = useAsyncQuerySelect<
    CidadaoAtendimentoSelectFieldModel,
    CidadaoAtendimentoSelectFieldQuery,
    CidadaoAtendimentoSelectFieldQueryVariables
  >({
    query: CidadaoAtendimentoSelectFieldDocument,
    extractItems: (data) => data?.cidadaosCombo,
    variables: (inputString) => ({
      input: {
        query: inputString,
        ativo: ativo,
        obito: obito,
        limit: DEFAULT_SELECT_SIZE,
      },
      emAtencaoDomiciliar: true,
      equipeResponsavelId: equipeResponsavelId,
      retrieveContato,
    }),
    skip: (inputString) => inputString?.trim()?.length < 3,
    debounceTime: 500,
  })

  const itemToString = (item: CidadaoAtendimentoSelectFieldModel) => item?.nomeSocial || item?.nome

  const renderItem = (item: CidadaoAtendimentoSelectFieldModel) => {
    return item && <CidadaoSelectFieldItem item={item} />
  }

  return (
    <SelectField<CidadaoAtendimentoSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      menuMinWidth={500}
      popperProps={{ modifiers: [{ name: 'preventOverflow', enabled: false }] }}
      placeholder='Pesquise pelo CPF ou CNS.'
      components={{
        EmptyItem: () => (
          <SelectHelperMenuItem>
            {skipping ? 'Digite para pesquisar por um cidadão.' : locale.select.emptyItem}
          </SelectHelperMenuItem>
        ),
        LoadingItem: () => (
          <SelectHelperMenuItem>
            Pesquise pelo CPF/CNS para ter resultados mais rápido.
            <Spinner
              style={css`
                color: ${theme.pallete.primary.main};
                float: right;
              `}
            />
          </SelectHelperMenuItem>
        ),
      }}
      {...selectProps}
      {...rest}
    />
  )
}
