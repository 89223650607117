import { Button, HFlow, Text, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Box } from 'components/Box'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useForcarRedefinirSenhasMutation } from 'graphql/hooks.generated'
import React from 'react'

export function ConfiguracoesRedefinirSenhaBox() {
  const handleRejection = useErrorHandler()
  const [forcarRedefinirSenhas, { loading }] = useForcarRedefinirSenhasMutation()

  const handleClick = () => {
    return forcarRedefinirSenhas()
      .then(() => {
        alert('success', 'Redefinição de senha dos usuários solicitada com sucesso.')
      })
      .catch(handleRejection)
  }

  return (
    <Box>
      <VFlow>
        <Text fontWeight='bold'>Solicitação de redefinição de senha dos usuários no próximo acesso</Text>
        <HFlow alignItems='center'>
          <Button
            kind='primary'
            size='small'
            onClick={confirm({
              title: 'Deseja que todos os profissionais redefinam suas senhas no próximo acesso?',
              confirmLabel: 'Redefinir senhas',
              type: 'danger',
              onConfirm: handleClick,
            })}
            loading={loading}
            disabled={loading}
          >
            Redefinir a senha de todos os usuários
          </Button>
        </HFlow>
      </VFlow>
    </Box>
  )
}
