import { LocalDateRange } from 'components/form'
import RichTextEditor from 'react-rte'

import { msg } from '..'
import * as Util from '../Util'

export function required(value: any) {
  if (Util.isEmpty(value) || !/\S/.test(value)) {
    return msg('required', value)
  }
}

export function oneRequired(values: any[]) {
  if (values.every((value) => Util.isEmpty(value))) {
    return msg('oneRequired', values)
  }
}

export function requiredPeriod(value: LocalDateRange) {
  if (
    Util.isEmpty(value?.startDate) ||
    !/\S/.test(value?.startDate) ||
    Util.isEmpty(value?.endDate) ||
    !/\S/.test(value?.endDate)
  ) {
    return msg('required', value)
  }
}

export function requiredRichText(value: any) {
  const richTextValue = RichTextEditor.createValueFromString(value, 'html')
  let texto = ''
  const textBlocks = JSON.parse(richTextValue.toString('raw')).blocks
  textBlocks.forEach((e) => (texto += e.text?.toString()))
  return required(value) || required(texto)
}

export function conditionalRequired(condition: boolean) {
  return (value: any) => {
    if (condition) {
      return required(value)
    }
    return null
  }
}
