/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { Box, BoxProps } from 'components/Box'
import { useEffect, useRef, useState } from 'react'
import { formatHoursMinutes } from 'util/date/formatDate'

import { ChatMessage } from '../model-videochamada'
import { ChatTextInput } from './ChatTextInput'

interface ChatVideochamadaProps extends BoxProps {
  messages: ChatMessage[]
  onSendMessage(message: string): void
  open: boolean
  onClose?(): void
  scrollDelay?: number
}

const SCROLL_CONFIDENCE_INTERVAL = 0.95

export function ChatVideochamada(props: ChatVideochamadaProps) {
  const { open, onClose, onSendMessage, messages, scrollDelay = 300, style: externalStyles, ...rest } = props

  const endOfMessagesContainerRef = useRef<HTMLDivElement>()

  const theme = useTheme()
  const styles = createStyles(theme)

  const [autoScrollToLastMessage, setAutoScrollToLastMessage] = useState(true)

  useEffect(() => {
    if (autoScrollToLastMessage && messages.length) {
      const scrollProcess = setTimeout(
        () => endOfMessagesContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }),
        scrollDelay
      )
      return () => clearTimeout(scrollProcess)
    }

    if (!open) setAutoScrollToLastMessage(true)
  }, [autoScrollToLastMessage, messages.length, open, scrollDelay])

  const handleScrollMessages = (ev) => {
    const element = ev.currentTarget
    const scrollMax = element?.scrollHeight - element?.clientHeight
    const newAutoScrollToLastMessageState = element?.scrollTop >= scrollMax * SCROLL_CONFIDENCE_INTERVAL
    if (newAutoScrollToLastMessageState !== autoScrollToLastMessage)
      setAutoScrollToLastMessage(newAutoScrollToLastMessageState)
  }

  const handleSendMessage = (message: string) => {
    onSendMessage(message)
    setTimeout(
      () => endOfMessagesContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }),
      scrollDelay
    )
  }

  return (
    <Box style={[styles.chat, externalStyles]} {...rest}>
      <div>
        <HFlow justifyContent='flex-end'>
          <Tooltip text='Ocultar mensagens'>
            <Icon style={styles.closeIcon} icon='timesDefault' onClick={onClose} />
          </Tooltip>
        </HFlow>
        <Heading level={1}>Mensagens</Heading>
      </div>
      <VFlow vSpacing={0.5} style={styles.messages} onScroll={handleScrollMessages}>
        {messages.map(({ sender, time, content, local }) => (
          <div css={local && styles.messageGap} key={sender + '-' + time}>
            <HFlow hSpacing={0}>
              <Heading level={5} style={styles.sender} fontWeight='bold'>
                {local ? 'Você' : sender}
              </Heading>
              <Text style={styles.messageTime}>&nbsp;| {formatHoursMinutes(time)}</Text>
            </HFlow>
            <Text>{content}</Text>
          </div>
        ))}
        <div ref={endOfMessagesContainerRef} />
      </VFlow>
      <ChatTextInput onSend={handleSendMessage} />
    </Box>
  )
}

const createStyles = (theme: Theme) => ({
  closeIcon: css`
    cursor: pointer;
  `,
  messages: css`
    flex: 1 1 auto;
    overflow-y: auto;
    height: 0px;
    gap: 0.5rem;
    overflow-wrap: break-word;
    margin-right: 0;
    margin-left: 0;
    padding-right: 1rem;
    padding-left: 1rem;
  `,
  chat: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
    padding: 1rem 0;
    overflow: hidden;
    border-radius: 0.25rem;

    > *:not(:nth-child(2)) {
      margin-right: 1rem;
      margin-left: 1rem;
    }
  `,
  sender: css`
    max-width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  messageTime: css`
    color: ${theme.pallete.gray.c40};
  `,
  messageGap: css`
    margin-bottom: 0.25rem;
  `,
})
