/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Icon, InfoLabel, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { StatusInformation } from 'components/StatusInformation'
import { format } from 'date-fns'
import { formatDate, toDate } from 'util/date/formatDate'

import { EvolucaoProblema, Problema } from '../../../soap/aside/types/ProblemaModel'
import { MainDetailAccordionBodyRenderProps } from '../../main-detail-accordion/components/BodyWithDetailAndForm'
import { SituacaoProblemaTag } from '../../SituacaoProblemaTag'

interface BodyProblemaModalProps extends MainDetailAccordionBodyRenderProps<EvolucaoProblema, Problema> {}

export function BodyProblemaModal(props: BodyProblemaModalProps) {
  const {
    item: { cid10, ciap },
    nextEvolucao,
    evolucao,
  } = props

  return (
    <VFlow>
      <HFlow alignItems='center'>
        <HFlow alignItems='center' hSpacing={0.6}>
          <Icon icon='calendarFilled' fill='info' size={1} />
          <Text fontWeight='bold'>
            {!!evolucao.atualizadoEm
              ? format(toDate(evolucao.atualizadoEm), "dd/MM/yyyy 'às' HH:mm")
              : 'Atendimento atual'}
          </Text>
        </HFlow>
        <SituacaoProblemaTag situacao={evolucao.situacao} />
        {nextEvolucao && evolucao.possuiCiap && !nextEvolucao.possuiCiap && (
          <StatusInformation text='Inclusão de CIAP2' />
        )}
        {nextEvolucao && evolucao.possuiCid && !nextEvolucao.possuiCid && (
          <StatusInformation text='Inclusão de CID10' />
        )}
      </HFlow>
      <Grid>
        <Cell size={6}>
          <InfoLabel title='CIAP2'>{evolucao.possuiCiap && `${ciap?.codigo} - ${ciap?.descricao}`}</InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title='CID'>{evolucao.possuiCid && `${cid10?.codigo} - ${cid10?.nome}`}</InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel
            title='Observações'
            childStyles={css`
              word-break: break-word;
            `}
          >
            {evolucao.observacao}
          </InfoLabel>
        </Cell>
        <Cell size={3}>
          <InfoLabel title='Início'>{formatDate(evolucao.dataInicio)}</InfoLabel>
        </Cell>
        <Cell size={3}>
          <InfoLabel title='Fim'>{formatDate(evolucao.dataFim)}</InfoLabel>
        </Cell>
      </Grid>
      {evolucao.atendimentoProfissional && (
        <HLabel title='Evoluído por: '>
          {evolucao.atendimentoProfissional.lotacao.profissional.nome.titleCase()}
          {' - '}
          {evolucao.atendimentoProfissional.lotacao.cbo.nome.capitalize()}
        </HLabel>
      )}
    </VFlow>
  )
}
