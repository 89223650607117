import useSession from 'components/auth/useSession'
import { differenceInMinutes } from 'date-fns'
import { useSobreQuery } from 'graphql/hooks.generated'

import { useServerTime } from './useServerTime'

export function useLoginGovBr() {
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()
  const { data: session } = useSession()
  const {
    data: { info },
  } = useSobreQuery({ fetchPolicy: 'cache-first' })

  const isGovBrEnabled = info?.govBREnabled

  return {
    isGovBrEnabled: isGovBrEnabled,
    isAccessTokenGovBrAlive: isGovBrEnabled && differenceInMinutes(new Date(session.expDateAccessTokenGovBr), now) > 0,
  }
}
