/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, isEmpty, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps, RichTextField, SubmitButton, TextField } from 'components/form'
import { CiapSelectField } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import EspecialidadeSisregSelectField from 'components/form/field/select/EspecialidadeSisregSelectField/EspecialidadeSisregSelectField'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { ClassificacaoRiscoEncaminhamentoEnum } from 'graphql/types.generated'
import { useCallback } from 'react'
import { metaPath } from 'util/metaPath'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { ClassificacaoRiscoEncaminhamentoGroupButton } from './ClassificacaoRiscoEncaminhamentoGroupButton'
import { EncaminhamentoExternoEspecializadoModel, EncaminhamentoExternoEspecializadoPlanoModel } from './model'
import { encaminhamentoExternoFormValidator } from './util/encaminhamentoExternoFormValidator'

export const path = metaPath<EncaminhamentoExternoEspecializadoModel>()
export interface EncaminhamentoExternoFormProps {
  prontuarioId: ID
  isAtendimentoOdonto: boolean
  cidadao: CidadaoAtendimento
  renderCiap: boolean
  onSubmit(values: EncaminhamentoExternoEspecializadoModel, formApi: FormApi): void
  encaminhamentosCache: EncaminhamentoExternoEspecializadoPlanoModel
}

const removeNonBreakingSpace = (value: string) => value.replace(/&nbsp;/g, '')

export default function EncaminhamentoExternoEspecializadoForm(props: EncaminhamentoExternoFormProps) {
  const { onSubmit, isAtendimentoOdonto, cidadao, renderCiap, encaminhamentosCache } = props
  const alert = useAlert()

  const handleSubmit = (values: EncaminhamentoExternoEspecializadoModel, formApi: FormApi) => {
    onSubmit(values, formApi)
    setTimeout(() => formApi.reset())
    formApi.getRegisteredFields().forEach((item) => formApi.resetFieldState(item))
    alert('success', 'Encaminhamento salvo com sucesso')
  }

  const handleCancel = (formProps: FormRenderProps<EncaminhamentoExternoEspecializadoModel>) => {
    if (formProps.dirty) {
      confirm({
        title: 'Deseja sair sem salvar?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar aqui',
        confirmLabel: 'Sim, sair da tela',
        onConfirm: () => {
          alert('success', 'Encaminhamento cancelado com sucesso')
          setTimeout(() => formProps.form.reset())
        },
      })()
    } else {
      alert('success', 'Encaminhamento cancelado com sucesso')
    }
    formProps.form.getRegisteredFields().forEach((item) => formProps.form.resetFieldState(item))
  }

  const renderForm = (formProps: FormRenderProps<EncaminhamentoExternoEspecializadoModel>) => {
    return (
      <VFlow style={classes.container}>
        {formProps.error && <Text color='danger'>{formProps.error}</Text>}
        <Grid style={classes.formBody}>
          <Cell
            size={5}
            style={css`
              padding-top: 0;
            `}
          >
            <EspecialidadeSisregSelectField
              name={path.especialidadeSisreg}
              label='Especialidade'
              isAtendimentoOdonto={isAtendimentoOdonto}
              required
            />
          </Cell>
          <Cell
            size={5}
            style={css`
              padding-top: 0;
            `}
          >
            <TextField name={path.complemento} label='Complemento' maxLength={100} parse={removeNonBreakingSpace} />
          </Cell>
          <Cell size={5}>
            {renderCiap ? (
              <CiapSelectField
                name={path.ciapEncaminhamento}
                label='Hipótese/Diagnóstico (CIAP2)'
                ciapComCid10Encaminhamento
                sexo={isEmpty(cidadao?.identidadeGeneroDbEnum) ? cidadao?.sexo : null}
                required
                fetchCID10Encaminhamento
              />
            ) : (
              <CidSelectField
                name={path.cidEncaminhamento}
                label='Hipótese/Diagnóstico (CID10)'
                renderGrupoCiapRelacionada={false}
                sexo={isEmpty(cidadao?.identidadeGeneroDbEnum) ? cidadao?.sexo : null}
                required
              />
            )}
          </Cell>
          <Cell size={12}>
            <ClassificacaoRiscoEncaminhamentoGroupButton name={path.classificacaoRiscoEncaminhamento} />
          </Cell>
          <Cell size={12}>
            <RichTextField
              name={path.motivoEncaminhamento}
              label='Motivo do encaminhamento'
              maxLength={1000}
              parse={removeNonBreakingSpace}
              placeholder='Insira o motivo do encaminhamento'
            />
          </Cell>
          <Cell size={12}>
            <RichTextField
              name={path.observacoes}
              label='Observações'
              maxLength={300}
              parse={removeNonBreakingSpace}
              placeholder='Insira outras observações'
            />
          </Cell>
        </Grid>
        <HFlow justifyContent='flex-end'>
          <Button size='small' onClick={() => handleCancel(formProps)}>
            Cancelar
          </Button>
          <SubmitButton size='small' kind='primary' handleSubmit={formProps.handleSubmit}>
            Adicionar
          </SubmitButton>
        </HFlow>
      </VFlow>
    )
  }

  const validator = useCallback(
    encaminhamentoExternoFormValidator(renderCiap, encaminhamentosCache?.encaminhamentosEspecializadosRecentes ?? []),
    [renderCiap, encaminhamentosCache?.encaminhamentosEspecializadosRecentes]
  )

  return (
    <Form<EncaminhamentoExternoEspecializadoModel>
      initialValues={
        {
          classificacaoRiscoEncaminhamento: ClassificacaoRiscoEncaminhamentoEnum.ELETIVO,
        } as EncaminhamentoExternoEspecializadoModel
      }
      render={renderForm}
      onSubmit={handleSubmit}
      validate={validator}
    />
  )
}

const classes = {
  container: css`
    margin-bottom: 1rem;
  `,
  formBody: css`
    padding-bottom: 1.25rem;
  `,
}
