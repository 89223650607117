/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, TabItem, Tabs } from 'bold-ui'

interface VisualizacaoTerritorialMicroareaTabsProps {
  microareas: string[]
  currentMicroareaTab: string
  onTabClick: (microarea: string) => void
}

export default function VisualizacaoTerritorialMicroareaTabs(props: VisualizacaoTerritorialMicroareaTabsProps) {
  const { currentMicroareaTab, microareas, onTabClick } = props

  return (
    <Grid
      style={css`
        margin-bottom: 0.25rem;
      `}
    >
      <Cell
        size={12}
        style={css`
          overflow-x: auto;
        `}
      >
        <Tabs>
          {microareas.map((microarea) => (
            <TabItem onClick={() => onTabClick(microarea)} key={microarea} active={microarea === currentMicroareaTab}>
              {microarea === 'FA'
                ? 'Fora de área'
                : microarea === currentMicroareaTab
                ? `Microárea ${microarea}`
                : microarea}
            </TabItem>
          ))}
        </Tabs>
      </Cell>
    </Grid>
  )
}
