/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Modal, ModalFooter, ModalProps, VFlow } from 'bold-ui'

interface SolicitacaoEntrarVideochamadaTimeoutModalProps extends Omit<ModalProps, 'open'> {
  nomeSolicitante: string
  timeoutLimit: number
}

export function SolicitacaoEntrarVideochamadaTimeoutModal(props: SolicitacaoEntrarVideochamadaTimeoutModalProps) {
  const { nomeSolicitante, timeoutLimit, onClose, ...rest } = props

  return (
    <Modal open size='small' onClose={onClose} {...rest}>
      <HFlow style={styles.modalBody}>
        <Icon icon='infoCircleOutline' fill='primary' size={3} />
        <VFlow>
          <Heading level={1}>{`${nomeSolicitante} não conseguiu acessar a chamada`}</Heading>
          {`${nomeSolicitante} solicitou acesso à chamada, porém a solicitação não foi aceita por você no prazo de ${
            timeoutLimit / 60000
          } minutos.`}
        </VFlow>
      </HFlow>

      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button kind='primary' style={styles.button} onClick={onClose}>
            Fechar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const styles = {
  button: css`
    min-width: 9rem;
  `,
  modalBody: css`
    padding: 2.5rem 0.5rem 2rem 2.5rem;
  `,
}
