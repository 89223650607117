/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { HistoricoSectionStyles } from 'view/atendimentos/detail/historico/model/model-historico'

interface GravidezAvaliacaoSectionProps {
  nuGestasPrevias: number
  nuPartos: number
}

export default function GravidezAvaliacaoSection(props: GravidezAvaliacaoSectionProps) {
  const { nuGestasPrevias, nuPartos } = props

  if (!nuGestasPrevias && !nuPartos) return null

  return (
    <VFlow vSpacing={0}>
      <Heading level={5} style={createStyles.title}>
        Gravidez
      </Heading>
      <HFlow hSpacing={0} style={createStyles.separator}>
        {nuGestasPrevias >= 0 && <HLabel title='Gestas prévias:'>{nuGestasPrevias}</HLabel>}
        {nuPartos >= 0 && <HLabel title='Partos:'>{nuPartos}</HLabel>}
      </HFlow>
    </VFlow>
  )
}

const createStyles = {
  separator: css`
    & > :after {
      content: '|';
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
    & > :last-child:after {
      content: none;
    }
  `,
  title: css`
    color: ${HistoricoSectionStyles['A'].color};
  `,
}
