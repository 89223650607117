import { Grid, Text } from 'bold-ui'
import { FilterItem } from 'components/filteritem'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsDdMmYyyy } from 'util/date/formatDate'

import { TransmissaoRecebimentoLoteFilterModel } from './TransmissaoRecebimentoLoteFilter'

interface TransmissaoRecebimentoLoteFilterTagsProps {
  filter: TransmissaoRecebimentoLoteFilterModel
}

export function TransmissaoRecebimentoLoteFilterTags(props: TransmissaoRecebimentoLoteFilterTagsProps) {
  const { filter } = props
  const { origensLote, periodo } = filter ?? {}

  const isPeriodoFiltered = !isUndefinedOrNull(periodo?.startDate) && !isUndefinedOrNull(periodo?.endDate)
  const isOrigemLoteFiltered = origensLote?.isNotEmpty()

  const periodoText =
    isPeriodoFiltered && `${dateAsDdMmYyyy(periodo.startDate)} até ${dateAsDdMmYyyy(periodo.endDate)}`.capitalize()
  const origensLotesFilterText = isOrigemLoteFiltered && origensLote.map((item) => item.descricao).join(', ')

  const hasFilters = isPeriodoFiltered || isOrigemLoteFiltered

  return hasFilters ? (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {isPeriodoFiltered && <FilterItem label='Período:' value={periodoText} />}
      {isOrigemLoteFiltered && <FilterItem label='Origem de lote:' value={origensLotesFilterText} />}
    </Grid>
  ) : (
    <Text>Sem filtros ativos</Text>
  )
}
