import { useHistory, useLocation, useRouteMatch } from 'react-router'

/**
 * @deprecated use os hooks específicos do react-router, ex: `useLocation`, `useRouteMatch` e `useHistory`
 */
export const useRouter = <T = any>() => {
  const location = useLocation()
  const match = useRouteMatch<T>()
  const history = useHistory()
  return {
    location,
    match,
    history,
  }
}
