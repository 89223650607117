import { HFlow, Omit, PagedTable, PagedTableProps, Tag, Text } from 'bold-ui'
import { DateTime } from 'components/date'
import { TableBox } from 'components/table/TableBox'
import { MunicipioResponsavelFiltroInput, MunicipiosResponsaveisQuery } from 'graphql/types.generated'
import React from 'react'

import { MunicipiosResponsaveisDropdown } from './MunicipiosResponsaveisDropdown'
import { MunicipiosResponsaveisFilter } from './MunicipiosResponsaveisFilter'

export type RowType = MunicipiosResponsaveisQuery['municipiosResponsaveis']['content'][0]

export interface MunicipiosResponsaveisTableProps extends Omit<PagedTableProps<RowType>, 'columns'> {
  initialFilter: MunicipioResponsavelFiltroInput
  onFilterChange(filter: MunicipioResponsavelFiltroInput): void
  refetch(): Promise<any>
}

export const MunicipiosResponsaveisTable = (props: MunicipiosResponsaveisTableProps) => {
  const { refetch, initialFilter, onFilterChange, ...rest } = props

  const renderResponsavel = (row: RowType) => (
    <HFlow hSpacing={0.5} alignItems='center'>
      <Text>{row.profissional.nome}</Text>
      {!row.ativo && <Tag>Inativo</Tag>}
    </HFlow>
  )
  const renderHabilitado = (row: RowType) => <Text>{row.habilitado ? 'Sim' : 'Não'}</Text>
  const renderMunicipio = (row: RowType) => (
    <Text>
      {row.municipio.nome}/{row.municipio.uf.sigla}
    </Text>
  )
  const renderDataAdicao = (row: RowType) => {
    if (!row.dataAdicao) {
      return '-'
    }
    return <DateTime value={row.dataAdicao} format='DD/MM/YYYY' />
  }
  const renderDataInativacao = (row: RowType) => {
    if (!row.dataInativacao) {
      return '-'
    }
    return <DateTime value={row.dataInativacao} format='DD/MM/YYYY' />
  }
  const renderBtns = (row: RowType) => <MunicipiosResponsaveisDropdown row={row} onUpdate={refetch} />

  return (
    <TableBox header={<MunicipiosResponsaveisFilter initialValues={initialFilter} onChange={onFilterChange} />}>
      <PagedTable<RowType>
        columns={[
          { name: 'municipio', header: 'Município', render: renderMunicipio, sortable: true },
          { name: 'nomeResponsavel', header: 'Responsável', render: renderResponsavel, sortable: true },
          { name: 'habilitado', header: 'Habilitado', render: renderHabilitado, sortable: true },
          { name: 'dataAdicao', header: 'Data de adição', render: renderDataAdicao, sortable: true },
          { name: 'dataInativacao', header: 'Data de inativação', render: renderDataInativacao, sortable: true },
          { name: 'btn', render: renderBtns },
        ]}
        {...rest}
      />
    </TableBox>
  )
}
