import { Alert, DataTable, Heading, VFlow } from 'bold-ui'
import React from 'react'

import { Cpf } from '../../../../components/label'
import { ProfissionalDetalhesImportacaoModel } from './model'

interface ProfissionaisSemAgendaBoxProps {
  profissionais: ProfissionalDetalhesImportacaoModel[]
  maximoProfissionaisSemAgendaConfigurada: number
  totalProfissionaisSemAgendaConfigurada: number
}

export const ProfissionaisSemAgendaBox = (props: ProfissionaisSemAgendaBoxProps) => {
  const { profissionais, maximoProfissionaisSemAgendaConfigurada, totalProfissionaisSemAgendaConfigurada } = props

  if (totalProfissionaisSemAgendaConfigurada === 0) {
    return null
  }

  const muitosProfissionaisSemAgendaConfigurada =
    totalProfissionaisSemAgendaConfigurada > maximoProfissionaisSemAgendaConfigurada

  const profissionaisSemAgenda = profissionais.filter(profissional => profissional.agendaNaoConfigurada)

  return (
    <VFlow>
      <Heading level={2}>Profissionais com mais de uma lotação que não tiveram agenda configurada</Heading>
      {muitosProfissionaisSemAgendaConfigurada && (
        <Alert type='warning'>
          Foram importados {totalProfissionaisSemAgendaConfigurada} profissionais que não tiveram suas agendas
          configuradas automaticamente. Os {maximoProfissionaisSemAgendaConfigurada} primeiros registros são
          apresentados abaixo.
        </Alert>
      )}
      <DataTable<ProfissionalDetalhesImportacaoModel>
        columns={[
          { header: 'Profissional', name: 'profissional', render: profissional => profissional.dados.nome },
          { header: 'CNS', name: 'cns ', render: profissional => profissional.dados.cns },
          { header: 'CPF', name: 'cpf ', render: profissional => <Cpf value={profissional.dados.cpf} /> },
        ]}
        rows={profissionaisSemAgenda}
      />
    </VFlow>
  )
}
