import { Text, VFlow } from 'bold-ui'
import React from 'react'

export function AtualizacaoImoveisModal(props: { numeroImoveis: number; numeroCidadaos: number }) {
  const { numeroCidadaos, numeroImoveis } = props
  return (
    <VFlow vSpacing={2}>
      <Text>Ao confirmar a ação as informações abaixo terão seus dados de território atualizados.</Text>
      <Text variant='h3' fontWeight='bold'>
        Nº de cadastros com alteração:
        <br />
        {numeroImoveis} {numeroImoveis === 1 ? 'imóvel' : 'imóveis'} |{' '}
        {`cidadão`.pluralizeAndConcatValue(numeroCidadaos)}
      </Text>
    </VFlow>
  )
}
