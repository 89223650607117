import { Heading, HFlow, Icon, Tag, Text, Tooltip } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { Box } from 'components/Box'
import { ButtonLink } from 'components/route'
import { ProfissionalAcessosQuery } from 'graphql/types.generated'
import React from 'react'
import { tipoAcesso } from 'types/enums'
import Permissions from 'types/Permissions'

import { OutroAcessoCardDropdown } from './OutroAcessoCardDropdown'

export interface OutroAcessoCardProps {
  acesso: ProfissionalAcessosQuery['profissional']['acessos'][0]
  refetch(): void
}

export function OutroAcessoCard(props: OutroAcessoCardProps) {
  const { acesso, refetch } = props
  const permissaoEdicao = acesso.actions.permissaoEdicao
  return (
    <Box style={{ height: '100%' }}>
      <CheckPermission permission={Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirAcesso}>
        <div style={{ float: 'right' }}>
          <Tooltip text={permissaoEdicao.enabled ? 'Editar' : permissaoEdicao.hint}>
            <ButtonLink size='small' skin='ghost' to={`acessos/${acesso.id}/edit`} disabled={!permissaoEdicao.enabled}>
              <Icon icon='penOutline' />
            </ButtonLink>
          </Tooltip>
          <OutroAcessoCardDropdown refetch={refetch} acesso={acesso} />
        </div>
      </CheckPermission>

      <HFlow hSpacing={0.5}>
        <Heading level={3} style={{ marginBottom: '0.5rem' }}>
          {tipoAcesso[acesso.tipo]}
        </Heading>
        {acesso.ativo === false && <Tag>Inativo</Tag>}
      </HFlow>

      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>Perfis</Text>
        <Text>{acesso.perfis ? acesso.perfis.map((p) => p.nome).join('; ') : '-'}</Text>
      </HFlow>

      {(acesso.__typename === 'GestorMunicipal' || acesso.__typename === 'AdministradorMunicipal') && (
        <HFlow hSpacing={0.5}>
          <Text fontWeight='bold'>Município</Text>
          <Text>{acesso.municipio.nome}</Text>
        </HFlow>
      )}

      {acesso.__typename === 'GestorEstadual' && (
        <HFlow hSpacing={0.5}>
          <Text fontWeight='bold'>UF</Text>
          <Text>{acesso.uf.nome}</Text>
        </HFlow>
      )}
    </Box>
  )
}
