import { createListValidator, createValidator, ErrorObject, validate } from 'util/validation'
import { ConfiguracaoAgendaFormType } from 'view/configuracao-agenda/component/ConfiguracaoAgendaPanel'

import { cfgDiaSemanaValidator } from './ConfiguracaoDiaSemanaValidator'

export const cfgHorarioAgendaValidator = createValidator<ConfiguracaoAgendaFormType>({}, (value) => {
  const errors: ErrorObject<ConfiguracaoAgendaFormType> = {}

  if (value) {
    const listvalidator = createListValidator(cfgDiaSemanaValidator)
    errors.configuracaoHorarioAgenda = {
      configuracaoDiasSemana: validate(value.configuracaoHorarioAgenda.configuracaoDiasSemana, listvalidator),
    }
  }

  return errors
})
