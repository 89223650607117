import { Checkbox, Tooltip } from 'bold-ui'
import React from 'react'

import { ImovelItemModel } from './ImoveisTerritorioTable'
import { MAXIMUM_RETERRITORIALIZACAO_SIZE } from './model'
import {
  disabledAction,
  getMaximumCheckedAction,
  getPermissionReterritorializarAction,
  numeroTotalCidadaosImovel,
} from './utils/reterritorializacao'

interface ImoveisTerritorioCheckItemProps {
  item: ImovelItemModel
  checkedItems: Map<string, number>
  setCheckedItems: (map: Map<string, number>) => void
  disabled?: boolean
}

export function ImoveisTerritorioCheckItem({
  item,
  checkedItems,
  setCheckedItems,
  disabled,
}: ImoveisTerritorioCheckItemProps) {
  const handleCheck = (event, imoveisId: string, cidadaosNumber: number) => {
    const map = new Map([...checkedItems])
    if (event.target.checked) map.set(imoveisId, cidadaosNumber)
    else map.delete(imoveisId)
    setCheckedItems(map)
  }

  const checkedIds = new Set([...checkedItems.keys()])
  let itemAction = disabled
    ? disabledAction
    : getMaximumCheckedAction(checkedIds, item, MAXIMUM_RETERRITORIALIZACAO_SIZE)
  itemAction = itemAction.enabled ? getPermissionReterritorializarAction(item) : itemAction

  return (
    <Tooltip text={itemAction.hint}>
      <div>
        <Checkbox
          onChange={(event) => handleCheck(event, item.id, numeroTotalCidadaosImovel(item.familias))}
          checked={checkedItems.has(item.id) || false}
          disabled={!itemAction.enabled}
        />
      </div>
    </Tooltip>
  )
}
