import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

interface MudancasNaPoliticaDePrivacidadeSectionProps {
  versaoAndDataAtualizacao: {
    dataAtualizacao: string
    versao: string
    formattedString: string
  }
}

export const MudancasNaPoliticaDePrivacidadeSection = (props: MudancasNaPoliticaDePrivacidadeSectionProps) => {
  const { versaoAndDataAtualizacao } = props

  return (
    <TermosDeUsoHeadingSection title='13. Mudanças na Política de Privacidade' id='mudancas_na_politica_de_privacidade'>
      <p>
        A presente versão {versaoAndDataAtualizacao.versao} desta Política de Privacidade foi atualizada pela última vez
        em: {versaoAndDataAtualizacao.dataAtualizacao}.
      </p>
      <br />
      <p>
        O editor se reserva o direito de modificar, a qualquer momento, o site as presentes normas, especialmente para
        adaptá-las às evoluções do serviço, seja pela disponibilização de novas funcionalidades, seja pela supressão ou
        modificação daquelas já existentes.
      </p>
      <br />
      <p>
        Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela
        qual se convida o usuário a consultar periodicamente esta seção.
      </p>
    </TermosDeUsoHeadingSection>
  )
}
