import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { HttpLink } from 'apollo-link-http'
import cache from 'graphql/cache'

import { RequestListenerLink } from './RequestListenerLink'

const options: HttpLink.Options = {
  uri: '/api/graphql',
  credentials: 'same-origin', // send auth cookie with every request
}

const singleRequestLink = new HttpLink(options)
const batchLink = new BatchHttpLink(options)

const httpLink = split(
  ({ getContext }) => {
    return getContext().newRequest
  },
  singleRequestLink,
  batchLink
)

export const requestListener = new RequestListenerLink()

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([requestListener, httpLink]),
  name: 'PEC Web',
  version: process.env.REACT_APP_VERSION,
})

export default client
