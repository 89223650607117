/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Icon } from 'bold-ui'
import { Box } from 'components/Box'
import { PrivateRoute } from 'components/route'
import { Fragment } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Switch } from 'react-router-dom'
import Permissions from 'types/Permissions'
import { GerarDeclaracaoComparecimentoView } from 'view/atendimentos/list/actions/declaracao-comparecimento/GerarDeclaracaoComparecimentoView'

interface DeclaracaoComparecimentoBoxProps {
  atendimentoId: ID
}

interface DeclaracaoComparecimentoButtonProps {
  atendimentoId: ID
}

export default function DeclaracaoComparecimentoBox({ atendimentoId }: DeclaracaoComparecimentoBoxProps) {
  return (
    <Box>
      <HFlow>
        <Heading
          level={4}
          style={css`
            margin-bottom: 0.75rem;
          `}
        >
          Declaração de comparecimento
        </Heading>
      </HFlow>
      <DeclaracaoComparecimentoButton atendimentoId={atendimentoId} />
    </Box>
  )
}

export const DeclaracaoComparecimentoButton = ({ atendimentoId }: DeclaracaoComparecimentoButtonProps) => {
  const history = useHistory()
  const match = useRouteMatch()

  const onGerarDeclaracao = () => {
    return history.push(
      `${match.url}/declaracaoComparecimento/${atendimentoId}?callbackUrl=${match.url.replace('/', '')}`
    )
  }

  return (
    <Fragment>
      <Switch>
        <PrivateRoute
          path={`${match.url}/declaracaoComparecimento/:atendId(\\d+)`}
          permission={Permissions.visualizarListaDeAtendimento.gerarDeclaracaoDeComparecimento}
          component={GerarDeclaracaoComparecimentoView}
        />
      </Switch>
      <Grid>
        <Cell size={12}>
          <Button kind='primary' size='small' onClick={onGerarDeclaracao}>
            <Icon
              icon='plus'
              style={css`
                margin-right: 0.75rem;
              `}
            />
            Gerar declaração de comparecimento
          </Button>
        </Cell>
      </Grid>
    </Fragment>
  )
}
