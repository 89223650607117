/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, SelectHelperMenuItem, Spinner, Text, useTheme } from 'bold-ui'

export function CidadaoAtendimentoPorPropriedadeLoadingItem() {
  const theme = useTheme()

  return (
    <SelectHelperMenuItem>
      <HFlow
        hSpacing={0.5}
        style={css`
          padding: 0.5rem;
        `}
      >
        <Spinner style={{ color: theme.pallete.primary.main }} />
        <Text
          fontWeight='bold'
          style={css`
            color: ${theme.pallete.primary.main};
          `}
        >
          Carregando resultados
        </Text>
      </HFlow>
    </SelectHelperMenuItem>
  )
}
