import { maxLength, minLength, RuleNestedArray } from 'util/validation'

import { msg } from '..'

const AT_LEAST_A_NUMBER_AND_A_LETTER = /^(?=.*(\d))(?=.*[a-zA-Z]).*$/

export const senha: RuleNestedArray = [
  minLength(8),
  maxLength(20),
  (inputValue: string): string => {
    // Testar se possui ao menos uma letra e um numero
    if (!AT_LEAST_A_NUMBER_AND_A_LETTER.test(inputValue)) {
      return msg('senha', inputValue)
    }
    return null
  },
]
