const plural = {
  question: 'Você tem certeza que estas alterações fenotípicas estão realmente presentes na criança?',
  headerList: 'As seguintes alterações fenotípicas foram marcadas como Presente:',
}

const singular = {
  question: 'Você tem certeza que esta alteração fenotípica está realmente presente na criança?',
  headerList: 'A seguinte alteração fenotípica foi marcada como Presente:',
}

export const alteracoesFenotipicasMessages = (sizeList: number) => (sizeList > 1 ? plural : singular)
