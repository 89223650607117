import { DateRange } from 'bold-ui'

import { dateAsYyyyMmDd } from './formatDate'

export type InstantRange = {
  startDate: number
  endDate: number
}

export type LocalDateRange = {
  startDate: LocalDate
  endDate: LocalDate
}

export const convertInstantRangeToDateRange = (instantRange: InstantRange): DateRange => {
  return { startDate: new Date(instantRange.startDate), endDate: new Date(instantRange.endDate) }
}

export const convertDateRangeToInstantRange = (dateRange: DateRange): InstantRange => {
  return { startDate: dateRange.startDate?.getTime() ?? null, endDate: dateRange.endDate?.getTime() ?? null }
}

export const convertDateRangeToLocalDateRange = (dateRange: DateRange): LocalDateRange => {
  return { startDate: dateAsYyyyMmDd(dateRange?.startDate), endDate: dateAsYyyyMmDd(dateRange?.endDate) }
}
