/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Theme, useTheme } from 'bold-ui'
import { Box } from 'components/Box'
import { ReactComponent as CookieIcon } from 'images/cookie/cookie.svg'

import { CookieBannerView } from './CookieBannerView'

interface CookieBannerProps {
  onClickAceitarNecessarios: () => void
  onClickAceitarTodos: () => void
}

export const CookieBanner = (props: CookieBannerProps) => {
  const { onClickAceitarTodos, onClickAceitarNecessarios } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Box style={styles.container}>
      <Grid justifyContent='center' alignItems='center'>
        <Cell xs={12} lg={8}>
          <HFlow hSpacing={1}>
            {/* TODO Foundation: quando icone for adicionado ao bold, substituir e remover pasta cookie de images */}
            <CookieIcon css={styles.cookie} aria-label='Ícone de cookie' />
            <CookieBannerView
              onClickAceitarTodos={onClickAceitarTodos}
              onClickAceitarNecessarios={onClickAceitarNecessarios}
            />
          </HFlow>
        </Cell>
      </Grid>
    </Box>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    width: 100%;
    height: 10rem;
    position: fixed;
    bottom: 0;
    padding: 1rem 2rem;
    z-index: 20;
    display: flex;
    justify-content: center;
  `,
  cookie: css`
    fill: ${theme.pallete.primary.c40};
    width: 2rem;
    height: 2rem;
  `,
})
