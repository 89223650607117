/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Tooltip } from 'bold-ui'
import { ParteBucalEnum } from 'graphql/types.generated'
import { memo } from 'react'
import { MetaPath } from 'util/metaPath'

import PeriogramaCompletoCheckboxField from '../../components/PeriogramaCompletoCheckboxField'
import PeriogramaCompletoTextField from '../../components/PeriogramaCompletoTextField'
import {
  PeriogramaCompletoCondicao,
  PeriogramaCompletoMultipleBoolean,
  PeriogramaCompletoTableInputValue,
  possuiPeriogramaLesaoDeFurca,
} from '../model'

export interface PeriogramaCompletoTableFieldInputProps {
  name: MetaPath<PeriogramaCompletoTableInputValue>
  dente: ParteBucalEnum
  condicao: PeriogramaCompletoCondicao
  error?: string
}

function PeriogramaCompletoTableFieldInput(props: PeriogramaCompletoTableFieldInputProps) {
  const { name, dente, condicao, error } = props

  return (
    <Tooltip text={error} style={classes.tooltip}>
      <div css={classes.inputWrapper}>
        {(condicao === PeriogramaCompletoCondicao.PROF_SONDAGEM ||
          condicao === PeriogramaCompletoCondicao.RECESSAO ||
          condicao === PeriogramaCompletoCondicao.MOBILIDADE) && (
          <PeriogramaCompletoTextField name={name as MetaPath<string>} dente={dente} condicao={condicao} />
        )}

        {(condicao === PeriogramaCompletoCondicao.PLACA || condicao === PeriogramaCompletoCondicao.SANG_SONDAGEM) && (
          <PeriogramaCompletoCheckboxField name={name as MetaPath<PeriogramaCompletoMultipleBoolean>} />
        )}

        {condicao === PeriogramaCompletoCondicao.LESAO_FURCA &&
          (possuiPeriogramaLesaoDeFurca(dente) ? (
            <PeriogramaCompletoTextField name={name as MetaPath<string>} dente={dente} condicao={condicao} />
          ) : (
            <Text>-</Text>
          ))}
      </div>
    </Tooltip>
  )
}

export default memo(PeriogramaCompletoTableFieldInput, (oldProps, newProps) => {
  return oldProps.error === newProps.error
})

const classes = {
  inputWrapper: css`
    display: flex;
    justify-content: center;
    align-items: center;
    &,
    & div {
      height: 100%;
    }
  `,
  tooltip: css`
    height: 100%;
  `,
}
