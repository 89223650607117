import { Alert, Heading, HFlow, Icon, Link, Modal, ModalBody, Text, VFlow } from 'bold-ui'
import { useInstalacaoHeaderQuery } from 'graphql/hooks.generated'
import React, { useState } from 'react'
export default AlertTreinamento

export interface AlertTreinamentoProps {
  showProducao: boolean
}

export function AlertTreinamento(props: AlertTreinamentoProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const {
    data: { info },
  } = useInstalacaoHeaderQuery()
  const { showProducao } = props
  const handleClose = () => setModalOpen(false)
  const handleOpen = () => setModalOpen(true)

  return (
    <>
      <Modal open={modalOpen} onClose={handleClose} size='small' closeOnBackdropClick={false}>
        <ModalBody>
          <VFlow>
            <Heading level={2}>
              <HFlow alignItems='center'>
                <Icon icon='infoCircleOutline' size={3} fill='primary' />
                Versão de treinamento
              </HFlow>
            </Heading>
            <Text>
              A versão de treinamento do e-SUS APS PEC possui todas as funcionalidades da versão de produção e pode ser
              utilizada em ambientes de teste e treinamento, pois não envia informações para o SISAB.
              <br />
              <br />
              Esta versão:
              <ul>
                <li>Não envia informações para o Centralizador Nacional;</li>
                <li>Envia dados somente para outra instalação e-SUS APS PEC de Treinamento;</li>
                <li>Não precisa ser autenticada com contra-chave;</li>
                <li>Apaga as informações digitadas no sistema após 10 dias.</li>
              </ul>
            </Text>
          </VFlow>
        </ModalBody>
      </Modal>
      {info?.treinamento ? (
        <Alert type='warning' inline>
          Esta é uma &nbsp;
          <Link onClick={handleOpen} style={{ fontSize: 'inherit' }}>
            Instalação de treinamento
          </Link>
        </Alert>
      ) : (
        showProducao && <Text style={{ fontSize: 'inherit' }}>Instalação de produção</Text>
      )}
    </>
  )
}
