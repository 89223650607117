/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tag, Theme, useTheme, VFlow } from 'bold-ui'
import { Form, PasswordField, SubmitButton, TextField } from 'components/form'
import { useCapslockState } from 'hooks/useCapslockState'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'

export interface LoginFormModel {
  username: string
  password: string
  force: boolean
}

const validator = createValidator<LoginFormModel>({
  username: [required],
  password: [required],
})

interface LoginFormProps {
  onLogin(values: LoginFormModel): Promise<any>
}

const path = metaPath<LoginFormModel>()

export const LoginForm = (props: LoginFormProps) => {
  const { onLogin } = props

  const isCapsOn = useCapslockState()
  const theme = useTheme()
  const styles = createStyles(theme)

  const renderLogin = (formRenderProps: FormRenderProps<LoginFormModel>) => (
    <form onSubmit={formRenderProps.handleSubmit} noValidate>
      <VFlow style={styles.container}>
        <TextField name={path.username} label='Usuário' clearable={false} autoFocus />
        <VFlow vSpacing={0.5}>
          <PasswordField name='password' label='Senha' clearable={false} maxLength={20} />
          {isCapsOn && <Tag style={styles.capsLockTag}>Caps lock ativado</Tag>}
        </VFlow>
        <div
          css={css`
            margin-top: 0.5rem;
          `}
        >
          <SubmitButton
            style={styles.button}
            block
            handleSubmit={formRenderProps.handleSubmit}
            data-cy='LoginForm.access-button'
          >
            Acessar
          </SubmitButton>
        </div>
      </VFlow>
      <input type='submit' hidden />
    </form>
  )

  return <Form<LoginFormModel> validate={validator} onSubmit={onLogin} render={renderLogin} />
}

const createStyles = (theme: Theme) => ({
  button: css`
    background: linear-gradient(to right, ${theme.pallete.primary.c40}, ${theme.pallete.primary.c60});
    color: ${theme.pallete.gray.c100};
    border: none;
  `,
  capsLockTag: css`
    display: inline-block;
  `,
  container: css`
    min-height: 15rem;
  `,
})
