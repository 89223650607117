import { TextInput, TextInputProps } from 'bold-ui'
import { composeRefs } from 'bold-ui/lib/util/react'
import { debounce } from 'lodash'
import React, { useCallback, useRef, useState } from 'react'

export interface SearchInputProps extends TextInputProps {
  onSearch?: (filter: string) => void
}

export function SearchInput(props: SearchInputProps) {
  const { onSearch, onChange, onClear, inputRef, ...rest } = props

  const [value, setValue] = useState('')
  const searchInputRef = useRef<HTMLInputElement>()

  const debounceOnSearch = useCallback(
    debounce((filter: string) => onSearch && onSearch(filter), 400),
    [onSearch]
  )
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e)
    const filter = e ? e.target.value : ''
    setValue(filter)
    debounceOnSearch(filter)
  }
  const handleSearchClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClear && onClear(e)
    setValue('')
    onSearch && onSearch('')
    searchInputRef.current.focus()
  }

  return (
    <TextInput
      inputRef={composeRefs(searchInputRef, inputRef)}
      value={value}
      onChange={handleSearchChange}
      onClear={handleSearchClear}
      {...rest}
    />
  )
}

SearchInput.defaultProps = {
  clearable: true,
  type: 'search',
  icon: 'zoomOutline',
} as SearchInputProps
