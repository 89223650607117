import { Cell, FormControl, Grid, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { Form, FormPrompt, FormRenderProps, SubmitButton } from 'components/form'
import { FormApi } from 'final-form'
import { useSalvarPodeAtenderEmadMutation } from 'graphql/hooks.generated'
import React from 'react'
import { createValidator, required } from 'util/validation'

import {
  ConfiguracoesADEquipeSelectField,
  ConfiguracoesADEquipeSelectFieldModel,
} from './ConfiguracoesADEquipeSelectField'

export interface PoderAtenderFormModel {
  idEquipePai: ConfiguracoesADEquipeSelectFieldModel
  idEquipeFilho: ConfiguracoesADEquipeSelectFieldModel
}

interface ConfiguracoesADPodeAtenderFormProps {
  reload(): Promise<any>
}

export const validate = createValidator<PoderAtenderFormModel>(
  {
    idEquipePai: [required],
    idEquipeFilho: [required],
  },
  (values: PoderAtenderFormModel, errors) => {
    if (values?.idEquipeFilho && values?.idEquipePai && values.idEquipeFilho.id === values.idEquipePai.id) {
      const msgErro = 'Não é possível relacionar uma EMAD consigo mesma.'
      errors.idEquipePai = msgErro
      errors.idEquipeFilho = msgErro
    }
    return errors
  }
)

export default function ConfiguracoesADPodeAtenderForm(props: ConfiguracoesADPodeAtenderFormProps) {
  const { reload } = props
  const [salvarPodeAtender] = useSalvarPodeAtenderEmadMutation()
  const alert = useAlert()

  const handleSubmit = (result: PoderAtenderFormModel, formApi: FormApi) => {
    return salvarPodeAtender({
      variables: { input: { idEquipePai: result.idEquipePai.id, idEquipeFilho: result.idEquipeFilho.id } },
    }).then(() => {
      alert(
        'success',
        `Relação entre ${result.idEquipePai.nome} - ${result.idEquipePai.ine} e ${result.idEquipeFilho.nome} - ${result.idEquipeFilho.ine} adicionada com sucesso.`
      )
      formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
      setTimeout(formApi.reset)
      reload()
    })
  }

  const renderForm = (formProps: FormRenderProps<PoderAtenderFormModel>) => {
    return (
      <form noValidate>
        <Grid gap={1} style={{ marginTop: '0rem', marginBottom: '0.25rem' }}>
          <Cell flexGrow={1} size={1}>
            <ConfiguracoesADEquipeSelectField name='idEquipePai' label='EMAD' dsTipoEquipe='EMAD' required />
          </Cell>
          <Cell>
            <FormPrompt />
            <FormControl label={<span>&nbsp;</span>}>
              <Box style={{ width: '160px', border: '0px', lineHeight: '2rem', padding: '0', textAlign: 'center' }}>
                <Text fontWeight='bolder'>pode atender cidadãos de</Text>
              </Box>
            </FormControl>
          </Cell>
          <Cell flexGrow={1} size={1}>
            <ConfiguracoesADEquipeSelectField name='idEquipeFilho' label='EMAD' dsTipoEquipe='EMAD' required />
          </Cell>
          <Cell>
            <FormControl label={<span>&nbsp;</span>}>
              <SubmitButton handleSubmit={formProps.handleSubmit} size='small'>
                Adicionar
              </SubmitButton>
            </FormControl>
          </Cell>
        </Grid>
      </form>
    )
  }

  return (
    <Form<PoderAtenderFormModel>
      onSubmit={handleSubmit}
      validate={validate}
      focusOnError={false}
      render={renderForm}
      decorators={[]}
    />
  )
}
