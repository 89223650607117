import { RegistroAplicado, TipoRegistroVacinacaoEnum } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'

import { RegistroVacinacaoFormModel } from '../model'

export interface RegistroProperties {
  doseId?: string
  imunobiologicoId?: string
  tipoRegistro?: TipoRegistroVacinacaoEnum
  idUnicoRegistro?: string
  isAprazamento?: boolean
  isRegistroAnterior?: boolean
}
export interface RegistrosVacinacaoInput {
  isRegistrosAplicados: boolean
  registros: ReadonlyArray<RegistroAplicado | RegistroVacinacaoFormModel>
}

export const getRegistroVacinacao = (
  input: RegistroProperties,
  { isRegistrosAplicados, registros }: RegistrosVacinacaoInput
) => {
  const registroEncontrado = registros.find((registroVacinacao) => {
    if (!matchDoseAndImuno(input, registroVacinacao)) return false

    if (!isRegistrosAplicados && !matchRegistroAplicado(input, registroVacinacao)) return false

    if (
      !isUndefinedOrNull(input?.isAprazamento) &&
      ((!input.isAprazamento && !isUndefinedOrNull(registroVacinacao?.dataAprazamento)) ||
        (input.isAprazamento && isUndefinedOrNull(registroVacinacao?.dataAprazamento)))
    )
      return false

    return true
  })

  return registroEncontrado
}

const matchDoseAndImuno = (input: RegistroProperties, registroVacinacao: any) => {
  if (!!registroVacinacao?.outrosImunosImunobiologico?.id) {
    if (input?.imunobiologicoId && input.imunobiologicoId !== registroVacinacao.outrosImunosImunobiologico.id)
      return false
    if (input?.doseId && input.doseId !== registroVacinacao.outrosImunosDose.id) return false
  } else {
    if (input?.doseId && input.doseId !== registroVacinacao?.doseId) return false
    if (input?.imunobiologicoId && input.imunobiologicoId !== registroVacinacao?.imunobiologicoId) return false
  }

  return true
}

const matchRegistroAplicado = (input: RegistroProperties, registroVacinacao: any) => {
  if (input?.tipoRegistro && input.tipoRegistro !== registroVacinacao?.tipoRegistroVacinacao) return false
  if (!isUndefinedOrNull(input?.idUnicoRegistro)) return false
  if (
    !isUndefinedOrNull(input?.isRegistroAnterior) &&
    input.isRegistroAnterior !== !!registroVacinacao?.isRegistroAnterior
  )
    return false

  return true
}
