import { useAlert } from 'components/alert'
import { useCriarPerfilMutation, usePerfilDetailQuery } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import React from 'react'

import { PerfilFormModel } from './components/PerfilForm'
import { PerfilFormView } from './PerfilFormView'

interface UrlParams {
  id: string
}

export default function PerfilCopyView() {
  const { match, history } = useRouter<UrlParams>()
  const perfilId = match.params.id
  const alert = useAlert()

  const [criarPerfil] = useCriarPerfilMutation()

  const {
    data: { perfil },
  } = usePerfilDetailQuery({
    variables: { perfilId },
  })

  const handleSubmit = (values: PerfilFormModel) => criarPerfil({ variables: { input: values } })
  const handleSubmitSucceeded = () => {
    history.push(`/perfil`)
    alert('success', 'Perfil salvo com sucesso.')
  }

  const initialValues: PerfilFormModel = perfil && {
    nome: `CÓPIA DE ${perfil.nome}`,
    recursos: perfil.recursos,
    tipoPerfil: perfil.tipoPerfil,
  }

  return (
    <PerfilFormView
      breadcrumb='Cópia'
      title='Cópia de perfil'
      onSubmit={handleSubmit}
      onSubmitSucceeded={handleSubmitSucceeded}
      initialValues={initialValues}
      disableTipoAcesso
    />
  )
}
