import { createValidator, ErrorObject, required } from 'util/validation'
import { agendamentoConsultaCompartilhadaValidator } from 'view/agenda/cadastro-agendamento/validator-agenda'
import { agendarConsultasValidator } from 'view/atendimentos/detail/soap/finalizacao/validator/validator-agendamento'

import { GarantiaAcessoAgendaModel } from './GarantiaAcessoAgendarConsultaModalForm'

export const agendarConsultaGarantiaAcessoValidator = (
  getServerTimeNow: () => Date,
  isConsultaCompartilhada: boolean
) =>
  createValidator<GarantiaAcessoAgendaModel>(
    { lotacaoResponsavel: isConsultaCompartilhada && [required] },
    (values: GarantiaAcessoAgendaModel, errors: ErrorObject<GarantiaAcessoAgendaModel>) => {
      if (isConsultaCompartilhada) {
        errors.agendamentoConsultaCompartilhadaInput = agendamentoConsultaCompartilhadaValidator(getServerTimeNow())(
          values.agendamentoConsultaCompartilhadaInput
        )
      } else {
        errors.agendamentoConsultaInput = agendarConsultasValidator(
          getServerTimeNow,
          true
        )(values.agendamentoConsultaInput)
      }

      return errors
    }
  )
