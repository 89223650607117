/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme, useTheme, VFlow } from 'bold-ui'
import { AccordionDataTableRowDefaultProps } from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'
import { useMemo } from 'react'

import { OrientacaoRowModel } from '../types/OrientacoesModel'

export function OrientacoesAccordionPanel(props: AccordionDataTableRowDefaultProps<OrientacaoRowModel>) {
  const { row } = props

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  return (
    <VFlow style={classes.container} vSpacing={0.5}>
      <b>Orientação</b>
      <Text dangerouslySetInnerHTML={{ __html: row.descricao }} />
    </VFlow>
  )
}

const styles = (theme: Theme) => ({
  container: css`
    background: ${theme.pallete.gray.c90};
    padding: 1rem;
  `,
})
