import 'react-big-calendar/lib/css/react-big-calendar.css'

/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, Theme, useTheme } from 'bold-ui'
import { pink } from 'bold-ui/lib/styles/colors'
import { addMinutes, isBefore, max, parseISO, startOfDay, subMinutes } from 'date-fns'
import React, { useCallback, useMemo } from 'react'
import { Calendar as BigCalendar, CalendarProps, Components, View } from 'react-big-calendar'
import { emptyArray } from 'util/array'

import { AgendaComponents } from './Agenda'
import { AgendaEvent } from './AgendaEvent'
import { AgendaEventWrapper } from './AgendaEventWrapper'
import agendaLocalizer from './agendaLocalizer'
import { AgendaTimeSlotWrapper } from './AgendaTimeSlotWrapper'
import { AgendaToolbar } from './AgendaToolbar'
import { AgendaEventModel, AgendaMessages, DateInterval, SlotStatus, WeekTimeIntervals } from './model'
import { computeOccupiedSlots } from './utils'

interface TimeSlotProps {
  children: React.ReactNode
  resource: null
  value: number
}

export interface AgendaDayPeriodProps<TEvent extends AgendaEventModel> {
  date: Date
  getNow(): Date
  isFirst: boolean
  isLast: boolean
  start: Date
  end: Date
  events: ReadonlyArray<TEvent>
  view: View
  stepInMinutes: number
  showUnavailableLimits: boolean
  showToolbar: boolean
  availableTimes: WeekTimeIntervals
  disablePastDates: boolean
  disablePartialSlots: boolean
  messages: AgendaMessages
  minStart: Date
  maxEnd: Date

  style: ExternalStyles

  components: AgendaComponents<TEvent>

  isAvailable(slot: DateInterval): boolean
  onSelectSlot?(start: Date, end: Date): void
  onNavigate?(newDate: Date): void
}

export function AgendaDayPeriod<TEvent extends AgendaEventModel = AgendaEventModel>(
  props: AgendaDayPeriodProps<TEvent>
) {
  const {
    start,
    end,
    date = new Date(),
    getNow = () => new Date(),
    events = emptyArray,
    stepInMinutes = 30,
    showUnavailableLimits = false,
    isFirst,
    isLast,
    components: { AvailableSlotHover, TimeSlot, Event = AgendaEvent },
    disablePastDates,
    disablePartialSlots,
    showToolbar = false,
    view,
    minStart,
    maxEnd,
    messages,
    style: externalStyles,
    isAvailable,
    onSelectSlot,
    onNavigate,
  } = props

  const slotsStart = max([showUnavailableLimits ? subMinutes(minStart, stepInMinutes) : minStart, startOfDay(date)])
  const { isOccupied } = useMemo(() => computeOccupiedSlots(start, stepInMinutes, events), [
    events,
    start,
    stepInMinutes,
  ])
  const getSlotStatus = useCallback(
    (slot) => (isAvailable(slot) && (isOccupied(slot) || SlotStatus.AVAILABLE)) || SlotStatus.UNAVAILABLE,
    [isAvailable, isOccupied]
  )

  const calendarComponents = useMemo<Components<TEvent>>(
    () => ({
      toolbar: AgendaToolbar,
      eventWrapper: (props) => <AgendaEventWrapper {...props} children={Event} />,
      timeSlotWrapper: ({ value, ...rest }: TimeSlotProps) => {
        const end = addMinutes(value, stepInMinutes)
        const slot = {
          start: value,
          end: end,
        }
        const currStatus = getSlotStatus(slot)
        const prevStatus =
          +value !== +slotsStart &&
          getSlotStatus({
            start: addMinutes(value, -stepInMinutes),
            end: value,
          })
        return (
          <AgendaTimeSlotWrapper
            {...rest}
            start={value}
            end={end.getTime()}
            firstOfStatus={
              currStatus !== prevStatus &&
              !(currStatus === SlotStatus.PARTIALLY_OCCUPIED && prevStatus === SlotStatus.OCCUPIED)
            }
            disablePastDates={disablePastDates}
            disablePartialSlots={disablePartialSlots}
            messages={messages}
            status={currStatus}
            components={{ AvailableSlotHover, TimeSlot }}
          />
        )
      },
    }),
    [
      AvailableSlotHover,
      Event,
      TimeSlot,
      disablePartialSlots,
      disablePastDates,
      getSlotStatus,
      messages,
      slotsStart,
      stepInMinutes,
    ]
  )

  const handleSelectSlot =
    onSelectSlot &&
    ((slot: DateInterval) =>
      (disablePastDates && isBefore(slot.start, Date.now())) || !isAvailable(slot) || isOccupied(slot)
        ? false
        : onSelectSlot(slot.start, slot.end))

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  const minSlot = isFirst ? slotsStart : start
  const maxSlot = isLast ? (showUnavailableLimits ? maxEnd : subMinutes(maxEnd, stepInMinutes)) : end
  const timeIntervalEvents = events?.filter(
    (ev) => !isBefore(ev.start, minSlot) && (isLast || isBefore(ev.start, maxSlot))
  )

  return (
    <BigCalendar
      defaultDate={!onNavigate && date}
      date={onNavigate && date}
      events={timeIntervalEvents}
      view={view}
      min={minSlot}
      max={maxSlot}
      step={stepInMinutes}
      timeslots={1}
      css={css(styles, externalStyles)}
      localizer={agendaLocalizer}
      defaultView='day'
      culture='pt-BR'
      getNow={getNow}
      components={calendarComponents as any}
      toolbar={!isFirst && showToolbar}
      showMultiDayTimes
      selectable={!!handleSelectSlot}
      onSelectSlot={({ start, end }) =>
        handleSelectSlot({ start: parseDateOrString(start), end: parseDateOrString(end) })
      }
      onNavigate={onNavigate}
      onSelecting={() => false} //Disables dragging select
      dayLayoutAlgorithm='no-overlap'
      {...accessors}
    />
  )
}

const accessors: Partial<CalendarProps<AgendaEventModel>> = {
  startAccessor: 'start',
  endAccessor: 'end',
  titleAccessor: 'title',
  allDayAccessor: () => false,
}

const parseDateOrString = (date: Date | string) => (typeof date === 'string' ? parseISO(date) : date)

const createStyles = (theme: Theme) => css`
  &.rbc-calendar {
    height: auto;

    &:first-of-type {
      .rbc-time-content {
        padding-top: 1.5rem;
      }
    }
  }

  .rbc-time-content {
    padding: 0;
    border-top-width: 0;
    overflow-y: visible;
  }

  .rbc-events-container {
    margin-right: 0;
  }

  .rbc-timeslot-group,
  .rbc-events-container,
  .rbc-time-view {
    border-width: 0;
  }

  .rbc-time-slot {
    padding-left: 1rem;
    padding-right: 0.5rem;
    min-height: 3.25rem;
    max-height: 3.25rem;

    .rbc-label {
      position: relative;
      top: -0.55rem;
    }
  }

  .rbc-day-slot .rbc-time-slot {
    border-top-color: ${theme.pallete.divider};
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-time-view .rbc-header {
    border-bottom: none;
  }

  .rbc-current-time-indicator {
    height: 2px;
    background-color: ${pink.c40};

    &::before {
      content: '';
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 0.25rem;
      background-color: ${pink.c40};
      position: relative;
      top: -0.55rem;
      left: -0.5rem;
    }
  }

  .rbc-today {
    background-color: transparent;
  }
`
