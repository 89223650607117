import { Text, Theme, useStyles } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { Box } from 'components/Box'
import React, { CSSProperties } from 'react'

interface ConfiguracaoAgendaOnlineSimpleBoxProps {
  horario?: string
}

export function ConfiguracaoAgendaOnlineSimpleBox(props: ConfiguracaoAgendaOnlineSimpleBoxProps) {
  const { classes } = useStyles(createStyles)

  return (
    <Box style={classes.default}>
      <Text fontWeight='bold'>{props.horario}</Text>
    </Box>
  )
}

const createStyles = (theme: Theme) => ({
  default: {
    userSelect: 'none',
    borderRadius: 0,
    border: 0,
    borderRight: '1px solid' + gray.c80,
    borderBottom: '1px solid' + gray.c80,
    ':not(:disabled):hover': {},
    ':last-child': {
      borderBottom: 0,
    },
  } as CSSProperties,
})
