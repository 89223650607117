import { AtendimentoVacinacaoInput } from 'graphql/types.generated'
import { convertFinalizacaoFormToInput } from 'view/atendimentos/detail/soap/finalizacao'

import { convertCondicoesToInput } from '../condicoes/converter'
import { AtendimentoVacinacaoState } from '../model'
import { convertRegistroVacinacaoToInput } from '../registros/converter'

export const convertAtendimentoVacinacaoModelToInput = (
  atendimentoId: ID,
  values: AtendimentoVacinacaoState
): AtendimentoVacinacaoInput => ({
  id: atendimentoId,
  condicoesVacinacao: convertCondicoesToInput(values.condicoesVacinacao, values.registroVacinacao),
  finalizacaoVacinacao: convertFinalizacaoFormToInput(values.finalizacaoVacinacao),
  registroVacinacao: convertRegistroVacinacaoToInput(values.registroVacinacao),
  imprimirAtendimento: values.imprimirAtendimento,
})
