import useSession from 'components/auth/useSession'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import * as React from 'react'
import { Redirect } from 'react-router'
import Permissions from 'types/Permissions'

import { TransmissaoEnvioGeralView } from './TransmissaoEnvioGeralView'

export interface TransmissaoEnvioRootProps {}
export const TransmissaoEnvioRoot = (props: TransmissaoEnvioRootProps) => {
  const { match } = useRouter()
  const { hasAuthorization } = useSession()
  return (
    <PecSwitch>
      {hasAuthorization(Permissions.transmissaoDeDados.visualizarEnvioDeDados) && (
        <Redirect exact from={`${match.url}`} to={`${match.url}/lotes/`} />
      )}
      <PrivateRoute
        exact
        path={`${match.url}/lotes/`}
        permission={Permissions.transmissaoDeDados.visualizarEnvioDeDados}
        component={TransmissaoEnvioGeralView}
      />
    </PecSwitch>
  )
}
