import { Cell, Grid, HFlow, Icon, PagedTable, Text, Tooltip } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors/blue'
import { DateTime } from 'components/date'
import { PageLoading } from 'components/loading'
import { TableBox } from 'components/table/TableBox'
import { usePagination } from 'components/table/usePagination'
import { format } from 'date-fns'
import { parseISO } from 'date-fns/esm'
import { css } from 'emotion'
import { useAcompanhamentoPreNatalQuery } from 'graphql/hooks.generated'
import { isNil } from 'lodash'
import React, { useMemo } from 'react'
import { calculateIdadeGestacional } from 'util/atendimento/gestante/calculateIdadeGestacional'
import { formatNumber } from 'util/number'
import {
  MedicoesPreNatal,
  MedicoesPreNatalAtendimentoModel,
} from 'view/atendimentos/detail/components/modals/types/HistoricoPreNatalModel'

import { convertDadosPreNatal, convertToGrafico } from '../../../soap/aside/pre-natal/util/AcompanhamentoPreNatalUtils'
import { GraficoAlturaUterinaView } from './graficos-antropometria/GraficoAlturaUterinaView'

interface HistoricoMedicoesPreNatalViewProps extends MedicoesPreNatalAtendimentoModel {
  prontuarioId: ID
  readOnly?: boolean
}

function renderConsulta(valor: number) {
  return <Text>{valor}ª</Text>
}

function renderDataMedicao(valor: LocalDate) {
  return <Text>{valor ? <DateTime value={valor} format={`DD/MM/YYYY`}></DateTime> : '-'}</Text>
}

function renderIdadeGestacional(dataInicioGestacao: Date, dataConsulta: LocalDate) {
  const {
    emSemanas: { semanas, dias },
    hasIdadeGestacional,
  } = calculateIdadeGestacional(dataInicioGestacao, parseISO(dataConsulta))
  return <Text>{hasIdadeGestacional ? `${semanas}s e ${dias}d` : '-'}</Text>
}

function renderEdema(valor: string) {
  return <Text>{valor ? `(${valor})` : '-'}</Text>
}

function renderMovimentacaoFetal(valor: boolean, isRegistradoAgora: boolean) {
  return (
    <HFlow alignItems='center' justifyContent='space-between'>
      <Text>{isNil(valor) ? '-' : `${valor ? 'Sim' : 'Não'}`}</Text>
      {isRegistradoAgora && (
        <Tooltip text='Registrado agora'>
          <Icon color={blue.c40} size={1} icon='clockOutline' />
        </Tooltip>
      )}
    </HFlow>
  )
}

function renderOutros(valor: string) {
  return <Text>{valor ? formatNumber(valor) : '-'}</Text>
}

export function HistoricoMedicoesPreNatalView(props: HistoricoMedicoesPreNatalViewProps) {
  const {
    prontuarioId,
    preNatalAtendimentoAtual,
    dataAtendimento,
    resultadosExamesAtendimentoAtual,
    dumAtendimentoAtual,
    readOnly = false,
  } = props

  const { data, loading } = useAcompanhamentoPreNatalQuery({
    variables: { input: prontuarioId },
  })

  const dataConverted = useMemo(
    () =>
      convertDadosPreNatal({
        data,
        readOnly,
        preNatalAtendimentoAtual,
        resultadosExamesAtendimentoAtual,
        dumAtendimentoAtual,
        dataAtendimento,
      }),
    [data, readOnly, preNatalAtendimentoAtual, resultadosExamesAtendimentoAtual, dumAtendimentoAtual, dataAtendimento]
  )

  const { paginatedItems, tableProps: propsPagination } = usePagination({ items: dataConverted?.medicoesPreNatal })
  const { pageSize, ...propsPaginationToPagedTable } = propsPagination

  const tableProps = {
    ...propsPaginationToPagedTable,
    size: pageSize,
    rows: paginatedItems,
  }

  const labelIG = (
    <HFlow alignItems='center' justifyContent='space-between' hSpacing={0.3}>
      <Text>{dataConverted?.isEcografico ? 'IG eco.' : 'IG cron.'}</Text>
      {dataConverted?.hasExamesPreNatalAtendimento && (
        <Tooltip text='Registrado agora'>
          <Icon color={blue.c40} size={1} icon='clockOutline' />
        </Tooltip>
      )}
    </HFlow>
  )

  return (
    <Grid>
      {loading && <PageLoading message='Carregando dados' />}
      {!loading && (
        <>
          <Cell size={12}>
            <TableBox>
              <PagedTable<MedicoesPreNatal>
                {...tableProps}
                columns={[
                  {
                    header: 'Consulta',
                    name: 'numeroConsulta',
                    render: (preNatal) => renderConsulta(preNatal.numeroConsulta),
                    style: estilo,
                  },
                  {
                    header: 'Data da medição',
                    name: 'dataMedicao',
                    render: (preNatal) => renderDataMedicao(preNatal.dataDaMedicao),
                    style: estilo,
                  },
                  {
                    header: labelIG,
                    name: 'idadeGestacional',
                    render: (preNatal) =>
                      renderIdadeGestacional(dataConverted?.dataInicioGestacao, preNatal.dataDaMedicao),
                    style: estilo,
                  },
                  {
                    header: 'Edema',
                    name: 'edema',
                    render: (preNatal) => renderEdema(preNatal?.edema),
                    style: estilo,
                  },
                  {
                    header: 'Altura uterina (cm)',
                    name: 'alturaUterina',
                    render: (preNatal) => renderOutros(preNatal.alturaUterina),
                    style: estilo,
                  },
                  {
                    header: 'BCF (bpm)',
                    name: 'batimentoCardiacoFetal',
                    render: (preNatal) => renderOutros(preNatal.batimentoCardiacoFetal),
                    style: estilo,
                  },
                  {
                    header: 'Movimentação fetal',
                    name: 'movimentacaoFetal',
                    render: (preNatal) =>
                      renderMovimentacaoFetal(preNatal?.movimentacaoFetal, preNatal.isRegistradoAgora),
                    style: estilo,
                  },
                ]}
              />
            </TableBox>
          </Cell>
          {dataConverted?.dataInicioGestacao && (
            <Cell size={12}>
              <GraficoAlturaUterinaView
                medicoes={convertToGrafico(dataConverted?.medicoesPreNatal) ?? []}
                gestacao={{
                  id: null,
                  inicio: format(dataConverted?.dataInicioGestacao, 'yyyy-MM-dd'),
                  fim: null,
                }}
              />
            </Cell>
          )}
        </>
      )}
    </Grid>
  )
}

const estilo = css`
  width: '5.5rem';
`
