import { CidadaoSelectFieldItem } from 'components/form/field/select/CidadaoSelectField/CidadaoSelectFieldItem'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { SelectFieldWithAddButton } from 'components/form/final-form/SelectField/SelectFieldWithAddButton'
import { CidadaoListaAdSelectFieldDocument } from 'graphql/hooks.generated'
import { CidadaoListaAdSelectFieldQuery, CidadaoListaAdSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'
import { useHistory } from 'react-router'

import { CidadaoListaAdSelectFieldModel } from './model-ad'

export interface CidadaoListaADSelectFieldProps extends AsyncSelectFieldProps<CidadaoListaAdSelectFieldModel> {
  ativo?: boolean
  obito?: boolean
  resetFieldState(): void
}

export function CidadaoListaADSelectField(props: CidadaoListaADSelectFieldProps) {
  const { ativo, obito, resetFieldState, ...rest } = props
  const history = useHistory()

  const { selectProps } = useAsyncQuerySelect<
    CidadaoListaAdSelectFieldModel,
    CidadaoListaAdSelectFieldQuery,
    CidadaoListaAdSelectFieldQueryVariables
  >({
    query: CidadaoListaAdSelectFieldDocument,
    extractItems: (data) => data?.cidadaosCombo,
    variables: (inputString) => ({
      input: {
        query: inputString,
        ativo: ativo,
        obito: obito,
        limit: 10,
      },
      emAtencaoDomiciliar: false,
    }),
    skip: (inputString) => inputString?.trim()?.length < 3,
    debounceTime: 500,
  })

  const itemToString = (item: CidadaoListaAdSelectFieldModel) => item?.nomeSocial || item?.nome

  const renderItem = (item: CidadaoListaAdSelectFieldModel) => {
    return item && <CidadaoSelectFieldItem item={item} />
  }

  const renderSelectedCustomText = (item: CidadaoListaAdSelectFieldModel) => {
    return `Em AD, sob a responsabilidade da equipe: ${item?.equipeAtendimentoAd?.ine} - ${item?.equipeAtendimentoAd?.nome}`
  }

  const isEmAtendimentoAd = (item: CidadaoListaAdSelectFieldModel) => {
    return item.equipeAtendimentoAd?.id !== undefined
  }

  const onClickCadastrarButton = () => {
    resetFieldState()
    return history.push('/cidadao?callbackUrl=/ad/lista/cadastro')
  }

  return (
    <SelectFieldWithAddButton<CidadaoListaAdSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      itemIsEqual={isEmAtendimentoAd}
      customText={renderSelectedCustomText}
      menuMinWidth={500}
      popperProps={{ modifiers: [{ name: 'preventOverflow', enabled: false }] }}
      addText='Cadastre um cidadão somente se não o encontrar na busca.'
      addButtonText='Cadastrar cidadão'
      onClickAddButton={onClickCadastrarButton}
      {...selectProps}
      {...rest}
    />
  )
}
