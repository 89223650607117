import { PecSwitch } from 'components/route'
import { useAutorizarMunicipioQuery } from 'graphql/hooks.generated'
import { TipoInstalacao } from 'graphql/types.generated'
import React from 'react'
import { Redirect, Route, useLocation } from 'react-router'

import { AutorizarMunicipioAtivarInstalacaoForm } from './AutorizarMunicipioAtivarInstalacaoForm'
import { AutorizarMunicipioConfigAgendaPadraoForm } from './AutorizarMunicipioConfigAgendaPadraoForm'
import { AutorizarMunicipioImportarCnesForm } from './AutorizarMunicipioImportarCnesForm'
import { AutorizarMunicipioLayout, AutorizarMunicipioSteps } from './AutorizarMunicipioLayout'

export interface AutorizarMunicipioRootViewProps {
  acessoId: ID
}

export const AutorizarMunicipioRootView = ({ acessoId }: AutorizarMunicipioRootViewProps) => {
  const location = useLocation()
  // TODO usar o acesso da sessão
  const { data } = useAutorizarMunicipioQuery({
    variables: { acessoId },
  })
  const isTreinamento = data?.info?.treinamento
  const isProntuario = TipoInstalacao.PRONTUARIO === data?.info?.tipoInstalacao

  if (!data.acesso || !data.info) {
    return null
  }

  const renderAtivarInstalacao = () => {
    return (
      <AutorizarMunicipioAtivarInstalacaoForm
        acesso={data && data.acesso}
        isTreinamento={isTreinamento}
        isProntuario={isProntuario}
      />
    )
  }

  const renderConfiguracaoAgenda = () => {
    return <AutorizarMunicipioConfigAgendaPadraoForm municipio={data.acesso.municipio} />
  }

  const renderImportCNES = () => {
    return (
      <AutorizarMunicipioImportarCnesForm
        municipio={data.acesso.municipio}
        isTreinamento={isTreinamento}
        isProntuario={isProntuario}
      />
    )
  }

  const currentStep =
    (location.pathname === '/autorizar/ativar' && AutorizarMunicipioSteps.ATIVAR) ||
    (location.pathname === '/autorizar/agenda' && AutorizarMunicipioSteps.CONFIGURAR_AGENDA_PADRAO) ||
    (location.pathname === '/autorizar/cnes' && AutorizarMunicipioSteps.IMPORTAR_CNES)

  return (
    <AutorizarMunicipioLayout step={currentStep} isProntuario={isProntuario}>
      <PecSwitch>
        {data.acesso.__typename === 'AdministradorMunicipal' && !data.acesso.autorizado && (
          <Redirect from='/' to='/autorizar/ativar' exact />
        )}
        {data.acesso.__typename === 'AdministradorMunicipal' && isProntuario && data.acesso.autorizado && (
          <Redirect from='/' to='/autorizar/agenda' exact />
        )}
        {data.acesso.__typename === 'AdministradorMunicipal' && !isProntuario && data.acesso.autorizado && (
          <Redirect from='/' to='/autorizar/cnes' exact />
        )}

        <Route path='/autorizar/ativar' render={renderAtivarInstalacao} exact />
        <Route path='/autorizar/agenda' render={renderConfiguracaoAgenda} exact />
        <Route path='/autorizar/cnes' render={renderImportCNES} exact />
      </PecSwitch>
    </AutorizarMunicipioLayout>
  )
}
