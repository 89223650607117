import { SituacaoProblema } from 'graphql/types.generated'
import { isEqual, partition } from 'lodash'
import { useMemo } from 'react'
import { emptyArray } from 'util/array'

import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import { EvolucaoProblema, Problema } from '../types/ProblemaModel'

export function useFormatProblemas(
  problemas: ReadonlyArray<Problema> = emptyArray,
  problemasAvaliacao: ReadonlyArray<ProblemaCondicaoModel> = emptyArray,
  problemasLPC: ReadonlyArray<ProblemaCondicaoModel> = emptyArray,
  isAtendimentoObservacao: boolean = false
) {
  return useMemo(() => {
    const {
      resolvidosAtuais,
      resolvidosAntigos,
      ativosLatentesAtuais,
      ativosLatentesAntigos,
      ativosLatentesMergiados,
    } = filterAndSortProblemas(problemas, problemasAvaliacao, problemasLPC, isAtendimentoObservacao)

    const getProblemasAtivosLatentesAtuais = () => ativosLatentesAtuais
    const getProblemasAtivosLatentesAntigos = () => ativosLatentesAntigos
    const getProblemasResolvidosAtuais = () => resolvidosAtuais
    const getProblemasResolvidosAntigos = () => resolvidosAntigos
    const getProblemasAtivosLatentesMergiados = () => ativosLatentesMergiados

    const gruposProblemas = breakProblemas(getProblemasAtivosLatentesMergiados())
    const getProblemasExpandido = () => gruposProblemas.problemasExpandido
    const getProblemasAccordion = () => gruposProblemas.problemasAccordion

    return {
      getProblemasExpandido,
      getProblemasAccordion,
      getProblemasAtivosLatentesAtuais,
      getProblemasResolvidosAtuais,
      getProblemasAtivosLatentesAntigos,
      getProblemasResolvidosAntigos,
      getProblemasAtivosLatentesMergiados,
    }
  }, [isAtendimentoObservacao, problemas, problemasAvaliacao, problemasLPC])
}

/**
 *
 * @param {Problema[]} problemas lista de todos os problemas
 * @param {ProblemaCondicaoModel[]} problemasAvaliados lista de problemas avaliados no atendimento atual
 * @param {boolean} isResolvido se true retornará os problemas RESOLVIDOS, senão retornará os problemas ATIVOS e LATENTES
 * @returns {Problema[]} lista de problemas avaliados, ativos, latentes e resolvidos ordenados de forma decrescente em relação a data da última atualização
 */
export const filterAndSortProblemas = (
  problemasAntigos: ReadonlyArray<Problema> = emptyArray,
  problemasAvaliados: ReadonlyArray<ProblemaCondicaoModel> = emptyArray,
  problemasLpc: ReadonlyArray<ProblemaCondicaoModel> = emptyArray,
  isAtendimentoObservacao: boolean = false
) => {
  const problemasAvaliadosIncluidosLPC = problemasAvaliados
    .filter(({ incluirNaListaProblemas }) => incluirNaListaProblemas)
    .map(convertAvaliacaoToProblema)

  const problemasLPCConvertidos = problemasLpc.map(convertLPCToProblema)

  const problemasAtuais = [...problemasAvaliadosIncluidosLPC, ...problemasLPCConvertidos]

  const problemasAntigosFiltrados = isAtendimentoObservacao
    ? updateProblemasAntigosComparingToProblemasAtuais(problemasAntigos, problemasAtuais)
    : problemasAntigos

  const [resolvidosAtuais, ativosLatentesAtuais] = partition(
    problemasAtuais,
    (problema) => problema.situacao === SituacaoProblema.RESOLVIDO
  )

  const [resolvidosAntigos, ativosLatentesAntigos] = partition(
    problemasAntigosFiltrados,
    (problema) => problema.situacao === SituacaoProblema.RESOLVIDO
  )

  const ativosLatentesMergiados = mergeProblemas(problemasAntigos, problemasAvaliados, problemasLpc, false)

  return {
    resolvidosAtuais: sortProblemasByUltimaEvolucaoAtualizadoEm(resolvidosAtuais),
    resolvidosAntigos: sortProblemasByUltimaEvolucaoAtualizadoEm(resolvidosAntigos),
    ativosLatentesAtuais: sortProblemasByUltimaEvolucaoAtualizadoEm(ativosLatentesAtuais),
    ativosLatentesAntigos: sortProblemasByUltimaEvolucaoAtualizadoEm(ativosLatentesAntigos),
    ativosLatentesMergiados: sortProblemasByUltimaEvolucaoAtualizadoEm(ativosLatentesMergiados),
  }
}

const updateProblemasAntigosComparingToProblemasAtuais = (
  problemasAntigos: ReadonlyArray<Problema> = emptyArray,
  problemasAtuais: ReadonlyArray<Problema> = emptyArray
): Problema[] => {
  return problemasAntigos
    .map((problema) => {
      const problemaAntigoNosAtuais = problemasAtuais.find(
        (atual) => atual.ciap?.id === problema?.ciap?.id && atual?.cid10?.id === problema?.cid10?.id
      )

      if (!problemaAntigoNosAtuais) {
        return problema
      }

      return updateProblemaAntigoComparingToProblemaAtual(problema, problemaAntigoNosAtuais)
    })
    .filter((item) => item.evolucoes?.isNotEmpty())
}

export const updateProblemaAntigoComparingToProblemaAtual = (
  problemaAntigo: Problema,
  problemaAtual: Problema
): Problema => {
  const isUltimaEvolucaoEqualToEvolucaoAtual =
    problemaAtual && isEvolucaoProblemaEqual(problemaAntigo.ultimaEvolucao, problemaAtual.ultimaEvolucao)

  const evolucoes = isUltimaEvolucaoEqualToEvolucaoAtual
    ? problemaAntigo.evolucoes.filter((item) => item.id !== problemaAntigo.ultimaEvolucao.id)
    : problemaAntigo.evolucoes

  const ultimaEvolucao = evolucoes?.[0]

  return {
    ...problemaAntigo,
    evolucoes,
    ultimaEvolucao,
    situacao: ultimaEvolucao?.situacao,
  }
}

const mergeProblemas = (
  problemasAntigos: ReadonlyArray<Problema> = emptyArray,
  problemasAvaliados: ReadonlyArray<ProblemaCondicaoModel> = emptyArray,
  problemasLpc: ReadonlyArray<ProblemaCondicaoModel> = emptyArray,
  isResolvido: boolean = false
): Problema[] => {
  return problemasAntigos
    .concat(extractNovosProblemas(problemasAvaliados, convertAvaliacaoToProblema))
    .map((problema) => mergeProblema(problema, problemasAvaliados, convertAvaliacaoToProblema))
    .concat(extractNovosProblemas(problemasLpc, convertLPCToProblema))
    .map((problema) => mergeProblema(problema, problemasLpc, convertLPCToProblema))
    .filter(
      (problema) =>
        problema &&
        (isResolvido
          ? problema.situacao === SituacaoProblema.RESOLVIDO
          : problema.situacao !== SituacaoProblema.RESOLVIDO)
    )
}

const mergeProblema = (
  problema: Problema,
  problemasListToMerge: ReadonlyArray<ProblemaCondicaoModel>,
  convertToProblema: (avaliacao: ProblemaCondicaoModel) => Problema
): Problema => {
  const problemaAvaliado = problemasListToMerge.find(
    (pa) => pa.incluirNaListaProblemas && pa.problemaId && pa.problemaId === problema.id
  )
  return problemaAvaliado
    ? {
        ...convertToProblema(problemaAvaliado),
        evolucoes: [convertAvaliacaoToEvolucao(problemaAvaliado), ...(problema.evolucoes ?? [])],
      }
    : problema
}

const extractNovosProblemas = (
  problemas: ReadonlyArray<ProblemaCondicaoModel>,
  mapFunction: (avaliacao: ProblemaCondicaoModel) => Problema
): Problema[] =>
  problemas.filter((p) => (p.incluirNaListaProblemas && !p.problemaId) || !!p.isRegistradoAgora).map(mapFunction)

const isEvolucaoProblemaEqual = (evolucao: EvolucaoProblema, evolucaoToCompare: EvolucaoProblema) => {
  return (
    isEqual(evolucao.situacao ?? null, evolucaoToCompare.situacao ?? null) &&
    isEqual(evolucao.dataInicio ?? null, evolucaoToCompare.dataInicio ?? null) &&
    isEqual(evolucao.dataFim ?? null, evolucaoToCompare.dataFim ?? null) &&
    isEqual(evolucao.observacao || null, evolucaoToCompare.observacao || null)
  )
}

/**
 * Se o total de problemas for menor ou igual a 6, todas devem ser apresentadas no grupo expandido e o accordion não deve ser exibido
 * Se o total de problemas for 6 ou +, no grupo de expandidos deve possuir apenas 5 problemas e o restante deve ficar dentro do accordion
 * @param {Problema[]} problemasAtivosLatentes lista de problemas ativos e latentes
 * @returns {{problemasExpandido: Problema[]; problemasAccordion: Problema[]}} listas de problemas a serem apresentados no card e no accordion
 */
export const breakProblemas = (problemasAtivosLatentes: Problema[]) => {
  const totalProblemas = problemasAtivosLatentes.length
  const problemasExpandido: Problema[] = []
  const problemasAccordion: Problema[] = []

  totalProblemas > 6 && problemasAccordion.push(...problemasAtivosLatentes.splice(5, totalProblemas - 1))
  problemasExpandido.push(...problemasAtivosLatentes)

  return { problemasExpandido, problemasAccordion }
}

const sortProblemasByUltimaEvolucaoAtualizadoEm = (problemas: Problema[]) =>
  problemas?.sort(
    (a, b) => (b.ultimaEvolucao.atualizadoEm ?? Date.now()) - (a.ultimaEvolucao.atualizadoEm ?? Date.now())
  ) ?? []

const convertAvaliacaoToEvolucao = (avaliacao: ProblemaCondicaoModel): EvolucaoProblema => ({
  id: avaliacao._id,
  situacao: avaliacao.situacaoProblema,
  dataInicio: avaliacao.dataInicio?.data,
  dataFim: avaliacao.dataFim?.data,
  observacao: avaliacao.observacao,
  possuiCiap: !!avaliacao.ciap,
  possuiCid: !!avaliacao.cid,
})

function convertAvaliacaoToProblema(avaliacao: ProblemaCondicaoModel): Problema {
  const evolucao = convertAvaliacaoToEvolucao(avaliacao)
  return {
    ...convertProblemaCondicaoToProblema(avaliacao),
    evolucoes: [evolucao],
    ultimaEvolucao: evolucao,
    isAvaliadoAgora: true,
    isRegistradoAgora: avaliacao.isRegistradoAgora,
  }
}

function convertLPCToProblema(lpc: ProblemaCondicaoModel): Problema {
  const evolucao = convertAvaliacaoToEvolucao(lpc)
  return {
    ...convertProblemaCondicaoToProblema(lpc),
    evolucoes: [evolucao],
    ultimaEvolucao: evolucao,
    isAvaliadoAgora: false,
    isRegistradoAgora: true,
  }
}

function convertProblemaCondicaoToProblema(problemaCondicao: ProblemaCondicaoModel): Problema {
  return {
    id: problemaCondicao.problemaId ?? problemaCondicao._id,
    ciap: problemaCondicao.ciap,
    cid10: problemaCondicao.cid,
    descricaoOutro: problemaCondicao.problemaCondicaoEvoluir?.descricaoOutro,
    situacao: problemaCondicao.situacaoProblema,
    isAutomatico: problemaCondicao.isAutomatico,
  }
}
