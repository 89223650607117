/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import { format, isEqual } from 'date-fns'

import { FolhaRostoCirurgiaInternacaoModel } from '../model'

interface CirurgiaInternacaoRowProps {
  cirurgiaInternacao: FolhaRostoCirurgiaInternacaoModel
  cidadaoDataNascimento: Date
}

export function CirurgiaInternacaoRow(props: CirurgiaInternacaoRowProps) {
  const { cirurgiaInternacao, cidadaoDataNascimento } = props

  const textoData = cirurgiaInternacao.dataRealizada
    ? `Realizada em ${
        cirurgiaInternacao.dataRealizada.data ? format(cirurgiaInternacao.dataRealizada.data, 'dd/MM/yyyy') : '-'
      } ${
        isEqual(cirurgiaInternacao.dataRealizada.data, cidadaoDataNascimento)
          ? `| ${cirurgiaInternacao.dataRealizada.idade}`
          : `com ${cirurgiaInternacao.dataRealizada.idade} de idade`
      }`
    : 'Sem registro de data de realização'

  return (
    <VFlow vSpacing={0}>
      <Ellipsis fontWeight='bold'>{cirurgiaInternacao.descricao}</Ellipsis>
      <Text>{textoData}</Text>
    </VFlow>
  )
}
