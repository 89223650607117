import { Button, HFlow, Icon, SelectDownshiftRenderProps, Text } from 'bold-ui'
import { SelectDownshiftComponentCustom } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import { SelectField, SelectFieldProps } from 'components/form'
import React, { useEffect, useRef, useState } from 'react'

export interface SelectFieldWithAddButtonProps<T = any> extends SelectFieldProps<T> {
  addText: string
  addButtonText: string
  showingAppend?: boolean
  onClickAddButton(): void
}

export function SelectFieldWithAddButton<T = any>(props: SelectFieldWithAddButtonProps<T>) {
  const { addText, addButtonText, onClickAddButton, onFocus, onChange, onFilterChange, components, ...rest } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef<HTMLInputElement>()
  const addButtonRef = useRef<HTMLButtonElement>()
  const showingAppend = props.showingAppend === undefined ? true : props.showingAppend

  useEffect(() => {
    const elem = ref.current
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && !e.shiftKey && addButtonRef.current) {
        e.preventDefault()
        addButtonRef.current.focus()
      }
    }

    elem.addEventListener('keydown', handleKeyDown)
  }, [isOpen])

  const handleChange = (value: T) => {
    onChange && onChange(value)
    if (!!value) setIsOpen(false)
  }

  const handleFocus = () => {
    onFocus && onFocus()
    setIsOpen(true)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget !== addButtonRef.current) setIsOpen(false)
  }

  const handleAddButtonBlur = (e: React.FocusEvent<HTMLButtonElement>) => {
    if (e.relatedTarget !== ref.current) setIsOpen(false)
  }

  const handleFilterChange = (a, b: SelectDownshiftRenderProps<T>) => {
    if (!isOpen) setIsOpen(true)
    onFilterChange(a, b)
  }

  return (
    <SelectField<T>
      {...rest}
      inputRef={ref}
      onFocus={handleFocus}
      onChange={handleChange}
      onBlur={handleBlur}
      onFilterChange={onFilterChange && handleFilterChange}
      isOpen={isOpen}
      components={{
        ...components,
        AppendItem: (props) =>
          showingAppend &&
          !props.loading && (
            <SelectDownshiftComponentCustom>
              <HFlow alignItems='center' justifyContent='space-between'>
                <Text>{addText}</Text>

                <Button
                  onMouseDown={onClickAddButton}
                  onClick={onClickAddButton}
                  onBlur={handleAddButtonBlur}
                  type='button'
                  kind='primary'
                  size='small'
                  innerRef={addButtonRef}
                >
                  <Icon icon='plus' style={{ marginRight: '0.25rem' }} />
                  {addButtonText}
                </Button>
              </HFlow>
            </SelectDownshiftComponentCustom>
          ),
      }}
    />
  )
}
