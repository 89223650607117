/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert } from 'bold-ui'
import moment from 'moment'
import { Fragment, useMemo } from 'react'
import { estadoNutricional } from 'util/atendimento'
import { parseNumber } from 'util/number'
import { PeriodoGestacaoModel } from 'view/atendimentos/detail/components/modals/types/PeriodoGestacaoModel'
import { CidadaoMedicoes } from 'view/atendimentos/detail/soap/aside/medicoes/model'

interface AlertImcProps {
  imc: string
  dataMedicao: number
  cidadao: CidadaoMedicoes
  gestacoes: ReadonlyArray<PeriodoGestacaoModel>
}

export const AlertImc = (props: AlertImcProps) => {
  const { imc, dataMedicao, cidadao, gestacoes } = props
  const classes = createStyles()

  const idadeDuranteMedicao = moment
    .duration(moment(dataMedicao).diff(cidadao.dataNascimento, 'days'), 'days')
    .asYears()

  const msgEstadoNutricional = useMemo(
    () =>
      imc &&
      estadoNutricional(parseNumber(imc), cidadao.sexo, idadeDuranteMedicao, gestacoes, cidadao.identidadeGeneroDbEnum),
    [imc, cidadao.sexo, cidadao.identidadeGeneroDbEnum, idadeDuranteMedicao, gestacoes]
  )

  return (
    <Fragment>
      {msgEstadoNutricional && (
        <Alert inline type={msgEstadoNutricional.alertType} styles={{ wrapper: classes.alertWrapper }}>
          {msgEstadoNutricional.message}
        </Alert>
      )}
    </Fragment>
  )
}

const createStyles = () => ({
  alertWrapper: css`
    background-color: transparent;
    border-width: 0;
    padding: 0;
    min-height: 0;
  `,
})
