import { PrescricaoDigitalIntegracaoMessageProps } from './PrescricaoDigitalIntegracaoMessage'

export const MEDICAMENTOS_NAO_PERMITIDOS_TOOLTIP_TEXT =
  'Medicamentos do tipo Especial - notificação branca, azul e amarela e presentes nas categorias C4, C5 e D2 não podem ser prescritos digitalmente. Realize a impressão para encaminhar a prescrição desses medicamentos'

export interface PrescricaoDigitalInvalidarInput {
  atendimentoId: ID
  codigoPrescricao: string
}

export type PrescricaoDigitalIntegracaoModel = Pick<
  PrescricaoDigitalIntegracaoMessageProps,
  'success' | 'message' | 'errorCode'
>

export type PrescricaoDigitalFormModel = Record<
  string,
  {
    prescricaoInvalidada: boolean
    emailEnvio: string
    dataAssinatura: string
    textoMedicamentos: string
  }
>
