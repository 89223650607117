import { Heading, TabItem, Tabs, VFlow } from 'bold-ui'
import React, { useState } from 'react'
import { HistoricoEvolucoesOdontoTable } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTable'
import HistoricoEvolucoesPeriodontaisTable from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/periograma/historico-evolucoes-periodontais/HistoricoEvolucoesPeriodontaisTable'
import HistoricoTecidosMolesTable from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/tecidos-moles/historico-tecidos-moles/HistoricoTecidosMolesTable'

import { OdontogramaFolhaRostoView } from './OdontogramaFolhaRostoView'

enum ActiveTab {
  ODONTOGRAMA,
  TECIDOS_MOLES,
  PERIODONTIA,
}

interface OdontologicoFolhaRostoViewProps {
  prontuarioId: ID
  dataReferencia: Instant
  cidadaoDataNascimento: LocalDate
}

export const OdontologicoFolhaRostoView = (props: OdontologicoFolhaRostoViewProps) => {
  const { prontuarioId, dataReferencia, cidadaoDataNascimento } = props

  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.ODONTOGRAMA)

  return (
    <VFlow>
      <Heading level={3}>Evoluções odontológicas</Heading>

      <Tabs>
        <TabItem active={activeTab === ActiveTab.ODONTOGRAMA} onClick={() => setActiveTab(ActiveTab.ODONTOGRAMA)}>
          Odontograma
        </TabItem>

        <TabItem active={activeTab === ActiveTab.TECIDOS_MOLES} onClick={() => setActiveTab(ActiveTab.TECIDOS_MOLES)}>
          Tecidos moles e duros
        </TabItem>

        <TabItem active={activeTab === ActiveTab.PERIODONTIA} onClick={() => setActiveTab(ActiveTab.PERIODONTIA)}>
          Periodontia
        </TabItem>
      </Tabs>

      {activeTab === ActiveTab.ODONTOGRAMA && (
        <VFlow>
          <OdontogramaFolhaRostoView
            prontuarioId={prontuarioId}
            dataReferencia={dataReferencia}
            cidadaoDataNascimento={cidadaoDataNascimento}
          />

          <HistoricoEvolucoesOdontoTable prontuarioId={prontuarioId} isFolhaRosto showEmpty />
        </VFlow>
      )}

      {activeTab === ActiveTab.TECIDOS_MOLES && <HistoricoTecidosMolesTable prontuarioId={prontuarioId} showEmpty />}

      {activeTab === ActiveTab.PERIODONTIA && (
        <HistoricoEvolucoesPeriodontaisTable prontuarioId={prontuarioId} showEmpty />
      )}
    </VFlow>
  )
}
