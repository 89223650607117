import { Button, HFlow } from 'bold-ui'
import { SexoEnum } from 'graphql/types.generated'
import React, { Fragment, useState } from 'react'

import { RegistroVacinacaoFormInput } from '../detail/vacinacao/VacinacaoCalendarioView'
import { AplicacaoVacinaModal } from './AplicacaoVacinaModal'
import { AprazamentoVacinaModal } from './AprazamentoVacinaModal'
import { RegistroVacinacaoFormModel } from './model'
import { RegistroAnteriorVacinaModal } from './RegistroAnteriorVacinaModal'
import { SubModalVacinacaoOptions } from './VacinacaoModal'

interface OutrosImunobiologicosButtonGroupProps {
  handleOnSubmit(input: RegistroVacinacaoFormInput): Promise<void>
  dataAtendimento: Instant
  dataNascimento: string
  sexo?: SexoEnum
  gestante: boolean
  isAcompanhamentoVacinal: boolean
}

export function OutrosImunobiologicosButtonGroup(props: OutrosImunobiologicosButtonGroupProps) {
  const { handleOnSubmit, dataAtendimento, dataNascimento, sexo, gestante, isAcompanhamentoVacinal } = props

  const [currentModalOpen, setCurrentModalOpen] = useState<SubModalVacinacaoOptions>(null)

  const handleModalVacinacaoOpen = (value: SubModalVacinacaoOptions) => {
    setCurrentModalOpen(value)
  }

  return (
    <Fragment>
      {!isAcompanhamentoVacinal && (
        <HFlow hSpacing={0.35}>
          <Button kind='normal' size='small' onClick={() => handleModalVacinacaoOpen('ANTERIOR')}>
            Transcrição de caderneta
          </Button>
          <Fragment>
            <Button kind='primary' size='small' onClick={() => handleModalVacinacaoOpen('APRAZAMENTO')}>
              Aprazar
            </Button>
            <Button kind='primary' size='small' onClick={() => handleModalVacinacaoOpen('APLICACAO')}>
              Aplicar
            </Button>
          </Fragment>
        </HFlow>
      )}

      <AplicacaoVacinaModal
        modalOpen={currentModalOpen === 'APLICACAO'}
        handleModalClose={() => setCurrentModalOpen(null)}
        handleOnSubmit={handleOnSubmit}
        outrosImunobiologicos
        sexo={sexo}
        gestante={gestante}
        dataAtendimento={dataAtendimento}
        // Os valores abaixo são setados para nulo para evitar problemas com o decorator do componente
        initialValues={
          {
            grupoAtendimento: null,
            estrategiaVacinacao: null,
            outrosImunosDose: null,
            loteImunobiologico: null,
          } as RegistroVacinacaoFormModel
        }
      />

      <RegistroAnteriorVacinaModal
        modalOpen={currentModalOpen === 'ANTERIOR'}
        handleModalClose={() => setCurrentModalOpen(null)}
        handleOnSubmit={props.handleOnSubmit}
        outrosImunobiologicos
        dataAtendimento={dataAtendimento}
        dataNascimento={dataNascimento}
        // Os valores abaixo são setados para nulo para evitar problemas com o decorator do componente
        initialValues={
          {
            outrosImunosDose: null,
            loteFabricante: null,
          } as RegistroVacinacaoFormModel
        }
      />

      <AprazamentoVacinaModal
        modalOpen={currentModalOpen === 'APRAZAMENTO'}
        handleModalClose={() => setCurrentModalOpen(null)}
        handleOnSubmit={props.handleOnSubmit}
        outrosImunobiologicos
        sexo={sexo}
        dataAtendimento={dataAtendimento}
        // Os valores abaixo são setados para nulo para evitar problemas com o decorator do componente
        initialValues={
          {
            estrategiaVacinacao: null,
            outrosImunosDose: null,
          } as RegistroVacinacaoFormModel
        }
      />
    </Fragment>
  )
}
