import { ConfiguracaoDiaSemanaInput } from 'graphql/types.generated'
import { createListValidator, createValidator, ErrorObject, validate } from 'util/validation'

import { cfgPeriodoValidator } from './ConfiguracaoPeriodoValidator'

export const cfgDiaSemanaValidator = createValidator({}, (value: ConfiguracaoDiaSemanaInput) => {
  const errors: ErrorObject<ConfiguracaoDiaSemanaInput> = {}
  let errorsPeriodos = new Array(4)

  if (value && value.periodos) {
    const listvalidator = createListValidator(cfgPeriodoValidator)
    errorsPeriodos = validate(value.periodos, listvalidator)
  }

  for (let referenceIndex = 0; referenceIndex < 4; referenceIndex++) {
    for (let targetIndex = referenceIndex + 1; targetIndex < 4; targetIndex++) {
      const ref = value.periodos[referenceIndex]
      const target = value.periodos[targetIndex]

      if (
        ref.horarioInicial &&
        ref.horarioFinal &&
        target.horarioInicial &&
        target.horarioFinal &&
        (ref.horarioInicial === target.horarioInicial ||
          ref.horarioFinal === target.horarioFinal ||
          checkBetweenHours(ref.horarioInicial, target.horarioInicial, target.horarioFinal) ||
          checkBetweenHours(ref.horarioFinal, target.horarioInicial, target.horarioFinal) ||
          checkBetweenHours(target.horarioInicial, ref.horarioInicial, ref.horarioFinal) ||
          checkBetweenHours(target.horarioFinal, ref.horarioInicial, ref.horarioFinal))
      ) {
        if (!errorsPeriodos) {
          errorsPeriodos = new Array(4)
        }

        let er

        if (errorsPeriodos[referenceIndex]) {
          er = {
            horarioInicial: 'Conflito entre períodos',
            horarioFinal: 'Conflito entre períodos',
          }
        } else {
          er = {
            horarioInicial: 'Conflito entre ' + (referenceIndex + 1) + 'º e ' + (targetIndex + 1) + 'º período',
            horarioFinal: 'Conflito entre ' + (referenceIndex + 1) + 'º e ' + (targetIndex + 1) + 'º período',
          }
        }

        errorsPeriodos.splice(referenceIndex, 1, er)
        errorsPeriodos.splice(targetIndex, 1, er)
      }
    }
  }

  errors.periodos = errorsPeriodos

  return errors
})

const checkBetweenHours = (referenceDate: string, targetInicioDate: string, targetFinalDate: string) => {
  return (
    referenceDate &&
    referenceDate.localeCompare(targetInicioDate) > 0 &&
    referenceDate.localeCompare(targetFinalDate) < 0
  )
}
