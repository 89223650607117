import { HeadingSection, VFlow } from 'bold-ui'
import {
  EnderecoFieldGroup,
  EnderecoFieldGroupModel,
  Form,
  FormProps,
  FormRenderProps,
  validate as validateEndereco,
} from 'components/form'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { DefaultFormFooter } from 'components/form/FormFooter'
import { FormPrompt } from 'components/form/FormPrompt'
import createCalculator from 'final-form-calculate'
import React, { useMemo } from 'react'
import { MetaPath, metaPath } from 'util/metaPath'
import { createValidator } from 'util/validation'
import { ProfissionalDadosFieldGroup } from 'view/profissional/components/ProfissionalDadosFieldGroup'

import { createDadosValidator } from '../validator-profissional'
import ProfissionalFormModel from './ProfissionalFormModel'

export interface ProfissionalFormProps extends FormProps<ProfissionalFormModel> {
  edit?: boolean
  possuiLotacaoOrEstagio?: boolean
}

export const createProfissionalValidator = (possuiLotacaoOrEstagio: boolean) =>
  createValidator<ProfissionalFormModel>(
    {
      endereco: validateEndereco,
    },
    createDadosValidator(possuiLotacaoOrEstagio)
  )

export const createEnderecoCalculator = (meta: MetaPath<EnderecoFieldGroupModel>) =>
  createCalculator({
    field: meta.semNumero.absolutePath(),
    updates: {
      [meta.numero.absolutePath()]: (value: boolean, values: any) => {
        const endereco = resolveValue(values, meta)
        return value ? 'S/N' : endereco.numero === 'S/N' ? undefined : endereco.numero
      },
    },
  })

const path = metaPath<ProfissionalFormModel>()

const enderecoCalculator = createEnderecoCalculator(path.endereco)

export function ProfissionalDadosForm(props: ProfissionalFormProps) {
  const { edit, possuiLotacaoOrEstagio } = props

  const validate = useMemo(() => createProfissionalValidator(possuiLotacaoOrEstagio), [possuiLotacaoOrEstagio])

  const renderForm = (formProps: FormRenderProps<ProfissionalFormModel>) => {
    return (
      <form noValidate>
        <FormPrompt />
        <VFlow vSpacing={2}>
          <HeadingSection level={2} title='Dados pessoais'>
            <ProfissionalDadosFieldGroup
              name={path}
              formProps={formProps}
              edit={edit}
              possuiLotacaoOrEstagio={possuiLotacaoOrEstagio}
            />
          </HeadingSection>

          <HeadingSection level={2} title='Endereço residencial'>
            <EnderecoFieldGroup name={path.endereco} formProps={formProps} />
          </HeadingSection>

          <DefaultFormFooter handleSubmit={formProps.handleSubmit} />
        </VFlow>
      </form>
    )
  }

  return (
    <Form<ProfissionalFormModel>
      render={renderForm}
      validate={validate}
      decorators={[enderecoCalculator]}
      validateOnBlur={false}
      {...props}
    />
  )
}
