import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import React from 'react'

interface ModalUnificarBasesProps {
  isModalOpen: boolean
  setIsModalOpen(isOpen: boolean): void
  onUnificarBase(): void
}

export const ModalUnificarBases = (props: ModalUnificarBasesProps) => {
  const { isModalOpen, setIsModalOpen, onUnificarBase } = props
  const handleFecharModal = () => setIsModalOpen(false)
  const onUnificarClicked = () => {
    onUnificarBase()
    handleFecharModal()
  }

  return (
    <Modal open={isModalOpen} onClose={handleFecharModal} size='small' closeOnBackdropClick={true} hasCloseIcon={true}>
      <ModalBody>
        <HFlow alignItems='flex-start'>
          <Icon icon='exclamationTriangleOutline' fill='alert' size={3} />
          <VFlow>
            <Heading level={1}>Atenção</Heading>
            <Text>
              Ao continuar com a ação de Unificação de Bases, os dados dessa instalação local serão enviados para a
              instalação central do município e unificados. Após o término do envio, a instalação local será desativada.
              Deseja continuar?
            </Text>
          </VFlow>
        </HFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' onClick={handleFecharModal}>
            Cancelar
          </Button>
          <Button kind='danger' onClick={onUnificarClicked}>
            Unificar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
