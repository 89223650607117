/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Box } from 'components/Box'
import theme from 'config/theme'
import React from 'react'

interface PrescricaoBoxItemProps {
  children: React.ReactNode
}

export function PrescricaoBoxItem({ children }: PrescricaoBoxItemProps) {
  return <Box style={styles.boxMedicamentos}>{children}</Box>
}

const styles = {
  boxMedicamentos: css`
    border: 1px solid;
    border-radius: 2px;
    border-color: ${theme.pallete.gray.c60};
    padding: 0.5rem 0.75rem;
  `,
}
