import { StyleSheet, View } from '@react-pdf/renderer'
import { gray } from 'bold-ui/lib/styles/colors'
import React from 'react'

export interface PrintSignatureProps {
  children: React.ReactNode
}

export const PrintSignature = (props: PrintSignatureProps) => {
  const { children } = props
  const classes = createStyles()

  return (
    <View style={classes.container}>
      <View style={classes.signature} />
      {children}
    </View>
  )
}

const createStyles = () =>
  StyleSheet.create({
    container: {
      width: 400,
      alignSelf: 'center',
      marginTop: 50,
      paddingTop: 2,
      lineHeight: 1.25,
    },
    signature: {
      width: 250,
      alignSelf: 'center',
      borderTopWidth: 1,
      borderTopColor: gray.c30,
    },
  })
