import { Heading } from 'bold-ui'
import React from 'react'

interface SubtitleCardProps {
  title: string
}

export const SubtitleCard = (props: SubtitleCardProps) => {
  return (
    <Heading level={4} style={{ margin: '0.4rem 0 0.2rem' }}>
      {`${props.title}:`}
    </Heading>
  )
}
