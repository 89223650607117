/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, Icon, Portal, Theme } from 'bold-ui'
import { useClickOutside } from 'bold-ui/lib/hooks'
import theme from 'config/theme'
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PopperProps, usePopper } from 'react-popper'

export interface OdontogramaPopperProps {
  open: boolean
  title: string
  anchorRef: HTMLElement | SVGSVGElement
  placement: PopperProps<unknown>['placement']
  children?: React.ReactNode
  width?: number
  onClose(): void
}

export default function OdontogramaPopper(props: OdontogramaPopperProps) {
  const { open, title, anchorRef, children, placement, width, onClose } = props
  const rootRef = useRef<HTMLDivElement>()
  const [popperRef, setPopperRef] = useState<HTMLDivElement>()
  const classes = useMemo(() => createStyles(theme, { open, width }), [open, width])

  const {
    styles: { popper: popperStyle },
  } = usePopper(anchorRef, popperRef, { placement })

  const closeAndFocusAnchor = useCallback(() => {
    onClose()
    anchorRef?.focus()
  }, [anchorRef, onClose])

  useClickOutside([(anchorRef as unknown) as RefObject<HTMLElement>, rootRef], () => {
    if (open) {
      closeAndFocusAnchor()
    }
  })

  useEffect(() => {
    // Attach "Escape" to close popper
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeAndFocusAnchor()
      }
    }

    if (open) {
      document.addEventListener('keydown', handleKeyDown)
    } else {
      document.removeEventListener('keydown', handleKeyDown)
    }
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [open, closeAndFocusAnchor])

  // Focus popper when open
  useEffect(() => {
    if (open && popperRef) {
      setTimeout(() => {
        const e: HTMLElement = popperRef.querySelector(
          'input:not([disabled]), textarea:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])'
        )
        e && e.focus()
      })
    }
  }, [open, popperRef])

  return (
    <Portal>
      <div ref={rootRef}>
        {open && (
          <div ref={setPopperRef} css={classes.popper} style={popperStyle as any} data-visible={open}>
            <div css={classes.content}>
              <Button size='small' skin='ghost' style={classes.close} onClick={closeAndFocusAnchor}>
                <Icon icon='timesDefault' />
              </Button>

              <Heading style={classes.title} level={2}>
                {title}
              </Heading>

              {children}
            </div>
          </div>
        )}
      </div>
    </Portal>
  )
}

const createStyles = (theme: Theme, { open, width }) => ({
  popper: css`
    max-height: 90vh;
    ${theme.breakpoints.down('lg')} {
      width: 28rem;
      max-height: 80vh;
    }
    ${theme.breakpoints.up('lg')} {
      width: ${width || 28}rem;
    }
    margin: 0 0.625rem;
    padding: 0;
    border-radius: ${theme.radius.popper}px;
    border: 1px solid ${theme.pallete.gray.c80};
    background: ${theme.pallete.primary.c100};
    box-shadow: ${theme.shadows.outer[160]};
    z-index: ${theme.zIndex.popper};
    outline: 0;
    visibility: ${open ? 'visible' : 'hidden'};
    transition: opacity 0.2s ease;
    opacity: ${open ? '1' : '0'};
  `,
  content: css`
    max-height: calc(90vh - 2px);
    ${theme.breakpoints.down('lg')} {
      max-height: calc(80vh - 2px);
    }
    overflow-x: hidden;
    overflow-y: auto;
    position: static;
    padding: 1rem;
    outline: 0;
  `,
  title: css`
    margin-top: 0;
    margin-bottom: 1rem;
  `,
  close: css`
    position: absolute;
    top: 1rem;
    right: 1rem;
  `,
})
