/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { Heading, Link, Table, TableBody, TableCell, TableRow } from 'bold-ui'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

interface CookiesSectionProps {
  tableCellStyles: {
    col25: SerializedStyles
    col50: SerializedStyles
  }
}

export const CookiesSection = (props: CookiesSectionProps) => {
  const { tableCellStyles } = props
  const styles = createStyles()

  return (
    <TermosDeUsoHeadingSection title='12. Cookies' id='cookies'>
      <p>
        Cookies são pequenos arquivos de texto enviados pelo site ao computador do usuário e que nele ficam armazenados,
        com informações relacionadas à navegação do site.
      </p>
      <br />
      <p>
        Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que o
        servidor do serviço possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo
        utilizado pelo usuário, bem como seu local e horário de acesso ao site.
      </p>
      <br />
      <p>
        É importante ressaltar que nem todo cookie contém dados pessoais do usuário, já que determinados tipos de
        cookies podem ser utilizados somente para que o serviço funcione corretamente.{' '}
      </p>
      <br />
      <p>
        As informações eventualmente armazenadas em cookies também são consideradas dados pessoais. Todas as regras
        previstas nesta Política de Privacidade também são aplicáveis aos referidos cookies.
      </p>
      <br />
      <p>O Prontuário Eletrônico do Cidadão utiliza os seguintes cookies:</p>
      <br />
      <Heading level={4} color='primary'>
        Cookies estritamente necessários
      </Heading>
      <br />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={[tableCellStyles.col25, styles.boldTableTitle]}>
              <p>Nome do Cookie</p>
            </TableCell>
            <TableCell css={[tableCellStyles.col50, styles.boldTableTitle]}>
              <p>Finalidade da utilização</p>
            </TableCell>
            <TableCell css={[tableCellStyles.col25, styles.boldTableTitle]}>
              <p>Duração</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell css={tableCellStyles.col25}>
              <p>JSESSIONID</p>
            </TableCell>
            <TableCell css={tableCellStyles.col50}>
              <p>Manter a sessão do usuário do PEC durante a utilização do sistema.</p>
            </TableCell>
            <TableCell css={tableCellStyles.col25}>
              <p>30 minutos</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell css={tableCellStyles.col25}>
              <p>XSRF-TOKEN</p>
            </TableCell>
            <TableCell css={tableCellStyles.col50}>
              <p>Proteção contra Cross-site Request Forgery (CSRF). </p>
            </TableCell>
            <TableCell css={tableCellStyles.col25}>
              <p>30 minutos</p>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <Heading level={4} color='primary'>
        Cookies de desempenho
      </Heading>
      <br />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell css={[tableCellStyles.col25, styles.boldTableTitle]}>
              <p>Nome do Cookie</p>
            </TableCell>
            <TableCell css={[tableCellStyles.col50, styles.boldTableTitle]}>
              <p>Finalidade da utilização</p>
            </TableCell>
            <TableCell css={[tableCellStyles.col25, styles.boldTableTitle]}>
              <p>Duração</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell css={tableCellStyles.col25}>
              <p>_ga</p>
            </TableCell>
            <TableCell css={tableCellStyles.col50}>
              <p>Coleta de dados estatísticos</p>
            </TableCell>
            <TableCell css={tableCellStyles.col25}>
              <p>Sessão do usuário</p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell css={tableCellStyles.col25}>
              <p>_ga_RXTX8LJCDF</p>
            </TableCell>
            <TableCell css={tableCellStyles.col50}>
              <p>Coleta de dados estatísticos</p>
            </TableCell>
            <TableCell css={tableCellStyles.col25}>
              <p>Sessão do usuário</p>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <p>
        Mais informações e configurações sobre o Consentimento do Google Analytics:{' '}
        <Link
          fontSize={0.875}
          href='https://policies.google.com/technologies/partner-sites?hl=pt-BR'
          target='_blank'
          rel='noopener noreferrer'
        >
          https://policies.google.com/technologies/partner-sites?hl=pt-BR
        </Link>
      </p>
    </TermosDeUsoHeadingSection>
  )
}

const createStyles = () => ({
  boldTableTitle: css`
    font-weight: bold;
  `,
})
