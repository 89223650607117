import axios, { AxiosPromise } from 'axios'
import { EmitirAssinaturaDigitalInput } from 'hooks/assinatura-digital/model-assinaturaDigitalPopup'
import {
  AtestadoImpressaoInput,
  AtestadoRecenteImpressaoInput,
} from 'view/atendimentos/detail/soap/plano/atestados/model-atestado'

export class Atestado {
  imprimirAtestado(input: AtestadoImpressaoInput): AxiosPromise<Blob> {
    return axios.post('/api/atestado/imprimirAtestado', input, { responseType: 'blob' })
  }

  imprimirAtestadoRecente(input: AtestadoRecenteImpressaoInput): AxiosPromise<Blob> {
    return axios.post('/api/atestado/imprimirAtestadoRecente', input, { responseType: 'blob' })
  }

  emitirAtestadoDigital(input: EmitirAssinaturaDigitalInput): AxiosPromise<void> {
    return axios.post('/api/atestado/emitirAtestadoDigital', input)
  }

  gerarAtestadoDigital(input: AtestadoRecenteImpressaoInput): AxiosPromise<string> {
    return axios.post('/api/atestado/gerarAtestadoDigital', input, { responseType: 'text' })
  }
}
