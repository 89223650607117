/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import theme from 'config/theme'
import { useOdontogramaProcedimentosQuery, useOdontogramaQuery } from 'graphql/hooks.generated'
import { OdontogramaProcedimentosQuery, SituacaoCoroaEnum } from 'graphql/types.generated'
import { useEffect, useState } from 'react'

import { FillColor } from '../odontograma/types/Coroa'
import ArcadasView from './arcadas-field/ArcadasView'
import { convertOdontogramaQueryModelToOdontogramaFieldModel } from './converter'
import DentesView from './dentes-field/DentesView'
import { OdontogramaViewMode } from './model'
import { OdontogramaFieldModel } from './OdontogramaField'
import OutrosTable from './outros-field/OutrosTable'

export type EvolucaoOdontoModel = OdontogramaProcedimentosQuery['evolucoesProcedimentoOdonto']['content'][0]

export interface OdontogramaViewProps {
  odontogramaId: ID
  prontuarioId: ID
  atendimentoId: ID
  dataReferencia: LocalDate
  showDeciduos?: boolean
  fillColors: FillColor<SituacaoCoroaEnum>[]
  viewMode: OdontogramaViewMode
}

export const OdontogramaView = (props: OdontogramaViewProps) => {
  const { odontogramaId, prontuarioId, atendimentoId, showDeciduos, fillColors, viewMode, dataReferencia } = props
  const [odontogramaValue, setOdontogramaValue] = useState<OdontogramaFieldModel>()

  const {
    data: { odontograma },
  } = useOdontogramaQuery({ variables: { id: odontogramaId } })

  const {
    data: { evolucoesProcedimentoOdonto },
  } = useOdontogramaProcedimentosQuery({
    variables: { input: { prontuarioId: prontuarioId, atendProfId: atendimentoId, pageParams: { size: 2000 } } },
  })

  useEffect(() => {
    setOdontogramaValue(
      convertOdontogramaQueryModelToOdontogramaFieldModel(odontograma, fillColors, evolucoesProcedimentoOdonto?.content)
    )
  }, [atendimentoId, evolucoesProcedimentoOdonto, fillColors, odontograma])

  return (
    <div css={classes.wrapper}>
      <VFlow>
        <HFlow
          alignItems='center'
          hSpacing={2}
          style={css`
            padding: 0 0.5rem;
            margin-bottom: 0.35rem;
          `}
        >
          <VFlow vSpacing={0.5}>
            <Text fontWeight='bold'>Possui aparelho</Text>

            <Text>{odontogramaValue?.possuiAparelho ? 'Sim' : 'Não'}</Text>
          </VFlow>

          <VFlow vSpacing={0.5}>
            <Text fontWeight='bold'>Possui contenção</Text>

            <Text>{odontogramaValue?.possuiContencao ? 'Sim' : 'Não'}</Text>
          </VFlow>

          <VFlow vSpacing={0.5}>
            <Text fontWeight='bold'>Prótese</Text>

            <Text>
              {odontogramaValue?.proteseTotalSuperior && odontogramaValue?.proteseTotalInferior && 'Total'}

              {odontogramaValue?.proteseTotalSuperior && !odontogramaValue?.proteseTotalInferior && 'Total superior'}

              {odontogramaValue?.proteseTotalInferior && !odontogramaValue?.proteseTotalSuperior && 'Total inferior'}

              {!odontogramaValue?.proteseTotalInferior && !odontogramaValue?.proteseTotalSuperior && 'Não'}
            </Text>
          </VFlow>
        </HFlow>

        {viewMode === OdontogramaViewMode.DENTES && (
          <div css={fixVFlowMarginBottom}>
            <DentesView
              fillColors={fillColors}
              showDeciduos={showDeciduos}
              proteseTotalSuperior={odontogramaValue?.proteseTotalSuperior}
              proteseTotalInferior={odontogramaValue?.proteseTotalInferior}
              value={odontogramaValue?.dentes}
            />
          </div>
        )}

        {viewMode === OdontogramaViewMode.ARCADAS && (
          <div css={fixVFlowMarginBottom}>
            <ArcadasView
              proteseTotalSuperior={odontogramaValue?.proteseTotalSuperior}
              proteseTotalInferior={odontogramaValue?.proteseTotalInferior}
              value={odontogramaValue?.arcadas}
            />
          </div>
        )}

        {viewMode === OdontogramaViewMode.OUTROS && (
          <div
            css={css`
              padding: 0 0.5rem;
            `}
          >
            <OutrosTable readonly items={odontogramaValue?.outros} dataAtendimento={dataReferencia} />
          </div>
        )}
      </VFlow>
    </div>
  )
}

const classes = {
  wrapper: css`
    padding: 0.5rem 1px;
    background: ${theme.pallete.gray.c80};
    margin-top: -0.5rem;
    ${theme.breakpoints.down('lg')} {
      padding: 0.5rem 0;
    }
  `,
}

const fixVFlowMarginBottom = css`
  margin-bottom: -0.5rem;
`
