import { CategoriaSubstanciaAlergia, CriticidadeAlergia, GrauCertezaAlergia } from 'graphql/types.generated'
import { useMemo } from 'react'

import { AlergiaReacaoModel } from '../../avaliacao/components/alergias-reacoes/model'
import { Alergia, criticidadeAlergiaRecord, EvolucaoAlergia, grauCertezaAlergiaRecord } from '../types/AlergiaModel'
import { formatNomePrincipioAtivo } from './formatNomePrincipioAtivo'

export default (
  alergias: Alergia[],
  alergiasAtendimentoAtual: AlergiaReacaoModel[],
  incluirRefutadasResolvidas = false
) =>
  useMemo(() => {
    const getAlergias = () => mergeAndSortAlergias(alergias, alergiasAtendimentoAtual ?? [], incluirRefutadasResolvidas)

    const gruposAlergias = breakAlergias(getAlergias())
    const getAlergiasExpandido = () => gruposAlergias.alergiasExpandido
    const getAlergiasAccordion = () => gruposAlergias.alergiasAccordion

    return { getAlergias, getAlergiasExpandido, getAlergiasAccordion }
  }, [alergias, alergiasAtendimentoAtual, incluirRefutadasResolvidas])

/**
 *
 * @param {Alergia[]} alergias lista de todas as alergias
 * @param {AlergiaReacaoModel[]} alergiasAtendimentoAtual lista de alergias criadas no atendimento atual (modal ou avaliacao)
 * @returns {Alergia[]} lista de alergias ordenada
 */
export const mergeAndSortAlergias = (
  alergias: Alergia[],
  alergiasAtendimentoAtual: AlergiaReacaoModel[],
  incluirRefutadasResolvidas: boolean
): Alergia[] =>
  alergias
    .concat(extractNovasAlergias(alergiasAtendimentoAtual))
    .map((alergia) =>
      mergeAlergiaComAlergiaAtendimentoAtual(
        alergia,
        alergiasAtendimentoAtual.find((pa) => pa.alergiaId && pa.alergiaId === alergia.id)
      )
    )
    .filter(
      (a) =>
        incluirRefutadasResolvidas ||
        ![GrauCertezaAlergia.REFUTADO, GrauCertezaAlergia.RESOLVIDO].includes(a.grauCerteza)
    )
    .sort(
      (a, b) =>
        getGrauImportancia(b) - getGrauImportancia(a) ||
        (b.ultimaEvolucao?.atualizadoEm ?? Date.now()) - (a.ultimaEvolucao?.atualizadoEm ?? Date.now())
    )

/**
 *
 * @param {Alergia} alergia
 * @param {AlergiaReacaoModel} alergiaAtendimentoAtual
 */
const mergeAlergiaComAlergiaAtendimentoAtual = (
  alergia: Alergia,
  alergiaAtendimentoAtual: AlergiaReacaoModel
): Alergia =>
  alergiaAtendimentoAtual
    ? {
        ...convertAlergiaAtendimentoAtualToAlergia(alergiaAtendimentoAtual),
        evolucoes: [convertAlergiaAtendimentoAtualToEvolucao(alergiaAtendimentoAtual), ...(alergia.evolucoes ?? [])],
      }
    : alergia

export const getGrauImportancia = (alergia: Alergia) =>
  grauCertezaAlergiaRecord[alergia.grauCerteza ?? GrauCertezaAlergia.NAO_INFORMADO].importancia +
  criticidadeAlergiaRecord[alergia.criticidade ?? CriticidadeAlergia.NAO_INFORMADA].importancia

/**
 * Se o total de alergias for menor ou igual a 6, todas devem ser apresentadas no grupo expandido e o accordion não deve ser exibido
 * Se o total de alergias for 6 ou +, no grupo de expandidos deve possuir apenas 5 alergias e o restante deve ficar dentro do accordion
 * @param {Alergia[]} alergiasAtivosLatentes lista de alergias confirmadas e suspeitas
 * @returns {{alergiasExpandido: Alergia[]; alergiasAccordion: Alergia[]}} listas de alergias a serem apresentados no card e no accordion
 */
export const breakAlergias = (alergiasAtivosLatentes: Alergia[]) => {
  const totalAlergias = alergiasAtivosLatentes.length
  const alergiasExpandido: Alergia[] = []
  const alergiasAccordion: Alergia[] = []

  totalAlergias > 6 && alergiasAccordion.push(...alergiasAtivosLatentes.splice(5, totalAlergias - 1))
  alergiasExpandido.push(...alergiasAtivosLatentes)

  return { alergiasExpandido, alergiasAccordion }
}

/**
 *
 * @param {AlergiaReacaoModel[]} alergiasAtendimentoAtual lista de alergias criadas no atendimento atual (modal ou avaliacao)
 * @returns {Alergia[]} lista de novas alergias a serem adicionadas na lista de alergias
 */
const extractNovasAlergias = (alergiasAtendimentoAtual: AlergiaReacaoModel[]): Alergia[] =>
  alergiasAtendimentoAtual.filter((pa) => !pa.alergiaId).map(convertAlergiaAtendimentoAtualToAlergia)

/**
 *
 * @param {EvolucaoAlergia} alergiaAtendimentoAtual
 */
const convertAlergiaAtendimentoAtualToEvolucao = (alergiaAtendimentoAtual: AlergiaReacaoModel): EvolucaoAlergia =>
  ({
    id: alergiaAtendimentoAtual._id,
    criticidade: alergiaAtendimentoAtual.criticidade ?? CriticidadeAlergia.NAO_INFORMADA,
    grauCerteza: alergiaAtendimentoAtual.grauCerteza ?? GrauCertezaAlergia.NAO_INFORMADO,
    dataInicio: alergiaAtendimentoAtual.dataInicio?.data,
    tipoReacao: alergiaAtendimentoAtual.tipoReacao,
    manifestacoes: alergiaAtendimentoAtual.manifestacoes,
    observacao: alergiaAtendimentoAtual.observacao,
    possuiSubstanciaEspecificaCbara: true,
  } as EvolucaoAlergia)

/**
 *
 * @param {AlergiaReacaoModel} alergiaAtendimentoAtual
 * @returns {Alergia}
 */
function convertAlergiaAtendimentoAtualToAlergia(alergiaAtendimentoAtual: AlergiaReacaoModel): Alergia {
  const evolucao = convertAlergiaAtendimentoAtualToEvolucao(alergiaAtendimentoAtual)
  return {
    id: alergiaAtendimentoAtual.alergiaId ?? alergiaAtendimentoAtual._id,
    categoriaSubstanciaEspecificaLegado: alergiaAtendimentoAtual.categoriaSubstanciaEspecificaLegado,
    substanciaEspecificaLegado: alergiaAtendimentoAtual.substanciaEspecificaLegado,
    substanciaEspecifica:
      (alergiaAtendimentoAtual.substanciaCbara && {
        id: alergiaAtendimentoAtual.substanciaCbara.id,
        nome: alergiaAtendimentoAtual.substanciaCbara.nome,
        categoria: alergiaAtendimentoAtual.categoriaSubstancia,
      }) ||
      (alergiaAtendimentoAtual.substanciaImunobiologico && {
        id: alergiaAtendimentoAtual.substanciaImunobiologico.id,
        nome: alergiaAtendimentoAtual.substanciaImunobiologico.nome,
        categoria: CategoriaSubstanciaAlergia.BIOLOGICO,
      }) ||
      (alergiaAtendimentoAtual.substanciaMedicamento && {
        id: alergiaAtendimentoAtual.substanciaMedicamento.medicamento.id,
        nome: formatNomePrincipioAtivo({
          principioAtivo: alergiaAtendimentoAtual?.substanciaMedicamento?.medicamento?.principioAtivo,
          concentracao: alergiaAtendimentoAtual?.substanciaMedicamento?.medicamento?.concentracao,
        }),
        categoria: CategoriaSubstanciaAlergia.MEDICAMENTO,
      }),
    criticidade: alergiaAtendimentoAtual.criticidade ?? CriticidadeAlergia.NAO_INFORMADA,
    grauCerteza: alergiaAtendimentoAtual.grauCerteza ?? GrauCertezaAlergia.NAO_INFORMADO,
    dataInicio: alergiaAtendimentoAtual.dataInicio?.data,
    tipoReacao: alergiaAtendimentoAtual.tipoReacao,
    evolucoes: [evolucao],
    ultimaEvolucao: evolucao,
    isCriadoAtendimentoAtual: true,
    isAvaliacao: alergiaAtendimentoAtual.isAvaliacao,
  }
}

export const sortEvolucoesAlergiasByAtualizadoEm = (alergias: Alergia[]): Alergia[] => {
  alergias.forEach((alergia) =>
    alergia.evolucoes.sort((a, b) => (b.atualizadoEm ?? Date.now()) - (a.atualizadoEm ?? Date.now()))
  )
  return alergias
}
