/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, VFlow } from 'bold-ui'
import { ErrorField, TextAreaField } from 'components/form'
import { FormSpy } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { isEmpty as isStringEmpty } from 'util/validation/Util'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import PeriogramaSimplificadoTextField from '../components/PeriogramaSimplificadoTextField'

export interface PeriogramaSimplificadoModel {
  avaliacaoSextante1: string
  avaliacaoSextante2: string
  avaliacaoSextante3: string
  avaliacaoSextante4: string
  avaliacaoSextante5: string
  avaliacaoSextante6: string
  observacao?: string
  error?: string
}

interface PeriogramaSimplificadoFieldProps {
  name: MetaPath<PeriogramaSimplificadoModel>
}

export function PeriogramaSimplificadoField(props: PeriogramaSimplificadoFieldProps) {
  const { name } = props

  return (
    <FormSpy<SoapState> subscription={{ values: true }}>
      {({ values }) => {
        const {
          avaliacaoSextante1,
          avaliacaoSextante2,
          avaliacaoSextante3,
          avaliacaoSextante4,
          avaliacaoSextante5,
          avaliacaoSextante6,
        } = values?.plano?.odontologia?.periodontia?.periogramaSimplificado || {}
        const isPreenchendo = isPreenchendoRPS([
          avaliacaoSextante1,
          avaliacaoSextante2,
          avaliacaoSextante3,
          avaliacaoSextante4,
          avaliacaoSextante5,
          avaliacaoSextante6,
        ])

        return (
          <VFlow vSpacing={0}>
            <HFlow
              hSpacing={2}
              style={css`
                grid-auto-columns: max-content auto;
              `}
            >
              <VFlow vSpacing={0.5}>
                <HFlow>
                  <PeriogramaSimplificadoTextField name={name.avaliacaoSextante1} label='S1' />
                  <PeriogramaSimplificadoTextField name={name.avaliacaoSextante2} label='S2' />
                  <PeriogramaSimplificadoTextField name={name.avaliacaoSextante3} label='S3' />
                </HFlow>
                <HFlow>
                  <PeriogramaSimplificadoTextField name={name.avaliacaoSextante6} label='S6' />
                  <PeriogramaSimplificadoTextField name={name.avaliacaoSextante5} label='S5' />
                  <PeriogramaSimplificadoTextField name={name.avaliacaoSextante4} label='S4' />
                </HFlow>
              </VFlow>
              <TextAreaField
                name={name.observacao}
                label='Observações'
                maxLength={400}
                placeholder='Insira informações adicionais sobre o Registro Periodontal Simplificado'
                disabled={!isPreenchendo}
                style={css`
                  width: 100%;
                  resize: none;
                `}
              />
            </HFlow>
            <ErrorField name={name.error} ignoreTouchedAndDirty />
          </VFlow>
        )
      }}
    </FormSpy>
  )
}

export const isPreenchendoRPS = (values: string[]) => {
  return values.map((value) => isStringEmpty(value)).filter((value) => !value).length > 0
}
