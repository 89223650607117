/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { confirm } from 'components/modals/confirm'
import { MouseEvent } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'

import { EncaminhamentoExternoEspecializadoModel, EncaminhamentoExternoRowModel } from '../model'

interface EncaminhamentoExternoTableButtonsProps {
  encaminhamento: EncaminhamentoExternoRowModel
  onImprimir(values: EncaminhamentoExternoRowModel): void
  removeItem(itemToRemove: EncaminhamentoExternoEspecializadoModel): void
  readOnly: boolean
}

export function EncaminhamentoExternoTableButtons(props: EncaminhamentoExternoTableButtonsProps) {
  const { encaminhamento, onImprimir, removeItem, readOnly } = props

  const alert = useAlert()
  const history = useHistory()
  const match = useRouteMatch()

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(encaminhamento)

  const removeTooltipText = isEditable
    ? tooltipText
    : !encaminhamento.isRegistradoAgora && 'Não é possível excluir encaminhamentos passados'

  const editTooltipText = !encaminhamento.isRegistradoAgora && 'Não é possível editar encaminhamentos passados'

  const handleExcluir = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    return confirm({
      title: 'Deseja excluir o encaminhamento?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        removeItem({ _id: encaminhamento.id } as EncaminhamentoExternoEspecializadoModel)
        alert('success', 'Encaminhamento excluído com sucesso')
      },
    })()
  }

  const handleEditModal = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    history.push(match.url + `/encaminhamentos-externos/editar/${encaminhamento.id}`)
  }

  const handleImprimir = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onImprimir(encaminhamento)
  }

  return (
    <HFlow justifyContent='flex-end' hSpacing={0}>
      <Tooltip text='Imprimir'>
        <Button size='small' skin='ghost' onClick={handleImprimir}>
          <Icon icon='printerOutline' />
        </Button>
      </Tooltip>
      {!readOnly && (
        <DropdownButton stopPropagationOnClick>
          <Tooltip text={editTooltipText}>
            <DropdownItem
              disabled={!encaminhamento.isRegistradoAgora}
              onClick={handleEditModal}
              style={classes.editarButton}
            >
              Editar
            </DropdownItem>
          </Tooltip>
          <Tooltip text={removeTooltipText}>
            <DropdownItem
              onClick={handleExcluir}
              type='danger'
              disabled={isEditable || !encaminhamento.isRegistradoAgora}
            >
              <HFlow alignItems='center' hSpacing={0.5}>
                <Icon icon='trashOutline' />
                Excluir
              </HFlow>
            </DropdownItem>
          </Tooltip>
        </DropdownButton>
      )}
    </HFlow>
  )
}

const classes = {
  editarButton: {
    height: '2rem',
  },
}
