import { HistoricoEvolucoesOdontoTableRowModel } from '../../historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTable'
import { getParteBucal, showDescricaoCodigoProcedimento } from '../../util-EvolucoesOdontologicasPlano'
import { EvolucoesPeriodontaisFieldModel, EvolucoesPeriodontaisTableItemModel } from './model'

export const joinEvolucoesPeriodontais = (
  valoresHistorico: HistoricoEvolucoesOdontoTableRowModel[],
  valoresAtuais: EvolucoesPeriodontaisFieldModel[],
  lotacaoAtual: EvolucoesPeriodontaisTableItemModel['lotacao'],
  atendIniciadoEm?: Instant
): EvolucoesPeriodontaisTableItemModel[] => {
  return [
    ...(valoresHistorico ?? []).map(convertHistoricoToJoined),
    ...(valoresAtuais || []).map((item) => convertValorAtualToJoined(item, lotacaoAtual, atendIniciadoEm)),
  ].sort((a, b) => b.lastUpdate - a.lastUpdate)
}

const convertHistoricoToJoined = (item: HistoricoEvolucoesOdontoTableRowModel): EvolucoesPeriodontaisTableItemModel => {
  return {
    local: getParteBucal(item.partesBucais[0].parteBucalEnum).title,
    observacao: item.observacao,
    procedimentos: item.procedimentos,
    lotacao: item.atendimentoProfissionalOdonto.atendimentoProfissional.lotacao,
    lastUpdate: item.atendimentoProfissionalOdonto.atendimentoProfissional.iniciadoEm,
    isRegistradoAgora: false,
    searchProcedimentos: item.procedimentos?.map(showDescricaoCodigoProcedimento).join(' '),
  }
}

const convertValorAtualToJoined = (
  item: EvolucoesPeriodontaisFieldModel,
  lotacaoAtual: EvolucoesPeriodontaisTableItemModel['lotacao'],
  atendIniciadoEm: Instant
): EvolucoesPeriodontaisTableItemModel => {
  return {
    local: getParteBucal(item.sextante).title,
    observacao: item.observacao,
    procedimentos: item.procedimentos,
    lotacao: lotacaoAtual,
    cacheId: item._id,
    lastUpdate: item.lastUpdate ?? atendIniciadoEm,
    isRegistradoAgora: true,
    searchProcedimentos: item.procedimentos?.map(showDescricaoCodigoProcedimento).join(' '),
  }
}
