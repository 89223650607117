/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ButtonGroup, HFlow, Theme, Tooltip, useTheme } from 'bold-ui'
import { green, red } from 'bold-ui/lib/styles/colors'
import { InfoIcon } from 'components/InfoIcon'
import { StatusAvaliadoEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

interface SwitchButtonFieldProps {
  name: MetaPath<StatusAvaliadoEnum>
  usePrimaryColors: boolean
  checkable: boolean
  disable: boolean
  infoDescription: string
}

export function SwitchButtonField(props: SwitchButtonFieldProps) {
  const { name, usePrimaryColors, checkable, disable, infoDescription } = props
  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme, usePrimaryColors), [theme, usePrimaryColors])

  const {
    input: { value, onChange: setStatus, onBlur },
  } = useField<StatusAvaliadoEnum>(name.absolutePath())

  const handleClick = (stateButton: StatusAvaliadoEnum) => () => {
    setStatus(checkable && value === stateButton ? StatusAvaliadoEnum.NAO_AVALIADO : stateButton)
    onBlur()
  }

  return (
    <ButtonGroup>
      <HFlow hSpacing={1} justifyContent='flex-end' alignItems='center'>
        {infoDescription && <InfoIcon icon='infoCircleFilled' text={infoDescription} />}
        <Tooltip text={disable && 'Avaliação anterior foi marcada como "Presente" e não pode ser alterada'}>
          <Button
            size='small'
            onClick={handleClick(StatusAvaliadoEnum.AUSENTE)}
            style={value === StatusAvaliadoEnum.AUSENTE && classes.ausente}
            disabled={disable}
          >
            Ausente
          </Button>
        </Tooltip>
        <Tooltip text={disable && 'Avaliação anterior foi marcada como "Presente" e não pode ser alterada'}>
          <Button
            size='small'
            onClick={handleClick(
              value === StatusAvaliadoEnum.PRESENTE_COM_ATRASO
                ? StatusAvaliadoEnum.PRESENTE_COM_ATRASO
                : StatusAvaliadoEnum.PRESENTE
            )}
            style={
              (value === StatusAvaliadoEnum.PRESENTE || value === StatusAvaliadoEnum.PRESENTE_COM_ATRASO) &&
              classes.presente
            }
            disabled={disable}
          >
            Presente
          </Button>
        </Tooltip>
      </HFlow>
    </ButtonGroup>
  )
}

const createStyles = (theme: Theme, usePrimaryColors: boolean) => ({
  presente: css`
    color: ${(usePrimaryColors ? theme.pallete.primary : green).c40};
    background-color: ${(usePrimaryColors ? theme.pallete.primary : green).c90};
    border: 1px solid ${(usePrimaryColors ? theme.pallete.primary : green).c40};
  `,
  ausente: css`
    color: ${(usePrimaryColors ? theme.pallete.primary : red).c40};
    background-color: ${(usePrimaryColors ? theme.pallete.primary : red).c90};
    border: 1px solid ${(usePrimaryColors ? theme.pallete.primary : red).c40};
  `,
})
