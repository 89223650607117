/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell } from 'bold-ui'
import { RadioGroupField } from 'components/form/field/RadioGroupField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { CaracteristicaLesaoEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import {
  getLesoesFundamentais,
  getTecidosMolesCaracteristicaLesao,
  getTipoLesaoFundamental,
  TipoLesaoFundamental,
} from '../../../model'

export interface LesaoFundamentalFieldModel {
  value: TipoLesaoFundamental
  lesaoEnvolvida?: CaracteristicaLesaoEnum
}

export interface LesaoFundamentalFieldProps {
  name: MetaPath<LesaoFundamentalFieldModel>
}

const lesoesFundametais = getLesoesFundamentais()

export default function LesaoFundamentalField(props: LesaoFundamentalFieldProps) {
  const { name } = props

  const {
    input: {
      value: { value: lesaoFundamentalValue },
    },
  } = useField<LesaoFundamentalFieldModel>(name.absolutePath(), { subscription: { value: true } })

  const tipoLesaoFundamental = lesaoFundamentalValue && getTipoLesaoFundamental(lesaoFundamentalValue)

  const {
    input: { onChange },
  } = usePecField({ type: 'radio', name: name.lesaoEnvolvida })

  const handleChange = () => {
    onChange(undefined)
  }

  return (
    <Fragment>
      <Cell size={6}>
        <RadioGroupField
          name={name.value}
          label='Lesão fundamental'
          clearable
          vSpacing={0.5}
          lines={lesoesFundametais.length}
          options={lesoesFundametais.map((lesao) => ({
            label: getTipoLesaoFundamental(lesao).title,
            value: lesao,
          }))}
          onChange={handleChange}
        />
      </Cell>

      <Cell size={6}>
        {tipoLesaoFundamental && (
          <RadioGroupField
            name={name.lesaoEnvolvida}
            label={tipoLesaoFundamental.lesaoEnvolvidaTitle}
            vSpacing={0.5}
            clearable
            lines={tipoLesaoFundamental.lesoesEnvolvidas.length}
            options={convertCaracteristicasLesaoToRadioOptions(tipoLesaoFundamental.lesoesEnvolvidas)}
          />
        )}
      </Cell>
    </Fragment>
  )
}

const convertCaracteristicasLesaoToRadioOptions = (caracteristicas: CaracteristicaLesaoEnum[]) =>
  caracteristicas.map((caracteristica) => ({
    label: getTecidosMolesCaracteristicaLesao(caracteristica).title,
    value: caracteristica,
  }))
