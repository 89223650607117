import { css } from '@emotion/core'
import { colors } from 'bold-ui'
import theme from 'config/theme'
import { SituacaoRaizEnum } from 'graphql/types.generated'
import { useMemo } from 'react'

import { DenteModel } from '../types/Dente'
import { dentePossuiCondicoesQueAlteram } from './DenteWrapper'

export const useDenteStyles = (
  value: DenteModel,
  active: boolean,
  protese: boolean,
  hasError: boolean,
  isDeciduo?: boolean,
  disabled?: boolean,
  disableStateEffects?: boolean,
  readonly?: boolean
) => {
  const possuiCondicoes = useMemo(() => dentePossuiCondicoesQueAlteram(value), [value])

  const faceActive = `fill: ${theme.pallete.primary.c90};
  stroke: ${theme.pallete.primary.c40};`

  const faceDisabled = `cursor: not-allowed;
  fill: ${!disableStateEffects && theme.pallete.gray.c90};
  stroke: ${!disableStateEffects && theme.pallete.gray.c80};`

  return useMemo(
    () => ({
      dente: css`
        max-width: 100%;
        outline: 0;
        transition: all 0.2s;

        ${
          !disableStateEffects &&
          !disabled &&
          `
        &:focus {
          path:last-of-type {
            opacity: 1;
          }
        }
        `
        }
        
        ${
          !disableStateEffects &&
          `
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12));
    &:hover {
      filter:
        drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
    };`
        }
        ${
          !disabled &&
          active &&
          `
        filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))
          drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
      `
        }
      ${
        disabled &&
        ` filter: none;
          &:hover {
            filter: none;
          }
          &:focus {
            border: none;
          }`
      }
      `,
      face: css`
        cursor: ${disableStateEffects ? 'inherit' : 'pointer'};
        stroke: ${theme.pallete.gray.c60};
      `,
      faceOne: css`
        fill: ${theme.pallete.gray.c90};
        ${!disabled && !readonly && active && faceActive}
        ${disabled && faceDisabled}
      `,
      faceTwo: css`
        fill: ${theme.pallete.gray.c100};
        ${
          possuiCondicoes &&
          `
        fill: ${theme.pallete.gray.c90};
        stroke: ${theme.pallete.gray.c60};
        `
        }
        ${
          protese &&
          (!isDeciduo
            ? `
            fill: ${colors.yellow.c90};
            stroke: ${theme.pallete.gray.c60};
            `
            : `
            fill: ${theme.pallete.gray.c90};
            stroke: ${theme.pallete.gray.c60};
            `)
        }
            ${!disabled && !readonly && active && faceActive}
            ${disabled && faceDisabled}
      `,
      faceCondicoes: css`
      ${
        possuiCondicoes &&
        `
      fill: ${theme.pallete.gray.c90};
      stroke: ${theme.pallete.gray.c60};
      `
      }
      ${
        value?.condicoes?.includes(SituacaoRaizEnum.ENDODONTIA_TRATADA) &&
        `
        fill: ${colors.blue.c70};
        stroke: ${theme.pallete.gray.c60};
        `
      }
      ${
        value?.condicoes?.includes(SituacaoRaizEnum.ENDODONTIA) &&
        `
        fill: ${colors.red.c70};
        stroke: ${theme.pallete.gray.c60};
        `
      }
      ${
        value?.condicoes?.includes(SituacaoRaizEnum.IMPLANTE) &&
        `
        fill: ${colors.purple.c80};
        stroke: ${theme.pallete.gray.c60};
        `
      }
      ${
        value?.condicoes?.includes(SituacaoRaizEnum.NUCLEO_METALICO_FUNDIDO) &&
        `
          fill: ${colors.yellow.c70};
          stroke: ${theme.pallete.gray.c60};
          `
      }
      ${
        protese &&
        (!isDeciduo
          ? `
          fill: ${colors.yellow.c90};
          stroke: ${theme.pallete.gray.c60};
          `
          : `
          fill: ${theme.pallete.gray.c90};
          stroke: ${theme.pallete.gray.c60};
          `)
      }
      ${!disabled && !readonly && active && faceActive}
      ${disabled && faceDisabled}
    `,
      focusPath: css`
        fill: ${!hasError || active ? theme.pallete.primary.c40 : theme.pallete.status.danger.main};
        opacity: 0;
        transition: opacity 0.125s;
      `,
    }),
    [
      active,
      disableStateEffects,
      disabled,
      faceActive,
      faceDisabled,
      hasError,
      isDeciduo,
      possuiCondicoes,
      protese,
      readonly,
      value,
    ]
  )
}
