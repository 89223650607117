import { Cell, Grid, HFlow } from 'bold-ui'
import bridge_logo from 'images/bridge.svg'
import gov_logo from 'images/sobre/logo-gov.svg'
import ufsc_logo from 'images/sobre/logo-ufsc.svg'
import React from 'react'

interface SobreFooterProps {}

export class SobreFooterView extends React.Component<SobreFooterProps> {
  render() {
    return (
      <div>
        <hr />
        <Grid>
          <Cell size={6}>
            <HFlow hSpacing={1} alignItems='center'>
              <img src={ufsc_logo} alt='Logo da Universidade Federal de Santa Catarina' />
              <img src={bridge_logo} alt='Logo do Laboratório Bridge' />
            </HFlow>
          </Cell>
          <Cell size={6}>
            <HFlow hSpacing={1} alignItems='center' justifyContent='flex-end'>
              <img src={gov_logo} alt='Logo do Ministério da Saúde' />
            </HFlow>
          </Cell>
        </Grid>
      </div>
    )
  }
}
