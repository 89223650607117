/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text, VFlow } from 'bold-ui'
import { TableBox } from 'components/table'
import { PeriogramaCompleto } from 'graphql/types.generated'
import { useMemo } from 'react'
import { Observacao } from 'view/atendimentos/detail/components/Observacao'
import { convertPeriogramaCompletoQueryToModel } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/periograma/periograma-completo/historico-periograma-completo/converter'
import PeriogramaCompletoView from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/periograma/periograma-completo/periograma-completo-table-view/PeriogramaCompletoView'
import {
  calculaPipsPeriogramaCompleto,
  calculaPorcentagensPeriogramaCompleto,
} from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/periograma/periograma-completo/periogramaCompletoCalculos'

interface HistoricoPeriogramaCompletoPanelProps {
  periogramaCompleto: PeriogramaCompleto[]
  observacao: string
}

export const HistoricoPeriogramaCompletoPanel = (props: HistoricoPeriogramaCompletoPanelProps) => {
  const { periogramaCompleto, observacao } = props

  const convertedValue = useMemo(() => convertPeriogramaCompletoQueryToModel(periogramaCompleto), [periogramaCompleto])

  const { porcentagemPlaca, porcentagemSang } = useMemo(
    () => calculaPorcentagensPeriogramaCompleto(convertedValue.periogramaCompleto),
    [convertedValue.periogramaCompleto]
  )

  return (
    <TableBox>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader
              style={css`
                font-weight: normal;
              `}
            >
              <VFlow vSpacing={0.125}>
                <Text fontWeight='bold'>
                  Periograma completo | % de sítios com Placa {porcentagemPlaca} | % de sítios com Sangramento{' '}
                  {porcentagemSang}
                </Text>
                {observacao && (
                  <Observacao
                    observacao={observacao}
                    italic={false}
                    style={css`
                      padding: 0;
                    `}
                  />
                )}
              </VFlow>
            </TableHeader>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>
              <PeriogramaCompletoView
                values={convertedValue.periogramaCompleto}
                pips={calculaPipsPeriogramaCompleto(convertedValue.periogramaCompleto)}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableBox>
  )
}
