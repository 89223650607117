import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, Tooltip, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import clearTypename from 'graphql/clearTypename'
import {
  useForcaAtualizacaoCidadaoCadsusLazyQuery,
  useSincronizaCidadaoCadsusPollingQuery,
} from 'graphql/hooks.generated'
import { SincronizacaoCadsusResultEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ResultSyncCadsusType } from '../types/CadsusModel'
import CidadaoFormModel from '../types/CidadaoFormModel'
import { convertToForm } from '../types/convertModelToInput'
import { CadsusAtmosphereResponse } from './CidadaoCreateView'
import { ModalBuscaInformacoesCadsus, ModalBuscaInformacoesContentState } from './ModalBuscaInformacoesCadsus'

interface SincronizarCadsusButtonProps {
  cns: string
  cidadaoId: string
  setInfoCidadaoState(value: React.SetStateAction<CidadaoFormModel>): void
  setModalSincronizacaoState(isModalopen: boolean): void
  setInfoCidadaoState(value: React.SetStateAction<CidadaoFormModel>): void
  needLoginGovbr: boolean
  hasAllConfigs: boolean
}

interface ModalConfirmarBuscaDadosProps {
  cns: string
}

let buscaCidadaoAtualizadoPolling = false

export function SincronizarCadsusButton(props: SincronizarCadsusButtonProps) {
  const [modalConfirmaBuscaDadosState, setModalConfirmaBuscaDadosState] = useState(false)

  const [isModalBuscaCidadaoOpenState, setModalBuscaCidadaoOpenState] = useState<boolean>(false)
  const [resultBuscaInfoCidadaoState, setResultBuscaInfoCidadaoState] = useState<ModalBuscaInformacoesContentState>({
    isLoading: false,
  })
  const [uuidBuscaCidadaoAtualizado, setUuidBuscaCidadaoAtualizado] = useState<string>()

  const [queryForcaAtualizacaoCidadaoCadsus] = useForcaAtualizacaoCidadaoCadsusLazyQuery()
  const { data: sessao } = useSession()
  const handleRejection = useErrorHandler()

  const atualizaResultadoBuscacidadao = useCallback(
    (data: ResultSyncCadsusType) => {
      buscaCidadaoAtualizadoPolling = false
      if (
        data.result === SincronizacaoCadsusResultEnum.SUCESSO ||
        data.result === SincronizacaoCadsusResultEnum.ERRO_CADSUS_DESABILITADO ||
        data.result === SincronizacaoCadsusResultEnum.ERRO_SEM_CONEXAO ||
        data.result === SincronizacaoCadsusResultEnum.ERRO_CERTIFICADO_DESABILITADO
      ) {
        setModalBuscaCidadaoOpenState(false)
      } else {
        setResultBuscaInfoCidadaoState({
          cns: data.cidadaoCadsus?.cns,
          cpf: data.cidadaoCadsus?.cpf,
          errors: data.errors,
          statusSincronizacao: data.result,
          isLoading: false,
        })
      }
      props.setInfoCidadaoState(convertToForm(clearTypename(data.cidadaoLocal)))
    },
    [props]
  )

  const { refetch: buscaCadsusPollingRefetch } = useSincronizaCidadaoCadsusPollingQuery({ skip: true })

  useEffect(() => {
    let shouldUpdateBuscaCidadaoAtualizado = true

    const scheduleCadsusPolling = setInterval(() => {
      if (!!uuidBuscaCidadaoAtualizado && buscaCidadaoAtualizadoPolling && shouldUpdateBuscaCidadaoAtualizado) {
        shouldUpdateBuscaCidadaoAtualizado = false
        buscaCadsusPollingRefetch({ uuid: uuidBuscaCidadaoAtualizado })
          .then(
            ({ data }) =>
              !!data.sincronizacaoCadsusPolling &&
              data.sincronizacaoCadsusPolling.uuid === uuidBuscaCidadaoAtualizado &&
              buscaCidadaoAtualizadoPolling &&
              atualizaResultadoBuscacidadao(data.sincronizacaoCadsusPolling)
          )
          .finally(() => (shouldUpdateBuscaCidadaoAtualizado = true))
          .catch(handleRejection)
      }
    }, 1 * 1000)

    return () => clearInterval(scheduleCadsusPolling)
  }, [atualizaResultadoBuscacidadao, buscaCadsusPollingRefetch, handleRejection, uuidBuscaCidadaoAtualizado])

  const fetchCadsusResult = useCallback(
    (body: CadsusAtmosphereResponse) => {
      if (body.uuid === uuidBuscaCidadaoAtualizado && buscaCidadaoAtualizadoPolling) {
        buscaCadsusPollingRefetch({ uuid: uuidBuscaCidadaoAtualizado })
          .then(
            ({ data }) =>
              !!data.sincronizacaoCadsusPolling &&
              data.sincronizacaoCadsusPolling.uuid === uuidBuscaCidadaoAtualizado &&
              buscaCidadaoAtualizadoPolling &&
              atualizaResultadoBuscacidadao(data.sincronizacaoCadsusPolling)
          )
          .catch(handleRejection)
      }
    },
    [atualizaResultadoBuscacidadao, buscaCadsusPollingRefetch, handleRejection, uuidBuscaCidadaoAtualizado]
  )

  useAtmosphere<CadsusAtmosphereResponse>({
    topic: `cadsus-atualizacao-forcada/${sessao.profissional.usuario.id}`,
    onMessage: fetchCadsusResult,
  })

  const handleOnSincronizarClicked = () => {
    setModalConfirmaBuscaDadosState(true)
  }

  const handleOnCloseModal = () => {
    setModalConfirmaBuscaDadosState(false)
  }

  const handleOnContinuarClicked = () => {
    const hash = uuidv4()
    buscaCidadaoAtualizadoPolling = true
    queryForcaAtualizacaoCidadaoCadsus({
      variables: { input: { cidadao: props.cidadaoId, uuid: hash } },
    })
    setUuidBuscaCidadaoAtualizado(hash)
    setResultBuscaInfoCidadaoState({ ...resultBuscaInfoCidadaoState, isLoading: true })
    setModalConfirmaBuscaDadosState(false)
    setModalBuscaCidadaoOpenState(true)
  }

  const botaoSincronizarDisabled = !props.hasAllConfigs || props.needLoginGovbr

  return (
    <>
      <Tooltip
        text={
          !botaoSincronizarDisabled
            ? 'Para sincronizar com o CADSUS, é necessário configurar o certificado e estar logado através do gov.br.'
            : 'Para sincronizar com o CADSUS, é necessário configurar o certificado e estar logado através do gov.br. Entre em contato com o administrador municipal.'
        }
      >
        <Button onClick={handleOnSincronizarClicked} kind='primary' disabled={botaoSincronizarDisabled}>
          <Icon icon='sync' />
          Sincronizar com Cadsus
        </Button>
      </Tooltip>

      <Modal open={modalConfirmaBuscaDadosState} onClose={handleOnCloseModal} size='small'>
        <ConfirmarBuscaDadosModalBody cns={props.cns} />
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={handleOnCloseModal}>Cancelar</Button>
            <Button onClick={handleOnContinuarClicked} kind='primary'>
              Continuar
            </Button>
          </HFlow>
        </ModalFooter>
      </Modal>

      <ModalBuscaInformacoesCadsus
        result={resultBuscaInfoCidadaoState}
        isModalOpen={isModalBuscaCidadaoOpenState}
        setModalState={setModalBuscaCidadaoOpenState}
        isLoading={resultBuscaInfoCidadaoState.isLoading}
      />
    </>
  )
}

function ConfirmarBuscaDadosModalBody(props: ModalConfirmarBuscaDadosProps) {
  return (
    <ModalBody>
      <VFlow>
        <HFlow>
          <Icon icon='questionMarkFilled' fill='info' size={4} />
          <Heading level={2}>Deseja sincronizar com o CADSUS?</Heading>
        </HFlow>
        <Text>
          Os dados do cadastro de CNS {props.cns} no CADSUS serão carregados para esta tela. As alterações só serão
          efetivadas após salvar o cadastro.
        </Text>
      </VFlow>
    </ModalBody>
  )
}
