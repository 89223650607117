import { ManterCidadaoLista } from './components/DesfechoAtendimentoPanel'

export interface isCondutaRequiredProps {
  isObservacao: boolean
  hasNotificacaoCasoSuspeito: boolean
  hasEncaminhamentoExterno: boolean
  manterCidadaoNaLista: ManterCidadaoLista
}

export const isCondutaRequired = (props: isCondutaRequiredProps) => {
  const { isObservacao, hasEncaminhamentoExterno, hasNotificacaoCasoSuspeito, manterCidadaoNaLista } = props

  const isObservacaoMantendoNaLista = isObservacao && manterCidadaoNaLista === ManterCidadaoLista.SIM
  const hasEncaminhamentoOuNotificacao = hasNotificacaoCasoSuspeito || hasEncaminhamentoExterno

  return !(hasEncaminhamentoOuNotificacao || isObservacaoMantendoNaLista)
}
