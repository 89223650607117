import { PeriogramaCompleto, PeriogramasCompletoQuery } from 'graphql/types.generated'
import { groupBy, orderBy, values } from 'lodash'

import { getPeriogramaCompletoDenteFaces, PeriogramaCompletoCondicao } from '../model'
import { HistoricoPeriogramaCompletoItemModel } from './HistoricoPeriogramaCompletoList'

export const convertPeriogramasCompletosQueryToModel = (content: PeriogramasCompletoQuery['periogramasCompleto']) => {
  if (content) {
    const items = orderBy(
      groupBy(content, (item) => item.atendimentoProfissionalOdonto.id),
      (item) => item[0].atendimentoProfissionalOdonto.atendimentoProfissional.iniciadoEm,
      'desc'
    )

    return values(items).map((item) => convertPeriogramaCompletoQueryToModel(item as PeriogramaCompleto[]))
  }
}

export const convertPeriogramaCompletoQueryToModel = (
  item: PeriogramaCompleto[]
): HistoricoPeriogramaCompletoItemModel => ({
  id: item[0].id,
  atendProfOdonto: item[0].atendimentoProfissionalOdonto,
  periogramaCompleto: Object.fromEntries(
    item.map((periogramaItem) => {
      const faces = getPeriogramaCompletoDenteFaces(periogramaItem.dente.parteBucalEnum)

      return [
        periogramaItem.dente.parteBucalEnum,
        {
          ausente: periogramaItem.excluido,
          ...(!periogramaItem.excluido && {
            values: {
              [faces.VESTIBULAR]: {
                [PeriogramaCompletoCondicao.PROF_SONDAGEM]: periogramaItem.profundidadeVestibular,
                [PeriogramaCompletoCondicao.RECESSAO]: periogramaItem.recessaoVestibular,
                [PeriogramaCompletoCondicao.PLACA]: {
                  d: periogramaItem.placaDVestibular,
                  v: periogramaItem.placaVVestibular,
                  m: periogramaItem.placaMVestibular,
                },
                [PeriogramaCompletoCondicao.SANG_SONDAGEM]: {
                  d: periogramaItem.sangramentoDVestibular,
                  v: periogramaItem.sangramentoVVestibular,
                  m: periogramaItem.sangramentoMVestibular,
                },
              },
              [faces.INTERNA]: {
                [PeriogramaCompletoCondicao.MOBILIDADE]: periogramaItem.mobilidade,
                [PeriogramaCompletoCondicao.LESAO_FURCA]: periogramaItem.lesaoDeFurca,
                [PeriogramaCompletoCondicao.PROF_SONDAGEM]: periogramaItem.profundidadeInterna,
                [PeriogramaCompletoCondicao.RECESSAO]: periogramaItem.recessaoInterna,
                [PeriogramaCompletoCondicao.PLACA]: {
                  d: periogramaItem.placaDInterna,
                  v: periogramaItem.placaVInterna,
                  m: periogramaItem.placaMInterna,
                },
                [PeriogramaCompletoCondicao.SANG_SONDAGEM]: {
                  d: periogramaItem.sangramentoDInterna,
                  v: periogramaItem.sangramentoVInterna,
                  m: periogramaItem.sangramentoMInterna,
                },
              },
            },
          }),
        },
      ]
    })
  ),
})
