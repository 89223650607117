import { msg } from '..'

const LETTERS_APOSTROPHE = /^([a-zA-ZáéíóúÁÉÍÓÚçÇâêôÂÊÔõãÕÃäöüÄÖÜ '\s])+$/

export function simpleName(inputValue: string) {
  if (!inputValue) {
    return null
  }

  if (!LETTERS_APOSTROPHE.test(inputValue)) {
    return msg('simpleName', inputValue)
  }

  return null
}
