import { Icons } from 'bold-ui'

export enum ProximaConsultaTypeEnum {
  PRE_NATAL,
  ODONTOLOGICO,
}

export interface SugestaoProximaConsultaModel {
  type: ProximaConsultaTypeEnum
  date: LocalDate
}

export const agendamentoPropsRecord: Record<ProximaConsultaTypeEnum, { icon: Icons; description: string }> = {
  [ProximaConsultaTypeEnum.PRE_NATAL]: { icon: 'pregnant', description: 'Acompanhamento pré-natal' },
  [ProximaConsultaTypeEnum.ODONTOLOGICO]: { icon: 'calendarFilled', description: 'Consulta odontológica pré-natal' },
}
