import {
  Cell,
  Grid,
  Heading,
  HFlow,
  Modal,
  ModalBody,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  VFlow,
} from 'bold-ui'
import { DateTime } from 'components/date'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { TableBox } from 'components/table/TableBox'
import { useAtendimentosDoDiaQuery } from 'graphql/hooks.generated'
import { AtendimentosDoDiaQuery, TipoAtendimentoProfissional } from 'graphql/types.generated'
import groupBy from 'lodash/groupBy'
import moment from 'moment'
import React from 'react'
import { Redirect, useHistory, useParams } from 'react-router'
import { humanizeAge } from 'util/date/humanize-age'

interface UrlParams {
  atendimentoId: string
}

interface AtendimentoProfissionalType {
  nome: string
}

const TipoAtendimentoMap: Record<TipoAtendimentoProfissional, AtendimentoProfissionalType> = {
  [TipoAtendimentoProfissional.CONSULTA]: { nome: 'Consulta' },
  [TipoAtendimentoProfissional.ESCUTA_INICIAL]: { nome: 'Escuta inicial' },
  [TipoAtendimentoProfissional.ATENDIMENTO_DOMICILIAR]: {
    nome: 'Atendimento domiciliar',
  },
  [TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO]: {
    nome: 'Atendimento odontológico',
  },
  [TipoAtendimentoProfissional.AVALIACAO_ELEGIBILIDADE]: {
    nome: 'Avaliação de elegibilidade',
  },
  [TipoAtendimentoProfissional.PRE_NATAL]: { nome: 'Pré-natal' },
  [TipoAtendimentoProfissional.PUERPERIO]: { nome: 'Puerpério' },
  [TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS]: {
    nome: 'Atendimento de procedimentos',
  },
  [TipoAtendimentoProfissional.PUERICULTURA]: { nome: 'Puericultura' },
  [TipoAtendimentoProfissional.VACINACAO]: { nome: 'Vacinação' },
  [TipoAtendimentoProfissional.ZIKA]: { nome: 'Zika' },
  [TipoAtendimentoProfissional.OBSERVACAO]: { nome: 'Observação' },
}

export type AtendimentosDoDiaData = AtendimentosDoDiaQuery['atendimentosDoDia']
export type AtendimentosDoDiaModel = AtendimentosDoDiaQuery['atendimentosDoDia']['content'][0]

export const AtendimentoDoDiaView = () => {
  const { atendimentoId } = useParams<UrlParams>()
  const history = useHistory()

  const { data } = useAtendimentosDoDiaQuery({
    variables: {
      id: atendimentoId,
    },
  })

  if (!data?.atendimentosDoDia) {
    return null
  }

  if (data?.atendimentosDoDia?.content?.length === 0) {
    return <Redirect to='/404' />
  }

  const goBack = () => {
    return history.push(`/lista-atendimento`)
  }

  const renderDataHora = (data: any) => {
    return <DateTime value={data} format='DD/MM/YYYY[ ]HH:mm' />
  }

  const cidadao = data.atendimentosDoDia.content[0].atendimento.cidadao
  const map = groupBy(data.atendimentosDoDia.content, (obj) => obj.atendimento.iniciadoEm)

  return (
    <Modal open={true} onClose={goBack} size='auto' closeOnBackdropClick={false} style={{ width: '56.5rem' }}>
      <ModalBody>
        <Grid alignItems='flex-start' gap={0}>
          <Cell size={12}>
            <Heading level={1}>Atendimentos no dia</Heading>
          </Cell>
          <Cell size={12} />
          <Cell size={12}>
            <VFlow vSpacing={0}>
              <Text>
                <b>{cidadao.nomeSocial || cidadao.nome}</b>
              </Text>
              <HFlow hSpacing={1}>
                <HLabel title='CPF ' children={<Cpf value={cidadao?.cpf} />} />
                <HLabel title='CNS ' children={cidadao?.cns} />
              </HFlow>
              <Text>
                <b>Data de nascimento</b> {moment(cidadao.dataNascimento).format('DD/MM/YYYY') + ' ('}
                {humanizeAge(cidadao.dataNascimento) + ')'}
              </Text>
            </VFlow>
          </Cell>
          {Object.keys(map).map((id: string) => {
            return (
              <React.Fragment key={id}>
                <Cell size={12} style={{ paddingBottom: '0rem' }}>
                  <b>Horário de inserção na lista de atendimentos </b>
                  <DateTime value={map[id][0].atendimento.dataCriacaoRegistro} format=' HH:mm' />
                </Cell>
                <Cell size={12}>
                  <TableBox>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableHeader>Início</TableHeader>
                          <TableHeader>Fim</TableHeader>
                          <TableHeader>Profissional</TableHeader>
                          <TableHeader>CBO</TableHeader>
                          <TableHeader>Tipo de atendimento</TableHeader>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {map[id].map((atendProf) => {
                          return (
                            <React.Fragment key={atendProf.id}>
                              <TableRow>
                                <TableCell style={{ padding: '0.5rem 1rem', width: '6.75rem' }}>
                                  {renderDataHora(atendProf.iniciadoEm)}
                                </TableCell>
                                <TableCell style={{ width: '6.75rem' }}>
                                  {atendProf.finalizadoEm ? renderDataHora(atendProf.finalizadoEm) : '-'}
                                </TableCell>
                                <TableCell style={{ width: '15rem' }}>{atendProf.lotacao.profissional.nome}</TableCell>
                                <TableCell style={{ width: '12.5625rem' }}>{atendProf.lotacao.cbo.nome}</TableCell>
                                <TableCell style={{ width: '10.25rem' }}>
                                  {TipoAtendimentoMap[atendProf.tipo].nome}
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableBox>
                </Cell>
              </React.Fragment>
            )
          })}
        </Grid>
      </ModalBody>
    </Modal>
  )
}
