/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { TipoParticipacaoAtendimentoSelectField } from 'components/form/field/select/TipoParticipacaoAtendimentoSelectField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { MetaPath } from 'util/metaPath'
import { OBSERVACAO_DISABLE_ATENDIMENTO_COMPARTILHADO } from 'view/atendimentos/atendimento-individual/atendimento-observacao/model-atendObservacao'

import { FinalizacaoAtendimentoFormModel } from '../FinalizacaoAtendimentoForm'

export interface AtendimentoCompartilhadoFieldGroupProps {
  name: MetaPath<FinalizacaoAtendimentoFormModel>
  disabled: boolean
  profissionaisIdsInvalidosAtendimentoCompartilhado: ID[]
}

// TODO @RNG - Criar um componente genérico utilizando <fieldset> e <legend> (#18472)
export function AtendimentoCompartilhadoFieldGroup(props: AtendimentoCompartilhadoFieldGroupProps) {
  const { name, disabled, profissionaisIdsInvalidosAtendimentoCompartilhado } = props

  const {
    input: { value: atendimentoCompartilhadoLotacao },
  } = usePecField({ name: name.atendimentoCompartilhadoLotacao.absolutePath() })

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Tooltip text={disabled && OBSERVACAO_DISABLE_ATENDIMENTO_COMPARTILHADO}>
      <div css={styles.container}>
        <Text fontWeight='bold' style={styles.title}>
          Atendimento compartilhado
        </Text>
        <Grid alignItems='center'>
          <Cell size={12}>
            <LotacaoAndEstagioSelectField
              excludeProfissionaisIds={profissionaisIdsInvalidosAtendimentoCompartilhado}
              name={name.atendimentoCompartilhadoLotacao}
              label='Profissional'
              loadItemsOnOpen={false}
              disabled={disabled}
            />
          </Cell>
          <Cell size={12}>
            <TipoParticipacaoAtendimentoSelectField
              label='Forma de participação'
              name={name.tipoParticipacaoProfissionalConvidado}
              disabled={disabled || !atendimentoCompartilhadoLotacao}
            />
          </Cell>
        </Grid>
      </div>
    </Tooltip>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    position: relative;
    margin-top: 0.5rem;
    padding: 1rem;
    border: 1px solid ${theme.pallete.gray.c80};
  `,
  title: css`
    position: absolute;
    margin-left: -0.5rem;
    top: -0.7rem;
    padding: 0 0.5rem;
    background-color: ${theme.pallete.surface.main};
  `,
})
