import { ConfiguracaoHorarioAgendaInput, DiaSemanaEnum } from 'graphql/types.generated'
import moment from 'moment'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { ConfiguracaoAgendaFormType } from 'view/configuracao-agenda/component/ConfiguracaoAgendaPanel'

export const hasPeriodosMenos2HorasChecker = (value: ConfiguracaoHorarioAgendaInput) => {
  for (const diaSemana of value.configuracaoDiasSemana) {
    for (const periodo of diaSemana.periodos) {
      if (periodo.horarioInicial && periodo.horarioFinal) {
        const hoursDiff = moment(periodo.horarioFinal, 'HH:mm').diff(moment(periodo.horarioInicial, 'HH:mm')) / 36e5

        if (hoursDiff < 2 && hoursDiff > 0) {
          return true
        }
      }
    }
  }

  return false
}

export const hasPeriodosMais6HorasChecker = (value: ConfiguracaoHorarioAgendaInput) => {
  for (const diaSemana of value.configuracaoDiasSemana) {
    for (const periodo of diaSemana.periodos) {
      if (periodo.horarioInicial && periodo.horarioFinal) {
        const hoursDiff = moment(periodo.horarioFinal, 'HH:mm').diff(moment(periodo.horarioInicial, 'HH:mm')) / 36e5

        if (hoursDiff > 6) {
          return true
        }
      }
    }
  }

  return false
}

export const hasFinalDeSemanaPreenchidoChecker = (configuracaoHorarioAgendaInput: ConfiguracaoHorarioAgendaInput) => {
  if (configuracaoHorarioAgendaInput) {
    const diaSemanaList = configuracaoHorarioAgendaInput.configuracaoDiasSemana

    for (let diaIndex = 0; diaIndex < 7; diaIndex++) {
      if (
        diaSemanaList[diaIndex].diaSemana === DiaSemanaEnum.SABADO ||
        diaSemanaList[diaIndex].diaSemana === DiaSemanaEnum.DOMINGO
      ) {
        for (let periodoIndex = 0; periodoIndex < 4; periodoIndex++) {
          if (
            diaSemanaList[diaIndex].periodos[periodoIndex].horarioInicial ||
            diaSemanaList[diaIndex].periodos[periodoIndex].horarioFinal
          ) {
            return true
          }
        }
      }
    }
  }

  return false
}

const path = metaPath<ConfiguracaoAgendaFormType>()

export const clearHorariosFinalDeSemana = (
  formProps: FormRenderProps<ConfiguracaoAgendaFormType>,
  mostrarFinalDeSemana: boolean
) => {
  if (!mostrarFinalDeSemana && formProps.values.configuracaoHorarioAgenda.configuracaoDiasSemana) {
    const diaSemanaList = formProps.values.configuracaoHorarioAgenda.configuracaoDiasSemana

    for (let diaIndex = 0; diaIndex < 7; diaIndex++) {
      if (
        diaSemanaList[diaIndex].diaSemana === DiaSemanaEnum.SABADO ||
        diaSemanaList[diaIndex].diaSemana === DiaSemanaEnum.DOMINGO
      ) {
        for (let periodoIndex = 0; periodoIndex < 4; periodoIndex++) {
          const periodoPath = path.configuracaoHorarioAgenda.configuracaoDiasSemana[diaIndex].periodos[periodoIndex]
          formProps.form.change(periodoPath.horarioInicial.absolutePath(), null)
          formProps.form.change(periodoPath.horarioFinal.absolutePath(), null)
        }
      }
    }
  }
}

export const buildPeriodoInconsistenteMsg = (form: ConfiguracaoHorarioAgendaInput) => {
  const hasMenos2Horas = hasPeriodosMenos2HorasChecker(form)
  const hasMais6Horas = hasPeriodosMais6HorasChecker(form)

  let titleMsg
  let bodyMsg

  if (hasMenos2Horas || hasMais6Horas) {
    titleMsg =
      hasMenos2Horas && hasMais6Horas
        ? 'Deseja configurar a agenda com períodos maiores que 6 horas e menores que 2 horas?'
        : hasMenos2Horas
        ? 'Deseja configurar a agenda com períodos menores do que 2 horas?'
        : 'Deseja configurar a agenda com períodos maiores do que 6 horas?'

    bodyMsg =
      hasMenos2Horas && hasMais6Horas
        ? 'É recomendado que o período de trabalho dos profissionais seja menor que 6 horas e maior que 2 horas.'
        : hasMenos2Horas
        ? 'É recomendado que o período de trabalho dos profissionais seja maior que 2 horas.'
        : 'É recomendado que o período de trabalho dos profissionais seja menor que 6 horas.'
  }

  return { titleMsg, bodyMsg }
}

export const hasInconsistenciasSegundaFeira = (formProps: FormRenderProps<ConfiguracaoAgendaFormType>) => {
  return formProps.errors?.configuracaoHorarioAgenda?.configuracaoDiasSemana[0]
}
