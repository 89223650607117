import { Spinner, Text, useTheme, VFlow } from 'bold-ui'
import React from 'react'

export interface LoadingIndicatorProps {
  message?: string
}

export function LoadingIndicator(props: LoadingIndicatorProps) {
  const { message } = props
  const theme = useTheme()

  return (
    <VFlow vSpacing={0.5} style={{ textAlign: 'center' }}>
      <Spinner size={2} borderWidth={3} style={{ color: theme.pallete.primary.main }} />

      {message && (
        <Text color='primary' fontWeight='bold'>
          {message}
        </Text>
      )}
    </VFlow>
  )
}

LoadingIndicator.defaultProps = {
  message: 'Carregando...',
} as LoadingIndicatorProps
