import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import React from 'react'

export type PeriodoTratamentoSelectFieldProps = Omit<SelectFieldProps<UnidadeMedidaTempoEnum>, 'items' | 'itemToString'>

export function PeriodoTratamentoSelectField(props: PeriodoTratamentoSelectFieldProps) {
  const itemToString = (item: UnidadeMedidaTempoEnum) => periodoTratamento[item]
  const items = [UnidadeMedidaTempoEnum.DIAS, UnidadeMedidaTempoEnum.SEMANAS, UnidadeMedidaTempoEnum.MESES]
  return <SelectField<UnidadeMedidaTempoEnum> items={items} itemToString={itemToString} {...props} />
}

export const periodoTratamento: Partial<Record<UnidadeMedidaTempoEnum, string>> = {
  [UnidadeMedidaTempoEnum.DIAS]: 'Dia(s)',
  [UnidadeMedidaTempoEnum.SEMANAS]: 'Semana(s)',
  [UnidadeMedidaTempoEnum.MESES]: 'Mês(es)',
  [UnidadeMedidaTempoEnum.INDETERMINADO]: 'Indeterminado',
  [UnidadeMedidaTempoEnum.NAO_INFORMADO]: 'Não informado',
}
