import axios, { AxiosPromise } from 'axios'

export interface ImpressaoAcompanhamentoPuericulturaInput {
  prontuarioId: ID
  atendimentoId?: ID
}

export class ImpressaoAcompanhamentoPuericultura {
  imprimirAcompanhamentoPuericultura(input: ImpressaoAcompanhamentoPuericulturaInput): AxiosPromise<Blob> {
    return axios.post('/api/atendimento/imprimirAcompanhamentoPuericultura', input, { responseType: 'blob' })
  }
}
