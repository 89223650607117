import { ApolloError } from 'apollo-client'
import { Alert, HFlow, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DateTime } from 'components/date'
import { useInstalacaoHeaderQuery, useUnificaCadastrosMutation } from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'
import { titleCase } from 'util/strConversor'
import { CidadaoListingFormModel } from 'view/cidadao/types/CidadaoPesquisarFormModel'

import ConfirmarModal from './components/ConfirmarModal'
import { UnificacaoCadastroConfirmacaoModal } from './components/modals/UnificacaoCadastroConfirmacaoModal'
import SelectedItemContainer from './components/SelectedItemContainer'
import { UnificacaoCadastroFoundListItem } from './components/UnificacaoCadastroFoundListItem'
import { UnificacaoCadastroSelectedList } from './components/UnificacaoCadastroSelectedList'
import UnificacaoErrorModal from './components/UnificacaoErrorModal'
import { handleUnificacaoError, UnificacaoCadastroErrorModel } from './UnificacaoCadastroErrorHandler'

interface UnificacaoCadastroListingProps {
  cadastrosEncontrados: CidadaoListingFormModel[]
  hasMoreResults: boolean
  clearCidadaosEncontrados(): void
}

export function UnificacaoCadastroListing(props: UnificacaoCadastroListingProps) {
  const { cadastrosEncontrados, hasMoreResults, clearCidadaosEncontrados } = props

  const alert = useAlert()
  const [error, setError] = useState<UnificacaoCadastroErrorModel>(null)
  const [unificaCadastros] = useUnificaCadastrosMutation()

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [openCidadaoMaisDeUmCnsDefinitivoModal, setOpenCidadaoMaisDeUmCnsDefinitivoModal] = useState<boolean>(false)
  const [openCidadaoFaleceuModal, setOpenCidadaoFaleceuModal] = useState<boolean>(false)
  const [cadastrosSelecionados, setCadastrosSelecionados] = useState<CidadaoListingFormModel[]>()
  const [cadastroPrincipal, setCadastroPrincipal] = useState<CidadaoListingFormModel>()
  const [totalEncontrados, setTotalEncontrados] = useState<number>(cadastrosEncontrados?.length)
  const [openCidadaoFaleceuECnsDefinitivoModal, setOpenCidadaoFaleceuECnsDefinitivoModal] = useState<boolean>(false)
  const [cidadaosCnsDefinitivo, setCidadaosCnsDefinitivo] = useState<CidadaoListingFormModel[]>()

  const [unidadeResponsavelResultante, setUnidadeResponsavelResultante] = useState<string>()
  const [cnsResultante, setCnsResultante] = useState<string>()
  const [cpfResultante, setCpfResultante] = useState<string>()

  const {
    data: { info },
  } = useInstalacaoHeaderQuery()

  const totalDeEncontradosSelecionados = cadastrosEncontrados?.filter((encontrado) =>
    cadastrosSelecionados?.some((selecionado) => encontrado.id === selecionado.id)
  )?.length

  useEffect(() => {
    setTotalEncontrados(cadastrosEncontrados?.length - totalDeEncontradosSelecionados)
  }, [cadastrosEncontrados, totalDeEncontradosSelecionados])

  const handleSelectCidadao = (cidadao: CidadaoListingFormModel, selected: boolean) => {
    if (selected) {
      setCadastrosSelecionados(cadastrosSelecionados ? [...cadastrosSelecionados, cidadao] : [cidadao])
    } else {
      setCadastrosSelecionados(cadastrosSelecionados.filter((item) => item.id !== cidadao.id))
      if (cidadao?.id === cadastroPrincipal?.id) {
        handleSetCadastroPrincipal(null)
      }
    }
  }

  const handleSetCadastroPrincipal = (cadastro: CidadaoListingFormModel) => {
    setCadastroPrincipal(cadastro)
  }

  const handleSameItem = (cidadaoId: ID) => {
    return cadastrosSelecionados?.length > 0 && !!cadastrosSelecionados?.find((item) => item.id === cidadaoId)
  }

  const handleOnClickUnificar = () => {
    handleResetCidadaoResultante()
    const cidadaoCpfValido = cadastrosSelecionados.find((cidadao) => cidadao.cpf !== null)
    const selecionadosMesmoCpf = cadastrosSelecionados?.every((cidadao) => {
      return cidadao.cpf === null || cidadao.cpf === cidadaoCpfValido.cpf
    })
    const cidadaosCnsDefinitivo = cadastrosSelecionados.filter((cidadao) => isCnsDefinitivo(cidadao.cns))
    const maisDeUmCnsDefinitivo = cidadaosCnsDefinitivo.length > 1

    if (!selecionadosMesmoCpf) {
      alert('warning', 'Não é permitido unificar cadastros de cidadãos com CPFs diferentes.')
    } else {
      fillCnsCidadaoResultante()
      fillCpfCidadaoResultante()
      fillEquipeUnidadeSaude()
      if (maisDeUmCnsDefinitivo && cadastroPrincipal.faleceu) {
        setCidadaosCnsDefinitivo(cidadaosCnsDefinitivo)
        setOpenCidadaoFaleceuECnsDefinitivoModal(true)
      } else if (maisDeUmCnsDefinitivo) {
        setCidadaosCnsDefinitivo(cidadaosCnsDefinitivo)
        setOpenCidadaoMaisDeUmCnsDefinitivoModal(true)
      } else if (cadastroPrincipal.faleceu) {
        setOpenCidadaoFaleceuModal(true)
      } else {
        setOpenConfirmModal(true)
      }
    }
  }

  const handleConfirmarMaisDeUmCnsDefinitivo = () => {
    setOpenCidadaoMaisDeUmCnsDefinitivoModal(false)
    setOpenConfirmModal(true)
  }

  const handleConfirmarCidFalecido = () => {
    setOpenCidadaoFaleceuModal(false)
    setOpenConfirmModal(true)
  }

  const handleConfirmarCidadaoFaleceuECnsDefinitivo = () => {
    setOpenCidadaoFaleceuECnsDefinitivoModal(false)
    setOpenConfirmModal(true)
  }

  const handleUnificarCadastro = () => {
    return unificaCadastros({
      variables: {
        input: {
          idCidadaoMaster: cadastroPrincipal?.id,
          idCidadaos: cadastrosSelecionados
            ?.filter((cidadao) => cadastroPrincipal.id !== cidadao.id)
            .map((cidadao) => cidadao.id),
        },
      },
    })
      .then(() => {
        setOpenConfirmModal(false)
        setCadastrosSelecionados([])
        setCadastroPrincipal(null)
        clearCidadaosEncontrados()
        alert('success', 'Unificação concluida com sucesso.')
      })
      .catch((e: ApolloError) => {
        setOpenConfirmModal(false)
        setError(handleUnificacaoError(e))
      })
  }

  const fillEquipeUnidadeSaude = () => {
    if (cadastroPrincipal?.cidadaoVinculacaoEquipe) {
      setUnidadeResponsavelResultante(cadastroPrincipal?.cidadaoVinculacaoEquipe?.unidadeSaude?.nome)
    } else {
      const vinculoMaisAtualizado = cadastrosSelecionados
        .filter((item) => item.cidadaoVinculacaoEquipe)
        .map((item) => item.cidadaoVinculacaoEquipe)
        .sort((a, b) => a.dataAtualizacaoCadastro - b.dataAtualizacaoCadastro)
        .pop()
      setUnidadeResponsavelResultante(vinculoMaisAtualizado?.unidadeSaude?.nome)
    }
  }

  const fillCnsCidadaoResultante = () => {
    let cnsResultante: string
    if (!isCnsDefinitivo(cadastroPrincipal?.cns)) {
      cnsResultante = cadastrosSelecionados?.find((cidadao: CidadaoListingFormModel) => isCnsDefinitivo(cidadao?.cns))
        ?.cns
      if (!cnsResultante) {
        if (cadastroPrincipal?.cns) {
          cnsResultante = cadastroPrincipal?.cns
        } else {
          cnsResultante = cadastrosSelecionados?.find((cidadao: CidadaoListingFormModel) => cidadao.cns)?.cns
        }
      }
    } else {
      cnsResultante = cadastroPrincipal?.cns
    }
    setCnsResultante(cnsResultante)
  }

  const fillCpfCidadaoResultante = () => {
    setCpfResultante(cadastrosSelecionados?.find((cidadao) => cidadao.cpf)?.cpf)
  }

  const isCnsDefinitivo = (cns: string) => {
    return info.treinamento ? cns?.startsWith('7') || cns?.startsWith('8') : cns?.startsWith('7')
  }

  const handleCancelarConfirmacao = () => {
    setCpfResultante('')
    setCnsResultante('')
    setUnidadeResponsavelResultante('')
    setOpenConfirmModal(false)
  }

  const handleClearCidadaosSelecionados = () => {
    setCadastrosSelecionados([])
    setCadastroPrincipal(undefined)
  }

  const handleResetCidadaoResultante = () => {
    setCnsResultante(cadastroPrincipal.cns)
    setCpfResultante(cadastroPrincipal.cpf)
  }

  const renderizarCidadaosMaisDeUmCnsModal = () => (
    <VFlow>
      <Text>Os seguintes cadastros selecionados possuem CNS definitivo:</Text>
      {cidadaosCnsDefinitivo.map((cidadao, key) => (
        <VFlow key={key} vSpacing={0}>
          <HFlow>
            <Text data-cy={'CidadaoId' + cidadao.id + '.MsgMaisDeUmCns.Nome'}>
              <b>Nome:</b> {cidadao?.nome ? titleCase(cidadao?.nome) : '-'}
            </Text>
          </HFlow>
          <HFlow>
            <Text data-cy={'CidadaoId' + cidadao.id + '.MsgMaisDeUmCns.Cns'}>
              <b>CNS:</b> {cidadao?.cns ? cidadao?.cns : '-'}
            </Text>
            <Text data-cy={'CidadaoId' + cidadao.id + '.MsgMaisDeUmCns.DataNasc'}>
              <b>Data de nascimento: </b>
              {cidadao?.dataNascimento ? <DateTime value={cidadao?.dataNascimento} format='DD/MM/YYYY' /> : '-'}
            </Text>
          </HFlow>
        </VFlow>
      ))}
    </VFlow>
  )

  const renderizarMensagemModalObito = () => (
    <>
      <Text>O cadastro selecionado como principal possui óbito informado.</Text>
      <br />
      <Text>Após a unificação, o resultado do cadastro do cidadão permanecerá com óbito informado.</Text>
    </>
  )

  return (
    <>
      {error && <UnificacaoErrorModal error={error} onClose={() => setError(null)} />}
      {openConfirmModal && (
        <UnificacaoCadastroConfirmacaoModal
          openConfirmModal={openConfirmModal}
          cancelarConfirmacao={handleCancelarConfirmacao}
          unificarCidadaos={handleUnificarCadastro}
          cadastroResultante={cadastroPrincipal}
          cnsCadastroResultante={cnsResultante}
          cpfCadastroResultante={cpfResultante}
          unidadeResponsavelResultante={unidadeResponsavelResultante}
        />
      )}
      {openCidadaoMaisDeUmCnsDefinitivoModal && (
        <ConfirmarModal
          open={openCidadaoMaisDeUmCnsDefinitivoModal}
          onClickConfirmarCidadao={handleConfirmarMaisDeUmCnsDefinitivo}
          closeModal={() => setOpenCidadaoMaisDeUmCnsDefinitivoModal(false)}
        >
          {renderizarCidadaosMaisDeUmCnsModal()}
        </ConfirmarModal>
      )}
      {openCidadaoFaleceuModal && (
        <ConfirmarModal
          open={openCidadaoFaleceuModal}
          onClickConfirmarCidadao={handleConfirmarCidFalecido}
          closeModal={() => setOpenCidadaoFaleceuModal(false)}
        >
          {renderizarMensagemModalObito()}
        </ConfirmarModal>
      )}
      {openCidadaoFaleceuECnsDefinitivoModal && (
        <ConfirmarModal
          open={openCidadaoFaleceuECnsDefinitivoModal}
          onClickConfirmarCidadao={handleConfirmarCidadaoFaleceuECnsDefinitivo}
          closeModal={() => setOpenCidadaoFaleceuECnsDefinitivoModal(false)}
        >
          <ul>
            <VFlow>
              <li>{renderizarMensagemModalObito()}</li>
              <li>{renderizarCidadaosMaisDeUmCnsModal()}</li>
            </VFlow>
          </ul>
        </ConfirmarModal>
      )}
      {cadastrosEncontrados ? (
        <>
          {(!hasMoreResults || cadastrosSelecionados?.length > 0) && (
            <>
              <HFlow style={{ marginBottom: '0.5rem', alignItems: 'center' }}>
                <Text fontWeight='bold' fontSize={1}>
                  Selecione os cadastros para unificar e defina o cadastro principal para manter os dados
                </Text>
                <Alert type='info' inline={true}>
                  A seleção é mantida ao aplicar novos filtros.
                </Alert>
              </HFlow>
              <SelectedItemContainer>
                <UnificacaoCadastroSelectedList
                  cidadaosSelecionados={cadastrosSelecionados}
                  cidadaoPrincipal={cadastroPrincipal}
                  setCidadaoPrincipal={handleSetCadastroPrincipal}
                  selectCidadao={handleSelectCidadao}
                  handleClearSelecionados={handleClearCidadaosSelecionados}
                  handleOnClickUnificar={handleOnClickUnificar}
                />
              </SelectedItemContainer>
            </>
          )}
          {!hasMoreResults && totalEncontrados > 0 && (
            <VFlow vSpacing={0.5} style={{ marginTop: '1rem' }}>
              <HFlow alignItems='baseline'>
                <Text fontSize={1}>
                  {totalEncontrados === 1
                    ? totalEncontrados + ' cadastro encontrado'
                    : totalEncontrados + ' cadastros encontrados'}
                </Text>
              </HFlow>
              {cadastrosEncontrados?.map((cidadaoEncontrado) => (
                <UnificacaoCadastroFoundListItem
                  key={cidadaoEncontrado.id}
                  cidadao={cidadaoEncontrado}
                  isSameItem={handleSameItem}
                  selectCidadao={handleSelectCidadao}
                />
              ))}
            </VFlow>
          )}
          {!totalEncontrados && !hasMoreResults && <Text>Nenhum resultado encontrado.</Text>}
          {hasMoreResults && <Text>Refine a busca incluindo mais informações.</Text>}
        </>
      ) : (
        <VFlow style={{ marginTop: '1rem' }}>
          <Text fontWeight='bold' fontSize={1}>
            Busque um cidadão pelo seu nome, CPF, CNS ou demais informações.
          </Text>
        </VFlow>
      )}
    </>
  )
}
