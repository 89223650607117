import { Heading, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { useCredenciaisRecebimentoExternoQuery } from 'graphql/hooks.generated'
import { CredencialRecebimentoExternoQueryInput } from 'graphql/types.generated'
import React, { useState } from 'react'

import { CredenciaisRecebimentoExternoForm } from './CredenciaisRecebimentoExternoForm'
import { CredenciaisRecebimentoExternoTable } from './CredenciaisRecebimentoExternoTable'

export function CredenciaisRecebimentoExternoBox() {
  const [filter, setFilter] = useState<CredencialRecebimentoExternoQueryInput>({ pageParams: { page: 0 } })

  const { data, loading, refetch } = useCredenciaisRecebimentoExternoQuery({
    variables: {
      input: filter,
    },
  })

  return (
    <VFlow vSpacing={0.5}>
      <Heading level={3}>Credenciais para API</Heading>

      <Box>
        <VFlow>
          <CredenciaisRecebimentoExternoForm refetch={refetch} />
          <CredenciaisRecebimentoExternoTable data={data} loading={loading} setFilter={setFilter} refetch={refetch} />
        </VFlow>
      </Box>
    </VFlow>
  )
}
