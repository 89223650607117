/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, MonthViewProps, ReferenceMonth, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { createStylesFn, ModifierPredicateMap, ModifierStyleMap } from 'bold-ui/lib/components/Calendar/Calendar'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { Weekday } from 'components/agenda'
import { getDay, isSameDay } from 'date-fns'
import { StatusDiaAgendaEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { DiaModel, statusDiasAgendaRecord } from 'view/agenda/model-agenda'

import { CalendarAgendaLotacaoMonthPaginator } from './CalendarAgendaLotacaoMonthPaginator'
import { CalendarAgendaLotacaoMonthView } from './CalendarAgendaLotacaoMonthView'

export interface CalendarAgendaLotacaoProps extends Omit<MonthViewProps, 'createDateStyles'> {
  onChange?(date: Date): void
  availableWeekdays: Set<Weekday>
  shouldEnableControls?: boolean
  statusDias: ReadonlyArray<DiaModel>
}

export function CalendarAgendaLotacao(props: CalendarAgendaLotacaoProps) {
  const {
    visibleDate: currentDate,
    onChange,
    onDayClick,
    availableWeekdays,
    shouldEnableControls = false,
    statusDias,
    ...rest
  } = props
  const { getServerTimeNow } = useServerTime()
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])
  const shouldDisableControls = !shouldEnableControls && !Boolean(availableWeekdays.size)

  const [visibleMonth, setVisibleMonth] = useState<ReferenceMonth>({
    month: currentDate.getMonth(),
    year: currentDate.getFullYear(),
  })

  const currentDateMillis = Number(currentDate)
  useEffect(() => {
    const internalCurrentDate = new Date(currentDateMillis)
    setVisibleMonth({
      month: internalCurrentDate.getMonth(),
      year: internalCurrentDate.getFullYear(),
    })
  }, [currentDateMillis])

  const statusDiasMap = useMemo(
    () =>
      statusDias?.reduce((mapDias, dia) => {
        mapDias.set(dia.data?.toString(), dia.status)
        return mapDias
      }, new Map<string, StatusDiaAgendaEnum>()),
    [statusDias]
  )

  const renderDay = useCallback(
    (dayOfMonth: Date) => {
      const statusRecord = statusDiasMap ? statusDiasAgendaRecord[statusDiasMap.get(dateAsYyyyMmDd(dayOfMonth))] : null
      return (
        <Tooltip text={statusRecord?.hint}>
          <Text style={styles.tooltip} color='inherit'>
            {dayFormatter.format(dayOfMonth)}
          </Text>
        </Tooltip>
      )
    },
    [statusDiasMap, styles]
  )

  const onChangeMonthPaginator = ({ year, month }: ReferenceMonth) => {
    onChange(new Date(year, month))
    setVisibleMonth({
      year,
      month,
    })
  }

  const visibleDate = useMemo(() => new Date(visibleMonth.year, visibleMonth.month), [visibleMonth])

  const dayModifiers = useMemo(() => {
    const internalCurrentDate = new Date(currentDateMillis)
    return getDayModifiers(
      getServerTimeNow,
      availableWeekdays,
      visibleMonth,
      internalCurrentDate,
      shouldEnableControls,
      statusDiasMap
    )
  }, [getServerTimeNow, availableWeekdays, visibleMonth, currentDateMillis, shouldEnableControls, statusDiasMap])

  const createDateStyles = useMemo(() => createStylesFn(dayModifiers, modifierStyles, theme), [dayModifiers, theme])

  return (
    <VFlow style={styles.container} vSpacing={0.75}>
      <HFlow justifyContent='space-between'>
        <Button
          skin='outline'
          kind='primary'
          size='small'
          onClick={() => onChange(getServerTimeNow())}
          disabled={shouldDisableControls}
          data-testid='hoje-button'
        >
          Hoje
        </Button>
        <div css={modifierStyles.monthPaginator}>
          <CalendarAgendaLotacaoMonthPaginator
            onChange={onChangeMonthPaginator}
            disabled={shouldDisableControls}
            referenceMonth={visibleMonth}
          />
        </div>
      </HFlow>
      <div
        css={css`
          margin-left: -0.5rem;
        `}
      >
        <CalendarAgendaLotacaoMonthView
          visibleDate={visibleDate}
          onChange={composeHandlers(onChange, onDayClick)}
          createDateStyles={createDateStyles}
          disabled={shouldDisableControls}
          renderDay={renderDay}
          {...rest}
        />
      </div>
    </VFlow>
  )
}

const isSameReferenceMonth = (dayOfMonth: Date, referenceMonth: ReferenceMonth): boolean =>
  dayOfMonth.getMonth() === referenceMonth.month && dayOfMonth.getFullYear() === referenceMonth.year

const isDaySelected = (selectedDate: Date, dayOfMonth: Date): boolean => selectedDate.getTime() === dayOfMonth.getTime()

const isToday = (dayOfMonth: Date, referenceMonth: ReferenceMonth, getServerTimeNow: () => Date): boolean =>
  isSameReferenceMonth(dayOfMonth, referenceMonth) && isSameDay(getServerTimeNow(), dayOfMonth)

const isDayAvailable = (dayOfMonth: Date, days?: Map<string, StatusDiaAgendaEnum>) =>
  days.get(dateAsYyyyMmDd(dayOfMonth)) === StatusDiaAgendaEnum.DISPONIVEL

const dayFormatter = new Intl.DateTimeFormat('br', { day: '2-digit' })

const getDayModifiers = (
  getServerTimeNow: () => Date,
  availableWeekdays: Set<Weekday>,
  visibleMonth: ReferenceMonth,
  selectedDate: Date,
  shouldEnableControls: boolean,
  daysStatus: Map<string, StatusDiaAgendaEnum>
): ModifierPredicateMap => ({
  today: (dayOfMonth: Date) => isToday(dayOfMonth, visibleMonth, getServerTimeNow),
  disabled: (dayOfMonth: Date) => !shouldEnableControls && !availableWeekdays.has(getDay(dayOfMonth)),
  selected: (dayOfMonth: Date) => isDaySelected(selectedDate, dayOfMonth),
  adjacentMonth: (dayOfMonth: Date, { visibleDate }) => visibleDate.getMonth() !== dayOfMonth.getMonth(),
  available: (dayOfMonth: Date) => !isDaySelected(selectedDate, dayOfMonth) && isDayAvailable(dayOfMonth, daysStatus),
})

const modifierStyles: ModifierStyleMap = {
  today: (theme: Theme) => css`
    font-weight: bold;
    color: ${theme.pallete.primary.main};
  `,
  disabled: (theme: Theme) => css`
    color: ${theme.pallete.gray.c50};
    &:hover {
      background: initial;
    }
  `,
  selected: (theme: Theme) => css`
    background: ${theme.pallete.primary.main};
    color: ${theme.pallete.surface.main};
    font-weight: bold;
    &:hover {
      background: ${theme.pallete.primary.c30};
      color: ${theme.pallete.surface.main};
    }
  `,
  adjacentMonth: (theme: Theme) => css`
    color: ${theme.pallete.text.disabled};
  `,
  available: () => css`
    background: ${statusDiasAgendaRecord[StatusDiaAgendaEnum.DISPONIVEL].highlightColor.c90};
    &:hover {
      background: ${statusDiasAgendaRecord[StatusDiaAgendaEnum.DISPONIVEL].highlightColor.c80};
    }
  `,
}

const createStyles = (theme: Theme) => ({
  container: css`
    width: 100%;
    max-width: 16rem;
    padding: 0.5rem 1rem;
    background-color: ${theme.pallete.surface.main};
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: 2px;
  `,
  tooltip: css`
    display: flex;
    width: 2rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
  `,
})
