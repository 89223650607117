import { VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { EnderecoSection } from 'components/endereco/EnderecoSection'
import { ProfissionalDetailFragment } from 'graphql/types.generated'
import React from 'react'
import Permissions from 'types/Permissions'
import { ProfissionalInformacoesSection } from 'view/profissional/detail/ProfissionalInformacoesSection'

import ProfissionalAcessosView from './ProfissionalAcessosView'

export interface ProfissionalDadosViewProps {
  profissional: ProfissionalDetailFragment
}

export function ProfissionalDadosView(props: ProfissionalDadosViewProps) {
  const { profissional } = props

  if (!profissional) {
    return null
  }

  return (
    <VFlow vSpacing={2}>
      <ProfissionalInformacoesSection profissional={profissional} />

      <EnderecoSection endereco={profissional.endereco} title='Endereço' />

      <CheckPermission permission={Permissions.visualizarProfissionais.visualizarAcessos}>
        <ProfissionalAcessosView profissionalId={profissional.id} />
      </CheckPermission>
    </VFlow>
  )
}
