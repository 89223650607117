import { css } from '@emotion/core'
import { Button, ButtonProps } from 'bold-ui'
import React from 'react'

export const FooterButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      style={css`
        min-width: 9rem;
        padding-left: 2rem;
        padding-right: 2rem;
      `}
    />
  )
}
