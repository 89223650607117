/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow } from 'bold-ui'
import { Fragment } from 'react'

interface TermosDeUsoHeadingSectionProps {
  title?: string
  children: any
  id?: string
}

export function TermosDeUsoHeadingSection(props: TermosDeUsoHeadingSectionProps) {
  const { title, children, id } = props
  const styles = createStyles()

  return (
    <Fragment>
      <HFlow alignItems='flex-start' hSpacing={0.5} id={id}>
        <Heading level={3} style={styles.heading} color='primary'>
          {title}
        </Heading>
      </HFlow>
      {children}
    </Fragment>
  )
}

const createStyles = () => ({
  heading: css`
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  `,
})
