import { Cell, Grid } from 'bold-ui'
import { CheckboxField, FormDebouncedValueSpy, FormRenderProps, TextField } from 'components/form'
import { FormState } from 'final-form'
import { ListaMedicamentoAtivoConcluidoQueryInput } from 'graphql/types.generated'
import React from 'react'
import { Form } from 'react-final-form'

export interface MedicamentosConcluidosTableFilterProps {
  initialValues?: ListaMedicamentoAtivoConcluidoQueryInput
  onChange(values: ListaMedicamentoAtivoConcluidoQueryInput): void
}

export default function MedicamentosConcluidosTableFilter(props: MedicamentosConcluidosTableFilterProps) {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<ListaMedicamentoAtivoConcluidoQueryInput>) => {
    props.onChange(formState.values)
  }

  const renderForm = (renderProps: FormRenderProps<ListaMedicamentoAtivoConcluidoQueryInput>) => {
    return (
      <>
        <FormDebouncedValueSpy onChange={handleChange} />
        <Grid alignItems='center' gap={1}>
          <Cell size={4}>
            <TextField name='query' placeholder='Pesquise por medicamento' icon='zoomOutline' />
          </Cell>
          <Cell size={8}>
            <CheckboxField
              name='usoContinuo'
              label='Ver apenas as prescrições que possuem medicamento de uso contínuo'
            />
          </Cell>
        </Grid>
      </>
    )
  }

  return (
    <Form<ListaMedicamentoAtivoConcluidoQueryInput>
      onSubmit={onChange}
      render={renderForm}
      initialValues={initialValues}
    />
  )
}
