import { Button, Cell, FormControl, Grid, Heading, HFlow, Text, VFlow } from 'bold-ui'
import { ErrorField, FormFooter, TextField } from 'components/form'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import { InfoIcon } from 'components/InfoIcon'
import { TipoInstalacao } from 'graphql/types.generated'
import React from 'react'

import { meta } from './InstalacaoRootView'

export interface TipoInstalacaoViewProps {
  onAvancar(): void
}

export const TipoInstalacaoView = (props: TipoInstalacaoViewProps) => {
  const { onAvancar } = props

  return (
    <>
      <VFlow>
        <Heading level={2}>Informe o nome e os recursos que estarão disponíveis na instalação</Heading>
        <Grid>
          <Cell xs={12} sm={6} md={5}>
            <TextField
              label={
                <HFlow hSpacing={0.5} alignItems='center'>
                  <RequiredLabel label='Nome da instalação' />
                  <InfoIcon
                    icon='infoCircleFilled'
                    text='Defina um nome para a instalação, ele será usado para identificá-la nos aplicativos do e-SUS APS e em relatórios.'
                  />
                </HFlow>
              }
              placeholder='Ex.: São Paulo, UBS da Trindade'
              name={meta.step1.nomeInstalacao}
            />
          </Cell>
          <Cell xs={12} sm={6} md={5}>
            <TextField
              label={
                <HFlow hSpacing={0.5} alignItems='center'>
                  <RequiredLabel label='Link da minha instalação' />
                  <InfoIcon
                    icon='infoCircleFilled'
                    text='Para realizar a configuração informe o link ou IP do servidor da instalação (por exemplo: esusab.dominio.com). Caso a porta não seja especificada será utilizado o valor padrão do sistema (80).'
                  />
                </HFlow>
              }
              name={meta.step1.linkInstalacao}
            />
          </Cell>
        </Grid>

        <FormControl label='Recursos disponíveis na instalação' required>
          <VFlow vSpacing={0.5}>
            <Grid>
              <Cell xs={12} sm={6} md={5}>
                <RadioFieldContainer name={meta.step1.tipoInstalacao} value={TipoInstalacao.PRONTUARIO}>
                  <VFlow vSpacing={0.5}>
                    <Heading level={3}>Prontuário</Heading>
                    <Text>
                      Possui todos os recursos de prontuário. Permite o uso da instalação por uma ou mais unidades de
                      saúde ou municípios.
                    </Text>
                  </VFlow>
                </RadioFieldContainer>
              </Cell>
              <Cell xs={12} sm={6} md={5}>
                <RadioFieldContainer name={meta.step1.tipoInstalacao} value={TipoInstalacao.CENTRALIZADORA}>
                  <VFlow vSpacing={0.5}>
                    <Heading level={3}>Centralizadora</Heading>
                    <Text>
                      Possui apenas recursos para centralização de dados de instalações de PEC e CDS. Permite
                      centralizar dados de diferentes municípios e unidades de saúde.
                    </Text>
                  </VFlow>
                </RadioFieldContainer>
              </Cell>
            </Grid>
            <ErrorField name={meta.step1.tipoInstalacao} />
          </VFlow>
        </FormControl>
      </VFlow>
      <FormFooter style={{ marginBottom: '1rem' }}>
        <Button kind='primary' onClick={onAvancar}>
          Avançar
        </Button>
      </FormFooter>
    </>
  )
}
