import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'
import { PerfilDetailView } from 'view/perfil/detail/PerfilDetailView'
import PerfilEditView from 'view/perfil/edit/PerfilEditView'
import PerfilCopyView from 'view/perfil/PerfilCopyView'
import PerfilCreateView from 'view/perfil/PerfilCreateView'

import PerfilListView from './list/PerfilListView'

export function PerfilRootView() {
  const { match } = useRouter()

  return (
    <>
      <Breadcrumb title='Perfis' />

      <PecSwitch>
        <PrivateRoute
          exact
          path={`${match.url}`}
          component={PerfilListView}
          permission={Permissions.visualizarPerfis}
        />
        <PrivateRoute
          exact
          path={`${match.url}/cadastro`}
          component={PerfilCreateView}
          permission={Permissions.visualizarPerfis.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:id(\\d+)/edicao`}
          component={PerfilEditView}
          permission={Permissions.visualizarPerfis.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:id(\\d+)/copia`}
          component={PerfilCopyView}
          permission={Permissions.visualizarPerfis.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:id(\\d+)/visualizacao`}
          component={PerfilDetailView}
          permission={Permissions.visualizarPerfis}
        />
      </PecSwitch>
    </>
  )
}
