/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { useMemo } from 'react'

import { AvaliacaoDesenvolvimentoModel } from '../model'
import { descricaoAvaliacoesPresente } from '../util'

interface AvaliacoesPresentesDescriptionProps {
  title: string
  placeholder: string
  avaliacoes: AvaliacaoDesenvolvimentoModel[]
}

export function AvaliacoesPresentesDescription(props: AvaliacoesPresentesDescriptionProps) {
  const { title, placeholder, avaliacoes } = props

  const descricaoAvaliadosComoPresente = useMemo(() => descricaoAvaliacoesPresente(avaliacoes), [avaliacoes])

  return (
    <HLabel title={title}>{descricaoAvaliadosComoPresente || <Text fontStyle='italic'>{placeholder}</Text>}</HLabel>
  )
}
