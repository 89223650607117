/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FormProps } from 'components/form'
import { useParams } from 'react-router'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { SolicitacaoExameModel } from './model'
import SolicitarExamesFormModal, { SolicitarExamesUrlParams } from './SolicitarExamesFormModal'

export interface SolicitarExamesEditarModalProps
  extends Omit<FormProps<SolicitacaoExameModel>, 'initialValues' | 'render'> {
  cidadao: CidadaoAtendimento
  adicionados: SolicitacaoExameModel[]
  onClose(): void
}

export default function SolicitarExamesEditarModal(props: SolicitarExamesEditarModalProps) {
  const { cidadao, onSubmit, onClose, adicionados } = props
  const { id } = useParams<SolicitarExamesUrlParams>()

  return (
    <SolicitarExamesFormModal
      cidadao={cidadao}
      editing={true}
      initialValues={adicionados?.find((item) => item._id === id)}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  )
}
