import { HeadingSection } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import clearTypename from 'graphql/clearTypename'
import { useCidadaoAdLoadQuery, useCidadaoAdSaveMutation, useCidadaoLazyQuery } from 'graphql/hooks.generated'
import { AtencaoDomiciliarCondutaDesfecho, AtencaoDomiciliarModalidade } from 'graphql/types.generated'
import qs from 'qs'
import React, { useEffect, useMemo } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router'

import CidadaoADDadosForm from './components/CidadaoADDadosForm'
import CidadaoADFormModel from './components/CidadaoADFormModel'
import convertModelToInput, { convertToFormCadastroCidadao, convertToFormPosObito } from './convertModelToInput'

interface CidadaoADCreateViewUrlParams {
  cidadaoADEmObitoId: string
  cidadaoId: string
}

export default function CidadaoADCreateView() {
  const history = useHistory()
  const location = useLocation()
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const [save] = useCidadaoAdSaveMutation()
  const { acesso } = useAcessoLotacaoOrEstagio()

  const urlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 1,
  }) as CidadaoADCreateViewUrlParams

  const {
    data: { cidadaoAD },
  } = useCidadaoAdLoadQuery({
    skip: !urlParams?.cidadaoADEmObitoId,
    variables: { input: decodeURIComponent(urlParams.cidadaoADEmObitoId) },
  })

  const [executeCidadaoQuery, { data: cidadaoData }] = useCidadaoLazyQuery()

  useEffect(() => {
    if (urlParams.cidadaoId) {
      executeCidadaoQuery({ variables: { id: urlParams.cidadaoId } })
    }
  }, [executeCidadaoQuery, urlParams.cidadaoId])

  const initialValues = useMemo(() => {
    if (cidadaoAD) return convertToFormPosObito(clearTypename(cidadaoAD))

    if (urlParams.cidadaoId) return convertToFormCadastroCidadao(cidadaoData?.cidadao)

    return {
      condutaDesfecho: AtencaoDomiciliarCondutaDesfecho.PERMANENCIA,
      cuidador: { naoPossuiCuidador: false },
    } as CidadaoADFormModel
  }, [cidadaoAD, cidadaoData, urlParams.cidadaoId])

  const confirmSaveRegistroAD = (values: CidadaoADFormModel, formApi: FormApi) => {
    confirm({
      title: 'Deseja salvar o registro de AD?',
      onConfirm: () => onSave(values, formApi),
    })()
  }

  const handleSubmit = (values: CidadaoADFormModel, formApi: FormApi) => {
    if (urlParams?.cidadaoADEmObitoId && values.modalidade === AtencaoDomiciliarModalidade.POS_OBITO) {
      confirmSaveRegistroAD(values, formApi)
    } else {
      onSave(values, formApi)
    }
  }

  const onSave = (values: CidadaoADFormModel, formApi: FormApi) =>
    save({ variables: { input: convertModelToInput(values) } })
      .then((ret) => {
        formApi.reset()
        alert('success', `Cadastro de ${values.cidadao.nome} salvo com sucesso.`)
        history.push('/ad/lista')
        return ret
      })
      .catch(handleRejection)

  if (acesso?.equipe?.tipoEquipe?.descricao === 'EMAP') {
    return <Redirect to='/403' />
  }

  return (
    <>
      <Breadcrumb title='Cadastro' />
      <PageContent type='filled'>
        <HeadingSection level={1} title='Cadastro de cidadão na atenção domiciliar' />
        <CidadaoADDadosForm onSubmit={handleSubmit} initialValues={initialValues} />
      </PageContent>
    </>
  )
}
