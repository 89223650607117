import { createValidator, required } from 'util/validation'
import { FinalizacaoAtendimentoFormModel } from 'view/atendimentos/detail/soap/finalizacao/FinalizacaoAtendimentoForm'

import { agendarConsultaAvulsaValidator } from './validator-agendamento'

export const finalizacaoOdontoValidator = (
  isDemandaEspontanea: boolean,
  hasEncaminhamentoExterno: boolean,
  getServerTimeNow: () => Date
) =>
  createValidator<FinalizacaoAtendimentoFormModel>({
    tipoAtendimento: [required],
    tipoConsulta: !isDemandaEspontanea && [required],
    condutasOdonto: !hasEncaminhamentoExterno && [required],
    agendamentoConsultas: agendarConsultaAvulsaValidator(getServerTimeNow),
  })
