import { Button, HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import theme from 'config/theme'
import React from 'react'

import { VincularEquipeFormModel } from '../../types/CidadaoFormModel'
import { EstadoComponenteVinculo, UsuarioLogadoVinculacao } from './VincularCidadaoComponentRoot'

interface VincularCidadaoConcludedProps {
  values: VincularEquipeFormModel
  setComponentState(state: EstadoComponenteVinculo): void
  clearComponent(): void
  usuarioLogado: UsuarioLogadoVinculacao
}

export const VincularCidadaoConcluded = (props: VincularCidadaoConcludedProps) => {
  const handleDesvincularClicked = () => {
    props.setComponentState('SEM_VINCULO')
    props.clearComponent()
  }

  return (
    <HFlow
      style={{
        border: `1px solid ${theme.pallete.divider}`,
        padding: '1rem',
      }}
      alignItems='center'
      justifyContent='space-between'
    >
      {props.values.cadastroIndividualCheck && !props.values.unidadeSaude ? (
        <VFlow vSpacing={0}>
          <Text fontStyle='italic' fontSize={0.8}>
            Cidadão sem equipe responsável pois não possui Cadastro Individual
          </Text>
          <Text fontSize={0.8} fontWeight='bold'>
            Utilizando a informação do Cadastro Individual do cidadão
          </Text>
        </VFlow>
      ) : (
        <VFlow vSpacing={0}>
          {props.values.equipe?.nome && (
            <Text fontWeight='bold' fontSize={0.9}>
              {props.values.equipe?.nome.titleCase()} - {props.values.equipe?.tipoEquipe?.sigla}
            </Text>
          )}
          <Text fontSize={0.8}>
            <b>Unidade de saúde</b> {props.values.unidadeSaude?.nome?.titleCase()}
          </Text>
        </VFlow>
      )}

      <HFlow>
        <Button size='small' onClick={() => props.setComponentState('CADASTRAR_VINCULO')}>
          <Icon icon='exchange' size={1.5} style={{ marginRight: '0.5rem' }} />
          Alterar vínculo
        </Button>
        {props.values.unidadeSaude?.nome === props.usuarioLogado.unidadeSaude.nome && (
          <Tooltip
            text={
              props.values.cadastroIndividualCheck &&
              'A desvinculação deve ser realizada no Cadastro Individual do cidadão informando a Mudança de território'
            }
          >
            <Button
              kind='danger'
              size='small'
              onClick={handleDesvincularClicked}
              disabled={props.values.cadastroIndividualCheck}
            >
              <Icon icon='timesDefault' size={1.5} style={{ marginRight: '0.3rem' }} />
              Desvincular
            </Button>
          </Tooltip>
        )}
      </HFlow>
    </HFlow>
  )
}
