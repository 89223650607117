import { Cell, Grid, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { AccordionDataTableRowDefaultProps } from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'
import { format } from 'date-fns'
import { css } from 'emotion'
import React, { useMemo } from 'react'
import { LembreteModel, visibilidadeLembrete } from 'view/atendimentos/detail/soap/aside/types/LembreteModel'

export default function LembretesAccordionPanel(props: AccordionDataTableRowDefaultProps<LembreteModel>) {
  const { row } = props
  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])
  const itemList = row.historicoLembrete.map((lembrete) => {
    const dataLembrete = format(lembrete.data, 'dd/MM/yyyy')
    return (
      <Grid key={lembrete.id} gap={0} gapVertical={0} alignItems='center' wrap={false} style={classes.grid}>
        <VFlow vSpacing={0}>
          <Cell>
            <Icon name='calendario' icon='calendarFilled' size={1} color={theme.pallete.primary.main} />
            <b>
              {dataLembrete} | {lembrete.lotacao.profissional.nome.titleCase()} |{' '}
              {lembrete.lotacao.cbo.nome.titleCase()}
            </b>
          </Cell>
          <Cell>
            {lembrete.ativo ? 'Ativo' : 'Inativo'} | {visibilidadeLembrete[lembrete.visibilidadeLembrete]}
          </Cell>
          <Cell style={classes.descricao} size={12}>
            <b>Descrição: </b>
            <Text dangerouslySetInnerHTML={{ __html: lembrete.descricao }} />
          </Cell>
        </VFlow>
      </Grid>
    )
  })

  return (
    <VFlow vSpacing={0.5} style={classes.vflow}>
      {itemList}
    </VFlow>
  )
}

const styles = (theme: Theme) => ({
  descricao: css`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    overflow: hidden;
    word-break: break-word;
  `,
  vflow: css`
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    background: ${theme.pallete.gray.c90};
    > div:not(:last-of-type) {
      border-bottom: 0.06rem solid ${theme.pallete.divider};
    }
  `,
  grid: css`
    padding: 0rem 1rem 0rem 1rem;
  `,
})
