/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Alert, Button, Cell, Grid, HeadingSection, HFlow, Modal, ModalBody, ModalFooter } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps, SubmitButton } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { metaPath } from 'util/metaPath'

import AvaliacaoDesenvolvimentoTable from '../components/AvaliacaoDesenvolvimentoTable'
import { FatoresRiscoModel } from './FatoresRiscoView'

interface FatoresRiscoModalProps {
  initialValues?: FatoresRiscoModel
  closeModal(): void
  updateFatoresRisco(rows: FatoresRiscoModel): void
}

const name = metaPath<FatoresRiscoModel>()

export function FatoresRiscoModal(props: FatoresRiscoModalProps) {
  const { initialValues, closeModal, updateFatoresRisco } = props
  const alert = useAlert()

  const handleCancelar = (dirtyForm: boolean) => () => {
    if (dirtyForm) {
      confirm({
        title: 'Deseja sair sem salvar?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar aqui',
        confirmLabel: 'Sim, cancelar',
        onConfirm: () => closeModal(),
      })()
    } else closeModal()
  }

  const handleSubmit = (formValues: FatoresRiscoModel) => {
    updateFatoresRisco(formValues)
    closeModal()
    alert('success', 'Avaliação de fatores de risco salva com sucesso')
  }

  const renderForm = (formProps: FormRenderProps<FatoresRiscoModel>) => {
    return (
      <Modal size='large' onClose={handleCancelar(formProps.dirty)} open>
        <ModalBody>
          <HeadingSection level={1} title='Avaliar fatores de risco' />
          <Grid>
            <Cell size={12}>
              <Alert type='info'>
                Um fator de risco é considerado como <strong>Presente</strong> quando este estiver associado a problemas
                no desenvolvimento da criança.
              </Alert>
            </Cell>
            <Cell size={12}>
              <AvaliacaoDesenvolvimentoTable name={name.fatoresRisco} usePrimaryColors />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button title='Cancelar' onClick={handleCancelar(formProps.dirty)}>
              Cancelar
            </Button>
            <SubmitButton title='Salvar' kind='primary' handleSubmit={formProps.handleSubmit}>
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return <Form<FatoresRiscoModel> render={renderForm} onSubmit={handleSubmit} initialValues={initialValues} />
}
