import {
  AcessoAdministradorMunicipalFragment as AcessoAdmMunicipal,
  AcessoEstagioFragment as AcessoEstagio,
  AcessoGestorEstadualFragment as AcessoGtEstadual,
  AcessoGestorMunicipalFragment as AcessoGtMunicipal,
  AcessoLotacaoFragment as AcessoLotacao,
  SessaoDataFragment,
  TipoAcesso,
} from 'graphql/types.generated'

type Acesso = SessaoDataFragment['profissional']['acessos'][0]

const gestorEstadualFn = (a: AcessoGtEstadual, b: AcessoGtEstadual) => {
  return a.uf.nome > b.uf.nome ? 1 : -1
}

const tipoMunicipalFn = (a: AcessoAdmMunicipal | AcessoGtMunicipal, b: AcessoAdmMunicipal | AcessoGtMunicipal) => {
  return a.municipio.nome > b.municipio.nome ? 1 : -1
}

const outrosAcessosFn = (a: Acesso, b: Acesso) => {
  return a.tipo > b.tipo ? 1 : -1
}

const lotacaoFn = (a: AcessoLotacao, b: AcessoLotacao) => {
  if (a.unidadeSaude.nome === b.unidadeSaude.nome) {
    return a.cbo.nome > b.cbo.nome ? 1 : -1
  } else {
    return a.unidadeSaude.nome > b.unidadeSaude.nome ? 1 : -1
  }
}

const estagioFn = (a: AcessoEstagio, b: AcessoEstagio) => {
  if (a.unidadeSaude.nome === b.unidadeSaude.nome) {
    return a.lotacaoSupervisora.profissional.nome > b.lotacaoSupervisora.profissional.nome ? 1 : -1
  } else {
    return a.unidadeSaude.nome > b.unidadeSaude.nome ? 1 : -1
  }
}

const groupCriteria = new Map()
groupCriteria.set(TipoAcesso.ADMINISTRADOR_GERAL, { order: 1, criteria: outrosAcessosFn })
groupCriteria.set(TipoAcesso.GESTOR_ESTADUAL, { order: 2, criteria: gestorEstadualFn })
groupCriteria.set(TipoAcesso.ADMINISTRADOR_MUNICIPAL, { order: 3, criteria: tipoMunicipalFn })
groupCriteria.set(TipoAcesso.GESTOR_MUNICIPAL, { order: 3, criteria: tipoMunicipalFn })
groupCriteria.set(TipoAcesso.LOTACAO, { order: 4, criteria: lotacaoFn })
groupCriteria.set(TipoAcesso.ESTAGIO, { order: 5, criteria: estagioFn })

export default function selecaoAcessoSortCompare(a: Acesso, b: Acesso) {
  const criteriaA = groupCriteria.get(a.tipo)
  const criteriaB = groupCriteria.get(b.tipo)

  if (criteriaA.order === criteriaB.order) {
    return criteriaA.criteria(a, b)
  } else {
    return criteriaA.order > criteriaB.order ? 1 : -1
  }
}
