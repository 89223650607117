import { useLayoutEffect, useState } from 'react'

const CABECALHO_FIXO_SCROLL_BREAKPOINT = 125

export function useCabecalhoFixo() {
  const [isFixo, setIsFixo] = useState(false)

  useLayoutEffect(() => {
    const listener = () => {
      const reachedBreakpoint = window.scrollY >= CABECALHO_FIXO_SCROLL_BREAKPOINT

      if (reachedBreakpoint && !isFixo) {
        setIsFixo(true)
        window.scrollTo(0, window.scrollY)
      } else if (!reachedBreakpoint && isFixo) {
        setIsFixo(false)
      }
    }

    window.addEventListener('scroll', listener)

    return () => window.removeEventListener('scroll', listener)
  }, [isFixo])
  return isFixo
}
