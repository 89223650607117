import axios, { AxiosPromise } from 'axios'

export interface ImpressaoAtendimentoInput {
  atendimentoProfissionalId: ID
  historicoCabecalhoId?: ID
}

export class ImpressaoAtendimentoIndividual {
  imprimirAtendimentoIndividual(input: ImpressaoAtendimentoInput): AxiosPromise<Blob> {
    return axios.post('/api/atendimento/imprimirAtendimentoIndividual', input, { responseType: 'blob' })
  }
}
