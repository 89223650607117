import { GrupoMedicoesTipo as GrupoMedicoesGenerated, TipoMedicaoEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { tipoGlicemiaRecord } from 'types/enums'
import { v4 as uuidv4 } from 'uuid'

import { GrupoMedicoesTipoModel, tipoMedicaoRecord } from '../types/MedicaoModel'

export default (grupoMedicoesHoje: GrupoMedicoesGenerated[], grupoMedicoesAnteriores: GrupoMedicoesGenerated[]) => {
  return useMemo(() => {
    const getMedicoesHoje = () => convertAndSortGrupoMedicoesHoje(grupoMedicoesHoje)

    const allGruposMedicoesAnteriores = breakMedicoesAnteriores(
      convertAndSortGrupoMedicoesAnteriores(grupoMedicoesAnteriores),
      grupoMedicoesAnteriores?.length ?? 0
    )
    const getMedicoesAnterioresExpandido = () => allGruposMedicoesAnteriores.gruposMedicoesExpandido
    const getMedicoesAnterioresAccordion = () => allGruposMedicoesAnteriores.gruposMedicoesAccordion

    return {
      getMedicoesHoje,
      getMedicoesAnterioresExpandido,
      getMedicoesAnterioresAccordion,
    }
  }, [grupoMedicoesAnteriores, grupoMedicoesHoje])
}

export const convertAndSortGrupoMedicoesHoje = (
  grupoMedicoesHoje: GrupoMedicoesGenerated[]
): GrupoMedicoesTipoModel[] => {
  // Ordena o grupo das medições pela ordem padrão que devem ser apresentadas
  grupoMedicoesHoje?.sort(
    (grupoMedicaoA, grupoMedicaoB) =>
      tipoMedicaoRecord[grupoMedicaoA.tipo].order - tipoMedicaoRecord[grupoMedicaoB.tipo].order
  )

  // Ordena todas as medições de cada grupo pela data crescente (da mais antiga pra mais nova)
  grupoMedicoesHoje?.forEach((grupoMedicao) =>
    grupoMedicao.medicoes.sort((medicaoA, medicaoB) => medicaoA.dataMedicao - medicaoB.dataMedicao)
  )

  return convertToModel(grupoMedicoesHoje)
}

export const convertAndSortGrupoMedicoesAnteriores = (
  grupoMedicoesAnteriores: GrupoMedicoesGenerated[]
): GrupoMedicoesTipoModel[] => {
  // A ordem deve ser da mais recente para mais antiga, caso possuam a mesma data, deve ser realizada pela ordenação padrão.
  grupoMedicoesAnteriores?.sort((grupoMedicaoA, grupoMedicaoB) => {
    const diffData =
      grupoMedicaoB.medicoes.sort((medicaoA, medicaoB) => medicaoA.dataMedicao - medicaoB.dataMedicao)[0].dataMedicao -
      grupoMedicaoA.medicoes.sort((medicaoA, medicaoB) => medicaoA.dataMedicao - medicaoB.dataMedicao)[0].dataMedicao

    return diffData === 0
      ? tipoMedicaoRecord[grupoMedicaoA.tipo].order - tipoMedicaoRecord[grupoMedicaoB.tipo].order
      : diffData
  })

  // Ordena todas as medições de cada grupo pela data crescente (da mais antiga pra mais nova)
  grupoMedicoesAnteriores?.forEach((grupoMedicao) =>
    grupoMedicao.medicoes.sort((medicaoA, medicaoB) => medicaoA.dataMedicao - medicaoB.dataMedicao)
  )

  return convertToModel(grupoMedicoesAnteriores)
}

export const convertToModel = (medicoesInput: GrupoMedicoesGenerated[]): GrupoMedicoesTipoModel[] => {
  return (
    medicoesInput
      ?.filter(
        (grupoMedicao) =>
          grupoMedicao.tipo !== TipoMedicaoEnum.DUM && grupoMedicao.tipo !== TipoMedicaoEnum.ALTURA_UTERINA
      )
      ?.map((grupoMedicao) => {
        const { tipo, medicoes } = grupoMedicao

        return {
          tipo: tipo,
          medicoes: medicoes.map(({ dataMedicao, valor, observacao }) => ({
            key: uuidv4(),
            dataMedicao: dataMedicao,
            valor: valor,
            observacao: tipo === TipoMedicaoEnum.GLICEMIA ? tipoGlicemiaRecord[observacao] : observacao,
          })),
        }
      }) ?? []
  )
}

// Quebra as medições anteriores em duas listas, as medições que são apresentadas expandidas e as que são apresentadas no accordion
export const breakMedicoesAnteriores = (
  grupoMedicoesAnterioresModel: GrupoMedicoesTipoModel[],
  totalMedicoesAnteriores: number
) => {
  const gruposMedicoesExpandido: GrupoMedicoesTipoModel[] = []
  const gruposMedicoesAccordion: GrupoMedicoesTipoModel[] = []

  const findAndSetTiposMedicaoInicialOnExpandido = (tiposMedicao: TipoMedicaoEnum[]) => {
    tiposMedicao.forEach((tipoMedicao) => {
      const grupoMedicao = grupoMedicoesAnterioresModel.find((grupoMedicao) => grupoMedicao.tipo === tipoMedicao)
      if (!!grupoMedicao) {
        gruposMedicoesExpandido.push(grupoMedicao)
        grupoMedicoesAnterioresModel.splice(grupoMedicoesAnterioresModel.indexOf(grupoMedicao), 1)
      }
    })
  }

  // O grupo de expandido deve exibir, indiferente da ordem, as medições do tipo Peso, Altura e IMC, caso existam.
  findAndSetTiposMedicaoInicialOnExpandido([TipoMedicaoEnum.PESO, TipoMedicaoEnum.ALTURA, TipoMedicaoEnum.IMC])

  grupoMedicoesAnterioresModel.forEach((medicao) => {
    // Caso o total de medições anteriores for inferior a 5 todas devem ser apresentadas no grupo expandido e o accordion não deve ser exibido
    // se o número de medições for 5 ou +, no grupo de expandidos deve possuir apenas 3 medições e o restante deve ficar dentro do accordion
    if (totalMedicoesAnteriores < 5) {
      gruposMedicoesExpandido.push(medicao)
    } else {
      gruposMedicoesExpandido.length < 3 ? gruposMedicoesExpandido.push(medicao) : gruposMedicoesAccordion.push(medicao)
    }
  })

  return {
    gruposMedicoesExpandido,
    gruposMedicoesAccordion,
  }
}
