/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  DateRange,
  DropdownItem,
  Heading,
  HFlow,
  Icon,
  InfoLabel,
  TableFooter,
  Tag,
  Text,
  Tooltip,
  VFlow,
} from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import AccordionList from 'components/accordion/AccordionList'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { DateTime } from 'components/date'
import { DropdownButton } from 'components/dropdown'
import { confirm } from 'components/modals/confirm'
import { StatusInformation } from 'components/StatusInformation'
import { usePagination } from 'components/table/usePagination'
import { differenceInDays, format } from 'date-fns'
import { Cbo, TipoExameEnum } from 'graphql/types.generated'
import { TextFilterType } from 'hooks/filter/model'
import { isDateInDateRange, useFilter } from 'hooks/filter/useFilter'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { Fragment, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { emptyArray } from 'util/array'
import { toDate } from 'util/date/formatDate'
import { metaPath } from 'util/metaPath'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'
import { LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { showCodigoProcedimento } from '../../objetivo/resultados-exames/util-resultadosExames'
import { grupoCboSolicitacaoExame } from '../acessos'
import { downloadSolicitacaoExames } from './DownloadSolicitacaoExames'
import SolicitacaoExameTableFilter from './ExamesTableFilter'
import { SolicitacaoExamesFilterPopperModel } from './ExamesTableFilterPopperForm'
import { ExameSolicitadoMergedModel, ItemHistoricoSolicitacaoExameModel, SolicitacaoExameModel } from './model'
import { urlSolicitacaoExameEditar, urlSolicitacaoExameReplicar } from './SolicitarExamesRootView'
import { convertHistoricoSolicitacaoExameToImpressao } from './utils'

interface SolicitacaoExamesTableProps {
  atendimentoId: ID
  prontuarioId: ID
  loading: boolean
  cidadaoId: ID
  dataReferencia: Instant
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
  removeItem?(item: SolicitacaoExameModel): void
  readOnly?: boolean
  isObservacaoAndAuxiliar?: boolean
  items: ItemHistoricoSolicitacaoExameModel[]
  solicitacoesExameFromAtend?: SolicitacaoExameModel[]
}

export interface SolicitacaoExameFilterModel extends SolicitacaoExamesFilterPopperModel {
  query?: string
  somenteMeus?: boolean
}

export default function SolicitacaoExamesTable(props: SolicitacaoExamesTableProps) {
  const {
    atendimentoId,
    prontuarioId,
    loading,
    cidadaoId,
    removeItem,
    readOnly,
    dataReferencia,
    lotacao,
    isObservacaoAndAuxiliar = false,
    items,
    solicitacoesExameFromAtend = emptyArray,
  } = props

  const alert = useAlert()
  const { analytics } = useFirebase()
  const history = useHistory()
  const match = useRouteMatch()

  const [filter, setFilter] = useState<SolicitacaoExameFilterModel>({
    tipoExames: [TipoExameEnum.ALTO_CUSTO, TipoExameEnum.COMUM],
  })

  const deleteRowItem = (row: ItemHistoricoSolicitacaoExameModel) => {
    return confirm({
      title: 'Deseja excluir a solicitação de exame?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        removeItem?.(solicitacoesExameFromAtend.find((item) => item._id === row.id))
        alert('success', 'Solicitação de exame excluída com sucesso')
        analytics.logEvent('excluir_solicitacao_exm')
      },
    })
  }

  const handleEditModal = (row: ItemHistoricoSolicitacaoExameModel) => {
    history.push(`${match.url}${urlSolicitacaoExameEditar}${row.id}`)
  }

  const handleReplicar = (row: ItemHistoricoSolicitacaoExameModel) => {
    history.push(`${match.url}${urlSolicitacaoExameReplicar}${row.id}`)
    analytics.logEvent('replicar_solicitação_exm')
  }

  const handleImpressao = (
    row: ItemHistoricoSolicitacaoExameModel,
    cidadaoId: ID,
    prontuarioId: ID,
    atendimentoId?: ID
  ) => {
    analytics.logEvent('imprimir_solicitacao_EXM')
    downloadSolicitacaoExames(
      convertHistoricoSolicitacaoExameToImpressao([row], prontuarioId, atendimentoId, cidadaoId)
    ).catch((_) => alert('danger', 'Falha na impressão da solicitação de exame'))
  }

  const accordionItemHeader = (row: ItemHistoricoSolicitacaoExameModel) => {
    return (
      <VFlow
        vSpacing={0}
        style={css`
          width: 100%;
        `}
      >
        <HFlow
          hSpacing={0.5}
          alignItems='center'
          justifyContent='flex-start'
          style={css`
            grid-auto-columns: auto;
            white-space: nowrap;
          `}
        >
          <Heading
            level={4}
            style={css`
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            <strong>{row.title}</strong>
          </Heading>
          {row.solicitacao.tipoExame === TipoExameEnum.ALTO_CUSTO && <Tag type='danger'>Alto custo</Tag>}
          {row.adicionadoAgora && <StatusInformation text='Registrado agora' />}
        </HFlow>
        <Fragment>
          <DateTime value={row.data} format='DD/MM/YYYY ' />
          {row.lotacao.profissional.nome}
          {' - '}
          {row.lotacao.cbo.nome}
        </Fragment>
      </VFlow>
    )
  }

  const filteredItems = useFilter<ItemHistoricoSolicitacaoExameModel, SolicitacaoExameFilterModel>({
    items: items,
    filter,
    filtersType: [filterTextTypeConfig],
    customFilters: [
      filterBySomenteMeus(filter.somenteMeus, lotacao.profissional.id),
      filterByProfissional(filter.profissional?.id),
      filterByCbo(filter.cbo),
      filterByTipoExames(filter.tipoExames),
      filterByPeriodo(filter.periodo),
    ],
  })

  const { paginatedItems, tableProps } = usePagination({ items: filteredItems })

  const renderActions = (row: ItemHistoricoSolicitacaoExameModel) => {
    const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(row)

    return (
      <Tooltip text='Mais opções'>
        <DropdownButton>
          <DropdownItem type='normal' disabled={!row.adicionadoAgora} onClick={() => handleEditModal(row)}>
            Editar
          </DropdownItem>
          <Tooltip text={tooltipText}>
            <DropdownItem type='danger' disabled={!row.adicionadoAgora || isEditable} onClick={deleteRowItem(row)}>
              <HFlow alignItems='center' hSpacing={0.5}>
                <Icon icon='trashOutline' />
                Excluir
              </HFlow>
            </DropdownItem>
          </Tooltip>
        </DropdownButton>
      </Tooltip>
    )
  }

  const accordionItemButtons = (row: ItemHistoricoSolicitacaoExameModel) => {
    const disabled = !isPrintAllowed(dataReferencia, row)

    return (
      !isObservacaoAndAuxiliar && (
        <CheckPermission permission={grupoCboSolicitacaoExame.adicionar}>
          <HFlow justifyContent='flex-end' hSpacing={0}>
            <Tooltip text={disabled ? 'Prazo de 30 dias para impressão expirado' : 'Imprimir'}>
              <Button
                kind='normal'
                skin='ghost'
                size='small'
                disabled={disabled}
                onClick={() => handleImpressao(row, cidadaoId, prontuarioId, atendimentoId)}
              >
                <Icon icon='printerOutline' />
              </Button>
            </Tooltip>
            {!readOnly && (
              <Fragment>
                <Tooltip text='Replicar exames'>
                  <Button
                    kind='normal'
                    skin='ghost'
                    size='small'
                    disabled={row.adicionadoAgora}
                    onClick={() => {
                      handleReplicar(row)
                    }}
                  >
                    <Icon icon='copyOutline' />
                  </Button>
                </Tooltip>
                {renderActions(row)}
              </Fragment>
            )}
          </HFlow>
        </CheckPermission>
      )
    )
  }

  return (
    <VFlow vSpacing={0.5}>
      <AccordionList<ItemHistoricoSolicitacaoExameModel>
        header={
          <SolicitacaoExameTableFilter
            dataInicioAtendimento={new Date(dataReferencia)}
            initialValues={filter}
            onChange={setFilter}
          />
        }
        loading={loading}
        data={{ content: paginatedItems, pageInfo: {} }}
        accordionItemHeader={accordionItemHeader}
        accordionItemBody={accordionItemBody}
        accordionItemButtons={accordionItemButtons}
        paginate={false}
      />
      <TableFooter {...tableProps} />
    </VFlow>
  )
}

const accordionItem = (resultado: ExameSolicitadoMergedModel) => {
  return (
    <li key={resultado.id} style={{ marginTop: '1em' }}>
      <VFlow vSpacing={0}>
        <HFlow>
          <VFlow vSpacing={0}>
            {resultado.exame.procedimentoReferencia && (
              <Text>
                <Text fontWeight='bold'>{resultado.exame.procedimentoReferencia.descricao.capitalize()}</Text> -{' '}
                {resultado.exame.procedimentoReferencia.codigo.capitalize()}
              </Text>
            )}
            <Text>
              <Text fontWeight='bold'>{resultado.exame.descricao.capitalize()}</Text>
              {` - ${showCodigoProcedimento(resultado.exame.codigo)}`}
            </Text>
          </VFlow>

          {resultado.conferido && (
            <Tag
              type='normal'
              style={css`
                background-color: ${gray.c90};
              `}
            >
              Resultado informado
            </Tag>
          )}
        </HFlow>

        {resultado.observacao && (
          <InfoLabel title='Observação' childStyles={styles.preLine}>
            {resultado.observacao}
          </InfoLabel>
        )}
      </VFlow>
    </li>
  )
}

const accordionItemBody = (row: ItemHistoricoSolicitacaoExameModel) => {
  return (
    <VFlow
      key={row.id}
      style={css`
        margin: 1em;
        margin-top: 0em;
        word-break: break-word;
      `}
    >
      <ul style={{ marginLeft: '-1em', marginBottom: '0' }}>
        {row.solicitacao.examesSolicitados.map((exame) => accordionItem(exame))}
      </ul>

      {row.solicitacao.cid10 && <InfoLabel title='CID 10'>{row.solicitacao.cid10.nome.capitalize()}</InfoLabel>}

      {row.solicitacao.justificativa && (
        <InfoLabel title='Justificativa do procedimento' childStyles={styles.preLine}>
          {row.solicitacao.justificativa}
        </InfoLabel>
      )}

      {row.solicitacao.observacao && (
        <InfoLabel title='Observação' childStyles={styles.preLine}>
          {row.solicitacao.observacao}
        </InfoLabel>
      )}
    </VFlow>
  )
}

const metaRow = metaPath<ItemHistoricoSolicitacaoExameModel>()
const metaFilter = metaPath<SolicitacaoExameFilterModel>()

const filterTextTypeConfig: TextFilterType = {
  filterField: metaFilter.query,
  searchFields: [metaRow.title, metaRow.codigos],
  removeTagsOnFilter: true,
}

const filterByProfissional = (profissionalId: ID) => (row: ItemHistoricoSolicitacaoExameModel) => {
  return profissionalId ? row.lotacao.profissional.id === profissionalId : true
}

const filterByCbo = (cbo: Cbo) => (row: ItemHistoricoSolicitacaoExameModel) => {
  return cbo?.id ? row.lotacao.cbo.id === cbo.id : true
}

const filterByTipoExames = (tipoExames: TipoExameEnum[]) => (row: ItemHistoricoSolicitacaoExameModel) => {
  return tipoExames?.length > 0 ? tipoExames.includes(row.solicitacao.tipoExame) : true
}

const filterBySomenteMeus = (doFilter: boolean, profissionalId: ID) => (row: ItemHistoricoSolicitacaoExameModel) => {
  return doFilter ? row.lotacao.profissional.id === profissionalId : true
}

const filterByPeriodo = (dateRange: DateRange) => (row: ItemHistoricoSolicitacaoExameModel) => {
  const rowDate = row.adicionadoAgora ? toDate(format(new Date(row.data), 'yyyy-MM-dd')) : toDate(row.data)

  return isDateInDateRange(rowDate, dateRange)
}

const isPrintAllowed = (dataAtendimento: number, row: ItemHistoricoSolicitacaoExameModel) => {
  return differenceInDays(dataAtendimento, new Date(row.data)) <= 30
}

const styles = {
  preLine: css`
    white-space: pre-line;
  `,
}
