/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente31(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='30' height='100' viewBox='0 0 30 100' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M21.8032 56.6601C22.1666 52.1292 22.8427 46.6613 23.4265 42.0643C23.49 41.5649 23.5523 41.0759 23.613 40.5994C23.8321 38.8805 24.0303 37.3254 24.1838 36.0392C24.377 34.4203 24.4945 33.2652 24.4998 32.7419C24.4895 32.7271 24.4671 32.6996 24.4222 32.6592C24.312 32.5602 24.1252 32.4383 23.8476 32.301C23.2966 32.0284 22.4864 31.7417 21.486 31.4594C19.4921 30.8965 16.8267 30.3735 14.175 30.0096C11.5205 29.6454 8.90803 29.4445 7.01574 29.5159C6.06077 29.5519 5.33533 29.6562 4.88546 29.819C4.65849 29.9011 4.55379 29.9801 4.51439 30.0257C4.50559 30.0359 4.50128 30.0406 4.49996 30.0459C4.49862 30.0514 4.5003 30.0575 4.50346 30.0708C4.68882 30.8521 5.01768 33.3304 5.42121 36.6981C5.8271 40.0854 6.31326 44.4164 6.8117 48.9397C7.12292 51.764 7.4389 54.6629 7.74316 57.4543C8.41391 63.608 9.02775 69.2396 9.40833 72.3992C9.96165 76.9929 10.8193 83.1 12.1064 88.0517C12.7509 90.5315 13.4952 92.689 14.346 94.2161C15.2185 95.7821 16.0977 96.5016 16.9454 96.5016C17.8635 96.5016 18.5855 96.0103 19.1756 95.0145C19.7766 94.0004 20.2 92.5197 20.4889 90.702C21.0654 87.0734 21.077 82.3107 21.077 77.7768C21.077 68.7276 21.0771 65.7145 21.8032 56.6601Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M7.05994 36.6766C7.66234 37.2317 8.85073 37.7186 10.4209 38.0502C11.968 38.377 13.8066 38.5368 15.6314 38.4951C17.4584 38.4533 19.2476 38.2099 20.7026 37.7448C22.1763 37.2737 23.2142 36.6053 23.6757 35.7833C24.1924 34.863 24.7997 32.971 25.379 30.5086C25.9537 28.0658 26.4887 25.1161 26.8778 22.1203C27.2669 19.1234 27.5085 16.0914 27.4998 13.4819C27.4909 10.8489 27.2261 8.72367 26.6546 7.48681C26.117 6.32313 24.7331 5.31922 22.7645 4.51888C20.8194 3.72809 18.407 3.17788 15.9727 2.85399C13.5406 2.53041 11.11 2.4358 9.13718 2.54499C8.15023 2.59962 7.28829 2.7047 6.60318 2.85375C5.90065 3.00657 5.44569 3.1931 5.21829 3.37172C4.7128 3.76878 4.24493 4.66246 3.85505 6.02926C3.47336 7.36736 3.19349 9.0565 2.99127 10.9216C2.5871 14.6492 2.5 19.01 2.5 22.5217C2.5 29.4812 4.37933 34.2065 7.05994 36.6766Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.70141 35.0489C0.968876 31.8996 0 27.6708 0 22.5217C0 18.9891 0.0866154 14.5185 0.505835 10.6521C0.714878 8.72414 1.01359 6.87672 1.45095 5.34349C1.85556 3.92505 2.49419 2.33246 3.674 1.40571C4.36531 0.862693 5.28647 0.58171 6.07175 0.410879C6.92664 0.224904 7.92858 0.108055 8.99902 0.0488068C11.1444 -0.0699366 13.7324 0.033905 16.3024 0.375827C18.8631 0.716524 21.4995 1.30586 23.7061 2.20295C25.8187 3.06183 27.9716 4.3765 28.9241 6.43828C29.6556 8.02149 29.9247 10.2513 29.986 12.5416C30.0493 14.9062 29.8936 17.6165 29.6057 20.3351C29.0446 25.6324 27.9569 31.1807 26.8691 34.535C26.7132 36.0489 26.4262 38.3011 26.0977 40.8787L26.0928 40.9169C25.4709 45.7965 24.6924 51.9082 24.2952 56.8599C23.577 65.8155 23.577 68.7548 23.577 77.7663V77.7768C23.577 82.2496 23.5719 87.2299 22.9579 91.0943C22.653 93.013 22.1694 94.8664 21.3263 96.2891C20.4398 97.785 19.0114 99.0016 16.9454 99.0016C14.5984 99.0016 13.0813 97.0826 12.1621 95.4329C11.1564 93.6278 10.3489 91.2278 9.68676 88.6806C8.35432 83.5544 7.48109 77.3044 6.92627 72.6982C6.54398 69.5244 5.92881 63.8805 5.25927 57.738L5.25741 57.7208C4.36472 49.531 3.37514 40.4529 2.70141 35.0489ZM4.63664 34.4072C3.00319 31.6155 2 27.6354 2 22.5217C2 15.4899 2.348 4.99052 4.90943 2.97852C7.47086 0.966515 24.6261 1.9039 27.1085 7.2771C29.3081 12.038 27.0002 27.8291 24.9012 34.1171C24.7542 35.6002 24.4576 37.9275 24.1088 40.664L24.1088 40.6641C23.4885 45.5312 22.7032 51.6925 22.3016 56.7C21.577 65.7354 21.577 68.7335 21.577 77.7768C21.577 86.8202 21.536 97.0016 16.9454 97.0016C12.3549 97.0016 10.0192 81.6514 8.91192 72.459C8.53099 69.2965 7.91659 63.6598 7.24563 57.5041C6.33196 49.1218 5.31341 39.7772 4.63664 34.4072Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
