import { Button, HFlow, TableCell, TableRow, Tooltip } from 'bold-ui'
import { useHasImpressaoDocumentosDuplicadosQuery } from 'graphql/hooks.generated'
import { StatusUnificacaoBaseEnum } from 'graphql/types.generated'
import React from 'react'
import { formatDate } from 'util/date/formatDate'

import { baseUnificadaList } from './model'
import { StatusUnificacaoBaseTag } from './StatusUnificacaoBaseTag'

interface RecebimentoDadosRowProps {
  unificacaoId: string
  nomeInstalacao: string
  status?: StatusUnificacaoBaseEnum
  dataSolicitacao: string
  dataFimUnificacao: string
  agendaOnlineAtiva: boolean
  handleMostrarModalAceitar: (id: string) => void
  handleMostrarModalNegar: (id: string) => void
}

export const RecebimentoDadosRow = (props: RecebimentoDadosRowProps) => {
  const {
    unificacaoId,
    nomeInstalacao,
    status,
    dataSolicitacao,
    dataFimUnificacao,
    agendaOnlineAtiva,
    handleMostrarModalAceitar,
    handleMostrarModalNegar,
  } = props

  const {
    data: { hasDocumentosDuplicados },
  } = useHasImpressaoDocumentosDuplicadosQuery({ variables: { id: unificacaoId } })

  const handleDownloadCsvReport = () => {
    const basePath = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : window.location.origin
    window.open(`${basePath}/api/unificacaobase/report?unificacaoId=${unificacaoId}`)
  }

  const isShowDownloadCsvButton = baseUnificadaList.includes(status) && hasDocumentosDuplicados

  return (
    <TableRow key={unificacaoId}>
      <TableCell>{nomeInstalacao}</TableCell>
      <TableCell />
      <TableCell>{formatDate(dataSolicitacao)}</TableCell>
      <TableCell>
        <StatusUnificacaoBaseTag status={status} dataFimUnificacao={dataFimUnificacao} isLoginAtivo={true} />
      </TableCell>
      <TableCell>
        <HFlow hSpacing={1} justifyContent='flex-end'>
          {isShowDownloadCsvButton ? (
            <Tooltip text='Gerar planilha dos cidadãos duplicados'>
              <Button onClick={handleDownloadCsvReport} size='small' kind='primary' skin='outline'>
                Cidadãos duplicados
              </Button>
            </Tooltip>
          ) : (
            <>
              <Button
                disabled={status !== 'AGUARDANDO_AVALIACAO'}
                onClick={() => handleMostrarModalNegar(unificacaoId)}
                size='small'
              >
                Negar
              </Button>

              {agendaOnlineAtiva && status === StatusUnificacaoBaseEnum.AGUARDANDO_AVALIACAO ? (
                <Tooltip text='Não é possível aprovar uma unificação de base com a Agenda Online Habilitada. Desabilite a Agenda Online'>
                  <Button disabled size='small'>
                    Aceitar
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  disabled={status !== 'AGUARDANDO_AVALIACAO'}
                  onClick={() => handleMostrarModalAceitar(unificacaoId)}
                  size='small'
                >
                  Aceitar
                </Button>
              )}
            </>
          )}
        </HFlow>
      </TableCell>
    </TableRow>
  )
}
