/* eslint-disable pec/import-graphql-hooks-from-local */

import { OperationVariables } from '@apollo/react-common'
import {
  MutationHookOptions as OptionsType,
  MutationTuple,
  useMutation as useApolloMutation,
} from '@apollo/react-hooks'
import { DocumentNode } from 'graphql'

// Override useMutation from @apollo/react-hooks to use custom defaults

export function useMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  return useApolloMutation<TData, TVariables>(mutation, options)
}

export type MutationHookOptions<TData = any, TVariables = OperationVariables> = OptionsType<TData, TVariables>
