import { Ref, useCallback, useEffect, useRef } from 'react'
import { timeout } from 'util/promise'

export const SUPPORTS_PICTURE_IN_PICTIRE = document.pictureInPictureEnabled ?? false

declare global {
  interface Document {
    readonly pictureInPictureElement: Element | null
    readonly pictureInPictureEnabled: boolean | undefined
    exitPictureInPicture(): Promise<void> | undefined
  }

  interface PictureInPictureWindow extends EventTarget {
    readonly height: number
    onresize: ((this: PictureInPictureWindow, ev: Event) => any) | null
    readonly width: number
  }

  interface HTMLVideoElement {
    autoPictureInPicture: boolean | undefined
    requestPictureInPicture(): Promise<PictureInPictureWindow> | undefined
    onleavepictureinpicture: ((this: HTMLVideoElement, ev: Event) => any) | null
  }
}

interface UsePictureInPictureProps {
  active: boolean
  onPipActiveChange(newVal: boolean): void
}

export function usePictureInPicture(props: UsePictureInPictureProps) {
  const { active, onPipActiveChange } = props

  const videoRef = useRef<HTMLVideoElement>()
  const metadataLoaded = useRef(false)

  const isMetadataLoaded = useCallback(async () => {
    if (metadataLoaded.current) return true
    if (!videoRef.current) return false

    const loadMetadataPromise = new Promise((resolve) =>
      videoRef.current.addEventListener('loadedmetadata', () => resolve(true), { once: true })
    )
    return await timeout(loadMetadataPromise, 2000, false)
  }, [])

  const openPip = useCallback(async () => {
    if ((await isMetadataLoaded()) && videoRef.current.requestPictureInPicture) {
      try {
        await videoRef.current.requestPictureInPicture()
        onPipActiveChange(true)
      } catch (error) {
        handlePipError(error)
      }
    } else {
      return Promise.resolve()
    }
  }, [isMetadataLoaded, onPipActiveChange])

  const handleShowPip = useCallback(
    async (isPipActive: boolean) => {
      try {
        if (isPipActive && !document.pictureInPictureElement) {
          await openPip()
        } else if (!isPipActive && document.pictureInPictureElement) {
          await closePip()
          onPipActiveChange(false)
        }
      } catch (error) {
        onPipActiveChange(false)
        handlePipError(error)
      }
    },
    [openPip, onPipActiveChange]
  )

  useEffect(() => {
    if (!SUPPORTS_PICTURE_IN_PICTIRE) return
    handleShowPip(active)
  }, [handleShowPip, active])

  const handlePictureinPictureChange = useCallback(() => onPipActiveChange(!!document.pictureInPictureElement), [
    onPipActiveChange,
  ])

  const setVideoRef: Ref<HTMLVideoElement> = useCallback(
    (element: HTMLVideoElement) => {
      const prevRef = videoRef.current
      videoRef.current = element
      metadataLoaded.current = false

      if (prevRef) {
        prevRef.removeEventListener('enterpictureinpicture', handlePictureinPictureChange)
        prevRef.removeEventListener('leavepictureinpicture', handlePictureinPictureChange)
      }

      if (!SUPPORTS_PICTURE_IN_PICTIRE) return

      if (element) {
        element.addEventListener('enterpictureinpicture', handlePictureinPictureChange)
        element.addEventListener('leavepictureinpicture', handlePictureinPictureChange)
      }

      handleShowPip(active)
    },
    [active, handlePictureinPictureChange, handleShowPip]
  )

  return { videoRef: setVideoRef }
}

const closePip = () => document.exitPictureInPicture?.() ?? Promise.resolve()

const handlePipError = (error: any) => {
  if (!(error instanceof DOMException && error.name === 'NotAllowedError')) {
    //Acontece em alguns casos
    throw error
  }
}
