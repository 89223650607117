import { useCallback, useEffect, useState } from 'react'

interface UseMediaDevicesOptions {
  deviceType?: 'audioinput' | 'videoinput' | 'audiooutput'
  onlyDefault?: boolean
}

export function useMediaDevices({ deviceType, onlyDefault }: UseMediaDevicesOptions): MediaDeviceInfo[] {
  const [mediaDevices, setMediaDevices] = useState<MediaDeviceInfo[]>([])

  const getMediaDevices = useCallback(({ deviceType, onlyDefault }: UseMediaDevicesOptions) => {
    navigator.mediaDevices
      ?.enumerateDevices()
      .then((devices) => {
        const filteredDevices = devices.filter(
          (device) =>
            (!deviceType || device.kind === deviceType) &&
            (onlyDefault ? device.deviceId === 'default' : !!device.deviceId)
        )
        setMediaDevices(filteredDevices)
      })
      .catch(() => {
        setMediaDevices([])
      })
  }, [])

  useEffect(() => {
    getMediaDevices({ deviceType, onlyDefault })
    const handleDeviceChange = () => getMediaDevices({ deviceType, onlyDefault })
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

    return () => navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange)
  }, [getMediaDevices, deviceType, onlyDefault])

  return mediaDevices
}
