import { Cell, Grid } from 'bold-ui'
import { Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { FormState } from 'final-form'
import { EquipesQueryInput } from 'graphql/types.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'

interface EquipesVinculadasTableFilterProps {
  initialFilter: EquipesQueryInput
  onChange(values: EquipesQueryInput): void
}

const meta = metaPath<EquipesQueryInput>()

export function EquipesVinculadasTableFilter(props: EquipesVinculadasTableFilterProps) {
  const handleChange = (formState: FormState<EquipesQueryInput>) => props.onChange(formState.values)

  const renderForm = () => {
    return (
      <>
        <FormDebouncedValueSpy onChange={handleChange} />

        <Grid alignItems='center' gap={1}>
          <Cell size={4}>
            <TextField name={meta.query} placeholder='Pesquise pelo nome, tipo ou INE' icon='zoomOutline' />
          </Cell>
        </Grid>
      </>
    )
  }

  return <Form<EquipesQueryInput> onSubmit={props.onChange} render={renderForm} initialValues={props.initialFilter} />
}
