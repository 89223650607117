import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import { ImportarCidadaoView } from './ImportarCidadaoView'

export const ImportarCidadaoRootView = () => {
  const match = useRouteMatch()
  return (
    <>
      <Breadcrumb title='Importar cidadão' />
      <PecSwitch>
        <PrivateRoute
          exact
          path={`${match.url}`}
          permission={Permissions.importarCadastrosDeCidadao}
          component={ImportarCidadaoView}
        />
      </PecSwitch>
    </>
  )
}
