import { Alert } from 'bold-ui'
import { DateTime } from 'components/date'
import { useLoadHorarioGeracaoLotesProcessamentoFichasQuery } from 'graphql/hooks.generated'
import moment from 'moment'
import * as React from 'react'

export const TransmissaoHorarioGeracao = () => {
  const { data } = useLoadHorarioGeracaoLotesProcessamentoFichasQuery({ context: { newRequest: true } })
  let elem = <i>Horário não definido</i>
  if (data && data.loadHorarioGeracaoLotesProcessamentoFichas) {
    elem = <DateTime value={moment(data.loadHorarioGeracaoLotesProcessamentoFichas, 'H:mm:ss')} format='H[h]mm[min]' />
  }
  return (
    <Alert type='info' inline>
      Fichas recebidas online ou importadas por arquivo são processadas automaticamente às {elem}. Se desejar processar
      as fichas agora, solicite ao Instalador.
    </Alert>
  )
}
