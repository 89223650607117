import { NeuroFaixaEtariaEnum } from 'graphql/types.generated'

import { AvaliacaoDesenvolvimentoModel } from '../model'

export const faixaEtariaAtual = (
  marco: Partial<Record<NeuroFaixaEtariaEnum, AvaliacaoDesenvolvimentoModel[]>>
): AvaliacaoDesenvolvimentoModel[] => {
  const marcoKeys = Object.keys(marco)
  return marco[marcoKeys[marcoKeys.length - 1]]
}
