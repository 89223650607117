import {
  AtendimentoObservacaoResponsavel,
  ResponsavelAtendimentoObservacaoFragment,
  TipoAtendimentoProfissional,
} from 'graphql/types.generated'

export interface AtendimentoObservacaoModel {
  isAtendimentoObservacao: boolean
  isObservacaoAndResponsavel: boolean
  isObservacaoAndAuxiliar: boolean
  isObservacaoEmAndamento: boolean
  ultimoResponsavelObservacao: ResponsavelObservacaoModel
  responsaveisObservacao: ResponsavelObservacaoModel[]
  atendeuComoResponsavel: boolean
}

export interface ResponsavelObservacaoModel extends ResponsavelAtendimentoObservacaoFragment {}

export interface AtendimentoObservacaoResponsavelModel
  extends Omit<AtendimentoObservacaoResponsavel, 'id' | 'atendimentoObservacao' | 'responsavel'> {
  responsavel: ResponsavelObservacaoModel
}

export interface AtendimentoProfissionalObservacaoInput {
  finalizadoEm: Instant
  tipo: TipoAtendimentoProfissional
  lotacao: {
    id: ID
  }
  estagio: {
    id: ID
  }
  atendimentoProfissionalObservacao?: {
    atendimentoObservacao: {
      responsaveis: AtendimentoObservacaoResponsavelModel[]
      ultimoResponsavelObservacao: ResponsavelObservacaoModel
    }
  }
}

export const OBSERVACAO_DISABLE_REMOVE_TOOLTIP_TEXT =
  'Não é possível excluir registros em rascunho dentro de um atendimento de observação.'

export const RESULTADO_EM_OBSERVACAO_REQUIRED_MESSAGE =
  'Não é possível remover resultados em rascunho dentro de um atendimento de observação.'

export const OBSERVACAO_DISABLE_ATENDIMENTO_COMPARTILHADO =
  'Não é possível editar o campo Atendimento compartilhado dentro de um atendimento de observação.'

export const OBSERVACAO_DISABLE_RACIONALIDADE_EM_SAUDE =
  'Não é possível editar o campo Racionalidade em saúde dentro de um atendimento de observação.'

export const OBSERVACAO_DISABLE_EDIT_AND_REMOVE_ATESTADOS_TOOLTIP_TEXT =
  'Não é possível editar ou excluir atestados em rascunho dentro de um atendimento de observação.'

export const OBSERVACAO_DISABLE_HISTORICO_PRINT =
  'Não é possível imprimir o atendimento, enquanto o atendimento de observação estiver em andamento'

export const OBSERVACAO_DISABLE_LIMPAR_CAMPOS_MCA =
  'Não é possível deletar informações em rascunho dentro de um atendimento de observação.'

export const OBSERVACAO_DISABLE_SWITCH_INSERIR_NA_LPC =
  'Não é possível remover este registro da lista de problemas/condições dentro de um atendimento de observação.'

export const OBSERVACAO_DISABLE_TIPO_ATENDIMENTO =
  'Todos os atendimentos de observação possuem o tipo de atendimento de urgência.'
