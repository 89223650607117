/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { Icons, Tag, Text, Theme, useTheme } from 'bold-ui'
import { green, orange, red } from 'bold-ui/lib/styles/colors'
import { StatusAvaliadoEnum } from 'graphql/types.generated'
import { formatDate } from 'util/date/formatDate'

interface StatusAvaliacaoTagProps {
  status: StatusAvaliadoEnum
  dataAvaliacao: LocalDate
  usePrimaryColors: boolean
}

export default function StatusAvaliacaoTag(props: StatusAvaliacaoTagProps) {
  const { status, dataAvaliacao, usePrimaryColors } = props
  const theme = useTheme()
  const classes = createTypeStyles(theme, usePrimaryColors)[status]

  return (
    <Tag type='info' icon={classes.icon} style={classes.style}>
      <Text fontWeight='bold' style={classes.style}>
        {`${classes.title.capitalize()}`}
        <Text fontWeight='normal' style={classes.style}>
          {` - `}
          {formatDate(dataAvaliacao)}
        </Text>
      </Text>
    </Tag>
  )
}

const createTypeStyles = (
  theme: Theme,
  usePrimaryColors: boolean
): Record<
  StatusAvaliadoEnum,
  {
    title: string
    icon: Icons
    style: SerializedStyles
  }
> => {
  return {
    PRESENTE: {
      title: 'Presente',
      icon: 'checkCircleFilled',
      style: css`
        background: ${usePrimaryColors ? theme.pallete.status.info.background : green.c90};
        color: ${usePrimaryColors ? theme.pallete.status.info.main : green.c40};
        border-color: ${usePrimaryColors ? theme.pallete.status.info.main : null};
      `,
    },
    PRESENTE_COM_ATRASO: {
      title: 'Presente com atraso',
      icon: 'exclamationTriangleFilled',
      style: css`
        background: ${red.c90};
        color: ${orange.c40};
      `,
    },
    AUSENTE: {
      title: 'Ausente',
      icon: 'timesFilled',
      style: css`
        background-color: ${usePrimaryColors ? theme.pallete.status.info.background : red.c90};
        color: ${usePrimaryColors ? theme.pallete.status.info.main : red.c40};
        border-color: ${usePrimaryColors ? theme.pallete.status.info.main : null};
      `,
    },
    NAO_AVALIADO: {
      title: null,
      icon: null,
      style: null,
    },
  }
}
