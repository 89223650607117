/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Link, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { useIsOverflowing } from 'hooks/useIsOverflowing'
import { useMemo, useRef, useState } from 'react'
import { formatHoursMinutes } from 'util/date/formatDate'

import { PlanoCuidadoModel } from '../model'

interface HistoricoPlanoCuidadoListItemProps {
  planoDeCuidado: PlanoCuidadoModel
}

export const HistoricoPlanoCuidadoListItem = (props: HistoricoPlanoCuidadoListItemProps) => {
  const { planoDeCuidado } = props

  const ref = useRef<HTMLDivElement>()
  const theme = useTheme()

  const [isExpanded, setIsExpanded] = useState(false)

  const isExpandable = useIsOverflowing(ref, 'vertical')
  const isDisabled = useMemo(() => !isExpandable && !isExpanded, [isExpandable, isExpanded])

  const classes = useMemo(() => createStyles(theme, isExpanded), [isExpanded, theme])

  const TextContent = () => (
    <Text
      style={classes.text}
      dangerouslySetInnerHTML={{
        __html: planoDeCuidado.descricao,
      }}
    />
  )

  const toggleExpanded = () => {
    if (!isDisabled) {
      setIsExpanded((prevValue) => !prevValue)
    }
  }

  return (
    <Box style={classes.box}>
      <VFlow vSpacing={0.5}>
        <HFlow hSpacing={0.5}>
          <Icon icon='calendarFilled' fill='info' size={1} />
          <Text>
            <strong>{formatHoursMinutes(planoDeCuidado.dataDescricao)}</strong> |{' '}
            {planoDeCuidado.atendimentoProfissionalObservacao.atendimentoProfissional.lotacao.profissional.nome} (
            {planoDeCuidado.atendimentoProfissionalObservacao.atendimentoProfissional.lotacao.cbo.nome.titleCase()})
          </Text>
        </HFlow>

        <div>
          <div ref={ref} css={classes.root}>
            <TextContent />
          </div>

          {isExpanded && <TextContent />}
        </div>

        {(isExpandable || isExpanded) && <Link onClick={toggleExpanded}>Ler {isExpanded ? 'menos' : 'mais'}</Link>}
      </VFlow>
    </Box>
  )
}

const createStyles = (theme: Theme, isExpanded: boolean) => ({
  box: css`
    border-color: ${theme.pallete.gray.c60};
  `,
  root: css`
    display: ${isExpanded ? 'none' : '-webkit-box'};
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  `,
  text: css`
    word-break: break-word;
  `,
})
