import { useEffect } from 'react'

declare global {
  interface MediaSession {
    setActionHandler(action: 'togglecamera' | 'togglemicrophone', handler?: () => void): void
    setCameraActive(active: boolean): void
    setMicrophoneActive(active: boolean): void
  }
  interface Navigator {
    readonly mediaSession: MediaSession
  }
}

interface UseMediaSessionOptions {
  isCameraActive: boolean
  isMicrophoneActive: boolean
  onCameraToggle?(): void
  onMicrophoneToggle?(): void
}

export function useMediaSession(options: UseMediaSessionOptions) {
  const { onCameraToggle, onMicrophoneToggle, isCameraActive, isMicrophoneActive } = options

  useEffect(() => {
    const mediaSession = window.navigator.mediaSession
    if (mediaSession) {
      try {
        mediaSession.setActionHandler('togglecamera', onCameraToggle)
        mediaSession.setActionHandler('togglemicrophone', onMicrophoneToggle)

        return () => {
          mediaSession.setActionHandler('togglecamera', null)
          mediaSession.setActionHandler('togglemicrophone', null)
        }
      } catch (error) {
        // Acontece no Firefox
      }
    }
  }, [onCameraToggle, onMicrophoneToggle])

  useEffect(() => {
    const mediaSession = window.navigator.mediaSession
    mediaSession?.setCameraActive?.(isCameraActive)
  }, [isCameraActive])

  useEffect(() => {
    const mediaSession = window.navigator.mediaSession
    mediaSession?.setMicrophoneActive?.(isMicrophoneActive)
  }, [isMicrophoneActive])
}
