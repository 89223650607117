/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, Theme, useTheme, VFlow } from 'bold-ui'
import { AccordionControls } from 'components/accordion/useAccordionControl'
import { Accordion, AccordionItem } from 'react-accessible-accordion'

import { BodyWithDetailAndForm, BodyWithDetailAndFormProps } from './components/BodyWithDetailAndForm'
import { MainDetailAccordionHeader, MainDetailAccordionHeaderProps } from './components/MainDetailAccordionHeader'
import { MainDetailAccordionEvolucaoType, MainDetailAccordionType } from './model'

interface MainDetailAccordionProps<E extends MainDetailAccordionEvolucaoType, T extends MainDetailAccordionType<E>>
  extends Omit<MainDetailAccordionHeaderProps<E, T>, 'item'>,
    Omit<BodyWithDetailAndFormProps<E, T>, 'item' | 'accordionItemPanelStyles'>,
    Partial<Pick<AccordionControls, 'isExpanded'>> {
  items: T[]
  onChange?(uuid: string[]): void
}

export function MainDetailAccordion<E extends MainDetailAccordionEvolucaoType, T extends MainDetailAccordionType<E>>(
  props: MainDetailAccordionProps<E, T>
) {
  const { items, loading, formProps, renderDetailBody, onChange, isExpanded, ...headerProps } = props

  const theme = useTheme()
  const { accortionItemStyles } = createStyles(theme)

  return (
    <Accordion allowZeroExpanded onChange={onChange}>
      <VFlow vSpacing={0.5}>
        {items?.map((item: T) => (
          <AccordionItem
            uuid={item.id}
            key={item.id}
            css={accortionItemStyles}
            dangerouslySetExpanded={isExpanded?.(item.id)}
          >
            <MainDetailAccordionHeader<E, T> item={item} {...headerProps} />

            <BodyWithDetailAndForm<E, T>
              item={item}
              loading={loading}
              formProps={formProps}
              renderDetailBody={renderDetailBody}
            />
          </AccordionItem>
        ))}
      </VFlow>
    </Accordion>
  )
}

const createStyles = (theme: Theme) => ({
  accortionItemStyles: css`
    border: 1px solid ${theme.pallete.gray.c60};
    border-radius: ${theme.radius.input}px;
    background: ${theme.pallete.gray.c90};
    &:focus-within {
      outline: none;
      box-shadow: ${focusBoxShadow(theme, 'primary')};
      transition-property: box-shadow;
      transition-duration: 0.2s;
    }
  `,
})
