/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useTheme } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { ReactComponent as UserSearch } from 'images/agenda/user-search.svg'
import { useHistory } from 'react-router'
import Permissions from 'types/Permissions'
import { grupoCboVisualizarApenasEncaminhamentos } from 'view/cidadao/acessos'

import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'

interface VisualizarInformacoesCidadaoButtonProps {
  cidadaoId: string
  isDropdown: boolean
  disabled: boolean
}

export function VisualizarInformacoesCidadaoButton(props: VisualizarInformacoesCidadaoButtonProps) {
  const { cidadaoId, isDropdown, disabled } = props
  const history = useHistory()
  const handleClickVisualizarInformacoesCidadao = () => {
    history.push(`/cidadao/${cidadaoId}/informacoes`)
  }
  const theme = useTheme()

  return (
    <CheckPermission
      hasAnyCbo={[grupoCboVisualizarApenasEncaminhamentos]}
      hasAnyPermission={[Permissions.visualizarAgenda]}
    >
      <MenuAcoesAgendamentoButton
        text='Visualizar informações do cidadão'
        tooltip='Visualizar informações do cidadão'
        disabled={disabled}
        isDropdown={isDropdown}
        onClick={handleClickVisualizarInformacoesCidadao}
        icon={
          <UserSearch
            css={css`
              fill: ${theme.pallete.text.main};
            `}
          />
        }
      />
    </CheckPermission>
  )
}
