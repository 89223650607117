import { NecessidadeProteseEnum } from './model'

export const convertNecessidadeProteseEnumToInput = (necessidadeProtese: NecessidadeProteseEnum): boolean => {
  switch (necessidadeProtese) {
    case NecessidadeProteseEnum.NAO:
      return false
    case NecessidadeProteseEnum.SIM:
      return true
  }

  return null
}
