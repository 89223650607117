/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Form, FormProps } from 'components/form'
import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { useServerTime } from 'hooks/useServerTime'
import { compact } from 'lodash'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, maxLength, required } from 'util/validation'

import { MAX_LENGTH_OBS_ODONTO } from '../model'
import { CaracteristicasLesaoFieldModel } from './components/caracteristicas-lesao/CaracteristicasLesaoField'
import { LesaoFundamentalFieldModel } from './components/caracteristicas-lesao/components/LesaoFundamentalField'
import { SintomatologiaFieldModel } from './components/caracteristicas-lesao/components/SintomatologiaField'
import { LocalTecidosMolesSelectFieldModel } from './components/LocalTecidosMolesSelectField/LocalTecidosMolesSelectField'
import { TecidosMolesFieldModel } from './TecidosMolesField'

export interface TecidosMolesFormModel extends Omit<TecidosMolesFieldModel, '_id'> {
  groupErrors?: any
}

interface TecidosMolesFormProps extends Omit<FormProps<TecidosMolesFormModel>, 'validate' | 'render'> {
  render: FormProps<TecidosMolesFormModel>['render']
  onCancel?(): void
  procedimentosSelecionados: ProcedimentoOdontoSelectModel[]
}

const meta = metaPath<TecidosMolesFormModel>()

const validator = (currentProcedimentosIds: ID[]) =>
  createValidator<TecidosMolesFormModel>(
    {
      local: [required],
      observacao: [maxLength(MAX_LENGTH_OBS_ODONTO)],
      caracteristicasLesao: createValidator<CaracteristicasLesaoFieldModel>({
        lesaoFundamental: createValidator<LesaoFundamentalFieldModel>({}, (values, errors) => {
          if (values?.value) {
            errors.lesaoEnvolvida = required(values?.lesaoEnvolvida)
          }

          return errors
        }),
        sintomatologia: createValidator<SintomatologiaFieldModel>({}, (values, errors) => {
          if (values?.value === 'outros') {
            errors.descricaoOutro = required(values?.descricaoOutro) || maxLength(100)(values?.descricaoOutro)
          }

          return errors
        }),
      }),
    },
    (values: TecidosMolesFormModel, errors: ErrorObject<TecidosMolesFormModel>) => {
      if (required(values.problema || values.procedimentos?.length > 0 || values.caracteristicasLesao)) {
        errors.groupErrors =
          'É obrigatório o preenchimento de pelo menos CID 10, Características da lesão ou Procedimentos.'
      }

      const hasProcedimentoDuplicado =
        compact(values.procedimentos?.map(({ id: procedId }) => currentProcedimentosIds?.includes(procedId))).length > 0

      if (hasProcedimentoDuplicado) {
        errors.procedimentos = 'Não é possível registrar procedimentos duplicados.'
      }

      return errors
    }
  )

const decorator = createDecorator(
  {
    field: meta.procedimentos.absolutePath(),
    updates: {
      [meta.observacao.absolutePath()]: (value: ProcedimentoOdontoSelectModel[], allValues: TecidosMolesFormModel) => {
        if (value?.length > 0) return allValues.observacao
      },
    },
  },
  {
    field: meta.local.absolutePath(),
    updates: {
      [meta.problema.absolutePath()]: (
        value: LocalTecidosMolesSelectFieldModel,
        allValues: TecidosMolesFormModel,
        prevValues: TecidosMolesFormModel
      ) => {
        const currentLocal = value?.value

        if (prevValues?.local?.value) {
          if (currentLocal === prevValues?.local?.value) return allValues.problema
        } else {
          if (currentLocal) return allValues.problema
        }
      },
      [meta.procedimentos.absolutePath()]: (
        value: LocalTecidosMolesSelectFieldModel,
        allValues: TecidosMolesFormModel,
        prevValues: TecidosMolesFormModel
      ) => {
        const currentLocal = value?.value

        if (prevValues?.local?.value) {
          if (currentLocal === prevValues?.local?.value) return allValues.procedimentos
        } else {
          if (currentLocal) return allValues.procedimentos
        }
      },
    },
  }
)

export function TecidosMolesForm(props: TecidosMolesFormProps) {
  const { render, onSubmit, procedimentosSelecionados, ...rest } = props

  const { getServerTimeNow } = useServerTime()

  const handleSubmit = (values: TecidosMolesFormModel, formApi: FormApi<TecidosMolesFormModel>) => {
    onSubmit(
      {
        ...values,
        observacao: values?.observacao?.trim() ? values.observacao : undefined,
        lastUpdate: getServerTimeNow().valueOf(),
      },
      formApi
    )
    setTimeout(formApi.reset)
  }

  return (
    <Form<TecidosMolesFormModel>
      validate={validator(procedimentosSelecionados?.map((proced) => proced?.id))}
      decorators={[decorator]}
      render={render}
      onSubmit={handleSubmit}
      {...rest}
    />
  )
}
