/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { LoadingIndicator } from 'components/loading'
import { AcessarVideochamadaButton, VideochamadaUrlTextInput } from 'components/videochamada'
import { useVideochamadaAtendimentoQuery } from 'graphql/hooks.generated'
import { ReactComponent as VideochamadaIcon } from 'images/videochamada/videochamada.svg'
import React from 'react'
import { lotacaoAgendamentoToString } from 'view/agenda/util-agenda'
import { ProntuarioCard } from 'view/atendimentos/detail/soap/aside/ProntuarioCard'

import { CompartilharVideochamadaFolhaRostoForm } from './CompartilharVideochamadaFolhaRostoForm'

export interface FolhaRostoVideochamadaPanelProps {
  atendimentoId: ID
}

export const FolhaRostoVideochamadaPanel = ({ atendimentoId }: FolhaRostoVideochamadaPanelProps) => {
  const { data } = useVideochamadaAtendimentoQuery({ variables: { atendimentoId } })

  if (!data?.atendimento?.agendado?.videochamada) return <LoadingIndicator />

  const { videochamada, profissionalParticipante, lotacaoAgendada } = data.atendimento.agendado

  return (
    <ProntuarioCard
      title={<PanelTitle />}
      content={
        <AcessarVideochamadaButton videochamadaUuid={videochamada.uuid} style={styles.headerButton}>
          Entrar
        </AcessarVideochamadaButton>
      }
    >
      <div css={styles.content}>
        <VFlow vSpacing={0.5}>
          <VFlow vSpacing={0.25}>
            <Heading level={4}>Compartilhar chamada de vídeo por e-mail</Heading>
            <VideochamadaUrlTextInput videochamadaUuid={videochamada.uuid} />
          </VFlow>
          <Text>
            Para compartilhar a chamada, é possível enviar um email com o link da chamada de vídeo para o profissional
            convidado.
          </Text>
          <HLabel title='Profissional convidado:'>
            {lotacaoAgendamentoToString({
              nomeProfissional: profissionalParticipante.nome,
              ineEquipe: profissionalParticipante.lotacao.equipe?.ine,
              nomeEquipe: profissionalParticipante.lotacao.equipe?.nome,
              nomeCbo: profissionalParticipante.lotacao.cbo?.nome,
            })}
          </HLabel>
          <CompartilharVideochamadaFolhaRostoForm
            videochamadaUuid={videochamada.uuid}
            emailDestinatario={profissionalParticipante.email}
            lotacaoAgendadaId={lotacaoAgendada.id}
          />
        </VFlow>
      </div>
    </ProntuarioCard>
  )
}

const PanelTitle = React.memo(() => (
  <HFlow hSpacing={0.5}>
    <VideochamadaIcon />
    <Heading level={3}>Consulta via Videochamada e-SUS APS</Heading>
  </HFlow>
))

const styles = {
  content: css`
    padding: 0.5rem;
  `,
  headerButton: css`
    margin: -0.25rem 0;
  `,
}
