import CheckPermission from 'components/auth/CheckPermission'
import React from 'react'
import Permissions from 'types/Permissions'

import TipoServicoTable from './TipoServicoTable'

export function TipoServicoView() {
  return (
    <CheckPermission permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirTipoDeServico}>
      <TipoServicoTable />
    </CheckPermission>
  )
}
