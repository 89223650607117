/**
 * @deprecated Utilize `input.titleCase()` no lugar.
 */
export const titleCase = (input: string) => {
  return input?.titleCase()
}

/**
 * @deprecated Utilize `input.capitalize()` no lugar.
 */
export const capitalize = (input: string) => {
  return input?.capitalize()
}
