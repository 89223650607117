/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import AccordionList from 'components/accordion/AccordionList'
import { DateTime } from 'components/date'
import { HLabel } from 'components/HLabel'
import theme from 'config/theme'
import { AtendimentoProfissionalOdonto } from 'graphql/types.generated'

import { PeriogramaCompletoTableModel } from '../model'
import PeriogramaCompletoView from '../periograma-completo-table-view/PeriogramaCompletoView'
import { calculaPipsPeriogramaCompleto, calculaPorcentagensPeriogramaCompleto } from '../periogramaCompletoCalculos'

export interface HistoricoPeriogramaCompletoItemModel {
  id: ID
  atendProfOdonto: AtendimentoProfissionalOdonto
  periogramaCompleto: PeriogramaCompletoTableModel
  observacao?: string
}

interface HistoricoPeriogramaCompletoListProps {
  data: HistoricoPeriogramaCompletoItemModel[]
  onAccordionChange(uuids: string[]): void
}

export const HistoricoPeriogramaCompletoList = (props: HistoricoPeriogramaCompletoListProps) => {
  const { data, onAccordionChange } = props

  return (
    <AccordionList<HistoricoPeriogramaCompletoItemModel>
      onAccordionChange={onAccordionChange}
      data={{
        content: data,
        pageInfo: {},
      }}
      paginate={false}
      style={classes.accordion}
      allowMultipleExpanded={false}
      accordionItemHeader={(item) => {
        const { porcentagemPlaca, porcentagemSang } = calculaPorcentagensPeriogramaCompleto(item.periogramaCompleto)

        return (
          <VFlow vSpacing={0}>
            <HFlow hSpacing={0.25}>
              <DateTime
                value={item.atendProfOdonto.atendimentoProfissional.iniciadoEm}
                format='DD/MM/YYYY'
                style={css`
                  font-weight: bold;
                `}
              />
              {'|'}
              <Text>
                {item.atendProfOdonto.atendimentoProfissional.lotacao.profissional.nome} (
                {item.atendProfOdonto.atendimentoProfissional.lotacao.cbo.nome})
              </Text>
            </HFlow>
            <HFlow hSpacing={0.25}>
              <HLabel title='% de sítios com Placa'>{porcentagemPlaca}</HLabel>
              {'|'}
              <HLabel title='% de sítios com Sangramento'>{porcentagemSang}</HLabel>
            </HFlow>
            {item.atendProfOdonto.observacaoRPC && (
              <Text>
                <strong>Observações: </strong>
                {item.atendProfOdonto.observacaoRPC}
              </Text>
            )}
          </VFlow>
        )
      }}
      accordionItemBody={(item) => (
        <PeriogramaCompletoView
          values={item.periogramaCompleto}
          pips={calculaPipsPeriogramaCompleto(item.periogramaCompleto)}
        />
      )}
    />
  )
}

const classes = {
  accordion: css`
    [data-accordion-component='AccordionItem'] {
      border: 0;
    }

    [data-accordion-component='AccordionItemButton'][aria-expanded='true'],
    [data-accordion-component='AccordionItemButton'][aria-expanded='false'] {
      border: 1px solid ${theme.pallete.gray.c80};
      border-radius: 2px;
    }
  `,
}
