import api from 'api'
import { ImpressaoAgendamentoInput, ImpressaoComprovanteAgendamentoInput } from 'api/ImpressaoAgendamento'
import { defaultHandleError, downloadFile } from 'util/downloadFile'

export const downloadImpressaoAgendamentos = (input: ImpressaoAgendamentoInput) => {
  return api.agendamento
    .gerarAgendamentos(input)
    .then((response) => {
      downloadFile(
        new Blob([response.data]),
        `Agendamentos_${input.nomeProfissional.split(' ').join('_')}_${new Date().getTime()}.pdf`
      )
      return response
    })
    .catch(defaultHandleError)
}

export const downloadImpressaoAgendamentoCidadao = (input: ImpressaoComprovanteAgendamentoInput) => {
  return api.agendamento
    .gerarComprovanteAgendamento(input)
    .then((response) => {
      const nomeCidadao = input.nomeCidadao.titleCase().split(' ').slice(0, 2).join('_')
      downloadFile(new Blob([response.data]), `${nomeCidadao}_comprovante_agendamento.pdf`)
      return response
    })
    .catch(defaultHandleError)
}
