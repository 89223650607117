import { Heading, HFlow, Icon, Text, VFlow } from 'bold-ui'
import React from 'react'

interface AtestadoConfirmarAssinaturaDigitalModalProps {
  emailCidadao: string
  children: React.ReactNode
}

export const AtestadoConfirmarAssinaturaDigitalModal = (props: AtestadoConfirmarAssinaturaDigitalModalProps) => {
  const { emailCidadao, children } = props
  return (
    <VFlow vSpacing={2}>
      <HFlow>
        <Icon icon='exclamationTriangleOutline' fill='alert' size={3} />
        <div>
          <Heading level={1}>Confira os dados do atestado</Heading>
          <Text>
            Este atestado será assinado e enviado ao cidadão via e-mail, verifique atentamente os dados incluídos no
            documento.{' '}
          </Text>
          <Text fontWeight='bold'>
            Após gerar e assinar um atestado digital não será possível editar ou excluir o registro.
          </Text>
        </div>
      </HFlow>
      <HFlow>
        <div>
          <Text fontWeight='bold'>Enviar para: </Text>
          <Text>{emailCidadao}</Text>
        </div>
      </HFlow>
      {children}
    </VFlow>
  )
}
