import { Highlight, Text, Theme, useStyles } from 'bold-ui'
import React, { useEffect, useState } from 'react'
import { RecursoNode } from 'view/perfil/components/RecursoNode'

export interface RecursoListProps {
  root: RecursoNode
  search?: string
  renderNode?(node: RecursoNode, search: string): React.ReactNode
}

export const RecursoList = (props: RecursoListProps) => {
  const { root, search, renderNode } = props
  const { classes } = useStyles(createListStyles)

  return (
    <ul className={classes.ul}>
      <li>
        <Text fontWeight='bold'>Recurso</Text>
      </li>
      {root.children.map(node => (
        <RecursoListItem
          key={node.state.uri}
          renderNode={renderNode}
          search={search}
          node={node}
          nivel={0}
          hidden={!node.state.visible}
        />
      ))}
    </ul>
  )
}

RecursoList.defaultProps = {
  renderNode: (node: RecursoNode, search: string) => <Highlight text={node.state.label} words={[search]} />,
} as RecursoListProps

interface RecursoListItemProps {
  hidden: boolean
  nivel: number
  node: RecursoNode
  search?: string
  renderNode?(node: RecursoNode, search: string): React.ReactNode
}

export const RecursoListItem = (props: RecursoListItemProps) => {
  const { hidden, nivel, renderNode, node, search } = props
  const { classes } = useStyles(createItemStyles, props)

  const [, setNodeState] = useState(null)
  useEffect(() => {
    return node.subscribe(state => setNodeState(state))
  }, [node])

  return (
    <>
      <li className={classes.li}>{renderNode(node, search)}</li>

      {node.hasChildren() &&
        node.children.map(child => (
          <RecursoListItem
            {...props}
            key={child.state.uri}
            hidden={hidden || !child.state.visible}
            nivel={nivel + 1}
            node={child}
          />
        ))}
    </>
  )
}

export const createListStyles = (theme: Theme) => ({
  ul: {
    listStyle: 'none',
    margin: 0,
    paddingLeft: 0,
    '& > *': {
      border: '1px solid ' + theme.pallete.divider,
      paddingLeft: 16,
      paddingTop: 12,
      paddingBottom: 12,
    },
    '& > :not(:first-of-type)': {
      borderTop: 'none',
    },
  } as React.CSSProperties,
})

export const createItemStyles = (theme: Theme, { hidden, nivel }: RecursoListItemProps) => ({
  li: {
    display: hidden ? 'none' : 'flex',
    alignItems: 'center',
    paddingLeft: `calc(12px + ${nivel * 2}rem)`,
    paddingRight: 16,
    height: '2.5rem',
    fontWeight: nivel === 0 ? 'bold' : 'normal',
    '&:hover': {
      background: theme.pallete.surface.background,
    },
  } as React.CSSProperties,
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    flex: 1,
    textDecoration: 'none',
    color: 'inherit',
  } as React.CSSProperties,
})
