/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Tooltip, VFlow } from 'bold-ui'
import { gray, yellow } from 'bold-ui/lib/styles/colors'
import { format } from 'date-fns'

import { EventoAgendamentoProps } from './EventoAgendamento'

const formatoData = 'dd/MM/yyyy'

export function FechamentoAgenda({ event }: EventoAgendamentoProps) {
  return (
    <Tooltip placement='auto-start' text='Não é possível agendar em horários fechados para agendamento.'>
      <VFlow vSpacing={0} style={style}>
        <Text fontWeight='bold'>{event.title}</Text>
        <Text>
          {format(event.dataInicioFechamento, formatoData)} até {format(event.dataFimFechamento, formatoData)}
        </Text>
      </VFlow>
    </Tooltip>
  )
}

const bkgColor = yellow.c90

const style = css`
  padding: 1rem;

  background-color: ${bkgColor};
  background-size: 11px 11px;
  background-image: repeating-linear-gradient(
    -45deg,
    ${gray.c80} 0,
    ${gray.c80} 1px,
    ${bkgColor} 0,
    ${bkgColor} 0.5rem
  );

  height: 100%;
  width: 100%;

  cursor: not-allowed;
`
