/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import theme from 'config/theme'
import { isUndefinedOrNull } from 'util/checks'
import { formatDate } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import {
  CidadaoVisualizacaoImovel,
  EditarAtualizarCidadaoCallback,
  StatusFichaEnum,
} from 'view/visualizacaoTerritorial/model'

interface CidadaoCardProps {
  imovelId: ID
  isResponsavel?: Boolean
  cidadao: CidadaoVisualizacaoImovel
  onEditarAtualizarCidadaos: EditarAtualizarCidadaoCallback
}

export function CidadaoCard(props: CidadaoCardProps) {
  const { imovelId, isResponsavel, cidadao, onEditarAtualizarCidadaos } = props

  const isFichaNaoProcessadaAplicativo = isUndefinedOrNull(cidadao.ultimaFichaCadastrada)
  const isUltimaFichaCadastradaProcessada = cidadao?.ultimaFichaCadastrada?.isProcessado === StatusFichaEnum.ENVIADA

  return (
    <HFlow
      alignItems='center'
      justifyContent='space-between'
      style={css`
        border: 1px solid ${theme.pallete.gray.c80};
        padding: 0.5rem 0.75rem;
      `}
    >
      <VFlow vSpacing={0.25}>
        {isResponsavel && (
          <Tag icon='userFilled' style={styles.tag}>
            Responsável familiar
          </Tag>
        )}
        <HFlow hSpacing={0.2}>
          <Text fontWeight='bold'>{(cidadao.nomeSocial ?? cidadao.nome).titleCase()}</Text>
          <Text>{` | ${cidadao.sexo.titleCase()}`}</Text>
        </HFlow>
        <HFlow>
          <Text>
            {humanizeAge(cidadao.dataNascimento)} | Nasceu em
            {` ${formatDate(cidadao.dataNascimento)}`}
          </Text>
          {cidadao.cpf ? (
            <HLabel title='CPF'>
              <Cpf value={cidadao.cpf} />
            </HLabel>
          ) : (
            <HLabel title='CNS'>{cidadao.cns}</HLabel>
          )}
        </HFlow>
        {cidadao?.ultimaFichaCadastrada?.isProcessado !== StatusFichaEnum.ENVIADA && (
          <HFlow hSpacing={0.25} alignItems='center'>
            <Icon icon='exclamationTriangleOutline' color={theme.pallete.status.alert.main} />
            <Text color='alert'>Existem fichas não processadas para esse cidadão</Text>
          </HFlow>
        )}
      </VFlow>
      <Tooltip text={cidadaoToolTipMessage(isUltimaFichaCadastradaProcessada, isFichaNaoProcessadaAplicativo)}>
        <Button
          disabled={isFichaNaoProcessadaAplicativo}
          skin='ghost'
          style={css`
            padding: 0.5rem;
          `}
          onClick={() =>
            onEditarAtualizarCidadaos(
              isUltimaFichaCadastradaProcessada,
              cidadao.ultimaFichaCadastrada.uuidFichaOrigem,
              cidadao.ultimaFichaCadastrada.id,
              imovelId
            )
          }
        >
          <Icon icon='penFilled' />
        </Button>
      </Tooltip>
    </HFlow>
  )
}

const cidadaoToolTipMessage = (isUltimaFichaCadastradaProcessada: boolean, isFichaCadastradaAplicativo: boolean) => {
  if (isFichaCadastradaAplicativo)
    return 'Ação bloqueada pois existe uma ficha ainda não processada vinda do aplicativo e-SUS Território.'
  return isUltimaFichaCadastradaProcessada ? 'Atualizar cidadão' : 'Editar cidadão'
}

const styles = {
  tag: css`
    color: ${theme.pallete.primary.c40};
    background-color: transparent;
    border: 1px solid;
  `,
}
