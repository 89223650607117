import { Cell, Checkbox, Grid } from 'bold-ui'
import { SearchInput } from 'components/search/SearchInput'
import React from 'react'

import { OrientacaoFilterModel } from '../types/OrientacoesModel'

interface OrientacoesTableFilterProps {
  onChangeFilter(value: OrientacaoFilterModel)
  currentFilter: OrientacaoFilterModel
}

export function OrientacoesTableFilter(props: OrientacoesTableFilterProps) {
  const { onChangeFilter, currentFilter } = props

  const handleSearch = (filter: string) => onChangeFilter({ texto: filter, somenteMeus: currentFilter.somenteMeus })

  return (
    <Grid alignItems='center'>
      <Cell size={6}>
        <SearchInput placeholder='Pesquise por profissional, CBO ou orientação' onSearch={handleSearch} />
      </Cell>
      <Cell size={6}>
        <Checkbox
          label='Ver somente as minhas orientações'
          onChange={() => onChangeFilter({ texto: currentFilter.texto, somenteMeus: !currentFilter.somenteMeus })}
        />
      </Cell>
    </Grid>
  )
}
