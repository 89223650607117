import { VFlow } from 'bold-ui'
import { ConfiguracaoAgendaOnlineLotacaoQuery } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

import { ConfiguracaoAgendaOnlineHeaderSection } from './components/ConfiguracaoAgendaOnlineHeaderSection'
import { ConfiguracaoAgendaOnlineHorariosSection } from './ConfiguracaoAgendaOnlineHorariosSection'

export interface ConfiguracaoAgendaOnlineLotacaoPanelProps {
  formProps: FormRenderProps
  submitFailedStatus: boolean
  cfgHorarioAgendaOnline: ConfiguracaoAgendaOnlineLotacaoQuery
}

export function ConfiguracaoAgendaOnlineLotacaoPanel(props: ConfiguracaoAgendaOnlineLotacaoPanelProps) {
  const { formProps } = props
  const { submitFailedStatus } = props

  return (
    <VFlow vSpacing={1}>
      <ConfiguracaoAgendaOnlineHeaderSection formProps={formProps} submitFailedStatus={submitFailedStatus} />
      <ConfiguracaoAgendaOnlineHorariosSection
        horariosAgendaOnline={props.cfgHorarioAgendaOnline.lotacao.gradeConfiguracaoAgendaOnline}
        formProps={props.formProps}
      />
    </VFlow>
  )
}
