import { identity, isFunction } from 'lodash'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'

/**
 * based on https://github.com/WebDevSimplified/useful-custom-react-hooks/blob/main/src/8-useStorage/useStorage.js
 */

interface StorageValueWrapper<T> {
  __version: string
  item: T
}

type DefaultValue<T> = T | (() => T) | null
export interface UseStorageOptions<T> {
  defaultValue?: DefaultValue<T>
  checkVersionCompatibility?: boolean
  modifier?: (value: T) => T
}

export function useLocalStorage<T = any>(key: string, options?: UseStorageOptions<T>) {
  return useStorage(key, window.localStorage, options)
}

export function useSessionStorage<T = any>(key: string, options?: UseStorageOptions<T>) {
  return useStorage(key, window.sessionStorage, options)
}

export function useLocalStorageState<T = any>(key: string, options?: UseStorageOptions<T>) {
  return useStorageState(key, window.localStorage, options)
}

export function useSessionStorageState<T = any>(key: string, options?: UseStorageOptions<T>) {
  return useStorageState(key, window.sessionStorage, options)
}

function useStorage<T>(
  key: string,
  storageObject: Storage,
  options: UseStorageOptions<T>
): [() => T, (value: T) => void, () => void] {
  const { modifier = identity, checkVersionCompatibility = false, defaultValue = null } = options ?? {}

  const getValue = useCallback(
    () => getStorageValue(key, storageObject, defaultValue, checkVersionCompatibility, modifier),
    [checkVersionCompatibility, defaultValue, key, modifier, storageObject]
  )

  const setValue = useCallback(
    (value: T) => {
      if (isUndefinedOrNull(value)) storageObject.removeItem(key)
      else storageObject.setItem(key, JSON.stringify(wrapStorageValue(value)))
    },
    [key, storageObject]
  )

  const remove = useCallback(() => {
    storageObject.removeItem(key)
  }, [key, storageObject])

  return [getValue, setValue, remove]
}

function useStorageState<T>(
  key: string,
  storageObject: Storage,
  options: UseStorageOptions<T>
): [T, Dispatch<SetStateAction<T>>, (updateState?: boolean) => void] {
  const [getValue, setValue, removeValue] = useStorage(key, storageObject, options)

  const [state, setState] = useState<T>(() => getValue())

  useEffect(() => {
    setValue(state)
  }, [setValue, state])

  const remove = useCallback(
    (updateState: boolean = true) => {
      removeValue()
      updateState && setState(null)
    },
    [removeValue]
  )

  const handleSetState = useCallback((state: T) => setState(state ?? null), [])

  return [state, handleSetState, remove]
}

function getStorageValue<T>(
  key: string,
  storageObject: Storage,
  defaultValue: DefaultValue<T>,
  checkVersionCompatibility: boolean,
  modifier: (value: T) => T
): T {
  const jsonValue = storageObject.getItem(key)

  if (jsonValue != null) {
    const parsedValue = unwrapStorageValue<T>(JSON.parse(jsonValue), checkVersionCompatibility)
    return parsedValue ? modifier(parsedValue) : resolveDefaultValue(defaultValue)
  } else {
    return resolveDefaultValue(defaultValue)
  }
}

const isVersionCompatible = <T>(value: StorageValueWrapper<T>) => value.__version === process.env.REACT_APP_VERSION
const wrapStorageValue = <T>(value: T): StorageValueWrapper<T> => ({
  __version: process.env.REACT_APP_VERSION,
  item: value,
})
const unwrapStorageValue = <T>(wrappedValue: StorageValueWrapper<T>, nullIfVersionIncompatible: boolean): T =>
  !nullIfVersionIncompatible || isVersionCompatible(wrappedValue) ? wrappedValue.item : null
const resolveDefaultValue = <T>(defaultValue: DefaultValue<T>): T =>
  isFunction(defaultValue) ? defaultValue() : defaultValue
