/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'
import { useFirebase } from 'hooks/firebase/useFirebase'
import logo from 'images/esus.svg'
import usuario_com_dados_protegidos from 'images/termoDeUso/usuario_com_dados_protegidos.svg'

import { TermosDeUsoAccordion } from '../components/TermosDeUsoAccordion'
import { TermosDeUsoHeadingSection } from '../components/TermosDeUsoHeadingSection'

interface TermosDeUsoSimplificadoProps {
  faltaAceitarTermos: boolean
}

export const TermosDeUsoSimplificado = (props: TermosDeUsoSimplificadoProps) => {
  const { faltaAceitarTermos } = props

  const styles = createStyles()

  const { analytics } = useFirebase()

  const handleUserClickOnAccordion = () => {
    analytics.logEvent('user_click_on_accordion_termos_de_uso')
  }

  return (
    <VFlow>
      <VFlow css={styles.imgContainer}>
        <img src={logo} alt='Logo do e-SUS Atenção Primária' css={styles.images} />
      </VFlow>

      <TermosDeUsoHeadingSection>
        <VFlow>
          <p>
            Este documento busca esclarecer os direitos e obrigações do fornecedor e do usuário do Sistema e-SUS Atenção
            Primária (e-SUS APS), bem como as condições da correta utilização do referido sistema.
          </p>
          <p>
            Ao utilizar o e-SUS APS você se torna um <b>USUÁRIO</b>, e isso implica a aceitação plena e sem reserva de
            todos os itens do presente <b>TERMO</b>.
            {faltaAceitarTermos &&
              ' Para aceitar o termo, ao final, clique em “Declaro que li e estou ciente dos termos de uso”. Caso não concorde com estes termos, clique em "Sair".'}
          </p>
        </VFlow>
      </TermosDeUsoHeadingSection>

      <VFlow css={styles.imgContainer}>
        <img src={usuario_com_dados_protegidos} alt='Pessoa mexendo no computador com seus dados protegidos' />
      </VFlow>

      <TermosDeUsoHeadingSection title='Qual a função do Prontuário Eletrônico do Cidadão (PEC)?'>
        <p>
          O PEC tem como objetivo facilitar o trabalho dos profissionais da saúde. Isso porque eles podem preencher
          informações cadastrais e clínicas do paciente, de forma totalmente digital! Isso aumenta a segurança e
          facilita a troca de informações, tanto das organizações que utilizam o sistema, quanto no Ministério da Saúde.
        </p>
      </TermosDeUsoHeadingSection>

      <VFlow vSpacing={0} style={styles.vflowPageContent}>
        <TermosDeUsoAccordion
          title='Então significa que meus dados pessoais estão sendo mantidos pelo Ministério da Saúde? Eles podem
                    fazer o que quiserem com essas informações?'
          onAccordionClick={handleUserClickOnAccordion}
        >
          Não! O Ministério da Saúde e as organizações que utilizam o PEC têm a obrigação legal de garantir que seus
          dados estejam protegidos, além de disponibilizar todas as informações sobre o serviço que você estiver
          utilizando. Lembrando ainda que o Ministério da Saúde e as organizações que utilizam o sistema só podem
          utilizar os dados necessários e em conformidade com a base legal utilizada.
        </TermosDeUsoAccordion>

        <TermosDeUsoAccordion title='E que base legal é essa?' onAccordionClick={handleUserClickOnAccordion}>
          O Ministério da Saúde e as organizações que utilizam o PEC se respaldam nas hipóteses do art. 11º, da LGPD,
          especialmente:
          <br />
          Art. 11º O tratamento de dados pessoais sensíveis somente poderá ocorrer nas seguintes hipóteses:
          <br />
          II - <u>sem fornecimento de consentimento do titular,</u> nas hipóteses em que for indispensável para:
          <br />
          a) cumprimento de <u>obrigação legal ou regulatória</u> pelo controlador;
          <br />
          b) tratamento compartilhado de dados necessários à{' '}
          <u>execução, pela administração pública, de políticas públicas</u> previstas em leis ou regulamentos;
          <br />
          c) realização de <u>estudos por órgão de pesquisa,</u> garantida, sempre que possível, a anonimização dos
          dados pessoais sensíveis;
          <br />
          d) <u>tutela da saúde,</u> exclusivamente, em procedimento realizado por profissionais de saúde, serviços de
          saúde ou autoridade sanitária.
        </TermosDeUsoAccordion>
      </VFlow>

      <TermosDeUsoHeadingSection title='O que significa que o Ministério da Saúde e as organizações que utilizam o sistema irão ‘tratar os meus dados’ dentro do PEC? '>
        <p>
          Significa que eles podem coletar, ter acesso, distribuir, processar, arquivar, armazenar os seus dados. Mas
          para isso, terá de ter alguma das bases legais que falamos há pouco, para cada uma dessas ações!
        </p>
      </TermosDeUsoHeadingSection>

      <VFlow vSpacing={0}>
        <TermosDeUsoAccordion
          title='Entendi! Então, eu não preciso fazer nada?'
          onAccordionClick={handleUserClickOnAccordion}
        >
          Você deve sempre lembrar de manter os aparelhos que utilizar para acessar o serviço livres de vírus e
          malwares, pois eles podem colocar seus dados em risco. Não clique em links suspeitos e utilize senhas fortes.
          Além disso, é sua responsabilidade fornecer dados corretos e atualizados, não compartilhar sua senha com
          ninguém. Juntos, você, o Ministério da Saúde e as organizações que utilizam o sistema conseguiremos deixar
          seus dados mais seguros!
        </TermosDeUsoAccordion>

        <TermosDeUsoAccordion
          title='E se algo acontecer um incidente de segurança ou se eu tiver dúvidas e pedidos para fazer? Com quem eu devo falar?'
          onAccordionClick={handleUserClickOnAccordion}
        >
          Você pode entrar em contato com a Encarregada de Dados do Ministério da Saúde, Adriana Macedo Marques, a
          partir da plataforma{' '}
          <a href='https://falabr.cgu.gov.br/web/home' target='_blank' rel='noopener noreferrer'>
            https://falabr.cgu.gov.br/web/home
          </a>
        </TermosDeUsoAccordion>
      </VFlow>
    </VFlow>
  )
}

const createStyles = () => ({
  imgContainer: css`
    display: flex;
    justify-content: center;
    margin: 1.5rem;
  `,
  images: css`
    transform: scale(3);
  `,
  vflowPageContent: css`
    font-size: 0.875rem;
  `,
})
