import { ApolloLink } from 'apollo-link'

export type SessionKeeperListener = (operation, response) => void

export class RequestListenerLink extends ApolloLink {
  private listeners: SessionKeeperListener[] = []

  request(operation, forward) {
    const forwardReturn = forward(operation)

    if (forwardReturn.map) {
      return forwardReturn.map(data => {
        this.notify(operation, data)
        return data
      })
    }

    return forwardReturn
  }

  addListener(listener: SessionKeeperListener) {
    this.listeners.push(listener)
    return () => {
      this.listeners.splice(this.listeners.indexOf(listener), 1)
    }
  }

  notify(operation, response) {
    this.listeners.forEach(listener => listener(operation, response))
  }
}
