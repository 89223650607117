import { SelectField } from 'components/form'
import { SubtipoAtendimentoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  SubtipoAtendimentoHistoricoClinico,
  SubtipoAtendimentoSelectFieldQuery,
  SubtipoAtendimentoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type SubtipoAtendimentoSelectModel = Partial<
  SubtipoAtendimentoSelectFieldQuery['subtipoAtendimentoHistorico'][0]
>
interface SubtipoAtendimentoSelectFieldProps extends AsyncSelectFieldProps<SubtipoAtendimentoSelectModel> {
  tiposAtendimentoId?: ID[]
}

export function SubtipoAtendimentoSelectField(props: SubtipoAtendimentoSelectFieldProps) {
  const { tiposAtendimentoId, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    SubtipoAtendimentoSelectModel,
    SubtipoAtendimentoSelectFieldQuery,
    SubtipoAtendimentoSelectFieldQueryVariables
  >({
    query: SubtipoAtendimentoSelectFieldDocument,
    extractItems: (data) => {
      return data?.subtipoAtendimentoHistorico
    },
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        tiposAtendimentoId: tiposAtendimentoId,
      },
    }),
  })

  return <SelectField<SubtipoAtendimentoSelectModel> itemToString={itemToString} {...selectProps} {...rest} />
}

const itemToString = (item: SubtipoAtendimentoSelectModel) => {
  return SubtipoRecord[item]
}

export const SubtipoRecord: Record<SubtipoAtendimentoHistoricoClinico, string> = {
  [SubtipoAtendimentoHistoricoClinico.CONSULTA]: 'Consulta',
  [SubtipoAtendimentoHistoricoClinico.CONSULTA_DIA]: 'Consulta no dia',
  [SubtipoAtendimentoHistoricoClinico.CONSULTA_PROGRAMADA_CUIDADO_CONTINUADO]:
    'Consulta programada / Cuidado continuado',
  [SubtipoAtendimentoHistoricoClinico.URGENCIA]: 'Urgência',
  [SubtipoAtendimentoHistoricoClinico.CONSULTA_AGENDADA_ODONTO]: 'Consulta agendada',
  [SubtipoAtendimentoHistoricoClinico.ESCUTA_INICIAL_ORIENTACAO]: 'Escuta inicial / Orientação',
  [SubtipoAtendimentoHistoricoClinico.CONSULTA_DIA_ODONTO]: 'Consulta no dia',
  [SubtipoAtendimentoHistoricoClinico.URGENCIA_ODONTO]: 'Urgência',
  [SubtipoAtendimentoHistoricoClinico.PRIMEIRA_CONSULTA]: '1ª consulta',
  [SubtipoAtendimentoHistoricoClinico.CONSULTA_RETORNO]: 'Consulta de retorno',
  [SubtipoAtendimentoHistoricoClinico.CONSULTA_MANUTENCAO]: 'Consulta de manutenção',
  [SubtipoAtendimentoHistoricoClinico.CRIANCAS_MENORES_6_MESES]: 'Crianças menores de 6 meses',
  [SubtipoAtendimentoHistoricoClinico.CRIANCAS_6_A_23_MESES]: 'Crianças de 6 a 23 meses',
  [SubtipoAtendimentoHistoricoClinico.CIDADAOS_MAIORES_2_ANOS]: 'Cidadãos com 2 anos ou mais',
  [SubtipoAtendimentoHistoricoClinico.VISITA_REALIZADA]: 'Visita realizada',
  [SubtipoAtendimentoHistoricoClinico.VISITA_RECUSADA]: 'Visita recusada',
  [SubtipoAtendimentoHistoricoClinico.CIDADAO_AUSENTE]: 'Cidadão ausente',
  [SubtipoAtendimentoHistoricoClinico.NAO_PROGRAMADA]: 'Não programada',
  [SubtipoAtendimentoHistoricoClinico.PROGRAMADA]: 'Programada',
  [SubtipoAtendimentoHistoricoClinico.VISITA_DOMICILIAR_POS_OBITO]: 'Visita domiciliar pós-óbito',
  [SubtipoAtendimentoHistoricoClinico.EDUCACAO_SAUDE]: 'Educação em saúde',
  [SubtipoAtendimentoHistoricoClinico.ATENDIMENTO_GRUPO]: 'Atendimento em grupo ',
  [SubtipoAtendimentoHistoricoClinico.AVALIACAO_PROCEDIMENTO_COLETIVO]: 'Avaliação / Procedimento coletivo',
  [SubtipoAtendimentoHistoricoClinico.MOBILIZACAO_SOCIAL]: 'Mobilização social',
}
