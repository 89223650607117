/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Theme, useTheme } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useAtendimentoProfissionalEncaminhamentoByIdQuery } from 'graphql/hooks.generated'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { EncaminhamentoExternoDetalheLotacao } from './EncaminhamentoExternoDetalheLotacao'

interface EncaminhamentoExternoRecenteDetalhesPanelProps {
  isRegistradoAgora: true
  lotacao: LotacaoAtendimento
}

interface EncaminhamentoExternoDetalhesPanelProps {
  isRegistradoAgora: false
  atendimentoProfissionalId: ID
}

export default function EncaminhamentoExternoDetalhesPanel(
  props: EncaminhamentoExternoDetalhesPanelProps | EncaminhamentoExternoRecenteDetalhesPanelProps
) {
  const theme = useTheme()
  const classes = getStyles(theme)

  const { data, loading } = useAtendimentoProfissionalEncaminhamentoByIdQuery({
    variables: { atendimentoProfissionalId: props.isRegistradoAgora === false && props.atendimentoProfissionalId },
    skip: props.isRegistradoAgora,
  })

  if (loading) return <LoadingIndicator />

  const { profissional, cbo } = props.isRegistradoAgora ? props.lotacao : data?.atendimentoIndividual?.lotacao

  return (
    <div css={classes.container}>
      <Grid>
        <Cell size={12}>
          <EncaminhamentoExternoDetalheLotacao
            cbo={cbo.nome}
            cpf={profissional.cpf}
            cns={profissional.cns}
            nome={profissional.nome}
          />
        </Cell>
      </Grid>
    </div>
  )
}

const getStyles = (theme: Theme) => ({
  container: css`
    background: ${theme.pallete.gray.c90};
    padding: 1rem;
  `,
})
