import { HFlow, Text, VFlow } from 'bold-ui'
import { TipoReacaoAlergia } from 'graphql/types.generated'
import React from 'react'
import { AlergiaTag } from 'view/atendimentos/detail/components/AlergiaTag'

import { AtendimentoAtualStatusMarker } from '../../../components/AtendimentoAtualStatusMarker'
import { IconTextCard } from '../components/IconTextCard'
import { Alergia, categoriaAlergiaRecord } from '../types/AlergiaModel'

interface AlergiasListCardProps {
  alergias: Alergia[]
}

export const AlergiasListCard = (props: AlergiasListCardProps) => (
  <VFlow vSpacing={0.5}>
    {props.alergias.map((alergia) => {
      const categoriaAlergia =
        categoriaAlergiaRecord[alergia.substanciaEspecifica?.categoria ?? alergia.categoriaSubstanciaEspecificaLegado]
      return (
        <IconTextCard
          icon={categoriaAlergia.icon}
          iconColor={categoriaAlergia.color}
          key={alergia.id}
          value={<AlergiasDescription alergia={alergia} descricaoCategoria={categoriaAlergia.descricao} />}
        />
      )
    })}
  </VFlow>
)

function AlergiasDescription({
  alergia: {
    criticidade,
    substanciaEspecifica,
    substanciaEspecificaLegado,
    tipoReacao,
    isCriadoAtendimentoAtual,
    isAvaliacao,
  },
  descricaoCategoria,
}: {
  alergia: Alergia
  descricaoCategoria: string
}) {
  return (
    <VFlow vSpacing={0.25}>
      <HFlow justifyContent='flex-start' hSpacing={0.5}>
        <Text fontWeight='bold'>{substanciaEspecifica?.nome ?? substanciaEspecificaLegado}</Text>
        {isCriadoAtendimentoAtual && <AtendimentoAtualStatusMarker isCriadoAvaliacao={isAvaliacao} />}
      </HFlow>
      <Text>
        {tipoReacao ? (tipoReacao === TipoReacaoAlergia.ALERGIA ? 'Alergia' : 'Intolerância') : 'Reação adversa'}
        {` a ${descricaoCategoria}`}
      </Text>
      <AlergiaTag type='criticidade' enum={criticidade} />
    </VFlow>
  )
}
