import { Breakpoint } from 'bold-ui'
import { useBreakpoint } from 'bold-ui/lib/hooks'
import { DropdownButton } from 'components/dropdown'
import React, { ReactNode } from 'react'

export interface TableRowMenuProps {
  children: ReactNode
  expandedBreakpoints: Breakpoint[]
}

export function TableRowMenu(props: TableRowMenuProps) {
  const { children, expandedBreakpoints } = props

  const breakPoint = useBreakpoint()

  return expandedBreakpoints?.includes(breakPoint) ? (
    <>{children}</>
  ) : (
    <DropdownButton>
      {React.Children.map(children, (c) => c && React.cloneElement(c as React.ReactElement, { asDropdownItem: true }))}
    </DropdownButton>
  )
}

TableRowMenu.defaultProps = {
  expandedBreakpoints: ['xl', 'lg'],
} as TableRowMenuProps
