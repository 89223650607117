import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import {
  ClassificacaoRiscoEncaminhamentoEnum,
  EncaminhamentosExternosEspecializadosQuery,
  EncaminhamentosExternosQuery,
  EspecialidadeSisreg,
  Period,
  TipoEncaminhamentoExternoEnum,
} from 'graphql/types.generated'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'
import { RowType } from 'view/atendimentos/detail/components/EditableList'

export interface EncaminhamentoExternoEspecializadoModel extends RowType, SoapEditableItem {
  especialidadeSisreg: EspecialidadeSisreg
  classificacaoRiscoEncaminhamento: ClassificacaoRiscoEncaminhamentoEnum
  cidEncaminhamento?: CidSelectFieldModel
  ciapEncaminhamento?: CiapSelectFieldModel
  observacoes?: string
  motivoEncaminhamento?: string
  complemento?: string
}
export interface EncaminhamentoExternoFilterModel {
  periodo?: Period
  somenteMeus?: boolean
  especialidade?: EspecialidadeSisregModel
  tipoEncaminhamento?: TipoEncaminhamentoExternoEnum
}

export interface EspecialidadeSisregModel {
  id: ID
  descricao: string
}

export interface EncaminhamentoExternoEspecializadoPlanoModel {
  encaminhamentosEspecializadosRecentes?: EncaminhamentoExternoEspecializadoModel[]
  encaminhamentosRecentes?: TipoEncaminhamentoExternoEnum[]
  openedAccordions?: string[]
}

export const tipoEncaminhamentoExternoEnumRecord: Record<TipoEncaminhamentoExternoEnum, { descricao: string }> = {
  [TipoEncaminhamentoExternoEnum.CAPS]: {
    descricao: 'CAPS',
  },
  [TipoEncaminhamentoExternoEnum.INTERNACAO_HOSPITALAR]: {
    descricao: 'Internação hospitalar',
  },
  [TipoEncaminhamentoExternoEnum.INTERSETORIAL]: {
    descricao: 'Intersetorial',
  },
  [TipoEncaminhamentoExternoEnum.SERVICO_ATENCAO_DOMICILIAR]: {
    descricao: 'Serviço de atenção domiciliar',
  },
  [TipoEncaminhamentoExternoEnum.URGENCIA]: {
    descricao: 'Urgência',
  },
  [TipoEncaminhamentoExternoEnum.SERVICO_ESPECIALIZADO]: {
    descricao: 'Serviço Especializado',
  },
}

export type EncaminhamentosModel = EncaminhamentosExternosEspecializadosQuery['encaminhamentosEspecializados']
export type EncaminhamentoExternoEspecializadoQueryModel = EncaminhamentosExternosEspecializadosQuery['encaminhamentosEspecializados']['content'][0]
export type EncaminhamentoExternoQueryModel = EncaminhamentosExternosQuery['encaminhamentos'][0]

export interface EncaminhamentoExternoRowModel extends SoapEditableItem {
  id: ID
  classificacaoRiscoEncaminhamento?: ClassificacaoRiscoEncaminhamentoEnum
  atendimentoProfissional: {
    id: ID
    iniciadoEm: Instant
    lotacao: { id: ID; profissional: { id: ID } }
  }
  especialidadeSisreg?: {
    id: ID
    descricao: string
  }
  hipoteseDiagnostica?: {
    id: ID
    nome: string
    codigo: string
  }
  isRegistradoAgora: boolean
  tipoEncaminhamento: TipoEncaminhamentoExternoEnum
}

export const classificacaoRiscoRecord: Record<ClassificacaoRiscoEncaminhamentoEnum, string> = {
  EMERGENCIA: 'Emergência',
  URGENCIA: 'Urgência',
  PRIORITARIO: 'Prioritário',
  ELETIVO: 'Eletivo',
}
