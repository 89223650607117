/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Box } from 'components/Box'
import {
  CboSelectField,
  EquipeSelectField,
  Form,
  FormProps,
  FormRenderProps,
  ProfissionalSelectField,
  SubmitButton,
} from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { CiapSelectField } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import { TipoRegistroSelectModel } from 'components/form/field/select/TipoRegistroSelectField/TipoRegistroSelectField'
import { DisponibilidadeDadosClinicosHistorico } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment } from 'react'
import { metaPath } from 'util/metaPath'

import { HistoricoAtendimentoAdvancedFilterFormModel } from '../../model/historicoFilterFormModel'

const path = metaPath<HistoricoAtendimentoAdvancedFilterFormModel>()

export interface HistoricoAtendimentoFilterPopperProps extends FormProps<HistoricoAtendimentoAdvancedFilterFormModel> {
  tiposAtendimento: TipoRegistroSelectModel[]
  disponibilidadeDadosClinicos: DisponibilidadeDadosClinicosHistorico
  clear(): void
  close(): void
}

export default function HistoricoAtendimentoFilterPopper(props: HistoricoAtendimentoFilterPopperProps) {
  const { initialValues, disponibilidadeDadosClinicos, clear, close, onSubmit } = props
  const { analytics } = useFirebase()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { getServerTimeNow } = useServerTime()
  const atendimento = useAtendimentoContext()
  const dataReferencia = atendimento?.atendimentoProfissional?.iniciadoEm ?? getServerTimeNow()

  const renderForm = (formRenderProps: FormRenderProps<HistoricoAtendimentoAdvancedFilterFormModel>) => {
    const { handleSubmit, pristine, values } = formRenderProps

    const submit = () => {
      handleSubmit()
      analytics.logEvent('click_button_filtrar_historico')
    }

    return (
      <Box
        style={css`
          width: 42rem;
          margin-top: 1rem;
        `}
      >
        <Grid gap={1} gapVertical={0.5}>
          {disponibilidadeDadosClinicos.statusProcessamento && (
            <Fragment>
              <Cell size={6}>
                <CiapSelectField name={path.ciaps2} label='CIAP 2' multiple />
              </Cell>
              <Cell size={6}>
                <CidSelectField name={path.cids10} label='CID 10' renderGrupoCiapRelacionada={false} multiple />
              </Cell>
            </Fragment>
          )}
          <Cell size={6}>
            <DateRangeField
              name={path.periodo}
              label='Período'
              startPlaceholder='Data inicial'
              finalPlaceholder='Data final'
              maxDate={dataReferencia}
            />
          </Cell>
          <Cell size={6}>
            <CboSelectField name={path.cbos} label='CBO' mostrarIndisponivelLotacao={false} multiple />
          </Cell>
          <Cell size={6}>
            <EquipeSelectField name={path.equipes} label='Equipe' unidadeSaudeId={acesso?.unidadeSaude.id} multiple />
          </Cell>
          <Cell
            size={6}
            style={css`
              margin-bottom: 1rem;
            `}
          >
            <ProfissionalSelectField
              name={path.profissionais}
              label='Profissional'
              multiple
              mostrarCns
              mostrarSemLotacaoAtiva
            />
          </Cell>

          <Cell size={12}>
            <HFlow hSpacing={1} justifyContent='flex-end'>
              <Button size='small' onClick={close}>
                Fechar
              </Button>
              <Button onClick={clear} disabled={!calculateDirtyFields(values)} size='small'>
                <Icon
                  icon='redo'
                  style={css`
                    margin-right: 0.5rem;
                  `}
                />
                Limpar filtros
              </Button>
              <SubmitButton kind='primary' size='small' disabled={pristine} handleSubmit={submit}>
                Filtrar
              </SubmitButton>
            </HFlow>
          </Cell>
        </Grid>
      </Box>
    )
  }

  return (
    <Form<HistoricoAtendimentoAdvancedFilterFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      initialValues={initialValues}
      onSubmitSucceeded={close}
    />
  )
}

function calculateDirtyFields(values: HistoricoAtendimentoAdvancedFilterFormModel) {
  return (
    values &&
    (values.periodo?.startDate ||
      values.periodo?.endDate ||
      values.subtiposAtendimento?.length > 0 ||
      values.cbos?.length > 0 ||
      values.ciaps2?.length > 0 ||
      values.cids10?.length > 0 ||
      values.equipes?.length > 0 ||
      values.profissionais?.length > 0)
  )
}
