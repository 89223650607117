import { VFlow } from 'bold-ui'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { HistoricoPeriogramaCompletoModal } from './historico-periograma-completo/HistoricoPeriogramaCompletoModal'
import { PeriogramaCompletoTableModel } from './model'
import { PeriogramaCompletoField } from './periograma-completo-table-field/PeriogramaCompletoField'

interface PeriogramaCompletoRootViewProps {
  name: MetaPath<PeriogramaCompletoTableModel>
  prontuarioId: ID
}

export const PeriogramaCompletoRootView = (props: PeriogramaCompletoRootViewProps) => {
  const { name, prontuarioId } = props

  return (
    <VFlow>
      <HistoricoPeriogramaCompletoModal prontuarioId={prontuarioId} />

      <PeriogramaCompletoField name={name} />
    </VFlow>
  )
}
