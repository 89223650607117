import { msg } from '../messages'

const SPACE = /^\S*$/

export function nonWhitespaces(value: string) {
  if (!new RegExp(SPACE).test(value)) {
    return msg('nonWhitespaces', value)
  }
  return null
}
