import { StatusAvaliadoEnum } from 'graphql/types.generated'

import { AvaliacaoDesenvolvimentoModel } from '../model'

export const avaliacoesListWithStatusPresenteComAtraso = (avaliacoes: AvaliacaoDesenvolvimentoModel[]) =>
  avaliacoesListWithStatus(avaliacoes, StatusAvaliadoEnum.PRESENTE_COM_ATRASO)

export const avaliacoesListWithStatusPresente = (avaliacoes: AvaliacaoDesenvolvimentoModel[]) =>
  avaliacoesListWithStatus(avaliacoes, StatusAvaliadoEnum.PRESENTE)

export const avaliacoesListWithStatusAusente = (avaliacoes: AvaliacaoDesenvolvimentoModel[]) =>
  avaliacoesListWithStatus(avaliacoes, StatusAvaliadoEnum.AUSENTE)

export const avaliacoesListWithStatusNaoAvaliado = (avaliacoes: AvaliacaoDesenvolvimentoModel[]) =>
  avaliacoesListWithStatus(avaliacoes, StatusAvaliadoEnum.NAO_AVALIADO)

const avaliacoesListWithStatus = (avaliacoes: AvaliacaoDesenvolvimentoModel[], statusEnum: StatusAvaliadoEnum) =>
  avaliacoes && avaliacoes.filter((row: AvaliacaoDesenvolvimentoModel) => row.statusButton === statusEnum)
