/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { useCalculaCabecalhoSideMenu } from 'components/side-menu-navegation/useCalculaCabecalhoSideMenu'
import { ReactNode } from 'react'

interface SideMenuProps {
  children: ReactNode
  alturaCabecalhoToMenu: number
  alturaCabecalho: number
  maxWidth: number
}

const ASIDE_HEIGHT_DIFF = 6.1 / 16 + 1.5
const BREAKPOINT_UP_XL = 2.73
const BREAKPOINT_DOWN_XL = 3.18
const BREAKPOINT_DOWN_MD = 3.3

export const SideMenu = (props: SideMenuProps) => {
  const { children, alturaCabecalhoToMenu, alturaCabecalho, maxWidth } = props

  const passouCabecalho = useCalculaCabecalhoSideMenu(alturaCabecalhoToMenu, alturaCabecalho)

  const theme = useTheme()

  const styles = createStyles(theme, passouCabecalho, maxWidth)

  return <aside css={styles.aside}>{children}</aside>
}

const createStyles = (theme: Theme, passouCabecalho: boolean, maxWidth: number) => ({
  aside: css`
    width: 100%;
    max-width: ${maxWidth}px;
    padding-right: 1.5rem;
    border-right: 1px solid ${theme.pallete.divider};
    position: ${passouCabecalho ? 'fixed' : 'static'};
    top: 1rem;
    padding-left: 0.5rem;
    height: calc(100% - ${ASIDE_HEIGHT_DIFF}rem);
    overflow: hidden auto;

    ${theme.breakpoints.up('xl')} {
      //1920
      width: ${passouCabecalho ? `calc(100% / 15 * ${BREAKPOINT_UP_XL})` : 'auto'};
    }

    ${theme.breakpoints.down('xl')} {
      //1920
      width: ${passouCabecalho ? `calc(100% / 15 * ${BREAKPOINT_DOWN_XL})` : 'auto'};
    }

    ${theme.breakpoints.down('md')} {
      //960
      width: ${passouCabecalho && `calc(100% / 15 * ${BREAKPOINT_DOWN_MD})`};
    }

    ${theme.breakpoints.up('lg')} {
      padding-left: 1rem;
    }

    &::-webkit-scrollbar {
      width: 0.3rem;
      height: 0.3rem;
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.pallete.gray.c40};
      border-radius: ${theme.radius.tag};
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${theme.pallete.gray.c60};
    }

    &::-webkit-scrollbar-track {
      background: ${theme.pallete.surface.main};
      border-radius: ${theme.radius.tag};
      box-shadow: inset 0.4rem 0.625rem 0.75rem ${theme.pallete.divider};
    }
  `,
})
