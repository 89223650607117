/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, HeadingSection, Theme, useTheme, VFlow } from 'bold-ui'

interface PreNatalBoxProps {
  children: React.ReactElement
}

export const PreNatalBox = (props: PreNatalBoxProps) => {
  const { children } = props

  const theme = useTheme()
  const classes = createStyles(theme)

  return (
    <VFlow vSpacing={0} style={classes.container}>
      <HeadingSection level={3} title='Pré-natal, parto e nascimento' />
      {children}
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    padding: 1rem;
    border: 1px ${colors.purple.c60} solid;
    border-radius: ${theme.radius.paper}px;
  `,
})
