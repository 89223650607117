/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel, Text, VFlow } from 'bold-ui'
import { AntecedenteInformacoesObstetricasDto } from 'graphql/types.generated'
import { useMemo } from 'react'

import HistoricoDetailTable from '../../table/HistoricoDetailTable'

interface HistoricoAntecedentesInformacoesObstetricasTableBoxProps {
  informacoesObstetricas: AntecedenteInformacoesObstetricasDto
}

export function HistoricoAntecedentesInformacoesObstetricasTableBox(
  props: HistoricoAntecedentesInformacoesObstetricasTableBoxProps
) {
  const { informacoesObstetricas: infoObst } = props

  const { hasGestaAbortos, hasPartos, hasNascidos, hasRecemNascidos, hasNascidosVivos } = useMemo(() => {
    return {
      hasGestaAbortos: infoObst.gestaPrevias || infoObst.abortos || infoObst.partoMenosDeUmAno,
      hasPartos: infoObst.partos || infoObst.partosVaginais || infoObst.partosCesareas || infoObst.partosDomiciliares,
      hasNascidos: infoObst.nascidosVivos || infoObst.natimortos,
      hasRecemNascidos: infoObst.recemNascidoAbaixo || infoObst.recemNascidoAcima,
      hasNascidosVivos: infoObst.filhosVivos || infoObst.obitoAntesPrimeiraSemana || infoObst.obitoAposPrimeiraSemana,
    }
  }, [
    infoObst.abortos,
    infoObst.filhosVivos,
    infoObst.gestaPrevias,
    infoObst.nascidosVivos,
    infoObst.natimortos,
    infoObst.obitoAntesPrimeiraSemana,
    infoObst.obitoAposPrimeiraSemana,
    infoObst.partoMenosDeUmAno,
    infoObst.partos,
    infoObst.partosCesareas,
    infoObst.partosDomiciliares,
    infoObst.partosVaginais,
    infoObst.recemNascidoAbaixo,
    infoObst.recemNascidoAcima,
  ])

  return (
    <HistoricoDetailTable title='Informações obstétricas' sectionStyle='AN'>
      <Grid>
        {hasGestaAbortos && (
          <Cell size={3}>
            <VFlow vSpacing={0.5}>
              {infoObst.gestaPrevias && <InfoLabel title='Gestas prévias'>{infoObst.gestaPrevias}</InfoLabel>}
              {infoObst.abortos && <InfoLabel title='Abortos'>{infoObst.abortos}</InfoLabel>}
              {infoObst.partoMenosDeUmAno && (
                <Text fontStyle='italic'>(Desfecho da última gestação aconteceu há menos de um ano)</Text>
              )}
            </VFlow>
          </Cell>
        )}

        {(hasPartos || hasNascidos) && (
          <Cell size={3}>
            <VFlow vSpacing={0.5}>
              {hasPartos && (
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>Partos</Text>
                  {infoObst.partos && <Text>Partos realizados: {infoObst.partos}</Text>}
                  {infoObst.partosVaginais && <Text>Vaginais: {infoObst.partosVaginais}</Text>}
                  {infoObst.partosCesareas && <Text>Cesáreas: {infoObst.partosCesareas}</Text>}
                  {infoObst.partosDomiciliares && <Text>Partos domiciliares: {infoObst.partosDomiciliares}</Text>}
                </VFlow>
              )}

              {hasNascidos && (
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>Nascidos</Text>
                  {infoObst.nascidosVivos && <Text>Vivos: {infoObst.nascidosVivos}</Text>}
                  {infoObst.natimortos && <Text>Mortos: {infoObst.natimortos}</Text>}
                </VFlow>
              )}
            </VFlow>
          </Cell>
        )}

        {(hasRecemNascidos || hasNascidosVivos) && (
          <Cell size={3}>
            <VFlow vSpacing={0.5}>
              {hasRecemNascidos && (
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>Recém-nascidos com</Text>
                  {infoObst.recemNascidoAbaixo && <Text>Menos de 2500g: {infoObst.recemNascidoAbaixo}</Text>}
                  {infoObst.recemNascidoAcima && <Text>Mais de 4500g: {infoObst.recemNascidoAcima}</Text>}
                </VFlow>
              )}

              {hasNascidosVivos && (
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>Nascidos vivos</Text>
                  {infoObst.filhosVivos && <Text>Vivem: {infoObst.filhosVivos}</Text>}
                  {infoObst.obitoAntesPrimeiraSemana && (
                    <Text>Mortos na 1ª semana: {infoObst.obitoAntesPrimeiraSemana}</Text>
                  )}
                  {infoObst.obitoAposPrimeiraSemana && (
                    <Text>Mortos depois da 1ª semana: {infoObst.obitoAposPrimeiraSemana}</Text>
                  )}
                </VFlow>
              )}
            </VFlow>
          </Cell>
        )}
      </Grid>
    </HistoricoDetailTable>
  )
}
