import React from 'react'
import Highlighter from 'react-highlight-words'

interface NormalizedHighlighterProps {
  textToHighlight: string
  searchWords: string
  splitWords: boolean
}

export const NormalizedHighlighter = (props: NormalizedHighlighterProps) => (
  <Highlighter
    searchWords={props.searchWords?.split(props.splitWords && /\s/) ?? []}
    textToHighlight={props.textToHighlight}
    sanitize={(val: string) =>
      val
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .escapeRegExp()
    }
  />
)

NormalizedHighlighter.defaultProps = {
  splitWords: true,
} as NormalizedHighlighterProps
