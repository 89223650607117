import { css } from '@emotion/core'
import { Cell, Grid, Heading, VFlow } from 'bold-ui'
import { Form, FormRenderProps } from 'components/form'
import { HLabel } from 'components/HLabel'
import { TableRowMenu, TableRowMenuButton } from 'components/table'
import { FormApi } from 'final-form'
import React, { Fragment, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, maxLength } from 'util/validation'
import { EditableRow } from 'view/atendimentos/detail/components/EditableList'
import { ObservacaoFormGrid } from 'view/atendimentos/detail/components/ObservacaoFormGrid'

import { AntecedentesFamiliaresCiapFormModel } from '../model'
import { ObservacaoLabel } from './ObservacaoLabel'

const meta = metaPath<AntecedentesFamiliaresCiapFormModel>()

interface AntecedenteCiapRowProps {
  model: AntecedentesFamiliaresCiapFormModel

  onChange(values: AntecedentesFamiliaresCiapFormModel): void

  onRemove(item: AntecedentesFamiliaresCiapFormModel): void
}

const validator = createValidator<AntecedentesFamiliaresCiapFormModel>({
  observacao: [maxLength(200)],
})

type AntecedenteCiapRowState = 'default' | 'observacao'

export function AntecedenteCiapRow(props: AntecedenteCiapRowProps) {
  const { model, onChange, onRemove } = props
  const [state, setState] = useState<AntecedenteCiapRowState>('default')

  const handleExibirLinha = () => setState('default')
  const handleEditarObservacao = () => setState('observacao')

  const handleRemove = () => onRemove(model)

  const handleSubmit = (values: AntecedentesFamiliaresCiapFormModel, formApi: FormApi) => {
    onChange(values)
    setTimeout(formApi.reset)
    handleExibirLinha()
  }

  const messageEditarObservacao = (model: AntecedentesFamiliaresCiapFormModel) => {
    return model.observacao
      ? model.atendimentoPrevio
        ? 'Atualizar observações'
        : 'Editar observações'
      : 'Adicionar observações'
  }

  const classes = createStyles()
  const renderForm = (formRenderProps: FormRenderProps<AntecedentesFamiliaresCiapFormModel>) => (
    <VFlow vSpacing={0.5} style={classes.flow}>
      <Heading level={4}>{messageEditarObservacao(model)}</Heading>
      <Grid style={classes.noMarginGrid}>
        {model.ciap && (
          <Cell size={4} flexGrow={1}>
            <HLabel title='CIAP 2:'>{`${model.ciap.descricao} - ${model.ciap.codigo}`}</HLabel>
          </Cell>
        )}
      </Grid>
      <ObservacaoFormGrid
        name={meta.observacao}
        onCancel={handleExibirLinha}
        onSubmit={formRenderProps.handleSubmit}
        style={classes.observacaoNoResize}
      />
    </VFlow>
  )

  return (
    <EditableRow editing={state !== 'default'}>
      {state === 'default' && (
        <Fragment>
          <Grid style={classes.noMarginGrid}>
            <Cell size={9} alignSelf='center'>
              {`${model.ciap.descricao} - ${model.ciap.codigo}`}
            </Cell>

            <Cell size={3} alignSelf='center' style={classes.cell}>
              <TableRowMenu>
                <TableRowMenuButton
                  text={messageEditarObservacao(model)}
                  icon='chatOutline'
                  onClick={handleEditarObservacao}
                />
                <TableRowMenuButton text='Excluir' icon='trashOutline' onClick={handleRemove} />
              </TableRowMenu>
            </Cell>
          </Grid>

          <ObservacaoLabel observacao={model.observacao} />
        </Fragment>
      )}
      {state === 'observacao' && (
        <Form<AntecedentesFamiliaresCiapFormModel>
          render={renderForm}
          initialValues={model}
          validate={validator}
          onSubmit={handleSubmit}
          initialValuesEqual={(oldInitial, newInitial) => oldInitial._id === newInitial._id}
        />
      )}
    </EditableRow>
  )
}

const createStyles = () => ({
  flow: css`
    padding: 1rem 0;
  `,
  noMarginGrid: css`
    margin: 0;
  `,
  cell: css`
    padding: 0.25rem 0;
    text-align: right;
  `,
  observacaoNoResize: css`
    resize: none;
    height: 4rem;
  `,
})
