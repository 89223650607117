import { LocalDateRange } from 'components/form'
import { MotivoFechamentoEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

export interface FechamentoFormModel {
  periodo: LocalDateRange
  motivo: MotivoFechamentoEnum
  especifique: string
}

export const name = metaPath<FechamentoFormModel>()
