import { usePecField } from 'components/form/final-form/hooks/useField'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { TipoCompartilhamentoCuidadoPlanoEnum } from '../plano/cuidado-compartilhado/model-cuidadocompartilhado'
import { CondutaOdontoPanel, CondutaOdontoPanelProps } from './components/CondutaOdontoPanel'
import { CondutaPanel, CondutaPanelProps } from './components/CondutaPanel'
import { FinalizacaoAtendimentoFormProps } from './FinalizacaoAtendimentoForm'

interface FinalizacaoAtendimentoCondutaPanelProps
  extends Pick<FinalizacaoAtendimentoFormProps, 'isAtendimentoOdonto' | 'isAtendimentoProcedimentos' | 'name'>,
    Omit<CondutaPanelProps, 'name' | 'hasEncaminhamentoExterno'>,
    Omit<CondutaOdontoPanelProps, 'name' | 'hasEncaminhamentoExterno'> {}

export function FinalizacaoAtendimentoCondutaPanel(props: FinalizacaoAtendimentoCondutaPanelProps) {
  const {
    isAtendimentoProcedimentos,
    isAtendimentoOdonto,
    tipoConsulta,
    hasNotificacaoCasoSuspeito,
    isObservacao,
    name,
  } = props

  const {
    input: { value: encaminhamentosEspecializadosRecentes },
  } = usePecField({
    name: meta.plano?.encaminhamentoExterno?.encaminhamentosEspecializadosRecentes,
    subscription: { value: true },
  })
  const {
    input: { value: encaminhamentosRecentes },
  } = usePecField({ name: meta.plano?.encaminhamentoExterno?.encaminhamentosRecentes, subscription: { value: true } })

  const {
    input: { value: compartilhamentoCuidado },
  } = usePecField({ name: meta.plano?.cuidadosCompartilhado, subscription: { value: true } })

  const hasGuiaEncaminhamento = useMemo(
    () =>
      compartilhamentoCuidado &&
      compartilhamentoCuidado.some((item) => item.tipo === TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO),
    [compartilhamentoCuidado]
  )

  const hasEncaminhamentoExterno =
    !isEmpty(encaminhamentosRecentes) || !isEmpty(encaminhamentosEspecializadosRecentes) || hasGuiaEncaminhamento

  if (isAtendimentoOdonto)
    return (
      <CondutaOdontoPanel
        name={name.condutasOdonto}
        tipoConsulta={tipoConsulta}
        hasEncaminhamentoExterno={hasEncaminhamentoExterno}
      />
    )

  if (!isAtendimentoProcedimentos)
    return (
      <CondutaPanel
        name={name.conduta}
        hasNotificacaoCasoSuspeito={hasNotificacaoCasoSuspeito}
        hasEncaminhamentoExterno={hasEncaminhamentoExterno}
        isObservacao={isObservacao}
      />
    )

  return null
}
