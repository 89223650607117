import { Button, HFlow, Icon, Tooltip } from 'bold-ui'
import {
  useAlternarTransmissaoLinkMutation,
  useExcluirTransmissaoLinkMutation,
  useTestarConexaoLinkTransmissaoMutation,
  useUpdateTransmissaoLinkMutation,
} from 'graphql/hooks.generated'
import React, { useState } from 'react'

import { TransmissaoLinksDestinoDropdown } from './TransmissaoLinksDestinoDropdown'
import { TransmissaoLinksDestinoModal } from './TransmissaoLinksDestinoModal'
import { TransmissaoLinksItem } from './TransmissaoLinksDestinoTable'

interface TransmissaoLinksBtnsProps {
  row: TransmissaoLinksItem
  refetch(): Promise<any>
}
interface BtnState {
  linkEditModal: boolean
}

export const TransmissaoLinksDestinoBtns = (props: TransmissaoLinksBtnsProps) => {
  const [btnState, setBtnState] = useState<BtnState>({ linkEditModal: false })
  const actions = props.row.actions

  const [excluirTransmissaoLinkMutation] = useExcluirTransmissaoLinkMutation()
  const [alternarTransmissaoLinkMutation] = useAlternarTransmissaoLinkMutation()
  const [updateTransmissaoLinkMutation] = useUpdateTransmissaoLinkMutation()
  const [testarConexaoLinkTransmissaoMutation] = useTestarConexaoLinkTransmissaoMutation()

  const handleClick = () => setBtnState({ linkEditModal: true })
  const closeModal = () => {
    setBtnState({ linkEditModal: false })
    props.refetch()
  }

  return (
    <HFlow justifyContent='flex-end' hSpacing={0}>
      <Tooltip text={actions.editar.enabled ? 'Editar' : actions.editar.hint}>
        <Button size='small' skin='ghost' onClick={handleClick} disabled={!actions.editar.enabled}>
          <Icon icon='penOutline' />
        </Button>
      </Tooltip>
      <TransmissaoLinksDestinoDropdown
        row={props.row}
        excluir={excluirTransmissaoLinkMutation}
        alternar={alternarTransmissaoLinkMutation}
        testarConexao={testarConexaoLinkTransmissaoMutation}
        refetch={props.refetch}
      />
      <TransmissaoLinksDestinoModal
        row={props.row}
        updateLink={updateTransmissaoLinkMutation}
        open={btnState.linkEditModal}
        closeModal={closeModal}
      />
    </HFlow>
  )
}
