import { HeadingSection } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { useRouter } from 'hooks/useRouter'
import React from 'react'

import { PerfilForm, PerfilFormProps } from './components/PerfilForm'

export interface PerfilFormViewProps extends PerfilFormProps {
  breadcrumb: string
  title: string
  hasVisualizacaoBreadcrumb?: boolean
}

interface UrlParams {
  id: ID
}

export function PerfilFormView(props: PerfilFormViewProps) {
  const { breadcrumb, title, ...rest } = props
  const { match } = useRouter<UrlParams>()

  return (
    <PageContent type='filled'>
      {props.hasVisualizacaoBreadcrumb && (
        <Breadcrumb title='Visualização' to={`/perfil/${match.params.id}/visualizacao`} />
      )}
      <Breadcrumb title={breadcrumb} />

      <HeadingSection level={1} title={title}>
        <PerfilForm {...rest} />
      </HeadingSection>
    </PageContent>
  )
}
