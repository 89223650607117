/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, SVGProps, useMemo } from 'react'

import { CondicoesDenteFieldModel } from '../../odontograma-field/components/CondicoesDenteField'
import { OdontogramaPosicaoProtese } from '../../odontograma-field/model'
import { CondicoesDente } from './CondicoesDente'

export interface PathWithCondicoesDenteProps extends SVGProps<SVGPathElement> {
  d: string
  placement?: OdontogramaPosicaoProtese
  parentRef?: SVGSVGElement
  value?: CondicoesDenteFieldModel
  denteActive?: boolean
  readonly?: boolean
}

export function PathWithCondicoesDente(props: PathWithCondicoesDenteProps) {
  const { d, placement, parentRef, value, denteActive, readonly, ...rest } = props

  const maskId = useMemo(() => d.split(' ').join(''), [d])

  return (
    <Fragment>
      <path d={d} {...rest} />
      <mask id={maskId}>
        <path
          d={d}
          css={css`
            fill: white;
          `}
        />
      </mask>
      {(!denteActive || readonly) && (
        <CondicoesDente maskId={maskId} value={value} placement={placement} parentRef={parentRef} />
      )}
    </Fragment>
  )
}
