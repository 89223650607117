/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import { AgendaTimeSlotProps } from 'components/agenda/AgendaTimeSlotWrapper'
import { format, subDays } from 'date-fns'
import { ReactComponent as AgendaOnlineIcon } from 'images/agenda/agenda-online.svg'
import { Fragment } from 'react'

export interface TimeSlotAgendaLotacaoProps extends AgendaTimeSlotProps {
  reservadoAgendaOnline: boolean
  diasLiberacaoReservaAgendaOnline: number
}

export function TimeSlotAgendaLotacao(props: React.PropsWithChildren<TimeSlotAgendaLotacaoProps>) {
  const { reservadoAgendaOnline, start, diasLiberacaoReservaAgendaOnline, children } = props

  return (
    <Fragment>
      {reservadoAgendaOnline && (
        <HFlow hSpacing={0.5} style={styles.wrapperReservaOnline} alignItems='center'>
          <AgendaOnlineIcon css={styles.iconReservaOnline} />
          Reservado ao Conecte SUS Cidadão | Disponível para o PEC em{' '}
          {format(subDays(start, diasLiberacaoReservaAgendaOnline), 'dd/MM/yyyy')}
        </HFlow>
      )}
      {children}
    </Fragment>
  )
}

const styles = {
  wrapperReservaOnline: css`
    position: absolute;
    width: 100%;
    padding: 0.9rem 1.125rem;
    background-color: ${blue.c90};
    color: ${blue.c60};
  `,
  iconReservaOnline: css`
    height: 1.5rem;
    width: 1.5rem;
    fill: ${blue.c70};
  `,
}
