/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  Grid,
  Heading,
  HeadingSection,
  HFlow,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  Theme,
  Tooltip,
  useTheme,
  VFlow,
} from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Box } from 'components/Box'
import { useLoadAtestadoByIdLazyQuery } from 'graphql/hooks.generated'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { formatDayMonthYear } from 'util/date/formatDate'
import { ProfissionalAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { grupoCboAtestado } from '../../acessos'
import {
  ASSINATURA_CIDADAO_TEXT,
  atestadoComCID10Text,
  CID10_DESCRICAO_ATESTADO,
  tituloAtestado,
} from '../atestadoUtils'
import { RegistroLista } from './AtestadosTable'

export interface AtestadosVisualizacaoModalProps {
  registro: RegistroLista
  descricaoAtestadoRecente?: string
  tipoAtestadoRecente?: TipoAtestadoEnum
  nomeCidadao: string
  handlePrint(): void
  handleModalClose(): void
}

export default function AtestadosVisualizacaoModal(props: AtestadosVisualizacaoModalProps) {
  const { registro, descricaoAtestadoRecente, tipoAtestadoRecente, handlePrint, handleModalClose, nomeCidadao } = props
  const [executeQuery, { data }] = useLoadAtestadoByIdLazyQuery()
  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })
  const theme = useTheme()
  const classes = createStyles(theme)

  useMemo(() => {
    if (!registro.recente) executeQuery({ variables: { id: registro.id } })
  }, [executeQuery, registro.id, registro.recente])

  const tipoAtestado = registro.recente ? tipoAtestadoRecente : data?.atestado.tipo
  const tituloModal =
    tipoAtestado === TipoAtestadoEnum.LICENCA_MATERNIDADE ? 'Visualizar licença maternidade' : 'Visualizar atestado'
  const descricao = registro.recente ? descricaoAtestadoRecente : data?.atestado.descricao
  const municipioRegistro = registro?.atendimentoProfissional?.lotacao.municipio
  const dataAtendimentoRegistro = registro?.atendimentoProfissional?.iniciadoEm
  const nomeCboRegistro = registro?.atendimentoProfissional?.lotacao.cbo.nome
  const profissionalRegistro = registro?.atendimentoProfissional?.lotacao.profissional as ProfissionalAtendimento
  const dataPorExtenso = formatDayMonthYear(dataAtendimentoRegistro)
  const hasPermissionImprimirAtestado = hasCboAuth(grupoCboAtestado.imprimir)
  const showAssinaturaCidadao = tipoAtestado && descricao.includes(CID10_DESCRICAO_ATESTADO)
  return (
    <Modal style={classes.modal} onClose={handleModalClose} open>
      <ModalBody>
        <HeadingSection level={1} title={tituloModal} />
        <Box>
          {tipoAtestado && (
            <VFlow style={classes.header}>
              <Heading level={3}>{tituloAtestado(tipoAtestado)}</Heading>
            </VFlow>
          )}
          <Grid>
            <Cell size={12}>
              <VFlow style={classes.body}>
                <Text dangerouslySetInnerHTML={{ __html: descricao }} />
              </VFlow>
            </Cell>
            <Cell size={3} />
          </Grid>
          <VFlow style={classes.center} vSpacing={0}>
            {`${municipioRegistro?.nome.titleCase()} - ${municipioRegistro?.uf.sigla},  ${dataPorExtenso}`}
            <VFlow style={classes.signature} vSpacing={0}>
              <Text fontWeight='bold'>
                {profissionalRegistro.nome}
                {profissionalRegistro.conselhoClasse &&
                  ` - ${profissionalRegistro.conselhoClasse.sigla} - ${profissionalRegistro.ufEmissoraConselhoClasse.sigla} ${profissionalRegistro.numeroConselhoClasse}`}
              </Text>
              {profissionalRegistro && <Text>{nomeCboRegistro}</Text>}
            </VFlow>
          </VFlow>
          {showAssinaturaCidadao && (
            <VFlow style={[classes.center, classes.body]} vSpacing={0}>
              <Text>{atestadoComCID10Text({ nomeCidadao, nomeProfissional: profissionalRegistro.nome })}</Text>
              <VFlow style={classes.signature} vSpacing={0}>
                <Text fontWeight='bold'>{ASSINATURA_CIDADAO_TEXT}</Text>
              </VFlow>
            </VFlow>
          )}
        </Box>
      </ModalBody>
      {hasPermissionImprimirAtestado && (
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Tooltip text='Imprimir'>
              <Button size='medium' kind='primary' onClick={handlePrint}>
                <Icon icon='printerOutline' style={classes.icon} />
                Imprimir
              </Button>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      )}
    </Modal>
  )
}

const createStyles = (theme: Theme) => ({
  signature: css`
    border-top: solid;
    border-top-width: 1px;
    border-top-color: ${theme.pallete.gray.c30};
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
    font-size: 13px;
  `,
  center: css`
    justify-content: center;
    align-items: center;
  `,
  body: css`
    text-align: justify;
    margin: 1rem 2rem;
  `,
  header: css`
    margin: 1.5rem 0.5rem;
    text-align: center;
  `,
  modal: css`
    width: 41rem;
  `,
  icon: css`
    margin-right: 0.75rem;
  `,
})
