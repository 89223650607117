import { partition } from 'lodash'

import { isAtendimentoObservacaoByStatusAtendimento } from '../atendimento-individual/atendimento-observacao/util-atendObservacao'
import { AtendimentoModel } from './model'

export const partitionAtendimentos = (atendimentos: AtendimentoModel[] = []) => {
  if (atendimentos?.length > 0) {
    const [atendimentosEmObservacao, restAtendimentos] = partition<AtendimentoModel>(atendimentos, (atendimento) =>
      isAtendimentoObservacaoByStatusAtendimento(atendimento.statusAtendimento)
    )

    return { atendimentosEmObservacao, restAtendimentos }
  }

  return { atendimentosEmObservacao: [], restAtendimentos: [] }
}
