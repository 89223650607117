import React from 'react'

import { ConfiguracoesInatividadeBox } from './ConfiguracoesInatividadeBox'
import { ConfiguracoesNumTentativasBox } from './ConfiguracoesNumTentativasBox'
import { ConfiguracoesRedefinirSenhaBox } from './ConfiguracoesRedefinirSenhasBox'
import { ConfiguracoesSenhaBox } from './ConfiguracoesSenhaBox'

export interface ConfiguracoesSegurancaBoxProps {
  periodoEmMesesParaRedefinir: number
  periodoEmMinutosInatividade: number
  loginNumeroTentativas: number
  refetch(): void
}

export function ConfiguracoesSegurancaBox(props: ConfiguracoesSegurancaBoxProps) {
  return (
    <>
      <ConfiguracoesSenhaBox periodoEmMesesParaRedefinir={props.periodoEmMesesParaRedefinir} refetch={props.refetch} />
      <ConfiguracoesInatividadeBox
        periodoEmMinutosInatividade={props.periodoEmMinutosInatividade}
        refetch={props.refetch}
      />
      <ConfiguracoesNumTentativasBox loginNumeroTentativas={props.loginNumeroTentativas} refetch={props.refetch} />
      <ConfiguracoesRedefinirSenhaBox />
    </>
  )
}
