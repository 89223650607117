import { Button, HeadingSection, HFlow, Modal, ModalBody, ModalFooter, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { RichTextField, SubmitButton } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import React, { useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { AtestadoAssinaturaDigitalView } from '../assinatura-digital/AtestadoAssinaturaDigitalView'
import { MESSAGE_TOOLTIP_SUBMIT_ATESTADO_DISABLED } from '../assinatura-digital/model-atestadoAssinaturaDigital'
import { useAtestadoAssinaturaDigitalForm } from '../assinatura-digital/useAtestadoAssinaturaDigitalForm'
import AtestadoModel from '../components/model-atestado'
import { name } from './AtestadoEmBrancoForm'
import { atestadoEmBrancoFormValidator } from './validator'

interface AtestadoEmBrancoEditModal {
  onSubmit(values: AtestadoModel): void
  handleModalClose(): void
  cidadaoId: ID
  initialValues?: AtestadoModel
  atendimentoId: ID
  prontuarioId: ID
  dataAtendimento: Instant
}

export default function AtestadoEmBrancoFormModal(props: AtestadoEmBrancoEditModal) {
  const { onSubmit, handleModalClose, cidadaoId, initialValues, atendimentoId, prontuarioId, dataAtendimento } = props

  const alert = useAlert()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [isEmailCidadaoDirty, setIsEmailCidadaoDirty] = useState(false)

  const handleSubmit = (values: AtestadoModel) => {
    onSubmit(values)
    handleModalClose()
    alert('success', 'Atestado salvo com sucesso')
  }

  const { emailCidadao, handleSubmitWrapper, isAssinando } = useAtestadoAssinaturaDigitalForm({
    cidadaoId: cidadaoId,
    atendimentoId: atendimentoId,
    prontuarioId: prontuarioId,
    tipoAtestado: TipoAtestadoEnum.EM_BRANCO,
    dataAtendimento: dataAtendimento,
    onSubmit: handleSubmit,
  })

  const handleCancel = (dirty: boolean) => {
    if (dirty) {
      confirm({
        title: 'Deseja cancelar a edição?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar edição',
        confirmLabel: 'Sim, cancelar edição',
        onConfirm: () => {
          alert('success', 'Edição de atestado cancelado com sucesso.')
          handleModalClose()
        },
      })()
    } else {
      alert('success', 'Edição de atestado cancelado com sucesso.')
      handleModalClose()
    }
  }

  return (
    <Form<AtestadoModel>
      initialValues={initialValues}
      onSubmit={handleSubmitWrapper}
      validate={atestadoEmBrancoFormValidator}
      render={(formProps: FormRenderProps<AtestadoModel>) => (
        <Modal open onClose={() => handleCancel(formProps.dirty || isEmailCidadaoDirty)}>
          <ModalBody>
            <HeadingSection level={1} title='Editar atestado' />
            <AtestadoAssinaturaDigitalView
              name={name.assinadoDigitalmente}
              cidadaoId={cidadaoId}
              cidadaoEmail={emailCidadao}
              onEmailCidadaoChange={setIsSubmitDisabled}
              hasCid10={!!formProps.values.cid10}
              onDirtyChange={setIsEmailCidadaoDirty}
            />
            <RichTextField
              name={name.descricao}
              maxLength={4000}
              placeholder='Insira o conteúdo do atestado'
              height={10}
              label='Conteúdo'
              required
            />
          </ModalBody>
          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <Button onClick={() => handleCancel(formProps.dirty)}>Cancelar</Button>
              <Tooltip text={isSubmitDisabled && MESSAGE_TOOLTIP_SUBMIT_ATESTADO_DISABLED}>
                <SubmitButton
                  title='Salvar'
                  kind='primary'
                  handleSubmit={formProps.handleSubmit}
                  disabled={isSubmitDisabled || isAssinando}
                  loading={isAssinando}
                >
                  Salvar
                </SubmitButton>
              </Tooltip>
            </HFlow>
          </ModalFooter>
        </Modal>
      )}
    />
  )
}
