import { Text, VFlow } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import { css } from 'emotion'
import React from 'react'
import { formatHoursMinutes } from 'util/date/formatDate'

import { PlanoCuidadoModel } from '../model'

interface HistoricoPlanoCuidadoProps {
  planoDeCuidado: PlanoCuidadoModel
}

export const HistoricoPlanoCuidado = (props: HistoricoPlanoCuidadoProps) => {
  const { planoDeCuidado } = props

  return (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>
        {formatHoursMinutes(planoDeCuidado.dataDescricao)} |{' '}
        {planoDeCuidado.atendimentoProfissionalObservacao.atendimentoProfissional.lotacao.profissional.nome}
      </Text>
      <Ellipsis maxLines={3}>
        <Text style={styles.descricao} dangerouslySetInnerHTML={{ __html: planoDeCuidado.descricao }} />
      </Ellipsis>
    </VFlow>
  )
}

const styles = {
  descricao: css`
    word-break: break-word;
  `,
}
