import api from 'api'
import { ImpressaoFichaSinanInput } from 'api/FichaSinan'
import { defaultHandleError, downloadFile } from 'util/downloadFile'

export const downloadFichaSinan = (input: ImpressaoFichaSinanInput) => {
  return api.fichaSinan
    .gerarFichaSinan(input)
    .then((response) => {
      downloadFile(new Blob([response.data]), 'FichaNotificacaoCasoSuspeito_' + new Date().getTime() + '.pdf')
      return response
    })
    .catch(defaultHandleError)
}
