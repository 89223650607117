import { msg } from '..'

const EMAIL = '^[_a-zA-Z0-9-\\+]+(\\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9]+)*(\\.[a-zA-Z]{2,})$'

export function email(value: string) {
  if (!isValid(value)) {
    return msg('invalid', value)
  }
}

function isValid(value) {
  if (!value) {
    return true
  }
  return new RegExp(EMAIL).test(value)
}
