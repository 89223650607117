/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { FormPrompt, FormProps } from 'components/form'
import { Fragment } from 'react'
import { useParams } from 'react-router'

import { TecidosMolesFieldModel } from './TecidosMolesField'
import { TecidosMolesForm, TecidosMolesFormModel } from './TecidosMolesForm'
import TecidosMolesFormFields from './TecidosMolesFormFields'
import { validateCaracteristicasLesao } from './validator'

interface UrlParams {
  id: ID
}

interface EditarTecidosMolesFormModalProps extends Omit<FormProps<TecidosMolesFormModel>, 'render'> {
  evolucoesAtuais: ReadonlyArray<TecidosMolesFieldModel>
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  onClose(): void
}

export default function EditarTecidosMolesFormModal(props: EditarTecidosMolesFormModalProps) {
  const { evolucoesAtuais, cidadaoDataNascimento, dataAtendimento, onSubmit, onClose, ...formProps } = props

  const { id } = useParams<UrlParams>()

  const initialValues = evolucoesAtuais.find((item) => item._id === id)

  const procedimentosSelecionados = evolucoesAtuais
    ?.flatMap((item) => item?.procedimentos)
    .filter((item) => !initialValues?.procedimentos?.map((proced) => proced?.id).includes(item?.id))

  return (
    <TecidosMolesForm
      onSubmit={onSubmit}
      onSubmitSucceeded={onClose}
      procedimentosSelecionados={procedimentosSelecionados}
      initialValues={initialValues}
      render={(renderProps) => (
        <Fragment>
          <FormPrompt />
          <Modal open={true} onClose={onClose} style={styles.modal}>
            <ModalBody>
              <VFlow>
                <Heading level={1}>Edição de registros - Tecidos moles e duros</Heading>

                <TecidosMolesFormFields
                  isModal
                  cidadaoDataNascimento={cidadaoDataNascimento}
                  dataAtendimento={dataAtendimento}
                  parteBucalSelecionada={renderProps.values?.local?.value}
                  hasProcedimentos={renderProps.values?.procedimentos?.length > 0}
                  preExpanded={!!initialValues?.caracteristicasLesao}
                  caracteristicasLesaoError={validateCaracteristicasLesao(renderProps)}
                  procedimentosSelecionados={procedimentosSelecionados}
                />
              </VFlow>
            </ModalBody>

            <ModalFooter>
              <HFlow justifyContent='flex-end'>
                <FooterButton kind='normal' onClick={onClose}>
                  Cancelar
                </FooterButton>
                <FooterButton kind='primary' onClick={renderProps.handleSubmit}>
                  Salvar
                </FooterButton>
              </HFlow>
            </ModalFooter>
          </Modal>
        </Fragment>
      )}
      {...formProps}
    />
  )
}

const styles = {
  modal: css`
    width: 45rem;
  `,
}
