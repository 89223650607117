import { HFlow, Text, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoCuidadoCompartilhadoLotacaoAfetadaQuery } from 'graphql/hooks.generated'
import React from 'react'

interface HistoricoCuidadoCompartilhadoTrocaProfissionalReferenciaProps {
  cuidadoCompartilhadoEvolucaoId: ID
  descricao?: string
}

export function HistoricoCuidadoCompartilhadoTrocaProfissionalReferencia(
  props: HistoricoCuidadoCompartilhadoTrocaProfissionalReferenciaProps
) {
  const { cuidadoCompartilhadoEvolucaoId, descricao } = props

  const { loading, data } = useHistoricoCuidadoCompartilhadoLotacaoAfetadaQuery({
    variables: { id: cuidadoCompartilhadoEvolucaoId },
  })

  if (loading) return <LoadingIndicator />

  const lotacaoAfetada = data?.cuidadoCompartilhadoEvolucao?.lotacaoAfetada
  if (!lotacaoAfetada) return null

  const equipe = lotacaoAfetada.equipe

  return (
    <VFlow vSpacing={0.25}>
      <HFlow hSpacing={0.25}>
        <Text fontWeight='bold'>Encaminhou para {lotacaoAfetada.profissional.nome}</Text>|{' '}
        {lotacaoAfetada.cbo.nome.toUpperCase()}| {equipe ? `| Equipe ${equipe.ine} - ${equipe.nome}` : ''}
      </HFlow>
      <HFlow hSpacing={0.25}>
        <Text fontWeight='bold'>Motivo: </Text>
        <Text dangerouslySetInnerHTML={{ __html: descricao }} />
      </HFlow>
    </VFlow>
  )
}
