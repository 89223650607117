import { Cell, Grid } from 'bold-ui'
import { Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { TipoServicoSelectField } from 'components/form/field/select/TipoServicoSelectField'
import { FormState } from 'final-form'
import React from 'react'
import { metaPath } from 'util/metaPath'

import { GarantiaAcessoFilterModel } from '../model-garantiaAcesso'

const path = metaPath<GarantiaAcessoFilterModel>()

interface GarantiaAcessoTableFilterCeoViewProps {
  filter: GarantiaAcessoFilterModel
  onChange(values: GarantiaAcessoFilterModel): void
}

export default function GarantiaAcessoTableFilterCeoView(props: GarantiaAcessoTableFilterCeoViewProps) {
  const { filter, onChange } = props

  const handleChange = (formState: FormState<GarantiaAcessoFilterModel>) => onChange(formState.values)

  const renderForm = () => {
    return (
      <Grid>
        <FormDebouncedValueSpy onChange={handleChange} />
        <Cell size={4}>
          <TextField name={path.nomeCpfCns} label='Cidadão' placeholder='Pesquise pelo nome, CNS ou CPF' />
        </Cell>
        <Cell size={4}>
          <TipoServicoSelectField name={path.tiposServico} label='Tipo de serviço' multiple />
        </Cell>
      </Grid>
    )
  }

  return <Form<GarantiaAcessoFilterModel> initialValues={filter} render={renderForm} />
}
