import { PecSwitch, PrivateRoute } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'

import RelatorioADView from './RelatorioADView'

export function RelatorioADRootView() {
  const { match } = useRouter()

  return (
    <PecSwitch>
      <PrivateRoute
        exact
        path={`${match.url}`}
        component={RelatorioADView}
        permission={Permissions.atencaoDomiciliar}
      />
    </PecSwitch>
  )
}
