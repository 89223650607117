import { Authorization } from 'components/auth/model-authorization'
import useSession from 'components/auth/useSession'
import { AcessoCbo, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

export interface PrivateRouteProps extends RouteProps {
  permission?: Authorization
  cbos?: AcessoCbo[]
  tiposEstabelecimento?: TipoEstabelecimentoEnum[]
  acceptEstagio?: boolean
  onlyAtencaoPrimaria?: boolean
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { permission, cbos, acceptEstagio = true, tiposEstabelecimento, onlyAtencaoPrimaria = false, ...rest } = props

  const {
    checkAuthorization,
    hasCboAuth,
    isEstagio,
    hasTipoEstabelecimentoAuth,
    isEstabelecimentoAtencaoPrimaria,
  } = useSession({
    fetchPolicy: 'cache-only',
  })

  return (permission === undefined || (permission && checkAuthorization(permission))) &&
    (!isEstagio || acceptEstagio) &&
    (isUndefinedOrNull(tiposEstabelecimento) || hasTipoEstabelecimentoAuth(tiposEstabelecimento)) &&
    (!onlyAtencaoPrimaria || isEstabelecimentoAtencaoPrimaria) &&
    (cbos === undefined || (cbos && hasCboAuth(cbos))) ? (
    <Route {...rest} />
  ) : (
    <Redirect to='/403' />
  )
}
