import { Heading, HFlow, Icon, Theme, Tooltip, useStyles } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import React, { CSSProperties } from 'react'
import { Accordion, AccordionItem, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion'

export interface OpenClosedSpec<T> {
  open: T
  closed: T
}

export interface AccordionCardSectionProps {
  id: string
  title: React.ReactNode | OpenClosedSpec<React.ReactNode>
  children: React.ReactNode
  tooltip?: string | OpenClosedSpec<string>
  expanded?: boolean
}

export function AccordionCardSection(props: AccordionCardSectionProps) {
  const { id, title, tooltip, children, expanded } = props
  const { classes } = useStyles(createStyles)
  return (
    <Accordion preExpanded={expanded ? [id] : undefined} allowZeroExpanded={true}>
      <AccordionItem uuid={id}>
        <AccordionItemState>
          {(state) => (
            <Tooltip text={getValueFromSpec(state.expanded, tooltip)}>
              <div className={classes.accordion}>
                <AccordionButton stopPropagation>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Icon icon='angleDown' size={1.5} />
                    <Heading level={5}>{getValueFromSpec(state.expanded, title)}</Heading>
                  </HFlow>
                </AccordionButton>
              </div>
            </Tooltip>
          )}
        </AccordionItemState>
        <AccordionItemPanel>
          <div style={{ padding: '0.5rem' }}>{children}</div>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

function getValueFromSpec<T>(isOpen: boolean, spec: T | OpenClosedSpec<T>): T {
  const openCloseSpec = spec as OpenClosedSpec<T>
  if (openCloseSpec?.open || openCloseSpec?.closed) return isOpen ? openCloseSpec.open : openCloseSpec.closed
  else return spec as T
}

const createStyles = (theme: Theme) => ({
  accordion: {
    borderTop: `0.0625rem solid ${theme.pallete.gray.c80}`,
    position: 'relative',
    top: '0.3rem',
    '&:hover': {
      backgroundColor: theme.pallete.surface.background,
    },
  } as CSSProperties,
})
