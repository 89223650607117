import useSession from 'components/auth/useSession'
import { useStatusVideochamadaQuery } from 'graphql/hooks.generated'
import { StatusVideochamadaEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { useCallback, useState } from 'react'

import { MotivoEncerramentoVideochamadaEnum, StatusVideochamadaAtmosphereResponse } from '../model-videochamada'

const SESSION_KEEP_ALIVE_INTERVAL_USUARIO_EXTERNO = 30 * 60 //segundos

export function useVideochamadaStatus(
  videochamadaUuid: string,
  keepAlive: Boolean = false
): NonNullable<StatusVideochamadaAtmosphereResponse & { loading: boolean }> {
  const { data: session, loading: isSessionLoading } = useSession()

  const [status, setStatus] = useState<StatusVideochamadaAtmosphereResponse>({
    status: null,
  })

  const keepAliveIntervalSeconds = session ? session.timeout / 2 : SESSION_KEEP_ALIVE_INTERVAL_USUARIO_EXTERNO
  const { loading } = useStatusVideochamadaQuery({
    variables: { uuid: videochamadaUuid },
    pollInterval: keepAlive ? keepAliveIntervalSeconds * 1000 : 0,
    onCompleted(data) {
      if (!data.videochamada)
        setStatus({
          status: StatusVideochamadaEnum.ENCERRADA,
          motivoEncerramento: MotivoEncerramentoVideochamadaEnum.ENCERRADA_POR_CRIADOR,
        })
      else if (status.status !== data.videochamada.status) setStatus({ status: data.videochamada.status })
    },
  })

  const handleMessage = useCallback(
    (message: StatusVideochamadaAtmosphereResponse) => {
      if (status.status !== message.status) setStatus(message)
    },
    [status]
  )

  useAtmosphere<StatusVideochamadaAtmosphereResponse>({
    topic: `public/videochamada/${videochamadaUuid}/status`,
    onMessage: handleMessage,
  })

  return { ...status, loading: isSessionLoading || loading }
}
