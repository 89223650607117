import { toEsusParameters } from 'components/esus'
import { useHistory, useRouteMatch } from 'react-router'
import { EsusDetailMode } from 'view/cds/model'

import {
  domicilioTipoImovelRecord,
  TipoDomicilioEnum,
  TipoImovelEnum,
  TipoImovelListModel,
  tipoImovelRecord,
} from '../model'

export const formatNumImovelCidadao = (numImoveis: number, numCidadaos: number) => {
  return `${numImoveis ?? 0} imóve${numImoveis === 1 ? 'l' : 'is'} | ${'cidadão'.pluralizeAndConcatValue(
    numCidadaos ?? 0
  )}`
}

export const getTipoImovelIcon = (tipoCdsImovelId: string, tipoDomicilioId: string): TipoImovelListModel => {
  if (!!tipoCdsImovelId) {
    if (tipoCdsImovelId !== TipoImovelEnum.DOMICILIO) return tipoImovelRecord[tipoCdsImovelId]
    if (!!tipoDomicilioId) return domicilioTipoImovelRecord[tipoDomicilioId]
    else return domicilioTipoImovelRecord[TipoDomicilioEnum.CASA]
  }
  return tipoImovelRecord[TipoImovelEnum.OUTROS]
}

export const redirectToAtualizarImovelCds = (
  history: ReturnType<typeof useHistory>,
  match: ReturnType<typeof useRouteMatch>,
  uuidDomicilio: ID,
  profissionalId: ID,
  lotacaoId: ID
) => {
  history.push(
    `/cds/cadastro-domiciliar/detail?${toEsusParameters({
      pecBackLink: btoa(`${match.url}?redirect=true`),
      readOnly: false,
      esusDetailMode: EsusDetailMode.APPEND,
      isAtualizarDomicilio: true,
      uuidOrigemDomicilio: uuidDomicilio,
      userId: profissionalId,
      lotacaoId: lotacaoId,
    })}`
  )
}

export const redirectToEditarImovelCds = (
  history: ReturnType<typeof useHistory>,
  match: ReturnType<typeof useRouteMatch>,
  idFichaDomicilio: ID,
  profissionalId: ID,
  lotacaoId: ID
) => {
  history.push(
    `/cds/cadastro-domiciliar/detail?${toEsusParameters({
      pecBackLink: btoa(`${match.url}?redirect=true`),
      readOnly: false,
      esusDetailMode: EsusDetailMode.EDIT,
      idFicha: idFichaDomicilio,
      userId: profissionalId,
      lotacaoId: lotacaoId,
    })}`
  )
}

export const redirectToAtualizarCidadao = (
  history: ReturnType<typeof useHistory>,
  match: ReturnType<typeof useRouteMatch>,
  uuidFichaOrigem: ID,
  profissionalId: ID,
  lotacaoId: ID
) => {
  history.push(
    `/cds/cadastro-individual/detail?${toEsusParameters({
      pecBackLink: btoa(`${match.url}?redirect=true`),
      readOnly: false,
      isAtualizarCidadao: true,
      esusDetailMode: EsusDetailMode.APPEND,
      uuidCadastroIndividual: uuidFichaOrigem,
      userId: profissionalId,
      lotacaoId: lotacaoId,
    })}`
  )
}

export const redirectToEditarCidadao = (
  history: ReturnType<typeof useHistory>,
  match: ReturnType<typeof useRouteMatch>,
  idFichaCidadao: ID,
  profissionalId: ID,
  lotacaoId: ID
) => {
  history.push(
    `/cds/cadastro-individual/detail?${toEsusParameters({
      pecBackLink: btoa(`${match.url}?redirect=true`),
      readOnly: false,
      idFicha: idFichaCidadao,
      esusDetailMode: EsusDetailMode.EDIT,
      userId: profissionalId,
      lotacaoId: lotacaoId,
    })}`
  )
}
