import { AcessoCbo } from 'graphql/types.generated'
import { isCboMedico } from 'util/atendimento/cboHelper'
import { isUndefinedOrNull } from 'util/checks'

import GruposCbo from './GruposCbo'

export const NIVEL_SUPERIOR: AcessoCbo[] = [
  AcessoCbo.MEDICO,
  AcessoCbo.CIRURGIAO_DENTISTA,
  AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
  AcessoCbo.ENFERMEIRO,
  AcessoCbo.PSICOLOGO,
  AcessoCbo.FARMACEUTICO,
  AcessoCbo.NUTRICIONISTA,
]

export const NIVEL_TECNICO: AcessoCbo[] = [AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM, AcessoCbo.TSB]

export const NIVEL_TECNICO_AS_STRING = NIVEL_TECNICO.map((item) => item.toString())
export const isCboNivelTecnico = (cbo: string) => {
  const gruposCbo = GruposCbo.filter((item) => item.cbos.includes(cbo)).map((item) => item.grupo)
  return NIVEL_TECNICO_AS_STRING.some((grupoCbo) => gruposCbo.includes(grupoCbo))
}

export const NIVEL_SUPERIOR_AS_STRING = NIVEL_SUPERIOR.map((item) => item.toString())
export const isCboNivelSuperior = (cbo: string) => {
  const gruposCbo = GruposCbo.filter((item) => item.cbos.includes(cbo)).map((item) => item.grupo)
  return NIVEL_SUPERIOR_AS_STRING.some((grupoCbo) => gruposCbo.includes(grupoCbo))
}

export const GRUPO_CBO_DENTISTA = GruposCbo.find((item) => item.grupo === AcessoCbo.CIRURGIAO_DENTISTA)
export const isCboDentista = (cbo: string) => {
  return grupoCboIncludesCbo(GRUPO_CBO_DENTISTA.cbos, cbo)
}

const CBO_DENTISTA_CLINICO_GERAL = '223208'
export const isCboDentistaClinicoGeral = (cbo: string) => cbo === CBO_DENTISTA_CLINICO_GERAL

export const grupoCboIncludesCbo = (grupoCbo: string[], cbo2002: string): boolean => {
  if (isUndefinedOrNull(grupoCbo) || isUndefinedOrNull(cbo2002)) return false
  return grupoCbo.some((cboListado) => cboListado === cbo2002 || cbo2002.startsWith(cboListado))
}

export const isCboMedicoOuDentista = (cbo: string) => {
  return isCboDentista(cbo) || isCboMedico(cbo)
}

export const GRUPO_CBO_ACS = GruposCbo.find((item) => item.grupo === AcessoCbo.ACS)

export const isCboAcs = (cbo: string) => grupoCboIncludesCbo(GRUPO_CBO_ACS.cbos, cbo)

export const CBOS_ACESSO_CC: AcessoCbo[] = [...NIVEL_SUPERIOR, AcessoCbo.GERENTE_SERVICOS]

export const GRUPO_CBO_GERENTE = GruposCbo.find((item) => item.grupo === AcessoCbo.GERENTE_SERVICOS)
export const isCboGerente = (cbo: string) => {
  return grupoCboIncludesCbo(GRUPO_CBO_GERENTE.cbos, cbo)
}
