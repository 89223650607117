/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Form, FormProps } from 'components/form'
import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { ParteBucalEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { compact } from 'lodash'
import { metaPath } from 'util/metaPath'
import { createValidator, maxLength, required } from 'util/validation'

import { MAX_LENGTH_OBS_ODONTO } from '../../model'
import { EvolucoesPeriodontaisFieldModel } from './model'

export interface EvolucoesPeriodontaisFormModel extends Omit<EvolucoesPeriodontaisFieldModel, 'sextante'> {
  sextantes: ParteBucalEnum[]
}

interface EvolucoesPeriodontaisFormProps
  extends Omit<FormProps<EvolucoesPeriodontaisFormModel>, 'validate' | 'render'> {
  render: FormProps<EvolucoesPeriodontaisFormModel>['render']
  evolucoesAtuais: EvolucoesPeriodontaisFieldModel[]
  isEditing?: boolean
  onCancel?(): void
}

const meta = metaPath<EvolucoesPeriodontaisFormModel>()

const validator = (evolucoesAtuais: EvolucoesPeriodontaisFieldModel[]) =>
  createValidator<EvolucoesPeriodontaisFormModel>(
    {
      sextantes: [required],
      procedimentos: [required],
      observacao: [maxLength(MAX_LENGTH_OBS_ODONTO)],
    },
    (values, errors) => {
      const procedimentosDosSextantesSelecionados = (evolucoesAtuais || [])
        ?.filter((evolucao) => values?.sextantes?.includes(evolucao.sextante) && evolucao._id !== values._id)
        .map(({ procedimentos }) => procedimentos)
        .flat()
        .map(({ id }) => id)

      if (
        compact(values?.procedimentos?.map((proced) => procedimentosDosSextantesSelecionados?.includes(proced?.id)))
          ?.length > 0
      ) {
        errors.procedimentos = 'Existem procedimento(s) já adicionado(s).'
      }

      return errors
    }
  )

const decorator = (evolucoesAtuais: EvolucoesPeriodontaisFieldModel[], isEditing: boolean = false) =>
  createDecorator(
    {
      field: meta.procedimentos.absolutePath(),
      updates: {
        [meta.observacao.absolutePath()]: (
          value: ProcedimentoOdontoSelectModel[],
          allValues: EvolucoesPeriodontaisFormModel
        ) => {
          if (value?.length > 0) return allValues.observacao
        },
      },
    },
    {
      field: meta.sextantes.absolutePath(),
      updates: {
        [meta.procedimentos.absolutePath()]: (
          value: ParteBucalEnum[],
          allValues: EvolucoesPeriodontaisFormModel,
          prevValues: EvolucoesPeriodontaisFormModel
        ) => {
          if (!isEditing) {
            if (value?.length > 0) {
              const procedimentosDosSextantesSelecionados = (evolucoesAtuais || [])
                ?.filter((evolucao) => value?.includes(evolucao.sextante))
                .flatMap(({ procedimentos }) => procedimentos)
                .map(({ id }) => id)

              if (compact(prevValues?.sextantes)?.length > 0) {
                if (value?.some((sextante) => prevValues?.sextantes.includes(sextante))) {
                  return allValues.procedimentos?.filter(
                    (proced) => !procedimentosDosSextantesSelecionados.includes(proced.id)
                  )
                }
              } else {
                if (value) return allValues.procedimentos
              }
            }
          } else {
            return allValues.procedimentos
          }
        },
      },
    }
  )

export default function EvolucoesPeriodontaisForm(props: EvolucoesPeriodontaisFormProps) {
  const { render, onSubmit, evolucoesAtuais, isEditing, ...rest } = props

  const { getServerTimeNow } = useServerTime()

  const handleSubmit = (values: EvolucoesPeriodontaisFormModel, formApi: FormApi<EvolucoesPeriodontaisFormModel>) => {
    onSubmit(
      {
        ...values,
        observacao: values?.observacao?.trim() ? values.observacao : null,
        lastUpdate: getServerTimeNow().valueOf(),
      },
      formApi
    )
    setTimeout(formApi.reset)
  }

  return (
    <Form<EvolucoesPeriodontaisFormModel>
      validate={validator(evolucoesAtuais)}
      decorators={[decorator(evolucoesAtuais, isEditing)]}
      render={render}
      onSubmit={handleSubmit}
      {...rest}
    />
  )
}
