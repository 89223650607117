import { ServicosCertificadoEnum } from 'graphql/types.generated'
import { createValidator, ErrorObject, maxLength, required } from 'util/validation'

import ConfiguracaoCertificadosFormModel from './ConfiguracoesCertificadosRootView'

export const configuracaoCertificadosCreateValidator = createValidator<ConfiguracaoCertificadosFormModel>(
  {
    senhaCertificado: [required, maxLength(255)],
    servicos: [required],
  },
  (values, errors: ErrorObject<ConfiguracaoCertificadosFormModel>) => {
    if (values?.certificadoSelect?.path) {
      errors.certificadoSelect = maxLength(255)(values?.certificadoSelect?.path)
    } else {
      errors.certificadoSelect = required(values?.certificadoSelect?.path)
    }
    if (values.servicos?.includes(ServicosCertificadoEnum.RNDS) && values.idSolicitante) {
      errors.idSolicitante = maxLength(20)(values?.idSolicitante)
    } else if (values.servicos?.includes(ServicosCertificadoEnum.RNDS) && !values.idSolicitante) {
      errors.idSolicitante = required(values?.idSolicitante)
    }
    return errors
  }
)
