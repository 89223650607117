import { EnderecoFieldGroupModel } from 'components/form'
import { PaisSelectFieldModel } from 'components/form/field/select/PaisSelectField'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import createCalculator from 'final-form-calculate'
import { MetaRoot } from 'util/metaPath'

const coPaisBrasil = '31'

export const enderecoCalculator = (path: MetaRoot<EnderecoFieldGroupModel>) => {
  return createCalculator(
    {
      field: path.pais.absolutePath(),
      updates: {
        [path.cep.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.cep
        },
        [path.uf.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.uf
        },
        [path.municipio.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.municipio
        },
        [path.bairro.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.bairro
        },
        [path.tipoLogradouro.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.tipoLogradouro
        },
        [path.logradouro.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.logradouro
        },
        [path.numero.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.numero
        },
        [path.semNumero.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.semNumero
        },
        [path.complemento.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.complemento
        },
        [path.pontoReferencia.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.pontoReferencia
        },
        [path.area.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.area
        },
        [path.microArea.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.microArea
        },
        [path.foraArea.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id !== coPaisBrasil ? null : endereco.foraArea
        },
        [path.municipioResidenciaExterior.absolutePath()]: (value: PaisSelectFieldModel, values: any) => {
          const endereco = resolveValue(values, path)
          return value?.id === coPaisBrasil ? null : endereco.municipioResidenciaExterior
        },
      },
    },
    {
      field: path.semNumero.absolutePath(),
      updates: {
        [path.numero.absolutePath()]: (value: boolean, values: any) => {
          const endereco = resolveValue(values, path)
          return value ? 'S/N' : endereco.numero === 'S/N' ? null : endereco.numero
        },
      },
    },
    {
      field: path.foraArea.absolutePath(),
      updates: {
        [path.area.absolutePath()]: (value: boolean, values: any) => {
          const endereco = resolveValue(values, path)
          return value ? null : endereco.area
        },
        [path.microArea.absolutePath()]: (value: boolean, values: any) => {
          const endereco = resolveValue(values, path)
          return value ? 'FA' : endereco.microArea === 'FA' ? null : endereco.microArea
        },
      },
    }
  )
}
