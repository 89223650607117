import { Button, ButtonProps, Icon, Tooltip } from 'bold-ui'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { useCallback, useState } from 'react'
import React from 'react'

import { FolhaRostoUltimosContatosModel } from '../model'
import { DetalhesUltimosContatosModal } from './DetalhesUltimosContatosModal'

interface DetalhesUltimosContatosButtonProps extends ButtonProps {
  contato: FolhaRostoUltimosContatosModel
  dataNascimentoCidadao: LocalDate
}

export function DetalhesUltimosContatosButton(props: DetalhesUltimosContatosButtonProps) {
  const { contato, dataNascimentoCidadao, title, onClick, ...rest } = props
  const [modalOpen, setModalOpen] = useState(false)

  const handleModalClose = useCallback(() => setModalOpen(false), [])
  const handleButtonClick = useCallback(() => setModalOpen(true), [])

  return (
    <>
      <DetalhesUltimosContatosModal
        title={`${contato.title} ${contato.dataOrTurno ? `| ${contato.dataOrTurno}` : ''}`}
        titleColor={contato.color}
        historicoAtendimento={contato.historicoAtendimento}
        dataNascimentoCidadao={dataNascimentoCidadao}
        open={modalOpen}
        onClose={handleModalClose}
      />

      <Tooltip text='Visualizar'>
        <Button size='small' skin='ghost' onClick={composeHandlers(handleButtonClick, onClick)} {...rest}>
          <Icon icon='zoomOutline' />
        </Button>
      </Tooltip>
    </>
  )
}
