/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Table, TableBody, TableFooter, TableHead, TableHeader, TableLoadingRow, TableRow } from 'bold-ui'
import { TableBox, usePagedTableProps } from 'components/table'
import theme from 'config/theme'
import { useHistoricoPeriogramaSimplificadoQuery } from 'graphql/hooks.generated'
import { HistoricoPeriogramaSimplificadoQuery, PeriogramaSimplificadoQueryInput } from 'graphql/types.generated'
import { useState } from 'react'

import { HistoricoPeriogramaSimplificadoTableRow } from './HistoricoPeriogramaSimplificadoTableRow'

export type HistoricoPeriogramaSimplificadoModel = HistoricoPeriogramaSimplificadoQuery['periogramasSimplificados']['content'][0]

interface HistoricoPeriogramaSimplificadoTableProps {
  prontuarioId: ID
}

export function HistoricoPeriogramaSimplificadoTable(props: HistoricoPeriogramaSimplificadoTableProps) {
  const { prontuarioId } = props

  const [tableState, setTableState] = useState<PeriogramaSimplificadoQueryInput>({
    prontuarioId,
    pageParams: { size: 5 },
  })

  const {
    data: { periogramasSimplificados },
    loading,
  } = useHistoricoPeriogramaSimplificadoQuery({ variables: { input: tableState } })

  const { rows, loading: isLoading, ...tableFooterProps } = usePagedTableProps({
    loading: loading,
    onChange: setTableState,
    result: periogramasSimplificados,
  })

  const isNotEmpty = rows?.length > 0

  return (
    (isNotEmpty || isLoading) && (
      <TableBox>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader
                style={css`
                  width: 7rem;
                `}
              >
                Data
              </TableHeader>
              <TableHeader>S1</TableHeader>
              <TableHeader>S2</TableHeader>
              <TableHeader>S3</TableHeader>
              <TableHeader>S4</TableHeader>
              <TableHeader>S5</TableHeader>
              <TableHeader>S6</TableHeader>
              <TableHeader
                style={css`
                  min-width: 13rem;
                `}
              >
                Profissional
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoadingRow colSpan={8} />
            ) : (
              rows.map((row) => <HistoricoPeriogramaSimplificadoTableRow key={row.id} item={row} />)
            )}
          </TableBody>
        </Table>
        <TableFooter
          {...tableFooterProps}
          style={classes.tableFooter}
          pageSize={tableState.pageParams?.size}
          sizeOptions={[5, 15, 25, 50]}
        />
      </TableBox>
    )
  )
}

const classes = {
  tableFooter: css`
    border-top: 0;
    background-color: ${theme.pallete.surface.main};
  `,
}
