import { HFlow, Icon, PagedTable, Tag, Text, Tooltip } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { usePerfilTableQuery } from 'graphql/hooks.generated'
import { PerfilTableQuery, PerfisQueryInput } from 'graphql/types.generated'
import React, { useState } from 'react'
import { tipoAcesso } from 'types/enums'
import Permissions from 'types/Permissions'
import { capitalize } from 'util/strConversor'
import { PerfilTableDropdown } from 'view/perfil/list/PerfilTableDropdown'
import { PerfilTableFilter } from 'view/perfil/list/PerfilTableFilter'

export type PerfilItem = PerfilTableQuery['perfis']['content'][0]

export default function PerfilTable() {
  const [filter, setFilter] = useState<PerfisQueryInput>({
    mostrarInativos: true,
    pageParams: {
      sort: ['nome'],
    },
  })

  const {
    data: { perfis },
    loading,
    refetch,
  } = usePerfilTableQuery({ variables: { input: filter } })

  const renderNome = (row: PerfilItem) => (
    <HFlow hSpacing={0.5}>
      <Text>{capitalize(row.nome)}</Text>
      {!row.ativo && <Tag>Inativo</Tag>}
    </HFlow>
  )

  const renderTipo = (row: PerfilItem) => <Text>{tipoAcesso[row.tipoPerfil]}</Text>

  const renderButtons = (row: PerfilItem) => (
    <>
      <Tooltip text='Visualizar'>
        <ButtonLink size='small' skin='ghost' to={`/perfil/${row.id}/visualizacao`}>
          <Icon icon='zoomOutline' />
        </ButtonLink>
      </Tooltip>

      <CheckPermission permission={Permissions.visualizarPerfis.cadastrarEditarEExcluir}>
        <Tooltip text='Editar'>
          <ButtonLink size='small' skin='ghost' to={`/perfil/${row.id}/edicao`}>
            <Icon icon='penOutline' />
          </ButtonLink>
        </Tooltip>

        <PerfilTableDropdown perfil={row} onUpdated={refetch} />
      </CheckPermission>
    </>
  )

  const tableProps = usePagedTableProps({
    result: perfis,
    loading,
    onChange: setFilter,
  })

  return (
    <TableBox header={<PerfilTableFilter initialValues={filter} onChange={setFilter} />}>
      <PagedTable<PerfilItem>
        {...tableProps}
        columns={[
          { name: 'nome', header: 'Perfil', render: renderNome, sortable: true },
          { name: 'tipoPerfil', header: 'Tipo de acesso', render: renderTipo, sortable: true },
          { name: 'botoes', render: renderButtons, style: { textAlign: 'right' } },
        ]}
      />
    </TableBox>
  )
}
