import api from 'api'
import { AcompanhamentoCidadaosVinculadosFilterInput } from 'graphql/types.generated'
import { defaultHandleError } from 'util/downloadFile'

export const downloadImpressaoAcompanhamentoCidadaosVinculados = (
  input: AcompanhamentoCidadaosVinculadosFilterInput
) => {
  return api.acompanhamentoCidadaosVinculados.imprimirAcompanhamentoCidadaosVinculados(input).catch(defaultHandleError)
}

export const downloadCSVAcompanhamentoCidadaosVinculados = (input: AcompanhamentoCidadaosVinculadosFilterInput) => {
  return api.acompanhamentoCidadaosVinculados
    .exportarCSVAcompanhamentoCidadaosVinculados(input)
    .catch(defaultHandleError)
}
