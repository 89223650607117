import { RadioGroupField, RadioGroupFieldProps } from 'components/form/field/RadioGroupField'
import { TipoAleitamentoMaternoEnum } from 'graphql/types.generated'
import React from 'react'

export type TipoAleitamentoFieldModel = TipoAleitamentoMaternoEnum

export interface TipoAleitamentoFieldProps extends Omit<RadioGroupFieldProps<TipoAleitamentoFieldModel>, 'options'> {}

export default function TipoAleitamentoField(props: TipoAleitamentoFieldProps) {
  return <RadioGroupField label='Tipo de aleitamento' options={items} {...props} />
}

const items = [
  {
    value: TipoAleitamentoMaternoEnum.EXCLUSIVO,
    label: 'Exclusivo',
  },
  {
    value: TipoAleitamentoMaternoEnum.PREDOMINANTE,
    label: 'Predominante',
  },
  {
    value: TipoAleitamentoMaternoEnum.COMPLEMENTADO,
    label: 'Complementado',
  },
  {
    value: TipoAleitamentoMaternoEnum.INEXISTENTE,
    label: 'Inexistente',
  },
]

export const tipoAleitamentoRecord: Record<TipoAleitamentoFieldModel, string> = {
  EXCLUSIVO: 'Exclusivo',
  PREDOMINANTE: 'Predominante',
  COMPLEMENTADO: 'Complementado',
  INEXISTENTE: 'Inexistente',
}
