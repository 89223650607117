/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Theme, useTheme } from 'bold-ui'
import { StreamPlayer } from 'components/stream-player'
import { useState } from 'react'

import { AcaoVideochamadaButton } from '../componentes/AcaoVideochamadaButton'
import { useConfiguracoesVideochamada } from '../hooks/useConfiguracoesVideochamada'
import { useMediaDevicesConfiguration } from '../hooks/useMediaDevicesConfiguration'
import { useUserMedia } from '../hooks/useUserMedia'
import { ConfiguracoesVideochamadaModel } from '../model-videochamada'
import { ConfiguracoesVideochamadaFormModal } from './ConfiguracoesVideochamadaFormModal'
import { MicrophoneFeedback } from './MicrophoneFeedback'

interface CameraPreviewProps {
  audioEnabled: boolean
  videoEnabled: boolean
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
}

export function CameraPreview(props: CameraPreviewProps) {
  const theme = useTheme()

  const { audioEnabled, videoEnabled, setAudioEnabled, setVideoEnabled } = props

  const [isConfiguracoesModalOpen, setConfiguracoesModalOpen] = useState(false)
  const [configuracoesVideochamada, setConfiguracoesVideochamada] = useConfiguracoesVideochamada()

  const { stream: localStream, audioDeviceAvailable, videoDeviceAvailable, mediaDevices } = useUserMedia({
    video: videoEnabled,
    audio: audioEnabled,
    videoDeviceId: configuracoesVideochamada ? configuracoesVideochamada.videoInput?.id : 'default',
    audioDeviceId: configuracoesVideochamada ? configuracoesVideochamada.audioInput?.id : 'default',
  })

  const streamAtivo = !!localStream?.getVideoTracks().length

  const styles = createStyles(theme, streamAtivo)

  const { mediaDevicesConfiguration, setStoredAudioDevice, setStoredVideoDevice } = useMediaDevicesConfiguration({
    mediaDevices,
    audioEnabled,
    videoEnabled,
  })

  const handleSetConfiguracoesVideochamada = (config: ConfiguracoesVideochamadaModel) => {
    setConfiguracoesVideochamada(config)
    setStoredAudioDevice(config.audioInput)
    setStoredVideoDevice(config.videoInput)
  }

  return (
    <div css={styles.container}>
      <ConfiguracoesVideochamadaFormModal
        open={isConfiguracoesModalOpen}
        onClose={() => setConfiguracoesModalOpen(false)}
        initialConfigurations={mediaDevicesConfiguration}
        onSubmit={handleSetConfiguracoesVideochamada}
      />
      <div css={styles.cameraAndButtons}>
        {!streamAtivo ? (
          <div css={styles.noCamera}>
            <Heading
              level={1}
              style={css`
                color: white;
              `}
            >
              Sem câmera
            </Heading>
          </div>
        ) : (
          <StreamPlayer style={styles.video} stream={localStream} playsInline muted autoPlay />
        )}
        <MicrophoneFeedback audioTrack={localStream?.getAudioTracks()[0]} styles={styles.micFeedback} />
        <HFlow style={styles.buttons}>
          <AcaoVideochamadaButton
            unavailable={!audioDeviceAvailable}
            state={audioDeviceAvailable && audioEnabled ? 'on' : 'off'}
            onClick={() => setAudioEnabled(!audioEnabled)}
            icon='mic'
          />
          <AcaoVideochamadaButton
            unavailable={!videoDeviceAvailable}
            state={videoDeviceAvailable && videoEnabled ? 'on' : 'off'}
            onClick={() => setVideoEnabled(!videoEnabled)}
            icon='video'
          />
          <AcaoVideochamadaButton state='on' onClick={() => setConfiguracoesModalOpen(true)} icon='config' />
        </HFlow>
      </div>
    </div>
  )
}

const createStyles = (theme: Theme, streamAtivo: boolean) => ({
  container: css`
    padding-top: 90%;
    position: relative;
    max-height: 19.4rem;
  `,
  cameraAndButtons: css`
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
  `,
  video: css`
    height: 100%;
    width: 100%;
    object-fit: cover;
  `,
  noCamera: css`
    height: 100%;
    background-color: ${theme.pallete.gray.c20};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  buttons: css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: ${streamAtivo ? '-3rem' : '-2.775rem'};
    z-index: 1;
    height: 0;
  `,
  micFeedback: css`
    position: absolute;
    top: 1rem;
    left: 1rem;
  `,
})
