/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Text } from 'bold-ui'
import theme from 'config/theme'
import { Fragment, useCallback, useState } from 'react'
import { EditableList } from 'view/atendimentos/detail/components/EditableList'

import { OutrosFieldModel } from './OutrosField'
import OutrosTableRow from './OutrosTableRow'

export interface OutrosTableProps {
  items?: OutrosFieldModel[]
  cidadaoDataNascimento?: LocalDate
  dataAtendimento: LocalDate
  updateItem?(item: OutrosFieldModel): void
  removeItem?(item: OutrosFieldModel): void
  readonly?: boolean
}

export default function OutrosTable(props: OutrosTableProps) {
  const { items, cidadaoDataNascimento, dataAtendimento, readonly, updateItem, removeItem } = props

  const [editingRowId, setEditingRowId] = useState<string>()

  const getItemsDisableds = useCallback(
    (currentItem: OutrosFieldModel) =>
      items
        ?.map((item) => item?.procedimentos)
        .flat()
        .filter((item) => !currentItem?.procedimentos?.map(({ id }) => id).includes(item.id)),
    [items]
  )

  return (items && items.length > 0) || readonly ? (
    <Fragment>
      <div css={classes.tableHeader}>
        <Grid>
          <Cell size={4}>
            <Text fontWeight='bold'>Local</Text>
          </Cell>
          <Cell size={6}>
            <Text fontWeight='bold'>Procedimentos</Text>
          </Cell>
          {!readonly && <Cell size={2}></Cell>}
        </Grid>
      </div>

      <EditableList
        css={css`
          border: 0;
          background-color: ${theme.pallete.primary.c100};
        `}
      >
        {items?.length > 0 ? (
          items.map((item, key) => (
            <OutrosTableRow
              item={item}
              key={key}
              cidadaoDataNascimento={cidadaoDataNascimento}
              dataAtendimento={dataAtendimento}
              procedimentosSelecionados={getItemsDisableds(item)}
              updateItem={updateItem}
              removeItem={removeItem}
              readonly={readonly}
              editingRowId={editingRowId}
              setEditingRowId={setEditingRowId}
            />
          ))
        ) : (
          <div css={classes.emptyRow}>Nenhum registro encontrado</div>
        )}
      </EditableList>
    </Fragment>
  ) : (
    <span />
  )
}

const classes = {
  tableHeader: css`
    padding: 0.5rem 1rem;
    background-color: ${theme.pallete.primary.c100};
    border-top: 1px solid ${theme.pallete.divider};
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
  emptyRow: css`
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.pallete.gray.c40};
    background-color: ${theme.pallete.primary.c100};
    text-align: center;
    font-style: italic;
    padding: 0 1rem;
  `,
}
