import { confirm } from 'components/modals/confirm'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => {
    if (message === 'esus') {
      const listener = (e) => {
        let messageData = e.data
        if (typeof messageData === 'string') {
          messageData = JSON.parse(e.data)
        }
        if (messageData.type === 'leavingConfirmation' && messageData.response === 'yes') {
          callback(true)
          window.removeEventListener('message', listener)
        }
      }
      window.addEventListener('message', listener)
      window.postMessage(
        JSON.stringify({
          type: 'URL',
          to: '/pec/user',
          force: true,
        }),
        '*'
      )
    } else {
      confirm({
        title: 'Deseja sair sem salvar?',
        body: 'As alterações realizadas serão perdidas.',
        onClose: () => callback(false),
        onCancel: () => callback(false),
        onConfirm: () => callback(true),
        confirmLabel: 'Sim, sair da tela',
        cancelLabel: 'Não, continuar aqui',
        depthLevel: 5,
        manageOverflow: false,
      })()
    }
  },
})
