/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text, TextColor, Theme, useTheme } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { Interpolation } from 'emotion'
import { useMemo } from 'react'
import { Accordion, AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'

export type AccordionInformationType = 'normal' | 'info'

interface AccordionInformationProps {
  type: AccordionInformationType
  uuid: string
  header: string
  children: React.ReactNode
}

export function AccordionInformation(props: AccordionInformationProps) {
  const { type, uuid, header, children } = props

  const theme = useTheme()
  const typeStyle = useMemo(() => createTypesStyles(theme)[type], [theme, type])

  return (
    <Accordion allowZeroExpanded>
      <AccordionItem uuid={uuid} css={typeStyle.accordionItemStyle}>
        <AccordionButton
          style={typeStyle.accordionButtonStyle}
          wrapperStyle={css`
            padding-bottom: 0;
          `}
        >
          <HFlow alignItems='center' hSpacing={0.625}>
            <Icon icon='angleDown' />
            <HFlow hSpacing={0.5} alignItems='center'>
              <Icon icon='infoCircleFilled' size={1} />
              <Text fontWeight='bold' color={typeStyle.textColor}>
                {header}
              </Text>
            </HFlow>
          </HFlow>
        </AccordionButton>
        <AccordionItemPanel
          css={css`
            padding: 0.5rem 0.5rem 0.5rem 2.8rem;
          `}
        >
          {children}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

export const createTypesStyles = (
  theme: Theme
): {
  [key in AccordionInformationType]: {
    textColor: TextColor
    accordionItemStyle: Interpolation
    accordionButtonStyle: Interpolation
  }
} => {
  return {
    normal: {
      textColor: 'normal',
      accordionItemStyle: {
        background: theme.pallete.gray.c90,
      },
      accordionButtonStyle: {
        padding: '0.25rem 0.625rem',
        margin: '0',
      },
    },
    info: {
      textColor: 'primary',
      accordionItemStyle: {
        background: theme.pallete.primary.c90,
        color: theme.pallete.primary.c40,
      },
      accordionButtonStyle: {
        padding: '0.25rem 0.625rem',
        margin: '0',
        ':hover': {
          background: theme.pallete.primary.c90,
        },
      },
    },
  }
}
