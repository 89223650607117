import { SelectField, SelectFieldProps } from 'components/form'
import { ListaEsperaTipoAtendimentoEnum } from 'graphql/types.generated'
import { Record } from 'immutable'
import React from 'react'

export interface TipoAtendimentoSelectModel {
  value: ListaEsperaTipoAtendimentoEnum
  nome: string
}

export interface TipoAtendimentoSelectFieldProps
  extends Omit<SelectFieldProps<TipoAtendimentoSelectModel>, 'items' | 'itemToString'> {
  isOnlyGarantiaAcesso?: boolean
}

export function TipoAtendimentoSelectField(props: TipoAtendimentoSelectFieldProps) {
  const { isOnlyGarantiaAcesso, ...rest } = props
  const { CONSULTA_EMULTI, ...tipoAtendimentoGarantiaAcessoRecord } = tipoAtendimentoRecord
  const items = isOnlyGarantiaAcesso ? tipoAtendimentoGarantiaAcessoRecord : tipoAtendimentoRecord
  const itemToString = (item: TipoAtendimentoSelectModel) => item && item.nome

  return (
    <SelectField<TipoAtendimentoSelectModel>
      items={Object.values(items).sort((a, b) => (a.nome > b.nome ? 1 : -1))}
      itemToString={itemToString}
      {...rest}
    />
  )
}

export const tipoAtendimentoRecord: Record<ListaEsperaTipoAtendimentoEnum, TipoAtendimentoSelectModel> = {
  [ListaEsperaTipoAtendimentoEnum.CONSULTA_MEDICA]: {
    value: ListaEsperaTipoAtendimentoEnum.CONSULTA_MEDICA,
    nome: 'Consulta médica',
  },
  [ListaEsperaTipoAtendimentoEnum.CONSULTA_ODONTOLOGICA]: {
    value: ListaEsperaTipoAtendimentoEnum.CONSULTA_ODONTOLOGICA,
    nome: 'Consulta odontológica',
  },
  [ListaEsperaTipoAtendimentoEnum.CONSULTA_ENFERMAGEM]: {
    value: ListaEsperaTipoAtendimentoEnum.CONSULTA_ENFERMAGEM,
    nome: 'Consulta de enfermagem',
  },
  [ListaEsperaTipoAtendimentoEnum.CONSULTA_EMULTI]: {
    value: ListaEsperaTipoAtendimentoEnum.CONSULTA_EMULTI,
    nome: 'Consulta com eMulti',
  },
}
