import { TipoPessoaEnum } from 'graphql/types.generated'
import React from 'react'

import { RadioGroupField, RadioGroupFieldProps, RadioOption } from './RadioGroupField'

interface TipoPessoaRadioGroupFieldProps extends Omit<RadioGroupFieldProps<TipoPessoaEnum>, 'options'> {}

export function TipoPessoaGroupField(props: TipoPessoaRadioGroupFieldProps) {
  return <RadioGroupField options={options} {...props} />
}

const options: RadioOption<TipoPessoaEnum>[] = [
  { value: TipoPessoaEnum.FISICA, label: 'Pessoa física' },
  { value: TipoPessoaEnum.JURIDICA, label: 'Pessoa jurídica' },
]
