/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Table, TableBody } from 'bold-ui'
import { isEmpty } from 'lodash'
import { useField } from 'react-final-form'
import { MetaArray } from 'util/metaPath'

import { AvaliacaoDesenvolvimentoModel } from '../model'
import TableRowAtendimentoPuericultura from './TableRowAtendimentoPuericultura'

interface AvaliacaoDesenvolvimentoTableProps {
  name: MetaArray<AvaliacaoDesenvolvimentoModel>
  usePrimaryColors: boolean
}

AvaliacaoDesenvolvimentoTable.defaultProps = {
  usePrimaryColors: false,
}

export default function AvaliacaoDesenvolvimentoTable(props: AvaliacaoDesenvolvimentoTableProps) {
  const { name, usePrimaryColors } = props

  const {
    input: { value: rows },
  } = useField<AvaliacaoDesenvolvimentoModel[]>(name.absolutePath())

  if (isEmpty(rows)) return null

  return (
    <Table>
      <TableBody>
        {rows.map((item: AvaliacaoDesenvolvimentoModel, index: number) => (
          <TableRowAtendimentoPuericultura
            key={item.id}
            name={name}
            item={item}
            index={index}
            usePrimaryColors={usePrimaryColors}
          />
        ))}
      </TableBody>
    </Table>
  )
}
