import { VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import React from 'react'
import { AuditoriaForm } from 'view/auditoria/AuditoriaForm'

import AuditoriaHistoricoTable from './AuditoriaHistoricoTable'

export function AuditoriaView() {
  return (
    <>
      <Breadcrumb title='Auditoria' />
      <PageHeaderSection title='Auditoria' />

      <PageContent type='filled'>
        <VFlow vSpacing={1.5}>
          <AuditoriaForm />
          <AuditoriaHistoricoTable />
        </VFlow>
      </PageContent>
    </>
  )
}
