import { TableFooter, useTheme } from 'bold-ui'
import { PageInfo, PageParams } from 'graphql/types.generated'
import React, { useEffect } from 'react'

interface ListaAtendimentoFooterProps {
  pageInfo: PageInfo
  pageParams: PageParams
  onChange(values: PageParams): void
}

export function ListaAtendimentoFooter(props: ListaAtendimentoFooterProps) {
  const theme = useTheme()

  const handlePageChange = (page: number) => {
    props.onChange({
      ...props.pageParams,
      page,
    })
  }

  const handleSizeChange = (size: number) => {
    props.onChange({
      ...props.pageParams,
      size,
      page: 0,
    })
  }

  useEffect(() => {
    // Se a página atual não possuír elementos ir para página anterior
    if (props.pageParams?.page !== 0 && props.pageInfo?.numberOfElements === 0) {
      props.onChange({
        ...props.pageParams,
        page: props.pageInfo.number > 0 ? props.pageInfo.number-- : 0,
      })
    }
  }, [props, props.pageInfo])

  if (props.pageInfo?.totalElements === 0) {
    return null
  }

  return (
    <TableFooter
      totalElements={props.pageInfo?.totalElements}
      totalPages={props.pageInfo?.totalPages}
      page={props.pageInfo?.number}
      onPageChange={handlePageChange}
      onSizeChange={handleSizeChange}
      pageSize={props.pageParams?.size}
      style={{ backgroundColor: theme.pallete.surface.main }}
    />
  )
}
