/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import React from 'react'

interface UnidadeResponsavelLabelProps {
  nomeUnidadeSaude: string
}

export const UnidadeResponsavelLabel = React.memo((props: UnidadeResponsavelLabelProps) => {
  const { nomeUnidadeSaude } = props

  return (
    <VFlow vSpacing={0}>
      <Text style={styles.label} fontWeight='bold'>
        Unidade responsável
      </Text>
      {nomeUnidadeSaude.titleCase()}
    </VFlow>
  )
})

const styles = {
  label: css`
    display: block;
    margin-bottom: 0.5rem;
  `,
}
