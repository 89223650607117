import { Alert, Heading, HFlow, Text, VFlow } from 'bold-ui'
import React from 'react'

import { Carousel, CarouselItem } from './carousel/Corousel'
import { NewsGif } from './NewsGif'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsImg } from './NewsImg'

export function NewsInternalPanelCincoZero() {
  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow vSpacing={1}>
      <Text fontSize={0.875} fontWeight='bold'>
        Módulos com novidades:{' '}
      </Text>
      <HFlow hSpacing={5}>
        <VFlow vSpacing={0.2}>
          <Text fontSize={0.875} component='li'>
            Acompanhamentos
          </Text>
          <Text fontSize={0.875} component='li'>
            Alergias
          </Text>
          <Text fontSize={0.875} component='li'>
            Antecedentes
          </Text>
          <Text fontSize={0.875} component='li'>
            Atendimentos
          </Text>
          <Text fontSize={0.875} component='li'>
            Atendimento odontológico
          </Text>
        </VFlow>
        <VFlow vSpacing={0.2}>
          <Text component='li' fontSize={0.875}>
            Atestados
          </Text>
          <Text fontSize={0.875} component='li'>
            Encaminhamentos e orientações
          </Text>
          <Text fontSize={0.875} component='li'>
            Escuta inicial / pré-atendimento
          </Text>
          <Text fontSize={0.875} component='li'>
            Exames
          </Text>
          <Text fontSize={0.875} component='li'>
            Histórico clínico do cidadão
          </Text>
        </VFlow>
        <VFlow vSpacing={0.2}>
          <Text fontSize={0.875} component='li'>
            Medicamentos
          </Text>
          <Text fontSize={0.875} component='li'>
            Pré-natal / puerpério
          </Text>
          <Text fontSize={0.875} component='li'>
            Prontuário / folha de rosto
          </Text>
          <Text fontSize={0.875} component='li'>
            Vacinação
          </Text>
        </VFlow>
      </HFlow>
      <NewsHeadingSection title='Acompanhamentos' id='acompanhamentos-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! Agora a lateral do atendimento irá mostrar as informações dos acompanhamentos específicos do
            cidadão. <br></br> <br></br>
          </Text>
          <Heading level={5}>Acompanhamento da criança:</Heading>
          <Text fontSize={0.875} component='li'>
            Marcos de desenvolvimento integrados ao SOAP, possibilitando que os profissionais consultem outras
            informações durante a avaliação.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_acompanhamento_crianca.gif'
            pausedSrc='/static/novidades/5-0_acompanhamento_crianca_paused.png'
            alt='Gif mostrando o acompanhamento da criança'
          />{' '}
          <br></br>
          <Heading level={4}>Acompanhamento de pré-natal:</Heading>
          <Text fontSize={0.875} component='li'>
            Apresentação de todas as informações que antes estavam na tela de acompanhamento. Risco da gravidez, DPPs,
            IGs, DUM, consultas de pré-natal realizadas e a última consulta odontológica feita durante a gestação serão
            informações acessadas rapidamente pelos profissionais através da lateral.
          </Text>
          <Text fontSize={0.875} component='li'>
            Medições antropológicas com as curvas de referência durante a fase de gestação.
          </Text>
          <Text fontSize={0.875} component='li'>
            Medições específicas do atendimento de pré-natal apresentadas na modal de medições em uma aba separada
            juntamente com o gráfico de referência de altura uterina.{' '}
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-0_acompanhamento_pre_natal_soap.png'
              alt='Acompanhamento de pré-natal no SOAP'
            />
            <CarouselItem
              image='/static/novidades/5-0_acompanhamento_pre_natal_medicoes.png'
              alt='Acompanhamento de pré-natal nas medições'
            />
            <CarouselItem
              image='/static/novidades/5-0_acompanhamento_pre_natal_card.png'
              alt='Acompanhamento de pré-natal na barra lateral'
            />
          </Carousel>
          <Heading level={4}>Acompanhamento do idoso:</Heading>
          <Text fontSize={0.875} component='li'>
            Apresentação da data da última avaliação multidimensional feita para os cidadãos com 60 anos ou mais.
          </Text>
          <Text fontSize={0.875} component='li'>
            Apresentação de um alerta quando 5 ou mais medicamentos de uso contínuo estiverem ativos no cadastro do
            cidadão.
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-0_acompanhamento_idoso_soap.png'
              alt='Acompanhamento do idoso no SOAP'
            />
            <CarouselItem
              image='/static/novidades/5-0_acompanhamento_idoso_card.png'
              alt='Acompanhamento do idoso na barra lateral'
            />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Alergias' id='alergias-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_alergia_antes.png' alt='Módulo de alergias antigo' />
            <CarouselItem image='/static/novidades/5-0_alergia_depois.png' alt='Módulo de alergias novo' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            O bloco para registrar a evolução de alergias estará sempre disponível na Avaliação do SOAP.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_alergia.gif'
            pausedSrc='/static/novidades/5-0_alergia_paused.png'
            alt='Gif da avaliação de alergias'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Antecedentes' id='antecedentes-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_antecedentes_antes.png' alt='antecedentes nas versões antigas' />
            <CarouselItem image='/static/novidades/5-0_antecedentes_depois.png' alt='antecedentes na nova versão' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Nova forma de cadastrar os antecedentes pessoais. Agora, o registro dos antecedentes é direto na Lista de
            Problemas e Condições através do botão "Adicionar antecedente resolvido".
          </Text>
          <NewsGif
            src='/static/novidades/5-0_antecedentes_pessoal.gif'
            pausedSrc='/static/novidades/5-0_antecedentes_pessoal_paused.png'
            alt='gif mostrando os antecedentes pessoais'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Antecedentes familiares em uma área exclusiva, com novo campo de observações para cada registro.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_antecedentes_familiar.gif'
            pausedSrc='/static/novidades/5-0_antecedentes_familiar_paused.png'
            alt='gif mostrando os antecedentes familiares'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Novo grupo de antecedentes hospitalares para cadastro de cirurgias e internações, com novos campos para data
            e idade.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_antecedentes_cirurgias.gif'
            pausedSrc='/static/novidades/5-0_antecedentes_cirurgias_paused.png'
            alt='gif mostrando os antecedentes hospitalares'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Novo botão para acompanhamento da criança, exibido após o cidadão completar 19 anos. Antes, ao atingir essa
            idade, a informação não era mais acessível. Agora, é possível através dos antecedentes.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_antecedentes_acompanhamento.gif'
            pausedSrc='/static/novidades/5-0_antecedentes_acompanhamento_paused.png'
            alt='gif mostrando o acompanhamento da criança'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Atendimentos' id='atendimentos-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface com estrutura de informações na lateral do atendimento! Facilita o acesso à informações do
            prontuário que podem ser relevantes durante um atendimento. <br />
            São agrupadas em: Alergias/Reações adversas, Problemas/condições, Medições e gráficos, Medicamentos em uso,
            Lembretes, Resultados de exames, Condições autorreferidas e Acompanhamentos de pré-natal, do idoso e da
            criança.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_atendimento_estrutura.gif'
            pausedSrc='/static/novidades/5-0_atendimento_estrutura_paused.png'
            alt='Gif mostrando a nova estrutura da tela de Atendimento'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Pesquisa na Avaliação de problemas e condições que estão ativos ou latentes na Lista de Problema/Condições
            do cidadão, facilitando o registro da evolução.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_atendimento_pesquisa.gif'
            pausedSrc='/static/novidades/5-0_atendimento_pesquisa_paused.png'
            alt='Gif mostrando as novas opções de pesquisa durante um atendimento'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Visualização das listas de Problemas e Condições e de Alergias/Reações adversas do cidadão no Atendimento de
            Procedimentos.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_atendimento_procedimentos.gif'
            pausedSrc='/static/novidades/5-0_atendimento_procedimentos_paused.png'
            alt='Gif mostrando o novo Atendimento de Procedimentos'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Atendimento odontológico' id='atendimento-odonto-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo está em um novo formato, que une a Evolução Odontológica do sistema antigo com a
            inserção de Problemas e Condições nos dentes. Confira nas imagens abaixo as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_odonto_arcada_antes.jpeg' alt='Arcada antiga' />
            <CarouselItem image='/static/novidades/5-0_odonto_arcada_depois.png' alt='Arcada nova' />
            <CarouselItem image='/static/novidades/5-0_odonto_dentes_antes.jpeg' alt='Dentes antigo' />
            <CarouselItem image='/static/novidades/5-0_odonto_dentes_depois.png' alt='Dentes novo' />
            <CarouselItem image='/static/novidades/5-0_odonto_sextante_antes.jpeg' alt='Sextante antigo' />
            <CarouselItem image='/static/novidades/5-0_odonto_sextante_depois.png' alt='Sextante novo' />
            <CarouselItem image='/static/novidades/5-0_odonto_outros_antes.jpeg' alt='Outros antigo' />
            <CarouselItem image='/static/novidades/5-0_odonto_outros_depois.png' alt='Outros novo' />
            <CarouselItem image='/static/novidades/5-0_odonto_tecidos_molese_duros.png' alt='Tecidos moles e duros' />
            <CarouselItem
              image='/static/novidades/5-0_odonto_registro_periodontal_simplificado.png'
              alt='Registro periodontal simplificado'
            />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Novos campos "Possui aparelho" e "Possui contenção";
          </Text>
          <Text fontSize={0.875} component='li'>
            Botão "Mostrar dentes decíduos" para ativar ou desativar a visualização dos dentes;
          </Text>
          <Text fontSize={0.875} component='li'>
            Registro de procedimentos em dentes supranumerários através de um botão abaixo do odontograma;
          </Text>
          <NewsGif
            src='/static/novidades/5-0_odonto_odontograma.gif'
            pausedSrc='/static/novidades/5-0_odonto_odontograma_paused.png'
            alt='Gif do registro de dentes supranumerarios no odontograma'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Nova aba "Tecidos duros e moles", onde é possível fazer a análise de outras parte da boca que não sejam os
            dentes;
          </Text>
          <NewsGif
            src='/static/novidades/5-0_odonto_tecidos_moles.gif'
            pausedSrc='/static/novidades/5-0_odonto_tecidos_moles_paused.png'
            alt='Gif da nova aba de tecidos moles e duros'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Nova aba "Periodontia", onde é possível fazer o Registro de procedimentos para sextantes, Registro
            Periodontal Simplificado e Periograma Completo.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_odonto_periograma_completo.gif'
            pausedSrc='/static/novidades/5-0_odonto_periograma_completo_paused.png'
            alt='Gif da nova aba de periodontia'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Atestados' id='atestados-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_atestado_padrao_antes.png' alt='Atestado padrão antigo' />
            <CarouselItem image='/static/novidades/5-0_atestado_padrao_depois.png' alt='Atestado padrão novo' />
            <CarouselItem image='/static/novidades/5-0_atestado_branco_antes.png' alt='Atestado em branco antigo' />
            <CarouselItem image='/static/novidades/5-0_atestado_branco_depois.png' alt='Atestado em branco novo' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Cadastro de modelos de atestado para otimizar o tempo dos profissionais que usam atestados diferentes do
            modelo padrão.
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-0_atestado_modelo_cadastro.png'
              alt='Cadastro de modelo de atestado'
            />
            <CarouselItem image='/static/novidades/5-0_atestado_modelo_exemplo.png' alt='Exemplo de atestado modelo' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Encaminhamentos e orientações' id='encaminhamento-orientacoes-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! Os módulos foram redesenhados e agora está ainda mais fácil de usar. Confira nas imagens
            abaixo as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_orientacoes_antes.png' alt='Orientacoes antigo' />
            <CarouselItem image='/static/novidades/5-0_orientacoes_depois.png' alt='Orientacoes novo' />
            <CarouselItem image='/static/novidades/5-0_encaminhamentos_antes.png' alt='Encaminhamentos antigo' />
            <CarouselItem image='/static/novidades/5-0_encaminhamentos_depois.png' alt='Encaminhamentos novo' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Escuta inicial / pré-atendimento' id='escuta-inicial-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_escuta_inicial_antes.png' alt='Escuta inicial antiga' />
            <CarouselItem image='/static/novidades/5-0_escuta_inicial_depois.png' alt='Escuta inicial nova' />
            <CarouselItem image='/static/novidades/5-0_escuta_inicial_antes_2.png' alt='Escuta inicial antiga' />
            <CarouselItem image='/static/novidades/5-0_escuta_inicial_depois_2.png' alt='Escuta inicial nova' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Exames' id='exames-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_exames_resultado_antes.jpeg' alt='Resultado de exames antigo' />
            <CarouselItem image='/static/novidades/5-0_exames_resultado_depois.png' alt='Resultado de exames novo' />
            <CarouselItem
              image='/static/novidades/5-0_exames_solicitar_comum_antes.jpeg'
              alt='Solicitação de exame comum antigo'
            />
            <CarouselItem
              image='/static/novidades/5-0_exames_solicitar_comum_depois.png'
              alt='Solicitação de exame comum novo'
            />
            <CarouselItem
              image='/static/novidades/5-0_exames_solicitar_alto_custo_antes.png'
              alt='Solicitação de alto custo antigo'
            />
            <CarouselItem
              image='/static/novidades/5-0_exames_solicitar_alto_custo_depois.png'
              alt='Solicitação de alto custo novo'
            />
            <CarouselItem image='/static/novidades/5-0_exames_solicitados_antes.jpeg' alt='Exame solicitado antigo' />
            <CarouselItem image='/static/novidades/5-0_exames_solicitados_depois.png' alt='Exame solicitado novo' />
            <CarouselItem image='/static/novidades/5-0_exames_historico_antes.jpeg' alt='Historico de exames antigo' />
            <CarouselItem image='/static/novidades/5-0_exames_historico_depois.png' alt='Historico de exames novo' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            No histórico de solicitações de exames, é possível imprimir uma solicitação em até 30 dias e replicar todos
            os exames de uma solicitação antiga.
          </Text>
          <Text fontSize={0.875} component='li'>
            Os resultados de exames com e sem solicitação são registrados no mesmo local. A interface foi otimizada para
            inserir todos os resultados de exames no mesmo momento.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_exames_registro_resultado.gif'
            pausedSrc='/static/novidades/5-0_exames_registro_resultado_paused.png'
            alt='Gif da mostrando os resultados de exames'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            O histórico de resultados de exames está localizado no menu lateral e permite a comparação de vários
            resultados do mesmo exame;
          </Text>
          <NewsGif
            src='/static/novidades/5-0_exames_historico_registros.gif'
            pausedSrc='/static/novidades/5-0_exames_historico_registros_paused.png'
            alt='Gif mostrando o historico de resultados de exame'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Histórico clínico do cidadão' id='historico-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado para facilitar a visualização dos diversos tipos de históricos e
            das principais informações indicadas pelos profissionais através de etiquetas (tags), sem ser necessário
            abrir os detalhamentos de cada histórico.
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_historico_antes.png' alt='Histórico antigo' />
            <CarouselItem image='/static/novidades/5-0_historico_depois.png' alt='Histórico novo' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Implementação de novos filtros para a localização mais refinada dos históricos dos atendimentos.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_historico_filtros.gif'
            pausedSrc='/static/novidades/5-0_historico_filtros_paused.png'
            alt='Gif dos filtros do histórico'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Otimização na performance do sistema ao trazer os resultados na listagem do histórico clínico.
          </Text>
          <Text fontSize={0.875} component='li'>
            Impressão individual e em lote do histórico, facilitando sua ação.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_historico_impressao.gif'
            pausedSrc='/static/novidades/5-0_historico_impressao_paused.png'
            alt='Gif da impressão de histórico'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Visualização do detalhamento de cada histórico com design que facilita a leitura das informações,
            disponibilizadas na própria listagem ao expandir o item.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_historico_visualizacao.gif'
            pausedSrc='/static/novidades/5-0_historico_visualizacao_paused.png'
            alt='Gif da visualização do historico'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Exclusão de um atendimento do tipo Vacinação.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_historico_exclusao.gif'
            pausedSrc='/static/novidades/5-0_historico_exclusao_paused.png'
            alt='Gif da exclusão de historico'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Medicamentos' id='medicamentos-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_medicamentos_antes.png' alt='Medicamentos antigo' />
            <CarouselItem image='/static/novidades/5-0_medicamentos_depois.png' alt='Medicamentos novo' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Impressão pode ser realizada na mesma tela que está sendo prescrito o medicamento, sendo possível imprimir
            todos medicamentos daquela receita com um único clique.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_medicamentos_impressao.gif'
            pausedSrc='/static/novidades/5-0_medicamentos_impressao_paused.png'
            alt='Gif da impressão de medicamentos'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Histórico das prescrições exibido na listagem em um único lugar com um design mais prático e moderno.
          </Text>
          <Text fontSize={0.875} component='li'>
            Pesquisa de um medicamento já prescrito, com opção de incluir os medicamentos de uso contínuo.{' '}
          </Text>
          <NewsGif
            src='/static/novidades/5-0_medicamentos_pesquisa.gif'
            pausedSrc='/static/novidades/5-0_medicamentos_pesquisa_paused.png'
            alt='Gif da pesquisa de medicamentos'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Interrupção de um tratamento, bem como cancelar a interrupção.{' '}
          </Text>
          <NewsGif
            src='/static/novidades/5-0_medicamentos_interrupcao.gif'
            pausedSrc='/static/novidades/5-0_medicamentos_interrupcao_paused.png'
            alt='Gif da interrupção de um tratamento'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Replicação do receituário completo ou apenas de um medicamento prescrito em uma receita.{' '}
          </Text>
          <NewsGif
            src='/static/novidades/5-0_medicamentos_replicar.gif'
            pausedSrc='/static/novidades/5-0_medicamentos_replicar_paused.png'
            alt='Gif da replicação de uma receita'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Pré-natal / puerpério' id='pre-natal-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_pre_natal_antes.png' alt='Pre-natal nas versões antigas' />
            <CarouselItem image='/static/novidades/5-0_pre_natal_depois.png' alt='Pre-natal na nova versão' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Pré-natal só pode ser iniciado a partir da avaliação do SOAP. Condições de pré-natal lançadas diretamente na
            Lista de Problemas/Condições não são consideradas como Atendimento Individual com pré-natal.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_pre_natal_novo.gif'
            pausedSrc='/static/novidades/5-0_pre_natal_novo_paused.png'
            alt='Gif do novo pre-natal'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Prontuário / folha de rosto' id='prontuario-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image='/static/novidades/5-0_folha_rosto_antes.png' alt='Folha de rosto antiga' />
            <CarouselItem image='/static/novidades/5-0_folha_rosto_depois.png' alt='Folha de rosto nova' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Através de cards, visualização de informações de Escuta Inicial/Pré-atendimento, Últimos contatos,
            Antecedentes, Medições, Vacinação, Problemas/Condições autorreferidas, Lembretes, Alergias/Reações adversas,
            Lista de problemas/condições, Resultados de exames, Medicamentos. Os cards são clicáveis e exibem mais
            informações ao serem abertos.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_folha_rosto_atendimento.gif'
            pausedSrc='/static/novidades/5-0_folha_rosto_atendimento_paused.png'
            alt='Gif da folha de rosto durante o atendimento'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Acesso à Folha de Rosto, Histórico e Vacinação a partir da visualização do prontuário. Na Folha de Rosto da
            visualização do prontuário há também informações do Plano, incluindo as evoluções odontológicas.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_folha_rosto_prontuario.gif'
            pausedSrc='/static/novidades/5-0_folha_rosto_prontuario_paused.png'
            alt='Gif da folha de rosto durante a visualização do prontuario'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Vacinação' id='vacinacao-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova funcionalidade de aprazamento automático de doses.
          </Text>
          <NewsGif
            src='/static/novidades/5-0_vacinacao_aprazamento.gif'
            pausedSrc='/static/novidades/5-0_vacinacao_aprazamento_paused.png'
            alt='Gif do aprazamento automatico de doses'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Visualização de informações da vacina na folha de rosto.
          </Text>
          <NewsImg
            src='/static/novidades/5-0_vacina_folha_rosto.png'
            alt='Imagem da visualização de informações de vacina na folha de rosto'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
