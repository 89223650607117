import { partition } from 'lodash'

/**
 * Filtra uma lista e retorna um objeto contendo
 * os itens que são recentes e os itens que são de
 * registro anterior.
 * @param items Uma lista de objetos
 * @param key Nome da propriedade que define um registro anterior
 * @returns {{recentes: T[], registroAnterior: T[]}} Objeto com uma lista de registros
 * recentes e uma de registros anteriores
 */

export const filterByRegistroAnterior = <T>(items: T[], key: string): { recentes: T[]; registroAnterior: T[] } => {
  const [registroAnterior, recentes] = partition(items, (item) => item[key])
  return { recentes, registroAnterior }
}
