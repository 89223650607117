import { Alert, Button, HFlow, InfoLabel, Text, Tooltip, VFlow } from 'bold-ui'
import { CheckboxField, EquipeSelectField, EquipeSelectModel, Form, FormRenderProps } from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'
import theme from 'config/theme'
import createCalculator from 'final-form-calculate'
import { PerguntaSaidaCadastroEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { MetaPath, metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, required, ValidateFunction } from 'util/validation'

import { VincularEquipeFormModel } from '../../types/CidadaoFormModel'
import {
  EstadoComponenteVinculo,
  UsuarioLogadoVinculacao,
  VinculacaoFichaCadastroIndividual,
} from './VincularCidadaoComponentRoot'

interface VincularCidadaoEditProps {
  name: MetaPath<VincularEquipeFormModel>
  values: VincularEquipeFormModel
  setComponentState(state: EstadoComponenteVinculo): void
  cadastroIndividualValues: VinculacaoFichaCadastroIndividual
  possuiEquipe: boolean
  usuarioLogado: UsuarioLogadoVinculacao
}

export const validate = (possuiEquipe: boolean) => {
  return createValidator<VincularEquipeFormModel>({}, valideteVinculacao(possuiEquipe))
}

function valideteVinculacao(possuiEquipe: boolean): ValidateFunction<VincularEquipeFormModel> {
  return (values: VincularEquipeFormModel, errors: ErrorObject<VincularEquipeFormModel>) => {
    if (!values) {
      return errors
    }

    if (possuiEquipe && !values.equipe?.nome && values.cadastroIndividualCheck !== true) {
      errors.equipe = required(errors.equipe)
    }

    return errors
  }
}

export const meta = metaPath<VincularEquipeFormModel>()

const createVinculacaoCalculator = (
  vinculacaoFicha: EquipeSelectModel,
  unidadeSaudeFicha: string,
  unidadeSaudeUsuarioLogado: string,
  perguntaSaidaCadastroEnum: PerguntaSaidaCadastroEnum,
  possuiEquipe: boolean
) =>
  createCalculator({
    field: meta.cadastroIndividualCheck.absolutePath(),
    updates: {
      [meta.equipe.absolutePath()]: (value: boolean) => {
        return value && !perguntaSaidaCadastroEnum && vinculacaoFicha
      },
      [meta.unidadeSaude.absolutePath()]: (value: boolean) => {
        if (value) {
          if (perguntaSaidaCadastroEnum) {
            return undefined
          } else {
            return unidadeSaudeFicha
          }
        }
        return unidadeSaudeUsuarioLogado
      },
    },
  })

export const VincularCidadaoEdit = (props: VincularCidadaoEditProps) => {
  const { values, cadastroIndividualValues, possuiEquipe } = props
  const formPaiProps = usePecField({ ...props })

  const decoratorVinculacao = useMemo(
    () =>
      createVinculacaoCalculator(
        {
          id: cadastroIndividualValues?.equipe?.id,
          nome: cadastroIndividualValues?.equipe?.nome,
          ine: cadastroIndividualValues?.equipe?.ine,
          tipoEquipe: {
            sigla: cadastroIndividualValues?.equipe?.tipoEquipe?.sigla,
            nome: cadastroIndividualValues?.equipe?.tipoEquipe?.nome,
            id: cadastroIndividualValues?.equipe?.tipoEquipe?.id,
          },
        },
        cadastroIndividualValues?.unidadeSaude?.nome,
        props.usuarioLogado.unidadeSaude.nome,
        cadastroIndividualValues?.perguntaSaidaCadastroEnum,
        possuiEquipe
      ),
    [cadastroIndividualValues, possuiEquipe, props.usuarioLogado.unidadeSaude.nome]
  )

  const handleSalvarClicked = (formRenderProps: FormRenderProps<VincularEquipeFormModel>) => {
    const formValues = formRenderProps.values

    const saveConfirm = () => {
      formRenderProps.handleSubmit()
    }

    const saveConfirmClear = () => {
      formRenderProps.form.change(meta.equipe.absolutePath(), undefined)
      formRenderProps.form.change(meta.unidadeSaude.absolutePath(), undefined)

      formRenderProps.handleSubmit()
    }

    if (formValues.cadastroIndividualCheck) {
      cadastroIndividualValues?.perguntaSaidaCadastroEnum ===
        PerguntaSaidaCadastroEnum.SAIDA_CIDADAO_MUDANCA_TERRITORIO &&
        confirm({
          title: 'Deseja utilizar a informação do cadastro individual?',
          body: (
            <Text>
              O cadastro mais atual do cidadão possui a informação de <b>Mudança de território</b>. Ao escolher
              "Utilizar a informação do cadastro individual do cidadão", o cidadão será automaticamente vinculado à
              equipe do profissional que realizar um novo cadastro individual para o cidadão.
            </Text>
          ),
          cancelLabel: 'Cancelar',
          confirmLabel: 'Concluir mesmo assim',
          onConfirm: () => saveConfirmClear(),
        })()

      cadastroIndividualValues?.perguntaSaidaCadastroEnum === PerguntaSaidaCadastroEnum.SAIDA_CIDADAO_OBITO &&
        confirm({
          title: 'Deseja utilizar a informação do cadastro individual?',
          body: (
            <Text>
              O cadastro mais atual do cidadão possui <b>óbito</b> informado. Ao escolher "Utilizar a informação do
              cadastro individual do cidadão", o cidadão será automaticamente vinculado à equipe do profissional que
              realizar um novo cadastro individual para o cidadão.
            </Text>
          ),
          cancelLabel: 'Cancelar',
          confirmLabel: 'Concluir mesmo assim',
          onConfirm: () => saveConfirmClear(),
        })()

      if (!formValues.unidadeSaude && !cadastroIndividualValues?.perguntaSaidaCadastroEnum) {
        confirm({
          title: 'Deseja utilizar a informação do cadastro individual?',
          body: (
            <Text>
              O cidadão não possui cadastro individual. Ao marcar a opção "Utilizar informação do cadastro individual do
              cidadão", o cidadão será automaticamente vinculado à equipe do profissional que realizar um novo cadastro
              individual para o cidadão.
            </Text>
          ),
          cancelLabel: 'Cancelar',
          confirmLabel: 'Concluir mesmo assim',
          onConfirm: () => saveConfirmClear(),
        })()
      }

      if (formValues.unidadeSaude && !cadastroIndividualValues?.perguntaSaidaCadastroEnum) {
        saveConfirm()
      }
    } else {
      saveConfirm()
    }
  }

  const handleCancelarClicked = (formRenderProps: FormRenderProps<VincularEquipeFormModel>) => {
    const formValues = formRenderProps.values

    if (
      formValues.equipe?.nome !== props.values.equipe?.nome ||
      formValues.cadastroIndividualCheck !== props.values.cadastroIndividualCheck ||
      formValues.unidadeSaude !== props.values.unidadeSaude
    ) {
      confirm({
        title: 'Deseja cancelar a edição?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar edição',
        confirmLabel: 'Sim, cancelar edição',
        onConfirm: () =>
          props.values.cadastroIndividualCheck || props.values.equipe?.nome || props.values.unidadeSaude
            ? props.setComponentState('VINCULO_CADASTRADO')
            : props.setComponentState('SEM_VINCULO'),
      })()
    } else {
      props.values.cadastroIndividualCheck || props.values.equipe?.nome || props.values.unidadeSaude
        ? props.setComponentState('VINCULO_CADASTRADO')
        : props.setComponentState('SEM_VINCULO')
    }
  }

  const handleSubmit = (formValues: VincularEquipeFormModel) => {
    formPaiProps.input.onChange(formValues)
    props.setComponentState('VINCULO_CADASTRADO')
  }

  const renderForm = (formRenderProps: FormRenderProps<VincularEquipeFormModel>) => {
    const formValues = formRenderProps.values

    formValues.unidadeSaude = formValues.cadastroIndividualCheck
      ? cadastroIndividualValues?.unidadeSaude
      : props.usuarioLogado.unidadeSaude

    return (
      <VFlow
        style={{
          border: `1px solid ${theme.pallete.divider}`,
          padding: '1rem',
        }}
        vSpacing={1}
      >
        <Text fontWeight='bold'> Vincular equipe responsável</Text>
        <Alert type='info' inline>
          Se a opção <b>Utilizar a informação do cadastro individual do cidadão</b> estiver marcada, a equipe
          responsável será sempre a última a atualizar o cadastro individual do cidadão. Se a opção estiver desmarcada,
          o vínculo será alterado apenas manualmente.
        </Alert>

        <CheckboxField
          name={meta.cadastroIndividualCheck}
          label='Utilizar informação do cadastro individual do cidadão'
        />

        <HFlow justifyContent='space-between'>
          <InfoLabel title='Unidade de saúde responsável'>
            <Text>{formValues.unidadeSaude?.nome?.titleCase()}</Text>
          </InfoLabel>

          <Tooltip
            text={
              !possuiEquipe && !formValues.cadastroIndividualCheck
                ? 'Não existem equipes cadastradas nessa unidade'
                : formValues.cadastroIndividualCheck && 'Utilizando informação do cadastro individual do cidadão'
            }
          >
            <EquipeSelectField
              name={meta.equipe}
              unidadeSaudeId={props.usuarioLogado.unidadeSaude.id}
              label='Equipe responsável'
              required
              style={{ width: '21rem' }}
              disabled={formValues.cadastroIndividualCheck || !possuiEquipe}
            />
          </Tooltip>
        </HFlow>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' size='small' onClick={() => handleCancelarClicked(formRenderProps)}>
            Cancelar
          </Button>
          <Button kind='primary' size='small' onClick={() => handleSalvarClicked(formRenderProps)}>
            Salvar
          </Button>
        </HFlow>
      </VFlow>
    )
  }
  return (
    <Form<VincularEquipeFormModel>
      onSubmit={handleSubmit}
      render={renderForm}
      validate={validate(possuiEquipe)}
      initialValues={values}
      decorators={[decoratorVinculacao]}
    />
  )
}
