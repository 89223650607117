import useSession from 'components/auth/useSession'
import { PageLoading } from 'components/loading'
import { useAtendimentoViewQuery } from 'graphql/hooks.generated'
import { StatusAtendimento } from 'graphql/types.generated'
import React from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'

import { isResponsavelAtendimentoProfissional } from '../util'
import { AtendimentoVacinacaoView } from './AtendimentoVacinacaoView'

interface AtendimentoVacinacaoViewParams {
  atendimentoId: ID
}

export const AtendimentoVacinacaoRootView = () => {
  const { atendimentoId } = useParams<AtendimentoVacinacaoViewParams>()
  const { data, loading } = useAtendimentoViewQuery({ variables: { id: atendimentoId } })
  const { data: sessao } = useSession()

  if (loading) {
    return <PageLoading message='Carregando atendimento...' />
  } else if (
    !data?.atendimento?.atendimentoProfissional ||
    data?.atendimento.statusAtendimento !== StatusAtendimento.EM_ATENDIMENTO
  ) {
    return <Redirect to='/404' />
  } else if (!isResponsavelAtendimentoProfissional(sessao.acesso, data?.atendimento?.atendimentoProfissional)) {
    return <Redirect to='/403' />
  } else {
    return <AtendimentoVacinacaoView atendimentoId={atendimentoId} />
  }
}
