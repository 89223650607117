import { css } from '@emotion/core'
import { Button, Cell, DataTable, Grid, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { TitleGroup } from 'components/TitleGroup'
import { useUltimosCincoArquivosQuery } from 'graphql/hooks.generated'
import { ArquivoIdentificadorEnum, ArquivoStatusEnum, StatusArquivo } from 'graphql/types.generated'
import { useState } from 'react'
import React from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { downloadArquivo } from 'view/arquivo/downloadsArquivo'
import { ArquivoModel, relatoriosRecentesRecord } from 'view/arquivo/model'
import StatusArquivoLabel from 'view/arquivo/StatusArquivoLabel'

import { ImportacaoCidadaoListener } from './ImportacaoCidadaoListener'

export const IMPORTAR_CIDADAO_PATH = 'importar-cidadao'

export function UltimasImportacoesCidadaoTable({ municipio }) {
  const { fileName } = relatoriosRecentesRecord[ArquivoIdentificadorEnum.RELATORIO_IMPORTACAO_CIDADAOS]

  const { data, loading, refetch } = useUltimosCincoArquivosQuery({
    variables: {
      input: ArquivoIdentificadorEnum.RELATORIO_IMPORTACAO_CIDADAOS,
    },
  })

  const [mostrarMais, setMostrarMais] = useState<boolean>(false)

  const theme = useTheme()
  const styles = createStyles(theme)

  const handleDownload = (item: StatusArquivo) => {
    if (item.status === ArquivoStatusEnum.PRONTO) downloadArquivo(item.id, fileName, item.formato.toLocaleLowerCase())
  }

  const ultimosCincoArquivos = !loading ? data?.ultimosCincoArquivos : []
  const first = ultimosCincoArquivos.isNotEmpty() ? [ultimosCincoArquivos[0]] : []

  return (
    <>
      <ImportacaoCidadaoListener onImportacaoMessage={refetch} municipio={municipio.id} />
      <TitleGroup title='Arquivos importados (7 dias)' />
      <Grid>
        <Cell size={12} style={styles.cell} alignSelf='flex-start'>
          <VFlow vSpacing={0} style={styles.table}>
            <DataTable<ArquivoModel>
              rows={mostrarMais ? ultimosCincoArquivos : first}
              columns={[
                {
                  name: 'dataRequisicao',
                  header: 'Data da importação',
                  render: (item) => formatDateAndHoursMinutes(item.dataInicioGerado),
                },
                {
                  name: 'statusProcessamento',
                  header: 'Status da importação',
                  render: (item) => <StatusArquivoLabel arquivo={item} isApenasUltimoRelatorio={false} />,
                },
                {
                  name: 'download',
                  header: '',
                  render: (item) => {
                    return (
                      <Button
                        type='button'
                        skin='ghost'
                        size='small'
                        disabled={item.status !== ArquivoStatusEnum.PRONTO}
                        onClick={() => handleDownload(item)}
                      >
                        <Tooltip text={item.status === ArquivoStatusEnum.PRONTO ? 'Download' : ''}>
                          <Icon icon='download' />
                        </Tooltip>
                      </Button>
                    )
                  },
                },
              ]}
            />
            <HFlow style={styles.footer}>
              <Button
                type='button'
                skin='ghost'
                size='small'
                onClick={() => {
                  setMostrarMais(!mostrarMais)
                }}
                style={styles.mostrarMais}
              >
                <Icon icon={mostrarMais ? 'angleUp' : 'angleDown'} />
                <Text>{mostrarMais ? 'Mostrar menos' : 'Mostrar mais'}</Text>
              </Button>
            </HFlow>
          </VFlow>
        </Cell>
      </Grid>
    </>
  )
}
const createStyles = (theme: Theme) => ({
  cell: css`
    padding-top: 2rem;
    padding-left: 0rem;
  `,
  footer: css`
    display: flex;
    align-items: stretch;
    border-left: 1px solid ${theme.pallete.divider};
    border-right: 1px solid ${theme.pallete.divider};
    border-bottom: 1px solid ${theme.pallete.divider};
    padding-top: 0rem;
    padding-left: 1rem;
    height: 2.25rem;
  `,
  mostrarMais: css`
    padding-top: 0.35rem;
  `,
  table: css`
    background: white;
  `,
})
