import { CheckboxGroupField, CheckboxGroupFieldProps, CheckboxOption } from 'components/form/field/CheckboxGroupField'
import { OpcaoTipoPerguntaEnum } from 'graphql/types.generated'
import React from 'react'

import { MarcadoresConsumoAlimentarListItem } from '../MarcadoresConsumoAlimentarListItem'
import { mcaDoisAnosOuMaisQuestions } from '../model'

export interface TipoRefeicaoCheckboxGroupFieldProps
  extends Omit<CheckboxGroupFieldProps<OpcaoTipoPerguntaEnum[]>, 'label' | 'options' | 'lines' | 'vSpacing'> {}

export function TipoRefeicaoCheckboxGroupField(props: TipoRefeicaoCheckboxGroupFieldProps) {
  return (
    <MarcadoresConsumoAlimentarListItem breakline label={mcaDoisAnosOuMaisQuestions.refeicoesPorDia}>
      <CheckboxGroupField options={options} {...props} />
    </MarcadoresConsumoAlimentarListItem>
  )
}

const options: CheckboxOption<OpcaoTipoPerguntaEnum>[] = [
  { value: OpcaoTipoPerguntaEnum.CAFE_DA_MANHA, label: 'Café da manhã' },
  { value: OpcaoTipoPerguntaEnum.LANCHE_DA_MANHA, label: 'Lanche da manhã' },
  { value: OpcaoTipoPerguntaEnum.ALMOCO, label: 'Almoço' },
  { value: OpcaoTipoPerguntaEnum.LANCHE_DA_TARDE, label: 'Lanche da tarde' },
  { value: OpcaoTipoPerguntaEnum.JANTAR, label: 'Jantar' },
  { value: OpcaoTipoPerguntaEnum.CEIA, label: 'Ceia' },
]
