/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles } from 'bold-ui'
import moment from 'moment'

export interface DateTimeProps {
  format?: string
  value: moment.Moment | string | number | Date
  style?: ExternalStyles
}

export function DateTime(props: DateTimeProps) {
  const { value, style, format } = props

  const mom = moment(value)

  if (!mom.isValid()) {
    return null
  }

  return <time css={css(style)}>{mom.format(format)}</time>
}

DateTime.defaultProps = {
  format: 'DD/MM/YYYY [às] HH:mm',
} as Partial<DateTimeProps>
