import { ReferenceMonth } from 'bold-ui'
import { subMonths } from 'date-fns'

import { dateAsYyyyMmDd, toDate } from './formatDate'

export function lastMonthAsReferenceMonth(now: Date): ReferenceMonth {
  const lastMonth = subMonths(now, 1)
  return { month: lastMonth.getMonth(), year: lastMonth.getFullYear() }
}

export function instantToYyyyMmDd(instant: Instant): LocalDate {
  const date = toDate(instant)
  if (date) {
    return dateAsYyyyMmDd(date)
  }
  return null
}
