import { css } from '@emotion/core'
import { Button, Icon, Text, Theme, useTheme } from 'bold-ui'
import React, { useState } from 'react'

import { RelatorioInconsistenciaModal } from './RelatorioInconsistenciaModal'

export const GerarRelatorioInconsistenciaButton = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const theme = useTheme()
  const classes = styles(theme)

  return (
    <>
      <Button kind='primary' onClick={() => setIsOpen(true)} style={classes.relatorioInconsistenciaButton}>
        <Icon icon='fileWithItemsOutline' style={classes.relatorioInconsistenciaIcon} />
        <Text color='inherit'>Gerar relatório de inconsistências</Text>
      </Button>
      {isOpen && <RelatorioInconsistenciaModal onClose={() => setIsOpen(false)} />}
    </>
  )
}

const styles = (theme: Theme) => ({
  relatorioInconsistenciaButton: css`
    paddingleft: 1rem;
    paddingright: 1rem;
  `,
  relatorioInconsistenciaIcon: css`
    marginright: 0.5rem;
  `,
})
