import { Cell, Grid, InfoLabel, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'

import { SubtitleCard } from '../../soap/aside/components/SubtitleCard'
import { FolhaRostoInformacoesObstetricasModel } from './model'

interface InformacoesObstetricasContentCardProps {
  informacoesObstetricas: FolhaRostoInformacoesObstetricasModel
}

export function InformacoesObstetricasContentCard(props: InformacoesObstetricasContentCardProps) {
  const { informacoesObstetricas } = props

  return (
    <>
      <SubtitleCard title='Informações obstétricas' />
      <Grid>
        <Cell size={3}>
          <VFlow vSpacing={0.5}>
            <InfoLabel title='Gestas prévias'>{informacoesObstetricas.gestaPrevias}</InfoLabel>
            <InfoLabel title='Abortos'>{informacoesObstetricas.abortos}</InfoLabel>
            {informacoesObstetricas.partoMenosDeUmAno && (
              <Text fontStyle='italic'>(Desfecho da última gestação aconteceu há menos de 1 ano)</Text>
            )}
          </VFlow>
        </Cell>
        <Cell size={4}>
          <VFlow vSpacing={0.5}>
            <InfoLabel title='Partos'>
              <VFlow vSpacing={0}>
                <HLabel title='Partos realizados:' titleFontWeight='normal'>
                  {informacoesObstetricas.partos}
                </HLabel>
                <HLabel title='Vaginais:' titleFontWeight='normal'>
                  {informacoesObstetricas.partosVaginais}
                </HLabel>
                <HLabel title='Cesáreas:' titleFontWeight='normal'>
                  {informacoesObstetricas.partosCesareas}
                </HLabel>
                <HLabel title='Partos domiciliares:' titleFontWeight='normal'>
                  {informacoesObstetricas.partosDomiciliares}
                </HLabel>
              </VFlow>
            </InfoLabel>
            <InfoLabel title='Nascidos'>
              <VFlow vSpacing={0}>
                <HLabel title='Vivos:' titleFontWeight='normal'>
                  {informacoesObstetricas.nascidosVivos}
                </HLabel>
                <HLabel title='Mortos:' titleFontWeight='normal'>
                  {informacoesObstetricas.natimortos}
                </HLabel>
              </VFlow>
            </InfoLabel>
          </VFlow>
        </Cell>
        <Cell size={5}>
          <VFlow vSpacing={0.5}>
            <InfoLabel title='Recém-nascidos com'>
              <VFlow vSpacing={0}>
                <HLabel title='Menos de 2500g:' titleFontWeight='normal'>
                  {informacoesObstetricas.recemNascidoAbaixo}
                </HLabel>
                <HLabel title='Mais de 4500g:' titleFontWeight='normal'>
                  {informacoesObstetricas.recemNascidoAcima}
                </HLabel>
              </VFlow>
            </InfoLabel>
            <InfoLabel title='Nascidos vivos'>
              <VFlow vSpacing={0}>
                <HLabel title='Vivem:' titleFontWeight='normal'>
                  {informacoesObstetricas.filhosVivos}
                </HLabel>
                <HLabel title='Mortos na 1ª semana:' titleFontWeight='normal'>
                  {informacoesObstetricas.obitoAntesPrimeiraSemana}
                </HLabel>
                <HLabel title='Mortos depois da 1ª semana:' titleFontWeight='normal'>
                  {informacoesObstetricas.obitoAposPrimeiraSemana}
                </HLabel>
              </VFlow>
            </InfoLabel>
          </VFlow>
        </Cell>
      </Grid>
    </>
  )
}
