import { AcompanhamentoCidadaosVinculadosQuery, Cidadao, PageParams } from 'graphql/types.generated'

export const TITULO_ACOMPANHAMENTO_CIDADAOS_VINCULADOS = 'Acompanhamento de cidadãos vinculados'
export const ACOMPANHAMENTO_CIDADAOS_VINCULADOS_ATMOSPHERE_PATH = 'acompanhamento-cidadaos-vinculados'
export const ACOMPANHAMENTO_CIDADAOS_VINCULADOS_FILENAME = 'acompanhamento-cidadaos-vinculados'

export interface AcompanhamentoCidadaosVinculadosRowModel
  extends Pick<Cidadao, 'nome' | 'cpf' | 'cns'>,
    Pick<AcompanhamentoCidadaosVinculadosQueryModel, 'origem' | 'equipe' | 'microarea' | 'endereco'> {
  idade: string
  sexoIdentidadeGenero: string
  dataNascimento?: string
  telefone: AcompanhamentoCidadaosVinculadosTelefoneModel
  dataUltimaAtualizacaoCadastral?: LocalDate
}

export interface AcompanhamentoCidadaosVinculadosTelefoneModel
  extends Pick<Cidadao, 'telefoneResidencial' | 'telefoneCelular' | 'telefoneContato'> {}

export type AcompanhamentoCidadaosVinculadosQueryModel = AcompanhamentoCidadaosVinculadosQuery['acompanhamentoCidadaosVinculados']['content'][0]

export type AcompanhamentoCidadaosVinculadosPageParams = {
  pageParams: PageParams
}
