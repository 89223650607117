import { differenceInMonths, differenceInYears, parseISO } from 'date-fns'
import { NeuroFaixaEtariaEnum } from 'graphql/types.generated'
import { toDate } from 'util/date/formatDate'
import { createValidator, ErrorObject, maxLength, range } from 'util/validation'

import { MarcoFormSectionAccordionModel } from './marcos/MarcoFormSectionAccordion'
import { AvaliacaoDesenvolvimentoModel, PrenatalPartoNascimentoModel, PuericulturaModel } from './model'
import { alcancadoComAtivo } from './util'

const antropometriaAoNascerValidator = createValidator<PrenatalPartoNascimentoModel['antropometriaAoNascer']>({
  peso: [maxLength(7), range(0.5, 500)],
  comprimento: [maxLength(5), range(20, 250)],
  perimetroCefalico: [maxLength(5), range(10, 200)],
})

const apgarValidator = createValidator<PrenatalPartoNascimentoModel['apgar']>({
  apgarUm: [maxLength(2), range(0, 10)],
  apgarCinco: [maxLength(2), range(0, 10)],
  apgarDez: [maxLength(2), range(0, 10)],
})

export const prenatalPartoNascimentoValidator = createValidator<PrenatalPartoNascimentoModel>(
  {
    apgar: apgarValidator,
    antropometriaAoNascer: antropometriaAoNascerValidator,
  },
  (formValues, errors) => {
    if (formValues) {
      const idadeGestacionalEmDias = formValues.idadeGestacional?.dias
      const idadeGestacionalEmSemanas = formValues.idadeGestacional?.semanas
      if (!errors.idadeGestacional && !idadeGestacionalEmSemanas && idadeGestacionalEmDias)
        errors.idadeGestacional = 'Semanas deve ter valor entre 20 e 42.'
      if (!errors.idadeGestacional)
        errors.idadeGestacional =
          maxLength(2)(idadeGestacionalEmSemanas) && 'Semanas deve possuir no máximo 2 caracteres.'
      if (!errors.idadeGestacional)
        errors.idadeGestacional = range(20, 42)(idadeGestacionalEmSemanas) && 'Semanas deve ter valor entre 20 e 42.'
      if (!errors.idadeGestacional)
        errors.idadeGestacional = maxLength(1)(idadeGestacionalEmDias) && 'Dias deve possuir no máximo 1 caracter.'
      if (!errors.idadeGestacional)
        errors.idadeGestacional = range(0, 6)(idadeGestacionalEmDias) && 'Dias deve ter valor entre 0 e 6.'

      return errors
    }
  }
)

const alcancadoComValidate = (
  idadeEmAnos: number,
  mesesRestanteModuloAno: number,
  avaliacoes: AvaliacaoDesenvolvimentoModel[]
): ErrorObject<AvaliacaoDesenvolvimentoModel[]> => {
  return avaliacoes.map((avaliacaoDesenvolvimento) => {
    const errors: ErrorObject<AvaliacaoDesenvolvimentoModel> = {}
    const limiteAnosField = Math.min(10, idadeEmAnos)
    if (alcancadoComAtivo(avaliacaoDesenvolvimento)) {
      const mesesFieldValue = Number(avaliacaoDesenvolvimento.alcancadoCom?.meses) || 0
      const anosFieldValue = Number(avaliacaoDesenvolvimento.alcancadoCom?.anos) || 0
      const limiteMesesField = anosFieldValue === idadeEmAnos ? mesesRestanteModuloAno : 11
      if (!mesesFieldValue && !anosFieldValue) errors.alcancadoCom = 'Preenchimento obrigátorio.'

      if (!errors.alcancadoCom)
        errors.alcancadoCom = maxLength(2)(mesesFieldValue) && 'Meses deve possuir no máximo 2 caracteres.'
      if (!errors.alcancadoCom)
        errors.alcancadoCom = range(0, limiteMesesField)(mesesFieldValue) && 'Esta não é uma idade válida.'

      if (!errors.alcancadoCom)
        errors.alcancadoCom = maxLength(2)(anosFieldValue) && 'Anos deve possuir no máximo 2 caracteres.'
      if (!errors.alcancadoCom)
        errors.alcancadoCom = range(0, limiteAnosField)(anosFieldValue) && 'Esta não é uma idade válida.'
    }
    return errors
  })
}

const marcosRecordValidator = (dataNascimento: LocalDate, dataAtendimento: Instant) =>
  createValidator<Record<NeuroFaixaEtariaEnum, AvaliacaoDesenvolvimentoModel[]>>({}, (formValues, errors) => {
    if (formValues) {
      const idadeEmAnos = differenceInYears(toDate(dataAtendimento), parseISO(dataNascimento))
      const mesesRestanteModuloAno = differenceInMonths(toDate(dataAtendimento), parseISO(dataNascimento)) % 12
      Object.entries(formValues).forEach(
        ([key, avaliacoes]) => (errors[key] = alcancadoComValidate(idadeEmAnos, mesesRestanteModuloAno, avaliacoes))
      )
    }
    return errors
  })

const marcosDesenvolvimentoValidator = (dataNascimento: LocalDate, dataAtendimento: Instant) =>
  createValidator<MarcoFormSectionAccordionModel>({
    marcosRecord: marcosRecordValidator(dataNascimento, dataAtendimento),
  })

export const puericulturaValidator = (dataNascimento: LocalDate, dataAtendimento: Instant) =>
  createValidator<PuericulturaModel>({
    prenatalPartoNascimento: prenatalPartoNascimentoValidator,
    marcosDesenvolvimento: marcosDesenvolvimentoValidator(dataNascimento, dataAtendimento),
  })
