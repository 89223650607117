/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Table, TableBody, TableCell, TableRow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { InfoIcon } from 'components/InfoIcon'
import { isEmpty } from 'lodash'

import { AvaliacaoDesenvolvimentoModel } from '../../../objetivo/puericultura/model'
import StatusAvaliacaoAcompanhamentoTag from './StatusAvaliacaoAcompanhamentoTag'

interface AvaliacaoDesenvolvimentoTableAcompanhamentoProps {
  rows: AvaliacaoDesenvolvimentoModel[]
}

export default function AvaliacaoDesenvolvimentoTableAcompanhamento(
  props: AvaliacaoDesenvolvimentoTableAcompanhamentoProps
) {
  const { rows } = props
  const theme = useTheme()
  const classes = createStyles(theme)

  if (isEmpty(rows)) return null

  return (
    <Table>
      <TableBody>
        {rows.map((item: AvaliacaoDesenvolvimentoModel, index: number) => (
          <TableRow key={item.id}>
            <TableCell style={classes.cell}>
              <HFlow alignItems='center' justifyContent='space-between'>
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>{item.descricao}</Text>
                  <StatusAvaliacaoAcompanhamentoTag status={item.statusButton} alcancadoCom={item.alcancadoCom} />
                </VFlow>
                {item.hint && <InfoIcon icon='infoCircleFilled' text={item.hint} />}
              </HFlow>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const createStyles = (theme: Theme) => ({
  cell: css`
    padding: 0.75rem 1rem;
    border: 1px solid ${theme.pallete.gray.c60};
  `,
})
