import { HFlow, Text } from 'bold-ui'
import { SelectField } from 'components/form/final-form'
import { UnidadesSaudeSelectFieldDocument } from 'graphql/hooks.generated'
import { UnidadesSaudeSelectFieldQuery, UnidadesSaudeSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type UnidadeSaudeSelectModel = UnidadesSaudeSelectFieldQuery['unidadesSaude']['content'][0]

export interface UnidadeSaudeSelectFieldProps extends AsyncSelectFieldProps<UnidadeSaudeSelectModel> {
  disabled?: boolean
  somenteUnidadesPermitidas?: boolean
  mostrarInativas?: boolean
}

export function UnidadeSaudeSelectField(props: UnidadeSaudeSelectFieldProps) {
  const { somenteUnidadesPermitidas, mostrarInativas = false, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    UnidadeSaudeSelectModel,
    UnidadesSaudeSelectFieldQuery,
    UnidadesSaudeSelectFieldQueryVariables
  >({
    query: UnidadesSaudeSelectFieldDocument,
    extractItems: (data) => data && data.unidadesSaude && data.unidadesSaude.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        mostrarInativas,
        somenteUnidadesPermitidas: somenteUnidadesPermitidas,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (option: UnidadeSaudeSelectModel) => option && option.nome

  const renderItem = (item: UnidadeSaudeSelectModel) => (
    <>
      <Text fontWeight='bold'>
        {item.nome}
        {!item.ativo && mostrarInativas ? ' (Inativa)' : ''}
      </Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>CNES:</Text>
        {item.cnes}
      </HFlow>
    </>
  )

  return (
    <SelectField<UnidadeSaudeSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...selectProps}
      {...rest}
    />
  )
}
