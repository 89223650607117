import { Alert, AlertType } from 'bold-ui'
import React from 'react'

interface AlertPrescricao {
  mensagem: string
  tipoAlerta: AlertType
}

interface AlertPrescricaoModalProps {
  tipoAlertaPrescricao: AlertPrescricaoEnum
  onCloseClick: any
}

export enum AlertPrescricaoEnum {
  SALVO,
  CAMPO_INCORRETO,
}

const enumToAlertPrescricao: Record<AlertPrescricaoEnum, AlertPrescricao> = {
  [AlertPrescricaoEnum.SALVO]: { mensagem: 'Medicamento salvo com sucesso.', tipoAlerta: 'success' },
  [AlertPrescricaoEnum.CAMPO_INCORRETO]: {
    mensagem: 'Existem campos preenchidos de forma incorreta.',
    tipoAlerta: 'danger',
  },
}

const AlertPrescricaoModal = ({ tipoAlertaPrescricao, onCloseClick }: AlertPrescricaoModalProps) => {
  const alert = enumToAlertPrescricao[tipoAlertaPrescricao]

  return (
    <Alert type={alert.tipoAlerta} onCloseClick={onCloseClick} inline>
      {alert.mensagem}
    </Alert>
  )
}

export default AlertPrescricaoModal
