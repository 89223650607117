import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const SegurancaDosDadosSection = () => {
  return (
    <TermosDeUsoHeadingSection title='11. Segurança dos Dados' id='seguranca_dos_dados'>
      <p>
        O Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS se compromete a aplicar as medidas técnicas e
        organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda,
        alteração, comunicação ou difusão de tais dados.
      </p>
      <br />
      <p>
        Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os
        custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os
        direitos e liberdades do usuário.
      </p>
      <br />
      <p>
        O serviço utiliza criptografia para que os dados sejam transmitidos de forma segura e confidencial, de maneira
        que a transmissão dos dados entre o servidor e o usuário, e em retroalimentação, ocorra de maneira totalmente
        cifrada ou encriptada.
      </p>
      <br />
      <p>
        No entanto, o serviço se exime de responsabilidade por culpa exclusiva de terceiros, como em caso de ataque de
        hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a
        terceiro. O Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS compromete- se, ainda, a comunicar o
        usuário em prazo adequado caso ocorra algum tipo de violação da segurança de seus dados pessoais que possa lhe
        causar um alto risco para seus direitos e liberdades pessoais.
      </p>
      <br />
      <p>
        A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou ilícito, a
        destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados pessoais transmitidos,
        conservados ou sujeitos a qualquer outro tipo de tratamento.
      </p>
      <br />
      <p>
        Por fim, o serviço se compromete a tratar os dados pessoais do usuário com confidencialidade, dentro dos limites
        legais.
      </p>
    </TermosDeUsoHeadingSection>
  )
}
