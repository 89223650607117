import { Button, Cell, DateRange, Grid, HFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { CboSelectField, Form, FormRenderProps, ProfissionalSelectField, SubmitButton } from 'components/form'
import { CheckboxGroupField, CheckboxOption } from 'components/form/field/CheckboxGroupField'
import DateRangeField from 'components/form/field/DateRangeField'
import { Cbo, Profissional, TipoExameEnum } from 'graphql/types.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'

export interface SolicitacaoExamesFilterPopperModel {
  profissional?: Profissional
  cbo?: Cbo
  periodo?: DateRange
  tipoExames?: TipoExameEnum[]
}

const path = metaPath<SolicitacaoExamesFilterPopperModel>()

export interface SolicitacaoExamesFilterPopperProps {
  dataInicioAtendimento: Date
  initialValues?: SolicitacaoExamesFilterPopperModel
  onSubmit(values: SolicitacaoExamesFilterPopperModel): void
  onClose(): void
}

const options: CheckboxOption<TipoExameEnum>[] = [
  {
    label: 'Comum',
    value: TipoExameEnum.COMUM,
  },
  {
    label: 'Alto custo',
    value: TipoExameEnum.ALTO_CUSTO,
  },
]

export function SolicitacaoExamesFilterPopper(props: SolicitacaoExamesFilterPopperProps) {
  const { dataInicioAtendimento, initialValues, onSubmit, onClose } = props

  const renderForm = (formProps: FormRenderProps<SolicitacaoExamesFilterPopperModel>) => {
    return (
      <Box style={{ width: '42rem', marginTop: '1rem', marginRight: '-0.1875rem' }}>
        <Grid gapVertical={1} gap={1} justifyContent='flex-end'>
          <Cell size={6} alignSelf='flex-end'>
            <CheckboxGroupField name={path.tipoExames} label='Tipo de exame' options={options} />
          </Cell>
          <Cell size={6}>
            <DateRangeField name={path.periodo} label='Período' maxDate={dataInicioAtendimento} />
          </Cell>

          <Cell size={6}>
            <ProfissionalSelectField name={path.profissional} label='Profissional' mostrarCns />
          </Cell>
          <Cell size={6}>
            <CboSelectField name={path.cbo} label='CBO' mostrarIndisponivelLotacao={false} />
          </Cell>
          <Cell size={12} flexGrow={1} alignSelf='flex-end'>
            <HFlow justifyContent='flex-end'>
              <Button onClick={onClose} size='small' skin='outline' kind='normal'>
                Fechar
              </Button>
              <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
                Filtrar
              </SubmitButton>
            </HFlow>
          </Cell>
        </Grid>
      </Box>
    )
  }

  return (
    <Form<SolicitacaoExamesFilterPopperModel>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={onSubmit}
      onSubmitSucceeded={onClose}
    />
  )
}
