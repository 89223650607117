import { Heading, HFlow, Text } from 'bold-ui'
import React from 'react'

export interface NewsHeadingSectionProps {
  icon?: React.ReactNode
  title: string
  subtitle?: string
  children: any
  id?: string
}

export function NewsHeadingSection(props: NewsHeadingSectionProps) {
  const { title, subtitle, icon, children, id } = props

  return (
    <>
      <HFlow alignItems='flex-start' hSpacing={0.5} id={id}>
        {icon}
        <Heading level={3} style={{ marginTop: '0.25rem', marginBottom: '1rem' }}>
          {title}
          <Text style={{ fontWeight: 'normal' }} variant='h3'>
            {subtitle}
          </Text>
        </Heading>
      </HFlow>
      {children}
    </>
  )
}
