import { useEffect } from 'react'

import { useEsusViewState } from './EsusViewContext'
import { useEsusNavigationController } from './useEsusNavigationController'

interface EsusViewProps {
  url: string
}

export default function EsusView(props: EsusViewProps) {
  const { hide, setBaseUrl, baseUrl, uri } = useEsusViewState()

  // uri como chave pra funcionar o click no menu num módulo que já está ativo
  useEffect(() => {
    uri.includes(baseUrl) && setBaseUrl(props.url)
  }, [setBaseUrl, props.url, uri, baseUrl])

  useEffect(() => {
    setBaseUrl(props.url)
  }, [setBaseUrl, props.url])

  useEffect(() => hide, [hide])

  useEsusNavigationController()

  return null
}
