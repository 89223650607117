import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const DefinicoesSection = () => {
  return (
    <TermosDeUsoHeadingSection title='1. Definições' id='definicoes'>
      <p>Para melhor compreensão deste documento, nesta Política de Privacidade, consideram-se:</p>
      <br />
      <p>
        <b>Agentes de tratamento: </b>O controlador e o operador.
      </p>
      <br />
      <p>
        <b>Anonimização: </b>Utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos
        quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.
      </p>
      <br />
      <p>
        <b>Autoridade Nacional: </b>Órgão da administração pública responsável por zelar, implementar e fiscalizar o
        cumprimento desta Lei em todo o território nacional.
      </p>
      <br />
      <p>
        <b>Banco de Dados: </b>Conjunto estruturado de dados pessoais, estabelecido em um ou em vários locais, em
        suporte eletrônico ou físico.
      </p>
      <br />
      <p>
        <b>Consentimento: </b>Manifestação livre, informada e inequívoca pela qual o titular concorda com o tratamento
        de seus dados pessoais para uma finalidade determinada.
      </p>
      <br />
      <p>
        <b>Controlador: </b>Pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões
        referentes ao tratamento de dados pessoais.
      </p>
      <br />
      <p>
        <b>Dado Anonimizado: </b>Dado relativo a um titular que não possa ser identificado, considerando a utilização de
        meios técnicos razoáveis e disponíveis na ocasião de seu tratamento.
      </p>
      <br />
      <p>
        <b>Dado Pessoal: </b>Informação relacionada a uma pessoa natural identificada ou identificável.
      </p>
      <br />
      <p>
        <b>Dado Pessoal Sensível: </b>Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política,
        filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à
        vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.
      </p>
      <br />
      <p>
        <b>Encarregado: </b>Pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o
        controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
      </p>
      <br />
      <p>
        <b>Operador: </b>Pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados
        pessoais em nome do controlador.
      </p>
      <br />
      <p>
        <b>Órgão de Pesquisa: </b>Órgão ou entidade da administração pública direta ou indireta ou pessoa jurídica de
        direito privado sem fins lucrativos, legalmente constituída sob as leis brasileiras e com sede e foro no País,
        que inclua em sua missão institucional ou em seu objetivo social ou estatutário a pesquisa básica ou aplicada de
        caráter histórico, científico, tecnológico ou estatístico.
      </p>
      <br />
      <p>
        <b>Titular: </b>Pessoa natural a quem se referem os dados pessoais que são objeto de tratamento.
      </p>
      <br />
      <p>
        <b>Transferência Internacional de Dados: </b>Transferência de dados pessoais para país estrangeiro ou organismo
        internacional do qual o país seja membro.
      </p>
      <br />
      <p>
        <b>Tratamento: </b>Toda operação realizada com dados pessoais, como as que se referem a coleta, produção,
        recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento,
        armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão
        ou extração.
      </p>
      <br />
      <p>
        <b>Uso Compartilhado de Dados: </b>Comunicação, difusão, transferência internacional, interconexão de dados
        pessoais ou tratamento compartilhado de bancos de dados pessoais por órgãos e entidades públicos no cumprimento
        de suas competências legais, ou entre esses e entes privados, reciprocamente, com autorização específica, para
        uma ou mais modalidades de tratamento permitidas por esses entes públicos, ou entre entes privados.
      </p>
    </TermosDeUsoHeadingSection>
  )
}
