/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'
import { TextAreaField } from 'components/form'
import {
  ProcedimentoOdontoSelectField,
  ProcedimentoOdontoSelectModel,
} from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { ParteBucalEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

import { MAX_LENGTH_OBS_ODONTO } from '../../model'
import SextantesCheckboxGroupField from '../components/SextantesCheckboxGroupField'
import { EvolucoesPeriodontaisFormModel } from './EvolucoesPeriodontaisForm'

interface EvolucoesPeriodontaisFormFieldsProps {
  editing?: boolean
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  partesBucais: ParteBucalEnum[]
  hasProcedimentos?: boolean
  procedimentosDisabled: ProcedimentoOdontoSelectModel[]
}

const path = metaPath<EvolucoesPeriodontaisFormModel>()

export default function EvolucoesPeriodontaisFormFields(props: EvolucoesPeriodontaisFormFieldsProps) {
  const {
    cidadaoDataNascimento,
    dataAtendimento,
    partesBucais,
    procedimentosDisabled,
    hasProcedimentos,
    editing,
  } = props

  return (
    <VFlow>
      <SextantesCheckboxGroupField name={path.sextantes} required disabled={editing} />

      <ProcedimentoOdontoSelectField
        name={path.procedimentos}
        label='Procedimentos (Evolução odontológica)'
        multiple
        dataNascimento={cidadaoDataNascimento}
        dataAtendimento={dataAtendimento}
        parteBucal={partesBucais && partesBucais[0]}
        required
        disabled={!(partesBucais?.length > 0)}
        itemsDisabled={procedimentosDisabled}
      />

      <TextAreaField
        name={path.observacao}
        label='Observações dos procedimentos'
        maxLength={MAX_LENGTH_OBS_ODONTO}
        placeholder='Insira informações adicionais sobre os procedimentos realizados'
        style={css`
          resize: none;
        `}
        disabled={!hasProcedimentos}
      />
    </VFlow>
  )
}
