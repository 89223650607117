import createDecorator from 'final-form-calculate'
import { MotivoFechamentoEnum } from 'graphql/types.generated'

import { FechamentoFormModel, name } from './model'

export const createFechamentoDecorator = () => [createDecorator(...createMotivoCalculations())]

const createMotivoCalculations = () => [
  {
    field: name.motivo.absolutePath(),
    updates: {
      [name.especifique.absolutePath()]: (motivoValue: MotivoFechamentoEnum, allValues: FechamentoFormModel) =>
        motivoValue !== MotivoFechamentoEnum.OUTRO ? undefined : allValues.especifique,
    },
  },
]
