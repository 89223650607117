import { Button, HFlow, Icon, Tooltip } from 'bold-ui'
import React from 'react'

interface ButtonsHeaderProblemaModalProps {
  registradoAgora: boolean
  isCiapW78Automatico: boolean
  isAntecedentes: boolean
  isSituacaoDiferenteDeResolvido: boolean
  handleEditarClick(e: React.MouseEvent<HTMLButtonElement>): void
  handleExcluirClick(e: React.MouseEvent<HTMLButtonElement>): void
}

export function ButtonsHeaderProblemaModal(props: ButtonsHeaderProblemaModalProps) {
  const {
    registradoAgora,
    isCiapW78Automatico,
    isAntecedentes,
    isSituacaoDiferenteDeResolvido,
    handleEditarClick,
    handleExcluirClick,
  } = props

  const getTooltipText = (isEdicao: boolean) => {
    if (isCiapW78Automatico) {
      return `Registro não pode ser ${
        isEdicao ? 'editado' : 'excluído'
      } pois possui relação com condição avaliada de Desfecho de gravidez ou Alto risco`
    } else if (registradoAgora) {
      if (isAntecedentes && isSituacaoDiferenteDeResolvido) {
        return `Para ${isEdicao ? 'editar' : 'excluir'}, acesse o card lateral de Lista de problemas/condições`
      } else {
        return `${isEdicao ? 'Editar' : 'Excluir'}`
      }
    } else {
      return `Avaliações do atendimento atual devem ser ${isEdicao ? 'alteradas' : 'excluídas'} no SOAP`
    }
  }

  const disabled = !registradoAgora || (isAntecedentes && isSituacaoDiferenteDeResolvido) || isCiapW78Automatico

  return (
    <HFlow justifyContent='flex-end' hSpacing={0.5}>
      <Tooltip text={getTooltipText(true)}>
        <Button size='small' skin='ghost' onClick={handleEditarClick} disabled={disabled}>
          <Icon icon='penOutline' />
        </Button>
      </Tooltip>

      <Tooltip text={getTooltipText(false)}>
        <Button size='small' skin='ghost' onClick={handleExcluirClick} disabled={disabled}>
          <Icon icon='trashOutline' />
        </Button>
      </Tooltip>
    </HFlow>
  )
}
