import { VFlow } from 'bold-ui'
import { useConfiguracaoAgendaMunicipioQuery } from 'graphql/hooks.generated'
import React from 'react'

import { ConfiguracaoAgendaMunicipioPanel } from './ConfiguracaoAgendaMunicipioPanel'
import { ConfiguracaoDuracaoAtendimentoPanel } from './ConfiguracaoDuracaoAtendimentoPanel'

export function ConfiguracaoAgendaMunicipioRootView() {
  const {
    data: { configuracaoHorariosMunicipio, duracaoAtendimentoPadraoMunicipio },
  } = useConfiguracaoAgendaMunicipioQuery({
    fetchPolicy: 'network-only',
  })

  if (!configuracaoHorariosMunicipio) {
    return null
  }

  return (
    <VFlow>
      <ConfiguracaoAgendaMunicipioPanel cfgAgendaMunicipio={configuracaoHorariosMunicipio} />
      <ConfiguracaoDuracaoAtendimentoPanel duracaoAtendimentoPadraoMunicipioInput={duracaoAtendimentoPadraoMunicipio} />
    </VFlow>
  )
}
