import { FormRenderProps } from 'components/form'
import { metaPath } from 'util/metaPath'

import { TecidosMolesFormModel } from './TecidosMolesForm'

const path = metaPath<TecidosMolesFormModel>()

export const validateCaracteristicasLesao = (formProps: FormRenderProps<any>) => {
  return (
    !!formProps?.errors?.caracteristicasLesao &&
    (formProps.touched[path.caracteristicasLesao.lesaoFundamental.lesaoEnvolvida.absolutePath()] ||
      formProps.touched[path.caracteristicasLesao.sintomatologia.descricaoOutro.absolutePath()])
  )
}
