import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text } from 'bold-ui'
import { VinculacaoEquipePorEstabelecimentoDto } from 'graphql/types.generated'
import React from 'react'
import { stringGrouper } from 'util/string/string-grouper'

interface EquipesVinculadasEstabelecimentoPanelProps {
  vinculacoesPorEstabelecimento: VinculacaoEquipePorEstabelecimentoDto[]
}

export function EquipesVinculadasEstabelecimentoPanel(props: EquipesVinculadasEstabelecimentoPanelProps) {
  const { vinculacoesPorEstabelecimento } = props

  return (
    <Table style={{ border: 0 }}>
      <TableHead>
        <TableRow>
          <TableHeader style={{ width: '30%' }}>Unidade básica de saúde</TableHeader>
          <TableHeader>Equipes</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {vinculacoesPorEstabelecimento.map((row) => (
          <TableRow key={row.estabelecimento.id}>
            <TableCell>
              <Text>{row.estabelecimento.nome}</Text>
            </TableCell>
            <TableCell>
              <Text>
                {stringGrouper(...row.equipes.map((equipe) => `${equipe.nome.toUpperCase()} - ${equipe.ine}`))}
              </Text>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
