import { Alert, Text } from 'bold-ui'
import React from 'react'

export const HistoricoAtendimentoObservacaoAlert = () => {
  return (
    <Alert type='info' inline>
      <Text color='primary'>Cidadã(o) ficou em observação</Text>
    </Alert>
  )
}
