import { Button, Cell, Grid, Heading, HFlow, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { Form, FormFooter, FormPrompt, FormRenderProps, NumberField } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { ButtonLink } from 'components/route'
import clearTypename from 'graphql/clearTypename'
import {
  useAutorizarMunicipioConfigAgendaPadraoQuery,
  useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation,
} from 'graphql/hooks.generated'
import { DuracaoAtendimentoPadraoMunicipioInput, Municipio } from 'graphql/types.generated'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { composeValidators } from 'util/validation'
import {
  ConfiguracaoAgendaFormType,
  ConfiguracaoAgendaPanel,
} from 'view/configuracao-agenda/component/ConfiguracaoAgendaPanel'
import { cfgHorarioAgendaValidator } from 'view/configuracao-agenda/validator/base/ConfiguracaoHorarioAgendaValidator'
import { duracaoAtendimentoValidator } from 'view/configuracoes/municipal/configuracao-padrao/ConfiguracaoDuracaoAtendimentoPanel'
import { clearAllFields } from 'view/profissional/agenda/ConfiguracaoAgendaLotacaoPanel'

export interface AutorizarMunicipioConfigAgendaPadraoFormProps {
  municipio?: Pick<Municipio, 'uf' | 'nome' | 'id'>
}

type AutorizarMunicipioAgendaPadrao = ConfiguracaoAgendaFormType & DuracaoAtendimentoPadraoMunicipioInput

export const AutorizarMunicipioConfigAgendaPadraoForm = (props: AutorizarMunicipioConfigAgendaPadraoFormProps) => {
  const { history } = useRouter()

  const {
    data: { configuracaoHorariosMunicipio, duracaoAtendimentoPadraoMunicipio },
  } = useAutorizarMunicipioConfigAgendaPadraoQuery()

  const [salvarConfiguracaoHorariosMunicipioMutation] = useSalvaAutorizarMunicipioConfiguracaoAgendaPadraoMutation()

  const handleSubmit = (form: AutorizarMunicipioAgendaPadrao) => {
    return salvarConfiguracaoHorariosMunicipioMutation({
      variables: {
        configuracaoAgendaMunicipalInput: {
          id: form.id,
          configuracaoHorarioAgendaInput: clearTypename(form.configuracaoHorarioAgenda),
        },
        duracaoAtendimentoPadraoMunicipioInput: {
          duracaoAtendimentoPadraoMunicipio: form.duracaoAtendimentoPadraoMunicipio,
        },
      },
    })
  }

  const handleSubmitSucceeded = () => history.push('/autorizar/cnes')

  const handleRender = (formProps: FormRenderProps<AutorizarMunicipioAgendaPadrao>) => (
    <>
      <FormPrompt />

      <VFlow>
        <Heading level={2}>
          {props.municipio.nome} - {props.municipio.uf.sigla}
        </Heading>
        <Heading level={3} style={{ display: 'flex', alignItems: 'center' }}>
          Configure a agenda padrão com os períodos de trabalho dos profissionais do município
          <InfoIcon
            icon='infoCircleFilled'
            size={1}
            style={{ marginLeft: '0.5rem' }}
            text='Será possível definir valores diferenciados para cada profissional após a instalação.'
          />
        </Heading>
        <div>
          <Box style={{ borderBottom: 'none', marginBottom: 0, padding: '0.5rem', paddingLeft: '1rem' }}>
            <HFlow justifyContent='space-between' alignItems='center'>
              <Heading level={4}>Períodos de trabalho</Heading>
              <Button type='reset' size='small' onClick={() => clearAllFields(formProps.form)}>
                Limpar todos os campos
              </Button>
            </HFlow>
          </Box>
          <ConfiguracaoAgendaPanel configuracaoSemana={formProps.values} formProps={formProps as any} />
        </div>

        <Heading level={3} style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
          Informe o valor padrão da duração dos agendamentos
        </Heading>
        <Grid>
          <Cell xs={12} md={3}>
            <NumberField
              clearable={false}
              name='duracaoAtendimentoPadraoMunicipio'
              label='Duração do agendamento (min)'
              required
              maxLength={2}
            />
          </Cell>
        </Grid>
      </VFlow>
      <FormFooter>
        <ButtonLink to='/autorizar/ativar'>Voltar</ButtonLink>
        <Button onClick={formProps.handleSubmit} kind='primary'>
          Avançar
        </Button>
      </FormFooter>
    </>
  )

  if (!configuracaoHorariosMunicipio || !duracaoAtendimentoPadraoMunicipio) {
    return null
  }

  return (
    <Form<AutorizarMunicipioAgendaPadrao>
      render={handleRender}
      onSubmit={handleSubmit}
      onSubmitSucceeded={handleSubmitSucceeded}
      validate={validate}
      initialValues={{
        id: configuracaoHorariosMunicipio.id,
        configuracaoHorarioAgenda: configuracaoHorariosMunicipio.configuracaoHorarioAgenda,
        mostrarFinalDeSemana: true,
        duracaoAtendimentoPadraoMunicipio: duracaoAtendimentoPadraoMunicipio.duracaoAtendimentoPadraoMunicipio,
      }}
    />
  )
}

const validate = composeValidators<AutorizarMunicipioAgendaPadrao>(
  duracaoAtendimentoValidator,
  cfgHorarioAgendaValidator
)
