import { Theme, useStyles } from 'bold-ui'
import React, { useRef } from 'react'

const getImage = (name: string) => require(`images/login/${name}.jpg`)

const images = [
  { path: getImage('img1'), caption: 'Fonte: Ministério da Saúde.' },
  { path: getImage('img2'), caption: 'Fonte: Ministério da Saúde.' },
  { path: getImage('img3'), caption: 'Fonte: Ministério da Saúde.' },
  { path: getImage('img4'), caption: 'Fonte: Ministério da Saúde.' },
  { path: getImage('img5'), caption: 'Fonte: Radilson Gomes, Ministério da Saúde.' },
  { path: getImage('img6'), caption: 'Fonte: Radilson Gomes, Ministério da Saúde.' },
  { path: getImage('img7'), caption: 'Fonte: Radilson Gomes, Ministério da Saúde.' },
  { path: getImage('img8'), caption: 'Fonte: Radilson Gomes, Ministério da Saúde.' },
  { path: getImage('img9'), caption: 'Fonte: Radilson Gomes, Ministério da Saúde.' },
  { path: getImage('img10'), caption: 'Fonte: Radilson Gomes, Ministério da Saúde.' },
]

export const InitialContainer = ({ children }) => {
  const imageIndex = useRef(Math.floor(Math.random() * images.length))
  const { classes } = useStyles(createStyles, imageIndex.current)

  return (
    <>
      <div className={classes.container}>
        <div className={classes.contentColumn}>{children}</div>
        <div className={classes.imageColumn}>
          <div className={classes.imageCaption}>{images[imageIndex.current].caption}</div>
        </div>
      </div>
    </>
  )
}

const createStyles = (theme: Theme, imageIndex: number) => ({
  container: {
    display: 'flex',
    height: 'calc(100vh - 42px)', // desconta o tamanho da barra brasil
  },
  contentColumn: {
    background: theme.pallete.surface.main,
    minWidth: '400px',
    width: '22%',
    padding: '4rem 4rem 4rem 4rem',
    overflow: 'auto',
  },
  imageColumn: {
    background: `url(${images[imageIndex].path}) no-repeat center center fixed`,
    backgroundSize: 'cover',
    width: '78%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  imageCaption: {
    opacity: 0.8,
    display: 'inline',
    background: '#f0f0f5',
    color: '#3a3a47',
    height: '36px',
    padding: '0.5rem 1rem',
    margin: '0.5rem 0.5rem',
  } as React.CSSProperties,
})
