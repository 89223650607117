import { Heading, VFlow } from 'bold-ui'
import { PeriogramaCompleto } from 'graphql/types.generated'
import React from 'react'
import { historicoSubsectionStyles } from 'view/atendimentos/detail/historico/model/model-historico'
import { HistoricoEvolucoesOdontoTableRowModel } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTable'
import { HistoricoPeriogramaSimplificadoModel } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/periograma/periograma-simplificado/historico-periograma-simplificado/HistoricoPeriogramaSimplificadoTable'

import { HistoricoOdontogramaTable } from '../odontograma/HistoricoOdontogramaTable'
import { HistoricoPeriogramaCompletoPanel } from './HistoricoPeriogramaCompletoPanel'
import { HistoricoPeriogramaSimplicadoTable } from './HistoricoPeriogramaSimplicadoTable'

interface HistoricoPeriogramaPanelProps {
  evolucoes: HistoricoEvolucoesOdontoTableRowModel[]
  periogramaSimplificado: Omit<HistoricoPeriogramaSimplificadoModel, 'atendimentoProfissionalOdonto'>
  periogramaCompleto: PeriogramaCompleto[]
  observacaoRPC: string
}

export const HistoricoPeriogramaPanel = (props: HistoricoPeriogramaPanelProps) => {
  const { evolucoes, periogramaSimplificado, periogramaCompleto, observacaoRPC } = props

  return (
    <VFlow vSpacing={0.5}>
      <Heading level={4} style={historicoSubsectionStyles.plano}>
        Periodontia
      </Heading>

      <VFlow>
        {evolucoes?.length > 0 && <HistoricoOdontogramaTable evolucoes={evolucoes} />}

        {periogramaSimplificado && (
          <VFlow vSpacing={0.5}>
            <Heading level={4} style={historicoSubsectionStyles.plano}>
              Registro periodontal simplificado
            </Heading>

            <HistoricoPeriogramaSimplicadoTable periogramaSimplificado={periogramaSimplificado} />
          </VFlow>
        )}

        {periogramaCompleto && (
          <HistoricoPeriogramaCompletoPanel periogramaCompleto={periogramaCompleto} observacao={observacaoRPC} />
        )}
      </VFlow>
    </VFlow>
  )
}
