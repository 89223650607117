import { Cell, FormControl, Grid, HFlow, Text } from 'bold-ui'
import { RadioField, SubmitButton, TimeField } from 'components/form'
import { FormRenderProps } from 'components/form/final-form/Form'
import { TextField } from 'components/form/final-form/TextField'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import createDecorator from 'final-form-calculate'
import { CidadaoDeclaracaoComparecimentoQuery } from 'graphql/types.generated'
import moment from 'moment'
import React from 'react'
import { FormSpy } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, hora, maxLength, nome, required } from 'util/validation'

export enum Periodo {
  MATUTINO = 'matutino',
  VESPERTINO = 'vespertino',
  NOTURNO = 'noturno',
  PERSONALIZADO = 'personalizado',
}

type CidadaoDeclaracaoComparecimentoModel = CidadaoDeclaracaoComparecimentoQuery['atendimento']

const path = metaPath<DeclaracaoComparecimentoModel>()

export interface DeclaracaoComparecimentoUrlParams {
  callbackUrl?: string
}

export interface DeclaracaoComparecimentoModel extends CidadaoDeclaracaoComparecimentoModel {
  nomeAcompanhante?: string
  periodo?: Periodo
  horarioEntrada?: string
  horarioSaida?: string
  onSubmit?: FormRenderProps<DeclaracaoComparecimentoModel>['handleSubmit']
}

export const validateDeclaracaoComparecimento = createValidator<DeclaracaoComparecimentoModel>(
  {
    nomeAcompanhante: [nome, maxLength(100)],
  },
  (values: DeclaracaoComparecimentoModel) => {
    const errors: ErrorObject<DeclaracaoComparecimentoModel> = {}
    if (values.periodo === Periodo.PERSONALIZADO) {
      errors.horarioEntrada = required(values.horarioEntrada) || hora(values.horarioEntrada)
      errors.horarioSaida = required(values.horarioSaida) || hora(values.horarioSaida)
    }

    return errors
  }
)

export const horarioEntradaCalculator = (iniciadoEm: string) =>
  createDecorator({
    field: path.periodo.absolutePath(),
    updates: {
      [path.horarioEntrada.absolutePath()]: (periodo) => {
        if (periodo === Periodo.PERSONALIZADO) {
          return moment(iniciadoEm).format('HH:mm')
        }
      },
    },
  })

export const horarioSaidaCalculator = () =>
  createDecorator({
    field: path.periodo.absolutePath(),
    updates: {
      [path.horarioSaida.absolutePath()]: (periodo) => {
        if (periodo === Periodo.PERSONALIZADO) {
          return moment().format('HH:mm')
        }
      },
    },
  })

export const DeclaracaoComparecimentoView = (props: DeclaracaoComparecimentoModel) => {
  return (
    <Grid alignItems='flex-start' gap={0}>
      <Cell size={12}>
        <Text fontSize={1} fontWeight='bold'>
          {props?.cidadao?.nomeSocial || props?.cidadao?.nome}
        </Text>
        <HFlow hSpacing={1}>
          <HLabel title='CPF ' children={<Cpf value={props?.cidadao?.cpf} />} />
          <HLabel title='CNS ' children={props?.cidadao?.cns} />
        </HFlow>
      </Cell>
      <Cell size={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <FormControl label='Período' required>
          <HFlow hSpacing={0.5}>
            <RadioField name={path.periodo} label='Matutino' value={Periodo.MATUTINO} style={{ marginLeft: -1 }} />
            <RadioField name={path.periodo} label='Vespertino' value={Periodo.VESPERTINO} />
            <RadioField name={path.periodo} label='Noturno' value={Periodo.NOTURNO} />
            <RadioField name={path.periodo} label='Horário personalizado' value={Periodo.PERSONALIZADO} />
          </HFlow>
        </FormControl>
      </Cell>

      <FormSpy subscription={{ values: true }}>
        {({ values }) =>
          values.periodo === Periodo.PERSONALIZADO && (
            <>
              <Cell size={4}>
                <TimeField name={path.horarioEntrada} label='Horário de entrada' clearable required />
              </Cell>
              <Cell style={{ padding: '0.5rem' }} />
              <Cell size={4}>
                <TimeField name={path.horarioSaida} label='Horário de saída' clearable required />
              </Cell>
            </>
          )
        }
      </FormSpy>
      <Cell size={7}>
        <TextField name={path.nomeAcompanhante} label='Incluir nome do acompanhante' maxLength={100} />
      </Cell>
      {props.onSubmit && (
        <Cell>
          <SubmitButton
            kind='primary'
            size='small'
            handleSubmit={props.onSubmit}
            style={{ marginLeft: '1rem', marginTop: '1.5rem' }}
          >
            Imprimir
          </SubmitButton>
        </Cell>
      )}
    </Grid>
  )
}
