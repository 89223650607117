/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Icons } from 'bold-ui'
import { Tag, TagProps } from 'bold-ui/lib/components/Tag/Tag'
import React from 'react'

import { PecIcon } from './PecIcon'

// TODO@RNG: Remover esse componente quando a funcionalidade de aceitar svg for implementada no componente Icon do bold
// issue: https://github.com/laboratoriobridge/bold/issues/821
export interface PecTagProps extends Omit<TagProps, 'icon'> {
  icon?: Icons | React.ComponentType<React.SVGProps<SVGSVGElement>>
}

export function PecTag(props: PecTagProps) {
  const { icon, children, ...rest } = props

  return (
    <Tag {...rest}>
      <PecIcon icon={icon} style={styles.icon} />
      {children}
    </Tag>
  )
}

const styles = {
  icon: css`
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  `,
}
