import { useStyles } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { LoadingIndicator } from 'components/loading'
import React, { CSSProperties, useEffect } from 'react'
import { useHistory } from 'react-router'

export function LogoutView() {
  const handleRejection = useErrorHandler()
  const { logoutSuccess } = useSession()
  const history = useHistory()
  const { classes } = useStyles(createStyles)

  useEffect(() => {
    logoutSuccess()
      .then(() => history.replace('/'))
      .catch(handleRejection)
  }, [history, handleRejection, logoutSuccess])

  return (
    <div className={classes.root}>
      <LoadingIndicator message='Efetuando logout...' />
    </div>
  )
}

const createStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '90vh',
  } as CSSProperties,
})
