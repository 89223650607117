import { Icon, Text, Theme, useStyles } from 'bold-ui'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { Box } from 'components/Box'
import { PopperButton } from 'components/popper'
import React, { CSSProperties } from 'react'

import { TipoServicoForm } from './TipoServicoForm'

export interface TipoServicoFormPopperProps {
  reload(): Promise<any>
}

export function TipoServicoFormPopper(props: TipoServicoFormPopperProps) {
  const { reload } = props
  const { classes } = useStyles(createStyles)

  const renderPopper = ({ close }) => {
    return (
      <Box style={classes.popper}>
        <TipoServicoForm onSuccess={composeHandlers(reload, close)} onCancel={close} />
      </Box>
    )
  }

  return (
    <PopperButton kind='primary' size='small' placement='bottom-end' renderPopper={renderPopper}>
      <Icon icon='plus' style={{ marginRight: '0.25rem' }} />
      <Text color='inherit'>Adicionar tipo de serviço</Text>
    </PopperButton>
  )
}

const createStyles = (theme: Theme) => ({
  popper: {
    boxShadow: theme.shadows.outer['40'],
    width: '22rem',
    transform: 'translateX(1.1rem)',
    textAlign: 'left',
    marginTop: '0.5rem',

    [theme.breakpoints.down('lg')]: {
      marginTop: '0.25rem',
    },
  } as CSSProperties,
})
