/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, HFlow, Tag, Text, VFlow } from 'bold-ui'
import theme from 'config/theme'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { ReactComponent as GarantiaAcessoIcon } from 'images/side-menu/garantia-acesso.svg'
import { MetaPath } from 'util/metaPath'
import {
  CuidadoCompartilhadoModel,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoSolicitanteFieldsModel,
  EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel,
  LabelSubtitleGarantiaAcessoRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { EnviarGarantiaAcessoCuidadoCompartilhadoField } from './EnviarGarantiaAcessoCuidadoCompartilhadoField'

interface EnviarGarantiaAcessoCuidadoCompartilhadoPanelProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel>
  garantiaAcessoInput: EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel
  cuidadoCompartilhado: CuidadoCompartilhadoModel
}

export const EnviarGarantiaAcessoCuidadoCompartilhadoPanel = (
  props: EnviarGarantiaAcessoCuidadoCompartilhadoPanelProps
) => {
  const { path, garantiaAcessoInput, cuidadoCompartilhado } = props

  return (
    <div css={styles.fundo}>
      <VFlow vSpacing={0.7}>
        <HFlow justifyContent='space-between'>
          <HFlow hSpacing={0.5} alignItems='center'>
            <GarantiaAcessoIcon />
            <Text fontWeight='bold' fontSize={0.9}>
              Envio de demanda para garantia de acesso
            </Text>
          </HFlow>
          <EnviarGarantiaAcessoCuidadoCompartilhadoField
            name={path.garantiaAcesso}
            pathCondutaGroup={path.condutaGroup}
            tipoAgendamento={garantiaAcessoInput.tipoAgendamento}
            cidadaoAceitaAtendTic={cuidadoCompartilhado.cidadaoAceitaAtendTic}
            isEdit
          />
        </HFlow>
        <Text>
          <Text fontWeight='bold'>Demanda:</Text>{' '}
          {LabelSubtitleGarantiaAcessoRecord[garantiaAcessoInput.tipoAgendamento]}
        </Text>
        <Text style={styles.textMotivo}>
          <Text fontWeight='bold'>Motivo:</Text> {garantiaAcessoInput.motivo}
        </Text>

        {garantiaAcessoInput.tipoAgendamento ===
          CondutaCuidadoCompartilhadoEnum.AGENDADO_TELEINTERCONSULTA_COM_CIDADAO && (
          <Tag type='info' style={styles.tag}>
            Via videochamada e-SUS APS
          </Tag>
        )}
      </VFlow>
    </div>
  )
}

const styles = {
  fundo: css`
    background-color: ${colors.blue.c90};
    margin: 0 -1rem -1rem -1rem;
    padding: 1.5rem 1rem 1rem 1rem;
  `,
  textMotivo: css`
    word-break: break-all;
  `,
  tag: css`
    background-color: ${theme.pallete.gray.c40};
  `,
}
