import {
  AgendamentoCuidadoCompartilhadoInput,
  InterconsultaAgendamentoCuidadoCompartilhadoInput,
  SalvarCuidadoCompartilhadoInput,
} from 'graphql/types.generated'
import { ErrorObject } from 'util/validation'
import {
  AgendarConsultaCuidadoCompartilhadoFieldsModel,
  CondutaGroupEnum,
  CuidadoCompartilhadoModel,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoFormModel,
  DiscussaoCasoSolicitanteFieldsModel,
  TipoAgendamentoCuidadoCompartilhado,
  TrocaExecutanteCuidadoCompartilhadoFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import {
  getResponsabilidadeCuidadoCompartilhado,
  isReclassificandoCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

const convertAgendamentoFormToInput = (
  values: AgendarConsultaCuidadoCompartilhadoFieldsModel['consultaComCidadao']
): AgendamentoCuidadoCompartilhadoInput => ({
  horario: Number(values.horario.inicial),
  observacoes: values.observacoes,
})

const convertConsultaCompartilhadaFormToInput = (
  values: AgendarConsultaCuidadoCompartilhadoFieldsModel['consultaCompartilhada'],
  videochamadaUuid: string
): InterconsultaAgendamentoCuidadoCompartilhadoInput => {
  return {
    horarioFinal: Number(values.horario.final),
    horarioInicial: Number(values.horario.inicial),
    observacoes: values.observacoes,
    cidadaoPresente: values.isCidadaoPresente,
    videochamadaUuid,
  }
}

const convertAgendarConsultaFormToInput = (
  values: DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel,
  videochamadaUuid: string
) => {
  const fieldInput: Partial<SalvarCuidadoCompartilhadoInput> = {}

  if (values.agendamento.tipoAgendamento === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO) {
    fieldInput['agendamento'] = convertAgendamentoFormToInput(values.agendamento.consultaComCidadao)
  } else {
    fieldInput['interconsulta'] = convertConsultaCompartilhadaFormToInput(
      values.agendamento.consultaCompartilhada,
      videochamadaUuid
    )
  }

  return fieldInput
}

const convertExecutanteFieldsFormToInput = (
  values: DiscussaoCasoExecutanteFieldsModel,
  videochamadaUuid: string
): Omit<SalvarCuidadoCompartilhadoInput, 'cuidadoCompartilhadoId'> => {
  let fieldInput = {}

  switch (values?.condutaGroup) {
    case CondutaGroupEnum.DEVOLUTIVA_DISCUSSAO_CASO: {
      fieldInput['devolutiva'] = { ...values.devolutiva }
      break
    }
    case CondutaGroupEnum.TROCA_PROFISSIONAL_REFERENCIA: {
      fieldInput['trocaExecutante'] = {
        lotacaoExecutanteId: values.trocaExecutante.lotacao.id,
        motivo: values.trocaExecutante.motivo,
      }
      break
    }
    case CondutaGroupEnum.AGENDAR_CONSULTA: {
      fieldInput = convertAgendarConsultaFormToInput(values, videochamadaUuid)
      break
    }
    case CondutaGroupEnum.SUGESTAO_AGENDAMENTO_GRUPO: {
      fieldInput['sugestaoAgendamentoGrupo'] = { ...values.sugestaoAgendamentoGrupo }
      break
    }
    case CondutaGroupEnum.ENVIAR_GARANTIA_ACESSO: {
      fieldInput['enviarGarantiaAcesso'] = {
        tipoAgendamento: values.garantiaAcesso.tipoAgendamento,
        motivo: values.garantiaAcesso.motivo,
      }
      break
    }
    default: {
      break
    }
  }

  return fieldInput
}

const convertSolicitanteFieldsFormToInput = (
  values: DiscussaoCasoSolicitanteFieldsModel,
  videochamadaUuid: string
): Omit<SalvarCuidadoCompartilhadoInput, 'cuidadoCompartilhadoId'> => {
  let fieldInput = {}

  switch (values?.condutaGroup) {
    case CondutaGroupEnum.NOVA_PERGUNTA: {
      fieldInput['pergunta'] = { ...values.pergunta }
      break
    }
    case CondutaGroupEnum.TROCA_PROFISSIONAL_REFERENCIA: {
      fieldInput['trocaExecutante'] = {
        lotacaoExecutanteId: values.trocaExecutante.lotacao.id,
        motivo: values.trocaExecutante.motivo,
      }
      break
    }
    case CondutaGroupEnum.AGENDAR_CONSULTA: {
      fieldInput = convertAgendarConsultaFormToInput(values, videochamadaUuid)
      break
    }
    default: {
      break
    }
  }

  return fieldInput
}

export const convertDiscussaoCasoFormModelToSalvarCuidadoCompartilhadoInput = (
  values: DiscussaoCasoFormModel,
  cuidadoCompartilhado: CuidadoCompartilhadoModel,
  lotacaoId: ID,
  videochamadaUuid: string
): SalvarCuidadoCompartilhadoInput => {
  const responsabilidade = getResponsabilidadeCuidadoCompartilhado(
    lotacaoId,
    cuidadoCompartilhado.lotacaoExecutanteAtual.id,
    cuidadoCompartilhado.lotacaoSolicitante.id
  )

  const isReclassificando = isReclassificandoCuidadoCompartilhado(
    cuidadoCompartilhado.classificacaoPrioridadeAtual,
    values.reclassificaoPrioridade
  )

  return {
    cuidadoCompartilhadoId: cuidadoCompartilhado.id,
    reclassificacaoPrioridade: isReclassificando ? values.reclassificaoPrioridade : null,
    ...(responsabilidade === 'EXECUTANTE'
      ? convertExecutanteFieldsFormToInput(values.executanteFields, videochamadaUuid)
      : convertSolicitanteFieldsFormToInput(values.solicitanteFields, videochamadaUuid)),
  }
}

const convertConsultaComCidadaoBackendErrorsToForm = (
  input: SalvarCuidadoCompartilhadoInput['agendamento']
): ErrorObject<AgendarConsultaCuidadoCompartilhadoFieldsModel['consultaComCidadao']> => ({
  horario: {
    inicial: input?.horario,
  },
})

const convertConsultaCopartilhadaBackendErrorsToForm = (
  input: SalvarCuidadoCompartilhadoInput['interconsulta']
): string | ErrorObject<AgendarConsultaCuidadoCompartilhadoFieldsModel['consultaCompartilhada']> => {
  if (input?.videochamadaUuid) {
    return input?.videochamadaUuid
  }

  const hasErrorHorario = input?.horarioFinal || input?.horarioFinal

  if (hasErrorHorario) {
    return {
      horario: {
        inicial: input?.horarioInicial,
        final: input?.horarioFinal,
      },
    }
  }
}

const convertTrocaExecutanteBackendErrorsToForm = (
  input: SalvarCuidadoCompartilhadoInput['trocaExecutante']
): ErrorObject<TrocaExecutanteCuidadoCompartilhadoFieldsModel> => ({
  lotacao: input?.lotacaoExecutanteId,
  motivo: input?.motivo,
})

export const convertDiscussaoCasoBackendErrorsToForm = (
  validationErrors: ErrorObject<SalvarCuidadoCompartilhadoInput>
): ErrorObject<DiscussaoCasoFormModel> => {
  let input = validationErrors as SalvarCuidadoCompartilhadoInput

  return {
    executanteFields: {
      trocaExecutante: convertTrocaExecutanteBackendErrorsToForm(input?.trocaExecutante),
      agendamento: {
        consultaComCidadao: convertConsultaComCidadaoBackendErrorsToForm(input?.agendamento),
        consultaCompartilhada: convertConsultaCopartilhadaBackendErrorsToForm(input?.interconsulta),
      },
    },
    solicitanteFields: {
      trocaExecutante: convertTrocaExecutanteBackendErrorsToForm(input?.trocaExecutante),
      agendamento: {
        consultaComCidadao: convertConsultaComCidadaoBackendErrorsToForm(input?.agendamento),
        consultaCompartilhada: convertConsultaCopartilhadaBackendErrorsToForm(input?.interconsulta),
      },
    },
  }
}
