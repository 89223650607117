import { ApolloQueryResult } from 'apollo-client'
import { Button, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { confirm } from 'components/modals/confirm'
import { useExcluirVinculacaoServicoMutation } from 'graphql/hooks.generated'
import { VinculacoesEstabelecimentosTableQuery } from 'graphql/types.generated'
import React, { SyntheticEvent } from 'react'

import { VinculacoesEstabelecimentosItem } from './VinculacoesEstabelecimentosTable'

interface GrupoExameExcludeActionProps {
  vinculosEstabelecimento: VinculacoesEstabelecimentosItem
  refetch(): Promise<ApolloQueryResult<VinculacoesEstabelecimentosTableQuery>>
}

export function VinculacoesEstabelecimentosExcludeAction(props: GrupoExameExcludeActionProps) {
  const {
    vinculosEstabelecimento: {
      referencia: { id, nome },
    },
    refetch,
  } = props
  const [excluirVinculacaoServico] = useExcluirVinculacaoServicoMutation({
    variables: { referenciaId: id.toString() },
    onCompleted: () => {
      alert('success', 'Vínculo excluído com sucesso.')
      refetch()
    },
  })
  const alert = useAlert()
  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    confirm({
      title: 'Deseja excluir o vínculo?',
      body: `Serão excluídos todos os vínculos entre o estabelecimento ${nome} e as unidades básicas de saúde do município`,
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => excluirVinculacaoServico(),
    })()
  }

  return (
    <Tooltip text='Excluir'>
      <Button size='small' skin='ghost' onClick={onClick}>
        <Icon icon='trashOutline' />
      </Button>
    </Tooltip>
  )
}
