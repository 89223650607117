import { merge } from 'bold-ui'
import { PageInfo, PageParams } from 'graphql/types.generated'
import { Dispatch, SetStateAction, useMemo } from 'react'

export interface CreatePagedTablePropsOptions<T, F> {
  result: T
  loading: boolean
  onChange: Dispatch<SetStateAction<F>>
}

function createPagedTableProps<
  T extends { content: any[]; pageInfo: PageInfo; paginationOptions?: any[] },
  F extends { pageParams?: PageParams }
>(options: CreatePagedTablePropsOptions<T, F>) {
  const { result, onChange: setFilter, loading } = options

  const onPageChange = (page: number) => {
    setFilter((state) => merge({}, state, { pageParams: { page } }))
  }

  const onSizeChange = (size: number) => {
    setFilter((state) => merge({}, state, { pageParams: { page: 0, size } }))
  }

  const onSortChange = (sort: string[]) => {
    setFilter((state) => merge({}, state, { pageParams: { page: 0, sort } }))
  }

  const pageInfo = result ? result.pageInfo : ({} as PageInfo)

  if (pageInfo.number && pageInfo.number > pageInfo.totalPages - 1) {
    // Volta para primeira página caso página do resultado atual seja maior que a última página
    setFilter((state) => merge({}, state, { pageParams: { page: 0 } }))
  }

  return {
    loading,
    rows: result ? result.content : [],
    page: pageInfo.number || 0,
    size: pageInfo.size || 0,
    totalPages: pageInfo.totalPages || 0,
    totalElements: pageInfo.totalElements || 0,
    sort: pageInfo.sort || [],
    sizeOptions: result?.paginationOptions || undefined,
    onPageChange,
    onSizeChange,
    onSortChange,
  }
}

export function usePagedTableProps<
  T extends { content: any[]; pageInfo: PageInfo },
  F extends { pageParams?: PageParams }
>(options: CreatePagedTablePropsOptions<T, F>) {
  const { result, onChange, loading } = options
  return useMemo(() => createPagedTableProps({ result, onChange, loading }), [result, onChange, loading])
}
