/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, Theme, Tooltip, useTheme } from 'bold-ui'
import { ReactComponent as AgendaOnlineIcon } from 'images/agenda/agenda-online.svg'

interface AgendaOnlineMarkerProps {
  styles?: ExternalStyles
}

export function AgendaOnlineMarker(props: AgendaOnlineMarkerProps) {
  const { styles } = props

  const theme = useTheme()
  const { iconeAgendaOnline } = createStyles(theme)

  return (
    <Tooltip text='Agendado pelo cidadão via Conecte SUS Cidadão'>
      <AgendaOnlineIcon css={css(iconeAgendaOnline, styles)} />
    </Tooltip>
  )
}

const createStyles = (theme: Theme) => ({
  iconeAgendaOnline: css`
    fill: ${theme.pallete.primary.c50};
    height: 1rem;
    width: 1rem;
    align-self: center;
  `,
})
