import { Cell, Grid, Heading, Modal, ModalBody, Tabs, useStyles } from 'bold-ui'
import { PageLoading } from 'components/loading'
import { TabLink } from 'components/route'
import { useMedicoesAtendimentosAnterioresQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { MetaArray } from 'util/metaPath'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { CidadaoMedicoes } from 'view/atendimentos/detail/soap/aside/medicoes/model'

import { MedicoesPreNatalAtendimentoModel } from '../types/HistoricoPreNatalModel'
import { PeriodoGestacaoModel } from '../types/PeriodoGestacaoModel'
import { EditableHistoricoMedicoesModalBody, ReadOnlyHistoricoMedicoesModalBody } from './HistoricoMedicoesModalBody'
import { MedicaoAnteriorFormModel } from './MedicaoAnteriorForm'

export interface HistoricoMedicoesModalProps {
  cidadao: CidadaoMedicoes
  prontuarioId: ID
  medicoesAtendimentoAtual?: MedicoesPanelModel
  dataAtendimento?: Instant
  isGestante: boolean
  gestacoes: ReadonlyArray<PeriodoGestacaoModel>
  isAtendimentoObservacao: boolean
  editable?: false
}

export interface EditableHistoricoMedicoesModalProps
  extends Omit<HistoricoMedicoesModalProps, 'editable'>,
    MedicoesPreNatalAtendimentoModel {
  fieldName: MetaArray<MedicaoAnteriorFormModel>
  editable: true
}

export function HistoricoMedicoesModal(props: HistoricoMedicoesModalProps | EditableHistoricoMedicoesModalProps) {
  const { cidadao, prontuarioId, isGestante, gestacoes, isAtendimentoObservacao } = props

  const history = useHistory()
  const match = useRouteMatch()
  const { classes } = useStyles(createStyles)

  const { data, loading } = useMedicoesAtendimentosAnterioresQuery({
    variables: { prontuarioId },
  })

  const onClose = () => {
    return history.push(match.url.replace('/historicoMedicoes', ''))
  }

  const initialRoute = isGestante ? '/pre-natal' : '/antropometria'

  return (
    <Modal open={true} onClose={onClose}>
      <ModalBody>
        <Grid alignItems='flex-start' gap={0}>
          <Cell size={12}>
            <Heading level={1} className={classes.header}>
              Histórico de medições
            </Heading>
          </Cell>
          <Cell size={12}>
            <Tabs>
              {isGestante && <TabLink to={`${match.url}/pre-natal`}>Gestação</TabLink>}
              <TabLink to={`${match.url}/antropometria`}>Antropometria</TabLink>
              <TabLink to={`${match.url}/sinaisVitais`}>Sinais vitais</TabLink>
              <TabLink to={`${match.url}/glicemia`}>Glicemia</TabLink>
            </Tabs>
          </Cell>
          <Cell size={12}>
            {loading ? (
              <PageLoading message='Carregando dados' />
            ) : props.editable ? (
              <EditableHistoricoMedicoesModalBody
                initialRoute={initialRoute}
                match={match}
                medicoes={data?.medicoes}
                medicoesNoNascimento={data?.medicoesNoNascimento}
                isAtendimentoObservacao={isAtendimentoObservacao}
                {...props}
              />
            ) : (
              <ReadOnlyHistoricoMedicoesModalBody
                initialRoute={initialRoute}
                match={match}
                cidadao={cidadao}
                prontuarioId={prontuarioId}
                medicoes={data?.medicoes}
                medicoesNoNascimento={data?.medicoesNoNascimento}
                gestacoes={gestacoes}
                isAtendimentoObservacao={isAtendimentoObservacao}
              />
            )}
          </Cell>
        </Grid>
      </ModalBody>
    </Modal>
  )
}

const createStyles = () => ({
  header: {
    marginBottom: '1rem',
  },
})
