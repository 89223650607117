import { Button, Cell, FormControl, Grid, Icon, Tooltip } from 'bold-ui'
import { Form, FormRenderProps } from 'components/form'
import {
  FichaNotificacaoCasoSuspeitoSelectField,
  FichaNotificacaoSelectModel,
} from 'components/form/field/select/FichasNotificacaoCasoSuspeitoSelectField'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React from 'react'
import { MetaArray, metaPath } from 'util/metaPath'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'
import { EditableList, EditableRow, useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { downloadFichaSinan } from './downloadFichaSinan'

export interface NotificacaoCasoSuspeitoFieldModel extends SoapEditableItem {
  _id: ID
  fichaNotificacao: FichaNotificacaoSelectModel
}

export interface NotificacaoCasoSuspeitoFieldProps<T> {
  name: MetaArray<NotificacaoCasoSuspeitoFieldModel>
  cidadaoId: ID
  dataAtendimento: Instant
}

const meta = metaPath<NotificacaoCasoSuspeitoFieldModel>()

export function NotificacaoCasoSuspeitoField<T = any>(props: NotificacaoCasoSuspeitoFieldProps<T>) {
  const { name, cidadaoId, dataAtendimento, ...rest } = props

  const {
    handleSubmit,
    removeItem,
    input: { value },
  } = useEditableListField({
    name,
  })

  const { id: atendimentoId } = useAtendimentoContext()

  const fichaIsEqual = (ficha: FichaNotificacaoSelectModel) => {
    return (value || []).find((o2) => ficha.id === o2.fichaNotificacao.id)
  }

  const printItem = (itemToPrint: NotificacaoCasoSuspeitoFieldModel) => {
    downloadFichaSinan({
      idNotificacao: itemToPrint.fichaNotificacao.id,
      cidadaoId: cidadaoId,
      dataAtendimento: dataAtendimento,
      atendimentoId: atendimentoId,
    })
  }

  const renderForm = (props: FormRenderProps<NotificacaoCasoSuspeitoFieldModel>) => {
    const handleImprimirClick = () => {
      printItem(props.values)
      return props.form.submit()
    }

    return (
      <Grid>
        <Cell size={8}>
          <FichaNotificacaoCasoSuspeitoSelectField
            name={meta.fichaNotificacao}
            label='Ficha de notificação de caso suspeito'
            cidadaoId={cidadaoId}
            itemIsEqual={fichaIsEqual}
            {...rest}
          />
        </Cell>

        <Cell>
          <FormControl label={<span>&nbsp;</span>}>
            <Tooltip text={!props.values.fichaNotificacao && 'Selecione uma ficha.'} placement='auto'>
              <Button
                size='small'
                kind='primary'
                onClick={handleImprimirClick}
                disabled={!props.values.fichaNotificacao}
              >
                Imprimir
              </Button>
            </Tooltip>
          </FormControl>
        </Cell>
      </Grid>
    )
  }

  return (
    <Grid gapVertical={0.5}>
      <Cell size={12}>
        <Form<NotificacaoCasoSuspeitoFieldModel> render={renderForm} onSubmit={handleSubmit} {...props} />
      </Cell>

      {value?.length > 0 && (
        <Cell size={12}>
          <EditableList>
            {value.map((item) => (
              <NotificacaoRow key={item._id} model={item} onRemove={removeItem} onPrint={printItem} />
            ))}
          </EditableList>
        </Cell>
      )}
    </Grid>
  )
}

interface NotificacaoRowProps {
  model: NotificacaoCasoSuspeitoFieldModel
  onRemove(itemToRemove: NotificacaoCasoSuspeitoFieldModel): void
  onPrint(itemToPrint: NotificacaoCasoSuspeitoFieldModel): void
}

function NotificacaoRow(props: NotificacaoRowProps) {
  const { model, onRemove, onPrint } = props

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(model)

  const handleRemove = () => onRemove(model)
  const handleImprimir = () => onPrint(model)

  return (
    <EditableRow editing={false}>
      <>
        <Grid style={{ margin: 0 }}>
          <Cell size={9} alignSelf='center'>{`${model.fichaNotificacao.nomeNotificacao}`}</Cell>
          <Cell size={3} alignSelf='center' style={{ padding: '0.25rem 0', textAlign: 'right' }}>
            <Tooltip text='Imprimir'>
              <Button size='small' skin='ghost' onClick={handleImprimir}>
                <Icon icon='printerOutline' />
              </Button>
            </Tooltip>
            <Tooltip text={tooltipText}>
              <Button size='small' skin='ghost' onClick={handleRemove} disabled={isEditable}>
                <Icon icon='trashOutline' />
              </Button>
            </Tooltip>
          </Cell>
        </Grid>
      </>
    </EditableRow>
  )
}
