/* eslint-disable no-console */
/**  @jsx jsx*/
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, PagedTable, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Ellipsis } from 'components/Ellipsis'
import { Cpf } from 'components/label'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useProfissionalTableLazyQuery } from 'graphql/hooks.generated'
import { ProfissionaisQueryInput, ProfissionalTableQuery } from 'graphql/types.generated'
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import Permissions from 'types/Permissions'
import { ProfissionalTableFilter } from 'view/profissional/list/ProfissionalTableFilter'

import { ProfissionalTableDropdown } from './ProfissionalTableDropdown'

export type ItemType = ProfissionalTableQuery['profissionais']['content'][0]

export interface ProfissionalTableProps {
  initialFilter?: ProfissionaisQueryInput
  loadOnMount?: boolean
}

const naoTemLotacao = (row: ItemType) => !row.lotacoes?.length && !row.estagios?.length

const naoTemLotacaoAtiva = (row: ItemType) =>
  !row.lotacoes?.some((lotacao) => lotacao.ativo) && !row.estagios?.some((estagio) => estagio.ativo)

const extractCbos = (row: ItemType): { cbo: string; isEstagiario: boolean }[] => [
  ...(row.lotacoes
    ? Array.from(new Set(row.lotacoes.map((lotacao) => lotacao.cbo.nome))).map((cbo) => ({
        cbo: cbo,
        isEstagiario: false,
      }))
    : []),
  ...(row.estagios
    ? Array.from(new Set(row.estagios.map((estagio) => estagio.cbo.nome))).map((cbo) => ({
        cbo: cbo,
        isEstagiario: true,
      }))
    : []),
]

export function ProfissionalTable(props: ProfissionalTableProps) {
  const { initialFilter, loadOnMount } = props
  const { hasAuthorization } = useSession()
  const initialFilterValues = useMemo(
    () => ({
      pageParams: {
        sort: ['nome'],
      },
      ...initialFilter,
      mostrarSemLotacaoAtiva: true,
    }),
    [initialFilter]
  )
  const [filter, setFilter] = useState<ProfissionaisQueryInput>(initialFilterValues)
  const [queryResult, setQueryResult] = useState<ProfissionalTableQuery['profissionais']>()

  const [execute, { loading }] = useProfissionalTableLazyQuery({
    onCompleted: (data) => setQueryResult(data.profissionais),
  })

  const fetchData = useCallback(() => execute({ variables: { input: filter } }), [execute, filter])

  useEffect(() => {
    if (loadOnMount || filter.query?.length > 1 || filter.cboId) {
      fetchData()
    } else {
      setQueryResult(undefined)
    }
  }, [fetchData, filter.cboId, filter.query, loadOnMount])

  const onChange = (value: ProfissionaisQueryInput) => {
    setFilter({ ...filter, ...value })
  }

  const renderNome = (row: ItemType) => {
    return (
      <HFlow hSpacing={0.5} alignItems='center'>
        <Text>{row.nome}</Text>
        {row.usuario && row.usuario.bloqueado && <Tag type='danger'>Login bloqueado</Tag>}
        {naoTemLotacao(row) && <Tag>Sem lotação</Tag>}
        {!naoTemLotacao(row) && naoTemLotacaoAtiva(row) && <Tag>Sem lotação ativa</Tag>}
      </HFlow>
    )
  }

  const renderCpf = (row: ItemType) => <Cpf value={row.cpf} />
  const renderCns = (row: ItemType) => <Text>{row.cns}</Text>
  const renderCbo = (row: ItemType) =>
    extractCbos(row).map((c, index) => (
      <Fragment>
        <Ellipsis
          key={index}
          title={c.isEstagiario ? c.cbo.capitalize() + ' (Estágio)' : c.cbo.capitalize()}
          style={css`
            line-height: 1.2rem;
            display: block;
          `}
        >
          {c.cbo.capitalize()}
          {c.isEstagiario && <Text fontStyle='italic'> (Estágio)</Text>}
        </Ellipsis>
      </Fragment>
    ))
  const renderButtons = (row: ItemType) => (
    <React.Fragment>
      <Tooltip text='Visualizar'>
        <ButtonLink size='small' skin='ghost' to={`/profissional/${row.id}`}>
          <Icon icon='zoomOutline' />
        </ButtonLink>
      </Tooltip>

      {(hasAuthorization(Permissions.visualizarProfissionais.cadastrarEditarEExcluir) ||
        hasAuthorization(Permissions.visualizarProfissionais.redefinirSenha)) && (
        <ProfissionalTableDropdown profissional={row} refetch={fetchData} />
      )}
    </React.Fragment>
  )

  const tableProps = usePagedTableProps({
    loading,
    result: queryResult,
    onChange: setFilter,
  })

  return (
    <VFlow>
      <TableBox header={<ProfissionalTableFilter onChange={onChange} initialValues={initialFilterValues} />}>
        <PagedTable<ItemType>
          {...tableProps}
          data-cy='ProfissionalTable'
          columns={[
            {
              name: 'nome',
              header: 'Profissional',
              render: renderNome,
              sortable: true,
            },
            {
              name: 'cns',
              header: 'CNS',
              render: renderCns,
              sortable: true,
              style: { whiteSpace: 'nowrap' },
            },
            {
              name: 'cpf',
              header: 'CPF',
              render: renderCpf,
              sortable: true,
              style: { whiteSpace: 'nowrap' },
            },
            {
              name: 'cbo',
              header: 'CBO',
              render: renderCbo,
              style: { maxWidth: '200px' },
            },
            {
              name: 'buttons',
              render: renderButtons,
              style: { textAlign: 'right', whiteSpace: 'nowrap' },
            },
          ]}
        />
      </TableBox>
      {(!queryResult || queryResult?.pageInfo?.totalElements === 0) && (
        <Heading style={{ textAlign: 'center' }} level={2}>
          {!queryResult
            ? 'Busque um profissional pelo seu nome, CPF, CNS ou CBO.'
            : 'Nenhum resultado encontrado para os filtros aplicados. Busque novamente.'}
        </Heading>
      )}
    </VFlow>
  )
}
