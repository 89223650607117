/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Heading, VFlow } from 'bold-ui'

interface ResultadoExameListItemContainerProps {
  title: string
  children: React.ReactNode
}

export const ResultadoExameListItemContainer = ({ title, children }: ResultadoExameListItemContainerProps) => {
  return (
    <Box>
      <VFlow vSpacing={0.5}>
        <Heading level={4}>{title.capitalize()}</Heading>

        {children}
      </VFlow>
    </Box>
  )
}

const Box = styled.div`
  padding: 0.5rem 1rem;
`
