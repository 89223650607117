import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'

export interface EscolaridadeSelectModel {
  id: ID
  nome: string
}

export interface EscolaridadeSelectFieldProps
  extends Omit<SelectFieldProps<EscolaridadeSelectModel>, 'items' | 'itemToString'> {}

export function EscolaridadeSelectField(props: EscolaridadeSelectFieldProps) {
  const itemToString = (item: EscolaridadeSelectModel) => item && item.nome

  return (
    <SelectField<EscolaridadeSelectModel>
      items={items.sort((a, b) => (a.nome > b.nome ? 1 : -1))}
      itemToString={itemToString}
      {...props}
    />
  )
}

const items: EscolaridadeSelectModel[] = [
  { id: 1, nome: 'Creche' },
  { id: 2, nome: 'Pré-escola (exceto CA)' },
  { id: 3, nome: 'Classe Alfabetizada - CA' },
  { id: 4, nome: 'Ensino Fundamental 1ª a 4ª séries' },
  { id: 5, nome: 'Ensino Fundamental 5ª a 8ª séries' },
  { id: 6, nome: 'Ensino Fundamental Completo' },
  { id: 7, nome: 'Ensino Fundamental Especial' },
  { id: 8, nome: 'Ensino Fundamental EJA - séries iniciais (Supletivo 1ª a 4ª)' },
  { id: 9, nome: 'Ensino Fundamental EJA - séries finais (Supletivo 5ª a 8ª)' },
  { id: 10, nome: 'Ensino Médio, Médio 2º Ciclo (Científico, Técnico e etc)' },
  { id: 11, nome: 'Ensino Médio Especial' },
  { id: 12, nome: 'Ensino Médio EJA (Supletivo)' },
  { id: 13, nome: 'Superior, Aperfeiçoamento, Especialização, Mestrado, Doutorado' },
  { id: 14, nome: 'Alfabetização para Adultos (Mobral, etc)' },
  { id: 15, nome: 'Nenhum' },
]
