import { fromPairs, partition } from 'lodash'
import { LembreteEvolucaoModel, LembreteModel } from 'view/atendimentos/detail/soap/aside/types/LembreteModel'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { LembreteFormModel } from './components/LembreteForm'

export function mergeSortLembretes(
  lembretesDatabase: LembreteModel[],
  lembretesCache: LembreteFormModel[],
  lotacaoProfissionalAtendimento: LotacaoAtendimento = null
): LembreteModel[] {
  const [lembretesAtualizados, lembretesNovos] = partition(lembretesCache, (lembreteCache) => lembreteCache.lembreteId)

  const lembretesDatabaseComAtualizacoes = mergeComUltimaAtualizacaoCache(
    lembretesDatabase,
    lembretesAtualizados,
    lotacaoProfissionalAtendimento
  )

  return mergeComLembretesNovos(lembretesDatabaseComAtualizacoes, lembretesNovos, lotacaoProfissionalAtendimento)
}

export function mergeComUltimaAtualizacaoCache(
  lembretesDatabase: LembreteModel[],
  lembretesCache: LembreteFormModel[],
  lotacaoProfissionalAtendimento: LotacaoAtendimento
): LembreteModel[] {
  const lembretesCacheByDatabaseId = fromPairs(
    lembretesCache.map((lembreteCache) => [lembreteCache.lembreteId, lembreteCache])
  )

  return lembretesDatabase
    .map((lembreteDatabase) => {
      const lembreteCache = lembretesCacheByDatabaseId[lembreteDatabase.id]

      if (!lembreteCache) {
        return lembreteDatabase
      }

      const evolucaoLembreteCache = createLembreteEvolucaoFromCache(lembreteCache, lotacaoProfissionalAtendimento)

      return {
        ...lembreteDatabase,
        _id: lembreteCache._id,
        historicoLembrete: lembreteDatabase.historicoLembrete
          ? [evolucaoLembreteCache, ...lembreteDatabase.historicoLembrete]
          : [evolucaoLembreteCache],
        ultimoLembrete: evolucaoLembreteCache,
        isRegistradoAgora: true,
      }
    })
    .sort((item0, item1) => {
      const item0Atualizado: Boolean = item0._id
      const item1Atualizado: Boolean = item1._id

      const lembreteEvolucao0 = item0.historicoLembrete ? item0.historicoLembrete[0] : item0.ultimoLembrete
      const lembreteEvolucao1 = item1.historicoLembrete ? item1.historicoLembrete[0] : item1.ultimoLembrete

      if (item0Atualizado && item1Atualizado) {
        return lembreteEvolucao1.data - lembreteEvolucao0.data
      } else if (item0Atualizado) {
        return -1
      } else if (item1Atualizado) {
        return 1
      } else {
        const compareByAtivo = Number(lembreteEvolucao1.ativo) - Number(lembreteEvolucao0.ativo)
        return compareByAtivo === 0 ? lembreteEvolucao1.data - lembreteEvolucao0.data : compareByAtivo
      }
    })
}

export function mergeComLembretesNovos(
  lembretesDatabase: LembreteModel[],
  lembretesCache: LembreteFormModel[],
  lotacaoProfissionalAtendimento: LotacaoAtendimento
): LembreteModel[] {
  const lembretesCacheTransformados: LembreteModel[] = lembretesCache.map((lembreteCache) => {
    const evolucaoLembreteCache = createLembreteEvolucaoFromCache(lembreteCache, lotacaoProfissionalAtendimento)

    return {
      id: null,
      _id: lembreteCache._id,
      historicoLembrete: [evolucaoLembreteCache],
      ultimoLembrete: evolucaoLembreteCache,
      isRegistradoAgora: true,
      profissionalCriador: {
        id: lotacaoProfissionalAtendimento?.id,
      },
    }
  })
  const lembretesCacheTransformadosOrdenados = lembretesCacheTransformados.reverse()
  return [...lembretesCacheTransformadosOrdenados, ...lembretesDatabase]
}

function createLembreteEvolucaoFromCache(
  lembreteCache: LembreteFormModel,
  lotacaoProfissionalAtendimento: LotacaoAtendimento
): LembreteEvolucaoModel {
  return {
    id: null,
    visibilidadeLembrete: lembreteCache.visibilidadeLembrete,
    ativo: lembreteCache.ativo,
    descricao: lembreteCache.descricao,
    data: lembreteCache.data,
    lotacao: {
      id: lotacaoProfissionalAtendimento?.id,
      cbo: lotacaoProfissionalAtendimento?.cbo,
      profissional: lotacaoProfissionalAtendimento?.profissional,
    },
  }
}
