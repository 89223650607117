/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, Text } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { format } from 'date-fns'
import { Fragment } from 'react'

import { InformacoesAdministrativas } from '../../../model/model-historico'
import { HistoricoInfoProfissional } from './HistoricoInfoProfissional'

interface HistoricoInfoAdminPanelProps {
  content: InformacoesAdministrativas
}

export default function HistoricoInfoAdminPanel(props: HistoricoInfoAdminPanelProps) {
  const { content } = props

  const principal = content.profissionalPrincipal
  const compartilhado = content.profissionalCompartilhado

  const hasDadosEstagiario = content.nomeEstagiario
  const hasDadosLocalAtendimento = principal?.cnesUnidadeSaude || content.localAtendimento

  return (
    <Fragment>
      <hr
        css={css`
          margin: 1rem -1rem;
        `}
      />
      <div>
        <Grid gapVertical={0.5} style={styles.grid}>
          <Cell size={12}>
            <Heading level={3}>Informações administrativas</Heading>
          </Cell>
          <HistoricoInfoProfissional
            label={content.stCuidadoCompartilhado && 'Registrado por:'}
            profissional={principal}
          />
          {hasDadosEstagiario && (
            <Cell size={12}>
              <HFlow hSpacing={0} style={styles.separator}>
                <HLabel title='Estagiário:'>{content.nomeEstagiario}</HLabel>
                <Text>{content.cboEstagiario?.titleCase()}</Text>
                {content.nomeEquipeEstagiario ? (
                  <Text>{`${content.nomeEquipeEstagiario} - ${content.ineEquipeEstagiario}`}</Text>
                ) : (
                  <Text>Sem equipe</Text>
                )}
              </HFlow>
            </Cell>
          )}
          {content.stRegistroTardio && (
            <Cell size={12}>
              <Text fontStyle='italic'>
                {`Registro tardio registrado em ${format(content.dataCriacaoRegistro, "dd/MM/yyyy' às 'HH:mm")}`}
              </Text>
            </Cell>
          )}
          {(content.cpfCnsCuidador || content.tipoCuidador) && (
            <Cell size={12}>
              <HLabel title='Cuidador:'>
                <Text>
                  {`${content.tipoCuidador ?? ''}
                  ${
                    content.tipoCuidador && content.cpfCnsCuidador
                      ? ` - ${content.cpfCnsCuidador ?? ''}`
                      : `${content.cpfCnsCuidador ?? ''}`
                  }`}
                </Text>
              </HLabel>
            </Cell>
          )}
          {hasDadosLocalAtendimento && (
            <Cell size={12}>
              <HFlow hSpacing={0} style={styles.separator}>
                {principal?.cnesUnidadeSaude && (
                  <HLabel title='CNES:'>
                    <Text>{`${principal.nomeUnidadeSaude} - ${principal.cnesUnidadeSaude}`}</Text>
                  </HLabel>
                )}
                {content.localAtendimento && (
                  <HLabel title='Local de atendimento:'>
                    <Text>{content.localAtendimento}</Text>
                  </HLabel>
                )}
                {content.codigoInep && (
                  <HLabel title='Local de atividade:'>
                    <Text>{`${content.nomeInep.titleCase()} - ${content.codigoInep}`}</Text>
                  </HLabel>
                )}
              </HFlow>
            </Cell>
          )}
          {content.stAtendimentoCompartilhado && (
            <Cell size={12}>
              <div css={styles.separator}>
                {compartilhado.nome && (
                  <HLabel title='Atendimento compartilhado com:' style={styles.inline}>
                    <Text>{compartilhado.nome}</Text>
                  </HLabel>
                )}
                {compartilhado.cbo && <Text>{compartilhado.cbo.titleCase()}</Text>}
                {compartilhado.ineEquipe && <Text>{`${compartilhado.nomeEquipe} - ${compartilhado.ineEquipe}`}</Text>}

                {content.tipoParticipacaoProfissionalConvidado && (
                  <HLabel title='Forma de participação:' style={styles.inline}>
                    {content.tipoParticipacaoProfissionalConvidado}
                  </HLabel>
                )}
              </div>
            </Cell>
          )}
          {content.profissionalFinalizadorObservacao && (
            <HistoricoInfoProfissional
              profissional={content.profissionalFinalizadorObservacao}
              label='Atendimento finalizado por:'
            />
          )}
        </Grid>
      </div>
    </Fragment>
  )
}

const styles = {
  grid: css`
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  `,
  separator: css`
    & > :not(:last-child):after {
      content: '|';
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  `,
  inline: css`
    display: inline;
  `,
}
