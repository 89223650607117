import { TipoEncaminhamentoExternoEnum } from 'graphql/types.generated'
import moment from 'moment'
import { LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import {
  EncaminhamentoExternoEspecializadoQueryModel,
  EncaminhamentoExternoQueryModel,
} from '../list/EncaminhamentoExternoTable'
import {
  EncaminhamentoExternoEspecializadoModel,
  EncaminhamentoExternoEspecializadoPlanoModel,
  EncaminhamentoExternoFilterModel,
  EncaminhamentoExternoRowModel,
} from '../model'

export function mergeAndFilterEncaminhamentos(
  encaminhamentosEspecializadosDatabase: EncaminhamentoExternoEspecializadoQueryModel[],
  encaminhamentosDatabase: EncaminhamentoExternoQueryModel[],
  encaminhamentosCache: EncaminhamentoExternoEspecializadoPlanoModel,
  profissionalId: ID,
  filtros: EncaminhamentoExternoFilterModel,
  dataReferencia: Instant,
  atendimentoProfissionalId: ID,
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
): EncaminhamentoExternoRowModel[] {
  const encaminhamentosCacheConvertidos =
    encaminhamentosCache.encaminhamentosEspecializadosRecentes?.map((value) =>
      convertEncaminhamentoEspecializadoToEncaminhamentoRowModel(
        value,
        atendimentoProfissionalId,
        dataReferencia,
        lotacao
      )
    ) ?? []
  const encaminhamentosCacheExternoConvertidos =
    encaminhamentosCache.encaminhamentosRecentes?.map((value) =>
      convertEncaminhamentoExternoToEncaminhamentoRowModel(value, atendimentoProfissionalId, dataReferencia, lotacao)
    ) ?? []
  const encaminhamentosEspecializadosDatabaseConvertidos =
    encaminhamentosEspecializadosDatabase?.map(createEncaminhamentosEspecializadosFromDatabase) ?? []
  const encaminhamentosDatabaseConvertidos = encaminhamentosDatabase?.map(encaminhamentosFromDatabase) ?? []

  return [
    ...encaminhamentosCacheConvertidos,
    ...encaminhamentosCacheExternoConvertidos,
    ...encaminhamentosDatabaseConvertidos,
    ...encaminhamentosEspecializadosDatabaseConvertidos,
  ]
    .filter(filterEncaminhamentos(filtros, profissionalId))
    .sort((a, b) => b.atendimentoProfissional.iniciadoEm - a.atendimentoProfissional.iniciadoEm)
}

const filterEncaminhamentos = (filter: EncaminhamentoExternoFilterModel, profissionalId: ID) => (
  value: EncaminhamentoExternoRowModel
) => {
  const somenteMeusFilter = () =>
    !filter.somenteMeus || value?.atendimentoProfissional?.lotacao.profissional.id === profissionalId

  const tipoEncaminhamentoFilter = () =>
    !filter.tipoEncaminhamento || value?.tipoEncaminhamento === filter.tipoEncaminhamento

  const especialidadeFilter = () =>
    !filter?.especialidade || value?.especialidadeSisreg.descricao === filter.especialidade.descricao

  const periodFilter = () =>
    (!filter.periodo?.startDate ||
      moment(value?.atendimentoProfissional?.iniciadoEm).isSameOrAfter(filter.periodo?.startDate, 'date')) &&
    (!filter.periodo?.endDate ||
      moment(value?.atendimentoProfissional?.iniciadoEm).isSameOrBefore(filter.periodo?.endDate, 'date'))

  return somenteMeusFilter() && periodFilter() && tipoEncaminhamentoFilter() && especialidadeFilter()
}

function createEncaminhamentosEspecializadosFromDatabase(
  encaminhamentosDatabase: EncaminhamentoExternoEspecializadoQueryModel
): EncaminhamentoExternoRowModel {
  return {
    id: encaminhamentosDatabase.id,
    classificacaoRiscoEncaminhamento: encaminhamentosDatabase.classificacaoRiscoEncaminhamento,
    atendimentoProfissional: {
      id: encaminhamentosDatabase.atendimentoProfissional.id,
      iniciadoEm: encaminhamentosDatabase.atendimentoProfissional.iniciadoEm,
      lotacao: encaminhamentosDatabase.atendimentoProfissional.lotacao,
    },
    especialidadeSisreg: {
      id: encaminhamentosDatabase.especialidadeSisreg.id,
      descricao: encaminhamentosDatabase.especialidadeSisreg.descricao,
    },
    hipoteseDiagnostica: {
      id: encaminhamentosDatabase.hipoteseDiagnosticoCid10?.id ?? encaminhamentosDatabase.hipoteseDiagnosticoCiap.id,
      nome:
        encaminhamentosDatabase.hipoteseDiagnosticoCid10?.nome ??
        encaminhamentosDatabase.hipoteseDiagnosticoCiap.descricao,
      codigo:
        encaminhamentosDatabase.hipoteseDiagnosticoCid10?.codigo ??
        encaminhamentosDatabase.hipoteseDiagnosticoCiap.codigo,
    },
    isRegistradoAgora: false,
    tipoEncaminhamento: TipoEncaminhamentoExternoEnum.SERVICO_ESPECIALIZADO,
  }
}

function encaminhamentosFromDatabase(
  encaminhamentosDatabase: EncaminhamentoExternoQueryModel
): EncaminhamentoExternoRowModel {
  return {
    id: `${encaminhamentosDatabase.atendimentoProfissional.id}|${encaminhamentosDatabase.tipo}`,
    atendimentoProfissional: {
      id: encaminhamentosDatabase.atendimentoProfissional.id,
      iniciadoEm: encaminhamentosDatabase.atendimentoProfissional.iniciadoEm,
      lotacao: encaminhamentosDatabase.atendimentoProfissional.lotacao,
    },
    isRegistradoAgora: false,
    tipoEncaminhamento: encaminhamentosDatabase.tipo,
  }
}

function convertEncaminhamentoEspecializadoToEncaminhamentoRowModel(
  encaminhamentosCache: EncaminhamentoExternoEspecializadoModel,
  dataReferencia: Instant,
  atendimentoProfissionalId: ID,
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
): EncaminhamentoExternoRowModel {
  return {
    id: encaminhamentosCache._id,
    editingId: encaminhamentosCache?.editingId ?? encaminhamentosCache.editingId,
    classificacaoRiscoEncaminhamento: encaminhamentosCache.classificacaoRiscoEncaminhamento,
    atendimentoProfissional: {
      id: atendimentoProfissionalId,
      iniciadoEm: dataReferencia,
      lotacao: lotacao,
    },
    especialidadeSisreg: {
      id: encaminhamentosCache.especialidadeSisreg.id,
      descricao: encaminhamentosCache.especialidadeSisreg.descricao,
    },
    hipoteseDiagnostica: {
      id: encaminhamentosCache?.cidEncaminhamento?.id ?? encaminhamentosCache.ciapEncaminhamento.id,
      nome: encaminhamentosCache?.cidEncaminhamento?.nome ?? encaminhamentosCache.ciapEncaminhamento.descricao,
      codigo: encaminhamentosCache.cidEncaminhamento?.codigo ?? encaminhamentosCache.ciapEncaminhamento.codigo,
    },
    isRegistradoAgora: true,
    tipoEncaminhamento: TipoEncaminhamentoExternoEnum.SERVICO_ESPECIALIZADO,
  }
}

function convertEncaminhamentoExternoToEncaminhamentoRowModel(
  encaminhamentosCache: TipoEncaminhamentoExternoEnum,
  dataReferencia: Instant,
  atendimentoProfissionalId: ID,
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
): EncaminhamentoExternoRowModel {
  return {
    id: encaminhamentosCache,
    atendimentoProfissional: {
      id: atendimentoProfissionalId,
      iniciadoEm: dataReferencia,
      lotacao: lotacao,
    },
    isRegistradoAgora: true,
    tipoEncaminhamento: encaminhamentosCache,
  }
}
