import { parseISO } from 'date-fns'
import { EstagioCreateInput, EstagioEditViewQuery } from 'graphql/types.generated'

import { EstagioFormModel, NivelEstagioEnum } from './model'

export const convertEstagioModelToInput = (model: EstagioFormModel): EstagioCreateInput => ({
  unidadeSaudeId: model.unidadeSaude.id,
  profissionalId: model.profissionalId,
  lotacaoSupervisoraId: model.lotacaoSupervisora.id,
  equipeId: model.lotacaoSupervisora.equipe?.id,
  cboId: model.cboFormacao?.id || model.lotacaoSupervisora.cbo.id,
  dataInicioVigencia: model.periodoEstagio.startDate,
  dataFimVigencia: model.periodoEstagio.endDate,
  nivelTecnico: !!model.cboFormacao,
  perfisId: [...model.perfis.map((perfil) => perfil.id)],
  instituicaoEnsino: model?.instituicaoEnsino,
})

export const convertEstagioInputToModel = (input: EstagioEditViewQuery['estagio']): EstagioFormModel => ({
  perfis: input.perfis,
  unidadeSaude: input.unidadeSaude,
  nivelEstagio: input.nivelTecnico ? NivelEstagioEnum.TECNICO : NivelEstagioEnum.SUPERIOR,
  lotacaoSupervisora: {
    __typename: input.lotacaoSupervisora.__typename,
    id: input.lotacaoSupervisora.id,
    cbo: input.lotacaoSupervisora.cbo,
    equipe: input.lotacaoSupervisora.equipe
      ? {
          id: input.lotacaoSupervisora.equipe.id,
          ine: input.lotacaoSupervisora.equipe.ine,
          nome: input.lotacaoSupervisora.equipe.nome,
          isAD: false,
        }
      : null,
    profissional: input.lotacaoSupervisora.profissional,
    unidadeSaude: input.lotacaoSupervisora.unidadeSaude,
  },
  cboFormacao: input.nivelTecnico ? input.cbo : input.lotacaoSupervisora.cbo,
  profissionalId: input.profissional.id,
  periodoEstagio: {
    startDate: parseISO(input.dataInicioVigencia),
    endDate: parseISO(input.dataFimVigencia),
  },
  instituicaoEnsino: input.instituicaoEnsino,
})
