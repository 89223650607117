import { Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import React from 'react'

interface RetificaAtendimentoErrorModalProps {
  modalRetificaErrorOpen: boolean
  setModalRetificaErrorOpen: (arg: boolean) => void
}

export default function RetificaAtendimentoErrorModal(props: RetificaAtendimentoErrorModalProps) {
  const { modalRetificaErrorOpen, setModalRetificaErrorOpen } = props
  return (
    <Modal open={modalRetificaErrorOpen} onClose={() => setModalRetificaErrorOpen(false)} size='small'>
      <ModalBody>
        <VFlow>
          <Heading level={2}>
            <HFlow alignItems='center'>
              <Icon icon='exclamationTriangleOutline' size={3} fill='alert' />
              Atenção
            </HFlow>
          </Heading>
          <Text>
            O atendimento de vacinação não pôde ser excluído pois não há conexão com a RNDS. Tente novamente mais tarde.
          </Text>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <FooterButton kind='primary' onClick={() => setModalRetificaErrorOpen(false)} size='medium'>
            Ok
          </FooterButton>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
