import { Cell, Grid } from 'bold-ui'
import { css } from 'emotion'
import { LoteImunobiologicoQueryInput } from 'graphql/types.generated'
import React from 'react'

import LoteImunobiologicoFormPopper from '../insert/LoteImunobiologicoFormPopper'
import { LoteImunobiologicoTableFilter } from './LoteImunobiologicoTableFilter'

export interface LoteImunobiologicoTableHeaderProps {
  initialValues?: LoteImunobiologicoQueryInput
  onChange(filter: LoteImunobiologicoQueryInput): void
  reload(): Promise<any>
}

export function LoteImunobiologicoTableHeader(props: LoteImunobiologicoTableHeaderProps) {
  const { onChange, initialValues, reload } = props

  return (
    <Grid justifyContent='space-between'>
      <Cell size={10}>
        <LoteImunobiologicoTableFilter onChange={onChange} initialValues={initialValues} />
      </Cell>
      <Cell
        size={2}
        style={css`
          justify-content: end;
          display: flex;
        `}
      >
        <LoteImunobiologicoFormPopper reload={reload} />
      </Cell>
    </Grid>
  )
}
