import { css } from '@emotion/core'
import { Theme } from 'bold-ui'
import { differenceInMonths } from 'date-fns'
import {
  FaixaEtariaConsumoAlimentar,
  OpcaoTipoPerguntaEnum,
  RespostaMarcadoresConsumoAlimentar,
} from 'graphql/types.generated'

import {
  MarcadoresConsumoAlimentarQuestions,
  mcaDoisAnosOuMaisQuestions,
  mcaMenoresDeSeisMesesQuestions,
  mcaSeisAVinteETresMesesQuestions,
} from './model'

export const anyFilledValue = <T>(questions: string[], values: T) =>
  values && Object.entries(values).some(([k, v]: [string, any]) => questions.includes(k) && !!v?.length)

export const getQuestionsByFaixaEtariaConsumoAlimentar = (
  faixaEtaria: FaixaEtariaConsumoAlimentar
): MarcadoresConsumoAlimentarQuestions => {
  switch (faixaEtaria) {
    case FaixaEtariaConsumoAlimentar.MENOR_SEIS_MESES:
      return mcaMenoresDeSeisMesesQuestions
    case FaixaEtariaConsumoAlimentar.MAIOR_SEIS_MESES_MENOR_DOIS_ANOS:
      return mcaSeisAVinteETresMesesQuestions
    case FaixaEtariaConsumoAlimentar.MAIOR_DOIS_ANOS:
      return mcaDoisAnosOuMaisQuestions
  }
}

export const getPrimeiraOpcaoSelecionada = (input: RespostaMarcadoresConsumoAlimentar): OpcaoTipoPerguntaEnum => {
  return input?.opcoesSelecionadas.first()
}

export const calculateFaixaEtariaMarcadoresConsumoAlimentar = (
  dataNascimento: Date,
  dataReferencia: Date
): FaixaEtariaConsumoAlimentar => {
  const idadeEmMeses: number = differenceInMonths(dataReferencia, dataNascimento)
  if (idadeEmMeses < 6) {
    return FaixaEtariaConsumoAlimentar.MENOR_SEIS_MESES
  } else if (idadeEmMeses >= 6 && idadeEmMeses < 24) {
    return FaixaEtariaConsumoAlimentar.MAIOR_SEIS_MESES_MENOR_DOIS_ANOS
  } else {
    return FaixaEtariaConsumoAlimentar.MAIOR_DOIS_ANOS
  }
}

export const createStyles = (theme: Theme) => ({
  sectionHeading: css`
    padding: 1rem;
    background-color: ${theme.pallete.surface.background};
  `,
  border: css`
    padding: 1rem;
    justify-content: space-between;
    &:not(:last-child) {
      border-bottom: 1px solid ${theme.pallete.divider};
    }
  `,
})
