import { Cell, Grid, Heading, InfoLabel, Text, VFlow } from 'bold-ui'
import React from 'react'

import { ValidationErrorModalItemModel } from './model'

interface ValidationErrorsModalItemProps {
  item: ValidationErrorModalItemModel
  expanded: boolean
}

export const ValidationErrorsModalItem = (props: ValidationErrorsModalItemProps) => {
  const { item, expanded } = props

  if (!item.label && !item.error && !item.fields) return null

  const isFieldsExpanded = item.fields?.length === 1

  return (
    <>
      {item.fields?.length > 0 || !item.showLabelOnModal ? (
        <Cell size={12}>
          <VFlow vSpacing={0.25}>
            {item.showLabelOnModal && <Heading level={4}>{item.label}</Heading>}

            {item.error && <Text>{item.error}</Text>}

            {item.fields?.isNotEmpty() && (
              <Grid gapVertical={0.5}>
                {item.fields.map((field) => (
                  <ValidationErrorsModalItem key={field.label} item={field} expanded={isFieldsExpanded} />
                ))}
              </Grid>
            )}
          </VFlow>
        </Cell>
      ) : (
        <Cell size={expanded ? 12 : 6}>
          <InfoLabel title={item.showLabelOnModal ? item.label : ''}>{item.error}</InfoLabel>
        </Cell>
      )}
    </>
  )
}
