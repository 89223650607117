import { CidadaoSelectField, CidadaoSelectFieldModel, Form } from 'components/form'
import React from 'react'
import { useHistory } from 'react-router'

export function AgendaCidadaoForm() {
  const history = useHistory()
  const handleClickCidadao = (cidadao?: CidadaoSelectFieldModel) =>
    cidadao && history.push(`/cidadao/${cidadao.id}/agendamentos`)

  return (
    <Form<CidadaoSelectFieldModel>
      render={() => (
        <CidadaoSelectField
          name='cidadao'
          label='Agenda do cidadão'
          onChange={handleClickCidadao}
          menuMinWidth={500}
          popperProps={{ placement: 'top-start' }}
        />
      )}
    />
  )
}
