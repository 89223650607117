/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tooltip } from 'bold-ui'
import { blue, green, red, yellow } from 'bold-ui/lib/styles/colors'
import { ClassificacaoRiscoEncaminhamentoEnum } from 'graphql/types.generated'
import { useMemo } from 'react'

interface StatusClassificacaoRiscoEncaminhamentoProps {
  status: ClassificacaoRiscoEncaminhamentoEnum
  size?: number
  showTooltip?: boolean
}

interface StatusCorDescricao {
  cor: string
  descricao: string
}

const classificacaoRiscoColors: Record<ClassificacaoRiscoEncaminhamentoEnum, StatusCorDescricao> = {
  EMERGENCIA: { cor: red.c40, descricao: 'Emergência' },
  URGENCIA: { cor: yellow.c80, descricao: 'Urgência' },
  PRIORITARIO: { cor: green.c40, descricao: 'Prioritário' },
  ELETIVO: { cor: blue.c40, descricao: 'Eletivo' },
}

export function StatusClassificacaoRiscoEncaminhamento(props: StatusClassificacaoRiscoEncaminhamentoProps) {
  const { status, size = 1, showTooltip = true } = props

  const { cor, descricao } = classificacaoRiscoColors[status]

  const classes = useMemo(() => createStyles(cor, size), [cor, size])

  return (
    <Tooltip text={showTooltip && descricao}>
      <div css={classes.classificacaoRiscoBox} />
    </Tooltip>
  )
}

const createStyles = (cor: string, size: number) => ({
  classificacaoRiscoBox: css`
    width: ${size}rem;
    height: ${size}rem;
    border-radius: 2px;
    background-color: ${cor};
  `,
})
