import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { PageLoading } from 'components/loading'
import { useAcessoHasVinculacaoEquipeNasfQuery } from 'graphql/hooks.generated'
import React from 'react'
import { Redirect, Switch, useRouteMatch } from 'react-router'
import { isCboGerente } from 'types/NivelCbo'

import { LCC_TABS_ROUTE } from '../CuidadosCompartilhadosRootView'
import { ListaCuidadoCompartilhadoGerenteView } from './ListaCuidadoCompartilhadoGerenteView'

export const ListaCuidadoCompartilhadoRootView = () => {
  const match = useRouteMatch()

  const {
    data: { acessoHasVinculacaoEquipeNasf },
    loading,
  } = useAcessoHasVinculacaoEquipeNasfQuery()

  const { acesso } = useAcessoLotacaoOrEstagio()
  const isGerenteUbs = isCboGerente(acesso.cbo.cbo2002)

  const defaultTabOpen = acessoHasVinculacaoEquipeNasf ? LCC_TABS_ROUTE['SOLICITANTE'] : LCC_TABS_ROUTE['EXECUTANTE']

  return loading ? (
    <PageLoading message='Carregando...' />
  ) : (
    <>
      {!isGerenteUbs && (
        <Switch>
          <Redirect path={`${match.url}`} to={`${match.url}/${defaultTabOpen}`} />
        </Switch>
      )}
      <ListaCuidadoCompartilhadoGerenteView />
    </>
  )
}
