import { useCallback, useEffect, useState } from 'react'

interface DisplayMediaStreamOptions {
  video?: boolean
  audio?: boolean
  selfBrowserSurface?: 'exclude' | 'include'
  surfaceSwitching?: 'exclude' | 'include'
  systemAudio?: 'exclude' | 'include'
}

declare global {
  interface MediaDevices {
    getDisplayMedia(options: DisplayMediaStreamOptions): Promise<MediaStream>
  }
}

interface UseUserMediaOptions {
  video: boolean
  audio: boolean
  onStreamClose?(): void
  onError?(err: any): void
}

export function useDisplayMedia(options: UseUserMediaOptions) {
  const { audio, video, onStreamClose, onError } = options

  const [stream, setStream] = useState<MediaStream>(null)
  const [audioAvailable, setAudioAvailable] = useState(false)
  const [videoAvailable, setVideoAvailable] = useState(false)

  const getDisplayStream = useCallback(
    async ({ audio, video, onStreamClose, onError }: UseUserMediaOptions) => {
      if (!navigator.mediaDevices) return

      try {
        const displayStream = await navigator.mediaDevices.getDisplayMedia({
          video,
          audio,
          selfBrowserSurface: 'exclude',
          surfaceSwitching: 'include',
          systemAudio: audio ? 'include' : 'exclude',
        })

        const audioTracks = displayStream.getAudioTracks()
        const videoTracks = displayStream.getVideoTracks()

        setAudioAvailable(!!audioTracks.length)
        setVideoAvailable(!!videoTracks.length)

        setStream(displayStream)

        videoTracks.forEach((track) =>
          track.addEventListener('ended', () => {
            setStream(null)
            onStreamClose?.()
          })
        )
      } catch (err) {
        setAudioAvailable(false)
        setVideoAvailable(false)
        setStream(null)
        onError?.(err)
      }
    },
    [setAudioAvailable, setVideoAvailable]
  )

  useEffect(() => {
    if (!video) {
      setStream(null)
      onStreamClose?.()
    } else getDisplayStream({ audio, video, onStreamClose, onError })
  }, [getDisplayStream, audio, video, onStreamClose, onError])

  //Para de usar o display ao destruir o componente
  useEffect(() => () => stream?.getTracks().forEach((track) => track.stop()), [stream])

  return { stream, audioDeviceAvailable: audioAvailable, videoDeviceAvailable: videoAvailable }
}
