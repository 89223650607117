import useSession from 'components/auth/useSession'
import { useEffect } from 'react'
import { subscribe } from 'util/multitab'

export function SessionMultitabListener() {
  const session = useSession()

  useEffect(() => {
    subscribe(event => {
      switch (event) {
        case 'LOGIN':
        case 'PERFIL_SELECIONADO':
          session.refresh()
          break
        case 'LOGOUT':
          session.afterLogout()
          break
      }
    })
  }, [session])

  return null
}
