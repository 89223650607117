/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente33(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='38' height='117' viewBox='0 0 38 117' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M29.8636 66.8419C30.542 56.4221 31.6625 43.3505 32.3716 37.9772C28.3455 35.4227 21.2867 33.6723 15.1846 32.9178C12.0764 32.5335 9.2568 32.4127 7.25901 32.5664C6.2528 32.6438 5.49565 32.7882 5.02012 32.9807C4.78129 33.0774 4.65515 33.17 4.59753 33.2354C4.55591 33.2827 4.55894 33.3008 4.56227 33.3207L4.56242 33.3217C4.75521 34.4797 5.56844 38.1978 6.60447 42.9346C6.8778 44.1842 7.16663 45.5047 7.46366 46.8679C8.88317 53.3822 10.4847 60.846 11.4529 66.0968C13.3837 76.5688 17.7221 95.2405 21.9793 106.717C23.0327 109.557 24.1797 111.336 25.2514 112.356C26.3182 113.371 27.2751 113.606 28.0054 113.464C28.7455 113.32 29.3826 112.765 29.7573 111.938C30.131 111.114 30.2244 110.051 29.8859 108.983C29.6817 108.339 29.5266 107.362 29.4061 106.157C29.2846 104.942 29.1953 103.462 29.1329 101.794C29.0081 98.4571 28.9902 94.3475 29.0407 90.0589C29.1417 81.4816 29.5169 72.166 29.8636 66.8419Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M30.8096 39.9801C31.5741 38.7605 32.3745 36.6104 33.0957 33.9792C33.813 31.362 34.44 28.3147 34.8739 25.3327C35.3081 22.3484 35.5464 19.4455 35.4926 17.1139C35.4657 15.9471 35.3659 14.9392 35.1873 14.1423C35.0052 13.3297 34.7561 12.8063 34.4837 12.5354C33.0069 11.0663 30.0778 8.54617 26.6084 6.39689C23.1257 4.23938 19.1713 2.50159 15.6254 2.50159C8.53077 2.50159 2.05291 8.85725 2.5244 16.6756L2.52732 16.7241C2.77199 20.7814 3.09542 26.1447 3.96368 30.7283C4.39972 33.0302 4.96835 35.1076 5.71909 36.7131C6.476 38.3318 7.37656 39.3826 8.42541 39.7778C10.848 40.6905 15.7436 41.9654 20.424 42.3719C22.7644 42.5751 25.0216 42.5584 26.8767 42.1856C28.7432 41.8104 30.111 41.0943 30.8096 39.9801Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32.9093 37.7303C32.2058 42.8725 31.0546 56.246 30.3626 66.8744C29.6705 77.5028 28.863 104.101 30.3626 108.832C31.8621 113.563 25.7825 118.407 21.5106 106.891C17.2387 95.3748 12.8941 76.6706 10.9612 66.1875C9.79358 59.8546 7.70137 50.2892 6.11915 43.0555C5.14816 38.6162 4.36923 35.0551 4.11393 33.658C2.74105 28.5749 2.32553 21.6843 2.02909 16.7684L2.02531 16.7057C1.53611 8.59361 8.24913 2.00159 15.6254 2.00159C23.0017 2.00159 31.8356 9.19583 34.8363 12.1809C37.4371 14.7681 35.2441 30.091 32.5148 37.4768C32.6494 37.5604 32.781 37.6449 32.9093 37.7303ZM34.7859 37.0387C36.0548 33.2038 37.139 27.9569 37.6621 23.2503C37.951 20.6509 38.078 18.1374 37.9508 16.0638C37.8874 15.0292 37.7574 14.0443 37.5263 13.1871C37.3062 12.3708 36.9376 11.4502 36.2468 10.763C34.652 9.17649 31.5752 6.53297 27.9249 4.27165C24.3277 2.0432 19.8825 0.00158691 15.6254 0.00158691C7.12256 0.00158691 -0.531124 7.53903 0.0289402 16.8261L0.0393018 16.998C0.330375 21.8259 0.752489 28.8275 2.16163 34.0995C2.43714 35.5817 3.20685 39.1007 4.14496 43.3897L4.16534 43.4828C5.75041 50.7297 7.83412 60.2573 8.99437 66.5501C10.9354 77.0777 15.3048 95.9119 19.6355 107.587C20.7619 110.623 22.0789 112.788 23.5283 114.167C24.9974 115.565 26.7378 116.257 28.4825 115.918C30.1883 115.586 31.4043 114.36 32.0344 112.97C32.669 111.57 32.7896 109.87 32.2691 108.228C32.1486 107.848 32.012 107.091 31.8937 105.908C31.7793 104.764 31.6926 103.341 31.6312 101.7C31.5086 98.4218 31.4903 94.3578 31.5406 90.0884C31.6411 81.55 32.0149 72.279 32.3583 67.0043C33.0502 56.3795 34.1977 43.0675 34.8909 38.0014C34.9362 37.6703 34.8972 37.3405 34.7859 37.0387Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
