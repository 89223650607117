import CheckPermission from 'components/auth/CheckPermission'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { ButtonLink } from 'components/route'
import React from 'react'
import Permissions from 'types/Permissions'

import { UnidadeSaudeTable } from './UnidadeSaudeTable'

export function UnidadeSaudeListView() {
  return (
    <>
      <PageHeaderSection
        title='Unidades de saúde'
        action={
          <CheckPermission permission={Permissions.importarCnes}>
            <ButtonLink to={`/importarCnes`} kind='primary'>
              Importar CNES
            </ButtonLink>
          </CheckPermission>
        }
      />

      <PageContent>
        <UnidadeSaudeTable />
      </PageContent>
    </>
  )
}
