/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core'
import { Cell, Grid, Theme, useTheme } from 'bold-ui'
import { useAlert } from 'components/alert'
import { PageContent } from 'components/layout/PageContent'
import { ExternalUserHeader } from 'components/user/ExternalUserHeader'
import { keyframes } from 'emotion'
import { useNotificarConexaoVideochamadaFalhouMutation } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { Fragment, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { ChatVideochamada } from './componentes/ChatVideochamada'
import { NewMessagesPreview } from './componentes/NewMessagesPreview'
import { SolicitacaoEntrarVideochamadaListener } from './componentes/SolicitacaoEntrarVideochamadaListener'
import { StreamPlayersVideochamadaLayout } from './componentes/StreamPlayersVideochamadaLayout'
import { VideoChamadaFooter } from './componentes/VideochamadaFooter'
import { useConfiguracoesVideochamada } from './hooks/useConfiguracoesVideochamada'
import { useMediaDevicesConfiguration } from './hooks/useMediaDevicesConfiguration'
import { useMediaSession } from './hooks/useMediaSession'
import { useScreenShare } from './hooks/useScreenShare'
import { useUserMedia } from './hooks/useUserMedia'
import { useWebRtc, WEBRTC_CONNECTION_FAILURE_MESSAGE, WEBRTC_ICE_CONNECTION_FAILURE_MESSAGE } from './hooks/useWebRtc'
import {
  ChatMessage,
  ConfiguracoesVideochamadaModel,
  LocalVideocallParticipant,
  VideocallStream,
} from './model-videochamada'
import { getVideocallStreams } from './utils-videochamada'

const MAX_MESSAGE_HEIGHT = 114
const VIDEOCHAMADA_NETWORK_TRESHOLD = 500

interface VideochamadaViewProps {
  selfData: LocalVideocallParticipant
  isOwner: boolean
  videochamadaUuid: ID
  audioEnabled: boolean
  videoEnabled: boolean
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
}

declare global {
  interface NetworkInformation extends EventTarget {
    readonly downlink: number
  }

  interface Navigator extends NetworkInformation {
    connection?: NetworkInformation
  }
}

export function VideochamadaView(props: VideochamadaViewProps) {
  const { videochamadaUuid, selfData, audioEnabled, videoEnabled, setAudioEnabled, setVideoEnabled, isOwner } = props
  const { analytics } = useFirebase()

  const selfId = selfData?.id
  const [chatOpen, setChatOpen] = useState(false)
  const [isChatTransitioning, setIsChatTransitioning] = useState(false)
  const [pipActive, setPipActive] = useState(false)
  const [slowConnection, setSlowConnection] = useState(false)
  const [hasNewMessages, setHasNewMessages] = useState(false)
  const [newMessages, dispatchNewMessages] = useReducer(newMessagesReducer, [])

  const messagesPreviewContainerRef = useRef<HTMLDivElement>()
  const messagesPreviewRef = useRef<HTMLDivElement>()

  useEffect(() => {
    setIsChatTransitioning(true)
    dispatchNewMessages({ type: 'remove_all' })
    setHasNewMessages(false)
  }, [chatOpen])

  const alert = useAlert()
  const theme = useTheme()
  const styles = createStyles(theme)
  const history = useHistory()
  const match = useRouteMatch()

  const [configuracoesVideochamada, setConfiguracoesVideochamada] = useConfiguracoesVideochamada()

  const { stream: localStream, audioDeviceAvailable, videoDeviceAvailable, mediaDevices } = useUserMedia({
    video: videoEnabled,
    audio: audioEnabled,
    videoDeviceId: configuracoesVideochamada ? configuracoesVideochamada.videoInput?.id : 'default',
    audioDeviceId: configuracoesVideochamada ? configuracoesVideochamada.audioInput?.id : 'default',
  })

  const { mediaDevicesConfiguration, setStoredAudioDevice, setStoredVideoDevice } = useMediaDevicesConfiguration({
    mediaDevices,
    audioEnabled,
    videoEnabled,
  })

  const handleSetConfiguracoesVideochamada = (config: ConfiguracoesVideochamadaModel) => {
    setConfiguracoesVideochamada(config)
    setStoredAudioDevice(config.audioInput)
    setStoredVideoDevice(config.videoInput)
  }

  const handleCameraToggle = useCallback(() => setVideoEnabled(!videoEnabled), [setVideoEnabled, videoEnabled])
  const handleMicrophoneToggle = useCallback(() => setAudioEnabled(!audioEnabled), [audioEnabled, setAudioEnabled])

  useMediaSession({
    isCameraActive: videoEnabled,
    isMicrophoneActive: audioEnabled,
    onCameraToggle: handleCameraToggle,
    onMicrophoneToggle: handleMicrophoneToggle,
  })

  const { shareScreen, stopSharingScreen, sharingScreen, displayStream } = useScreenShare()

  const handlePeerDisconnected = useCallback(
    (peerId: ID) =>
      !isOwner &&
      peerId === selfId &&
      navigator.onLine &&
      !window.location.pathname.endsWith('/sair') &&
      history.push('/videochamada/encerrada'),
    [isOwner, selfId, history]
  )

  const handleNewMessage = useCallback(
    (newMessage: ChatMessage) => {
      const containerHeight = messagesPreviewContainerRef.current ? messagesPreviewContainerRef.current.clientHeight : 0
      const messagesHeight = messagesPreviewRef.current ? messagesPreviewRef.current.clientHeight : 0

      if (!chatOpen && containerHeight - messagesHeight > MAX_MESSAGE_HEIGHT) {
        dispatchNewMessages({ type: 'add', message: newMessage })
        setHasNewMessages(true)

        setTimeout(() => {
          dispatchNewMessages({ type: 'remove_last' })
        }, 5000)
      }
    },
    [chatOpen]
  )

  const [notificarConexaoVideochamadaFalhou] = useNotificarConexaoVideochamadaFalhouMutation()

  const handleConnectionFail = useCallback(
    async (error: Error) => {
      if (
        isOwner &&
        (error?.message === WEBRTC_CONNECTION_FAILURE_MESSAGE ||
          error?.message === WEBRTC_ICE_CONNECTION_FAILURE_MESSAGE)
      ) {
        await notificarConexaoVideochamadaFalhou({ variables: { videochamadaUuid } })
        analytics.logEvent('TELE_ERR_videochamadas_conexao_falhou')
      }
    },
    [analytics, isOwner, notificarConexaoVideochamadaFalhou, videochamadaUuid]
  )

  const localStreams = useMemo(() => [localStream, displayStream].filterNotNull(), [displayStream, localStream])
  const {
    remoteParticipants,
    sendMessage,
    messages,
    addPresentingStreamId,
    removePresentingStreamId,
    presentingStreamsIds,
  } = useWebRtc({
    selfData,
    roomId: videochamadaUuid,
    localStreams,
    onPeerDisconnected: handlePeerDisconnected,
    onNewRemoteMessage: handleNewMessage,
    onConnectionFail: handleConnectionFail,
  })

  const handleConnectionChange = useCallback(
    () => !navigator.onLine && history.push(`${match.url}/perda-conexao?owner=${isOwner}`),
    [history, match.url, isOwner]
  )

  useEffect(() => {
    window.addEventListener('offline', handleConnectionChange)

    return () => {
      window.removeEventListener('offline', handleConnectionChange)
    }
  }, [handleConnectionChange])

  useEffect(() => {
    if (displayStream) addPresentingStreamId(displayStream.id)
    return () => {
      displayStream && removePresentingStreamId(displayStream.id)
    }
  }, [addPresentingStreamId, displayStream, removePresentingStreamId])

  const videocallStreams: VideocallStream[] = useMemo(
    () => getVideocallStreams(selfId, localStream, remoteParticipants, displayStream, isOwner, presentingStreamsIds),
    [displayStream, isOwner, localStream, presentingStreamsIds, remoteParticipants, selfId]
  )

  const [remoteVideoEnabled, setRemoteVideoEnabled] = useState(false)
  useEffect(() => {
    const remoteStreams = videocallStreams
      .filter((stream) => stream.remote && !!stream.stream)
      .map((stream) => stream.stream)

    const handleChangeTracks = () =>
      setRemoteVideoEnabled(remoteStreams.some((stream) => stream.getVideoTracks().some((track) => track.enabled)))

    handleChangeTracks()
    remoteStreams.forEach((stream) => {
      stream.addEventListener('addtrack', handleChangeTracks)
      stream.addEventListener('removetrack', handleChangeTracks)
    })

    return () => {
      remoteStreams.forEach((stream) => {
        stream.removeEventListener('addtrack', handleChangeTracks)
        stream.removeEventListener('removetrack', handleChangeTracks)
      })
    }
  }, [videocallStreams])

  useEffect(() => {
    if (navigator.connection?.downlink) {
      const checkSlowConnection = () => {
        const bitrate = navigator.connection.downlink * 1000
        const isSlowConnection = bitrate < VIDEOCHAMADA_NETWORK_TRESHOLD
        setSlowConnection(isSlowConnection)
      }
      checkSlowConnection()
      const intervalCheckSlowConnection = setInterval(checkSlowConnection, 10000)

      return () => clearInterval(intervalCheckSlowConnection)
    }
  }, [])

  useEffect(() => {
    if (slowConnection) alert('warning', 'A conexão pode estar prejudicada devido à lentidão na rede.')
  }, [alert, slowConnection])

  return (
    <Fragment>
      <Global styles={styles.global} />
      <ExternalUserHeader primary />
      <PageContent style={styles.pageContent} containerStyle={styles.pageContainer} fluid>
        <Grid
          direction='column'
          justifyContent='center'
          alignItems='stretch'
          style={css`
            flex-grow: 1;
          `}
          gapVertical={0.5}
        >
          <Cell flexGrow={1}>
            <Grid wrap={false} gap={0.5} style={styles.content}>
              <Cell size={chatOpen ? 9 : 12} style={styles.messagesPreviewAndPlayersContainer}>
                <StreamPlayersVideochamadaLayout
                  streams={videocallStreams}
                  pipActive={pipActive}
                  setPipActive={setPipActive}
                />
                {!chatOpen && (
                  <NewMessagesPreview
                    newMessages={newMessages}
                    containerRef={messagesPreviewContainerRef}
                    messagesRef={messagesPreviewRef}
                  />
                )}
              </Cell>
              {chatOpen && (
                <Cell style={styles.chatContainer}>
                  <ChatVideochamada
                    open={isChatTransitioning || chatOpen}
                    messages={messages}
                    onClose={() => setChatOpen(false)}
                    onSendMessage={sendMessage}
                    onAnimationEnd={() => setIsChatTransitioning(false)}
                    style={styles.chat}
                  />
                </Cell>
              )}
            </Grid>
          </Cell>
          <Cell>
            <VideoChamadaFooter
              isOwner={isOwner}
              audioDeviceAvailable={audioDeviceAvailable}
              audioEnabled={audioEnabled}
              setAudioEnabled={setAudioEnabled}
              videoDeviceAvailable={videoDeviceAvailable}
              videoEnabled={videoEnabled}
              setVideoEnabled={setVideoEnabled}
              videochamadaUuid={videochamadaUuid}
              onConfiguracoesChange={handleSetConfiguracoesVideochamada}
              configuracoes={mediaDevicesConfiguration}
              chatOpen={chatOpen}
              setChatOpen={setChatOpen}
              sharingScreen={sharingScreen}
              onShareScreenClick={sharingScreen ? stopSharingScreen : shareScreen}
              hasRemoteParticipant={videocallStreams.some((stream) => stream.remote)}
              hasRemoteVideo={remoteVideoEnabled}
              pipActive={pipActive}
              setPipActive={setPipActive}
              hasNewMessages={hasNewMessages}
            />
          </Cell>
        </Grid>
        {isOwner && (
          <SolicitacaoEntrarVideochamadaListener
            videochamadaUuid={videochamadaUuid}
            numeroParticipantes={remoteParticipants.length + 1}
          />
        )}
      </PageContent>
    </Fragment>
  )
}

const newMessagesReducer = (
  prevNewMessages: ChatMessage[],
  action: { type: 'add' | 'remove_last' | 'remove_all'; message?: ChatMessage }
) => {
  switch (action.type) {
    case 'add':
      return [...prevNewMessages, action.message]
    case 'remove_last':
      return prevNewMessages.slice(1)
    case 'remove_all':
      return []
    default:
      return prevNewMessages
  }
}

const animationConfig = '0.5s ease'
const createStyles = (theme: Theme) => ({
  global: css`
    body {
      overflow-y: auto;
    }
  `,
  pageContent: css`
    padding: 2rem 0 1.5rem;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-color: ${theme.pallete.gray.c10};
    overflow: hidden;
  `,
  pageContainer: css`
    display: flex;
    max-width: 75rem;
  `,
  chatContainer: css`
    overflow: hidden;
    width: 0;
    animation: ${chatContainerScrollIn} ${animationConfig} forwards;
  `,
  chat: css`
    animation: ${chatFadeIn} ${animationConfig} forwards;
  `,
  messagesPreviewAndPlayersContainer: css`
    height: 100%;
    transition: ${animationConfig};
    position: relative;
  `,
  content: css`
    height: 100%;
  `,
})

const chatFadeIn = keyframes`
  0% { 
    min-width: 14rem;
  }
  99% { 
    min-width: 14rem;
  }
  100% { 
    min-width: 0;
  }
`

const chatContainerScrollIn = keyframes`
  0% {
    flex-grow: 0.001;
    opacity: 0; 
  }
  100% { 
    flex-grow: 1; 
    opacity: 1; 
  }
`
