import { VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import React from 'react'

import { News } from './News'

export function NewsView() {
  return (
    <>
      <PageContent fluid type='filled'>
        <VFlow>
          <Breadcrumb title='Novidades da versão' />
          <News />
        </VFlow>
      </PageContent>
    </>
  )
}
