import { VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'

import { CredenciaisRecebimentoExternoBox } from './credenciais-recebimento-externo/CredenciaisRecebimentoExternoBox'
import { TransmissaoHorarioGeracaoLotesBox } from './horarioGeracaoLotes/TransmissaoHorarioGeracaoLotesBox'
import { TransmissaoLinksDestinoBox } from './instalacoesDestino/TransmissaoLinksDestinoBox'

export function TransmissaoConfiguracoesGeralView() {
  const { PILOTO_RECEBIMENTO_ONLINE_EXTERNO_ENABLED } = useFlags()

  return (
    <VFlow vSpacing={2}>
      <TransmissaoLinksDestinoBox />
      <TransmissaoHorarioGeracaoLotesBox />
      {PILOTO_RECEBIMENTO_ONLINE_EXTERNO_ENABLED && <CredenciaisRecebimentoExternoBox />}
    </VFlow>
  )
}
