/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente52(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='44' height='117' viewBox='0 0 44 117' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M6.20032 84.8795C5.98012 84.9019 5.78599 84.9213 5.62047 84.9376C5.68296 84.6026 5.76482 84.1425 5.85969 83.5595C6.07896 82.212 6.36774 80.2077 6.64756 77.572C7.20651 72.3071 8.1126 60.1635 8.67708 49.71C9.2418 39.252 9.30389 12.9728 8.6755 6.45562C8.533 4.97774 8.99906 3.99905 9.76675 3.38564C10.5594 2.75227 11.7485 2.44917 13.1398 2.50677C15.9285 2.62221 19.2598 4.17668 21.0784 6.78926C21.9938 8.10427 22.8729 10.1482 23.705 12.7653C24.5343 15.3734 25.3062 18.515 26.0205 22.0004C27.4492 28.9706 28.6417 37.284 29.6061 45.3896C29.6905 46.0991 29.7687 46.8048 29.8413 47.506C30.5879 54.7207 30.5793 61.4911 30.5724 66.9911C30.572 67.3093 30.5716 67.6233 30.5713 67.9328C30.5691 70.7565 30.5794 73.2254 30.7168 75.1947C30.7855 76.1801 30.8868 77.0529 31.0376 77.7903C31.1873 78.5222 31.3919 79.1528 31.6848 79.633C31.7295 79.7062 31.7247 79.7386 31.7 79.7902C31.6574 79.8795 31.5371 80.0227 31.2676 80.2034C30.7346 80.561 29.826 80.9303 28.6123 81.2952C26.203 82.0197 22.7771 82.6745 19.2614 83.2259C15.7516 83.7764 12.1746 84.2205 9.4736 84.527C8.12347 84.6803 6.99302 84.7991 6.20032 84.8795Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M2.753 101.347C2.44134 100.295 2.43054 98.7295 2.6505 96.8486C2.86865 94.983 3.30613 92.8655 3.84668 90.7425C4.92805 86.4954 6.41015 82.2718 7.3272 80.0809C7.50487 79.6565 7.91285 79.2113 8.57667 78.7727C9.2342 78.3382 10.1015 77.9375 11.1292 77.585C13.1836 76.8803 15.8158 76.3895 18.5348 76.1848C21.2532 75.9801 24.032 76.0634 26.3786 76.4915C28.75 76.9241 30.5791 77.6911 31.5141 78.769C33.5606 81.1284 35.8733 85.5325 37.6797 90.0273C38.5797 92.2669 39.3475 94.5128 39.8895 96.5154C40.4339 98.5267 40.7418 100.261 40.7418 101.489C40.7418 102.612 40.1385 103.889 39.007 105.214C37.8834 106.53 36.2841 107.839 34.4059 109.019C30.6446 111.382 25.8495 113.173 21.713 113.472C17.6438 113.766 13.2332 111.872 9.63966 109.284C7.85007 107.996 6.28238 106.549 5.0819 105.147C3.87439 103.737 3.06611 102.404 2.753 101.347Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M31.8918 78.4414C36.0966 83.2889 41.2418 96.3098 41.2418 101.489C41.2418 106.669 30.2195 113.358 21.7491 113.971C13.2788 114.583 3.6404 106.103 2.27361 101.489C1.24036 98.0016 3.33448 89.9728 5.21888 84.3489C5.44163 83.0856 5.80268 80.7926 6.15021 77.5192C6.70785 72.2668 7.61339 60.1329 8.17766 49.6831C8.74194 39.2333 8.80245 12.9834 8.17766 6.50365C7.55287 0.0238781 17.6527 0.992981 21.4886 6.50365C25.3246 12.0143 28.1722 29.1077 30.1025 45.3305C30.1872 46.0427 30.2657 46.751 30.3385 47.4546C31.0878 54.6961 31.0792 61.4901 31.0722 66.9878C31.0658 72.0199 31.0608 75.966 31.6397 78.1755C31.7288 78.2617 31.8129 78.3504 31.8918 78.4414ZM33.4707 77.2098C33.3015 76.3446 33.1936 75.1399 33.1351 73.5659C33.0665 71.7207 33.069 69.5187 33.0722 66.9903L33.0723 66.9603C33.0792 61.4743 33.088 54.5943 32.3279 47.2487C32.2541 46.5354 32.1744 45.817 32.0885 45.0942C31.1206 36.9594 29.9184 28.5676 28.4695 21.4984C27.7452 17.9647 26.953 14.7301 26.0874 12.0078C25.2332 9.32138 24.2636 6.98935 23.1301 5.36103C20.8147 2.03468 16.7203 0.152888 13.2431 0.00894491C11.4927 -0.0635154 9.63004 0.294741 8.20602 1.43258C6.68211 2.65024 5.97591 4.50742 6.18689 6.6956C6.79727 13.0258 6.74308 39.1582 6.18057 49.5752C5.61709 60.0104 4.71375 72.1053 4.16139 77.3081C3.83199 80.4107 3.49148 82.6082 3.2746 83.8568C2.33016 86.6903 1.33195 90.1266 0.691047 93.3276C0.365292 94.9546 0.124382 96.5572 0.0365555 98.01C-0.0493277 99.4306 0.000406563 100.857 0.355986 102.057C0.812042 103.597 1.87227 105.242 3.18298 106.773C4.52184 108.337 6.23923 109.916 8.17892 111.313C12.001 114.065 16.9946 116.32 21.8934 115.966C26.5236 115.631 31.7087 113.666 35.7358 111.136C37.7587 109.865 39.5742 108.399 40.9081 106.837C42.2104 105.312 43.2418 103.472 43.2418 101.489C43.2418 99.9285 42.8654 97.9411 42.3027 95.8622C41.7306 93.7484 40.929 91.4084 39.9994 89.0951C38.1832 84.5759 35.776 79.9043 33.4707 77.2098Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
