import { FORM_ERROR } from 'final-form'
import { ErrorObject } from 'util/validation'

import { ProblemaCondicaoModel } from '../model-problemasCondicoes'

const MESSAGE_CONDICAO_ATIVA_GRAVIDEZ_DUPLICADA = 'Já existe uma condição de gravidez ativa.'
const MESSAGE_RELACAO_DUPLICADA = 'Este relacionamento de CIAP2 e CID10 já está adicionado.'

export function fillErrorsWithRelacaoDuplicadaMessage(
  hasCondicaoAtivaGravidezDuplicada: boolean,
  errors: ErrorObject<ProblemaCondicaoModel>,
  hasItemDuplicado: boolean,
  hasItemDuplicadoAtivoOuLatente: boolean,
  messageRelacaoDuplicadaAtivoOuLatente: string
) {
  if (hasCondicaoAtivaGravidezDuplicada) {
    errors[FORM_ERROR] = MESSAGE_CONDICAO_ATIVA_GRAVIDEZ_DUPLICADA
  } else {
    errors[FORM_ERROR] = hasItemDuplicado
      ? MESSAGE_RELACAO_DUPLICADA
      : hasItemDuplicadoAtivoOuLatente && messageRelacaoDuplicadaAtivoOuLatente
  }
}
