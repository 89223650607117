import useSession from 'components/auth/useSession'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

const PATHS_MODULOS_ESPECIAIS = {
  '/videochamada': 106,
  '/': 42,
}

export const useOffsetAlerta = () => {
  const history = useHistory()

  const [offsetAlerta, setOffsetAlerta] = useState<number>(0)
  const session = useSession()

  useEffect(() => {
    const modulePath = `/${history.location.pathname.split('/')[1]}`

    const offset = PATHS_MODULOS_ESPECIAIS[modulePath] ?? (session?.data?.acesso?.moduloInicial ? 138 : 42)
    offset !== offsetAlerta && setOffsetAlerta(offset)
  }, [history.location.pathname, offsetAlerta, session])

  return offsetAlerta
}
