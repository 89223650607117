import { createValidator, ErrorObject, maxLength, required, requiredRichText, richTextMaxLength } from 'util/validation'
import {
  agendamentoConsultaCompartilhadaValidator,
  agendamentoConsultaValidator,
} from 'view/agenda/cadastro-agendamento/validator-agenda'
import {
  AgendarConsultaCuidadoCompartilhadoFieldsModel,
  DevolutivaCuidadoCompartilhadoFieldsModel,
  DiscussaoCasoFormModel,
  EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel,
  PerguntaCuidadoCompartilhadoFieldsModel,
  ResponsavelCuidadoCompartilhado,
  SugestaoAgendamentoGrupoCuidadoCompartilhadoFieldsModel,
  TipoAgendamentoCuidadoCompartilhado,
  TrocaExecutanteCuidadoCompartilhadoFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

export const devolutivaCuidadoCompartilhadoFieldValidator = createValidator<DevolutivaCuidadoCompartilhadoFieldsModel>({
  resposta: [requiredRichText, richTextMaxLength(1000)],
  conduta: [required],
})

export const perguntaCuidadoCompartilhadoFieldValidator = createValidator<PerguntaCuidadoCompartilhadoFieldsModel>({
  pergunta: [requiredRichText, richTextMaxLength(1000)],
})

export const trocaExecutanteCuidadoCompartilhadoFieldValidator = createValidator<
  TrocaExecutanteCuidadoCompartilhadoFieldsModel
>({
  motivo: [requiredRichText, richTextMaxLength(1000)],
  lotacao: [required],
})

export const agendarConsultaCuidadoCompartilhadoFieldValidator = (now: Date) =>
  createValidator<AgendarConsultaCuidadoCompartilhadoFieldsModel>({}, (values, errors) => {
    if (values.tipoAgendamento === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO) {
      errors['consultaComCidadao'] = agendamentoConsultaValidator(now, true)(values.consultaComCidadao)
    }

    if (values.tipoAgendamento === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA) {
      errors['consultaCompartilhada'] = agendamentoConsultaCompartilhadaValidator(
        now,
        true
      )(values.consultaCompartilhada)
    }

    return errors
  })

export const sugestaoAgendamentoGrupoCuidadoCompartilhadoFieldValidator = createValidator<
  SugestaoAgendamentoGrupoCuidadoCompartilhadoFieldsModel
>({
  observacao: [requiredRichText, richTextMaxLength(1000)],
})

export const enviarGarantiaAcessoCuidadoCompartilhadoFieldValidator = createValidator<
  EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel
>({
  motivo: [required, maxLength(200)],
  tipoAgendamento: [required],
})

export const discussaoCasoFormValidator = (responsavelCuidado: ResponsavelCuidadoCompartilhado) =>
  createValidator<DiscussaoCasoFormModel>(
    {},
    (values: DiscussaoCasoFormModel, errors: ErrorObject<DiscussaoCasoFormModel>) => {
      if (responsavelCuidado === 'EXECUTANTE') {
        const valuesExecutante = values.executanteFields
        errors.executanteFields = required(valuesExecutante?.condutaGroup)
      } else {
        const valuesSolicitante = values.solicitanteFields
        errors.solicitanteFields = required(valuesSolicitante?.condutaGroup)
      }

      return errors
    }
  )
