import axios, { AxiosPromise } from 'axios'

import { BuscaAtivaVacinacaoInput } from './../graphql/types.generated'

const API_ENDPOINT = '/api/buscaativavacinacao'
const IMPRIMIR_ENDPOINT = `${API_ENDPOINT}/imprimir`
const EXPORTAR_CSV_ENDPOINT = `${API_ENDPOINT}/exportarCSV`

export class BuscaAtivaVacinacao {
  imprimir(input: BuscaAtivaVacinacaoInput): AxiosPromise<Blob> {
    return axios.post(IMPRIMIR_ENDPOINT, input, {
      responseType: 'blob',
    })
  }

  exportarCSV(input: BuscaAtivaVacinacaoInput): AxiosPromise<Blob> {
    return axios.post(EXPORTAR_CSV_ENDPOINT, input, {
      responseType: 'blob',
    })
  }
}
