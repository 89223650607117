import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { useAcessoCreateQuery, useSalvarAcessoMutation } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { AcessoForm, AcessoFormModel } from './AcessoForm'
import convertModelToInput from './convertModelToInput'

interface UrlParams {
  profissionalId: string
}

export function AcessoCreateView() {
  const alert = useAlert()
  const match = useRouteMatch<UrlParams>()
  const history = useHistory()
  const { analytics } = useFirebase()

  const {
    data: { profissional },
  } = useAcessoCreateQuery({
    variables: { profissionalId: match.params.profissionalId },
  })
  const { data: sessao } = useSession()

  const [salvar] = useSalvarAcessoMutation()

  if (!profissional) {
    return null
  }

  const handleSubmit = (values: AcessoFormModel) => {
    const input = convertModelToInput(values)
    const perfis = values.perfis.map((p) => p.nome).join(', ')
    analytics.logEvent('cadastro_acesso', { 'Perfis adicionados': perfis })

    return salvar({ variables: { input } })
  }

  const handleSubmitSuccess = () => {
    alert('success', 'Acesso cadastrado com sucesso.')
    history.push(`/profissional/${profissional.id}/dados`)
  }

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/profissional/${profissional.id}/dados`} />
      <Breadcrumb title='Cadastro de acesso' />

      <VFlow vSpacing={1.5}>
        <PageHeader title='Cadastro de acesso' subtitle={profissional.nome} />
        <AcessoForm
          initialValues={{ profissional: profissional.id, municipio: sessao.acesso.municipio } as any}
          onSubmit={handleSubmit}
          onSubmitSucceeded={handleSubmitSuccess}
        />
      </VFlow>
    </PageContent>
  )
}
