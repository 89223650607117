import { DocumentNode, ExecutableDefinitionNode, OperationDefinitionNode } from 'graphql'

const isOperationDefinition = (
  executableDefinitionNode: ExecutableDefinitionNode
): executableDefinitionNode is OperationDefinitionNode => executableDefinitionNode.kind === 'OperationDefinition'

/**
 * Gets the top level field name of each graphql operation (i.e., query or mutation) defined in the document.
 * @param document Any graphql definition.
 * @returns An array with the field names.
 */
export const operationTopLevelFieldNames = (document: DocumentNode): string[] =>
  document.definitions
    .filter(isOperationDefinition)
    .flatMap((operation) => operation.selectionSet.selections)
    .map((selection) => selection.kind === 'Field' && selection.name.value)

/**
 * Gets all variable names of each operation (i.e., query or mutation) defined in the document
 * @param document Any graphql definition.
 * @returns An array to each operation.
 */
export const operationVariableNames = (document: DocumentNode): string[][] => {
  return document.definitions
    .filter(isOperationDefinition)
    .map((operation) =>
      operation.variableDefinitions.map((variableDefinition) => variableDefinition.variable.name.value)
    )
}
