import { Button, HFlow, Text } from 'bold-ui'
import { LotesEnvioFiltro, StatusEnvio } from 'graphql/types.generated'
import moment from 'moment'
import * as React from 'react'

import { FilterTag } from '../../../../../components/FilterTag'

export interface TransmissaoEnvioTableFilterAuxProps {
  filter: LotesEnvioFiltro
  onChange(newFilter: LotesEnvioFiltro): void
}

export const TransmissaoEnvioTableFilterAux = ({ filter, onChange }: TransmissaoEnvioTableFilterAuxProps) => {
  const removeData = () => () => onChange({ ...filter, dataCriacaoInicio: undefined, dataCriacaoFim: undefined })
  const removeStatusEnvio = (tag: TagFilter) => () => {
    onChange({ ...filter, statusEnvio: filter.statusEnvio.filter(s => s !== tag.id) })
  }
  const limparTags = () => onChange({ ...filter, dataCriacaoInicio: null, dataCriacaoFim: null, statusEnvio: [] })
  return (
    <HFlow alignItems='center' justifyContent='space-between'>
      <HFlow alignItems='center'>
        <HFlow alignItems='center' hSpacing={0.25}>
          <Text style={{ fontWeight: 'bold' }}>Status de envio:</Text>
          {filter &&
            filter.statusEnvio &&
            filter.statusEnvio.map(translateStatusEnvio).map((o, i) => (
              <FilterTag key={i} onRemove={removeStatusEnvio(o)}>
                {o.title}
              </FilterTag>
            ))}
        </HFlow>
        <HFlow alignItems='center' hSpacing={0.25}>
          <Text style={{ fontWeight: 'bold' }}>Data:</Text>
          {filter && filter.dataCriacaoFim && filter.dataCriacaoInicio && (
            <FilterTag onRemove={removeData()}>{translateDate(filter)}</FilterTag>
          )}
        </HFlow>
      </HFlow>
      <Button skin='outline' size='small' onClick={limparTags}>
        Limpar filtros
      </Button>
    </HFlow>
  )
}

interface TagFilter {
  title?: string
  id?: StatusEnvio
}

const translateStatusEnvio = (codigo: StatusEnvio): TagFilter => {
  switch (codigo) {
    case 'NAO_ENVIADO':
      return { title: 'Não enviado', id: StatusEnvio.NAO_ENVIADO }
    case 'TOTALMENTE_ENVIADO':
      return { title: 'Enviado', id: StatusEnvio.TOTALMENTE_ENVIADO }
    default:
      return undefined
  }
}
const translateDate = ({ dataCriacaoInicio, dataCriacaoFim }: LotesEnvioFiltro): string => {
  const formatDate = (date: string): string => moment(date).format('DD/MM/YYYY')
  return formatDate(dataCriacaoInicio) + ' - ' + formatDate(dataCriacaoFim)
}
