import { Button, Icon, Tooltip } from 'bold-ui'
import { alert } from 'components/alert'
import { confirm } from 'components/modals/confirm'
import { useRemoverUnidadeConsultaHorusMutation } from 'graphql/hooks.generated'
import { UnidadeConsultaHorusIds } from 'graphql/types.generated'
import React from 'react'

import { PermissionToEditHorus } from './HorusTable'

export interface HorusRemoveUnidadeComponentProps {
  unidadeRemovidaId: ID
  unidadeSaudeId: ID
  nomeUnidadeRemovida: string
  permissionToEditHorus: PermissionToEditHorus
  reload(): any
}

export function HorusRemoveUnidadeComponent(props: HorusRemoveUnidadeComponentProps) {
  const { unidadeRemovidaId, unidadeSaudeId, nomeUnidadeRemovida, permissionToEditHorus } = props
  const [removerUnidadeConsulta] = useRemoverUnidadeConsultaHorusMutation()

  const handleClick = () => {
    const input: UnidadeConsultaHorusIds = {
      unidadeSaudeId,
      unidadeConsultaId: unidadeRemovidaId,
    }
    return confirm({
      title: `Deseja excluir ${nomeUnidadeRemovida}?`,
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () =>
        removerUnidadeConsulta({ variables: { input } }).then((success) => {
          alert('success', `Unidade de consulta ${nomeUnidadeRemovida} excluída com sucesso.`)
          props.reload()
        }),
    })
  }

  return (
    <Tooltip text={permissionToEditHorus.hint}>
      <Button size='small' skin='ghost' onClick={handleClick()} disabled={!permissionToEditHorus.enabled}>
        <Icon icon='trashOutline' />
      </Button>
    </Tooltip>
  )
}
