import api from 'api'
import { ImpressaoCadernetaVacinacaoInput } from 'api/impressaoCadernetaVacinacao'
import moment from 'moment'
import { defaultHandleError, downloadFile } from 'util/downloadFile'

const asFormat = (val) => moment(val).format('YYYY-MM-DD_hhmm')

export const downloadCadernetaVacinacao = (input: ImpressaoCadernetaVacinacaoInput) => {
  return api.cadernetaVacinacao
    .imprimirCadernetaVacinacao(input)
    .then((response) => {
      downloadFile(new Blob([response.data]), `CadernetaVacinacao_${asFormat(moment().toDate())}.pdf`)
      return response
    })
    .catch(defaultHandleError)
}
