import { Text, VFlow } from 'bold-ui'
import React from 'react'

import { NewsHeadingSection } from './NewsHeadingSection'

export function NewsInternalEstabilizacaoV50() {
  return (
    <VFlow>
      <NewsHeadingSection title='Versão 5.0.24' id='estabilizacao-5-0-24'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde, em algumas instalações, não estava sendo possível gerar relatórios de
            inconsistências muito grandes.
          </Text>
          <Text component='li'>
            Correção de erro onde não era possível finalizar um atendimento após evoluir uma condição de alto risco de
            gravidez através da Avaliação e editá-la para situação "Resolvido".
          </Text>
          <Text component='li'>
            Ajuste no atendimento para não apagar o conteúdo do campo DUM ao remover "Gravidez - W78" do campo CIAP 2 na
            avaliação de Problemas e/ou condições.
          </Text>
          <Text component='li'>
            Correção de cenário onde a impressão e o cartão de medições não exibiam consultas de pré-natal sem nenhum
            dado preenchido.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.23' id='estabilizacao-5-0-23'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde o campo de seleção de cidadão no módulo de agendamento estava fechando
            inesperadamente.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.22' id='estabilizacao-5-0-22'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde não era possível cadastrar alguns lotes de imunobiológico para vacinas da Covid-19.
          </Text>
          <Text component='li'>
            Correção de erro onde não era possível gerar e enviar lotes de fichas em algumas instalações.
          </Text>
          <Text component='li'>
            Ajustes no campo Profissionais dos filtros do Histórico clínico do cidadão para exibir todos os
            profissionais que possuam pelo menos uma lotação (ativa ou inativa).
          </Text>
          <Text component='li'>
            Correção de erro onde não estava sendo possível imprimir atendimentos domiciliares realizados através do
            aplicativo e-SUS AD.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.21' id='estabilizacao-5-0-21'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário em que os imunobiológicos "Sinovac" e "Sinovac/Butantan" estavam invertidos ao
            acessá-los através do calendário vacinal.
          </Text>
          <Text component='li'>
            Correção de cenário que impedia a finalização de atendimentos ao replicar prescrições criadas em versões
            antigas do PEC.
          </Text>
          <Text component='li'>
            Inclusão de opção para atualização do telefone celular e e-mail do cidadão ao registrar um agendamento.
          </Text>
          <Text component='li'>
            Correção de cenário em que o nome do profissional que realizou uma vacinação não era exibido após alteração
            do seu CNS.
          </Text>
          <Text component='li'>
            Adequação no relatório de resumo de produção para considerar o histórico de CNS dos profissionais.
          </Text>
          <Text component='li'>Ajustes para facilitar a edição de registros atuais do odontograma.</Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.20' id='estabilizacao-5-0-20'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Atualização do calendário de doses, regras de aplicação, faixas etárias recomendadas e aprazamento de
            imunobiológicos contra COVID-19.
          </Text>
          <Text component='li'>Inclusão da estratégia de vacinação "Campanha Seletiva".</Text>
          <Text component='li'>Atualização da lista de Grupos de atendimento.</Text>
          <Text component='li'>
            Correção de erro onde uma tela branca era apresentada em alguns cenários de solicitação de exames.
          </Text>
          <Text component='li'>
            Correção de cenário onde não era possível finalizar alguns registros tardios de atendimento.
          </Text>
          <Text component='li'>
            Idade máxima do procedimento "01.01.04.011-3 - Dispensação de Suplemento de Ferro" atualizada para 60 anos.
          </Text>
          <Text component='li'>
            Alteração dos procedimentos "0301070210 - Reabilitação de Pacientes Pós Covid-19" e "0301070229 -
            Reabilitação Cardiorrespiratória de Pacientes Pós Covid-19" para que não sejam mais exibidos nos
            procedimentos administrativos no desfecho do atendimento.
          </Text>
          <Text component='li'>
            Adicionada funcionalidade de impressão de lote de todos os exames solicitados em um mesmo atendimento.
          </Text>
          <Text component='li'>
            Adicionado histórico de procedimentos para odontograma, dentes supranumerários e arcadas dentro do
            atendimento atual.
          </Text>
          <Text component='li'>
            Padronização no histórico de tecidos moles, duros e sextantes para serem apresentados em um mesmo
            componente.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.19' id='estabilizacao-5-0-19'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde alguns elementos de marcação de texto eram exibidos incorretamente no histórico de
            exames.
          </Text>
          <Text component='li'>Melhorias de usabilidade na tela de resultados de exames.</Text>
          <Text component='li'>Padronização da apresentação de valores numéricos nos históricos de exames.</Text>
          <Text component='li'>
            Inclusão de novo botão de acesso ao histórico de resultados de exames ao lado da funcionalidade de registrar
            resultados de exames.
          </Text>
          <Text component='li'>
            Fechamento automático da modal de "Imprimir prescrição" após a impressão de uma prescrição de medicamentos.
          </Text>
          <Text component='li'>
            Adição da assinatura do cidadão no atestado para autorizar o registro de diagnóstico quando o CID é
            preenchido.
          </Text>
          <Text component='li'>
            Correção na impressão de atendimentos para considerar a quebras de linha em campos de texto.
          </Text>
          <Text component='li'>
            Correção de cenário onde poderia haver inconsistências na transmissão de dados entre versões.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.18' id='estabilizacao-5-0-18'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Inclusão do campo Conduta no histórico clínico do cidadão para os atendimentos odontológicos.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.17' id='estabilizacao-5-0-17'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Inclusão de novo imunobiológico contra a Covid-19: "Vacina Covid-19 - Pfizer (Comirnaty) - Bivalente".
          </Text>
          <Text component='li'>
            Inclusão de dose de reforço para o imunobiológico "Vacina Covid-19 - Pfizer (Comirnaty) - Pediátrica".
          </Text>
          <Text component='li'>
            Correção de cenário em que não era possível cadastrar lotes do imunobiológico "Covid-19 - Pfizer (Comirnaty)
            - Pediátrica - p/ menores de 5 anos" através do módulo Lotes de imunobiológico.
          </Text>
          <Text component='li'>
            Correção de cenário em que não era possível finalizar um registro tardio caso existisse um agendamento para
            o cidadão no mesmo dia e horário do atendimento.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.16' id='estabilizacao-5-0-16'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>Otimização no processamento de relatórios.</Text>
          <Text component='li'>Otimização no processo de envio de dados.</Text>
          <Text component='li'>
            Correção de cenário em que alguns procedimentos eram apresentados várias vezes no detalhe de alguns
            atendimentos no histórico do cidadão.
          </Text>
          <Text component='li'>
            Inclusão de novo imunobiológico contra a Covid-19: Pfizer pediátrica p/ menores de 5 anos.
          </Text>
          <Text component='li'>
            Retirada a possibilidade de realizar a transcrição de caderneta através do Registro Tardio.
          </Text>
          <Text component='li'>
            Inclusão do grupo de atendimento "Pessoas de 6 meses a 2 anos" nos registros de vacinação.
          </Text>
          <Text component='li'>
            Inclusão das doses Única e de 2º Reforço para o imunobiológico 'Meningocócica Conjugada', no calendário
            vacinal do grupo alvo de Adolescentes.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.15' id='estabilizacao-5-0-15'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde não estava sendo possível gerar Relatórios operacionais de crianças e gestantes.
          </Text>
          <Text component='li'>
            Correção de erro de tela branca ao acessar o histórico clínico que não contenha forma farmacêutica definida
            nas prescrições de medicamentos.
          </Text>
          <Text component='li'>
            Ajustes no Relatório de produção de visita domiciliar e territorial para não exibir profissionais duplicados
            em casos de mudança de CNS.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.14' id='estabilizacao-5-0-14'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>Adição da funcionalidade de impressão da Escuta inicial.</Text>
          <Text component='li'>
            Limitação do número de procedimentos que podem ser inseridos nos atendimentos para 20.
          </Text>
          <Text component='li'>
            Correção de cenário onde uma mensagem de erro era apresentada incorretamente ao finalizar atendimento para
            cidadãs gestantes.
          </Text>
          <Text component='li'>
            Inclusão dos procedimentos SIGTAP "0301010366 - Consulta de Pré-natal de Gestante Alto Risco", "0202031292 -
            Dosagem de Anti-Beta-2-Glicoproteína I - IGM" e "0301010374 - Consulta de Acompanhamento de Recém-Nascidos e
            Criança, Prioritariamente, Egressa de Unidade Neonatal".
          </Text>
          <Text component='li'>
            Correção de cenário nos Relatórios operacionais para apresentar somente o cidadão conforme o último estado
            de território que ele se encontra; evitando registros duplicados nas impressões.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.13' id='estabilizacao-5-0-13'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>Correção de erro onde a tela ficava branca ao atender crianças de 5 anos.</Text>
          <Text component='li'>
            Agrupamento de todos os imunobiológicos relacionados a Covid-19 em um único componente no Atendimento de
            vacinação.
          </Text>
          <Text component='li'>
            Correção de cenário onde ocorria a quebra de página na impressão de encaminhamentos para Unidades de Saúde
            com nome muito extenso.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.12' id='estabilizacao-5-0-12'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Ao finalizar um atendimento, agora é exibido um alerta com todos os campos preenchidos de forma incorreta e
            que precisam ser corrigidos antes de salvar.
          </Text>
          <Text component='li'>
            Correção de erro onde em alguns casos não estava sendo possível visualizar o histórico clínico do cidadão.
          </Text>
          <Text component='li'>
            Correção de cenário onde não estava sendo possível atualizar o sistema para versões superiores a 5.0 em
            instalações com grandes quantidades de registros.
          </Text>
          <Text component='li'>
            Ajustes na Ficha de atividade coletiva que mostrava uma inconsistência incorreta no preenchimento dos
            registros.
          </Text>
          <Text component='li'>
            Inclusão do nome do profissional do agendamento juntamente do horário na Lista de atendimentos.
          </Text>
          <Text component='li'>
            Correção de erro onde ao realizar a redefinição de senha do instalador, o mesmo ainda continuava bloqueado.
          </Text>
          <Text component='li'>
            Ajuste de cenário onde, em algumas instalações, não estava sendo possível importar arquivos de CNES do CEO.
          </Text>
          <Text component='li'>Inclusão do procedimento SIGTAP "030100313 - Ações de Redução de Danos".</Text>
          <Text component='li'>
            Ajuste de cenário onde o filtro de Equipe da Lista de atendimentos não estava funcionando corretamente.
          </Text>
          <Text component='li'>
            Inativação do procedimento SIGTAP "0301040079 - Escuta inicial / orientação (acolhimento a demanda
            espontânea)".
          </Text>
          <Text component='li'>
            Correção de erro inesperado ao adicionar um CID10 ou CIAP2 de alto risco sem pré-natal cadastrado.
          </Text>
          <Text component='li'>
            Ajustes no tamanho da fonte e formatações de data nas impressões de atestado e declaração de comparecimento.
          </Text>
          <Text component='li'>
            Correção de cenário onde não estava sendo mostrado o alerta de "Comunicante de Hanseníase" ao aplicar uma
            vacina de BCG no atendimento de vacinação.
          </Text>
          <Text component='li'>
            Alteração nominal do procedimento SIGTAP "0307020061" para "Tratamento endodôntico de dente permanente
            unirradicular".
          </Text>
          <Text component='li'>
            Atualização da lista de procedimentos disponíveis para todos os dentes no odontograma.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.11' id='estabilizacao-5-0-11'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde várias mensagens de erro eram apresentadas quando o profissional abria a própria
            agenda.
          </Text>
          <Text component='li'>
            Inclusão dos imunobiológicos Varíola Bavarian Nordic e Herpes-Zoster (recombinante).
          </Text>
          <Text component='li'>
            Correção de erro onde não era possível visualizar o prontuário do cidadão caso ele possuísse um exame
            específico requisitado.
          </Text>
          <Text component='li'>
            Correção de cenário onde não era possível realizar o atendimento de um cidadão caso ele possuísse alergia a
            uma substância específica cadastrada.
          </Text>
          <Text component='li'>
            Alteradas as descrições dos textos do campo "É membro de Povo ou Comunidade Tradicional ou Campo, Floresta e
            Águas?" na Ficha de cadastro individual.
          </Text>
          <Text component='li'>
            Inclusão do procedimento SIGTAP "0301010382 - Estratificação do risco cardiovascular".
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.10' id='estabilizacao-5-0-10'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>Correção de cenário onde ao acessar alguns atendimentos a tela ficava branca.</Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.9' id='estabilizacao-5-0-9'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Inclusão da terceira dose de reforço para os imunobiológicos COV19 Oxford - AstraZeneca Covishield, COV19
            Janssen - Cilag e COV19 Biontech - Pfizer.
          </Text>
          <Text component='li'>
            Correção de cenário onde o cartão de Acompanhamento de pré-natal não estava exibindo as informações
            corretamente e em alguns casos impossibilitava a impressão.
          </Text>
          <Text component='li'>
            Ajustes na Avaliação do SOAP, onde ao evoluir um problema/condição sem data de início, a data atual era
            incluída erroneamente.
          </Text>
          <Text component='li'>
            Correção de erro onde não era possível visualizar o histórico clínico do cidadão caso ele possuísse uma
            avaliação de exame registrada no prontuário.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.0.8' id='estabilizacao-5-0-8'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde não era possível atender cidadãos que não possuiam data de nascimento registrada.
          </Text>
          <Text component='li'>
            Correção de erro onde os alertas do imunobiológico Meningocócica Conjugada C não estavam sendo exibidos
            corretamente.
          </Text>
          <Text component='li'>
            Ajustes na impressão do Acompanhamento da criança para exibir a data do campo "Data de início" de um
            problema ou condição ativo.
          </Text>
          <Text component='li'>Ajustes gerais visando performance no módulo de atendimentos.</Text>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
