import { PecSwitch, PrivateRoute } from 'components/route'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import BuscaAtivaVacinacaoView from './vacinacao/BuscaAtivaVacinacaoView'

export default function BuscaAtivaRootView() {
  const { path } = useRouteMatch()

  return (
    <PecSwitch>
      <PrivateRoute
        path={`${path}/vacinacao`}
        component={BuscaAtivaVacinacaoView}
        permission={Permissions.buscaAtivaDeVacinacao}
        onlyAtencaoPrimaria
      />
    </PecSwitch>
  )
}
