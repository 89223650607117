/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente24(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='40' height='110' viewBox='0 0 40 110' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M33.724 77.499C33.8967 77.4999 34.0526 77.5005 34.1903 77.5009C34.0909 77.2119 33.9704 76.834 33.8405 76.3671C33.5053 75.1621 33.1073 73.3648 32.8464 70.9735C32.3291 66.2335 31.4908 55.303 30.9686 45.8945C30.4461 36.4812 30.3884 12.8263 30.9702 6.95607C31.1108 5.53801 31.0083 4.4667 30.7549 3.73127C30.5029 3.00023 30.1262 2.65642 29.7341 2.54616C29.3259 2.43141 28.7475 2.52483 28.0098 3.00399C27.2779 3.4794 26.4475 4.29998 25.5827 5.50874C23.8503 7.92989 21.2324 13.4086 18.8196 20.1371C16.4113 26.8529 14.2274 34.7583 13.3405 42.0102C13.2624 42.6484 13.1901 43.2832 13.123 43.914C12.637 48.4829 12.2786 53.1109 11.9483 57.3756C11.7804 59.5435 11.6198 61.6175 11.4534 63.5421C11.2072 66.3903 10.948 68.9124 10.6333 70.9107C10.4759 71.9098 10.3033 72.7868 10.1089 73.5129C9.91661 74.2311 9.6944 74.8355 9.42375 75.2672C9.41582 75.2798 9.41091 75.2894 9.4079 75.2962C9.43618 75.341 9.53325 75.4411 9.78539 75.5691C10.2928 75.8266 11.1605 76.0663 12.3239 76.278C14.63 76.6977 17.9032 76.9753 21.26 77.1575C24.6111 77.3395 28.0245 77.4256 30.6014 77.4661C31.8895 77.4864 32.9678 77.4952 33.724 77.499Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M37.2668 94.4943C37.5542 93.4485 37.5636 91.8949 37.3617 90.0337C37.1614 88.187 36.7598 86.0916 36.2637 83.9914C35.2714 79.7901 33.9114 75.6121 33.0699 73.4447C32.906 73.0227 32.5326 72.5852 31.9294 72.1556C31.3305 71.729 30.5395 71.3348 29.6003 70.9875C27.7226 70.2932 25.3144 69.8089 22.8251 69.6069C20.3363 69.4049 17.7941 69.4872 15.6494 69.909C13.4826 70.3352 11.8182 71.0888 10.967 72.1467C9.09032 74.4791 6.96834 78.8349 5.31065 83.2816C3.65028 87.7354 2.49988 92.1625 2.49988 94.6268C2.49988 95.7479 3.05774 97.0137 4.09319 98.3209C5.12264 99.6204 6.58744 100.913 8.30748 102.078C11.7536 104.412 16.1423 106.177 19.9212 106.472C23.6272 106.761 27.6575 104.9 30.9528 102.342C32.5922 101.069 34.029 99.6402 35.1295 98.2546C36.236 96.8617 36.9786 95.5432 37.2668 94.4943Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.60245 83.4111C1.12258 87.5617 -0.00012207 91.882 -0.00012207 94.6268C-0.00012207 96.5456 0.928383 98.3518 2.13354 99.8732C3.36274 101.425 5.03757 102.883 6.90561 104.148C10.6176 106.662 15.4158 108.628 19.7268 108.964C24.3292 109.323 28.9739 107.043 32.4857 104.317C34.2746 102.928 35.8559 101.36 37.0871 99.8096C38.2948 98.2893 39.2631 96.6647 39.6775 95.1568C40.0768 93.7035 40.0576 91.8695 39.8765 90.0463C39.6901 88.1708 39.3075 86.0885 38.8394 84.0317C37.9228 80.0041 36.6439 75.9236 35.7251 73.4044C35.5803 72.6247 35.4429 71.7241 35.3314 70.7022C34.821 66.026 33.9857 55.1456 33.4645 45.7559C32.9443 36.3847 32.895 12.8807 33.4578 7.20261C33.6181 5.58513 33.5319 4.11699 33.1182 2.91662C32.6984 1.6987 31.846 0.543097 30.4105 0.139465C29.0389 -0.246202 27.7037 0.221571 26.6478 0.90741C25.5688 1.60828 24.5255 2.68954 23.5493 4.05397C21.6071 6.7683 18.8958 12.5175 16.4661 19.2932C14.0183 26.1192 11.7752 34.2129 10.8587 41.7067C10.779 42.3585 10.7052 43.0063 10.6368 43.6496C10.1471 48.2534 9.78569 52.9195 9.45567 57.1807L9.45466 57.1937C8.94654 63.7543 8.52038 69.174 7.85349 72.2051C5.95782 75.1242 4.04877 79.3546 2.60245 83.4111ZM9.72154 73.0055C10.4803 69.8518 10.9287 64.0616 11.4487 57.3481C11.7793 53.079 12.1389 48.4365 12.6255 43.8611C12.6929 43.2278 12.7655 42.5904 12.8439 41.9495C14.6296 27.3489 21.6272 10.1773 25.1758 5.21776C28.7244 0.258181 32.0456 1.17358 31.4676 7.00535C30.8896 12.8371 30.9455 36.4619 31.4676 45.8667C31.9896 55.2715 32.8273 66.192 33.3432 70.9192C33.4681 72.0643 33.6247 73.0703 33.7903 73.9372C35.5814 78.8001 38.9436 90.2798 37.749 94.6268C36.4951 99.1895 27.6529 107.576 19.8823 106.97C12.1117 106.365 1.99988 99.7492 1.99988 94.6268C1.99988 89.8782 6.05642 78.465 9.72154 73.0055Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
