import { isSameDay } from 'date-fns'
import { AtendimentosQueryInput, StatusAtendimento } from 'graphql/types.generated'
import { isEqual } from 'lodash'
import { emptyArray } from 'util/array'
import { InstantRange } from 'util/date/dateRange'

import { OBSERVACAO_STATUS_TO_TOGGLE, STATUS_SOMENTE_NAO_FINALIZADOS } from '../model'
import { AtendimentosFilterModel, ListaAtendimentoFilterModel } from './model'

export const isFilterEqualDefaultFilter = (
  filter: ListaAtendimentoFilterModel,
  filterDefault: ListaAtendimentoFilterModel
) => {
  const isPeriodo24h = filter.isPeriodo24h

  const isStartDateEqual = isPeriodo24h || isSameDay(filter.periodo.startDate, filterDefault.periodo.startDate)
  const isEndDateEqual = isPeriodo24h || isSameDay(filter.periodo.endDate, filterDefault.periodo.endDate)

  const isRestEqual = isEqual(
    {
      ...filter,
      statusAtendimento: (filter?.statusAtendimento || []).sort(),
      periodo: null,
      query: filter.query || null,
    },
    {
      ...filterDefault,
      statusAtendimento: filterDefault.statusAtendimento?.sort(),
      periodo: null,
      query: null,
    }
  )

  return isStartDateEqual && isEndDateEqual && isRestEqual
}

export const convertListaAtendimentoFilterToInput = (
  state: AtendimentosFilterModel,
  periodoParaNaoFinalizados: InstantRange,
  periodoDefault: InstantRange
): AtendimentosQueryInput => {
  const isSomenteNaoFinalizados = state.filter.isSomenteNaoFinalizados
  const isPeriodo24h = state.filter.isPeriodo24h

  const dataInicio = isSomenteNaoFinalizados
    ? periodoParaNaoFinalizados.startDate
    : isPeriodo24h
    ? periodoDefault.startDate
    : state.filter.periodo.startDate

  const dataFim = isSomenteNaoFinalizados
    ? periodoParaNaoFinalizados.endDate
    : isPeriodo24h
    ? periodoDefault.endDate
    : state.filter.periodo.endDate

  return {
    query: state.filter.query,
    ordem: state.filter.ordem,
    somenteMeusAtendimentos: state.filter.isSomenteMeusAtendimentos,
    statusAtendimento: filterStatusAtendimento(state.filter),
    dataInicio,
    dataFim,
    tiposServicoIds: state.filter.tiposServico?.map((value) => value.id),
    responsaveisIds: state.filter.responsaveis?.map((value) => value.id),
    equipesIds: state.filter.equipes?.map((value) => value.id),
    isRegistroTardio: false,
    pageParams: state.pageParams,
  }
}

const filterStatusAtendimento = (filter: ListaAtendimentoFilterModel): StatusAtendimento[] => {
  if (filter.isSomenteNaoFinalizados && (!filter.statusAtendimento || filter.statusAtendimento.length === 0)) {
    return STATUS_SOMENTE_NAO_FINALIZADOS
  } else {
    let newStatusAtendimento = [...(filter.statusAtendimento ?? emptyArray)]

    OBSERVACAO_STATUS_TO_TOGGLE.forEach(([statusAtendimento, statusObservacao]) => {
      toggleStatusObservacao(newStatusAtendimento, statusAtendimento, statusObservacao)
    })

    return newStatusAtendimento
  }
}

const toggleStatusObservacao = (
  statusSelected: StatusAtendimento[],
  statusAtendimento: StatusAtendimento,
  statusObservacao: StatusAtendimento
) => {
  const indexOfObservacao = statusSelected.indexOf(statusObservacao)

  const hasStatusAtendimento = statusSelected.includes(statusAtendimento)
  const hasStatusObservacao = indexOfObservacao > -1

  if (hasStatusAtendimento && !hasStatusObservacao) {
    statusSelected.push(statusObservacao)
  } else if (hasStatusObservacao) {
    statusSelected.splice(indexOfObservacao, 1)
  }
}
