import { useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

export function SideMenuList(props: React.HTMLAttributes<HTMLUListElement>) {
  const { classes } = useStyles(createStyles)
  return <ul className={classes.list} {...props} />
}

export const createStyles = () => ({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  } as CSSProperties,
})
