import { AvaliacaoDesenvolvimentoModel } from '../model'
import { avaliacoesListWithStatusAusente, avaliacoesListWithStatusPresenteComAtraso } from '../util'
import { marcoMessages } from './messages'

export const messageR4 = (avaliacoes: AvaliacaoDesenvolvimentoModel[]) => {
  const marcosAusente = avaliacoesListWithStatusAusente(avaliacoes)
  const marcosComAtraso = avaliacoesListWithStatusPresenteComAtraso(avaliacoes)

  if (marcosAusente.length && marcosComAtraso.length) {
    return `${marcoMessages(marcosAusente?.length).desenvolvimentoComAtrasoEAusenteR4}${
      marcoMessages(marcosComAtraso?.length).desenvolvimentoComAtrasoR4
    }${avaliacoes[0].descricaoFaixaEtaria}.`
  } else if (marcosAusente.length) {
    return `${marcoMessages(marcosAusente?.length).desenvolvimentoAusenteR4}${avaliacoes[0].descricaoFaixaEtaria}.`
  } else if (marcosComAtraso.length) {
    return `${marcoMessages(marcosComAtraso?.length).desenvolvimentoComAtrasoR4}${avaliacoes[0].descricaoFaixaEtaria}.`
  }
}
