import { DropdownItem, DropdownItemProps, Omit } from 'bold-ui'
import React from 'react'

export interface ExposedDropdownItemProps<T> extends Omit<DropdownItemProps, 'onClick'> {
  value: T
  onClick(value: T): void
}

export function ExposedDropdownItem<T>(props: ExposedDropdownItemProps<T>) {
  const { value, onClick, ...rest } = props

  const handleClick = () => onClick(value)

  return (
    <>
      <DropdownItem onClick={handleClick} {...rest} />
    </>
  )
}
