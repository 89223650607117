import { LogEventType } from './logFinalizarAtendimento'

export const logEventCancelarAtendimento = (
  logEvent: LogEventType,
  nomeCompletoAtendimento: string,
  isStartObservacao: boolean,
  isAtendimentoObservacao: boolean,
  atendProfFinalizadoEm: Instant
) => {
  if (isStartObservacao) {
    logEvent('cancelar_primeiro_atend_resp_OBS')
  } else if (isAtendimentoObservacao) {
    if (!atendProfFinalizadoEm) {
      logEvent('cancelar_primeiro_atend_aux_OBS')
    } else {
      logEvent('cancelar_rascunhos_atend_OBS')
    }
  } else {
    logEvent('cancelar_atendimento', { Tipo_de_atendimento: nomeCompletoAtendimento })
  }
}
