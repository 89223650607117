import { LotacaoInput } from 'graphql/types.generated'

import { LotacaoFormModel } from './LotacaoForm'

export default (model: LotacaoFormModel): LotacaoInput => ({
  ...model,
  cbo: model.cbo.id,
  equipe: model.equipe && model.equipe.id,
  unidadeSaude: model.unidadeSaude.id,
  perfis: model.perfis && model.perfis.map(p => p.id),
})
