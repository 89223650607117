import { Button, Cell, Grid, HFlow, Omit } from 'bold-ui'
import { alert } from 'components/alert'
import { Box } from 'components/Box'
import { Form, FormPrompt, FormRenderProps, SelectField, SelectFieldProps } from 'components/form'
import { FormApi } from 'final-form'
import { useAlterarPeriodoRedefinicaoSenhaMutation } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import { createValidator, required } from 'util/validation'

export interface ConfiguracoesSenhaBoxProps {
  periodoEmMesesParaRedefinir: number
  refetch(): any
}

const validator = createValidator<FormModel>({
  periodo: [required],
})

export function ConfiguracoesSenhaBox(props: ConfiguracoesSenhaBoxProps) {
  const { periodoEmMesesParaRedefinir, refetch } = props

  const [alterarPeriodoRedefinicaoSenha] = useAlterarPeriodoRedefinicaoSenhaMutation()
  const initialValues = useMemo(
    () =>
      periodoEmMesesParaRedefinir && {
        periodo: {
          id: periodoEmMesesParaRedefinir,
          label: periodoRedefinirSenhaItems.find((item) => item.id === periodoEmMesesParaRedefinir).label,
        },
      },
    [periodoEmMesesParaRedefinir]
  )

  const handleSubmit = (values: FormModel, form: FormApi) => {
    return alterarPeriodoRedefinicaoSenha({ variables: { periodo: values.periodo.id } }).then(() => {
      alert('success', 'Período para redefinição de senha alterado com sucesso.')
      refetch()
    })
  }

  const renderForm = (formProps: FormRenderProps<FormModel>) => {
    return (
      <>
        <FormPrompt />
        <HFlow>
          <Grid>
            <Cell>
              <PeriodoRedefinicaoSenhaSelectField
                label='Período para redefinição de senha'
                name='periodo'
                style={{ width: 287 }}
                clearable={false}
              />
            </Cell>
          </Grid>
          <Grid>
            <Cell>
              <Button
                kind='primary'
                size='small'
                onClick={formProps.handleSubmit}
                style={{ marginTop: '1.5rem' }}
                disabled={!formProps.values.periodo}
              >
                Salvar
              </Button>
            </Cell>
          </Grid>
        </HFlow>
      </>
    )
  }

  return (
    <Box>
      <Form<FormModel> onSubmit={handleSubmit} render={renderForm} validate={validator} initialValues={initialValues} />
    </Box>
  )
}

function PeriodoRedefinicaoSenhaSelectField(props: Omit<SelectFieldProps<ItemType>, 'items' | 'itemToString'>) {
  const itemToString = (item: ItemType) => item && item.label

  return <SelectField<ItemType> items={periodoRedefinirSenhaItems} itemToString={itemToString} {...props} />
}

interface FormModel {
  periodo: ItemType
}

interface ItemType {
  id: number
  label: string
}

export const periodoRedefinirSenhaItems = [
  { id: 1, label: '1 mês' },
  { id: 2, label: '2 meses' },
  { id: 3, label: '3 meses' },
  { id: 4, label: '4 meses' },
  { id: 5, label: '5 meses' },
  { id: 6, label: '6 meses (opção padrão)' },
]
