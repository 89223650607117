/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { SubmitButton } from 'components/form'
import theme from 'config/theme'
import { FormApi } from 'final-form'
import { ParteBucalEnum } from 'graphql/types.generated'
import boca from 'images/atendimento/boca_periodontia.svg'
import { MetaPath } from 'util/metaPath'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { EvolucoesPeriodontaisTable } from './evolucoes-periodontais-table/EvolucoesPeriodontaisTable'
import EvolucoesPeriodontaisForm, { EvolucoesPeriodontaisFormModel } from './EvolucoesPeriodontaisForm'
import EvolucoesPeriodontaisFormFields from './EvolucoesPeriodontaisFormFields'
import { EvolucoesPeriodontaisFieldModel } from './model'

interface EvolucoesPeriodontaisFieldProps {
  name: MetaPath<EvolucoesPeriodontaisFieldModel[]>
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
}

export default function EvolucoesPeriodontaisField(props: EvolucoesPeriodontaisFieldProps) {
  const { name, cidadaoDataNascimento, dataAtendimento } = props

  const {
    handleRowChanged,
    removeItem,
    handleSubmitMultiple,
    input: { value: evolucoes },
  } = useEditableListField<EvolucoesPeriodontaisFieldModel>({
    name,
  })

  const onSubmit = (values: EvolucoesPeriodontaisFormModel, formApi: FormApi) => {
    handleSubmitMultiple(
      values.sextantes.map((sextante) => ({
        _id: undefined,
        sextante: sextante,
        observacao: values.observacao,
        procedimentos: values.procedimentos,
        lastUpdate: values.lastUpdate,
      })),
      formApi
    )
  }

  const handleRemove = (id: ID) => {
    const itemToRemove = evolucoes?.find((outro) => outro._id === id)
    removeItem(itemToRemove)
  }

  return (
    <VFlow>
      <Grid>
        <Cell
          size={3}
          style={css`
            display: flex;
            justify-content: center;
          `}
        >
          <VFlow>
            <img
              height={272}
              src={boca}
              alt='Boca de Periodontia'
              css={css`
                margin-top: 1rem;
              `}
            />
            <Text
              style={css`
                color: ${theme.pallete.gray.c60};
                font-style: italic;
              `}
            >
              *Imagem ilustrativa
            </Text>
          </VFlow>
        </Cell>
        <Cell size={9}>
          <Box>
            <EvolucoesPeriodontaisForm
              onSubmit={onSubmit}
              evolucoesAtuais={evolucoes}
              render={(formProps) => (
                <VFlow>
                  <EvolucoesPeriodontaisFormFields
                    cidadaoDataNascimento={cidadaoDataNascimento}
                    dataAtendimento={dataAtendimento}
                    partesBucais={formProps.values?.sextantes}
                    hasProcedimentos={formProps.values?.procedimentos?.length > 0}
                    procedimentosDisabled={getProcedimentosAtuaisPorSextantes(evolucoes, formProps.values?.sextantes)}
                  />

                  <HFlow justifyContent='flex-end'>
                    <SubmitButton size='small' kind='primary' handleSubmit={formProps.handleSubmit}>
                      Adicionar
                    </SubmitButton>
                  </HFlow>
                </VFlow>
              )}
            />
          </Box>
        </Cell>
      </Grid>

      <EvolucoesPeriodontaisTable
        valoresAtuais={evolucoes}
        cidadaoDataNascimento={cidadaoDataNascimento}
        dataAtendimento={dataAtendimento}
        updateItem={handleRowChanged}
        onRemove={handleRemove}
      />
    </VFlow>
  )
}

export const getProcedimentosAtuaisPorSextantes = (
  evolucoes: EvolucoesPeriodontaisFieldModel[],
  partesBucais: ParteBucalEnum[]
) =>
  (evolucoes || [])
    ?.filter((item) => partesBucais?.includes(item.sextante))
    .map(({ procedimentos }) => procedimentos)
    .flat()
