import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { PecSwitch } from 'components/route'
import { IdadeGestacionalAcompanhamentoPreNatal } from 'graphql/types.generated'
import { useHeight } from 'hooks/useMeasure'
import { useServerTime } from 'hooks/useServerTime'
import { isEmpty } from 'lodash'
import React from 'react'
import { Redirect, Route, useRouteMatch } from 'react-router'
import { emptyArray } from 'util/array'
import { calculateAge } from 'util/date/calculateAge'
import { FolhaRostoView } from 'view/atendimentos/detail/folha-rosto/FolhaRostoView'
import { AtendimentoHeader } from 'view/atendimentos/detail/header/AtendimentoHeader'
import { HistoricoAtendimentoView } from 'view/atendimentos/detail/historico/HistoricoAtendimentoView'

import { CuidadoCompartilhadoFooter } from '../cuidado-compartilhado/components/CuidadoCompartilhadoFooter'
import { CidadaoCuidadoCompartilhado, CuidadoCompartilhadoModel } from '../model-cuidadoCompartilhado'

interface CuidadoCompartilhadoHeaderTabsProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  isVisualizacao: boolean
  idadeGestacional: IdadeGestacionalAcompanhamentoPreNatal
  renderDiscussaoCaso: (cidadao: CidadaoCuidadoCompartilhado, headerHeight: number) => JSX.Element
  handleCancel?: () => void
}

export const CuidadoCompartilhadoHeaderTabs = (props: CuidadoCompartilhadoHeaderTabsProps) => {
  const { cuidadoCompartilhado, isVisualizacao, idadeGestacional, renderDiscussaoCaso, handleCancel } = props

  const match = useRouteMatch()
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const { getServerTimeNow } = useServerTime()
  const [headerRef, headerHeight] = useHeight()

  const prontuario = cuidadoCompartilhado.cidadao.prontuario
  const cidadao: CidadaoCuidadoCompartilhado = {
    ...cuidadoCompartilhado.cidadao,
    idadeEmAnos: calculateAge(cuidadoCompartilhado.cidadao.dataNascimento, getServerTimeNow().getTime()).years,
    isGestante: !isEmpty(prontuario.preNatalAtivo),
  }

  const renderFolhaRosto = () => (
    <>
      <FolhaRostoView
        prontuarioId={prontuario.id}
        cidadao={cidadao}
        lotacao={{ ...acesso, profissional }}
        isGestante={cidadao.isGestante}
        gestacoes={prontuario.gestacoes || emptyArray}
        isVisualizacao={isVisualizacao}
        idadeGestacional={idadeGestacional}
      />
      {handleCancel && <CuidadoCompartilhadoFooter handleCancel={handleCancel} />}
    </>
  )

  const renderHistorico = () => (
    <>
      <HistoricoAtendimentoView
        prontuarioId={prontuario.id}
        cidadao={cidadao}
        unidadeSaudeCnes={acesso?.unidadeSaude.cnes}
        isAtendimentoObservacao={false}
        isObservacaoAndResponsavel={false}
        isVisualizacao={isVisualizacao}
      />
      {handleCancel && <CuidadoCompartilhadoFooter handleCancel={handleCancel} />}
    </>
  )

  return (
    <>
      <AtendimentoHeader
        ref={headerRef}
        cidadaoId={cidadao.id}
        cnsCidadao={cidadao.cns}
        dataAtendimento={cuidadoCompartilhado.iniciadoEm}
        renderSoap={false}
        renderVacinacao={false}
        renderCadastroCidadao={false}
        renderAgendamentos={false}
        renderDiscussaoCaso
      />

      <PecSwitch>
        <Redirect exact path={match.url} to={`${match.url}/discussao-caso`} />
        <Route path={`${match.url}/folha-rosto`} render={renderFolhaRosto} />
        <Route path={`${match.url}/historico`} render={renderHistorico} />
        <Route path={`${match.url}/discussao-caso`} render={() => renderDiscussaoCaso(cidadao, headerHeight)} />
      </PecSwitch>
    </>
  )
}
