/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, useTheme } from 'bold-ui'

export function MedicamentoOnPrescricaoDigitalInfoLabel() {
  const theme = useTheme()

  return (
    <HFlow
      hSpacing={0.5}
      alignItems='center'
      style={css`
        color: ${theme.pallete.status.success.c40};
      `}
    >
      <Icon icon='checkCircleOutline' size={1} />
      Prescrição digital
    </HFlow>
  )
}
