/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { ParteBucalEnum, SituacaoCoroaEnum } from 'graphql/types.generated'
import { Fragment, useEffect, useState } from 'react'

import {
  getCondicaoDente,
  getParteBucal,
  showDescricaoCodigoProcedimento,
} from '../../../util-EvolucoesOdontologicasPlano'
import { Odontograma } from '../../odontograma/Odontograma'
import { FillColor } from '../../odontograma/types/Coroa'
import OdontogramaPopper from '../components/OdontogramaPopper'
import { convertDentesFieldModelToDentesModel } from '../converter'
import { DenteFieldModel, getCoroasDisableds } from './DentesField'

interface DenteActive {
  parteBucalEnum: ParteBucalEnum
  value: DenteFieldModel
}

export interface DentesViewProps {
  fillColors: FillColor<SituacaoCoroaEnum>[]
  showDeciduos?: boolean
  proteseTotalSuperior?: boolean
  proteseTotalInferior?: boolean
  value?: Record<ParteBucalEnum, DenteFieldModel>
}

export default function DentesView(props: DentesViewProps) {
  const { fillColors, showDeciduos, proteseTotalSuperior, proteseTotalInferior, value } = props
  const [popperOpen, setPopperOpen] = useState(false)
  const [coroasDisabled, setCoroasDisabled] = useState<ParteBucalEnum[]>()

  const [activeDente, setActiveDente] = useState<DenteActive>()
  const [activeRef, setActiveRef] = useState<SVGSVGElement | HTMLButtonElement>()

  const onClick = (dente: ParteBucalEnum) => {
    setActiveDente({ parteBucalEnum: dente, value: value && value[dente] })
    setPopperOpen(true)
  }

  const handleClose = () => {
    setActiveDente(undefined)
    setPopperOpen(false)
  }

  useEffect(() => {
    value && setCoroasDisabled(getCoroasDisableds(Object.entries(value) as [ParteBucalEnum, DenteFieldModel][]))
  }, [value])

  return (
    <Fragment>
      <Odontograma
        activeDente={activeDente?.parteBucalEnum}
        fillColors={fillColors}
        showDeciduos={showDeciduos}
        disableCoroaStateEffects
        disabledCoroas={coroasDisabled}
        readonly
        value={{
          dentes: value && convertDentesFieldModelToDentesModel(value),
          proteseTotalSuperior: proteseTotalSuperior,
          proteseTotalInferior: proteseTotalInferior,
        }}
        onClickDente={onClick}
        setActiveRef={({ ref }) => setActiveRef(ref)}
      />

      {activeDente && (
        <OdontogramaPopper
          open={popperOpen}
          width={25}
          title={`Dente - ${getParteBucal(activeDente.parteBucalEnum).number}`}
          placement='right'
          anchorRef={activeRef}
          onClose={handleClose}
        >
          {!activeDente.value?.procedimentos?.length && !activeDente.value?.condicoesDente?.length ? (
            <Text>Nenhuma condição/procedimento registrado.</Text>
          ) : (
            <VFlow>
              {activeDente.value.condicoesDente?.length > 0 && (
                <VFlow vSpacing={0.5}>
                  <Text fontWeight='bold'>Problemas/condições</Text>
                  <VFlow vSpacing={0}>
                    {activeDente.value.condicoesDente
                      .sort((a, b) => getCondicaoDente(a).title.localeCompare(getCondicaoDente(b).title))
                      .map((condicao, key) => (
                        <Text key={key}>{getCondicaoDente(condicao).title}</Text>
                      ))}
                  </VFlow>
                </VFlow>
              )}

              {activeDente.value.procedimentos?.length > 0 && (
                <VFlow vSpacing={0.5}>
                  <Text fontWeight='bold'>Procedimentos</Text>
                  <VFlow vSpacing={0}>
                    {activeDente.value.procedimentos
                      .sort((a, b) => a.descricao.localeCompare(b.descricao))
                      .map((procedimento) => (
                        <Text key={procedimento.id}>{showDescricaoCodigoProcedimento(procedimento)}</Text>
                      ))}
                  </VFlow>
                </VFlow>
              )}

              {activeDente.value.observacoes?.filter((i) => !!i).length > 0 && (
                <VFlow vSpacing={0.5}>
                  <Text fontWeight='bold'>Observações</Text>

                  <VFlow vSpacing={0}>
                    {activeDente.value.observacoes.map((observacao, key) => (
                      <Text key={key} dangerouslySetInnerHTML={{ __html: observacao }} />
                    ))}
                  </VFlow>
                </VFlow>
              )}
            </VFlow>
          )}
        </OdontogramaPopper>
      )}
    </Fragment>
  )
}
