import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { SituacaoProblema } from 'graphql/types.generated'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'

import { Problema } from '../../../aside/types/ProblemaModel'
import { DataIdadeFieldModel } from '../../../components/DataIdadeField'

export interface ProblemaCondicaoModel extends SoapEditableItem {
  _id: ID
  problemaId?: ID
  ciap?: {
    id: ID
    codigo: string
    descricao: string
  }
  cid?: CidSelectFieldModel
  incluirNaListaProblemas?: boolean
  observacao?: string
  situacaoProblema?: SituacaoProblema
  dataInicio?: DataIdadeFieldModel
  dataFim?: DataIdadeFieldModel
  problemaCondicaoEvoluir?: Problema
  isAvaliadoAgora?: boolean
  isRegistradoAgora?: boolean
  isAntecedentes?: boolean
  isAutomatico?: boolean
}

export interface ProblemaCondicaoAntecedenteModel extends ProblemaCondicaoModel {
  hasErrorInicio?: Boolean
  hasErrorFim?: Boolean
}

export interface TodosProblemasCondicoesModel {
  problemasCondicoesAvaliados?: ProblemaCondicaoModel[]
  problemasCondicoesAnteriores?: ProblemaCondicaoModel[]
}

export enum CiapCidRowState {
  DEFAULT,
  OBSERVACAO,
  EDICAO,
}

export interface TooltipAndIsDisabledModel {
  tooltip: string
  isDisabled: boolean
}
