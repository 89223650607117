/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Heading,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableHeader,
  TableLoadingRow,
  TablePlaceholderRow,
  TableRow,
  VFlow,
} from 'bold-ui'
import { useAlert } from 'components/alert'
import { confirm } from 'components/modals/confirm'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import theme from 'config/theme'
import { useHistoricoEvolucoesOdontoTableQuery } from 'graphql/hooks.generated'
import { TipoParteBucalEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { TextFilterType } from 'hooks/filter/model'
import { useFilter } from 'hooks/filter/useFilter'
import { Fragment, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { metaPath } from 'util/metaPath'

import EditarEvolucoesPeriodontaisFormModal from '../EditarEvolucoesPeriodontaisFormModal'
import { EvolucoesPeriodontaisFormModel } from '../EvolucoesPeriodontaisForm'
import {
  EvolucoesPeriodontaisFieldModel,
  EvolucoesPeriodontaisTableItemModel,
  URL_EDITAR_PROCEDIMENTOS_PERIODONTAIS,
} from '../model'
import { joinEvolucoesPeriodontais } from '../util'
import { EvolucoesPeriodontaisTableHeader } from './EvolucoesPeriodontaisTableHeader'
import EvolucoesPeriodontaisTableRow from './EvolucoesPeriodontaisTableRow'

interface EvolucoesPeriodontaisTableProps {
  valoresAtuais?: EvolucoesPeriodontaisFieldModel[]
  cidadaoDataNascimento?: LocalDate
  dataAtendimento: LocalDate
  updateItem?(item: EvolucoesPeriodontaisFieldModel): void
  onRemove(id: ID): void
}

interface EvolucoesPeriodontaisTableFilterModel {
  query: string
}

const TIPOS_PARTES_BUCAIS = [TipoParteBucalEnum.SEXTANTE]

export function EvolucoesPeriodontaisTable(props: EvolucoesPeriodontaisTableProps) {
  const { valoresAtuais, cidadaoDataNascimento, dataAtendimento, updateItem, onRemove } = props

  const alert = useAlert()
  const match = useRouteMatch()
  const history = useHistory()

  const {
    prontuario: { id: prontuarioId },
    atendimentoProfissional: { iniciadoEm, lotacao },
  } = useAtendimentoContext()

  const [filter, setFilter] = useState<EvolucoesPeriodontaisTableFilterModel>()

  const {
    data: { evolucoesProcedimentoOdonto },
    loading,
  } = useHistoricoEvolucoesOdontoTableQuery({
    variables: {
      input: {
        tipoPartesBucais: TIPOS_PARTES_BUCAIS,
        prontuarioId,
        unpaged: true,
      },
    },
  })

  const joinedItems = joinEvolucoesPeriodontais(
    evolucoesProcedimentoOdonto?.content,
    valoresAtuais,
    lotacao,
    iniciadoEm.valueOf()
  )

  const filteredItems = useFilter<EvolucoesPeriodontaisTableItemModel, EvolucoesPeriodontaisTableFilterModel>({
    items: joinedItems,
    filter,
    filtersType: [filterTextTypeConfig],
  })

  const { paginatedItems, tableProps } = usePagination<EvolucoesPeriodontaisTableItemModel>({ items: filteredItems })

  const isNotEmpty = paginatedItems.length > 0

  const onSubmitEdicao = (values: EvolucoesPeriodontaisFormModel) => {
    const { sextantes, ...rest } = values
    updateItem({ ...rest, sextante: sextantes[0] })
  }

  const handleRemove = (id: ID) =>
    confirm({
      title: 'Deseja excluir o registro odontológico?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        onRemove(id)
        alert('success', 'Registro odontológico excluído com sucesso')
      },
    })()

  return (
    (isNotEmpty || loading || !!filter?.query) && (
      <Fragment>
        <VFlow vSpacing={0.5}>
          <Heading level={4}>Histórico de evoluções</Heading>

          <TableBox header={<EvolucoesPeriodontaisTableHeader onChangeFilter={(query) => setFilter({ query })} />}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>Data</TableHeader>
                  <TableHeader>Local</TableHeader>
                  <TableHeader>Procedimentos</TableHeader>
                  <TableHeader />
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableLoadingRow colSpan={4} />
                ) : isNotEmpty ? (
                  paginatedItems.map((item, index) => (
                    <EvolucoesPeriodontaisTableRow key={index} row={item} onRemove={handleRemove} />
                  ))
                ) : (
                  <TablePlaceholderRow colSpan={4} />
                )}
              </TableBody>
            </Table>
            <TableFooter {...tableProps} style={classes.tableFooter} />
          </TableBox>
        </VFlow>
        <Route path={`${match.url}${URL_EDITAR_PROCEDIMENTOS_PERIODONTAIS}:id`}>
          <EditarEvolucoesPeriodontaisFormModal
            cidadaoDataNascimento={cidadaoDataNascimento}
            dataAtendimento={dataAtendimento}
            evolucoesAtuais={valoresAtuais}
            onSubmit={onSubmitEdicao}
            onClose={() => {
              history.push(match.url.split(URL_EDITAR_PROCEDIMENTOS_PERIODONTAIS)[0])
            }}
          />
        </Route>
      </Fragment>
    )
  )
}

const metaRow = metaPath<EvolucoesPeriodontaisTableItemModel>()
const metaFilter = metaPath<EvolucoesPeriodontaisTableFilterModel>()

const filterTextTypeConfig: TextFilterType = {
  filterField: metaFilter.query,
  searchFields: [
    metaRow.local,
    metaRow.searchProcedimentos,
    metaRow.lotacao.profissional.nome,
    metaRow.lotacao.cbo.nome,
  ],
  removeTagsOnFilter: true,
}

const classes = {
  tableHeader: css`
    padding: 0.5rem 1rem;
    background-color: ${theme.pallete.primary.c100};
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
  tableFooter: css`
    border-top: 0;
  `,
}
