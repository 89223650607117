import { HFlow } from 'bold-ui'
import { Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { FormState } from 'final-form'
import { TodasVinculacoesEquipesInput } from 'graphql/types.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'

interface EquipeEMultiVinculacoesTableFilterProps {
  initialValues?: TodasVinculacoesEquipesInput
  onChange(values: TodasVinculacoesEquipesInput): void
}

const meta = metaPath<TodasVinculacoesEquipesInput>()

export function EquipeEMultiVinculacoesTableFilter(props: EquipeEMultiVinculacoesTableFilterProps) {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<TodasVinculacoesEquipesInput>) => {
    onChange(formState.values)
  }

  const renderForm = () => (
    <HFlow justifyContent='space-between'>
      <FormDebouncedValueSpy onChange={handleChange} />
      <TextField name={meta.query} icon='zoomOutline' placeholder='Pesquise por equipes' style={{ width: '19rem' }} />
    </HFlow>
  )

  return <Form<TodasVinculacoesEquipesInput> onSubmit={onChange} render={renderForm} initialValues={initialValues} />
}
