import { useEffect } from 'react'

export function useOnBeforeUnload(onBeforeUnload: () => void) {
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [onBeforeUnload])
}
