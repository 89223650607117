/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, Text, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import image from 'images/videochamada/videochamada-encerrada.png'

interface VideochamadaEncerradaQueryParams {
  owner?: string
}

export function VideochamadaEncerradaView() {
  const { owner } = useUrlQueryParams<VideochamadaEncerradaQueryParams>()
  const isOwner = owner === 'true'

  return (
    <PageContent type='filled' style={styles.container}>
      <Grid alignItems='center' justifyContent='space-between'>
        <Cell size={5}>
          <VFlow vSpacing={0}>
            <Heading level={1}>Chamada encerrada</Heading>
            {isOwner ? (
              <VFlow vSpacing={0}>
                <Text fontWeight='bold'>Você encerrou a chamada, não é mais possível acessá-la.</Text>
                <Text>Para criar uma nova chamada, volte ao módulo de Videochamadas.</Text>
              </VFlow>
            ) : (
              <Text>A chamada foi encerrada pelo profissional responsável, não é mais possível acessá-la.</Text>
            )}
          </VFlow>
        </Cell>
        <Cell>
          <img
            width={528}
            height={390}
            src={image}
            alt='Duas pessoas com as mãos levantadas, representando que estão se despedindo em uma chamada de vídeo'
          />
        </Cell>
      </Grid>
    </PageContent>
  )
}

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 1024px;
  `,
}
