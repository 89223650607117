import { Text, VFlow } from 'bold-ui'
import React from 'react'

export const UnificacaoBaseOrientacoesView = () => {
  return (
    <VFlow vSpacing={0}>
      <Text color='primary'>
        <strong>1</strong> - É recomendado realizar o backup de ambas as bases antes de iniciar o processo de unificação
        de base;
      </Text>
      <Text color='primary'>
        <strong>2</strong> - Antes de iniciar a unificação de base, sincronize os aplicativos AD e AC e ao finalizar a
        sincronização, desinstale-os. Após o término da unificação de base, instale novamente os aplicativos com a nova
        configuração;
      </Text>
      <Text color='primary'>
        <strong>3</strong> - É recomendado que o processo de unificação de base seja realizado apenas para o link da
        instalação central do município e fora do horário de expediente de trabalho;
      </Text>
      <Text color='primary'>
        <strong>4</strong> - É necessário que ambas as instalações estejam na mesma versão do sistema e com a agenda
        online desabilitada;
      </Text>
      <Text color='primary'>
        <strong>5</strong> - A instalação central do município deve possuir o link de instalação com o protocolo https{' '}
        <i>(ex.: https://linkdainstalacao.gov.br)</i>;
      </Text>
      <Text color='primary'>
        <strong>6</strong> - Atualize o CNES da instalação local e da instalação central do município para o mais
        recente;
      </Text>
      <Text color='primary'>
        <strong>7</strong> - Para realizar a solicitação de unificação, o responsável pela instalação local deverá ter
        uma lotação ativa na instalação central que receberá os dados;
      </Text>
      <Text color='primary'>
        <strong>8</strong> - Os perfis personalizados criados manualmente na instalação local não serão enviados para a
        instalação central do município. Serão enviados os profissionais que possuam lotações ativas ou inativas no
        sistema. Todas as lotações dos profissionais serão cadastradas com o perfil padrão de acordo com o CBO do
        profissional. Caso necessário, os perfis cadastrados manualmente devem ser recadastrados na instalação central
        do município <i>(ex.: perfil de administrador de banco de dados)</i>;
      </Text>
      <Text color='primary'>
        <strong>9</strong> - Serão enviados cidadãos que possuam prontuário ou vínculo com equipes profissionais
        cadastradas via PEC. As consultas agendadas dos cidadãos serão enviadas normalmente. Contudo, as agendas
        individuais dos profissionais não serão enviadas
        <i>(fechamento, dias e horários de trabalho)</i>. Será necessário recadastrá-las na instalação central do
        município;
      </Text>
      <Text color='primary'>
        <strong>10</strong> - A partir do início do processo de unificação de bases, a instalação que está realizando o
        envio ficará inacessível aos usuários, exceto para o administrador da instalação.
      </Text>
      <Text color='primary'>
        <strong>11</strong> - Enquanto estiver recebendo e processando alguma unificação de bases, a instalação central
        do município não poderá solicitar uma nova unificação.
      </Text>
    </VFlow>
  )
}
