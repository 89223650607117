import { TipoEdemaEnum } from 'graphql/types.generated'
import React from 'react'

import { RadioGroupField, RadioGroupFieldProps, RadioOption } from './RadioGroupField'

interface EdemaRadioGroupFieldProps extends Omit<RadioGroupFieldProps<TipoEdemaEnum>, 'label' | 'options'> {}

export function EdemaRadioGroupField(props: EdemaRadioGroupFieldProps) {
  return <RadioGroupField label='Edema' options={options} {...props} />
}

const options: RadioOption<TipoEdemaEnum>[] = [
  { value: TipoEdemaEnum.NEGATIVO, label: '-' },
  { value: TipoEdemaEnum.POSITIVO, label: '+' },
  { value: TipoEdemaEnum.DOIS_POSITIVOS, label: '++' },
  { value: TipoEdemaEnum.TRES_POSITIVOS, label: '+++' },
]
