import api from 'api'
import { AxiosPromise, AxiosResponse } from 'axios'
import { TipoReceitaEnum } from 'graphql/types.generated'
import React from 'react'
import { AtestadoRecenteImpressaoInput } from 'view/atendimentos/detail/soap/plano/atestados/model-atestado'
import { ImpressaoMedicamentosInput } from 'view/atendimentos/detail/soap/plano/prescricao-medicamento/modal/impressao/ImpressaoMedicamentosInput'

import { ContaGovBrConfiabilidadeInsuficienteMessage } from './ContaGovBrConfiabilidadeInsuficienteMessage'

export enum TipoDocumentoAssinaturaDigitalPopup {
  PRESCRICAO,
  ATESTADO,
}

type StatusAssinaturaDigitalPopup = 'success' | 'failure' | 'canceled'

export const INTERVAL_DELAY_ASSINATURA_DIGITAL_POPUP = 500 // ms

export type GerarAssinaturaDigitalInputType = ImpressaoMedicamentosInput | AtestadoRecenteImpressaoInput

export type EmitirAssinaturaDigitalInputType = EmitirPrescricaoDigitalInput | EmitirAssinaturaDigitalInput

export interface AssinaturaDigitalPopupResult<T> {
  data: AssinaturaDigitalPopupResponse
  openPopup: (input: T) => Promise<void>
  closePopup: () => void
}

export interface AssinaturaDigitalPopupResponse {
  status?: StatusAssinaturaDigitalPopup
  response?: AxiosResponse
  codigoDocumento?: string
  error?: DocumentoDigitalErrorCodeEnum
  identifier: string
}

export interface EmitirPrescricaoDigitalInput extends EmitirAssinaturaDigitalPopupInput {
  codigoDocumento: string
  tipoReceita: TipoReceitaEnum
}

export interface EmitirAssinaturaDigitalInput extends EmitirAssinaturaDigitalPopupInput {
  codigoDocumento: string
}

export interface EmitirPrescricaoDigitalPopupInput extends EmitirAssinaturaDigitalPopupInput {
  tipoReceita: TipoReceitaEnum
}

export interface EmitirAssinaturaDigitalPopupInput {
  atendimentoId: ID
  cidadaoId: ID
  emailCidadao: string
}

interface AssinaturaDigitalPopupConfig {
  target: string
  gerar: (input: GerarAssinaturaDigitalInputType) => AxiosPromise
  emitir: (input: EmitirAssinaturaDigitalInputType) => AxiosPromise
}

export const configAssinaturaDigitalByTipoDocumento: Record<
  TipoDocumentoAssinaturaDigitalPopup,
  AssinaturaDigitalPopupConfig
> = {
  [TipoDocumentoAssinaturaDigitalPopup.PRESCRICAO]: {
    target: 'prescricao-digital',
    gerar: api.prescricaoDigital.gerarPrescricaoDigital,
    emitir: api.prescricaoDigital.emitirPrescricaoDigital,
  },
  [TipoDocumentoAssinaturaDigitalPopup.ATESTADO]: {
    target: 'atestado-digital',
    gerar: api.atestado.gerarAtestadoDigital,
    emitir: api.atestado.emitirAtestadoDigital,
  },
}

export enum DocumentoDigitalErrorCodeEnum {
  ASSINATURA_INVALIDA = 1,
  CONFIABILIDADE_INSUFICIENTE = 2,
  CPFS_DIFERENTES = 3,
  DOCUMENTO_JA_ASSINADO = 4,
  PROFISSIONAL_SEM_CONSELHO = 5,
}

export const documentoDigitalErrorCodeToMessage: Record<DocumentoDigitalErrorCodeEnum, React.ReactNode> = {
  [DocumentoDigitalErrorCodeEnum.ASSINATURA_INVALIDA]: 'Não foi possível obter a assinatura.',
  [DocumentoDigitalErrorCodeEnum.CONFIABILIDADE_INSUFICIENTE]: <ContaGovBrConfiabilidadeInsuficienteMessage />,
  [DocumentoDigitalErrorCodeEnum.CPFS_DIFERENTES]: 'O CPF do profissional não condiz com o CPF da assinatura.',
  [DocumentoDigitalErrorCodeEnum.DOCUMENTO_JA_ASSINADO]: 'O documento já foi assinado previamente.',
  [DocumentoDigitalErrorCodeEnum.PROFISSIONAL_SEM_CONSELHO]:
    'É necessário possuir um conselho de classe cadastrado para assinar um documento.',
}

export const defaultAssinaturaDigitalErrorMessage = 'Não foi possível assinar o documento. Tente novamente mais tarde.'
export const assinaturaDigitalCancelMessage = 'Geração de atestado digital cancelada.'
