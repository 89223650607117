import { parseJSON } from 'date-fns'

import { HorarioAgendaSelectFieldModel } from './HorarioAgendaSelectField'

/**
 * Ao resgatar um tipo Date da cache esta retornando no formato ISO8601
 * não sendo reconhecida adequadamente como tipo Date.
 */
export const parseDateFromLocalStorage = (horario: any): HorarioAgendaSelectFieldModel =>
  horario
    ? {
        inicial: horario.inicial && parseJSON(horario.inicial),
        final: horario.final && parseJSON(horario.final),
      }
    : undefined
