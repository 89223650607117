import EsusView from 'components/esus/EsusView'
import React from 'react'

export default props => {
  return <EsusView url='/pec/user/relatorios/producao/analiticoAtendimentoDomiciliar' />
  // const { match, history } = useRouter()
  // const isSerie = matchPath(history.location.pathname, `${match.url}/serie`)
  // return (
  //   <PageContent type='filled'>
  //     <Breadcrumb title='Atendimento domiciliar' />
  //     <VFlow>
  //       <Header isSerie={isSerie} url={match.url} />
  //       <HeadingSection level={3} title='Modelo do relatório' />
  //       <PecSwitch>
  //         {hasAuthorization(Permissions.relatorios.producao.atendimentoDomiciliar) && (
  //           <Redirect exact path={`${match.url}`} to={`${match.url}/serie`} />
  //         )}
  //         <PrivateRoute
  //           exact
  //           path={`${match.url}/serie`}
  //           permission={Permissions.relatorios.producao.atendimentoDomiciliar}
  //           component={AtendimentoDomiciliarSerieHistoricaView}
  //         />
  //         <PrivateRoute
  //           exact
  //           path={`${match.url}/analitico`}
  //           permission={Permissions.relatorios.producao.atendimentoDomiciliar}
  //           component={AtendimentoDomiciliarAnaliticoView}
  //         />
  //       </PecSwitch>
  //     </VFlow>
  //   </PageContent>
  // )
}

// const Header = ({ isSerie, url }) => {
//   return (
//     <VFlow vSpacing={1}>
//       <PageHeaderSection title='Atendimento domiciliar' />
//       <HFlow justifyContent='flex-end'>
//         <ButtonGroup>
//           <ButtonLink kind={!isSerie ? 'primary' : 'normal'} size='small' to={`${url}/analitico`}>
//             Analítico
//           </ButtonLink>
//           <ButtonLink kind={isSerie ? 'primary' : 'normal'} size='small' to={`${url}/serie`}>
//             Série Histórica
//           </ButtonLink>
//         </ButtonGroup>
//       </HFlow>
//     </VFlow>
//   )
// }
