import { Omit, Text } from 'bold-ui'
import { useTipoEventoSelectFieldQuery } from 'graphql/hooks.generated'
import { TipoEventoSelectFieldQuery } from 'graphql/types.generated'
import React from 'react'

import { SelectField, SelectFieldProps } from '../../../final-form'

export type TipoEventoSelectModel = TipoEventoSelectFieldQuery['auditoriaTipoEvento'][0]

export interface TipoEventoProps extends Omit<SelectFieldProps<TipoEventoSelectModel>, 'items' | 'itemToString'> {}

export interface TipoEventoVariables {}

export function TipoEventoSelectField(props: TipoEventoProps) {
  const { data } = useTipoEventoSelectFieldQuery({
    fetchPolicy: 'cache-first',
  })

  if (!data) {
    return null
  }

  const itemToString = (item: TipoEventoSelectModel) => item && item.nome

  const renderItem = (item: TipoEventoSelectModel) => {
    return <Text>{item.nome}</Text>
  }
  return (
    <SelectField<TipoEventoSelectModel>
      items={data.auditoriaTipoEvento}
      itemToString={itemToString}
      renderItem={renderItem}
      {...props}
    />
  )
}
