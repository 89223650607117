import { FormControl, FormControlProps, Theme, useStyles } from 'bold-ui'
import { TypographyVariant } from 'bold-ui/lib/styles/theme/createTypography'
import { CSSProperties } from 'react'
import React from 'react'

interface HeadingFormControlProps extends FormControlProps {
  level: TypographyVariant
}

function HeadingFormControl(props: HeadingFormControlProps) {
  const { level } = props

  const { classes } = useStyles(createStyles, { level })

  return <FormControl style={classes.heading} {...props} />
}

export default React.memo(HeadingFormControl)

const createStyles = (theme: Theme, { level }) => ({
  heading: {
    '> label': {
      ...theme.typography.variant(level),
    } as CSSProperties,
  } as CSSProperties,
})
