/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, Theme, useTheme } from 'bold-ui'

interface PuericulturaBoxProps {
  children: React.ReactElement
}

export const PuericulturaBox = (props: PuericulturaBoxProps) => {
  const { children } = props

  const theme = useTheme()
  const classes = createStyles(theme)

  return <div css={classes.container}>{children}</div>
}

const createStyles = (theme: Theme) => ({
  container: css`
    padding: 1rem;
    border: 1px ${colors.turquoise.c60} solid;
    border-radius: ${theme.radius.input}px;
  `,
})
