/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Heading,
  HFlow,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalProps,
  Switch,
  Tag,
  Text,
  Theme,
  Tooltip,
  useTheme,
  VFlow,
} from 'bold-ui'
import { Box } from 'components/Box'
import { useCallback, useMemo, useState } from 'react'

interface CookiePreferencesModalProps extends Omit<ModalProps, 'open'> {
  onClickAceitarNecessarios: () => void
  onClickAceitarTodos: () => void
  onOpenTermosUso: () => void
}

export const CookiePreferencesModal = (props: CookiePreferencesModalProps) => {
  const { onClickAceitarNecessarios, onClickAceitarTodos, onOpenTermosUso, ...rest } = props
  const theme = useTheme()
  const [enableCookiesDesempenho, setEnableCookiesDesempenho] = useState(false)
  const styles = useMemo(() => createStyles(theme, enableCookiesDesempenho), [theme, enableCookiesDesempenho])

  const toggleEnableCookiesDesempenho = useCallback(() => {
    setEnableCookiesDesempenho((prev) => !prev)
  }, [])

  return (
    <Modal open {...rest}>
      <ModalBody>
        <VFlow vSpacing={2}>
          <Heading level={1}>
            <HFlow hSpacing={1} alignItems='center'>
              <Icon icon='cookieOutline' style={styles.cookieOutline} aria-label='Ícone de cookie' />
              <HFlow alignItems='center'>Configurações avançadas de cookies</HFlow>
            </HFlow>
          </Heading>
          <VFlow vSpacing={2}>
            <Box>
              <HFlow hSpacing={0.5}>
                <Text>
                  Para melhorar a sua experiência no sistema e prover serviços personalizados, utilizamos cookies.
                </Text>
                <Button
                  kind='normal'
                  skin='default'
                  size='small'
                  style={styles.declaracaoButton}
                  onClick={onOpenTermosUso}
                >
                  <Icon icon='fileVisualizationOutline' />
                  Ver declaração de cookies
                </Button>
              </HFlow>
            </Box>
            <VFlow vSpacing={1}>
              <Box>
                <VFlow vSpacing={0.5}>
                  <HFlow alignItems='center' justifyContent='space-between'>
                    <Heading level={3}>Cookies estritamente necessários</Heading>
                    <HFlow style={styles.cookiesEssenciaisSwitchState}>
                      Ativado
                      <Tooltip text='Cookies obrigatórios não podem ser desativados'>
                        <Tag style={styles.switchLocked}>
                          <Icon icon='lockCloseFilled' style={styles.lock} size={1} />
                        </Tag>
                      </Tooltip>
                    </HFlow>
                  </HFlow>
                  <Text>
                    Esses cookies permitem funcionalidades essenciais, tais como a manutenção e segurança da sessão do
                    usuário. Esses cookies não podem ser desativados em nossos sistemas. Embora sejam necessários, você
                    pode bloquear esses cookies diretamente no seu navegador, mas isso pode comprometer sua experiência
                    e prejudicar o funcionamento do site.
                  </Text>
                </VFlow>
              </Box>
              <Box>
                <VFlow vSpacing={0.5}>
                  <HFlow alignItems='center' justifyContent='space-between'>
                    <Heading level={3}>Cookies de desempenho</Heading>
                    <HFlow style={styles.cookiesDesempenhoSwitchState}>
                      {enableCookiesDesempenho ? 'Ativado' : 'Desativado'}
                      <Switch onChange={toggleEnableCookiesDesempenho} />
                    </HFlow>
                  </HFlow>
                  <Text>
                    Cookies de desempenho visam a melhoria do desempenho do site por meio da coleta de dados
                    anonimizados sobre navegação e uso dos recursos disponibilizados. Se você não permitir a coleta
                    desses cookies, esses dados não serão usados para melhoria do site.
                  </Text>
                </VFlow>
              </Box>
            </VFlow>
          </VFlow>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='space-between'>
          <Button onClick={onClickAceitarTodos} style={styles.aceitarButton}>
            Aceitar todos
          </Button>
          <Button
            onClick={enableCookiesDesempenho ? onClickAceitarTodos : onClickAceitarNecessarios}
            kind='primary'
            style={styles.salvarButton}
          >
            Salvar configuração
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const createStyles = (theme: Theme, cookiesDesempenhoState: boolean) => ({
  declaracaoButton: css`
    width: 14rem;
    height: 2rem;
    color: ${theme.pallete.primary.c40};
    border-color: ${theme.pallete.primary.c40};
  `,
  aceitarButton: css`
    width: 10.5rem;
  `,
  salvarButton: css`
    width: 13rem;
  `,
  cookiesEssenciaisSwitchState: css`
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    color: ${theme.pallete.primary.c40};
  `,
  cookiesDesempenhoSwitchState: css`
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    color: ${cookiesDesempenhoState ? theme.pallete.primary.c40 : theme.pallete.gray.c40};
  `,
  switchLocked: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 1.5rem;
    background-color: ${theme.pallete.primary.c80};
    border-radius: 0.75rem;
  `,
  cookieOutline: css`
    fill: ${theme.pallete.primary.c40};
    width: 3rem;
    height: 3rem;
  `,
  lock: css`
    fill: ${theme.pallete.gray.c100};
  `,
})
