import { Heading, HFlow, Text, VFlow } from 'bold-ui'
import logo from 'images/esus.svg'
import * as React from 'react'

interface InstalacaoHeaderProps {
  title: string
  subTitle?: string
}

export const InstalacaoHeader = (props: InstalacaoHeaderProps) => {
  return (
    <HFlow justifyContent='space-between' alignItems='center' style={{ paddingTop: '2.125rem' }}>
      <img src={logo} alt='Logo e-SUS Atenção Primária' />
      <VFlow vSpacing={0} style={{ textAlign: 'center' }}>
        <Heading level={1}>{props.title}</Heading>
        {props.subTitle && (
          <Heading fontWeight='normal' level={3}>
            {props.subTitle}
          </Heading>
        )}
      </VFlow>
      <VFlow vSpacing={0} style={{ textAlign: 'right' }}>
        <Text>Sistema fornecido pelo</Text>
        <Text fontWeight='bold'>Ministério da Saúde</Text>
        <Text>Versão {process.env.REACT_APP_VERSION}</Text>
      </VFlow>
    </HFlow>
  )
}
