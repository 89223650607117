import { SelectField } from 'components/form'
import { UnidadeMedidaSelectFieldDocument } from 'graphql/hooks.generated'
import { UnidadeMedidaSelectFieldQuery, UnidadeMedidaSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface UnidadeMedidaSelectModel {
  id: ID
  nome: string
  nomePlural: string
}

export interface UnidadeMedidaSelectFieldProps extends AsyncSelectFieldProps<UnidadeMedidaSelectModel> {}

export function UnidadeMedidaSelectField(props: UnidadeMedidaSelectFieldProps) {
  const extractItems = (data: UnidadeMedidaSelectFieldQuery) => {
    return data?.unidadesMedida?.content
  }

  const handleItemToString = (item: UnidadeMedidaSelectModel) => {
    return item?.nome
  }

  const variables = (inputQuery: string): UnidadeMedidaSelectFieldQueryVariables => ({
    input: {
      query: inputQuery,
      pageParams: DEFAULT_SELECT_PAGE_PARAM,
    },
  })

  const { selectProps } = useAsyncQuerySelect<
    UnidadeMedidaSelectModel,
    UnidadeMedidaSelectFieldQuery,
    UnidadeMedidaSelectFieldQueryVariables
  >({
    query: UnidadeMedidaSelectFieldDocument,
    extractItems,
    variables,
  })

  return <SelectField<UnidadeMedidaSelectModel> itemToString={handleItemToString} {...selectProps} {...props} />
}
