import { EncaminhamentoExternoInput } from 'graphql/types.generated'
import { v4 as uuidv4 } from 'uuid'

import { EncaminhamentoExternoEspecializadoModel, EncaminhamentoExternoEspecializadoQueryModel } from './model'

export const convertEncaminhamentoPlanoFormToInput = (
  encaminhamentos: EncaminhamentoExternoEspecializadoModel[]
): EncaminhamentoExternoInput[] =>
  encaminhamentos.map((encaminhamento) => ({
    id: encaminhamento.editingId,
    observacoes: encaminhamento.observacoes,
    especialidadeSisreg: encaminhamento.especialidadeSisreg.id,
    motivoEncaminhamento: encaminhamento.motivoEncaminhamento,
    hipoteseDiagnosticoCid10:
      encaminhamento.cidEncaminhamento?.id || encaminhamento.ciapEncaminhamento.cid10Encaminhamento.id,
    hipoteseDiagnosticoCiap: encaminhamento.ciapEncaminhamento?.id,
    complemento: encaminhamento.complemento,
    classificacaoRiscoEncaminhamento: encaminhamento.classificacaoRiscoEncaminhamento,
  }))

export const convertEncaminhamentoEspecializadoQueryToForm = (
  encaminhamento: EncaminhamentoExternoEspecializadoQueryModel
): EncaminhamentoExternoEspecializadoModel => {
  const {
    id,
    classificacaoRiscoEncaminhamento,
    especialidadeSisreg,
    hipoteseDiagnosticoCiap,
    hipoteseDiagnosticoCid10,
    complemento,
    motivoEncaminhamento,
    observacoes,
  } = encaminhamento

  return {
    _id: uuidv4(),
    editingId: id,
    classificacaoRiscoEncaminhamento,
    observacoes,
    especialidadeSisreg,
    complemento,
    motivoEncaminhamento,
    ciapEncaminhamento: hipoteseDiagnosticoCiap,
    cidEncaminhamento: hipoteseDiagnosticoCid10,
  }
}
