import useSession from 'components/auth/useSession'
import { useSessionStorage } from 'hooks/useStorage'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { JustificativaVerProntuario } from './useJustificativaVerProntuarioId'

export function useRemoveJustificativaAcessoProntuarioOnNavigation() {
  const { data: { acesso } = {} } = useSession()

  const location = useLocation()
  const key = `${acesso.id}/justificativa-ver-prontuario`
  const [getJustificativa, , removerJustificativa] = useSessionStorage<JustificativaVerProntuario>(key)

  useEffect(() => {
    if (acesso) {
      const justificativa = getJustificativa()
      if (justificativa && !location.pathname.includes(justificativa.basePath)) {
        removerJustificativa()
      }
    }
  }, [acesso, getJustificativa, location.pathname, removerJustificativa])
}
