import { SortDirection } from 'bold-ui'

interface TableSortProps {
  sort: string[]
  onSortChange(sort: string[]): void
}

export function useTableSort(props: TableSortProps) {
  const { sort, onSortChange } = props

  const handleSortChange = (columnName: string) => (direction: SortDirection, shiftKey: boolean) => {
    onSortChange(changeSort(sort, columnName, direction, shiftKey))
  }

  const getSortProps = (columnName: string) => {
    return {
      sortDirection: getSortDirection(sort, columnName),
      onSortChange: handleSortChange(columnName),
      sortable: true,
    }
  }

  return { getSortProps }
}

export const getSortDirection = (currentSort: string[], columnName: string): SortDirection => {
  for (const eachSort of currentSort) {
    if (eachSort === columnName) {
      return 'ASC'
    } else if (eachSort === `-${columnName}`) {
      return 'DESC'
    }
  }
  return null
}

export const changeSort = (
  currentSort: string[],
  columnName: string,
  sortDirection: SortDirection,
  shiftKey: boolean = false
) => {
  if (shiftKey) {
    return changeShiftSort(currentSort, columnName, sortDirection)
  } else {
    return [sortDirection === 'ASC' ? columnName : `-${columnName}`]
  }
}

const changeShiftSort = (currentSort: string[], name: string, dir: SortDirection): string[] => {
  const newSort = dir === 'ASC' ? name : `-${name}`
  let swap = false
  const newArray = currentSort.map(s => {
    if (s === name || s === `-${name}`) {
      swap = true
      return newSort
    }
    return s
  })
  return swap ? newArray : [...newArray, newSort]
}
