/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Modal, ModalBody, Theme, useTheme } from 'bold-ui'
import { PageLoading } from 'components/loading'
import { useAlergiasModalQuery } from 'graphql/hooks.generated'
import { useHistory, useRouteMatch } from 'react-router'
import { MetaArray } from 'util/metaPath'
import formatAlergias from 'view/atendimentos/detail/soap/aside/alergias/formatAlergias'
import { AlergiaReacaoModel } from 'view/atendimentos/detail/soap/avaliacao/components/alergias-reacoes/model'

import { CadastroAlergiasModalProps } from './CadastroAlergiasModal'
import { EditableListaAlergiasModalBody, ReadOnlyListaAlergiasModalBody } from './ListaAlergiasModalBody'

export interface ListaAlergiasModalProps extends Pick<CadastroAlergiasModalProps, 'dataAtendimento'> {
  prontuarioId: ID
  alergiasAtendimentoAtual: AlergiaReacaoModel[]
  isAtendimentoProcedimentos?: boolean
  editable?: false
}

export interface EditableListaAlergiasModalProps extends Omit<ListaAlergiasModalProps, 'editable'> {
  fieldName: MetaArray<AlergiaReacaoModel>
  editable: true
}

export function ListaAlergiasModal(props: ListaAlergiasModalProps | EditableListaAlergiasModalProps) {
  const { prontuarioId, alergiasAtendimentoAtual, isAtendimentoProcedimentos = false } = props

  const history = useHistory()
  const match = useRouteMatch()
  const theme = useTheme()

  const { data, loading } = useAlergiasModalQuery({
    variables: { filtro: { prontuarioId } },
  })

  const todasAlergias = formatAlergias(data?.alergias?.content ?? [], alergiasAtendimentoAtual, true).getAlergias()

  const onClose = () => history.push(match.url.replace('/lista-alergias', ''))

  const classes = createStyles(theme)

  return (
    <Modal style={classes.modal} open onClose={onClose}>
      <ModalBody>
        {loading ? (
          <PageLoading message='Carregando dados' />
        ) : !isAtendimentoProcedimentos && props.editable ? (
          <EditableListaAlergiasModalBody
            todasAlergias={todasAlergias}
            alergiasCidadao={data?.alergias?.content}
            {...props}
          />
        ) : (
          <ReadOnlyListaAlergiasModalBody todasAlergias={todasAlergias} />
        )}
      </ModalBody>
    </Modal>
  )
}

const createStyles = (theme: Theme) => ({
  modal: css`
    background-color: ${theme.pallete.surface.main};
    min-width: 64rem;
    max-height: 95vh;
  `,
})
