import { Omit, useStyles } from 'bold-ui'
import React from 'react'

interface ConfiguracaoAgendaOnlineGridColumnProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'style'> {
  horarios: number
}

export function ConfiguracaoAgendaOnlineGridColumn(props: ConfiguracaoAgendaOnlineGridColumnProps) {
  const { horarios } = props
  const { css, classes } = useStyles(() => ({
    column: {
      display: 'grid',
      gridTemplateRows: `repeat(${horarios + 1}, 48px)`,
    },
  }))

  return <div className={css(classes.column)} {...props} />
}
