import { BoxGroup } from 'components/BoxGroup'
import { DadosServidoresQuery } from 'graphql/types.generated'
import React from 'react'

import { ConfiguracoesServidoresBox } from './ConfiguracoesServidoresBox'

interface ServidoresBoxProps {
  dadosServidor: DadosServidoresQuery['servidores']['dadosServidor']
  internetConnection: boolean
  servidorSMTP: DadosServidoresQuery['servidores']['servidorSMTP']
  load(): void
}

export class ServidoresBox extends React.Component<ServidoresBoxProps> {
  render() {
    return (
      <BoxGroup>
        <ConfiguracoesServidoresBox
          internetConnection={this.props.internetConnection}
          dadosServidor={this.props.dadosServidor}
          servidorSMTP={this.props.servidorSMTP}
          refetch={this.props.load}
        />
      </BoxGroup>
    )
  }
}
