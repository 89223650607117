/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Text, VFlow } from 'bold-ui'
import { useLoadHorarioGeracaoLotesProcessamentoFichasQuery } from 'graphql/hooks.generated'
import { Fragment } from 'react'
import { inconsistenciasRecord, TipoInconsistenciaEnum } from 'view/visualizacaoTerritorial/model'

interface InconsistenciasImovelListProps {
  inconsistencias: Map<TipoInconsistenciaEnum, string[]>
}

export function InconsistenciasImovelList({ inconsistencias }: InconsistenciasImovelListProps) {
  const {
    data: { loadHorarioGeracaoLotesProcessamentoFichas: horario },
  } = useLoadHorarioGeracaoLotesProcessamentoFichasQuery()

  return (
    <Fragment>
      <Alert type='warning'>
        O processamento de fichas ocorre automaticamente às {horario} e pode alterar os dados apresentados ou corrigir
        eventuais inconsistências.
      </Alert>
      <ol>
        {[...inconsistencias.entries()].map(([tipoInconsistencia, referencias]) => {
          const record = inconsistenciasRecord[tipoInconsistencia]

          return (
            <VFlow vSpacing={0} style={styles.container} key={tipoInconsistencia}>
              <Text fontSize={0.875} fontWeight='bold'>
                <li>{record.titulo}</li>
              </Text>
              <Text fontSize={0.813}>{record.sugestao}</Text>
              <ul css={styles.ul}>
                {referencias.map((ref, index) => (
                  <li key={'ref' + index}>
                    <Text> {ref} </Text>
                  </li>
                ))}
              </ul>
            </VFlow>
          )
        })}
      </ol>
    </Fragment>
  )
}

const styles = {
  container: css`
    margin-bottom: 1rem;
    margin-left: -1.5rem;
  `,
  ul: css`
    margin-left: -1.5rem;
    list-style-type: disc;
  `,
}
