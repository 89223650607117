/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import moment from 'moment'
import { conformToMask } from 'react-text-mask'
import { masks } from 'util/mask'

import { CidadaoAtestado } from '../components/model-atestado'
interface LicencaMaternidadeTextBoxProps {
  cidadao: CidadaoAtestado
  cpfCns: string
  naoPossui: boolean
  dataAtendimento: Instant
}

export default function LicencaMaternidadeTextBox(props: LicencaMaternidadeTextBoxProps) {
  const { cidadao, cpfCns, dataAtendimento, naoPossui } = props

  return (
    <Box>
      <VFlow
        vSpacing={0.5}
        style={css`
          justify-content: center;
          align-items: center;
          margin: 1rem 6rem 2rem;
        `}
      >
        <Heading level={3}>LICENÇA MATERNIDADE</Heading>
        <Text>{getLicencaMaternidadeText(cidadao, cpfCns, naoPossui, dataAtendimento)}</Text>
      </VFlow>
    </Box>
  )
}

const renderCpfCnsSubString = (cidadao: CidadaoAtestado, cpfCns: string, naoPossui: boolean) => {
  const getCpfMasked = (cpf: string) => conformToMask(cpf, masks.cpf, { guide: false }).conformedValue

  if (cidadao.cpf) {
    return `CPF ${getCpfMasked(cidadao.cpf)}, `
  }

  if (cidadao.cns) {
    return `CNS ${cidadao.cns}, `
  }

  if ((!cpfCns || (cpfCns.length !== 11 && cpfCns.length !== 15)) && !naoPossui) {
    return '[CNS/CPF], '
  }

  if (cpfCns.length === 11) {
    return `CPF ${getCpfMasked(cpfCns)}, `
  }

  if (cpfCns.length === 15) {
    return `CNS ${cpfCns}, `
  }

  if (naoPossui) {
    return ''
  }
}

export const getLicencaMaternidadeText = (
  cidadao: CidadaoAtestado,
  cpfCns: string,
  naoPossui: boolean,
  dataAtendimento: Instant
) => {
  const cidadaoNome = !cidadao.nomeSocial ? cidadao.nome : `${cidadao.nomeSocial} - (${cidadao.nome})`
  return `Atesto, para os fins de licença maternidade, que ${cidadaoNome.titleCase()}, ${renderCpfCnsSubString(
    cidadao,
    cpfCns,
    naoPossui
  )}necessita de afastamento de suas atividades laborativas a partir do dia ${moment(dataAtendimento).format(
    'DD/MM/YYYY'
  )}.`
}
