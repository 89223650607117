import { StyleSheet } from '@react-pdf/renderer'
import { PrintHeader, PrintHFlow, PrintLabel, PrintText, PrintTitle, PrintVFlow, SpaceLine } from 'components/print'
import { PrintBox } from 'components/print/components/PrintBox'
import { PrintRichText } from 'components/print/components/PrintRichText'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ClassificacaoRisco } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import React from 'react'
import { conformToMask } from 'react-text-mask'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { masks } from 'util/mask'
import { isRichTextValueEmpty } from 'util/validation/Util'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { ProcedimentoSigtapFieldModel } from 'view/atendimentos/detail/soap/finalizacao/components/ProcedimentoSigtapField'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { humanizeAge } from '../../../../util/date/humanize-age'
import { recordEscutaHistoricoTagStyle } from '../../detail/historico/model/model-historico'
import { MotivoConsultaFormModel } from '../motivo-consulta/MotivoConsultaForm'
import { ImpressaoGrupoMedicoes } from './ImpressaoGrupoMedicoes'

interface ImpressaoEscutaInicialProps {
  dataAtendimento: Date
  isPreAtendimento: boolean
  cidadao: CidadaoAtendimento
  medicoes: MedicoesPanelModel
  motivoConsulta: MotivoConsultaFormModel
  classificacaoRisco?: ClassificacaoRisco
  procedimentos?: ProcedimentoSigtapFieldModel[]
  unidadeSaudeProfissional?: string
  unidadeSaudeAtendimento?: string
  cnes?: string
}

export const ImpressaoEscutaInicial = (props: ImpressaoEscutaInicialProps) => {
  const {
    isPreAtendimento,
    cidadao,
    dataAtendimento,
    motivoConsulta,
    classificacaoRisco,
    medicoes,
    procedimentos,
    unidadeSaudeProfissional,
    unidadeSaudeAtendimento,
    cnes,
  } = props
  const nome = cidadao.nomeSocial ? `${cidadao.nomeSocial} (${cidadao.nome})` : cidadao.nome
  const cpfCns = cidadao.cpf
    ? conformToMask(cidadao.cpf, masks.cpf, { guide: false }).conformedValue
    : cidadao.cns || 'Não informado'
  const idade = humanizeAge(cidadao.dataNascimento, dateAsYyyyMmDd(dataAtendimento))

  const showCnes = !(unidadeSaudeProfissional === unidadeSaudeAtendimento)

  const renderClassificacaoRisco = () => {
    const isAgudo = classificacaoRisco !== ClassificacaoRisco.PEC_NAO_AGUDA

    return (
      <>
        <PrintTitle level={4} title='Classificação de risco/Vulnerabilidade' align='left' underline />
        <PrintBox>
          <PrintText>
            {`${isAgudo ? 'Aguda - ' : ''}${recordEscutaHistoricoTagStyle[classificacaoRisco].descricao}`}
          </PrintText>
        </PrintBox>
        <SpaceLine />
      </>
    )
  }

  return (
    <>
      <PrintTitle title={isPreAtendimento ? 'Pré-atendimento' : 'Escuta inicial'} level={3} transform='uppercase' />
      <PrintHeader underline styles={{ justifyContent: 'space-between', paddingBottom: 2 }}>
        <PrintText textStyles={{ fontFamily: 'Lato Bold', fontSize: 11, textTransform: 'uppercase' }}>
          Cidadão
        </PrintText>
        <PrintText
          textStyles={{ fontFamily: 'Lato Bold', fontSize: 11, textTransform: 'uppercase', marginRight: '15%' }}
        >
          Atendimento
        </PrintText>
      </PrintHeader>
      <PrintHFlow>
        <PrintVFlow styles={{ paddingTop: 3 }}>
          <PrintLabel value={nome} bold />
          <PrintLabel title={cidadao.cpf ? 'CPF' : cidadao.cns ? 'CNS' : 'CPF / CNS'} value={cpfCns} />
          <PrintText>{idade} no dia deste atendimento</PrintText>
        </PrintVFlow>
        <PrintVFlow styles={{ paddingTop: 3, marginLeft: '32%' }}>
          <PrintText align='left'>Escuta inicial / orientação </PrintText>
          <PrintText align='left'>
            {format(dataAtendimento, "dd 'de' LLLL 'de' yyyy 'às' HH:mm", { locale: ptBR })}
          </PrintText>
          {showCnes && (
            <>
              <PrintText textStyles={{ fontFamily: 'Lato Bold', fontSize: '10', paddingTop: '10rem' }}>CNES</PrintText>
              <PrintText align='left'>
                {unidadeSaudeAtendimento} - {cnes}
              </PrintText>
            </>
          )}
        </PrintVFlow>
      </PrintHFlow>
      <PrintTitle level={4} title='Motivo da consulta' align='left' underline styles={{ paddingTop: 3 }} />
      <PrintBox title='CIAP 2'>
        <PrintText>
          {motivoConsulta.ciap.codigo} - {motivoConsulta.ciap.descricao}
        </PrintText>
      </PrintBox>
      <SpaceLine />

      {!isRichTextValueEmpty(motivoConsulta.texto) && (
        <>
          <PrintBox>
            <PrintRichText content={motivoConsulta.texto} />
          </PrintBox>
          <SpaceLine />
        </>
      )}

      <ImpressaoGrupoMedicoes medicoes={medicoes} />
      <SpaceLine />

      <PrintTitle level={4} title='Intervenções e/ou procedimentos clínicos realizados' align='left' underline />
      <PrintBox>
        {isEmpty(procedimentos) ? (
          <PrintText textStyles={{ fontFamily: 'Lato Italic' }}>
            Não foram realizados procedimentos neste atendimento
          </PrintText>
        ) : (
          procedimentos.map((value) => (
            <PrintText wrapperStyles={styles.procedimentos}>
              {value.procedimento.codigo} - {value.procedimento.descricao}
            </PrintText>
          ))
        )}
      </PrintBox>
      <SpaceLine />

      {!isPreAtendimento &&
        classificacaoRisco &&
        classificacaoRisco !== ClassificacaoRisco.NAO_CLASSIFICADO &&
        renderClassificacaoRisco()}
    </>
  )
}

const styles = StyleSheet.create({
  procedimentos: {
    borderBottomWidth: 0.5,
    borderStyle: 'dotted',
    paddingVertical: 5,
  },
})
