/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, SelectMenuItem, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { SelectDownshiftMenuProps } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import { SelectField, selectFieldStatus } from 'components/form/final-form'
import { HLabel } from 'components/HLabel'
import theme from 'config/theme'
import { AntecedenteCiapSelectFieldDocument } from 'graphql/hooks.generated'
import {
  AntecedenteCiapSelectFieldQuery,
  AntecedenteCiapSelectFieldQueryVariables,
  CiapCapituloEnum,
} from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'
import { AntecedentesFamiliaresCiapFormModel } from 'view/atendimentos/detail/soap/antecedentes/model'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type AntecedenteCiapSelectFieldModel = AntecedenteCiapSelectFieldQuery['ciaps']['content'][0]

export interface AntecedenteCiapSelectFieldProps extends AsyncSelectFieldProps<AntecedenteCiapSelectFieldModel> {
  capitulosExcluidos?: CiapCapituloEnum[]
  excluirCIAPsDAB?: boolean
  formValues?: Array<AntecedentesFamiliaresCiapFormModel>
  defaultItens: {
    itens: AntecedenteCiapSelectFieldModel[]
    ids: string[]
  }
}

const ID_GRUPO_SUGERIDOS = 'idGrupoSugeridos'
const IG_GRUPO_OUTROS_CIAPS = 'idGrupoOutrosCiaps'

const defaultDisabledMenuItemProps = {
  style: {
    cursor: 'not-allowed',
    '&:hover': {
      background: theme.pallete.primary.c100,
    },
  },
}

export function AntecedenteCiapSelectField(props: AntecedenteCiapSelectFieldProps) {
  const { capitulosExcluidos, excluirCIAPsDAB, formValues, defaultItens, itemIsDisabled, ...rest } = props

  const {
    skipping,
    selectProps: { items, ...asyncProps },
  } = useAsyncQuerySelect<
    AntecedenteCiapSelectFieldModel,
    AntecedenteCiapSelectFieldQuery,
    AntecedenteCiapSelectFieldQueryVariables
  >({
    query: AntecedenteCiapSelectFieldDocument,
    extractItems: (data) => data?.ciaps?.content,
    variables: (inputQuery: string): AntecedenteCiapSelectFieldQueryVariables => ({
      input: {
        query: inputQuery,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
        capitulosExcluidos,
        excluirCIAPsDAB,
      },
    }),
    skip: (inputString) => inputString?.trim()?.length < 2,
    debounceTime: 500,
  })

  const selectItems = useMemo<{
    hasCiapsSugeridos: boolean
    hasOutrosCiaps: boolean
    items: AntecedenteCiapSelectFieldModel[]
  }>(() => {
    items?.sort((a, b) => {
      return a.descricao.localeCompare(b.descricao)
    })

    const ciapsSugeridos: AntecedenteCiapSelectFieldModel[] = [
      { id: ID_GRUPO_SUGERIDOS, codigo: '', descricao: '' },
    ].concat(defaultItens.itens)

    const outrosCiaps: AntecedenteCiapSelectFieldModel[] = skipping
      ? []
      : [{ id: IG_GRUPO_OUTROS_CIAPS, codigo: '', descricao: '' }]

    // Não duplicar itens na combo com os defaultSugeridos
    items?.forEach((item) => {
      if (!defaultItens.ids.includes(item.id)) outrosCiaps.push(item)
    })

    return {
      hasCiapsSugeridos: ciapsSugeridos.length > 1,
      hasOutrosCiaps: outrosCiaps.length > 1,
      items: outrosCiaps.concat(ciapsSugeridos),
    }
  }, [items, defaultItens.itens, defaultItens.ids, skipping])

  const itemToString = (item: AntecedenteCiapSelectFieldModel) => item && `${item.descricao} - ${item.codigo}`

  const renderItem = (item: AntecedenteCiapSelectFieldModel) => {
    return (
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>{item.descricao}</Text>
        <HLabel title='Código '>{item.codigo}</HLabel>
      </VFlow>
    )
  }

  const Item = (
    itemProps: SelectDownshiftMenuProps<AntecedenteCiapSelectFieldModel> & {
      item: AntecedenteCiapSelectFieldModel
      index: number
    }
  ) => {
    const {
      downshift: { getItemProps, highlightedIndex },
      index,
      item,
      renderItem,
    } = itemProps

    const renderGrupoCiapsSugeridos = itemProps.item?.id === ID_GRUPO_SUGERIDOS
    const renderGrupoOutrosCiaps = itemProps.item?.id === IG_GRUPO_OUTROS_CIAPS

    if (renderGrupoOutrosCiaps && skipping) return <Fragment></Fragment>

    const isDisabled = itemIsDisabled(itemProps.item)

    const menuItemProps = isDisabled ? defaultDisabledMenuItemProps : { style: {} }
    const setOpacity = isDisabled ? { style: { opacity: 0.5 } } : { style: {} }

    return (
      <Fragment>
        {renderGrupoCiapsSugeridos || renderGrupoOutrosCiaps ? (
          <RenderGrupoHeader
            renderGrupoOutrosCiaps={renderGrupoOutrosCiaps}
            hasCiapsSugeridos={selectItems.hasCiapsSugeridos}
            hasOutrosCiaps={selectItems.hasOutrosCiaps}
            itemProps={getItemProps({ item, disabled: true })}
          />
        ) : (
          <SelectMenuItem
            selected={highlightedIndex === index}
            {...menuItemProps}
            {...(isDisabled ? getItemProps({ item, disabled: true }) : getItemProps({ item }))}
          >
            <HFlow alignItems='center' justifyContent='space-between'>
              <div {...setOpacity}>{renderItem(item)}</div>

              {isDisabled && (
                <HFlow alignItems='center' hSpacing={0.2} style={{ color: selectFieldStatus.SUCCESS.color }}>
                  <Icon icon={selectFieldStatus.SUCCESS.icon} size={1} style={{ display: 'block' }} />
                  {'Adicionado'}
                </HFlow>
              )}
            </HFlow>
          </SelectMenuItem>
        )}
      </Fragment>
    )
  }

  return (
    <SelectField<AntecedenteCiapSelectFieldModel>
      placeholder='Pesquise ou selecione um CIAP 2'
      itemToString={itemToString}
      items={selectItems.items}
      renderItem={renderItem}
      components={{
        Item,
      }}
      {...asyncProps}
      {...rest}
    />
  )
}

interface GrupoHeaderProps {
  hasCiapsSugeridos: boolean
  hasOutrosCiaps: boolean
  renderGrupoOutrosCiaps: boolean
  itemProps: any
}

function RenderGrupoHeader(props: GrupoHeaderProps) {
  const { hasCiapsSugeridos, hasOutrosCiaps, renderGrupoOutrosCiaps, itemProps } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const emptyGroupMessage = renderGrupoOutrosCiaps
    ? !hasOutrosCiaps && 'Nenhuma outra CIAP2.'
    : !hasCiapsSugeridos && 'Nenhuma CID10 relacionada.'

  return (
    <Fragment>
      <SelectMenuItem style={styles.headerItem} {...itemProps}>
        <Text fontWeight='bold' style={styles.headerItemText}>
          {!renderGrupoOutrosCiaps ? 'Opções rápidas para CIAP 2' : 'Outras CIAP 2'}
        </Text>
      </SelectMenuItem>
      {emptyGroupMessage && (
        <SelectMenuItem style={styles.disabledItem} {...itemProps}>
          <Text>{emptyGroupMessage}</Text>
        </SelectMenuItem>
      )}
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  headerItem: css`
    pointer-events: none;
    cursor: not-allowed;
    background-color: ${theme.pallete.primary.c90};
  `,
  headerItemText: css`
    color: ${theme.pallete.primary.c40};
  `,
  disabledItem: css`
    pointer-events: none;
    cursor: not-allowed;
  `,
})
