import { Button, Cell, Grid, HFlow, Omit } from 'bold-ui'
import { alert } from 'components/alert'
import { Box } from 'components/Box'
import { Form, SelectField, SelectFieldProps } from 'components/form'
import { FormPrompt } from 'components/form/FormPrompt'
import { useAlterarPeriodoInatividadeMutation } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, required } from 'util/validation'

export interface ConfiguracoesInatividadeBoxProps {
  periodoEmMinutosInatividade: number
  refetch(): any
}

const validator = createValidator<FormModel>({
  periodo: [required],
})

export function ConfiguracoesInatividadeBox(props: ConfiguracoesInatividadeBoxProps) {
  const { periodoEmMinutosInatividade, refetch } = props

  const [alterarPeriodoInatividade] = useAlterarPeriodoInatividadeMutation()

  const initialValues = useMemo(
    () =>
      periodoEmMinutosInatividade && {
        periodo: {
          id: periodoEmMinutosInatividade,
          label: periodoMaximoInatividadeItems.find((item) => item.id === periodoEmMinutosInatividade).label,
        },
      },
    [periodoEmMinutosInatividade]
  )

  const handleSubmit = (values: FormModel) => {
    return alterarPeriodoInatividade({ variables: { periodo: values.periodo.id } }).then(() => {
      alert('success', 'Período de inatividade alterado com sucesso.')
      refetch()
    })
  }

  const renderForm = (formProps: FormRenderProps<FormModel>) => (
    <>
      <FormPrompt />
      <HFlow hSpacing={1}>
        <Grid>
          <Cell>
            <PeriodoInatividadeSelectField
              label='Período máximo de inatividade'
              name='periodo'
              style={{ width: 287 }}
              clearable={false}
            />
          </Cell>
        </Grid>
        <Grid>
          <Cell>
            <Button
              size='small'
              onClick={formProps.handleSubmit}
              style={{ marginTop: '1.5rem' }}
              kind='primary'
              disabled={!formProps.values.periodo}
            >
              Salvar
            </Button>
          </Cell>
        </Grid>
      </HFlow>
    </>
  )

  return (
    <Box>
      <Form<FormModel> onSubmit={handleSubmit} render={renderForm} validate={validator} initialValues={initialValues} />
    </Box>
  )
}

function PeriodoInatividadeSelectField(props: Omit<SelectFieldProps<ItemType>, 'items' | 'itemToString'>) {
  const itemToString = (item: ItemType) => item && item.label

  return <SelectField<ItemType> items={periodoMaximoInatividadeItems} itemToString={itemToString} {...props} />
}

interface FormModel {
  periodo: ItemType
}

interface ItemType {
  id: number
  label: string
}

export const periodoMaximoInatividadeItems = [
  { id: 30, label: '30 minutos' },
  { id: 60, label: '1 hora (opção padrão)' },
  { id: 120, label: '2 horas' },
  { id: 180, label: '3 horas' },
  { id: 240, label: '4 horas' },
]
