import React from 'react'

import { ErrorGroup, ErrorItem, ErrorsBox, ValidationError, ValidationErrorsMessage } from './ErrorsBox'
import { EquipesVinculadasCnesModel, UnidadeSaudeDetalhesImportacaoModel, ValidacaoModel } from './model'

interface EquipesVinculadasErrorsBoxProps {
  unidadesSaude: UnidadeSaudeDetalhesImportacaoModel[]
}

export const EquipesVinculadasErrorsBox = (props: EquipesVinculadasErrorsBoxProps) => {
  const { unidadesSaude } = props
  if (unidadesSaude.isEmpty()) {
    return null
  }

  const unidadesSaudeComErroEmEquipeVinculada = unidadesSaude.filter(
    (unidadeSaude) =>
      unidadeSaude.vinculacaoEquipes.filter((equipeVinculada) => equipeVinculada.validacoes).length !== 0
  )

  if (unidadesSaudeComErroEmEquipeVinculada.isEmpty()) {
    return null
  }

  return (
    <ErrorsBox title='Equipes vinculadas'>
      <ErrorGroup title='Campos preenchidos de forma incorreta'>
        {unidadesSaudeComErroEmEquipeVinculada.map((unidadeSaude) =>
          unidadeSaude.vinculacaoEquipes
            .filter((equipesVinculadas) => equipesVinculadas.validacoes.erro)
            .map((equipesVinculadas, index) => (
              <EquipesVinculadasErrorItem
                key={index}
                equipesVinculadas={equipesVinculadas.dados}
                errorMessage={equipesVinculadas.validacoes.erro}
              />
            ))
        )}
      </ErrorGroup>
      <ErrorGroup title='Outras inconsistências'>
        {unidadesSaudeComErroEmEquipeVinculada.map((unidadeSaude) =>
          unidadeSaude.vinculacaoEquipes
            .filter((equipesVinculadas) => equipesVinculadas.validacoes.camposPreenchidosIncorretos.length > 0)
            .map((equipesVinculadas, index) => (
              <EquipesVinculadasErrorItem
                key={index}
                equipesVinculadas={equipesVinculadas.dados}
                fieldErrors={equipesVinculadas.validacoes.camposPreenchidosIncorretos}
              />
            ))
        )}
      </ErrorGroup>
    </ErrorsBox>
  )
}

interface EquipesVinculadasErrorItemProps {
  equipesVinculadas: EquipesVinculadasCnesModel
  errorMessage?: string
  fieldErrors?: ValidacaoModel[]
}

const EquipesVinculadasErrorItem = (props: EquipesVinculadasErrorItemProps) => {
  const { equipesVinculadas, errorMessage, fieldErrors } = props
  return (
    <ErrorItem>
      INE equipe principal: {equipesVinculadas.ineEquipePrincipal ?? '-'}, INE equipe vinculada:{' '}
      {equipesVinculadas.ineEquipeVinculada ?? '-'}
      {errorMessage && <ValidationError error={errorMessage} />}
      {fieldErrors && <ValidationErrorsMessage errors={fieldErrors} />}
    </ErrorItem>
  )
}
