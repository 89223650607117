import { handleError } from 'components/error'

export const buildAgendamentosConflitantesErrorMessage = (apolloError) => {
  const errors = handleError({ error: apolloError, suppressNotificationError: true })

  const errorsSet = new Set(Object.keys(errors).map((key) => errors[key]))

  return errorsSet.join('. ')
}

export function lotacaoAgendamentoToString({
  nomeEquipe,
  ineEquipe,
  nomeProfissional,
  nomeCbo,
}: {
  nomeEquipe?: string
  ineEquipe?: string
  nomeProfissional: string
  nomeCbo?: string
}): string {
  if (!!nomeProfissional) {
    const textoEquipe = !!nomeEquipe ? ` | ${nomeEquipe} - ${ineEquipe}` : ''
    const textoCbo = !!nomeCbo ? ` | ${nomeCbo.capitalize()}` : ''

    return `${nomeProfissional}${textoCbo}${textoEquipe}`
  } else {
    return ''
  }
}
