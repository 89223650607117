import { CidadaosComboQueryInput } from 'graphql/types.generated'

import { DEFAULT_SELECT_SIZE } from '../useAsyncQuerySelect'

export const commonCidadaoAtendimentoPorPropriedadeSelectFieldInput: CidadaosComboQueryInput = {
  ativo: true,
  obito: false,
  limit: DEFAULT_SELECT_SIZE,
}

export function skipNomeCidadaoAtendimentoSelectField(input: string) {
  return input?.trim()?.length < 3
}
