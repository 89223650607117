/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormProps, FormRenderProps, RichTextField, SubmitButton } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, requiredRichText, richTextMaxLength } from 'util/validation'

import { OrientacaoFormModel } from '../types/OrientacoesModel'

const path = metaPath<OrientacaoFormModel>()

interface OrientacoesFormProps extends Pick<FormProps<OrientacaoFormModel>, 'initialValues' | 'initialValuesEqual'> {
  showAlerts?: boolean
  onSubmit(formValues: OrientacaoFormModel): void
  onCancel?(): void
  onFocus?(): void
}

export function OrientacoesForm(props: OrientacoesFormProps) {
  const { onSubmit, onCancel, onFocus, initialValues, showAlerts, initialValuesEqual } = props

  const alert = useAlert()

  const handleSubmit = (values: OrientacaoFormModel, formApi: FormApi) => {
    onSubmit(values)
    setTimeout(formApi.reset)
    formApi.getRegisteredFields().forEach((item) => formApi.resetFieldState(item))
    showAlerts && alert('success', 'Orientação salva com sucesso.')
  }

  const handleCancel = (formProps: FormRenderProps<OrientacaoFormModel>) => {
    if (showAlerts && formProps.dirty) {
      confirm({
        title: 'Deseja cancelar a inclusão?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar inclusão',
        confirmLabel: 'Sim, cancelar inclusão',
        onConfirm: () => {
          alert('success', 'Cadastro da orientação cancelado com sucesso.')
          setTimeout(() => formProps.form.reset())
          formProps.form.getRegisteredFields().forEach((item) => formProps.form.resetFieldState(item))
        },
      })()
    }
    onCancel?.()
  }

  const renderForm = (formProps: FormRenderProps<OrientacaoFormModel>) => {
    return (
      <Grid
        style={css`
          padding: 0 0 1rem 0;
        `}
      >
        <Cell size={12}>
          <RichTextField
            name={path.descricao}
            maxLength={4000}
            height={8}
            placeholder='Insira as informações de orientações para o cidadão.'
            removeNonBreakingSpaces
            required
            onFocus={onFocus}
          />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button kind='normal' size='small' onClick={() => handleCancel(formProps)}>
              Cancelar
            </Button>
            <SubmitButton
              throttleSubmit
              type='button'
              kind='primary'
              size='small'
              handleSubmit={formProps.handleSubmit}
            >
              Salvar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return (
    <Form<OrientacaoFormModel>
      validate={validate}
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      initialValuesEqual={initialValuesEqual}
    />
  )
}

const validate = createValidator<OrientacaoFormModel>({
  descricao: [requiredRichText, richTextMaxLength(4000)],
})
