import { Text } from 'bold-ui'
import React from 'react'
export default LabelItemInativo

export interface LabelItemInativoProps {
  ativo: boolean
}

export function LabelItemInativo(props: LabelItemInativoProps) {
  const { ativo } = props

  return ativo ? null : <Text fontWeight='bold'> [Inativo]</Text>
}
