import { Calculation } from 'final-form-calculate'
import { OpcaoTipoPerguntaEnum } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { MCASeisAVinteETresMesesPanelModel } from './model'

export const createMarcadoresConsumoAlimentarCalculations = (
  meta: MetaPath<MCASeisAVinteETresMesesPanelModel>
): Calculation[] => {
  return [
    {
      field: meta.fruta.absolutePath(),
      updates: {
        [meta.quantidadeRefeicoesFruta?.absolutePath()]: (value: OpcaoTipoPerguntaEnum, allValues: SoapState) => {
          const mcaSeisAVinteETresMeses = (allValues?.objetivo
            ?.marcadoresConsumoAlimentar as unknown) as MCASeisAVinteETresMesesPanelModel
          return ifSimElseNull(value, mcaSeisAVinteETresMeses?.quantidadeRefeicoesFruta)
        },
      },
    },
    {
      field: meta.comidaDeSal?.absolutePath(),
      updates: {
        [meta.quantidadeRefeicoesComidaDeSal?.absolutePath()]: (value: OpcaoTipoPerguntaEnum, allValues: SoapState) => {
          const mcaSeisAVinteETresMeses = (allValues?.objetivo
            ?.marcadoresConsumoAlimentar as unknown) as MCASeisAVinteETresMesesPanelModel
          return ifSimElseNull(value, mcaSeisAVinteETresMeses?.quantidadeRefeicoesComidaDeSal)
        },
        [meta.consistenciaAlimentoComidaDeSal?.absolutePath()]: (
          value: OpcaoTipoPerguntaEnum,
          allValues: SoapState
        ) => {
          const mcaSeisAVinteETresMeses = (allValues?.objetivo
            ?.marcadoresConsumoAlimentar as unknown) as MCASeisAVinteETresMesesPanelModel
          return ifSimElseNull(value, mcaSeisAVinteETresMeses?.consistenciaAlimentoComidaDeSal)
        },
      },
    },
  ]
}

const ifSimElseNull = <T>(reference: OpcaoTipoPerguntaEnum, dependent: T): T => {
  return reference !== OpcaoTipoPerguntaEnum.SIM ? null : dependent
}
