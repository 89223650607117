import { EncaminhamentoExternoEspecializadoModel } from '../model'
import { EncaminhamentoExternoEspecializadoImpressaoModel } from './ImpressaoEncaminhamentoExternoInput'

export const convertEncaminhamentoExternoEspecializadoToImpressao = (
  value: EncaminhamentoExternoEspecializadoModel
): EncaminhamentoExternoEspecializadoImpressaoModel => ({
  especialidadeSisreg: value.especialidadeSisreg,
  classificacaoRiscoEncaminhamento: value.classificacaoRiscoEncaminhamento,
  cidEncaminhamento: !!value.cidEncaminhamento
    ? {
        codigo: value.cidEncaminhamento.codigo,
        nome: value.cidEncaminhamento.nome,
      }
    : undefined,
  ciapId: value.ciapEncaminhamento?.id,
  observacoes: value.observacoes,
  motivoEncaminhamento: value.motivoEncaminhamento,
  complemento: value.complemento,
})
