/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, isEmpty, Spinner, Text, Tooltip, VFlow } from 'bold-ui'
import { AccordionCompositeItem } from 'components/accordion/AccordionCompositeItem'
import useSession from 'components/auth/useSession'
import { resolveName } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'
import { ButtonLink, PrivateRoute } from 'components/route'
import theme from 'config/theme'
import { useMedicamentoUsoModalQuery } from 'graphql/hooks.generated'
import { AcessoCbo, ReceitaMedicamento } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback, useState } from 'react'
import { Accordion } from 'react-accessible-accordion'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { Switch, useRouteMatch } from 'react-router'
import { metaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { getEditAtendProfId } from 'view/atendimentos/atendimento-individual/util'
import { grupoCboPrescricao } from 'view/atendimentos/detail/soap/plano/acessos'

import InterromperTratamentoModal, { MedicamentoInterromperFormModel } from './InterromperTratamentoModal'

interface MedicamentosAccordionProps {
  podeInterromperMedicamento: boolean
  setAlertState: React.Dispatch<React.SetStateAction<AlertInlineProps>>
  renderItemBody: (receita: ReceitaMedicamento) => JSX.Element
  renderItemHeader: (
    interrompidosAgora?: MedicamentoInterromperFormModel[]
  ) => (receita: ReceitaMedicamento) => JSX.Element

  prontuarioId: ID
  dataAtendimento: Instant
  atendimentoProfissionalId: ID
  isAtendimentoObservacao: boolean
}

interface AlertInlineProps {
  visible: boolean
  message: string
}

const path = metaPath<SoapState>()

function MedicamentosAccordion(props: MedicamentosAccordionProps) {
  const {
    podeInterromperMedicamento,
    setAlertState,
    renderItemBody,
    renderItemHeader,
    prontuarioId,
    dataAtendimento,
    atendimentoProfissionalId,
    isAtendimentoObservacao = false,
  } = props
  const { analytics } = useFirebase()
  const match = useRouteMatch()
  const [medicamentoToInterrupt, setMedicamentoToInterrupt] = useState<ReceitaMedicamento>()
  const {
    data: { acesso },
    hasCboAuth,
  } = useSession({ fetchPolicy: 'cache-only' })

  const isNutricionistaOuFarmaceutico = hasCboAuth([AcessoCbo.NUTRICIONISTA, AcessoCbo.FARMACEUTICO])

  const editAtendProfId = getEditAtendProfId(isAtendimentoObservacao, atendimentoProfissionalId)

  const {
    data: { listaMedicamento: listaMedicamentosAtivos },
    loading: loadingAtivos,
  } = useMedicamentoUsoModalQuery({
    variables: {
      input: {
        prontuarioId,
        editAtendProfId,
        dataAtendimento,
        usoContinuo: false,
        ativo: true,
        pageParams: { unpaged: true },
      },
    },
  })

  const renderFieldArray = useCallback(
    (fieldArrayRenderProps: FieldArrayRenderProps<MedicamentoInterromperFormModel, any>) => {
      const {
        fields: { push, remove, value },
      } = fieldArrayRenderProps

      const onClearItem = () => {
        setMedicamentoToInterrupt(undefined)
      }

      const handleOnSubmit = (interrupcao: MedicamentoInterromperFormModel) => {
        push(interrupcao)
        setAlertState({ visible: true, message: 'Tratamento interrompido com sucesso.' })
      }

      const handleOnCancel = (interrupcao: MedicamentoInterromperFormModel) => {
        const item = value?.find((item) => item.id === interrupcao.id)
        if (item) {
          const index = value.indexOf(item)
          confirm({
            title: 'Deseja cancelar a interrupção do tratamento?',
            confirmLabel: 'Confirmar',
            cancelLabel: 'Cancelar',
            depthLevel: 5,
            manageOverflow: false,
            onConfirm: () => {
              remove(index)
              onClearItem()
              setAlertState({ visible: true, message: 'Interrupção cancelada com sucesso.' })
            },
          })()
        }
        analytics.logEvent('click_button_cancelar_interrupcao')
      }

      const handleInterromperTratamento = (receita: ReceitaMedicamento, podeInterromperTratamento: boolean) => {
        if (!isNutricionistaOuFarmaceutico || podeInterromperTratamento) {
          setMedicamentoToInterrupt(receita)
        }

        analytics.logEvent('click_button_interromper_tratamento')
      }

      const renderItemButton = (receita: ReceitaMedicamento) => {
        if (!podeInterromperMedicamento) return null
        const item = value?.find((item) => item.id === receita.id)
        const lotacaoAtualQuePrescreveu = receita.atendProf.lotacao.id === acesso.id
        const podeInterromperTratamento = isNutricionistaOuFarmaceutico && lotacaoAtualQuePrescreveu
        const tooltipText =
          isNutricionistaOuFarmaceutico &&
          !lotacaoAtualQuePrescreveu &&
          'O tratamento só pode ser interrompido pelo profissional que o iniciou'

        return (
          <div
            css={css`
              width: 12rem;
            `}
          >
            {item ? (
              <Button
                size='small'
                onClick={() => handleOnCancel(item)}
                data-cy='MedicamentoAtivosConcluidos.BotaoCancelarInterrupcao'
              >
                Cancelar interrupção
              </Button>
            ) : (
              <Tooltip text={tooltipText}>
                <ButtonLink
                  size='small'
                  to={`${match.url}/interromper-tratamento`}
                  onClick={() => handleInterromperTratamento(receita, podeInterromperTratamento)}
                  disabled={isNutricionistaOuFarmaceutico && !lotacaoAtualQuePrescreveu}
                  data-cy='MedicamentoAtivosConcluidos.InterromperButton'
                >
                  Interromper tratamento
                </ButtonLink>
              </Tooltip>
            )}
          </div>
        )
      }

      return (
        <div>
          <Accordion allowZeroExpanded allowMultipleExpanded>
            <VFlow vSpacing={0.5}>
              {loadingAtivos && (
                <div css={styles.loadingContainer}>
                  <Spinner />
                  {'Carregando resultados'}
                </div>
              )}
              {!loadingAtivos && isEmpty(listaMedicamentosAtivos?.content) && (
                <Text
                  style={css`
                    color: ${theme.pallete.text.secondary};
                    font-style: italic;
                    text-align: center;
                  `}
                >
                  {isEmpty(listaMedicamentosAtivos?.content) && 'Nenhum medicamento em uso'}
                </Text>
              )}
              {listaMedicamentosAtivos?.content?.map((item: ReceitaMedicamento) => (
                <AccordionCompositeItem
                  key={item.id}
                  header={renderItemHeader(value)(item)}
                  headerButtons={renderItemButton(item)}
                  body={renderItemBody(item)}
                />
              ))}
            </VFlow>
          </Accordion>
          <Switch>
            <PrivateRoute
              path={`${match.url}/interromper-tratamento`}
              permission={grupoCboPrescricao.interromperTratamento}
            >
              <InterromperTratamentoModal
                receita={medicamentoToInterrupt}
                onSubmit={handleOnSubmit}
                onClear={onClearItem}
              />
            </PrivateRoute>
          </Switch>
        </div>
      )
    },
    [
      loadingAtivos,
      listaMedicamentosAtivos,
      match.url,
      medicamentoToInterrupt,
      setAlertState,
      analytics,
      podeInterromperMedicamento,
      isNutricionistaOuFarmaceutico,
      acesso.id,
      renderItemHeader,
      renderItemBody,
    ]
  )

  return (
    <FieldArray<MedicamentoInterromperFormModel>
      name={resolveName(path.medicamentosInterromper)}
      render={renderFieldArray}
    />
  )
}

export default React.memo(MedicamentosAccordion)

const styles = {
  loadingContainer: css`
    color: ${theme.pallete.primary.main};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    & > *:first-of-type {
      margin-right: 0.5rem;
    }
  `,
}
