import { ProximaConsultaTypeEnum, SugestaoProximaConsultaModel } from './model'

export const convertSugestaoConsultaPreNatalToSugestaoProximaConsultaModel = (
  sugestao: LocalDate
): SugestaoProximaConsultaModel => ({
  date: sugestao,
  type: ProximaConsultaTypeEnum.PRE_NATAL,
})

export const convertSugestaoConsultaOdontoToSugestaoProximaConsultaModel = (
  sugestao: LocalDate
): SugestaoProximaConsultaModel => ({
  date: sugestao,
  type: ProximaConsultaTypeEnum.ODONTOLOGICO,
})
