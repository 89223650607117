import { Modal, ModalBody, VFlow } from 'bold-ui'
import { TitleGroup } from 'components/TitleGroup'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { EquipeModel } from 'view/profissional/detail/model'

import { EquipeLabel } from './EquipeLabel'
import { EquipesVinculadasTable } from './EquipesVinculadasTable'

export const EQUIPES_VINCULADAS_MODAL_URL = '/equipes-vinculadas'

interface EquipesVinculadasModalProps {
  equipePrincipal: EquipeModel
}

export function EquipesVinculadasModal(props: EquipesVinculadasModalProps) {
  const { equipePrincipal } = props
  const { url } = useRouteMatch()
  const history = useHistory()

  const handleClose = () => {
    history.push(url.replace(EQUIPES_VINCULADAS_MODAL_URL, ''))
  }

  return (
    <Modal open onClose={handleClose}>
      <ModalBody>
        <VFlow>
          <TitleGroup title='Equipes vinculadas' />
          <VFlow>
            <EquipeLabel nome={equipePrincipal.nome} ine={equipePrincipal.ine} />
            <EquipesVinculadasTable equipePrincipalId={equipePrincipal.id} />
          </VFlow>
        </VFlow>
      </ModalBody>
    </Modal>
  )
}
