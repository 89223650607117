import { AuditoriaInput } from 'graphql/types.generated'

import AuditoriaFormModel from './AuditoriaFormModel'

export default (model: AuditoriaFormModel): AuditoriaInput => {
  const { periodo, ...rest } = model

  return {
    ...rest,
    dataInicio: model.periodo?.startDate,
    dataFim: model.periodo?.endDate,
    tiposEvento: model.tiposEvento && model.tiposEvento.map((value) => value.id),
    usuariosGerador: model.usuariosGerador && model.usuariosGerador.map((value) => value.id),
    tiposRegistroAfetado: model.tiposRegistroAfetado && model.tiposRegistroAfetado.map((value) => value.id),
  }
}
