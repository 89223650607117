import { css } from '@emotion/core'
import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { SubmitButton } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { PopperControls } from 'components/popper'
import React from 'react'

import { AgendamentosCidadaoFilterModel, metaAgendamentoCidadaoFilter } from '../model'

interface AgendamentosCidadaoFilterPopperProps extends PopperControls {
  onSubmit(e: React.SyntheticEvent<HTMLFormElement, Event>): void
  filter: AgendamentosCidadaoFilterModel
}

export function AgendamentosCidadaoFilterPopper(props: AgendamentosCidadaoFilterPopperProps) {
  const { close, onSubmit: handleSubmit, filter } = props
  return (
    <Box
      style={css`
        width: 25rem;
      `}
    >
      <Grid>
        <Cell size={12}>
          <DateRangeField
            name={metaAgendamentoCidadaoFilter.periodo}
            label='Período'
            initialValue={filter?.periodo}
            required
          />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='small' onClick={close}>
              Fechar
            </Button>
            <SubmitButton size='small' handleSubmit={handleSubmit}>
              Filtrar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    </Box>
  )
}
