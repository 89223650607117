export function isCboMedico(codigoCbo: string): boolean {
  return CBO_MEDICO.some((cbo) => codigoCbo.startsWith(cbo))
}

export function isCboEnfermeiro(codigoCbo: string): boolean {
  return CBO_ENFERMEIRO.some((cbo) => codigoCbo.startsWith(cbo))
}

export function isCboCirurgiaoDentista(codigoCbo: string): boolean {
  return CBO_CIRURGIAO_DENTISTA.some((cbo) => codigoCbo.startsWith(cbo))
}

export function isCboPsicologo(codigoCbo: string): boolean {
  return CBO_PSICOLOGO.some((cbo) => codigoCbo.startsWith(cbo))
}

export function isCboPodeRegistrarCid(codigoCbo: string): boolean {
  return codigoCbo && (isCboMedico(codigoCbo) || isCboCirurgiaoDentista(codigoCbo) || isCboPsicologo(codigoCbo))
}

const CBO_MEDICO = ['2251', '2252', '2253', '2231F9']
const CBO_CIRURGIAO_DENTISTA = ['2232']
const CBO_PSICOLOGO = ['2515']
const CBO_ENFERMEIRO = ['2235']
