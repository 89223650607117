import { SelectField } from 'components/form'
import { PaisSelectFieldDocument } from 'graphql/hooks.generated'
import { PaisSelectFieldQuery, PaisSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type PaisSelectFieldModel = Partial<PaisSelectFieldQuery['paises']['content'][0]>

interface PaisSelectFieldProps extends AsyncSelectFieldProps<PaisSelectFieldModel> {
  omiteBrasil?: boolean
}

export function PaisSelectField(props: PaisSelectFieldProps) {
  const { selectProps } = useAsyncQuerySelect<
    PaisSelectFieldModel,
    PaisSelectFieldQuery,
    PaisSelectFieldQueryVariables
  >({
    query: PaisSelectFieldDocument,
    extractItems: (data) => data && data.paises && data.paises.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        omiteBrasil: props.omiteBrasil,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: PaisSelectFieldModel) => item && item.nome

  return <SelectField<PaisSelectFieldModel> itemToString={itemToString} {...selectProps} {...props} />
}
