import { Alert, AlertType } from 'bold-ui'
import React from 'react'

interface MCAFormAlertContent {
  type: AlertType
  text: string
}

interface MCAFormAlertProps {
  hasError: boolean
}

function MCAFormAlert(props: MCAFormAlertProps) {
  const { hasError } = props

  const { type, text }: MCAFormAlertContent = hasError
    ? {
        type: 'danger',
        text: 'É necessário responder todas as perguntas do formulário para registrar o consumo alimentar.',
      }
    : {
        type: 'info',
        text: 'Para registrar os marcadores de consumo alimentar todas as perguntas devem ser respondidas.',
      }

  return (
    <Alert inline type={type}>
      {text}
    </Alert>
  )
}

export default React.memo(MCAFormAlert)
