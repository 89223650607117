import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { gray } from 'bold-ui/lib/styles/colors'
import logo from 'images/esus-logo.png'
import React from 'react'

export interface PrintHeaderProps {
  ufNome: string
  municipio: string
  unidadeSaude?: string
}

export const PrintHeader = (props: PrintHeaderProps) => {
  const { ufNome, municipio, unidadeSaude } = props
  return (
    <View style={classes.main} fixed>
      <View style={classes.leftColumn}>
        <Image style={classes.image} src={logo} />
      </View>
      <View style={classes.rightColumn}>
        <Text style={classes.text}>MINISTÉRIO DA SAÚDE</Text>
        <Text style={classes.text}>ESTADO DE {ufNome}</Text>
        <Text style={classes.text}>MUNICÍPIO DE {municipio}</Text>
        {unidadeSaude && <Text style={classes.text}>{unidadeSaude}</Text>}
      </View>
    </View>
  )
}

const classes = StyleSheet.create({
  main: {
    flexDirection: 'row',
    position: 'absolute',
    top: 20,
    left: 30,
    right: 30,
    borderBottomWidth: 2,
    borderBottomColor: gray.c10,
  },
  leftColumn: {
    width: '50%',
    marginTop: 2,
    marginBottom: 4,
    flexDirection: 'column',
    borderRightWidth: 2,
    borderRightColor: gray.c10,
    justifyContent: 'center',
  },
  rightColumn: {
    marginTop: 2,
    marginBottom: 2,
    marginLeft: 4,
    flexDirection: 'column',
    alignContent: 'flex-start',
    maxWidth: 270,
  },
  text: {
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    fontFamily: 'Lato',
    fontSize: 8.5,
  },
  image: {
    width: 115,
  },
})
