/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, FormControl, HFlow, Icon, Theme, Tooltip, useTheme } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps, SubmitButton, TextField } from 'components/form'
import { HLabel } from 'components/HLabel'
import { useCidadaoEmailUpdateMutation } from 'graphql/hooks.generated'
import React, { Fragment, useEffect } from 'react'
import { FormProps, FormSpy } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, email, required } from 'util/validation'

interface EmailUpdateFormModel {
  email: string
}

const validate = createValidator<EmailUpdateFormModel>({
  email: [required, email],
})

const path = metaPath<EmailUpdateFormModel>()
const DISABLED_TOOLTIP_TEXT = 'Não é possível editar o e-mail sem gerar um atestado digital.'

interface EmailUpdateFormProps extends Omit<FormProps<EmailUpdateFormModel>, 'onSubmit' | 'validate' | 'render'> {
  cidadaoId: ID
  cidadaoEmail: string
  onSubmitSuccess: (novoEmail: string) => void
  editing: boolean
  onEditingChange: (editing: boolean) => void
  onEditarClick?: () => void
  disabled?: boolean
  onDirtyChange?: (dirty: boolean) => void
  canceled?: boolean
  onCancel?: (canceled: boolean) => void
}

export const EmailUpdateForm = React.memo((props: EmailUpdateFormProps) => {
  const {
    cidadaoId,
    cidadaoEmail,
    onSubmitSuccess,
    editing,
    onEditingChange,
    onEditarClick,
    canceled = false,
    onCancel,
    disabled = false,
    onDirtyChange,
    ...rest
  } = props

  const [updateEmailMutation] = useCidadaoEmailUpdateMutation()
  const handleRejection = useErrorHandler()
  const theme = useTheme()
  const { disabledLabel } = createStyles(theme)

  useEffect(() => {
    if (canceled) {
      onEditingChange(false)
      onCancel?.(false)
    }
  }, [canceled, onCancel, onEditingChange])

  const handleSubmitSuccess = (email: string) => {
    onSubmitSuccess(email)
    onEditingChange(false)
  }

  const handleEditarClick = () => {
    onEditarClick?.()
    onEditingChange(true)
  }

  const handleSubmitForm = ({ email }: EmailUpdateFormModel, form) => {
    updateEmailMutation({
      variables: {
        input: {
          cidadaoId,
          email,
        },
      },
    })
      .then(() => {
        handleSubmitSuccess(email)
        setTimeout(() => form.reset())
      })
      .catch(handleRejection)
  }

  const renderForm = ({ handleSubmit }: FormRenderProps<EmailUpdateFormModel>) => {
    return editing ? (
      <Fragment>
        <HFlow hSpacing={0.5}>
          <Tooltip text={disabled ? DISABLED_TOOLTIP_TEXT : null}>
            <HFlow>
              <TextField lowercase name={path.email} label='E-mail' maxLength={50} disabled={disabled} />
              <FormControl label={<span>&nbsp;</span>}>
                <SubmitButton kind='primary' size='small' handleSubmit={handleSubmit} disabled={disabled}>
                  Atualizar
                </SubmitButton>
              </FormControl>
            </HFlow>
          </Tooltip>
        </HFlow>
        {onDirtyChange && (
          <FormSpy
            subscription={{ dirty: true }}
            onChange={(props) => {
              onDirtyChange?.(props.dirty)
            }}
          />
        )}
      </Fragment>
    ) : (
      <HFlow hSpacing={0.25} alignItems='center'>
        <HLabel title='E-mail:' style={disabled ? disabledLabel : null}>
          {cidadaoEmail}
        </HLabel>
        <Tooltip text={disabled ? DISABLED_TOOLTIP_TEXT : 'Editar'}>
          <Button size='small' skin='ghost' onClick={handleEditarClick} disabled={disabled}>
            <Icon icon='penOutline' />
          </Button>
        </Tooltip>
      </HFlow>
    )
  }

  return (
    <Form<EmailUpdateFormModel>
      onSubmit={handleSubmitForm}
      validate={validate}
      render={renderForm}
      suppressNotificationError
      resetFormToInitialValues={true}
      {...rest}
    />
  )
})

const createStyles = (theme: Theme) => ({
  disabledLabel: css`
    color: ${theme.pallete.text.disabled};
    pointer-events: none;
    cursor: not-allowed;
  `,
})
