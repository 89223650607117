/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ApolloError } from 'apollo-client'
import { Button, DropdownDivider, DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { isBefore } from 'date-fns'
import {
  useAtenderMutation,
  useCancelarAgendamentoMutation,
  useCancelarAtendimentoMutation,
  useExcluirAtendimentoMutation,
} from 'graphql/hooks.generated'
import { JustificativaCancelarAgendamentoEnum, StatusAtendimento } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment } from 'react'
import { useHistory } from 'react-router'
import { ListaAtendimentoItemActionsProps } from 'view/atendimentos/list/ListaAtendimentoItemActions'

import { REGISTRO_TARDIO_PATH } from './RegistroTardioRootView'

interface ListaRegistroTardioItemActionsProps
  extends Omit<ListaAtendimentoItemActionsProps, 'responsavelId' | 'isAtendimentoObservacao'> {
  dataInicioAtendimento: Instant
  agendadoId: string
  onAtenderClick(dataAtendimento: number, cidadaoId: ID)
}

export default function ListaRegistroTardioItemActions(props: ListaRegistroTardioItemActionsProps) {
  const {
    actions,
    atendimentoId,
    cidadaoId,
    dataInicioAtendimento,
    statusAtendimento,
    agendadoId,
    onAtenderClick: onClick,
  } = props
  const pathCidadao = `/cidadao/${cidadaoId}`
  const { analytics } = useFirebase()
  const { getServerTimeNow } = useServerTime()
  const history = useHistory()
  const alert = useAlert()
  const [atender] = useAtenderMutation()
  const handleRejection = useErrorHandler()

  const [excluir] = useExcluirAtendimentoMutation()
  const [cancelar] = useCancelarAtendimentoMutation()
  const [cancelarAgendamento] = useCancelarAgendamentoMutation()

  const handleOnClickAtender = async () => {
    const sucesso = await onClick(dataInicioAtendimento, cidadaoId)

    if (sucesso) {
      try {
        const res = await atender({ variables: { atendimento: atendimentoId } })
        history.push(`${REGISTRO_TARDIO_PATH}/${atendimentoId}`)
        return res
      } catch (err) {
        handleRejection(err as ApolloError)
      }
    } else {
      if (statusAtendimento === StatusAtendimento.EM_ATENDIMENTO) {
        return await cancelar({ variables: { atendimentoId } })
      }
    }
  }

  const onVisualizarProntuarioClick = () => history.push(`${pathCidadao}/folha-rosto`)

  const onEditarClick = () => {
    return history.push(`${REGISTRO_TARDIO_PATH}/editar/${atendimentoId}`)
  }

  const onExcluirClick = () => {
    const isAgendadoAndBeforeDataInicio = agendadoId && isBefore(getServerTimeNow(), dataInicioAtendimento)
    confirm({
      title: 'Deseja realmente excluir o atendimento de registro tardio?',
      body: isAgendadoAndBeforeDataInicio && 'O agendamento do cidadão também será cancelado.',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        if (isAgendadoAndBeforeDataInicio) {
          cancelarAgendamento({
            variables: {
              input: {
                id: agendadoId,
                justificativa: JustificativaCancelarAgendamentoEnum.OUTRA,
                justificativaOutra: 'Profissional cancelou o agendamento do atendimento realizado fora da UBS.',
              },
            },
          })
            .then(() => {
              alert('success', 'Registro excluído com sucesso.')
              analytics.logEvent('excluir_cidadao_LRT')
            })
            .catch(handleRejection)
        } else {
          excluir({ variables: { atendimento: atendimentoId } })
            .then(() => {
              alert('success', 'Registro excluído com sucesso.')
              analytics.logEvent('excluir_cidadao_LRT')
            })
            .catch(handleRejection)
        }
      },
    })()
  }

  const onCancelarRegistroClick = () => {
    confirm({
      title: 'Cancelar registro de atendimento?',
      body: 'O prazo de atendimento foi excedido para este registro. Deseja cancelar o registro?',
      confirmLabel: 'Cancelar registro',
      cancelLabel: 'Voltar',
      type: 'danger',
      onConfirm: () => {
        cancelar({ variables: { atendimentoId } }).catch(handleRejection)
      },
    })()
  }

  return (
    <Fragment>
      <HFlow
        hSpacing={0.25}
        style={css`
          margin-right: 0.5rem;
          margin-left: auto;
        `}
      >
        {actions.cancelarRegistro && (
          <Tooltip text={actions.cancelarRegistro.hint}>
            <Button
              size='small'
              kind='normal'
              skin='ghost'
              onClick={onCancelarRegistroClick}
              disabled={!actions.cancelarRegistro.enabled}
            >
              <Icon icon='userTimes' />
            </Button>
          </Tooltip>
        )}

        {actions.atender && (
          <Tooltip text={actions.atender.hint}>
            <Button
              size='small'
              kind='normal'
              skin='ghost'
              onClick={handleOnClickAtender}
              disabled={!actions.atender.enabled}
            >
              <Icon icon='multipleUsersOutline' />
            </Button>
          </Tooltip>
        )}
        {actions.editar && (
          <Tooltip text='Mais opções'>
            <DropdownButton icon='dots'>
              {actions.visualizarProntuario && (
                <DropdownItem disabled={!actions.visualizarProntuario.enabled} onClick={onVisualizarProntuarioClick}>
                  Visualizar prontuário
                </DropdownItem>
              )}

              {actions.editar && (
                <Tooltip text={actions.editar?.hint}>
                  <DropdownItem disabled={!actions.editar.enabled} onClick={onEditarClick}>
                    Editar
                  </DropdownItem>
                </Tooltip>
              )}

              {actions.excluir && (
                <Fragment>
                  <DropdownDivider />
                  <Tooltip text={actions.excluir?.hint}>
                    <DropdownItem type='danger' disabled={!actions.excluir.enabled} onClick={onExcluirClick}>
                      <HFlow alignItems='center' hSpacing={0.5}>
                        <Icon icon='trashOutline' />
                        Excluir
                      </HFlow>
                    </DropdownItem>
                  </Tooltip>
                </Fragment>
              )}
            </DropdownButton>
          </Tooltip>
        )}
      </HFlow>
    </Fragment>
  )
}
