import { Heading, HFlow, InfoLabel, VFlow } from 'bold-ui'
import React from 'react'
import { tipoAleitamentoRecord } from 'types/enums'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { DesenvolvimentoCriancaAcompanhamentoPuericultura } from '../../aside/acompanhamento-puericultura/DesenvolvimentoCriancaAcompanhamentoPuericultura'
import { AcompanhamentoPuericulturaModel } from '../../aside/acompanhamento-puericultura/model'
import { PreNatalPartoNascimentoAcompanhamentoPuericultura } from '../../aside/acompanhamento-puericultura/PreNatalPartoNascimentoAcompanhamentoPuericultura'
import { PuericulturaBox } from './components/PuericulturaBox'
import { PLACE_HOLDER_PUERICULTURA } from './model'

interface PuericulturaViewProps {
  cidadao: Pick<CidadaoAtendimento, 'dataNascimento' | 'sexo'>
  dataAtendimento: Instant
  value: AcompanhamentoPuericulturaModel
}

export const PuericulturaView = (props: PuericulturaViewProps) => {
  const { cidadao, dataAtendimento, value } = props

  const { preNatalPartoNascimento, tipoAleitamentoMaterno } = value ?? {}
  const { peso, altura, perimetroCefalico } = preNatalPartoNascimento ?? {}

  if (!value) return null

  return (
    <PuericulturaBox>
      <VFlow>
        <PreNatalPartoNascimentoAcompanhamentoPuericultura
          preNatalPartoNascimento={value?.preNatalPartoNascimento}
          hideVisualizarMedicoesButton
          isAntecedentes={false}
        />

        <VFlow vSpacing={0.5}>
          <Heading level={4}>Antropometria ao nascer</Heading>
          <VFlow>
            <HFlow hSpacing={2}>
              <InfoLabel title='Peso' placeholder={PLACE_HOLDER_PUERICULTURA}>
                {peso && `${peso.replace('.', ',')} cm`}
              </InfoLabel>
              <InfoLabel title='Comprimento' placeholder={PLACE_HOLDER_PUERICULTURA}>
                {altura && `${altura.replace('.', ',')} cm`}
              </InfoLabel>

              <InfoLabel title='Per. cefálico' placeholder={PLACE_HOLDER_PUERICULTURA}>
                {perimetroCefalico && `${perimetroCefalico.replace('.', ',')} cm`}
              </InfoLabel>
            </HFlow>

            <InfoLabel title='Tipo de aleitamento' placeholder={PLACE_HOLDER_PUERICULTURA}>
              {tipoAleitamentoMaterno && tipoAleitamentoRecord[tipoAleitamentoMaterno.tipo]}
            </InfoLabel>
          </VFlow>
        </VFlow>

        <DesenvolvimentoCriancaAcompanhamentoPuericultura
          acompanhamentoPuericultura={value}
          atendimentoPuericultura={null}
          dataAtendimento={dataAtendimento}
          cidadao={cidadao}
        />
      </VFlow>
    </PuericulturaBox>
  )
}
