import { Text, VFlow } from 'bold-ui'
import { SelectField } from 'components/form'
import { HLabel } from 'components/HLabel'
import { ManifestacaoAlergiaSelectFieldDocument } from 'graphql/hooks.generated'
import {
  ManifestacaoAlergiaSelectFieldQuery,
  ManifestacaoAlergiaSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_SIZE, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type ManifestacaoAlergiaSelectFieldModel = ManifestacaoAlergiaSelectFieldQuery['manifestacoesAlergia']['content'][0]

export interface ManifestacaoAlergiaSelectFieldProps
  extends AsyncSelectFieldProps<ManifestacaoAlergiaSelectFieldModel> {}

const itemToString = (item: ManifestacaoAlergiaSelectFieldModel) => item?.nome.capitalize()

const renderItem = (item: ManifestacaoAlergiaSelectFieldModel) => (
  <VFlow vSpacing={0}>
    <Text fontWeight='bold'>{item.nome}</Text>
    {item.sinonimo && <HLabel title='Sinônimos:'>{item.sinonimo}</HLabel>}
  </VFlow>
)

export function ManifestacaoAlergiaSelectField(props: ManifestacaoAlergiaSelectFieldProps) {
  const { selectProps } = useAsyncQuerySelect<
    ManifestacaoAlergiaSelectFieldModel,
    ManifestacaoAlergiaSelectFieldQuery,
    ManifestacaoAlergiaSelectFieldQueryVariables
  >({
    query: ManifestacaoAlergiaSelectFieldDocument,
    extractItems: (data) => data?.manifestacoesAlergia?.content,
    variables: (inputQuery: string): ManifestacaoAlergiaSelectFieldQueryVariables => ({
      filtro: {
        query: inputQuery,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['nome'],
        },
      },
    }),
  })

  return (
    <SelectField<ManifestacaoAlergiaSelectFieldModel>
      renderItem={renderItem}
      itemToString={itemToString}
      {...selectProps}
      {...props}
    />
  )
}
