import { Alert } from 'bold-ui'
import {
  defaultAssinaturaDigitalErrorMessage,
  DocumentoDigitalErrorCodeEnum,
  documentoDigitalErrorCodeToMessage,
} from 'hooks/assinatura-digital/model-assinaturaDigitalPopup'
import React from 'react'

export interface PrescricaoDigitalIntegracaoMessageProps {
  success: boolean
  message?: string
  errorCode?: DocumentoDigitalErrorCodeEnum

  onAlertClose: () => void
}

export const PrescricaoDigitalIntegracaoMessage = ({
  success,
  message,
  errorCode,
  onAlertClose,
}: PrescricaoDigitalIntegracaoMessageProps) => {
  return (
    <Alert inline type={success ? 'success' : 'warning'} onCloseClick={onAlertClose}>
      {message ?? documentoDigitalErrorCodeToMessage[errorCode] ?? defaultAssinaturaDigitalErrorMessage}
    </Alert>
  )
}
