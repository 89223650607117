import { JustificativaCancelamentoAgendaComboEnum as JustificativaEnum } from 'types/enums'
import { composeRules, createValidator, ErrorObject, maxLength, required } from 'util/validation'
import { JustificativaCancelamentoAgendamentoModel } from 'view/agenda/model-agenda'

export const validateJustificativaCancelamento = createValidator<JustificativaCancelamentoAgendamentoModel>(
  { justificativa: [required] },
  (
    values: JustificativaCancelamentoAgendamentoModel,
    error: ErrorObject<JustificativaCancelamentoAgendamentoModel>
  ) => {
    if (values.justificativa === JustificativaEnum.OUTRA) {
      error.justificativaOutra = composeRules([required, maxLength(500)])(values.justificativaOutra)
    }
    return error
  }
)
