import React from 'react'
import { HistoricoTecidosMolesQueryModel } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/tecidos-moles/model'

import { HistoricoTecidosMolesTable } from './HistoricoTecidosMolesTable'

interface HistoricoTecidosMolesPanelProps {
  evolucoes: HistoricoTecidosMolesQueryModel[]
}

export const HistoricoTecidosMolesPanel = (props: HistoricoTecidosMolesPanelProps) => {
  const { evolucoes } = props

  return <HistoricoTecidosMolesTable title='Tecidos moles' titleStyle='P' tecidosMoles={evolucoes} />
}
