import { InfoLabel } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import theme from 'config/theme'
import { css } from 'emotion'
import React from 'react'
import { CboModel, EquipeModel } from 'view/profissional/detail/model'

export interface PermissoesEstagiarioProps {
  cbo?: CboModel
  equipe?: Pick<EquipeModel, 'id' | 'nome' | 'ine'>
  quebraDeLinha?: Boolean
  exibirBorda?: Boolean
}

export function PermissoesEstagiario({
  quebraDeLinha = false,
  exibirBorda = false,
  ...props
}: PermissoesEstagiarioProps) {
  const Label = quebraDeLinha ? InfoLabel : HLabel
  return (
    <div className={exibirBorda ? styles.permissoes : null}>
      <Label title='Permissões'>{permissoesEstagiario({ ...props })}</Label>
    </div>
  )
}

export const permissoesEstagiario = ({ cbo, equipe }: PermissoesEstagiarioProps) => {
  if (!cbo) return ''

  const permissoesCbo = `${cbo.nome} - ${cbo.cbo2002}`?.titleCase()
  const permissoesEquipe = equipe ? `Equipe ${equipe.nome} - ${equipe.ine}` : 'Sem equipe'

  return `${permissoesCbo} | ${permissoesEquipe}`
}

const styles = {
  permissoes: css`
    border: 1px solid ${theme.pallete.divider};
    border-radius: 2px;
    padding: 1rem;
  `,
}
