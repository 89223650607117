import { HFlow, Text, VFlow } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { Cpf } from 'components/label'
import React from 'react'

import { ProfissionalSelectModel } from '../ProfissionalSelectField'

export interface ProfissionalCacheSelectFieldProps
  extends Omit<SelectFieldProps<ProfissionalSelectModel>, 'itemToString' | 'renderItem'> {
  mostrarCns?: boolean
}

const itemToString = (item: ProfissionalSelectModel) => item && item.nome

export function ProfissionalCacheSelectField(props: ProfissionalCacheSelectFieldProps) {
  const { mostrarCns, ...rest } = props

  const renderItem = (item: ProfissionalSelectModel) => (
    <VFlow vSpacing={0}>
      <Text component='p' fontWeight='bold'>
        {item.nome}
      </Text>
      <HFlow hSpacing={0.25}>
        <Text fontWeight='bold'>CPF</Text>
        <Cpf value={item.cpf} />
      </HFlow>
      {mostrarCns && (
        <HFlow hSpacing={0.25}>
          <Text fontWeight='bold'>CNS</Text>
          <Text>{item.cns}</Text>
        </HFlow>
      )}
    </VFlow>
  )

  return <SelectField<ProfissionalSelectModel> itemToString={itemToString} renderItem={renderItem} {...rest} />
}
