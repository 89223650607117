import { Button, HFlow, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Form, SubmitButton, UnidadeSaudeSelectField, UnidadeSaudeSelectModel } from 'components/form'
import { FormApi } from 'final-form'
import { useCadastroUnidadeConsultaHorusMutation } from 'graphql/hooks.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'

interface FormModel {
  unidadeSaudeId: ID
  unidadeConsultaId: UnidadeSaudeSelectModel
}
const validate = createValidator<FormModel>({
  unidadeConsultaId: [required],
})

const path = metaPath<FormModel>()

export interface HorusCadastroFormProps {
  unidadeSaudeId: ID
  onCancel(): void
  onSuccess(): void
}

export function HorusForm(props: HorusCadastroFormProps) {
  const { onCancel, onSuccess } = props
  const [cadastroUnidadeHorus] = useCadastroUnidadeConsultaHorusMutation()

  const renderForm = (formProps: FormRenderProps<FormModel>) => {
    return (
      <VFlow style={{ textAlign: 'left' }}>
        <UnidadeSaudeSelectField label='Unidade de saúde' name={path.unidadeConsultaId} />
        <HFlow justifyContent='flex-end'>
          <Button size='small' onClick={handleCancel(formProps)}>
            Cancelar
          </Button>
          <SubmitButton size='small' skin='outline' handleSubmit={formProps.handleSubmit}>
            Consultar e adicionar
          </SubmitButton>
        </HFlow>
      </VFlow>
    )
  }

  const handleCancel = (formProps: FormRenderProps<FormModel>) => (e) => {
    onCancel()
    setTimeout(formProps.form.reset)
  }

  const handleSubmit = (values: FormModel, formApi: FormApi<FormModel>) => {
    return cadastroUnidadeHorus({
      variables: {
        input: {
          unidadeConsultaId: values.unidadeConsultaId.id,
          unidadeSaudeId: values.unidadeSaudeId,
        },
      },
    }).then((success) => {
      alert('success', `Unidade de saúde ${success.data.adicionarUnidadeConsultaHorus.nome} adicionada com sucesso.`)
      setTimeout(formApi.reset)
      onSuccess()
    })
  }
  return (
    <Form<FormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={{
        unidadeConsultaId: null,
        unidadeSaudeId: props.unidadeSaudeId,
      }}
    />
  )
}
