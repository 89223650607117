import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { AtencaoDomiciliarModalidade } from 'graphql/types.generated'
import React from 'react'

export interface AtencaoDomiciliarModalidadeSelectFieldProps
  extends Omit<SelectFieldProps<string>, 'items' | 'itemToString'> {
  removePosObito?: boolean
}

export type AtencaoDomiciliarModalidadeModel = string

export function AtencaoDomiciliarModalidadeSelectField(props: AtencaoDomiciliarModalidadeSelectFieldProps) {
  const { removePosObito, ...rest } = props

  const itemToString = (item: AtencaoDomiciliarModalidade) => item && descriptions[item]

  return (
    <SelectField<AtencaoDomiciliarModalidadeModel>
      items={renderItems(removePosObito)}
      itemToString={itemToString}
      {...rest}
    />
  )
}

const renderItems = (removePosObito: boolean) => {
  if (removePosObito) {
    return items.filter((e) => e !== AtencaoDomiciliarModalidade.POS_OBITO)
  }

  return items
}

export const modalidadeDescriptionByEnumType = (item: AtencaoDomiciliarModalidadeModel) => descriptions[item]

const items = [
  AtencaoDomiciliarModalidade.MODALIDADE_AD_1,
  AtencaoDomiciliarModalidade.MODALIDADE_AD_2,
  AtencaoDomiciliarModalidade.MODALIDADE_AD_3,
  AtencaoDomiciliarModalidade.POS_OBITO,
]

const descriptions = {
  MODALIDADE_AD_1: 'AD1',
  MODALIDADE_AD_2: 'AD2',
  MODALIDADE_AD_3: 'AD3',
  POS_OBITO: 'Pós-óbito',
}
