import { AcessoCbo } from 'graphql/types.generated'
import { NIVEL_SUPERIOR, NIVEL_TECNICO } from 'types/NivelCbo'

import { grupoCboPreNatal } from '../pre-natal/acessos'

export const grupoCboPuericultura = [
  ...grupoCboPreNatal,
  AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
  AcessoCbo.PSICOLOGO,
  AcessoCbo.NUTRICIONISTA,
]

export const grupoCboResultadosExames = {
  visualizar: [
    AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM,
    AcessoCbo.CIRURGIAO_DENTISTA,
    AcessoCbo.ENFERMEIRO,
    AcessoCbo.MEDICO,
    AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
    AcessoCbo.PSICOLOGO,
    AcessoCbo.NUTRICIONISTA,
  ],
  adicionar: [
    AcessoCbo.CIRURGIAO_DENTISTA,
    AcessoCbo.ENFERMEIRO,
    AcessoCbo.MEDICO,
    AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
    AcessoCbo.PSICOLOGO,
    AcessoCbo.NUTRICIONISTA,
  ],
}

export const grupoCboMedicoes = {
  visualizar: [...NIVEL_SUPERIOR, ...NIVEL_TECNICO],
  adicionar: [...NIVEL_SUPERIOR, ...NIVEL_TECNICO],
}
