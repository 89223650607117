/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { ReactComponent as ContinuousMedicineIcon } from 'images/medical/medicine-continuous-bicolor.svg'
import { ReactComponent as MedicineExclamationIcon } from 'images/medical/medicine-exclamation.svg'
import { formatDate } from 'util/date/formatDate'

import { MedicamentoBarraLateralModel } from '../types/MedicamentoBarraLateralModel'

const ICON_COLOR = gray.c40

interface ItemMedicamentoBarraLateralProps {
  medicamento: MedicamentoBarraLateralModel
  concluido?: boolean
}

export function ItemMedicamentoBarraLateral({ medicamento, concluido = false }: ItemMedicamentoBarraLateralProps) {
  const periodoMedicamento = getPeriodoMedicamentoString(medicamento)

  return (
    <HFlow hSpacing={0.5} alignItems='center'>
      {medicamento.usoContinuo ? (
        <Tooltip text={`Medicamento de uso contínuo: ${periodoMedicamento}`}>
          <ContinuousMedicineIcon fill={ICON_COLOR} />
        </Tooltip>
      ) : (
        <Tooltip text={`Período: ${periodoMedicamento}`}>
          {concluido ? (
            <MedicineExclamationIcon fill={ICON_COLOR} />
          ) : (
            <Icon icon='medicineBottleFilled' color={ICON_COLOR} />
          )}
        </Tooltip>
      )}
      <VFlow
        vSpacing={0.2}
        style={css`
          word-break: break-word;
        `}
      >
        <Text fontWeight='bold'>
          {`${medicamento.medicamento.principioAtivo} ${medicamento.medicamento.concentracao ?? ''}`.trim()}
        </Text>
        <Text>{medicamento.posologia}</Text>
      </VFlow>
    </HFlow>
  )
}
const getPeriodoMedicamentoString = ({
  dtInicioTratamento: inicio,
  dtFimTratamento: fim,
}: MedicamentoBarraLateralModel) => `${formatDate(inicio)} até ${fim ? formatDate(fim) : 'Indeterminado'}`
