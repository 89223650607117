import { isEmpty, Text } from 'bold-ui'
import { UltimoArquivoProntoEmProcessamentoQuery } from 'graphql/types.generated'
import React from 'react'
import StatusArquivoLabel from 'view/arquivo/StatusArquivoLabel'

interface UltimoRelatorioStatusProps {
  data: UltimoArquivoProntoEmProcessamentoQuery
  loading: boolean
}

export default function UltimoRelatorioStatus({ data, loading }: UltimoRelatorioStatusProps) {
  return !isEmpty(data.ultimoArquivoProntoEmProcessamento) ? (
    <StatusArquivoLabel arquivo={data.ultimoArquivoProntoEmProcessamento} isApenasUltimoRelatorio />
  ) : (
    !loading && <Text>Nenhum relatório gerado nos últimos 7 dias</Text>
  )
}
