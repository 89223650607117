/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import { SubmitButton } from 'components/form'
import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import {} from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'
import { Fragment, useCallback, useMemo } from 'react'
import { EditableRow } from 'view/atendimentos/detail/components/EditableList'

import { showDescricaoCodigoProcedimento } from '../../../util-EvolucoesOdontologicasPlano'
import { OutrosFieldModel } from './OutrosField'
import { OutrosForm } from './OutrosForm'
import { OutrosFormFields } from './OutrosFormFields'

export interface OutrosTableRowProps {
  item: OutrosFieldModel
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  procedimentosSelecionados: ProcedimentoOdontoSelectModel[]
  updateItem(item: OutrosFieldModel): void
  removeItem(item: OutrosFieldModel): void
  editingRowId: string
  setEditingRowId(id: string): void
  readonly?: boolean
}

export default function OutrosTableRow(props: OutrosTableRowProps) {
  const {
    item,
    cidadaoDataNascimento,
    dataAtendimento,
    procedimentosSelecionados,
    readonly,
    editingRowId,
    updateItem,
    removeItem,
    setEditingRowId,
  } = props

  const isEditing = useMemo(() => editingRowId === item._id && !readonly, [editingRowId, item._id, readonly])

  const clearEditingRow = useCallback(() => setEditingRowId(undefined), [setEditingRowId])

  const handleSubmitEdicao = useCallback(
    (values: OutrosFieldModel) => {
      updateItem(values)
      clearEditingRow()
    },
    [clearEditingRow, updateItem]
  )

  return (
    <EditableRow editing={isEditing}>
      {!isEditing ? (
        <Fragment>
          <Grid
            style={css`
              margin: 0;
            `}
          >
            <Cell size={4} alignSelf='center'>
              <Text
                style={css`
                  word-break: break-word;
                `}
              >
                {item.local}
              </Text>
            </Cell>

            <Cell size={6} alignSelf='center'>
              {item.procedimentos.map((proced) => (
                <Text key={proced.id}>
                  {showDescricaoCodigoProcedimento(proced)}
                  <br />
                </Text>
              ))}
            </Cell>

            {!readonly && (
              <Cell size={2} alignSelf='center'>
                <HFlow hSpacing={0.5} justifyContent='flex-end'>
                  <Tooltip text='Editar'>
                    <Button size='small' skin='ghost' onClick={() => setEditingRowId(item._id)}>
                      <Icon icon='penOutline' />
                    </Button>
                  </Tooltip>
                  <Tooltip text='Excluir'>
                    <Button size='small' skin='ghost' onClick={() => removeItem(item)}>
                      <Icon icon='trashOutline' />
                    </Button>
                  </Tooltip>
                </HFlow>
              </Cell>
            )}
          </Grid>
          {!!item?.observacao?.trim().length && (
            <HFlow
              hSpacing={0.2}
              style={css`
                padding-bottom: 0.5rem;
              `}
            >
              <Text fontWeight='bold'>Observações: </Text>
              <Text
                fontStyle='italic'
                style={css`
                  word-break: break-all;
                `}
              >
                <div dangerouslySetInnerHTML={{ __html: item.observacao }} />
              </Text>
            </HFlow>
          )}
        </Fragment>
      ) : (
        <VFlow
          style={css`
            padding: 1rem 0;
          `}
        >
          <Heading level={4}>Editar procedimentos (evoluções odontológicas) de outros locais</Heading>

          <OutrosForm
            procedimentosSelecionados={procedimentosSelecionados}
            onSubmit={handleSubmitEdicao}
            initialValues={item}
            render={(formProps) => (
              <Grid>
                <Cell size={12}>
                  <OutrosFormFields
                    editing
                    procedimentosSelecionados={procedimentosSelecionados}
                    cidadaoDataNascimento={cidadaoDataNascimento}
                    dataAtendimento={dataAtendimento}
                  />
                </Cell>
                <Cell size={12}>
                  <HFlow justifyContent='flex-end'>
                    <Button size='small' onClick={clearEditingRow}>
                      Cancelar
                    </Button>

                    <SubmitButton size='small' kind='primary' handleSubmit={formProps.handleSubmit}>
                      Adicionar
                    </SubmitButton>
                  </HFlow>
                </Cell>
              </Grid>
            )}
          />
        </VFlow>
      )}
    </EditableRow>
  )
}
