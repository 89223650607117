import { FormControl, HFlow, Tooltip, VFlow } from 'bold-ui'
import { ErrorField } from 'components/form/final-form/ErrorField'
import { RadioField } from 'components/form/final-form/RadioField'
import { TipoConsultaOdontoEnum } from 'graphql/types.generated'
import React from 'react'
import { Meta } from 'util/metaPath'

export interface TipoConsultaProps {
  name: Meta<TipoConsultaOdontoEnum>
  isDemandaEspontanea: boolean
  isUrgencia: boolean
}

export function TipoConsultaPanel(props: TipoConsultaProps) {
  const { name, isDemandaEspontanea, isUrgencia } = props

  return (
    <FormControl label='Tipo de consulta' required={!isDemandaEspontanea}>
      <VFlow vSpacing={0.5}>
        <ErrorField name={name} />

        <HFlow style={{ marginLeft: '-0.25rem' }}>
          <Tooltip text='Primeira consulta odontológica programática'>
            <span>
              <RadioField
                name={name}
                label='1ª consulta'
                value={TipoConsultaOdontoEnum.PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA}
              />
            </span>
          </Tooltip>
          {!isUrgencia && (
            <Tooltip text='Consulta de retorno em odontologia'>
              <span>
                <RadioField
                  name={name}
                  label='Consulta de retorno'
                  value={TipoConsultaOdontoEnum.CONSULTA_DE_RETORNO}
                />
              </span>
            </Tooltip>
          )}
          <Tooltip text='Consulta de manutenção em odontologia'>
            <span>
              <RadioField
                name={name}
                label='Consulta de manutenção'
                value={TipoConsultaOdontoEnum.CONSULTA_DE_MANUTENCAO}
              />
            </span>
          </Tooltip>
        </HFlow>
      </VFlow>
    </FormControl>
  )
}
