import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { FormRenderProps, RadioGroupField, TextAreaField } from 'components/form'
import FormModal from 'components/modals/FormModal'
import createDecorator from 'final-form-calculate'
import { useCancelarAgendamentoMutation } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import {
  JustificativaCancelamentoAgendaComboEnum as JustificativaEnum,
  JustificativaCancelamentoAgendaRecord,
} from 'types/enums'
import { metaPath } from 'util/metaPath'
import {
  EventoAgendaLotacao,
  JustificativaCancelamentoAgendamentoModel,
  TipoAgendamento,
} from 'view/agenda/model-agenda'

import { calculatorJustificativaCancelamento } from './calculator'
import { validateJustificativaCancelamento } from './validator'

interface JustificativaCancelarAgendamentoModalProps {
  event: Pick<EventoAgendaLotacao, 'id' | 'tipo' | 'nomeCidadao' | 'motivoReserva'>
  onAction?(): void
  open: boolean
  setOpen(open: boolean): void
}

const path = metaPath<JustificativaCancelamentoAgendamentoModel>()
const decorator = [createDecorator(calculatorJustificativaCancelamento(path))]

export default function JustificativaCancelarAgendamentoModal(props: JustificativaCancelarAgendamentoModalProps) {
  const { event, open, setOpen, onAction } = props

  const alert = useAlert()
  const handleException = useErrorHandler()
  const [cancelarAgendamento] = useCancelarAgendamentoMutation()

  const handleClose = () => setOpen(false)

  const handleSubmit = (values: JustificativaCancelamentoAgendamentoModel) => {
    cancelarAgendamento({
      variables: {
        input: {
          id: event.id,
          justificativa:
            JustificativaCancelamentoAgendaRecord[values.justificativa].justificativaCancelarAgendamentoEnum,
          justificativaOutra: values.justificativaOutra,
        },
      },
    })
      .then(() => {
        alert(
          'success',
          event.tipo === TipoAgendamento.CONSULTA
            ? `Agendamento de ${event.nomeCidadao.titleCase()} foi cancelado.`
            : `Reserva de horário para ${event.motivoReserva.titleCase()} foi cancelada`
        )
        handleClose()
        onAction?.()
      })
      .catch(handleException)
  }

  const renderBody = (formProps: FormRenderProps<JustificativaCancelamentoAgendamentoModel>) => {
    const { values } = formProps
    return (
      <VFlow>
        {event.tipo === TipoAgendamento.CONSULTA && (
          <RadioGroupField
            name={path.justificativa}
            required
            label='Justificativa de cancelamento'
            lines={4}
            options={[
              {
                value: JustificativaEnum.LICENCA_MEDICA,
                label: JustificativaCancelamentoAgendaRecord[JustificativaEnum.LICENCA_MEDICA].descricao,
              },
              {
                value: JustificativaEnum.CIDADAO_CANCELOU,
                label: JustificativaCancelamentoAgendaRecord[JustificativaEnum.CIDADAO_CANCELOU].descricao,
              },
              {
                value: JustificativaEnum.REUNIAO_NAO_PROGRAMADA,
                label: JustificativaCancelamentoAgendaRecord[JustificativaEnum.REUNIAO_NAO_PROGRAMADA].descricao,
              },
              {
                value: JustificativaEnum.OUTRA,
                label: JustificativaCancelamentoAgendaRecord[JustificativaEnum.OUTRA].descricao,
              },
            ]}
          />
        )}
        {values.justificativa === JustificativaEnum.OUTRA && (
          <TextAreaField name={path.justificativaOutra} required label='Especifique a justificativa' maxLength={500} />
        )}
      </VFlow>
    )
  }

  const initialValues: JustificativaCancelamentoAgendamentoModel = useMemo(
    () => ({
      id: event.id,
      justificativa: event.tipo === TipoAgendamento.RESERVA ? JustificativaEnum.OUTRA : undefined,
    }),
    [event.id, event.tipo]
  )

  return (
    <FormModal<JustificativaCancelamentoAgendamentoModel>
      type='danger'
      icon='exclamationTriangleOutline'
      title={`Justificativa para cancelamento agendamento de ${event.tipo}`}
      buttonLabels={{ confirm: 'Salvar e cancelar agendamento', cancel: 'Cancelar justificativa' }}
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      validate={validateJustificativaCancelamento}
      renderBody={renderBody}
      decorators={decorator}
      initialValues={initialValues}
    />
  )
}
