import { useLocalStorageState } from 'hooks/useStorage'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'

import { VisualizacaoTerritorialCacheModel } from '../model'

interface VisualizacaoQueryParams {
  redirect?: string
}

export const useVisualizacaoCache = (acessoId: ID) => {
  const { redirect } = useUrlQueryParams<VisualizacaoQueryParams>()

  const [visualizacaoCacheState, setVisualizacaoCacheState, deleteVisualizacaoCacheState] = useLocalStorageState<
    VisualizacaoTerritorialCacheModel
  >(`${acessoId}/visualizacaoTerritorial/`)

  const renderCache = !!visualizacaoCacheState && redirect === 'true'

  return {
    visualizacaoCacheState,
    setVisualizacaoCacheState,
    deleteVisualizacaoCacheState,
    renderCache,
  }
}
