/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Tooltip } from 'bold-ui'
import { Box } from 'components/Box'
import {
  CboSelectField,
  CboSelectModel,
  CheckboxField,
  Form,
  FormDebouncedValueSpy,
  FormRenderProps,
  SubmitButton,
  TextField,
} from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import { AtestadoQueryInput } from 'graphql/types.generated'
import moment from 'moment'
import { useState } from 'react'
import { metaPath } from 'util/metaPath'

interface AtestadoFilterProps {
  filter: AtestadoQueryInput
  onChange(values: AtestadoQueryInput): void
}

interface AtestadoFilterModel extends Omit<AtestadoQueryInput, 'prontuarioId' | 'cboId'> {
  cbo: CboSelectModel
}

const name = metaPath<AtestadoFilterModel>()

export const AtestadoFilter = ({ filter, onChange }: AtestadoFilterProps) => {
  const [activeFilter, setActiveFilter] = useState<{ periodFilter: boolean; cboFilter?: CboSelectModel }>({
    periodFilter: false,
  })

  const handleOnSubmit = ({ cbo, periodo }: AtestadoFilterModel) => {
    onChange({ ...filter, cboId: cbo?.id, periodo: periodo })
    setActiveFilter({ periodFilter: periodo?.endDate || periodo?.startDate, cboFilter: cbo })
  }

  const renderPopper = ({ close }: PopperControls) => (
    <Form<AtestadoFilterModel>
      render={(formProps: FormRenderProps<AtestadoFilterModel>) => {
        return (
          <Box
            style={css`
              width: 25rem;
            `}
          >
            <Grid>
              <Cell size={12}>
                <CboSelectField
                  name={name.cbo}
                  label='CBO'
                  openOnFocus={false}
                  mostrarIndisponivelLotacao={false}
                  initialValue={activeFilter.cboFilter}
                />
              </Cell>
              <Cell size={12}>
                <DateRangeField
                  name={name.periodo}
                  label='Período'
                  maxDate={moment().toDate()}
                  initialValue={filter.periodo}
                />
              </Cell>
              <Cell size={12} alignSelf='flex-end'>
                <HFlow justifyContent='flex-end'>
                  <Button size='small' onClick={close}>
                    Fechar
                  </Button>
                  <SubmitButton size='small' handleSubmit={() => handleOnSubmit(formProps.values)}>
                    Filtrar
                  </SubmitButton>
                </HFlow>
              </Cell>
            </Grid>
          </Box>
        )
      }}
    />
  )

  const handleDebounceChange = (formState: FormState<AtestadoFilterModel>) => {
    onChange({ ...filter, ...formState.values, query: formState.values.query })
  }

  const countAppliedFilters = () => {
    let count = 0
    activeFilter.periodFilter && count++
    activeFilter.cboFilter && count++
    return !!count && `(${count})`
  }

  const renderForm = () => {
    return (
      <Grid alignItems='center' justifyContent='space-between'>
        <FormDebouncedValueSpy onChange={handleDebounceChange} />

        <Cell size={10}>
          <Grid alignItems='center'>
            <Cell size={6}>
              <Tooltip text='Pesquise por Profissional ou Unidade de saúde'>
                <TextField
                  name={name.query}
                  placeholder='Pesquise por Profissional ou Unidade de saúde'
                  icon='zoomOutline'
                />
              </Tooltip>
            </Cell>
            <Cell>
              <CheckboxField name={name.somenteMeusAtestados} label='Ver somente os meus atestados' />
            </Cell>
          </Grid>
        </Cell>
        <Cell>
          <PopperButton kind='primary' size='small' placement='bottom-end' renderPopper={renderPopper}>
            <Icon icon='filterOutline' />
            Filtros {countAppliedFilters()}
          </PopperButton>
        </Cell>
      </Grid>
    )
  }
  return <Form<AtestadoFilterProps> render={renderForm} />
}
