export function consistentPeriod(
  dtInicial: LocalDate,
  dtFinal: LocalDate,
  lbDataInicial: string = 'Data inicial',
  lbDataFinal: string = 'Data final'
) {
  if (!(dtInicial && dtFinal)) {
    return null
  }

  if (dtInicial.valueOf() > dtFinal.valueOf()) {
    return {
      msgLblDataInicial: 'A ' + lbDataInicial + ' não pode ser posterior à ' + lbDataFinal + '.',
      msgLbDataFinal: 'A ' + lbDataFinal + ' não pode ser anterior à ' + lbDataInicial + '.',
    }
  }
}
