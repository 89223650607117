import api from 'api'
import { ImpressaoAtendimentoInput } from 'api/ImpressaoAtendimentoIndividual'
import moment from 'moment'
import { defaultHandleError, downloadFile } from 'util/downloadFile'

const asFormat = (val) => moment(val).format('YYYY-MM-DD_hhmm')

export const downloadAtendimentoIndividual = (input: ImpressaoAtendimentoInput) => {
  return api.atendimentoIndividual
    .imprimirAtendimentoIndividual(input)
    .then((response) => {
      downloadFile(new Blob([response.data]), `AtendimentoIndividual_${asFormat(moment().toDate())}.pdf`)
      return response
    })
    .catch(defaultHandleError)
}
