/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid } from 'bold-ui'
import React from 'react'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { ReflexosNeuroAlert } from '../alerts/ReflexosNeuroAlert'
import { getStatusDesenvolvimentoAlertProps } from '../alerts/regrasStatusDesenvolvimento'
import { MarcoFormSectionAccordionModel } from '../marcos/MarcoFormSectionAccordion'
import { AvaliacaoDesenvolvimentoModel, MedicaoAnteriorPuericulturaModel } from '../model'
import { faixaEtariaAnterior, faixaEtariaAtual, idadeMenorQue } from '../util'
import StatusDesenvolvimentoAlert from './StatusDesenvolvimentoAlert'

interface PuericulturaAlertProps {
  cidadao: Pick<CidadaoAtendimento, 'dataNascimento' | 'sexo'>
  marcosDesenvolvimento: MarcoFormSectionAccordionModel
  fatoresRisco: AvaliacaoDesenvolvimentoModel[]
  alteracoesFenotipicas: AvaliacaoDesenvolvimentoModel[]
  medicaoAnteriorPerimetroCefalico: MedicaoAnteriorPuericulturaModel
  isAcompanhamentoCard?: boolean
  dataReferencia: Instant
}

function PuericulturaAlert(props: PuericulturaAlertProps) {
  const {
    marcosDesenvolvimento,
    fatoresRisco,
    alteracoesFenotipicas,
    medicaoAnteriorPerimetroCefalico,
    cidadao,
    isAcompanhamentoCard,
    dataReferencia,
  } = props

  if (!marcosDesenvolvimento) return null

  const marcoFaixaEtariaAtual = faixaEtariaAtual(marcosDesenvolvimento.marcosRecord)
  const marcoFaixaEtariaAnterior = faixaEtariaAnterior(marcosDesenvolvimento.marcosRecord)

  const validateAlertR1 = idadeMenorQue(dataReferencia, cidadao?.dataNascimento, 2)

  const statusDesenvolvimentoAtivo = getStatusDesenvolvimentoAlertProps(
    fatoresRisco,
    alteracoesFenotipicas,
    marcoFaixaEtariaAnterior,
    marcoFaixaEtariaAtual,
    validateAlertR1 && {
      cidadaoDataNascimento: cidadao.dataNascimento,
      cidadaoSexo: cidadao.sexo,
      medicaoPerimetroCefalico: medicaoAnteriorPerimetroCefalico,
    }
  )

  return (
    <Grid gapVertical={isAcompanhamentoCard && 0.5}>
      <ReflexosNeuroAlert
        reflexosNeuroList={marcosDesenvolvimento.marcosRecord?.PRIMEIROS_15_DIAS}
        isAcompanhamentoCard={isAcompanhamentoCard}
      />
      {statusDesenvolvimentoAtivo && (
        <Cell size={12}>
          <StatusDesenvolvimentoAlert {...statusDesenvolvimentoAtivo} isAcompanhamentoCard={isAcompanhamentoCard} />
        </Cell>
      )}
    </Grid>
  )
}

export default React.memo(PuericulturaAlert)
