/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Form, FormRenderProps } from 'components/form'
import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { ParteBucalEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

import { getParteBucal } from '../../../util-EvolucoesOdontologicasPlano'
import { CondicoesDenteFieldModel } from '../components/CondicoesDenteField'
import OdontogramaPopper, { OdontogramaPopperProps } from '../components/OdontogramaPopper'
import { ReplicarItemSelectFieldModel } from '../components/ReplicarItemSelectField'
import { DenteFormFields } from './DenteFormFields'
import { DenteFieldModel } from './DentesField'

interface DenteFormPopperProps extends Omit<OdontogramaPopperProps, 'title' | 'onClose'> {
  parteBucalEnum: ParteBucalEnum
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  dentesValues: Record<ParteBucalEnum, DenteFieldModel>
  protese?: boolean
  initialValues?: DenteFieldModel
  proteseTotalSuperior?: boolean
  proteseTotalInferior?: boolean
  onSubmit?(values: DenteFieldModel, form: FormApi<DenteFormModel>): void
}

export interface DenteFormModel extends DenteFieldModel {
  replicarCondicoes?: ReplicarItemSelectFieldModel[]
  replicarProcedimentos?: ReplicarItemSelectFieldModel[]
}

const meta = metaPath<DenteFormModel>()

const denteFormDecorator = createDecorator(
  {
    field: meta.procedimentos.absolutePath(),
    updates: {
      [meta.observacoes.absolutePath()]: (value: ProcedimentoOdontoSelectModel[], allValues: DenteFormModel) => {
        if (value?.length > 0) return allValues.observacoes
      },
    },
  },
  {
    field: meta.condicoesDente.absolutePath(),
    updates: {
      [meta.replicarCondicoes.absolutePath()]: (value: CondicoesDenteFieldModel[], allValues: DenteFormModel) => {
        if (value?.length > 0) return allValues.replicarCondicoes
      },
    },
  }
)

export default function DenteFormPopper(props: DenteFormPopperProps) {
  const {
    parteBucalEnum,
    initialValues,
    cidadaoDataNascimento,
    dataAtendimento,
    protese,
    dentesValues,
    proteseTotalSuperior,
    proteseTotalInferior,
    onSubmit,
    ...popperProps
  } = props

  const dente = getParteBucal(parteBucalEnum)

  const renderForm = (formProps: FormRenderProps<DenteFormModel>) => {
    return (
      <OdontogramaPopper title={`Dente - ${dente.number}`} width={38} onClose={formProps.handleSubmit} {...popperProps}>
        <DenteFormFields
          parteBucalEnum={parteBucalEnum}
          dentesValues={dentesValues}
          cidadaoDataNascimento={cidadaoDataNascimento}
          dataAtendimento={dataAtendimento}
          isProtese={protese}
          isProteseTotalSuperior={proteseTotalSuperior}
          isProteseTotalInferior={proteseTotalInferior}
        />
      </OdontogramaPopper>
    )
  }

  return (
    <Form<DenteFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[denteFormDecorator]}
    />
  )
}
