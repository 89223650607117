/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Cell, Grid, Heading, Modal, ModalBody, VFlow } from 'bold-ui'
import { PageLoading } from 'components/loading'
import { SearchInput } from 'components/search/SearchInput'
import { useProblemasModalQuery } from 'graphql/hooks.generated'
import { Fragment, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { MetaPath } from 'util/metaPath'
import { CiapCidPreNatal } from 'view/atendimentos/atendimento-individual/model'
import { useFormatProblemas } from 'view/atendimentos/detail/soap/aside/lista-problemas/useFormatProblemas'
import { Problema } from 'view/atendimentos/detail/soap/aside/types/ProblemaModel'
import { ProblemaCondicaoModel } from 'view/atendimentos/detail/soap/avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { AccordionProblemasModal } from './AccordionProblemasModal'
import { ProblemasLPCField } from './problemas-registrados-agora/ProblemasLPCField'

interface UrlParams {
  isAntecedentes: string
}

interface ListaProblemasModalProps {
  name: MetaPath<ProblemaCondicaoModel[]>
  prontuarioId: ID
  dataAtendimento: Instant
  problemasAvaliacao: ProblemaCondicaoModel[]
  problemasLPC: ProblemaCondicaoModel[]
  ciapCidPreNatal: CiapCidPreNatal
  somenteCiap: boolean
  cidadao: CidadaoAtendimento
  isAtendimentoObservacao: boolean
  readOnly?: boolean
}

export function ListaProblemasModal(props: ListaProblemasModalProps) {
  const {
    name,
    prontuarioId,
    dataAtendimento,
    problemasAvaliacao,
    problemasLPC,
    ciapCidPreNatal,
    somenteCiap,
    cidadao,
    isAtendimentoObservacao,
    readOnly,
  } = props

  const history = useHistory()
  const match = useRouteMatch<UrlParams>()

  const [filtro, setFiltro] = useState('')
  const [isAntecedentes] = useState<boolean>(match.path.includes('antecedentes'))

  const {
    data: { problemas },
    loading,
  } = useProblemasModalQuery({
    variables: { filtro: { prontuarioId } },
  })

  const {
    getProblemasAtivosLatentesAtuais,
    getProblemasAtivosLatentesAntigos,
    getProblemasResolvidosAtuais,
    getProblemasResolvidosAntigos,
  } = useFormatProblemas(problemas?.content, problemasAvaliacao, problemasLPC, isAtendimentoObservacao)

  const problemasResolvidosAtuais = getProblemasResolvidosAtuais()
  const problemasResolvidosAntigos = getProblemasResolvidosAntigos()
  const problemasAtivosLatentesAtuais = getProblemasAtivosLatentesAtuais()
  const problemasAtivosLatentesAntigos = getProblemasAtivosLatentesAntigos()

  const problemasResolvidosAntigosFiltrados = problemasResolvidosAntigos.filter((problema) =>
    problemaMatchesFilter(filtro.toUpperCase(), problema)
  )

  const problemasAtuais = [...problemasAtivosLatentesAtuais, ...problemasResolvidosAtuais]

  const onClose = () => {
    isAntecedentes
      ? history.push(match.url.replace('/antecedentes/lista-problemas-condicoes', ''))
      : history.push(match.url.replace('/lista-problemas-condicoes', ''))
  }

  const handleSearch = (filter: string) => {
    setFiltro(filter)
  }

  return (
    <Modal
      open
      onClose={onClose}
      style={css`
        width: 64.5rem;
      `}
    >
      <ModalBody>
        <Grid>
          <Cell size={12}>
            <Heading
              level={1}
              style={css`
                margin-bottom: 1rem;
              `}
            >
              Lista de problemas/condições
            </Heading>
            {!readOnly && (
              <Alert inline type='info'>
                Os problemas e condições que precisem de diagnóstico/evolução devem ser inseridos pela avaliação. Os
                registros feitos nesta seção não evoluirão nenhum problema ou condição.
              </Alert>
            )}
          </Cell>
          {!readOnly && (
            <Cell size={12}>
              <ProblemasLPCField
                name={name}
                prontuarioId={prontuarioId}
                dataAtendimento={dataAtendimento}
                problemasCondicoesAnteriores={problemasLPC ?? []}
                problemasCondicoesAvaliados={problemasAvaliacao ?? []}
                fieldValuesConvertido={problemasAtuais}
                problemasCidadao={problemas?.content}
                ciapCidPreNatal={ciapCidPreNatal}
                somenteCiap={somenteCiap}
                cidadao={cidadao}
                isAntecedentes={isAntecedentes}
              />
            </Cell>
          )}
          {loading ? (
            <PageLoading message='Carregando dados' />
          ) : (
            <Fragment>
              <Cell size={12}>
                <Heading level={2}>Ativos/Latentes</Heading>
                <AccordionProblemasModal
                  problemas={problemasAtivosLatentesAntigos}
                  noResultMessage='Nenhum problema/condição ativo ou latente registrado.'
                />
              </Cell>
              <Cell size={12}>
                <VFlow vSpacing={0.5}>
                  <Heading level={2}>Resolvidos</Heading>
                  <div
                    css={css`
                      width: 19rem;
                    `}
                  >
                    <SearchInput placeholder='Pesquise por um problema/condição' onSearch={handleSearch} />
                  </div>
                  <AccordionProblemasModal
                    problemas={problemasResolvidosAntigosFiltrados}
                    filtro={filtro}
                    noResultMessage={
                      filtro ? 'Nenhum resultado encontrado.' : 'Nenhum problema/condição resolvido registrado.'
                    }
                  />
                </VFlow>
              </Cell>
            </Fragment>
          )}
        </Grid>
      </ModalBody>
    </Modal>
  )
}

const problemaMatchesFilter = (filter: string, problema: Problema) =>
  (problema.ciap && (problema.ciap.codigo.includes(filter) || problema.ciap.descricao.includes(filter))) ||
  (problema.cid10 && (problema.cid10.codigo.includes(filter) || problema.cid10.nome.includes(filter))) ||
  (problema.descricaoOutro && problema.descricaoOutro.includes(filter))
