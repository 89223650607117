import React from 'react'
import { masks, onlyNumbers } from 'util/mask'

import { MaskedField, MaskedFieldProps } from '../final-form/MaskedField'

export interface CnsFieldProps extends MaskedFieldProps {}

export function CnsField(props: CnsFieldProps) {
  return <MaskedField mask={masks.cns} parse={onlyNumbers} {...props} />
}
