import { ColorScale } from 'bold-ui/lib/styles/colors'
import { RespostaSolicitacaoEntradaVideochamadaEnum, StatusVideochamadaEnum } from 'graphql/types.generated'

export interface RemoteVideocallParticipant {
  id: ID
  streams: Map<ID, MediaStream>
  nome: string
}

export interface LocalVideocallParticipant {
  id: ID
  nome: string
  cpf: string
  termoProfissionalAceito: boolean
}

export interface VideocallStream {
  id: ID
  label: string
  color: ColorScale
  stream: MediaStream
  remote: boolean
  presenting: boolean
}

export interface ChatMessage {
  time: number
  content: string
  sender: string
  local: boolean
}

export interface VideochamadaUrlParams {
  uuid: string
}

export enum MotivoEncerramentoVideochamadaEnum {
  TIMEOUT = 'TIMEOUT',
  ENCERRADA_POR_CRIADOR = 'ENCERRADA_POR_CRIADOR',
  CONEXAO_FALHOU = 'CONEXAO_FALHOU',
}

export enum AtorVideochamada {
  CIDADAO = 'CIDADAO',
  PROFISSIONAL = 'PROFISSIONAL',
  PROFISSIONAL_EXTERNO = 'PROFISSIONAL_EXTERNO',
}

export enum StatusSolicitacaoEntradaVideochamadaEnum {
  ACEITA = 'ACEITA',
  NEGADA = 'NEGADA',
  TIMEOUT = 'TIMEOUT',
  AGUARDANDO_RESPOSTA = 'AGUARDANDO_RESPOSTA',
  VIDEOCHAMADA_LOTADA = 'VIDEOCHAMADA_LOTADA',
}

export interface DispositivoMidiaSelectModel {
  id: ID
  nome: string
}

export interface ConfiguracoesVideochamadaModel {
  audioInput: DispositivoMidiaSelectModel
  audioOutput: DispositivoMidiaSelectModel
  videoInput: DispositivoMidiaSelectModel
}

export type SolicitacaoEntradaVideochamadaAtmosphereResponse =
  | {
      participanteId: string
      nomeParticipante: string
      cancelar: undefined
    }
  | {
      participanteId: string
      nomeParticipante: undefined
      cancelar: true
    }

export interface RespostaSolicitacaoVideochamadaAtmosphereResponse {
  resposta: RespostaSolicitacaoEntradaVideochamadaEnum
  participanteNegadoId?: string
}

export interface StatusVideochamadaAtmosphereResponse {
  status: StatusVideochamadaEnum
  motivoEncerramento?: MotivoEncerramentoVideochamadaEnum
}
