/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Tag, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { green } from 'bold-ui/lib/styles/colors'
import { LoadingIndicator } from 'components/loading'
import { useVisualizarImoveisQuery } from 'graphql/hooks.generated'
import { TerritorioLogradouroQueryInput } from 'graphql/types.generated'
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'
import { humanizeDate } from 'util/date/humanize-date'
import { FamiliasTerritorio } from 'view/reterritorializacao/model'
import {
  getNomesResponsaveisFamiliares,
  getTooltipResponsaveisFamiliares,
  numeroTotalCidadaosImovel,
} from 'view/reterritorializacao/utils/reterritorializacao'
import {
  SaveVisualizacaoCacheState,
  StatusFichaEnum,
  TerritorioMicroareaItemModel,
  TerritorioModel,
  TipoDomicilioEnum,
  TipoImovelEnum,
  TipoResponsavelEnum,
} from 'view/visualizacaoTerritorial/model'
import { ImovelModel } from 'view/visualizacaoTerritorial/model'
import {
  getTipoImovelIcon,
  redirectToAtualizarCidadao,
  redirectToAtualizarImovelCds,
  redirectToEditarCidadao,
  redirectToEditarImovelCds,
} from 'view/visualizacaoTerritorial/utils/visualizacaoTerritorialUtils'

import { ModalImovel } from '../modal/ModalImovel'
import { ModalOutrosImoveis } from '../modal/ModalOutrosImoveis'

interface MicroareaLogradouroListItemBodyProps {
  rowItem: TerritorioMicroareaItemModel
  territorio: TerritorioModel
  isExpanded: boolean
  lotacaoId: ID
  profissionalId: ID
  cachedImovelId: ID
  saveVisualizacaoCacheState: SaveVisualizacaoCacheState
  deleteVisualizacaoCacheState(): void
}

interface NomeResponsavelFieldProps {
  familias: FamiliasTerritorio[]
  nomeResponsavelTecnico: string
  tipoResponsavel: TipoResponsavelEnum
}

interface ImovelCardProps extends ImovelModel {
  lotacaoId: ID
  profissionalId: ID
  saveVisualizacaoCacheState: SaveVisualizacaoCacheState
  deleteVisualizacaoCacheState(): void
  openImovelFromCache: boolean
}

export default function MicroareaLogradouroListItemBody(props: MicroareaLogradouroListItemBodyProps) {
  const {
    rowItem: { bairro, logradouro, tipoLogradouroId, tipoLogradouroNome, cep },
    territorio: {
      cnes,
      ine,
      microarea,
      isCadastroAtualizadoMenosDeUmAno,
      isCadastroCompleto,
      isImovelVisitadoMenosDeUmMes,
    },
    isExpanded,
    lotacaoId,
    profissionalId,
    saveVisualizacaoCacheState,
    deleteVisualizacaoCacheState,
    cachedImovelId,
  } = props

  const [queryInput, setQueryInput] = useState<TerritorioLogradouroQueryInput>()

  const {
    data: { territorioLogradouro },
    loading,
  } = useVisualizarImoveisQuery({
    variables: {
      input: queryInput,
    },
    fetchPolicy: 'no-cache',
    skip: !isExpanded || isUndefinedOrNull(queryInput),
  })

  useEffect(() => {
    if (isExpanded) {
      setQueryInput({
        bairro,
        logradouro,
        tipoLogradouroId,
        cnes,
        microarea,
        ine,
        isCadastroAtualizadoMenosDeUmAno,
        isCadastroCompleto,
        cep,
        isImovelVisitadoMenosDeUmMes,
      })
    }
  }, [
    bairro,
    cep,
    cnes,
    ine,
    isCadastroAtualizadoMenosDeUmAno,
    isCadastroCompleto,
    isExpanded,
    isImovelVisitadoMenosDeUmMes,
    logradouro,
    microarea,
    tipoLogradouroId,
  ])

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <VFlow vSpacing={0} style={styles.containerLogradouro} onClick={(e) => e.stopPropagation()}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        territorioLogradouro?.map((value) => (
          <LogradouroImovelItemBody
            key={value.id}
            imovelId={value.id}
            lotacaoId={lotacaoId}
            profissionalId={profissionalId}
            cep={value.cep}
            numero={value.numero}
            nomeResponsavelTecnico={value?.responsavelTecnico?.nome}
            dataUltimaVisita={value.dataUltimaVisita}
            logradouro={logradouro}
            nomeTipoLogradouro={tipoLogradouroNome}
            bairro={bairro}
            saveVisualizacaoCacheState={saveVisualizacaoCacheState}
            deleteVisualizacaoCacheState={deleteVisualizacaoCacheState}
            openImovelFromCache={cachedImovelId === value.id}
            {...value}
          />
        ))
      )}
    </VFlow>
  )
}

const LogradouroImovelItemBody = React.memo((props: ImovelCardProps) => {
  const {
    imovelId,
    complemento,
    numero,
    dataUltimaVisita,
    nomeResponsavelTecnico,
    familias,
    tipoCdsImovelId,
    lotacaoId,
    profissionalId,
    ultimaFichaProcessada,
    ultimaFichaCadastrada,
    openImovelFromCache,
    dataUltimaAtualizacao,
    saveVisualizacaoCacheState,
    deleteVisualizacaoCacheState,
    isCadastroCompleto,
  } = props

  const [isOpenModal, setIsOpenModal] = useState(openImovelFromCache)
  const history = useHistory()
  const match = useRouteMatch()
  const theme = useTheme()
  const styles = createStyles(theme)

  const isFichaNaoProcessadaAplicativo = isUndefinedOrNull(ultimaFichaProcessada)

  const numeroTotalCidadaos = numeroTotalCidadaosImovel(familias)
  const { IconImovel, tooltip, fill, tipoResponsavel } = getTipoImovelIcon(
    tipoCdsImovelId,
    !isFichaNaoProcessadaAplicativo ? ultimaFichaProcessada?.tipoDomicilioId : TipoDomicilioEnum.OUTRO
  )

  const handleCloseModal = () => {
    deleteVisualizacaoCacheState()
    setIsOpenModal(false)
  }

  const handleEditarAtualizarImoveis = (
    isUltimaFichaCadastradaProcessada: boolean,
    uuidOrigemImovel: ID,
    ultimaFichaCadastradaId: ID
  ) => {
    saveVisualizacaoCacheState(imovelId)
    setTimeout(() => {
      isUltimaFichaCadastradaProcessada
        ? redirectToAtualizarImovelCds(history, match, uuidOrigemImovel, profissionalId, lotacaoId)
        : redirectToEditarImovelCds(history, match, ultimaFichaCadastradaId, profissionalId, lotacaoId)
    })
  }
  const handleEditarAtualizarCidadaos = (
    isUltimaFichaCadastradaProcessada: boolean,
    uuidFichaOrigem: ID,
    ultimaFichaCadastradaId: ID
  ) => {
    saveVisualizacaoCacheState(imovelId)
    setTimeout(() => {
      isUltimaFichaCadastradaProcessada
        ? redirectToAtualizarCidadao(history, match, uuidFichaOrigem, profissionalId, lotacaoId)
        : redirectToEditarCidadao(history, match, ultimaFichaCadastradaId, profissionalId, lotacaoId)
    })
  }

  const numeroCidadaos = renderizarNumeroMoradores(
    isFichaNaoProcessadaAplicativo,
    tipoResponsavel === TipoResponsavelEnum.TECNICO,
    ultimaFichaProcessada?.numeroMoradores,
    numeroTotalCidadaos
  )

  return (
    <HFlow
      style={css`
        padding: 0.5rem 1rem;
      `}
    >
      <Tooltip text={tooltip}>
        <IconImovel
          css={css`
            fill: ${fill};
            margin-top: 1.35rem;
          `}
        />
      </Tooltip>

      <Grid gapVertical={0} style={styles.imovelCardItem}>
        <Cell
          size={12}
          style={css`
            padding-top: 0.5rem;
            margin-bottom: 0.5rem;
          `}
        >
          <HFlow>
            <Text fontWeight='bold' variant='h3'>
              nº {numero} {complemento ? ` | ${complemento} ` : ''}
            </Text>
            {getTooltipCadastroCompleto(isCadastroCompleto, styles)}
            {ultimaFichaCadastrada?.isProcessado !== StatusFichaEnum.ENVIADA && (
              <HFlow hSpacing={0.25} alignItems='center'>
                <Icon icon='exclamationTriangleOutline' color={theme.pallete.status.alert.main} />
                <Text color='alert'>Existem fichas ainda não processadas para este imóvel</Text>
              </HFlow>
            )}
          </HFlow>
        </Cell>
        <Cell size={3}>
          <Text fontWeight='bold'>Última visita </Text>
          <Text>{dataUltimaVisita ? humanizeDate(dataUltimaVisita) : 'desconhecida'}</Text>
        </Cell>
        <Cell
          size={3}
          style={css`
            white-space: break-spaces;
          `}
        >
          <Text fontWeight='bold'>Última atualização </Text>
          <Text>{dataUltimaAtualizacao ? humanizeDate(dataUltimaAtualizacao) : 'desconhecida'}</Text>
        </Cell>
        {tipoResponsavel !== TipoResponsavelEnum.SEM_RESPONSAVEL ? (
          <Fragment>
            <Cell
              size={3}
              style={css`
                white-space: break-spaces;
              `}
            >
              {tipoResponsavel === TipoResponsavelEnum.TECNICO ? (
                <Text fontWeight='bold'>Responsável técnico </Text>
              ) : (
                <Text fontWeight='bold'>Responsável familiar </Text>
              )}
              <NomeResponsavelField
                familias={familias}
                nomeResponsavelTecnico={nomeResponsavelTecnico}
                tipoResponsavel={tipoResponsavel}
              />
            </Cell>
            <Cell size={2}>
              <Text fontWeight='bold'>Nº de cidadãos </Text>
              {numeroCidadaos ?? 'desconhecido'}
            </Cell>
          </Fragment>
        ) : (
          <Cell size={5} />
        )}
        <Cell
          size={1}
          style={css`
            margin-top: -1rem;
          `}
        >
          <Button skin='ghost' kind='primary' onClick={() => setIsOpenModal(true)} style={styles.button}>
            <Icon icon='zoomOutline' />
          </Button>
        </Cell>
      </Grid>
      {isOpenModal &&
        (TipoImovelEnum.DOMICILIO !== tipoCdsImovelId ? (
          <ModalOutrosImoveis
            imovelId={imovelId}
            isModalOpen={isOpenModal}
            isFichaNaoProcessadaAplicativo={isFichaNaoProcessadaAplicativo}
            onCloseModal={handleCloseModal}
            onEditarAtualizarImovel={handleEditarAtualizarImoveis}
            onEditarAtualizarCidadao={handleEditarAtualizarCidadaos}
          />
        ) : (
          <ModalImovel
            imovelId={imovelId}
            numeroCidadaosConsistentes={numeroCidadaos}
            isModalOpen={isOpenModal}
            isFichaNaoProcessadaAplicativo={isFichaNaoProcessadaAplicativo}
            onCloseModal={handleCloseModal}
            onEditarAtualizarImovel={handleEditarAtualizarImoveis}
            onEditarAtualizarCidadao={handleEditarAtualizarCidadaos}
          />
        ))}
    </HFlow>
  )
})

const createStyles = (theme: Theme) => ({
  containerLogradouro: css`
    & > :not(:last-child) {
      border-bottom: 1px solid ${theme.pallete.gray.c70};
    }
    scroll-behavior: smooth;
    overflow: auto;
    white-space: nowrap;
    max-height: 25rem;
  `,
  imovelCardItem: css`
    width: 65rem;
    align-items: start;
  `,
  button: css`
    color: ${theme.pallete.gray.c20};
    padding: 0.5rem;
    margin-left: 3rem;
  `,
  tagCompleto: css`
    color: ${green.c40};
    background-color: transparent;
    border: 1px solid;
  `,
  tagIncompleto: css`
    color: ${theme.pallete.gray.c40};
    background-color: transparent;
    border: 1px solid;
  `,
})

const NomeResponsavelField = (props: NomeResponsavelFieldProps) => {
  const { familias, nomeResponsavelTecnico, tipoResponsavel } = props

  if (tipoResponsavel === TipoResponsavelEnum.TECNICO) {
    return <Text>{nomeResponsavelTecnico?.titleCase() ?? 'Desconhecido'}</Text>
  } else if (tipoResponsavel === TipoResponsavelEnum.FAMILIAR) {
    return <ResponsavelTooltipAndText familias={familias} />
  }
}

const renderizarNumeroMoradores = (
  isFichaNaoProcessadaAplicativo: boolean,
  isResponsavelTecnico: boolean,
  numeroMoradoresFicha: number,
  numeroTotalCidadaos: number
) => {
  if (isFichaNaoProcessadaAplicativo && isResponsavelTecnico) return null

  return isResponsavelTecnico ? numeroMoradoresFicha : numeroTotalCidadaos
}

const ResponsavelTooltipAndText = (props: { familias: FamiliasTerritorio[] }) => {
  const { familias } = props

  return (
    <Tooltip text={getTooltipResponsaveisFamiliares(familias)} placement='bottom-end'>
      <Text>{familias.length !== 0 ? getNomesResponsaveisFamiliares(familias) : 'não informado'}</Text>
    </Tooltip>
  )
}

const getTooltipCadastroCompleto = (isCompleto: boolean, styles): JSX.Element => {
  const isCompletoText = isCompleto
    ? 'Todos os campos do cadastro estão preenchidos.'
    : 'Alguns campos do cadastro ainda não foram preenchidos.'

  return (
    <Tooltip text={isCompletoText}>
      {isCompleto ? <Tag style={styles.tagCompleto}>Completo</Tag> : <Tag style={styles.tagIncompleto}>Incompleto</Tag>}
    </Tooltip>
  )
}
