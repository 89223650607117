import { Button, Modal, ModalBody } from 'bold-ui'
import {
  useVisualizarNovidadesQuery,
  useVisualizouNovidadesMutation,
  VisualizarNovidadesDocument,
} from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { NewsModalBody } from './NewsModalBody'

export const NewsModal = () => {
  const { data } = useVisualizarNovidadesQuery({ fetchPolicy: 'cache-first' })
  const [isOpen, setIsOpen] = useState(true)
  const visualizarDepois = localStorage.getItem('NEWS')
  const visualizarNovidades = data?.sessao?.profissional?.usuario?.visualizouNovidades

  const [visualizouNews] = useVisualizouNovidadesMutation({
    refetchQueries: [{ query: VisualizarNovidadesDocument }],
  })

  const { analytics } = useFirebase()
  const [timeStart, setTimeStart] = useState(undefined)

  useEffect(() => {
    setTimeStart(moment.now())
  }, [setTimeStart])

  const handleModalClose = (visitouNews: boolean) => {
    setIsOpen(false)
    const timeStop = moment.now()
    const duration = timeStop - timeStart
    if (visitouNews) {
      analytics.logEvent('news_modal_visit')
    } else {
      analytics.logEvent('news_modal_close', getAnalitycsEventNewsProps(duration))
    }
    return visualizouNews()
  }

  const getAnalitycsEventNewsProps = (duration: number) => {
    var speed = 'slow'
    if (duration < 3000) {
      speed = 'fast'
    } else if (duration < 6000) {
      speed = 'medium'
    }
    return {
      speed: speed,
      duration: duration,
    }
  }

  const handleRememberLater = () => {
    setIsOpen(false)
    analytics.logEvent('news_modal_remind_later')
    localStorage.setItem('NEWS', '1')
  }

  return (
    <>
      {visualizarNovidades === false && visualizarDepois !== '1' && (
        <Modal
          onClose={() => handleModalClose(false)}
          open={isOpen}
          size='auto'
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
        >
          <ModalBody style={{ width: '46rem', paddingTop: '0', paddingBottom: '0.7rem' }}>
            <NewsModalBody handleModalClose={() => handleModalClose(true)} />
          </ModalBody>
          <footer style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', paddingBottom: '2.5rem' }}>
            <Button onClick={handleRememberLater} kind='primary' size='small' style={{ marginRight: '1rem' }}>
              Lembrar depois
            </Button>
          </footer>
        </Modal>
      )}
    </>
  )
}
