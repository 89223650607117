/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Paper, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { differenceInDays } from 'date-fns'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useMemo } from 'react'
import { formatDate, toDate } from 'util/date/formatDate'

export const DumPaper = () => {
  const {
    cidadao: { ultimaDum },
  } = useAtendimentoContext()

  const theme = useTheme()
  const classes = styles(theme)

  const diffDumDays = useMemo(() => differenceInDays(Date.now(), toDate(ultimaDum?.dum)), [ultimaDum])

  return (
    <Paper style={classes.paper}>
      <VFlow vSpacing={0.25}>
        <HFlow hSpacing={0.5}>
          <Heading level={4}>Última DUM:</Heading>
          <Text component='span'>
            {ultimaDum?.dum && `${formatDate(ultimaDum.dum)} (${'dia'.pluralizeAndConcatValue(diffDumDays)})`}
          </Text>
        </HFlow>
        <Text component='span' fontStyle='italic'>
          {ultimaDum?.registradoEm ? `Registrada em: ${formatDate(ultimaDum.registradoEm)}` : 'Não registrada'}
        </Text>
      </VFlow>
    </Paper>
  )
}

const styles = (theme: Theme) => ({
  paper: css`
    border: 0;
    background: ${theme.pallete.surface.background};
    padding: 0.5rem;
    ${theme.breakpoints.up('lg')} {
      max-height: 3.75rem;
    }
    ${theme.breakpoints.down('lg')} {
      max-height: 4.5rem;
    }
  `,
})
