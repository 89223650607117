import React, { useState } from 'react'

import { TextField, TextFieldProps } from '../final-form/TextField'

interface PasswordFieldProps extends TextFieldProps {}

export const PasswordField = (props: PasswordFieldProps) => {
  const [typeTextField, setTypeTextField] = useState('password')

  const handleIconClick = () => (typeTextField === 'text' ? setTypeTextField('password') : setTypeTextField('text'))

  return (
    <TextField
      icon={typeTextField === 'text' ? 'eyeHiddenFilled' : 'eyeFilled'}
      onIconClick={handleIconClick}
      type={typeTextField}
      {...props}
    />
  )
}
