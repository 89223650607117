/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SelectMenuItem, Text, Theme, useTheme } from 'bold-ui'
import { Fragment } from 'react'

interface CboCuidadoCompartilhadoGrupoHeaderProps {
  itemProps: any
  isGrupoCboVinculado: boolean
  hasCboVinculados: boolean
  hasCboNaoVinculados: boolean
}

export function CboCuidadoCompartilhadoRenderGrupoHeader(props: CboCuidadoCompartilhadoGrupoHeaderProps) {
  const { itemProps, isGrupoCboVinculado, hasCboVinculados, hasCboNaoVinculados } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const emptyGroupMessage =
    ((isGrupoCboVinculado && !hasCboVinculados) || (!isGrupoCboVinculado && !hasCboNaoVinculados)) &&
    'Nenhum CBO disponível.'

  return (
    <Fragment>
      <SelectMenuItem style={styles.headerItem} {...itemProps}>
        <Text fontWeight='bold' style={styles.headerItemText}>
          {isGrupoCboVinculado
            ? "CBO's vinculadas - via compartilhamento de cuidado"
            : "Sem CBO's vinculadas - via guia de encaminhamento"}
        </Text>
      </SelectMenuItem>
      {emptyGroupMessage && (
        <SelectMenuItem style={styles.disabledItem} {...itemProps}>
          <Text>{emptyGroupMessage}</Text>
        </SelectMenuItem>
      )}
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  headerItem: css`
    pointer-events: none;
    cursor: not-allowed;
    background-color: ${theme.pallete.primary.c90};
  `,
  headerItemText: css`
    color: ${theme.pallete.primary.c40};
  `,
  disabledItem: css`
    pointer-events: none;
    cursor: not-allowed;
  `,
})
