/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { ParteBucalEnum } from 'graphql/types.generated'
import { Fragment, useState } from 'react'

import { getParteBucal, showDescricaoCodigoProcedimento } from '../../../util-EvolucoesOdontologicasPlano'
import { Arcadas } from '../../odontograma/Arcadas'
import OdontogramaPopper from '../components/OdontogramaPopper'
import { convertArcadasFieldModelToArcadasModel } from '../converter'
import { ArcadaFieldModel } from './ArcadasField'

interface ArcadaActive {
  parteBucalEnum: ParteBucalEnum
  value: ArcadaFieldModel
}

export interface ArcadasViewProps {
  proteseTotalSuperior?: boolean
  proteseTotalInferior?: boolean
  value?: Record<ParteBucalEnum, ArcadaFieldModel>
}

export default function ArcadasView(props: ArcadasViewProps) {
  const { proteseTotalSuperior, proteseTotalInferior, value } = props
  const [popperOpen, setPopperOpen] = useState(false)

  const [activeArcada, setActiveArcada] = useState<ArcadaActive>()
  const [activeRef, setActiveRef] = useState<SVGSVGElement | HTMLButtonElement>()

  const onClick = (arcada: ParteBucalEnum, ref: HTMLButtonElement) => {
    setActiveArcada({ parteBucalEnum: arcada, value: value && value[arcada] })
    setPopperOpen(true)
  }

  const handleClose = () => {
    setActiveArcada(undefined)
    setPopperOpen(false)
  }

  return (
    <Fragment>
      <Arcadas
        activeItem={activeArcada?.parteBucalEnum}
        proteseTotalSuperior={proteseTotalSuperior}
        proteseTotalInferior={proteseTotalInferior}
        value={value && convertArcadasFieldModelToArcadasModel(value)}
        onClick={onClick}
        setActiveRef={({ ref }) => setActiveRef(ref)}
      />

      {activeArcada && (
        <OdontogramaPopper
          open={popperOpen}
          width={25}
          title={getParteBucal(activeArcada?.parteBucalEnum).title}
          placement='top'
          anchorRef={activeRef}
          onClose={handleClose}
        >
          {!activeArcada.value?.procedimentos?.length ? (
            <Text>Nenhum procedimento registrado.</Text>
          ) : (
            <VFlow>
              {activeArcada.value.procedimentos?.length && (
                <VFlow vSpacing={0.5}>
                  <Text fontWeight='bold'>Procedimentos</Text>
                  <VFlow vSpacing={0}>
                    {activeArcada.value.procedimentos
                      .sort((a, b) => a.descricao.localeCompare(b.descricao))
                      .map((procedimento) => (
                        <Text key={procedimento.id}>{showDescricaoCodigoProcedimento(procedimento)}</Text>
                      ))}
                  </VFlow>
                </VFlow>
              )}

              {!!activeArcada.value.observacoes?.filter((i) => !!i).length && (
                <VFlow vSpacing={0.5}>
                  <Text fontWeight='bold'>Observações</Text>

                  <VFlow vSpacing={0}>
                    {activeArcada.value.observacoes.map((observacao, key) => (
                      <Text key={key} dangerouslySetInnerHTML={{ __html: observacao }} />
                    ))}
                  </VFlow>
                </VFlow>
              )}
            </VFlow>
          )}
        </OdontogramaPopper>
      )}
    </Fragment>
  )
}
