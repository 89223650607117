import { ParteBucalEnum, Procedimento, TipoParteBucalEnum } from 'graphql/types.generated'

interface EvolucaoOdontoTableItemParteBucalModel {
  tipoParteBucal: TipoParteBucalEnum
  parteBucalEnum: ParteBucalEnum
}

interface EvolucaoOdontoTableItemLotacaoModel {
  id: ID
  profissional: { id: string; nome: string }
  cbo: { id: string; nome: string }
}

export interface EvolucaoOdontoTableItemModel {
  key: string
  partesBucais: EvolucaoOdontoTableItemParteBucalModel[]
  procedimentos: Procedimento[]
  observacoes: string
  lotacao: EvolucaoOdontoTableItemLotacaoModel
  searchProcedimentos: string
  searchPartesBucais: string
  lastUpdate: Instant
  isRegistradoAgora: boolean
  descricaoParteBucal?: string
  cacheId?: ID
}

export const URL_EDITAR_SUPRANUMERARIOS = '/procedimentos-supranumerarios/editar/'
export const URL_EDITAR_OUTROS = '/procedimentos-outros/editar/'

export const TOOLTIP_DELETE_DENTES = 'Remoção de registros deve ser feita no Odontograma'
export const TOOLTIP_DELETE_ARCADAS = 'Remoção de registros deve ser feita em Arcadas'
