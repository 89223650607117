/**
 * Adiciona o protocolo HTTP, caso nenhum esteja definido. Caso a URL termine com "/", a mesma é removida.
 * Exemplo: `urlToModel('teste.pec.esusab.ufsc.br/')` resulta em `'http://teste.pec.esusab.ufsc.br'`
 *
 * @param url URL a ser manipulada.
 */
export function urlToModel(url: string) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'http://'.concat(url)
  }

  if (url.endsWith('/')) {
    url = url.substr(0, url.length - 1)
  }

  return url
}
