import axios, { AxiosPromise } from 'axios'

const API_ENDPOINT = '/api/arquivo'
const DOWNLOAD_ARQUIVO_ENDPOINT = API_ENDPOINT + '/download'

export class Arquivo {
  downloadArquivo(arquivoId: ID): AxiosPromise<ArrayBuffer> {
    return axios.post(DOWNLOAD_ARQUIVO_ENDPOINT, null, {
      params: { arquivoId },
      responseType: 'arraybuffer',
    })
  }
}
