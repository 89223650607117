import { View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import React from 'react'

import { usePrinterStyles } from '../usePrinterStyles'

interface PrintVFlowProps {
  children: React.ReactNode
  styles?: Style
}

export const PrintVFlow = (props: PrintVFlowProps) => {
  const { children, styles } = props
  const { config } = usePrinterStyles()
  return <View style={[config.simpleColumn, styles]}>{children}</View>
}
