import { addWeeks } from 'date-fns'

import { humanizeDate } from './humanize-date'

interface DppResult {
  dpp: Date
  humanizedDpp: string
}

export function calculateDpp(dum: Date): DppResult {
  if (dum) {
    const dpp = addWeeks(dum, 40)
    return {
      dpp,
      humanizedDpp: humanizeDate(Date.now(), dpp.getTime()),
    }
  }

  return {} as DppResult
}
