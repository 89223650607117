/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente13(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='42' height='122' viewBox='0 0 42 122' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M8.79077 78.0882C8.79077 73.5942 8.96466 58.2252 9.6324 47.3718C9.80995 44.4859 9.86037 40.0069 9.91697 34.9789C9.96692 30.5418 10.0217 25.677 10.1729 21.1023C10.3349 16.2013 10.6071 11.6214 11.0985 8.19633C11.3446 6.48084 11.6424 5.07999 11.9988 4.08066C12.1771 3.58053 12.3626 3.20296 12.5487 2.94165C12.7368 2.6776 12.8959 2.57314 13.0103 2.53976C13.335 2.44494 13.742 2.51619 14.2634 2.89548C14.7881 3.27725 15.3697 3.93263 15.9876 4.87157C17.2203 6.74485 18.522 9.62917 19.7739 13.326C22.2745 20.7108 24.5421 31.235 25.681 43.1303C26.8251 55.0807 28.7091 65.2467 30.4499 72.7027C31.3203 76.4307 32.1553 79.4831 32.8449 81.7439C33.4622 83.7673 33.9711 85.1844 34.2904 85.8797C34.1937 85.9214 34.0473 85.9706 33.8386 86.0208C33.1957 86.1755 32.1868 86.2904 30.902 86.3683C28.3445 86.5235 24.8102 86.5268 21.2054 86.4611C17.604 86.3955 13.9452 86.2614 11.1442 86.1436C9.59674 86.0786 8.30034 86.0179 7.42353 85.9769C7.1444 85.9638 6.90779 85.9528 6.71915 85.9442C6.72069 85.9401 6.72224 85.936 6.72381 85.9319C6.80023 85.7312 6.90447 85.4843 7.03065 85.1854L7.0385 85.1668C7.16677 84.8629 7.31424 84.513 7.4672 84.1268C8.07737 82.5863 8.79077 80.432 8.79077 78.0882ZM6.60796 86.3004C6.60804 86.2997 6.60812 86.2989 6.60821 86.2982L6.60796 86.3004Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M2.86815 107.258C2.66757 106.867 2.53803 106.206 2.5072 105.28C2.47688 104.37 2.54312 103.26 2.69029 102.013C2.98448 99.5203 3.59658 96.5227 4.3675 93.5575C5.13823 90.5931 6.06401 87.6754 6.98092 85.3427C7.43957 84.1759 7.89292 83.1634 8.32003 82.3678C8.75476 81.558 9.13719 81.0215 9.4397 80.7597C9.74763 80.4932 10.2664 80.2167 10.9886 79.9529C11.7022 79.6922 12.5792 79.4564 13.5735 79.2543C15.5618 78.85 17.9852 78.5871 20.4394 78.5191C22.8945 78.4511 25.3623 78.5788 27.442 78.9477C29.5475 79.3213 31.1592 79.9265 31.9992 80.7406C32.8699 81.5845 33.7556 83.2303 34.6023 85.3812C35.4408 87.5114 36.2149 90.0666 36.8913 92.6628C38.2444 97.8563 39.1942 103.167 39.4841 105.469C39.6028 106.412 39.0531 107.645 37.8827 109.049C36.7334 110.427 35.071 111.868 33.1862 113.204C29.401 115.886 24.8437 118.054 21.9555 118.45C20.5725 118.639 18.8139 118.303 16.8832 117.587C14.9631 116.874 12.9221 115.804 10.9949 114.58C9.06864 113.356 7.26805 111.987 5.82809 110.684C4.37655 109.371 3.33046 108.16 2.86815 107.258Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.29057 78.0882C6.29057 73.5761 6.4645 58.1476 7.13691 47.2183C7.31039 44.3986 7.36021 40.0088 7.41661 34.9744L7.41705 34.9347C7.49449 28.0223 7.58473 19.9667 8.04614 13.52C8.27655 10.3008 8.60497 7.39409 9.0947 5.20003C9.33756 4.11199 9.64185 3.09629 10.0471 2.27781C10.4078 1.54934 11.0804 0.498776 12.3094 0.139959C13.6427 -0.249275 14.8501 0.230858 15.734 0.873944C16.6042 1.50712 17.3806 2.44096 18.0757 3.49727C19.4781 5.62829 20.8608 8.74178 22.1416 12.5242C24.7153 20.1248 27.0159 30.8433 28.1694 42.892C29.8505 60.4512 33.1434 74.0937 35.1704 80.7986C36.9922 83.785 38.5281 88.8231 39.6638 93.4259C40.8521 98.242 41.686 102.945 41.9645 105.157C42.2216 107.199 41.0534 109.15 39.8028 110.65C38.4674 112.251 36.6198 113.835 34.6315 115.244C30.7164 118.018 25.7673 120.451 22.2944 120.927C20.3139 121.197 18.0978 120.704 16.0134 119.931C13.8866 119.141 11.6865 117.981 9.6545 116.69C7.61907 115.397 5.70424 113.943 4.15092 112.538C2.64388 111.174 1.31899 109.717 0.643448 108.399C0.303928 107.736 0.148016 106.978 0.0714113 106.276C-0.00800847 105.549 -0.0163979 104.739 0.0227425 103.892C0.101045 102.198 0.377531 100.198 0.773941 98.1119C1.55793 93.9861 2.8487 89.3275 4.18963 85.6554C4.25199 85.4005 4.339 85.1676 4.39887 85.0119C4.48928 84.7768 4.60379 84.5056 4.7158 84.2402L4.72459 84.2194C5.37453 82.6798 6.29057 80.4599 6.29057 78.0882ZM6.11505 86.2138C3.47615 93.3872 0.981181 104.673 2.42322 107.486C4.44305 111.426 16.0131 119.767 22.0233 118.945C28.0336 118.123 40.5659 110.058 39.9802 105.407C39.4457 101.162 36.7085 86.9052 33.3337 81.6332C31.2766 74.899 27.8942 61.0027 26.1785 43.0827C23.8949 19.2307 17.0815 0.830237 12.8699 2.05981C9.77534 2.96328 9.57196 21.1185 9.41648 34.9968C9.36036 40.0063 9.31049 44.4585 9.13314 47.3411C8.46446 58.2097 8.29057 73.5906 8.29057 78.0882C8.29057 80.9146 7.20738 83.4806 6.56714 84.9972C6.3289 85.5616 6.15199 85.9807 6.11505 86.2138Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
