import { calculateAge } from './calculateAge'

/**
 * Retorna uma representação textual da idade de uma pessoa, no
 * formato 'XX anos e XX meses' para idades maiores que 1 ano
 * e 'XX meses e XX dias' para idades menores que um ano.
 *
 * @param dataNascimento A data de nascimento da pessoa
 */
export function humanizeAge(dataNascimento: string, refDate: string | number = Date.now()) {
  const { years, months, days } = calculateAge(dataNascimento, refDate)

  if (years === 0 && months === 0 && days === 0) {
    return 'Recém nascido'
  } else if (years < 1 && months < 1) {
    return `${days} ${days === 1 ? 'dia' : 'dias'}`
  } else if (years < 1) {
    return `${months} ${months === 1 ? 'mês' : 'meses'}${days > 0 ? ` e ${days} ${days === 1 ? 'dia' : 'dias'}` : ''}`
  } else if (years >= 1 && months === 0) {
    return `${years} ${years === 1 ? 'ano' : 'anos'}`
  } else {
    return `${years} ${years === 1 ? 'ano' : 'anos'} e ${months} ${months === 1 ? 'mês' : 'meses'}`
  }
}
