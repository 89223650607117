import { MarcoDesenvolvimento, NeuroFaixaEtariaEnum } from 'graphql/types.generated'

const faixaEtariaAtualEnum = (data: MarcoDesenvolvimento[]) => data.slice(-1)[0].faixaEtariaEnum

const faixaEtariaAtualDias = (data: MarcoDesenvolvimento[]) => data.slice(-1)[0].faixaEtariaFim

const isMaisVelhoQueFaixaEtariaMaxima = (data: MarcoDesenvolvimento[], idadeEmDias: number) =>
  faixaEtariaAtualDias(data) < idadeEmDias

export const isFaixaEtariaAtual = (data: MarcoDesenvolvimento[], item: MarcoDesenvolvimento, idadeEmDias: number) =>
  !item.alcancadoComMeses &&
  !item.alcancadoComAnos &&
  item.faixaEtariaEnum !== NeuroFaixaEtariaEnum.PRIMEIROS_15_DIAS &&
  item.faixaEtariaEnum === faixaEtariaAtualEnum(data) &&
  !isMaisVelhoQueFaixaEtariaMaxima(data, idadeEmDias)
