import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { useFlags } from 'config/useFlagsContext'
import { GrupoAlvoBuscaAtivaVacinacaoEnum } from 'graphql/types.generated'
import React from 'react'

export interface GrupoAlvoVacinacaoSelectModel {
  id: ID
  enum: GrupoAlvoBuscaAtivaVacinacaoEnum
  descricao: string
}

export interface GrupoAlvoVacinacaoSelectFieldProps
  extends Omit<SelectFieldProps<GrupoAlvoVacinacaoSelectModel>, 'items' | 'itemToString'> {
  isTipoBuscaAtivaCovid: boolean
}

export function GrupoAlvoVacinacaoSelectField(props: GrupoAlvoVacinacaoSelectFieldProps) {
  const { isTipoBuscaAtivaCovid, ...rest } = props
  const { BUSCA_ATIVA_VACINACAO_ENABLED } = useFlags()
  const itemToString = (item: GrupoAlvoVacinacaoSelectModel) => item?.descricao

  return (
    <SelectField<GrupoAlvoVacinacaoSelectModel>
      items={getItems(!!BUSCA_ATIVA_VACINACAO_ENABLED, isTipoBuscaAtivaCovid).sort((a, b) => a.id - b.id)}
      itemToString={itemToString}
      {...rest}
    />
  )
}

const baseItems = [
  { id: 1, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.CRIANCAS, descricao: 'Crianças (0 a 9 anos)' },
  { id: 2, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.ADOLESCENTES, descricao: 'Adolescentes (10 a 19 anos)' },
  { id: 4, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.IDOSOS, descricao: 'Idosos (60 anos ou mais)' },
  { id: 5, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.GESTANTES, descricao: 'Gestantes' },
]

const covidItems = [
  { id: 6, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.GESTANTES_E_PUERPERAS, descricao: 'Gestantes/Puérperas' },
]

const flaggedItems = [{ id: 3, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.ADULTOS, descricao: 'Adultos (20 a 59 anos)' }]

const getItems = (flag: boolean, isTipoBuscaAtivaCovid: boolean) => {
  if (isTipoBuscaAtivaCovid) {
    /* TODO (Royal Flush): Remover filter quando Gestantes e Puérperas for unificado para calendário vacinal */
    return [...baseItems, ...covidItems, ...flaggedItems].filter((grupoAlvo) => grupoAlvo.id !== 5)
  }

  if (flag) {
    return [...baseItems, ...flaggedItems]
  }

  return baseItems
}
