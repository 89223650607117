import { HFlow, Text } from 'bold-ui'
import { SelectField } from 'components/form'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { EquipeCuidadoCompartilhadoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  EquipeCuidadoCompartilhadoSelectFieldQuery,
  EquipeCuidadoCompartilhadoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'
import { EquipeCuidadoCompartilhadoSelectFieldQueryModel } from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/model-cuidadocompartilhado'

export interface EquipesCuidadoCompartilhadoSelectFieldProps
  extends AsyncSelectFieldProps<EquipeCuidadoCompartilhadoSelectFieldQueryModel> {}

const itemToString = (option: EquipeCuidadoCompartilhadoSelectFieldQueryModel) => option && option.nome

const renderItemComEquipe = (item: EquipeCuidadoCompartilhadoSelectFieldQueryModel) => (
  <>
    <Text fontWeight='bold'>
      {item.nome.capitalize()} - {item.tipoEquipe.sigla}
    </Text>
    <HFlow hSpacing={0.5}>
      <Text fontWeight='bold'>INE:</Text>
      {item.ine}
    </HFlow>
  </>
)

export function EquipesCuidadoCompartilhadoSelectField(props: EquipesCuidadoCompartilhadoSelectFieldProps) {
  const { role, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    EquipeCuidadoCompartilhadoSelectFieldQueryModel,
    EquipeCuidadoCompartilhadoSelectFieldQuery,
    EquipeCuidadoCompartilhadoSelectFieldQueryVariables
  >({
    query: EquipeCuidadoCompartilhadoSelectFieldDocument,
    extractItems: (data) => data && data.equipesListaCuidadoCompartilhado,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
      },
    }),
  })

  return (
    <SelectField<EquipeCuidadoCompartilhadoSelectFieldQueryModel>
      itemToString={itemToString}
      renderItem={renderItemComEquipe}
      {...selectProps}
      {...rest}
    />
  )
}
