import { DateRange } from 'bold-ui'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { isAfter, isBefore } from 'date-fns'
import { deburr } from 'lodash'
import { useMemo } from 'react'
import { Meta } from 'util/metaPath'

import { CustomFilterType, FilterType, isTextFilterType, TextFilterType } from './model'

interface UseFilterProps<T, F> {
  items: T[]
  filter: F
  filtersType: FilterType[]
  customFilters?: CustomFilterType<T>[]
}

export function useFilter<T, F>(props: UseFilterProps<T, F>): T[] {
  const { items, filter, filtersType, customFilters } = props

  const filterType: TextFilterType = filtersType.find((filterType) => isTextFilterType(filterType))
  const itemToFilterTextMap = useMemo(
    () => createItemToFilterTextMap<T>(items, filterType?.searchFields, filterType?.removeTagsOnFilter),
    [filterType, items]
  )

  let itemsFiltered = items

  filtersType.forEach((filterType) => {
    itemsFiltered = itemsFiltered.filter((item) => handleFilter(item, filter, filterType, itemToFilterTextMap))
  })

  customFilters?.forEach((customFilter) => {
    itemsFiltered = itemsFiltered.filter((item) => customFilter(item))
  })

  return itemsFiltered
}

function createItemToFilterTextMap<T>(
  items: T[],
  searchFields: Meta<string>[],
  removeTagsOnFilter: boolean
): Map<T, string[]> {
  const result = new Map<T, string[]>()

  if (searchFields) {
    items.forEach((item) => {
      let normalizedSearchFields: string[] = []

      searchFields.forEach((searchField) => {
        let normalizedItemText: string = ''
        normalizedItemText += deburr(resolveValue(item, searchField)).toLowerCase()

        if (removeTagsOnFilter) {
          normalizedItemText = normalizedItemText.removeTags()
        }

        normalizedSearchFields.push(normalizedItemText)
      })

      result.set(item, normalizedSearchFields)
    })
  }

  return result
}

function handleFilter<T, F>(
  item: T,
  filter: F,
  filterType: FilterType,
  itemToFilterTextMap: Map<T, string[]>
): boolean {
  return isTextFilterType(filterType) && filterByTextField(item, filter, filterType, itemToFilterTextMap)
}

function filterByTextField<T, F>(
  item: T,
  filter: F,
  filterType: FilterType,
  itemToFilterTextMap: Map<T, string[]>
): boolean {
  const filterInputText: string = resolveValue(filter, filterType.filterField)
  if (filterInputText && filterInputText.trim() !== '') {
    const normalizedInputText = deburr(filterInputText).toLowerCase()
    const escapedInputText = normalizedInputText.escapeRegExp()
    const normalizedInputTextWithoutSpace = escapedInputText.replace(/\s/g, '.*')
    const normalizedInputTextRegex = new RegExp(`.*${normalizedInputTextWithoutSpace}.*`)

    const normalizedItemArray = itemToFilterTextMap.get(item)
    return normalizedItemArray.some((itemSearchField) => itemSearchField.match(normalizedInputTextRegex))
  }

  return true
}

export function isDateInDateRange(dateToCompare: Date, dateRange: DateRange) {
  if (dateRange?.startDate && isBefore(dateToCompare, dateRange.startDate)) {
    return false
  }

  if (dateRange?.endDate && isAfter(dateToCompare, dateRange.endDate)) {
    return false
  }

  return true
}
