/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme } from 'bold-ui'
import { blue, green, red, yellow } from 'bold-ui/lib/styles/colors'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import theme from 'config/theme'
import { ClassificacaoRiscoEncaminhamentoEnum } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'

export interface ClassificacaoRiscoEncaminhamentoButtonsProps {
  name: MetaPath<ClassificacaoRiscoEncaminhamentoEnum>
  value: ClassificacaoRiscoEncaminhamentoEnum
}

interface classificacaoButtonContent {
  fieldColorHover: string
  fieldColor: string
  borderColor: string
  fontColor: string
  descricao: string
}

export const classificacaoRiscoColors: Record<ClassificacaoRiscoEncaminhamentoEnum, classificacaoButtonContent> = {
  EMERGENCIA: {
    fieldColorHover: red.c80,
    fieldColor: red.c90,
    borderColor: red.c60,
    fontColor: red.c40,
    descricao: 'Emergência',
  },
  URGENCIA: {
    fieldColorHover: yellow.c80,
    fieldColor: yellow.c90,
    borderColor: yellow.c60,
    fontColor: yellow.c40,
    descricao: 'Urgência',
  },
  PRIORITARIO: {
    fieldColorHover: green.c80,
    fieldColor: green.c90,
    borderColor: green.c60,
    fontColor: green.c40,
    descricao: 'Prioritário',
  },
  ELETIVO: {
    fieldColorHover: blue.c80,
    fieldColor: blue.c90,
    borderColor: blue.c60,
    fontColor: blue.c40,
    descricao: 'Eletivo',
  },
}

export function ClassificacaoRiscoEncaminhamentoButton(props: ClassificacaoRiscoEncaminhamentoButtonsProps) {
  const { name, value } = props
  const { fieldColorHover, fieldColor, borderColor, fontColor, descricao } = classificacaoRiscoColors[value]
  const classes = createStyles(theme, fontColor, borderColor, fieldColor, fieldColorHover)

  return <RadioFieldContainer name={name} style={classes.card} value={value} label={descricao} />
}

const createStyles = (
  theme: Theme,
  fontColor: string,
  borderColor: string,
  fieldColor: string,
  fieldColorHover: string
) => ({
  card: css`
    display: flex;
    align-itens: center;
    width: 9.5rem;
    ${theme.breakpoints.down('lg')} {
      width: 8.9rem;
    }
    height: 4rem;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.04), 0px 1px 5px rgba(0, 0, 0, 0.12);
    font-weight: bold;
    border-radius: 2px;
    border: 1px solid ${borderColor};
    background-color: ${fieldColor};
    color: ${fontColor};
    :focus {
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.04), 0px 1px 5px rgba(0, 0, 0, 0.12);
    }
    :hover {
      background-color: ${fieldColorHover};
    }
  `,
})
