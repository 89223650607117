import { Text } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import React from 'react'

export interface SpaceLineProps {
  styles?: Style
}

export const SpaceLine = (props: SpaceLineProps) => {
  return <Text style={[{ height: 10 }, props.styles]} />
}
