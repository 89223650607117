import { HeadingSection, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { ButtonLink } from 'components/route'
import { usePerfilDetailQuery } from 'graphql/hooks.generated'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { tipoAcesso } from 'types/enums'
import Permissions from 'types/Permissions'
import { titleCase } from 'util/strConversor'

import { createTree } from '../components/RecursoNode'
import { RecursoTree } from '../components/RecursoTree'

interface UrlParams {
  id: ID
}

export const PerfilDetailView = () => {
  const { match } = useRouter<UrlParams>()
  const perfilId = match.params.id

  const { data } = usePerfilDetailQuery({
    variables: { perfilId },
  })

  if (!data || !data.perfil) {
    return null
  }

  const { perfil } = data

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' />

      {perfil && (
        <VFlow vSpacing={2}>
          <PageHeader
            title={titleCase(perfil.nome)}
            subtitle={tipoAcesso[perfil.tipoPerfil]}
            action={
              <CheckPermission permission={Permissions.visualizarPerfis.cadastrarEditarEExcluir}>
                <ButtonLink kind='primary' to={`/perfil/${perfilId}/edicao`}>
                  Editar perfil
                </ButtonLink>
              </CheckPermission>
            }
          />

          <HeadingSection level={2} title='Recursos'>
            <RecursoTree root={createTree(perfil.recursos)} />
          </HeadingSection>
        </VFlow>
      )}
    </PageContent>
  )
}
