import { MaskType } from 'bold-ui'
import { conformToMask } from 'react-text-mask'

export function applyMask(value: string, mask: MaskType): string {
  if (!value || value.trim() === '') {
    return null
  }

  return conformToMask(value, mask as any, { guide: false }).conformedValue
}
