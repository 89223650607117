import { Button, Cell, Grid, HFlow, VFlow } from 'bold-ui'
import { TextAreaField } from 'components/form'
import {
  AntecedenteCiapSelectField,
  AntecedenteCiapSelectFieldModel,
} from 'components/form/field/select/CiapSelectField/AntecedenteCiapSelectField'
import { CiapCapituloEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import {
  EditableListForm,
  EditableListFormProps,
  EditableListFormRenderProps,
} from 'view/atendimentos/detail/components/EditableListForm'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { AntecedentesFamiliaresCiapFormModel, defaultItensAntecedenteCiap } from '../model'
import { familiarValidator } from '../validator'

interface AntecedentesFamiliaresCiapFormProps
  extends Pick<EditableListFormProps<AntecedentesFamiliaresCiapFormModel>, 'initialValues' | 'onSubmit'> {
  isEditing?: boolean
  formValues?: Array<AntecedentesFamiliaresCiapFormModel>

  cidadao: CidadaoAtendimento
}

export function AntecedentesFamiliaresCiapForm(props: AntecedentesFamiliaresCiapFormProps) {
  const { initialValues, isEditing, cidadao, formValues, ...rest } = props

  const prefix = isEditing ? initialValues._id ?? 'edit' : 'new'

  const defaultItens = useMemo(() => defaultItensAntecedenteCiap(cidadao), [cidadao])

  const ciapIsEqual = (item: AntecedenteCiapSelectFieldModel) => {
    return !!(formValues || []).find((o2) => item.id === o2.ciap.id)
  }

  const renderForm = ({ handleSubmit, name }: EditableListFormRenderProps<AntecedentesFamiliaresCiapFormModel>) => {
    return (
      <VFlow vSpacing={4}>
        <Grid>
          <Cell size={8}>
            <AntecedenteCiapSelectField
              name={name.ciap}
              label='CIAP 2'
              capitulosExcluidos={[CiapCapituloEnum.PROCEDIMENTOS]}
              excluirCIAPsDAB
              menuMinWidth={620}
              formValues={formValues}
              defaultItens={defaultItens}
              itemIsDisabled={ciapIsEqual}
            />
          </Cell>

          <Cell size={12}>
            <TextAreaField
              name={name.observacao}
              label='Observações'
              maxLength={200}
              placeholder='Insira as demais informações relacionadas aos antecedentes familiares'
              resize='none'
            />
          </Cell>
          <Cell size={12}>
            <HFlow justifyContent='flex-end'>
              <Button kind='primary' onClick={handleSubmit} size='small'>
                Adicionar
              </Button>
            </HFlow>
          </Cell>
        </Grid>
      </VFlow>
    )
  }

  return (
    <EditableListForm<AntecedentesFamiliaresCiapFormModel>
      render={renderForm}
      initialValues={initialValues}
      validate={familiarValidator}
      prefix={prefix}
      {...rest}
    />
  )
}
