/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { FormPrompt } from 'components/form'
import { Fragment } from 'react'
import { useParams } from 'react-router'

import { EvolucaoDenteSupranumerarioModel } from './DentesSupranumerariosField'
import { DentesSupranumerariosForm } from './DentesSupranumerariosForm'
import { DentesSupranumerariosFormFields } from './DentesSupranumerariosFormFields'

interface EditarDentesSupranumerariosModalProps {
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  evolucoesAtuais: EvolucaoDenteSupranumerarioModel[]
  onSubmit(index: number, values: EvolucaoDenteSupranumerarioModel): void
  onClose(): void
}

export const EditarDentesSupranumerariosModal = (props: EditarDentesSupranumerariosModalProps) => {
  const { cidadaoDataNascimento, dataAtendimento, evolucoesAtuais, onSubmit, onClose } = props

  const { id } = useParams<{ id: string }>()

  const idAsNumber = parseInt(id, 10)
  const initialValues = evolucoesAtuais ? evolucoesAtuais[idAsNumber] : null

  const handleSubmit = (values: EvolucaoDenteSupranumerarioModel) => onSubmit(idAsNumber, values)

  return (
    <DentesSupranumerariosForm
      onSubmit={handleSubmit}
      onSubmitSucceeded={onClose}
      initialValues={initialValues}
      render={(formProps) => (
        <Modal style={styles.modal} onClose={onClose} open={true}>
          <Fragment>
            <ModalBody>
              <FormPrompt />
              <VFlow>
                <VFlow vSpacing={0}>
                  <Heading level={1}>Edição de registros - Dentes supranumerários</Heading>
                  <Text style={styles.local}>{initialValues?.local}</Text>
                </VFlow>

                <DentesSupranumerariosFormFields
                  cidadaoDataNascimento={cidadaoDataNascimento}
                  dataAtendimento={dataAtendimento}
                />
              </VFlow>
            </ModalBody>

            <ModalFooter>
              <HFlow justifyContent='flex-end'>
                <FooterButton kind='normal' onClick={onClose}>
                  Cancelar
                </FooterButton>

                <FooterButton kind='primary' onClick={formProps.handleSubmit}>
                  Salvar
                </FooterButton>
              </HFlow>
            </ModalFooter>
          </Fragment>
        </Modal>
      )}
    />
  )
}

const styles = {
  modal: css`
    width: 40rem;
  `,
  local: css`
    word-break: break-word;
  `,
}
