import { confirm } from 'components/modals/confirm'
import { UnidadeSaude } from 'graphql/types.generated'
import { stringGrouper } from 'util/string/string-grouper'

import { CidadaoGarantiaAcesso } from '../model-garantiaAcesso'

export const concatNomesUnidadesSaude = (nomesUnidadesSaude: string[], isCEO: Boolean) => {
  const preposicao = isCEO ? 'no ' : 'na '
  return preposicao + stringGrouper(...nomesUnidadesSaude)
}

export const confirmCidadaoPresenteOutraUnidadeSaude = (
  unidadesSaude: UnidadeSaude[],
  currentUnidadeSaudeId: ID,
  cidadao: CidadaoGarantiaAcesso,
  isCEO: Boolean,
  isEdicao: Boolean,
  handleSubmit: () => void
) => {
  const isPresenteNestaUbs = unidadesSaude.some((unidadeSaude) => unidadeSaude.id === currentUnidadeSaudeId)
  if (!isPresenteNestaUbs && unidadesSaude.length > 0) {
    const nomeCidadao = (cidadao.nomeSocial ?? cidadao.nome).titleCase()
    const nomesUnidadesSaude = unidadesSaude.map((unidadeSaude) => unidadeSaude.nome)
    return confirm({
      title: `Deseja continuar com ${isEdicao ? 'a edição?' : 'o registro?'}`,
      body: `${nomeCidadao} já possui um registro para o tipo de ${
        isCEO ? 'serviço' : 'atendimento'
      } selecionado ${concatNomesUnidadesSaude(nomesUnidadesSaude, isCEO)}.`,
      confirmLabel: `${isEdicao ? 'Continuar' : 'Adicionar'}`,
      cancelLabel: 'Voltar',
      onConfirm: handleSubmit,
    })()
  } else {
    handleSubmit()
  }
}
