/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ExternalStyles } from 'bold-ui'
import { composeRefs } from 'bold-ui/lib/util/react'
import { noop } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, VideoHTMLAttributes } from 'react'
import { usePictureInPicture } from 'view/videochamada/hooks/usePictureInPicture'

interface StreamPlayerProps extends Omit<VideoHTMLAttributes<HTMLVideoElement>, 'style'> {
  stream: MediaStream
  pipActive?: boolean
  setPipActive?(newVal: boolean): void
  style?: ExternalStyles
}

export function StreamPlayer(props: StreamPlayerProps) {
  const { stream, style, pipActive = false, setPipActive = noop, ...rest } = props
  const videoRef = useRef<HTMLVideoElement>()

  const setVideoRef = useCallback(
    (ref: HTMLVideoElement) => {
      videoRef.current = ref
      if (videoRef.current) videoRef.current.srcObject = stream
    },
    [stream]
  )

  useEffect(() => {
    const handleChangeTracks = () => {
      const possuiTrackVideo = stream.getVideoTracks().some((track) => track.enabled)
      if (pipActive && !possuiTrackVideo) setPipActive(false)
    }
    if (!stream && pipActive) setPipActive(false)

    stream?.addEventListener('removetrack', handleChangeTracks)
    return () => {
      stream?.removeEventListener('removetrack', handleChangeTracks)
    }
  }, [pipActive, setPipActive, stream])

  const { videoRef: pipVideoRef } = usePictureInPicture({ active: pipActive, onPipActiveChange: setPipActive })

  const ref = useMemo(() => composeRefs(setVideoRef, pipVideoRef), [pipVideoRef, setVideoRef])

  useEffect(() => {
    if (videoRef.current) videoRef.current.srcObject = stream
  }, [stream])

  return <video ref={ref} css={style} {...rest} />
}
