import { information } from 'components/modals/information'
import { ProcessamentoStatusEnum } from 'graphql/types.generated'
import React from 'react'

interface ProcessamentoPendenteModalProps {
  status: ProcessamentoStatusEnum
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const MODAL_TITLE = 'Não foi possível gerar este relatório'
const STATUS_EM_PROCESSAMENTO_MSG = 'Os dados estão sendo processados. Aguarde o término do processamento.'
const STATUS_ERRO_MSG = 'Ocorreu um erro no processamento. Contate o administrador da instalação.'

export const ProcessamentoPendenteModal = (props: ProcessamentoPendenteModalProps) => {
  const { status, isOpen, setIsOpen } = props

  const handleClose = () => setIsOpen(false)

  const modalBody = status === ProcessamentoStatusEnum.EM_PROCESSAMENTO ? STATUS_EM_PROCESSAMENTO_MSG : STATUS_ERRO_MSG

  return (
    <>
      {isOpen &&
        information({
          title: MODAL_TITLE,
          body: modalBody,
          onClose: handleClose,
        })()}
    </>
  )
}
