import { HFlow, Icon, Text } from 'bold-ui'
import { IconLabel } from 'components/IconLabel'
import { OrigemAgendamentoEnum } from 'graphql/types.generated'
import React from 'react'
import { formatDate, formatDayOfWeek, formatHoursMinutes } from 'util/date/formatDate'
import { AgendaOnlineMarker } from 'view/agenda/components/markers/AgendaOnlineMarker'

import { situacaoAgendamentoRecord } from '../../../model-agenda'
import { AgendamentoCidadaoModel } from '../../model'

interface AgendamentoCidadaoHeaderProps {
  agendamento: AgendamentoCidadaoModel
}

export function AgendamentoCidadaoHeader(props: AgendamentoCidadaoHeaderProps) {
  const {
    agendamento: { isForaUbs, situacao, horarioFinal, horarioInicial, localAtendimento, origem },
  } = props

  const isAgendadoPeloCidadao = origem === OrigemAgendamentoEnum.MEUDIGISUS

  return (
    <HFlow hSpacing={0.5}>
      {horarioInicial && horarioFinal && (
        <>
          {isAgendadoPeloCidadao ? <AgendaOnlineMarker /> : <Icon icon='calendarFilled' size={1} fill='primary' />}
          <Text fontWeight='bold'>
            {formatDate(horarioInicial)} - {formatDayOfWeek(horarioInicial).capitalize()}
            {` às
            ${formatHoursMinutes(horarioInicial)} até ${formatHoursMinutes(horarioFinal)}`}
          </Text>
          {'|'}
        </>
      )}
      <Text> {situacaoAgendamentoRecord[situacao].descricao} </Text>
      {isForaUbs && <IconLabel icon='mapMarkerFilled' text={localAtendimento.localAtendimentoExibicao} />}
    </HFlow>
  )
}
