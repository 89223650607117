/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, Icon, Modal, ModalBody, VFlow } from 'bold-ui'
import { ButtonLink } from 'components/route'
import { StickyButton } from 'components/sticky-button/StickyButton'
import { usePeriogramasCompletoQuery } from 'graphql/hooks.generated'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'

import { convertPeriogramasCompletosQueryToModel } from './converter'
import { HistoricoPeriogramaCompletoList } from './HistoricoPeriogramaCompletoList'

interface HistoricoPeriogramaCompletoModalProps {
  prontuarioId: ID
}

const urlHistoricoPeriogramaCompleto = '/historico-periograma-completo/visualizar/'

export const HistoricoPeriogramaCompletoModal = (props: HistoricoPeriogramaCompletoModalProps) => {
  const { prontuarioId } = props

  const match = useRouteMatch()
  const history = useHistory()

  const [hasItemExpanded, setHasItemExpanded] = useState(false)
  const [ref, setRef] = useState<HTMLDivElement>()

  const {
    data: { periogramasCompleto },
  } = usePeriogramasCompletoQuery({ variables: { input: { prontuarioId } } })

  const data = useMemo(() => convertPeriogramasCompletosQueryToModel(periogramasCompleto), [periogramasCompleto])

  const handleAccordionChange = useCallback(
    (items: string[]) => {
      setHasItemExpanded(items.length > 0)

      if (ref?.scrollTop > 0) {
        setTimeout(() => {
          const accordionExpanded: HTMLElement = ref.querySelector(
            '*[data-accordion-component="Accordion"] *[data-accordion-component="AccordionItemButton"][aria-expanded="true"] '
          )

          ref?.scrollTo({ top: accordionExpanded?.offsetTop - 16 })
        })
      }
    },
    [ref]
  )

  const collapseAccordion = useCallback(() => {
    const accordionExpanded: HTMLElement = ref.querySelector(
      '*[data-accordion-component="Accordion"] *[data-accordion-component="AccordionItemButton"][aria-expanded="true"] '
    )

    accordionExpanded?.click()
  }, [ref])

  return (
    <Fragment>
      <ButtonLink
        kind='primary'
        size='small'
        to={`${match.url}${urlHistoricoPeriogramaCompleto}`}
        disabled={data?.length === 0}
      >
        <Icon
          icon='zoomOutline'
          style={css`
            margin-right: 0.5rem;
          `}
        />
        Visualizar histórico
      </ButtonLink>

      <Route path={`${match.url}${urlHistoricoPeriogramaCompleto}`}>
        <Modal
          open={true}
          size='large'
          onClose={() => history.push(match.url.replace(urlHistoricoPeriogramaCompleto, ''))}
          style={css`
            width: 58.5rem;
            max-height: 95vh;
            position: relative;
          `}
          containerRef={setRef}
        >
          <ModalBody>
            <VFlow vSpacing={1.5}>
              <Heading level={1}>Histórico de periograma completo</Heading>

              <HistoricoPeriogramaCompletoList data={data} onAccordionChange={handleAccordionChange} />
            </VFlow>
          </ModalBody>

          {hasItemExpanded && (
            <StickyButton containerRef={ref} showsAt={200} kind='normal' size='small' onClick={collapseAccordion}>
              <Icon
                icon='angleUp'
                style={css`
                  margin-right: 0.5rem;
                `}
              />
              Fechar histórico
            </StickyButton>
          )}
        </Modal>
      </Route>
    </Fragment>
  )
}
