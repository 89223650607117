import { LoadingIndicator } from 'components/loading'
import React from 'react'

interface SwitchPanelObjetivoFormProps {
  isLoading: boolean
  isReadOnly: boolean
  hasAccess: boolean
  components: {
    Field: JSX.Element
    View: JSX.Element
  }
}

export const SwitchPanelObjetivoForm = (props: SwitchPanelObjetivoFormProps) => {
  const {
    isLoading,
    isReadOnly,
    hasAccess,
    components: { Field, View },
  } = props

  return isLoading ? <LoadingIndicator /> : isReadOnly ? View : hasAccess && Field
}
