import { IdadeFieldModel } from 'components/form'
import { format } from 'date-fns'
import { AtendimentoPuericultura, TipoAleitamentoMaternoEnum } from 'graphql/types.generated'
import { booleanToString, tipoAleitamentoRecord } from 'types/enums'

export const instantToDateString = (data: Instant) => (data ? format(data, 'dd/MM/yyyy') : undefined)

export const idadeGestacionalToString = (semanas: number | string, dias: number | string) => {
  const labelDias = Number(dias) === 1 ? 'dia' : 'dias'
  return semanas && `${semanas} semanas e ${dias || '0'} ${labelDias}`
}

export const vacinacaoToString = (vacinacaoEmDia: boolean, dataVacinacao: Instant) =>
  vacinacaoEmDia !== undefined &&
  `${booleanToString[vacinacaoEmDia?.toString()]} (${instantToDateString(dataVacinacao)})`

export const tipoAleitamentoToString = (tipoAleitamento: TipoAleitamentoMaternoEnum, dataTipoAleitamento: Instant) =>
  tipoAleitamento && `${tipoAleitamentoRecord[tipoAleitamento]} (${instantToDateString(dataTipoAleitamento)})`

export const ultimoAtendimentoLabel = (data: AtendimentoPuericultura) =>
  data?.dataInicio && `${instantToDateString(data?.dataInicio)} por ${data?.nomeProfissional}`

export const alcancadoComToString = (alcancadoCom: IdadeFieldModel) => {
  const { meses, anos } = alcancadoCom
  const labelAnos = Number(anos) === 1 ? 'ano' : 'anos'
  const labelMeses = Number(meses) === 1 ? 'mês' : 'meses'
  return ` - ${Number(anos)} ${labelAnos} e ${Number(meses)} ${labelMeses}`
}
