/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, hexToRGB, Text, Theme, useTheme } from 'bold-ui'
import { NavHashLink, NavHashLinkProps } from 'react-router-hash-link'

interface SideMenuSublinkProps extends NavHashLinkProps {
  title: string
}

export const SideMenuSublink = (props: SideMenuSublinkProps) => {
  const { title, ...rest } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <li>
      <NavHashLink smooth css={styles.sublink} {...rest}>
        <Text color='inherit'>{title}</Text>
      </NavHashLink>
    </li>
  )
}

const createStyles = (theme: Theme) => ({
  sublink: css`
    display: flex;
    align-items: center;
    color: ${theme.pallete.text.main};
    background-color: ${theme.pallete.gray.c100};
    outline: none;
    font-weight: normal;
    padding: 0.5rem 1.5rem 0.5rem 2.5rem;
    text-decoration: none;

    &:hover {
      background-color: ${hexToRGB(theme.pallete.gray.c20, 0.1)};
    }

    &:focus {
      border-radius: 4;
      box-shadow: ${focusBoxShadow(theme, 'primary', 'inset')};
    }

    &.active {
      color: ${theme.pallete.primary.main};
      font-weight: bold;
      background-color: ${hexToRGB(theme.pallete.gray.c20, 0.1)};
    }
  `,
})
