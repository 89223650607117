import { DateRange } from 'bold-ui'
import createDecorator from 'final-form-calculate'
import { metaPath } from 'util/metaPath'
import { ListaAtendimentoFilterPopperFormModel } from 'view/atendimentos/list/filter/ListaAtendimentoFilterPopperForm'

import { STATUS_REGISTRO_FINALIZADO } from '../model'
import { ListaRegistroTardioFilterPopperModel } from './ListaRegistroTardioFilterPopperForm'

const meta = metaPath<ListaRegistroTardioFilterPopperModel>()

const filterStatusAtendimentoNaoFinalizados = (value: boolean, allValues: ListaRegistroTardioFilterPopperModel) => {
  return value && allValues.statusAtendimento?.length > 0
    ? allValues.statusAtendimento.filter((item) => !STATUS_REGISTRO_FINALIZADO.includes(item))
    : allValues.statusAtendimento
}

const adjustPeriodoBasedOnIsSomenteNaoFinalizados = (
  value: boolean,
  allValues: ListaAtendimentoFilterPopperFormModel,
  prevValues: ListaAtendimentoFilterPopperFormModel,
  defaultPeriodo: DateRange
) => {
  if (value) return { startDate: undefined, endDate: undefined }

  if (prevValues.isSomenteNaoFinalizados) return defaultPeriodo

  return allValues.periodo
}

export const createRegistroTardioFilterPopperCalculator = (defaultPeriodo: DateRange) => {
  return createDecorator({
    field: meta.isSomenteNaoFinalizados.absolutePath(),
    updates: {
      [meta.statusAtendimento.absolutePath()]: filterStatusAtendimentoNaoFinalizados,
      [meta.periodo.absolutePath()]: (
        value: boolean,
        allValues: ListaAtendimentoFilterPopperFormModel,
        prevValues: ListaAtendimentoFilterPopperFormModel
      ) => adjustPeriodoBasedOnIsSomenteNaoFinalizados(value, allValues, prevValues, defaultPeriodo),
    },
  })
}
