import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { AntecedentesFormModel } from '../antecedentes/AntecedentesForm'
import { AvaliacaoFormModel } from '../avaliacao/AvaliacaoForm'
import { FinalizacaoAtendimentoFormModel } from '../finalizacao'
import { ObjetivoFormModel } from '../objetivo'
import { PlanoFormModel } from '../plano/PlanoForm'
import { SubjetivoFormModel } from '../subjetivo/SubjetivoForm'

export type SoapFormModel =
  | AntecedentesFormModel
  | SubjetivoFormModel
  | ObjetivoFormModel
  | AvaliacaoFormModel
  | PlanoFormModel
  | FinalizacaoAtendimentoFormModel

interface SoapConfig {
  /**
   * Secoes do SOAP que nao devem aparecer
   */
  hiddenSections: MetaPath<SoapFormModel>[]
}

export const soapConfig = (
  tipoAtendProf: TipoAtendimentoProfissional,
  isObservacaoAndAuxiliarDaObservacao: boolean
): SoapConfig => {
  switch (tipoAtendProf) {
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS: {
      return {
        hiddenSections: [meta.avaliacao],
      }
    }
    default: {
      if (isObservacaoAndAuxiliarDaObservacao) {
        return {
          hiddenSections: [meta.avaliacao],
        }
      }

      return {
        hiddenSections: [],
      }
    }
  }
}
