import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import { UnificacaoBaseCadastrosDuplicadosView } from './UnificacaoBaseCadastrosDuplicadosView'

export default function UnificacaoBaseCadastrosDuplicadosRootView() {
  const match = useRouteMatch()
  return (
    <>
      <Breadcrumb title='Unificação de bases - Cadastros duplicados' />
      <PecSwitch>
        <PrivateRoute
          exact
          path={`${match.url}`}
          component={UnificacaoBaseCadastrosDuplicadosView}
          permission={Permissions.gestaoDeCadastrosDeCidadao.unificacaoDeBases}
        />
      </PecSwitch>
    </>
  )
}
