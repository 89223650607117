/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, Icon, Tooltip } from 'bold-ui'
import { CheckboxField } from 'components/form'
import { Fragment } from 'react'
import { isHttpsConnection } from 'util/https'
import { Meta } from 'util/metaPath'
import { HTTPS_ASSINATURA_DIGITAL_MESSAGE } from 'view/configuracoes/instalacao/conexao/internet/assinatura-digital/model-assinaturaDigital'

interface AtestadoAssinaturaDigitalGerarCheckboxProps {
  name: Meta<boolean>
  hasCid10?: boolean
  emailEditing?: boolean
}

export function AtestadoAssinaturaDigitalGerarCheckbox(props: AtestadoAssinaturaDigitalGerarCheckboxProps) {
  const { name, hasCid10 = false, emailEditing = false } = props

  const disabled = !isHttpsConnection || hasCid10
  const tooltipCheckboxText: string = !isHttpsConnection
    ? HTTPS_ASSINATURA_DIGITAL_MESSAGE
    : hasCid10
    ? 'Atestados digitais não podem conter o CID10 do cidadão.'
    : null

  const checkboxField = (
    <Tooltip text={tooltipCheckboxText}>
      <div css={styles.wrapper}>
        <CheckboxField
          name={name}
          style={styles.checkbox}
          label='Gerar e enviar atestado digital'
          disabled={disabled}
        />
      </div>
    </Tooltip>
  )

  return (
    <Fragment>
      {emailEditing ? (
        <FormControl label={<span>&nbsp;</span>} style={styles.formControl}>
          {checkboxField}
        </FormControl>
      ) : (
        checkboxField
      )}
      <Tooltip text='O atestado digital será assinado digitalmente e enviado ao cidadão no e-mail cadastrado.'>
        <Icon icon='infoCircleFilled' fill='normal' size={1} style={emailEditing ? styles.iconEditing : styles.icon} />
      </Tooltip>
    </Fragment>
  )
}

const styles = {
  icon: css`
    margin: auto 0 auto -0.5rem;
  `,
  iconEditing: css`
    margin: 2rem 0 auto -0.5rem;
  `,
  checkbox: css`
    margin: auto 0;
  `,
  wrapper: css`
    display: flex;
    align-items: flex-end;
  `,
  formControl: css`
    label {
      margin-bottom: 0.5rem;
    }
  `,
}
