import { Cell, Grid, HFlow, Icon, InfoLabel, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { StatusInformation } from 'components/StatusInformation'
import { format } from 'date-fns'
import React from 'react'
import { formatDate } from 'util/date/formatDate'
import {
  Alergia,
  categoriaAlergiaRecord,
  EvolucaoAlergia,
  tipoReacaoAlergiaRecord,
} from 'view/atendimentos/detail/soap/aside/types/AlergiaModel'

import { AlergiaTag } from '../../AlergiaTag'
import { MainDetailAccordionBodyRenderProps } from '../../main-detail-accordion/components/BodyWithDetailAndForm'

interface BodyAlergiaModalProps extends MainDetailAccordionBodyRenderProps<EvolucaoAlergia, Alergia> {}

function atualizouCategoria(alergia: Alergia) {
  return (
    alergia.substanciaEspecifica &&
    alergia.categoriaSubstanciaEspecificaLegado &&
    categoriaAlergiaRecord[alergia.categoriaSubstanciaEspecificaLegado].descricao !==
      categoriaAlergiaRecord[alergia.substanciaEspecifica.categoria].descricao
  )
}

export function BodyAlergiaModal(props: BodyAlergiaModalProps) {
  const { item: alergia, evolucao, nextEvolucao } = props

  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        <HFlow alignItems='center'>
          <HFlow alignItems='center' hSpacing={0.6}>
            <Icon icon='calendarFilled' fill='info' size={1} />
            <Text fontWeight='bold'>
              {!!evolucao.atualizadoEm
                ? format(new Date(evolucao.atualizadoEm), "dd/MM/yyyy 'às' HH:mm")
                : 'Atendimento atual'}
            </Text>
          </HFlow>
          <HFlow hSpacing={0.5}>
            <AlergiaTag type='grauCerteza' enum={evolucao.grauCerteza} />
            <AlergiaTag type='criticidade' enum={evolucao.criticidade} />
          </HFlow>
        </HFlow>
        {nextEvolucao &&
          evolucao.possuiSubstanciaEspecificaCbara &&
          !nextEvolucao.possuiSubstanciaEspecificaCbara &&
          (atualizouCategoria(alergia) ? (
            <StatusInformation text='Atualização da Categoria do agente causador e Agente/substância específica.' />
          ) : (
            <StatusInformation text='Atualização do Agente/substância específica.' />
          ))}
      </VFlow>
      <Grid>
        <Cell size={2}>
          <InfoLabel title='Tipo'>{tipoReacaoAlergiaRecord[evolucao.tipoReacao]?.descricao.capitalize()}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Categoria do agente causador'>
            {categoriaAlergiaRecord[
              evolucao.possuiSubstanciaEspecificaCbara
                ? alergia.substanciaEspecifica?.categoria
                : alergia.categoriaSubstanciaEspecificaLegado
            ]?.descricao.capitalize()}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Agente/substância específica'>
            {evolucao.possuiSubstanciaEspecificaCbara
              ? alergia.substanciaEspecifica?.nome
              : alergia.substanciaEspecificaLegado}
          </InfoLabel>
        </Cell>
        <Cell size={2}>
          <InfoLabel title='Data de início'>{formatDate(evolucao.dataInicio)}</InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title='Manifestações'>
            {evolucao.manifestacoes?.map((m) => m.nome).join(', ') || evolucao.manifestacoesLegado}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title='Observações'>{evolucao.observacao}</InfoLabel>
        </Cell>
      </Grid>
      {evolucao.atendimentoProfissional && (
        <HLabel title='Evoluído por: '>
          {evolucao.atendimentoProfissional.lotacao.profissional.nome.titleCase()}
          {' - '}
          {evolucao.atendimentoProfissional.lotacao.cbo.nome.capitalize()}
        </HLabel>
      )}
    </VFlow>
  )
}
