import { useErrorHandler } from 'components/error'
import { useVisualizarCuidadoCompartilhadoMutation } from 'graphql/hooks.generated'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'
import { JustificativaFormModel, JustificativaModal } from 'view/prontuario/justificativa/modal/JustificativaModal'
import { useJustificativaVerProntuarioId } from 'view/prontuario/justificativa/useJustificativaVerProntuarioId'

export interface CheckJustificativaAcessoCuidadoCompartilhadoProps {
  cidadaoId: ID
  prontuarioId: ID
  cuidadoCompartilhadoId: ID
  isDiscutirCaso: boolean
  basePath: string
  children: JSX.Element
}

export function CheckJustificativaAcessoCuidadoCompartilhado(
  props: CheckJustificativaAcessoCuidadoCompartilhadoProps
): JSX.Element {
  const { children, cidadaoId, prontuarioId, cuidadoCompartilhadoId, basePath, isDiscutirCaso = false } = props

  const history = useHistory()
  const handleRejection = useErrorHandler()
  const [visualizarCuidadoCompartilhadoMutation] = useVisualizarCuidadoCompartilhadoMutation()

  const [isModalOpen, setIsModalOpen] = useState(!isDiscutirCaso)
  const [justificativaId, setJustificativaId, deleteJustificativaId] = useJustificativaVerProntuarioId(
    basePath,
    cidadaoId
  )

  const visualizar = useCallback(
    (values: JustificativaFormModel) => {
      return visualizarCuidadoCompartilhadoMutation({
        variables: {
          input: {
            prontuarioId,
            cuidadoCompartilhadoId,
            isDiscutirCaso,
            justificativa: values?.justificativa,
          },
        },
      })
        .then(({ data: { visualizarCuidadoCompartilhado: justificativaId } }) => {
          setJustificativaId(justificativaId)
          setIsModalOpen(false)
        })
        .catch(handleRejection)
    },
    [
      cuidadoCompartilhadoId,
      handleRejection,
      isDiscutirCaso,
      prontuarioId,
      setJustificativaId,
      visualizarCuidadoCompartilhadoMutation,
    ]
  )

  useEffect(() => {
    if (isDiscutirCaso && isUndefinedOrNull(justificativaId)) {
      visualizar(null)
    }
  }, [isDiscutirCaso, justificativaId, setJustificativaId, visualizar])

  const handleSubmit = (values: JustificativaFormModel) => {
    visualizar(values)
  }

  const handleCancel = () => {
    deleteJustificativaId()
    setIsModalOpen(false)
    history.goBack()
  }

  return justificativaId ? (
    children
  ) : (
    <JustificativaModal
      heading='Justificativa para visualizar a discussão de caso'
      text='Informe uma justificativa para visualizar a discussão de caso:'
      isModalOpen={isModalOpen}
      onConfirm={handleSubmit}
      onCancel={handleCancel}
      onReject={handleCancel}
    />
  )
}
