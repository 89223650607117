/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, Theme, Tooltip, useTheme } from 'bold-ui'
import { ReactComponent as AgendamentoCompartilhadoIcon } from 'images/agenda/calendar-add-filled.svg'

interface AgendaOnlineMarkerProps {
  styles?: ExternalStyles
}

export function AgendamentoCompartilhadoMarker(props: AgendaOnlineMarkerProps) {
  const { styles } = props

  const theme = useTheme()
  const { iconeAgendamentocompartilhado } = createStyles(theme)

  return (
    <Tooltip text='Agendamento entre profissionais'>
      <AgendamentoCompartilhadoIcon css={css(iconeAgendamentocompartilhado, styles)} />
    </Tooltip>
  )
}

const createStyles = (theme: Theme) => ({
  iconeAgendamentocompartilhado: css`
    fill: ${theme.pallete.primary.c50};
    height: 1rem;
    width: 1rem;
    align-self: center;
  `,
})
