import { Link, VFlow } from 'bold-ui'
import { useHasEquipesVinculadasQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { EquipeModel } from 'view/profissional/detail/model'
import { EQUIPES_VINCULADAS_MODAL_URL } from 'view/vinculacao-equipes/EquipesVinculadasModal'
import EquipesVinculadasRootView from 'view/vinculacao-equipes/EquipesVinculadasRootView'

import { EquipeLabel } from './EquipeLabel'

export interface LinkVerEquipesVinculadasViewProps {
  equipe: EquipeModel
}

export function LinkVerEquipesVinculadasView({ equipe }: LinkVerEquipesVinculadasViewProps) {
  const { nome, ine, id } = equipe || {}
  const { url } = useRouteMatch()
  const history = useHistory()

  const {
    data: { hasEquipesVinculadas },
  } = useHasEquipesVinculadasQuery({
    variables: { equipePrincipalId: id },
    skip: !id,
  })

  const handleClick = () => history.push(`${url}${EQUIPES_VINCULADAS_MODAL_URL}`)

  return (
    <VFlow vSpacing={0.25}>
      <EquipeLabel nome={nome || 'Todas as equipes'} ine={ine} />
      {hasEquipesVinculadas && (
        <>
          <Link onClick={handleClick}>Ver equipes vinculadas</Link>
          <EquipesVinculadasRootView equipePrincipal={equipe} />
        </>
      )}
    </VFlow>
  )
}
