/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Heading, Text, VFlow } from 'bold-ui'
import { AtendimentoProfissionalOdonto, TipoParteBucalEnum } from 'graphql/types.generated'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { historicoSubsectionStyles } from 'view/atendimentos/detail/historico/model/model-historico'

import { HistoricoOdontogramaPanel } from './odontograma/HistoricoOdontogramaPanel'
import { HistoricoPeriogramaPanel } from './periograma/HistoricoPeriogramaPanel'
import { HistoricoTecidosMolesPanel } from './tecidos-moles/HistoricoTecidosMolesPanel'

interface HistoricoEvolucoesOdontologicasPanelProps {
  atendProfOdonto: AtendimentoProfissionalOdonto
}

export default function HistoricoEvolucoesOdontologicasPanel(props: HistoricoEvolucoesOdontologicasPanelProps) {
  const {
    atendProfOdonto: {
      odontograma,
      evolucoes,
      tecidosMoles,
      periogramaSimplificado,
      periogramaCompleto,
      observacaoRPC,
    },
  } = props

  const evolucoesOdontologicas = useMemo(
    () =>
      compact(
        evolucoes?.filter((evolucao) =>
          evolucao.partesBucais?.every(
            (parteBucal) =>
              parteBucal.tipoParteBucal !== TipoParteBucalEnum.TECIDO_MOLE &&
              parteBucal.tipoParteBucal !== TipoParteBucalEnum.SEXTANTE
          )
        )
      ),
    [evolucoes]
  )

  const evolucoesPeriodontais = useMemo(
    () =>
      compact(
        evolucoes?.filter((evolucao) =>
          evolucao.partesBucais?.every((parteBucal) => parteBucal.tipoParteBucal === TipoParteBucalEnum.SEXTANTE)
        )
      ),
    [evolucoes]
  )

  const hasEvolucoesOdontologicas = odontograma || evolucoesOdontologicas?.length > 0
  const hasTecidosMoles = tecidosMoles?.length > 0
  const hasEvolucoesPeriodontais = evolucoesPeriodontais?.length > 0 || periogramaSimplificado || periogramaCompleto

  const hasEvolucoes = hasEvolucoesOdontologicas || hasTecidosMoles || hasEvolucoesPeriodontais

  return (
    <VFlow vSpacing={hasEvolucoes ? 0.5 : 0}>
      <Heading level={3} style={historicoSubsectionStyles.plano}>
        Evoluções odontológicas
      </Heading>

      {!hasEvolucoes && <Text fontStyle='italic'>Não foram realizadas evoluções odontológicas neste atendimento.</Text>}

      {hasEvolucoesOdontologicas && (
        <HistoricoOdontogramaPanel odontograma={odontograma} evolucoes={evolucoesOdontologicas} />
      )}

      {hasTecidosMoles && <HistoricoTecidosMolesPanel evolucoes={tecidosMoles} />}

      {hasEvolucoesPeriodontais && (
        <HistoricoPeriogramaPanel
          evolucoes={evolucoesPeriodontais}
          periogramaSimplificado={periogramaSimplificado}
          periogramaCompleto={periogramaCompleto}
          observacaoRPC={observacaoRPC}
        />
      )}
    </VFlow>
  )
}
