import { Button, Cell, FormControl, Grid } from 'bold-ui'
import { Form, MunicipioSelectField, MunicipioSelectModel } from 'components/form'
import { ProfissionalSelectModel } from 'components/form/field'
import { FormApi } from 'final-form'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'

import { MunicipioResponsavelProfissionalSelect } from './MunicipioResponsavelProfissionalSelect'

export default interface MunicipioResponsavelFormModel {
  profissional: ProfissionalSelectModel
  localidade: MunicipioSelectModel
}

const validator = createValidator<MunicipioResponsavelFormModel>({
  localidade: [required],
  profissional: [required],
})

const meta = metaPath<MunicipioResponsavelFormModel>()

export interface MunicipioResponsavelFormProps {
  initialValues?: Partial<MunicipioResponsavelFormModel>
  onSubmit(values: MunicipioResponsavelFormModel, formApi: FormApi): void
}

export function MunicipioResponsavelForm(props: MunicipioResponsavelFormProps) {
  const renderForm = (formProps: FormRenderProps) => {
    const { values, handleSubmit } = formProps

    return (
      <Grid gap={1}>
        <Cell xs={12} md={4}>
          <MunicipioSelectField name={meta.localidade} label='Município' required />
        </Cell>
        <Cell xs={12} md={4}>
          <MunicipioResponsavelProfissionalSelect name={meta.profissional} localidade={values.localidade} />
        </Cell>
        <Cell xs={12} md={4}>
          <FormControl label={<span>&nbsp;</span>}>
            <Button size='small' kind='primary' onClick={handleSubmit}>
              Salvar
            </Button>
          </FormControl>
        </Cell>
      </Grid>
    )
  }

  return <Form render={renderForm} validate={validator} {...props} />
}
