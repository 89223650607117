/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert } from 'bold-ui'

export const VideochamadaAlert = ({ children }: { children: React.ReactNode }) => (
  <div css={stylesAlert.container}>
    <Alert inline type='warning' styles={{ wrapper: stylesAlert.infoWrapper }}>
      {children}
    </Alert>
  </div>
)

const stylesAlert = {
  container: css`
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  `,
  infoWrapper: css`
    padding: 0;
    background: none;
    border: none;
    min-height: 0;
  `,
}
