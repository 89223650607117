import { SelectField } from 'components/form'
import { EtniaSelectFieldDocument } from 'graphql/hooks.generated'
import { EtniaSelectFieldQuery, EtniaSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type EtniaSelectFieldModel = Partial<EtniaSelectFieldQuery['etnias']['content'][0]>

export function EtniaSelectField(props: AsyncSelectFieldProps<EtniaSelectFieldModel>) {
  const { selectProps } = useAsyncQuerySelect<
    EtniaSelectFieldModel,
    EtniaSelectFieldQuery,
    EtniaSelectFieldQueryVariables
  >({
    query: EtniaSelectFieldDocument,
    extractItems: (data) => data && data.etnias && data.etnias.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: EtniaSelectFieldModel) => item && item.nome

  return <SelectField<EtniaSelectFieldModel> itemToString={itemToString} {...selectProps} {...props} />
}
