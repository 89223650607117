/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Text, useStyles } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import { FilterItemProps } from 'components/filteritem/FilterItem'

// TODO Royal Flush - Aplicar correções no componente FilterItem para corrigir nos outros módulos (excluir esse) #17213
export function BuscaAtivaFilterItem(props: FilterItemProps) {
  const { label, value, style, textStyle } = props
  const { css: styles } = useStyles()
  const classes = createStyles()

  return (
    <Cell style={[classes.separator, style]}>
      {label && <Text style={classes.label}>{label}</Text>}

      <Ellipsis
        className={styles([textStyle])}
        style={[classes.value]}
        tooltipStyle={css`
          max-width: 70vw;
        `}
      >
        {value}
      </Ellipsis>
    </Cell>
  )
}

const createStyles = () => ({
  // TODO Royal Flush - Verificar tamanho dinâmico do max-width de cada item com a contagem dos filtros #17213
  separator: css`
    display: inline-flex;
    align-items: baseline;
    max-width: calc(100% / 10 * 3.5);

    &:after {
      display: block;
      content: '|';
      margin-left: 0.25rem;
    }

    &:last-child:after {
      content: none;
      max-width: 100%;
    }
  `,

  label: css`
    font-weight: bold;
    white-space: nowrap;
    margin-right: 0.25rem;
  `,

  value: css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
})
