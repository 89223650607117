import { Alert } from 'bold-ui'
import * as React from 'react'

export interface InlineErrorListProps {
  error: any
}

export function InlineErrorList(props: InlineErrorListProps) {
  const { error } = props

  if (!error) {
    return null
  }

  if (typeof error.message === 'string' || typeof error === 'string') {
    return (
      <Alert type='danger' inline={true}>
        {error.message || error}
      </Alert>
    )
  }

  if (error.networkError) {
    return (
      <Alert type='danger' inline={true}>
        {error.networkError}
      </Alert>
    )
  }

  return (
    <>
      {error?.graphQLErrors.map((graphqlError, index) => (
        <Alert key={index} type='danger' inline={true}>
          {graphqlError.message}
        </Alert>
      ))}
    </>
  )
}
