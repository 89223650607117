import { Cell, Grid, Tag, Text } from 'bold-ui'
import React from 'react'
import { LembreteModel, visibilidadeLembrete } from 'view/atendimentos/detail/soap/aside/types/LembreteModel'

export const renderDescricaoLembrete = (row: LembreteModel) => (
  <Grid gap={0} gapVertical={0} alignItems='center' wrap={false}>
    <Cell size={12}>
      <Text>{row.historicoLembrete?.[0].descricao.removeTags().truncate(100)}</Text>
    </Cell>
  </Grid>
)

export const renderVisibilidadeLembrete = (row: LembreteModel) => (
  <Grid gap={0} gapVertical={0} alignItems='center' wrap={false}>
    <Cell size={5}>{visibilidadeLembrete[row.historicoLembrete?.[0].visibilidadeLembrete]}</Cell>
  </Grid>
)

export const renderInativoLembrete = (row: LembreteModel) => (
  <Grid gap={0} gapVertical={0} alignItems='center' wrap={false}>
    <Cell size={3}>{!row.historicoLembrete?.[0].ativo && <Tag>Inativo</Tag>}</Cell>
  </Grid>
)
