import { Heading, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { Breadcrumb } from 'components/breadcrumb'
import { PageLoading } from 'components/loading'
import { startOfDay } from 'date-fns'
import { css } from 'emotion'
import { useIdadeGestacionalQuery } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { dateAsYyyyMmDd } from 'util/date/formatDate'

import { CuidadoCompartilhadoHeaderTabs } from '../components/CuidadoCompartilhadoHeaderTabs'
import { DiscussaoCasoPageContent } from '../components/DiscussaoCasoPageContent'
import { CuidadoCompartilhadoEvolucoes } from '../cuidado-compartilhado/components/cuidado-compartilhado-evolucoes/CuidadoCompartilhadoEvolucoes'
import { CuidadoCompartilhadoPanel } from '../cuidado-compartilhado/components/CuidadoCompartilhadoPanel'
import { CheckJustificativaAcessoCuidadoCompartilhado } from '../cuidado-compartilhado/justificativa/CheckJustificativaCuidadoCompartilhado'
import { CidadaoCuidadoCompartilhado, CuidadoCompartilhadoModel } from '../model-cuidadoCompartilhado'

interface VisualizarCuidadoCompartilhadoViewProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
}

export const VisualizarCuidadoCompartilhadoView = (props: VisualizarCuidadoCompartilhadoViewProps) => {
  const { cuidadoCompartilhado } = props
  const match = useRouteMatch()
  const { getServerTimeNow } = useServerTime()

  const prontuario = cuidadoCompartilhado.cidadao.prontuario
  const dataAtendimento = Number(startOfDay(getServerTimeNow()))
  const preNatalAtivo = prontuario?.preNatalAtivo
  const ultimaDumPreNatalAtivo = preNatalAtivo?.ultimaDum

  const { loading: loadingIdadeGestacional, data: dataIdadeGestacional } = useIdadeGestacionalQuery({
    variables: {
      input: {
        prontuarioId: prontuario.id,
        dataAtendimento: dateAsYyyyMmDd(dataAtendimento),
        dum: ultimaDumPreNatalAtivo,
      },
    },
    skip: !prontuario.id || !ultimaDumPreNatalAtivo,
  })
  const idadeGestacional = dataIdadeGestacional?.idadeGestacional

  if (loadingIdadeGestacional) {
    return <PageLoading message='Carregando dados do cuidado compartilhado...' />
  }

  const renderDiscussaoCaso = (cidadao: CidadaoCuidadoCompartilhado, headerHeight: number) => (
    <CheckJustificativaAcessoCuidadoCompartilhado
      cidadaoId={cidadao.id}
      prontuarioId={cuidadoCompartilhado.cidadao.prontuario.id}
      cuidadoCompartilhadoId={cuidadoCompartilhado.id}
      isDiscutirCaso={false}
      basePath={match.path.substring(0, match.path.lastIndexOf('/'))}
    >
      <DiscussaoCasoPageContent cidadao={cidadao} headerHeight={headerHeight} idadeGestacional={idadeGestacional}>
        <VFlow>
          <CuidadoCompartilhadoPanel cuidadoCompartilhado={cuidadoCompartilhado} />

          <VFlow vSpacing={0.5}>
            <Heading level={3}>Discussão</Heading>

            <Box
              style={css`
                padding: 1rem 0;
              `}
            >
              <CuidadoCompartilhadoEvolucoes cuidadoCompartilhadoId={cuidadoCompartilhado.id} />
            </Box>
          </VFlow>
        </VFlow>
      </DiscussaoCasoPageContent>
    </CheckJustificativaAcessoCuidadoCompartilhado>
  )

  return (
    <>
      <Breadcrumb title='Visualizar discussão' />
      <CuidadoCompartilhadoHeaderTabs
        cuidadoCompartilhado={cuidadoCompartilhado}
        renderDiscussaoCaso={renderDiscussaoCaso}
        isVisualizacao={true}
        idadeGestacional={idadeGestacional}
      />
    </>
  )
}
