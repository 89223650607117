/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { isString } from 'lodash'
import { cloneElement, Fragment, useMemo } from 'react'

interface IconTextCardProps {
  icon?: React.ReactElement
  iconColor?: string
  label?: string
  value?: string | React.ReactElement
  labelValueDirection?: 'row' | 'column'
  isAlertPresentInLine?: boolean
}

export const IconTextCard = (props: IconTextCardProps) => {
  const { icon, iconColor = gray.c40, label, value, labelValueDirection = 'row', isAlertPresentInLine = false } = props
  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme, labelValueDirection, isAlertPresentInLine), [
    isAlertPresentInLine,
    labelValueDirection,
    theme,
  ])
  return (
    <Fragment>
      {value && (
        <HFlow hSpacing={0.5}>
          {cloneElement(icon, {
            style: { color: `${iconColor}`, fill: `${iconColor}`, width: '1.5rem', height: '1.5rem' },
          })}
          <div css={classes.container}>
            {label && <Text css={classes.title}>{`${label}`}</Text>}
            {isString(value) ? <Text css={classes.content}>{value}</Text> : value}
          </div>
        </HFlow>
      )}
    </Fragment>
  )
}

const createStyles = (theme: Theme, labelValueDirection: 'row' | 'column', isAlertPresentInLine: boolean) => ({
  title: css`
    font-weight: bold;
    margin-right: 0.375rem;
    ${theme.breakpoints.up('lg')} {
      &:after {
        content: ':';
      },
    }`,
  content: css`
    ${theme.breakpoints.down('lg')} {
      display: block;
    }
  `,
  container: css`
    display: grid;
    grid-auto-flow: ${labelValueDirection};
    ${theme.breakpoints.down('lg')} {
      grid-auto-flow: row;
    }
    align-items: ${labelValueDirection === 'column' && isAlertPresentInLine ? 'baseline' : 'flex-start'};
    margin-top: ${labelValueDirection === 'column' && isAlertPresentInLine ? '0.2rem' : '0.25rem'};
  `,
})
