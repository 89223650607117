/** @jsx jsx */

import { css, jsx } from '@emotion/core'
import { Heading, HFlow } from 'bold-ui'
import theme from 'config/theme'

export const LoginSeparator = () => (
  <HFlow hSpacing={0.5} alignItems='center' style={styles.hFlow}>
    <hr css={styles.hr} />

    <Heading level={4} style={styles.heading}>
      Ou
    </Heading>

    <hr css={styles.hr} />
  </HFlow>
)

const styles = {
  hFlow: css`
    grid-auto-columns: 1fr auto 1fr;
  `,
  heading: css`
    font-weight: 400;
  `,
  hr: css`
    margin: 0;
    background: ${theme.pallete.gray.c80};
  `,
}
