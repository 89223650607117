import useSession from 'components/auth/useSession'
import { useLocalStorageState } from 'hooks/useStorage'

import { ConfiguracoesVideochamadaModel, DispositivoMidiaSelectModel } from '../model-videochamada'

export function useConfiguracoesVideochamada(): [
  ConfiguracoesVideochamadaModel,
  (value: ConfiguracoesVideochamadaModel) => void
] {
  const { data: session } = useSession()

  const [configuracoesVideochamada, setConfiguracoesVideochamada] = useLocalStorageState<
    ConfiguracoesVideochamadaModel
  >(`${session?.profissional?.id ?? ''}/configuracoes-videochamada`)

  return [configuracoesVideochamada, setConfiguracoesVideochamada]
}

export function useStoredAudioDevice(): [
  DispositivoMidiaSelectModel,
  (audioDevice: DispositivoMidiaSelectModel) => void
] {
  const { data: session } = useSession()

  const [storedAudioDevice, setStoredAudioDevice] = useLocalStorageState<DispositivoMidiaSelectModel>(
    `${session?.profissional?.id ?? ''}/dispositivo-audio-videochamada`
  )

  return [storedAudioDevice, setStoredAudioDevice]
}

export function useStoredVideoDevice(): [
  DispositivoMidiaSelectModel,
  (videoDevice: DispositivoMidiaSelectModel) => void
] {
  const { data: session } = useSession()

  const [storedVideoDevice, setStoredVideoDevice] = useLocalStorageState<DispositivoMidiaSelectModel>(
    `${session?.profissional?.id ?? ''}/dispositivo-video-videochamada`
  )

  return [storedVideoDevice, setStoredVideoDevice]
}
