import { Omit, Switch, SwitchProps } from 'bold-ui'
import React from 'react'

import { UseFieldProps, usePecField } from '../hooks/useField'

export type SwitchFieldProps = UseFieldProps<boolean> & Omit<SwitchProps, 'name'>

export function SwitchField(props: SwitchFieldProps) {
  const { input, meta, ...rest } = usePecField({ type: 'checkbox', ...props })

  return <Switch {...input} {...rest} />
}
