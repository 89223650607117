import { DropdownItem, Tooltip } from 'bold-ui'
import { alert } from 'components/alert'
import { DropdownButton } from 'components/dropdown/DropdownButton'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import {
  useAtualizarMunicipioResponsavelMutation,
  useExcluirMunicipioResponsavelMutation,
} from 'graphql/hooks.generated'
import React from 'react'

import { RowType } from './MunicipiosResponsaveisTable'

export interface MunicipiosResponsaveisDropdownProps {
  row: RowType
  onUpdate(): void
}

export function MunicipiosResponsaveisDropdown(props: MunicipiosResponsaveisDropdownProps) {
  const { row, onUpdate } = props

  const handleRejection = useErrorHandler()
  const [excluir] = useExcluirMunicipioResponsavelMutation()
  const [atualizar] = useAtualizarMunicipioResponsavelMutation()

  const handleExcluir = () => {
    confirm({
      title: `Deseja excluir esta relação de Município e Responsável?`,
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return excluir({ variables: { id: row.id } })
          .then((res) => {
            alert('info', `O acesso de Administrador Municipal foi removido do profissional ${row.profissional.nome}.`)
            return res
          })
          .finally(() => onUpdate())
          .catch(handleRejection)
      },
    })()
  }

  const handleAlternar = () => {
    return atualizar({ variables: { input: { id: row.id, ativo: !row.ativo } } })
      .then((ret) => {
        let str: string = 'removido do'
        if (!row.ativo) {
          str = 'adicionado ao'
        }
        alert('success', `O acesso Administrador municipal foi ${str} profissional ${row.profissional.nome}.`)
        return ret
      })
      .finally(() => onUpdate())
      .catch(handleRejection)
  }

  return (
    <DropdownButton>
      <DropdownItem onClick={handleAlternar}>{row.ativo ? 'Inativar' : 'Ativar'}</DropdownItem>
      <Tooltip text={row.habilitado && 'Responsável que já foi habilitado não pode ser excluído.'}>
        <DropdownItem disabled={row.habilitado} onClick={handleExcluir} type='danger'>
          Excluir
        </DropdownItem>
      </Tooltip>
    </DropdownButton>
  )
}
