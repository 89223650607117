/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { AccordionField } from 'components/form/field/AccordionField'
import { AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'
import { MetaArray } from 'util/metaPath'

import { AntecedentesFormAccordionButton } from './AntecedentesFormAccordionButton'

export interface FormSectionAccordionProps {
  title: string
  children: React.ReactNode
  uuid: string
  name: MetaArray<string | number>
  dataAtualizacao: Instant
}

export function AntecedentesFormSectionAccordion(props: FormSectionAccordionProps) {
  const { title, children, name, dataAtualizacao, uuid } = props
  const theme = useTheme()
  const classes = createStyles(theme)

  return (
    <div css={classes.marginDiv}>
      <AccordionField name={name} allowZeroExpanded>
        <AccordionItem uuid={uuid}>
          <AntecedentesFormAccordionButton text={title} style={classes.button} dataAtualizacao={dataAtualizacao} />
          <AccordionItemPanel css={classes.panel}>{children}</AccordionItemPanel>
        </AccordionItem>
      </AccordionField>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  panel: css`
    border: 1px ${theme.pallete.gray.c60} solid;
    border-top-width: 0;
    padding: 1rem;
  `,
  button: css`
    border: 1px ${theme.pallete.gray.c60} solid;
    background-color: ${theme.pallete.gray.c90};
    &:hover {
      background-color: ${theme.pallete.gray.c80};
    }
  `,
  animation: css`
    margin-top: -1px;
  `,
  marginDiv: css`
    margin: 0.3rem 0;
  `,
})
