/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Alert, VFlow } from 'bold-ui'
import { MetaPath } from 'util/metaPath'

import { PrenatalPartoNascimentoModel } from '../model'
import { PrenatalPartoNascimentoForm } from './components/PrenatalPartoNascimentoForm'

export interface PrenatalPartoNascimentoProps {
  name: MetaPath<PrenatalPartoNascimentoModel>
  hasAtendimentoPuericultura: boolean
}

export default function PrenatalPartoNascimento(props: PrenatalPartoNascimentoProps) {
  const { name, hasAtendimentoPuericultura } = props

  return (
    <VFlow>
      {hasAtendimentoPuericultura ? (
        <Alert type='info' inline>
          Pré-natal, parto e nascimento já informados.
        </Alert>
      ) : (
        <PrenatalPartoNascimentoForm name={name} />
      )}
    </VFlow>
  )
}
