/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, IconProps, Icons, Link, Text, Tooltip } from 'bold-ui'
import { ColorScale } from 'bold-ui/lib/styles/colors'

export interface StatusSincronizacaoAgendaOnlineProps {
  icon: Icons
  color: ColorScale
  text: string
  tooltip?: string
  style?: IconProps['style']
  expanded?: boolean
  link?: { text: string; url: string }
}

export function StatusSincronizacaoAgendaOnline({
  color,
  icon,
  text,
  expanded = false,
  style,
  tooltip,
  link,
}: StatusSincronizacaoAgendaOnlineProps) {
  return expanded ? (
    <HFlow hSpacing={0} alignItems='center'>
      <Icon icon={icon} color={color.c40} size={1} style={css(styles.icon, style)} />
      <Text
        style={css`
          color: ${color.c40};
        `}
      >
        {text}.{' '}
        {link && (
          <Link href={link.url} target='_blank'>
            {link.text}.
          </Link>
        )}
      </Text>
    </HFlow>
  ) : (
    <Tooltip text={tooltip ?? text}>
      <Icon icon={icon} color={color.c40} size={1} style={css(styles.icon, style)} />
    </Tooltip>
  )
}

const styles = {
  icon: css`
    align-self: center;
    margin-right: 0.5rem;
  `,
}
