/** @jsx jsx * */
import { css, jsx } from '@emotion/core'
import { Button, Checkbox, Heading, HFlow, Modal, ModalBody, ModalFooter, ModalProps, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import { HLabel } from 'components/HLabel'
import { useEffect, useMemo, useRef, useState } from 'react'

export interface Termo {
  titulo: string
  subtitulo?: string
  itens: ReadonlyArray<string>
  acceptText: string
}
export type TermosRecord<TTiposTermos extends string | number | symbol> = Record<TTiposTermos, Termo>

interface TermoModalProps<TTiposTermos extends string | number | symbol> extends ModalProps {
  onAccept?(autorizacoes: Record<TTiposTermos, boolean>): void
  termos: TermosRecord<TTiposTermos>
  nextText?: string
  prevText?: string
  confirmText?: string
  readonly?: boolean
}

export function TermoModal<TTiposTermos extends string | number | symbol>(props: TermoModalProps<TTiposTermos>) {
  const {
    open,
    onClose,
    onAccept,
    termos,
    nextText = 'Continuar',
    prevText = 'Anterior',
    confirmText = 'Aceitar termos',
    style: externalStyles,
    readonly = false,
    ...rest
  } = props

  const totalPaginas = Object.keys(termos).length - 1
  const paginasTermo = useMemo(() => Object.keys(termos) as TTiposTermos[], [termos])
  const [indexPaginaAtual, setIndexPaginaAtual] = useState(0)
  const initialCheckboxValues = useMemo(
    () =>
      paginasTermo.reduce((acc, cur) => {
        acc[cur] = false
        return acc
      }, {} as Record<TTiposTermos, boolean>),
    [paginasTermo]
  )
  const [checkboxValues, setCheckboxValues] = useState(initialCheckboxValues)
  const refTermos = useRef<HTMLDivElement>()

  useEffect(() => {
    !open && setCheckboxValues(initialCheckboxValues)
  }, [initialCheckboxValues, open, termos])

  const paginaAtual = paginasTermo[indexPaginaAtual]
  const termoPaginaAtual = termos[paginaAtual]

  const nextPageOrAccept = () => {
    if (indexPaginaAtual < totalPaginas) {
      setIndexPaginaAtual(indexPaginaAtual + 1)
      refTermos.current?.scrollIntoView()
    } else {
      setIndexPaginaAtual(0)
      onAccept?.(checkboxValues)
    }
  }

  const prevPage = () => {
    if (indexPaginaAtual > 0) {
      setIndexPaginaAtual(indexPaginaAtual - 1)
      refTermos.current?.scrollIntoView()
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        setIndexPaginaAtual(0)
        onClose?.()
      }}
      size='large'
      style={css(styles.modal, externalStyles)}
      {...rest}
    >
      <ModalBody style={styles.scrollTerm}>
        <VFlow vSpacing={1}>
          <div ref={refTermos}>
            <Heading
              level={1}
              style={css`
                text-align: center;
                padding-bottom: ${!termoPaginaAtual.subtitulo && '1.85rem'};
              `}
            >
              {termoPaginaAtual.titulo}
            </Heading>
            {termoPaginaAtual.subtitulo && (
              <Heading
                level={2}
                style={css`
                  text-align: center;
                `}
              >
                {termoPaginaAtual.subtitulo}
              </Heading>
            )}
          </div>
          <VFlow vSpacing={1}>
            {termoPaginaAtual.itens?.map((termo, index) => (
              <HLabel title={`${index + 1} -`} key={index}>
                {termo}
              </HLabel>
            ))}
            {!readonly && (
              <Box>
                <Checkbox
                  onChange={() =>
                    setCheckboxValues((prev) => {
                      return { ...prev, [paginaAtual]: !checkboxValues[paginaAtual] }
                    })
                  }
                  checked={checkboxValues[paginaAtual]}
                  label={<RequiredLabel inline fontWeight='bold' label={termoPaginaAtual.acceptText} />}
                />
              </Box>
            )}
          </VFlow>
        </VFlow>
      </ModalBody>
      {(!readonly || totalPaginas > 1) && (
        <ModalFooter
          style={css`
            width: 100%;
          `}
        >
          <HFlow justifyContent='flex-end' alignItems='center'>
            {totalPaginas > 0 && (
              <Text>
                <Text fontWeight='bold'>{indexPaginaAtual + 1} </Text>
                de
                <Text fontWeight='bold'> {totalPaginas + 1} </Text>
                termos obrigatórios
              </Text>
            )}
            {indexPaginaAtual > 0 && readonly && (
              <Button onClick={prevPage} kind='primary'>
                {prevText}
              </Button>
            )}
            {(indexPaginaAtual < totalPaginas || !readonly) && (
              <Button disabled={!checkboxValues[paginaAtual]} onClick={nextPageOrAccept} kind='primary'>
                {indexPaginaAtual < totalPaginas ? nextText : confirmText}
              </Button>
            )}
          </HFlow>
        </ModalFooter>
      )}
    </Modal>
  )
}

const styles = {
  scrollTerm: css`
    overflow-y: auto;
    width: 100%;
    padding-top: 0;
    flex: 1;
  `,
  modal: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,
}
