import { Button, Icon, Tooltip } from 'bold-ui'
import { alert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useExcluirTipoServicoMutation } from 'graphql/hooks.generated'
import React from 'react'

import { TipoServicoItem } from '../TipoServicoTable'

export interface TipoServicoRemoveComponentProps {
  tipoServico: TipoServicoItem
  onRemove(): void
}

export function TipoServicoRemoveComponent(props: TipoServicoRemoveComponentProps) {
  const { tipoServico, onRemove } = props

  const [excluir] = useExcluirTipoServicoMutation()
  const errorHandler = useErrorHandler()

  const handleClick = confirm({
    title: `Deseja excluir o tipo de serviço?`,
    type: 'danger',
    confirmLabel: 'Excluir',
    onConfirm: () =>
      excluir({ variables: { id: tipoServico.id } })
        .then((success) => {
          alert('success', 'Tipo de serviço excluído com sucesso.')
          onRemove()
        })
        .catch(errorHandler),
  })

  return (
    <Tooltip text='Excluir'>
      <Button size='small' skin='ghost' onClick={handleClick}>
        <Icon icon='trashOutline' />
      </Button>
    </Tooltip>
  )
}
