import { Button, Cell, Grid, HFlow, Omit } from 'bold-ui'
import { alert } from 'components/alert'
import { Box } from 'components/Box'
import { Form, SelectField, SelectFieldProps } from 'components/form'
import { FormPrompt } from 'components/form/FormPrompt'
import { useAlterarNumeroTentativasLoginMutation } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, required } from 'util/validation'

export interface ConfiguracoesNumTentativasBoxProps {
  loginNumeroTentativas: number
  refetch(): any
}

const validator = createValidator<FormModel>({
  tentativas: [required],
})

export function ConfiguracoesNumTentativasBox(props: ConfiguracoesNumTentativasBoxProps) {
  const { loginNumeroTentativas, refetch } = props

  const [alterarNumeroTentativasLogin] = useAlterarNumeroTentativasLoginMutation()

  const initialValues = useMemo(
    () =>
      loginNumeroTentativas && {
        tentativas: {
          id: loginNumeroTentativas,
          label: numMaxTentativaLoginItems.find((item) => item.id === loginNumeroTentativas).label,
        },
      },
    [loginNumeroTentativas]
  )

  const handleSubmit = (values: FormModel) => {
    return alterarNumeroTentativasLogin({ variables: { tentativas: values.tentativas.id } }).then(() => {
      alert('success', 'Quantidade máxima de tentativas de login alterada com sucesso.')
      refetch()
    })
  }

  const renderForm = (formProps: FormRenderProps<FormModel>) => {
    return (
      <>
        <FormPrompt />
        <HFlow hSpacing={1}>
          <Grid>
            <Cell>
              <NumTentativasSelectField
                label='Quantidade máxima de tentativas de login'
                name='tentativas'
                style={{ width: 287 }}
                clearable={false}
              />
            </Cell>
          </Grid>
          <Grid>
            <Cell>
              <Button
                size='small'
                onClick={formProps.handleSubmit}
                style={{ marginTop: '1.5rem' }}
                kind='primary'
                disabled={!formProps.values.tentativas}
              >
                Salvar
              </Button>
            </Cell>
          </Grid>
        </HFlow>
      </>
    )
  }

  return (
    <Box>
      <Form<FormModel> onSubmit={handleSubmit} render={renderForm} validate={validator} initialValues={initialValues} />
    </Box>
  )
}

interface FormModel {
  tentativas: ItemType
}

export function NumTentativasSelectField(props: Omit<SelectFieldProps<ItemType>, 'items' | 'itemToString'>) {
  const itemToString = (item: ItemType) => item && item.label

  return <SelectField<ItemType> items={numMaxTentativaLoginItems} itemToString={itemToString} {...props} />
}

interface ItemType {
  id: number
  label: string
}

export const numMaxTentativaLoginItems: ItemType[] = Array(10)
  .fill({})
  .map((e, i) => {
    i++
    if (i === 10) {
      return { id: i, label: String(i) + ' (opção padrão)' }
    }
    return { id: i, label: String(i) }
  })
