import qs from 'qs'
import { useMemo } from 'react'
import { useLocation } from 'react-router'

export function useUrlQueryParams<T extends Partial<Record<keyof T, string>>>(): T {
  const { search } = useLocation()

  return useMemo<T>(
    () =>
      qs.parse(search, {
        ignoreQueryPrefix: true,
      }),
    [search]
  )
}
