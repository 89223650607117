/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ButtonProps, ExternalStyles, Icon, Icons, Theme, useTheme } from 'bold-ui'
import { ButtonKind } from 'bold-ui/lib/components/Button/Button/ButtonSkins'
import { Fragment, memo, ReactElement } from 'react'

import { ReactComponent as NotificationCircle } from '../../../images/novidades/icons/notification_circle.svg'

type SwitchButtonState = 'on' | 'off'

export interface SwitchButtonProps extends ButtonProps {
  state: SwitchButtonState
  iconOn: Icons | ReactElement
  iconOff?: Icons | ReactElement
  kindOn?: ButtonKind
  kindOff?: ButtonKind
  styleOn?: ExternalStyles
  styleOff?: ExternalStyles
  defaultState?: SwitchButtonState
  disabled?: boolean
  hasNotification?: boolean
}

export function SwitchButton(props: SwitchButtonProps) {
  const {
    defaultState = 'off',
    state,
    iconOn,
    iconOff = iconOn,
    kindOn = defaultState === 'on' ? 'normal' : 'primary',
    kindOff = defaultState === 'on' ? 'danger' : 'normal',
    styleOn: externalStyleOn,
    styleOff: externalStyleOff,
    disabled = false,
    hasNotification = false,
    ...rest
  } = props

  const theme = useTheme()
  const styles = createStyles(theme, defaultState)

  return (
    <Button
      kind={state === 'on' ? kindOn : kindOff}
      style={[
        styles.button,
        ...(state === 'on' ? [styles.styleOn, externalStyleOn] : [styles.styleOff, externalStyleOff]),
      ]}
      disabled={disabled}
      size='small'
      {...rest}
    >
      <SwitchButtonIcon icon={state === 'on' && !disabled ? iconOn : iconOff} />
      {!!hasNotification && <NotificationCircle css={styles.notification} />}
    </Button>
  )
}

const SwitchButtonIcon = memo(({ icon }: { icon: Icons | React.ReactNode }) =>
  typeof icon === 'string' ? <Icon size={1.5} color='white' icon={icon as Icons} /> : <Fragment>{icon}</Fragment>
)

const createStyles = (theme: Theme, defaultState: SwitchButtonState) => ({
  button: css`
    border: none;
    padding: 0.5rem;
  `,
  styleOn:
    defaultState === 'on'
      ? css`
          background-color: ${theme.pallete.gray.c30};
          &:not(:disabled):hover {
            background-color: ${theme.pallete.gray.c20};
          }
        `
      : css`
          background-color: ${theme.pallete.primary.c40};
          &:not(:disabled):hover {
            background-color: ${theme.pallete.primary.c30};
          }
        `,
  styleOff:
    defaultState !== 'on' &&
    css`
      background-color: ${theme.pallete.gray.c30};
      &:not(:disabled):hover {
        background-color: ${theme.pallete.gray.c20};
      }
    `,
  notification: css`
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
  `,
})
