import { Link } from 'bold-ui'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const EncarregadoSection = () => {
  return (
    <TermosDeUsoHeadingSection title='5. Encarregado' id='encarregado'>
      <p>
        <b>
          Quem é o responsável por atuar como canal de comunicação entre o controlador, os titulares dos dados e a
          Autoridade Nacional de Proteção de Dados (Encarregado)?
        </b>
      </p>
      <br />
      <p>
        A Lei Geral de Proteção de Dados Pessoais define como encarregado, em seu Art. 5º, inciso VIII, a pessoa
        indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos
        dados e a Autoridade Nacional de Proteção de Dados (ANPD).
      </p>
      <br />
      <p>
        O encarregado de dados do Controlador Ministério da Saúde e, consequentemente, para o Prontuário Eletrônico do
        Cidadão (PEC) do Sistema e-SUS APS, o responsável por atuar como canal de comunicação entre o controlador, os
        titulares dos dados e a Autoridade Nacional de Proteção de Dados é a encarregada Adriana Macedo Marques,
        conforme a Portaria GM/MS nº 953, de 11 de maio de 2023.
      </p>
      <br />
      <p>
        O usuário poderá entrar em contato por meio do e-mail
        <Link fontSize={0.875} href='mailto:encarregado@saude.gov.br'>
          {' '}
          encarregado@saude.gov.br
        </Link>{' '}
        ou{' '}
        <Link fontSize={0.875} href='mailto:adriana.mmarques@saude.gov.br'>
          adriana.mmarques@saude.gov.br
        </Link>{' '}
        do Encarregado, para sanar quaisquer dúvidas sobre esta Política de Privacidade ou para obter mais informações
        sobre o tratamento dos dados realizado com fundamento na LGPD.
      </p>
    </TermosDeUsoHeadingSection>
  )
}
