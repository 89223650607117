import ReactPDF, { pdf } from '@react-pdf/renderer'
import useSession from 'components/auth/useSession'
import { PromiseErrorContextValue } from 'components/error'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { generatePdf } from 'util/generatePdf'

import { PecPrintPageLayout, PecPrintPageLayoutProps } from './layout/PecPrintPageLayout'
import { PrinterType } from './model'

interface PrintDocumentBody extends Omit<PecPrintPageLayoutProps, 'serverTime'> {
  docBody: React.ReactElement
}

export const usePrinter = (session?: PrinterType) => {
  const { data } = useSession({ fetchPolicy: 'cache-only' })
  const { getServerTimeNow } = useServerTime()

  const printPDF = (
    documentBody: PrintDocumentBody,
    handleRejection: PromiseErrorContextValue,
    audit?: Function,
    signatureDate?: Date
  ) => {
    const document = renderDocument(
      documentBody,
      session ?? data,
      data.profissional.nome,
      getServerTimeNow(),
      signatureDate
    )

    pdf(document)
      .toBlob()
      .then((blob) => {
        generatePdf(blob, 'pdf')
      })
      .then(() => {
        audit && audit()
      })
      .catch((error) => {
        handleRejection(error)
      })
  }
  return { printPDF }
}

const renderDocument = (
  documentBody: PrintDocumentBody,
  session: PrinterType,
  nameFooter: string,
  serverTime: Date,
  signatureDate?: Date
): React.ReactElement<ReactPDF.DocumentProps> => {
  const { docBody, ...rest } = documentBody
  return (
    <PecPrintPageLayout
      session={session}
      nameFooter={nameFooter}
      serverTime={serverTime}
      signatureDate={signatureDate}
      {...rest}
    >
      {docBody}
    </PecPrintPageLayout>
  )
}
