import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'

export interface EstadoCivilSelectModel {
  id: ID
  nome: string
}

export interface EstadoCivilSelectFieldProps
  extends Omit<SelectFieldProps<EstadoCivilSelectModel>, 'items' | 'itemToString'> {}

export function EstadoCivilSelectField(props: EstadoCivilSelectFieldProps) {
  const itemToString = (item: EstadoCivilSelectModel) => item && item.nome

  return (
    <SelectField<EstadoCivilSelectModel>
      items={items.sort((a, b) => (a.nome > b.nome ? 1 : -1))}
      itemToString={itemToString}
      {...props}
    />
  )
}

const items: EstadoCivilSelectModel[] = [
  { id: 1, nome: 'SOLTEIRO' },
  { id: 2, nome: 'CASADO' },
  { id: 3, nome: 'VIÚVO' },
  { id: 4, nome: 'SEPARADO JUDICIALMENTE' },
  { id: 5, nome: 'UNIÃO CONSENSUAL' },
]
