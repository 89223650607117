import { Alert, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { useConfiguracoesAtencaoDomQuery } from 'graphql/hooks.generated'
import React from 'react'

import ConfiguracoesADApoioTable from './ConfiguracoesADApoioTable'
import ConfiguracoesADDuracaoAgendamentoADForm from './ConfiguracoesADDuracaoAgendamentoADForm'
import ConfiguracoesADPodeAtenderTable from './ConfiguracoesADPodeAtenderTable'

export default function ConfiguracoesADView() {
  const { data, refetch } = useConfiguracoesAtencaoDomQuery()

  return (
    <>
      <Breadcrumb title='Configurações AD' />
      <PageHeaderSection title='Configurações AD'></PageHeaderSection>

      <PageContent>
        <Alert type='info' inline style={{ marginBottom: '1rem' }}>
          As configurações realizadas nesta tela afetam diretamente na duração de agendamentos na agenda AD bem como a
          sincronização com aplicativo e-SUS Atenção domiciliar. O cadastro de EMADs apoiadas por EMAPs e EMADs que
          podem atender cidadãos de outras EMADs interferem no vínculo do cidadão com sua respectiva equipe e a
          possibilidade de realização de atendimentos por outras equipes.
        </Alert>

        <ConfiguracoesADDuracaoAgendamentoADForm duracao={data?.configuracoesAtencaoDom?.duracao} reload={refetch} />

        <VFlow style={{ marginBottom: '2rem' }} vSpacing={0.5}>
          <PageHeader title='EMADs apoiadas por uma EMAP' />
          <ConfiguracoesADApoioTable />
        </VFlow>

        <VFlow vSpacing={0.5}>
          <PageHeader title='EMADs que podem atender cidadãos de outras EMADs' />
          <ConfiguracoesADPodeAtenderTable />
        </VFlow>
      </PageContent>
    </>
  )
}
