/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, isEmpty, Text, VFlow } from 'bold-ui'
import { green, red, turquoise } from 'bold-ui/lib/styles/colors'
import { NeuroAlteracaoFenotipicaEvolucao, NeuroFatorRiscoEvolucao, StatusAvaliadoEnum } from 'graphql/types.generated'
import { Fragment, useEffect, useState } from 'react'
import { stringGrouper } from 'util/string/string-grouper'

interface HistoricoNeuroPuericulturaStatusProps {
  neuroAlteracaoFenotipicaElements?: NeuroAlteracaoFenotipicaEvolucao[]
  neuroFatorRiscoEvolucaoElements?: NeuroFatorRiscoEvolucao[]
}

export default function HistoricoNeuroPuericulturaStatus(props: HistoricoNeuroPuericulturaStatusProps) {
  const { neuroAlteracaoFenotipicaElements, neuroFatorRiscoEvolucaoElements } = props

  const [neuroStatusPresenteAlteracaoFenotipica, setNeuroStatusPresenteAlteracaoFenotipica] = useState([])
  const [neuroStatusAusenteAlteracaoFenotipica, setNeuroStatusAusenteAlteracaoFenotipica] = useState([])
  const [neuroStatusPresenteFatoresRisco, setNeuroStatusPresenteFatoresRisco] = useState([])
  const [neuroStatusAusenteFatoresRisco, setNeuroStatusAusenteFatoresRisco] = useState([])

  useEffect(() => {
    if (neuroAlteracaoFenotipicaElements) {
      const statusPresente = neuroAlteracaoFenotipicaElements
        .filter((element) => element.statusAvaliacao === StatusAvaliadoEnum.PRESENTE)
        .map((detalhe: NeuroAlteracaoFenotipicaEvolucao) => detalhe.alteracaoFenotipicaDetalhe?.descricao)

      setNeuroStatusPresenteAlteracaoFenotipica(statusPresente)

      const statusAusente = neuroAlteracaoFenotipicaElements
        .filter((element) => element.statusAvaliacao === StatusAvaliadoEnum.AUSENTE)
        .map((detalhe: NeuroAlteracaoFenotipicaEvolucao) => detalhe.alteracaoFenotipicaDetalhe?.descricao)

      setNeuroStatusAusenteAlteracaoFenotipica(statusAusente)
    }
    if (neuroFatorRiscoEvolucaoElements) {
      const statusPresente = neuroFatorRiscoEvolucaoElements
        .filter((element) => element.statusAvaliacao === StatusAvaliadoEnum.PRESENTE)
        .map((detalhe: NeuroFatorRiscoEvolucao) => detalhe.fatorRiscoDetalhe?.descricao)

      setNeuroStatusPresenteFatoresRisco(statusPresente)

      const statusAusente = neuroFatorRiscoEvolucaoElements
        .filter((element) => element.statusAvaliacao === StatusAvaliadoEnum.AUSENTE)
        .map((detalhe: NeuroFatorRiscoEvolucao) => detalhe.fatorRiscoDetalhe?.descricao)

      setNeuroStatusAusenteFatoresRisco(statusAusente)
    }
  }, [neuroAlteracaoFenotipicaElements, neuroFatorRiscoEvolucaoElements])

  return (
    <Fragment>
      {!isEmpty(neuroAlteracaoFenotipicaElements) && (
        <VFlow vSpacing={0}>
          <Heading
            level={5}
            style={css`
              color: ${turquoise.c40};
            `}
          >
            Alterações fenotípicas
          </Heading>
          {!isEmpty(neuroStatusPresenteAlteracaoFenotipica) && (
            <HFlow hSpacing={0.5}>
              <Text
                style={css`
                  color: ${green.c40};
                `}
                fontWeight='bold'
              >
                Presente:
              </Text>
              <Text>{stringGrouper(...neuroStatusPresenteAlteracaoFenotipica)}</Text>
            </HFlow>
          )}
          {!isEmpty(neuroStatusAusenteAlteracaoFenotipica) && (
            <HFlow hSpacing={0.5}>
              <Text
                style={css`
                  color: ${red.c40};
                `}
                fontWeight='bold'
              >
                Ausente:
              </Text>
              <Text>{stringGrouper(...neuroStatusAusenteAlteracaoFenotipica)}</Text>
            </HFlow>
          )}
        </VFlow>
      )}

      {!isEmpty(neuroFatorRiscoEvolucaoElements) && (
        <VFlow vSpacing={0}>
          <Heading
            level={5}
            style={css`
              color: ${turquoise.c40};
              padding-top: 0.5rem;
            `}
          >
            Fatores de Risco
          </Heading>
          {!isEmpty(neuroStatusPresenteFatoresRisco) && (
            <HFlow hSpacing={0.5}>
              <Text
                style={css`
                  color: ${green.c40};
                `}
                fontWeight='bold'
              >
                Presente:
              </Text>
              <Text>{stringGrouper(...neuroStatusPresenteFatoresRisco)}</Text>
            </HFlow>
          )}
          {!isEmpty(neuroStatusAusenteFatoresRisco) && (
            <HFlow hSpacing={0.5}>
              <Text
                style={css`
                  color: ${red.c40};
                `}
                fontWeight='bold'
              >
                Ausente:
              </Text>
              <Text>{stringGrouper(...neuroStatusAusenteFatoresRisco)}</Text>
            </HFlow>
          )}
        </VFlow>
      )}
    </Fragment>
  )
}
