import { HFlow, Text } from 'bold-ui'
import { SessaoDataFragment } from 'graphql/types.generated'
import React from 'react'
import { tipoAcesso } from 'types/enums'

import { AcessoEstagioCard } from './AcessoEstagioCard'
import { AcessoLotacaoCard } from './AcessoLotacaoCard'
import { Card } from './Card'
import { CardHeading } from './CardHeading'

export interface AcessoProps {
  acesso: SessaoDataFragment['profissional']['acessos'][0]
  onClick(acessoId: ID): void
}

export const Acesso = (props: AcessoProps) => {
  const { acesso, onClick } = props
  const handleClick = () => {
    onClick(acesso.id)
  }

  if (acesso.__typename === 'Lotacao') {
    return <AcessoLotacaoCard acesso={acesso} onClick={handleClick} />
  }

  if (acesso.__typename === 'Estagio') {
    return <AcessoEstagioCard acessoId={acesso.id} onClick={handleClick} />
  }

  return (
    <Card onClick={handleClick}>
      {acesso.__typename === 'AdministradorGeral' && <CardHeading>Administrador da Instalação</CardHeading>}

      {(acesso.__typename === 'AdministradorMunicipal' || acesso.__typename === 'GestorMunicipal') && (
        <CardHeading>{acesso.municipio.nome}</CardHeading>
      )}

      {acesso.__typename === 'GestorEstadual' && <CardHeading>{acesso.uf.nome}</CardHeading>}
      {acesso.__typename !== 'AdministradorGeral' && (
        <HFlow hSpacing={0.5} style={{ marginTop: '0.5rem' }}>
          <Text fontWeight='bold'>Tipo</Text>
          <Text>{tipoAcesso[acesso.tipo]}</Text>
        </HFlow>
      )}
    </Card>
  )
}
