import React from 'react'
import { masks, onlyNumbers } from 'util/mask'

import { MaskedField, MaskedFieldProps } from '../final-form/MaskedField'

export interface TelefoneFieldProps extends MaskedFieldProps {}

export function TelefoneField(props: TelefoneFieldProps) {
  return <MaskedField mask={masks.telefone} placeholder='(00) 00000-0000' parse={onlyNumbers} {...props} />
}
