/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Paper, Text, useTheme, VFlow } from 'bold-ui'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { calculateDpp } from 'util/date/calculateDpp'
import { dateAsDdMmYyyy, toDate } from 'util/date/formatDate'

export function DppPaper() {
  const {
    cidadao: { ultimaDum },
  } = useAtendimentoContext()

  const theme = useTheme()

  if (!ultimaDum?.dum) {
    return null
  }

  const { dpp, humanizedDpp } = calculateDpp(toDate(ultimaDum?.dum))

  return (
    <Paper
      style={css`
        border: 0;
        background: ${theme.pallete.surface.background};
        padding: 0.5rem;
      `}
    >
      <VFlow vSpacing={0.25}>
        <Text fontWeight='bold'>DPP</Text>
        <Text>{`${dateAsDdMmYyyy(dpp)} (${humanizedDpp})`}</Text>
      </VFlow>
    </Paper>
  )
}
