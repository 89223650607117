import { Alert, Button, Cell, Grid, HFlow, Link, TextField, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import copy from 'copy-to-clipboard'
import React from 'react'
import { useState } from 'react'

interface InformacaoContraChaveCertificadoComponentProps {
  contraChave: string
}

export const InformacaoContraChaveCertificadoComponent = (props: InformacaoContraChaveCertificadoComponentProps) => {
  const alert = useAlert()
  const [contraChaveState] = useState<string>(props.contraChave)

  const copyOnClick = () => {
    copy(props.contraChave)
    alert('success', 'Contra-chave copiada com sucesso.')
  }

  return (
    <>
      <VFlow>
        <Alert type='info' inline>
          <span>É necessário copiar a contra-chave para gerar o certificado no </span>
          <Link href='https://servicos-datasus.saude.gov.br/' target='_blank'>
            e-Gestor AB
          </Link>
        </Alert>
        <Grid wrap>
          <Cell size={5}>
            <HFlow alignItems='center'>
              <TextField label='Contra-chave' disabled={true} value={contraChaveState} />
              <Button size='small' type='button' onClick={copyOnClick} style={{ marginTop: 24 }}>
                Copiar
              </Button>
            </HFlow>
          </Cell>
        </Grid>
      </VFlow>
    </>
  )
}
