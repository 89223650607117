import { FormState } from 'final-form'
import { useFormState } from 'react-final-form'

export interface FormBlurSpyProps<T> {
  onChange(values: T): void
}

/**
 * Invoca o callback sempre que um campo do formulário recebe o evento de blur ou os valores mudem.
 * Chama o `props.onBlur` sempre que o field ativo mudar para `undefined`.
 */
export function FormValueSpy<T>(props: FormBlurSpyProps<T>) {
  const { onChange } = props

  useFormState<T>({
    subscription: { dirty: true, active: true, values: true },
    onChange: ({ pristine, active, values }: FormState<T>) => {
      if (!pristine && active === undefined) {
        onChange(values)
      }
    },
  })

  return null
}
