import { HeadingSection, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import { useLoadHorarioGeracaoLotesProcessamentoFichasQuery } from 'graphql/hooks.generated'
import moment from 'moment'
import * as React from 'react'
import { TransmissaoEnvioTable } from 'view/transmissao/components/envio/table/TransmissaoEnvioTable'

export const TransmissaoEnvioGeralView = () => {
  const { data } = useLoadHorarioGeracaoLotesProcessamentoFichasQuery()

  return (
    <>
      <VFlow vSpacing={2}>
        <Box>
          <VFlow vSpacing={0.5}>
            <Text>
              A próxima geração de lotes ocorrerá às{' '}
              <DateTime
                value={moment(data.loadHorarioGeracaoLotesProcessamentoFichas, 'HH:mm:ss')}
                format='H[h]mm[min]'
              />
              . Os lotes gerados são enviados automaticamente para as instalações de destino.
            </Text>
          </VFlow>
        </Box>

        <HeadingSection level={2} title='Lotes gerados para envio' vSpace={0.5}>
          <TransmissaoEnvioTable />
        </HeadingSection>
      </VFlow>
    </>
  )
}
