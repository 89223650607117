import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'

export function sexoByIdentidadeGenero(sexo: SexoEnum, idGenero?: IdentidadeGeneroEnum) {
  if (idGenero) {
    switch (idGenero) {
      case IdentidadeGeneroEnum.MULHER_TRANS:
      case IdentidadeGeneroEnum.TRAVESTI:
        return SexoEnum.MASCULINO
      case IdentidadeGeneroEnum.HOMEM_TRANS:
        return SexoEnum.FEMININO
      case IdentidadeGeneroEnum.OUTRO_IDENTIDADE_GENERO:
        return sexo
    }
  }
  return sexo
}
