import { SelectHelperMenuItem, useLocale } from 'bold-ui'
import React from 'react'

export function ProcedimentoSelectFieldEmptyItem(skipping: boolean, loadItemsOnOpen: boolean) {
  const locale = useLocale()

  if (!skipping) {
    return <SelectHelperMenuItem>{locale.select.emptyItem}</SelectHelperMenuItem>
  } else if (!loadItemsOnOpen) {
    return <SelectHelperMenuItem>{'Digite para buscar um procedimento.'}</SelectHelperMenuItem>
  }
  return null
}
