import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'

export class Transmissao {
  importarFichas(formData: FormData, config?: AxiosRequestConfig): AxiosPromise<void> {
    return axios.post('/api/transmissao/importarFichas', formData, config)
  }
  gerarRelatorioInconsistencia(formData: FormData): AxiosPromise<Blob> {
    return axios.post('/api/transmissao/gerarRelatorioInconsistencia', formData, { responseType: 'blob' })
  }
  baixarZip(formData: FormData): AxiosPromise<Blob> {
    return axios.post(`/api/transmissao/exportacaoArquivo`, formData, { responseType: 'blob' })
  }
}
