/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, TableCell, TableRow, Text, Tooltip } from 'bold-ui'
import { DateTime } from 'components/date'
import { ParteBucalEnum, TipoParteBucalEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { Observacao } from 'view/atendimentos/detail/components/Observacao'

import {
  renderLocalEvolucao,
  renderProcedimentoEvolucao,
} from '../historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTableRow'
import { EvolucoesOdontoTableRowActions } from './EvolucoesOdontoTableRowActions'
import { EvolucaoOdontoTableItemModel } from './model'

interface EvolucoesOdontoTableRowProps {
  row: EvolucaoOdontoTableItemModel
  isEditingDenteOrArcada: boolean
  onEditDenteOrArcada(parteBucal: ParteBucalEnum, tipoParteBucal: TipoParteBucalEnum): void
  onRemove: (id: ID, tipoParteBucal: TipoParteBucalEnum) => void
}

export const EvolucoesOdontoTableRow = (props: EvolucoesOdontoTableRowProps) => {
  const { row, isEditingDenteOrArcada, onEditDenteOrArcada, onRemove } = props

  const hasObservacao = !isUndefinedOrNull(row.observacoes)
  const classes = styles(hasObservacao)

  return (
    <Fragment>
      <TableRow>
        <TableCell style={classes.cell}>
          <HFlow hSpacing={0.25}>
            <DateTime value={row.lastUpdate} format='DD/MM/YYYY' />

            {row.isRegistradoAgora && (
              <Tooltip text='Adicionado agora'>
                <Icon size={1} icon='clockOutline' fill='primary' />
              </Tooltip>
            )}
          </HFlow>
        </TableCell>
        <TableCell style={classes.cell}>
          {renderLocalEvolucao({
            partesBucais: row.partesBucais,
            descricaoParteBucal: row.descricaoParteBucal,
          })}
        </TableCell>
        <TableCell style={classes.cell}>{renderProcedimentoEvolucao(row.procedimentos)}</TableCell>
        <TableCell style={classes.cell}>
          {row.isRegistradoAgora && (
            <EvolucoesOdontoTableRowActions
              row={row}
              isEditingDenteOrArcada={isEditingDenteOrArcada}
              onEditDenteOrArcada={onEditDenteOrArcada}
              onRemove={onRemove}
            />
          )}
        </TableCell>
      </TableRow>

      {hasObservacao && (
        <TableRow>
          <TableCell colSpan={4} style={classes.profissionalOrObservacaoCell}>
            <HFlow hSpacing={0.2}>
              <Text fontWeight='bold'>Observações: </Text>
              <Text fontStyle='italic' style={classes.wordBreakAll}>
                <div dangerouslySetInnerHTML={{ __html: row.observacoes }} />
              </Text>
            </HFlow>
            <Observacao />
          </TableCell>
        </TableRow>
      )}

      {!row.isRegistradoAgora && (
        <TableRow>
          <TableCell colSpan={4} style={classes.profissionalOrObservacaoCell}>
            <HFlow hSpacing={0.2}>
              <Text fontWeight='bold'>Profissional: </Text>
              <Text style={classes.wordBreakAll}>{`${row.lotacao.profissional.nome} - ${row.lotacao.cbo.nome}`}</Text>
            </HFlow>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}

const styles = (hasObservacao: boolean) => ({
  cell: css`
    padding: 1.25rem 1rem;
    ${hasObservacao && 'border-bottom: 0;'}
  `,
  profissionalOrObservacaoCell: css`
    height: auto;
    border-top: 0;
    padding: 0.5rem 1rem;
  `,
  wordBreakAll: css`
    word-break: break-all;
  `,
})
