/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, InfoLabel, isEmpty, Text, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import {
  CheckboxField,
  DateField,
  ErrorField,
  HorarioAgendaSelectField,
  TelefoneField,
  TextAreaField,
  TextField,
} from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { Telefone } from 'components/label'
import { useFlags } from 'config/useFlagsContext'
import { addMonths, parseISO } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'
import { InserirVideochamadaAgendamentoSection } from 'view/agenda/components/InserirVideochamadaAgendamentoSection'
import {
  CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER,
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoSolicitanteFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { getLotacoesConsultaCompartilhadaCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'
import { useVideochamadasConfiguration } from 'view/videochamada/hooks/useVideochamadasConfiguration'

import { AlertCidadaoNaoAceitouCC } from '../AlertCidadaoNaoAceitouCC'
import { EnviarGarantiaAcessoCuidadoCompartilhadoField } from './EnviarGarantiaAcessoCuidadoCompartilhadoField'

interface AgendarCunsultaCompartilhadaCuidadoCompartilhadoPanelProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
}

export const AgendarCunsultaCompartilhadaCuidadoCompartilhadoPanel = (
  props: AgendarCunsultaCompartilhadaCuidadoCompartilhadoPanelProps
) => {
  const { path, cuidadoCompartilhado, videochamadaUuidState } = props

  const { PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED } = useFlags()

  const { getServerTimeNow } = useServerTime()
  const { acesso: lotacao } = useAcessoLotacaoOrEstagio()
  const serverTime = getServerTimeNow()
  const { loading: loadingConfiguracoesVideochamada, videochamadasEnabled } = useVideochamadasConfiguration()
  const [videochamadaUuid, setVideochamadaUuid] = videochamadaUuidState

  const {
    input: { value: agendarCuidadoValue },
  } = usePecField({ name: path.agendamento, subscription: { value: true } })

  const { lotacaoConvidada, lotacaoResponsavel } = getLotacoesConsultaCompartilhadaCuidadoCompartilhado(
    lotacao.id,
    cuidadoCompartilhado
  )

  const cidadaoHasTelefone = !isUndefinedOrNull(cuidadoCompartilhado.cidadao.telefoneCelular)
  const cidadaoHasEmail = !isUndefinedOrNull(cuidadoCompartilhado.cidadao.email)

  const isCheckedCidadaoPresenteConsultaCompartilhada = !!agendarCuidadoValue?.consultaCompartilhada?.isCidadaoPresente
  const isProfissionalExecutante = cuidadoCompartilhado.lotacaoExecutanteAtual.id === lotacaoResponsavel.id
  const areFlagsEnabledAndIsExecutante = PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && isProfissionalExecutante

  const hasDataAndHorarioAgendamento =
    !isEmpty(agendarCuidadoValue.consultaCompartilhada.data) &&
    !isEmpty(agendarCuidadoValue.consultaCompartilhada.horario)

  return (
    <VFlow>
      <ErrorField name={FORM_ERROR} inline={false} type='alert' />
      <ErrorField name={path.agendamento.consultaCompartilhada} inline={false} type='alert' ignoreObjectError />
      <Grid>
        <Cell size={6} lg={3}>
          <DateField
            name={path.agendamento.consultaCompartilhada.data}
            label='Data do agendamento'
            minDate={serverTime}
            maxDate={addMonths(serverTime, 12)}
            clearable
            required
          />
        </Cell>

        <Cell size={6} lg={3}>
          <HorarioAgendaSelectField
            name={path.agendamento.consultaCompartilhada.horario}
            lotacaoId={lotacao.id}
            outraLotacaoIdVerificarDisponibilidade={lotacaoConvidada?.id}
            dia={
              agendarCuidadoValue.consultaCompartilhada?.data
                ? parseISO(agendarCuidadoValue.consultaCompartilhada.data)
                : undefined
            }
            disabled={!agendarCuidadoValue.consultaCompartilhada?.data}
            labels={{ inicial: 'Horário do agendamento' }}
            required
          />
        </Cell>

        {areFlagsEnabledAndIsExecutante && (
          <Cell size={6} lg={3}>
            <EnviarGarantiaAcessoCuidadoCompartilhadoField
              name={path.garantiaAcesso}
              pathCondutaGroup={path.condutaGroup}
              tipoAgendamento={CondutaCuidadoCompartilhadoEnum.AGENDADO_INTERCONSULTA_COM_CIDADAO}
              cidadaoAceitaAtendTic={cuidadoCompartilhado.cidadaoAceitaAtendTic}
            />
          </Cell>
        )}

        <HFlow alignItems='center' hSpacing={0.25}>
          <Cell size={12}>
            <VFlow vSpacing={0}>
              <HFlow alignItems='center' hSpacing={0.25}>
                <Text fontSize={0.875} fontWeight='bold'>
                  Dados do profissional convidado
                </Text>
              </HFlow>
              <Text>
                {lotacaoConvidada.profissional.nome} | {lotacaoConvidada.cbo.nome.capitalize()} |{' '}
                {lotacaoConvidada.equipe?.nome}
              </Text>
            </VFlow>
          </Cell>

          {(videochamadasEnabled || loadingConfiguracoesVideochamada) && (
            <Cell size={12}>
              <InserirVideochamadaAgendamentoSection
                videochamadaUuid={videochamadaUuid}
                onVideochamadaUuidChange={setVideochamadaUuid}
                onRemoverVideochamadaClick={() => setVideochamadaUuid(null)}
                disabled={!hasDataAndHorarioAgendamento}
                tooltipTextDisabled='Defina uma data e horário de agendamento para habilitar a opção de adicionar videochamada'
              />
            </Cell>
          )}
        </HFlow>

        <Cell size={12}>
          <InfoLabel title='E-mail'>{lotacaoConvidada?.profissional.email ?? '-'}</InfoLabel>
        </Cell>

        <Cell size={12}>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} fontWeight='bold'>
              Dados do cidadão
            </Text>

            <CheckboxField
              name={path.agendamento.consultaCompartilhada.isCidadaoPresente}
              label={<Text fontWeight='bold'>Cidadão estará presente na consulta compartilhada</Text>}
              style={styles.checkbox}
            />
          </VFlow>
        </Cell>

        <Cell size={6} lg={cidadaoHasEmail ? 3 : 6}>
          {cidadaoHasEmail ? (
            <InfoLabel title='E-mail do cidadão'>{cuidadoCompartilhado.cidadao.email}</InfoLabel>
          ) : (
            <TextField
              lowercase
              name={path.agendamento.consultaCompartilhada.cidadaoParticipante.contato.email}
              label='E-mail do cidadão'
              maxLength={50}
              disabled={!isCheckedCidadaoPresenteConsultaCompartilhada}
            />
          )}
        </Cell>

        <Cell size={6}>
          {cidadaoHasTelefone ? (
            <InfoLabel title='Telefone celular do cidadão'>
              <Telefone value={cuidadoCompartilhado.cidadao.telefoneCelular} />
            </InfoLabel>
          ) : (
            <TelefoneField
              name={path.agendamento.consultaCompartilhada.cidadaoParticipante.contato.telefoneCelular}
              label='Telefone celular do cidadão'
              disabled={!isCheckedCidadaoPresenteConsultaCompartilhada}
            />
          )}
        </Cell>

        {!cuidadoCompartilhado.cidadaoAceitaAtendTic && (
          <Cell size={12}>
            <AlertCidadaoNaoAceitouCC />
          </Cell>
        )}

        <Cell size={12}>
          <TextAreaField
            label='Observações'
            name={path.agendamento.consultaCompartilhada.observacoes}
            placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
            maxLength={200}
            style={styles.textArea}
          />
        </Cell>
      </Grid>
    </VFlow>
  )
}

const styles = {
  checkbox: css`
    margin-left: -0.25rem;
  `,
  textArea: css`
    resize: vertical;
  `,
  textMotivo: css`
    word-break: break-all;
  `,
}
