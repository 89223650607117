import { Icon } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { ButtonLink } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'

import PerfilTable from './PerfilTable'

export default function PerfilListView() {
  const { match } = useRouter()

  return (
    <>
      <PageHeaderSection
        title='Perfis'
        action={
          <CheckPermission permission={Permissions.visualizarPerfis.cadastrarEditarEExcluir}>
            <ButtonLink to={`${match.url}/cadastro`} kind='primary'>
              <Icon icon='plus' style={{ marginRight: '0.5rem' }} />
              Cadastrar perfil
            </ButtonLink>
          </CheckPermission>
        }
      />

      <PageContent>
        <PerfilTable />
      </PageContent>
    </>
  )
}
