import React from 'react'
import { masks, onlyNumbers } from 'util/mask'

import { MaskedField, MaskedFieldProps } from '../final-form/MaskedField'

export interface CnpjFieldProps extends MaskedFieldProps {}

export function CnpjField(props: CnpjFieldProps) {
  return <MaskedField mask={masks.cnpj} placeholder='00.000.000/0000-00' parse={onlyNumbers} {...props} />
}
