/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme, useTheme } from 'bold-ui'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment } from 'react'
import { Field } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { CondutaGroupEnum, CondutaGroupRecord } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

interface CondutaGroupCuidadoCompartilhadoRadioFieldContainerProps<T> {
  name: MetaPath<T>
  pathCondutaGroup: MetaPath<CondutaGroupEnum>
  children: React.ReactNode
  value: CondutaGroupEnum
}

export function CondutaGroupCuidadoCompartilhadoRadioFieldContainer<T>(
  props: CondutaGroupCuidadoCompartilhadoRadioFieldContainerProps<T>
) {
  const { name, pathCondutaGroup, children, value } = props

  const { getServerTimeNow } = useServerTime()
  const theme = useTheme()
  const styles = createStyles(theme)

  const {
    input: { value: currentConduta },
  } = usePecField({ name: pathCondutaGroup, subscription: { value: true } })

  const isSelected = currentConduta === value
  const { label, validator } = CondutaGroupRecord[value]

  return (
    <Field<T>
      name={name.absolutePath()}
      validate={isSelected && validator(getServerTimeNow())}
      render={() => {
        return (
          <Fragment>
            <RadioFieldContainer name={pathCondutaGroup} value={value} style={styles.radioFieldContainer}>
              <Text fontWeight='bold'>{label}</Text>
            </RadioFieldContainer>
            {isSelected && <div css={styles.paper}>{children}</div>}
          </Fragment>
        )
      }}
    />
  )
}

const createStyles = (theme: Theme) => ({
  radioFieldContainer: css`
    background-color: ${theme.pallete.gray.c90};
  `,
  paper: css`
    padding: 1rem;
    border: 1px solid ${theme.pallete.gray.c60};
    border-radius: 0 0 2px 2px;
    margin-top: -1px;
  `,
})
