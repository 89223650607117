import { CaracteristicaLesaoEnum, HistoricoTecidosMolesQuery } from 'graphql/types.generated'

export type HistoricoTecidosMolesQueryModel = HistoricoTecidosMolesQuery['tecidosMoles']['content'][0]

export interface TecidosMolesTableItemModel {
  isRegistradoAgora: boolean
  lastUpdate: Instant
  lotacao: {
    id: ID
    profissional: { id: ID; nome: string }
    cbo: { id: ID; nome: string }
  }
  parteBucal: string
  searchProcedimentos: string
  cid10?: { id: ID; codigo: string; nome: string }
  procedimentos?: Array<{ id: ID; codigo: string; descricao: string }>
  caracteristicasLesao?: CaracteristicaLesaoEnum[]
  observacaoProcedimentos?: string
  descricaoSintomatologia?: string
  cacheId?: ID
}

export interface TecidoMoleParteBucalModel {
  title: string
}

export interface TecidoMoleCaracteristicaLesaoModel {
  title: string
}

export interface TipoLesaoFundamentalModel {
  title: string
  lesaoEnvolvidaTitle: string
  lesoesEnvolvidas: CaracteristicaLesaoEnum[]
}

export interface TipoCaracteristicaLesaoModel {
  title?: string
  items: CaracteristicaLesaoEnum[]
}

export enum TipoCaracteristicaLesao {
  QUANTIDADE = 'QUANTIDADE',
  TAMANHO = 'TAMANHO',
  BASE = 'BASE',
  COR = 'COR',
  SINTOMATOLOGIA = 'SINTOMATOLOGIA',
  CONSISTENCIA = 'CONSISTENCIA',
  SUPERFICIE = 'SUPERFICIE',
  LESOES_ENVOLVIDAS = 'LESOES_ENVOLVIDAS',
}

export enum TipoLesaoFundamental {
  PLANA = 'PLANA',
  LIQUIDA = 'LIQUIDA',
  TECIDUAL = 'TECIDUAL',
}

export const URL_EDITAR_TECIDOS_MOLES = '/procedimentos-tecidos-moles-e-duros/editar/'

export function getTecidosMolesCaracteristicaLesao(value: CaracteristicaLesaoEnum): TecidoMoleCaracteristicaLesaoModel {
  return caracteristicasLesao[value]
}

export function getLesoesFundamentais(): TipoLesaoFundamental[] {
  return Object.keys(tiposLesoesEnvolvidas) as TipoLesaoFundamental[]
}

export function getTipoLesaoFundamental(tipo: TipoLesaoFundamental): TipoLesaoFundamentalModel {
  return tiposLesoesEnvolvidas[tipo]
}

export function getTipoCaracteristicaLesao(value: TipoCaracteristicaLesao): TipoCaracteristicaLesaoModel {
  return tiposCaracteristicaLesao[value]
}

export function getLesaoFundamentalByLesaoEnvolvida(lesaoEnvolvida: CaracteristicaLesaoEnum): TipoLesaoFundamental {
  return (
    lesaoEnvolvida &&
    (Object.entries(tiposLesoesEnvolvidas) as [TipoLesaoFundamental, TipoLesaoFundamentalModel][]).find((lesao) =>
      lesao[1].lesoesEnvolvidas.includes(lesaoEnvolvida)
    )[0]
  )
}

export function extractCaracteristicaLesaoByTipo(
  tipo: TipoCaracteristicaLesao,
  items?: CaracteristicaLesaoEnum[]
): CaracteristicaLesaoEnum {
  return items?.find((caracteristica) => getTipoCaracteristicaLesao(tipo).items.includes(caracteristica))
}

const caracteristicasLesao: Record<CaracteristicaLesaoEnum, TecidoMoleCaracteristicaLesaoModel> = {
  [CaracteristicaLesaoEnum.LESAO_PLANA_MACULA]: {
    title: 'Mácula/mancha',
  },
  [CaracteristicaLesaoEnum.LESAO_PLANA_PLACA]: {
    title: 'Placa',
  },
  [CaracteristicaLesaoEnum.LESAO_PLANA_PAPULA]: {
    title: 'Pápula/nódulo',
  },
  [CaracteristicaLesaoEnum.LESAO_PLANA_TUMEFACAO]: {
    title: 'Tumefação/edema',
  },
  [CaracteristicaLesaoEnum.LESAO_PLANA_PSEUDOMEMBRANA]: {
    title: 'Pseudomembrana',
  },
  [CaracteristicaLesaoEnum.LESAO_LIQUIDA_VESICULA]: {
    title: 'Vesícula/bolha',
  },
  [CaracteristicaLesaoEnum.LESAO_LIQUIDA_PUSTULA]: {
    title: 'Pústula',
  },
  [CaracteristicaLesaoEnum.LESAO_TECIDUAL_EROSAO]: {
    title: 'Erosão',
  },
  [CaracteristicaLesaoEnum.LESAO_TECIDUAL_ULCERA]: {
    title: 'Úlcera',
  },
  [CaracteristicaLesaoEnum.LESAO_TECIDUAL_FISSURA]: {
    title: 'Fissura',
  },
  [CaracteristicaLesaoEnum.QUANTIDADE_UNICA]: {
    title: 'Única',
  },
  [CaracteristicaLesaoEnum.QUANTIDADE_BILATERAL]: {
    title: 'Bilateral',
  },
  [CaracteristicaLesaoEnum.QUANTIDADE_MULTIPLAS]: {
    title: 'Múltiplas',
  },
  [CaracteristicaLesaoEnum.BASE_SESSIL]: {
    title: 'Séssil',
  },
  [CaracteristicaLesaoEnum.BASE_PEDICULADA]: {
    title: 'Pediculada',
  },
  [CaracteristicaLesaoEnum.TAMANHO_ATE_4]: {
    title: 'Até 4mm',
  },
  [CaracteristicaLesaoEnum.TAMANHO_ENTRE_5_E_10]: {
    title: 'De 5 à 10mm',
  },
  [CaracteristicaLesaoEnum.TAMANHO_ACIMA_DE_10]: {
    title: 'Mais de 1cm',
  },
  [CaracteristicaLesaoEnum.COR_BRANCA]: {
    title: 'Branca',
  },
  [CaracteristicaLesaoEnum.COR_VIOLACEA]: {
    title: 'Violácea',
  },
  [CaracteristicaLesaoEnum.COR_VERMELHA]: {
    title: 'Eritematosa',
  },
  [CaracteristicaLesaoEnum.COR_ACASTANHADA]: {
    title: 'Acastanhada',
  },
  [CaracteristicaLesaoEnum.SUPERFICIE_LISA]: {
    title: 'Lisa',
  },
  [CaracteristicaLesaoEnum.SUPERFICIE_RUGOSA]: {
    title: 'Rugosa',
  },
  [CaracteristicaLesaoEnum.SUPERFICIE_ULCERADA]: {
    title: 'Ulcerada',
  },
  [CaracteristicaLesaoEnum.SUPERFICIE_GLANULOMATOSA]: {
    title: 'Granulomatosa',
  },
  [CaracteristicaLesaoEnum.SUPERFICIE_NECROSADA]: {
    title: 'Necrosada',
  },
  [CaracteristicaLesaoEnum.CONSISTENCIA_MOLE]: {
    title: 'Mole',
  },
  [CaracteristicaLesaoEnum.CONSISTENCIA_FIRME]: {
    title: 'Firme',
  },
  [CaracteristicaLesaoEnum.CONSISTENCIA_DURA]: {
    title: 'Dura',
  },
  [CaracteristicaLesaoEnum.SINTOMATOLOGIA_AUSENTE]: {
    title: 'Ausente',
  },
  [CaracteristicaLesaoEnum.SINTOMATOLOGIA_DOR]: {
    title: 'Dor',
  },
  [CaracteristicaLesaoEnum.SINTOMATOLOGIA_PARESTESIA]: {
    title: 'Parestesia',
  },
  [CaracteristicaLesaoEnum.SINTOMATOLOGIA_ARDENCIA]: {
    title: 'Ardência',
  },
}

const tiposLesoesEnvolvidas: Record<TipoLesaoFundamental, TipoLesaoFundamentalModel> = {
  [TipoLesaoFundamental.PLANA]: {
    title: 'Plana ou elevada',
    lesaoEnvolvidaTitle: 'Lesão plana ou elevada',
    lesoesEnvolvidas: [
      CaracteristicaLesaoEnum.LESAO_PLANA_MACULA,
      CaracteristicaLesaoEnum.LESAO_PLANA_PAPULA,
      CaracteristicaLesaoEnum.LESAO_PLANA_PLACA,
      CaracteristicaLesaoEnum.LESAO_PLANA_PSEUDOMEMBRANA,
      CaracteristicaLesaoEnum.LESAO_PLANA_TUMEFACAO,
    ],
  },
  [TipoLesaoFundamental.LIQUIDA]: {
    title: 'Conteúdo líquido',
    lesaoEnvolvidaTitle: 'Lesão com conteúdo líquido',
    lesoesEnvolvidas: [CaracteristicaLesaoEnum.LESAO_LIQUIDA_PUSTULA, CaracteristicaLesaoEnum.LESAO_LIQUIDA_VESICULA],
  },
  [TipoLesaoFundamental.TECIDUAL]: {
    title: 'Envolve perda tecidual',
    lesaoEnvolvidaTitle: 'Lesão envolvendo perda tecidual',
    lesoesEnvolvidas: [
      CaracteristicaLesaoEnum.LESAO_TECIDUAL_EROSAO,
      CaracteristicaLesaoEnum.LESAO_TECIDUAL_FISSURA,
      CaracteristicaLesaoEnum.LESAO_TECIDUAL_ULCERA,
    ],
  },
}

const tiposCaracteristicaLesao: Record<TipoCaracteristicaLesao, TipoCaracteristicaLesaoModel> = {
  [TipoCaracteristicaLesao.QUANTIDADE]: {
    title: 'Quantidade',
    items: [
      CaracteristicaLesaoEnum.QUANTIDADE_UNICA,
      CaracteristicaLesaoEnum.QUANTIDADE_BILATERAL,
      CaracteristicaLesaoEnum.QUANTIDADE_MULTIPLAS,
    ],
  },
  [TipoCaracteristicaLesao.TAMANHO]: {
    title: 'Tamanho',
    items: [
      CaracteristicaLesaoEnum.TAMANHO_ATE_4,
      CaracteristicaLesaoEnum.TAMANHO_ENTRE_5_E_10,
      CaracteristicaLesaoEnum.TAMANHO_ACIMA_DE_10,
    ],
  },
  [TipoCaracteristicaLesao.BASE]: {
    title: 'Implantação ou base',
    items: [CaracteristicaLesaoEnum.BASE_SESSIL, CaracteristicaLesaoEnum.BASE_PEDICULADA],
  },
  [TipoCaracteristicaLesao.COR]: {
    title: 'Cor',
    items: [
      CaracteristicaLesaoEnum.COR_BRANCA,
      CaracteristicaLesaoEnum.COR_VERMELHA,
      CaracteristicaLesaoEnum.COR_VIOLACEA,
      CaracteristicaLesaoEnum.COR_ACASTANHADA,
    ],
  },
  [TipoCaracteristicaLesao.SINTOMATOLOGIA]: {
    title: 'Sintomatologia',
    items: [
      CaracteristicaLesaoEnum.SINTOMATOLOGIA_AUSENTE,
      CaracteristicaLesaoEnum.SINTOMATOLOGIA_DOR,
      CaracteristicaLesaoEnum.SINTOMATOLOGIA_PARESTESIA,
      CaracteristicaLesaoEnum.SINTOMATOLOGIA_ARDENCIA,
    ],
  },
  [TipoCaracteristicaLesao.SUPERFICIE]: {
    title: 'Superfície',
    items: [
      CaracteristicaLesaoEnum.SUPERFICIE_LISA,
      CaracteristicaLesaoEnum.SUPERFICIE_GLANULOMATOSA,
      CaracteristicaLesaoEnum.SUPERFICIE_RUGOSA,
      CaracteristicaLesaoEnum.SUPERFICIE_NECROSADA,
      CaracteristicaLesaoEnum.SUPERFICIE_ULCERADA,
    ],
  },
  [TipoCaracteristicaLesao.CONSISTENCIA]: {
    title: 'Consistência',
    items: [
      CaracteristicaLesaoEnum.CONSISTENCIA_MOLE,
      CaracteristicaLesaoEnum.CONSISTENCIA_FIRME,
      CaracteristicaLesaoEnum.CONSISTENCIA_DURA,
    ],
  },
  [TipoCaracteristicaLesao.LESOES_ENVOLVIDAS]: {
    items: [
      CaracteristicaLesaoEnum.LESAO_PLANA_MACULA,
      CaracteristicaLesaoEnum.LESAO_PLANA_PLACA,
      CaracteristicaLesaoEnum.LESAO_PLANA_PAPULA,
      CaracteristicaLesaoEnum.LESAO_PLANA_TUMEFACAO,
      CaracteristicaLesaoEnum.LESAO_PLANA_PSEUDOMEMBRANA,
      CaracteristicaLesaoEnum.LESAO_LIQUIDA_VESICULA,
      CaracteristicaLesaoEnum.LESAO_LIQUIDA_PUSTULA,
      CaracteristicaLesaoEnum.LESAO_TECIDUAL_EROSAO,
      CaracteristicaLesaoEnum.LESAO_TECIDUAL_ULCERA,
      CaracteristicaLesaoEnum.LESAO_TECIDUAL_FISSURA,
    ],
  },
}
