/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, Theme, useTheme } from 'bold-ui'

export function NewsSideMenuHeader() {
  const theme = useTheme()
  const styles = createStyles(theme)
  return (
    <div css={styles.div}>
      <Heading level={3}>Versões</Heading>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  div: css`
    padding: 0.55rem 0.7rem;
    border: 1px ${theme.pallete.divider} solid;
    border-bottom: 0;
    border-radius: ${theme.radius.input}px ${theme.radius.input}px 0 0;
    background-color: ${theme.pallete.surface.background};
  `,
})
