import { AleitamentoMaterno, Antecedente, AtendimentoPuericultura } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

import {
  AvaliacaoDesenvolvimentoModel,
  MedicaoAnteriorPuericulturaModel,
  PuericulturaModel,
} from '../../objetivo/puericultura/model'

export type MedicaoAnteriorAcompanhamentoPuericultura = MedicaoAnteriorPuericulturaModel

export interface AcompanhamentoPuericulturaModel
  extends Omit<PuericulturaModel, 'hasPuericultura' | 'prenatal' | 'tipoAleitamento'> {
  ultimoAtendimentoPuericultura: AtendimentoPuericultura
  tipoAleitamentoMaterno: AleitamentoMaterno
  preNatalPartoNascimento?: Antecedente
  vacinacaoEmDia: MedicaoAnteriorAcompanhamentoPuericultura
}
export const acompanhamentoPuericulturaFieldName = metaPath<{
  acompanhamentoPuericultura: AcompanhamentoPuericulturaModel
}>()

export const initialValueAvaliacaoAcompanhamento = (
  avaliacao: AvaliacaoDesenvolvimentoModel[]
): AvaliacaoDesenvolvimentoModel[] =>
  avaliacao
    .map<AvaliacaoDesenvolvimentoModel>((item) => ({ ...item, statusButton: item.status }))
    .sort((a, b) => Number(a.id) - Number(b.id))
