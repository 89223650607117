/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { ReactComponent as AgendamentoCompartilhadoIcon } from 'images/agenda/calendar-add-filled.svg'

interface AgendamentosDiaLabelProps {
  nomeProf: string
  horarioInicial: string
  cbo: string
  nomeProfParticipante?: string
  cboProfParticipante?: string
}

export const AgendamentosDiaLabel = (props: AgendamentosDiaLabelProps) => {
  const { nomeProf, horarioInicial, cbo, nomeProfParticipante, cboProfParticipante } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <VFlow vSpacing={0} style={styles.container}>
      <HFlow hSpacing={0} alignItems='center'>
        {nomeProfParticipante && <AgendamentoCompartilhadoIcon css={styles.iconeAgendCompartilhado} />}
        <Text fontWeight='bold'>
          <DateTime value={horarioInicial} format='HH:mm' />
          {` -  ${nomeProf}`}
        </Text>
      </HFlow>
      <Text>{cbo.titleCase()}</Text>

      {nomeProfParticipante && (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold' style={styles.nomeProfParticipante}>
            Com {nomeProfParticipante}
          </Text>

          <Text>{cboProfParticipante.titleCase()}</Text>
        </VFlow>
      )}
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    padding-left: 0.5rem;
  `,
  iconeAgendCompartilhado: css`
    height: 1rem;
    width: 1rem;
    margin-right: 0.25rem;
    fill: ${theme.pallete.gray.c20};
  `,
  nomeProfParticipante: css`
    margin-top: 0.25rem;
  `,
})
