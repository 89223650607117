/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { TextAreaField } from 'components/form'
import { useMemo } from 'react'
import { MetaPath } from 'util/metaPath'

interface ResultadoExameTextAreaFieldProps {
  name: MetaPath<string> | string
  expanded: boolean
  hasValue: boolean
}

export const ResultadoExameTextAreaField = (props: ResultadoExameTextAreaFieldProps) => {
  const { name, expanded, hasValue } = props

  const classes = useMemo(() => styles(expanded, hasValue), [expanded, hasValue])

  return (
    <TextAreaField
      name={name}
      maxLength={2000}
      placeholder='Clique para inserir o resultado...'
      onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => e.target.scrollTo({ top: 0 })}
      style={classes.textArea}
    />
  )
}

const styles = (expanded: boolean, hasValue: boolean) => ({
  textArea: css`
    width: calc(100% + 1rem + 2px);
    margin: 0 calc((0.5rem + 1px) * -1);

    margin-bottom: ${expanded ? '0' : 'calc(-0.75rem - 1px)'};
    max-height: ${expanded ? 'auto' : '2rem'};
    resize: ${expanded ? 'vertical' : 'none'};
    overflow: ${expanded ? 'visible' : 'hidden'};

    ${!expanded &&
    hasValue &&
    `
      display: -webkit-box;
      align-items: center;
      height: 2rem;
      line-height: 2rem;
      padding-top: 0;
      padding-bottom: 0;
      text-overflow: ellipsis;
      white-space: pre-line;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    `}

    & + div {
      display: ${expanded ? 'flex' : 'none'};
    }
  `,
})
