/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useState } from 'react'

import pausedImg from '../../images/novidades/icons/pause.png'

interface NewsGifProps {
  src: string
  alt: string
  pausedSrc: string
}

export const NewsGif = (props: NewsGifProps) => {
  const { alt, src, pausedSrc } = props
  const [isPlaying, setIsPlaying] = useState(false)
  const classes = styles()

  const togglePlaying = () => {
    setIsPlaying(!isPlaying)
  }

  return (
    <div css={classes.container} onClick={togglePlaying}>
      <img src={isPlaying ? src : pausedSrc} alt={alt} css={classes.imageGif} />
      {!isPlaying && <img src={pausedImg} alt='Imagem de pause' css={classes.pausedImg} />}
    </div>
  )
}

const styles = () => ({
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 430px;
    position: relative;
    cursor: pointer;
  `,
  imageGif: css`
    max-width: 100%;
    max-height: 430px;
    image-rendering: -webkit-optimize-contrast;
    border: 1px solid;
  `,
  pausedImg: css`
    position: absolute;
    left: 45%;
    top: 42%;
    width: 80px;
    height: 80px;
  `,
})
