import { Calculation } from 'final-form-calculate'
import { JustificativaCancelamentoAgendaComboEnum as JustificativaEnum } from 'types/enums'
import { MetaRoot } from 'util/metaPath'
import { JustificativaCancelamentoAgendamentoModel } from 'view/agenda/model-agenda'

export const calculatorJustificativaCancelamento = (
  meta: MetaRoot<JustificativaCancelamentoAgendamentoModel>
): Calculation => ({
  field: meta.justificativa.absolutePath(),
  updates: {
    [meta.justificativaOutra.absolutePath()]: (
      justificativaValue: JustificativaEnum,
      allValues: JustificativaCancelamentoAgendamentoModel
    ) => (justificativaValue !== JustificativaEnum.OUTRA ? undefined : allValues.justificativaOutra),
  },
})
