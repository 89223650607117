import { Button, HFlow, Icon, Tooltip } from 'bold-ui'
import { ButtonLink } from 'components/route'
import { ParteBucalEnum, TipoParteBucalEnum } from 'graphql/types.generated'
import React from 'react'
import { useRouteMatch } from 'react-router'

import {
  EvolucaoOdontoTableItemModel,
  TOOLTIP_DELETE_ARCADAS,
  TOOLTIP_DELETE_DENTES,
  URL_EDITAR_OUTROS,
  URL_EDITAR_SUPRANUMERARIOS,
} from './model'

interface EvolucoesOdontoTableRowActionsProps {
  row: EvolucaoOdontoTableItemModel
  isEditingDenteOrArcada: boolean
  onEditDenteOrArcada(parteBucal: ParteBucalEnum, tipoParteBucal: TipoParteBucalEnum): void
  onRemove: (id: ID, tipoParteBucal: TipoParteBucalEnum) => void
}

export const EvolucoesOdontoTableRowActions = (props: EvolucoesOdontoTableRowActionsProps) => {
  const { row, isEditingDenteOrArcada, onEditDenteOrArcada, onRemove } = props

  const match = useRouteMatch()

  const { parteBucalEnum, tipoParteBucal } = row.partesBucais[0]

  const isDenteOrArcada = tipoParteBucal === TipoParteBucalEnum.DENTE || tipoParteBucal === TipoParteBucalEnum.ARCADA

  const editButtonTo = `${match.url}${
    tipoParteBucal === TipoParteBucalEnum.SUPRANUMERARIO ? URL_EDITAR_SUPRANUMERARIOS : URL_EDITAR_OUTROS
  }${row.cacheId}`

  return (
    <HFlow hSpacing={0.5} justifyContent='flex-end'>
      <Tooltip text={isEditingDenteOrArcada ? 'Edição em andamento' : getTooltip(tipoParteBucal, 'edit')}>
        {isDenteOrArcada ? (
          <Button
            size='small'
            skin='ghost'
            onClick={() => !isEditingDenteOrArcada && onEditDenteOrArcada(parteBucalEnum, tipoParteBucal)}
            disabled={isEditingDenteOrArcada}
          >
            <Icon icon='penOutline' />
          </Button>
        ) : (
          <ButtonLink to={editButtonTo} size='small' skin='ghost'>
            <Icon icon='penOutline' />
          </ButtonLink>
        )}
      </Tooltip>

      <Tooltip text={getTooltip(tipoParteBucal, 'remove')}>
        <Button
          size='small'
          skin='ghost'
          onClick={() => onRemove(row.cacheId, tipoParteBucal)}
          disabled={isDenteOrArcada}
        >
          <Icon icon='trashOutline' />
        </Button>
      </Tooltip>
    </HFlow>
  )
}

const getTooltip = (tipoParteBucal: TipoParteBucalEnum, action: 'edit' | 'remove') => {
  if (tipoParteBucal === TipoParteBucalEnum.DENTE && action === 'remove') {
    return TOOLTIP_DELETE_DENTES
  } else if (tipoParteBucal === TipoParteBucalEnum.ARCADA && action === 'remove') {
    return TOOLTIP_DELETE_ARCADAS
  }

  return action === 'edit' ? 'Editar' : 'Excluir'
}
