import { Text } from 'bold-ui'
import React, { ReactNode } from 'react'

export const EMPTY = <Text>-</Text>

export function createRender<T, K extends keyof T>(row: T, key: K, render: (value: T[K]) => ReactNode) {
  const value = row[key]
  if (value) {
    return render(value)
  } else {
    return EMPTY
  }
}
