import { useEffect, useState } from 'react'

import { AccordionControls } from './useAccordionControl'

interface Props<Row> extends Pick<AccordionControls, 'isExpanded' | 'handleAccordionItemClick'> {
  items: Row[]
  extractUuid: (row: Row) => string
}

export function useAccordionAutoOpenUniqueItem<T>(props: Props<T>) {
  const { items, handleAccordionItemClick, isExpanded, extractUuid } = props

  const [autoOpen, setAutoOpen] = useState<string>()
  const [wasPrevExpanded, setWasPrevExpanded] = useState(false)

  useEffect(() => {
    if (items?.length > 0) {
      const uuid = extractUuid(items[0])

      if (items.length === 1 && !isExpanded(uuid) && !autoOpen && !wasPrevExpanded) {
        handleAccordionItemClick(uuid)
        setAutoOpen(uuid)
      }
    }
  }, [autoOpen, extractUuid, handleAccordionItemClick, isExpanded, items, wasPrevExpanded])

  useEffect(() => {
    if (autoOpen && isExpanded(autoOpen) && items.length !== 1) {
      handleAccordionItemClick(autoOpen)
      setAutoOpen(null)
    }
  }, [autoOpen, handleAccordionItemClick, isExpanded, items])

  useEffect(() => {
    if (items.length !== 1 && autoOpen && !isExpanded(autoOpen)) {
      setAutoOpen(null)
    }
  }, [autoOpen, isExpanded, items, items.length])

  useEffect(() => {
    if (items?.length > 0) {
      const uuid = extractUuid(items[0])

      if (items.length === 1 && isExpanded(uuid) && !autoOpen && !wasPrevExpanded) {
        setWasPrevExpanded(true)
      }
    }
  }, [autoOpen, extractUuid, isExpanded, items, wasPrevExpanded])

  useEffect(() => {
    if (items.length !== 1 && wasPrevExpanded) {
      setWasPrevExpanded(false)
    }
  }, [items.length, wasPrevExpanded])
}
