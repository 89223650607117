import { Alert, Text, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { Box } from 'components/Box'
import { useFlags } from 'config/useFlagsContext'
import { useCertificadoRndsQuery } from 'graphql/hooks.generated'
import { TipoRegistroRndsEnum } from 'graphql/types.generated'
import React from 'react'
import Permissions from 'types/Permissions'

import EnviosRndsAccordion from './EnviosRndsAccordion'
import RndsTitle from './RndsTitle'
import { StatusUltimoEnvioRnds } from './StatusUltimoEnvioRnds'

export function RndsView() {
  const {
    data: { conexao, configuracaoCertificadoHabilitado },
  } = useCertificadoRndsQuery()
  const { ENVIO_RAC_ENABLED, ENVIO_RPM_ENABLED, ENVIO_VACINA_ENABLED } = useFlags()

  return (
    <CheckPermission permission={Permissions.configuracaoMunicipal.visualizarEnvioRnds}>
      <Box>
        <VFlow>
          <RndsTitle />
          {configuracaoCertificadoHabilitado?.rndsHabilitado ? (
            <VFlow>
              {conexao?.internet?.habilitado ? (
                <StatusUltimoEnvioRnds />
              ) : (
                <Alert type='warning' inline>
                  A conexão com a internet está desabilitada no PEC. Entre em contato com o Administrador da Instalação.
                </Alert>
              )}
              {ENVIO_VACINA_ENABLED && (
                <EnviosRndsAccordion title='Vacinação' tipoRegistro={TipoRegistroRndsEnum.VACINA} />
              )}
              {ENVIO_RAC_ENABLED && (
                <EnviosRndsAccordion
                  title='Atendimentos individuais'
                  tipoRegistro={TipoRegistroRndsEnum.ATENDIMENTO_INDIVIDUAL}
                />
              )}
              {ENVIO_RPM_ENABLED && (
                <EnviosRndsAccordion
                  title='Prescrição de medicamentos'
                  tipoRegistro={TipoRegistroRndsEnum.PRESCRICAO_MEDICAMENTO}
                />
              )}
            </VFlow>
          ) : (
            <Text fontStyle='italic'>Certificado não configurado. Não há registros a serem exibidos.</Text>
          )}
        </VFlow>
      </Box>
    </CheckPermission>
  )
}
