/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Tag, Text, useStyles, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Fragment } from 'react'
import { humanizeAge } from 'util/date/humanize-age'
import AtendimentoListingItem from 'view/atendimentos/components/AtendimentoListingItem'
import { AtendimentoTags } from 'view/atendimentos/list/AtendimentoTags'
import { ListaAtendimentoItemProps } from 'view/atendimentos/list/ListaAtendimentoItem'
import { createItemStyles } from 'view/atendimentos/list/listaUtils'

import ListaRegistroTardioItemActions from './ListaRegistroTardioItemActions'
import { listaRegistroTardioStyles } from './utils/listaRegistroTardioStyles'

interface ListaRegistroTardioItemProps
  extends Omit<ListaAtendimentoItemProps, 'isAtendimentoObservacao' | 'serverTime'> {
  onClick(dataAtendimento: Instant, cidadaoId: ID)
}

export function ListaRegistroTardioItem(props: ListaRegistroTardioItemProps) {
  const { atend, onClick } = props
  const { classes } = useStyles(createItemStyles)
  const statusAtendimento = listaRegistroTardioStyles[atend.statusAtendimento]

  const renderTime = (
    <VFlow vSpacing={0}>
      {atend.hasTagNaoFinalizado && (
        <Tag icon='exclamationTriangleFilled' style={classes.naoFinalizadoTag}>
          Não finalizado
        </Tag>
      )}
      <HFlow style={classes.hFlow}>
        <VFlow vSpacing={0}>
          <DateTime
            style={css`
              line-height: 1.35rem;
              font-weight: bold;
            `}
            value={atend.iniciadoEm}
            format='DD/MM | HH:mm'
          />
          <Text>{listaRegistroTardioStyles[atend.statusAtendimento].descricao}</Text>
        </VFlow>

        <VFlow vSpacing={0}>
          <span className={classes.nomeCidadao}>{`${atend.cidadao.nomeSocial || atend.cidadao.nome} `}</span>
          <span className={classes.idadeCidadao}>{humanizeAge(atend.cidadao.dataNascimento)}</span>
        </VFlow>
      </HFlow>
    </VFlow>
  )

  const renderTags = (
    <AtendimentoTags
      horaAgendamento={atend.agendado?.horarioInicial}
      nomeProfissional={atend.agendado?.lotacaoAgendada?.profissional?.nome}
    />
  )

  const renderLotacao = (
    <Fragment>
      {atend.responsavel && (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>{atend.responsavel.profissional.nome}</Text>
        </VFlow>
      )}
      {!atend.responsavel && atend.equipe && (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'> {atend.equipe.nome} </Text>
          <Text>
            <b>Área </b> {atend.equipe.area}
          </Text>
        </VFlow>
      )}
    </Fragment>
  )

  const renderActions = (
    <ListaRegistroTardioItemActions
      actions={atend.actions}
      atendimentoId={atend.id}
      cidadaoId={atend.cidadao.id}
      prontuarioId={atend.prontuarioId}
      statusAtendimento={atend.statusAtendimento}
      statusRevisaoAtendimento={atend.statusRevisaoAtendimento}
      dataInicioAtendimento={atend.iniciadoEm}
      agendadoId={atend.agendado?.id}
      onAtenderClick={onClick}
    />
  )

  return (
    <AtendimentoListingItem
      status={statusAtendimento}
      time={renderTime}
      tags={renderTags}
      lotacao={renderLotacao}
      actions={renderActions}
    />
  )
}
