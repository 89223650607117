/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SerializedStyles } from '@emotion/serialize'
import { Icon, Icons, Theme, useTheme } from 'bold-ui'
import { ReactElement } from 'react'

export type BoxProps = {
  styles?: SerializedStyles
  icon?: Icons
  rotation?: '0' | '90'
  label: string
  children: ReactElement
}

export function Box(props: BoxProps) {
  const theme = useTheme()
  const { styles, icon, rotation, label, children } = props

  const localStyles = createStyles(theme)

  return (
    <div css={localStyles.wrapper}>
      <div css={localStyles.panel}>
        <h4 css={localStyles.header}>
          {icon && (
            <Icon
              icon={icon}
              style={css`
                transform: rotate(${rotation}deg);
              `}
            />
          )}
          {label}
        </h4>
      </div>
      <div
        css={css`
          min-height: 7.18rem;
          ${styles}
        `}
      >
        {children}
      </div>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  header: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  panel: css`
    background-color: ${theme.pallete.gray.c90};
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
  wrapper: css`
    border: 1px solid ${theme.pallete.divider};
  `,
})
