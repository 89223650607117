import { Cell, Grid, TextField } from 'bold-ui'
import React from 'react'

interface HistoricoTecidosMolesTableHeaderProps {
  onChangeFilter(filter: string): void
}

export function HistoricoTecidosMolesTableHeader(props: HistoricoTecidosMolesTableHeaderProps) {
  const { onChangeFilter } = props

  return (
    <Grid alignItems='flex-start' gap={1}>
      <Cell md={7} lg={6}>
        <TextField
          placeholder='Pesquise por local, CID 10, procedimento ou profissional'
          icon='zoomOutline'
          onChange={(e) => onChangeFilter(e.currentTarget.value)}
        />
      </Cell>
    </Grid>
  )
}
