/* eslint-disable no-extend-native */
export {}

declare global {
  interface Set<T> {
    /**
     * Function to add all the elements of a set into a string, separated by the specified separator string.
     * @param separator A string used to separate one element of the set from the next in the resulting string. If omitted, the set elements are separated with a comma.
     *
     * @returns string
     */
    join(separator?: string): string
  }
}

Set.prototype.join = function (separator?: string): string {
  return [...this].join(separator)
}
