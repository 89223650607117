import { ApolloError } from 'apollo-client'
import React, { useContext } from 'react'

interface PromiseErrorBoundaryProps {
  children: React.ReactNode
  onError(error: ApolloError): void
}

export type PromiseErrorContextValue = (error: ApolloError) => void

export const PromiseErrorContext = React.createContext<PromiseErrorContextValue>(() => null)

export function PromiseErrorBoundary(props: PromiseErrorBoundaryProps) {
  const { children, onError } = props

  return <PromiseErrorContext.Provider value={onError}>{children}</PromiseErrorContext.Provider>
}

/**
 * Handler padrão de rejeição de promises. Utilize como parâmetro dos `.catch` das mutations e requisições assíncronas
 *
 * @example
 * const handleRejection = useErrorHandler()
 * executeMutation(...) // returns a promise
 *  .then(...)
 *  .catch(handleRejection)
 */
export function useErrorHandler() {
  return useContext(PromiseErrorContext)
}
