import { useFirebase } from 'hooks/firebase/useFirebase'
import { useCallback, useState } from 'react'

import { useDisplayMedia } from './useDisplayMedia'

export function useScreenShare() {
  const { analytics } = useFirebase()
  const [sharingScreen, setSharingScreen] = useState(false)

  const shareScreen = useCallback(() => {
    analytics.logEvent('TELE_videochamadas_compartilhar_tela_ativado')
    setSharingScreen(true)
  }, [analytics])
  const stopSharingScreen = useCallback(() => setSharingScreen(false), [])
  const handleError = useCallback(() => setSharingScreen(false), [])

  const { stream: displayStream } = useDisplayMedia({
    audio: false,
    video: sharingScreen,
    onStreamClose: stopSharingScreen,
    onError: handleError,
  })

  return {
    sharingScreen: sharingScreen && !!displayStream,
    displayStream,
    shareScreen,
    stopSharingScreen,
  }
}
