/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Theme,
  Tooltip,
  useTheme,
  VFlow,
} from 'bold-ui'
import { ParteBucalEnum } from 'graphql/types.generated'
import { isEqual } from 'lodash'
import { Fragment, memo, useMemo } from 'react'
import { MetaPath } from 'util/metaPath'

import { getParteBucal } from '../../../util-EvolucoesOdontologicasPlano'
import {
  getPeriogramaCompletoCondicao,
  getPeriogramaCompletoFace,
  getPeriogramaCompletoQuadrante,
  PeriogramaCompletoQuadrante,
  PeriogramaCompletoQuadranteModel,
  PeriogramaCompletoTableModel,
} from '../model'
import { PipsModel } from '../periogramaCompletoCalculos'
import PeriogramaCompletoTableFieldCell from './PeriogramaCompletoTableFieldCell'

export interface PeriogramaCompletoTableFieldProps {
  name: MetaPath<PeriogramaCompletoTableModel>
  quadrante: PeriogramaCompletoQuadrante
  pips: PipsModel
  dentesAusentes?: ParteBucalEnum[]
  onClickDenteAsAusente?(dente: ParteBucalEnum): void
}

function PeriogramaCompletoTableField(props: PeriogramaCompletoTableFieldProps) {
  const { name, quadrante: quadranteEnum, pips, dentesAusentes, onClickDenteAsAusente } = props

  const theme = useTheme()
  const classes = useMemo(() => periogramaCompletoTableStyles(theme), [theme])

  const quadrante: PeriogramaCompletoQuadranteModel = useMemo(() => getPeriogramaCompletoQuadrante(quadranteEnum), [
    quadranteEnum,
  ])

  return (
    <VFlow vSpacing={0.5}>
      <Text fontWeight='bold'>{quadrante.title}</Text>

      <div css={classes.tableWrapper}>
        <Table style={classes.table}>
          <TableHead>
            <TableRow>
              <TableHeader style={[classes.firstColumn, classes.headerCell]}>Dente</TableHeader>
              {quadrante.dentes.map((dente) => (
                <TableHeader
                  key={`${quadranteEnum}${dente}header`}
                  style={[classes.tableCell, classes.headerCell, classes.tableHeaderButtonCell]}
                >
                  <Tooltip text={`${dentesAusentes?.includes(dente) ? `Desmarcar` : `Marcar`} dente como ausente`}>
                    <Button
                      size='small'
                      skin='ghost'
                      style={classes.tableHeaderButton}
                      onClick={() => onClickDenteAsAusente?.(dente)}
                    >
                      {getParteBucal(dente).number}
                    </Button>
                  </Tooltip>
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {quadrante.faces.map((faceEnum) => {
              const face = getPeriogramaCompletoFace(faceEnum)

              return (
                <Fragment key={`${quadranteEnum}${faceEnum}fragment`}>
                  <TableRow>
                    <TableCell style={[classes.firstColumn, classes.headerCell]}>{face.title}</TableCell>

                    {quadrante.dentes.map((dente) => (
                      <Tooltip text={face.longPlaceholder} key={`${quadranteEnum}${faceEnum}${dente}placeholder`}>
                        <TableCell style={[classes.tableCell, classes.headerCell]}>{face.placeholder}</TableCell>
                      </Tooltip>
                    ))}
                  </TableRow>

                  {face.condicoes.map((condicaoEnum) => {
                    const condicao = getPeriogramaCompletoCondicao(condicaoEnum)

                    return (
                      <TableRow key={`${quadranteEnum}${faceEnum}${condicaoEnum}row`}>
                        <Tooltip text={condicao.longTitle}>
                          <TableCell style={classes.firstColumn}>{condicao.title}</TableCell>
                        </Tooltip>

                        {quadrante.dentes.map((dente) => (
                          <PeriogramaCompletoTableFieldCell
                            key={`${quadranteEnum}${faceEnum}${dente}${condicaoEnum}cell`}
                            name={name[dente].values[faceEnum][condicaoEnum]}
                            dente={dente}
                            condicao={condicaoEnum}
                            ausente={dentesAusentes?.includes(dente)}
                            pip={pips?.[dente]?.[faceEnum]}
                          />
                        ))}
                      </TableRow>
                    )
                  })}
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </VFlow>
  )
}

export default memo(
  PeriogramaCompletoTableField,
  (oldProps, newProps) =>
    isEqual(oldProps.dentesAusentes, newProps.dentesAusentes) && isEqual(oldProps.pips, newProps.pips)
)

export const periogramaCompletoTableStyles = (theme: Theme) => ({
  // `width: 0` to fix overflow
  tableWrapper: css`
    width: 0;
    max-width: 100%;
    min-width: 100%;
    overflow-x: auto;
  `,
  table: css`
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;

    ${theme.breakpoints.down('lg')} {
      width: auto;
    }

    tr th,
    tr td {
      border-right: 1px solid ${theme.pallete.gray.c80};
      border-bottom: 1px solid ${theme.pallete.gray.c80};
    }

    tr th {
      border-top: 1px solid ${theme.pallete.gray.c60};
    }

    tr th:first-of-type,
    tr td:first-of-type {
      border-left: 1px solid ${theme.pallete.gray.c60};
    }

    tr th:last-of-type,
    tr td:last-of-type {
      border-right: 1px solid ${theme.pallete.gray.c60};
    }

    tr:last-of-type td {
      border-bottom: 1px solid ${theme.pallete.gray.c60};
    }

    tr th:first-of-type {
      &,
      &:before {
        border-top-left-radius: 4px;
      }
    }

    tr th:last-of-type {
      &,
      &:before {
        border-top-right-radius: 4px;
      }
    }
    tr:last-of-type td:first-of-type {
      &,
      &:before,
      & input {
        border-bottom-left-radius: 4px;
      }
    }

    tr:last-of-type td:last-of-type {
      &,
      &:before,
      & input {
        border-bottom-right-radius: 4px;
      }
    }
  `,
  headerCell: css`
    background: ${theme.pallete.gray.c90};
  `,
  tableHeaderButtonCell: css`
    padding: 0;

    ${theme.breakpoints.down('lg')} {
      min-width: 5.6rem;
    }
  `,
  tableHeaderButton: css`
    width: 100%;
    height: 100%;
    text-decoration: underline;
    border-radius: 0;
  `,
  firstColumn: css`
    width: 7.5rem;
    white-space: nowrap;
    font-weight: bold;
    background: ${theme.pallete.surface.main};
  `,
  tableCell: css`
    text-align: center;
    background: ${theme.pallete.surface.main};
  `,
  tableCellInput: css`
    position: relative;
    padding: 0;
  `,
  tableCellInputError: css`
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid ${theme.pallete.status.danger.main};
      margin: -1px;
    }
  `,
  tableCellAusente: css`
    color: ${theme.pallete.gray.c60};
    background: ${theme.pallete.gray.c90};
  `,
})
