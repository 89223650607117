import { HFlow, Icon, PagedTable, Tag, Text, Tooltip } from 'bold-ui'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useEquipeTableQuery } from 'graphql/hooks.generated'
import { EquipesQueryInput, EquipeTableQuery } from 'graphql/types.generated'
import React, { useState } from 'react'
import { useRouteMatch } from 'react-router'
import { EquipeTableFilter } from 'view/equipe/list/EquipeTableFilter'

type EquipeTableModel = EquipeTableQuery['equipes']['content'][0]

const TOOLTIP_TEXT = 'Ver profissionais e equipes vinculadas'

interface EquipeTableProps {
  unidadeSaudeId: ID
}

export function EquipeTable(props: EquipeTableProps) {
  const { unidadeSaudeId } = props
  const { url } = useRouteMatch()

  const [filter, setFilter] = useState<EquipesQueryInput>({
    unidadeSaudeId: unidadeSaudeId,
    mostrarInativas: false,
    pageParams: {
      sort: ['nome'],
    },
  })

  const {
    data: { equipes },
    loading,
  } = useEquipeTableQuery({
    variables: { input: filter },
  })

  const renderNome = (row: EquipeTableModel) => {
    return (
      <HFlow hSpacing={0.5} alignItems='center'>
        <Text>{row.nome.capitalize()}</Text>
        {!row.ativo && <Tag>Inativa</Tag>}
      </HFlow>
    )
  }

  const renderIne = (row: EquipeTableModel) => {
    return <Text>{row.ine}</Text>
  }

  const renderArea = (row: EquipeTableModel) => {
    return <Text>{row.area}</Text>
  }

  const renderTipoEquipe = (row: EquipeTableModel) => {
    return <Text>{row.tipoEquipe.sigla + ' - ' + row.tipoEquipe.nome.capitalize()}</Text>
  }

  const renderButtons = (row: EquipeTableModel) => {
    return (
      <Tooltip text={TOOLTIP_TEXT}>
        <ButtonLink to={`${url}/${row.id}`} size='small' skin='ghost'>
          <Icon icon='zoomOutline' />
        </ButtonLink>
      </Tooltip>
    )
  }

  const tableProps = usePagedTableProps({
    loading,
    onChange: setFilter,
    result: equipes,
  })

  return (
    <TableBox header={<EquipeTableFilter initialFilter={filter} onChange={setFilter} />}>
      <PagedTable<EquipeTableModel>
        {...tableProps}
        columns={[
          { name: 'nome', header: 'Nome', render: renderNome, sortable: true },
          { name: 'ine', header: 'INE', render: renderIne },
          { name: 'area', header: 'Área', render: renderArea },
          { name: 'tipoEquipe', header: 'Tipo de equipe', render: renderTipoEquipe },
          { name: 'buttons', render: renderButtons, style: { textAlign: 'right' } },
        ]}
      />
    </TableBox>
  )
}
