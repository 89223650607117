/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { FormPrompt, FormProps } from 'components/form'
import { Fragment } from 'react'
import { useParams } from 'react-router'

import { getProcedimentosAtuaisPorSextantes } from './EvolucoesPeriodontaisField'
import EvolucoesPeriodontaisForm, { EvolucoesPeriodontaisFormModel } from './EvolucoesPeriodontaisForm'
import EvolucoesPeriodontaisFormFields from './EvolucoesPeriodontaisFormFields'
import { EvolucoesPeriodontaisFieldModel } from './model'

interface UrlParams {
  id: ID
}

interface EditarEvolucoesPeriodontaisFormModalProps extends Omit<FormProps<EvolucoesPeriodontaisFormModel>, 'render'> {
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  evolucoesAtuais: EvolucoesPeriodontaisFieldModel[]
  onClose(): void
}

export default function EditarEvolucoesPeriodontaisFormModal(props: EditarEvolucoesPeriodontaisFormModalProps) {
  const { cidadaoDataNascimento, dataAtendimento, onSubmit, onClose, evolucoesAtuais, ...formProps } = props

  const { id } = useParams<UrlParams>()
  const evolucao = evolucoesAtuais.find((item) => item._id === id)
  const initialValues: EvolucoesPeriodontaisFormModel = { ...evolucao, sextantes: [evolucao?.sextante] }

  return (
    <EvolucoesPeriodontaisForm
      isEditing
      onSubmit={onSubmit}
      onSubmitSucceeded={onClose}
      evolucoesAtuais={evolucoesAtuais}
      initialValues={initialValues}
      render={(renderProps) => {
        const sextantes = renderProps.values?.sextantes
        const procedimentosAtuais = renderProps.values?.procedimentos
        const procedimentosDisabled = getProcedimentosAtuaisPorSextantes(evolucoesAtuais, sextantes).filter(
          (proced) => !initialValues?.procedimentos?.map((initialProced) => initialProced?.id)?.includes(proced.id)
        )

        return (
          <Fragment>
            <FormPrompt />
            <Modal open onClose={onClose} style={styles.modal}>
              <ModalBody>
                <VFlow>
                  <Heading level={1}>Edição de registros - Sextantes</Heading>

                  <EvolucoesPeriodontaisFormFields
                    cidadaoDataNascimento={cidadaoDataNascimento}
                    dataAtendimento={dataAtendimento}
                    partesBucais={sextantes}
                    hasProcedimentos={procedimentosAtuais?.length > 0}
                    procedimentosDisabled={procedimentosDisabled}
                    editing
                  />
                </VFlow>
              </ModalBody>

              <ModalFooter>
                <HFlow justifyContent='flex-end'>
                  <FooterButton kind='normal' onClick={onClose}>
                    Cancelar
                  </FooterButton>
                  <FooterButton kind='primary' onClick={renderProps.handleSubmit}>
                    Salvar
                  </FooterButton>
                </HFlow>
              </ModalFooter>
            </Modal>
          </Fragment>
        )
      }}
      {...formProps}
    />
  )
}

const styles = {
  modal: css`
    width: 40rem;
  `,
}
