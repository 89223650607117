import { DateRange } from 'bold-ui'
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns'
import { Calculation } from 'final-form-calculate'
import {
  ModeloPersonalizadoRelatorioGerencial,
  OpcaoSelecionadaRelatorioGerencialEnum,
  TipoModuloEnum,
  UnidadePeriodoRelatorioGerencialEnum,
} from 'graphql/types.generated'
import { isNull } from 'lodash'
import { Permission } from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { MetaRoot } from 'util/metaPath'

import { KeyMapping } from '../../common/keyMapping-relatorioGerencial'
import {
  relatorioGerencialDiaOpcoesRecord,
  relatorioGerencialMesOpcoesRecord,
  relatorioGerencialSemanaOpcoesRecord,
} from '../../common/model-relatorioGerencial'
import { modeloPersonalizadoToFiltrosFormModel } from '../modelo-personalizado/converter-modeloPersonalizadoRelatorioGerencial'
import { ModeloPersonalizadoModel } from '../modelo-personalizado/model-modeloPersonalizado'
import { RELATORIO_GERENCIAL_FILTROS_FORM_DEFAULT_VALUES } from './model-relatorioGerencialFiltrosForm'
import { RelatorioGerencialFiltrosFormModel } from './model-relatorioGerencialFiltrosForm'

export const createRelatorioGerencialFiltroFormCalculations = (
  name: MetaRoot<RelatorioGerencialFiltrosFormModel>,
  modeloPersonalizado: ModeloPersonalizadoRelatorioGerencial,
  keyMapping: Map<string, KeyMapping>,
  modulo: TipoModuloEnum,
  isGestorEstadual: boolean,
  hasAuthorization: (permission: Permission) => boolean
): Calculation[] => [
  unidadePeriodoCalculation(name),
  loadModeloCalculation(name, modeloPersonalizado, keyMapping, modulo, isGestorEstadual, hasAuthorization),
  periodoCalculation(name),
]

export const loadModeloCalculation = (
  name: MetaRoot<RelatorioGerencialFiltrosFormModel>,
  modeloPersonalizadoLoaded: ModeloPersonalizadoRelatorioGerencial,
  keyMapping: Map<string, KeyMapping>,
  modulo: TipoModuloEnum,
  isGestorEstadual: boolean,
  hasAuthorization: (permission: Permission) => boolean
): Calculation => ({
  field: [name.modeloPersonalizado.absolutePath()],
  updates: (
    modeloPersonalizado: ModeloPersonalizadoModel,
    _,
    allValues: RelatorioGerencialFiltrosFormModel,
    prevValues: RelatorioGerencialFiltrosFormModel
  ) => {
    const isRemovingModeloPersonalizado = isUndefinedOrNull(modeloPersonalizado) && prevValues.modeloPersonalizado
    const isSelectingAnotherModelo = modeloPersonalizado?.id !== prevValues.modeloPersonalizado?.id
    const hasLoadedSelectedModelo = modeloPersonalizadoLoaded?.id === modeloPersonalizado?.id

    if (isRemovingModeloPersonalizado) {
      return RELATORIO_GERENCIAL_FILTROS_FORM_DEFAULT_VALUES
    }

    if (isSelectingAnotherModelo && hasLoadedSelectedModelo) {
      return modeloPersonalizadoToFiltrosFormModel(
        modeloPersonalizadoLoaded,
        keyMapping,
        modulo,
        isGestorEstadual,
        hasAuthorization
      )
    }

    return allValues
  },
})

export const periodoCalculation = (name: MetaRoot<RelatorioGerencialFiltrosFormModel>): Calculation => ({
  field: [name.opcaoSelecionada.absolutePath(), name.unidade.absolutePath(), name.modeloPersonalizado.absolutePath()],
  updates: {
    [name.periodo.absolutePath()]: (_, { opcaoSelecionada, unidade, periodo }: RelatorioGerencialFiltrosFormModel) => {
      return calculatePeriodoRelatorioGerencial(unidade, opcaoSelecionada, periodo)
    },
  },
})

export const unidadePeriodoCalculation = (name: MetaRoot<RelatorioGerencialFiltrosFormModel>): Calculation => ({
  field: [name.unidade.absolutePath()],
  updates: {
    [name.unidade.absolutePath()]: (
      unidade: UnidadePeriodoRelatorioGerencialEnum,
      _,
      { unidade: prevUnidade }: RelatorioGerencialFiltrosFormModel
    ) => {
      return isNull(unidade) ? prevUnidade : unidade
    },
  },
})

type RelatoriosGerenciaisOpcoesCalculaveis =
  | OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA
  | OpcaoSelecionadaRelatorioGerencialEnum.SEGUNDA
  | OpcaoSelecionadaRelatorioGerencialEnum.TERCEIRA

export const calculatePeriodoRelatorioGerencial = (
  unidade: UnidadePeriodoRelatorioGerencialEnum,
  opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum,
  periodo?: DateRange
) => {
  if (opcaoSelecionada !== OpcaoSelecionadaRelatorioGerencialEnum.OUTRO) {
    const now = new Date()
    switch (unidade) {
      case UnidadePeriodoRelatorioGerencialEnum.DIA:
        return calculatePeriodForDay(now, opcaoSelecionada)

      case UnidadePeriodoRelatorioGerencialEnum.SEMANA:
        return calculatePeriodForWeek(now, opcaoSelecionada)

      case UnidadePeriodoRelatorioGerencialEnum.MES:
        return calculatePeriodForMonth(now, opcaoSelecionada)
    }
  } else return periodo
}

export const calculatePeriodForMonth = (
  now: Date,
  opcaoSelecionada: RelatoriosGerenciaisOpcoesCalculaveis
): DateRange => {
  const lastMonth = subMonths(now, 1)
  const month = subMonths(now, relatorioGerencialMesOpcoesRecord[opcaoSelecionada].quantityToSubtract)

  return {
    startDate: startOfMonth(month),
    endDate: endOfMonth(lastMonth),
  }
}

export const calculatePeriodForWeek = (
  now: Date,
  opcaoSelecionada: RelatoriosGerenciaisOpcoesCalculaveis
): DateRange => {
  const lastWeek = subWeeks(now, 1)
  const week = subWeeks(now, relatorioGerencialSemanaOpcoesRecord[opcaoSelecionada].quantityToSubtract)

  return {
    startDate: startOfWeek(week),
    endDate: endOfWeek(lastWeek),
  }
}

export const calculatePeriodForDay = (
  now: Date,
  opcaoSelecionada: RelatoriosGerenciaisOpcoesCalculaveis
): DateRange => {
  const day = subDays(now, relatorioGerencialDiaOpcoesRecord[opcaoSelecionada].quantityToSubtract)

  return {
    startDate: startOfDay(day),
    endDate: endOfDay(now),
  }
}
