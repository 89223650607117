import { Button, Heading, HFlow, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { useErrorHandler } from 'components/error'
import { LoadingIndicator } from 'components/loading'
import { confirm } from 'components/modals/confirm'
import {
  useConfiguracaoCertificadoHabilitadoQuery,
  useContraChaveAtivacaoMunicipioQuery,
  useExcluirConfiguracaoCertificadoMutation,
  useSalvarConfiguracaoCertificadoMutation,
} from 'graphql/hooks.generated'
import { ServicosCertificadoEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useEffect, useState } from 'react'

import { CertificadoSelectModel } from './CertificadoSelectField'
import { ConfiguracaoCertificadosCreateView } from './ConfiguracaoCertificadosCreateView'
import { ConfiguracaoCertificadosDadosView } from './ConfiguracaoCertificadosDadosView'

export default interface ConfiguracaoCertificadosFormModel {
  certificadoSelect: CertificadoSelectModel
  senhaCertificado: string
  idSolicitante: string
  servicos: ServicosCertificadoEnum[]
  error?: string[]
}

export function ConfiguracoesCertificadosRootView() {
  const { data, refetch, loading } = useConfiguracaoCertificadoHabilitadoQuery()
  const { data: dataContraChave, loading: loadingContraChave } = useContraChaveAtivacaoMunicipioQuery()
  const [configuracaoCertificado, setConfiguracaoCertificado] = useState(data.configuracaoCertificadoHabilitado)
  const [save] = useSalvarConfiguracaoCertificadoMutation()
  const [excluirConfiguracaoCertificado] = useExcluirConfiguracaoCertificadoMutation()
  const handleRejection = useErrorHandler()
  const alert = useAlert()
  const { analytics } = useFirebase()
  const [loadingSubmit, setloadingSubmit] = useState(false)

  useEffect(() => setConfiguracaoCertificado(data.configuracaoCertificadoHabilitado), [
    data,
    setConfiguracaoCertificado,
  ])

  const handleSubmit = ({
    servicos,
    senhaCertificado,
    idSolicitante,
    certificadoSelect: { path: pathCertificado },
  }: ConfiguracaoCertificadosFormModel) => {
    setloadingSubmit(true)
    return save({
      variables: {
        configuracaoCertificadoInput: {
          servicos,
          senhaCertificado,
          pathCertificado,
          idSolicitante,
        },
      },
    })
      .then(({ data: { salvarConfiguracaoCertificado } }) => {
        setConfiguracaoCertificado(salvarConfiguracaoCertificado)
        analytics.logEvent('habilita_certificado')
        alert('success', 'Certificado habilitado com sucesso.')
      })
      .catch(handleRejection)
      .finally(() => {
        setloadingSubmit(false)
      })
  }

  const handleExcluir = () => {
    confirm({
      title: `Deseja desabilitar o certificado?`,
      body: `Ao desabilitar o certificado digital, os serviços habilitados serão desativados.`,
      confirmLabel: 'Desabilitar',
      type: 'danger',
      onConfirm: () => {
        return excluirConfiguracaoCertificado({ variables: { configuracaoCertificadoId: configuracaoCertificado.id } })
          .then(() => {
            alert('success', 'Certificado digital desabilitado.')
            analytics.logEvent('desabilita_certificado')
          })
          .then(() => refetch())
          .then((value) => setConfiguracaoCertificado(value.data.configuracaoCertificadoHabilitado))
          .catch(handleRejection)
      },
    })()
  }

  return (
    <Box>
      <VFlow>
        <HFlow alignItems='center' style={{ position: 'relative' }}>
          <Heading level={2} style={{ display: 'inline' }}>
            Certificados
          </Heading>
          {configuracaoCertificado && (
            <Button kind='primary' size='small' style={{ position: 'absolute', right: '0px' }} onClick={handleExcluir}>
              Desabilitar
            </Button>
          )}
        </HFlow>
        {loading || loadingContraChave ? (
          <LoadingIndicator />
        ) : configuracaoCertificado ? (
          <ConfiguracaoCertificadosDadosView
            nome={configuracaoCertificado.admMunicipal.profissional.nome}
            dataHabilitacao={configuracaoCertificado.dataHabilitacao}
            dataInclusao={configuracaoCertificado.dataInclusao}
            dataValidadeCertificado={configuracaoCertificado.dataValidadeCertificado}
            rndsHabilitado={configuracaoCertificado.rndsHabilitado}
            cadsusHabilitado={configuracaoCertificado.cadsusHabilitado}
            error={configuracaoCertificado.error}
          />
        ) : (
          <ConfiguracaoCertificadosCreateView
            internetHabilitada={data.conexao?.internet?.habilitado}
            handleSubmit={handleSubmit}
            contraChave={dataContraChave?.contraChaveAtivacaoMunicipio}
            loading={loadingSubmit}
          />
        )}
      </VFlow>
    </Box>
  )
}
