/* eslint-disable no-console */
import { Cell, FormControl, Grid, HFlow, Text, VFlow } from 'bold-ui'
import {
  CheckboxField,
  CnsField,
  CpfField,
  DateField,
  FormRenderProps,
  MunicipioSelectField,
  SexoSelectField,
  TextField,
} from 'components/form'
import { DeclaracaoObitoField } from 'components/form/field/DeclaracaoObitoField'
import { RadioGroupField } from 'components/form/field/RadioGroupField'
import { EtniaSelectField } from 'components/form/field/select/EtniaSelectField'
import { PaisSelectField } from 'components/form/field/select/PaisSelectField'
import { RacaCorSelectField } from 'components/form/field/select/RacaCorSelectField'
import { InfoIcon } from 'components/InfoIcon'
import { NacionalidadeEnum, RacaCorDbEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { FormSpy } from 'react-final-form'
import { onlyNumbers } from 'util/mask'
import { MetaPath, MetaRoot } from 'util/metaPath'
import {
  beforeEqualToday,
  cns,
  consistentPeriod,
  cpf,
  createValidator,
  dataNasc,
  ErrorObject,
  length,
  maxLength,
  minLength,
  nome,
  required,
  simpleName,
  validate,
} from 'util/validation'

import CidadaoFormModel from '../types/CidadaoFormModel'

export const validator = createValidator<CidadaoFormModel>(
  {
    cpf: [cpf],
    cns: [cns],
    nome: [required, nome, minLength(5), maxLength(70)],
    nomeSocial: [simpleName, maxLength(70)],
    dataNascimento: [required, dataNasc],
    sexo: [required],
    racaCor: [required],
    numeroDeclaracaoObito: [length(9)],
  },

  (values: CidadaoFormModel, errors: ErrorObject<CidadaoFormModel>) => {
    if (values == null) {
      return errors
    }
    if (values.racaCor?.value === RacaCorDbEnum.INDIGENA) {
      errors.etnia = required(values.etnia)
    }
    if (!values.desconheceNomeMae) {
      errors.nomeMae = validate(values.nomeMae, [required, nome, minLength(5), maxLength(70)])
    }
    if (!values.desconheceNomePai) {
      errors.nomePai = validate(values.nomePai, [required, nome, minLength(5), maxLength(70)])
    }

    if (NacionalidadeEnum.BRASILEIRA === values.nacionalidade) {
      errors.municipioNascimento = required(values.municipioNascimento)
    } else if (NacionalidadeEnum.NATURALIZADA === values.nacionalidade) {
      errors.portariaNaturalizacao = validate(values.portariaNaturalizacao, [required, maxLength[16]])
      errors.dataNaturalizacao = validate(values.dataNaturalizacao, [required, beforeEqualToday])
    } else if (NacionalidadeEnum.ESTRANGEIRA === values.nacionalidade) {
      errors.paisNascimento = required(values.paisNascimento)
      errors.dataEntradaPais = validate(values.dataEntradaPais, [required, beforeEqualToday])
    }

    if (values.cidadaoFaleceu) {
      errors.dataObito = validate(values.dataObito, [required, beforeEqualToday])
    }

    const errorDataNascimentoEntradaPais = consistentPeriod(
      values.dataNascimento,
      values.dataEntradaPais,
      'Data de nascimento',
      'Data de entrada no Brasil'
    )
    if (errorDataNascimentoEntradaPais) {
      errors.dataNascimento = errorDataNascimentoEntradaPais.msgLblDataInicial
      errors.dataEntradaPais = errorDataNascimentoEntradaPais.msgLbDataFinal
    }
    const errorDataNascimentoNaturalizacao = consistentPeriod(
      values.dataNascimento,
      values.dataNaturalizacao,
      'Data de nascimento',
      'Data de naturalização'
    )
    if (errorDataNascimentoNaturalizacao) {
      errors.dataNascimento = errorDataNascimentoNaturalizacao.msgLblDataInicial
      errors.dataNaturalizacao = errorDataNascimentoNaturalizacao.msgLbDataFinal
    }
    const errorDataNascimentoObito = consistentPeriod(
      values.dataNascimento,
      values.dataObito,
      'Data de nascimento',
      'Data de óbito'
    )

    if (errorDataNascimentoObito) {
      errors.dataNascimento = errorDataNascimentoObito.msgLblDataInicial
      errors.dataObito = errorDataNascimentoObito.msgLbDataFinal
    }
    const errorDataEntradaPaisObito = consistentPeriod(
      values.dataEntradaPais,
      values.dataObito,
      'Data de entrada no Brasil',
      'Data de óbito'
    )
    if (errorDataEntradaPaisObito) {
      errors.dataEntradaPais = errorDataEntradaPaisObito.msgLblDataInicial
      errors.dataObito = errorDataEntradaPaisObito.msgLbDataFinal
    }
    const errorDataNaturalizacaoObito = consistentPeriod(
      values.dataNaturalizacao,
      values.dataObito,
      'Data de naturalização',
      'Data de óbito'
    )
    if (errorDataNaturalizacaoObito) {
      errors.dataNaturalizacao = errorDataNaturalizacaoObito.msgLblDataInicial
      errors.dataObito = errorDataNaturalizacaoObito.msgLbDataFinal
    }
    return errors
  }
)

export interface CidadaoDadosFieldGroupProps {
  name: MetaPath<CidadaoFormModel> | MetaRoot<CidadaoFormModel>
  formProps: FormRenderProps<any>
}

export function CidadaoDadosFieldGroup(props: CidadaoDadosFieldGroupProps) {
  const { name } = props
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()
  return (
    <>
      <Grid wrap>
        <Cell size={3}>
          <CpfField name={name.cpf} label='CPF' />
        </Cell>
        <Cell size={3}>
          <CnsField name={name.cns} label='CNS' disabled={props.formProps?.initialValues?.cns} />
        </Cell>
        <Cell size={3} />
        <Cell size={6}>
          <TextField uppercase name={name.nome} label='Nome completo' maxLength={70} required />
        </Cell>
        <Cell size={6}>
          <TextField
            uppercase
            name={name.nomeSocial}
            label={
              <HFlow hSpacing={0.5} alignItems='center'>
                <Text>Nome social</Text>
                <InfoIcon
                  icon='infoCircleFilled'
                  text='O nome social do cidadão cadastrado pelo PEC não será enviado ao CADSUS. Atualmente, só é possível incluir o nome social no CADSUS a partir do cadastro na Receita Federal.'
                />
              </HFlow>
            }
            maxLength={70}
          />
        </Cell>
        <Cell size={3}>
          <DateField name={name.dataNascimento} maxDate={now} label='Data de nascimento' required />
        </Cell>
        <Cell size={3}>
          <SexoSelectField name={name.sexo} label='Sexo' required />
        </Cell>
        <Cell size={6} />
        <Cell size={6}>
          <RacaCorSelectField name={name.racaCor} label='Raça/Cor' required />
        </Cell>
        <Cell size={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <EtniaSelectField
                name={name.etnia}
                label='Etnia'
                disabled={RacaCorDbEnum.INDIGENA !== values.racaCor?.value}
                required={RacaCorDbEnum.INDIGENA === values.racaCor?.value}
              />
            )}
          </FormSpy>
        </Cell>
        <Cell size={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) =>
              !values.desconheceNomeMae ? (
                <TextField uppercase name={name.nomeMae} label='Nome da mãe' maxLength={70} required />
              ) : (
                <TextField name='nomeMaeDummy' label='Nome da mãe' disabled />
              )
            }
          </FormSpy>
        </Cell>
        <Cell size={6}>
          <FormControl label={<span>&nbsp;</span>}>
            <CheckboxField
              name={name.desconheceNomeMae}
              label='Desconhece essa informação'
              style={{ marginTop: '0.25rem' }}
            />
          </FormControl>
        </Cell>
        <Cell size={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) =>
              !values.desconheceNomePai ? (
                <TextField uppercase name={name.nomePai} label='Nome do pai' maxLength={70} required />
              ) : (
                <TextField name='nomePaiDummy' label='Nome do pai' disabled />
              )
            }
          </FormSpy>
        </Cell>
        <Cell size={6}>
          <FormControl label={<span>&nbsp;</span>}>
            <CheckboxField
              name={name.desconheceNomePai}
              label='Desconhece essa informação'
              style={{ marginTop: '0.25rem' }}
            />
          </FormControl>
        </Cell>
        <Cell size={12}>
          <VFlow vSpacing={0.8}>
            <Text fontWeight='bold'>Nacionalidade</Text>
            <HFlow>
              <RadioGroupField
                name={name.nacionalidade}
                options={[
                  { value: NacionalidadeEnum.BRASILEIRA, label: 'Brasileira' },
                  { value: NacionalidadeEnum.NATURALIZADA, label: 'Naturalizado' },
                  { value: NacionalidadeEnum.ESTRANGEIRA, label: 'Estrangeiro' },
                ]}
              />
            </HFlow>
          </VFlow>
        </Cell>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              <Cell size={6}>
                {values.nacionalidade === NacionalidadeEnum.ESTRANGEIRA && (
                  <PaisSelectField name={name.paisNascimento} label='País de nascimento' omiteBrasil={true} required />
                )}
                {values.nacionalidade === NacionalidadeEnum.NATURALIZADA && (
                  <TextField
                    name={name.portariaNaturalizacao}
                    label='Portaria de naturalização'
                    maxLength={16}
                    parse={onlyNumbers}
                    required
                  />
                )}
                {values.nacionalidade === NacionalidadeEnum.BRASILEIRA && (
                  <MunicipioSelectField name={name.municipioNascimento} label='Município de nascimento' required />
                )}
              </Cell>
              <Cell size={3}>
                {values.nacionalidade === NacionalidadeEnum.ESTRANGEIRA && (
                  <DateField name={name.dataEntradaPais} label='Data de entrada no Brasil' required />
                )}
                {values.nacionalidade === NacionalidadeEnum.NATURALIZADA && (
                  <DateField name={name.dataNaturalizacao} label='Data de naturalização' required />
                )}
              </Cell>
            </>
          )}
        </FormSpy>
        <Cell size={12}>
          <CheckboxField name={name.cidadaoFaleceu} label='Cidadão faleceu' />
        </Cell>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <>
              {values.cidadaoFaleceu && (
                <>
                  <Cell size={3}>
                    <DateField name={name.dataObito} label='Data de óbito' required />
                  </Cell>
                  <Cell size={3}>
                    <DeclaracaoObitoField name={name.numeroDeclaracaoObito} label='Número da declaração de óbito' />
                  </Cell>
                </>
              )}
            </>
          )}
        </FormSpy>
      </Grid>
    </>
  )
}
