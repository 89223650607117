import { css } from '@emotion/core'
import { Cell, Grid, InfoLabel, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { RegistroVacinacao } from 'graphql/types.generated'
import React from 'react'
import { formatDate } from 'util/date/formatDate'

import { vacinadoExterior } from '../../../utils/utils-historico'
import HistoricoDetailTable from '../../table/HistoricoDetailTable'

interface HistoricoVacinacoesAnterioresTableBoxProps {
  registrosVacinacao: RegistroVacinacao[]
}

export default function HistoricoVacinacoesAnterioresTableBox(props: HistoricoVacinacoesAnterioresTableBoxProps) {
  const { registrosVacinacao } = props

  return (
    <HistoricoDetailTable title='Vacinação' sectionStyle='RA' hasLines>
      <VFlow vSpacing={0}>
        {registrosVacinacao.map((registroVacinacao) => (
          <div key={registroVacinacao.id}>
            <Grid
              gapVertical={1}
              style={css`
                padding: 0.5rem 0;
              `}
            >
              <Cell size={4}>
                <InfoLabel title='Imunobiológico/Dose'>
                  {`${registroVacinacao.imunobiologico.nome}, ${registroVacinacao.doseImunobiologico.sigla}`}
                </InfoLabel>
              </Cell>
              <Cell size={4}>
                <InfoLabel title='Lote/Fabricante'>
                  {`${registroVacinacao.loteImunobiologico?.lote ?? '-'}/${
                    registroVacinacao.loteImunobiologico?.fabricante?.nome ?? '-'
                  }`}
                </InfoLabel>
              </Cell>
              <Cell size={2}>
                <InfoLabel title='Data de aplicação'>{formatDate(registroVacinacao.dataAplicacao)}</InfoLabel>
              </Cell>
              <Cell size={2}>
                <InfoLabel title='Vacinado no exterior'>
                  {vacinadoExterior(registroVacinacao.isAplicadoExterior)}
                </InfoLabel>
              </Cell>
              {registroVacinacao.estrategiaVacinacao && (
                <Cell size={3}>
                  <HLabel title='Estratégia:'>{registroVacinacao.estrategiaVacinacao.nome.titleCase()}</HLabel>
                </Cell>
              )}
              {registroVacinacao.observacoes && (
                <Cell size={12}>
                  <HLabel title='Observações:'>{registroVacinacao.observacoes}</HLabel>
                </Cell>
              )}
            </Grid>
          </div>
        ))}
      </VFlow>
    </HistoricoDetailTable>
  )
}
