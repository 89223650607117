import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { GARANTIA_ACESSO_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { FormApi } from 'final-form'
import { useGarantiaAcessoCidadaoQuery } from 'graphql/hooks.generated'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router'

import { useGarantiaAcessoConflitantes } from '../hooks/useGarantiaAcessoConflitantes'
import { GarantiaAcessoFormModel } from '../model-garantiaAcesso'
import GarantiaAcessoCeoFormView from './GarantiaAcessoCeoFormView'
import GarantiaAcessoFormView from './GarantiaAcessoFormView'

interface GarantiaAcessoFormProps {
  cidadaoId?: ID
}

export default function GarantiaAcessoForm(props: GarantiaAcessoFormProps) {
  const { cidadaoId } = props
  const alert = useAlert()
  const history = useHistory()
  const { analytics } = useFirebase()
  const {
    acesso: { unidadeSaude },
    tipoEstabelecimento,
  } = useAcessoLotacaoOrEstagio()
  const { verificarRegistrosConflitantes } = useGarantiaAcessoConflitantes()
  const isCEO = tipoEstabelecimento === TipoEstabelecimentoEnum.CEO
  const {
    data: { cidadao },
  } = useGarantiaAcessoCidadaoQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id: cidadaoId,
    },
    skip: !cidadaoId,
  })

  const initialValues: GarantiaAcessoFormModel = useMemo(() => {
    if (cidadao?.faleceu === false) {
      return {
        cidadao: { ...cidadao, presenteListaAtendimento: false },
        equipe: { ...cidadao.cidadaoVinculacaoEquipe?.equipe },
      }
    }
  }, [cidadao])

  const resetFormFields = (formApi: FormApi) => {
    formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
    setTimeout(formApi.reset)
    history.replace(GARANTIA_ACESSO_PATH)
  }

  const handleSaveSuccess = () => {
    analytics.logEvent('adicionar_registro_GGA', { tipo_de_estabelecimento: tipoEstabelecimento })
    alert('success', 'Cidadão foi adicionado com sucesso.')
  }

  const handleRegistrosConflitantes = (values: GarantiaAcessoFormModel) => {
    return verificarRegistrosConflitantes(unidadeSaude.id, cidadao || values.cidadao, isCEO, values)
  }

  return isCEO ? (
    <GarantiaAcessoCeoFormView
      initialValues={initialValues}
      onRegistrosConflitantes={handleRegistrosConflitantes}
      onResetFormFields={resetFormFields}
      onSaveSuccess={handleSaveSuccess}
    />
  ) : (
    <GarantiaAcessoFormView
      unidadeSaudeId={unidadeSaude.id}
      initialValues={initialValues}
      onRegistrosConflitantes={handleRegistrosConflitantes}
      onResetFormFields={resetFormFields}
      onSaveSuccess={handleSaveSuccess}
    />
  )
}
