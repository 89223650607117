import ApolloClient from 'apollo-client'
import { CidadaoAtendimentoSelectFieldModel } from 'components/form'
import { GarantiaAcessoCidadaoDocument } from 'graphql/hooks.generated'
import { MetaRoot } from 'util/metaPath'

import { GarantiaAcessoFormModel } from './model-garantiaAcesso'

export const createEquipeCalculations = (
  meta: MetaRoot<GarantiaAcessoFormModel>,
  unidadeSaudeId: ID,
  apollo: ApolloClient<object>
) => [
  {
    field: meta.cidadao.absolutePath(),
    updates: {
      [meta.equipe.absolutePath()]: (cidadao: CidadaoAtendimentoSelectFieldModel) => {
        if (cidadao) {
          return apollo
            .query({
              query: GarantiaAcessoCidadaoDocument,
              variables: { id: cidadao.id },
            })
            .then((res) => {
              const unidadeSaudeVinculadaId = res.data.cidadao?.cidadaoVinculacaoEquipe?.unidadeSaude?.id
              if (unidadeSaudeVinculadaId === unidadeSaudeId) {
                return res.data.cidadao.cidadaoVinculacaoEquipe.equipe
              }
            })
        }
      },
    },
  },
]
