/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, ButtonProps, HFlow, Icon, Tooltip } from 'bold-ui'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { useCallback } from 'react'

interface AcessarVideochamadaButtonProps extends ButtonProps {
  videochamadaUuid: string
}

export function AcessarVideochamadaButton(props: AcessarVideochamadaButtonProps) {
  const { videochamadaUuid, children = 'Entrar na videochamada', onClick, ...rest } = props

  const handleClick = useCallback(() => {
    window.open(
      `/videochamada/${videochamadaUuid}`,
      'videochamada',
      `width=${window.screen.availWidth}, heigth=${window.screen.availHeight}, noreferrer`
    )
  }, [videochamadaUuid])

  return (
    <Tooltip text='Entrar na chamada de vídeo'>
      <Button kind='primary' size='small' onClick={composeHandlers(handleClick, onClick)} {...rest}>
        <HFlow hSpacing={0.5} alignItems='center'>
          <Icon icon='videoFilled' />
          {children}
        </HFlow>
      </Button>
    </Tooltip>
  )
}
