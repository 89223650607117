/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TableCell, TableRow, Tooltip, useTheme } from 'bold-ui'
import { ParteBucalEnum } from 'graphql/types.generated'
import { memo, useMemo } from 'react'

import {
  getPeriogramaCompletoCondicao,
  PeriogramaCompletoCondicao,
  PeriogramaCompletoFace,
  PeriogramaCompletoQuadrante,
  PeriogramaCompletoTableModel,
} from '../model'
import { PipsModel } from '../periogramaCompletoCalculos'
import { periogramaCompletoTableStyles } from './PeriogramaCompletoTableView'
import PeriogramaCompletoTableViewCell from './PeriogramaCompletoTableViewCell'

export interface PeriogramaCompletoTableViewRowProps {
  quadrante: PeriogramaCompletoQuadrante
  face: PeriogramaCompletoFace
  dentes: ParteBucalEnum[]
  condicao: PeriogramaCompletoCondicao
  pips: PipsModel
  values: PeriogramaCompletoTableModel
}

function PeriogramaCompletoTableViewRow(props: PeriogramaCompletoTableViewRowProps) {
  const { face, dentes, condicao: condicaoEnum, pips, values } = props

  const theme = useTheme()
  const classes = useMemo(() => periogramaCompletoTableStyles(theme), [theme])

  const condicao = useMemo(() => getPeriogramaCompletoCondicao(condicaoEnum), [condicaoEnum])

  return (
    <TableRow>
      <Tooltip text={condicao.longTitle}>
        <TableCell style={classes.firstColumn}>{condicao.title}</TableCell>
      </Tooltip>

      {dentes.map((dente, key) => (
        <PeriogramaCompletoTableViewCell
          key={key}
          condicao={condicaoEnum}
          ausente={values?.[dente]?.ausente}
          value={values?.[dente]?.values?.[face]?.[condicaoEnum]}
          pip={pips?.[dente]?.[face]}
        />
      ))}
    </TableRow>
  )
}

export default memo(PeriogramaCompletoTableViewRow)
