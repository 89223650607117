import { SerializedStyles } from '@emotion/core'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

interface DireitosDoUsuarioDoServicoSectionProps {
  listStyle: SerializedStyles
}

export const DireitosDoUsuarioDoServicoSection = (props: DireitosDoUsuarioDoServicoSectionProps) => {
  const { listStyle } = props

  return (
    <TermosDeUsoHeadingSection title='5. Direitos do Usuário do Serviço' id='direitos_do_usuario_do_serviço'>
      <p>De acordo com a Lei nº 13.460, de 26 de junho de 2017, são direitos básicos do usuário:</p>
      <br />
      <ol type='I' css={listStyle}>
        <li>Participação no acompanhamento da prestação e na avaliação dos serviços;</li>
        <li>
          Obtenção e utilização dos serviços com liberdade de escolha entre os meios oferecidos e sem discriminação;
        </li>
        <li>
          Acesso e obtenção de informações relativas à sua pessoa constantes de registros ou bancos de dados, observado
          o disposto no inciso X do caput do art. 5º da Constituição Federal e na Lei nº 12.527, de 18 de novembro de
          2011;
        </li>
        <li>Proteção de suas informações pessoais, nos termos da Lei nº 12.527, de 18 de novembro de 2011;</li>
        <li>
          Atuação integrada e sistêmica na expedição de atestados, certidões e documentos comprobatórios de
          regularidade; e
        </li>
        <li>
          Obtenção de informações precisas e de fácil acesso nos locais de prestação do serviço, assim como sua
          disponibilização na internet, especialmente sobre:
          <ol type='a'>
            <br />
            <li>horário de funcionamento das unidades administrativas;</li>
            <li>
              serviços prestados pelo órgão ou entidade, sua localização exata e a indicação do setor responsável pelo
              atendimento ao público;
            </li>
            <li>acesso ao agente público ou ao órgão encarregado de receber manifestações;</li>
            <li>situação da tramitação dos processos administrativos em que figure como interessado; e</li>
            <li>
              valor das taxas e tarifas cobradas pela prestação dos serviços, contendo informações para a compreensão
              exata da extensão do serviço prestado.
            </li>
          </ol>
        </li>
      </ol>
    </TermosDeUsoHeadingSection>
  )
}
