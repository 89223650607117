import { Theme, useStyles } from 'bold-ui'
import { Interpolation } from 'emotion'
import React, { CSSProperties } from 'react'

export interface TitleGroupProps {
  title: string | React.ReactNode
  subtitle?: React.ReactNode
  style?: Interpolation
}

export function TitleGroup(props: TitleGroupProps) {
  const { title, subtitle, style } = props

  const { classes, css } = useStyles(createStyles)

  return (
    <hgroup className={css(classes.group, style)}>
      {typeof title === 'string' ? (
        <h1 className={classes.title} data-testid={title + '.Title'}>
          {title}
        </h1>
      ) : (
        title
      )}
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
    </hgroup>
  )
}

const createStyles = (theme: Theme) => ({
  group: {},
  title: {},
  subtitle: {
    fontSize: theme.typography.sizes.text,
    margin: '0',
    lineHeight: '1.5',
    fontWeight: 'normal',
  } as CSSProperties,
})
