/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, VFlow } from 'bold-ui'
import {
  AccordionDataTableRowDefault,
  AccordionDataTableRowDefaultProps,
} from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'
import { EditableRow } from 'view/atendimentos/detail/components/EditableList'

import { OrientacaoFormModel, OrientacaoRowModel } from '../types/OrientacoesModel'
import { OrientacoesForm } from './OrientacoesForm'

interface OrientacoesTableRowProps extends AccordionDataTableRowDefaultProps<OrientacaoRowModel> {
  isEditing: boolean
  onUpdate(index: number, value: OrientacaoFormModel): void
  removeOrientacaoEmEdicao(): void
}

export function OrientacoesTableRow(props: OrientacoesTableRowProps) {
  const { row, isEditing, onUpdate, removeOrientacaoEmEdicao } = props

  const handleChange = (orientacao: OrientacaoFormModel) => {
    onUpdate(row.cacheId, orientacao)
    removeOrientacaoEmEdicao()
  }

  return (
    <VFlow vSpacing={0}>
      <AccordionDataTableRowDefault {...props} />
      {isEditing && (
        <EditableRow editing={isEditing}>
          <Heading
            level={4}
            style={css`
              padding-top: 1rem;
            `}
          >
            Editar orientação
          </Heading>
          <OrientacoesForm
            onSubmit={handleChange}
            initialValues={row}
            onCancel={removeOrientacaoEmEdicao}
            initialValuesEqual={(oldInitial, newInitial) => oldInitial.descricao === newInitial.descricao}
          />
        </EditableRow>
      )}
    </VFlow>
  )
}
