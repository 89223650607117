/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme } from 'bold-ui'
import theme from 'config/theme'
import { Fragment, useMemo } from 'react'

import Coroa, { CoroaFaceEnum } from '../Coroa'
import { CoroaFaceModel, CoroaModel } from '../types/Coroa'
import { DenteProps } from '../types/Dente'

export interface OdontogramaCellProps {
  placement: 'superior' | 'inferior'
  children: React.ReactElement<DenteProps, any>
  number: number
  coroaValue?: CoroaModel
  protese?: boolean
  isDeciduo?: boolean
  disabled?: boolean
  disableCoroa?: boolean
  disableStateEffects?: boolean
  readonly?: boolean
  onClickCoroa?(face: CoroaFaceModel, faceEnum: CoroaFaceEnum): void
}

export function OdontogramaCell(props: OdontogramaCellProps) {
  const {
    placement,
    children,
    number,
    coroaValue,
    isDeciduo,
    protese,
    disabled,
    disableCoroa,
    disableStateEffects,
    readonly,
    onClickCoroa,
  } = props
  const classes = useMemo(() => createStyles(theme), [])

  return (
    <div css={classes.cell}>
      {placement === 'superior' ? (
        <Fragment>
          {children}

          <Text style={[classes.label, classes.labelCellSuperior]}>{number}</Text>

          <Coroa
            css={classes.coroaDenteSuperior}
            disableStateEffects={disableStateEffects}
            disabled={disabled || disableCoroa || protese}
            protese={protese}
            isDeciduo={isDeciduo}
            value={coroaValue}
            readonly={readonly}
            onClickCoroaTop={(face) => onClickCoroa(face, CoroaFaceEnum.FaceTop)}
            onClickCoroaLeft={(face) => onClickCoroa(face, CoroaFaceEnum.FaceLeft)}
            onClickCoroaRight={(face) => onClickCoroa(face, CoroaFaceEnum.FaceRight)}
            onClickCoroaBottom={(face) => onClickCoroa(face, CoroaFaceEnum.FaceBottom)}
            onClickCoroaCenter={(face) => onClickCoroa(face, CoroaFaceEnum.FaceCenter)}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Coroa
            css={classes.coroaDenteInferior}
            disableStateEffects={disableStateEffects}
            disabled={disabled || disableCoroa || protese}
            value={coroaValue}
            protese={protese}
            isDeciduo={isDeciduo}
            readonly={readonly}
            onClickCoroaTop={(face) => onClickCoroa(face, CoroaFaceEnum.FaceTop)}
            onClickCoroaLeft={(face) => onClickCoroa(face, CoroaFaceEnum.FaceLeft)}
            onClickCoroaRight={(face) => onClickCoroa(face, CoroaFaceEnum.FaceRight)}
            onClickCoroaBottom={(face) => onClickCoroa(face, CoroaFaceEnum.FaceBottom)}
            onClickCoroaCenter={(face) => onClickCoroa(face, CoroaFaceEnum.FaceCenter)}
          />

          <Text style={[classes.label, classes.labelCellInferior]}>{number}</Text>

          {children}
        </Fragment>
      )}
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  cell: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-width: 3.25rem;
    flex: 1;
    ${theme.breakpoints.down('lg')} {
      width: auto;
      flex: 1;
    },
  `,
  label: css`
    font-size: 1rem;
    font-weight: bold;
  `,
  labelCellSuperior: css`
    margin-top: 1rem;
  `,
  labelCellInferior: css`
    margin-bottom: 1rem;
  `,
  coroaDenteSuperior: css`
    margin-top: 0.5rem;
  `,
  coroaDenteInferior: css`
    margin-bottom: 0.5rem;
  `,
})
