/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel, Text, Theme, useTheme } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useEncaminhamentoEspecializadoByIdQuery } from 'graphql/hooks.generated'
import { Fragment } from 'react'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { EncaminhamentoExternoEspecializadoModel } from '../../model'
import { convertEncaminhamentoEspecializado, convertEncaminhamentoEspecializadoRecente } from './converter'
import { EncaminhamentoExternoDetalheLotacao } from './EncaminhamentoExternoDetalheLotacao'

interface EncaminhamentoExternoEspecializadoRecenteDetalhesPanelProps {
  isRegistradoAgora: true
  encaminhamento: EncaminhamentoExternoEspecializadoModel
  lotacao: LotacaoAtendimento
}

interface EncaminhamentoExternoEspecializadoDetalhesPanelProps {
  isRegistradoAgora: false
  encaminhamentoId: ID
}

export default function EncaminhamentoExternoEspecializadoDetalhesPanel(
  props:
    | EncaminhamentoExternoEspecializadoDetalhesPanelProps
    | EncaminhamentoExternoEspecializadoRecenteDetalhesPanelProps
) {
  const theme = useTheme()
  const classes = getStyles(theme)

  const { data, loading } = useEncaminhamentoEspecializadoByIdQuery({
    variables: { id: props.isRegistradoAgora === false && props.encaminhamentoId },
    skip: props.isRegistradoAgora,
  })

  const encaminhamento = props.isRegistradoAgora
    ? convertEncaminhamentoEspecializadoRecente(props.encaminhamento, props.lotacao)
    : convertEncaminhamentoEspecializado(data?.encaminhamentoEspecializado)

  return loading ? (
    <LoadingIndicator />
  ) : (
    <div css={classes.container}>
      <Grid>
        <Fragment>
          <Cell size={4}>
            <InfoLabel title='Especialidade'>{encaminhamento.especialidade}</InfoLabel>
          </Cell>
          <Cell size={8}>
            <div css={classes.secondColumn}>
              <InfoLabel title='Complemento'>{encaminhamento.complemento}</InfoLabel>
            </div>
          </Cell>

          <Cell size={4}>
            <InfoLabel title={`Hipótese/Diagnóstico (${encaminhamento.renderCiap ? 'CIAP2' : 'CID10'})`}>
              {encaminhamento.hipoteseDiagnostica}
            </InfoLabel>
          </Cell>
          <Cell size={8}>
            <div css={classes.secondColumn}>
              <InfoLabel title='Classificação de risco/Vulnerabilidade'>{encaminhamento.classificacaoRisco}</InfoLabel>
            </div>
          </Cell>

          <Cell size={12} style={classes.richText}>
            <InfoLabel title='Motivo do encaminhamento'>
              {encaminhamento.motivo && <Text dangerouslySetInnerHTML={{ __html: encaminhamento.motivo }} />}
            </InfoLabel>
          </Cell>

          <Cell size={12} style={classes.richText}>
            <InfoLabel title='Observações'>
              {encaminhamento.observacoes && <Text dangerouslySetInnerHTML={{ __html: encaminhamento.observacoes }} />}
            </InfoLabel>
          </Cell>
        </Fragment>
        <Cell size={12}>
          <EncaminhamentoExternoDetalheLotacao
            nome={encaminhamento.lotacao.nome}
            cpf={encaminhamento.lotacao.cpf}
            cns={encaminhamento.lotacao.cns}
            cbo={encaminhamento.lotacao.cbo}
          />
        </Cell>
      </Grid>
    </div>
  )
}

const getStyles = (theme: Theme) => ({
  container: css`
    background: ${theme.pallete.gray.c90};
    padding: 1rem;
  `,
  richText: css`
    word-break: break-word;
  `,
  secondColumn: css`
    padding-left: 1.25rem;
  `,
})
