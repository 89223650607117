/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tag, Theme, useTheme } from 'bold-ui'
import { OrigemAtendimento } from 'graphql/types.generated'

interface TagOrigemAcompanhamentoCidadaosVinculadosProps {
  origem: OrigemAtendimento
}

export const TagOrigemAcompanhamentoCidadaosVinculados = (props: TagOrigemAcompanhamentoCidadaosVinculadosProps) => {
  const { origem } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return <Tag style={origem === OrigemAtendimento.CDS ? styles.cds : styles.pec}>{origem}</Tag>
}

const createStyles = (theme: Theme) => ({
  pec: css`
    background-color: ${theme.pallete.primary.c80};
    color: ${theme.pallete.gray.c10};
  `,
  cds: css`
    background-color: ${theme.pallete.primary.c30};
    color: ${theme.pallete.gray.c100};
  `,
})
