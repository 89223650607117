import { intervalToDuration, parseISO } from 'date-fns'

export interface Age {
  days: number
  months: number
  years: number
}

export function calculateAge(birthDate: string | number, refDate: string | number = Date.now()): Age {
  const endDate = refDate || Date.now()
  const { years, months, days } = intervalToDuration({
    start: isString(birthDate) ? parseISO(birthDate) : birthDate,
    end: isString(endDate) ? parseISO(endDate) : endDate,
  })
  return { years, months, days }
}

function isString(x: any): x is string {
  return typeof x === 'string'
}

export function monthsToYearsFloor(months: number): number {
  return Math.floor(months / 12)
}
