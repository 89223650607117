/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import { EvolucaoSubjetivo } from 'graphql/types.generated'
import { Fragment } from 'react'
import { naoRegistradoNesseAtendimento } from 'view/atendimentos/detail/historico/utils/utils-historico'

import MotivoConsultaBox from './MotivoConsultaBox'

export interface HistoricoSubjetivoPecPanelProps {
  evolucaoSubjetivo: EvolucaoSubjetivo
}

export default function HistoricoSubjetivoPecPanel({ evolucaoSubjetivo }: HistoricoSubjetivoPecPanelProps) {
  const descricaoEvolucaoSubjetivo = evolucaoSubjetivo?.descricao?.removeTags()
  const hasSubjetivo = descricaoEvolucaoSubjetivo || evolucaoSubjetivo?.ciaps

  return (
    <Fragment>
      {hasSubjetivo ? (
        <VFlow>
          {descricaoEvolucaoSubjetivo && <Text dangerouslySetInnerHTML={{ __html: evolucaoSubjetivo.descricao }} />}
          {evolucaoSubjetivo.ciaps && <MotivoConsultaBox subjetivoCiaps={evolucaoSubjetivo.ciaps} />}
        </VFlow>
      ) : (
        <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
      )}
    </Fragment>
  )
}
