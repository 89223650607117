import { LoteImunobiologicoQuery } from 'graphql/types.generated'

export const covidImunosRecord: Record<string, string> = {
  '85': 'OXFORD-ASTRAZENECA',
  '86': 'SINOVAC/BUTANTAN',
  '87': 'PFIZER/BIONTECH',
  '88': 'JANSSEN-CILAG',
  '99': 'COMIRNATY-PFIZER/BIONTECH',
  '89': 'ASTRAZENECA-ChAdOx1-S',
  '95': 'GAMALEYA-SPUTNIK-V',
  '96': 'BHARAT-COVAXIN',
  '97': 'MODERNA',
  '98': 'SINOVAC-CORONAVAC',
  '102': 'COMIRNATY-PFIZER/BIONTECH',
  '103': 'COMIRNATY-PFIZER/BIONTECH',
  '105': 'MODERNA',
}

export type LoteImunobiologicoModel = LoteImunobiologicoQuery['lotesImunobiologico']['content'][0]
