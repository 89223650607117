import { HFlow, Icon, VFlow } from 'bold-ui'
import { Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import { TransmissaoRecebimentoLoteFiltro } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import {
  TransmissaoRecebimentoLoteFilterPopper,
  TransmissaoRecebimentoLoteFilterPopperModel,
} from './TransmissaoRecebimentoLoteFilterPopper'
import { TransmissaoRecebimentoLoteFilterTags } from './TransmissaoRecebimentoLoteFilterTags'

export type TransmissaoRecebimentoLoteFilterModel = Omit<TransmissaoRecebimentoLoteFiltro, 'periodo' | 'origensLote'> &
  TransmissaoRecebimentoLoteFilterPopperModel

interface TransmissaoRecebimentoLoteFilterProps {
  filter: TransmissaoRecebimentoLoteFilterModel
  setFilter(values: TransmissaoRecebimentoLoteFilterModel): void
  resetFilter: () => void
}

const path = metaPath<TransmissaoRecebimentoLoteFilterModel>()

export function TransmissaoRecebimentoLoteFilter(props: TransmissaoRecebimentoLoteFilterProps) {
  const { filter, setFilter, resetFilter } = props

  const popperInitialValues = useMemo(() => ({ origensLote: filter.origensLote, periodo: filter.periodo }), [
    filter.origensLote,
    filter.periodo,
  ])

  const handleChange = (formState: FormState<TransmissaoRecebimentoLoteFilterModel>) => {
    setFilter(formState.values)
  }

  const onSubmitPopper = (values: TransmissaoRecebimentoLoteFilterPopperModel) => setFilter({ ...filter, ...values })

  const renderPopper = ({ close }: PopperControls) => (
    <TransmissaoRecebimentoLoteFilterPopper
      onSubmit={onSubmitPopper}
      onReset={resetFilter}
      onClose={close}
      initialValues={popperInitialValues}
    />
  )

  const renderFilter = () => (
    <HFlow alignItems='center' justifyContent='space-between'>
      <FormDebouncedValueSpy onChange={handleChange} />

      <TextField
        name={path.query}
        icon='zoomOutline'
        style={{ width: '19rem' }}
        placeholder='Pesquise por lote ou responsável'
      />

      <PopperButton kind='primary' size='small' placement='bottom-end' renderPopper={renderPopper}>
        <Icon icon='filterOutline' />
        Filtros
      </PopperButton>
    </HFlow>
  )

  return (
    <VFlow vSpacing={0.5}>
      <Form initialValues={filter} render={renderFilter} />
      <TransmissaoRecebimentoLoteFilterTags filter={filter} />
    </VFlow>
  )
}
