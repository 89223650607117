import useSession from 'components/auth/useSession'
import { differenceInCalendarDays, differenceInHours } from 'date-fns'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { useEffect } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { InstantRange } from 'util/date/dateRange'

import { AtendimentosFilterModel } from '../filter/model'
import { useListaAtendimentoFilterDefault } from '../filter/useListaAtendimentoFilterDefault'

/*
  Atualiza o período, lastDailyUpdate e filterDefault
  a cada duração definida pelo tipo de estabelecimento
 */

interface Props {
  state: AtendimentosFilterModel
  setState: React.Dispatch<React.SetStateAction<AtendimentosFilterModel>>
  updateFilterDefault: (newFilterDefault?: AtendimentosFilterModel) => void
  updateBackupPeriodo: (newPeriodo: InstantRange) => void
}

export function useUpdateListaAtendimentoState(props: Props) {
  const { state, setState, updateBackupPeriodo, updateFilterDefault } = props

  const { getServerTimeNow } = useServerTime()
  const { tipoEstabelecimento } = useSession()
  const { getFilterDefault } = useListaAtendimentoFilterDefault()

  const serverTimeNow = getServerTimeNow().getTime()

  useEffect(() => {
    const shouldUpdate = getShouldUpdate(serverTimeNow, state.lastDailyUpdate, tipoEstabelecimento)

    if (shouldUpdate) {
      const updatedFilterDefault = getFilterDefault()

      updateFilterDefault(updatedFilterDefault)
      updateBackupPeriodo(updatedFilterDefault.filter.periodo)

      setState((prevValue) => ({
        ...prevValue,
        filter: {
          ...prevValue.filter,
          periodo: updatedFilterDefault.filter.periodo,
        },
        lastDailyUpdate: serverTimeNow,
      }))
    }
  }, [
    getFilterDefault,
    serverTimeNow,
    setState,
    state.lastDailyUpdate,
    tipoEstabelecimento,
    updateBackupPeriodo,
    updateFilterDefault,
  ])
}

const getShouldUpdate = (
  serverTimeNow: Instant,
  lastDailyUpdate: Instant,
  tipoEstabelecimento: TipoEstabelecimentoEnum
) => {
  if (isUndefinedOrNull(lastDailyUpdate)) {
    return true
  } else if (tipoEstabelecimento === TipoEstabelecimentoEnum.UPA) {
    return differenceInHours(serverTimeNow, lastDailyUpdate) > 23
  } else {
    return differenceInCalendarDays(serverTimeNow, lastDailyUpdate) > 0
  }
}
