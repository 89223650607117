/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Icon } from 'bold-ui'
import { ColorScale } from 'bold-ui/lib/styles/colors'

type ParticipanteVideochamadaIconProps = {
  color: ColorScale
}

export function ParticipanteVideochamadaIcon(props: ParticipanteVideochamadaIconProps) {
  const { color } = props
  const styles = createStyles(color)

  return (
    <div css={styles.iconContainer}>
      <Icon icon='userFilled' color='white' style={styles.icon} />
    </div>
  )
}

const createStyles = (color: ColorScale) => ({
  iconContainer: css`
    border-radius: 50%;
    background-color: ${color.c40};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
    aspect-ratio: 1/1;
  `,
  icon: css`
    width: 100%;
    height: 100%;
    margin: 15%;
  `,
})
