import { Alert, FormError, FormErrorProps } from 'bold-ui'
import React from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { resolveName } from '../hooks/useField'
import { getFieldError, getSimpleFieldError } from '../util'

export interface ErrorFieldProps extends FormErrorProps {
  name: string | MetaPath<any>
  type?: 'alert' | 'form-error'
  ignoreObjectError?: boolean
  ignoreTouchedAndDirty?: boolean
  inline?: boolean
}

export function ErrorField(props: ErrorFieldProps) {
  const { name, ignoreObjectError, ignoreTouchedAndDirty, type, inline = true, ...rest } = props

  const renderField = (renderProps: FieldRenderProps<any, HTMLElement>) => {
    const { meta } = renderProps

    const error = ignoreTouchedAndDirty ? getSimpleFieldError(meta) : getFieldError(meta)

    const ignore = ignoreObjectError && typeof error !== 'string'

    if (error && !ignore) {
      return type === 'alert' ? (
        <Alert type='danger' inline={inline} {...(rest as any)}>
          {error}
        </Alert>
      ) : (
        <FormError {...rest}>{error}</FormError>
      )
    }

    return null
  }

  return <Field name={resolveName(name)} render={renderField} />
}
