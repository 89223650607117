import { useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

export function SideMenuItem(props: React.LiHTMLAttributes<HTMLLIElement>) {
  const { classes } = useStyles(createStyles)
  return <li className={classes.item} {...props} />
}

export const createStyles = () => ({
  item: {
    padding: '0.75rem 1.5rem',
  } as CSSProperties,
})
