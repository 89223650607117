import { HFlow, Icon, TableCell, TableRow, Text, Tooltip } from 'bold-ui'
import { VinculacaoEquipePorEstabelecimentoDto } from 'graphql/types.generated'
import React from 'react'

import { EquipeEMultiVinculacoesItem } from './EquipeEMultiVinculacoesTable'
import { EquipesVinculadasEstabelecimentoPanel } from './EquipesVinculadasEstabelecimentoPanel'

interface EquipeEMultiVinculacoesTableRowProps {
  equipeEMultiVinculacao: EquipeEMultiVinculacoesItem
  expandedRow: string
  handleClick(equipeEMultiVinculacao: EquipeEMultiVinculacoesItem): any
}

export function EquipeEMultiVinculacoesTableRow(props: EquipeEMultiVinculacoesTableRowProps) {
  const { equipeEMultiVinculacao, expandedRow, handleClick } = props
  const { equipeEmulti } = equipeEMultiVinculacao
  const vinculacoesPorEstabelecimento = equipeEMultiVinculacao.vinculacoesPorEstabelecimento as VinculacaoEquipePorEstabelecimentoDto[]

  const isExpanded = expandedRow === equipeEmulti.id

  return (
    <React.Fragment key={equipeEmulti.id}>
      <Tooltip placement='left-start' text={isExpanded ? 'Minimizar' : 'Expandir'}>
        <TableRow onClick={handleClick(equipeEMultiVinculacao)}>
          <TableCell>
            <HFlow alignItems='center' justifyContent='flex-start'>
              <Icon icon={isExpanded ? 'angleUp' : 'angleDown'} />
              <Text>
                <b>{`${equipeEmulti.nome.toUpperCase()} - ${equipeEmulti.ine}`}</b> | <b>Equipes vinculadas: </b>
                {equipeEMultiVinculacao?.quantidadeVinculacoes}
              </Text>
            </HFlow>
          </TableCell>
        </TableRow>
      </Tooltip>
      {expandedRow && isExpanded && vinculacoesPorEstabelecimento && (
        <TableRow style={{ backgroundColor: '#f0f0f5' }}>
          <TableCell style={{ padding: 0 }}>
            <EquipesVinculadasEstabelecimentoPanel vinculacoesPorEstabelecimento={vinculacoesPorEstabelecimento} />
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}
