import { Tabs } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { TabLink } from 'components/route'
import { PecSwitch } from 'components/route/PecSwitch'
import { PrivateRoute } from 'components/route/PrivateRoute'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'
import { Redirect, useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import { ConfiguracoesCertificadosRootView } from './certificados/ConfiguracoesCertificadosRootView'
import { ConfiguracaoAgendaMunicipioRootView } from './configuracao-padrao/ConfiguracaoAgendaMunicipioRootView'
import { GrupoExameRootView } from './grupos-exames/GrupoExameRootView'
import { RndsView } from './rnds/RndsView'
import { VincularEstabelecimentoReferenciaView } from './servicos-referencia/estabelecimento/vincular/VincularEstabelecimentoReferenciaView'
import { SERVICOS_REFERENCIA_PATH, ServicosReferenciaView } from './servicos-referencia/ServicosReferenciaView'
import { TipoServicoView } from './tipo-servico/TipoServicoView'

export function GestaoMunicipalRootView() {
  const match = useRouteMatch()
  const { hasAuthorization } = useSession()
  const { ENVIO_RNDS_ENABLED, PILOTO_VINCULACAO_SERVICO_1_ENABLED } = useFlags()

  return (
    <>
      <Breadcrumb title='Gestão municipal' />
      <PecSwitch>
        <PrivateRoute
          path={`${match.url}${SERVICOS_REFERENCIA_PATH}/adicionar`}
          component={VincularEstabelecimentoReferenciaView}
          permission={Permissions.configuracaoMunicipal}
        />
        <PrivateRoute
          path={`${match.url}${SERVICOS_REFERENCIA_PATH}/editar/:estabelecimentoReferenciaId(\\d+)`}
          component={VincularEstabelecimentoReferenciaView}
          permission={Permissions.configuracaoMunicipal}
        />

        <PrivateRoute path={match.url} permission={Permissions.configuracaoMunicipal}>
          <PageHeaderSection title='Gestão municipal'>
            <Tabs>
              <CheckPermission permission={Permissions.configuracaoMunicipal.configurarAgendaPadrao}>
                <TabLink to={`${match.url}/cfgagendapadrao`}>Configuração da agenda padrão</TabLink>
              </CheckPermission>
              <CheckPermission permission={Permissions.configuracaoMunicipal.configurarCertificado}>
                <TabLink to={`${match.url}/cfgcertificados`}>Certificados</TabLink>
              </CheckPermission>
              <CheckPermission permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirGruposDeExames}>
                <TabLink to={`${match.url}/gpexames`}>Grupos de exames</TabLink>
              </CheckPermission>
              <CheckPermission permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirTipoDeServico}>
                <TabLink to={`${match.url}/tpservico`}>Tipos de serviço</TabLink>
              </CheckPermission>
              {ENVIO_RNDS_ENABLED && (
                <CheckPermission permission={Permissions.configuracaoMunicipal.visualizarEnvioRnds}>
                  <TabLink to={`${match.url}/rnds`}>RNDS</TabLink>
                </CheckPermission>
              )}
              {PILOTO_VINCULACAO_SERVICO_1_ENABLED && (
                <CheckPermission permission={Permissions.configuracaoMunicipal}>
                  <TabLink to={`${match.url}${SERVICOS_REFERENCIA_PATH}`}>Serviços de referência</TabLink>
                </CheckPermission>
              )}
            </Tabs>
          </PageHeaderSection>
          <PageContent>
            <PecSwitch>
              {hasAuthorization(Permissions.configuracaoMunicipal.configurarAgendaPadrao) && (
                <Redirect exact from={`${match.url}`} to={`${match.url}/cfgagendapadrao`} />
              )}
              {hasAuthorization(Permissions.configuracaoMunicipal.configurarCertificado) && (
                <Redirect exact from={`${match.url}`} to={`${match.url}/cfgcertificados`} />
              )}
              {hasAuthorization(Permissions.configuracaoMunicipal.cadastrarEditarEExcluirGruposDeExames) && (
                <Redirect exact from={`${match.url}`} to={`${match.url}/gpexames`} />
              )}
              {hasAuthorization(Permissions.configuracaoMunicipal.cadastrarEditarEExcluirTipoDeServico) && (
                <Redirect exact from={`${match.url}`} to={`${match.url}/tpservico`} />
              )}
              {hasAuthorization(Permissions.configuracaoMunicipal.visualizarEnvioRnds) && ENVIO_RNDS_ENABLED && (
                <Redirect exact from={`${match.url}`} to={`${match.url}/rnds`} />
              )}
              {hasAuthorization(Permissions.configuracaoMunicipal) && PILOTO_VINCULACAO_SERVICO_1_ENABLED && (
                <Redirect exact from={`${match.url}`} to={`${match.url}${SERVICOS_REFERENCIA_PATH}`} />
              )}

              <PrivateRoute
                path={`${match.url}/cfgagendapadrao`}
                component={ConfiguracaoAgendaMunicipioRootView}
                permission={Permissions.configuracaoMunicipal.configurarAgendaPadrao}
              />
              <PrivateRoute
                path={`${match.url}/cfgcertificados`}
                component={ConfiguracoesCertificadosRootView}
                permission={Permissions.configuracaoMunicipal.configurarCertificado}
              />
              <PrivateRoute
                path={`${match.url}/gpexames`}
                component={GrupoExameRootView}
                permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirGruposDeExames}
              />
              <PrivateRoute
                path={`${match.url}/tpservico`}
                component={TipoServicoView}
                permission={Permissions.configuracaoMunicipal.cadastrarEditarEExcluirTipoDeServico}
              />
              <PrivateRoute
                path={`${match.url}/rnds`}
                component={RndsView}
                permission={ENVIO_RNDS_ENABLED && Permissions.configuracaoMunicipal.visualizarEnvioRnds}
              />
              <PrivateRoute
                path={`${match.url}${SERVICOS_REFERENCIA_PATH}`}
                component={ServicosReferenciaView}
                permission={PILOTO_VINCULACAO_SERVICO_1_ENABLED && Permissions.configuracaoMunicipal}
              />
            </PecSwitch>
          </PageContent>
        </PrivateRoute>
      </PecSwitch>
    </>
  )
}
