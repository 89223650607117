import useAtmosphere from 'hooks/useAtmosphere'

import { UnificacaoBaseTopic } from './model'

interface UnificacaoBaseListenerProps {
  handleMessage(message: UnificacaoBaseTopic)
}

export const UnificacaoBaseListener = (props: UnificacaoBaseListenerProps) => {
  const { handleMessage } = props

  useAtmosphere<UnificacaoBaseTopic>({
    topic: `unificacao-base`,
    onMessage: handleMessage,
  })

  return null
}
