import { useTheme } from 'bold-ui'
import { IconLabel } from 'components/IconLabel'
import React from 'react'

export function AlertCidadaoNaoAceitouCC() {
  const theme = useTheme()
  return (
    <IconLabel
      icon='exclamationTriangleFilled'
      color={theme.pallete.status.alert.main}
      text='O cidadão não concordou com agendamento da consulta com o especialista mediada por
                          tecnologia durante o atendimento em que houve a solicitação de cuidado compartilhado.'
    />
  )
}
