import { SelectField } from 'components/form'
import { EquipeSelectModel } from 'components/form/field/select/EquipeAdSelectField/EquipeAdSelectField'
import { resolveName } from 'components/form/final-form/hooks/useField'
import { MicroareasSelectFieldDocument } from 'graphql/hooks.generated'
import { MicroareasSelectFieldQuery, MicroareasSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { AsyncSelectFieldProps, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface MicroareaSelectFieldProps extends AsyncSelectFieldProps<string> {
  metaEquipe: MetaPath<EquipeSelectModel>
}

const itemToString = (item: string) => item

export function MicroareaSelectField(props: MicroareaSelectFieldProps) {
  const { metaEquipe, ...rest } = props

  const {
    input: { value: equipe },
  } = useField<EquipeSelectModel>(resolveName(metaEquipe), { subscription: { value: true } })

  const { selectProps } = useAsyncQuerySelect<string, MicroareasSelectFieldQuery, MicroareasSelectFieldQueryVariables>({
    query: MicroareasSelectFieldDocument,
    refetchOnFilterChange: false,
    extractItems: (data) => data?.microareas,
    variables: () => ({ equipeResponsavelId: equipe?.id }),
    itemToString,
  })

  return <SelectField<string> {...rest} {...selectProps} multiple itemToString={itemToString} />
}
