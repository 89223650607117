import { Select } from 'bold-ui'
import { DEFAULT_SELECT_PAGE_PARAM, DEFAULT_SELECT_SIZE } from 'components/form/field/select/useAsyncQuerySelect'
import { usePeriodoOdontogramaSelectQuery } from 'graphql/hooks.generated'
import { PeriodoOdontogramaSelectQuery } from 'graphql/types.generated'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { OdontogramaUseMode } from '../../model'

export interface PeriodoOdontogramaSelectModel {
  odontogramaId: ID
  atendimentoId: ID
  dataIniciado: Date
  label: string
}

interface PeriodoOdontogramaSelectProps {
  prontuarioId: ID
  useMode: OdontogramaUseMode
  readOnly?: boolean
  onChange(item: PeriodoOdontogramaSelectModel): void
}

const initialItem = {
  odontogramaId: null,
  label: 'Atual',
} as PeriodoOdontogramaSelectModel

export const PeriodoOdontogramaSelect = (props: PeriodoOdontogramaSelectProps) => {
  const { prontuarioId, useMode, onChange, readOnly } = props
  const [value, setValue] = useState<PeriodoOdontogramaSelectModel>(readOnly ? null : initialItem)
  const [items, setItems] = useState<PeriodoOdontogramaSelectModel[]>(readOnly ? [] : [initialItem])

  const {
    data: { odontogramas },
  } = usePeriodoOdontogramaSelectQuery({
    variables: {
      input: {
        prontuarioId: prontuarioId,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          size: DEFAULT_SELECT_SIZE,
        },
      },
    },
  })

  useEffect(() => {
    setItems(convertOdontogramasInputToPeriodoModel(odontogramas?.content, readOnly))
  }, [odontogramas, readOnly])

  useEffect(() => {
    if (readOnly && items && !value) setValue(items[0])
  }, [items, readOnly, value])

  useEffect(() => {
    if (useMode === OdontogramaUseMode.INSERTING) setValue(items[0])
  }, [items, useMode])

  useEffect(() => {
    onChange(items[0])
  }, [items, onChange])

  return (
    <Select<PeriodoOdontogramaSelectModel>
      label='Período'
      clearable={false}
      items={items}
      itemToString={(item) => item?.label}
      value={value}
      onChange={(item: PeriodoOdontogramaSelectModel) => {
        setValue(item)
        onChange(item)
      }}
    />
  )
}

function convertOdontogramasInputToPeriodoModel(
  odontogramas: PeriodoOdontogramaSelectQuery['odontogramas']['content'],
  readOnly: boolean
) {
  const items =
    odontogramas?.map(
      (odontograma) =>
        odontograma.id &&
        ({
          odontogramaId: odontograma.id,
          atendimentoId: odontograma.atendimentoProfissionalOdonto.id,
          dataIniciado: odontograma.atendimentoProfissionalOdonto.atendimentoProfissional.iniciadoEm,
          label: `${moment(odontograma.atendimentoProfissionalOdonto.atendimentoProfissional.iniciadoEm).format(
            'DD/MM/YYYY'
          )}`,
        } as PeriodoOdontogramaSelectModel)
    ) || []

  if (!readOnly) items.unshift(initialItem)
  return items
}
