import { HFlow, Icon, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { lotacaoAgendamentoToString } from 'view/agenda/util-agenda'

import { AgendamentoCidadaoModel } from '../../model'

interface AgendamentoCompartilhadoCidadaoProfissionaisAgendadosAgendadaProps
  extends Pick<AgendamentoCidadaoModel, 'profissionalAnfitriao' | 'profissionalParticipante'> {
  nomeUnidadeSaude?: string
}

export function AgendamentoCompartilhadoCidadaoProfissionaisAgendados(
  props: AgendamentoCompartilhadoCidadaoProfissionaisAgendadosAgendadaProps
) {
  const { profissionalAnfitriao, profissionalParticipante, nomeUnidadeSaude } = props

  return (
    <VFlow vSpacing={0.5}>
      <HFlow hSpacing={0.5}>
        <Icon icon='userFilled' size={1} fill='primary' />
        <VFlow vSpacing={0}>
          <HLabel title='Profissional anfitrião:'>
            {lotacaoAgendamentoToString({
              nomeProfissional: profissionalAnfitriao?.nome,
              nomeCbo: profissionalAnfitriao?.cbo?.nome,
              ineEquipe: profissionalAnfitriao?.equipe?.ine,
              nomeEquipe: profissionalAnfitriao?.equipe?.nome,
            })}
          </HLabel>
          <HLabel title='Profissional convidado:'>
            {lotacaoAgendamentoToString({
              nomeProfissional: profissionalParticipante?.nome,
              nomeCbo: profissionalParticipante?.lotacao.cbo?.nome,
              ineEquipe: profissionalParticipante?.lotacao.equipe?.ine,
              nomeEquipe: profissionalParticipante?.lotacao.equipe?.nome,
            })}
          </HLabel>
        </VFlow>
      </HFlow>
      {!!nomeUnidadeSaude && (
        <HFlow hSpacing={0.5}>
          <Icon icon='hospital' size={1} fill='primary' />
          <Text fontWeight='bold'> {nomeUnidadeSaude.titleCase()}</Text>
        </HFlow>
      )}
    </VFlow>
  )
}
