import { Link } from 'bold-ui'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const OperadorSection = () => {
  return (
    <TermosDeUsoHeadingSection title='4. Operador' id='operador'>
      <p>
        <b>Quem realiza o tratamento de dados (Operador)?</b>
      </p>
      <br />
      <p>
        A Lei Geral de Proteção de Dados Pessoais define como operador, em seu Art. 5º, inciso VII, a pessoa natural ou
        jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.
      </p>
      <br />
      <p>
        Para o Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS, quem realiza o tratamento de dados pessoais
        em nome do controlador é o operador,{' '}
        <b>
          <i>no que tange ao desenvolvimento do sistema</i>
        </b>
        :
      </p>
      <br />
      <p>Nome: Laboratório Bridge</p>
      <br />
      <p>Endereço: Torre Flora - R. Lauro Linhares, 2055 - Sala 902 - Trindade, Florianópolis - SC, 88036-003.</p>
      <br />
      <p>
        E-mail:{' '}
        <Link fontSize={0.875} href='mailto:contato@bridge.ufsc.br'>
          contato@bridge.ufsc.br
        </Link>
      </p>
      <br />
      <p>Telefone: (48) 3721-6190</p>
      <br />
      <p>
        Outros operadores de dados que atuem em nome dos controladores relacionados aos estados e municípios, serão
        informados aos titulares de dados pelos respectivos entes federativos.
      </p>
    </TermosDeUsoHeadingSection>
  )
}
