import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { RacaCorDbEnum } from 'graphql/types.generated'
import React from 'react'

export interface RacaCorSelectModel {
  id: ID
  nome: string
  value: RacaCorDbEnum
}

export interface RacaCorSelectFieldProps extends Omit<SelectFieldProps<RacaCorSelectModel>, 'items' | 'itemToString'> {}

export function RacaCorSelectField(props: RacaCorSelectFieldProps) {
  const itemToString = (item: RacaCorSelectModel) => item && item.nome

  return (
    <SelectField<RacaCorSelectModel>
      items={items.sort((a, b) => (a.nome > b.nome ? 1 : -1))}
      itemToString={itemToString}
      {...props}
    />
  )
}

const items: RacaCorSelectModel[] = [
  { id: 1, nome: 'BRANCA', value: RacaCorDbEnum.BRANCA },
  { id: 2, nome: 'PRETA', value: RacaCorDbEnum.PRETA },
  { id: 3, nome: 'AMARELA', value: RacaCorDbEnum.AMARELA },
  { id: 4, nome: 'PARDA', value: RacaCorDbEnum.PARDA },
  { id: 5, nome: 'INDÍGENA', value: RacaCorDbEnum.INDIGENA },
]
