import axios, { AxiosPromise } from 'axios'

export interface ImpressaoCadernetaVacinacaoInput {
  atendimentoId?: ID
  prontuarioId: ID
}

export class ImpressaoCadernetaVacinacao {
  imprimirCadernetaVacinacao(input: ImpressaoCadernetaVacinacaoInput): AxiosPromise<Blob> {
    return axios.post('/api/atendimento/imprimirAcompanhamentoVacinacao', input, { responseType: 'blob' })
  }
}
