import { Cell, Grid, HeadingSection, InfoLabel } from 'bold-ui'
import { Cep } from 'components/label'
import React from 'react'
import { capitalize, titleCase } from 'util/strConversor'

export interface EnderecoSectionModel {
  bairro?: string
  cep?: string
  complemento?: string
  logradouro?: string
  municipio?: {
    nome: string
  }
  numero?: string
  pontoReferencia?: string
  semNumero?: boolean
  tipoLogradouro?: {
    nome: string
  }
  uf?: {
    sigla: string
  }
}

export interface EnderecoSectionProps {
  endereco: EnderecoSectionModel
  title: string
  renderTipoLogradouro?: boolean
}

export function EnderecoSection(props: EnderecoSectionProps) {
  const { renderTipoLogradouro } = props
  return (
    <HeadingSection level={2} title={props.title}>
      <Grid wrap>
        <Cell size={12} data-cy='EnderecoSection.cep'>
          <InfoLabel title='CEP'>
            <Cep value={props.endereco && props.endereco.cep} />
          </InfoLabel>
        </Cell>
        <Cell size={4} data-cy='EnderecoSection.estado'>
          <InfoLabel title='Estado'>{props.endereco && props.endereco.uf && props.endereco.uf.sigla}</InfoLabel>
        </Cell>
        <Cell size={4} data-cy='EnderecoSection.municipio'>
          <InfoLabel title='Município'>{titleCase(props.endereco?.municipio?.nome)}</InfoLabel>
        </Cell>
        <Cell size={4} data-cy='EnderecoSection.bairro'>
          <InfoLabel title='Bairro'>{titleCase(props.endereco?.bairro)}</InfoLabel>
        </Cell>
        {renderTipoLogradouro && (
          <Cell size={4} data-cy='EnderecoSection.tipoLogradouro'>
            <InfoLabel title='Tipo de logradouro'>{titleCase(props.endereco?.tipoLogradouro?.nome)}</InfoLabel>
          </Cell>
        )}
        <Cell size={4} data-cy='EnderecoSection.logradouro'>
          <InfoLabel title='Logradouro'>{titleCase(props.endereco?.logradouro)}</InfoLabel>
        </Cell>
        <Cell size={4} data-cy='EnderecoSection.numero'>
          <InfoLabel title='Número'>
            {props.endereco && props.endereco.semNumero ? 'S/N' : props.endereco?.numero?.toUpperCase()}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-cy='EnderecoSection.complemento'>
          <InfoLabel title='Complemento'>{titleCase(props.endereco?.complemento)}</InfoLabel>
        </Cell>
        <Cell size={4} data-cy='EnderecoSection.pontoReferencia'>
          <InfoLabel title='Ponto de referência'>{capitalize(props.endereco?.pontoReferencia)}</InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}
