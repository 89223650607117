import { Cell, Grid, Text } from 'bold-ui'
import { FilterItem } from 'components/filteritem'
import { isSameDay } from 'date-fns'
import { css } from 'emotion'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { dateAsDdMmYyyy } from 'util/date/formatDate'

import { listaRegistroTardioStyles } from '../utils/listaRegistroTardioStyles'
import { ListaRegistroTardioFilterModel } from './ListaRegistroTardioFilter'

interface ListaRegistroTardioFilterTagsProps {
  filtros: ListaRegistroTardioFilterModel
}

export function ListaRegistroTardioFilterTags(props: ListaRegistroTardioFilterTagsProps) {
  const { filtros } = props
  const { getServerTimeNow } = useServerTime()
  const parsedStartDate = filtros.periodo.startDate && new Date(filtros.periodo.startDate)
  const parsedEndDate = filtros.periodo.endDate && new Date(filtros.periodo.endDate)

  const now = getServerTimeNow()

  const statusAtendimentoText = filtros.statusAtendimento
    ?.map((item) => listaRegistroTardioStyles[item].descricao)
    .join(', ')

  const statusAtendimento = filtros.statusAtendimento?.length > 0 && (
    <FilterItem label='Status do registro:' value={statusAtendimentoText} />
  )

  const startDateText = isSameDay(parsedStartDate, now) ? 'hoje' : dateAsDdMmYyyy(parsedStartDate)

  const endDateText = isSameDay(parsedEndDate, now) ? 'hoje' : dateAsDdMmYyyy(parsedEndDate)

  const periodoStartAndEndDateIsSameAndToday =
    isSameDay(parsedStartDate, parsedEndDate) && isSameDay(parsedStartDate, now)

  const periodoText = !periodoStartAndEndDateIsSameAndToday
    ? filtros?.periodo?.startDate && filtros?.periodo?.endDate && `${startDateText} até ${endDateText}`.capitalize()
    : 'Hoje'

  const periodo = !filtros.isSomenteNaoFinalizados && filtros.periodo?.startDate && filtros.periodo?.endDate && (
    <FilterItem
      label='Período:'
      value={periodoText}
      style={css`
        overflow: visible;
      `}
    />
  )

  const showSomenteNaoFinalizados = filtros.isSomenteNaoFinalizados && (
    <FilterItem
      value='Somente registros não finalizados'
      textStyle={css`
        font-weight: bold;
      `}
    />
  )

  const lotacoesText = filtros.responsaveis?.map((item) => item.profissional.nome).join(', ')

  const lotacoes = filtros.responsaveis?.length > 0 && <FilterItem label='Profissionais:' value={lotacoesText} />

  return (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {statusAtendimento || periodo || lotacoes ? (
        <>
          {statusAtendimento}
          {showSomenteNaoFinalizados}
          {periodo}
          {lotacoes}
        </>
      ) : (
        <Cell>
          <Text>'Sem filtros ativos'</Text>
        </Cell>
      )}
    </Grid>
  )
}
