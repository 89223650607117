import { Cell, Grid } from 'bold-ui'
import { CheckboxField, FormDebouncedValueSpy, TextField } from 'components/form'
import { FormState } from 'final-form'
import { HistoricoPrescricaoQueryInput } from 'graphql/types.generated'
import React from 'react'
import { Form } from 'react-final-form'

export interface HistoricoPrescricaoTableFilterProps {
  initialValues?: HistoricoPrescricaoQueryInput
  onChange(values: HistoricoPrescricaoQueryInput): void
}

export default function HistoricoPrescricaoTableFilter(props: HistoricoPrescricaoTableFilterProps) {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<HistoricoPrescricaoQueryInput>) => {
    props.onChange(formState.values)
  }

  const renderForm = () => {
    return (
      <Grid alignItems='center' gap={1}>
        <FormDebouncedValueSpy onChange={handleChange} />
        <Cell size={4}>
          <TextField name='query' placeholder='Pesquise por medicamento' icon='zoomOutline' />
        </Cell>
        <Cell size={6}>
          <CheckboxField name='usoContinuo' label='Ver apenas as prescrições que possuem medicamento de uso contínuo' />
        </Cell>
      </Grid>
    )
  }

  return <Form<HistoricoPrescricaoQueryInput> onSubmit={onChange} render={renderForm} initialValues={initialValues} />
}
