/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  HeadingSection,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableHeader,
  TableLoadingRow,
  TablePlaceholderRow,
  TableRow,
} from 'bold-ui'
import { TableBox, usePagedTableProps } from 'components/table'
import theme from 'config/theme'
import { useHistoricoEvolucoesOdontoTableQuery } from 'graphql/hooks.generated'
import {
  EvolucoesProcedimentoOdontoInput,
  HistoricoEvolucoesOdontoTableQuery,
  PageInfo,
  TipoParteBucalEnum,
} from 'graphql/types.generated'
import { useCallback, useMemo, useState } from 'react'

import { HistoricoEvolucoesOdontoTableHeader } from './HistoricoEvolucoesOdontoTableHeader'
import { HistoricoEvolucoesOdontoTableRow } from './HistoricoEvolucoesOdontoTableRow'

export type HistoricoEvolucoesOdontoModel = HistoricoEvolucoesOdontoTableQuery['evolucoesProcedimentoOdonto']['content']
export type HistoricoEvolucoesOdontoTableRowModel = HistoricoEvolucoesOdontoTableQuery['evolucoesProcedimentoOdonto']['content'][0]

// usado pra fazer o content required pro usePagedTableProps, uma vez que no type da query ele é opcional
export type EvolucoesOdontoTableModel = {
  content: HistoricoEvolucoesOdontoModel
  pageInfo: PageInfo
}

interface HistoricoEvolucoesOdontoTableProps {
  prontuarioId: ID
  showEmpty?: boolean
  isFolhaRosto?: boolean
}

export function HistoricoEvolucoesOdontoTable(props: HistoricoEvolucoesOdontoTableProps) {
  const { prontuarioId, showEmpty, isFolhaRosto } = props

  const [filterState, setFilterState] = useState<EvolucoesProcedimentoOdontoInput>({
    tipoPartesBucais: [
      TipoParteBucalEnum.DENTE,
      TipoParteBucalEnum.ARCADA,
      TipoParteBucalEnum.OUTRO,
      TipoParteBucalEnum.SUPRANUMERARIO,
    ],
    prontuarioId,
    pageParams: {
      size: 5,
    },
  })

  const {
    data: { evolucoesProcedimentoOdonto },
    loading,
  } = useHistoricoEvolucoesOdontoTableQuery({
    variables: { input: filterState },
  })

  const { rows, loading: isLoading, ...tableFooterProps } = usePagedTableProps({
    loading,
    result: evolucoesProcedimentoOdonto as EvolucoesOdontoTableModel,
    onChange: setFilterState,
  })

  const isNotEmpty = useMemo(() => rows?.length > 0, [rows])

  const onChangeFilter = useCallback((filter: string) => {
    setFilterState((state) => ({
      ...state,
      query: filter,
    }))
  }, [])

  return isNotEmpty || !!filterState.query || isLoading || showEmpty ? (
    <HeadingSection level={isFolhaRosto ? 4 : 3} title='Histórico de evoluções odontológicas'>
      <TableBox header={<HistoricoEvolucoesOdontoTableHeader onChangeFilter={onChangeFilter} />}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader style={classes.dataProcedColumn}>Data dos procedimentos</TableHeader>
              <TableHeader style={classes.localColumn}>Local</TableHeader>
              <TableHeader style={classes.procedColumn}>Procedimentos</TableHeader>
              <TableHeader style={classes.profColumn}>Profissional</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoadingRow colSpan={4} />
            ) : isNotEmpty ? (
              rows.map((row) => <HistoricoEvolucoesOdontoTableRow key={row.id} evolucaoOdonto={row} />)
            ) : (
              <TablePlaceholderRow colSpan={4} />
            )}
          </TableBody>
        </Table>
        <TableFooter
          {...tableFooterProps}
          style={classes.tableFooter}
          pageSize={filterState.pageParams?.size}
          sizeOptions={[5, 15, 25, 50]}
        />
      </TableBox>
    </HeadingSection>
  ) : null
}

const classes = {
  dataProcedColumn: css`
    max-width: 9.375rem;
  `,
  localColumn: css`
    max-width: 6.875rem;
  `,
  procedColumn: css`
    max-width: 13.75rem;
  `,
  profColumn: css`
    max-width: 14.375rem;
  `,
  emptyList: css`
    color: ${theme.pallete.gray.c40};
    text-align: center;
    font-style: italic;
  `,
  tableFooter: css`
    border-top: 0;
    background-color: ${theme.pallete.surface.main};
  `,
}
