/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HFlow, Icon, Text, VFlow } from 'bold-ui'
import { css } from 'emotion'
import React, { useMemo } from 'react'
import { emptyArray } from 'util/array'

import { AccordionCardSection } from '../components/AccordionCardSection'
import { ContentCardSection } from '../components/ContentCardSection'
import { MedicamentoBarraLateralModel } from '../types/MedicamentoBarraLateralModel'
import { ItemMedicamentoBarraLateral } from './ItemMedicamentoBarraLateral'
import { mergeMedicamentoAtivoConcluido } from './util/mergeMedicamentoAtivoConcluido'

export interface MedicamentosCardProps {
  listaMedicamentosAtivos: MedicamentoBarraLateralModel[]
  listaMedicamentosInativos: MedicamentoBarraLateralModel[]
  loading: boolean
  loadingTratamentoConcluido: boolean
  isCidadaoIdoso: boolean
  canRender: boolean
  mostrarInativos: boolean
}

const renderPolifarmacia = () => (
  <HFlow
    alignItems='center'
    hSpacing={0.5}
    style={css`
      margin-bottom: 0.25rem;
    `}
  >
    <Icon
      icon='exclamationTriangleOutline'
      style={css`
        margin-left: 0.4rem;
      `}
      size={1}
      fill='alert'
    />
    <Text color='alert'>5 ou mais medicamentos de uso contínuo</Text>
  </HFlow>
)

export function MedicamentosCard(props: MedicamentosCardProps) {
  const {
    isCidadaoIdoso,
    listaMedicamentosAtivos = emptyArray,
    listaMedicamentosInativos = emptyArray,
    loading,
    loadingTratamentoConcluido,
    canRender,
    mostrarInativos,
  } = props

  const {
    medicamentosAtivosExpandido,
    medicamentosAtivosAccordion,
    medicamentosConcluidosExpandido,
    medicamentosConcluidosAccordion,
  } = useMemo(() => mergeMedicamentoAtivoConcluido(listaMedicamentosAtivos, listaMedicamentosInativos), [
    listaMedicamentosAtivos,
    listaMedicamentosInativos,
  ])

  const qtdMedicamentosAccordion = medicamentosAtivosAccordion.length + medicamentosConcluidosAccordion.length

  const isPolifarmacia = useMemo(
    () => isCidadaoIdoso && listaMedicamentosAtivos.filter((medicamento) => medicamento.usoContinuo).length > 4,
    [listaMedicamentosAtivos, isCidadaoIdoso]
  )

  return (
    <React.Fragment>
      <ContentCardSection
        isLoading={loading || loadingTratamentoConcluido}
        messageLoading='Carregando dados dos medicamentos'
        canRender={canRender}
      >
        <VFlow vSpacing={0.5}>
          {isPolifarmacia && renderPolifarmacia()}
          {medicamentosAtivosExpandido.length > 0 && (
            <VFlow vSpacing={0.5}>
              {mostrarInativos && <Text fontWeight='bold'>Em uso:</Text>}
              {medicamentosAtivosExpandido?.map((medicamento) => (
                <ItemMedicamentoBarraLateral key={medicamento.id} medicamento={medicamento} />
              ))}
            </VFlow>
          )}
          {medicamentosConcluidosExpandido.length > 0 && (
            <VFlow vSpacing={0.5}>
              <Text fontWeight='bold'>Concluídos nos últimos 3 meses:</Text>
              {medicamentosConcluidosExpandido?.map((medicamento) => (
                <ItemMedicamentoBarraLateral key={medicamento.id} medicamento={medicamento} concluido />
              ))}
            </VFlow>
          )}
        </VFlow>
      </ContentCardSection>
      {qtdMedicamentosAccordion > 0 && (
        <AccordionCardSection
          id='MedicamentosCard'
          title={{
            closed: `Mostrar + ${qtdMedicamentosAccordion} medicamentos`,
            open: `Esconder medicamentos`,
          }}
          tooltip={{ closed: 'Expandir medicamentos', open: 'Esconder medicamentos' }}
        >
          <ContentCardSection>
            <VFlow vSpacing={0.5}>
              {medicamentosAtivosAccordion.length > 0 && (
                <VFlow vSpacing={0.5}>
                  {medicamentosAtivosAccordion.map((medicamento) => (
                    <ItemMedicamentoBarraLateral key={medicamento.id} medicamento={medicamento} />
                  ))}
                </VFlow>
              )}
              {medicamentosConcluidosAccordion.length > 0 && (
                <VFlow vSpacing={0.5}>
                  {medicamentosConcluidosExpandido.length === 0 && (
                    <Text fontWeight='bold'>Concluídos nos últimos 3 meses:</Text>
                  )}
                  {medicamentosConcluidosAccordion?.map((medicamento) => (
                    <ItemMedicamentoBarraLateral key={medicamento.id} medicamento={medicamento} concluido />
                  ))}
                </VFlow>
              )}
            </VFlow>
          </ContentCardSection>
        </AccordionCardSection>
      )}
    </React.Fragment>
  )
}
