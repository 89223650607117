/** @jsx jsx */
import { jsx } from '@emotion/core'
import { AntecedenteCirurgiasInternacoes, AntecedenteItemDto } from 'graphql/types.generated'
import { useMemo } from 'react'
import HistoricoSection from 'view/atendimentos/detail/historico/components/HistoricoSection'

import HistoricoAntecedentesPecPanel from '../../HistoricoAntecedentesPecPanel'

interface HistoricoAntecedenteSectionProps {
  antecedentes: AntecedenteItemDto
  cirurgiasInternacoes: AntecedenteCirurgiasInternacoes[]
  dataNascimento: LocalDate
}

export default function HistoricoAntecedenteSection(props: HistoricoAntecedenteSectionProps) {
  const { antecedentes, cirurgiasInternacoes, dataNascimento } = props

  const hasAntecedentes = useMemo(() => {
    return antecedentes?.observacoes || antecedentes?.puericultura || antecedentes?.informacoesObstetricas
  }, [antecedentes])

  return (
    (hasAntecedentes || cirurgiasInternacoes) && (
      <HistoricoSection section='AN'>
        <HistoricoAntecedentesPecPanel
          observacoes={antecedentes?.observacoes}
          puericultura={antecedentes?.puericultura}
          informacoesObstetricas={antecedentes?.informacoesObstetricas}
          cirurgiasInternacoes={cirurgiasInternacoes}
          dataNascimento={dataNascimento}
        />
      </HistoricoSection>
    )
  )
}
