/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, HFlow, VFlow } from 'bold-ui'
import { FormAccordionButton } from 'components/accordion/FormSectionAccordionButton'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import useOnBlur from 'components/form/final-form/hooks/useOnBlur'
import { HLabel } from 'components/HLabel'
import { ValidationErrors } from 'final-form'
import { ParteBucalEnum } from 'graphql/types.generated'
import { useCallback, useEffect, useState } from 'react'
import { Accordion, AccordionItem, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion'
import { useForm } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { getParteBucal } from '../../../util-EvolucoesOdontologicasPlano'
import { PeriogramaCompletoQuadrante, PeriogramaCompletoTableModel } from '../model'
import {
  calculaPipsPeriogramaCompleto,
  calculaPorcentagensPeriogramaCompleto,
  PipsModel,
} from '../periogramaCompletoCalculos'
import PeriogramaCompletoTableField from './PeriogramaCompletoTableField'
import { getDenteCurrentValue, getDentesAsIntegrosByArcada, getDentesAusentesByQuadrantes } from './util'

export interface PeriogramaCompletoFieldProps {
  name: MetaPath<PeriogramaCompletoTableModel>
}

const accordionSuperiorUuid = 'periograma-arcada-superior-accordion-item'
const accordionInferiorUuid = 'periograma-arcada-inferior-accordion-item'

export const PeriogramaCompletoField = (props: PeriogramaCompletoFieldProps) => {
  const { name } = props

  const [accordionOpened, setAccordionOpened] = useState({ superior: false, inferior: false })
  const [porcentagens, setPorcentagens] = useState({ placa: '0%', sang: '0%' })
  const [dentesAusentes, setDentesAusentes] = useState<Partial<Record<PeriogramaCompletoQuadrante, ParteBucalEnum[]>>>()
  const [pips, setPips] = useState<PipsModel>({})

  const {
    mutators: { changeValue },
    getState,
  } = useForm()

  const getStateResolved = useCallback(() => {
    const { values, errors } = getState()

    return {
      values: resolveValue(values, name),
      errors: resolveValue(errors, name),
    }
  }, [getState, name])

  const onChangeAccordion = useCallback(
    (uuids: string[]) => {
      if (!accordionOpened.superior || !accordionOpened.inferior) {
        setTimeout(() =>
          setAccordionOpened((prevState) => ({
            superior: prevState.superior || uuids.includes(accordionSuperiorUuid),
            inferior: prevState.inferior || uuids.includes(accordionInferiorUuid),
          }))
        )
      }
    },
    [accordionOpened.inferior, accordionOpened.superior]
  )

  const calculaPIPsOnBlur = useCallback((values: PeriogramaCompletoTableModel, errors: ValidationErrors) => {
    setPips(calculaPipsPeriogramaCompleto(values, errors))
  }, [])

  const calculaPorcentagensOnBlur = useCallback((values: PeriogramaCompletoTableModel) => {
    const { porcentagemPlaca, porcentagemSang } = calculaPorcentagensPeriogramaCompleto(values)

    setPorcentagens({ placa: porcentagemPlaca, sang: porcentagemSang })
  }, [])

  const updateAllDentesAusentes = useCallback(
    (values: PeriogramaCompletoTableModel) => {
      calculaPorcentagensOnBlur(values)
      setDentesAusentes(getDentesAusentesByQuadrantes(values))
    },
    [calculaPorcentagensOnBlur]
  )

  const setDenteAsAusente = useCallback(
    (dente: ParteBucalEnum) => {
      const { values } = getStateResolved()

      const denteCurrentValue = getDenteCurrentValue(dente, values)
      const newValue = { ...denteCurrentValue, ausente: !denteCurrentValue?.ausente }

      changeValue(name[dente], newValue)
      updateAllDentesAusentes({ ...values, [dente]: newValue })
    },
    [changeValue, getStateResolved, name, updateAllDentesAusentes]
  )

  const setDentesAsIntegrosByArcada = useCallback(
    (arcada: ParteBucalEnum) => {
      const { values } = getStateResolved()
      const newValues = getDentesAsIntegrosByArcada(arcada, values)

      if (newValues) {
        changeValue(name, newValues)
        setTimeout(() => {
          const { values, errors } = getStateResolved()

          calculaPIPsOnBlur(values, errors)
        })
      }
    },
    [calculaPIPsOnBlur, changeValue, getStateResolved, name]
  )

  useOnBlur({
    name: /PROF_SONDAGEM|RECESSAO|MOBILIDADE/g,
    callback: ({ allValues, errors }) => {
      calculaPIPsOnBlur(resolveValue(allValues, name), resolveValue(errors, name))
    },
  })

  useOnBlur({
    name: /PLACA|SANG_SONDAGEM/g,
    callback: ({ allValues }) => {
      calculaPorcentagensOnBlur(resolveValue(allValues, name))
    },
  })

  useEffect(() => {
    const { values, errors } = getStateResolved()

    calculaPIPsOnBlur(values, errors)
    calculaPorcentagensOnBlur(values)
    updateAllDentesAusentes(values)
  }, [calculaPIPsOnBlur, calculaPorcentagensOnBlur, getStateResolved, updateAllDentesAusentes])

  return (
    <VFlow vSpacing={1.5}>
      <Alert inline type='info'>
        Para finalizar o periograma completo é necessário preencher todos os campos.
      </Alert>

      <Accordion allowZeroExpanded allowMultipleExpanded onChange={onChangeAccordion}>
        <VFlow vSpacing={0.5}>
          <AccordionItem uuid={accordionSuperiorUuid}>
            <FormAccordionButton text={getParteBucal(ParteBucalEnum.ARCADA_SUPERIOR).title} />
            <AccordionItemPanel css={css(classes.accordionItemPanel)}>
              <AccordionItemState>
                {(state) =>
                  (state.expanded || accordionOpened.superior) && (
                    <VFlow>
                      <Button
                        kind='primary'
                        skin='outline'
                        size='small'
                        onClick={() => setDentesAsIntegrosByArcada(ParteBucalEnum.ARCADA_SUPERIOR)}
                      >
                        Marcar dentes não preenchidos como íntegros
                      </Button>

                      <PeriogramaCompletoTableField
                        name={name}
                        quadrante={PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA}
                        dentesAusentes={dentesAusentes?.ARCADA_SUPERIOR_DIREITA}
                        pips={pips}
                        onClickDenteAsAusente={setDenteAsAusente}
                      />

                      <PeriogramaCompletoTableField
                        name={name}
                        quadrante={PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA}
                        dentesAusentes={dentesAusentes?.ARCADA_SUPERIOR_ESQUERDA}
                        pips={pips}
                        onClickDenteAsAusente={setDenteAsAusente}
                      />
                    </VFlow>
                  )
                }
              </AccordionItemState>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem uuid={accordionInferiorUuid}>
            <FormAccordionButton text={getParteBucal(ParteBucalEnum.ARCADA_INFERIOR).title} />
            <AccordionItemPanel css={css(classes.accordionItemPanel)}>
              <AccordionItemState>
                {(state) =>
                  (state.expanded || accordionOpened.inferior) && (
                    <VFlow>
                      <Button
                        kind='primary'
                        skin='outline'
                        size='small'
                        onClick={() => setDentesAsIntegrosByArcada(ParteBucalEnum.ARCADA_INFERIOR)}
                      >
                        Marcar dentes não preenchidos como íntegros
                      </Button>

                      <PeriogramaCompletoTableField
                        name={name}
                        quadrante={PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA}
                        dentesAusentes={dentesAusentes?.ARCADA_INFERIOR_DIREITA}
                        pips={pips}
                        onClickDenteAsAusente={setDenteAsAusente}
                      />

                      <PeriogramaCompletoTableField
                        name={name}
                        quadrante={PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA}
                        dentesAusentes={dentesAusentes?.ARCADA_INFERIOR_ESQUERDA}
                        pips={pips}
                        onClickDenteAsAusente={setDenteAsAusente}
                      />
                    </VFlow>
                  )
                }
              </AccordionItemState>
            </AccordionItemPanel>
          </AccordionItem>
        </VFlow>
      </Accordion>
      <HFlow hSpacing={0.25}>
        <HLabel title='% de sítios com Placa'>{porcentagens?.placa}</HLabel>
        {'|'}
        <HLabel title='% de sítios com Sangramento'>{porcentagens?.sang}</HLabel>
      </HFlow>
    </VFlow>
  )
}

const classes = {
  accordionItemPanel: css`
    padding-top: 0.5rem;
  `,
}
