/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import {
  AccordionDataTableRowDefault,
  AccordionDataTableRowDefaultProps,
} from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'
import theme from 'config/theme'

import { TecidosMolesTableItemModel } from './model'

export default function TecidosMolesTableRow(props: AccordionDataTableRowDefaultProps<TecidosMolesTableItemModel>) {
  return (
    <VFlow vSpacing={0}>
      <AccordionDataTableRowDefault {...props} />

      {props.row.observacaoProcedimentos && (
        <HFlow hSpacing={0.2} style={classes.hFlow}>
          <Text fontWeight='bold'>Observações: </Text>
          <Text fontStyle='italic' style={classes.wordBreakAll}>
            <div dangerouslySetInnerHTML={{ __html: props.row.observacaoProcedimentos }} />
          </Text>
        </HFlow>
      )}

      {!props.row.isRegistradoAgora && (
        <HFlow hSpacing={0.2} style={css(classes.hFlow, classes.profissionalRow)}>
          <Text fontWeight='bold'>Profissional: </Text>
          <Text
            style={classes.wordBreakAll}
          >{`${props.row.lotacao.profissional.nome} - ${props.row.lotacao.cbo.nome}`}</Text>
        </HFlow>
      )}
    </VFlow>
  )
}

const classes = {
  hFlow: css`
    padding: 0.5rem 1rem;
  `,
  profissionalRow: css`
    border-top: 1px solid ${theme.pallete.divider};,
  `,
  wordBreakAll: css`
    word-break: break-all;
  `,
}
