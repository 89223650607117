import { cep, createValidator, ErrorObject, maxLength, required } from 'util/validation'
import { microarea } from 'util/validation/rules/microarea'

import { AtualizacaoImoveisFormModel, ReterritorializacaoBuscaFormModel } from './model'

export const validateReterritorializacaoBuscaForm = createValidator<ReterritorializacaoBuscaFormModel>(
  {
    cep: [cep],
    bairro: [maxLength(72)],
    logradouro: [maxLength(72)],
  },
  (values: ReterritorializacaoBuscaFormModel, errors: ErrorObject<ReterritorializacaoBuscaFormModel>) => {
    const qtdCamposPreenchidos = Object.values(values).filter((value) => !!value).length

    if (qtdCamposPreenchidos < 3 && values.bairro && values.bairro?.length < 4)
      errors.bairro = 'Informe ao menos 3 campos para realizar a busca.'

    if (qtdCamposPreenchidos < 3 && values.logradouro && values.logradouro?.length < 4)
      errors.logradouro = 'Informe ao menos 3 campos para realizar a busca.'

    return errors
  }
)

export const validateAtualizacaoImoveisForm = createValidator<AtualizacaoImoveisFormModel>({
  novaMicroarea: [microarea, required],
  lotacao: [required],
})
