import { gray, green, red } from 'bold-ui/lib/styles/colors'
import {
  Chart,
  ChartBody,
  ChartContainer,
  ChartFooter,
  DateRange,
  ReferenceArea,
  SeriesType,
  ValueRange,
} from 'components/chart'
import { SexoEnum } from 'graphql/types.generated'
import moment, { Duration, Moment } from 'moment'
import React from 'react'
import { reduceMedicoesIguais } from 'util/atendimento/reduceMedicoesIguais'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { LinhaTooltipGrafico } from '../../../LinhaTooltipGrafico'
import { getReferenceAreasCalculators, ReferenceAreasCalculator } from './referenceAreaCalculators'
import { TickIdade } from './TickIdade'

export interface GraficoAlturaProps {
  medicoes: HistoricoMedicaoModel[]
  dataNascimento: Moment
  dataRange: DateRange
  sexo: SexoEnum
}

export function GraficoAlturaView(props: GraficoAlturaProps) {
  const { medicoes, dataNascimento, dataRange, sexo } = props

  const maxIdadeNoRange = moment.duration(dataRange.end.diff(dataNascimento))
  const medicoesComAltura = reduceMedicoesIguais(medicoes, 'valorAltura') ?? []
  const seriesData = medicoesComAltura.map((m) => ({
    x: m.dataMedicao,
    y: m.valorAltura,
  }))
  const yRange = getYRange(maxIdadeNoRange)

  return (
    <ChartContainer>
      <ChartBody height={500}>
        <Chart<Moment>
          type={SeriesType.Area}
          series={[{ name: 'Altura', data: seriesData }]}
          referenceAreas={getReferenceAreas(sexo, dataRange, maxIdadeNoRange, dataNascimento)}
          xAxis={{
            title: 'Idade (meses completos e anos)',
            domain: dataRange,
            tickRenderer: (props) => <TickIdade {...props} dataNascimento={dataNascimento} />,
          }}
          yAxis={{
            title: maxIdadeNoRange.years() <= 2 ? 'Comprimento' : 'Altura',
            unit: 'cm',
            domain: yRange,
          }}
          showLegend={false}
          tooltip={{
            type: 'point',
            render: (points) => (
              <>
                {points?.map((p) => (
                  <LinhaTooltipGrafico key={p.y}>{`Altura: ${p.y} cm`}</LinhaTooltipGrafico>
                ))}
                <LinhaTooltipGrafico>{`Data: ${moment(points[0].x).format('DD/MM/YYYY')}`}</LinhaTooltipGrafico>
              </>
            ),
          }}
        />
      </ChartBody>
      <ChartFooter>{getFooterText(sexo, maxIdadeNoRange)}</ChartFooter>
    </ChartContainer>
  )
}

function getFooterText(sexo: SexoEnum, maxIdadeNoRange: Duration): string {
  if (maxIdadeNoRange.years() <= 10) {
    if (sexo === SexoEnum.MASCULINO) return 'Fonte: Caderneta de Saúde da Criança Menino - 2020.'
    else if (sexo === SexoEnum.FEMININO) return 'Fonte: Caderneta de Saúde da Criança Menina - 2020.'
    else return ''
  } else if (maxIdadeNoRange.years() <= 19) {
    if (sexo === SexoEnum.MASCULINO) return 'Fonte: Caderneta de Saúde do Adolescente - 2013.'
    else if (sexo === SexoEnum.FEMININO) return 'Fonte: Caderneta de Saúde da Adolescente - 2013.'
  }
  return ''
}

function getYRange(maxIdadeNoRange: Duration): ValueRange {
  if (maxIdadeNoRange.years() <= 2) return { init: 40, end: 105, step: 5 }
  else if (maxIdadeNoRange.years() <= 5) return { init: 75, end: 130, step: 5 }
  else if (maxIdadeNoRange.years() <= 10) return { init: 90, end: 165, step: 5 }
  else if (maxIdadeNoRange.years() <= 15) return { init: 100, end: 205, step: 5 }
  else if (maxIdadeNoRange.years() <= 19) return { init: 110, end: 210, step: 10 }
  else return { init: 110, end: 200, step: 5 }
}

function getReferenceAreas(
  sexo: SexoEnum,
  xRange: DateRange,
  idadeNoRange: Duration,
  dataNascimento: Moment
): ReferenceArea<Moment>[] {
  if (idadeNoRange.years() <= 19) {
    const refAreaCalculators = getReferenceAreasCalculators(sexo, dataNascimento, xRange, 'altura')
    return [
      getReferenceAreaMuitoBaixo(refAreaCalculators),
      getReferenceAreaBaixo(refAreaCalculators),
      getReferenceAreaAdequado(refAreaCalculators),
      getReferenceAreaElevado(refAreaCalculators),
      getReferenceAreaMuitoElevado(refAreaCalculators),
    ]
  } else return undefined
}

function getReferenceAreaMuitoBaixo(getsPercentis: ReferenceAreasCalculator[]): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit: referenceAreaCalculator(-3),
    })),
    name: 'Muito baixo',
    color: gray.c90,
    strokeColor: gray.c40,
    tickColor: gray.c40,
  }
}

function getReferenceAreaBaixo(getsPercentis: ReferenceAreasCalculator[]): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit: referenceAreaCalculator(-2),
    })),
    name: 'Baixo',
    color: red.c90,
    strokeColor: red.c60,
    tickColor: red.c60,
  }
}

function getReferenceAreaAdequado(getsPercentis: ReferenceAreasCalculator[]): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit: referenceAreaCalculator(2),
    })),
    name: 'Adequado',
    color: green.c90,
    strokeColor: red.c60,
    tickColor: green.c60,
  }
}

function getReferenceAreaElevado(getsPercentis: ReferenceAreasCalculator[]): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit: referenceAreaCalculator(3),
    })),
    name: 'Elevado',
    color: red.c90,
    strokeColor: gray.c40,
    tickColor: red.c60,
  }
}

function getReferenceAreaMuitoElevado(getsPercentis: ReferenceAreasCalculator[]): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date }) => ({
      x: date,
    })),
    name: 'Muito elevado',
    color: gray.c90,
    stroke: false,
    tickColor: gray.c40,
  }
}
