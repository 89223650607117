import { ExternalStyles, Heading, Icon, Icons, Theme, useStyles } from 'bold-ui'
import { ColorScale, gray } from 'bold-ui/lib/styles/colors'
import { AccordionButton } from 'components/accordion/AccordionButton'
import React, { CSSProperties } from 'react'
import { AccordionItemPanel } from 'react-accessible-accordion'

export interface SoapSectionProps {
  title: React.ReactNode
  initial?: string
  initialIcon?: Icons
  initialImage?: string
  altText?: string
  color?: ColorScale
  children: React.ReactNode
  style?: ExternalStyles
  alwaysOpen?: boolean
}

export function SoapSection(props: SoapSectionProps) {
  const { title, initial, initialIcon, initialImage, altText, children, style, alwaysOpen } = props
  const { classes } = useStyles(createStyles, props.color, alwaysOpen)

  return (
    <>
      <AccordionButton style={style} alwaysOpen={alwaysOpen}>
        <div className={classes.header}>
          {initialIcon && (
            <span className={classes.initialIcon}>
              <Icon icon={initialIcon} size={1.5} style={{ verticalAlign: 'middle' }} />
            </span>
          )}
          {initialImage && <img src={initialImage} alt={altText} className={classes.initialIcon} />}
          {initial && <span className={classes.initial}>{initial}</span>}
          <Heading level={2} style={classes.title}>
            {title}
          </Heading>
          {!alwaysOpen && <Icon icon='angleDown' size={1.5} className={classes.icon} />}
        </div>
      </AccordionButton>

      <AccordionItemPanel>
        <div className={classes.content}>{children}</div>
      </AccordionItemPanel>
    </>
  )
}

SoapSection.defaultProps = {
  color: gray,
}

const createStyles = (theme: Theme, color: ColorScale, alwaysOpen: boolean) => ({
  header: {
    ...theme.typography.variant('h2'),
    display: 'flex',
    borderRadius: 2,
    background: color.c90,
    '&:hover': {
      backgroundColor: alwaysOpen ? color.c90 : color.c80,
    },
  } as CSSProperties,
  initial: {
    background: color.c40,
    color: theme.pallete.gray.c100,
    borderRadius: 4,
    padding: '0.25rem 0.75rem',
  } as CSSProperties,
  initialIcon: {
    background: color.c40,
    color: theme.pallete.gray.c100,
    borderRadius: 4,
    padding: '0.2rem 0.4rem',
  } as CSSProperties,
  title: {
    flex: 1,
    padding: '0.25rem 0.75rem',
  } as CSSProperties,
  icon: {
    alignSelf: 'center',
    marginRight: '0.5rem',
  } as CSSProperties,
  content: {
    padding: '0.4rem 0.3rem 1rem',
  } as CSSProperties,
})
