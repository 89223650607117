/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, HFlow } from 'bold-ui'
import { CheckboxField } from 'components/form/final-form/CheckboxField'
import { FornecimentoOdontoEnum } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'

export interface FornecimentoPanelProps {
  name: MetaPath<FornecimentoOdontoEnum[]>
}

export function FornecimentoPanel(props: FornecimentoPanelProps) {
  const { name } = props

  return (
    <FormControl label='Fornecimento'>
      <HFlow
        style={css`
          margin-left: -0.25rem;
        `}
        justifyContent='space-between'
      >
        <CheckboxField name={name} label='Escova dental' value={FornecimentoOdontoEnum.ESCOVA_DENTAL} />

        <CheckboxField name={name} label='Creme dental' value={FornecimentoOdontoEnum.CREME_DENTAL} />

        <CheckboxField name={name} label='Fio dental' value={FornecimentoOdontoEnum.FIO_DENTAL} />
      </HFlow>
    </FormControl>
  )
}
