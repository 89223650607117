import { HFlow, Text } from 'bold-ui'
import { SelectField } from 'components/form'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_PAGE_PARAM,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { Cpf } from 'components/label'
import { ProfissionalAdSelectFieldDocument } from 'graphql/hooks.generated'
import { ProfissionalAdSelectFieldQuery, ProfissionalAdSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

export type ProfissionalAdSelectModel = ProfissionalAdSelectFieldQuery['profissionaisAD']['content'][0]

export interface ProfissionalAdSelectFieldProps extends AsyncSelectFieldProps<ProfissionalAdSelectModel> {
  localidadeId?: ID
  equipeId?: ID
  mostrarEquipesRelacionadas?: boolean
}

export function ProfissionalAdSelectField(props: ProfissionalAdSelectFieldProps) {
  const { localidadeId, equipeId, mostrarEquipesRelacionadas, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    ProfissionalAdSelectModel,
    ProfissionalAdSelectFieldQuery,
    ProfissionalAdSelectFieldQueryVariables
  >({
    query: ProfissionalAdSelectFieldDocument,
    extractItems: (data) => data?.profissionaisAD?.content,
    variables: (inputQuery) => ({
      inputProf: {
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
      inputEquipe: {
        localidadeId,
        equipeId,
        mostrarEquipesRelacionadas,
      },
    }),
  })

  const itemToString = (item: ProfissionalAdSelectModel) => item?.nome

  const renderItem = (item: ProfissionalAdSelectModel) => {
    return (
      <>
        <Text component='p' fontWeight='bold'>
          {item.nome}
        </Text>
        <HFlow hSpacing={0.25}>
          <Text fontWeight='bold'>CPF</Text>
          <Cpf value={item.cpf} />
        </HFlow>
      </>
    )
  }

  return (
    <SelectField<ProfissionalAdSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      menuMinWidth={496}
      {...selectProps}
      {...rest}
    />
  )
}
