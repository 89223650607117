import { Checkbox, Tooltip } from 'bold-ui'
import React from 'react'

import { ImovelItemModel } from './ImoveisTerritorioTable'
import { MAXIMUM_RETERRITORIALIZACAO_SIZE } from './model'
import { ImoveisQueryResult } from './ReterritorializacaoView'
import {
  disabledAction,
  getPermissionReterritorializarAction,
  maxCheckedAction,
  numeroTotalCidadaosImovel,
  validAction,
} from './utils/reterritorializacao'

interface ImoveisTerritorioCheckAllProps {
  checkedItems: Map<string, number>
  setCheckedItems: (map: Map<string, number>) => void
  pageResult: ImoveisQueryResult
  disabled?: boolean
}

export function ImoveisTerritorioCheckAll({
  checkedItems,
  setCheckedItems,
  pageResult,
  disabled,
}: ImoveisTerritorioCheckAllProps) {
  const validImoveisChecked = []
  const validImoveisUnchecked = []
  pageResult.content.forEach((imovelDto) => {
    const action = getPermissionReterritorializarAction(imovelDto)
    action.enabled && !checkedItems.has(imovelDto.id) && validImoveisUnchecked.push(imovelDto)
    action.enabled && checkedItems.has(imovelDto.id) && validImoveisChecked.push(imovelDto)
  })

  const handleCheck = (event) => {
    const newCheckedItems = new Map(checkedItems)
    if (event.target.checked)
      validImoveisUnchecked.forEach((imovel: ImovelItemModel) =>
        newCheckedItems.set(imovel.id, numeroTotalCidadaosImovel(imovel.familias))
      )
    else validImoveisChecked.forEach((imovel) => newCheckedItems.delete(imovel.id))
    setCheckedItems(newCheckedItems)
  }

  const checkAllChecked = validImoveisChecked.length > 0 && validImoveisUnchecked.length === 0

  const checkAllAction = disabled
    ? disabledAction
    : !checkAllChecked && checkedItems.size + validImoveisUnchecked.length > MAXIMUM_RETERRITORIALIZACAO_SIZE
    ? maxCheckedAction(MAXIMUM_RETERRITORIALIZACAO_SIZE)
    : validAction

  return (
    <Tooltip text={checkAllAction.hint}>
      <div>
        <Checkbox
          onChange={(event) => {
            handleCheck(event)
          }}
          checked={checkAllChecked}
          disabled={!checkAllAction.enabled}
        />
      </div>
    </Tooltip>
  )
}
