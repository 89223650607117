/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente22(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='34' height='108' viewBox='0 0 34 108' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M28.301 70.953C28.2743 70.8606 28.2444 70.7564 28.2115 70.6409C28.0591 70.1063 27.8415 69.3308 27.5805 68.3658C27.0585 66.436 26.3626 63.7469 25.6665 60.71C24.2772 54.6486 22.8767 47.1559 22.8767 41.5483C22.8767 37.5937 23.4999 31.6812 24.1314 25.6895C24.3906 23.2309 24.6511 20.7589 24.8706 18.4035C25.2495 14.3365 25.5068 10.6158 25.435 7.86616C25.3991 6.48718 25.2808 5.38579 25.0679 4.61696C24.9615 4.23246 24.839 3.95984 24.7125 3.782C24.59 3.60954 24.4828 3.55088 24.3982 3.5323C23.7277 3.38497 22.8026 3.76324 21.6452 5.03344C20.5133 6.27569 19.2775 8.24773 18.0112 10.9285C15.4819 16.2829 12.8794 24.3599 10.7386 34.7824C8.59504 45.2177 7.13609 54.0134 6.05239 60.6657C5.97877 61.1176 5.90685 61.5598 5.83656 61.992C5.38984 64.7388 5.00858 67.0831 4.66868 68.9784C4.27728 71.1608 3.93433 72.7867 3.59917 73.7526C3.5707 73.8346 3.58574 73.849 3.59303 73.856C3.59385 73.8568 3.59458 73.8575 3.59513 73.8582C3.63058 73.9023 3.74218 73.9893 4.01511 74.0735C4.55249 74.2391 5.4376 74.312 6.61009 74.2927C8.93267 74.2545 12.1696 73.8613 15.4719 73.3486C18.7679 72.8369 22.106 72.2098 24.6216 71.7103C25.879 71.4606 26.9301 71.2429 27.6666 71.0877C27.9154 71.0353 28.1282 70.99 28.301 70.953Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M29.0478 102.421C29.556 102.153 30.0151 101.574 30.3959 100.646C30.7732 99.7263 31.0476 98.5276 31.2281 97.12C31.5889 94.3071 31.5645 90.7501 31.3078 87.134C31.0515 83.5221 30.565 79.873 30.0107 76.8843C29.7335 75.3898 29.4402 74.0652 29.1517 72.9958C28.8602 71.9154 28.5823 71.1296 28.345 70.69C28.1408 70.3115 27.7205 69.9175 27.0632 69.5374C26.4129 69.1613 25.5712 68.8221 24.5837 68.5332C22.6096 67.9555 20.1102 67.5951 17.5323 67.5174C14.9546 67.4398 12.3232 67.6456 10.0837 68.1855C7.82743 68.7295 6.04786 69.5936 5.07946 70.7702C4.60218 71.3501 4.18002 72.2297 3.82407 73.3696C3.47016 74.5031 3.19162 75.8611 2.98333 77.3708C2.56676 80.39 2.43644 83.9755 2.5276 87.5004C2.61875 91.0248 2.93087 94.4722 3.39337 97.2132C3.62476 98.5845 3.89207 99.7686 4.18431 100.693C4.4836 101.639 4.78745 102.24 5.05342 102.516C5.32977 102.803 5.81447 103.088 6.51743 103.348C7.2114 103.605 8.07644 103.822 9.0712 103.995C11.06 104.343 13.5208 104.511 16.0693 104.501C18.6163 104.492 21.2356 104.306 23.5391 103.951C25.8546 103.595 27.8066 103.075 29.0478 102.421Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.96294 101.931C2.24666 102.73 2.64512 103.62 3.25336 104.251C3.91116 104.933 4.80444 105.38 5.65019 105.693C6.53185 106.019 7.55477 106.268 8.6409 106.458C10.8161 106.838 13.4316 107.011 16.0785 107.001C18.7313 106.991 21.4761 106.798 23.9193 106.422C26.3145 106.053 28.5983 105.484 30.2136 104.632C31.4707 103.969 32.2298 102.762 32.7087 101.595C33.2014 100.395 33.5129 98.9575 33.7078 97.4381C34.0987 94.3902 34.0635 90.6472 33.8015 86.957C33.5384 83.2499 33.0399 79.5077 32.4688 76.4284C32.1833 74.8891 31.8761 73.4962 31.5654 72.3446C31.2666 71.2372 30.9286 70.2132 30.5451 69.5026C30.5092 69.4361 30.4717 69.371 30.4327 69.3075C29.1257 64.643 25.3768 50.4479 25.3768 41.5483C25.3768 37.7526 25.9791 32.0124 26.6143 25.9862L26.6207 25.9252C27.191 20.5151 27.7818 14.9099 27.9178 10.5843C27.9856 8.42812 27.9459 6.46644 27.6964 4.96231C27.5732 4.21922 27.3781 3.45191 27.0342 2.79773C26.6906 2.14414 26.0463 1.33476 24.9348 1.09055C22.859 0.634436 21.0664 1.95692 19.7974 3.34964C18.4265 4.85417 17.0655 7.07748 15.7508 9.86075C13.1078 15.4559 10.4545 23.7408 8.28981 34.2793C5.98999 45.4755 4.47483 54.7919 3.37001 61.585L3.36902 61.5911C2.88586 64.562 2.48458 67.0288 2.12998 68.9683C1.76814 70.9474 1.47832 72.2389 1.23745 72.933C1.13675 73.2231 1.07336 73.5557 1.08398 73.9094C-0.0249138 78.4849 -0.192221 85.1092 0.175713 90.8447C0.366684 93.8216 0.707001 96.6419 1.16398 98.9071C1.39194 100.037 1.65693 101.069 1.96294 101.931ZM3.11802 74.0216C0.884768 82.5675 2.30238 100.382 4.6934 102.863C7.51457 105.79 24.017 105.638 29.281 102.863C34.545 100.088 30.9172 74.4032 28.785 70.4525C28.7313 70.3529 28.6676 70.255 28.5946 70.1589C27.3917 65.9004 23.3768 50.9796 23.3768 41.5483C23.3768 37.6216 23.9952 31.7549 24.6253 25.7766C25.7753 14.8662 26.9645 3.58423 24.5056 3.04395C20.6994 2.20763 14.5402 13.7904 10.2489 34.6817C7.95741 45.8374 6.44764 55.1206 5.34309 61.9122C4.37915 67.8392 3.72384 71.8685 3.12692 73.5886C3.0705 73.7512 3.06911 73.8951 3.11802 74.0216Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
