import React from 'react'

import { ErrorGroup, ErrorItem, ErrorsBox, ValidationErrorsField } from './ErrorsBox'
import { UnidadeSaudeCnesModel, UnidadeSaudeDetalhesImportacaoModel, ValidacaoModel } from './model'

interface UnidadesSaudeErrorsBoxProps {
  unidadesSaude: UnidadeSaudeDetalhesImportacaoModel[]
}

export const UnidadesSaudeErrorsBox = (props: UnidadesSaudeErrorsBoxProps) => {
  const { unidadesSaude } = props

  const unidadesSaudeComErro = unidadesSaude.filter((unidadeSaude) => unidadeSaude.validacoes)

  if (unidadesSaudeComErro.length === 0) {
    return null
  }
  return (
    <ErrorsBox title='Unidades de saúde'>
      <ErrorGroup title='Faltam dados obrigatórios'>
        {unidadesSaudeComErro
          .filter((unidadeSaude) => unidadeSaude.validacoes.faltamDadosObrigatorios.length > 0)
          .map((unidadeSaude, index) => (
            <UnidadeSaudeErrorItem
              key={index}
              unidadeSaude={unidadeSaude.dados}
              errors={unidadeSaude.validacoes.faltamDadosObrigatorios}
            />
          ))}
      </ErrorGroup>
      <ErrorGroup title='Campos preenchidos de forma incorreta'>
        {unidadesSaudeComErro
          .filter((unidadeSaude) => unidadeSaude.validacoes.camposPreenchidosIncorretos.length > 0)
          .map((unidadeSaude, index) => (
            <UnidadeSaudeErrorItem
              key={index}
              unidadeSaude={unidadeSaude.dados}
              errors={unidadeSaude.validacoes.camposPreenchidosIncorretos}
            />
          ))}
      </ErrorGroup>
    </ErrorsBox>
  )
}

interface UnidadeSaudeErrorItemProps {
  unidadeSaude: UnidadeSaudeCnesModel
  errors: ValidacaoModel[]
}

const UnidadeSaudeErrorItem = (props: UnidadeSaudeErrorItemProps) => {
  const { unidadeSaude, errors } = props
  return (
    <ErrorItem>
      {unidadeSaude.nome || 'Nome não informado'}, CNES: {unidadeSaude.cnes || '-'}
      {errors && <ValidationErrorsField errors={errors} />}
    </ErrorItem>
  )
}
