import { HFlow, SelectHelperMenuItem, Text } from 'bold-ui'
import locale from 'bold-ui/lib/i18n/locales/pt-BR'
import { SelectField } from 'components/form'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_PAGE_PARAM,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { ImunobiologicoComboDocument } from 'graphql/hooks.generated'
import { ImunobiologicoComboQuery, ImunobiologicoComboQueryVariables, SexoEnum } from 'graphql/types.generated'
import React from 'react'

export type ImunobiologicoSelectModel = ImunobiologicoComboQuery['imunobiologicos']['content'][0]

export interface ImunobiologicoSelectFieldProps extends AsyncSelectFieldProps<ImunobiologicoSelectModel> {
  semRegras?: boolean
  sexo?: SexoEnum
  isRegistroAnterior?: boolean
  outrosImunobiologicos?: boolean
  onlyCalendarioVacinal?: boolean
  isOnlyCovid?: boolean
  grupoAlvoId?: ID
}

export function ImunobiologicoSelectField(props: ImunobiologicoSelectFieldProps) {
  const {
    semRegras,
    sexo,
    isRegistroAnterior,
    outrosImunobiologicos,
    isOnlyCovid,
    onlyCalendarioVacinal,
    grupoAlvoId,
    ...rest
  } = props

  const { skipping, selectProps } = useAsyncQuerySelect<
    ImunobiologicoSelectModel,
    ImunobiologicoComboQuery,
    ImunobiologicoComboQueryVariables
  >({
    query: ImunobiologicoComboDocument,
    extractItems: (data) => data?.imunobiologicos?.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        sexo,
        semRegras,
        isRegistroAnterior,
        outrosImunobiologicos,
        isOnlyCovid,
        onlyCalendarioVacinal,
        grupoAlvoId,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          size: isOnlyCovid ? 20 : 10,
        },
      },
    }),
    skip: (inputString) => inputString?.trim()?.length < 2,
    debounceTime: 500,
  })

  const itemToString = (item: ImunobiologicoSelectModel) => (item ? `${item.sigla} - ${item.nome}` : undefined)

  const renderItem = (item: ImunobiologicoSelectModel) => (
    <>
      <Text fontWeight='bold'>{item.sigla}</Text>
      <HFlow hSpacing={0.5}>
        <Text> {item.nome}</Text>
      </HFlow>
    </>
  )

  return (
    <SelectField<ImunobiologicoSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      components={{
        EmptyItem: () => (
          <SelectHelperMenuItem>
            {skipping ? 'Digite para buscar um agente da categoria biológico.' : locale.select.emptyItem}
          </SelectHelperMenuItem>
        ),
      }}
      {...selectProps}
      {...rest}
    />
  )
}
