import { isAfter, isSameDay, startOfDay } from 'date-fns'
import { createValidator, ErrorObject, maxLength, required } from 'util/validation'

import { EstagioFormModel, NivelEstagioEnum } from './model'

export const estagioFormValidator = (getServerTimeNow: () => Date) =>
  createValidator<EstagioFormModel>(
    {
      unidadeSaude: [required],
      lotacaoSupervisora: [required],
      perfis: [required],
      nivelEstagio: [required],
      instituicaoEnsino: [maxLength(255)],
    },
    (values: EstagioFormModel, errors: ErrorObject<EstagioFormModel>) => {
      const isCboFormacaoRequired = values?.nivelEstagio === NivelEstagioEnum.TECNICO

      if (values?.periodoEstagio?.startDate && values?.periodoEstagio?.endDate) {
        if (isAfter(values?.periodoEstagio.startDate, startOfDay(getServerTimeNow())))
          errors.periodoEstagio = 'Data de início do estágio deve ser menor ou igual à data atual.'
        if (isSameDay(values?.periodoEstagio.startDate, values?.periodoEstagio?.endDate))
          errors.periodoEstagio = 'Data de início do estágio deve ser diferente da data do fim do estágio.'
        if (!isAfter(values?.periodoEstagio.endDate, startOfDay(getServerTimeNow())))
          errors.periodoEstagio = 'Data de fim do estágio deve ser maior que a data atual.'
      } else {
        errors.periodoEstagio = 'Preenchimento obrigatório.'
      }

      if (isCboFormacaoRequired) {
        errors.cboFormacao = required(values.cboFormacao)
      }

      return errors
    }
  )
