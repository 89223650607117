/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel, VFlow } from 'bold-ui'
import { TipoAleitamentoMaternoEnum } from 'graphql/types.generated'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'
import { tipoAleitamentoRecord } from 'view/atendimentos/detail/soap/objetivo/puericultura/prenatal-parto-nascimento/components/TipoAleitamentoField'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

interface HistoricoMedicoesPuericulturaTableBoxProps {
  medicoes?: HistoricoMedicaoModel[]
  tipoAleitamentoMaterno?: TipoAleitamentoMaternoEnum
}

export default function HistoricoMedicoesPuericulturaTableBox(props: HistoricoMedicoesPuericulturaTableBoxProps) {
  const { tipoAleitamentoMaterno } = props

  return (
    <HistoricoDetailTable title='Medições de puericultura' sectionStyle='RA'>
      <VFlow>
        <Grid>
          {tipoAleitamentoMaterno && (
            <Cell size={2}>
              <InfoLabel title='Tipo de aleitamento'>{tipoAleitamentoRecord[tipoAleitamentoMaterno]}</InfoLabel>
            </Cell>
          )}
        </Grid>
      </VFlow>
    </HistoricoDetailTable>
  )
}
