import { StyleSheet } from '@react-pdf/renderer'
import { PrintText, PrintTitle, SpaceLine } from 'components/print'
import { PrintRichText } from 'components/print/components/PrintRichText'
import { PrintSignature } from 'components/print/components/PrintSignature'
import { convertDadosProfissionalPrint } from 'components/print/layout/convert'
import { PrinterType } from 'components/print/model'
import { PrintSignatureProfissional } from 'components/print/PrintSignatureProfissional'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import React from 'react'
import { formatDayMonthYear } from 'util/date/formatDate'

import {
  ASSINATURA_CIDADAO_TEXT,
  atestadoComCID10Text,
  CID10_DESCRICAO_ATESTADO,
  tituloAtestado,
} from '../atestadoUtils'
import { PrintAtestadoLegado } from './PrintAtestadoLegado'

interface ImpressaoAtestadoProps {
  tipoAtestado: TipoAtestadoEnum
  content: string
  dataAtendimento: Instant
  session: PrinterType
  nomeCidadao: string
}

export const ImpressaoAtestado = (props: ImpressaoAtestadoProps) => {
  const { tipoAtestado, content, dataAtendimento, session, nomeCidadao } = props
  const { nomeProfissional, nomeCbo, nomeMunicipio, nomeMunicipioUf, conselhoClasse } = convertDadosProfissionalPrint(
    session
  )
  const printAssinaturaCidadao = tipoAtestado && content.includes(CID10_DESCRICAO_ATESTADO)
  const classes = createStyles()
  const dataPorExtenso = formatDayMonthYear(dataAtendimento)

  return (
    <>
      {!tipoAtestado ? (
        <PrintAtestadoLegado textStyles={classes.marginBody} content={content} />
      ) : (
        <>
          <PrintTitle title={tituloAtestado(tipoAtestado)} level={3} transform='uppercase' />
          <SpaceLine />
          <PrintRichText textStyles={classes.marginBody} content={content} />
        </>
      )}

      <PrintText wrapperStyles={classes.rightLocalData}>
        {`${nomeMunicipio.titleCase()} - ${nomeMunicipioUf}, ${dataPorExtenso}`}
      </PrintText>
      <PrintSignatureProfissional
        nomeProfissional={nomeProfissional}
        cboProfissional={nomeCbo}
        conselhoClasse={conselhoClasse}
        data={dataPorExtenso}
        municipio={nomeMunicipio}
        uf={nomeMunicipioUf}
      />
      {printAssinaturaCidadao && (
        <>
          <SpaceLine />
          <PrintRichText
            textStyles={{ ...classes.marginBody, marginTop: '100px' }}
            content={atestadoComCID10Text({ nomeCidadao, nomeProfissional })}
          />
          <PrintSignature>
            <PrintText textStyles={classes.signatureTextBold}>{ASSINATURA_CIDADAO_TEXT}</PrintText>
          </PrintSignature>
        </>
      )}
    </>
  )
}
const createStyles = () =>
  StyleSheet.create({
    marginBody: {
      marginLeft: '24px',
      marginRight: '24px',
    },
    signatureTextBold: {
      fontFamily: 'Lato Bold',
      alignSelf: 'center',
    },
    rightLocalData: {
      marginTop: '100px',
      marginRight: '32px',
      alignSelf: 'flex-end',
    },
  })
