import { DataTable, DataTableProps, HFlow, Omit, Tag } from 'bold-ui'
import { TableBox } from 'components/table/TableBox'
import { HorusListViewQuery } from 'graphql/types.generated'
import React from 'react'
import { HorusEditUnidadePadraoComponent } from 'view/horus/HorusEditUnidadePadraoComponent'
import { HorusRemoveUnidadeComponent } from 'view/horus/HorusRemoveUnidadeComponent'
import { HorusTableHeader } from 'view/horus/HorusTableHeader'

export type HorusTableItemDto = HorusListViewQuery['unidadesConsultaHorus'][0]
export type PermissionToEditHorus = HorusListViewQuery['unidadeSaude']['actions']['permissaoConexaoHorus']

export interface HorusTableProps extends Omit<DataTableProps<HorusTableItemDto>, 'columns'> {
  unidadeSaudeId?: ID
  consultaHorusAtiva: boolean
  actions: HorusListViewQuery['unidadeSaude']['actions']
  reload(): Promise<any>
}

export default function HorusTable(props: HorusTableProps) {
  const { actions, reload, unidadeSaudeId, consultaHorusAtiva, ...rest } = props

  const renderNome = (row: HorusTableItemDto) => {
    return (
      <HFlow hSpacing={0.5} alignItems='center'>
        {row.unidadeSaude.nome}
        {row.unidadePadrao && <Tag>Unidade prioritária para consulta</Tag>}
      </HFlow>
    )
  }

  const renderCnes = (row: HorusTableItemDto) => {
    return row.unidadeSaude.cnes
  }

  const renderButtons = (row: HorusTableItemDto) => {
    return (
      <>
        <HorusRemoveUnidadeComponent
          reload={reload}
          nomeUnidadeRemovida={row.unidadeSaude.nome}
          unidadeRemovidaId={row.unidadeSaude.id}
          unidadeSaudeId={unidadeSaudeId}
          permissionToEditHorus={actions && actions.permissaoConexaoHorus}
        />
        <HorusEditUnidadePadraoComponent
          reload={reload}
          unidadeEditada={row.unidadeSaude.id}
          unidadeSaudeId={unidadeSaudeId}
          permissionToEditHorus={actions && actions.permissaoConexaoHorus}
        />
      </>
    )
  }

  return (
    <TableBox
      header={
        <HorusTableHeader
          unidadeSaudeId={unidadeSaudeId}
          reload={reload}
          consultaHorusAtiva={consultaHorusAtiva}
          maxCincoUnidadeConsulta={props.rows.length >= 5}
          permissionToEditHorus={actions && actions.permissaoConexaoHorus}
        />
      }
    >
      <DataTable<HorusTableItemDto>
        placeholder='Nenhuma Unidade de saúde adicionada para consulta'
        columns={[
          {
            name: 'nome',
            header: 'Unidade de saúde',
            render: renderNome,
          },
          {
            name: 'cnes',
            header: 'CNES',
            render: renderCnes,
          },
          {
            name: 'buttons',
            render: renderButtons,
            style: {
              textAlign: 'right',
            },
          },
        ]}
        {...rest}
      />
    </TableBox>
  )
}
