import { Text, Theme, useTheme, VFlow } from 'bold-ui'
import { css } from 'emotion'
import React from 'react'
import { ActionEvolucaoCuidadoCompartilhadoRecord } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CuidadoCompartilhadoEvolucaoDetalhes } from '../HistoricoCuidadoCompartilhadoPanel'

interface HistoricoCuidadoCompartilhadoCondutaPadraoProps {
  cuidadoCompartilhadoEvolucao: CuidadoCompartilhadoEvolucaoDetalhes
}

export function HistoricoCuidadoCompartilhadoCondutaPadrao(props: HistoricoCuidadoCompartilhadoCondutaPadraoProps) {
  const { cuidadoCompartilhadoEvolucao } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const {
    subtitleLabel,
    getSubtitle,
    descriptionLabel,
    getDescription,
    getBottomTextLabel,
    getBottomText,
  } = ActionEvolucaoCuidadoCompartilhadoRecord[cuidadoCompartilhadoEvolucao.conduta]
  const subtitle = getSubtitle?.(cuidadoCompartilhadoEvolucao)
  const description = getDescription(cuidadoCompartilhadoEvolucao)
  const bottomTextLabel = getBottomTextLabel?.(cuidadoCompartilhadoEvolucao)
  const bottomText = getBottomText?.(cuidadoCompartilhadoEvolucao)

  return (
    <VFlow vSpacing={0}>
      <Text>
        {subtitleLabel && subtitle && <Text fontWeight='bold'>{subtitleLabel}: </Text>}
        {subtitle && <Text>{subtitle}</Text>}
      </Text>
      <Text>
        {descriptionLabel && description && <Text fontWeight='bold'>{descriptionLabel}: </Text>}
        {description && <Text dangerouslySetInnerHTML={{ __html: description }} style={styles.richText} />}
      </Text>
      <Text>
        {bottomTextLabel && bottomText && <Text fontWeight='bold'>{bottomTextLabel}: </Text>}
        {bottomText && <Text>{bottomText}</Text>}
      </Text>
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  richText: css`
    color: ${theme.pallete.gray.c20};
    word-break: break-all;

    & p:first-child {
      display: inline;
    }
  `,
})
