/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, Grid, VFlow } from 'bold-ui'
import { ErrorField, TextAreaField } from 'components/form'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import {
  ProcedimentoOdontoSelectField,
  ProcedimentoOdontoSelectModel,
} from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { ParteBucalEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

import { MAX_LENGTH_OBS_ODONTO } from '../model'
import CaracteristicasLesaoField from './components/caracteristicas-lesao/CaracteristicasLesaoField'
import LocalTecidosMolesSelectField from './components/LocalTecidosMolesSelectField/LocalTecidosMolesSelectField'
import { TecidosMolesFormModel } from './TecidosMolesForm'

interface TecidosMolesFormFieldsProps {
  isModal?: boolean
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  parteBucalSelecionada: ParteBucalEnum
  hasProcedimentos?: boolean
  preExpanded?: boolean
  caracteristicasLesaoError?: boolean
  procedimentosSelecionados: ProcedimentoOdontoSelectModel[]
}

const path = metaPath<TecidosMolesFormModel>()

export default function TecidosMolesFormFields(props: TecidosMolesFormFieldsProps) {
  const {
    isModal,
    cidadaoDataNascimento,
    dataAtendimento,
    parteBucalSelecionada,
    hasProcedimentos,
    preExpanded,
    caracteristicasLesaoError,
    procedimentosSelecionados,
  } = props

  return (
    <VFlow vSpacing={0.5}>
      <ErrorField type='alert' name={path.groupErrors} />

      <Grid gap={1}>
        <Cell size={7} xl={isModal ? 7 : 5}>
          <LocalTecidosMolesSelectField name={path.local} required />
        </Cell>
        <Cell size={12}>
          <CaracteristicasLesaoField
            name={path.caracteristicasLesao}
            preExpanded={preExpanded}
            error={caracteristicasLesaoError}
          />
        </Cell>
        <Cell size={7} xl={isModal ? 7 : 5}>
          <CidSelectField
            name={path.problema}
            label='CID 10'
            renderGrupoCiapRelacionada={false}
            parteBucal={parteBucalSelecionada}
            disabled={!parteBucalSelecionada}
          />
        </Cell>
        <Cell size={12}>
          <ProcedimentoOdontoSelectField
            name={path.procedimentos}
            label='Procedimentos (Evolução odontológica)'
            multiple
            dataNascimento={cidadaoDataNascimento}
            dataAtendimento={dataAtendimento}
            parteBucal={parteBucalSelecionada}
            disabled={!parteBucalSelecionada}
            itemsDisabled={procedimentosSelecionados}
          />
        </Cell>
        <Cell size={12}>
          <FormControl label='Observações dos procedimentos'>
            <TextAreaField
              name={path.observacao}
              maxLength={MAX_LENGTH_OBS_ODONTO}
              placeholder='Insira informações adicionais sobre os procedimentos realizados.'
              style={css`
                resize: none;
              `}
              disabled={!hasProcedimentos}
            />
          </FormControl>
        </Cell>
      </Grid>
    </VFlow>
  )
}
