/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Tag, Text, Theme, useTheme } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { FormSectionAccordionButtonProps } from 'components/accordion/FormSectionAccordionButton'
import moment from 'moment'
import { useMemo } from 'react'

export interface AntecedentesFormAccordionButtonProps extends FormSectionAccordionButtonProps {
  dataAtualizacao: Instant
}

export function AntecedentesFormAccordionButton(props: AntecedentesFormAccordionButtonProps) {
  const { style, text, dataAtualizacao, ...rest } = props
  const theme = useTheme()
  const classes = createStyles(theme)
  const date = useMemo(() => moment(props.dataAtualizacao).format('DD/MM/YYYY'), [props.dataAtualizacao])

  return (
    <AccordionButton wrapperStyle={{ paddingBottom: 0 }} style={css(classes.button, style)} {...rest}>
      <HFlow alignItems='center' justifyContent='space-between' style={css(classes.wraper)}>
        <HFlow alignItems='center'>
          <Icon icon='angleDown' size={1.5} />
          <Text component='span' fontWeight='bold'>
            {text}
          </Text>
        </HFlow>
        {dataAtualizacao && (
          <Tag icon='redo' type='normal'>
            Última atualização - <span style={{ fontWeight: 'normal' }}> &#160; {date}</span>
          </Tag>
        )}
      </HFlow>
    </AccordionButton>
  )
}

const createStyles = (theme: Theme) => ({
  button: css`
    display: flex;
    padding: 1rem 1.8rem;
    border: 1px ${theme.pallete.divider} solid;
    background-color: ${theme.pallete.gray.c100};
    margin: 0;
    position: relative;
  `,
  wraper: css`
    width: 100%;
  `,
  date: css`
    font-weight: normal;
  `,
})
