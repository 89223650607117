import { createValidator, ErrorObject, maxLength, nomeFabricante, nomeLote, required } from 'util/validation'

import { LoteImunobiologicoFormModel } from '../insert/LoteImunologicoTooltipAddForm'

export const formPopperValidator = createValidator<LoteImunobiologicoFormModel>(
  {
    lote: [required, maxLength(30), nomeLote],
    fabricante: [required, maxLength(60)],
    imunobiologico: [required],
    validade: [required],
  },
  (values: LoteImunobiologicoFormModel, errors: ErrorObject<LoteImunobiologicoFormModel>) => {
    if (!errors.fabricante && values?.fabricante?.nome) {
      const errorFabricanteImunoRule = nomeFabricante(values.fabricante.nome)
      if (errorFabricanteImunoRule) {
        errors.fabricante = errorFabricanteImunoRule
      }
    }

    return errors
  }
)
