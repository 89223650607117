import { Cell, Grid } from 'bold-ui'
import { TipoServicoQueryInput } from 'graphql/types.generated'
import React from 'react'

import { TipoServicoFormPopper } from './create/TipoServicoFormPopper'
import { TipoServicoTableFilter } from './TipoServicoTableFilter'

export interface TipoServicoTableHeaderProps {
  initialValues?: TipoServicoQueryInput
  onChange(filter: TipoServicoQueryInput): void
  reload(): Promise<any>
}

export function TipoServicoTableHeader(props: TipoServicoTableHeaderProps) {
  const { onChange, initialValues, reload } = props

  return (
    <Grid justifyContent='space-between'>
      <Cell size={6}>
        <TipoServicoTableFilter onChange={onChange} initialValues={initialValues} />
      </Cell>
      <Cell>
        <TipoServicoFormPopper reload={reload} />
      </Cell>
    </Grid>
  )
}
