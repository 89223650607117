/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DataTable, Heading, HFlow, isEmpty, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoAvaliacaoElegibilidadeAdQuery } from 'graphql/hooks.generated'
import { partition } from 'lodash'
import { memo } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import { modalidadeDescriptionByEnumType } from 'view/ad/lista/selectFields/AtencaoDomiciliarModalidadeSelectField'
import { descriptions as origemDescription } from 'view/ad/lista/selectFields/AtencaoDomiciliarOrigemSelectField'
import { HistoricoPecPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'
import { mountInfosAdministrativasAtendimentosRecentes } from 'view/atendimentos/detail/historico/utils/utils-historico'

import HistoricoDetailTable from '../../../../table/HistoricoDetailTable'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'

function HistoricoAvaliacaoElegibilidadeADPanel(props: HistoricoPecPanelProps) {
  const { idAtend, idadeOrigemAtendimento } = props
  const {
    data: { historicoAtendimentoPec: historico },
    loading,
  } = useHistoricoAvaliacaoElegibilidadeAdQuery({
    variables: {
      input: {
        idAtend,
        isOrigemPec: true,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  const colunaDestinoConclusao = [
    {
      name: 'conclusao',
      header: historico.atendimentoProfissionalAD.elegivel ? 'Elegível' : 'Inelegível',
      render: (item) => item,
      style: css`
        width: 30%;
      `,
    },
  ]

  const [condicoesAtivas, condicoesInativas] = partition(
    historico.atendimentoProfissionalAD.condicoesAvaliadasElegibilidade,
    (resposta) => resposta.situacaoAtiva
  )

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentosRecentes(historico)}
    >
      <VFlow vSpacing={0.5}>
        <Heading level={3}>Procedência</Heading>
        <Text>{origemDescription[historico.atendimentoProfissionalAD.origem]}</Text>
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={3}>CID 10</Heading>
        <VFlow vSpacing={0.5}>
          <Heading level={5}>Principal</Heading>
          <Box
            style={css`
              padding: 0.8rem 1rem;
            `}
          >
            <HFlow hSpacing={0.5}>
              {historico.atendimentoProfissionalAD.cid10Principal && (
                <Text>
                  {`${historico.atendimentoProfissionalAD.cid10Principal.nome} -
                ${historico.atendimentoProfissionalAD.cid10Principal.codigo}`}
                </Text>
              )}
            </HFlow>
          </Box>
        </VFlow>
        {historico.atendimentoProfissionalAD.cid10Secundario1 && (
          <VFlow vSpacing={0.5}>
            <Heading level={5}>Secundário 1</Heading>
            <Box
              style={css`
                padding: 0.8rem 1rem;
              `}
            >
              <HFlow hSpacing={0.5}>
                <Text>{`${historico.atendimentoProfissionalAD.cid10Secundario1.nome} -
                ${historico.atendimentoProfissionalAD.cid10Secundario1.codigo}`}</Text>
              </HFlow>
            </Box>
          </VFlow>
        )}
        {historico.atendimentoProfissionalAD.cid10Secundario2 && (
          <VFlow vSpacing={0.5}>
            <Heading level={5}>Secundário 2</Heading>
            <Box
              style={css`
                padding: 0.8rem 1rem;
              `}
            >
              <HFlow hSpacing={0.5}>
                <Text>{`${historico.atendimentoProfissionalAD.cid10Secundario2.nome} -
                ${historico.atendimentoProfissionalAD.cid10Secundario2.codigo}`}</Text>
              </HFlow>
            </Box>
          </VFlow>
        )}
      </VFlow>
      <HistoricoDetailTable title='Condições avaliadas' sectionStyle='D'>
        <VFlow vSpacing={0.5}>
          {!isEmpty(condicoesAtivas) && (
            <VFlow vSpacing={0}>
              <Text fontWeight='bold' color='success'>
                Ativos
              </Text>
              <Text>{stringGrouper(...condicoesAtivas.map((resposta) => resposta.descricao))}</Text>
            </VFlow>
          )}
          {!isEmpty(condicoesInativas) && (
            <VFlow vSpacing={0}>
              <Text fontWeight='bold' color='danger'>
                Inativos
              </Text>
              <Text>{stringGrouper(...condicoesInativas.map((resposta) => resposta.descricao))}</Text>
            </VFlow>
          )}
        </VFlow>
      </HistoricoDetailTable>
      <VFlow vSpacing={0.5}>
        <Heading level={3}>Conclusão</Heading>
        {modalidadeDescriptionByEnumType(historico.atendimentoProfissionalAD.modalidade)}
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={5}>Destino</Heading>
        <DataTable<string> rows={historico.atendimentoProfissionalAD.conclusao} columns={colunaDestinoConclusao} />
      </VFlow>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoAvaliacaoElegibilidadeADPanel)
