/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, FormError, Grid, VFlow } from 'bold-ui'
import { FormAccordionButton } from 'components/accordion/FormSectionAccordionButton'
import { RadioField, RadioFieldProps } from 'components/form'
import { RadioGroupField } from 'components/form/field/RadioGroupField'
import theme from 'config/theme'
import { CaracteristicaLesaoEnum } from 'graphql/types.generated'
import { Accordion, AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'
import { MetaPath } from 'util/metaPath'

import { getTecidosMolesCaracteristicaLesao, getTipoCaracteristicaLesao, TipoCaracteristicaLesao } from '../../model'
import LesaoFundamentalField, { LesaoFundamentalFieldModel } from './components/LesaoFundamentalField'
import SintomatologiaField, { SintomatologiaFieldModel } from './components/SintomatologiaField'

export interface CaracteristicasLesaoFieldModel {
  lesaoFundamental: LesaoFundamentalFieldModel
  quantidade: CaracteristicaLesaoEnum
  base: CaracteristicaLesaoEnum
  tamanho: CaracteristicaLesaoEnum
  cor: CaracteristicaLesaoEnum
  superficie: CaracteristicaLesaoEnum
  consistencia: CaracteristicaLesaoEnum
  sintomatologia: SintomatologiaFieldModel
}

export interface CaracteristicasLesaoFieldProps {
  name: MetaPath<CaracteristicasLesaoFieldModel>
  preExpanded?: boolean
  error?: boolean
}

export default function CaracteristicasLesaoField(props: CaracteristicasLesaoFieldProps) {
  const { name, preExpanded, error } = props

  return (
    <VFlow vSpacing={0.5}>
      <Accordion allowZeroExpanded preExpanded={preExpanded ? ['lesao-tecidos-moles'] : []}>
        <AccordionItem uuid='lesao-tecidos-moles'>
          <FormAccordionButton
            text='Características da lesão'
            style={
              error &&
              css`
                border-color: ${theme.pallete.status.danger.main};
              `
            }
          />
          <AccordionItemPanel
            css={[
              classes.accordionItemPanel,
              error &&
                css`
                  border-color: ${theme.pallete.status.danger.main};
                `,
            ]}
          >
            <Grid gap={1}>
              <LesaoFundamentalField name={name.lesaoFundamental} />

              <Cell size={6}>
                <RadioGroupField
                  name={name.quantidade}
                  label={getTipoCaracteristicaLesao(TipoCaracteristicaLesao.QUANTIDADE).title}
                  lines={3}
                  vSpacing={0.5}
                  clearable
                  options={convertCaracteristicasLesaoToRadioOptions(
                    getTipoCaracteristicaLesao(TipoCaracteristicaLesao.QUANTIDADE).items
                  )}
                />
              </Cell>

              <Cell size={6}>
                <FormControl label={getTipoCaracteristicaLesao(TipoCaracteristicaLesao.BASE).title}>
                  <Grid gapVertical={0.5} gap={1}>
                    {getTipoCaracteristicaLesao(TipoCaracteristicaLesao.BASE).items.map((item, index) => (
                      <Cell size={5} key={index}>
                        <RadioFieldStyled name={name.base} value={item} />
                      </Cell>
                    ))}
                  </Grid>
                </FormControl>
              </Cell>

              <Cell size={6}>
                <RadioGroupField
                  name={name.tamanho}
                  label={getTipoCaracteristicaLesao(TipoCaracteristicaLesao.TAMANHO).title}
                  lines={3}
                  vSpacing={0.5}
                  clearable
                  options={convertCaracteristicasLesaoToRadioOptions(
                    getTipoCaracteristicaLesao(TipoCaracteristicaLesao.TAMANHO).items
                  )}
                />
              </Cell>

              <Cell size={6}>
                <FormControl label={getTipoCaracteristicaLesao(TipoCaracteristicaLesao.COR).title}>
                  <Grid gapVertical={0.5} gap={1}>
                    {getTipoCaracteristicaLesao(TipoCaracteristicaLesao.COR).items.map((item, index) => (
                      <Cell size={5} key={index}>
                        <RadioFieldStyled name={name.cor} value={item} />
                      </Cell>
                    ))}
                  </Grid>
                </FormControl>
              </Cell>

              <Cell size={6}>
                <FormControl label={getTipoCaracteristicaLesao(TipoCaracteristicaLesao.SUPERFICIE).title}>
                  <Grid gapVertical={0.5} gap={1}>
                    {getTipoCaracteristicaLesao(TipoCaracteristicaLesao.SUPERFICIE).items.map((item, index) => (
                      <Cell size={5} key={index}>
                        <RadioFieldStyled name={name.superficie} value={item} />
                      </Cell>
                    ))}
                  </Grid>
                </FormControl>
              </Cell>

              <Cell size={6}>
                <FormControl label={getTipoCaracteristicaLesao(TipoCaracteristicaLesao.CONSISTENCIA).title}>
                  <Grid gapVertical={0.5}>
                    {getTipoCaracteristicaLesao(TipoCaracteristicaLesao.CONSISTENCIA).items.map((item, index) => (
                      <Cell size={5} key={index}>
                        <RadioFieldStyled name={name.consistencia} value={item} />
                      </Cell>
                    ))}
                  </Grid>
                </FormControl>
              </Cell>

              <Cell size={6}>
                <SintomatologiaField name={name.sintomatologia} />
              </Cell>
            </Grid>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      {error && <FormError>Existem campos preenchidos de forma incorreta.</FormError>}
    </VFlow>
  )
}

const RadioFieldStyled = (props: { name: RadioFieldProps['name']; value: CaracteristicaLesaoEnum }) => (
  <RadioField
    label={getTecidosMolesCaracteristicaLesao(props.value).title}
    style={{ marginLeft: -0.25 }}
    clearable
    {...props}
  />
)

const convertCaracteristicasLesaoToRadioOptions = (caracteristicas: CaracteristicaLesaoEnum[]) =>
  caracteristicas.map((caracteristica) => ({
    label: getTecidosMolesCaracteristicaLesao(caracteristica).title,
    value: caracteristica,
  }))

const classes = {
  accordionItemPanel: css`
    padding: 1em;
    border: 1px ${theme.pallete.divider} solid;
    border-radius: 2px;
    border-top: 0;
    margin-top: -1px;
  `,
}
