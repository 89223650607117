/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tag } from 'bold-ui'
import { CriticidadeAlergia, GrauCertezaAlergia } from 'graphql/types.generated'

import { criticidadeAlergiaRecord, grauCertezaAlergiaRecord } from '../soap/aside/types/AlergiaModel'

interface AlergiaTagProps {
  type: 'criticidade' | 'grauCerteza'
  enum: CriticidadeAlergia | GrauCertezaAlergia
}

const records = {
  criticidade: criticidadeAlergiaRecord,
  grauCerteza: grauCertezaAlergiaRecord,
}

export function AlergiaTag(props: AlergiaTagProps) {
  const tag = records[props.type][props.enum]
  return (
    <Tag
      style={css`
        color: ${tag.color};
        background: ${tag.background};
      `}
    >
      {tag.descricao}
    </Tag>
  )
}
