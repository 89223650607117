/**
 * Palavras que devem permanecer em caixa baixa
 *
 * Ao adicionar uma nova excecao, se ela for composta por mais de uma palavra, adicione cada uma separadamente
 * para os metodos do string-extensions funcionarem corretamente. Por exemplo, se quiser adicionar
 * a excecao 'das dos', voce deve adicionar 'das' e 'dos' na lista.
 */
export const lowers: string[] = [
  'de',
  'di',
  'do',
  'da',
  'dos',
  'das',
  'dello',
  'della',
  'don',
  'dalla',
  'dal',
  'del',
  'e',
  'ou',
  'com',
  'em',
  'na',
  'no',
  'nas',
  'nos',
  'van',
  'von',
  'às',
  'as',
]
