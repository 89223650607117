import { calculateAge } from './calculateAge'

export function roundedAge(date: LocalDate | number, refDate: LocalDate | number = Date.now()): string {
  const { years, months, days } = calculateAge(date, refDate)

  const yearsLabel = 'ano'.pluralizeAndConcatValue(years)
  const monthsLabel = months === 1 ? 'mês' : 'meses'
  const daysLabel = 'dia'.pluralizeAndConcatValue(days)

  if (years === 0 && months === 0 && days === 0) return 'Hoje'

  return years > 0
    ? months > 0 || days > 0
      ? `Mais de ${yearsLabel}`
      : `${yearsLabel}`
    : months > 0
    ? `${months} ${monthsLabel}`
    : `${daysLabel}`
}
