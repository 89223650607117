import { Text } from 'bold-ui'
import React from 'react'
import { humanizeAge } from 'util/date/humanize-age'

export interface AgeProps {
  dateOfBirth: string
  refDate?: string | number
}

export function Age(props: AgeProps) {
  return <Text>{props.dateOfBirth ? '(' + humanizeAge(props.dateOfBirth, props.refDate) + ')' : '-'}</Text>
}
