import { Text } from 'bold-ui'
import React from 'react'
import { EvolucaoProblema, Problema } from 'view/atendimentos/detail/soap/aside/types/ProblemaModel'

import { MainDetailAccordion } from '../../main-detail-accordion/MainDetailAccordion'
import { BodyProblemaModal } from './BodyProblemaModal'
import { HeaderProblemaModal } from './HeaderProblemaModal'

interface AccordionProblemasModalProps {
  problemas: Problema[]
  filtro?: string
  noResultMessage?: string
}

export function AccordionProblemasModal(props: AccordionProblemasModalProps) {
  const { problemas, filtro, noResultMessage } = props

  return (
    <>
      {problemas?.isNotEmpty() ? (
        <MainDetailAccordion<EvolucaoProblema, Problema>
          items={problemas}
          renderHeader={(renderProps) => <HeaderProblemaModal highlight={filtro} {...renderProps} />}
          renderDetailBody={BodyProblemaModal}
        />
      ) : (
        <Text component='p'>{noResultMessage}</Text>
      )}
    </>
  )
}
