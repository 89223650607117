import { Button, Icon, PagedTable, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { TableBox } from 'components/table'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { css } from 'emotion'
import { useExcluirFechamentoAgendaMutation, useFechamentoAgendaTableQuery } from 'graphql/hooks.generated'
import { FechamentoAgendaQueryInput } from 'graphql/types.generated'
import moment from 'moment'
import React, { useState } from 'react'

import FechamentoAgendaForm from './FechamentoAgendaForm'

interface FechamentoModel {
  id?: ID
  dataInicial?: LocalDate
  dataFinal?: LocalDate
  motivo?: string
  especifique?: string
}

interface FechamentoTableProps {
  idLotacao: ID
}

export default function FechamentoAgendaTable(props: FechamentoTableProps) {
  const { idLotacao } = props
  const alert = useAlert()

  const handleRejection = useErrorHandler()
  const [mutationExcluirFechamento] = useExcluirFechamentoAgendaMutation()

  const [state, setState] = useState<FechamentoAgendaQueryInput>({
    idLotacao: idLotacao,
    pageParams: {
      size: 10,
      sort: ['inicio'],
    },
  })

  const {
    data: { fechamentos },
    refetch,
    loading,
  } = useFechamentoAgendaTableQuery({
    variables: { input: { idLotacao: state.idLotacao, pageParams: state.pageParams } },
  })

  const handleExludeRow = (row: FechamentoModel) => {
    confirm({
      title: 'Deseja excluir o fechamento?',
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return mutationExcluirFechamento({ variables: { id: row.id } })
          .then(() => {
            alert('success', 'Fechamento excluído com sucesso.')
            refetch()
          })
          .catch(handleRejection)
      },
    })()
  }

  const renderPeriodo = (row: FechamentoModel) => {
    const dateFormat = 'DD/MM/YYYY'
    const dtInicialFormat = moment(row.dataInicial).format(dateFormat)
    const dtFinalFormat = moment(row.dataFinal).format(dateFormat)
    return (
      <Text>
        {dtInicialFormat}&nbsp;-&nbsp;{dtFinalFormat}
      </Text>
    )
  }

  const renderMotivo = (row: FechamentoModel) => {
    const motivo = row.motivo === 'Outro' ? `${row.especifique} - ${row.motivo}` : row.motivo
    return (
      <Text
        style={css`
          word-break: break-word;
        `}
      >
        {motivo}
      </Text>
    )
  }

  const renderBotoes = (row: FechamentoModel) => {
    return (
      <Button size='small' skin='ghost' onClick={() => handleExludeRow(row)}>
        <Icon icon='trashOutline' />
      </Button>
    )
  }

  const tableProps = usePagedTableProps({
    result: fechamentos,
    loading,
    onChange: setState,
  })

  return (
    <>
      <Box
        style={css`
          margin-top: 1.5rem;
          margin-bottom: 1rem;
        `}
      >
        <FechamentoAgendaForm onUpdated={refetch} idLotacao={idLotacao} />
      </Box>
      <TableBox>
        <PagedTable<FechamentoModel>
          {...tableProps}
          columns={[
            {
              name: 'period',
              header: 'Período',
              sortable: false,
              render: renderPeriodo,
              style: css`
                width: 20%;
              `,
            },
            {
              name: 'motivo',
              header: 'Motivo',
              sortable: false,
              render: renderMotivo,
              style: css`
                width: 75%;
              `,
            },
            {
              name: 'actions',
              render: renderBotoes,
              style: css`
                textalign: 'right';
                width: 5%;
              `,
            },
          ]}
        />
      </TableBox>
    </>
  )
}
