import { Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { CategoriaSubstanciaAlergia } from 'graphql/types.generated'
import React from 'react'
import { categoriaAlergiaRecord } from 'view/atendimentos/detail/soap/aside/types/AlergiaModel'

export type CategoriaSubstanciaAlergiaSelectModel = CategoriaSubstanciaAlergia

export interface CategoriaSubstanciaAlergiaSelectFieldProps
  extends Omit<SelectFieldProps<CategoriaSubstanciaAlergiaSelectModel>, 'items' | 'itemToString'> {}

const itemToString = (item: CategoriaSubstanciaAlergiaSelectModel) => item && categoriaAlergiaRecord[item].descricao

const renderItem = (item: CategoriaSubstanciaAlergiaSelectModel) => (
  <Text fontWeight='bold'>{item && categoriaAlergiaRecord[item].descricao}</Text>
)

const sortedItems = Object.values(CategoriaSubstanciaAlergia).sort((a, b) =>
  itemToString(a).localeCompare(itemToString(b))
)

export function CategoriaSubstanciaAlergiaSelectField(props: CategoriaSubstanciaAlergiaSelectFieldProps) {
  return (
    <SelectField<CategoriaSubstanciaAlergiaSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      items={sortedItems}
      {...props}
    />
  )
}
