export function calcularRiscoCardiovascular(sexo, valor) {
  const riscoAlto = 'Risco cardiovascular alto'
  const riscoAumentado = 'Risco cardiovascular aumentado'

  switch (sexo) {
    case 'FEMININO':
      if (valor >= 88) {
        return riscoAlto
      } else if (valor >= 80 && valor < 88) {
        return riscoAumentado
      }
      break
    case 'MASCULINO':
      if (valor >= 102) {
        return riscoAlto
      } else if (valor >= 94 && valor < 102) {
        return riscoAumentado
      }
      break
    default:
  }
}
