import { AvaliacaoDesenvolvimentoInput, PrenatalPartoNascimentoInput, PuericulturaInput } from 'graphql/types.generated'
import { parseNumber } from 'util/number'

import { AlteracoesFenotipicasModel } from './alteracao-fenotipica/AlteracaoFenotipicaView'
import { FatoresRiscoModel } from './fatores-risco/FatoresRiscoView'
import { MarcoFormSectionAccordionModel } from './marcos/MarcoFormSectionAccordion'
import { AvaliacaoDesenvolvimentoModel, PrenatalPartoNascimentoModel, PuericulturaModel } from './model'
import { alcancadoComAtivo } from './util'

export const convertPuericulturaToInput = (values: PuericulturaModel): PuericulturaInput => ({
  prenatalPartoNascimento:
    values.prenatalPartoNascimento && convertPrenatalPartoNascimento(values.prenatalPartoNascimento),
  tipoAleitamento: values.tipoAleitamento,
  fatoresRisco: values.fatoresRisco && convertFatoresRisco(values.fatoresRisco),
  alteracoesFenotipicas: values.alteracoesFenotipicas && convertAlteracoesFenotipicas(values.alteracoesFenotipicas),
  marcosDesenvolvimento: values.marcosDesenvolvimento && convertMarcosDesenvolvimento(values.marcosDesenvolvimento),
})

export const convertPrenatalPartoNascimento = (values: PrenatalPartoNascimentoModel): PrenatalPartoNascimentoInput => ({
  tipoParto: values.tipoParto?.value,
  tipoGravidez: values.tipoGravidez?.value,
  apgarPrimeiroMinuto: values.apgar?.apgarUm,
  apgarQuintoMinuto: values.apgar?.apgarCinco,
  apgarDecimoMinuto: values.apgar?.apgarDez,
  altura: parseNumber(values.antropometriaAoNascer?.comprimento),
  peso: parseNumber(values.antropometriaAoNascer?.peso),
  perimetroCefalico: parseNumber(values.antropometriaAoNascer?.perimetroCefalico),
  idadeGestacionalEmDias: values.idadeGestacional?.dias,
  idadeGestacionalEmSemanas: values.idadeGestacional?.semanas,
})

const convertFatoresRisco = (values: FatoresRiscoModel): AvaliacaoDesenvolvimentoInput[] =>
  values.fatoresRisco.map((it) => ({
    id: it.id,
    status: it.statusButton,
  }))

const convertAlteracoesFenotipicas = (values: AlteracoesFenotipicasModel): AvaliacaoDesenvolvimentoInput[] =>
  values.alteracoesFenotipicas.map((it) => ({
    id: it.id,
    status: it.statusButton,
  }))

const convertMarcosDesenvolvimento = (values: MarcoFormSectionAccordionModel): AvaliacaoDesenvolvimentoInput[] =>
  values.marcosRecord &&
  Object.values(values.marcosRecord)?.flatMap((marcoSection: AvaliacaoDesenvolvimentoModel[]) =>
    marcoSection.map((it: AvaliacaoDesenvolvimentoModel) => ({
      id: it.id,
      status: it.statusButton,
      alcancadoComAnos: alcancadoComAtivo(it) ? Number(it.alcancadoCom?.anos) : undefined,
      alcancadoComMeses: alcancadoComAtivo(it) ? Number(it.alcancadoCom?.meses) : undefined,
    }))
  )
