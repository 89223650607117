import { Cell, Grid, HFlow, Icon, InfoLabel, Tag, Text, Theme, Tooltip, useStyles, VFlow } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { DateTime } from 'components/date'
import { AccordionField } from 'components/form/field/AccordionField'
import { css } from 'emotion'
import { OrigemAtendimento } from 'graphql/types.generated'
import { noop } from 'lodash'
import React, { CSSProperties } from 'react'
import { AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'

import { origemDadosVacinacao } from '../model'
import { SubModalVacinacaoOptions } from '../VacinacaoModal'
import { VacinacaoVisualizacaoButtons } from './VacinacaoVisualizacaoButtons'

export interface AprazamentoDoseProps {
  dataRegistro: string
  dataAprazamento: string
  estrategia: string
  nomeProfissional: string
  cbo: string
  unidadeSaude: string
  origemDados: OrigemAtendimento
  registradoAtendimento: boolean
  idUnicoRegistro?: ID
  handleOnDelete?(idUnicoRegistro: ID, tipoModal: SubModalVacinacaoOptions): void
  handleOnEdit?(idUnicoRegistro: ID, tipoModal: SubModalVacinacaoOptions): void
  readOnly?: boolean
  idUnicoRegistroAplicacaoProxDose?: ID
}

export const AprazamentoDose = (props: AprazamentoDoseProps) => {
  const {
    dataRegistro,
    dataAprazamento,
    estrategia,
    nomeProfissional,
    cbo,
    unidadeSaude,
    origemDados,
    registradoAtendimento,
    idUnicoRegistro,
    handleOnDelete = noop,
    handleOnEdit = noop,
    readOnly = false,
    idUnicoRegistroAplicacaoProxDose,
  } = props

  const { classes } = useStyles(createStyles)

  const renderAprazamentoDoseContent = () => (
    <AprazamentoDoseContent
      dataRegistro={dataRegistro}
      estrategia={estrategia}
      nomeProfissional={nomeProfissional}
      cbo={cbo}
      unidadeSaude={unidadeSaude}
    />
  )

  if (readOnly) {
    return renderAprazamentoDoseContent()
  }

  return (
    <VFlow vSpacing={0}>
      <AccordionField name='Registro' allowZeroExpanded>
        <AccordionItem>
          <AccordionButton style={classes.accordionButton}>
            <HFlow
              alignItems='center'
              justifyContent='space-between'
              style={css`
                padding: 0.5rem;
                min-width: 35rem;
                width: 100%;
              `}
            >
              <HFlow>
                <Icon icon='angleDown' size={1.5} />
                <HFlow hSpacing={0.3}>
                  <Icon color={blue.c40} icon='calendarFilled' size={1.1} />
                  <Text fontWeight='bold'>Dose aprazada para </Text>
                  <DateTime
                    value={dataAprazamento}
                    style={css`
                      font-weight: bold;
                    `}
                    format='DD/MM/YYYY'
                  />
                </HFlow>
              </HFlow>
              <HFlow hSpacing={0} alignItems='center'>
                <Tooltip text={origemDadosVacinacao[origemDados]?.hint}>
                  <Tag type='info'>{origemDadosVacinacao[origemDados]?.label}</Tag>
                </Tooltip>
                {registradoAtendimento && (
                  <VacinacaoVisualizacaoButtons
                    idUnicoRegistro={idUnicoRegistro}
                    tipoModal='APRAZAMENTO'
                    idUnicoRegistroAplicacaoProxDose={idUnicoRegistroAplicacaoProxDose}
                    handleOnDelete={handleOnDelete}
                    handleOnEdit={handleOnEdit}
                  />
                )}
              </HFlow>
            </HFlow>
          </AccordionButton>
          <AccordionItemPanel className={classes.panel}>{renderAprazamentoDoseContent()}</AccordionItemPanel>
        </AccordionItem>
      </AccordionField>
    </VFlow>
  )
}

interface AprazamentoDoseContentProps {
  dataRegistro: string
  estrategia: string
  nomeProfissional: string
  cbo: string
  unidadeSaude: string
}

const AprazamentoDoseContent = (props: AprazamentoDoseContentProps) => {
  const { unidadeSaude, estrategia, nomeProfissional, cbo, dataRegistro } = props

  return (
    <Grid
      gap={0}
      gapVertical={1}
      style={css`
        padding: 1rem;
        width: 36rem;
      `}
    >
      <Cell size={12}>
        <InfoLabel title='Estratégia'>{estrategia?.capitalize()}</InfoLabel>
      </Cell>
      <Cell>
        <InfoLabel title='Aprazado por'>{`${nomeProfissional} (${cbo})`}</InfoLabel>
        {unidadeSaude}
      </Cell>
      <Cell size={12}>
        <Text fontWeight='bold'>Aprazamento registrado em </Text>
        <DateTime
          value={dataRegistro}
          style={css`
            font-weight: bold;
          `}
          format='DD/MM/YYYY [às] HH:mm'
        />
      </Cell>
    </Grid>
  )
}

const createStyles = (theme: Theme) => ({
  panel: {
    margin: '0 0.3rem',
    borderWidth: '0 0.063rem 0.063rem',
    borderStyle: 'solid',
    borderColor: theme.pallete.gray.c60,
    position: 'relative',
    top: '-0.6rem',
  } as CSSProperties,
  accordionButton: {
    display: 'flex',
    padding: '0.5rem',
    border: `0.063rem solid ${theme.pallete.gray.c60}`,
    borderBlockEnd: `0.063rem solid ${theme.pallete.gray.c80}`,
  } as CSSProperties,
})
