/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Heading, HFlow, Icon, VFlow } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { AccordionInformation } from 'components/accordion/AccordionInformation'
import { PageContent } from 'components/layout/PageContent'
import theme from 'config/theme'
import { Fragment, useState } from 'react'
import { Accordion, AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'

import { EnvioDadosView } from './EnvioDadosView'
import { UnificacaoBaseTopic } from './model'
import { RecebimentoDadosView } from './RecebimentoDadosView'
import { UnificacaoBaseListener } from './UnificacaoBaseListener'
import { UnificacaoBaseOrientacoesView } from './UnificacaoBaseOrientacoesView'

interface ConfiguracaoUnificacaoBaseBoxProps {
  conexaoInternet: boolean
  agendaOnlineAtiva: boolean
}

export const ConfiguracaoUnificacaoBaseBox = (props: ConfiguracaoUnificacaoBaseBoxProps) => {
  const { conexaoInternet, agendaOnlineAtiva } = props

  const [topicMessage, setTopicMessage] = useState<UnificacaoBaseTopic>({ origem: undefined })

  return (
    <Fragment>
      <UnificacaoBaseListener handleMessage={setTopicMessage} />
      <VFlow>
        {!conexaoInternet && (
          <Alert type='info' inline>
            Não é possível unificar bases pois não há conexão com a internet
          </Alert>
        )}
        <PageContent type='filled' style={styles.pageContent}>
          <VFlow>
            <AccordionInformation
              type='info'
              uuid='unificacaoBase-info'
              header='Informações importantes para realizar a unificação de base'
            >
              <UnificacaoBaseOrientacoesView />
            </AccordionInformation>

            <Accordion allowZeroExpanded={true}>
              <AccordionItem>
                <AccordionButton
                  wrapperStyle={css`
                    padding-bottom: 0;
                  `}
                  style={styles.button}
                >
                  <HFlow alignItems='center' style={styles.heading}>
                    <Icon icon='angleDown' />
                    <Heading level={3}>Envio de dados para a instalação central do município</Heading>
                  </HFlow>
                </AccordionButton>
                <AccordionItemPanel css={styles.content}>
                  <EnvioDadosView
                    conexaoInternet={conexaoInternet}
                    agendaOnlineAtiva={agendaOnlineAtiva}
                    topicMessage={topicMessage}
                    setTopicMessage={setTopicMessage}
                  />
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>

            <Accordion allowZeroExpanded={true}>
              <AccordionItem>
                <AccordionButton
                  wrapperStyle={css`
                    padding-bottom: 0;
                  `}
                  style={styles.button}
                >
                  <HFlow alignItems='center' style={styles.heading}>
                    <Icon icon='angleDown' />
                    <Heading level={3}>Recebimento de dados de instalações locais</Heading>
                  </HFlow>
                </AccordionButton>
                <AccordionItemPanel css={styles.content}>
                  <RecebimentoDadosView
                    agendaOnlineAtiva={agendaOnlineAtiva}
                    topicMessage={topicMessage}
                    setTopicMessage={setTopicMessage}
                  />
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </VFlow>
        </PageContent>
      </VFlow>
    </Fragment>
  )
}

const styles = {
  heading: css`
    padding: 0.5rem 0.7rem;
  `,
  button: css`
    position: relative;
    border: 1px solid ${theme.pallete.gray.c60};
    background-color: ${theme.pallete.gray.c90};
    border-radius: 2px;
    margin: 0;
  `,
  content: css`
    padding: 1rem;
    background-color: ${theme.pallete.primary.c100};
    border: 1px solid ${theme.pallete.gray.c60};
    border-top: 0px;
  `,
  pageContent: css`
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: 2px;
  `,
}
