import { Cell, Grid, Tooltip, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { isEstagio } from 'components/auth/useSessionUtils'
import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
} from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import {
  RacionalidadeEmSaudeSelectField,
  RacionalidadeEmSaudeSelectModel,
} from 'components/form/field/select/RacionalidadeEmSaudeSelectField'
import { TipoParticipacaoAtendimentoSelectModel } from 'components/form/field/select/TipoParticipacaoAtendimentoSelectField'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { useFlags } from 'config/useFlagsContext'
import {
  CondutaEnum,
  FornecimentoOdontoEnum,
  TipoAtendimentoEnum,
  TipoConsultaOdontoEnum,
  TipoEncaminhamentoOdontoEnum,
} from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React from 'react'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { isUndefinedOrNull } from 'util/checks'
import { instantToYyyyMmDd } from 'util/date/converter'
import { dateAsYyyyMmDd, toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'
import {
  OBSERVACAO_DISABLE_ATENDIMENTO_COMPARTILHADO,
  OBSERVACAO_DISABLE_RACIONALIDADE_EM_SAUDE,
} from 'view/atendimentos/atendimento-individual/atendimento-observacao/model-atendObservacao'
import { meta as metaSoap } from 'view/atendimentos/atendimento-individual/model'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'

import { TipoPreNatal } from '../pre-natal/model-preNatal'
import { AgendarConsultaPanel, AgendarConsultaPanelModel } from './components/AgendarConsultaPanel'
import { AtendimentoCompartilhadoFieldGroup } from './components/AtendimentoCompartilhadoFieldGroup'
import { DesfechoAtendimentoFormModel, DesfechoAtendimentoPanel } from './components/DesfechoAtendimentoPanel'
import { FornecimentoPanel } from './components/FornecimentoPanel'
import {
  NotificacaoCasoSuspeitoField,
  NotificacaoCasoSuspeitoFieldModel,
} from './components/NotificacaoCasoSuspeitoField'
import { ParticipacaoCidadaoFieldGroup } from './components/ParticipacaoCidadaoFieldGroup'
import { ProcedimentoSigtapField, ProcedimentoSigtapFieldModel } from './components/ProcedimentoSigtapField'
import { TipoAtendimentoPanel } from './components/TipoAtendimentoPanel'
import { TipoConsultaPanel } from './components/TipoConsultaPanel'
import { FinalizacaoAtendimentoCondutaPanel } from './FinalizacaoAtendimentoCondutaPanel'
import { MSG_OBSERVACAO_TOOLTIP_TIPO_ATENDIMENTO } from './model-finalizacao'

export interface FinalizacaoAtendimentoFormModel {
  tipoAtendimento?: TipoAtendimentoEnum
  tipoConsulta?: TipoConsultaOdontoEnum
  atendimentoCompartilhadoLotacao?: LotacaoAndEstagioSelectFieldModel
  fornecimento?: FornecimentoOdontoEnum[]
  procedimentosAdministrativos?: Array<ProcedimentoSigtapFieldModel>
  fichasNotificacaoCasoSuspeito?: Array<NotificacaoCasoSuspeitoFieldModel>
  racionalidadeEmSaude?: RacionalidadeEmSaudeSelectModel
  conduta?: CondutaEnum[]
  condutasOdonto?: TipoEncaminhamentoOdontoEnum[]
  desfechoAtendimento?: DesfechoAtendimentoFormModel
  agendamentoConsultas?: AgendarConsultaPanelModel
  cidadaoPresente?: boolean
  tipoParticipacaoCidadao?: TipoParticipacaoAtendimentoSelectModel
  tipoParticipacaoProfissionalConvidado?: TipoParticipacaoAtendimentoSelectModel
}

export interface FinalizacaoAtendimentoFormProps {
  name: MetaPath<FinalizacaoAtendimentoFormModel>
  profissionalId?: ID
  dataAtendimento: Instant
  agendamentoAtendimentoId?: ID
  concluiAgendamento: boolean
  agendamentosDia?: AgendamentoDia[]
  isAtendimentoProcedimentos: boolean
  isAtendimentoOdonto: boolean
  tipoPreNatal?: TipoPreNatal
  isRegistroTardio: boolean
}

export const FinalizacaoAtendimentoForm = React.memo((props: FinalizacaoAtendimentoFormProps) => {
  const {
    agendamentoAtendimentoId,
    concluiAgendamento,
    profissionalId,
    name,
    dataAtendimento,
    agendamentosDia,
    isAtendimentoProcedimentos,
    isAtendimentoOdonto,
    tipoPreNatal,
    isRegistroTardio,
  } = props

  const { PILOTO_VIDEOCHAMADA_3_ENABLED } = useFlags()

  const { acesso } = useAcessoLotacaoOrEstagio()

  const profissionaisIdsInvalidosAtendimentoCompartilhado = useMemo(
    () => [profissionalId, ...(isEstagio(acesso) ? [acesso.lotacaoSupervisora.profissional.id] : [])],
    [acesso, profissionalId]
  )

  const {
    observacao: {
      isAtendimentoObservacao,
      isObservacaoAndAuxiliar,
      isObservacaoAndResponsavel,
      atendeuComoResponsavel,
    },
    cidadao,
    prontuario: { id: prontuarioId },
    tipoEstabelecimento,
  } = useAtendimentoContext()

  const { values: formValues, initialValues } = useFormState({ subscription: { values: true, initialValues: true } })

  const values = resolveValue(formValues, name)
  const isObservacao = isAtendimentoObservacao || resolveValue(formValues, metaSoap.plano?.startObservacao)

  const hasInitialValueAtendimentoCompartilhado = !isUndefinedOrNull(
    resolveValue(initialValues, metaSoap.finalizacao.atendimentoCompartilhadoLotacao)
  )
  const hasInitialValueRacionalidadeEmSaude = !isUndefinedOrNull(
    resolveValue(initialValues, metaSoap.finalizacao.racionalidadeEmSaude)
  )
  const hasNotificacaoCasoSuspeito = !isEmpty(values.fichasNotificacaoCasoSuspeito)

  const isLotacaoAndEstagioDisabled = isAtendimentoObservacao && hasInitialValueAtendimentoCompartilhado
  const isRacionalidadeEmSaudeDisabled = isAtendimentoObservacao && hasInitialValueRacionalidadeEmSaude

  const isAtendimentoAgendado = !!agendamentoAtendimentoId
  const agendamentosMostrar = concluiAgendamento
    ? agendamentosDia.filter((agendamento) => agendamento.id !== agendamentoAtendimentoId)
    : agendamentosDia

  return (
    <>
      <Grid gapVertical={1}>
        {!isAtendimentoProcedimentos && (
          <Cell size={12}>
            <TipoAtendimentoPanel
              name={name.tipoAtendimento}
              isAtendimentoAgendado={isAtendimentoAgendado}
              isAtendimentoOdonto={isAtendimentoOdonto}
              isObservacao={isObservacao}
              tipoEstabelecimento={tipoEstabelecimento}
              tooltipText={isAtendimentoObservacao && MSG_OBSERVACAO_TOOLTIP_TIPO_ATENDIMENTO}
              disabled={isAtendimentoObservacao && !!atendeuComoResponsavel}
            />
          </Cell>
        )}
        {isAtendimentoOdonto && (
          <Cell size={12}>
            <TipoConsultaPanel
              name={name.tipoConsulta}
              isDemandaEspontanea={!concluiAgendamento}
              isUrgencia={values.tipoAtendimento === TipoAtendimentoEnum.URGENCIA}
            />
          </Cell>
        )}
        {PILOTO_VIDEOCHAMADA_3_ENABLED && !isAtendimentoProcedimentos ? (
          <>
            <Cell size={6}>
              <ParticipacaoCidadaoFieldGroup name={name} />
            </Cell>
            <Cell size={6} />
            <Cell size={6}>
              <AtendimentoCompartilhadoFieldGroup
                name={name}
                disabled={isLotacaoAndEstagioDisabled}
                profissionaisIdsInvalidosAtendimentoCompartilhado={profissionaisIdsInvalidosAtendimentoCompartilhado}
              />
            </Cell>
          </>
        ) : (
          !isAtendimentoProcedimentos && (
            <Cell size={8}>
              <Tooltip text={isLotacaoAndEstagioDisabled && OBSERVACAO_DISABLE_ATENDIMENTO_COMPARTILHADO}>
                <LotacaoAndEstagioSelectField
                  excludeProfissionaisIds={profissionaisIdsInvalidosAtendimentoCompartilhado}
                  name={name.atendimentoCompartilhadoLotacao}
                  label='Atendimento compartilhado'
                  loadItemsOnOpen={false}
                  disabled={isLotacaoAndEstagioDisabled}
                />
              </Tooltip>
            </Cell>
          )
        )}
        {isAtendimentoOdonto && (
          <Cell size={8}>
            <FornecimentoPanel name={name.fornecimento} />
          </Cell>
        )}
        <Cell size={12}>
          <ProcedimentoSigtapField
            name={name.procedimentosAdministrativos}
            cidadao={cidadao}
            dataAtendimento={dateAsYyyyMmDd(toDate(dataAtendimento))}
          />
        </Cell>
        <Cell size={12}>
          <NotificacaoCasoSuspeitoField
            name={name.fichasNotificacaoCasoSuspeito}
            cidadaoId={cidadao?.id}
            dataAtendimento={dataAtendimento}
          />
        </Cell>
        <Cell size={8}>
          <Tooltip text={isRacionalidadeEmSaudeDisabled && OBSERVACAO_DISABLE_RACIONALIDADE_EM_SAUDE}>
            <RacionalidadeEmSaudeSelectField
              name={name.racionalidadeEmSaude}
              label='Racionalidade em saúde (Exceto alopatia/convencional)'
              disabled={isRacionalidadeEmSaudeDisabled}
            />
          </Tooltip>
        </Cell>
        <Cell size={12}>
          <FinalizacaoAtendimentoCondutaPanel
            name={name}
            hasNotificacaoCasoSuspeito={hasNotificacaoCasoSuspeito}
            tipoConsulta={values.tipoConsulta}
            isAtendimentoOdonto={isAtendimentoOdonto}
            isAtendimentoProcedimentos={isAtendimentoProcedimentos}
            isObservacao={isObservacao}
          />
        </Cell>
        <Cell size={12}>
          <VFlow>
            {!isRegistroTardio && (
              <DesfechoAtendimentoPanel
                name={name.desfechoAtendimento}
                condutasName={name.conduta}
                agendamentosDia={agendamentosMostrar}
                isAtendimentoObservacao={isAtendimentoObservacao}
                isObservacaoAndResponsavel={isObservacaoAndResponsavel}
                isObservacaoAndAuxiliar={isObservacaoAndAuxiliar}
                hasNotificacaoCasoSuspeito={hasNotificacaoCasoSuspeito}
              />
            )}
            {!isObservacaoAndAuxiliar && (
              <AgendarConsultaPanel
                name={name.agendamentoConsultas}
                isRegistroTardio={isRegistroTardio}
                tipoPreNatal={tipoPreNatal}
                prontuarioId={prontuarioId}
                dataAtendimento={instantToYyyyMmDd(dataAtendimento)}
              />
            )}
          </VFlow>
        </Cell>
      </Grid>
    </>
  )
})
