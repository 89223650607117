import { Alert, HFlow, Icon, VFlow } from 'bold-ui'
import { Text } from 'bold-ui'
import { DateTime } from 'components/date'
import theme from 'config/theme'
import { UltimaVacina } from 'graphql/types.generated'
import React from 'react'

import { ContentCardSection } from '../components/ContentCardSection'

interface AcompanhamentoVacinacaoCardProps {
  ultimasVacinasAplicadas: UltimaVacina[]
  ultimaVacinaAprazada: UltimaVacina
  hasVacinaAtrasada: boolean
  loading: boolean
}

export const AcompanhamentoVacinacaoCard = (props: AcompanhamentoVacinacaoCardProps) => {
  return (
    <ContentCardSection isLoading={props.loading}>
      {props.ultimasVacinasAplicadas && (
        <>
          <Text fontWeight='bold'>Últimas aplicações:</Text>
          {props.ultimasVacinasAplicadas.map((vacinaAplicada) => {
            return (
              <HFlow hSpacing={0.5} style={{ padding: '0.5rem 0' }}>
                <Icon icon='needleFilled' color={theme.pallete.status.success.c50} />
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>
                    {vacinaAplicada.imunobiologicoNome} ({vacinaAplicada.doseNomeApresentacao})
                  </Text>
                  <DateTime format='DD/MM/YYYY' value={vacinaAplicada.dataAplicacao} />
                </VFlow>
              </HFlow>
            )
          })}
        </>
      )}

      {props.ultimaVacinaAprazada && (
        <>
          <Text fontWeight='bold'>Última aplicação aprazada:</Text>

          <HFlow hSpacing={0.5} style={{ padding: '0.5rem 0' }}>
            <Icon icon='calendarFilled' color={theme.pallete.gray.c30} />
            <VFlow vSpacing={0}>
              <Text fontWeight='bold'>
                {props.ultimaVacinaAprazada.imunobiologicoNome} ({props.ultimaVacinaAprazada.doseNomeApresentacao})
              </Text>
              <DateTime format='DD/MM/YYYY' value={props.ultimaVacinaAprazada.dataAprazamento} />
            </VFlow>
          </HFlow>
        </>
      )}

      {props.hasVacinaAtrasada && (
        <Alert type='warning'>
          Existem vacinas atrasadas ou não registradas! Confira o cartão de vacinação do cidadão.
        </Alert>
      )}
    </ContentCardSection>
  )
}
