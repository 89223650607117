/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow } from 'bold-ui'
import { CheckboxField } from 'components/form'
import { MetaPath } from 'util/metaPath'

import { PeriogramaCompletoMultipleBoolean } from '../periograma-completo/model'

export interface PeriogramaCompletoCheckboxFieldProps {
  name: MetaPath<PeriogramaCompletoMultipleBoolean>
}

export default function PeriogramaCompletoCheckboxField(props: PeriogramaCompletoCheckboxFieldProps) {
  const { name } = props

  return (
    <HFlow
      hSpacing={0}
      justifyContent='center'
      alignItems='center'
      style={css`
        margin-left: -0.25rem;
        padding: 0 0.25rem;
      `}
    >
      <CheckboxField name={name.d} />

      <CheckboxField name={name.v} />

      <CheckboxField name={name.m} />
    </HFlow>
  )
}
