/** @jsx jsx */
import { jsx } from '@emotion/core'
import { MetaPath } from 'util/metaPath'

import { PrenatalPartoNascimentoModel } from '../objetivo/puericultura/model'
import { PrenatalPartoNascimentoForm } from '../objetivo/puericultura/prenatal-parto-nascimento/components/PrenatalPartoNascimentoForm'
import { AntecedentesFormSectionAccordion } from './AntecedentesFormSectionAccordion'

export interface PuericulturaPanelModel extends PrenatalPartoNascimentoModel {
  openedAccordions?: string[]
}

export interface PuericulturaPanelProps {
  name: MetaPath<PuericulturaPanelModel>
  dataAtualizacao: Instant
}

export function PuericulturaPanel(props: PuericulturaPanelProps) {
  const { name, dataAtualizacao } = props
  return (
    <AntecedentesFormSectionAccordion
      name={name.openedAccordions}
      title='Puericultura'
      uuid='puericultura'
      dataAtualizacao={dataAtualizacao}
    >
      <PrenatalPartoNascimentoForm name={name} />
    </AntecedentesFormSectionAccordion>
  )
}
