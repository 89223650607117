/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, VFlow } from 'bold-ui'
import { DecimalField, NumberField } from 'components/form'
import TipoGravidezSelectField from 'components/form/field/select/TipoGravidezSelectField'
import TipoPartoSelectField from 'components/form/field/select/TipoPartoSelectField'
import { SemanasDiasField } from 'components/form/field/SemanasDiasField'
import { MetaPath } from 'util/metaPath'

import { PrenatalPartoNascimentoModel } from '../../model'

export interface PrenatalPartoNascimentoFormProps {
  name: MetaPath<PrenatalPartoNascimentoModel>
}

export function PrenatalPartoNascimentoForm(props: PrenatalPartoNascimentoFormProps) {
  const { name } = props
  return (
    <VFlow>
      <VFlow>
        <Heading level={3}>Pré-natal, parto e nascimento</Heading>

        <Grid gap={1}>
          <Cell xs={6} lg={4}>
            <TipoGravidezSelectField name={name.tipoGravidez} />
          </Cell>

          <Cell xs={6} lg={4}>
            <TipoPartoSelectField name={name.tipoParto} />
          </Cell>

          <Cell xs={6} lg={4}>
            <SemanasDiasField name={name.idadeGestacional} label='Idade gestacional' />
          </Cell>
        </Grid>
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={4}>Apgar</Heading>

        <Grid gap={1}>
          <Cell size={3} xl={2}>
            <NumberField name={name.apgar.apgarUm} maxLength={2} label='1º minuto' />
          </Cell>

          <Cell size={3} xl={2}>
            <NumberField name={name.apgar.apgarCinco} maxLength={2} label='5º minuto' />
          </Cell>

          <Cell size={3} xl={2}>
            <NumberField name={name.apgar.apgarDez} maxLength={2} label='10º minuto' />
          </Cell>
        </Grid>
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={4}>Antropometria ao nascer</Heading>

        <Grid gap={1}>
          <Cell size={3} xl={2}>
            <DecimalField name={name.antropometriaAoNascer.peso} label='Peso (kg)' maxLength={7} precisao={3} />
          </Cell>

          <Cell size={3} xl={2}>
            <DecimalField
              name={name.antropometriaAoNascer.comprimento}
              label='Comprimento (cm)'
              maxLength={5}
              precisao={1}
            />
          </Cell>

          <Cell size={3} xl={2}>
            <DecimalField
              name={name.antropometriaAoNascer.perimetroCefalico}
              label={
                <span
                  css={css`
                    white-space: nowrap;
                  `}
                >
                  Perímetro cefálico (cm)
                </span>
              }
              maxLength={5}
              precisao={1}
            />
          </Cell>
        </Grid>
      </VFlow>
    </VFlow>
  )
}
