/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Icon, Text, Theme, useTheme } from 'bold-ui'

import { MicrophoneFeedback } from './MicrophoneFeedback'

interface MicrophoneFeedbackLabelProps {
  audioStreamInativo: boolean
  audioTrack: MediaStreamTrack
}

export function MicrophoneFeedbackLabel(props: MicrophoneFeedbackLabelProps) {
  const { audioStreamInativo, audioTrack } = props

  const theme = useTheme()
  const styles = createStyles(theme, audioStreamInativo)
  return (
    <div css={styles.microphoneLabel}>
      {audioStreamInativo ? (
        <Icon icon='microphoneOffFilled' size={1.25} />
      ) : (
        <MicrophoneFeedback alwaysVisible audioTrack={audioTrack} />
      )}
      <Text
        color='inherit'
        fontWeight='bold'
        style={css`
          margin-left: 0.5rem;
        `}
      >
        {audioStreamInativo ? 'Microfone desligado' : 'Microfone Ligado'}
      </Text>
    </div>
  )
}

const createStyles = (theme: Theme, audioStreamInativo: boolean) => ({
  microphoneLabel: css`
    display: flex;
    height: 1.5rem;
    align-items: center;
    justify-content: space-around;
    background-color: ${audioStreamInativo ? theme.pallete.gray.c30 : theme.pallete.primary.c40};
    border-radius: 12px;
    color: ${theme.pallete.gray.c100};
    padding: 0 0.5rem;
  `,
})
