/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, HFlow, Tooltip } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { FormPrompt } from 'components/form'
import { RadioGroupField } from 'components/form/field/RadioGroupField'
import { RichTextField } from 'components/form/final-form'
import { FormApi } from 'final-form'
import { VisibilidadeLembreteEnum } from 'graphql/types.generated'
import React from 'react'
import { createValidator, required, requiredRichText, richTextMaxLength } from 'util/validation'
import { LembreteEvolucaoModel, visibilidadeLembrete } from 'view/atendimentos/detail/soap/aside/types/LembreteModel'
import { grupoCboLembretes } from 'view/atendimentos/detail/soap/plano/acessos'

import { EditableListForm, EditableListFormProps, EditableListFormRenderProps } from '../../../EditableListForm'

export interface LembreteFormModel extends Pick<LembreteEvolucaoModel, 'visibilidadeLembrete' | 'descricao' | 'ativo'> {
  /**
   * Caso seja uma atualizacao de um lembrete antigo, serah o id desse lembrete a ser atualizado.
   * Se for um lembrete criado no atendimento atual, ele serah undefined.
   */
  lembreteId?: ID

  /**
   * O id do lembrete na cache do soap state.
   */
  _id: ID

  /**
   * Data de quando esse lembrete foi submetido no form e salvo na cache.
   */
  data?: Instant
}

interface LembreteFormProps extends EditableListFormProps<LembreteFormModel> {
  hasCancelButton: boolean
  canChangeVisibility: boolean
  isEditing: boolean
  onSubmit(formValues: LembreteFormModel, formApi: FormApi): void
  onCancel?(): void
}

export function LembreteForm(props: LembreteFormProps) {
  const { hasCancelButton, initialValues, isEditing, onSubmit, onCancel, canChangeVisibility } = props

  const prefix = isEditing ? initialValues._id ?? 'edit' : 'new'

  const renderForm = ({ handleSubmit, name, form }: EditableListFormRenderProps<LembreteFormModel>) => {
    return (
      <React.Fragment>
        <FormPrompt />
        <HFlow hSpacing={4}>
          <Cell size={6}>
            <Tooltip text={!canChangeVisibility && 'Lotações de estágio podem apenas criar lembretes públicos'}>
              <div
                css={css`
                  margin-right: auto;
                `}
              >
                <RadioGroupField
                  name={name.visibilidadeLembrete}
                  label='Visibilidade'
                  options={[
                    visibilidadeToRadioOption(VisibilidadeLembreteEnum.PUBLICO),
                    visibilidadeToRadioOption(VisibilidadeLembreteEnum.SOMENTE_EU),
                  ]}
                  required={canChangeVisibility}
                  disabled={!canChangeVisibility}
                />
              </div>
            </Tooltip>
          </Cell>
        </HFlow>
        <Cell size={12}>
          <RichTextField
            name={name.descricao}
            maxLength={500}
            placeholder='Insira as informações que serão observadas pelos profissionais nos próximos atendimentos.'
            removeNonBreakingSpaces
            required
          />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            {hasCancelButton && (
              <Button
                kind='normal'
                onClick={(e) => {
                  e.stopPropagation()
                  setTimeout(() => form.reset())
                  form.getRegisteredFields().forEach((item) => form.resetFieldState(item))
                  onCancel?.()
                }}
                size='small'
              >
                Cancelar
              </Button>
            )}
            <Button
              kind='primary'
              onClick={(e) => {
                e.stopPropagation()
                handleSubmit()
              }}
              size='small'
            >
              Salvar
            </Button>
          </HFlow>
        </Cell>
      </React.Fragment>
    )
  }

  return (
    <CheckPermission permission={grupoCboLembretes.adicionar}>
      <EditableListForm<LembreteFormModel>
        render={renderForm}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        prefix={prefix}
        onSubmitFailed={() => {}}
      />
    </CheckPermission>
  )
}

LembreteForm.defaultProps = {
  hasCancelButton: false,
  isEditing: false,
  hasAtivoSwitch: false,
} as Partial<LembreteFormProps>

const validate = createValidator<LembreteFormModel>({
  descricao: [requiredRichText, richTextMaxLength(500)],
  visibilidadeLembrete: [required],
  ativo: [required],
})

const visibilidadeToRadioOption = (visibilidade: VisibilidadeLembreteEnum) => ({
  value: visibilidade,
  label: visibilidadeLembrete[visibilidade],
})
