import { Alert, Link } from 'bold-ui'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React from 'react'

interface ExibindoSomenteNaoFinalizadosAlertProps {
  atendimentosOuRegistros: 'atendimentos' | 'registros'
  onClear(): void
}

export const ExibindoSomenteNaoFinalizadosAlert = (props: ExibindoSomenteNaoFinalizadosAlertProps) => {
  const { atendimentosOuRegistros, onClear } = props
  const { analytics } = useFirebase()

  const handleOnClear = () => {
    onClear()
    analytics.logEvent('voltar_filtro_padrao_msg_LA')
  }

  return (
    <Alert type='warning' style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
      Estão sendo exibidos somente os <strong>{atendimentosOuRegistros.capitalize()} não finalizados</strong>. Para
      visualizar apenas os {atendimentosOuRegistros} atuais,{' '}
      <Link tabIndex={1} onClick={handleOnClear} style={{ fontSize: 'inherit' }}>
        reverta para o filtro padrão
      </Link>
      .
    </Alert>
  )
}
