/** @jsx jsx */
import { jsx } from '@emotion/core'
import { memo, useMemo } from 'react'
import { HistoricoPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'

import HistoricoEscutaInicialPec from './HistoricoEscutaInicialPec'

interface HistoricoEscutaInicialPanelProps extends HistoricoPanelProps {}

function HistoricoEscutaInicialPanel(props: HistoricoEscutaInicialPanelProps) {
  const { isAtendRecente, idAtend, idadeOrigemAtendimento } = props

  const atendimentoPecProcessado: boolean = !isAtendRecente && idAtend

  const selectContent = useMemo(() => {
    return isAtendRecente || atendimentoPecProcessado ? (
      <HistoricoEscutaInicialPec idAtend={idAtend} idadeOrigemAtendimento={idadeOrigemAtendimento} />
    ) : null
  }, [isAtendRecente, atendimentoPecProcessado, idAtend, idadeOrigemAtendimento])

  return selectContent
}

export default memo(HistoricoEscutaInicialPanel)
