/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { ExternalStyles, Theme } from 'bold-ui'
import { useHeight } from 'hooks/useMeasure'
import React from 'react'
import { animated, useSpring } from 'react-spring'

export interface AccordionAnimationProps {
  children?: React.ReactNode
  style?: ExternalStyles
  wrapperStyle?: ExternalStyles
}

export function AccordionAnimation(props: AccordionAnimationProps) {
  const { children, style, wrapperStyle } = props
  const [ref, height] = useHeight()
  const springProps = useSpring({ height })

  return (
    <Animation style={springProps} css={wrapperStyle}>
      <div ref={ref} css={css(style)}>
        {children}
      </div>
    </Animation>
  )
}

const Animation = animated(styled.div<{}, Theme>`
  overflow: hidden;
`)
