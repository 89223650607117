/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Heading, HFlow, VFlow } from 'bold-ui'
import { SubmitButton } from 'components/form'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { MetaPath } from 'util/metaPath'
import { RowType, useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { CaracteristicasLesaoFieldModel } from './components/caracteristicas-lesao/CaracteristicasLesaoField'
import { LocalTecidosMolesSelectFieldModel } from './components/LocalTecidosMolesSelectField/LocalTecidosMolesSelectField'
import { TecidosMolesForm } from './TecidosMolesForm'
import TecidosMolesFormFields from './TecidosMolesFormFields'
import { TecidosMolesTable } from './TecidosMolesTable'
import { validateCaracteristicasLesao } from './validator'

export interface TecidosMolesFieldModel extends RowType {
  local: LocalTecidosMolesSelectFieldModel
  problema?: CidSelectFieldModel
  caracteristicasLesao?: CaracteristicasLesaoFieldModel
  procedimentos?: ProcedimentoOdontoSelectModel[]
  observacao?: string
  lastUpdate: Instant
}

interface TecidosMolesFieldProps {
  name: MetaPath<TecidosMolesFieldModel[]>
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
}

export default function TecidosMolesField(props: TecidosMolesFieldProps) {
  const { name, cidadaoDataNascimento, dataAtendimento } = props

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value },
  } = useEditableListField<TecidosMolesFieldModel>({
    name,
  })

  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={3}>Tecidos moles e duros</Heading>
        <TecidosMolesForm
          onSubmit={handleSubmit}
          procedimentosSelecionados={(value || [])?.map((item: TecidosMolesFieldModel) => item?.procedimentos).flat()}
          render={(formProps) => (
            <VFlow>
              <TecidosMolesFormFields
                parteBucalSelecionada={formProps.values?.local?.value}
                cidadaoDataNascimento={cidadaoDataNascimento}
                dataAtendimento={dataAtendimento}
                hasProcedimentos={formProps.values?.procedimentos?.length > 0}
                procedimentosSelecionados={(value || [])
                  ?.map((item: TecidosMolesFieldModel) => item?.procedimentos)
                  .flat()}
                caracteristicasLesaoError={validateCaracteristicasLesao(formProps)}
              />

              <HFlow justifyContent='flex-end'>
                <SubmitButton size='small' kind='primary' handleSubmit={formProps.handleSubmit}>
                  Adicionar
                </SubmitButton>
              </HFlow>
            </VFlow>
          )}
        />
      </VFlow>

      <TecidosMolesTable
        valoresAtuais={value}
        cidadaoDataNascimento={cidadaoDataNascimento}
        dataAtendimento={dataAtendimento}
        updateItem={handleRowChanged}
        removeItem={removeItem}
      />
    </VFlow>
  )
}
