/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tooltip } from 'bold-ui'
import { StatusPedidoEnvioEnum, StatusUnificacaoBaseEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { formatDate } from 'util/date/formatDate'

import {
  aguardandoAprovacaoList,
  aguardandoUnificacaoList,
  baseUnificadaList,
  erroUnificacaoList,
  erroVersaoList,
  solicitacaoCanceladaList,
  unificacaoNegadaList,
  unificandoBaseList,
} from './model'
import StatusSoliticacaoTag from './StatusSolicitacaoTag'

export interface StatusUnificacaoBaseTagProps {
  status: StatusUnificacaoBaseEnum | StatusPedidoEnvioEnum
  dataFimUnificacao: string
  isLoginAtivo: Boolean
}

export const StatusUnificacaoBaseTag = (props: StatusUnificacaoBaseTagProps) => {
  if (!props.isLoginAtivo) {
    return (
      <Tooltip text='Sessão expirada. Insira a senha novamente e acione a opção "Continuar unificação".'>
        <StatusSoliticacaoTag type='danger' icon='timesFilled'>
          Sessão expirada
        </StatusSoliticacaoTag>
      </Tooltip>
    )
  } else {
    return (
      <Fragment>
        {aguardandoAprovacaoList.includes(props.status) && (
          <Tooltip
            text={
              props.status in StatusUnificacaoBaseEnum
                ? 'Para dar continuidade, é necessário aceitar o pedido de solicitação de unificação.'
                : 'Aguardando a aprovação do administrador da instalação central do município para o envio de base.'
            }
          >
            <StatusSoliticacaoTag type='alert' icon='exclamationTriangleFilled'>
              Aguardando aprovação
            </StatusSoliticacaoTag>
          </Tooltip>
        )}
        {aguardandoUnificacaoList.includes(props.status) && (
          <Tooltip
            text={
              props.status in StatusUnificacaoBaseEnum
                ? 'Aguardando o início da unificação de bases pelo administrador da instalação solicitante.'
                : 'Solicitação aprovada. Acionar a opção "Unificar" para dar continuidade na unificação de bases.'
            }
          >
            <StatusSoliticacaoTag type='alert' icon='exclamationTriangleFilled'>
              Aguardando unificação
            </StatusSoliticacaoTag>
          </Tooltip>
        )}
        {unificandoBaseList.includes(props.status) && (
          <Tooltip text='As bases estão sendo unificadas. Aguarde até que o processo seja finalizado.'>
            <StatusSoliticacaoTag type='info' icon='clockFilled'>
              Unificando base
            </StatusSoliticacaoTag>
          </Tooltip>
        )}
        {baseUnificadaList.includes(props.status) && (
          <Tooltip
            text={
              props.status in StatusUnificacaoBaseEnum
                ? 'Unificação de bases concluída.'
                : 'Unificação de bases concluída. Para acessar o sistema, utilize o link da instalação central do município.'
            }
          >
            <StatusSoliticacaoTag type='success' icon='checkCircleFilled'>
              Base unificada
              <span
                css={css`
                  font-weight: normal;
                `}
              >
                &nbsp;- {formatDate(props.dataFimUnificacao)}
              </span>
            </StatusSoliticacaoTag>
          </Tooltip>
        )}
        {solicitacaoCanceladaList.includes(props.status) && (
          <Tooltip text='A solicitação de unificação de bases foi cancelada pelo solicitante.'>
            <StatusSoliticacaoTag type='normal' icon='banFilled'>
              Solicitação cancelada
            </StatusSoliticacaoTag>
          </Tooltip>
        )}
        {unificacaoNegadaList.includes(props.status) && (
          <Tooltip text='A solicitação foi negada e não será possível continuar com o processo de unificação.'>
            <StatusSoliticacaoTag type='normal' icon='banFilled'>
              Unificação negada
            </StatusSoliticacaoTag>
          </Tooltip>
        )}
        {erroUnificacaoList.includes(props.status) && (
          <Tooltip text='Unificação interrompida pois possui inconsistências no banco de dados. Entre em contato o suporte.'>
            <StatusSoliticacaoTag type='danger' icon='timesFilled'>
              Erro
            </StatusSoliticacaoTag>
          </Tooltip>
        )}
        {erroVersaoList.includes(props.status) && (
          <Tooltip text='Uma das instalações foi atualizada e as versões estão diferentes. É necessário que ambas tenham a mesma versão.'>
            <StatusSoliticacaoTag type='danger' icon='timesFilled'>
              Erro
            </StatusSoliticacaoTag>
          </Tooltip>
        )}
      </Fragment>
    )
  }
}
