import { Cell, Grid } from 'bold-ui'
import { TodasVinculacoesEquipesInput } from 'graphql/types.generated'
import React from 'react'

import { EquipeEMultiVinculacoesTableFilter } from './EquipeEMultiVinculacoesTableFilter'

interface EquipeEMultiVinculacoesTableHeaderProps {
  initialValues?: TodasVinculacoesEquipesInput
  onChange(filter: TodasVinculacoesEquipesInput): void
}

export function EquipeEMultiVinculacoesTableHeader(props: EquipeEMultiVinculacoesTableHeaderProps) {
  const { initialValues, onChange } = props

  return (
    <Grid>
      <Cell size={6}>
        <EquipeEMultiVinculacoesTableFilter initialValues={initialValues} onChange={onChange} />
      </Cell>
    </Grid>
  )
}
