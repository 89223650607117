/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  Grid,
  HeadingSection,
  HFlow,
  Icon,
  InfoLabel,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  useTheme,
  VFlow,
} from 'bold-ui'
import { calculateAge } from 'util/date/calculateAge'
import { humanizeAge } from 'util/date/humanize-age'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { PLACE_HOLDER_PUERICULTURA, PuericulturaModel } from '../../objetivo/puericultura/model'
import { handlePrint } from './AcompanhamentoPuericulturaSection'
import { DesenvolvimentoCriancaAcompanhamentoPuericultura } from './DesenvolvimentoCriancaAcompanhamentoPuericultura'
import { AcompanhamentoPuericulturaModel } from './model'
import { PreNatalPartoNascimentoAcompanhamentoPuericultura } from './PreNatalPartoNascimentoAcompanhamentoPuericultura'
import { tipoAleitamentoToString, ultimoAtendimentoLabel, vacinacaoToString } from './util/convertToLabel'

interface AcompanhamentoPuericulturaModalProps {
  acompanhamentoPuericultura: AcompanhamentoPuericulturaModel
  atendimentoPuericultura?: PuericulturaModel
  vacinacaoEmDiaAtendimento?: boolean
  isAntecedentes?: Boolean
  closeModal(): void
  dataAtendimento: Instant
  cidadao: Pick<CidadaoAtendimento, 'dataNascimento' | 'sexo'>
  prontuarioId: ID
  atendimentoId?: ID
}

export function AcompanhamentoPuericulturaModal(props: AcompanhamentoPuericulturaModalProps) {
  const {
    acompanhamentoPuericultura,
    closeModal,
    vacinacaoEmDiaAtendimento,
    atendimentoPuericultura,
    isAntecedentes,
    dataAtendimento,
    cidadao,
    prontuarioId,
    atendimentoId,
  } = props
  const theme = useTheme()

  const idadeCidadao = humanizeAge(cidadao?.dataNascimento, dataAtendimento)
  const { years, months } = calculateAge(cidadao?.dataNascimento, dataAtendimento)
  const idadeEmMeses = 12 * years + months
  const { tipoAleitamentoMaterno, preNatalPartoNascimento, vacinacaoEmDia } = acompanhamentoPuericultura || {}

  return (
    <Modal
      style={css`
        width: 60rem;
      `}
      open
      onClose={closeModal}
    >
      <ModalBody>
        <VFlow>
          <HeadingSection level={1} title='Acompanhamento da criança'>
            <HFlow
              hSpacing={0.2}
              style={css`
                margin-top: -1rem;
              `}
            >
              <Text>{idadeCidadao}</Text>
              {idadeEmMeses >= 12 && !isAntecedentes && <Text>({idadeEmMeses} meses)</Text>}
            </HFlow>
          </HeadingSection>
          <PreNatalPartoNascimentoAcompanhamentoPuericultura
            closeModal={closeModal}
            preNatalPartoNascimento={preNatalPartoNascimento}
            prenatalPartoNascimentoAtendimento={atendimentoPuericultura?.prenatalPartoNascimento}
            isAntecedentes={isAntecedentes}
          />

          <Grid
            style={css`
              border-top: 1px solid ${theme.pallete.divider};
            `}
          >
            <Cell size={3}>
              <InfoLabel title='Vacinação em dia' placeholder={PLACE_HOLDER_PUERICULTURA}>
                {vacinacaoToString(vacinacaoEmDiaAtendimento, dataAtendimento) ||
                  vacinacaoToString(vacinacaoEmDia?.valorMedicao as boolean, vacinacaoEmDia?.dataMedicao)}
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Aleitamento materno' placeholder={PLACE_HOLDER_PUERICULTURA}>
                {tipoAleitamentoToString(atendimentoPuericultura?.tipoAleitamento, dataAtendimento) ||
                  tipoAleitamentoToString(tipoAleitamentoMaterno?.tipo, tipoAleitamentoMaterno?.dataInicioAtendimento)}
              </InfoLabel>
            </Cell>
            <Cell size={6}>
              <InfoLabel title='Última consulta'>
                {ultimoAtendimentoLabel(acompanhamentoPuericultura?.ultimoAtendimentoPuericultura)}
              </InfoLabel>
            </Cell>
            <Cell size={12}>
              <DesenvolvimentoCriancaAcompanhamentoPuericultura
                acompanhamentoPuericultura={acompanhamentoPuericultura}
                atendimentoPuericultura={atendimentoPuericultura}
                dataAtendimento={dataAtendimento}
                cidadao={cidadao}
              />
            </Cell>
          </Grid>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button kind='primary' onClick={() => handlePrint(prontuarioId, atendimentoId)}>
            <HFlow>
              <Icon icon='printerOutline' />
              Imprimir acompanhamento
            </HFlow>
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
