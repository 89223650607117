import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text } from 'bold-ui'
import { UnidadeSaude } from 'graphql/types.generated'
import React from 'react'

interface VinculacoesEstabelecimentosTableRowPanelProps {
  estabelecimentosVinculados: UnidadeSaude[]
}

export function VinculacoesEstabelecimentosTableRowPanel(props: VinculacoesEstabelecimentosTableRowPanelProps) {
  const { estabelecimentosVinculados } = props

  return (
    <Table style={{ border: 0 }}>
      <TableHead>
        <TableRow>
          <TableHeader style={{ width: '30%' }}>Unidade básica de saúde</TableHeader>
          <TableHeader>CNES</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {estabelecimentosVinculados.map((row) => (
          <TableRow key={row.id}>
            <TableCell>
              <Text>{row.nome}</Text>
            </TableCell>
            <TableCell>
              <Text>{row.cnes}</Text>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
