import { Form, FormRenderProps } from 'components/form'
import { Decorator, FORM_ERROR, FormApi, FormState, SubmissionErrors, ValidationErrors } from 'final-form'
import React, { useMemo } from 'react'
import { FormProps } from 'react-final-form'
import { MetaPath, metaPath } from 'util/metaPath'
import { RowType } from 'view/atendimentos/detail/components/EditableList'

type FormType<T extends RowType> = { [prefix: string]: T }
export type EditableListFormRenderProps<T extends RowType> = Omit<FormRenderProps<FormType<T>>, 'values'> & {
  values: T
  name: MetaPath<T>
}

export interface EditableListFormProps<T extends RowType>
  extends Pick<FormProps<T>, 'initialValues' | 'validate'>,
    Omit<FormProps<FormType<T>>, 'initialValues' | 'onSubmit' | 'validate' | 'render' | 'decorator'> {
  render: (props: EditableListFormRenderProps<T>) => React.ReactNode
  prefix: string

  decorators?: Decorator[]

  onSubmit?(values: T, form: FormApi, callback?: (errors?: SubmissionErrors) => void): void

  onCancel?(): void

  onSubmitFailed?(formState: FormState<FormType<T>>): void
}

function convertValidation<T extends RowType>(
  validate: (vals: T) => ValidationErrors | Promise<ValidationErrors>,
  prefix: string
) {
  return (vals: FormType<T>) => Promise.resolve(validate(vals[prefix])).then((v) => ({ [prefix]: v }))
}

export function EditableListForm<T extends RowType>(props: EditableListFormProps<T>) {
  const { initialValues, render, validate, onSubmit, prefix, decorators, onSubmitFailed, ...rest } = props

  const validations = useMemo(() => convertValidation(validate, prefix), [validate, prefix])

  const meta = metaPath<FormType<T>>()

  const handleSubmit = useMemo(() => (values, form, callback) => onSubmit(values[prefix], form, callback), [
    onSubmit,
    prefix,
  ])

  const renderForm = useMemo(
    () => (props: FormRenderProps<FormType<T>>) =>
      render?.({
        ...props,
        values: props.values[prefix],
        name: meta[prefix],
        error: props.errors?.[prefix]?.[FORM_ERROR],
      }),
    [meta, prefix, render]
  )

  return (
    <Form<FormType<T>>
      validate={validations}
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={{ [prefix]: initialValues }}
      decorators={decorators}
      onSubmitFailed={onSubmitFailed}
      {...rest}
    />
  )
}
