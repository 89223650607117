import useSession from 'components/auth/useSession'
import { endOfDay, startOfDay, startOfMinute, sub } from 'date-fns'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { InstantRange } from 'util/date/dateRange'

import {
  LA_DEFAULT_PERIODO_IN_DAYS,
  LA_PERIODO_SOMENTE_NAO_FINALIZADOS_IN_DAYS,
  LA_UPA_PERIODO_IN_HOURS,
} from '../model'

/*
  Recalcula o período para input da query dos atendimentos
  quando filtrada por "Somente não finalizados"
  baseado no lastUpdate
 */

export function usePeriodoParaAtendimentosNaoFinalizados(lastUpdate: Instant) {
  const { tipoEstabelecimento } = useSession()

  return getPeriodoParaNaoFinalizados(lastUpdate, tipoEstabelecimento)
}

const getPeriodoParaNaoFinalizados = (
  lastUpdate: Instant,
  tipoEstabelecimento: TipoEstabelecimentoEnum
): InstantRange => {
  const isEstabelecimento24horas = tipoEstabelecimento === TipoEstabelecimentoEnum.UPA

  if (isEstabelecimento24horas) {
    const startDate = startOfMinute(sub(lastUpdate, { days: LA_PERIODO_SOMENTE_NAO_FINALIZADOS_IN_DAYS })).getTime()
    const endDate = startOfMinute(sub(lastUpdate, { hours: LA_UPA_PERIODO_IN_HOURS })).getTime()

    return { startDate, endDate }
  } else {
    const startDate = startOfDay(sub(lastUpdate, { days: LA_PERIODO_SOMENTE_NAO_FINALIZADOS_IN_DAYS })).getTime()
    const endDate = endOfDay(sub(lastUpdate, { days: LA_DEFAULT_PERIODO_IN_DAYS })).getTime()

    return { startDate, endDate }
  }
}
