import React from 'react'
import { Redirect, Switch } from 'react-router'

export const PecSwitch: React.SFC = props => {
  return (
    <Switch>
      {props.children}
      <Redirect to='/404' />
    </Switch>
  )
}
