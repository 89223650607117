import { msg } from '../messages'

const REGEX_CAMPO_NUMERO = /^[a-zA-Z0-9]*$/

export function numeroEndereco(value: string) {
  if (value !== 'S/N' && !isValid(value)) {
    return msg('addressNumberPattern', value)
  }
}

function isValid(value: any) {
  return !value || REGEX_CAMPO_NUMERO.test(value)
}
