import { getStepProps, Paper, Step, Stepper, Text, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import * as React from 'react'
import { InstalacaoHeader } from 'view/instalacao/InstalacaoHeader'

interface AutorizarMunicipioLayoutProps {
  step: AutorizarMunicipioSteps
  children: React.ReactNode
  isProntuario: boolean
}

export enum AutorizarMunicipioSteps {
  ATIVAR,
  CONFIGURAR_AGENDA_PADRAO,
  IMPORTAR_CNES,
}

export const AutorizarMunicipioLayout = (props: AutorizarMunicipioLayoutProps) => {
  const { step, children, isProntuario } = props

  return (
    <PageContent>
      <VFlow vSpacing={2}>
        <InstalacaoHeader>
          <Stepper style={{ width: '480px', margin: '0 auto' }}>
            <Step {...getStepProps(0, step)}>Ativar instalação</Step>
            {isProntuario && <Step {...getStepProps(1, step)}>Configurar agenda padrão</Step>}
            <Step {...getStepProps(2, step)}>
              <Text component='p'>Importar CNES</Text>
              <Text component='p' fontStyle='italic' fontWeight='normal'>
                Opcional
              </Text>
            </Step>
          </Stepper>
        </InstalacaoHeader>

        <Paper style={{ backgroundColor: 'white', padding: '2rem 2rem 0 2rem' }}>{children}</Paper>
      </VFlow>
    </PageContent>
  )
}
