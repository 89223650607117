import { Button, HFlow, Icon, Text, useStyles } from 'bold-ui'
import { Box } from 'components/Box'
import React, { CSSProperties } from 'react'

interface PesquisaSatisfacaoBoxProps {
  onClose(): void
}

export const PesquisaSatisfacaoEnviadaBox = (props: PesquisaSatisfacaoBoxProps) => {
  const { classes } = useStyles(createStyles)

  return (
    <Box style={classes.container}>
      <HFlow alignItems='center' hSpacing={0.5}>
        <Icon icon='checkCircleOutline' size={1} fill='success' />
        <HFlow alignItems='center' justifyContent='flex-start'>
          <Text color='success'>Pesquisa de satisfação enviada.</Text>
          <Button skin='ghost' size='small' onClick={props.onClose}>
            <Icon icon='timesDefault' />
          </Button>
        </HFlow>
      </HFlow>
    </Box>
  )
}

const createStyles = () => ({
  container: {
    width: 295,
    height: 52,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    position: 'fixed',
    left: 109,
    bottom: 20,
    zIndex: 9,
    border: 'solid 1px #d3d4dd',
    boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
  } as CSSProperties,
})
