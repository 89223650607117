import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import React from 'react'

import CboTable from './CboTable'

export function CboListView() {
  return (
    <>
      <Breadcrumb title='CBO' />

      <PageHeaderSection title='CBO' />

      <PageContent>
        <CboTable />
      </PageContent>
    </>
  )
}
