import { startOfMinute } from 'date-fns'

export interface AgendaEventModel extends DateInterval {
  title: string
}

export enum Weekday {
  SUNDAY = 0,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THRUSDAY,
  FRIDAY,
  SATURDAY,
}

export enum SlotStatus {
  AVAILABLE = 1,
  UNAVAILABLE,
  OCCUPIED,
  PARTIALLY_OCCUPIED,
}

export interface TimeInterval {
  start: TimeOfDay
  end: TimeOfDay
}

export interface DateInterval {
  start: Date
  end: Date
}

export interface TimeOfDay {
  hours: number
  minutes: number
}

export interface AgendaMessages {
  unavailableSlot: string
  insufficientTime: string
  tooltips: AgendaTooltips
}

export interface AgendaTooltips {
  disabledPastSlot: string
  unavailableSlot: string
  insufficientTime: string
}

export type WeekTimeIntervals = Partial<Record<Weekday, TimeInterval[]>>

export const convertIntervalToMinutePrecision = (interval: DateInterval) => ({
  ...interval,
  start: startOfMinute(interval.start),
  end: startOfMinute(interval.end),
})
