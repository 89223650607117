/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, Theme, useTheme } from 'bold-ui'
import { TextField } from 'components/form'
import { ParteBucalEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { MetaPath } from 'util/metaPath'

import { getPeriogramaCompletoCondicaoConfig, PeriogramaCompletoCondicao } from '../periograma-completo/model'

export interface PeriogramaCompletoTextFieldProps {
  name: MetaPath<string>
  dente: ParteBucalEnum
  condicao: PeriogramaCompletoCondicao
  onBlur?(): void
}

export default function PeriogramaCompletoTextField(props: PeriogramaCompletoTextFieldProps) {
  const { name, dente, condicao, onBlur } = props

  const { placeholder, maxLength } = useMemo(() => getPeriogramaCompletoCondicaoConfig(condicao, dente), [
    condicao,
    dente,
  ])

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  return (
    <TextField
      name={name}
      placeholder={placeholder}
      maxLength={maxLength}
      style={classes.input}
      error={null}
      clearable={false}
      onBlur={onBlur}
    />
  )
}

const styles = (theme: Theme) => ({
  input: css`
    border: none;
    border-radius: 0;
    text-align: center;

    width: 100%;
    height: 100%;

    & * {
      height: 100%;
    }

    &:not(:disabled):focus,
    &:not(:disabled):active {
      border: 0;
      box-shadow: ${focusBoxShadow(theme, 'primary', 'inset')};
    }
  `,
})
