/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HFlow, Icons, Theme, useTheme } from 'bold-ui'
import { green, orange, red } from 'bold-ui/lib/styles/colors'
import { InfoIcon } from 'components/InfoIcon'
import { Color } from 'csstype'
import { StatusAvaliadoEnum } from 'graphql/types.generated'

import { AvaliacaoDesenvolvimentoModel } from '../model'

interface MarcoStatusSectionAccordionProps {
  marcosDesenvolvimento: AvaliacaoDesenvolvimentoModel[]
}

export function MarcoStatusSectionAccordion(props: MarcoStatusSectionAccordionProps) {
  const { marcosDesenvolvimento } = props
  const theme = useTheme()

  return (
    <HFlow alignItems='center' hSpacing={0.3}>
      {marcosDesenvolvimento.flatMap((row) => {
        const infoIconStatus = statusInfoIcon(theme)[row.statusButton]
        return (
          <InfoIcon
            key={row.id}
            icon={infoIconStatus.icon}
            color={infoIconStatus.color}
            text={row.descricao}
            size={1.5}
          />
        )
      })}
    </HFlow>
  )
}
const statusInfoIcon = (
  theme: Theme
): Record<
  StatusAvaliadoEnum,
  {
    icon: Icons
    color: Color
  }
> => ({
  PRESENTE: {
    icon: 'checkCircleFilled',
    color: green.c60,
  },
  PRESENTE_COM_ATRASO: {
    icon: 'exclamationTriangleFilled',
    color: orange.c60,
  },
  AUSENTE: {
    icon: 'timesFilled',
    color: red.c60,
  },
  NAO_AVALIADO: {
    icon: 'minusCircleFilled',
    color: theme.pallete.gray.c60,
  },
})
