import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { EquipeModel } from 'view/profissional/detail/model'

import { EQUIPES_VINCULADAS_MODAL_URL, EquipesVinculadasModal } from './EquipesVinculadasModal'

interface EquipesVinculadasRootViewProps {
  equipePrincipal: EquipeModel
}

export default function EquipesVinculadasRootView(props: EquipesVinculadasRootViewProps) {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${url}${EQUIPES_VINCULADAS_MODAL_URL}`}>
        <EquipesVinculadasModal {...props} />
      </Route>
    </Switch>
  )
}
