import { StatusAvaliadoEnum } from 'graphql/types.generated'

import { AvaliacaoDesenvolvimentoModel } from '../model'

export const descricaoAvaliacoesPresente = (avaliacoes: AvaliacaoDesenvolvimentoModel[]) =>
  avaliacoes &&
  avaliacoes
    .filter((row: AvaliacaoDesenvolvimentoModel) => row.statusButton === StatusAvaliadoEnum.PRESENTE)
    .sort((a, b) => Number(a.id) - Number(b.id))
    .map((row) => row.descricao)
    .join('; ')
