import { RadioGroupField, RadioGroupFieldProps, RadioOption } from 'components/form'
import { OpcaoTipoPerguntaEnum } from 'graphql/types.generated'
import React from 'react'

interface QuantidadeRefeicoesRadioGroupFieldProps
  extends Omit<RadioGroupFieldProps<OpcaoTipoPerguntaEnum>, 'options'> {}

export function QuantidadeRefeicoesRadioGroupField(props: QuantidadeRefeicoesRadioGroupFieldProps) {
  return <RadioGroupField options={options} {...props} />
}

const options: RadioOption<OpcaoTipoPerguntaEnum>[] = [
  { value: OpcaoTipoPerguntaEnum.UMA_VEZ, label: '1 vez' },
  { value: OpcaoTipoPerguntaEnum.DUAS_VEZES, label: '2 vezes' },
  { value: OpcaoTipoPerguntaEnum.TRES_VEZES_OU_MAIS, label: '3 vezes ou mais' },
  { value: OpcaoTipoPerguntaEnum.NAO_SABE, label: 'Não sabe' },
]
