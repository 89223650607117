import {
  CiapCidModel,
  CiapCidSelectModel,
  isCiap,
} from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField'
import { convertDateRangeToInstantRange, convertInstantRangeToDateRange } from 'util/date/dateRange'

import { RelatorioGerencialFiltrosStorageModel } from '../../common/model-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from './model-relatorioGerencialFiltrosForm'

export const convertRelatorioGerencialStorageModelToFormModel = (
  storageModel: RelatorioGerencialFiltrosStorageModel
): RelatorioGerencialFiltrosFormModel => {
  if (storageModel) {
    const { periodo, ...rest } = storageModel
    return {
      ...rest,
      periodo: periodo ? convertInstantRangeToDateRange(periodo) : null,
    }
  }

  return null
}

export const convertRelatorioGerencialFormModelToStorageModel = (
  formModel: RelatorioGerencialFiltrosFormModel
): RelatorioGerencialFiltrosStorageModel => {
  const { periodo, ...rest } = formModel
  return {
    ...rest,
    periodo: periodo ? convertDateRangeToInstantRange(periodo) : null,
  }
}

export const convertCiapCidFilter = (
  ciapsCids: CiapCidSelectModel[],
  gruposCondicoesPrioritarios: GrupoCondicaoSelectModel[]
): string[] => {
  const ciapsCidsGrupoCondicoes = convertGrupoCondicoesPrioritariosToCiapsCids(gruposCondicoesPrioritarios ?? [])

  return [...convertCiapCidToRelatorioGerencialCiapCidFilter((ciapsCids ?? []).concat(ciapsCidsGrupoCondicoes))]
}

export const convertCiapCidToRelatorioGerencialCiapCidFilter = (ciapsCids: CiapCidSelectModel[]): Set<string> => {
  return new Set<string>(
    ciapsCids.map((ciapOuCid) =>
      isCiap(ciapOuCid)
        ? convertCiapToRelatorioGerencialCiapFilter(ciapOuCid)
        : convertCidToRelatorioGerencialCidFilter(ciapOuCid)
    )
  )
}

export const convertGrupoCondicoesPrioritariosToCiapsCids = (
  grupos: GrupoCondicaoSelectModel[]
): CiapCidSelectModel[] => {
  const ciapsCids: CiapCidSelectModel[] = []
  const ciaps = grupos.flatMap((grupo) => grupo.ciaps)
  const cids = grupos.flatMap((grupo) => grupo.cids)
  return ciapsCids.concat(ciaps, cids)
}

const convertCiapToRelatorioGerencialCiapFilter = (ciap: CiapCidModel): string => `Ciap-${ciap.codigo}`
const convertCidToRelatorioGerencialCidFilter = (cid: CiapCidModel): string => `Cid10-${cid.codigo}`
