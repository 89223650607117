import { Alert, Cell, Grid, Table, TableBody, TableHead, TableHeader, TableRow, Text, VFlow } from 'bold-ui'
import { TableBox } from 'components/table/TableBox'
import { useUnificacoesBaseQuery } from 'graphql/hooks.generated'
import { UnificacaoBaseOrigemBroadcastEnum } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'

import { AvaliarSolicitacao, ModalAvaliarSolicitacao } from './ModalAvaliarSolicitacao'
import { UnificacaoBaseTopic } from './model'
import { RecebimentoDadosRow } from './RecebimentoDadosRow'

export interface RecebimentoDadosViewProps {
  agendaOnlineAtiva: boolean
  topicMessage: UnificacaoBaseTopic
  setTopicMessage: (topic: UnificacaoBaseTopic) => void
}

export const RecebimentoDadosView = (props: RecebimentoDadosViewProps) => {
  const { agendaOnlineAtiva, topicMessage, setTopicMessage } = props
  const { data, refetch } = useUnificacoesBaseQuery()
  const [unificacaoBaseId, setUnificacaoBaseId] = useState<string>()
  const [avaliarSolicitacao, setAvaliarSolicitacao] = useState<AvaliarSolicitacao>()
  const [isModalAvaliarOpen, setIsModalAvaliarOpen] = useState<boolean>()

  const handleMostrarModalNegar = (id: string) => {
    setIsModalAvaliarOpen(true)
    setUnificacaoBaseId(id)
    setAvaliarSolicitacao('negar')
  }
  const handleMostrarModalAceitar = (id: string) => {
    setIsModalAvaliarOpen(true)
    setUnificacaoBaseId(id)
    setAvaliarSolicitacao('aceitar')
  }

  useEffect(() => {
    if (UnificacaoBaseOrigemBroadcastEnum.RECEBIMENTO === topicMessage?.origem) {
      refetch()
      setTopicMessage({ origem: undefined })
    }
  }, [refetch, setTopicMessage, topicMessage])

  return (
    <VFlow vSpacing={1}>
      <Grid gap={2}>
        <Cell size={12}>
          <Alert type='info' inline={true}>
            Para realizar a unificação de base, é necessário aceitar a solicitação. Ao iniciar o processo de unificação,
            a instalação de envio será desativada.
          </Alert>
        </Cell>
        {data.unificacoesBase?.length === 0 ? (
          <Cell size={12}>
            <Text>Nenhuma solicitação de unificação realizada.</Text>
          </Cell>
        ) : (
          <>
            <Cell size={12}>
              <TableBox>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader>Instalação</TableHeader>
                      <TableHeader>Responsável</TableHeader>
                      <TableHeader>Data de solicitação</TableHeader>
                      <TableHeader>Status</TableHeader>
                      <TableHeader />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.unificacoesBase?.map((item) => {
                      return (
                        <RecebimentoDadosRow
                          key={item.id}
                          unificacaoId={item.id}
                          nomeInstalacao={item.nomeInstalacao}
                          status={item.status}
                          dataSolicitacao={item.dataSolicitacao}
                          dataFimUnificacao={item.dataFimUnificacao}
                          agendaOnlineAtiva={agendaOnlineAtiva}
                          handleMostrarModalAceitar={handleMostrarModalAceitar}
                          handleMostrarModalNegar={handleMostrarModalNegar}
                        />
                      )
                    })}
                  </TableBody>
                </Table>
              </TableBox>

              <ModalAvaliarSolicitacao
                isModalOpen={isModalAvaliarOpen}
                setIsModalOpen={setIsModalAvaliarOpen}
                onAvaliarSolicitacao={refetch}
                avaliarSolicitacao={avaliarSolicitacao}
                unificacaoBaseId={unificacaoBaseId}
              />
            </Cell>
          </>
        )}
      </Grid>
    </VFlow>
  )
}
