import { Icon, Tooltip } from 'bold-ui'
import { ButtonLink } from 'components/route'
import React, { SyntheticEvent } from 'react'
import { useRouteMatch } from 'react-router'

import { VinculacoesEstabelecimentosItem } from './VinculacoesEstabelecimentosTable'

interface GrupoExameEditActionProps {
  vinculosEstabelecimento: VinculacoesEstabelecimentosItem
}

export function VinculacoesEstabelecimentosEditAction(props: GrupoExameEditActionProps) {
  const { vinculosEstabelecimento } = props
  const match = useRouteMatch()
  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation()
  }

  return (
    <Tooltip text='Editar'>
      <ButtonLink
        onClick={onClick}
        to={`${match.url}/editar/${vinculosEstabelecimento.referencia.id}`}
        size='small'
        skin='ghost'
      >
        <Icon icon='penOutline' />
      </ButtonLink>
    </Tooltip>
  )
}
