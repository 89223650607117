/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, HFlow, VFlow } from 'bold-ui'
import { ColorScale } from 'bold-ui/lib/styles/colors'
import { ErrorField } from 'components/form'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import theme from 'config/theme'
import { MetaPath } from 'util/metaPath'

export interface CardRadioGroupFieldOption<T> {
  value: T
  label: string
  colorScale: ColorScale
}

interface CardRadioGroupFieldProps<T> {
  name: MetaPath<T>
  label: string
  options: CardRadioGroupFieldOption<T>[]
  required?: boolean
}

export function CardRadioGroupField<T>(props: CardRadioGroupFieldProps<T>) {
  const { name, label, options, required } = props

  return (
    <FormControl label={label} required={required}>
      <VFlow vSpacing={0.5}>
        <HFlow style={styles.hFlow}>
          {options.map((option) => (
            <RadioFieldContainer
              name={name}
              key={`${option.value}`}
              value={option.value}
              label={option.label}
              style={styles.card(option.colorScale)}
            />
          ))}
        </HFlow>
        <ErrorField name={name} />
      </VFlow>
    </FormControl>
  )
}

const styles = {
  hFlow: css`
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fill, 9.5rem);

    ${theme.breakpoints.down('lg')} {
      grid-template-columns: repeat(auto-fill, 8.9rem);
    }
  `,
  card: (colorScale: ColorScale) => css`
    display: flex;
    align-itens: center;
    height: 4rem;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.04), 0px 1px 5px rgba(0, 0, 0, 0.12);
    font-weight: bold;
    border-radius: 2px;
    border: 1px solid ${colorScale.c60};
    background-color: ${colorScale.c90};
    color: ${colorScale.c40};

    :hover {
      background-color: ${colorScale.c80};
    }
  `,
}
