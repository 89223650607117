import { Grid, Text } from 'bold-ui'
import { FilterItem } from 'components/filteritem'
import { isSameDay } from 'date-fns'
import { useServerTime } from 'hooks/useServerTime'
import { isEmpty } from 'lodash'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsDdMmYyyy } from 'util/date/formatDate'
import { HOJE } from 'util/date/humanize-date'

import { GarantiaAcessoFilterModel } from '../model-garantiaAcesso'

interface GarantiaAcessoTableFilterTagsProps {
  filter: GarantiaAcessoFilterModel
}

export const GarantiaAcessoTableFilterTags = (props: GarantiaAcessoTableFilterTagsProps) => {
  const { filter } = props
  const { equipes, nomeCpfCns, origem, periodo, lotacoes, tiposAtendimento } = filter
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()

  const isCidadaoFiltered = !isEmpty(nomeCpfCns.trim())
  const isLotacoesFiltered = lotacoes.isNotEmpty()
  const isEquipesFiltered = equipes.isNotEmpty()
  const isTiposAtendimentoFiltered = tiposAtendimento?.isNotEmpty()
  const isOrigemFiltered = origem.isNotEmpty()
  const isPeriodoFiltered = !isUndefinedOrNull(periodo?.startDate) && !isUndefinedOrNull(periodo?.endDate)
  const hasFilters =
    isCidadaoFiltered ||
    isLotacoesFiltered ||
    isEquipesFiltered ||
    isTiposAtendimentoFiltered ||
    isOrigemFiltered ||
    isPeriodoFiltered

  const isPeriodoStartAndEndDateSameDayAndAreToday =
    isPeriodoFiltered && isSameDay(periodo.startDate, periodo.endDate) && isSameDay(periodo.startDate, now)

  const lotacoesText = isLotacoesFiltered && lotacoes.map((item) => item.profissional.nome).join(', ')
  const equipesText = isEquipesFiltered && equipes.map((item) => item.nome).join(', ')
  const tiposAtendimentoText = isTiposAtendimentoFiltered && tiposAtendimento.map((item) => item.nome).join(', ')
  const startDateText = isSameDay(periodo?.startDate, now) ? HOJE : dateAsDdMmYyyy(periodo?.startDate)
  const endDateText = isSameDay(periodo?.endDate, now) ? HOJE : dateAsDdMmYyyy(periodo?.endDate)
  const periodoText = isPeriodoStartAndEndDateSameDayAndAreToday
    ? HOJE
    : `${startDateText} até ${endDateText}`.capitalize()

  return hasFilters ? (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {isLotacoesFiltered && <FilterItem label='Profissional:' value={lotacoesText} />}
      {isEquipesFiltered && <FilterItem label='Equipe:' value={equipesText} />}
      {isTiposAtendimentoFiltered && <FilterItem label='Tipo Atendimento:' value={tiposAtendimentoText} />}
      {isPeriodoFiltered && <FilterItem label='Período do registro:' value={periodoText} />}
    </Grid>
  ) : (
    <Text>Sem filtros ativos</Text>
  )
}
