import api from 'api'
import { ImpressaoRegistrosHistoricoInput } from 'api/ImpressaoRegistrosHistorico'
import { format } from 'date-fns'
import { downloadFile } from 'util/downloadFile'

export const formatDate = (value: Date): string => format(value, 'yyyyMMdd_HHmm')

export const downloadRegistrosHistorico = (input: ImpressaoRegistrosHistoricoInput) => {
  return api.registrosHistorico
    .imprimirRegistrosHistorico(input)
    .then((response) => {
      downloadFile(new Blob([response.data]), `HistoricoClinico_${formatDate(new Date())}.pdf`)
      return response
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', (e) => {
          reject((e.srcElement as any).result)
        })
        reader.readAsText(error.response.data)
      })
    })
}
