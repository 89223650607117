import { Text, VFlow } from 'bold-ui'
import { RichTextField } from 'components/form/final-form/RichTextField/RichTextField'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import {
  CondutaGroupEnum,
  CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER,
  DiscussaoCasoSolicitanteFieldsModel,
  PerguntaCuidadoCompartilhadoFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CondutaGroupCuidadoCompartilhadoRadioFieldContainer } from './CondutaGroupCuidadoCompartilhadoRadioFieldContainer'

interface PerguntaCuidadoCompartilhadoFieldProps {
  path: MetaPath<DiscussaoCasoSolicitanteFieldsModel>
}

export const PerguntaCuidadoCompartilhadoField = (props: PerguntaCuidadoCompartilhadoFieldProps) => {
  const { path } = props
  const name = path.pergunta

  return (
    <CondutaGroupCuidadoCompartilhadoRadioFieldContainer<PerguntaCuidadoCompartilhadoFieldsModel>
      name={name}
      pathCondutaGroup={path.condutaGroup}
      value={CondutaGroupEnum.NOVA_PERGUNTA}
    >
      <VFlow>
        <Text>
          Insira uma observação caso ainda existam dúvidas ou questionamentos sobre a discussão de caso do cidadão.
        </Text>
        <RichTextField
          name={name.pergunta}
          label='Observações'
          placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
          maxLength={1000}
          required
        />
      </VFlow>
    </CondutaGroupCuidadoCompartilhadoRadioFieldContainer>
  )
}
