import { CategoriaSubstanciaAlergiaSelectModel } from 'components/form'
import { AlergiaSelectFieldModel } from 'components/form/field/select/AlergiaSelectField/AlergiaSelectField'
import createDecorator from 'final-form-calculate'
import { CategoriaSubstanciaAlergia } from 'graphql/types.generated'
import { toString } from 'lodash'
import { metaPath } from 'util/metaPath'

import { convertAlergiaReacao } from './converter'
import { AlergiaReacaoModel } from './model'

const meta = metaPath<{ [x: string]: AlergiaReacaoModel }>()

const findAlergia = (
  alergiasCidadao: ReadonlyArray<AlergiaSelectFieldModel>,
  substanciaId: ID,
  categoria: CategoriaSubstanciaAlergia
) =>
  alergiasCidadao.find(
    (alergia) =>
      alergia.substanciaEspecifica &&
      toString(alergia.substanciaEspecifica.coSubstanciaEspecifica) === substanciaId &&
      alergia.substanciaEspecifica.categoria === categoria
  )

const getCategoriaByFieldName = (fieldName: string) => {
  if (fieldName.endsWith('substanciaImunobiologico')) return CategoriaSubstanciaAlergia.BIOLOGICO
  if (fieldName.endsWith('substanciaMedicamento')) return CategoriaSubstanciaAlergia.MEDICAMENTO
  return undefined
}

export const createAlergiaReacaoCalculator = (
  dataNascimentoCidadao: LocalDate,
  alergiasCidadao: ReadonlyArray<AlergiaSelectFieldModel>,
  prefix: string
) => {
  return createDecorator(
    {
      field: [
        meta[prefix].substanciaCbara.absolutePath(),
        meta[prefix].substanciaImunobiologico.absolutePath(),
        meta[prefix].substanciaMedicamento.absolutePath(),
      ],
      updates: (
        value: { id: ID; categoria: CategoriaSubstanciaAlergia },
        fieldName: string,
        allValues: { [x: string]: AlergiaReacaoModel },
        prevValues: { [x: string]: AlergiaReacaoModel }
      ) => {
        if (allValues[prefix]?.alergiaReacaoEvoluir || JSON.stringify(allValues) === JSON.stringify(prevValues))
          return {}

        const alergia =
          value && findAlergia(alergiasCidadao, value.id, value.categoria ?? getCategoriaByFieldName(fieldName))
        return !!alergia ? { [prefix]: convertAlergiaReacao(alergia, dataNascimentoCidadao) } : {}
      },
    },
    {
      field: [meta[prefix].alergiaReacaoEvoluir.absolutePath()],
      updates: (value: AlergiaSelectFieldModel) =>
        value
          ? {
              [prefix]: {
                ...convertAlergiaReacao(value, dataNascimentoCidadao),
                alergiaReacaoEvoluir: value,
              },
            }
          : {},
    },
    {
      field: [meta[prefix].categoriaSubstancia.absolutePath()],
      updates: (
        value: CategoriaSubstanciaAlergiaSelectModel,
        field: string,
        allValues: { [x: string]: AlergiaReacaoModel }
      ) =>
        !allValues[prefix]?.alergiaReacaoEvoluir
          ? {
              [prefix]: {
                ...allValues[prefix],
                substanciaCbara: undefined,
                substanciaImunobiologico: undefined,
                substanciaMedicamento: undefined,
              },
            }
          : {},
    }
  )
}
