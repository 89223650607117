/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Theme, useTheme } from 'bold-ui'
import { Box } from 'components/Box'
import { formatWeekdayDayMonthYear } from 'util/date/formatDate'

import { agendamentoPropsRecord, SugestaoProximaConsultaModel } from './model'

interface SugestaoProximaConsultaCardProps {
  sugestao: SugestaoProximaConsultaModel
}

export function SugestaoProximaConsultaCard(props: SugestaoProximaConsultaCardProps) {
  const {
    sugestao: { date, type },
  } = props

  const theme = useTheme()
  const classes = createStyles(theme)

  const dateFormatted = formatWeekdayDayMonthYear(date).capitalize()
  const iconName = agendamentoPropsRecord[type].icon

  return (
    <Box style={[classes.box]}>
      <HFlow alignItems='center' hSpacing={0.5}>
        <Icon icon={iconName} size={1} />
        <Heading level={4}>
          {agendamentoPropsRecord[type].description} | {dateFormatted}
        </Heading>
      </HFlow>
    </Box>
  )
}

const createStyles = (theme: Theme) => ({
  box: css`
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: ${theme.radius.modal}px;
    box-sizing: border-box;
    box-shadow: 'flat';
    padding: 0.75rem;
    margin-bottom: 0.375rem;
  `,
})
