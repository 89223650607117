import { Icon } from 'bold-ui'
import { blue, gray, green, red } from 'bold-ui/lib/styles/colors'
import { CategoriaSubstanciaAlergiaSelectModel } from 'components/form'
import {
  AlergiasModalQuery,
  CategoriaSubstanciaAlergia,
  CategoriaSubstanciaAlergiaLegado,
  CriticidadeAlergia,
  GrauCertezaAlergia,
  ManifestacaoAlergia,
  TipoReacaoAlergia,
} from 'graphql/types.generated'
import { ReactComponent as Dish } from 'images/atendimento/aside/dish-orange-filled.svg'
import { ReactComponent as ReacoesAdversas } from 'images/atendimento/aside/reacoesadversas-pink-filled.svg'
import { ReactComponent as Leaf } from 'images/nature/leaf-green-filled.svg'
import React from 'react'

type AtendimentoProfissionalModel = AlergiasModalQuery['alergias']['content'][0]['evolucoes'][0]['atendimentoProfissional']

export interface Alergia {
  id: ID
  categoriaSubstanciaEspecificaLegado?: CategoriaSubstanciaAlergiaLegado
  substanciaEspecificaLegado?: string
  criticidade?: CriticidadeAlergia
  grauCerteza?: GrauCertezaAlergia
  substanciaEspecifica?: SubstanciaAlergia
  tipoReacao?: TipoReacaoAlergia
  ultimaEvolucao?: EvolucaoAlergia
  evolucoes?: EvolucaoAlergia[]
  dataInicio?: LocalDate
  isCriadoAtendimentoAtual?: boolean
  isAvaliacao?: boolean
}

export interface SubstanciaAlergia {
  id: ID
  categoria: CategoriaSubstanciaAlergiaSelectModel
  nome: string
}

export interface EvolucaoAlergia {
  id: ID
  tipoReacao?: TipoReacaoAlergia
  criticidade?: CriticidadeAlergia
  grauCerteza?: GrauCertezaAlergia
  dataInicio?: LocalDate
  manifestacoes?: ManifestacaoAlergia[]
  manifestacoesLegado?: string
  observacao?: string
  possuiSubstanciaEspecificaCbara?: boolean
  atendimentoProfissional?: AtendimentoProfissionalModel
  atualizadoEm?: Instant
  alergia?: Alergia
}

export const tipoReacaoAlergiaRecord: Record<TipoReacaoAlergia, { descricao: string }> = {
  [TipoReacaoAlergia.ALERGIA]: {
    descricao: 'Alergia',
  },
  [TipoReacaoAlergia.INTOLERANCIA]: {
    descricao: 'Intolerância',
  },
}

export const criticidadeAlergiaRecord: Record<
  CriticidadeAlergia,
  { descricao: string; color: string; background: string; importancia: number }
> = {
  [CriticidadeAlergia.ALTA]: {
    descricao: 'Crit. alta',
    color: gray.c100,
    background: red.c40,
    importancia: 3,
  },
  [CriticidadeAlergia.BAIXA]: {
    descricao: 'Crit. baixa',
    color: gray.c100,
    background: green.c40,
    importancia: 2,
  },
  [CriticidadeAlergia.NAO_INFORMADA]: {
    descricao: 'Crit. indeterminada',
    color: gray.c100,
    background: gray.c60,
    importancia: 1,
  },
}

export const grauCertezaAlergiaRecord: Record<
  GrauCertezaAlergia,
  { descricao: string; importancia: number; color: string; background: string }
> = {
  [GrauCertezaAlergia.CONFIRMADO]: {
    descricao: 'Confirmado',
    color: gray.c20,
    background: blue.c80,
    importancia: 13,
  },
  [GrauCertezaAlergia.SUSPEITO]: {
    descricao: 'Suspeito',
    color: gray.c20,
    background: blue.c80,
    importancia: 8,
  },
  [GrauCertezaAlergia.NAO_INFORMADO]: {
    descricao: 'Grau de certeza não informado',
    color: gray.c100,
    background: gray.c60,
    importancia: 5,
  },
  [GrauCertezaAlergia.RESOLVIDO]: {
    descricao: 'Resolvido',
    color: gray.c20,
    background: blue.c80,
    importancia: 1,
  },
  [GrauCertezaAlergia.REFUTADO]: {
    descricao: 'Refutado',
    color: gray.c20,
    background: blue.c80,
    importancia: -1,
  },
}

export const categoriaAlergiaRecord: Record<
  CategoriaSubstanciaAlergia | CategoriaSubstanciaAlergiaLegado,
  { descricao: string; color?: string; icon: React.ReactElement }
> = {
  [CategoriaSubstanciaAlergia.ALIMENTO]: {
    descricao: 'Alimento',
    icon: <Dish />,
  },
  [CategoriaSubstanciaAlergia.MEDICAMENTO]: {
    descricao: 'Medicamento',
    icon: <Icon icon='medicineBottleFilled' />,
  },
  [CategoriaSubstanciaAlergia.AMBIENTE]: {
    descricao: 'Ambiente',
    icon: <Leaf />,
  },
  [CategoriaSubstanciaAlergia.BIOLOGICO]: {
    descricao: 'Biológico',
    icon: <Icon icon='needleFilled' />,
  },
  [CategoriaSubstanciaAlergiaLegado.ALIMENTO]: {
    descricao: 'Alimento',
    icon: <Dish />,
  },
  [CategoriaSubstanciaAlergiaLegado.ANIMAL]: {
    descricao: 'Animal',
    icon: <Leaf />,
  },
  [CategoriaSubstanciaAlergiaLegado.INGREDIENTE_NAO_ATIVO]: {
    descricao: 'Ingrediente não ativo do Medicamento',
    icon: <Icon icon='medicineBottleFilled' />,
  },
  [CategoriaSubstanciaAlergiaLegado.FARMACO_PRESENTE]: {
    descricao: 'Fármaco(s) presente(s) no medicamento ou contraste radiológico',
    icon: <Icon icon='medicineBottleFilled' />,
  },
  [CategoriaSubstanciaAlergiaLegado.OUTRAS_SUBSTANCIAS]: {
    descricao: 'Outras substâncias ou produtos químicos',
    icon: <Leaf />,
  },
  [CategoriaSubstanciaAlergiaLegado.OUTROS]: {
    descricao: 'Outros',
    icon: <ReacoesAdversas />,
  },
  [CategoriaSubstanciaAlergiaLegado.PRODUTO_AMBIENTAL]: {
    descricao: 'Produto ambiental',
    icon: <Leaf />,
  },
}
