import { Observable } from 'bold-ui'

interface RecursoConfig {
  uri: string
  label: string
  parentUri: string
}

export const createTree = (uris: string[]) => {
  const recursos: RecursoConfig[] = uris.map(uri => {
    const parts = uri.split('/')

    return {
      uri,
      label: parts[parts.length - 1],
      parentUri: parts.length > 1 ? parts.slice(0, -1).join('/') : null,
    }
  })

  const root = new RecursoNode('/', null, null)
  root.children = extractChildren(recursos, null, null)
  root.children.forEach(child => (child.parent = root))
  return root
}

const extractChildren = (
  recursos: RecursoConfig[],
  parentUri: string | null,
  parentNode: RecursoNode
): RecursoNode[] => {
  return recursos
    .filter(rec => rec.parentUri === parentUri)
    .map(rec => {
      const node = new RecursoNode(rec.uri, rec.label, parentNode)
      node.children = extractChildren(recursos, rec.uri, node)
      return node
    })
}

export type NodeCallback = (node: RecursoNode) => void

export interface RecursoNodeState {
  /**
   * URI de identificação única do nó.
   */
  uri: string

  /**
   * Nome do nó.
   */
  label: string

  /**
   * Flag que indica se o nó está marcado.
   */
  checked: boolean

  /**
   * Flag que indica se o nó está visível ou não
   * O nó pode estar invisível por decorrência de uma filtragem por label, por exemplo
   */
  visible: boolean

  /**
   * Número total de nós desta (sub)árvore
   */
  totalCount: number

  /**
   * Número de nós "checked" desta (sub)árvore
   */
  checkCount: number
}

export type RecursoNodeListener = (state: RecursoNodeState) => void

export class RecursoNode extends Observable<RecursoNodeState> {
  parent: RecursoNode | null
  children: RecursoNode[] = []

  public state: RecursoNodeState = {
    uri: null,
    label: null,
    checked: false,
    visible: true,
    checkCount: 0,
    totalCount: 0,
  }

  constructor(uri: string, label: string, parentNode: RecursoNode) {
    super()
    this.state.uri = uri
    this.state.label = label
    this.parent = parentNode
  }

  /**
   * Recursivele
   */
  public updateTree() {
    this.traverseParents(parent => parent.update())
    this.traverseChildren(node => node.update())
  }

  /**
   * Update the node's calculated state attributes and notifies the listeners.
   */
  public update() {
    this.setState({
      totalCount: this.totalCount(),
      checkCount: this.checkCount(),
    })
    this.notify(this.state)
  }

  /**
   * Changes the current node state and notifies all listeners.
   *
   * @param state The new values of the state (can be a partial state - it will be merged with current one).
   */
  public setState(state: Partial<RecursoNodeState>) {
    this.state = { ...this.state, ...state }
  }

  /**
   * @return Whether the node has children or not.
   */
  public hasChildren() {
    return this.children.length > 0
  }

  /**
   * @return The number of nodes of this (sub)tree.
   */
  public totalCount() {
    if (!this.hasChildren()) {
      return 1
    }

    return this.children.reduce((sum, node) => sum + node.totalCount(), 1)
  }

  /**
   * @return The number of checked nodes of this (sub)tree.
   */
  public checkCount() {
    if (!this.hasChildren()) {
      return this.state.checked ? 1 : 0
    }

    return this.children.reduce((sum, node) => sum + node.checkCount(), 0) + (this.state.checked ? 1 : 0)
  }

  /**
   * Get the current tree value.
   * @return An array containing the URIs of all checked nodes.
   */
  public getTreeValue() {
    const root = this.getRoot()
    return root.getCheckedUris()
  }

  public setTreeValue(values: string[] = []) {
    this.traverseChildren(node => {
      node.setState({
        checked: values.indexOf(node.state.uri) >= 0,
      })
    })
    this.updateTree()
  }

  /**
   * Walk the tree starting on current node by using depth-first-search.
   */
  public traverseChildren(callback: NodeCallback) {
    callback(this)
    this.children.forEach(node => node.traverseChildren(callback))
  }

  /**
   * Walk the tree upwards starting on current node until root.
   */
  public traverseParents(callback: NodeCallback) {
    callback(this)
    if (this.parent) {
      this.parent.traverseParents(callback)
    }
  }

  /**
   * Change a node value, preserving the consistency of the tree.
   * A ResourceTree is consistent if all ancestors of a checked node are checked.
   */
  public changeValue(checked: boolean) {
    if (checked) {
      // if checked, mark itself and all parents
      this.traverseParents(node => node.setState({ checked: true }))
    } else {
      // if unchecked, dismark itself and all children
      this.traverseChildren(node => node.setState({ checked: false }))
    }

    this.updateTree()
  }

  /**
   * Check the current node and all its children.
   */
  public checkChildren() {
    this.traverseChildren(node => {
      if (node.state.visible) {
        node.changeValue(true)
      }
    })
  }

  /**
   * Uncheck the current node and all its children.
   */
  public uncheckChildren() {
    this.traverseChildren(node => node.changeValue(false))
  }

  /**
   * Returns the tree root.
   */
  public getRoot(): RecursoNode {
    let root: RecursoNode = this
    while (root.parent) {
      root = root.parent
    }
    return root
  }

  /**
   * Filter the tree nodes, marking as visible = false the ones that do not match the search string.
   * @param search Search text to look for.
   */
  public filter(search: string) {
    this.traverseChildren(node => node.setState({ visible: false }))

    this.traverseChildren(node => {
      if (node.state.label && node.state.label.match(new RegExp(search, 'i'))) {
        node.setState({ visible: true })
        node.traverseParents(ancestor => ancestor.setState({ visible: true }))
        node.traverseChildren(child => child.setState({ visible: true }))
      }
    })

    this.updateTree()
  }

  /**
   * Returns an array containing the URIs of all current node's children (and itself).
   */
  private getCheckedUris() {
    if (!this.hasChildren()) {
      return this.state.checked ? [this.state.uri] : []
    }

    return this.children.reduce(
      (arr, node) => [...arr, ...node.getCheckedUris()],
      this.state.checked && this.state.uri !== '/' ? [this.state.uri] : []
    )
  }
}
