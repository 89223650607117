import { Cell, Grid, Tooltip, VFlow } from 'bold-ui'
import {
  CboSelectField,
  Form,
  FormPrompt,
  FormProps,
  FormRenderProps,
  PerfilSelectField,
  RadioGroupField,
  TextField,
  UnidadeSaudeSelectField,
} from 'components/form'
import { selectFieldStatus } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { DefaultFormFooter } from 'components/form/FormFooter'
import { TipoAcesso } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { useMemo } from 'react'
import { estagioFormValidator } from 'view/estagiario/validator'

import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
} from '../../../components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { estagioFormCalculator as calculator } from '../calculator'
import { EstagioFormModel, NivelEstagioEnum, nivelEstagioOptions, path } from '../model'
import { PermissoesEstagiario } from './PermissoesEstagiario'
export interface EstagioCreateFormProps extends FormProps<EstagioFormModel> {
  edit?: boolean
  profissionalId: ID
}

const isAD = (item: LotacaoAndEstagioSelectFieldModel) => !!item.equipe?.isAD

const equipeADAlertText = () => 'Equipe AD'

const equipeADTooltip = () => 'Não é possível adicionar um estagiário a uma equipe AD'

export function EstagioForm(props: EstagioCreateFormProps) {
  const { getServerTimeNow } = useServerTime()

  const validators = useMemo(() => estagioFormValidator(getServerTimeNow), [getServerTimeNow])

  const { onSubmit, initialValues, edit = false, profissionalId } = props

  const idProprioProfissional = useMemo(() => [profissionalId], [profissionalId])

  const renderForm = ({ values, handleSubmit }: FormRenderProps<EstagioFormModel>) => {
    return (
      <form noValidate>
        <FormPrompt />
        <Grid wrap>
          <Cell xs={6}>
            <VFlow>
              <UnidadeSaudeSelectField label='Unidade de saúde' required name={path.unidadeSaude} disabled={edit} />
              <Tooltip text={!values?.unidadeSaude && 'Informe a unidade de saúde'} placement='auto'>
                <LotacaoAndEstagioSelectField
                  label='Supervisor'
                  required
                  nivelSuperior
                  name={path.lotacaoSupervisora.absolutePath()}
                  unidadeSaudeId={values?.unidadeSaude?.id}
                  excludeProfissionaisIds={idProprioProfissional}
                  disabled={!values?.unidadeSaude || edit}
                  customText={equipeADAlertText}
                  selectedItemTooltip={equipeADTooltip}
                  selectedItemStyle={selectFieldStatus.INFO}
                  itemIsEqual={isAD}
                />
              </Tooltip>
              <Grid>
                <Cell>
                  <RadioGroupField
                    required
                    label='Estágio em curso de nível'
                    name={path.nivelEstagio.absolutePath()}
                    options={nivelEstagioOptions}
                    disabled={edit}
                  />
                </Cell>
                <Cell flexGrow={1}>
                  {values?.nivelEstagio === NivelEstagioEnum.TECNICO && (
                    <Tooltip placement='auto' text={!values?.lotacaoSupervisora && 'Informe o supervisor'}>
                      <CboSelectField
                        label='Formação técnica'
                        codigoCboSupervisor={values.lotacaoSupervisora?.cbo.cbo2002}
                        required
                        name={path.cboFormacao.absolutePath()}
                        disabled={!values?.lotacaoSupervisora || edit}
                      />
                    </Tooltip>
                  )}
                </Cell>
              </Grid>
              <PermissoesEstagiario
                cbo={
                  values?.nivelEstagio === NivelEstagioEnum.SUPERIOR || !!values?.cboFormacao
                    ? values?.cboFormacao || values?.lotacaoSupervisora?.cbo
                    : null
                }
                equipe={values?.lotacaoSupervisora?.equipe}
                exibirBorda
                quebraDeLinha
              />
            </VFlow>
          </Cell>

          <Cell xs={12}>
            <Grid>
              <Cell size={6}>
                <DateRangeField name={path.periodoEstagio} label='Período de estágio' required />
              </Cell>
              <Cell size={6}>
                <PerfilSelectField
                  tipoAcesso={TipoAcesso.LOTACAO}
                  label='Perfis'
                  name={path.perfis}
                  multiple
                  required
                />
              </Cell>
            </Grid>
          </Cell>

          <Cell xs={12} sm={6}>
            <TextField label='Instituição de ensino' name={path.instituicaoEnsino} maxLength={255} />
          </Cell>
        </Grid>
        <DefaultFormFooter handleSubmit={handleSubmit} />
      </form>
    )
  }

  return (
    <Form<EstagioFormModel>
      render={renderForm}
      decorators={edit ? null : decorators}
      validateOnBlur={false}
      validate={validators}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  )
}

const decorators = [calculator]
