/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HeadingProps, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { HLabel } from 'components/HLabel'
import { EvolucaoSubjetivoCiap } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { Fragment } from 'react'
import { HistoricoSectionStyles } from 'view/atendimentos/detail/historico/model/model-historico'

export interface MotivoConsultaBoxProps {
  subjetivoCiaps: EvolucaoSubjetivoCiap[]
  level?: HeadingProps['level']
}

export default function MotivoConsultaBox({ subjetivoCiaps, level = 5 }: MotivoConsultaBoxProps) {
  const styles = createStyles()

  return (
    <Fragment>
      {!isEmpty(subjetivoCiaps) && (
        <VFlow vSpacing={0.5}>
          <Heading level={level} style={styles.motivoHeading}>
            Motivo da consulta
          </Heading>
          <Box>
            <VFlow>
              {subjetivoCiaps?.map((subjetivoCiap) => (
                <div key={subjetivoCiap.id} css={styles.ciapContent}>
                  <Text>
                    <Text fontWeight='bold'>
                      {`${subjetivoCiap.ciap.descricao} - ${subjetivoCiap.ciap.codigo}`.toUpperCase()}
                    </Text>
                    {' (CIAP 2)'}
                  </Text>
                  {subjetivoCiap.nota && <HLabel title='Observações:'>{subjetivoCiap.nota}</HLabel>}
                </div>
              ))}
            </VFlow>
          </Box>
        </VFlow>
      )}
    </Fragment>
  )
}

const createStyles = () => ({
  motivoHeading: css`
    color: ${HistoricoSectionStyles['S'].color};
  `,
  ciapContent: css`
    p:last-child {
      margin-top: 1rem;
    }
  `,
})
