import { isEstagio } from 'components/auth/useSessionUtils'
import { LotacaoOuEstagio } from 'graphql/types.generated'

import { ProcedimentoPlanoModel } from '../components/ProcedimentoPlanoField'

export const hasMudancaProcedimentosAutomaticos = (
  listaProcedimentosA: ProcedimentoPlanoModel[],
  listaProcedimentosB: ProcedimentoPlanoModel[]
) =>
  getDiferencaEntreListasProcedimentos(listaProcedimentosA, listaProcedimentosB)
    .concat(getDiferencaEntreListasProcedimentos(listaProcedimentosB, listaProcedimentosA))
    .find((proced) => proced.automatico)

const getDiferencaEntreListasProcedimentos = (listaA: ProcedimentoPlanoModel[], listaB: ProcedimentoPlanoModel[]) =>
  listaA.filter((itemA) => !listaB.some((itemB) => saoProcedimentosIguais(itemA, itemB)))

export const saoProcedimentosIguais = (procedimentoA: ProcedimentoPlanoModel, procedimentoB: ProcedimentoPlanoModel) =>
  procedimentoA.procedimento.codigo === procedimentoB.procedimento.codigo &&
  procedimentoA.automatico === procedimentoB.automatico

export const getCbosIdsProcedimentos = (acesso: LotacaoOuEstagio) =>
  isEstagio(acesso) ? [acesso.lotacaoSupervisora.cbo.id, acesso.cbo.id] : [acesso.cbo.id]
