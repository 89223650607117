import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { gray } from 'bold-ui/lib/styles/colors'
import React from 'react'

export interface PrintFooterProps {
  data: string
  hora: string
  nomeProfissional: string
}

export const PrintFooter = (props: PrintFooterProps) => {
  const { data, hora, nomeProfissional } = props
  return (
    <View style={classes.main} fixed>
      <View style={classes.leftColumn}>
        <Text style={classes.text}>
          Impresso em {data} às {hora} por {nomeProfissional}.
        </Text>
      </View>
      <View style={classes.rightColumn}>
        <Text style={classes.text} fixed render={({ pageNumber, totalPages }) => `Pág. ${pageNumber}/${totalPages}`} />
      </View>
    </View>
  )
}

const classes = StyleSheet.create({
  main: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 20,
    left: 30,
    right: 30,
    borderTopWidth: 2,
    borderTopColor: gray.c10,
  },
  leftColumn: {
    marginTop: 2,
    flexGrow: 5,
    flexDirection: 'column',
    textAlign: 'left',
  },
  rightColumn: {
    marginTop: 2,
    flexGrow: 1,
    flexDirection: 'column',
    textAlign: 'right',
  },
  text: {
    fontSize: 8,
    fontFamily: 'Lato',
  },
})
