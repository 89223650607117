import { Omit, SelectHelperMenuItem } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React, { useMemo } from 'react'

import { useMediaDevices } from '../hooks/useMediaDevices'
import { DispositivoMidiaSelectModel } from '../model-videochamada'

export interface DispositivoMidiaSelectFieldProps
  extends Omit<SelectFieldProps<DispositivoMidiaSelectModel>, 'items' | 'itemToString'> {
  tipo: 'audioinput' | 'videoinput' | 'audiooutput'
  emptyMessage: string
  showOnlyDefault?: boolean
}

const itemToString = (item: DispositivoMidiaSelectModel) => item && item.nome

export function DispositivoMidiaSelectField(props: DispositivoMidiaSelectFieldProps) {
  const { tipo, emptyMessage, showOnlyDefault = false, ...rest } = props

  const mediaDevices = useMediaDevices({
    deviceType: tipo,
    onlyDefault: showOnlyDefault,
  })

  const items = useMemo(
    () =>
      mediaDevices
        .map((device) => ({
          id: device.deviceId,
          nome: showOnlyDefault ? 'Padrão' : device.label || `Dispositivo ${device.deviceId}`,
        }))
        .sort((a, b) => a.nome.localeCompare(b.nome)),
    [mediaDevices, showOnlyDefault]
  )

  return (
    <SelectField<DispositivoMidiaSelectModel>
      items={items}
      itemToString={itemToString}
      components={{
        EmptyItem: () => <SelectHelperMenuItem>{emptyMessage}</SelectHelperMenuItem>,
      }}
      {...rest}
    />
  )
}
