import { Alert, Text, VFlow } from 'bold-ui'
import { information } from 'components/modals/information'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React from 'react'

import { CIAPS_ENCERRAMENTO_GESTACAO, CIDS_ENCERRAMENTO_GESTACAO } from '../../../../pre-natal/model-preNatal'

const ALERTA_MEDICO =
  'A cidadã possui uma gestação ativa. Para encerrar a gestação no sistema, é necessário incluir a(s) CIAP 2/ CID 10 correspondentes na lista de problemas/condições. '
const LINK_ALERTA_MEDICO = 'Clique para acessar a lista de CIAP 2 / CID 10 relacionadas.'

const ALERTA_ENFERMEIRO =
  'A cidadã possui uma gestação ativa. Para encerrar a gestação no sistema, é necessário incluir a(s) CIAP 2 correspondentes na lista de problemas/condições. '
const LINK_ALERTA_ENFERMEIRO = 'Clique para acessar a lista de CIAP 2 relacionadas.'

const TEXTO_CIAPS = `CIAP2: ${CIAPS_ENCERRAMENTO_GESTACAO.join(', ')}`
const TEXTO_CIDS = `CID10: ${CIDS_ENCERRAMENTO_GESTACAO.join(', ')}`

interface AlertaEncerrarGestacaoModel {
  alertaText: string
  linkText: string
}

export function AlertaEncerrarGestacao() {
  const {
    permissoes: { somenteCiap },
  } = useAtendimentoContext()

  const alerta: AlertaEncerrarGestacaoModel = somenteCiap
    ? { alertaText: ALERTA_ENFERMEIRO, linkText: LINK_ALERTA_ENFERMEIRO }
    : { alertaText: ALERTA_MEDICO, linkText: LINK_ALERTA_MEDICO }

  function handleClick() {
    information({
      title: 'Encerrar gestação',
      body: (
        <VFlow>
          Para encerrar gestação no sistema, uma das condições abaixo deve ser incluída na Avaliação:
          <Text fontWeight='bold'>{TEXTO_CIAPS}.</Text>
          {!somenteCiap && <Text fontWeight='bold'>{TEXTO_CIDS}.</Text>}
        </VFlow>
      ),
    })()
  }

  return (
    <Alert inline type='info'>
      {alerta.alertaText}
      <Text style={{ cursor: 'pointer' }} variant='link' fontWeight='bold' onClick={handleClick}>
        {alerta.linkText}
      </Text>
    </Alert>
  )
}
