import {
  EvolucaoDente,
  ParteBucalEnum,
  SituacaoCoroaEnum,
  SituacaoFaceEnum,
  TipoParteBucalEnum,
} from 'graphql/types.generated'
import { union } from 'lodash'

import {
  getArcadas,
  getDentes,
  getFillColorBySituacaoCoroa,
  getParteBucal,
  getParteBucalByDenteNumber,
  getSextantes,
} from '../../util-EvolucoesOdontologicasPlano'
import { ArcadaModel } from '../odontograma/types/Arcada'
import { FillColor } from '../odontograma/types/Coroa'
import { DenteModel } from '../odontograma/types/Dente'
import { SextanteModel } from '../odontograma/types/Sextante'
import { ArcadaFieldModel } from './arcadas-field/ArcadasField'
import { DenteFieldModel } from './dentes-field/DentesField'
import { OdontogramaFieldModel, OdontogramaQueryModel } from './OdontogramaField'
import { EvolucaoOdontoModel } from './OdontogramaView'
import { OutrosFieldModel } from './outros-field/OutrosField'
import { SextanteFieldModel } from './sextantes-field/SextantesField'

export function convertOdontogramaQueryModelToOdontogramaFieldModel(
  odontograma: OdontogramaQueryModel,
  fillColors: FillColor<SituacaoCoroaEnum>[],
  evolucoesOdonto?: EvolucaoOdontoModel[]
) {
  return {
    dentes: convertEvolucaoDenteInputToDentesFieldModel(odontograma?.evolucoesDente, fillColors, evolucoesOdonto),
    arcadas: evolucoesOdonto && convertEvolucoesOdontoToArcadasFieldModel(evolucoesOdonto),
    outros: evolucoesOdonto && convertEvolucoesOdontoToOutrosFieldModel(evolucoesOdonto),
    proteseTotalSuperior: odontograma?.proteseTotalSuperior,
    proteseTotalInferior: odontograma?.proteseTotalInferior,
    possuiAparelho: odontograma?.aparelho,
    possuiContencao: odontograma?.contencao,
  } as OdontogramaFieldModel
}

export function convertEvolucaoDenteInputToDentesFieldModel(
  dentesInput: EvolucaoDente[],
  fillColors: FillColor<SituacaoCoroaEnum>[],
  evolucoesOdonto?: EvolucaoOdontoModel[]
) {
  const evolucoesDeDentes = evolucoesOdonto?.filter((item) =>
    item.partesBucais?.map((parteBucal) => parteBucal.tipoParteBucal).includes(TipoParteBucalEnum.DENTE)
  )

  const mapKeyValue = new Map(
    getDentes().map((dente) => {
      const denteId = Object.keys(ParteBucalEnum).indexOf(dente)
      const denteInput = dentesInput?.find((item) => item.dente.numero === getParteBucal(dente).number)
      const evolucoesDesteDente = evolucoesDeDentes?.filter((item) =>
        item.partesBucais.map(({ id }) => parseInt(id)).includes(denteId)
      )

      return [
        getParteBucalByDenteNumber(getParteBucal(dente).number),
        {
          condicoesDente: []
            .concat(denteInput?.situacoesFace, denteInput?.situacoesRaiz)
            .filter((item) => !!item && item !== SituacaoFaceEnum.NENHUM && item !== SituacaoFaceEnum.PROTESE_TOTAL),
          procedimentos: evolucoesDesteDente && union(evolucoesDesteDente.map(({ procedimentos }) => procedimentos))[0],
          observacoes: evolucoesDesteDente?.map(({ observacao }) => observacao),
          coroa: {
            faceTop: {
              activeColor: getFillColorBySituacaoCoroa(denteInput?.situacaoCoroaCima, fillColors),
            },
            faceLeft: {
              activeColor: getFillColorBySituacaoCoroa(denteInput?.situacaoCoroaEsquerda, fillColors),
            },
            faceRight: {
              activeColor: getFillColorBySituacaoCoroa(denteInput?.situacaoCoroaDireita, fillColors),
            },
            faceBottom: {
              activeColor: getFillColorBySituacaoCoroa(denteInput?.situacaoCoroaBaixo, fillColors),
            },
            faceCenter: {
              activeColor: getFillColorBySituacaoCoroa(denteInput?.situacaoCoroaCentro, fillColors),
            },
          },
        } as DenteFieldModel,
      ] as [ParteBucalEnum, DenteFieldModel]
    })
  )

  return Object.fromEntries(mapKeyValue) as Record<ParteBucalEnum, DenteFieldModel>
}

export function convertEvolucoesOdontoToSextantesFieldModel(evolucoesOdonto: EvolucaoOdontoModel[]) {
  const evolucoesDeSextantes = evolucoesOdonto.filter((item) =>
    item.partesBucais?.map((parteBucal) => parteBucal.tipoParteBucal).includes(TipoParteBucalEnum.SEXTANTE)
  )

  const mapKeyValue = new Map(
    getSextantes()
      .map((sextante) => {
        const sextanteId = Object.keys(ParteBucalEnum).indexOf(sextante)
        const evolucoesDesteSextante = evolucoesDeSextantes.filter((item) =>
          item.partesBucais.map(({ id }) => parseInt(id)).includes(sextanteId)
        )

        return (
          evolucoesDesteSextante &&
          ([
            sextante,
            {
              observacoes: evolucoesDesteSextante.map(({ observacao }) => observacao),
              procedimentos: union(evolucoesDesteSextante.map(({ procedimentos }) => procedimentos))[0],
            },
          ] as [ParteBucalEnum, SextanteFieldModel])
        )
      })
      .filter((i) => !!i)
  )

  return Object.fromEntries(mapKeyValue) as Record<ParteBucalEnum, SextanteFieldModel>
}

export function convertEvolucoesOdontoToArcadasFieldModel(evolucoesOdonto: EvolucaoOdontoModel[]) {
  const evolucoesDeArcadas = evolucoesOdonto.filter((item) =>
    item.partesBucais?.map((parteBucal) => parteBucal.tipoParteBucal).includes(TipoParteBucalEnum.ARCADA)
  )

  const mapKeyValue = new Map(
    getArcadas()
      .map((arcada) => {
        const arcadaId = Object.keys(ParteBucalEnum).indexOf(arcada)
        const evolucoesDestaArcada = evolucoesDeArcadas.filter((item) =>
          item.partesBucais.map(({ id }) => parseInt(id)).includes(arcadaId)
        )

        return (
          evolucoesDestaArcada &&
          ([
            arcada,
            {
              observacoes: evolucoesDestaArcada.map(({ observacao }) => observacao),
              procedimentos: union(evolucoesDestaArcada.map(({ procedimentos }) => procedimentos))[0],
            },
          ] as [ParteBucalEnum, ArcadaFieldModel])
        )
      })
      .filter((i) => !!i)
  )

  return Object.fromEntries(mapKeyValue) as Record<ParteBucalEnum, ArcadaFieldModel>
}

export function convertEvolucoesOdontoToOutrosFieldModel(evolucoesOdonto: EvolucaoOdontoModel[]) {
  return evolucoesOdonto
    ?.flatMap((evolucao) =>
      evolucao?.partesBucais?.map(
        (parteBucal) =>
          parteBucal.tipoParteBucal === TipoParteBucalEnum.OUTRO &&
          ({
            local: evolucao.descricaoParteBucal,
            procedimentos: evolucao.procedimentos,
            observacao: evolucao.observacao,
          } as OutrosFieldModel)
      )
    )
    .filter((item) => !!item)
}

export function convertDentesFieldModelToDentesModel(dentes: Record<ParteBucalEnum, DenteFieldModel>) {
  const mapKeyValue = new Map(
    Object.entries(dentes).map(
      ([key, dente]) =>
        [
          key,
          {
            coroa: dente?.coroa,
            condicoes: dente.condicoesDente,
            procedimentos: dente.procedimentos,
          },
        ] as [ParteBucalEnum, DenteModel]
    )
  )

  return Object.fromEntries(mapKeyValue) as Record<ParteBucalEnum, DenteModel>
}

export function convertSextantesFieldModelToSextantesModel(sextantes: Record<ParteBucalEnum, SextanteFieldModel>) {
  const mapKeyValue = new Map(
    Object.entries(sextantes).map(
      ([key, sextante]) =>
        [
          key,
          {
            tooltipNumber: sextante.procedimentos?.length || 0,
            tooltipText:
              sextante.procedimentos?.length &&
              sextante.procedimentos?.length +
                (sextante?.procedimentos.length > 1 ? ' procedimentos' : ' procedimento'),
          },
        ] as [ParteBucalEnum, SextanteModel]
    )
  )

  return Object.fromEntries(mapKeyValue) as Record<ParteBucalEnum, SextanteModel>
}

export function convertArcadasFieldModelToArcadasModel(arcadas: Record<ParteBucalEnum, ArcadaFieldModel>) {
  const mapKeyValue = new Map(
    Object.entries(arcadas).map(
      ([key, arcada]) =>
        [
          key,
          {
            tooltipNumber: arcada.procedimentos?.length || 0,
            tooltipText:
              arcada.procedimentos?.length &&
              arcada.procedimentos?.length + (arcada?.procedimentos.length > 1 ? ' procedimentos' : ' procedimento'),
          },
        ] as [ParteBucalEnum, ArcadaModel]
    )
  )

  return Object.fromEntries(mapKeyValue) as Record<ParteBucalEnum, ArcadaModel>
}
