import { HFlow } from 'bold-ui'
import { ExposedDropdown } from 'components/dropdown/ExposedDropdown'
import { UseFieldProps, usePecField } from 'components/form/final-form/hooks/useField'
import React from 'react'
import { ExibirPorHistoricoAtendimentoFilterEnum } from 'types/enums'

export interface HistoricoAtendimentoSortDropdownProps extends UseFieldProps<ExibirPorHistoricoAtendimentoFilterEnum> {}

export function HistoricoAtendimentoSortDropdown(props: HistoricoAtendimentoSortDropdownProps) {
  const {
    input: { value, onChange },
  } = usePecField(props)

  const itemToString = (sort: ExibirPorHistoricoAtendimentoFilterEnum) => sort && ExibirPorHistoricoFilterRecord[sort]

  const items = Object.keys(ExibirPorHistoricoAtendimentoFilterEnum).filter(
    (item) => item !== ExibirPorHistoricoAtendimentoFilterEnum.PERSONALIZADO
  ) as ExibirPorHistoricoAtendimentoFilterEnum[]

  return (
    <HFlow hSpacing={0.25} alignItems='center' justifyContent='flex-end'>
      <ExposedDropdown<ExibirPorHistoricoAtendimentoFilterEnum>
        label='Exibir:'
        selectedItem={value}
        items={items}
        itemToString={itemToString}
        onChange={onChange}
      />
    </HFlow>
  )
}

const ExibirPorHistoricoFilterRecord: Record<ExibirPorHistoricoAtendimentoFilterEnum, string> = {
  [ExibirPorHistoricoAtendimentoFilterEnum.TODOS_REGISTROS]: 'Todos os registros',
  [ExibirPorHistoricoAtendimentoFilterEnum.ULTIMOS_TRINTA_DIAS]: 'Últimos 30 dias',
  [ExibirPorHistoricoAtendimentoFilterEnum.ULTIMOS_SESSENTA_DIAS]: 'Últimos 60 dias',
  [ExibirPorHistoricoAtendimentoFilterEnum.ULTIMOS_NOVENTA_DIAS]: 'Últimos 90 dias',
  [ExibirPorHistoricoAtendimentoFilterEnum.ULTIMO_ANO]: 'Último ano',
  [ExibirPorHistoricoAtendimentoFilterEnum.PERSONALIZADO]: 'Personalizado',
}
