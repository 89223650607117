/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { TableFooter, TableFooterProps, Text, Theme, useTheme, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { isLotacao } from 'components/auth/useSessionUtils'
import { LoadingIndicator } from 'components/loading'
import { usePagedTableProps } from 'components/table'
import { startOfDay } from 'date-fns'
import { useAgendamentosCidadaoPagedQuery } from 'graphql/hooks.generated'
import { PageParams } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { useState } from 'react'
import { useParams } from 'react-router'
import Permissions from 'types/Permissions'

import { AgendamentosCidadaoFilter } from './filter/AgendamentosCidadaoFilter'
import { AgendamentosCidadaoListingItem } from './list/AgendamentosCidadaoListingItem'
import { AgendamentoCidadaoModel, AgendamentoCidadaoPermissions, AgendamentosCidadaoFilterModel } from './model'
import { getSituacoesAgendado } from './util'

interface AgendamentoCidadaoViewParams {
  cidadaoId: ID
}

export function AgendamentosCidadaoView() {
  const params = useParams<AgendamentoCidadaoViewParams>()

  const {
    data: {
      profissional: { id: profissionalId },
      acesso,
    },
    hasAuthorization,
  } = useSession()

  const permissions: AgendamentoCidadaoPermissions = {
    podeCancelarTodasEquipes: hasAuthorization(Permissions.visualizarAgenda.cancelar.todasAsEquipes),
    podeCancelarAgendamento: hasAuthorization(Permissions.visualizarAgenda.cancelar),
  }
  const equipeId = isLotacao(acesso) && acesso.equipe?.id
  const ubsId = isLotacao(acesso) && acesso.unidadeSaude.id

  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()
  const today = startOfDay(now)

  const [filter, setFilter] = useState<AgendamentosCidadaoFilterModel>({
    periodo: { startDate: today, endDate: null },
  })

  const [pageParams, setPageParams] = useState<{ pageParams?: PageParams }>({
    pageParams: {
      page: 0,
      size: 10,
    },
  })
  const { refetch, data, loading } = useAgendamentosCidadaoPagedQuery({
    variables: {
      input: {
        cidadaoId: params.cidadaoId,
        dataAgendadoInicio: filter.periodo?.startDate,
        dataAgendadoFim: filter.periodo?.endDate,
        situacao: getSituacoesAgendado(today, filter.periodo?.endDate),
        profissionalId: filter.isSomenteMeus ? profissionalId : null,
        nomeProfissional: filter.nomeProfissional,
        maisNovosPrimeiro: true,
        pageParams: pageParams.pageParams,
      },
    },
  })

  const content = data.agendados?.content
  const pageInfo = data.agendados?.pageInfo

  const pagedTableProps = usePagedTableProps({ loading, result: data.agendados, onChange: setPageParams })
  const tableFooterProps: TableFooterProps = { pageSize: pagedTableProps.size, ...pagedTableProps }

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <VFlow vSpacing={0.5}>
      <AgendamentosCidadaoFilter updateFilter={setFilter} filter={filter} today={today} />
      {loading ? (
        <LoadingIndicator />
      ) : pageInfo.totalElements === 0 ? (
        <div css={styles.textDiv}>
          <Text>Nenhum agendamento foi encontrado.</Text>
        </div>
      ) : (
        content?.map((agendamento: AgendamentoCidadaoModel) => (
          <AgendamentosCidadaoListingItem
            onCancelar={refetch}
            agendamento={agendamento}
            profissionalId={profissionalId}
            equipeId={equipeId}
            ubsId={ubsId}
            permissions={permissions}
            serverTime={now}
            key={agendamento.id}
            isAgendaOnlineAtiva={data?.conexao.agendaOnline.ativa}
          />
        ))
      )}
      <TableFooter {...tableFooterProps} style={styles.tableFooter} />
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  textDiv: css`
    margin-top: 1rem;
    text-align: center;
  `,
  tableFooter: css`
    background-color: ${theme.pallete.surface.main};
  `,
})
