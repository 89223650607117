import { EquipeSelectModel, UnidadeSaudeSelectModel } from 'components/form'
import createDecorator from 'final-form-calculate'
import { isUndefinedOrNull } from 'util/checks'
import { MetaRoot } from 'util/metaPath'

import { AtualizacaoImoveisFormModel, ReterritorializacaoBuscaFormModel } from './model'

export const createAtualizaImoveisFormCalculator = (meta: MetaRoot<AtualizacaoImoveisFormModel>) =>
  createDecorator(
    {
      field: meta.unidadeSaude.absolutePath(),
      updates: {
        [meta.equipe.absolutePath()]: (
          unidadeSaude: UnidadeSaudeSelectModel,
          allValues: AtualizacaoImoveisFormModel,
          prevValues: AtualizacaoImoveisFormModel
        ) => {
          return unidadeSaude === null || unidadeSaude?.id !== prevValues.unidadeSaude?.id ? null : allValues.equipe
        },
        [meta.lotacao.absolutePath()]: (
          unidadeSaude: UnidadeSaudeSelectModel,
          allValues: AtualizacaoImoveisFormModel,
          prevValues: AtualizacaoImoveisFormModel
        ) => {
          return unidadeSaude === null || unidadeSaude?.id !== prevValues.unidadeSaude?.id ? null : allValues.lotacao
        },
      },
    },
    {
      field: meta.equipe.absolutePath(),
      updates: {
        [meta.lotacao.absolutePath()]: (
          equipe: EquipeSelectModel,
          allValues: AtualizacaoImoveisFormModel,
          prevValues: AtualizacaoImoveisFormModel
        ) => {
          return equipe === null || equipe?.id !== prevValues.equipe?.id ? null : allValues.lotacao
        },
      },
    }
  )

export const createBuscarImoveisFormCalculator = (meta: MetaRoot<ReterritorializacaoBuscaFormModel>) =>
  createDecorator(
    {
      field: meta.unidadeSaude.absolutePath(),
      updates: {
        [meta.equipe.absolutePath()]: (
          unidadeSaude: UnidadeSaudeSelectModel,
          allValues: ReterritorializacaoBuscaFormModel,
          prevValues: ReterritorializacaoBuscaFormModel
        ) => {
          return unidadeSaude !== prevValues.unidadeSaude || isUndefinedOrNull(unidadeSaude) ? null : allValues.equipe
        },
      },
    },
    {
      field: [meta.equipe.absolutePath()],
      updates: {
        [meta.microarea.absolutePath()]: (
          equipe: EquipeSelectModel,
          allValues: ReterritorializacaoBuscaFormModel,
          prevValues: ReterritorializacaoBuscaFormModel
        ) => {
          return equipe !== prevValues.equipe || isUndefinedOrNull(equipe) ? null : allValues.equipe
        },
      },
    }
  )
