import { Icon, Text, useStyles } from 'bold-ui'
import React, { useState } from 'react'

import { ValidacaoModel } from './model'

export const ErrorsBox = (props: { title: string; children?: React.ReactNode }) => {
  const { title, children } = props
  const { classes } = useStyles((theme) => ({
    box: {
      border: '1px solid' + theme.pallete.divider,
      borderRadius: theme.radius.input,
      '& > :not(:last-child)': {
        borderBottom: '1px solid' + theme.pallete.divider,
      },
      '& > :last-child': {
        borderBottom: '1px solid transparent',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.375rem 1rem',
    },
  }))
  return (
    <div className={classes.box}>
      <div className={classes.header}>
        <Text fontWeight='bold'>{title}</Text>
        <Text fontWeight='bold'>Total</Text>
      </div>
      {children}
    </div>
  )
}

export const ErrorGroup = (props: { title: string; children: React.ReactNode }) => {
  const { title, children } = props
  const [opened, setOpened] = useState(false)
  const { classes } = useStyles((theme) => ({
    group: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      height: '2.5rem',
      paddingLeft: '2rem',
      paddingRight: '1rem',
    },
    icon: {
      marginRight: '1rem',
      color: theme.pallete.text.secondary,
    },
    text: {
      flexGrow: 1,
    },
  }))

  const count = React.Children.count(children)

  if (count === 0) {
    return null
  }

  const toggleOpened = () => setOpened((oldState) => !oldState)

  return (
    <>
      <div className={classes.group} onClick={toggleOpened}>
        <Icon style={classes.icon} icon={opened ? 'angleDown' : 'angleRight'} />
        <Text style={classes.text}>{title}</Text>
        <Text>{count}</Text>
      </div>
      {opened && children}
    </>
  )
}

export const ErrorItem = (props: { children: React.ReactNode }) => {
  const { children } = props
  const { classes } = useStyles((theme) => ({
    item: {
      alignItems: 'center',
      display: 'flex',
      height: '2.5rem',
      paddingLeft: '5.5rem',
    },
  }))
  return <div className={classes.item}>{children}</div>
}

export const ValidationErrorsField = (props: { errors: ValidacaoModel[] }) => (
  <ValidationError error={props.errors.map((erro) => erro.campo).join('; ')} />
)

export const ValidationErrorsMessage = (props: { errors: ValidacaoModel[] }) => (
  <ValidationError error={props.errors.map((erro) => erro.erro).join('; ')} />
)

export const ValidationError = (props: { error: string }) => {
  const { classes } = useStyles((theme) => ({
    error: {
      fontSize: '1rem',
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      color: theme.pallete.status.danger.main,
    },
  }))

  return (
    <>
      <Icon style={classes.error} icon='banOutline' />
      <Text color='danger'>{props.error}</Text>
    </>
  )
}
