import { Icon } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { ButtonLink } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'

import { ProfissionalTable } from './ProfissionalTable'

export function ProfissionalListView() {
  const { match } = useRouter()

  return (
    <>
      <PageHeaderSection
        title='Profissionais'
        action={
          <CheckPermission permission={Permissions.visualizarProfissionais.cadastrarEditarEExcluir}>
            <ButtonLink to={`${match.url}/create`} kind='primary'>
              <Icon icon='plus' style={{ marginRight: '0.5rem' }} />
              Cadastrar profissional
            </ButtonLink>
          </CheckPermission>
        }
      />

      <PageContent>
        <ProfissionalTable />
      </PageContent>
    </>
  )
}

export default ProfissionalListView
