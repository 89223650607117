import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const DescricaoDoServicoSection = () => {
  return (
    <TermosDeUsoHeadingSection title='4. Descrição do Serviço' id='descricao_do_serviço'>
      <p>
        O Prontuário Eletrônico do Cidadão (PEC) integra a estratégia e-SUS APS (Atenção Primária à Saúde): sistema
        gratuito disponibilizado pelo Ministério da Saúde, utilizado nos serviços públicos de Atenção Primária à Saúde
        (APS). A estratégia envolve o registro das informações no sistema, o qual pode ser realizado no Prontuário
        Eletrônico do Cidadão (PEC) ou pela Coleta de Dados Simplificada (CDS). O Sistema e-SUS APS auxilia, entre
        outras coisas, nas ações de gerenciamento de Unidades de Atenção Primária à Saúde, organização da agenda e
        processo de trabalho, registro e organização, em formato de prontuário eletrônico, para os profissionais de
        saúde, registro e organização, em formato de Coleta de Dados Simplificada (módulo CDS), por meio de fichas e
        processo de digitação destas, bem como monitoramento e avaliação das ações de saúde no território.
      </p>
      <br />
      <p>
        O PEC tem o objetivo de atender as equipes da APS lotadas unidades básicas de saúde (UBS) parcialmente ou
        totalmente informatizadas. Esse sistema é usado para registrar informações de cadastros e clínicas sobre os
        cidadãos. O prontuário funciona em cenários com ou sem conexão com a internet.
      </p>
    </TermosDeUsoHeadingSection>
  )
}
