import { resolveName } from 'components/form/final-form/hooks/useField'
import { getIn } from 'final-form'
import { MetaPath } from 'util/metaPath'

import { ValidationErrorModalConfig, ValidationErrorModalConfigItem } from './model'

const resolveLabelName = <TFieldModel>(fieldName: MetaPath<TFieldModel>) =>
  resolveName(fieldName).replaceAll('.', '.fields.')

export const getFieldLabelConfig = <TFieldModel, TFormModel>(
  fieldName: MetaPath<TFieldModel>,
  config: ValidationErrorModalConfig<TFormModel>
): ValidationErrorModalConfigItem<TFieldModel> => {
  const resolvedName = resolveLabelName<TFieldModel>(fieldName)
  return getIn(config, resolvedName) ?? {}
}
