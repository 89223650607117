import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'

export interface MotivoFechamentoSelectFieldProps extends Omit<SelectFieldProps<string>, 'items' | 'itemToString'> {}

export function MotivoFechamentoSelectField(props: MotivoFechamentoSelectFieldProps) {
  const itemToString = (item: MotivoFechamentoSelectModel) => item && descriptions[item]

  return <SelectField<MotivoFechamentoSelectModel> items={items} itemToString={itemToString} {...props} />
}

export type MotivoFechamentoSelectModel = string

const items = ['ATESTADO', 'CURSO', 'FERIAS', 'LICENCA', 'REUNIAO', 'OUTRO']

const descriptions = {
  ATESTADO: 'Atestado',
  CURSO: 'Curso',
  FERIAS: 'Férias',
  LICENCA: 'Licença',
  REUNIAO: 'Reunião',
  OUTRO: 'Outro',
}
