import { HFlow, Tabs, Tag } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PecSwitch, TabLink } from 'components/route'
import { useEquipeDetailQuery } from 'graphql/hooks.generated'
import React from 'react'
import { Redirect, Route, useRouteMatch } from 'react-router'
import { ProfissionalTable } from 'view/profissional/list/ProfissionalTable'
import { EquipesVinculadasTable } from 'view/vinculacao-equipes/EquipesVinculadasTable'

export interface UrlParams {
  equipeId: string
}

export interface EquipeDetailRootViewProps {
  unidadeSaudeId: ID
}

export function EquipeDetailRootView(props: EquipeDetailRootViewProps) {
  const { unidadeSaudeId } = props
  const { url, params } = useRouteMatch<UrlParams>()
  const { equipeId } = params

  const {
    data: { equipe },
  } = useEquipeDetailQuery({
    variables: { id: equipeId },
  })

  const renderProfissionais = () => {
    return <ProfissionalTable loadOnMount initialFilter={{ equipesId: [equipeId] }} />
  }

  const renderEquipesVinculadas = () => {
    return <EquipesVinculadasTable equipePrincipalId={equipeId} />
  }

  if (!equipe) {
    return null
  }

  return (
    <>
      <Breadcrumb title='Visualização' to={`/unidadeSaude/${unidadeSaudeId}/equipes`} />
      <Breadcrumb title='Visualização de equipe' />

      <PageHeaderSection
        title={
          <HFlow alignItems='center'>
            <h1>{equipe.nome + ' - ' + equipe.ine}</h1>
            {!equipe.ativo && <Tag style={{ fontSize: '0.75rem' }}>Inativa</Tag>}
          </HFlow>
        }
        subtitle={`Área ${equipe.area}`}
      >
        <Tabs>
          <TabLink to={`${url}/profissionais`}>Profissionais</TabLink>
          <TabLink to={`${url}/equipesVinculadas`}>Equipes vinculadas</TabLink>
        </Tabs>
      </PageHeaderSection>

      <PageContent>
        <PecSwitch>
          <Redirect exact path={`${url}`} to={`${url}/profissionais`} />
          <Route exact path={`${url}/profissionais`} render={renderProfissionais} />
          <Route exact path={`${url}/equipesVinculadas`} render={renderEquipesVinculadas} />
        </PecSwitch>
      </PageContent>
    </>
  )
}
