import { Icon, Text, Theme, useStyles } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { PageContent } from 'components/layout/PageContent'
import { ButtonLink } from 'components/route'
import React, { CSSProperties } from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import ListaADTable from './list/ListaADTable'

export default function ListaADView() {
  const { classes } = useStyles(createStyles)
  const match = useRouteMatch()
  const { hasAuthorization } = useSession()
  const { acesso } = useAcessoLotacaoOrEstagio()

  const isCoordenadorAd = !hasAuthorization(Permissions.atencaoDomiciliar.visualizarListaDeAdDaEquipe.cadastrar)

  return (
    <>
      <PageContent type='filled' style={classes.heading}>
        <Text style={classes.headingText}>Lista de atenção domiciliar</Text>
        {acesso?.equipe?.tipoEquipe?.descricao !== 'EMAP' && (
          <CheckPermission permission={Permissions.atencaoDomiciliar.visualizarListaDeAdDaEquipe.cadastrar}>
            <ButtonLink to={`${match.url}/cadastro`} kind='primary' style={classes.headingButton}>
              <Icon icon='plus' style={{ marginRight: '0.5rem' }} />
              Adicionar cidadão
            </ButtonLink>
          </CheckPermission>
        )}
      </PageContent>
      <PageContent type='filled' style={classes.container}>
        <ListaADTable equipe={acesso?.equipe} localidadeId={acesso?.municipio?.id} isCoordenadorAd={isCoordenadorAd} />
      </PageContent>
    </>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    backgroundColor: theme.pallete.gray.c90,
  } as CSSProperties,
  heading: {
    flexGrow: 0,
    padding: '2rem 0 2rem 0',
    width: '100%',
  } as CSSProperties,
  headingText: {
    fontSize: '1.5rem',
    fontWeight: 'bolder',
  } as CSSProperties,
  headingButton: {
    float: 'right',
  } as CSSProperties,
})
