/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Heading,
  isEmpty,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableHeader,
  TableLoadingRow,
  TablePlaceholderRow,
  TableRow,
  VFlow,
} from 'bold-ui'
import { useAlert } from 'components/alert'
import { confirm } from 'components/modals/confirm'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { useHistoricoEvolucoesOdontoTableQuery } from 'graphql/hooks.generated'
import { ParteBucalEnum, TipoParteBucalEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { TextFilterType } from 'hooks/filter/model'
import { useFilter } from 'hooks/filter/useFilter'
import { useState } from 'react'
import { metaPath } from 'util/metaPath'

import { OdontogramaFieldModel } from '../components/odontograma-field/OdontogramaField'
import { EvolucoesOdontoTableHeader } from './EvolucoesOdontoTableHeader'
import { EvolucoesOdontoTableRow } from './EvolucoesOdontoTableRow'
import { EvolucaoOdontoTableItemModel } from './model'
import { joinEvolucoesOdonto } from './util'

interface EvolucoesOdontoTableProps {
  prontuarioId: ID
  valoresAtuais: OdontogramaFieldModel
  isEditingDenteOrArcada: boolean
  onEditDenteOrArcada(parteBucal: ParteBucalEnum, tipoParteBucal: TipoParteBucalEnum): void
  onRemove: (id: ID, tipoParteBucal: TipoParteBucalEnum) => void
}

interface EvolucoesOdontoTableFilterModel {
  query: string
}

export const EvolucoesOdontoTable = (props: EvolucoesOdontoTableProps) => {
  const { prontuarioId, valoresAtuais, isEditingDenteOrArcada, onEditDenteOrArcada, onRemove } = props

  const alert = useAlert()

  const [filter, setFilter] = useState<EvolucoesOdontoTableFilterModel>()

  const {
    atendimentoProfissional: { iniciadoEm, lotacao },
  } = useAtendimentoContext()

  const {
    data: { evolucoesProcedimentoOdonto },
    loading: isLoadingQuery,
  } = useHistoricoEvolucoesOdontoTableQuery({
    variables: {
      input: {
        tipoPartesBucais: [
          TipoParteBucalEnum.DENTE,
          TipoParteBucalEnum.ARCADA,
          TipoParteBucalEnum.OUTRO,
          TipoParteBucalEnum.SUPRANUMERARIO,
        ],
        prontuarioId,
        unpaged: true,
      },
    },
  })

  const joinedItems = joinEvolucoesOdonto(
    evolucoesProcedimentoOdonto?.content,
    valoresAtuais,
    lotacao,
    iniciadoEm.valueOf()
  )

  const filteredItems = useFilter<EvolucaoOdontoTableItemModel, EvolucoesOdontoTableFilterModel>({
    items: joinedItems,
    filter,
    filtersType: [filterTextTypeConfig],
  })

  const { paginatedItems, tableProps } = usePagination<EvolucaoOdontoTableItemModel>({ items: filteredItems })

  const isNotEmpty = !isEmpty(paginatedItems)

  const handleRemove = (id: ID, tipoParteBucal: TipoParteBucalEnum) =>
    confirm({
      title: 'Deseja excluir o registro odontológico?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        onRemove(id, tipoParteBucal)
        alert('success', 'Registro odontológico excluído com sucesso')
      },
    })()

  return (
    (isNotEmpty || isLoadingQuery || !!filter?.query) && (
      <VFlow vSpacing={0.5}>
        <Heading level={4}>Histórico de evoluções</Heading>

        <TableBox header={<EvolucoesOdontoTableHeader onChange={(query) => setFilter({ query })} />}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Data</TableHeader>
                <TableHeader>Local</TableHeader>
                <TableHeader>Procedimentos</TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoadingQuery ? (
                <TableLoadingRow colSpan={4} />
              ) : isNotEmpty ? (
                paginatedItems.map((item) => (
                  <EvolucoesOdontoTableRow
                    key={item.key}
                    row={item}
                    isEditingDenteOrArcada={isEditingDenteOrArcada}
                    onEditDenteOrArcada={onEditDenteOrArcada}
                    onRemove={handleRemove}
                  />
                ))
              ) : (
                <TablePlaceholderRow colSpan={4} />
              )}
            </TableBody>
          </Table>
          <TableFooter {...tableProps} style={styles.tableFooter} />
        </TableBox>
      </VFlow>
    )
  )
}

const metaRow = metaPath<EvolucaoOdontoTableItemModel>()
const metaFilter = metaPath<EvolucoesOdontoTableFilterModel>()

const filterTextTypeConfig: TextFilterType = {
  filterField: metaFilter.query,
  searchFields: [
    metaRow.searchPartesBucais,
    metaRow.searchProcedimentos,
    metaRow.descricaoParteBucal,
    metaRow.lotacao.profissional.nome,
    metaRow.lotacao.cbo.nome,
  ],
  removeTagsOnFilter: true,
}

const styles = {
  tableFooter: css`
    border-top: 0;
  `,
}
