/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { EvolucaoAvaliacao } from 'graphql/types.generated'
import { Fragment } from 'react'
import { formatDate } from 'util/date/formatDate'
import { EvolucaoProblema, situacaoProblemaRecord } from 'view/atendimentos/detail/soap/aside/types/ProblemaModel'

import StatusSquare from '../../../../../../StatusSquare'

interface HistoricoAvaliacaoProblemasCondicoesTableContentProps {
  evolucaoAvaliacao: EvolucaoAvaliacao
  evolucaoProblema: EvolucaoProblema[]
}

export default function HistoricoAvaliacaoProblemasCondicoesTableContent(
  props: HistoricoAvaliacaoProblemasCondicoesTableContentProps
) {
  const { evolucaoAvaliacao, evolucaoProblema } = props

  return (
    <VFlow>
      {evolucaoProblema?.map((evolucao) => (
        <Grid gap={2} key={evolucao.id}>
          <Cell size={6}>
            {evolucao.possuiCiap && (
              <Fragment>
                <Text fontWeight='bold'>{`${evolucao.problema.ciap.descricao} - ${evolucao.problema.ciap.codigo} `}</Text>
                <Text>(CIAP 2)</Text>
              </Fragment>
            )}
            {evolucao.possuiCiap && evolucao.possuiCid && <Text> | </Text>}
            {evolucao.possuiCid && (
              <Fragment>
                <Text fontWeight='bold'>{`${evolucao.problema.cid10.nome} - ${evolucao.problema.cid10.codigo} `}</Text>
                <Text>(CID 10)</Text>
              </Fragment>
            )}
          </Cell>
          <Cell size={6}>
            <InfoLabel title='Lista de problemas/condições'>
              <StatusSquare
                color={situacaoProblemaRecord[evolucao.situacao].background}
                description={situacaoProblemaRecord[evolucao.situacao].descricao}
              />
            </InfoLabel>
          </Cell>
          <Cell
            size={6}
            style={css`
              align-self: end;
            `}
          >
            {evolucao.observacao && <HLabel title='Observações:'>{evolucao.observacao}</HLabel>}
          </Cell>
          {(evolucao.dataFim || evolucao.dataInicio) && (
            <Cell size={6}>
              <Fragment>
                <HLabel title='Data de início:'>{formatDate(evolucao.dataInicio)}</HLabel>
                <HLabel title='Data de fim:'>{formatDate(evolucao.dataFim)}</HLabel>
              </Fragment>
            </Cell>
          )}
        </Grid>
      ))}
      {evolucaoAvaliacao.ciapsCids?.map(
        (ciapCid) =>
          !ciapCid.isCiapCidNaListaDeProblemas && (
            <Grid gap={2} key={ciapCid.id}>
              <Cell size={6}>
                {ciapCid.ciap && (
                  <Fragment>
                    <Text fontWeight='bold'>{`${ciapCid.ciap.descricao} - ${ciapCid.ciap.codigo} `}</Text>
                    <Text>(CIAP 2)</Text>
                  </Fragment>
                )}
                {ciapCid.ciap && ciapCid.cid10 && <Text> | </Text>}
                {ciapCid.cid10 && (
                  <Fragment>
                    <Text fontWeight='bold'>{`${ciapCid.cid10.nome} - ${ciapCid.cid10.codigo} `}</Text>
                    <Text>(CID 10)</Text>
                  </Fragment>
                )}
              </Cell>
              <Cell size={6}>
                <InfoLabel title='Lista de problemas/condições'>Não incluido</InfoLabel>
              </Cell>
              {ciapCid.nota && (
                <Cell size={12}>
                  <HLabel title='Observações:'>{ciapCid.nota}</HLabel>
                </Cell>
              )}
            </Grid>
          )
      )}
    </VFlow>
  )
}
