import { PrivateRoute } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { Switch } from 'react-router'
import Permissions from 'types/Permissions'

import { AtendimentoDoDiaView } from './list/actions/atendimento-do-dia/AtendimentoDiaModal'
import { GerarDeclaracaoComparecimentoView } from './list/actions/declaracao-comparecimento/GerarDeclaracaoComparecimentoView'
import { EditarAtendimentoModalForm } from './list/actions/editar/EditarAtendimentoModalForm'
import { ListaAtendimentosView } from './list/ListaAtendimentoView'

export function ListaAtendimentoRootView() {
  const { match } = useRouter()

  return (
    <>
      <ListaAtendimentosView />
      <Switch>
        <PrivateRoute
          path={`${match.url}/declaracaoComparecimento/:atendId(\\d+)`}
          component={GerarDeclaracaoComparecimentoView}
          permission={Permissions.visualizarListaDeAtendimento.gerarDeclaracaoDeComparecimento}
        />

        <PrivateRoute
          path={`${match.url}/atendimentoDoDia/:atendimentoId(\\d+)`}
          component={AtendimentoDoDiaView}
          permission={Permissions.visualizarListaDeAtendimento}
        />

        <PrivateRoute
          path={`${match.url}/editar/:atendimentoId(\\d+)`}
          component={EditarAtendimentoModalForm}
          permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}
        />
      </Switch>
    </>
  )
}
