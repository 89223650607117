import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const FinalidadeDeTratamentoDosDadosSection = () => {
  return (
    <TermosDeUsoHeadingSection
      title='8. Qual a Finalidade de Tratamento dos Dados'
      id='qual_a_finalidade_de_tratamento_dos_dados'
    >
      <p>Execução de políticas públicas em saúde, respaldadas no item 3 dos termos de uso.</p>
    </TermosDeUsoHeadingSection>
  )
}
