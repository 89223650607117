/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente62(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='44' height='117' viewBox='0 0 44 117' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M37.4294 84.9666C37.3882 84.6305 37.3305 84.1533 37.2581 83.5376C37.1005 82.1972 36.8732 80.2001 36.5941 77.572C36.0352 72.3071 35.1291 60.1635 34.5646 49.71C33.9999 39.252 33.9378 12.9728 34.5662 6.45562C34.7087 4.97774 34.2426 3.99905 33.4749 3.38564C32.6823 2.75227 31.4932 2.44917 30.1018 2.50677C27.3132 2.62221 23.9819 4.17668 22.1633 6.78926C21.2479 8.10427 20.3688 10.1482 19.5366 12.7653C18.7073 15.3734 17.9355 18.515 17.2212 22.0004C15.7925 28.9706 14.6 37.284 13.6356 45.3896C13.5512 46.0991 13.4729 46.8048 13.4004 47.506C12.6995 54.2794 12.5402 60.8811 12.4063 66.4346C12.3891 67.144 12.3724 67.8363 12.3551 68.5097C12.2786 71.4762 12.189 74.0845 11.9921 76.1582C11.8937 77.1955 11.7674 78.1103 11.5995 78.876C11.433 79.6349 11.2186 80.2815 10.9266 80.7602C10.8814 80.8344 10.8914 80.8565 10.9097 80.8925C10.9495 80.9703 11.0671 81.1031 11.3419 81.2703C11.8835 81.5999 12.8062 81.9278 14.0388 82.2405C16.4848 82.861 19.9596 83.3761 23.5241 83.7867C27.0826 84.1967 30.7084 84.5002 33.4459 84.7013C34.8143 84.8018 35.96 84.8767 36.7634 84.9264C37.0223 84.9424 37.2456 84.9558 37.4294 84.9666Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M40.4887 101.347C40.8003 100.295 40.8111 98.7295 40.5912 96.8486C40.373 94.983 39.9355 92.8655 39.395 90.7425C38.3136 86.4954 36.8315 82.2718 35.9145 80.0809C35.7368 79.6565 35.3288 79.2113 34.665 78.7727C34.0075 78.3382 33.1402 77.9375 32.1125 77.585C30.0581 76.8803 27.4258 76.3895 24.7069 76.1848C21.9885 75.9801 19.2097 76.0634 16.8631 76.4915C14.4916 76.9241 12.6625 77.6911 11.7275 78.769C9.68104 81.1284 7.36837 85.5325 5.56199 90.0273C4.66197 92.2669 3.89416 94.5128 3.35212 96.5154C2.80771 98.5267 2.49984 100.261 2.49984 101.489C2.49984 102.612 3.1031 103.889 4.23466 105.214C5.35829 106.53 6.95754 107.839 8.8358 109.019C12.597 111.382 17.3922 113.173 21.5286 113.472C25.5979 113.766 30.0085 111.872 33.602 109.284C35.3916 107.996 36.9593 106.549 38.1598 105.147C39.3673 103.737 40.1756 102.404 40.4887 101.347Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.14331 89.3428C1.37515 93.7899 -0.000156403 98.5333 -0.000156403 101.489C-0.000156403 103.472 1.03129 105.312 2.33353 106.837C3.66748 108.399 5.48294 109.865 7.50588 111.136C11.533 113.666 16.7181 115.631 21.3483 115.966C26.2471 116.32 31.2407 114.065 35.0628 111.313C37.0025 109.916 38.7198 108.337 40.0587 106.773C41.3694 105.242 42.4296 103.597 42.8857 102.057C43.2564 100.806 43.2957 99.3045 43.1922 97.8112C43.0863 96.2821 42.8168 94.5926 42.4599 92.8839C41.7625 89.546 40.7006 85.9926 39.7306 83.1565C39.5745 81.8262 39.3522 79.8688 39.0803 77.3081C38.5279 72.1053 37.6246 60.0104 37.0611 49.5752C36.4986 39.1582 36.4444 13.0258 37.0548 6.6956C37.2658 4.50742 36.5596 2.65024 35.0357 1.43258C33.6116 0.294741 31.749 -0.0635154 29.9986 0.00894491C26.5213 0.152887 22.427 2.03468 20.1116 5.36103C18.9781 6.98935 18.0085 9.32138 17.1543 12.0078C16.2887 14.7301 15.4965 17.9647 14.7722 21.4984C13.3233 28.5676 12.1211 36.9594 11.1532 45.0942C11.0672 45.817 10.9876 46.5354 10.9138 47.2487C10.2026 54.1219 10.0413 60.809 9.90775 66.3478L9.90709 66.375C9.78329 71.5088 9.67992 75.454 9.25592 77.8418C7.07792 80.6396 4.84457 85.0639 3.14331 89.3428ZM11.1244 78.7079C6.96353 83.75 1.99984 96.402 1.99984 101.489C1.99984 106.669 13.0222 113.358 21.4926 113.971C29.9629 114.583 39.6013 106.103 40.9681 101.489C42.0479 97.8443 39.7119 89.2393 37.7694 83.6031C37.6116 82.265 37.379 80.2277 37.0915 77.5192C36.5338 72.2668 35.6283 60.1329 35.064 49.6831C34.4997 39.2333 34.4392 12.9834 35.064 6.50365C35.6888 0.0238781 25.589 0.992981 21.753 6.50365C17.9171 12.0143 15.0694 29.1077 13.1392 45.3305C13.0545 46.0427 12.976 46.751 12.9032 47.4546C12.2001 54.2491 12.0404 60.8711 11.9065 66.4232C11.7772 71.7857 11.6719 76.1501 11.1244 78.7079Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
