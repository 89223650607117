import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'

export interface PeriodoUltimaAtualizacaoSelectModel {
  atualizadoHaMenosDeUmAno: boolean
  nome: string
}

export interface PeriodoUltimaAtualizacaoSelectFieldProps
  extends Omit<SelectFieldProps<PeriodoUltimaAtualizacaoSelectModel>, 'items' | 'itemToString'> {}

export function PeriodoUltimaAtualizacaoSelectField(props: PeriodoUltimaAtualizacaoSelectFieldProps) {
  const itemToString = (item: PeriodoUltimaAtualizacaoSelectModel) => item && item.nome

  return <SelectField<PeriodoUltimaAtualizacaoSelectModel> items={items} itemToString={itemToString} {...props} />
}

export const ATUALIZADO_MENOS_DE_UM_ANO: PeriodoUltimaAtualizacaoSelectModel = {
  atualizadoHaMenosDeUmAno: true,
  nome: 'Menos de um ano',
}
export const ATUALIZADO_MAIS_DE_UM_ANO: PeriodoUltimaAtualizacaoSelectModel = {
  atualizadoHaMenosDeUmAno: false,
  nome: 'Um ano ou mais',
}

const items: PeriodoUltimaAtualizacaoSelectModel[] = [ATUALIZADO_MENOS_DE_UM_ANO, ATUALIZADO_MAIS_DE_UM_ANO]
