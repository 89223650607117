import { Cell, Checkbox, Grid, Heading, HFlow, Icon, Text, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { ButtonLink } from 'components/route'
import { ProfissionalAcessosQuery } from 'graphql/types.generated'
import React, { useState } from 'react'
import Permissions from 'types/Permissions'

import { OutroAcessoCard } from './OutroAcessoCard'

export interface ProfissionalOutrosAcessosSectionProps {
  acessos: ProfissionalAcessosQuery['profissional']['acessos'][0][]
  refetch(): void
}

export const ProfissionalOutrosAcessosSection = (props: ProfissionalOutrosAcessosSectionProps) => {
  const { acessos } = props

  const [mostrarInativos, setMostrarInativos] = useState(true)
  const handleChangeMostrarInativos = (e: React.ChangeEvent<HTMLInputElement>) => setMostrarInativos(e.target.checked)

  const items = acessos.filter((item: any) => (mostrarInativos ? true : item.ativo !== false))

  return (
    <VFlow>
      <HFlow alignItems='center' justifyContent='space-between'>
        <HFlow alignItems='center'>
          <Heading level={3}>Acessos</Heading>
          <Checkbox label='Mostrar inativos' checked={mostrarInativos} onChange={handleChangeMostrarInativos} />
        </HFlow>

        <CheckPermission
          permission={Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirAcesso}
        >
          <ButtonLink kind='primary' size='small' to='acessos/create'>
            <Icon icon='plus' style={{ marginRight: '0.5rem' }} />
            Cadastrar acesso
          </ButtonLink>
        </CheckPermission>
      </HFlow>

      {items.length === 0 && <Text fontStyle='italic'>Nenhum acesso cadastrado</Text>}

      {items.length > 0 && (
        <Grid wrap>
          {items.map((acesso) => (
            <Cell key={acesso.id} xs={12} md={6}>
              <OutroAcessoCard acesso={acesso} refetch={props.refetch} />
            </Cell>
          ))}
        </Grid>
      )}
    </VFlow>
  )
}
