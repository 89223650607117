import { css } from '@emotion/core'
import { Button, Cell, Grid, HFlow, TableCell, TableRow, Text } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import { useAlert } from 'components/alert'
import { DateField, Form, FormRenderProps, TextField } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { useSalvarLoteImunobiologicoMutation } from 'graphql/hooks.generated'
import moment from 'moment'
import React from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, maxLength, nomeFabricante, nomeLote, required } from 'util/validation'

import { ImunobiologicoSelectField } from '../components/ImunobiologicoSelectField'
import { LoteImunobiologicoFabricanteSelectField } from '../components/LoteImunobiologicoFabricanteSelectField'
import { LoteImunobiologicoFormModel } from '../insert/LoteImunologicoTooltipAddForm'
import convertModelToInput from '../LoteImunoModelnputConverter'
import { covidImunosRecord, LoteImunobiologicoModel } from '../model'

interface LoteImunobiologicoEditInlineFormRowProps {
  row: LoteImunobiologicoModel
  reload(): Promise<any>
  onEditChange(): void
}

const validate = createValidator<LoteImunobiologicoFormModel>(
  {
    lote: [required, maxLength(30), nomeLote],
    fabricante: [required, maxLength(60)],
    validade: [required],
  },
  (values: LoteImunobiologicoFormModel, errors: ErrorObject<LoteImunobiologicoFormModel>) => {
    if (!errors.fabricante && values?.fabricante?.nome) {
      const errorFabricanteImunoRule = nomeFabricante(values.fabricante.nome)
      if (errorFabricanteImunoRule) {
        errors.fabricante = errorFabricanteImunoRule
      }
    }

    return errors
  }
)

export default function LoteImunobiologicoEditInlineFormRow(props: LoteImunobiologicoEditInlineFormRowProps) {
  const { row, reload, onEditChange } = props

  const alert = useAlert()

  const [salvar] = useSalvarLoteImunobiologicoMutation()

  const path = metaPath<LoteImunobiologicoFormModel>()

  const renderForm = (formProps: FormRenderProps<LoteImunobiologicoFormModel>) => {
    return (
      <TableRow style={{ height: '15rem', backgroundColor: blue.c90 }}>
        <TableCell colSpan={6}>
          <Text component='b' fontSize={1}>
            Editar lote de imunobiológico
          </Text>

          <Grid
            style={css`
              margin-top: 1rem;
            `}
          >
            <Cell md={2} lg={2}>
              <TextField name={path.lote} label='Lote' maxLength={30} required />
            </Cell>
            <Cell md={3} lg={4}>
              <LoteImunobiologicoFabricanteSelectField
                name={path.fabricante}
                label='Fabricante'
                required
                maxLength={60}
                disabled={!!covidImunosRecord[formProps?.values?.imunobiologico?.id]}
              />
            </Cell>
            <Cell md={4} lg={4}>
              <ImunobiologicoSelectField name={path.imunobiologico} label='Imunobiológico' disabled={true} semRegras />
            </Cell>
            <Cell md={3} lg={2}>
              <DateField name={path.validade} label='Data de validade' required />
            </Cell>
          </Grid>
          <HFlow justifyContent='flex-end' style={{ marginTop: '1.5rem' }}>
            <Button size='small' onClick={onEditChange}>
              Cancelar
            </Button>
            <Button size='small' kind='primary' onClick={handleSubmit(formProps)}>
              Salvar
            </Button>
          </HFlow>
        </TableCell>
      </TableRow>
    )
  }

  const handleSubmit = (formProps: FormRenderProps<LoteImunobiologicoFormModel>) => () => {
    if (
      row.lote === formProps.values?.lote &&
      row.fabricante.nome === formProps.values.fabricante?.nome &&
      row.imunobiologico.id === formProps.values.imunobiologico?.id &&
      row.validade === formProps.values?.validade
    ) {
      onEditChange()
    } else if (formProps.values?.validade && moment().isAfter(formProps.values.validade, 'date')) {
      confirm({
        title: 'Deseja confirmar a edição?',
        body:
          'A data de validade deste lote de imunobiológico está vencida e a edição deste lote irá alterar todos os registros de vacinação relacionados a ele.',
        cancelLabel: 'Cancelar',
        confirmLabel: 'Salvar',
        onConfirm: () => {
          formProps.handleSubmit()
        },
      })()
    } else {
      confirm({
        title: 'Deseja confirmar a edição?',
        body: 'A edição deste lote de imunobiológico irá alterar todos os registros de vacinação relacionados a ele.',
        cancelLabel: 'Cancelar',
        confirmLabel: 'Salvar',
        onConfirm: () => {
          formProps.handleSubmit()
        },
      })()
    }
  }

  const submitForm = (form: LoteImunobiologicoFormModel, formApi: FormApi) => {
    return salvar({ variables: { input: convertModelToInput(form) } }).then((success) => {
      alert('success', 'Lote de imunobiológico foi atualizado com sucesso.')
      setTimeout(formApi.reset)
      reload()
      onEditChange()
    })
  }

  return (
    <Form<LoteImunobiologicoFormModel>
      render={renderForm}
      onSubmit={submitForm}
      validate={validate}
      initialValues={row}
    />
  )
}
