import { SelectHelperMenuItem, Text, VFlow } from 'bold-ui'
import locale from 'bold-ui/lib/i18n/locales/pt-BR'
import { SelectField } from 'components/form'
import { HLabel } from 'components/HLabel'
import { SubstanciaAlergiaSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CategoriaSubstanciaAlergia,
  SubstanciaAlergiaSelectFieldQuery,
  SubstanciaAlergiaSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_SIZE, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type SubstanciasCbaraSelectFieldModel = SubstanciaAlergiaSelectFieldQuery['substanciasCbaraAlergia']['content'][0]

export interface SubstanciasCbaraSelectFieldProps extends AsyncSelectFieldProps<SubstanciasCbaraSelectFieldModel> {
  categoria?: CategoriaSubstanciaAlergia
}

const itemToString = (item: SubstanciasCbaraSelectFieldModel) => item?.nome

const renderItem = (item: SubstanciasCbaraSelectFieldModel) => (
  <VFlow vSpacing={0}>
    <Text fontWeight='bold'>{item.nome}</Text>
    {item.sinonimos && <HLabel title='Sinônimos:'>{item.sinonimos}</HLabel>}
  </VFlow>
)

export function SubstanciasCbaraSelectField(props: SubstanciasCbaraSelectFieldProps) {
  const { categoria, ...rest } = props

  const messageEmptyItem =
    categoria === CategoriaSubstanciaAlergia.ALIMENTO
      ? 'Digite para buscar um agente da categoria alimento.'
      : categoria === CategoriaSubstanciaAlergia.AMBIENTE
      ? 'Digite para buscar um agente da categoria ambiente.'
      : null

  const { skipping, selectProps } = useAsyncQuerySelect<
    SubstanciasCbaraSelectFieldModel,
    SubstanciaAlergiaSelectFieldQuery,
    SubstanciaAlergiaSelectFieldQueryVariables
  >({
    query: SubstanciaAlergiaSelectFieldDocument,
    extractItems: (data) => data?.substanciasCbaraAlergia?.content,
    variables: (inputQuery: string): SubstanciaAlergiaSelectFieldQueryVariables => ({
      filtro: {
        query: inputQuery,
        categoria: categoria,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['nome'],
        },
      },
    }),
    skip: (inputString) => inputString?.trim()?.length < 2,
    debounceTime: 500,
  })

  return (
    <SelectField<SubstanciasCbaraSelectFieldModel>
      renderItem={renderItem}
      itemToString={itemToString}
      components={{
        EmptyItem: () => (
          <SelectHelperMenuItem>{skipping ? messageEmptyItem : locale.select.emptyItem}</SelectHelperMenuItem>
        ),
      }}
      {...selectProps}
      {...rest}
    />
  )
}
