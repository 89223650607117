/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel, Text, VFlow } from 'bold-ui'
import { formatDate } from 'util/date/formatDate'
import { isEmpty } from 'util/validation/Util'
import { historicoTipoGlicemiaRecord } from 'view/atendimentos/detail/historico/model/model-historico'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { HistoricoSectionType } from '../../HistoricoSection'
import HistoricoDetailTable from '../../table/HistoricoDetailTable'

interface HistoricoMedicoesTableBoxProps {
  medicoes: HistoricoMedicaoModel[]
  sectionStyle: HistoricoSectionType
}

export default function HistoricoMedicoesTableBox(props: HistoricoMedicoesTableBoxProps) {
  const { medicoes, sectionStyle } = props

  return (
    <HistoricoDetailTable title='Medições' sectionStyle={sectionStyle}>
      {medicoes.map((medicao) => (
        <VFlow key={medicao.id}>
          <Grid>
            {medicao.valorPeso && (
              <Cell size={2}>
                <InfoLabel title='Peso'>{medicao.valorPeso} kg</InfoLabel>
              </Cell>
            )}
            {medicao.valorAltura && (
              <Cell size={2}>
                <InfoLabel title='Altura'>{medicao.valorAltura} cm</InfoLabel>
              </Cell>
            )}
            {medicao.valorImc && (
              <Cell size={2}>
                <InfoLabel title='IMC'>{medicao.valorImc.toFixed(2)} Kg/m²</InfoLabel>
              </Cell>
            )}
            {medicao.valorPerimetroCefalico && (
              <Cell size={2}>
                <InfoLabel title='Per. cefálico'>{medicao.valorPerimetroCefalico} cm</InfoLabel>
              </Cell>
            )}
            {!isEmpty(medicao.valorCircunferenciaAbdominal) && (
              <Cell size={2}>
                <InfoLabel title='Circ. abdominal'>{medicao.valorCircunferenciaAbdominal} cm</InfoLabel>
              </Cell>
            )}
            {medicao.perimetroPanturrilha && (
              <Cell size={2}>
                <InfoLabel title='Per. da panturrilha'>{medicao.perimetroPanturrilha} cm</InfoLabel>
              </Cell>
            )}
            {medicao.valorPressaoArterial && (
              <Cell size={2}>
                <InfoLabel title='Pressão arterial'>{medicao.valorPressaoArterial} mmHg</InfoLabel>
              </Cell>
            )}
            {!isEmpty(medicao.valorFrequenciaRespiratoria) && (
              <Cell size={2}>
                <InfoLabel title='Freq. respiratória'>{medicao.valorFrequenciaRespiratoria} mpm</InfoLabel>
              </Cell>
            )}
            {!isEmpty(medicao.valorFrequenciaCardiaca) && (
              <Cell size={2}>
                <InfoLabel title='Freq. cardíaca'>{medicao.valorFrequenciaCardiaca} bpm</InfoLabel>
              </Cell>
            )}
            {medicao.valorTemperatura && (
              <Cell size={2}>
                <InfoLabel title='Temperatura'>{medicao.valorTemperatura} ºC</InfoLabel>
              </Cell>
            )}
            {!isEmpty(medicao.valorSaturacaoO2) && (
              <Cell size={2}>
                <InfoLabel title='Saturação de 02'>{medicao.valorSaturacaoO2}%</InfoLabel>
              </Cell>
            )}
            {!isEmpty(medicao.valorVacinacaoEmDia) && (
              <Cell size={2}>
                <InfoLabel title='Vacinação em dia'>
                  <Text>{medicao.valorVacinacaoEmDia ? 'Sim' : 'Não'}</Text>
                </InfoLabel>
              </Cell>
            )}
            {!isEmpty(medicao.valorGlicemia) && (
              <Cell size={2}>
                <InfoLabel title='Glicemia capilar'>{medicao.valorGlicemia} mg/dL </InfoLabel>
              </Cell>
            )}
            {medicao.tipoGlicemia && (
              <Cell size={2}>
                <InfoLabel title='Momento da coleta (glicemia)'>
                  {historicoTipoGlicemiaRecord[medicao.tipoGlicemia]}
                </InfoLabel>
              </Cell>
            )}
            {medicao.dum && (
              <Cell size={2}>
                <InfoLabel title='Dum'>{formatDate(medicao.dum)}</InfoLabel>
              </Cell>
            )}
          </Grid>
        </VFlow>
      ))}
    </HistoricoDetailTable>
  )
}
