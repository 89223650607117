export function noKeyErrorText(errorList) {
  let errorString = ''
  errorList.forEach((element, i) => {
    if (i === 0) {
      errorString += element
    } else if (i === errorList.length - 1) {
      errorString += ' e ' + element
    } else {
      errorString += ', ' + element
    }
  })
  return errorString + ' '
}
