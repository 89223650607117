import { Link, LinkProps } from 'bold-ui'
import React from 'react'
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom'

export interface RouterLinkProps extends LinkProps {
  to: ReactRouterLinkProps['to']
  replace?: ReactRouterLinkProps['replace']
}

export function RouterLink(props: RouterLinkProps) {
  return <Link component={ReactRouterLink} {...props} />
}
