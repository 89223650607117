import { Heading, HFlow } from 'bold-ui'
import { InfoIcon } from 'components/InfoIcon'
import React from 'react'

export default function RndsTitle() {
  return (
    <Heading level={2}>
      <HFlow alignItems='center' hSpacing={0.5}>
        RNDS
        <InfoIcon
          icon='infoCircleFilled'
          text='A quantidade exibida de registros é referente às últimas 24h ou no período entre o último envio ao Centralizador Nacional. Assim que é realizado o envio ao Centralizador Nacional, a contagem é atualizada.'
        />
      </HFlow>
    </Heading>
  )
}
