import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { AtencaoDomiciliarOrigem } from 'graphql/types.generated'
import React from 'react'

export interface AtencaoDomiciliarOrigemSelectFieldProps
  extends Omit<SelectFieldProps<string>, 'items' | 'itemToString'> {}

export type AtencaoDomiciliarOrigemModel = string

export function AtencaoDomiciliarOrigemSelectField(props: AtencaoDomiciliarOrigemSelectFieldProps) {
  const itemToString = (item: AtencaoDomiciliarOrigem) => item && descriptions[item]

  return <SelectField<AtencaoDomiciliarOrigemModel> items={items} itemToString={itemToString} {...props} />
}

export const origemDescriptionByEnumType = (item) => descriptions[item]

const items = [
  AtencaoDomiciliarOrigem.ATENCAO_BASICA,
  AtencaoDomiciliarOrigem.INTERNACAO_HOSPITALAR,
  AtencaoDomiciliarOrigem.URGENCIA_EMERGENCIA,
  AtencaoDomiciliarOrigem.CACON_UNACON,
  AtencaoDomiciliarOrigem.INICIATIVA_PACIENTE_TERCEIROS,
  AtencaoDomiciliarOrigem.OUTROS,
]

export const descriptions = {
  ATENCAO_BASICA: 'Atenção Primária',
  INTERNACAO_HOSPITALAR: 'Internação hospitalar',
  URGENCIA_EMERGENCIA: 'Urgência e emergência',
  CACON_UNACON: 'CACON/UNACON',
  INICIATIVA_PACIENTE_TERCEIROS: 'Iniciativa do paciente ou terceiros',
  OUTROS: 'Outros',
}
