/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { colors } from 'bold-ui'
import { SituacaoRaizEnum } from 'graphql/types.generated'
import { useEffect, useState } from 'react'

import { CondicoesDenteFieldModel } from '../../odontograma-field/components/CondicoesDenteField'
import { OdontogramaPosicaoProtese } from '../../odontograma-field/model'

export interface CondicoesDenteProps {
  maskId: string
  value?: CondicoesDenteFieldModel
  placement?: OdontogramaPosicaoProtese
  parentRef: SVGSVGElement
}

export function CondicoesDente(props: CondicoesDenteProps) {
  const { maskId, placement, value, parentRef } = props

  const [yCoords, setYCoords] = useState<{ lesao: string; carie: string }>()

  const Rect = styled.rect`
    width: 100%;
    height: 15px;
    pointer-events: none;
  `

  useEffect(() => {
    if (parentRef) {
      const faceHeight = parentRef
        .getElementsByTagName('path')
        [parentRef.getElementsByTagName('path').length - 2]?.getBoundingClientRect().height

      setYCoords({
        carie: placement === 'inferior' ? `${faceHeight}px` : `calc(100% - ${faceHeight + 15}px)`,
        lesao: placement === 'inferior' ? `${faceHeight + 17}px` : `calc(100% - ${faceHeight + 32}px)`,
      })
    }
  }, [placement, parentRef])

  return (
    <g mask={`url(#${maskId})`}>
      {value?.includes(SituacaoRaizEnum.CARIE_DE_RAIZ_TRATADA) && (
        <Rect
          css={css`
            fill: ${colors.blue.c40};
            y: ${yCoords?.carie};
          `}
        />
      )}

      {value?.includes(SituacaoRaizEnum.CARIE_DE_RAIZ) && (
        <Rect
          css={css`
            fill: ${colors.red.c40};
            y: ${yCoords?.carie};
          `}
        />
      )}

      {value?.includes(SituacaoRaizEnum.LESAO_DE_FURCA_TRATADA) && (
        <Rect
          css={css`
            fill: ${colors.blue.c40};
            y: ${yCoords?.lesao};
          `}
        />
      )}

      {value?.includes(SituacaoRaizEnum.LESAO_DE_FURCA) && (
        <Rect
          css={css`
            fill: ${colors.red.c40};
            y: ${yCoords?.lesao};
          `}
        />
      )}
    </g>
  )
}
