import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { OrientacaoSexualEnum } from 'graphql/types.generated'
import React from 'react'

export type OrientacaoSexualValidaEnum = Exclude<OrientacaoSexualEnum, OrientacaoSexualEnum.HOMOSSEXUAL>

export interface OrientacaoSexualSelectFieldProps
  extends Omit<SelectFieldProps<OrientacaoSexualEnum>, 'items' | 'itemToString'> {}

export function OrientacaoSexualSelectField(props: OrientacaoSexualSelectFieldProps) {
  return <SelectField<OrientacaoSexualEnum> items={items} itemToString={renderItem} {...props} />
}

const renderItem = (item: OrientacaoSexualEnum) => itemToString[item]

const itemToString: Record<OrientacaoSexualValidaEnum, string> = {
  HETEROSSEXUAL: 'Heterossexual',
  GAY: 'Gay',
  LESBICA: 'Lésbica',
  ASSEXUAL: 'Assexual',
  PANSEXUAL: 'Pansexual',
  BISSEXUAL: 'Bissexual',
  OUTRO_ORIENTACAO_SEXUAL: 'Outro',
}

export const items = Object.keys(itemToString) as OrientacaoSexualEnum[]
