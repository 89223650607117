import { HFlow, Icon, Tooltip } from 'bold-ui'
import { MunicipioSelectModel } from 'components/form'
import { ProfissionalSelectField, ProfissionalSelectModel } from 'components/form/field'
import theme from 'config/theme'
import React from 'react'
import { Meta } from 'util/metaPath'

export interface ProfissionalSelectProps {
  name: Meta<ProfissionalSelectModel>
  localidade?: MunicipioSelectModel
}

export const MunicipioResponsavelProfissionalSelect = (props: ProfissionalSelectProps) => {
  const { name } = props

  return (
    <div>
      <HFlow alignItems='center' style={{ position: 'absolute', width: '23rem', height: '1.5rem', marginLeft: '6rem' }}>
        <Tooltip text='Ao ser definido como responsável receberá um acesso com perfil Administrador municipal.'>
          <Icon
            icon='infoCircleFilled'
            size={1}
            style={{
              '&:hover': {
                color: theme.pallete.primary.main,
              },
            }}
          />
        </Tooltip>
      </HFlow>

      <ProfissionalSelectField name={name} label='Responsável' mostrarSemLotacaoAtiva required />
    </div>
  )
}
