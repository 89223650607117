/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, HFlow, Icon, Theme, useTheme, VFlow } from 'bold-ui'

interface InfoNovaSenhaProps {
  style?: ExternalStyles
}

export const InfoNovaSenha = ({ style }: InfoNovaSenhaProps) => {
  const theme = useTheme()
  const { info, infoIcon } = createStyles(theme)

  return (
    <div css={css(info, style)}>
      <HFlow hSpacing={0.5}>
        <span css={infoIcon}>
          <Icon icon='infoCircleFilled' size={2.0} />
        </span>
        <VFlow vSpacing={0.1}>
          <p>A nova senha não pode ser igual à senha atual.</p>
          <p>Use pelo menos uma letra e um número.</p>
          <p>Mínimo de 8 e máximo de 20 caracteres.</p>
          <p>A nova senha não pode conter dados pessoais (data de nascimento, CPF, CNS e/ou nome).</p>
          <p>Use somente letras, números e caracteres especiais.</p>
          <p>O sistema diferencia letras maiúsculas de minúsculas.</p>
        </VFlow>
      </HFlow>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  info: css`
    background-color: ${theme.pallete.status.info.background};
    border: ${`1px solid ${theme.pallete.status.info.main}`};
    font-size: 13px;
    border-radius: 2px;
    padding: 0.5rem;
    color: ${theme.pallete.status.info.main};
  `,
  infoIcon: css`
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  `,
})
