import { useCondicoesAutorreferidasQuery } from 'graphql/hooks.generated'
import React from 'react'

import { ProntuarioCard } from '../ProntuarioCard'
import { CondicoesAutorreferidasCard } from './CondicoesAutorreferidasCard'

export interface CondicoesAutorreferidasSectionProps {
  cidadaoId: ID
}

export function CondicoesAutorreferidasSection(props: CondicoesAutorreferidasSectionProps) {
  const { cidadaoId } = props

  const {
    data: { condicoesAutorreferidas },
    loading,
  } = useCondicoesAutorreferidasQuery({
    fetchPolicy: 'cache-first',
    variables: {
      cidadaoId: cidadaoId,
    },
  })

  const canRender = condicoesAutorreferidas?.length > 0

  return (
    <ProntuarioCard
      title='Problemas/condições autorreferidas'
      tooltip={!canRender && 'Nenhum problema/condição autorreferida registrada'}
    >
      <CondicoesAutorreferidasCard
        condicoesAutorreferidas={condicoesAutorreferidas}
        loading={loading}
        canRender={canRender}
      />
    </ProntuarioCard>
  )
}
