/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Color } from 'csstype'
import { isUndefinedOrNull } from 'util/checks'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import {
  ActionEvolucaoCuidadoCompartilhadoRecord,
  ClassificacaoPrioridadeCuidadoRecord,
  CuidadoCompartilhadoEvolucaoModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { getResponsabilidadeCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

interface CuidadoCompartilhadoEvolucaoItemProps {
  evolucao: CuidadoCompartilhadoEvolucaoModel
  isFirstEvolucao: boolean
  isLastEvolucao: boolean
}

export const CuidadoCompartilhadoEvolucaoItem = (props: CuidadoCompartilhadoEvolucaoItemProps) => {
  const { evolucao, isFirstEvolucao, isLastEvolucao } = props

  const isExecutante =
    getResponsabilidadeCuidadoCompartilhado(
      evolucao.lotacao.id,
      evolucao.lotacaoExecutante?.id,
      evolucao.cuidadoCompartilhado.lotacaoSolicitante.id
    ) === 'EXECUTANTE'

  const {
    Icon,
    backgroundColor,
    getTitle,
    subtitleLabel,
    getSubtitle,
    descriptionLabel,
    getDescription,
    getBottomTextLabel,
    getBottomText,
  } = ActionEvolucaoCuidadoCompartilhadoRecord[evolucao.conduta]
  const subtitle = getSubtitle?.(evolucao)
  const description = getDescription(evolucao)
  const bottomTextLabel = getBottomTextLabel?.(evolucao)
  const bottomText = getBottomText?.(evolucao)

  const theme = useTheme()
  const styles = createStyles(theme, isExecutante, isFirstEvolucao, isLastEvolucao, backgroundColor)

  return (
    <div css={styles.wrapper}>
      <HFlow hSpacing={0.5}>
        <div css={styles.lineColumn}>
          <div css={styles.topLine} />
          <div css={styles.iconWrapper}>
            <Icon />
          </div>
          <div css={styles.bottomLine} />
        </div>
        <VFlow vSpacing={0.5} style={styles.contentColumn}>
          <VFlow vSpacing={0}>
            <HFlow hSpacing={0.25} alignItems='center'>
              {getTitle(evolucao, isFirstEvolucao)}|{' '}
              <Text color='secondary'>
                <DateTime value={evolucao.dataEvolucao} />
              </Text>
            </HFlow>
            <Text>
              {subtitleLabel && subtitle && <Text fontWeight='bold'>{subtitleLabel}: </Text>}
              {subtitle && <Text>{subtitle}</Text>}
            </Text>
            <Text>
              {descriptionLabel && description && !isFirstEvolucao && (
                <Text fontWeight='bold'>{descriptionLabel}: </Text>
              )}
              {description && <Text dangerouslySetInnerHTML={{ __html: description }} style={styles.richText} />}
            </Text>
            <Text>
              {bottomTextLabel && bottomText && <Text fontWeight='bold'>{bottomTextLabel}: </Text>}
              {bottomText && <Text style={styles.bottomText}>{bottomText}</Text>}
            </Text>
          </VFlow>
          {isFirstEvolucao && (
            <HFlow hSpacing={0.5}>
              <Text fontWeight='bold'>Classificação de prioridade inicial</Text>
              <StatusSquare
                color={ClassificacaoPrioridadeCuidadoRecord[evolucao.classificacaoPrioridade].cor}
                description={ClassificacaoPrioridadeCuidadoRecord[evolucao.classificacaoPrioridade].descricao}
              />
            </HFlow>
          )}
          {!isFirstEvolucao && !isUndefinedOrNull(evolucao.reclassificacaoPrioridade) && (
            <HFlow hSpacing={0.5}>
              <Text fontWeight='bold'>Reclassificação de prioridade</Text>
              <StatusSquare
                color={ClassificacaoPrioridadeCuidadoRecord[evolucao.reclassificacaoPrioridade].cor}
                description={ClassificacaoPrioridadeCuidadoRecord[evolucao.reclassificacaoPrioridade].descricao}
              />
            </HFlow>
          )}
        </VFlow>
      </HFlow>
    </div>
  )
}

const createStyles = (
  theme: Theme,
  isExecutante: boolean,
  isFirstEvolucao: boolean,
  isLastEvolucao: boolean,
  backgroundColor: Color
) => ({
  wrapper: css`
    padding: 0 1rem;
    background: ${backgroundColor};
  `,
  contentColumn: css`
    padding: 0.5rem 0;
    padding-top: ${isFirstEvolucao ? '0' : '0.5rem'};
    padding-bottom: ${isLastEvolucao ? '0' : '0.5rem'};
  `,
  lineColumn: css`
    display: grid;
    flex-direction: column;
    justify-items: center;
    height: 100%;
    grid-template-rows: 0.5rem 1.5rem 1fr;
  `,
  iconWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background: ${isExecutante ? theme.pallete.gray.c40 : theme.pallete.primary.c40};
    border-radius: 50%;

    svg {
      width: 1rem;
      height: 1rem;
      * {
        fill: ${theme.pallete.surface.main};
      }
    }
  `,
  icon: css`
    fill: ${theme.pallete.surface.main};
  `,
  topLine: css`
    width: 1px;
    height: inherit;
    background-color: ${theme.pallete.gray.c80};
    opacity: ${isFirstEvolucao ? '0' : '1'};
  `,
  bottomLine: css`
    width: 1px;
    height: inherit;
    background-color: ${theme.pallete.gray.c80};
    opacity: ${isLastEvolucao ? '0' : '1'};
  `,
  richText: css`
    color: ${theme.pallete.gray.c20};
    word-break: break-all;

    & p:first-child {
      display: inline;
    }
  `,
  bottomText: css`
    word-break: break-word;
  `,
})
