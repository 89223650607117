import { Observable } from 'bold-ui'
import qs from 'qs'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

interface EsusViewStoreState {
  baseUrl: string
  url: string
  show: boolean
}

class EsusViewStore extends Observable<EsusViewStoreState> {
  private stateValue: EsusViewStoreState = {
    baseUrl: '/pec/user',
    url: '/pec/user',
    show: false,
  }

  get url(): string {
    return this.stateValue.url
  }

  public setUrl = (url: string) => {
    this.stateValue = { ...this.stateValue, url }
    setTimeout(() => this.notify(this.stateValue))
  }

  get baseUrl() {
    return this.stateValue.baseUrl
  }

  public setBaseUrl = (url: string) => {
    this.stateValue = { url, baseUrl: url, show: true }
    setTimeout(() => this.notify(this.stateValue))
  }

  public hide = () => {
    this.stateValue = { baseUrl: '/pec/user', url: '/pec/user', show: false }
    setTimeout(() => this.notify(this.stateValue))
  }

  get show() {
    return this.stateValue.show
  }

  get state() {
    return this.stateValue
  }
}

export const defaultEsusViewStore = new EsusViewStore()
export const EsusViewContext = React.createContext<EsusViewStore>(defaultEsusViewStore)

export const useEsusViewState = () => {
  const esusViewContext = useContext(EsusViewContext)
  const [viewState, setViewState] = useState(esusViewContext.state)
  const location = useLocation()

  useEffect(() => {
    return esusViewContext.subscribe(setViewState)
  }, [esusViewContext])

  const urlSearch = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const uri = urlSearch.iframeUrl || viewState.url

  return {
    baseUrl: viewState.baseUrl,
    show: viewState.show,
    uri,
    hide: esusViewContext.hide,
    setBaseUrl: esusViewContext.setBaseUrl,
    setUrl: esusViewContext.setUrl,
  }
}
