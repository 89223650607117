import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { DateField, Form, FormRenderProps, TextField } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { useSalvarLoteImunobiologicoMutation } from 'graphql/hooks.generated'
import moment from 'moment'
import React from 'react'
import { metaPath } from 'util/metaPath'

import { fabricanteCalculator } from '../calculator/calculatorFabricante'
import { ImunobiologicoSelectField, ImunobiologicoSelectModel } from '../components/ImunobiologicoSelectField'
import {
  LoteImunobiologicoFabricanteSelectField,
  LoteImunobiologicoFabricanteSelectFieldModel,
} from '../components/LoteImunobiologicoFabricanteSelectField'
import convertModelToInput from '../LoteImunoModelnputConverter'
import { covidImunosRecord } from '../model'
import { formPopperValidator } from '../validator/validatorFormPopper'

export interface LoteImunobiologicoFormModel {
  id?: ID
  lote?: string
  fabricante?: LoteImunobiologicoFabricanteSelectFieldModel
  imunobiologico?: ImunobiologicoSelectModel
  validade?: LocalDate
}

export interface LoteImunobiologicoTooltipAddFormProps {
  onClose(): void
  onCancel(): void
  onSuccess(): void
}

const path = metaPath<LoteImunobiologicoFormModel>()

export default function LoteImunobiologicoTooltipAddForm(props: LoteImunobiologicoTooltipAddFormProps) {
  const { onCancel, onClose, onSuccess } = props
  const alert = useAlert()
  const errorHandler = useErrorHandler()
  const [salvar] = useSalvarLoteImunobiologicoMutation()

  const renderForm = (formProps: FormRenderProps<LoteImunobiologicoFormModel>) => {
    return (
      <>
        <Grid wrap gap={1}>
          <Cell xs={12} md={5}>
            <TextField name={path.lote} label='Lote' required maxLength={30} />
          </Cell>
          <Cell xs={12} md={7}>
            <LoteImunobiologicoFabricanteSelectField
              name={path.fabricante}
              label='Fabricante'
              required
              maxLength={60}
              disabled={!!covidImunosRecord[formProps?.values?.imunobiologico?.id]}
            />
          </Cell>
          <Cell xs={12} md={7}>
            <ImunobiologicoSelectField name={path.imunobiologico} label='Imunobiológico' required semRegras />
          </Cell>
          <Cell xs={12} md={5}>
            <DateField name={path.validade} label='Data de validade' required />
          </Cell>
        </Grid>
        <HFlow justifyContent='flex-end' style={{ marginTop: '1rem' }}>
          <Button size='small' onMouseDown={(e) => e.preventDefault()} onClick={handleClose(formProps)}>
            Fechar
          </Button>
          <Button size='small' kind='primary' onClick={handleSubmit(formProps)}>
            Adicionar
          </Button>
        </HFlow>
      </>
    )
  }

  const handleClose = (formProps: FormRenderProps) => (e) => {
    onClose()
    setTimeout(formProps.form.reset)
  }

  const validadeVencida = (formProps: FormRenderProps<LoteImunobiologicoFormModel>) => {
    if (
      formProps?.values?.lote &&
      formProps?.values?.fabricante?.nome &&
      formProps?.values?.imunobiologico?.nome &&
      formProps?.values?.validade &&
      moment().isAfter(formProps.values.validade, 'date')
    ) {
      confirm({
        title: 'Deseja confirmar o cadastro deste lote de imunobiológico?',
        body: 'A data de validade do lote de imunobiológico está vencida.',
        cancelLabel: 'Cancelar',
        confirmLabel: 'Confirmar',
        onConfirm: () => {
          formProps.handleSubmit()
        },
        onCancel: () => {
          onCancel()
        },
      })()
    } else {
      formProps.handleSubmit()
    }
  }

  const handleSubmit = (form: FormRenderProps<LoteImunobiologicoFormModel>) => () => {
    validadeVencida(form)
  }

  const submitForm = (form: LoteImunobiologicoFormModel, formApi: FormApi) => {
    return salvar({ variables: { input: convertModelToInput(form) } })
      .then((success) => {
        alert('success', 'Lote de imunobiológico foi salvo com sucesso')
        formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
        setTimeout(formApi.reset)
        onSuccess()
      })
      .catch(errorHandler)
  }

  return (
    <Form<LoteImunobiologicoFormModel>
      decorators={[fabricanteCalculator]}
      render={renderForm}
      onSubmit={submitForm}
      validate={formPopperValidator}
    />
  )
}
