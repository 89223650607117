import { TabItem, TabItemProps } from 'bold-ui'
import React from 'react'
import { useLocation } from 'react-router'
import { matchPath, NavLink, NavLinkProps } from 'react-router-dom'

export interface TabLinkProps extends TabItemProps, Pick<NavLinkProps, 'to' | 'replace' | 'exact' | 'strict'> {}

export function TabLink(props: TabLinkProps) {
  const location = useLocation()

  const path: string = props.to.toString()
  const match = matchPath(location.pathname + location.search, {
    path,
    exact: props.exact,
    strict: props.strict,
  })
  const isActive = !!match

  return <TabItem component={!props.disabled ? NavLink : 'span'} active={isActive} {...props} />
}
