import { useConfiguracaoInternetQuery } from 'graphql/hooks.generated'
import React from 'react'

import { ConfiguracaoUnificacaoBaseBox } from './ConfiguracoesUnificacaoBaseBox'

export const UnificacaoBaseBox = () => {
  const { data } = useConfiguracaoInternetQuery()
  return (
    <>
      {data?.conexao && (
        <ConfiguracaoUnificacaoBaseBox
          conexaoInternet={data.conexao.internet.habilitado}
          agendaOnlineAtiva={data.conexao.agendaOnline.ativa}
        />
      )}
    </>
  )
}
