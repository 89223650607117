import { TipoAtestadoEnum } from 'graphql/types.generated'

export const ASSINATURA_CIDADAO_TEXT = 'ASSINATURA DO CIDADÃO OU RESPONSÁVEL'

export const CID10_DESCRICAO_ATESTADO = ', com o diagnóstico segundo CID10 '

export const atestadoComCID10Text = ({ nomeCidadao, nomeProfissional }) =>
  `Eu, ${nomeCidadao.titleCase()}, autorizo o(a) Dr.(a) ${nomeProfissional} a registrar o diagnóstico codificado CID10 neste atestado.`

export const tituloAtestado = (tipoAtestado: TipoAtestadoEnum) =>
  tipoAtestado === TipoAtestadoEnum.LICENCA_MATERNIDADE ? 'LICENÇA MATERNIDADE' : 'ATESTADO'
