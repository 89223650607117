/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Tag, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Telefone } from 'components/label'
import { ResponsavelTecnico } from 'view/visualizacaoTerritorial/model'

interface ResponsavelTecnicoProps {
  responsavelTecnico: ResponsavelTecnico
}

export function ResponsavelTecnicoImovelList(props: ResponsavelTecnicoProps) {
  const {
    responsavelTecnico: { nome, cargo, cns, contato },
  } = props
  const theme = useTheme()
  const styles = createStyles(theme)
  return (
    <VFlow vSpacing={0.25} style={styles.border}>
      {nome && (
        <Tag icon='userFilled' style={styles.tag}>
          Responsável técnico
        </Tag>
      )}
      <HFlow hSpacing={0.2}>
        <Text fontWeight='bold'>{nome.titleCase()}</Text>
        <Text fontWeight='normal'>{cargo ? `| ${cargo.capitalize()}` : ''}</Text>
      </HFlow>
      <HFlow hSpacing={0.25}>
        <HLabel title='CNS' placeholder='Não informado'>
          {cns}
        </HLabel>
        <span css={styles.textFoneMargin} />
        <HLabel title='Telefone' placeholder='Não informado'>
          <Telefone value={contato} />
        </HLabel>
      </HFlow>
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  tag: css`
    color: ${theme.pallete.primary.c40};
    background-color: transparent;
    border: 1px solid;
  `,
  border: css`
    border: 1px solid ${theme.pallete.gray.c80};
    padding: 0.5rem 0.75rem;
  `,
  textFoneMargin: css`
    margin-left: 4rem;
  `,
})
