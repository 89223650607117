/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente21(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='42' height='106' viewBox='0 0 42 106' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M36.4343 71.9525C36.3951 71.8277 36.3494 71.6816 36.2977 71.5153C36.1043 70.8929 35.8268 69.9868 35.4903 68.8507C34.8173 66.5787 33.9079 63.3861 32.9619 59.7025C31.0716 52.3421 29.0281 42.9952 28.4406 35.1121C28.1473 31.1761 28.0706 27.0363 28.0242 23.0779C28.0172 22.4838 28.0109 21.894 28.0046 21.3096C27.9692 17.9934 27.9357 14.8524 27.7905 12.1029C27.619 8.8552 27.2936 6.22053 26.6562 4.5134C26.3376 3.66029 25.9632 3.10127 25.5493 2.79691C25.1622 2.51233 24.6897 2.41262 24.0501 2.58963C23.4193 2.76419 22.7375 3.25416 22.0184 4.08893C21.3041 4.91809 20.587 6.0478 19.8765 7.4299C18.4561 10.1933 17.0958 13.8992 15.8471 18.0567C13.351 26.3671 11.3231 36.404 10.1487 44.1244C9.17792 50.5063 8.47316 56.5997 7.8731 61.788C7.74678 62.8802 7.6251 63.9323 7.50655 64.9385C7.1661 67.8281 6.85092 70.3444 6.52576 72.3399C6.21492 74.2475 5.88661 75.7389 5.49375 76.6237C5.58695 76.6621 5.75025 76.7082 6.01179 76.7434C6.72819 76.8397 7.87001 76.8185 9.33462 76.6978C12.2469 76.4579 16.2847 75.8394 20.3863 75.1181C24.4838 74.3975 28.6298 73.577 31.7523 72.9365C33.3134 72.6162 34.6181 72.3411 35.5324 72.146C35.8985 72.0679 36.202 72.0026 36.4343 71.9525Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M38.0844 97.7226C38.6259 96.4872 39.0453 94.4903 39.2826 92.0608C39.5186 89.6431 39.5705 86.8378 39.3986 84.0096C39.2266 81.1805 38.8313 78.3407 38.1784 75.851C37.523 73.3522 36.6191 71.2491 35.4566 69.8556C34.3562 68.5366 32.3408 67.8167 29.7353 67.5853C27.1501 67.3556 24.0917 67.6166 21.0415 68.149C17.9953 68.6808 14.9799 69.4794 12.489 70.3113C9.98146 71.1488 8.05503 72.0039 7.15314 72.6331C6.77068 72.8999 6.34166 73.45 5.9007 74.293C5.46655 75.1231 5.04692 76.1859 4.65899 77.4163C3.88351 79.8761 3.249 82.9604 2.873 86.0912C2.49685 89.2233 2.38213 92.3796 2.63555 94.9858C2.76228 96.2891 2.97973 97.4401 3.29483 98.3755C3.612 99.3172 4.01452 99.9998 4.48398 100.413C4.95924 100.831 5.79291 101.215 6.9599 101.534C8.11357 101.849 9.53988 102.087 11.1509 102.249C14.3719 102.571 18.2855 102.581 22.138 102.304C25.992 102.027 29.7624 101.465 32.7006 100.654C34.1709 100.248 35.4144 99.7839 36.351 99.2726C37.3019 98.7535 37.8648 98.2239 38.0844 97.7226Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.03903 99.4941C1.42138 100.524 1.98122 101.541 2.83225 102.29C3.74131 103.09 5.02163 103.596 6.30081 103.945C7.63331 104.309 9.20735 104.567 10.9019 104.736C14.2956 105.076 18.3561 105.082 22.3172 104.797C26.2722 104.513 30.2176 103.933 33.3661 103.063C34.9354 102.63 36.3802 102.105 37.5489 101.467C38.6601 100.86 39.8192 99.9925 40.3742 98.7262C41.0456 97.1942 41.4843 95.0176 41.7355 92.6498C41.9913 90.2383 42.0682 87.4681 41.937 84.6594C41.6856 79.2781 40.6515 73.3336 38.3133 69.5612C36.533 63.6921 31.8602 47.3556 30.9339 34.9263C30.6037 30.4953 30.5527 25.7807 30.5047 21.2851L30.5031 21.1286C30.4451 15.6941 30.3895 10.474 29.7787 6.76115C29.4763 4.92264 28.9946 3.13928 28.0876 1.871C27.608 1.20046 26.953 0.594049 26.0745 0.260145C25.1838 -0.0784505 24.2595 -0.0621882 23.3835 0.18021C22.0735 0.542721 20.9954 1.44629 20.1245 2.45726C19.2343 3.49063 18.4133 4.80841 17.6533 6.28702C16.1316 9.24741 14.72 13.1194 13.453 17.3376C10.9137 25.7917 8.86369 35.9495 7.67735 43.7485C6.69967 50.1757 5.9903 56.3092 5.39088 61.492L5.38956 61.5034C4.84703 66.1943 4.4012 70.0396 3.9314 72.6865C3.01023 74.2831 2.23877 76.5299 1.63422 78.9453C0.972389 81.5895 0.468067 84.6118 0.204567 87.5732C-0.0580254 90.5245 -0.0880928 93.4885 0.233788 95.9974C0.39473 97.252 0.650425 98.4479 1.03903 99.4941ZM5.83381 73.4039C2.46308 78.747 0.0656319 97.1902 4.15364 100.788C8.6498 104.746 36.2485 103.157 38.5424 97.9234C40.7325 92.9261 40.7649 76.9722 36.479 70.4036C34.7652 64.7804 29.8983 47.941 28.9395 35.075C28.6037 30.5692 28.5527 25.7897 28.5049 21.3064C28.3863 10.1923 28.2871 0.898418 23.9169 2.10777C17.7839 3.80495 12.0082 28.5771 9.6546 44.0493C8.68234 50.4408 7.97638 56.5448 7.37632 61.7331L7.37629 61.7334C6.81305 66.6033 6.34311 70.6666 5.83381 73.4039Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
