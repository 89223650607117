import { Font, StyleSheet } from '@react-pdf/renderer'
import { useTheme } from 'bold-ui'
import { useMemo } from 'react'

import LatoBold from './fonts/Lato-Bold.ttf'
import LatoItalic from './fonts/Lato-Italic.ttf'
import Lato from './fonts/Lato-Regular.ttf'

export const usePrinterStyles = () => {
  const theme = useTheme()
  return useMemo(() => {
    const base = {
      fontFamily: 'Lato',
      fontSize: 10,
      color: theme.pallete.text.main,
    }

    const col = {
      flex: 1,
      borderBottom: 1,
      borderRight: 1,
    }

    const cell = {
      ...base,
      padding: 7,
      margin: 'auto',
    }

    const config = StyleSheet.create({
      page: {
        paddingTop: 85,
        paddingBottom: 40,
        paddingHorizontal: 25,
      },
      main: { ...base },
      secondary: { ...base, color: theme.pallete.text.secondary },
      disabled: { ...base, color: theme.pallete.text.disabled },
      h1: { ...base, fontFamily: 'Lato Bold', fontSize: 16 },
      h2: { ...base, fontFamily: 'Lato Bold', fontSize: 12.5 },
      h3: { ...base, fontFamily: 'Lato Bold', fontSize: 12 },
      h4: { ...base, fontFamily: 'Lato Bold', fontSize: 11 },
      h5: { ...base, fontFamily: 'Lato Bold', fontSize: 10.5 },
      h6: { ...base, fontFamily: 'Lato Bold', fontSize: 10 },
      link: {
        ...base,
        color: theme.pallete.primary.main,
        fontWeight: 'bold',
        textDecoration: 'underline',
      },
      table: {
        display: 'flex',
        alignSelf: 'center',
        width: '95%',
      },
      row: {
        flexDirection: 'row',
      },
      column: { ...col },
      simpleColumn: { flex: 1 },
      cell: { ...cell },
      headerColumn: {
        ...col,
        borderTop: 1,
        backgroundColor: theme.pallete.surface.background,
      },
      headerCell: {
        ...cell,
        fontFamily: 'Lato Bold',
      },
      value: {
        fontFamily: 'Lato Bold',
      },
    })

    const getTitleStyle = (level?: number) => {
      switch (level) {
        case 2:
          return config.h2
        case 3:
          return config.h3
        case 4:
          return config.h4
        case 5:
          return config.h5
        case 6:
          return config.h6
        default:
          return config.h1
      }
    }

    return { config, getTitleStyle }
  }, [theme.pallete])
}
Font.register({
  family: 'Lato',
  src: Lato,
})
Font.register({
  family: 'Lato Bold',
  src: LatoBold,
})
Font.register({
  family: 'Lato Italic',
  src: LatoItalic,
})

/** Valid units
 * pt (default. Based on the standard 72 dpi PDF document)
 * in inches
 * mm millimeters
 * cm centimeters
 * % percentage
 * vw viewport/page width
 * vh viewport/page height
 * refer to https://react-pdf.org/styling
 */
