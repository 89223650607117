import { Breakpoint, useTheme } from 'bold-ui'
import { useEffect, useState } from 'react'

type BreakpointType = 'up' | 'down'

const breakpointTypeCondition: Record<BreakpointType, (windowSize: number, breakpointSize: number) => boolean> = {
  up: (windowSize, breakpointSize) => windowSize > breakpointSize,
  down: (windowSize, breakpointSize) => windowSize <= breakpointSize,
}

export function useCheckBreakpoint(breakpoint: Breakpoint, type: BreakpointType) {
  const theme = useTheme()
  const size = window.innerWidth
  const breakpointSize = theme.breakpoints.size[breakpoint] - (type === 'up' ? 0 : 1)
  const [isBreakpointConditionTrue, setIsBreakpointConditionTrue] = useState(
    breakpointTypeCondition[type](size, breakpointSize)
  )

  useEffect(() => {
    const handleResize = () => {
      setIsBreakpointConditionTrue(breakpointTypeCondition[type](size, breakpointSize))
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [size, breakpointSize, type])

  return isBreakpointConditionTrue
}
