/** @jsx jsx */
import { jsx } from '@emotion/core'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { useAlert } from 'components/alert'
import { Form, FormProps } from 'components/form'
import { FormState } from 'final-form'
import { useCompartilharVideochamadaMutation } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useMemo } from 'react'

import {
  AlertVideochamadaType,
  compartilharChamadaAlertRecord,
  CompartilharVideochamadaAlertData,
} from './model-compartilharVideochamada'
import { validateCompartilharVideochamada } from './validator-compartilharVideochamada'

export interface CompartilharVideochamadaModel {
  emailDestinatario: string
}

interface CompartilharVideochamadaFormProps
  extends Omit<Partial<FormProps<CompartilharVideochamadaModel>>, 'onSubmit'> {
  videochamadaUuid: string
  emailDestinatario: string
  lotacaoAgendadaId: ID
  onAlert?(alert: CompartilharVideochamadaAlertData): void
}

//TODO (RNG): Implementar teste (#14543)
export function CompartilharVideochamadaForm(props: CompartilharVideochamadaFormProps) {
  const alert = useAlert()

  const {
    videochamadaUuid,
    emailDestinatario,
    lotacaoAgendadaId,
    onAlert = ({ type, message }) => alert(type, message),
    onSubmitSucceeded,
    onSubmitFailed,
    ...rest
  } = props
  const { analytics } = useFirebase()

  const handleSubmitSuccess = () => {
    analytics.logEvent('TELE_videochamadas_envio_email')
    onAlert(compartilharChamadaAlertRecord[AlertVideochamadaType.ENVIO_EMAIL_PROFISSIONAL_SUCESSO])
  }

  const handleSubmitFailed = (formState: FormState<CompartilharVideochamadaModel>) => {
    if (formState.hasSubmitErrors) {
      analytics.logEvent('ERR_TELE_videochamadas_envio_email')
      onAlert(compartilharChamadaAlertRecord[AlertVideochamadaType.ENVIO_EMAIL_PROFISSIONAL_ERRO])
    }
  }

  const [compartilharVideochamada] = useCompartilharVideochamadaMutation()

  const handleSubmit = ({ emailDestinatario }: CompartilharVideochamadaModel) =>
    compartilharVideochamada({
      variables: {
        input: {
          videochamadaUuid,
          emailDestinatario,
          lotacaoAgendadaId,
        },
      },
    })

  const initialValues = useMemo(() => ({ emailDestinatario }), [emailDestinatario])

  return (
    <Form<CompartilharVideochamadaModel>
      initialValues={initialValues}
      validate={validateCompartilharVideochamada}
      onSubmit={handleSubmit}
      onSubmitSucceeded={composeHandlers(handleSubmitSuccess, onSubmitSucceeded)}
      onSubmitFailed={composeHandlers(handleSubmitFailed, onSubmitFailed)}
      {...rest}
    />
  )
}
