import { firebaseConfig } from 'config/firebase'
import firebase from 'firebase'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import { useFirebaseQuery } from '../../graphql/hooks.generated'

interface AppFirebase {
  app: firebase.app.App
}

const isSupported = process.env.NODE_ENV !== 'test'
const AppFirebaseContext = React.createContext<AppFirebase>({
  app: undefined,
})

export function useFirebase() {
  const appContext = useContext(AppFirebaseContext)
  const [firebaseApp] = useState(appContext.app)

  const {
    data: { info },
  } = useFirebaseQuery({
    fetchPolicy: 'cache-first',
  })

  const initializeFirebase = useCallback(() => {
    const firebaseEnabled = isSupported && info?.firebaseEnabled && !!!firebaseApp
    if (firebaseEnabled) {
      const app = firebase.initializeApp(firebaseConfig)
      const analytics = app.analytics()
      const performance = app.performance()

      analytics.setAnalyticsCollectionEnabled(true)
      performance.dataCollectionEnabled = true
      performance.instrumentationEnabled = true

      appContext.app = app
    }
  }, [appContext.app, firebaseApp, info])

  const disableFirebase = useCallback(() => {
    if (firebaseApp) {
      firebaseApp.delete()
    }
    removeLocalStorageAndCookie()
  }, [firebaseApp])

  const analyticsEvent = useCallback(
    (eventName: string, eventParams?: { [key: string]: any }, options?: firebase.analytics.AnalyticsCallOptions) =>
      firebaseApp?.analytics().logEvent(eventName, eventParams, options),
    [firebaseApp]
  )

  const performanceTrace = useCallback((traceName: string) => firebaseApp?.performance().trace(traceName), [
    firebaseApp,
  ])

  const setUserIdLocal = useCallback(
    (id: string) => {
      firebaseApp?.analytics().setUserId(id)
    },
    [firebaseApp]
  )

  const setUserPropertiesLocal = useCallback(
    (properties: firebase.analytics.CustomParams) => {
      firebaseApp?.analytics().setUserProperties(properties)
    },
    [firebaseApp]
  )

  const analytics = useMemo(
    () => ({
      logEvent: analyticsEvent,
      setUserId: setUserIdLocal,
      setUserProperties: setUserPropertiesLocal,
    }),
    [analyticsEvent, setUserIdLocal, setUserPropertiesLocal]
  )

  const performance = useMemo(
    () => ({
      trace: performanceTrace,
    }),
    [performanceTrace]
  )

  return {
    analytics,
    performance,
    initializeFirebase,
    disableFirebase,
  }
}

const removeLocalStorageAndCookie = () => {
  window.localStorage.removeItem('@firebase/performance/config')
  window.localStorage.removeItem('@firebase/performance/configexpire')
  document.cookie = '_ga=; Max-Age=-99999999;'
  document.cookie = `_ga_${firebaseConfig.measurementId.replace('G-', '')}=; Max-Age=-99999999;`
}
