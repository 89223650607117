import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { RacionalidadeEmSaudeEnum } from 'graphql/types.generated'
import React from 'react'

export interface RacionalidadeEmSaudeSelectFieldProps
  extends Omit<SelectFieldProps<string>, 'items' | 'itemToString'> {}

export function RacionalidadeEmSaudeSelectField(props: RacionalidadeEmSaudeSelectFieldProps) {
  const itemToString = (item: RacionalidadeEmSaudeEnum) => item && descriptions[item]

  return <SelectField<RacionalidadeEmSaudeSelectModel> items={items} itemToString={itemToString} {...props} />
}

export type RacionalidadeEmSaudeSelectModel = string

const items = [
  RacionalidadeEmSaudeEnum.MEDICINA_TRADICIONAL_CHINESA,
  RacionalidadeEmSaudeEnum.ANTROPOSOFIA_APLICADA_A_SAUDE,
  RacionalidadeEmSaudeEnum.HOMEOPATIA,
  RacionalidadeEmSaudeEnum.FITOTERAPIA,
  RacionalidadeEmSaudeEnum.AYUVEDA,
  RacionalidadeEmSaudeEnum.OUTRA,
]

const descriptions = {
  MEDICINA_TRADICIONAL_CHINESA: '01 - Medicina tradicional chinesa',
  ANTROPOSOFIA_APLICADA_A_SAUDE: '02 - Antroposofia aplicada à saúde',
  HOMEOPATIA: '03 - Homeopatia',
  FITOTERAPIA: '04 - Fitoterapia',
  AYUVEDA: '05 - Ayurveda',
  OUTRA: '06 - Outra',
}
