import { Button, HeadingSection, HFlow, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Form, TextField } from 'components/form'
import { useUpdateTransmissaoLinkMutation } from 'graphql/hooks.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, required } from 'util/validation'

import { TransmissaoLinksItem } from './TransmissaoLinksDestinoTable'

export interface TransmissaoLinksModal {
  row: TransmissaoLinksItem
  open: boolean
  closeModal(): void
  updateLink(values): Promise<any>
}

const validator = createValidator<TransmissaoLinksItem>({
  name: [required],
})

export const TransmissaoLinksDestinoModal = (props: TransmissaoLinksModal) => {
  const [updateTransmissaoLinkMutation] = useUpdateTransmissaoLinkMutation()

  const handleSubmit = values => {
    return updateTransmissaoLinkMutation({ variables: { form: { id: props.row.id, name: values.name } } }).then(() => {
      alert('success', 'Link para envio editado com sucesso.')
      props.closeModal()
    })
  }

  const renderEditForm = (formProps: FormRenderProps) => {
    return (
      <Modal open={props.open} size='small' onClose={props.closeModal}>
        <ModalBody>
          <HeadingSection level={2} title='Editar link de destino'>
            <VFlow vSpacing={1}>
              <TextField name='name' label='Nome do destino' required />
              <HeadingSection level={4} title='Link para envio'>
                <Text>{props.row.hostname}</Text>
              </HeadingSection>
            </VFlow>
          </HeadingSection>
        </ModalBody>
        <ModalFooter>
          <HFlow hSpacing={1} justifyContent='flex-end'>
            <Button onClick={props.closeModal}>Cancelar</Button>
            <Button kind='primary' onClick={formProps.handleSubmit}>
              Adicionar
            </Button>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }
  return (
    <Form
      onSubmit={handleSubmit}
      render={renderEditForm}
      initialValues={{ name: props.row.name }}
      validate={validator}
    />
  )
}
