import { Form, FormDebouncedValueSpy, TextField } from 'components/form'
import React from 'react'
import { metaPath } from 'util/metaPath'

const path = metaPath<HistoricoResultadosExamesFilterModel>()

export interface HistoricoResultadosExamesFilterModel {
  texto: string
}

interface HistoricoResultadosExamesFilterProps {
  onChangeFilter(values: HistoricoResultadosExamesFilterModel): void
}

export const HistoricoResultadosExamesFilter = (props: HistoricoResultadosExamesFilterProps) => {
  const { onChangeFilter } = props

  return (
    <Form<HistoricoResultadosExamesFilterModel>
      render={() => (
        <>
          <FormDebouncedValueSpy onChange={({ values }) => onChangeFilter(values)} wait={300} />

          <TextField name={path.texto} icon='zoomOutline' placeholder='Pesquise por exame ou código' />
        </>
      )}
    />
  )
}
