import { DataTable, Heading, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { DateTime } from 'components/date'
import { PageContent } from 'components/layout/PageContent'
import { useDetalhesImportacaoCnesViewQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useParams } from 'react-router'

import { DetalhesImportacaoCnesV1 } from './v1/DetalhesImportacaoCnesV1'
import { DetalhesImportacaoCnesV1Model } from './v1/model'

export const DetalhesImportacaoCnesView = () => {
  const params = useParams<any>()
  const { data } = useDetalhesImportacaoCnesViewQuery({
    variables: {
      id: params.importacaoId,
    },
  })

  if (!data || !data.importacaoCnes) {
    return null
  }

  const detalhes: Detalhes = JSON.parse(data.importacaoCnes.detalhes)

  const stats = [
    {
      label: 'Unidades de saúde',
      new: data.importacaoCnes.unidadesSaudeNovas,
      updated: data.importacaoCnes.unidadesSaudeAtualizadas,
    },
    {
      label: 'Equipes',
      new: data.importacaoCnes.equipesNovas,
      updated: data.importacaoCnes.equipesAtualizadas,
    },
    {
      label: 'Equipes vinculadas',
      new: data.importacaoCnes.vinculacoesEquipesNovas,
      updated: data.importacaoCnes.vinculacoesEquipesAtualizadas,
    },
    {
      label: 'Profissionais',
      new: data.importacaoCnes.profissionaisNovos,
      updated: data.importacaoCnes.profissionaisAtualizados,
    },
    {
      label: 'Lotações',
      new: data.importacaoCnes.lotacoesNovas,
      updated: data.importacaoCnes.lotacoesAtualizadas,
    },
  ]

  return (
    <>
      <Breadcrumb title='Detalhes' />
      <PageContent type='filled'>
        <VFlow vSpacing={2}>
          <div>
            <Heading level={1}>Relatório de importação de CNES - {data.importacaoCnes.municipio.nome}</Heading>
            <DateTime value={data.importacaoCnes.dataImportacao} />
          </div>
          {detalhes && createViewForVersion(detalhes.versao, detalhes.content)}
          <VFlow>
            <Heading level={2}>Registros importados</Heading>
            <DataTable
              columns={[
                { name: 'label', render: (row) => row.label },
                {
                  name: 'new',
                  header: 'Novos',
                  render: (row) => row.new,
                  style: { textAlign: 'right' },
                },
                {
                  name: 'updated',
                  header: 'Atualizados',
                  render: (row) => row.updated,
                  style: { textAlign: 'right' },
                },
              ]}
              rows={stats}
            />
          </VFlow>
        </VFlow>
      </PageContent>
    </>
  )
}

const createViewForVersion = (versao: string, content: any) => {
  if (isV1(versao, content)) {
    return <DetalhesImportacaoCnesV1 detalhes={content} />
  }
  return 'View para a versão ' + versao + 'não encontrada'
}

const isV1 = (versao: string, content: any): content is DetalhesImportacaoCnesV1Model => {
  return versao === 'v1'
}

interface Detalhes {
  versao: string
  content: any
}
