import qs from 'qs'
import { useHistory, useLocation } from 'react-router'

interface QueryParams {
  callbackUrl: string
  callbackParams: string
}

export function useNavigateToCidadaoCallbackUrl() {
  const location = useLocation()
  const history = useHistory()

  const urlParams: QueryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  return (cidadaoId: ID) => {
    try {
      //callback para módulos antigos (falha quando callbackUrl não é base64) - remover try/catch quando os módulos forem refeitos
      const url = atob(urlParams.callbackUrl)
      const decodedParams = atob(urlParams.callbackParams)
      const path = url === '/pec/user/registrotardiolisting/registrotardiodetail' ? 'registroTardio' : ''
      history.push(
        `/${path}?iframeUrl=${url}?${btoa(decodedParams + '&' + qs.stringify({ cidadaoCadastradoId: cidadaoId }))}`
      )
    } catch {
      history.push(`${urlParams.callbackUrl}?cidadaoId=${cidadaoId}`)
    }
  }
}
