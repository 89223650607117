import { Heading, HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import { css } from 'emotion'
import React, { Fragment } from 'react'

interface VacinacaoModalHeaderProps {
  imunobiologicoNome: string
  imunobiologicoSigla: string
  nomeDose: string
  idadeRecomendada: string
  outrosImunobiologicos?: boolean
  labelTipoRegistro?: string
  isSubModalHeader?: boolean
  isTranscricaoCaderneta?: boolean
}

export const VacinacaoModalHeader = (props: VacinacaoModalHeaderProps) => {
  const {
    imunobiologicoNome,
    nomeDose,
    idadeRecomendada,
    imunobiologicoSigla,
    outrosImunobiologicos,
    labelTipoRegistro,
    isSubModalHeader,
    isTranscricaoCaderneta,
  } = props

  return (
    <Fragment>
      <HFlow>
        {isSubModalHeader && <Heading level={1}>{labelTipoRegistro} de imunobiológico</Heading>}
        {isTranscricaoCaderneta && (
          <Tooltip
            text='Transcrição de Caderneta / Registro Anterior: São doses que foram registradas anteriormente, mesmo que a dose da vacina não
    tenha sido administrada no mesmo ano ou no mesmo estabelecimento de saúde que está transcrevendo o registro.'
          >
            <Icon
              style={css`
                margin-top: 0.75rem;
              `}
              icon='infoCircleFilled'
              size={1}
            />
          </Tooltip>
        )}
      </HFlow>
      {!outrosImunobiologicos && (
        <VFlow vSpacing={0}>
          <HFlow alignItems='center' hSpacing={0.5}>
            <Heading level={isSubModalHeader ? 2 : 1}>{imunobiologicoSigla}</Heading>
            <Heading level={isSubModalHeader ? 3 : 2}>{nomeDose}</Heading>
            {idadeRecomendada && <Text fontSize={1}>| {idadeRecomendada.capitalize()}</Text>}
          </HFlow>
          <Text>{imunobiologicoNome}</Text>
        </VFlow>
      )}
    </Fragment>
  )
}
