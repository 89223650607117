import { Cell, Grid, HFlow, Icon, Tooltip, useStyles } from 'bold-ui'
import { Form, MunicipioSelectField, MunicipioSelectModel } from 'components/form'
import { FormState } from 'final-form'
import React from 'react'
import { FormSpy } from 'react-final-form'

import { metaPath } from '../../util/metaPath'

interface ImportacaoCnesHeaderFormProps {
  onChange(values: MunicipioSelectModel): void
}

interface ImportacaoCnesHeaderFormModel {
  municipio: MunicipioSelectModel
}

const headerFormPath = metaPath<ImportacaoCnesHeaderFormModel>()
export function ImportacaoCnesHeaderForm(props: ImportacaoCnesHeaderFormProps) {
  const { onChange } = props
  const { theme } = useStyles()

  const handleChange = (formState: FormState<ImportacaoCnesHeaderFormModel>) => handleSubmit(formState.values)

  const handleSubmit = (values: ImportacaoCnesHeaderFormModel) => onChange(values.municipio)

  const renderForm = () => (
    <Grid style={{ paddingBottom: '1rem' }}>
      <Cell size={6}>
        <HFlow alignItems='center' style={{ position: 'absolute', marginLeft: '4.125rem', paddingTop: '0.125rem' }}>
          <Tooltip text='Somente municípios ativos na instalação são apresentados.'>
            <Icon
              icon='infoCircleFilled'
              size={1}
              style={{
                '&:hover': {
                  color: theme.pallete.primary.main,
                },
              }}
            />
          </Tooltip>
        </HFlow>
        <FormSpy onChange={handleChange} subscription={{ values: true }} />
        <MunicipioSelectField label='Município' name={headerFormPath.municipio} ativo={true} />
      </Cell>
    </Grid>
  )

  return <Form<ImportacaoCnesHeaderFormModel> onSubmit={handleSubmit} render={renderForm} />
}
