import { createValidator, maxLength, required, requiredRichText, richTextMaxLength } from 'util/validation'

import { items as excludeNames } from '../components/AtestadoSelectField'
import { AtestadoModeloModel } from './AtestadoModeloModal'

export const atestadoModeloModalValidator = createValidator<AtestadoModeloModel>(
  {
    nome: [required, maxLength(40)],
    descricao: [requiredRichText, richTextMaxLength(4000)],
  },
  (values: AtestadoModeloModel, errors) => {
    if (excludeNames.some((it) => it.nome.toUpperCase() === values.nome?.toUpperCase().trim()))
      errors.nome = 'Já existe um modelo de atestado com este nome.'

    return errors
  }
)
