import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { RegistroTardioJustificativaEnum } from 'graphql/types.generated'
import React from 'react'

export interface RegistroTardioJustificativaSelectFieldProps
  extends Omit<SelectFieldProps<RegistroTardioJustificativaEnum>, 'items' | 'itemToString'> {}

export function RegistroTardioJustificativaSelectField(props: RegistroTardioJustificativaSelectFieldProps) {
  return (
    <SelectField<RegistroTardioJustificativaEnum>
      items={Object.values(RegistroTardioJustificativaEnum)}
      itemToString={renderItem}
      {...props}
    />
  )
}

const renderItem = (item: RegistroTardioJustificativaEnum) => itemToString[item]

const itemToString: Record<RegistroTardioJustificativaEnum, string> = {
  FALTA_ENERGIA_ELETRICA: 'Falta de energia elétrica',
  PEC_INDISPONIVEL: 'PEC indisponível',
  COMPUTADOR_INOPERANTE: 'Computador inoperante',
  OUTRO: 'Outros',
}
