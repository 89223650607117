import { useAlert } from 'components/alert'
import { information } from 'components/modals/information'
import { GraphQLError } from 'graphql'
import {
  useCancelarSolicitacaoEntradaVideochamadaMutation,
  useIsParticipantePermitidoVideochamadaQuery,
  useSolicitarEntradaVideochamadaMutation,
} from 'graphql/hooks.generated'
import { RespostaSolicitacaoEntradaVideochamadaEnum, SolicitarEntradaVideochamadaInput } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import useAtmosphere from 'hooks/useAtmosphere'
import { useOnBeforeUnload } from 'hooks/useOnBeforeUnload'
import { useCallback, useState } from 'react'

import {
  LocalVideocallParticipant,
  RespostaSolicitacaoVideochamadaAtmosphereResponse,
  StatusSolicitacaoEntradaVideochamadaEnum,
} from '../model-videochamada'

interface SolicitarEntradaVideochamadaOptions {
  videochamadaUuid: string
  onEntrar?(selfData: LocalVideocallParticipant): void
  isOwner?: boolean
}

interface SolicitarEntradaVideochamadaPayload {
  solicitarEntradaVideochamada(nome: string, cpf: string, termoProfissionalAceito: boolean): Promise<void>
  statusSolicitacao: StatusSolicitacaoEntradaVideochamadaEnum
}

export function useSolicitarEntradaVideochamada(
  options: SolicitarEntradaVideochamadaOptions
): SolicitarEntradaVideochamadaPayload {
  const { videochamadaUuid, onEntrar, isOwner = false } = options

  const [statusSolicitacao, setStatusSolicitacao] = useState<StatusSolicitacaoEntradaVideochamadaEnum>()
  const { analytics } = useFirebase()
  const alert = useAlert()

  const [solicitarEntrada] = useSolicitarEntradaVideochamadaMutation()
  const [cancelarSolicitacao] = useCancelarSolicitacaoEntradaVideochamadaMutation()
  const [candidateSelfData, setCandidateSelfData] = useState<LocalVideocallParticipant>()

  const { refetch: refetchIsParticipantePermitidoVideochamada } = useIsParticipantePermitidoVideochamadaQuery({
    variables: {
      input: {
        videochamadaUuid,
        participanteId: candidateSelfData?.id,
      },
    },
    skip: !candidateSelfData?.id,
  })

  const handleSetCandidateSelfData = (newVal: LocalVideocallParticipant) => !!newVal?.id && setCandidateSelfData(newVal)

  const handleRespostaSolicitacaoEntrada = async ({
    resposta,
    participanteNegadoId,
  }: RespostaSolicitacaoVideochamadaAtmosphereResponse) => {
    if (resposta === RespostaSolicitacaoEntradaVideochamadaEnum.ACEITA) {
      const minhaEntradaPermitida =
        !!candidateSelfData?.id &&
        (await refetchIsParticipantePermitidoVideochamada())?.data?.isParticipantePermitidoVideochamada

      if (minhaEntradaPermitida) {
        onEntrar?.(candidateSelfData)
        setCandidateSelfData(null)
        setStatusSolicitacao(StatusSolicitacaoEntradaVideochamadaEnum.ACEITA)
        analytics.logEvent('videochamadas_acesso_externo')
      }
    } else {
      const minhaEntradaNegada = participanteNegadoId === candidateSelfData?.id

      if (minhaEntradaNegada) {
        setCandidateSelfData(null)
        setStatusSolicitacao(convertRespostaSolicitacaoEntrada(resposta))
      }
    }
  }

  useAtmosphere<RespostaSolicitacaoVideochamadaAtmosphereResponse>({
    topic: `public/videochamada/${videochamadaUuid}/permissoesEntrada`,
    onMessage: handleRespostaSolicitacaoEntrada,
  })

  const solicitarEntradaVideochamada = useCallback(
    (nome: string, cpf: string, termoProfissionalAceito: boolean) =>
      solicitarEntrada({
        variables: {
          input: convertToInput(videochamadaUuid, nome, cpf, termoProfissionalAceito),
        },
      })
        .then(({ data: { solicitarEntradaVideochamada: selfData } }) => {
          return { ...selfData, termoProfissionalAceito }
        })
        .then((selfData) => {
          if (isOwner) {
            onEntrar(selfData)
            setStatusSolicitacao(StatusSolicitacaoEntradaVideochamadaEnum.ACEITA)
            analytics.logEvent('videochamadas_acesso_PEC')
          } else {
            handleSetCandidateSelfData(selfData)
            setStatusSolicitacao(StatusSolicitacaoEntradaVideochamadaEnum.AGUARDANDO_RESPOSTA)
          }
        })
        .catch((err) => {
          if (!handleEntrarErrors(err.graphQLErrors)) alert('danger', 'Erro ao entrar em videochamada.')
        }),
    [alert, analytics, isOwner, onEntrar, solicitarEntrada, videochamadaUuid]
  )

  const handleBeforeUnload = useCallback(
    () =>
      candidateSelfData &&
      cancelarSolicitacao({
        variables: { input: { participanteId: candidateSelfData.id, videochamadaUuid } },
        context: {
          fetchOptions: {
            keepalive: true,
          },
        },
      }),
    [cancelarSolicitacao, candidateSelfData, videochamadaUuid]
  )
  useOnBeforeUnload(handleBeforeUnload)

  return {
    solicitarEntradaVideochamada,
    statusSolicitacao,
  }
}

const handleEntrarErrors = (errors: GraphQLError[]): boolean =>
  errors?.some((error) => {
    const exceptionType = error.extensions?.classification

    switch (exceptionType) {
      case 'VideochamadaInexistenteException':
        showChamadaEncerradaInformation()
        return true

      default:
        return false
    }
  })

const showChamadaEncerradaInformation = () =>
  information({
    title: 'Chamada encerrada',
    body: 'Não é possível ingressar em chamadas que já foram encerradas.',
    showCloseButton: true,
  })()

const convertToInput = (
  videochamadaUuid: string,
  nome: string,
  cpf: string,
  termoProfissionalAceito: boolean
): SolicitarEntradaVideochamadaInput => ({
  videochamadaUuid,
  nomeParticipante: nome,
  cpfParticipante: cpf,
  termoProfissionalAceito,
})

function convertRespostaSolicitacaoEntrada(
  resposta: RespostaSolicitacaoEntradaVideochamadaEnum
): StatusSolicitacaoEntradaVideochamadaEnum {
  switch (resposta) {
    case RespostaSolicitacaoEntradaVideochamadaEnum.ACEITA:
      return StatusSolicitacaoEntradaVideochamadaEnum.ACEITA
    case RespostaSolicitacaoEntradaVideochamadaEnum.NEGADA:
      return StatusSolicitacaoEntradaVideochamadaEnum.NEGADA
    case RespostaSolicitacaoEntradaVideochamadaEnum.TIMEOUT:
      return StatusSolicitacaoEntradaVideochamadaEnum.TIMEOUT
    case RespostaSolicitacaoEntradaVideochamadaEnum.VIDEOCHAMADA_LOTADA:
      return StatusSolicitacaoEntradaVideochamadaEnum.VIDEOCHAMADA_LOTADA
    default:
      throw Error('Resposta inesperada.')
  }
}
