/* eslint-disable pec/import-graphql-hooks-from-local */

import { OperationVariables, QueryResult } from '@apollo/react-common'
import { QueryHookOptions as OptionsType, useQuery as useApolloQuery } from '@apollo/react-hooks'
import { useErrorHandler } from 'components/error'
import { DocumentNode } from 'graphql'
import { useEffect } from 'react'

// Override useQuery from @apollo/react-hooks to use custom defaults

export function useQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options: OptionsType<TData, TVariables> = {}
): QueryResult<TData, TVariables> {
  const handleError = useErrorHandler()

  const queryReturn = useApolloQuery<TData, TVariables>(query, {
    fetchPolicy: 'network-only',
    ...options,
  })

  useEffect(() => {
    if (queryReturn.error) {
      handleError(queryReturn.error)
    }
  }, [queryReturn.error, handleError])

  return {
    ...queryReturn,
    data: queryReturn.data || ({} as any),
  }
}

export type QueryHookOptions<TData = any, TVariables = OperationVariables> = OptionsType<TData, TVariables>
