/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, Grid, VFlow } from 'bold-ui'
import { CheckboxField } from 'components/form'
import { ParteBucalEnum, SituacaoFaceEnum, SituacaoRaizEnum } from 'graphql/types.generated'
import { Fragment, useEffect, useState } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import {
  getCondicaoDente,
  isDenteDeciduo,
  isDentePermanente,
  possuiLesaoDeFurca,
} from '../../../util-EvolucoesOdontologicasPlano'
import { condicoesExclusivas, condicoesPermanentesOne, condicoesPermanentesTwo } from '../model'

export type CondicoesDenteFieldModel = Array<SituacaoRaizEnum | SituacaoFaceEnum>

export interface CondicoesDenteFieldProps {
  name: MetaPath<CondicoesDenteFieldModel>
  parteBucalEnum: ParteBucalEnum
  protese?: boolean
}

export default function CondicoesDenteField(props: CondicoesDenteFieldProps) {
  const { name, parteBucalEnum, protese } = props
  const [condicoesConfig, setCondicoesConfig] = useState<{
    showLesaoDeFurca: boolean
    isPermanente: boolean
    isDeciduo: boolean
  }>()

  const {
    input: { value },
  } = useField<CondicoesDenteFieldModel>(name.absolutePath())

  useEffect(() => {
    setCondicoesConfig({
      showLesaoDeFurca: possuiLesaoDeFurca(parteBucalEnum),
      isPermanente: isDentePermanente(parteBucalEnum),
      isDeciduo: isDenteDeciduo(parteBucalEnum),
    })
  }, [parteBucalEnum])

  const getCondicaoDenteCheckboxFieldProps = (condicao: SituacaoRaizEnum | SituacaoFaceEnum) => ({
    name: name,
    label: getCondicaoDente(condicao).title,
    value: condicao,
    disabled: isDisabled(condicao, value) || protese,
  })

  return (
    <FormControl label='Problemas/condições'>
      <VFlow vSpacing={0.5}>
        <Grid gap={0.5} gapVertical={0.5}>
          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.AUSENTE)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.EXTRAIDO)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.HIGIDO)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.HIGIDO_SELADO)} />
          </Cell>

          {condicoesConfig?.isPermanente && (
            <Fragment>
              <Cell size={6} lg={4} style={styles.checkbox}>
                <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.PROTESE_PARCIAL_REMOVIVEL)} />
              </Cell>

              <Cell size={6} lg={4} style={styles.checkbox}>
                <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.PROTESE_TEMPORARIA)} />
              </Cell>
            </Fragment>
          )}

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.INCLUSO)} />
          </Cell>

          {condicoesConfig?.isDeciduo && (
            <Cell size={6} lg={4} style={styles.checkbox}>
              <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.MANTENEDOR_ESPACO)} />
            </Cell>
          )}

          <Cell size={12}>
            <hr css={styles.hr} />
          </Cell>

          {condicoesConfig?.isPermanente && (
            <Fragment>
              <Cell size={6} lg={4} style={styles.checkbox}>
                <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.PROTESE_CORONARIA_UNITARIA)} />
              </Cell>

              <Cell size={6} lg={4} style={styles.checkbox}>
                <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.COROA)} />
              </Cell>

              <Cell size={6} lg={4} style={styles.checkbox}>
                <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoRaizEnum.IMPLANTE)} />
              </Cell>

              <Cell size={6} lg={4} style={styles.checkbox}>
                <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.PILAR)} />
              </Cell>

              <Cell size={6} lg={4} style={styles.checkbox}>
                <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoRaizEnum.NUCLEO_METALICO_FUNDIDO)} />
              </Cell>
            </Fragment>
          )}

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.RESTO_RADICULAR)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.RETRACAO_GENGIVAL)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.CALCULO_DENTAL)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.EXTRACAO_INDICADA)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.SELANTE_INDICADO)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.FRATURA)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.MANCHA_BRANCA_ATIVA)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.CARIADO)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.RESTAURADO)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoRaizEnum.CARIE_DE_RAIZ)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoFaceEnum.RESTAURADO_COM_CARIE)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoRaizEnum.CARIE_DE_RAIZ_TRATADA)} />
          </Cell>

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoRaizEnum.ENDODONTIA)} />
          </Cell>

          {condicoesConfig?.isPermanente && condicoesConfig?.showLesaoDeFurca && (
            <Cell size={6} lg={4} style={styles.checkbox}>
              <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoRaizEnum.LESAO_DE_FURCA)} />
            </Cell>
          )}

          <Cell size={6} lg={4} style={styles.checkbox}>
            <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoRaizEnum.ENDODONTIA_TRATADA)} />
          </Cell>

          {condicoesConfig?.isPermanente && condicoesConfig?.showLesaoDeFurca && (
            <Cell size={6} lg={4} style={styles.checkbox}>
              <CheckboxField {...getCondicaoDenteCheckboxFieldProps(SituacaoRaizEnum.LESAO_DE_FURCA_TRATADA)} />
            </Cell>
          )}
        </Grid>
      </VFlow>
    </FormControl>
  )
}

const styles = {
  checkbox: css`
    margin: 0 -0.25rem;
  `,
  hr: css`
    margin: 0.25rem 0;
  `,
}

const isDisabled = (condicao: SituacaoFaceEnum | SituacaoRaizEnum, value: CondicoesDenteFieldModel) => {
  if (value) {
    if (value.includes(condicao)) return false

    if (value.some((v) => condicoesExclusivas.includes(v))) return true

    if (value.some((v) => condicoesPermanentesOne.includes(v)) && !condicoesPermanentesOne.includes(condicao))
      return true

    if (value.some((v) => condicoesPermanentesTwo.includes(v)) && !condicoesPermanentesTwo.includes(condicao))
      return true
  }

  return false
}
