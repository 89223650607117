export const generatePdf = (pdf: Blob, title: string) => {
  const pdfWindow: any = window.open(
    '',
    '',
    'width=' + window.screen.width * 0.8 + ', height=' + window.screen.height * 0.8
  )
  const url = pdfWindow.URL.createObjectURL(pdf, { type: 'application/pdf' })
  pdfWindow.document.write("<iframe width='100%' height='100%' frameborder='0' src='" + url + "'></iframe>")
  pdfWindow.document.title = title
}
