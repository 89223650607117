import { PrivateRoute } from 'components/route'
import { isSameDay, parseISO } from 'date-fns'
import { useMedicoesCardQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { MetaArray } from 'util/metaPath'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { HistoricoMedicoesModal } from 'view/atendimentos/detail/components/modals/medicoes/HistoricoMedicoesModal'
import { MedicaoAnteriorFormModel } from 'view/atendimentos/detail/components/modals/medicoes/MedicaoAnteriorForm'
import { MedicoesPreNatalAtendimentoModel } from 'view/atendimentos/detail/components/modals/types/HistoricoPreNatalModel'
import { PeriodoGestacaoModel } from 'view/atendimentos/detail/components/modals/types/PeriodoGestacaoModel'

import { grupoCboMedicoes } from '../../objetivo/acessos'
import { ProntuarioCard } from '../ProntuarioCard'
import { concatenarOrigemTagAnalytics } from '../util/analyticsUtils'
import convertInputToModelAndFormat from './convertInputToModelAndFormat'
import { MedicoesCard } from './MedicoesCard'
import { mergeMedicoesNoNascimento } from './mergeMedicoesNoNascimento'
import { CidadaoMedicoes } from './model'

export interface MedicoesSectionProps extends MedicoesPreNatalAtendimentoModel {
  fieldName?: MetaArray<MedicaoAnteriorFormModel>
  prontuarioId: ID
  dataAtendimento?: Instant
  cidadao: CidadaoMedicoes
  medicoesAtendimentoAtual?: MedicoesPanelModel
  gestacoes: ReadonlyArray<PeriodoGestacaoModel>
  isGestante: boolean
  isAtendimentoObservacao: boolean
  readOnly?: boolean
}

export function MedicoesSection(props: MedicoesSectionProps) {
  const { prontuarioId, dataAtendimento, isGestante, gestacoes, cidadao, isAtendimentoObservacao, readOnly } = props
  const history = useHistory()
  const { getServerTimeNow } = useServerTime()
  const match = useRouteMatch()
  const modalUrl = `${match.url}/historicoMedicoes`
  const { analytics } = useFirebase()

  const handleClick = () => {
    history.push(modalUrl)
    const tag = concatenarOrigemTagAnalytics('acessar_card_medicoes', match.url)
    analytics.logEvent(tag)
  }

  const [canRender, setCanRender] = useState(false)

  const { data, loading } = useMedicoesCardQuery({
    variables: { prontuarioId: prontuarioId, fetchMedicoesAnteriores: !readOnly, dataReferencia: dataAtendimento },
  })

  const nascimentoCidadao = Number(parseISO(cidadao.dataNascimento))
  const cidadaoNasceuHoje = isSameDay(nascimentoCidadao, getServerTimeNow())
  const ultimasMedicoesDoDia = cidadaoNasceuHoje
    ? mergeMedicoesNoNascimento(data?.ultimasMedicoesDoDia, data?.medicoesNoNascimento, nascimentoCidadao)
    : data?.ultimasMedicoesDoDia
  const ultimasMedicoesDiasAnteriores =
    !cidadaoNasceuHoje && !readOnly
      ? mergeMedicoesNoNascimento(data?.ultimasMedicoesDiasAnteriores, data?.medicoesNoNascimento, nascimentoCidadao)
      : data?.ultimasMedicoesDiasAnteriores
  const todasMedicoes = convertInputToModelAndFormat(ultimasMedicoesDoDia, ultimasMedicoesDiasAnteriores)
  const medicoesHoje = todasMedicoes.getMedicoesHoje()
  const medicoesAnterioresExpandido = todasMedicoes.getMedicoesAnterioresExpandido()
  const medicoesAnterioresAccordion = todasMedicoes.getMedicoesAnterioresAccordion()

  const tooltipMessage = readOnly
    ? medicoesHoje.length === 0
      ? 'Nenhuma medição registrada hoje'
      : 'Ver medições'
    : canRender
    ? 'Ver ou adicionar medições '
    : 'Adicionar medições anteriores'

  useEffect(() => {
    setCanRender(medicoesHoje.length !== 0 || medicoesAnterioresExpandido.length !== 0)
  }, [medicoesHoje, medicoesAnterioresExpandido])

  return (
    <>
      <PrivateRoute
        permission={grupoCboMedicoes.visualizar}
        path={modalUrl}
        render={(renderProps) =>
          readOnly ? (
            <HistoricoMedicoesModal
              {...renderProps}
              cidadao={cidadao}
              prontuarioId={prontuarioId}
              isGestante={isGestante}
              gestacoes={gestacoes}
              isAtendimentoObservacao={isAtendimentoObservacao}
            />
          ) : (
            <HistoricoMedicoesModal
              {...renderProps}
              cidadao={cidadao}
              prontuarioId={prontuarioId}
              isGestante={isGestante}
              gestacoes={gestacoes}
              dataAtendimento={dataAtendimento}
              fieldName={props.fieldName}
              medicoesAtendimentoAtual={props.medicoesAtendimentoAtual}
              preNatalAtendimentoAtual={props.preNatalAtendimentoAtual}
              dumAtendimentoAtual={props.dumAtendimentoAtual}
              resultadosExamesAtendimentoAtual={props.resultadosExamesAtendimentoAtual}
              isAtendimentoObservacao={isAtendimentoObservacao}
              editable
            />
          )
        }
      />
      <ProntuarioCard title='Medições' tooltip={tooltipMessage} onClick={handleClick}>
        {(!readOnly || medicoesHoje.length > 0) && (
          <MedicoesCard
            loading={loading}
            medicoesHoje={medicoesHoje}
            medicoesAnterioresExpandido={medicoesAnterioresExpandido}
            medicoesAnterioresAccordion={medicoesAnterioresAccordion}
            canRender={canRender}
            cidadao={cidadao}
            gestacoes={gestacoes}
            readOnly={readOnly}
          />
        )}
      </ProntuarioCard>
    </>
  )
}
