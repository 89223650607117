import createDecorator from 'final-form-calculate'
import { Cbo, CondutaCuidadoCompartilhadoEnum, Lotacao } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath, MetaRoot } from 'util/metaPath'
import {
  DiscussaoCasoFormModel,
  EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel,
  ResponsavelCuidadoCompartilhado,
  TrocaExecutanteCuidadoCompartilhadoFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { getFieldsByResponsavel } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

const getTrocaExecutanteField = (
  field: DiscussaoCasoFormModel,
  responsavelCuidado: ResponsavelCuidadoCompartilhado
): TrocaExecutanteCuidadoCompartilhadoFieldsModel => getFieldsByResponsavel(responsavelCuidado, field)?.trocaExecutante

export const dicussaoCasoCalculator = (
  path: MetaRoot<DiscussaoCasoFormModel>,
  responsavelCuidado: ResponsavelCuidadoCompartilhado
) => {
  const pathField = responsavelCuidado === 'EXECUTANTE' ? path.executanteFields : path.solicitanteFields

  return createDecorator(
    {
      field: pathField.trocaExecutante.lotacao.absolutePath(),
      updates: {
        [pathField.trocaExecutante.cbo.absolutePath()]: (value: Lotacao, allValues: DiscussaoCasoFormModel) => {
          if (!isUndefinedOrNull(value)) return value.cbo
          return getTrocaExecutanteField(allValues, responsavelCuidado)?.cbo
        },
      },
    },
    {
      field: pathField.trocaExecutante.cbo.absolutePath(),
      updates: {
        [pathField.trocaExecutante.lotacao.absolutePath()]: (
          value: Cbo,
          allValues: DiscussaoCasoFormModel,
          prevValues: DiscussaoCasoFormModel
        ) => {
          const prevTrocaExecutanteField = getTrocaExecutanteField(prevValues, responsavelCuidado)
          return isUndefinedOrNull(value) ||
            (!isEmpty(prevTrocaExecutanteField) && value?.id !== prevTrocaExecutanteField?.lotacao?.cbo?.id)
            ? null
            : getTrocaExecutanteField(allValues, responsavelCuidado)?.lotacao
        },
      },
    },
    {
      // limpa email se o cidadao nao ter essa informacao no cadastro
      field: pathField.agendamento.consultaCompartilhada.isCidadaoPresente.absolutePath(),
      updates: {
        [pathField.agendamento.consultaCompartilhada.cidadaoParticipante.contato.email.absolutePath()]: (
          value: boolean,
          allValues: DiscussaoCasoFormModel
        ) => {
          const cidadaoParticipante = getFieldsByResponsavel(responsavelCuidado, allValues).agendamento
            .consultaCompartilhada.cidadaoParticipante

          const cidadaoHasEmail = !isUndefinedOrNull(cidadaoParticipante.cidadao.contato.email)
          const newValue = cidadaoHasEmail ? cidadaoParticipante.contato?.email : null

          return !value ? newValue : cidadaoParticipante.contato.email
        },
      },
    },
    {
      // limpa telefone se o cidadao nao ter essa informacao no cadastro
      field: pathField.agendamento.consultaCompartilhada.isCidadaoPresente.absolutePath(),
      updates: {
        [pathField.agendamento.consultaCompartilhada.cidadaoParticipante.contato.telefoneCelular.absolutePath()]: (
          value: boolean,
          allValues: DiscussaoCasoFormModel
        ) => {
          const cidadaoParticipante = getFieldsByResponsavel(responsavelCuidado, allValues).agendamento
            .consultaCompartilhada.cidadaoParticipante

          const cidadaoHasCelular = !isUndefinedOrNull(cidadaoParticipante.cidadao.contato.telefoneCelular)
          const newValue = cidadaoHasCelular ? cidadaoParticipante.contato?.telefoneCelular : null

          return !value ? newValue : cidadaoParticipante.contato.telefoneCelular
        },
      },
    }
  )
}

const meta = metaPath<EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel>()

export const agendamentoConsultaCompartilhadaDecorator = [
  createDecorator({
    field: meta.isVideochamada.absolutePath(),
    updates: {
      [meta.tipoAgendamento.absolutePath()]: (
        tipoAgendamento: CondutaCuidadoCompartilhadoEnum,
        allValues: EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel
      ) => {
        if (tipoAgendamento !== CondutaCuidadoCompartilhadoEnum.AGENDADO_CONSULTA_CIDADAO_PRESENCIAL) {
          if (allValues.isVideochamada) {
            return CondutaCuidadoCompartilhadoEnum.AGENDADO_TELEINTERCONSULTA_COM_CIDADAO
          } else {
            return CondutaCuidadoCompartilhadoEnum.AGENDADO_INTERCONSULTA_COM_CIDADAO
          }
        }
      },
    },
  }),
]
