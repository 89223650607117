import { DropdownDivider, DropdownItem, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown/DropdownButton'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useAlterarAtivacaoAcessoMutation, useExcluirAcessoMutation } from 'graphql/hooks.generated'
import { ProfissionalAcessosQuery } from 'graphql/types.generated'
import * as React from 'react'

export interface OutroAcessoCardDropdownProps {
  acesso: ProfissionalAcessosQuery['profissional']['acessos'][0]
  refetch(): void
}
export const OutroAcessoCardDropdown = ({ acesso, refetch }: OutroAcessoCardDropdownProps) => {
  const alert = useAlert()
  const [alternarAtivacao] = useAlterarAtivacaoAcessoMutation()
  const [excluirAcesso] = useExcluirAcessoMutation({
    variables: { id: acesso.id },
  })
  const permissaoAtivacaoInativacao = acesso.actions.permissaoAtivacaoInativacao
  const permissaoExclusao = acesso.actions.permissaoExclusao
  const handleRejection = useErrorHandler()

  const handleAlternarAtivacao = () =>
    alternarAtivacao({ variables: { id: acesso.id } })
      .then(() => {
        alert('success', `Acesso ${(acesso as any).ativo ? 'inativado' : 'ativado'} com sucesso.`)
        refetch()
      })
      .catch(handleRejection)

  const handleExcluir = confirm({
    type: 'danger',
    title: 'Deseja excluir o acesso?',
    confirmLabel: 'Excluir',
    onConfirm: () =>
      excluirAcesso()
        .then((d) => {
          alert('success', 'Acesso excluído com sucesso.')
          refetch()
        })
        .catch(handleRejection),
  })

  const txtAtivarInativar = acesso.ativo ? 'Inativar' : 'Ativar'
  const btnAlternarAtivacao = permissaoAtivacaoInativacao.enabled ? (
    <DropdownItem onClick={handleAlternarAtivacao}>{txtAtivarInativar}</DropdownItem>
  ) : (
    <Tooltip text={permissaoAtivacaoInativacao.hint}>
      <DropdownItem onClick={handleAlternarAtivacao} disabled={true}>
        {txtAtivarInativar}
      </DropdownItem>
    </Tooltip>
  )
  const btnRemocao = permissaoExclusao.enabled ? (
    <DropdownItem onClick={handleExcluir} type='danger'>
      Excluir
    </DropdownItem>
  ) : (
    <Tooltip text={permissaoExclusao.hint}>
      <DropdownItem onClick={handleExcluir} type='danger' disabled={true}>
        Excluir
      </DropdownItem>
    </Tooltip>
  )
  return (
    <DropdownButton>
      {btnAlternarAtivacao}
      <DropdownDivider />
      {btnRemocao}
    </DropdownButton>
  )
}
