import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { createValidator, requiredRichText, richTextMaxLength } from 'util/validation'

import { odontogramaValidator } from './evolucoes-odontologicas/components/odontograma-field/validator'
import { OdontologiaModel } from './evolucoes-odontologicas/EvolucoesOdontologicasView'
import { periogramaCompletoValidator } from './evolucoes-odontologicas/periograma/periograma-completo/periograma-completo-table-field/validator'
import { periogramaSimplificadoValidator } from './evolucoes-odontologicas/periograma/periograma-simplificado/validator'
import { PeriodontiaModel } from './evolucoes-odontologicas/periograma/PeriogramaView'
import { PlanoFormModel } from './PlanoForm'
import { planoObservacaoValidator } from './validator/validatorObservacao'
import { planoProcedimentosValidator } from './validator/validatorProcedimentos'

const planoIndividualValidator = (isAtendimentoObservacao: boolean) =>
  createValidator<PlanoFormModel>(
    {
      texto: [richTextMaxLength(4000)],
      odontologia: createValidator<OdontologiaModel>({
        periodontia: createValidator<PeriodontiaModel>({
          periogramaSimplificado: periogramaSimplificadoValidator,
          periogramaCompleto: periogramaCompletoValidator,
        }),
        odontograma: odontogramaValidator,
      }),
    },
    (values, errors) => {
      if (values?.startObservacao || isAtendimentoObservacao) {
        errors.texto = requiredRichText(values?.texto) ?? richTextMaxLength(4000)(values?.texto)
      }

      return errors
    }
  )

export const planoValidator = (
  tipoAtendProf: TipoAtendimentoProfissional,
  isAtendimentoObservacao: boolean,
  isResponsavelAtendimentoObservacao: boolean
) => {
  switch (tipoAtendProf) {
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS: {
      return planoProcedimentosValidator()
    }
    case TipoAtendimentoProfissional.OBSERVACAO: {
      if (isResponsavelAtendimentoObservacao) {
        return planoIndividualValidator(isAtendimentoObservacao)
      }
      return planoObservacaoValidator()
    }
    default: {
      return planoIndividualValidator(isAtendimentoObservacao)
    }
  }
}
