import { ParteBucalEnum, SituacaoFaceEnum, SituacaoRaizEnum, TipoParteBucalEnum } from 'graphql/types.generated'

export type OdontogramaPosicaoProtese = 'superior' | 'inferior'

export type OdontogramaSetActiveRef = ({ ref }: { ref: SVGSVGElement | HTMLButtonElement }) => void

export enum OdontogramaViewMode {
  DENTES = 'dentes',
  ARCADAS = 'arcadas',
  OUTROS = 'outros',
}

export enum OdontogramaUseMode {
  INSERTING = 'inserting',
  VIEWING = 'viewing',
}

export type ParteBucalModel = {
  title: string
  type: TipoParteBucalEnum
  number?: number
  initials?: string
}

export type CondicaoDenteModel = {
  title: string
  initials?: string
}

export const partesBucal: Partial<Record<ParteBucalEnum, ParteBucalModel>> = {
  [ParteBucalEnum.ARCADA_INFERIOR]: {
    title: 'Arcada inferior',
    type: TipoParteBucalEnum.ARCADA,
  },
  [ParteBucalEnum.ARCADA_SUPERIOR]: {
    title: 'Arcada superior',
    type: TipoParteBucalEnum.ARCADA,
  },
  [ParteBucalEnum.SEXTANTE_S1]: {
    title: 'Sextante S1',
    type: TipoParteBucalEnum.SEXTANTE,
    initials: 'S1',
  },
  [ParteBucalEnum.SEXTANTE_S2]: {
    title: 'Sextante S2',
    type: TipoParteBucalEnum.SEXTANTE,
    initials: 'S2',
  },
  [ParteBucalEnum.SEXTANTE_S3]: {
    title: 'Sextante S3',
    type: TipoParteBucalEnum.SEXTANTE,
    initials: 'S3',
  },
  [ParteBucalEnum.SEXTANTE_S4]: {
    title: 'Sextante S4',
    type: TipoParteBucalEnum.SEXTANTE,
    initials: 'S4',
  },
  [ParteBucalEnum.SEXTANTE_S5]: {
    title: 'Sextante S5',
    type: TipoParteBucalEnum.SEXTANTE,
    initials: 'S5',
  },
  [ParteBucalEnum.SEXTANTE_S6]: {
    title: 'Sextante S6',
    type: TipoParteBucalEnum.SEXTANTE,
    initials: 'S6',
  },
  [ParteBucalEnum.DENTE_11]: { title: 'Dente 11', type: TipoParteBucalEnum.DENTE, number: 11 },
  [ParteBucalEnum.DENTE_12]: { title: 'Dente 12', type: TipoParteBucalEnum.DENTE, number: 12 },
  [ParteBucalEnum.DENTE_13]: { title: 'Dente 13', type: TipoParteBucalEnum.DENTE, number: 13 },
  [ParteBucalEnum.DENTE_14]: { title: 'Dente 14', type: TipoParteBucalEnum.DENTE, number: 14 },
  [ParteBucalEnum.DENTE_15]: { title: 'Dente 15', type: TipoParteBucalEnum.DENTE, number: 15 },
  [ParteBucalEnum.DENTE_16]: { title: 'Dente 16', type: TipoParteBucalEnum.DENTE, number: 16 },
  [ParteBucalEnum.DENTE_17]: { title: 'Dente 17', type: TipoParteBucalEnum.DENTE, number: 17 },
  [ParteBucalEnum.DENTE_18]: { title: 'Dente 18', type: TipoParteBucalEnum.DENTE, number: 18 },
  [ParteBucalEnum.DENTE_21]: { title: 'Dente 21', type: TipoParteBucalEnum.DENTE, number: 21 },
  [ParteBucalEnum.DENTE_22]: { title: 'Dente 22', type: TipoParteBucalEnum.DENTE, number: 22 },
  [ParteBucalEnum.DENTE_23]: { title: 'Dente 23', type: TipoParteBucalEnum.DENTE, number: 23 },
  [ParteBucalEnum.DENTE_24]: { title: 'Dente 24', type: TipoParteBucalEnum.DENTE, number: 24 },
  [ParteBucalEnum.DENTE_25]: { title: 'Dente 25', type: TipoParteBucalEnum.DENTE, number: 25 },
  [ParteBucalEnum.DENTE_26]: { title: 'Dente 26', type: TipoParteBucalEnum.DENTE, number: 26 },
  [ParteBucalEnum.DENTE_27]: { title: 'Dente 27', type: TipoParteBucalEnum.DENTE, number: 27 },
  [ParteBucalEnum.DENTE_28]: { title: 'Dente 28', type: TipoParteBucalEnum.DENTE, number: 28 },
  [ParteBucalEnum.DENTE_31]: { title: 'Dente 31', type: TipoParteBucalEnum.DENTE, number: 31 },
  [ParteBucalEnum.DENTE_32]: { title: 'Dente 32', type: TipoParteBucalEnum.DENTE, number: 32 },
  [ParteBucalEnum.DENTE_33]: { title: 'Dente 33', type: TipoParteBucalEnum.DENTE, number: 33 },
  [ParteBucalEnum.DENTE_34]: { title: 'Dente 34', type: TipoParteBucalEnum.DENTE, number: 34 },
  [ParteBucalEnum.DENTE_35]: { title: 'Dente 35', type: TipoParteBucalEnum.DENTE, number: 35 },
  [ParteBucalEnum.DENTE_36]: { title: 'Dente 36', type: TipoParteBucalEnum.DENTE, number: 36 },
  [ParteBucalEnum.DENTE_37]: { title: 'Dente 37', type: TipoParteBucalEnum.DENTE, number: 37 },
  [ParteBucalEnum.DENTE_38]: { title: 'Dente 38', type: TipoParteBucalEnum.DENTE, number: 38 },
  [ParteBucalEnum.DENTE_41]: { title: 'Dente 41', type: TipoParteBucalEnum.DENTE, number: 41 },
  [ParteBucalEnum.DENTE_42]: { title: 'Dente 42', type: TipoParteBucalEnum.DENTE, number: 42 },
  [ParteBucalEnum.DENTE_43]: { title: 'Dente 43', type: TipoParteBucalEnum.DENTE, number: 43 },
  [ParteBucalEnum.DENTE_44]: { title: 'Dente 44', type: TipoParteBucalEnum.DENTE, number: 44 },
  [ParteBucalEnum.DENTE_45]: { title: 'Dente 45', type: TipoParteBucalEnum.DENTE, number: 45 },
  [ParteBucalEnum.DENTE_46]: { title: 'Dente 46', type: TipoParteBucalEnum.DENTE, number: 46 },
  [ParteBucalEnum.DENTE_47]: { title: 'Dente 47', type: TipoParteBucalEnum.DENTE, number: 47 },
  [ParteBucalEnum.DENTE_48]: { title: 'Dente 48', type: TipoParteBucalEnum.DENTE, number: 48 },
  [ParteBucalEnum.DENTE_51]: { title: 'Dente 51', type: TipoParteBucalEnum.DENTE, number: 51 },
  [ParteBucalEnum.DENTE_52]: { title: 'Dente 52', type: TipoParteBucalEnum.DENTE, number: 52 },
  [ParteBucalEnum.DENTE_53]: { title: 'Dente 53', type: TipoParteBucalEnum.DENTE, number: 53 },
  [ParteBucalEnum.DENTE_54]: { title: 'Dente 54', type: TipoParteBucalEnum.DENTE, number: 54 },
  [ParteBucalEnum.DENTE_55]: { title: 'Dente 55', type: TipoParteBucalEnum.DENTE, number: 55 },
  [ParteBucalEnum.DENTE_61]: { title: 'Dente 61', type: TipoParteBucalEnum.DENTE, number: 61 },
  [ParteBucalEnum.DENTE_62]: { title: 'Dente 62', type: TipoParteBucalEnum.DENTE, number: 62 },
  [ParteBucalEnum.DENTE_63]: { title: 'Dente 63', type: TipoParteBucalEnum.DENTE, number: 63 },
  [ParteBucalEnum.DENTE_64]: { title: 'Dente 64', type: TipoParteBucalEnum.DENTE, number: 64 },
  [ParteBucalEnum.DENTE_65]: { title: 'Dente 65', type: TipoParteBucalEnum.DENTE, number: 65 },
  [ParteBucalEnum.DENTE_71]: { title: 'Dente 71', type: TipoParteBucalEnum.DENTE, number: 71 },
  [ParteBucalEnum.DENTE_72]: { title: 'Dente 72', type: TipoParteBucalEnum.DENTE, number: 72 },
  [ParteBucalEnum.DENTE_73]: { title: 'Dente 73', type: TipoParteBucalEnum.DENTE, number: 73 },
  [ParteBucalEnum.DENTE_74]: { title: 'Dente 74', type: TipoParteBucalEnum.DENTE, number: 74 },
  [ParteBucalEnum.DENTE_75]: { title: 'Dente 75', type: TipoParteBucalEnum.DENTE, number: 75 },
  [ParteBucalEnum.DENTE_81]: { title: 'Dente 81', type: TipoParteBucalEnum.DENTE, number: 81 },
  [ParteBucalEnum.DENTE_82]: { title: 'Dente 82', type: TipoParteBucalEnum.DENTE, number: 82 },
  [ParteBucalEnum.DENTE_83]: { title: 'Dente 83', type: TipoParteBucalEnum.DENTE, number: 83 },
  [ParteBucalEnum.DENTE_84]: { title: 'Dente 84', type: TipoParteBucalEnum.DENTE, number: 84 },
  [ParteBucalEnum.DENTE_85]: { title: 'Dente 85', type: TipoParteBucalEnum.DENTE, number: 85 },
  [ParteBucalEnum.DENTE_SUPRANUMERARIO]: { title: 'Dente Supranumerário', type: TipoParteBucalEnum.SUPRANUMERARIO },
}

export const dentesDeciduos: ParteBucalEnum[] = [
  ParteBucalEnum.DENTE_51,
  ParteBucalEnum.DENTE_52,
  ParteBucalEnum.DENTE_53,
  ParteBucalEnum.DENTE_54,
  ParteBucalEnum.DENTE_55,
  ParteBucalEnum.DENTE_61,
  ParteBucalEnum.DENTE_62,
  ParteBucalEnum.DENTE_63,
  ParteBucalEnum.DENTE_64,
  ParteBucalEnum.DENTE_65,
  ParteBucalEnum.DENTE_71,
  ParteBucalEnum.DENTE_72,
  ParteBucalEnum.DENTE_73,
  ParteBucalEnum.DENTE_74,
  ParteBucalEnum.DENTE_75,
  ParteBucalEnum.DENTE_81,
  ParteBucalEnum.DENTE_82,
  ParteBucalEnum.DENTE_83,
  ParteBucalEnum.DENTE_84,
  ParteBucalEnum.DENTE_85,
]

export const dentesComLesaoDeFurca: ParteBucalEnum[] = [
  ParteBucalEnum.DENTE_14,
  ParteBucalEnum.DENTE_15,
  ParteBucalEnum.DENTE_16,
  ParteBucalEnum.DENTE_17,
  ParteBucalEnum.DENTE_18,
  ParteBucalEnum.DENTE_24,
  ParteBucalEnum.DENTE_25,
  ParteBucalEnum.DENTE_26,
  ParteBucalEnum.DENTE_27,
  ParteBucalEnum.DENTE_28,
  ParteBucalEnum.DENTE_34,
  ParteBucalEnum.DENTE_35,
  ParteBucalEnum.DENTE_36,
  ParteBucalEnum.DENTE_37,
  ParteBucalEnum.DENTE_38,
  ParteBucalEnum.DENTE_44,
  ParteBucalEnum.DENTE_45,
  ParteBucalEnum.DENTE_46,
  ParteBucalEnum.DENTE_47,
  ParteBucalEnum.DENTE_48,
]

export const condicoesDente: Record<SituacaoRaizEnum | SituacaoFaceEnum, CondicaoDenteModel> = {
  [SituacaoRaizEnum.CARIE_DE_RAIZ]: {
    title: 'Cárie da raiz',
  },
  [SituacaoRaizEnum.CARIE_DE_RAIZ_TRATADA]: {
    title: 'Raiz restaurada',
  },
  [SituacaoRaizEnum.ENDODONTIA]: {
    title: 'Necessita de tratamento endodôntico',
  },
  [SituacaoRaizEnum.ENDODONTIA_TRATADA]: {
    title: 'Tratamento endodôntico realizado',
  },
  [SituacaoRaizEnum.IMPLANTE]: {
    title: 'Implante',
  },
  [SituacaoRaizEnum.LESAO_DE_FURCA]: {
    title: 'Possui lesão de furca',
  },
  [SituacaoRaizEnum.LESAO_DE_FURCA_TRATADA]: {
    title: 'Lesão de furca tratada',
  },
  [SituacaoRaizEnum.NUCLEO_METALICO_FUNDIDO]: {
    title: 'Núcleo (Pino)',
  },
  [SituacaoRaizEnum.NENHUM]: {
    title: 'Nenhum',
  },
  [SituacaoFaceEnum.AUSENTE]: {
    title: 'Ausente (A)',
    initials: 'A',
  },
  [SituacaoFaceEnum.CALCULO_DENTAL]: {
    title: 'Cálculo dental (Cd)',
    initials: 'Cd',
  },
  [SituacaoFaceEnum.CARIADO]: {
    title: 'Cariado (C)',
    initials: 'C',
  },
  [SituacaoFaceEnum.COROA]: {
    title: 'Coroa (Co)',
    initials: 'Co',
  },
  [SituacaoFaceEnum.EXTRACAO_INDICADA]: {
    title: 'Extração indicada (Ei)',
    initials: 'Ei',
  },
  [SituacaoFaceEnum.EXTRAIDO]: {
    title: 'Extraído (E)',
    initials: 'E',
  },
  [SituacaoFaceEnum.FRATURA]: {
    title: 'Fratura (Fr)',
    initials: 'Fr',
  },
  [SituacaoFaceEnum.HIGIDO]: {
    title: 'Hígido (H)',
    initials: 'H',
  },
  [SituacaoFaceEnum.HIGIDO_SELADO]: {
    title: 'Hígido selado (Hs)',
    initials: 'Hs',
  },
  [SituacaoFaceEnum.INCLUSO]: {
    title: 'Incluso (I)',
    initials: 'I',
  },
  [SituacaoFaceEnum.MANCHA_BRANCA_ATIVA]: {
    title: 'Mancha branca ativa (M)',
    initials: 'M',
  },
  [SituacaoFaceEnum.MANTENEDOR_ESPACO]: {
    title: 'Mantenedor de espaço (ME)',
    initials: 'ME',
  },
  [SituacaoFaceEnum.NENHUM]: {
    title: 'Nenhum',
  },
  [SituacaoFaceEnum.PILAR]: {
    title: 'Pilar (P)',
    initials: 'P',
  },
  [SituacaoFaceEnum.PROTESE_CORONARIA_UNITARIA]: {
    title: 'Prótese coronária / unitária',
  },
  [SituacaoFaceEnum.PROTESE_PARCIAL_REMOVIVEL]: {
    title: 'Prótese parcial removível',
  },
  [SituacaoFaceEnum.PROTESE_TEMPORARIA]: {
    title: 'Prótese temporária',
  },
  [SituacaoFaceEnum.PROTESE_TOTAL]: {
    title: 'Prótese total',
  },
  [SituacaoFaceEnum.RESTAURADO]: {
    title: 'Restaurado (R)',
    initials: 'R',
  },
  [SituacaoFaceEnum.RESTAURADO_COM_CARIE]: {
    title: 'Restaurado com cárie (Rc)',
    initials: 'Rc',
  },
  [SituacaoFaceEnum.RESTO_RADICULAR]: {
    title: 'Resto radicular (RR)',
    initials: 'RR',
  },
  [SituacaoFaceEnum.RETRACAO_GENGIVAL]: {
    title: 'Retração gengival (Rg)',
    initials: 'Rg',
  },
  [SituacaoFaceEnum.SELANTE_INDICADO]: {
    title: 'Selante indicado (Si)',
    initials: 'Si',
  },
}

export const condicoesExclusivas: Array<SituacaoRaizEnum | SituacaoFaceEnum> = [
  SituacaoFaceEnum.AUSENTE,
  SituacaoFaceEnum.HIGIDO,
  SituacaoFaceEnum.HIGIDO_SELADO,
  SituacaoFaceEnum.MANTENEDOR_ESPACO,
  SituacaoFaceEnum.EXTRAIDO,
  SituacaoFaceEnum.INCLUSO,
  SituacaoFaceEnum.PROTESE_PARCIAL_REMOVIVEL,
  SituacaoFaceEnum.PROTESE_TEMPORARIA,
]

// primeira lista de condições permanentes, quando selecionar uma, só permite adicionar mais do mesmo grupo
export const condicoesPermanentesOne: Array<SituacaoRaizEnum | SituacaoFaceEnum> = [
  SituacaoFaceEnum.PROTESE_CORONARIA_UNITARIA,
  SituacaoFaceEnum.PILAR,
  SituacaoRaizEnum.IMPLANTE,
  SituacaoRaizEnum.NUCLEO_METALICO_FUNDIDO,
  SituacaoFaceEnum.COROA,
]

// segunda lista de condições permanentes, possui o mesmo funcionamento da primeira
export const condicoesPermanentesTwo: Array<SituacaoRaizEnum | SituacaoFaceEnum> = [
  SituacaoFaceEnum.CALCULO_DENTAL,
  SituacaoFaceEnum.CARIADO,
  SituacaoFaceEnum.EXTRACAO_INDICADA,
  SituacaoFaceEnum.FRATURA,
  SituacaoFaceEnum.MANCHA_BRANCA_ATIVA,
  SituacaoFaceEnum.RESTAURADO,
  SituacaoFaceEnum.RESTAURADO_COM_CARIE,
  SituacaoFaceEnum.RESTO_RADICULAR,
  SituacaoFaceEnum.RETRACAO_GENGIVAL,
  SituacaoFaceEnum.SELANTE_INDICADO,
  SituacaoRaizEnum.CARIE_DE_RAIZ,
  SituacaoRaizEnum.CARIE_DE_RAIZ_TRATADA,
  SituacaoRaizEnum.ENDODONTIA,
  SituacaoRaizEnum.ENDODONTIA_TRATADA,
  SituacaoRaizEnum.LESAO_DE_FURCA,
  SituacaoRaizEnum.LESAO_DE_FURCA_TRATADA,
]

export const condicoesQueAlteramDente: Array<SituacaoRaizEnum | SituacaoFaceEnum> = [
  SituacaoFaceEnum.AUSENTE,
  SituacaoFaceEnum.EXTRAIDO,
  SituacaoFaceEnum.PROTESE_PARCIAL_REMOVIVEL,
  SituacaoFaceEnum.PROTESE_TEMPORARIA,
  SituacaoFaceEnum.PROTESE_CORONARIA_UNITARIA,
  SituacaoFaceEnum.MANTENEDOR_ESPACO,
]

export const condicoesQueDesabilitamCoroa: Array<SituacaoRaizEnum | SituacaoFaceEnum> = [
  SituacaoFaceEnum.AUSENTE,
  SituacaoFaceEnum.EXTRAIDO,
  SituacaoFaceEnum.HIGIDO,
  SituacaoFaceEnum.INCLUSO,
  SituacaoFaceEnum.PROTESE_PARCIAL_REMOVIVEL,
  SituacaoFaceEnum.PROTESE_TEMPORARIA,
  SituacaoFaceEnum.MANTENEDOR_ESPACO,
]

export const condicoesDeProtese: Array<SituacaoRaizEnum | SituacaoFaceEnum> = [
  SituacaoFaceEnum.PROTESE_PARCIAL_REMOVIVEL,
  SituacaoFaceEnum.PROTESE_TEMPORARIA,
  SituacaoFaceEnum.PROTESE_CORONARIA_UNITARIA,
]

export const condicoesDeLesaoDeFurca: Array<SituacaoRaizEnum | SituacaoFaceEnum> = [
  SituacaoRaizEnum.LESAO_DE_FURCA,
  SituacaoRaizEnum.LESAO_DE_FURCA_TRATADA,
]

export const ODONTOGRAMA_SUBMIT_ALERT_MESSAGE = 'Os registros odontológicos foram salvos corretamente.'
