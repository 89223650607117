import { SelectFieldProps } from 'components/form'
import { SelectFieldWithAddButton } from 'components/form/final-form/SelectField/SelectFieldWithAddButton'
import { useAtestadoModelosLoadQuery } from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'

import AtestadoModeloModal from '../modelo/AtestadoModeloModal'

export enum AtestadoModeloEnum {
  PADRAO,
  EM_BRANCO,
  LICENCA_MATERNIDADE,
  PERSONALIZADO,
}

export type AtestadoSelectModel = {
  tipo: AtestadoModeloEnum
  nome: string
  id?: ID
}

export interface AtestadoSelectFieldProps
  extends Omit<SelectFieldProps<AtestadoSelectModel>, 'items' | 'itemToString'> {
  canAccessLicencaMaternidade: boolean
  needRefetch: boolean
  onRefetch(): void
}

export function AtestadoSelectField(props: AtestadoSelectFieldProps) {
  const { canAccessLicencaMaternidade, needRefetch, onRefetch, ...rest } = props
  const [isModeloOpen, setModeloOpen] = useState(false)
  const { data, refetch } = useAtestadoModelosLoadQuery({ fetchPolicy: 'cache-first' })

  useEffect(() => {
    if (needRefetch) {
      refetch()
      onRefetch()
    }
  }, [refetch, onRefetch, needRefetch])

  const extractItems = () => {
    const modelosCustom = data?.atestadoModelos
      ?.map((modelo) => ({ ...modelo, tipo: AtestadoModeloEnum.PERSONALIZADO } as AtestadoSelectModel))
      .sort((a, b) => a.nome.localeCompare(b.nome))

    const modelosDefault = canAccessLicencaMaternidade
      ? items
      : items.filter((e) => e.tipo !== AtestadoModeloEnum.LICENCA_MATERNIDADE)

    return modelosCustom ? modelosDefault.concat(modelosCustom) : modelosDefault
  }

  const itemToString = (item: AtestadoSelectModel) => item?.nome

  return (
    <>
      <SelectFieldWithAddButton<AtestadoSelectModel>
        items={extractItems()}
        itemToString={itemToString}
        clearable={false}
        defaultValue={items[0] as any}
        addText={undefined}
        addButtonText='Cadastrar modelo'
        onClickAddButton={() => setModeloOpen(true)}
        {...rest}
      />
      {isModeloOpen && <AtestadoModeloModal onClose={() => setModeloOpen(false)} onUpdate={refetch} />}
    </>
  )
}

export const items: AtestadoSelectModel[] = [
  { tipo: AtestadoModeloEnum.PADRAO, nome: 'Padrão' },
  { tipo: AtestadoModeloEnum.EM_BRANCO, nome: 'Em branco' },
  { tipo: AtestadoModeloEnum.LICENCA_MATERNIDADE, nome: 'Licença maternidade' },
]
