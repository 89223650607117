/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, FormControl, Grid, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import { useState } from 'react'

import { usePecField } from '../hooks/useField'
import { TextField, TextFieldProps } from '../TextField'

interface EditableValueFieldProps extends Omit<TextFieldProps, 'style' | 'className' | 'value'> {
  valuePosition?: 'right' | 'bottom'
}

export function EditableValueField(props: EditableValueFieldProps) {
  const { name, label, disabled, required, valuePosition = 'right', ...rest } = props

  const [isEditing, setIsEditing] = useState(false)
  const {
    meta: { initial },
    tools: { resetToInitialValue },
  } = usePecField({ name })

  return !!initial && !isEditing ? (
    <VFlow vSpacing={0.25}>
      {valuePosition === 'bottom' && (
        <Text style={styles.valueLabel} fontWeight='bold'>
          {label}
        </Text>
      )}
      <div css={styles.readonlyContainer}>
        {valuePosition === 'right' && (
          <Text style={styles.valueLabel} fontWeight='bold'>
            {label}:&nbsp;
          </Text>
        )}
        <Ellipsis>{initial}</Ellipsis>
        {!disabled && (
          <Tooltip text='Editar'>
            <Button skin='ghost' size='small' onClick={() => setIsEditing(true)} style={styles.editButton}>
              <Icon size={1} icon='penOutline' />
            </Button>
          </Tooltip>
        )}
      </div>
    </VFlow>
  ) : (
    <FormControl label={label} required={required}>
      <Grid gap={1}>
        <Cell flexGrow={1}>
          <TextField name={name} disabled={disabled} {...rest} />
        </Cell>
        {initial && (
          <Cell>
            <Button
              size='small'
              kind='normal'
              onClick={() => {
                setIsEditing(false)
                resetToInitialValue()
              }}
            >
              Cancelar edição
            </Button>
          </Cell>
        )}
      </Grid>
    </FormControl>
  )
}

const styles = {
  editButton: css`
    padding: 0;
    margin-left: 0.5rem;
  `,
  readonlyContainer: css`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  `,
  valueLabel: css`
    white-space: nowrap;
  `,
}
