import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { noop } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { sortEvolucoesAlergiasByAtualizadoEm } from 'view/atendimentos/detail/soap/aside/alergias/formatAlergias'
import { Alergia, EvolucaoAlergia } from 'view/atendimentos/detail/soap/aside/types/AlergiaModel'
import { AlergiasReacoesForm } from 'view/atendimentos/detail/soap/avaliacao/components/alergias-reacoes/AlergiasReacoesForm'
import { AlergiaReacaoModel } from 'view/atendimentos/detail/soap/avaliacao/components/alergias-reacoes/model'

import { EditableRow } from '../../EditableList'
import { MainDetailAccordion } from '../../main-detail-accordion/MainDetailAccordion'
import { BodyAlergiaModal } from '../alergias/BodyAlergiaModal'
import { HeaderAlergiaModal, HeaderAlergiaModalProps } from '../alergias/HeaderAlergiaModal'

interface AccordionAlergiasModalProps extends Pick<HeaderAlergiaModalProps, 'showAlteracaoExclusao'> {
  alergias: Alergia[]
  onRemove?(alergia: AlergiaReacaoModel): void
  onEdit?(alergia: AlergiaReacaoModel): void
  findFormModel?(alergiaId: ID): AlergiaReacaoModel
}

export function AccordionAlergiasModal(props: AccordionAlergiasModalProps) {
  const { alergias, onRemove = noop, onEdit = noop, findFormModel = () => undefined, ...headerProps } = props

  const [alergiasEmEdicao, setAlergiasEmEdicao] = useState<Set<string>>(new Set<string>())

  const isEditing = useCallback((alergiaId: ID) => alergiasEmEdicao.has(alergiaId), [alergiasEmEdicao])

  const { handleAccordionItemClick, isExpanded } = useAccordionControl({ allowMultipleExpanded: true })

  const removeAlergiaEmEdicao = useCallback(
    (alergiaId: ID) => {
      alergiasEmEdicao.delete(alergiaId)
      setAlergiasEmEdicao(new Set<string>(alergiasEmEdicao))
      handleAccordionItemClick(alergiaId)
    },
    [alergiasEmEdicao, handleAccordionItemClick]
  )

  const handleSubmit = useCallback(
    (values: AlergiaReacaoModel) => {
      onEdit(values)
      removeAlergiaEmEdicao(values.alergiaId ?? values._id)
    },
    [onEdit, removeAlergiaEmEdicao]
  )

  const handleEditClick = (alergiaId: ID) => {
    if (!isEditing(alergiaId)) {
      setAlergiasEmEdicao(new Set<string>(alergiasEmEdicao.add(alergiaId)))
      !isExpanded(alergiaId) && handleAccordionItemClick(alergiaId)
    }
  }

  const renderFormBody = useCallback(
    (item: Alergia) => (
      <EditableRow editing={isEditing(item.id)}>
        <AlergiasReacoesForm
          initialValues={findFormModel(item.id)}
          edicao
          isModal
          onSubmit={handleSubmit}
          onCancel={() => removeAlergiaEmEdicao(item.id)}
          initialValuesEqual={(oldInitial, newInitial) => oldInitial._id === newInitial._id}
        />
      </EditableRow>
    ),
    [findFormModel, handleSubmit, isEditing, removeAlergiaEmEdicao]
  )

  const alergiasOrdenadas = useMemo(() => sortEvolucoesAlergiasByAtualizadoEm(alergias), [alergias])

  const formProps = useMemo(() => ({ isEditing, renderFormBody }), [isEditing, renderFormBody])

  return (
    <MainDetailAccordion<EvolucaoAlergia, Alergia>
      items={alergiasOrdenadas}
      renderHeader={(renderProps) => (
        <HeaderAlergiaModal onRemove={onRemove} onEdit={handleEditClick} {...renderProps} {...headerProps} />
      )}
      renderDetailBody={BodyAlergiaModal}
      formProps={formProps}
      onHeaderClick={handleAccordionItemClick}
      isExpanded={isExpanded}
      isHeaderDisabled={isEditing}
    />
  )
}
