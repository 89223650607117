import { Heading, HFlow, Text, VFlow } from 'bold-ui'
import AlertTreinamento from 'components/AlertTreinamento'
import { useInstalacaoHeaderQuery } from 'graphql/hooks.generated'
import logo from 'images/esus.svg'
import * as React from 'react'

export interface InstalacaoHeaderProps {
  children: React.ReactNode
}

export const InstalacaoHeader = (props: InstalacaoHeaderProps) => {
  const { children } = props

  const {
    data: { info },
  } = useInstalacaoHeaderQuery()

  return (
    <VFlow vSpacing={2}>
      <HFlow justifyContent='space-between' alignItems='flex-start' style={{ paddingTop: '2.125rem' }}>
        <img src={logo} alt='Logo e-SUS Atenção Primária' />
        <VFlow vSpacing={2} style={{ textAlign: 'center' }}>
          <div>
            <Heading level={1}>Instalação e-SUS APS PEC</Heading>
            <AlertTreinamento showProducao={true} />
          </div>
          {children}
        </VFlow>
        <VFlow vSpacing={0} style={{ textAlign: 'right' }}>
          <Text>Sistema fornecido pelo</Text>
          <Text fontWeight='bold'>Ministério da Saúde</Text>
          <Text>Versão {info && info.versao}</Text>
        </VFlow>
      </HFlow>
    </VFlow>
  )
}
