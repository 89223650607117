import {
  Button,
  Cell,
  Grid,
  HeadingSection,
  Icon,
  Modal,
  ModalBody,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  VFlow,
} from 'bold-ui'
import { DateTime } from 'components/date'
import { Cpf } from 'components/label'
import { LoadingIndicator } from 'components/loading'
import { TableBox } from 'components/table/TableBox'
import { useCidadaoAdLoadLazyQuery } from 'graphql/hooks.generated'
import { AtencaoDomiciliarCondutaDesfecho, AtencaoDomiciliarModalidade } from 'graphql/types.generated'
import React, { useState } from 'react'

import { destinoDescriptionByEnumType } from '../selectFields/AtencaoDomiciliarCondutaDesfechoSelectField'
import { modalidadeDescriptionByEnumType } from '../selectFields/AtencaoDomiciliarModalidadeSelectField'
import { origemDescriptionByEnumType } from '../selectFields/AtencaoDomiciliarOrigemSelectField'
import { relacaoCuidadorDescriptionByEnumType } from '../selectFields/RelacaoCidadaoCuidadorSelectField'

export interface CidadaoADDetailButtonViewProps {
  cidadaoADId: string
}

export default function CidadaoADDetailButtonView(props: CidadaoADDetailButtonViewProps) {
  const { cidadaoADId } = props

  const [modalOpen, setModalOpen] = useState(false)

  const [executeQuery, { data, loading }] = useCidadaoAdLoadLazyQuery()

  const onVisualizarClick = () => {
    executeQuery({ variables: { input: cidadaoADId } })
    setModalOpen(true)
  }

  const cidadaoAD = data?.cidadaoAD

  const renderCidadaosEmPosObito = () => {
    return (
      <Cell size={12}>
        <HeadingSection level={2} title='Cidadãos em pós-obito' />
        <TableBox>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Cidadão</TableHeader>
                <TableHeader>CPF/CNS</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {cidadaoAD?.cidadaosAdEmPosObito?.map((items) => {
                return (
                  <TableRow key={items.id}>
                    <TableCell> {items?.cidadao?.nomeSocial || items.cidadao?.nome || '—'} </TableCell>
                    <TableCell>
                      {items.cidadao?.cpf ? <Cpf value={items.cidadao?.cpf} /> : items.cidadao?.cns}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableBox>
      </Cell>
    )
  }

  const renderCuidador = () => {
    return (
      <Grid wrap>
        <Cell size={12}>
          <VFlow vSpacing={0}>
            <Text component='b'>Nome</Text>
            {cidadaoAD?.cidadao?.nomeCuidador || '—'}
          </VFlow>
        </Cell>
        <Cell size={5}>
          <VFlow vSpacing={0}>
            <Text component='b'>Data de nascimento</Text>
            {cidadaoAD?.cidadao?.dtNascimentoCuidador ? (
              <DateTime format='DD/MM/YYYY' value={cidadaoAD?.cidadao?.dtNascimentoCuidador} />
            ) : (
              '—'
            )}
          </VFlow>
        </Cell>
        <Cell size={7}>
          <VFlow vSpacing={0}>
            <Text component='b'>CPF/CNS</Text>
            {cidadaoAD?.cidadao?.cpfCnsCuidador || '—'}
          </VFlow>
        </Cell>
        <Cell>
          <VFlow vSpacing={0}>
            <Text component='b'>Relação com o cidadão</Text>
            {relacaoCuidadorDescriptionByEnumType(cidadaoAD?.cidadao?.tipoCuidador) || '—'}
          </VFlow>
        </Cell>
      </Grid>
    )
  }

  return (
    <>
      <Tooltip text='Visualizar'>
        <Button size='small' skin='ghost' onClick={onVisualizarClick}>
          <Icon icon='zoomOutline' />
        </Button>
      </Tooltip>

      <Modal style={{ width: '46.5rem', height: '58.5rem' }} onClose={() => setModalOpen(false)} open={modalOpen}>
        <ModalBody>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <Grid wrap>
              <Cell size={12}>
                <HeadingSection level={1} vSpace={-2} title='Cidadão na atenção domiciliar' />
                {cidadaoAD?.cidadao?.nomeSocial || cidadaoAD?.cidadao?.nome || '—'}
              </Cell>
              <Cell size={12} />
              <Cell size={5}>
                <VFlow vSpacing={0}>
                  <Text component='b'>Data de admissão</Text>
                  {<DateTime format='DD/MM/YYYY' value={cidadaoAD?.dataAdmissao} /> || '—'}
                </VFlow>
              </Cell>
              <Cell size={7}>
                <VFlow vSpacing={0}>
                  <Text component='b'>Modalidade</Text>
                  {modalidadeDescriptionByEnumType(cidadaoAD?.modalidade) || '—'}
                </VFlow>
              </Cell>
              {cidadaoAD?.modalidade === AtencaoDomiciliarModalidade.POS_OBITO && (
                <Cell size={12}>
                  <VFlow vSpacing={0}>
                    <Text component='b'>Cidadão em óbito</Text>
                    {cidadaoAD?.cidadaoAdEmObito?.cidadao?.nomeSocial ||
                      cidadaoAD?.cidadaoAdEmObito?.cidadao?.nome ||
                      '—'}
                  </VFlow>
                </Cell>
              )}
              <Cell size={5}>
                <VFlow vSpacing={0}>
                  <Text component='b'>Procedência</Text>
                  {origemDescriptionByEnumType(cidadaoAD?.procedencia) || '—'}
                </VFlow>
              </Cell>
              <Cell size={7}>
                <VFlow vSpacing={0}>
                  <Text component='b'>Especifique</Text>
                  {cidadaoAD?.procedenciaEspecificacao || '—'}
                </VFlow>
              </Cell>
              <Cell size={12}>
                <VFlow vSpacing={0}>
                  <Text component='b'>CID10 Principal</Text>
                  {cidadaoAD?.cid10Principal?.nome || '—'}
                </VFlow>
              </Cell>
              <Cell size={12}>
                <VFlow vSpacing={0}>
                  <Text component='b'>CID10 Secundário 1</Text>
                  {cidadaoAD?.cid10Secundario1?.nome || '—'}
                </VFlow>
              </Cell>
              <Cell size={12}>
                <VFlow vSpacing={0}>
                  <Text component='b'>CID10 Secundário 2</Text>
                  {cidadaoAD?.cid10Secundario2?.nome || '—'}
                </VFlow>
              </Cell>
              <Cell size={5}>
                <VFlow vSpacing={0}>
                  <Text component='b'>Conduta/Desfecho</Text>
                  {destinoDescriptionByEnumType(cidadaoAD?.condutaDesfecho) || '—'}
                </VFlow>
              </Cell>
              {cidadaoAD?.condutaDesfecho &&
                cidadaoAD?.condutaDesfecho !== AtencaoDomiciliarCondutaDesfecho.PERMANENCIA && (
                  <Cell size={7}>
                    <VFlow vSpacing={0}>
                      <Text component='b'>Data de saída</Text>
                      {cidadaoAD?.dataSaida ? <DateTime format='DD/MM/YYYY' value={cidadaoAD?.dataSaida} /> : '—'}
                    </VFlow>
                  </Cell>
                )}
              {cidadaoAD?.condutaDesfecho && cidadaoAD?.condutaDesfecho === AtencaoDomiciliarCondutaDesfecho.OBITO && (
                <>
                  <Cell size={5}>
                    <VFlow vSpacing={0}>
                      <Text component='b'>Número da declaração de óbito</Text>
                      {cidadaoAD?.numeroDocumentoObito || '—'}
                    </VFlow>
                  </Cell>
                  <Cell size={7}>
                    <VFlow vSpacing={0}>
                      <Text component='b'>Data de óbito</Text>
                      {cidadaoAD?.dataObito ? <DateTime format='DD/MM/YYYY' value={cidadaoAD?.dataObito} /> : '—'}
                    </VFlow>
                  </Cell>
                </>
              )}
              <Cell size={12} />

              <Cell size={12}>
                <HeadingSection level={2} title='Responsável' vSpace={0.5} />
              </Cell>
              <Cell size={12}>
                <VFlow vSpacing={0}>
                  <Text component='b'>Nome</Text>
                  {cidadaoAD?.cidadao?.nomeResponsavel || '—'}
                </VFlow>
              </Cell>
              <Cell size={5}>
                <VFlow vSpacing={0}>
                  <Text component='b'>Data de nascimento</Text>
                  {cidadaoAD?.cidadao.dtNascimentoResponsavel ? (
                    <DateTime format='DD/MM/YYYY' value={cidadaoAD?.cidadao.dtNascimentoResponsavel} />
                  ) : (
                    '—'
                  )}
                </VFlow>
              </Cell>
              <Cell size={7}>
                <VFlow vSpacing={0}>
                  <Text component='b'>CPF/CNS</Text>
                  {cidadaoAD?.cidadao?.cpfCnsResponsavel || '—'}
                </VFlow>
              </Cell>
              <Cell size={12} />

              <Cell size={12}>
                <HeadingSection level={2} title='Cuidador' />
                {cidadaoAD?.cidadao?.naoPossuiCuidador ? (
                  <Text component='i'>Não possui cuidador</Text>
                ) : (
                  renderCuidador()
                )}
              </Cell>

              <Cell size={12} />
              {cidadaoAD?.condutaDesfecho === AtencaoDomiciliarCondutaDesfecho.OBITO &&
                cidadaoAD?.cidadaosAdEmPosObito?.length > 0 &&
                renderCidadaosEmPosObito()}
            </Grid>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}
