/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon } from 'bold-ui'
import { Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { FormApi } from 'final-form'
import { metaPath } from 'util/metaPath'

import { HospitalarAntecedentesPopper } from './HospitalarAntecedentesPopper'
import { CirurgiaInternacaoModel, HospitalarAntecedentesFilterModel } from './model'

interface HospitalarAntecedentesTableHeaderProps extends HospitalarAntecedentesFilterProps {
  dataNascimentoCidadao: LocalDate
  onSubmit(value: CirurgiaInternacaoModel, formApi: FormApi)
}

interface HospitalarAntecedentesFilterProps {
  onChangeFilter(values: HospitalarAntecedentesFilterModel): void
}

const path = metaPath<HospitalarAntecedentesFilterModel>()

export const HospitalarAntecedentesTableHeader = (props: HospitalarAntecedentesTableHeaderProps) => {
  const { dataNascimentoCidadao, onSubmit, onChangeFilter } = props
  const classes = createStyles()

  const renderPopper = ({ close }: PopperControls) => (
    <HospitalarAntecedentesPopper close={close} onSubmit={onSubmit} dataNascimentoCidadao={dataNascimentoCidadao} />
  )

  const renderForm = () => {
    return (
      <HFlow alignItems='center' justifyContent='space-between'>
        <FormDebouncedValueSpy onChange={({ values }) => onChangeFilter(values)} />
        <TextField
          name={path.texto}
          placeholder='Pesquise por cirurgia ou internação'
          icon='zoomOutline'
          style={classes.searchField}
        />
        <PopperButton kind='primary' size='small' placement='bottom-end' renderPopper={renderPopper}>
          <Icon icon='plus' style={classes.popperButtonIcon} />
          Adicionar cirurgia e/ou internação
        </PopperButton>
      </HFlow>
    )
  }

  return <Form<CirurgiaInternacaoModel> render={renderForm} />
}

const createStyles = () => ({
  searchField: css`
    width: 18rem;
  `,
  popperButtonIcon: css`
    margin-right: 0.5rem;
  `,
})
