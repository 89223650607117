/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Checkbox, Grid, Heading, HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { ButtonLink } from 'components/route/ButtonLink'
import React, { useState } from 'react'
import Permissions from 'types/Permissions'

import { EstagioModel } from '../model'
import { EstagioCard } from './EstagioCard'

export interface ProfissionalEstagioSectionProps {
  estagio: EstagioModel[]
  possuiCns: boolean
  refetch(): void
}

export const ProfissionalLotacoesEstagioSection = (props: ProfissionalEstagioSectionProps) => {
  const { estagio, possuiCns, refetch } = props

  const [mostrarInativos, setMostrarInativos] = useState(true)

  const handleChangeMostrarInativos = (e: React.ChangeEvent<HTMLInputElement>) => setMostrarInativos(e.target.checked)

  const items = mostrarInativos ? estagio : estagio?.filter((item) => item.ativo)

  return (
    <VFlow>
      <HFlow justifyContent='space-between'>
        <HFlow alignItems='center'>
          <Heading level={3}>Lotações de estágio</Heading>
          <Checkbox label='Mostrar inativas' checked={mostrarInativos} onChange={handleChangeMostrarInativos} />
        </HFlow>

        <CheckPermission
          permission={Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirLotacao}
        >
          <Tooltip text={!possuiCns && 'Profissional sem CNS não pode ter lotação de estágio.'}>
            <ButtonLink to='lotacoesEstagio/create' size='small' kind='primary' disabled={!possuiCns}>
              <Icon icon='plus' style={styles.icon} />
              Cadastrar lotação de estágio
            </ButtonLink>
          </Tooltip>
        </CheckPermission>
      </HFlow>

      {items?.length === 0 ? (
        <Text fontStyle='italic'>Nenhuma lotação de estágio cadastrada</Text>
      ) : (
        <Grid wrap>
          {items.map((lotacao) => (
            <Cell key={lotacao.id} xs={12} md={6}>
              <EstagioCard estagio={lotacao} refetch={refetch} />
            </Cell>
          ))}
        </Grid>
      )}
    </VFlow>
  )
}

const styles = {
  icon: css`
    margin-right: 0.5rem;
  `,
}
