import api from 'api'
import { AxiosRequestConfig } from 'axios'
import { Alert, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { FileUploaderComponent } from 'components/upload/FileUploaderComponent'
import React from 'react'

import { UltimasImportacoesCidadaoTable } from './UltimasImportacoesCidadaoTable'

export function ImportarCidadaoView() {
  const alert = useAlert()
  const { data: session } = useSession()

  const municipio = session.acesso.municipio

  const upload = (formData: FormData, config?: AxiosRequestConfig) => {
    return api.importarCidadao.importar(formData, municipio && municipio.id, config).catch((error) => {
      if (error.response.data.message) {
        alert('danger', error.response.data.message)
      } else {
        alert('danger', error.response.data || 'Falha na importação do arquivo.')
      }
      throw error
    })
  }

  return (
    <>
      <PageHeaderSection title='Importar cidadão' />
      <PageContent>
        <VFlow>
          <Alert type='info' inline>
            <div>Essa funcionalidade possibilita a importação de cidadãos gerados a partir de outros sistemas.</div>
          </Alert>
          <VFlow vSpacing={0}>
            <FileUploaderComponent uploadRequest={upload} disabled={!municipio} />
          </VFlow>

          <UltimasImportacoesCidadaoTable municipio={municipio} />
        </VFlow>
      </PageContent>
    </>
  )
}
