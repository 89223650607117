/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, ExternalStyles, Grid, Heading, Text, Theme, useTheme, VFlow } from 'bold-ui'
import image from 'images/videochamada/picture-in-picture.png'

interface PictureInPicturePlaceholderProps {
  setPipActive?(newVal: boolean): void
  style?: ExternalStyles
  minimizado?: boolean
}

export function PictureInPicturePlaceholder(props: PictureInPicturePlaceholderProps) {
  const { minimizado = false, ...rest } = props

  return minimizado ? (
    <PictureInPicturePlaceholderMinimizado {...rest} />
  ) : (
    <PictureInPicturePlaceholderMaximizado {...rest} />
  )
}

export function PictureInPicturePlaceholderMaximizado(props: PictureInPicturePlaceholderProps) {
  const { setPipActive, style: externalStyles } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Grid alignItems='center' justifyContent='space-between' style={css(styles.container, externalStyles)}>
      <Cell size={8}>
        <VFlow>
          <Heading level={1} style={styles.text}>
            A chamada de vídeo está em outra janela
          </Heading>
          <Text style={styles.text}>
            O vídeo do outro participante aparecerá em uma nova janela enquanto o picture-in-picture estiver habilitado.
          </Text>
          <Button kind='primary' onClick={() => setPipActive?.(false)}>
            Fechar picture-in-picture
          </Button>
        </VFlow>
      </Cell>
      <Cell style={styles.imageCell} size={4}>
        <div css={styles.imageContainer}>
          <img css={styles.image} src={image} alt='Recurso sendo utilizado para minimizar o vídeo do convidado.' />
        </div>
      </Cell>
    </Grid>
  )
}

function PictureInPicturePlaceholderMinimizado(props: PictureInPicturePlaceholderProps) {
  const { style: externalStyles } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <div css={css(styles.containerMinimizado, externalStyles)}>
      <Heading level={3} style={styles.text}>
        A chamada de vídeo está em outra janela
      </Heading>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    background-color: ${theme.pallete.gray.c10};
  `,
  containerMinimizado: css`
    background-color: ${theme.pallete.gray.c30};
    display: flex;
    align-items: end;
    padding: 0.5rem;
  `,
  text: css`
    color: ${theme.pallete.gray.c100};
  `,
  imageCell: css`
    height: 100%;
  `,
  imageContainer: css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  `,
  image: css`
    max-width: 100%;
    max-height: 100%;
  `,
})
