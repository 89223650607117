/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { Fragment, useMemo } from 'react'

import { getParteBucal } from '../../../util-EvolucoesOdontologicasPlano'
import {
  getPeriogramaCompletoFace,
  getPeriogramaCompletoQuadrante,
  PeriogramaCompletoQuadrante,
  PeriogramaCompletoTableModel,
} from '../model'
import { PipsModel } from '../periogramaCompletoCalculos'
import PeriogramaCompletoTableViewRow from './PeriogramaCompletoTableViewRow'

export interface PeriogramaCompletoTableViewProps {
  quadrante: PeriogramaCompletoQuadrante
  pips: PipsModel
  values: PeriogramaCompletoTableModel
}

export default function PeriogramaCompletoTableView(props: PeriogramaCompletoTableViewProps) {
  const { quadrante: quadranteEnum, pips, values } = props

  const theme = useTheme()
  const classes = useMemo(() => periogramaCompletoTableStyles(theme), [theme])

  const quadrante = useMemo(() => getPeriogramaCompletoQuadrante(quadranteEnum), [quadranteEnum])

  return (
    <VFlow vSpacing={0.5}>
      <Text fontWeight='bold'>{quadrante.title}</Text>

      <div css={classes.tableWrapper}>
        <Table style={classes.table}>
          <TableHead>
            <TableRow>
              <TableHeader style={[classes.firstColumn, classes.headerCell]}>Dente</TableHeader>
              {quadrante.dentes.map((dente, key) => (
                <TableHeader key={key} style={[classes.tableCell, classes.headerCell, classes.tableHeaderButtonCell]}>
                  {getParteBucal(dente).number}
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {quadrante.faces.map((faceEnum, key) => {
              const face = getPeriogramaCompletoFace(faceEnum)

              return (
                <Fragment key={key}>
                  <TableRow>
                    <TableCell style={[classes.firstColumn, classes.headerCell]}>{face.title}</TableCell>

                    {quadrante.dentes.map((_, key) => (
                      <TableCell key={key} style={[classes.tableCell, classes.headerCell]}>
                        {face.placeholder}
                      </TableCell>
                    ))}
                  </TableRow>

                  {face.condicoes.map((condicao, key) => {
                    return (
                      <PeriogramaCompletoTableViewRow
                        key={key}
                        quadrante={quadranteEnum}
                        dentes={quadrante.dentes}
                        face={faceEnum}
                        condicao={condicao}
                        pips={pips}
                        values={values}
                      />
                    )
                  })}
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </VFlow>
  )
}

export const periogramaCompletoTableStyles = (theme: Theme) => ({
  // `width: 0` to fix overflow
  tableWrapper: css`
    width: 0;
    max-width: 100%;
    min-width: 100%;
    overflow-x: auto;
  `,
  table: css`
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;

    ${theme.breakpoints.down('lg')} {
      width: auto;
    }

    tr th,
    tr td {
      border-right: 1px solid ${theme.pallete.gray.c80};
      border-bottom: 1px solid ${theme.pallete.gray.c80};
    }

    tr th {
      border-top: 1px solid ${theme.pallete.gray.c60};
    }

    tr th:first-of-type,
    tr td:first-of-type {
      border-left: 1px solid ${theme.pallete.gray.c60};
    }

    tr th:last-of-type,
    tr td:last-of-type {
      border-right: 1px solid ${theme.pallete.gray.c60};
    }

    tr:last-of-type td {
      border-bottom: 1px solid ${theme.pallete.gray.c60};
    }

    tr th:first-of-type {
      &,
      &:before {
        border-top-left-radius: 4px;
      }
    }

    tr th:last-of-type {
      &,
      &:before {
        border-top-right-radius: 4px;
      }
    }
    tr:last-of-type td:first-of-type {
      &,
      &:before,
      & input {
        border-bottom-left-radius: 4px;
      }
    }

    tr:last-of-type td:last-of-type {
      &,
      &:before,
      & input {
        border-bottom-right-radius: 4px;
      }
    }
  `,
  headerCell: css`
    background: ${theme.pallete.gray.c90};
  `,
  tableHeaderButtonCell: css`
    padding: 0;

    ${theme.breakpoints.down('lg')} {
      min-width: 5.6rem;
    }
  }
  `,
  tableHeaderButton: css`
    width: 100%;
    height: 100%;
    text-decoration: underline;
    border-radius: 0;
  }
  `,
  firstColumn: css`
    width: 7.5rem;
    white-space: nowrap;
    font-weight: bold;
    background: ${theme.pallete.surface.main};
  `,
  tableCell: css`
    text-align: center;
    background: ${theme.pallete.surface.main};
  `,
  tableCellInput: css`
    position: relative;
    padding: 0;
  `,
  tableCellInputError: css`
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid ${theme.pallete.status.danger.main};
      margin: -1px;
    }
  `,
  tableCellAusente: css`
    color: ${theme.pallete.gray.c60};
    background: ${theme.pallete.gray.c90};
  `,
})
