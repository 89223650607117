/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { ComposedFormControl, ComposedFormControlProps, NumberField } from 'components/form'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

export interface SemanasDiasFieldModel {
  semanas?: number
  dias?: number
}

export interface SemanasDiasFieldProps extends Omit<ComposedFormControlProps<SemanasDiasFieldModel>, 'name'> {
  name: MetaPath<SemanasDiasFieldModel>
}

export function SemanasDiasField(props: SemanasDiasFieldProps) {
  const { name, style, onBlur, ...rest } = props

  const {
    input: { onChange: setSemanasIdade, value: semanasIdade },
  } = useField(name.semanas.absolutePath())

  return (
    <ComposedFormControl
      name={name}
      {...rest}
      style={css`
        width: 100%;
      `}
    >
      <NumberField
        name={name.semanas}
        placeholder='00'
        maxLength={2}
        clearable={false}
        onBlur={() => {
          setSemanasIdade(semanasIdade ?? 0)
          onBlur && onBlur()
        }}
        style={css`
          width: 1rem;
          text-align: right;
        `}
      />

      <Text fontWeight='bold'>semanas e</Text>

      <NumberField
        name={name.dias}
        placeholder='0'
        maxLength={1}
        clearable={false}
        onBlur={() => onBlur && onBlur()}
        style={css`
          width: 0.5rem;
          text-align: right;
        `}
      />
      <Text fontWeight='bold'>dias</Text>
    </ComposedFormControl>
  )
}

SemanasDiasField.defaultProps = {
  clearable: true,
} as SemanasDiasFieldProps
