import React from 'react'

import { Breadcrumb } from '../../components/breadcrumb'
import { PecSwitch, PrivateRoute } from '../../components/route'
import { useRouter } from '../../hooks/useRouter'
import Permissions from '../../types/Permissions'
import { DetalhesImportacaoCnesView } from './detalhes/DetalhesImportacaoCnesView'
import { ImportacaoCnesView } from './ImportacaoCnesView'

export const ImportacaoCnesRootView = () => {
  const { match } = useRouter()
  return (
    <>
      <Breadcrumb title='Importação de CNES' />
      <PecSwitch>
        <PrivateRoute
          exact
          path={`${match.url}`}
          permission={Permissions.importarCnes}
          component={ImportacaoCnesView}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:importacaoId(\\d+)`}
          permission={Permissions.importarCnes}
          component={DetalhesImportacaoCnesView}
        />
      </PecSwitch>
    </>
  )
}
