/** @jsx jsx */

import { css, jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'
import theme from 'config/theme'
import { Fragment } from 'react'

import { AccordionCardSection } from '../../soap/aside/components/AccordionCardSection'
import { ContentCardSection } from '../../soap/aside/components/ContentCardSection'
import { SubtitleCard } from '../../soap/aside/components/SubtitleCard'
import { isInformacoesObstetricasPreenchidas } from './AntecedentesFolhaRostoSection'
import { CirurgiaInternacaoRow } from './components/CirurgiaInternacaoRow'
import { InformacoesObstetricasContentCard } from './InformacoesObstetricasContentCard'
import { FolhaRostoCirurgiaInternacaoModel, FolhaRostoInformacoesObstetricasModel } from './model'

interface AntecedentesFolhaRostoCardProps {
  loading: boolean
  informacoesObstetricas: FolhaRostoInformacoesObstetricasModel
  cirurgiasInternacoes: FolhaRostoCirurgiaInternacaoModel[]
  cidadaoDataNascimento: Date
}

export function AntecedentesFolhaRostoCard(props: AntecedentesFolhaRostoCardProps) {
  const { loading, informacoesObstetricas, cirurgiasInternacoes, cidadaoDataNascimento } = props

  const showInformacoesObstetricas = isInformacoesObstetricasPreenchidas(informacoesObstetricas)
  const showCirurgiasInternacoes = cirurgiasInternacoes.length > 0
  const cirurgiasInternacoesCard = cirurgiasInternacoes.slice(0, 5)
  const cirurgiasInternacoesAccordion = cirurgiasInternacoes.slice(5)

  return (
    <Fragment>
      <ContentCardSection isLoading={loading} messageLoading='Carregando dados de antecedentes'>
        {showInformacoesObstetricas && (
          <InformacoesObstetricasContentCard informacoesObstetricas={informacoesObstetricas} />
        )}
        {showInformacoesObstetricas && showCirurgiasInternacoes && (
          <hr
            css={css`
              margin: 0.5rem -0.5rem;
              background: ${theme.pallete.gray.c80};
            `}
          />
        )}
        {showCirurgiasInternacoes && (
          <Fragment>
            <SubtitleCard title='Antecedentes hospitalares' />
            <VFlow vSpacing={0.5}>
              {cirurgiasInternacoesCard.map((cirurgiaInternacao) => {
                return (
                  <CirurgiaInternacaoRow
                    key={cirurgiaInternacao.idCirurgiaInternacao}
                    cirurgiaInternacao={cirurgiaInternacao}
                    cidadaoDataNascimento={cidadaoDataNascimento}
                  />
                )
              })}
            </VFlow>
          </Fragment>
        )}
      </ContentCardSection>

      {showCirurgiasInternacoes && cirurgiasInternacoesAccordion.length > 0 && (
        <AccordionCardSection
          id='AntecedentesFRCard'
          title={{
            closed: `Mostrar + ${
              cirurgiasInternacoesAccordion.length === 1
                ? cirurgiasInternacoesAccordion.length + ' antecedente hospitalar'
                : cirurgiasInternacoesAccordion.length + ' antecedentes hospitalares'
            }`,
            open: `Esconder antecedentes hospitalares`,
          }}
        >
          <VFlow vSpacing={0.5}>
            {cirurgiasInternacoesAccordion.map((cirurgiaInternacao) => {
              return (
                <CirurgiaInternacaoRow
                  key={cirurgiaInternacao.idCirurgiaInternacao}
                  cirurgiaInternacao={cirurgiaInternacao}
                  cidadaoDataNascimento={cidadaoDataNascimento}
                />
              )
            })}
          </VFlow>
        </AccordionCardSection>
      )}
    </Fragment>
  )
}
