import { RegistroTardioInput } from 'graphql/types.generated'
import { convertDateAndTimeToInstant } from 'util/date/formatDate'

import { RegistroTardioFormModel } from './model'

export const convertModelToInput = (values: RegistroTardioFormModel): RegistroTardioInput => ({
  atendimentoId: values.atendimentoId,
  cidadaoId: values.cidadao.id,
  atorPapelId: values.lotacao.id,
  dataHorarioAtendimento: convertDateAndTimeToInstant(values.dataAtendimento, values.horaAtendimento),
  localAtendimentoId: values.localAtendimento.id,
  justificativa: values.justificativa,
})
