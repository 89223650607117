import { Text } from 'bold-ui'
import React from 'react'
import { PeriodoGestacaoModel } from 'view/atendimentos/detail/components/modals/types/PeriodoGestacaoModel'
import { CidadaoMedicoes } from 'view/atendimentos/detail/soap/aside/medicoes/model'

import { AccordionCardSection } from '../components/AccordionCardSection'
import { ContentCardSection } from '../components/ContentCardSection'
import { SubtitleCard } from '../components/SubtitleCard'
import { GrupoMedicoesTipoModel } from '../types/MedicaoModel'
import { MedicoesListCard } from './components/MedicoesListCard'

export interface MedicoesCardProps {
  loading: boolean
  medicoesHoje: ReadonlyArray<GrupoMedicoesTipoModel>
  medicoesAnterioresExpandido: ReadonlyArray<GrupoMedicoesTipoModel>
  medicoesAnterioresAccordion: ReadonlyArray<GrupoMedicoesTipoModel>
  canRender: boolean
  cidadao: CidadaoMedicoes
  gestacoes: ReadonlyArray<PeriodoGestacaoModel>
  readOnly?: boolean
}

export function MedicoesCard(props: MedicoesCardProps) {
  const {
    loading,
    medicoesHoje,
    medicoesAnterioresExpandido,
    medicoesAnterioresAccordion,
    canRender,
    cidadao,
    gestacoes,
    readOnly,
  } = props

  return (
    <>
      <ContentCardSection isLoading={loading} messageLoading='Carregando dados das medições' canRender={canRender}>
        <SubtitleCard title='De hoje' />
        <MedicoesListCard medicoes={medicoesHoje} cidadao={cidadao} gestacoes={gestacoes} />
        {medicoesHoje.length === 0 && <Text component='p'>Nenhuma medição registrada hoje.</Text>}

        {!readOnly && (
          <>
            <SubtitleCard title='Anteriores' />
            <MedicoesListCard
              medicoes={medicoesAnterioresExpandido}
              isListMedicaoAnterior
              cidadao={cidadao}
              gestacoes={gestacoes}
            />
            {medicoesAnterioresExpandido.length === 0 && (
              <Text component='p'>Nenhuma medição realizada em dias anteriores.</Text>
            )}
          </>
        )}
      </ContentCardSection>

      {!readOnly && medicoesAnterioresAccordion.length > 0 && (
        <AccordionCardSection
          id='MedicoesCard'
          title={{
            closed: `Mostrar + ${medicoesAnterioresAccordion.length} medições`,
            open: `Esconder medições`,
          }}
        >
          <MedicoesListCard
            medicoes={medicoesAnterioresAccordion}
            isListMedicaoAnterior
            cidadao={cidadao}
            gestacoes={gestacoes}
          />
          {medicoesAnterioresAccordion.length === 0 && (
            <Text component='p'>Nenhuma medição realizada em dias anteriores.</Text>
          )}
        </AccordionCardSection>
      )}
    </>
  )
}
