import { compareAsc } from 'date-fns'

import { OrientacaoRowModel } from '../types/OrientacoesModel'

function sortOrientacoesByProfissional(item0: OrientacaoRowModel, item1: OrientacaoRowModel): number {
  return `${item0.atendimentoProfissional.lotacao.profissional.nome} ${item0.atendimentoProfissional.lotacao.cbo.nome}`.localeCompare(
    `${item1.atendimentoProfissional.lotacao.profissional.nome} ${item1.atendimentoProfissional.lotacao.cbo.nome}`
  )
}

function sortOrientacoesByData(item0: OrientacaoRowModel, item1: OrientacaoRowModel): number {
  return compareAsc(
    new Date(item0.atendimentoProfissional.iniciadoEm),
    new Date(item1.atendimentoProfissional.iniciadoEm)
  )
}

const sortOrientacoesOptions: Record<string, (item0: OrientacaoRowModel, item1: OrientacaoRowModel) => number> = {
  data: sortOrientacoesByData,
  profissional: sortOrientacoesByProfissional,
}

export function sortOrientacoes(
  orientacoes: ReadonlyArray<OrientacaoRowModel>,
  sort: ReadonlyArray<string>
): OrientacaoRowModel[] {
  const currentSort: string = sort[0]
  const isDesc: boolean = currentSort.startsWith('-')
  const currentSortClean: string = isDesc ? currentSort.substring(1) : currentSort

  const orientacoesSorted = [...orientacoes].sort(sortOrientacoesOptions[currentSortClean])

  return isDesc ? orientacoesSorted.reverse() : orientacoesSorted
}
