import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { SubmitButton } from 'components/form'
import { ServicosCertificadoEnum } from 'graphql/types.generated'
import React from 'react'

type ServicosSelecionadosProps = {
  servicosSelecionados: ServicosCertificadoEnum[]
  servicosValidados: ServicosCertificadoEnum[]
  isModalOpen: boolean
  handleModalClose(): void
  handleModalSubmit(): void
  loading: boolean
}

export function ServicosSelecionadosConfirmacaoModal({
  servicosSelecionados,
  servicosValidados,
  isModalOpen,
  handleModalClose,
  handleModalSubmit,
  loading,
}: ServicosSelecionadosProps) {
  const rndsChecked = servicosSelecionados?.includes(ServicosCertificadoEnum.RNDS)
  const cadsusChecked = servicosSelecionados?.includes(ServicosCertificadoEnum.CADSUS)
  const rndsValidated = servicosValidados?.includes(ServicosCertificadoEnum.RNDS)
  const cadsusValidated = servicosValidados?.includes(ServicosCertificadoEnum.CADSUS)

  return (
    <Modal open={isModalOpen} onClose={handleModalClose} size='small'>
      <ModalBody>
        <HFlow>
          <Icon icon='exclamationTriangleOutline' fill='alert' style={{ marginRight: '0.5rem' }} size={3} />
          <VFlow vSpacing={0}>
            <Heading level={1} style={{ display: 'inline' }}>
              Serviços selecionados diferentes do certificado
            </Heading>
            <VFlow vSpacing={0}>
              <Text>
                Serviços selecionados: {rndsChecked && 'RNDS'}
                {rndsChecked && cadsusChecked && ','} {cadsusChecked && 'CADSUS'}
              </Text>
              <Text>
                Serviços vinculados no certificado: {rndsValidated && 'RNDS'}
                {rndsValidated && cadsusValidated && ','} {cadsusValidated && 'CADSUS'}
              </Text>
              <Text>Deseja continuar?</Text>
            </VFlow>
          </VFlow>
        </HFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button size='medium' onClick={handleModalClose}>
            Cancelar
          </Button>
          <SubmitButton kind='primary' skin='default' size='medium' handleSubmit={handleModalSubmit} loading={loading}>
            Continuar
          </SubmitButton>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
