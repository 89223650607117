import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { JustificativaVisualizarProntuarioModal } from './modal'
import { useJustificativaVerProntuarioId } from './useJustificativaVerProntuarioId'

export interface CheckJustificativaAcessoProntuarioProps {
  cidadaoId: ID
  prontuarioId: ID
  basePath: string
  children: JSX.Element
  disabled?: boolean
}

export function CheckJustificativaAcessoProntuario(props: CheckJustificativaAcessoProntuarioProps) {
  const { children, cidadaoId, prontuarioId, basePath, disabled = false } = props

  const history = useHistory()

  const [isModalProntuarioOpen, setIsModalProntuarioOpen] = useState(true)
  const [storedJustificativaId, setStorageJustificativa] = useJustificativaVerProntuarioId(basePath, cidadaoId)
  const [justificativaId, setJustificativaId] = useState(storedJustificativaId)

  const handleRejection = () => {
    setIsModalProntuarioOpen(false)
    history.goBack()
  }
  const handleConfirm = (justificativaId: ID) => {
    setJustificativaId(justificativaId)
    setStorageJustificativa(justificativaId)
    setIsModalProntuarioOpen(false)
  }

  return disabled || justificativaId ? (
    <>{children}</>
  ) : (
    <JustificativaVisualizarProntuarioModal
      cidadaoId={cidadaoId}
      prontuarioId={prontuarioId}
      isModalOpen={isModalProntuarioOpen}
      onConfirm={handleConfirm}
      onReject={handleRejection}
      redirecionarParaFolhaRosto={false}
    />
  )
}
