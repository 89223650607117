import { randomStr } from 'bold-ui/lib/util/string'
import { useContext, useEffect, useRef } from 'react'
import { useRouteMatch } from 'react-router'

import { BreadcrumbContext } from './BreadcrumbContext'
import { BreadcrumbEntry } from './BreadcrumbStore'

export interface BreadcrumbProps extends Pick<BreadcrumbEntry, 'title' | 'to'> {}

export function Breadcrumb(props: BreadcrumbProps) {
  const { title, to } = props
  const match = useRouteMatch()
  const store = useContext(BreadcrumbContext)

  const entry = useRef({
    key: randomStr(),
    title,
    to: to || match.url,
    dataTestId: title,
  })

  useEffect(() => {
    const item = entry.current

    store.push(item)
    return () => store.pop(item)
  }, [store])

  return null
}
