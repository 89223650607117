/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Tooltip, useTheme } from 'bold-ui'
import { ReactComponent as SvgChatSend } from 'images/videochamada/mail-send-filled.svg'
import { useState } from 'react'

import { ChatTextArea } from './ChatTextArea'

interface ChatTextInputProps {
  onSend(text: string): void
}

export function ChatTextInput(props: ChatTextInputProps) {
  const { onSend } = props

  const theme = useTheme()

  const [messageInput, setMessageInput] = useState('')

  const handleClick = () => {
    if (!!messageInput) {
      onSend(messageInput)
      setMessageInput('')
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageInput(e.target.value)
  }

  return (
    <Grid
      alignItems='center'
      justifyContent='space-between'
      gap={0}
      style={css`
        border: 1px solid gray;
      `}
    >
      <Cell size={11}>
        <ChatTextArea value={messageInput} onChange={handleChange} onPressEnter={handleClick} />
      </Cell>
      <Cell size={1}>
        <Tooltip text='Enviar mensagem'>
          <Button size='small' onClick={handleClick} style={styles.icon} disabled={!messageInput}>
            <SvgChatSend fill={!messageInput ? theme.pallete.gray.c60 : theme.pallete.primary.c40} fontSize='1.5rem' />
          </Button>
        </Tooltip>
      </Cell>
    </Grid>
  )
}

const styles = {
  icon: css`
    display: flex;
    align-items: center;
    width: inherit;
    padding: 0;
    border: none;
    &:focus {
      box-shadow: none;
    }
    ,
    &:not(:disabled):hover {
      background-color: transparent;
    }
  `,
}
