import React from 'react'

import { EquipeTable } from './EquipeTable'

export interface EquipeListViewProps {
  unidadeSaudeId: ID
}

export class EquipeListView extends React.Component<EquipeListViewProps> {
  render() {
    return <EquipeTable unidadeSaudeId={this.props.unidadeSaudeId} />
  }
}
