import { Table, TableBody, TableFooter, TableLoadingRow, TablePlaceholderRow } from 'bold-ui'
import { TableBox, usePagedTableProps } from 'components/table'
import { useVinculacoesEstabelecimentosTableQuery } from 'graphql/hooks.generated'
import { VinculacaoServicosInput, VinculacoesEstabelecimentosTableQuery } from 'graphql/types.generated'
import React, { useMemo, useState } from 'react'

import { VinculacoesEstabelecimentosTableHeader } from './VinculacoesEstabelecimentosTableHeader'
import { VinculacoesEstabelecimentosTableRow } from './VinculacoesEstabelecimentosTableRow'

export type VinculacoesEstabelecimentosItem = VinculacoesEstabelecimentosTableQuery['vinculacoesServico']['content'][0]

export default function VinculacoesEstabelecimentosTable() {
  const [filter, setFilter] = useState<VinculacaoServicosInput>({ pageParams: { size: 10 } })
  const [expandedRow, setExpandedRow] = useState<ID>()

  const { data, loading, refetch } = useVinculacoesEstabelecimentosTableQuery({ variables: { input: filter } })

  const tableProps = usePagedTableProps({
    loading,
    result: data?.vinculacoesServico,
    onChange: setFilter,
  })

  const { rows, loading: isLoading } = tableProps

  const handleClick = (row: VinculacoesEstabelecimentosItem) => () => {
    setExpandedRow(expandedRow === row.referencia.id ? null : row.referencia.id)
  }

  const isNotEmpty = useMemo(() => rows?.length > 0, [rows])

  const tableHeader = <VinculacoesEstabelecimentosTableHeader initialValues={filter} onChange={setFilter} />

  return (
    <TableBox header={tableHeader}>
      <Table hovered>
        <TableBody>
          {isLoading ? (
            <TableLoadingRow />
          ) : isNotEmpty ? (
            rows.map((row) => (
              <VinculacoesEstabelecimentosTableRow
                key={row.referencia.id}
                vinculosEstabelecimento={row}
                expandedRow={expandedRow}
                handleClick={handleClick}
                refetch={refetch}
              />
            ))
          ) : (
            <TablePlaceholderRow />
          )}
        </TableBody>
      </Table>
      <TableFooter
        {...tableProps}
        style={{ borderTopWidth: 0 }}
        totalElements={data?.vinculacoesServico?.pageInfo?.totalElements ?? 0}
        pageSize={tableProps.size}
        sizeOptions={[5, 10, 15, 25]}
      />
    </TableBox>
  )
}
