import { HFlow, Icon, PagedTable, Tag, Text, Tooltip } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { DateTime } from 'components/date'
import { EquipeSelectModel } from 'components/form'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useListaAdTableLazyQuery } from 'graphql/hooks.generated'
import {
  AtencaoDomiciliarCondutaDesfecho,
  BuscaPeriodoEnum,
  ListaAdTableQuery,
  PageParams,
} from 'graphql/types.generated'
import React, { useCallback, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import { convertAtencaoDomiciliarCidadaoModelToInput } from '../convertModelToInput'
import { AdFilterModel, AtencaoDomiciliarTableFilter } from '../filter/AtencaoDomiciliarTableFilter'
import { destinoDescriptionByEnumType } from '../selectFields/AtencaoDomiciliarCondutaDesfechoSelectField'
import { modalidadeDescriptionByEnumType } from '../selectFields/AtencaoDomiciliarModalidadeSelectField'
import CidadaoADDetailButtonView from './CidadaoADDetailButtonView'
import ListaADTableDropdown, { ListaADTableDropdownProps } from './ListaADTableDropdown'

export interface AtencaoDomiciliarCidadaoFilterModel {
  filter: AdFilterModel
  pageParams: PageParams
}

export interface ListaADTableProps extends Partial<ListaADTableDropdownProps> {
  equipe?: EquipeSelectModel
  isCoordenadorAd: boolean
  localidadeId?: ID
}

export type CidadaoAdItemType = ListaAdTableQuery['cidadaosAD']['content'][0]

export default function ListaADTable(props: ListaADTableProps) {
  const { equipe, isCoordenadorAd, localidadeId, ...rest } = props
  const match = useRouteMatch()

  const [state, setState] = useState<AtencaoDomiciliarCidadaoFilterModel>({
    filter: {
      tipoBuscaPeriodo: BuscaPeriodoEnum.PERIODO_NENHUM,
      condutaDesfecho: AtencaoDomiciliarCondutaDesfecho.PERMANENCIA,
    },
    pageParams: {
      size: 10,
      sort: ['dataUltimaVisita'],
    },
  })

  const [executeQuery, { data, loading, refetch }] = useListaAdTableLazyQuery()

  useEffect(() => {
    if (!state.filter.query || state.filter.query?.trim()?.length >= 3) {
      executeQuery({ variables: { input: convertAtencaoDomiciliarCidadaoModelToInput(state) } })
    }
  }, [executeQuery, state])

  const onChangeFilter = useCallback(
    (filter: AdFilterModel) => {
      setState((prevState) => ({
        ...prevState,
        filter,
        pageParams: {
          ...prevState.pageParams,
          page: 0,
        },
      }))
    },
    [setState]
  )

  const renderNome = (row: CidadaoAdItemType) => (
    <HFlow hSpacing={0.5} style={{ alignItems: 'center' }}>
      <Text>{row.cidadao.nomeSocial || row.cidadao.nome}</Text>
      {!row.cidadao.cns && !row.cidadao.cpf && (
        <>
          <Tooltip text='Cidadão não possui CPF e CNS cadastrados.'>
            <Tag type='danger'>Sem CPF e CNS</Tag>
          </Tooltip>
        </>
      )}
    </HFlow>
  )

  const renderModalidade = (row: CidadaoAdItemType) => <Text>{modalidadeDescriptionByEnumType(row.modalidade)}</Text>

  const renderUltimaVisita = (row: CidadaoAdItemType) => (
    <Text> {row?.dataUltimaVisita ? <DateTime format='DD/MM/YYYY' value={row?.dataUltimaVisita} /> : '—'} </Text>
  )

  const renderAgendadoPara = (row: CidadaoAdItemType) => (
    <Text> {row?.dataAgendado ? <DateTime format='DD/MM/YYYY' value={row?.dataAgendado} /> : '—'} </Text>
  )

  const renderCondutaDesfecho = (row: CidadaoAdItemType) => (
    <Text> {destinoDescriptionByEnumType(row.condutaDesfecho)} </Text>
  )

  const renderEquipeResponsavel = (row: CidadaoAdItemType) => <Text> {row.equipe.nome} </Text>

  const renderButtons = (row: CidadaoAdItemType) => (
    <CheckPermission permission={Permissions.atencaoDomiciliar.visualizarListaDeAdDaEquipe}>
      {
        <Tooltip
          text={
            !row.actions.adEncerrado.enabled
              ? row.actions.adEncerrado.hint
              : !row.actions.permissaoEditarAD.enabled
              ? row.actions.permissaoEditarAD.hint
              : 'Editar'
          }
        >
          <ButtonLink
            size='small'
            skin='ghost'
            to={`${match.url}/${row.id}/edit`}
            disabled={!row.actions.adEncerrado.enabled || !row.actions.permissaoEditarAD.enabled}
          >
            <Icon icon='penOutline' />
          </ButtonLink>
        </Tooltip>
      }
      <CidadaoADDetailButtonView cidadaoADId={row.id} />
      <ListaADTableDropdown cidadaoAd={row} refetch={refetch} {...rest} />
    </CheckPermission>
  )

  const tableProps = usePagedTableProps({
    result: data?.cidadaosAD,
    loading,
    onChange: setState,
  })

  return (
    <>
      <TableBox
        header={
          <AtencaoDomiciliarTableFilter
            filter={state.filter}
            onChangeFilter={onChangeFilter}
            isCoordenadorAd={isCoordenadorAd}
            equipeId={equipe?.id}
            localidadeId={localidadeId}
          />
        }
      >
        <PagedTable<CidadaoAdItemType>
          {...tableProps}
          sizeOptions={[5, 10, 30]}
          columns={[
            {
              name: 'cidadao',
              header: 'Cidadão',
              render: renderNome,
            },
            {
              name: 'modalidade',
              header: 'Modalidade',
              render: renderModalidade,
            },
            {
              name: 'dataUltimaVisita',
              header: 'Última visita',
              render: renderUltimaVisita,
              sortable: true,
            },
            {
              name: 'agendadoPara',
              header: 'Agendado para',
              render: renderAgendadoPara,
            },
            {
              name: 'destino',
              header: 'Conduta/Desfecho',
              render: renderCondutaDesfecho,
            },
            {
              name: 'equipe',
              header: 'Equipe responsável',
              render: renderEquipeResponsavel,
            },
            {
              name: 'botoes',
              render: renderButtons,
              style: { textAlign: 'right', whiteSpace: 'nowrap' },
            },
          ]}
        />
      </TableBox>
    </>
  )
}
