import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { LogEventType } from 'view/atendimentos/atendimento-individual/logFinalizarAtendimento'
import {
  CondutaGroupEnum,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoFormModel,
  ResponsavelCuidadoCompartilhado,
  TipoAgendamentoCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { getFieldsByResponsavel } from '../util-cuidadoCompartilhado'

const logs: Record<
  Exclude<CondutaGroupEnum, CondutaGroupEnum.DEVOLUTIVA_DISCUSSAO_CASO | CondutaGroupEnum.AGENDAR_CONSULTA>,
  string
> = {
  NOVA_PERGUNTA: 'CC_conduta_nova_pergunta',
  TROCA_PROFISSIONAL_REFERENCIA: 'CC_conduta_troca_profissional_referencia',
  SUGESTAO_AGENDAMENTO_GRUPO: 'CC_conduta_sugestao_agendamento_grupo',
  ENVIAR_GARANTIA_ACESSO: 'CC_conduta_enviar_garantia_acesso',
}

export const logRegistrarDiscussao = (
  logEvent: LogEventType,
  values: DiscussaoCasoFormModel,
  responsabilidade: ResponsavelCuidadoCompartilhado
) => {
  const fields = getFieldsByResponsavel(responsabilidade, values)

  switch (fields.condutaGroup) {
    case CondutaGroupEnum.DEVOLUTIVA_DISCUSSAO_CASO: {
      switch ((fields as DiscussaoCasoExecutanteFieldsModel).devolutiva.conduta) {
        case CondutaCuidadoCompartilhadoEnum.PEDIDO_ESCLARECIMENTO: {
          logEvent('CC_conduta_pedido_esclarecimento')
          break
        }
        case CondutaCuidadoCompartilhadoEnum.TELECONSULTORIA: {
          logEvent('CC_conduta_teleconsultoria')
          break
        }
        default: {
          break
        }
      }
      break
    }

    case CondutaGroupEnum.AGENDAR_CONSULTA: {
      if (fields.agendamento.tipoAgendamento === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO) {
        logEvent('CC_conduta_agendar_cidadao_presencial')
      }

      if (fields.agendamento.tipoAgendamento === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA) {
        logEvent('CC_conduta_agendar_inter_prof')
      }
      break
    }

    default: {
      if (logs[fields.condutaGroup]) logEvent(logs[fields.condutaGroup])
      break
    }
  }
}
