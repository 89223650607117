import { MotivoFechamentoEnum } from 'graphql/types.generated'
import moment from 'moment'
import { createValidator, maxLength, required } from 'util/validation'

import { FechamentoFormModel } from './model'

export const validateFechamento = createValidator<FechamentoFormModel>(
  {
    periodo: [required],
    motivo: [required],
    especifique: [maxLength(300)],
  },
  (values, errors) => {
    if (!errors.periodo) errors.periodo = required(values.periodo.startDate)
    if (!errors.periodo) errors.periodo = required(values.periodo.endDate)
    if (!errors.periodo && moment(values.periodo.startDate).isBefore(moment().toDate(), 'date')) {
      errors.periodo = 'Data inicial é menor que a data atual.'
    }
    if (!errors.periodo && moment(values.periodo.endDate).isBefore(values.periodo.startDate, 'date')) {
      errors.periodo = 'Data inicial é maior que a data final.'
    }

    if (values.motivo === MotivoFechamentoEnum.OUTRO) {
      errors.especifique = required(values.especifique)
    }
    return errors
  }
)
