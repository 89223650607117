import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, Tooltip } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { SubmitButton } from 'components/form'
import { Form, FormRenderProps } from 'components/form/final-form/Form'
import { usePrinter } from 'components/print'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useAuditGerarDeclaracaoComparecimentoMutation } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import qs from 'qs'
import React, { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import {
  DeclaracaoComparecimentoModel,
  DeclaracaoComparecimentoView,
  horarioEntradaCalculator,
  horarioSaidaCalculator,
  Periodo,
  validateDeclaracaoComparecimento,
} from './DeclaracaoComparecimentoView'
import { DeclaracaoComparecimento } from './ImpressaoDeclaracaoComparecimento'

export interface GerarDeclaracaoComparecimentoUrlParams {
  callbackUrl?: string
}

export const GerarDeclaracaoComparecimentoModalForm = (props: DeclaracaoComparecimentoModel) => {
  const history = useHistory()
  const location = useLocation()
  const handleRejection = useErrorHandler()
  const { printPDF } = usePrinter()
  const [audit] = useAuditGerarDeclaracaoComparecimentoMutation({ variables: { atendimento: props.id } })

  const { getServerTimeNow } = useServerTime()

  const [{ callbackUrl }] = useState<GerarDeclaracaoComparecimentoUrlParams>(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
  )

  const goBack = () => {
    return history.push(`/${callbackUrl}`)
  }

  const handleSubmit = (values: DeclaracaoComparecimentoModel) => {
    const docBody = DeclaracaoComparecimento({
      ...values,
      dataDeclaracao: format(values.isRegistroTardio ? values.iniciadoEm : values.dataCriacaoRegistro, 'dd/MM/yyyy'),
      dataPorExtenso: format(
        values.isRegistroTardio ? values.iniciadoEm : getServerTimeNow(),
        "dd 'de' MMMM 'de' yyyy",
        {
          locale: ptBR,
        }
      ).toLowerCase(),
    })

    printPDF(
      {
        docBody,
        documentTitle: 'DeclaracaoComparecimento-' + (values.cidadao.nomeSocial || values.cidadao.nome),
      },
      handleRejection,
      audit,
      values.iniciadoEm
    )
    goBack()
  }

  const horarioEntradaCalculatorCallback = useMemo(() => horarioEntradaCalculator(props.dataCriacaoRegistro), [
    props.dataCriacaoRegistro,
  ])

  const horarioSaidaCalculatorMemo = useMemo(() => horarioSaidaCalculator(), [])

  const renderForm = (formProps: FormRenderProps<DeclaracaoComparecimentoModel>) => {
    return (
      <Modal open={true} onClose={goBack} size='auto' closeOnBackdropClick={false} style={{ width: '34.75rem' }}>
        <ModalBody>
          <Grid alignItems='flex-start' gap={0}>
            <Cell size={12}>
              <Heading level={1}>Gerar declaração de comparecimento</Heading>
            </Cell>
            <DeclaracaoComparecimentoView {...props} />
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={goBack}>Cancelar</Button>
            <Tooltip text='Gerar declaração de comparecimento'>
              <SubmitButton kind='primary' handleSubmit={formProps.handleSubmit}>
                Gerar declaração
              </SubmitButton>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<DeclaracaoComparecimentoModel>
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={{ ...props, periodo: Periodo.MATUTINO }}
      validate={validateDeclaracaoComparecimento}
      decorators={[horarioEntradaCalculatorCallback, horarioSaidaCalculatorMemo]}
    />
  )
}
