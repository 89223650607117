import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { useResponderPesquisaSatisfacaoMutation } from 'graphql/hooks.generated'
import { TipoRespostaPesquisa } from 'graphql/types.generated'
import React, { useState } from 'react'

import { PesquisaSatisfacaoBox, PesquisaSatisfacaoBoxFormModel } from './PesquisaSatisfacaoBox'
import { PesquisaSatisfacaoEnviadaBox } from './PesquisaSatisfacaoEnviada'

export const PesquisaSatisfacao = () => {
  const [satisfacaoBoxOpen, setSatisfacaoBoxOpen] = useState(false)
  const [pesquisaEnviadaBoxOpen, setPesquisaEnviadaBoxOpen] = useState(false)
  const handleRejection = useErrorHandler()

  const [enviarRespostaPesquisaMutation] = useResponderPesquisaSatisfacaoMutation({
    onCompleted: () => setSatisfacaoBoxOpen(false),
  })

  const { data } = useSession({
    onCompleted: () => setSatisfacaoBoxOpen(data.profissional.usuario.mostrarPesquisaSatisfacao),
  })

  const handleCloseSatisfacaoBox = (value: TipoRespostaPesquisa) => {
    enviarRespostaPesquisa({ tipoResposta: value })
  }
  const handleOnSubmit = (values: PesquisaSatisfacaoBoxFormModel) => {
    return enviarRespostaPesquisa({ ...values, tipoResposta: TipoRespostaPesquisa.ENVIADO })
      .then(() => setPesquisaEnviadaBoxOpen(true))
      .catch(handleRejection)
  }

  const handleClosePesquisaEnviadaBox = () => {
    setPesquisaEnviadaBoxOpen(false)
  }

  const enviarRespostaPesquisa = (values: PesquisaSatisfacaoBoxFormModel) => {
    return enviarRespostaPesquisaMutation({
      variables: {
        input: {
          grauSatisfacao: values.grauSatisfacao,
          observacao: values.observacao,
          tipoResposta: values.tipoResposta,
          enviaMunicipio: values.enviaMunicipio,
        },
      },
    }).catch(handleRejection)
  }
  return (
    <>
      {satisfacaoBoxOpen && (
        <PesquisaSatisfacaoBox handleOnSubmit={handleOnSubmit} onClose={handleCloseSatisfacaoBox} />
      )}
      {pesquisaEnviadaBoxOpen && <PesquisaSatisfacaoEnviadaBox onClose={handleClosePesquisaEnviadaBox} />}
    </>
  )
}
