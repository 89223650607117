/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { useMemo } from 'react'
import { AccordionItemButton, AccordionItemHeading } from 'react-accessible-accordion'

import { MainDetailAccordionEvolucaoType, MainDetailAccordionType } from '../model'

export interface MainDetailAccordionHeaderRenderProps<T> {
  item: T
  disabled?: boolean
}

export interface MainDetailAccordionHeaderProps<
  E extends MainDetailAccordionEvolucaoType,
  T extends MainDetailAccordionType<E>
> {
  item: T
  renderHeader(renderProps: MainDetailAccordionHeaderRenderProps<T>): JSX.Element
  onHeaderClick?(itemId: ID): void
  isHeaderDisabled?(itemId: ID): boolean
}

export function MainDetailAccordionHeader<
  E extends MainDetailAccordionEvolucaoType,
  T extends MainDetailAccordionType<E>
>(props: MainDetailAccordionHeaderProps<E, T>) {
  const { item, renderHeader, onHeaderClick, isHeaderDisabled } = props

  const theme = useTheme()

  const disabled = isHeaderDisabled?.(item.id)

  const { accordionItemButton } = useMemo(() => {
    return createStyles(theme, disabled)
  }, [disabled, theme])

  return disabled ? (
    <div css={accordionItemButton}>{renderHeader({ item, disabled })}</div>
  ) : (
    <AccordionItemHeading onClick={() => onHeaderClick?.(item.id)}>
      <AccordionItemButton css={accordionItemButton}>{renderHeader({ item, disabled })}</AccordionItemButton>
    </AccordionItemHeading>
  )
}

const createStyles = (theme: Theme, disabled: boolean) => ({
  accordionItemButton: css`
    padding: 0.5rem 1rem;
    cursor: pointer;
    ${!disabled &&
    `&:hover {
      border-color: ${theme.pallete.gray.c50};
      background-color: ${theme.pallete.gray.c80};
    }`}
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &[aria-expanded='true'] svg[name='accordionAngleIcon'] {
      transform: rotate(180deg);
      transition: 0.4s;
    }
    &[aria-expanded='false'] svg[name='accordionAngleIcon'] {
      transform: rotate(0deg);
      transition: 0.4s;
    }
  `,
})
