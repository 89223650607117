import {
  useHasVacinaAtrasadaQuery,
  useUltimasTresVacinasAplicadasQuery,
  useUltimaVacinaAprazadaQuery,
} from 'graphql/hooks.generated'
import React from 'react'

import { ProntuarioCard } from '../ProntuarioCard'
import { AcompanhamentoVacinacaoCard } from './AcompanhamentoVacinacaoCard'

interface AcompanhamentoVacinacaoSectionProps {
  idProntuario: string
  dataAtendimento: Instant
}

export const AcompanhamentoVacinacaoSection = (props: AcompanhamentoVacinacaoSectionProps) => {
  const { idProntuario, dataAtendimento } = props

  const { data: ultimasAplicadas, loading: loadingAplicadas } = useUltimasTresVacinasAplicadasQuery({
    variables: {
      input: {
        idProntuario,
      },
    },
  })

  const { data: ultimaAprazada, loading: loadingAprazada } = useUltimaVacinaAprazadaQuery({
    variables: {
      input: {
        idProntuario,
        dataAtendimento,
      },
    },
  })

  const { data: vacinaAtrasada, loading: loadingAtrasada } = useHasVacinaAtrasadaQuery({
    variables: {
      input: {
        idProntuario,
        dataAtendimento,
      },
    },
  })

  const vacinasAplicadas = ultimasAplicadas.ultimasTresVacinasAplicadas

  const aprazada = ultimaAprazada.ultimaVacinaAprazada

  const loading = loadingAplicadas && loadingAprazada && loadingAtrasada

  const hasInfo = vacinasAplicadas || aprazada || vacinaAtrasada

  const renderizaInfoInfo = !loading && hasInfo

  return (
    <ProntuarioCard title='Vacinação' tooltip={!renderizaInfoInfo && 'Não há registros de vacinação'}>
      {renderizaInfoInfo && (
        <AcompanhamentoVacinacaoCard
          ultimasVacinasAplicadas={vacinasAplicadas}
          ultimaVacinaAprazada={aprazada}
          hasVacinaAtrasada={vacinaAtrasada?.hasVacinaAtrasada}
          loading={loading}
        />
      )}
    </ProntuarioCard>
  )
}
