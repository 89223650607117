import { DoseImunobiologicoVacinacaoSelectFieldModel } from 'components/form/field/select/DoseImunobiologicoVacinacaoSelectField'
import { EstrategiaVacinacaoSelectFieldModel } from 'components/form/field/select/EstrategiaVacinacaoSelectField'
import createDecorator from 'final-form-calculate'
import { EstrategiaVacinacaoEnum } from 'graphql/types.generated'
import { MetaPath, MetaRoot } from 'util/metaPath'
import { ImunobiologicoSelectModel } from 'view/lote-imunobiologico/components/ImunobiologicoSelectField'
import { LoteImunobiologicoFabricanteSelectFieldModel } from 'view/lote-imunobiologico/components/LoteImunobiologicoFabricanteSelectField'
import { covidImunosRecord } from 'view/lote-imunobiologico/model'

import { RegistroVacinacaoFormModel } from './model'

export const createAplicacaoDoseModalCalculator = (
  meta: MetaPath<RegistroVacinacaoFormModel> | MetaRoot<RegistroVacinacaoFormModel>
) =>
  createDecorator(
    {
      field: meta.viaAdministracao.absolutePath(),
      updates: {
        [meta.localAplicacaoVacinacao.absolutePath()]: () => {
          return null
        },
      },
    },
    {
      field: meta.estrategiaVacinacao.absolutePath(),
      updates: {
        [meta.grupoAtendimento.absolutePath()]: (
          estrategiaVacinacao: EstrategiaVacinacaoSelectFieldModel,
          values: RegistroVacinacaoFormModel
        ) => {
          return estrategiaVacinacao?.estrategiaVacinacaoDbEnum !== EstrategiaVacinacaoEnum.CAMPANHA_INDISCRIMINADA
            ? null
            : values.grupoAtendimento
        },
        [meta.dataAprazamentoProxDose.absolutePath()]: (
          estrategiaVacinacao: EstrategiaVacinacaoSelectFieldModel,
          values: RegistroVacinacaoFormModel,
          prevValues: RegistroVacinacaoFormModel
        ) => {
          return estrategiaVacinacao !== prevValues.estrategiaVacinacao ? null : values.dataAprazamentoProxDose
        },
      },
    },
    {
      field: meta.isCadastrarNovoLote.absolutePath(),
      updates: {
        [meta.loteImunobiologico.absolutePath()]: (
          isCadastrarNovoLote: boolean,
          values: RegistroVacinacaoFormModel
        ) => {
          return isCadastrarNovoLote ? null : values.loteImunobiologico
        },
        [meta.loteNome.absolutePath()]: (isCadastrarNovoLote: boolean, values: RegistroVacinacaoFormModel) => {
          return !isCadastrarNovoLote ? null : values.loteNome
        },
        [meta.loteFabricante.absolutePath()]: (isCadastrarNovoLote: boolean, values: RegistroVacinacaoFormModel) => {
          if (
            isCadastrarNovoLote &&
            covidImunosRecord[values.imunobiologicoId || values.outrosImunosImunobiologico?.id]
          ) {
            return {
              id: values.loteFabricante?.id,
              nome: covidImunosRecord[values.imunobiologicoId || values.outrosImunosImunobiologico?.id],
            } as LoteImunobiologicoFabricanteSelectFieldModel
          } else return null
        },
        [meta.loteDataValidade.absolutePath()]: (isCadastrarNovoLote: boolean, values: RegistroVacinacaoFormModel) => {
          return !isCadastrarNovoLote ? null : values.loteDataValidade
        },
      },
    }
  )

export const createOutrosImunobiologicosCalculator = (
  meta: MetaPath<RegistroVacinacaoFormModel> | MetaRoot<RegistroVacinacaoFormModel>,
  isRegistroAnterior: boolean = false
) =>
  createDecorator(
    {
      field: meta.outrosImunosImunobiologico.absolutePath(),
      updates: {
        [meta.estrategiaVacinacao.absolutePath()]: (
          imunobiologico: ImunobiologicoSelectModel,
          values: RegistroVacinacaoFormModel
        ) => {
          return !imunobiologico || imunobiologico ? null : values.estrategiaVacinacao
        },
        [meta.outrosImunosDose.absolutePath()]: (
          imunobiologico: ImunobiologicoSelectModel,
          values: RegistroVacinacaoFormModel
        ) => {
          return !imunobiologico || imunobiologico ? null : values.outrosImunosDose
        },
        [meta.loteImunobiologico.absolutePath()]: (
          imunobiologico: ImunobiologicoSelectModel,
          values: RegistroVacinacaoFormModel
        ) => {
          return !imunobiologico || imunobiologico ? null : values.loteImunobiologico
        },
        [meta.loteFabricante.absolutePath()]: (
          imunobiologico: ImunobiologicoSelectModel,
          values: RegistroVacinacaoFormModel
        ) => {
          if (covidImunosRecord[imunobiologico?.id]) {
            return {
              id: values.loteFabricante?.id,
              nome: covidImunosRecord[imunobiologico?.id],
            } as LoteImunobiologicoFabricanteSelectFieldModel
          } else return isRegistroAnterior ? null : values.loteFabricante
        },
      },
    },
    {
      field: meta.estrategiaVacinacao.absolutePath(),
      updates: {
        [meta.outrosImunosDose.absolutePath()]: (
          estrategiaVacinacao: EstrategiaVacinacaoSelectFieldModel,
          values: RegistroVacinacaoFormModel,
          prevValues: RegistroVacinacaoFormModel
        ) => {
          return estrategiaVacinacao !== prevValues.estrategiaVacinacao ? null : values.outrosImunosDose
        },
      },
    },
    {
      field: meta.outrosImunosDose.absolutePath(),
      updates: {
        [meta.dataAprazamentoProxDose.absolutePath()]: (
          outrosImunosDose: DoseImunobiologicoVacinacaoSelectFieldModel,
          values: RegistroVacinacaoFormModel,
          prevValues: RegistroVacinacaoFormModel
        ) => {
          return outrosImunosDose !== prevValues.outrosImunosDose ? null : values.dataAprazamentoProxDose
        },
      },
    }
  )
