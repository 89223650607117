import { Cell, Grid, Icon, Text, Tooltip } from 'bold-ui'
import { ButtonLink } from 'components/route'
import theme from 'config/theme'
import { useRouter } from 'hooks/useRouter'
import React from 'react'

interface GrupoExameTableHeaderProps {
  quantidade: number
}

export function GrupoExameTableHeader(props: GrupoExameTableHeaderProps) {
  const { match } = useRouter()

  const iconStyle = {
    display: 'block',
    '&:hover': {
      color: theme.pallete.primary.main,
    },
  }

  const iconToolTipText =
    'Os grupos de exames são apresentados no módulo de Solicitação de exames no Atendimento. ' +
    'Estes grupos são filtrados de acordo com idade e sexo do cidadão em atendimento.'

  const isOverQuantity = props.quantidade >= 14

  const buttonToolTipText = isOverQuantity ? 'Não é possível adicionar mais de 14 grupos de exames' : ''

  const button = (
    <Cell size={6} style={{ textAlign: 'right' }}>
      <Tooltip text={buttonToolTipText} placement='auto'>
        <ButtonLink
          to={isOverQuantity ? match.url : `${match.url}/create`}
          kind='primary'
          size='small'
          disabled={isOverQuantity}
        >
          <Icon icon='plus' style={{ marginRight: '0.25rem' }} />
          <Text color='inherit'>Adicionar grupo de exames</Text>
        </ButtonLink>
      </Tooltip>
    </Cell>
  )

  return (
    <Grid alignItems='center'>
      <Cell size={6}>
        <Tooltip text={iconToolTipText} placement='auto'>
          <Icon style={iconStyle} icon='infoCircleFilled' size={1} />
        </Tooltip>
      </Cell>
      {button}
    </Grid>
  )
}
