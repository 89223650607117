import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import { dateFnsLocalizer } from 'react-big-calendar'

import { AgendaMessages, AgendaTooltips } from './model'

const locales = {
  'pt-BR': require('date-fns/locale/pt-BR'),
}

export const dateFnsDefaultLocale = locales['pt-BR']

export default dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const defaultTooltips: AgendaTooltips = {
  disabledPastSlot: 'Não é possível agendar em horários passados.',
  unavailableSlot: 'Não é possível agendar em horários sem configuração de agenda.',
  insufficientTime: 'Não é possível agendar em horários menores que a duração padrão de agendamento.',
}

export const defaultMessages: AgendaMessages = {
  unavailableSlot: 'Agenda fechada [Profissional sem agenda configurada neste período].',
  insufficientTime: 'Não possui duração mínima para realizar um agendamento.',
  tooltips: defaultTooltips,
}
