import React from 'react'

import { ErrorGroup, ErrorItem, ErrorsBox, ValidationError, ValidationErrorsField } from './ErrorsBox'
import { LotacaoCnesModel, ProfissionalCnesModel, ProfissionalDetalhesImportacaoModel, ValidacaoModel } from './model'

interface LotacoesErrorsBoxProps {
  profissionais: ProfissionalDetalhesImportacaoModel[]
}

export const LotacoesErrorsBox = (props: LotacoesErrorsBoxProps) => {
  const { profissionais } = props
  if (profissionais.length === 0) {
    return null
  }

  const profissionaisComErroEmLotacao = profissionais.filter(
    (profissional) => profissional.lotacoes.filter((lotacao) => lotacao.validacoes).length !== 0
  )

  if (profissionaisComErroEmLotacao.length === 0) {
    return null
  }

  return (
    <ErrorsBox title='Lotações'>
      <ErrorGroup title='Faltam dados obrigatórios'>
        {profissionaisComErroEmLotacao.map((profissional) =>
          profissional.lotacoes
            .filter((lotacao) => lotacao.validacoes.faltamDadosObrigatorios.length > 0)
            .map((lotacao, index) => (
              <LotacaoErrorItem
                key={index}
                profissional={profissional.dados}
                lotacao={lotacao.dados}
                errors={lotacao.validacoes.faltamDadosObrigatorios}
              />
            ))
        )}
      </ErrorGroup>
      <ErrorGroup title='Campos preenchidos de forma incorreta'>
        {profissionaisComErroEmLotacao.map((profissional) =>
          profissional.lotacoes
            .filter((lotacao) => lotacao.validacoes.camposPreenchidosIncorretos.length > 0)
            .map((lotacao, index) => (
              <LotacaoErrorItem
                key={index}
                profissional={profissional.dados}
                lotacao={lotacao.dados}
                errors={lotacao.validacoes.camposPreenchidosIncorretos}
              />
            ))
        )}
      </ErrorGroup>
      <ErrorGroup title='Outras inconsistências'>
        {profissionaisComErroEmLotacao.map((profissional) =>
          profissional.lotacoes
            .filter((lotacao) => lotacao.validacoes.erro)
            .map((lotacao, index) => (
              <LotacaoErrorItem
                key={index}
                profissional={profissional.dados}
                lotacao={lotacao.dados}
                error={lotacao.validacoes.erro}
              />
            ))
        )}
      </ErrorGroup>
    </ErrorsBox>
  )
}

interface LotacaoErrorItemProps {
  profissional: ProfissionalCnesModel
  lotacao: LotacaoCnesModel
  error?: string
  errors?: ValidacaoModel[]
}

const LotacaoErrorItem = (props: LotacaoErrorItemProps) => {
  const { profissional, lotacao, error, errors } = props
  return (
    <ErrorItem>
      {profissional.nome || 'Nome não informado'}, CBO: {lotacao.cbo2002 || '-'}, INE: {lotacao.equipeIne || '-'}
      {error && <ValidationError error={error} />}
      {errors && <ValidationErrorsField errors={errors} />}
    </ErrorItem>
  )
}
