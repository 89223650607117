import { FormControl, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { Form, FormPrompt, NumberField, SubmitButton } from 'components/form'
import { useSalvarDuracaoAgendamentoAdMutation } from 'graphql/hooks.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, range, required } from 'util/validation'

export interface DuracaoAtendimentoADProps {
  duracao: number
  reload(): Promise<any>
}

interface DuracaoAgendamentoFormModel {
  duracao: number
}

export default function ConfiguracoesADDuracaoAgendamentoADForm(props: DuracaoAtendimentoADProps) {
  const { duracao, reload } = props
  const [salvarDuracao] = useSalvarDuracaoAgendamentoAdMutation()
  const alert = useAlert()

  const validator = createValidator<DuracaoAgendamentoFormModel>({
    duracao: [required, range(5, 240)],
  })

  const handleSubmit = (form: DuracaoAgendamentoFormModel) => {
    return salvarDuracao({ variables: { input: { duracaoAgendamento: form.duracao } } }).then(() => {
      alert('success', 'Duração do agendamento foi alterado com sucesso.')
      reload()
    })
  }

  const renderForm = (formProps: FormRenderProps<DuracaoAgendamentoFormModel>) => (
    <HFlow>
      <FormPrompt />
      <NumberField name='duracao' label='Duração do agendamento (min)' maxLength={3} required clearable={false} />
      <FormControl label={<span>&nbsp;</span>}>
        <SubmitButton kind='primary' size='small' handleSubmit={formProps.handleSubmit}>
          Salvar
        </SubmitButton>
      </FormControl>
    </HFlow>
  )

  return (
    <Box style={{ marginBottom: '2rem' }}>
      <Form<DuracaoAgendamentoFormModel>
        render={renderForm}
        onSubmit={handleSubmit}
        initialValues={{ duracao: duracao }}
        validate={validator}
      ></Form>
    </Box>
  )
}
