import { Alert, Cell } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps } from 'components/form/final-form/Form'
import { FormApi } from 'final-form'
import { ExecutionResult } from 'graphql'
import {
  useCadastrarEnderecoEnvioMutation,
  useCancelarSolicitacaoEnvioMutation,
  useDadosServidoresQuery,
  useInicarUnificacaoBaseMutation,
  usePedidoEnvioBaseAtivoQuery,
  usePedidosEnvioBaseQuery,
  useRefazerLoginMutation,
  useUnificacoesBaseQuery,
} from 'graphql/hooks.generated'
import {
  CadastrarEnderecoEnvioMutation,
  LocalidadeCnesDesatualizadoDto,
  RefazerLoginMutation,
  UnificacaoBaseOrigemBroadcastEnum,
} from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, maxLength, required } from 'util/validation'

import { EnvioDadosUnificacaoForm } from './EnvioDadosUnificacaoForm'
import { unificacaoAtivaList, UnificacaoBaseTopic } from './model'

interface EnvioDadosViewProps {
  conexaoInternet: boolean
  agendaOnlineAtiva: boolean
  topicMessage: UnificacaoBaseTopic
  setTopicMessage: (topic: UnificacaoBaseTopic) => void
}

export interface SolicitacaoEnvioModel {
  nomeInstalacao: string
  linkInstalacao?: string
  senhaInstalacao?: string
}

export const EnvioDadosView = (props: EnvioDadosViewProps) => {
  const { conexaoInternet, agendaOnlineAtiva, topicMessage, setTopicMessage } = props

  const path = metaPath<SolicitacaoEnvioModel>()
  const alert = useAlert()
  const handleRejection = useErrorHandler()
  const [isCamposAtivos, setIsCamposAtivos] = useState<boolean>(true)
  const [hasUnificacaoAtiva, setHasUnificacaoAtiva] = useState<boolean>(false)
  const [errorResponseMessage, setErrorResponseMessage] = useState<JSX.Element>()
  const [errorLoginMessage, setErrorLoginMessage] = useState<JSX.Element>()

  const [cadastrarPedidoEnvio, { loading: isLoadingCadastrarEndereco }] = useCadastrarEnderecoEnvioMutation()
  const [initialValues, setInitialValues] = useState<SolicitacaoEnvioModel>()
  const [cancelarSolicitacaoEnvio] = useCancelarSolicitacaoEnvioMutation()
  const [iniciarUnificacaoBase] = useInicarUnificacaoBaseMutation()
  const { data: dataPedidosEnvio, refetch } = usePedidosEnvioBaseQuery()

  const { data: dataServidor } = useDadosServidoresQuery({
    onCompleted: (data) => {
      setInitialValues({ nomeInstalacao: data.servidores?.dadosServidor.nomeInstalacao })
    },
  })
  const [loginAtivo, setLoginAtivo] = useState(true)
  const [refazLoginMutation, { loading: isLoadingRefazerLogin }] = useRefazerLoginMutation()

  const { data: pedidoAtivo, refetch: refetchPedidoAtivo } = usePedidoEnvioBaseAtivoQuery({
    onCompleted: (data) => {
      if (data.pedidoEnvioBaseAtivo) {
        setIsCamposAtivos(false)
        setLoginAtivo(!data.pedidoEnvioBaseAtivo.loginAtivo)
        setInitialValues({
          nomeInstalacao: data.pedidoEnvioBaseAtivo.nomeInstalacao,
          linkInstalacao: data.pedidoEnvioBaseAtivo.linkInstalacao,
        })
      }
    },
  })

  useEffect(() => {
    if (pedidoAtivo.pedidoEnvioBaseAtivo) {
      setLoginAtivo(pedidoAtivo.pedidoEnvioBaseAtivo.loginAtivo)
    }
  }, [pedidoAtivo.pedidoEnvioBaseAtivo])

  const { refetch: refetchUnificacoes } = useUnificacoesBaseQuery({
    onCompleted: (data) => {
      const unificacoesAtivas = data.unificacoesBase?.filter((item) => {
        return unificacaoAtivaList.includes(item.status)
      })
      setHasUnificacaoAtiva(unificacoesAtivas.length > 0)
    },
  })

  useEffect(() => {
    if (!!topicMessage?.origem) {
      switch (topicMessage?.origem) {
        case UnificacaoBaseOrigemBroadcastEnum.RECEBIMENTO:
          refetchUnificacoes()
            .then((response) => {
              const unificacoesAtivas = response.data.unificacoesBase?.filter((item) => {
                return unificacaoAtivaList.includes(item.status)
              })
              setHasUnificacaoAtiva(unificacoesAtivas.length > 0)
            })
            .catch(useErrorHandler)
          break
        case UnificacaoBaseOrigemBroadcastEnum.RECUSA:
          setIsCamposAtivos(true)
          refetch()
          setInitialValues({ nomeInstalacao: dataServidor.servidores?.dadosServidor.nomeInstalacao })
          break
        case UnificacaoBaseOrigemBroadcastEnum.ENVIO:
          refetch()
          refetchPedidoAtivo()
          break
        case UnificacaoBaseOrigemBroadcastEnum.LOGIN:
          setLoginAtivo(topicMessage.loginAtivo)
          break
      }
      setTopicMessage({ origem: undefined })
    }
  }, [dataServidor.servidores, refetch, refetchPedidoAtivo, refetchUnificacoes, setTopicMessage, topicMessage])

  const onSubmit = (values: SolicitacaoEnvioModel, formApi: FormApi<SolicitacaoEnvioModel>) => {
    if (!conexaoInternet) {
      alert(
        'warning',
        'Não é possível realizar a solicitação de envio de dados sem conexão com a internet. Habilite a conexão ou tente novamente mais tarde.'
      )
    } else if (agendaOnlineAtiva) {
      alert(
        'warning',
        'Não é possível realizar a solicitação de envio de dados se a agenda online estiver habilitada. Desabilite a agenda online.'
      )
    } else if (!loginAtivo) {
      refazLoginMutation({
        variables: {
          senha: values.senhaInstalacao,
        },
      })
        .then((data) => handleRefazLogin(data))
        .catch(handleRejection)
    } else {
      cadastrarPedidoEnvio({
        variables: {
          input: { ...values },
        },
      })
        .then((data) => handleCadastroPedidoEnvio(data, formApi))
        .catch(handleRejection)
    }
  }

  const handleRefazLogin = (data: ExecutionResult<RefazerLoginMutation>) => {
    if (data.data.refazLoginExpirado) {
      setErrorLoginMessage(errorLoginAlert(data.data.refazLoginExpirado))
    } else {
      alert('success', 'Login completo. O processo de unificação de bases será retomado.')
      setErrorLoginMessage(null)
      setIsCamposAtivos(false)
      setLoginAtivo(false)
      refetch()
      refetchPedidoAtivo()
    }
  }

  const handleCadastroPedidoEnvio = (
    data: ExecutionResult<CadastrarEnderecoEnvioMutation>,
    formApi: FormApi<SolicitacaoEnvioModel>
  ) => {
    if (data.data.cadastrarEnderecoEnvio.errorImportacaoCnes) {
      setErrorResponseMessage(errorImportacaoCnesAlert(data.data.cadastrarEnderecoEnvio.errorImportacaoCnes))
      setErrorLoginMessage(null)
    } else if (data.data.cadastrarEnderecoEnvio.errorLogin) {
      setErrorResponseMessage(null)
      setErrorLoginMessage(errorLoginAlert(data.data.cadastrarEnderecoEnvio.errorLogin))
    } else {
      alert('success', 'Solicitação enviada com sucesso.')
      setErrorResponseMessage(null)
      setErrorLoginMessage(null)
      setIsCamposAtivos(false)
      formApi.change(path.senhaInstalacao.alias, null)
      formApi.resetFieldState(path.senhaInstalacao.alias)
      refetch()
    }
  }

  const validator = createValidator<SolicitacaoEnvioModel>(
    {
      nomeInstalacao: [required, maxLength(255)],
      linkInstalacao: [required, maxLength(255)],
      senhaInstalacao: [required, maxLength(255)],
    },
    (values: SolicitacaoEnvioModel, errors: ErrorObject<SolicitacaoEnvioModel>) => {
      if (!values.senhaInstalacao && !loginAtivo) {
        errors.senhaInstalacao = 'Sessão expirada. Insira a senha novamente para continuar.'
      }

      return errors
    }
  )

  const renderForm = (formProps: FormRenderProps<SolicitacaoEnvioModel>) => {
    const onCancelarSolicitacao = (solicitacaoId) => {
      cancelarSolicitacaoEnvio({ variables: { input: { solicitacaoId: solicitacaoId } } })
        .then(() => {
          setIsCamposAtivos(true)
          refetch()
          setInitialValues({ nomeInstalacao: dataServidor.servidores?.dadosServidor.nomeInstalacao })
          formProps.form.reset()
          formProps.form.getRegisteredFields().forEach((field) => formProps.form.resetFieldState(field))
        })
        .catch((error) => {
          handleRejection(error)
          refetch()
          refetchPedidoAtivo()
        })
    }

    const onUnificarBase = () => {
      iniciarUnificacaoBase().then(refetch).catch(handleRejection)
    }

    return (
      <EnvioDadosUnificacaoForm
        hasUnificacaoAtiva={hasUnificacaoAtiva}
        errorResponseMessage={errorResponseMessage}
        isCamposAtivos={isCamposAtivos}
        loginAtivo={loginAtivo}
        errorLoginMessage={errorLoginMessage}
        formProps={formProps}
        onUnificarBase={onUnificarBase}
        onCancelarSolicitacao={onCancelarSolicitacao}
        dataPedidosEnvio={dataPedidosEnvio}
        isLoading={isLoadingCadastrarEndereco || isLoadingRefazerLogin}
      />
    )
  }

  return (
    <Form<SolicitacaoEnvioModel>
      onSubmit={onSubmit}
      render={renderForm}
      validate={validator}
      initialValues={initialValues}
      {...props}
    />
  )
}

const errorImportacaoCnesAlert = (error: LocalidadeCnesDesatualizadoDto) => {
  return (
    <Cell size={12}>
      <Alert type='danger' inline>
        <p>
          Para solicitar o envio, atualize os seguintes CNES:
          {error.instalacaoLocal && (
            <span>
              <br />
              <b>Instalação local: </b>
              {error.instalacaoLocal}
            </span>
          )}
          {error.instalacaoCentral && (
            <span>
              <br />
              <b>Instalação central do município: </b>
              {error.instalacaoCentral}
            </span>
          )}
        </p>
      </Alert>
    </Cell>
  )
}

const errorLoginAlert = (error: String[]) => {
  return (
    <Cell size={12}>
      <Alert type='danger' inline>
        {error}
      </Alert>
    </Cell>
  )
}
