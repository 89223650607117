import { StyleSheet } from '@react-pdf/renderer'
import { PrintHFlow, PrintLabel, PrintText, PrintTitle } from 'components/print'
import { PrintBox } from 'components/print/components/PrintBox'
import React from 'react'
import { calcularIMC } from 'util/atendimento'
import { parseNumber } from 'util/number'
import { isEmpty } from 'util/validation/Util'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { historicoTipoGlicemiaRecord } from 'view/atendimentos/detail/historico/model/model-historico'

interface ImpressaoGrupoMedicoesProps {
  medicoes: MedicoesPanelModel
}

export const ImpressaoGrupoMedicoes = (props: ImpressaoGrupoMedicoesProps) => {
  const { medicoes } = props

  const isMedicoesEmpty = Object.values(medicoes).every((value) => isEmpty(value) || Array.isArray(value))

  return (
    <>
      <PrintTitle level={4} title='Medições' align='left' underline />
      <PrintBox>
        {isMedicoesEmpty ? (
          <PrintText textStyles={{ fontFamily: 'Lato Italic' }}>
            Não foram realizadas medições neste atendimento
          </PrintText>
        ) : (
          <PrintHFlow styles={{ flexWrap: 'wrap' }}>
            {!isEmpty(medicoes.peso) && (
              <PrintLabel title='Peso' value={`${medicoes.peso.replace('.', ',')} kg`} styles={styles.label} />
            )}
            {!isEmpty(medicoes.altura) && (
              <PrintLabel title='Altura' value={`${medicoes.altura.replace('.', ',')} cm`} styles={styles.label} />
            )}
            {!isEmpty(medicoes.altura) && !isEmpty(medicoes.peso) && (
              <PrintLabel
                title='IMC'
                value={`${calcularIMC(parseNumber(medicoes.peso), parseNumber(medicoes.altura))} Kg/m²`.replace(
                  '.',
                  ','
                )}
                styles={styles.label}
              />
            )}
            {!isEmpty(medicoes.perimetroCefalico) && (
              <PrintLabel
                title='Per. cefálico'
                value={`${medicoes.perimetroCefalico.replace('.', ',')} cm`}
                styles={styles.label}
              />
            )}
            {!isEmpty(medicoes.circunferenciaAbdominal) && (
              <PrintLabel
                title='Circ. abdominal'
                value={`${medicoes.circunferenciaAbdominal.replace('.', ',')} cm`}
                styles={styles.label}
              />
            )}
            {!isEmpty(medicoes.perimetroPanturrilha) && (
              <PrintLabel
                title='Per. da panturrilha'
                value={`${medicoes.perimetroPanturrilha.replace('.', ',')} cm`}
                styles={styles.label}
              />
            )}
            {!isEmpty(medicoes.pressaoArterialSistolica) && !isEmpty(medicoes.pressaoArterialDiastolica) && (
              <PrintLabel
                title='Pressão arterial'
                value={`${medicoes.pressaoArterialSistolica}/${medicoes.pressaoArterialDiastolica} mmHg`}
                styles={styles.label}
              />
            )}

            {!isEmpty(medicoes.frequenciaRespiratoria) && (
              <PrintLabel
                title='Freq. respiratória'
                value={`${medicoes.frequenciaRespiratoria} mpm`}
                styles={styles.label}
              />
            )}
            {!isEmpty(medicoes.frequenciaCardiaca) && (
              <PrintLabel title='Freq. cardíaca' value={`${medicoes.frequenciaCardiaca} bpm`} styles={styles.label} />
            )}
            {!isEmpty(medicoes.temperatura) && (
              <PrintLabel
                title='Temperatura'
                value={`${medicoes.temperatura.replace('.', ',')} ºC`}
                styles={styles.label}
              />
            )}
            {!isEmpty(medicoes.saturacaoO2) && (
              <PrintLabel title='Saturação de O2' value={`${medicoes.saturacaoO2}%`} styles={styles.label} />
            )}
            {!isEmpty(medicoes.glicemia) && (
              <PrintLabel title='Glicemia capilar' value={`${medicoes.glicemia} mg/dL`} styles={styles.label} />
            )}
            {!isEmpty(medicoes.tipoGlicemia) && (
              <PrintLabel
                title='Momento da coleta (glicemia)'
                value={historicoTipoGlicemiaRecord[medicoes.tipoGlicemia]}
                styles={styles.label}
              />
            )}
          </PrintHFlow>
        )}
      </PrintBox>
    </>
  )
}

const styles = StyleSheet.create({
  label: { minWidth: '140rem' },
})
