import { isSameDay } from 'date-fns'
import { GrupoMedicoesCardFragment, MedicoesCardQuery, TipoMedicaoEnum } from 'graphql/types.generated'
import { calcularIMC } from 'util/atendimento'

const antecendentesToMedicaoRecord: Partial<Record<
  TipoMedicaoEnum,
  keyof MedicoesCardQuery['medicoesNoNascimento'] | 'imc'
>> = {
  ALTURA: 'altura',
  PESO: 'peso',
  PERIMETRO_CEFALICO: 'perimetroCefalico',
  IMC: 'imc',
}

export function mergeMedicoesNoNascimento(
  medicoes: GrupoMedicoesCardFragment[],
  medicoesNascimento: MedicoesCardQuery['medicoesNoNascimento'],
  dataNascimentoCidadao: Instant
): GrupoMedicoesCardFragment[] {
  if (!medicoesNascimento) return medicoes

  const medicoesClone = [...(medicoes ?? [])]
  const medicoesNascimentoClone = { ...medicoesNascimento }
  if (medicoesNascimentoClone.altura && medicoesNascimentoClone.peso)
    medicoesNascimentoClone['imc'] = calcularIMC(
      parseFloat(medicoesNascimentoClone.peso),
      parseFloat(medicoesNascimentoClone.altura)
    )?.toString()

  const medicoesAdicionar = new Set(
    Object.keys(antecendentesToMedicaoRecord).filter(
      (key) => !!medicoesNascimentoClone[antecendentesToMedicaoRecord[key]]
    ) as TipoMedicaoEnum[]
  )

  for (let index = 0; index < medicoesClone.length && medicoesAdicionar.size > 0; index++) {
    medicoesClone[index] = { ...medicoesClone[index] }
    const grupoMedicoes = medicoesClone[index]

    const keyMedicaoNascimento = antecendentesToMedicaoRecord[grupoMedicoes.tipo]
    const medicao = keyMedicaoNascimento && medicoesNascimentoClone[keyMedicaoNascimento]
    const areMedicoesNoDiaNascimento = grupoMedicoes.medicoes.some((e) =>
      isSameDay(e.dataMedicao, dataNascimentoCidadao)
    )

    if (medicao && areMedicoesNoDiaNascimento) {
      grupoMedicoes.medicoes = [
        ...grupoMedicoes.medicoes,
        {
          dataMedicao: dataNascimentoCidadao,
          valor: parseFloat(medicao).toLocaleString('pt-BR'),
        },
      ]
    }

    medicoesAdicionar.delete(grupoMedicoes.tipo)
  }

  medicoesAdicionar.forEach((tipo) => {
    const medicao = medicoesNascimentoClone[antecendentesToMedicaoRecord[tipo]]
    medicoesClone.push({
      tipo: tipo,
      medicoes: [
        {
          dataMedicao: dataNascimentoCidadao,
          valor: parseFloat(medicao).toLocaleString('pt-BR'),
        },
      ],
    })
  })

  return medicoesClone
}
