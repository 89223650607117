import { Cell, Grid, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { RichTextField } from 'components/form'
import { VigilanciaEmSaudeBucalEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { MetaPath } from 'util/metaPath'
import { CiapCidPreNatal } from 'view/atendimentos/atendimento-individual/model'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { Problema } from '../aside/types/ProblemaModel'
import HeadingFormControl from '../plano/components/HeadingFormControl'
import { grupoCboPreNatal } from '../pre-natal/acessos'
import { EncerrarGestacaoForm } from '../pre-natal/EncerrarGestacaoForm'
import { EncerrarGestacaoFormModel, MetasPreNatal, TipoPreNatal } from '../pre-natal/model-preNatal'
import { PreNatalField } from '../pre-natal/PreNatalField'
import { grupoCboAlergias } from './acessos'
import { AlergiasReacoesField } from './components/alergias-reacoes/AlergiasReacoesField'
import { AlergiaReacaoModel } from './components/alergias-reacoes/model'
import { NecessidadeProteseEnum } from './components/necessidade-protese/model'
import { NecessidadeProteseField } from './components/necessidade-protese/NecessidadeProteseField'
import { ProblemaCondicaoModel } from './components/problemas-condicoes/model-problemasCondicoes'
import { ProblemasCondicoesField } from './components/problemas-condicoes/ProblemasCondicoesField'
import { hasProblemaNaoEvolucaoEncerraGestacao } from './components/problemas-condicoes/utils/verifications-problemasCondicoes'
import { VigilanciaField } from './components/VigilanciaField'

export interface AvaliacaoFormModel {
  texto?: string
  problemasECondicoes?: ProblemaCondicaoModel[]
  alergias?: AlergiaReacaoModel[]
  tiposVigilancia?: VigilanciaEmSaudeBucalEnum[]
  necessidadeProtese?: NecessidadeProteseEnum
  openedAccordions?: string[]
  encerrarGestacao?: EncerrarGestacaoFormModel
}

export interface AvaliacaoFormProps {
  name: MetaPath<AvaliacaoFormModel>
  metasPreNatal: Pick<MetasPreNatal, 'metaPreNatal'>
  tipoPreNatal: TipoPreNatal
  cidadao: CidadaoAtendimento
  isAtendimentoOdonto: boolean
  prontuarioId: ID
  dataAtendimento: Instant
  ciapCidPreNatal: CiapCidPreNatal
  somenteCiap: boolean
  problemasAvaliacao?: ProblemaCondicaoModel[]
  problemasAtivosLatentes: Problema[]
  hasProblemaComCiapW78AtivoPersistido: boolean
}

function AvaliacaoForm(props: AvaliacaoFormProps) {
  const {
    name,
    metasPreNatal,
    tipoPreNatal,
    cidadao,
    isAtendimentoOdonto,
    prontuarioId,
    dataAtendimento,
    ciapCidPreNatal,
    somenteCiap,
    problemasAvaliacao,
    problemasAtivosLatentes,
    hasProblemaComCiapW78AtivoPersistido,
  } = props

  const alert = useAlert()

  const alertAvaliacaoAutomaticaAlterada = () => {
    alert('info', 'Uma avaliação automática foi alterada na lista de problemas e/ou condições avaliados.')
  }

  const showEncerrarGestacaoForm = useMemo(
    () =>
      tipoPreNatal === TipoPreNatal.ENCERRAMENTO_GESTACAO ||
      (tipoPreNatal === TipoPreNatal.W78_RESOLVIDO_NA_AVALIACAO &&
        hasProblemaNaoEvolucaoEncerraGestacao(problemasAvaliacao)),
    [problemasAvaliacao, tipoPreNatal]
  )

  return (
    <VFlow>
      <RichTextField
        name={name.texto}
        maxLength={4000}
        placeholder='Insira impressões adicionais sobre a avaliação dos problemas/condições detectados.'
      />

      <HeadingFormControl label='Problemas e/ou condições avaliados neste atendimento' required level='h3'>
        <Grid>
          <Cell size={12}>
            <ProblemasCondicoesField
              name={name.problemasECondicoes}
              somenteCiap={somenteCiap}
              prontuarioId={prontuarioId}
              dataAtendimento={dataAtendimento}
              ciapCidPreNatal={ciapCidPreNatal}
              tipoPreNatal={tipoPreNatal}
              onAutomaticChange={alertAvaliacaoAutomaticaAlterada}
              problemasAtivosLatentes={problemasAtivosLatentes}
              hasProblemaComCiapW78AtivoPersistido={hasProblemaComCiapW78AtivoPersistido}
            />
          </Cell>

          <CheckPermission permission={grupoCboPreNatal}>
            {tipoPreNatal === TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL ? (
              <Cell size={12}>
                <PreNatalField name={metasPreNatal.metaPreNatal} tipoPreNatal={tipoPreNatal} />
              </Cell>
            ) : (
              showEncerrarGestacaoForm && (
                <Cell size={12}>
                  <EncerrarGestacaoForm meta={name.encerrarGestacao} />
                </Cell>
              )
            )}
          </CheckPermission>

          <CheckPermission permission={grupoCboAlergias.adicionarEditarExcluir}>
            <Cell size={12}>
              <AlergiasReacoesField
                name={name}
                prontuarioId={prontuarioId}
                cidadao={cidadao}
                dataAtendimento={dataAtendimento}
              />
            </Cell>
          </CheckPermission>

          {isAtendimentoOdonto && (
            <>
              <Cell size={12}>
                <VigilanciaField name={name.tiposVigilancia} />
              </Cell>

              <Cell size={12}>
                <NecessidadeProteseField name={name.necessidadeProtese} />
              </Cell>
            </>
          )}
        </Grid>
      </HeadingFormControl>
    </VFlow>
  )
}

export default React.memo(AvaliacaoForm)
