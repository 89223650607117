import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { useAceitarSolicitacaoMutation, useNegarSolicitacaoMutation } from 'graphql/hooks.generated'
import React from 'react'
export declare type AvaliarSolicitacao = 'aceitar' | 'negar'

interface ModalAvaliarSolicitacaoProps {
  isModalOpen: boolean
  setIsModalOpen(isModalOpen: boolean): void
  onAvaliarSolicitacao(): void
  avaliarSolicitacao: AvaliarSolicitacao
  unificacaoBaseId: string
}

export const ModalAvaliarSolicitacao = (props: ModalAvaliarSolicitacaoProps) => {
  const { isModalOpen, setIsModalOpen, onAvaliarSolicitacao, avaliarSolicitacao, unificacaoBaseId } = props
  const handleRejection = useErrorHandler()
  const handleFecharModal = () => setIsModalOpen(false)
  const [aceitarSolicitacao] = useAceitarSolicitacaoMutation()
  const handleAceitarSolicitacao = () => {
    aceitarSolicitacao({ variables: { id: unificacaoBaseId } })
      .then(() => {
        onAvaliarSolicitacao()
        handleFecharModal()
      })
      .catch(handleRejection)
  }
  const [negarSolicitacao] = useNegarSolicitacaoMutation()
  const handleNegarSolicitacao = () => {
    negarSolicitacao({ variables: { id: unificacaoBaseId } })
      .then(() => {
        onAvaliarSolicitacao()
        handleFecharModal()
      })
      .catch(handleRejection)
  }

  return (
    <Modal open={isModalOpen} onClose={handleFecharModal} size='small' closeOnBackdropClick={true} hasCloseIcon={true}>
      <ModalBody>
        <HFlow alignItems='flex-start'>
          <Icon icon='infoCircleOutline' fill='info' size={3} />
          <VFlow>
            <Heading level={2}>
              {avaliarSolicitacao === 'aceitar'
                ? 'Deseja aceitar a solicitação de envio de base?'
                : 'Deseja negar a solicitação de envio de base?'}
            </Heading>
            <Text>
              {avaliarSolicitacao === 'aceitar'
                ? 'Ao aceitar a solicitação, os dados da instalação local serão enviados para esta instalação central.'
                : 'Ao negar a solicitação de envio, a instalação local não conseguirá enviar os dados para esta instalação central.'}
            </Text>
          </VFlow>
        </HFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' onClick={handleFecharModal}>
            Não, voltar
          </Button>
          {avaliarSolicitacao === 'aceitar' ? (
            <Button kind='primary' onClick={handleAceitarSolicitacao}>
              Sim, aceitar solicitação
            </Button>
          ) : (
            <Button kind='primary' onClick={handleNegarSolicitacao}>
              Sim, negar solicitação
            </Button>
          )}
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
