import { Link, Theme, useStyles } from 'bold-ui'
import AlertTreinamento from 'components/AlertTreinamento'
import useSession from 'components/auth/useSession'
import { PageContainer } from 'components/layout/PageContainer'
import { SIDE_MENU_WIDTH } from 'components/layout/SideMenu'
import { UserMenu } from 'components/user/UserMenu'
import logo from 'images/esus_white.svg'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export const UserHeader = () => {
  const { classes } = useStyles(createStyles)

  const { data } = useSession({ fetchPolicy: 'cache-only' })

  if (!data || !data.acesso) {
    return null
  }

  const { acesso } = data

  return (
    <header>
      <div className={classes.container}>
        <PageContainer fluid>
          <div className={classes.content}>
            <Link component={RouterLink} to={acesso.moduloInicial}>
              <img className={classes.image} src={logo} alt='Logo e-SUS Atenção Primária' />
            </Link>
            <div style={{ flexGrow: 1.0 }} />
            <AlertTreinamento showProducao={false} />
            {acesso && (
              <div className={classes.rightContent}>
                <UserMenu />
              </div>
            )}
          </div>
        </PageContainer>
      </div>
    </header>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    borderBottom: '1px solid ' + theme.pallete.divider,
    background: theme.pallete.primary.c30,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: SIDE_MENU_WIDTH,
    },
  },
  content: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  image: {
    height: '35px',
  },
  rightContent: {
    display: 'flex',
  },
})
