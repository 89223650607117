/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell } from 'bold-ui'
import { StatusAvaliadoEnum } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'

import StatusDesenvolvimentoAlert from '../components/StatusDesenvolvimentoAlert'
import { AvaliacaoDesenvolvimentoModel } from '../model'
import { marcoMessages } from './messages'

interface ReflexosNeuroAlertProps {
  reflexosNeuroList: AvaliacaoDesenvolvimentoModel[]
  isAcompanhamentoCard?: boolean
}

export function ReflexosNeuroAlert(props: ReflexosNeuroAlertProps) {
  const { reflexosNeuroList, isAcompanhamentoCard } = props

  const reflexosNeuroAusente = useMemo(() => avaliacaoAusenteList(reflexosNeuroList), [reflexosNeuroList])
  const reflexosNeuroMessage = isAcompanhamentoCard
    ? marcoMessages(reflexosNeuroAusente?.length).reflexosAcompanhamento
    : marcoMessages(reflexosNeuroAusente?.length).reflexosNeuro

  return (
    !isEmpty(reflexosNeuroAusente) && (
      <Cell size={12}>
        <StatusDesenvolvimentoAlert
          type='S2'
          title={reflexosNeuroMessage}
          messages={reflexosNeuroAusente.join(', ').concat('.')}
          isAcompanhamentoCard={isAcompanhamentoCard}
        />
      </Cell>
    )
  )
}

const avaliacaoAusenteList = (avaliacoes: AvaliacaoDesenvolvimentoModel[]) =>
  avaliacoes
    ?.filter((row: AvaliacaoDesenvolvimentoModel) => row.statusButton === StatusAvaliadoEnum.AUSENTE)
    .sort((a, b) => Number(a.id) - Number(b.id))
    .map((row) => row.descricao)
