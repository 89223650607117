import React from 'react'

import { TextField, TextFieldProps } from '../TextField'

export type NumberFieldProps = TextFieldProps

export function NumberField(props: NumberFieldProps) {
  return <TextField parse={parse} {...props} />
}

const parse = (value) => value.replace(/\D/g, '')
