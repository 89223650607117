/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, DateRange, HFlow, Icon, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { Form, SubmitButton } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import {
  TiposOrigemDadoTransporteSelectField,
  TiposOrigemDadoTransporteSelectFieldModel,
} from 'components/form/field/select/TiposOrigemDadoTransporteSelectField/TiposOrigemDadoTransporteSelectField'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

export interface TransmissaoRecebimentoLoteFilterPopperModel {
  periodo?: DateRange
  origensLote?: TiposOrigemDadoTransporteSelectFieldModel[]
}

interface TransmissaoRecebimentoLoteFilterPopperProps {
  initialValues: TransmissaoRecebimentoLoteFilterPopperModel
  onClose: () => void
  onReset: () => void
  onSubmit: (values: TransmissaoRecebimentoLoteFilterPopperModel) => void
}

const path = metaPath<TransmissaoRecebimentoLoteFilterPopperModel>()

export function TransmissaoRecebimentoLoteFilterPopper(props: TransmissaoRecebimentoLoteFilterPopperProps) {
  const { initialValues, onReset, onClose, onSubmit } = props

  const renderForm = (formProps: FormRenderProps<any>) => (
    <Box
      style={css`
        width: 25rem;
      `}
    >
      <VFlow>
        <DateRangeField label='Período' name={path.periodo} />

        <TiposOrigemDadoTransporteSelectField name={path.origensLote} multiple />

        <HFlow justifyContent='space-between'>
          <Button onClick={onClose} size='small' skin='outline' kind='normal'>
            Fechar
          </Button>
          <HFlow justifyContent='flex-end'>
            <Button onClick={onReset} size='small' skin='outline' kind='normal'>
              <Icon
                icon='redo'
                style={css`
                  padding-right: 0.5rem;
                `}
              />
              Voltar para padrão
            </Button>
            <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
              Filtrar
            </SubmitButton>
          </HFlow>
        </HFlow>
      </VFlow>
    </Box>
  )

  return <Form render={renderForm} onSubmit={onSubmit} initialValues={initialValues} />
}
