import { Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { EvolucaoPlanoCiap } from 'graphql/types.generated'
import React, { Fragment } from 'react'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'
import { showCodigoProcedimento } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util-resultadosExames'

interface HistoricoPlanoPecIntervencoesProcedimentosPanelProps {
  intervencoesProcedimentos?: EvolucaoPlanoCiap[]
}

export default function HistoricoPlanoPecIntervencoesProcedimentosPanel({
  intervencoesProcedimentos,
}: HistoricoPlanoPecIntervencoesProcedimentosPanelProps) {
  return (
    <HistoricoDetailTable hasLines>
      <VFlow vSpacing={0}>
        {intervencoesProcedimentos.map(({ id, ciap, procedimento, nota }: EvolucaoPlanoCiap) => (
          <Fragment key={id}>
            {ciap && (
              <Text>
                {ciap.descricao} - {ciap.codigo}
              </Text>
            )}
            {procedimento && (
              <Text>
                {procedimento.descricao} - {showCodigoProcedimento(procedimento.codigo)}
              </Text>
            )}
            {(ciap || procedimento) && nota && <HLabel title='Observações:'>{nota}</HLabel>}
          </Fragment>
        ))}
      </VFlow>
    </HistoricoDetailTable>
  )
}
