import createDecorator from 'final-form-calculate'
import { MetaPath, MetaRoot } from 'util/metaPath'

import { GarantiaAcessoAgendaModel } from './GarantiaAcessoAgendarConsultaModalForm'

export const createAgendarConsultaCalculations = (
  name: MetaPath<GarantiaAcessoAgendaModel> | MetaRoot<GarantiaAcessoAgendaModel>
) =>
  createDecorator(
    {
      field: name.agendamentoConsultaInput.lotacao.absolutePath(),
      updates: {
        [name.agendamentoConsultaInput.data.absolutePath()]: (
          lotacaoValue: string,
          allValues: GarantiaAcessoAgendaModel
        ) => lotacaoValue && allValues.agendamentoConsultaInput.data,
      },
    },
    {
      field: name.agendamentoConsultaInput.data.absolutePath(),
      updates: {
        [name.agendamentoConsultaInput.horario.absolutePath()]: (
          dataValue: string,
          allValues: GarantiaAcessoAgendaModel
        ) => dataValue && allValues.agendamentoConsultaInput.horario,
      },
    },
    {
      field: name.lotacaoResponsavel.absolutePath(),
      updates: {
        [name.agendamentoConsultaCompartilhadaInput.data.absolutePath()]: (
          lotacaoResponsavelValue: string,
          allValues: GarantiaAcessoAgendaModel
        ) => lotacaoResponsavelValue && allValues.agendamentoConsultaCompartilhadaInput.data,
      },
    },
    {
      field: name.agendamentoConsultaCompartilhadaInput.lotacaoConvidada.absolutePath(),
      updates: {
        [name.agendamentoConsultaCompartilhadaInput.data.absolutePath()]: (
          lotacaoConvidadaValue: string,
          allValues: GarantiaAcessoAgendaModel
        ) => lotacaoConvidadaValue && allValues.agendamentoConsultaCompartilhadaInput.data,
      },
    }
  )
