import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'
import { UnidadeSaudeDetailRootView } from 'view/unidade-saude/detail/UnidadeSaudeDetailRootView'

import { UnidadeSaudeListView } from './list/UnidadeSaudeListView'

export function UnidadeSaudeRootView() {
  const { match } = useRouter()

  return (
    <>
      <Breadcrumb title='Unidades de saúde' />

      <PecSwitch>
        <PrivateRoute
          exact
          path={`${match.url}`}
          component={UnidadeSaudeListView}
          permission={Permissions.visualizarUnidadesDeSaude}
        />
        <PrivateRoute
          path={`${match.url}/:unidadeSaudeId(\\d+)`}
          component={UnidadeSaudeDetailRootView}
          permission={Permissions.visualizarUnidadesDeSaude}
        />
      </PecSwitch>
    </>
  )
}
