import { css } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import React from 'react'

interface ModalInformarLoginGovBrProps {
  onClose(): void
  onConfirm(): void
  open: boolean
}

export function ModalInformarLoginGovBr({ open, onClose, onConfirm }: ModalInformarLoginGovBrProps) {
  return (
    <Modal open={open} onClose={onClose} size='small'>
      <ModalBody>
        <HFlow>
          <Icon icon='exclamationTriangleOutline' fill='alert' size={3} />
          <VFlow>
            <Heading style={classes.heading} level={2}>
              Login gov.br expirado
            </Heading>
            <Text>
              Para que o cadastro do cidadão sincronize com o CADSUS, é necessário estar com o login do gov.br válido.
              <br />
              Realize o login através do botão <b>"Entrar com gov.br".</b>
              <br />
              <br />
              <b>
                Atenção: Ao continuar sem login, as alterações realizadas no PEC não serão sincronizadas com o CADSUS.
              </b>
            </Text>
          </VFlow>
        </HFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button style={classes.button} onClick={onClose} kind='normal'>
            Cancelar
          </Button>
          <Button onClick={onConfirm} kind='danger'>
            Continuar sem login
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const classes = {
  heading: css`
    padding-top: 0.5rem;
  `,
  button: css`
    width: 9rem;
  `,
}
