/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente14(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='38' height='110' viewBox='0 0 38 110' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M6.13054 77.4149C6.02112 77.4242 5.91915 77.4328 5.82503 77.4407C5.91694 77.1594 6.02605 76.8 6.14314 76.3623C6.46533 75.1581 6.84783 73.3617 7.09867 70.9714C7.59603 66.2319 8.40213 55.3018 8.9043 45.8934C9.4067 36.4805 9.46208 12.8267 8.90275 6.95794C8.76744 5.53821 8.86591 4.46399 9.11062 3.72565C9.35497 2.9884 9.71805 2.65126 10.0824 2.54473C10.4597 2.4344 11.0047 2.52109 11.7123 2.99902C12.4136 3.47273 13.2114 4.29202 14.0434 5.50121C15.7095 7.92275 18.2272 13.4022 20.5476 20.1313C22.8636 26.8477 24.9642 34.7542 25.8172 42.0079C25.8923 42.6462 25.9619 43.2811 26.0264 43.912C26.6618 50.1242 26.7636 56.0539 26.8482 60.977C26.8572 61.499 26.866 62.0097 26.8752 62.5082C26.923 65.1024 26.9824 67.3769 27.1425 69.1894C27.2226 70.0962 27.3288 70.8988 27.4744 71.5748C27.6188 72.2449 27.8084 72.8228 28.0719 73.2597C28.1067 73.3175 28.0997 73.333 28.0888 73.3568L28.0878 73.3591C28.0602 73.42 27.9718 73.5348 27.7472 73.6868C27.3024 73.9879 26.5315 74.2983 25.4862 74.6024C23.4147 75.205 20.46 75.7299 17.4216 76.1618C14.3894 76.5928 11.297 76.9282 8.96126 77.1559C7.79375 77.2697 6.8161 77.3565 6.13054 77.4149Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M2.72103 94.5012C2.44836 93.4506 2.43997 91.8927 2.63073 90.0308C2.82008 88.1826 3.19963 86.086 3.66821 83.9854C4.60563 79.783 5.89032 75.6041 6.68544 73.4359C6.84141 73.0106 7.19457 72.5743 7.76046 72.1475C8.32329 71.723 9.06718 71.3304 9.9515 70.9841C11.7199 70.2918 13.9894 69.8084 16.3364 69.6067C18.6831 69.405 21.0786 69.4874 23.098 69.9079C25.1372 70.3326 26.7004 71.0824 27.5009 72.1358C29.2735 74.4683 31.2781 78.8252 32.8441 83.273C34.4121 87.7265 35.5001 92.1572 35.5001 94.6268C35.5001 95.7567 34.9696 97.0263 33.9929 98.3318C33.0211 99.6308 31.6388 100.923 30.0163 102.086C26.7643 104.418 22.6268 106.179 19.07 106.472C15.5906 106.759 11.7958 104.908 8.68354 102.351C7.13664 101.079 5.78039 99.6509 4.74116 98.2656C3.69678 96.8734 2.9942 95.5537 2.72103 94.5012Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.21602 73.2638C6.24819 73.1761 6.28631 73.0894 6.33015 73.0037C6.42964 72.3694 6.52214 71.6746 6.60141 70.9192C7.09747 66.192 7.90303 55.2716 8.40501 45.8668C8.90699 36.462 8.96082 12.8372 8.40501 7.00541C7.8492 1.17363 11.0429 0.258232 14.4553 5.21781C17.8677 10.1774 24.5967 27.349 26.3138 41.9495C26.3892 42.5905 26.459 43.2279 26.5238 43.8612C27.1615 50.0957 27.2636 56.0463 27.3481 60.9678C27.4363 66.1019 27.5052 70.1161 28.1428 72.1644C31.7379 77.1953 36.0001 89.6182 36.0001 94.6269C36.0001 99.7492 26.4501 106.365 19.1111 106.97C11.7722 107.576 3.42129 99.1896 2.23707 94.6269C1.05284 90.0641 4.61002 77.6431 6.21602 73.2638ZM29.9757 71.2955C31.8772 74.0712 33.8282 78.4857 35.3088 82.7476C36.8312 87.1296 38.0001 91.7612 38.0001 94.6269C38.0001 96.5102 37.1379 98.3014 35.9947 99.8294C34.8319 101.384 33.2454 102.847 31.4732 104.118C27.9571 106.639 23.3957 108.624 19.2757 108.964C14.8459 109.329 10.4129 107.008 7.09624 104.282C5.40106 102.889 3.90491 101.317 2.74135 99.7659C1.59835 98.2422 0.688818 96.6227 0.301206 95.1293C-0.0883946 93.6282 -0.0534303 91.7007 0.143754 89.776C0.34661 87.796 0.747497 85.596 1.22818 83.4411C2.1886 79.1356 3.5038 74.8507 4.3383 72.5752C4.35629 72.5261 4.37512 72.4776 4.39474 72.4296C4.47374 71.9007 4.54757 71.3276 4.61233 70.7105C5.10324 66.0324 5.90665 55.1505 6.40785 45.7602C6.9081 36.3877 6.95575 12.8791 6.41403 7.19516C6.26051 5.58436 6.34358 4.1279 6.73757 2.93917C7.133 1.74607 7.94937 0.563781 9.38075 0.145234C10.7603 -0.258142 12.0889 0.236576 13.1116 0.927362C14.1593 1.63504 15.1653 2.72141 16.1029 4.08415C17.9695 6.79695 20.5754 12.5431 22.911 19.3163C25.2642 26.1403 27.4196 34.2292 28.3001 41.7159C28.3767 42.3673 28.4477 43.0148 28.5134 43.6577C29.1597 49.9766 29.2631 55.9971 29.3474 60.9067L29.3479 60.9334C29.3922 63.5135 29.431 65.7565 29.5362 67.6099C29.6319 69.2957 29.7768 70.5081 29.9757 71.2955Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
