import { Calculation } from 'final-form-calculate'
import { MetaPath } from 'util/metaPath'
import { EscutaInicialState } from 'view/atendimentos/escuta-inicial/model'

import { SoapState } from '../../atendimento-individual/model'
import { MedicoesPanelModel } from './MedicoesPanel'

export const createMedicoesCalculations = (meta: MetaPath<MedicoesPanelModel>): Calculation[] => [
  {
    field: meta.glicemia.absolutePath(),
    updates: {
      [meta.tipoGlicemia.absolutePath()]: (glicemiaValue: string, allValues: SoapState) =>
        glicemiaValue && allValues.objetivo.medicoes.tipoGlicemia,
    },
  },
]

export const createMedicoesEscutaCalculations = (meta: MetaPath<MedicoesPanelModel>): Calculation[] => [
  {
    field: meta.glicemia.absolutePath(),
    updates: {
      [meta.tipoGlicemia.absolutePath()]: (glicemiaValue: string, allValues: EscutaInicialState) =>
        glicemiaValue && allValues.medicoes.tipoGlicemia,
    },
  },
]
