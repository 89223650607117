import {
  cns,
  cpf,
  createValidator,
  dataNasc,
  email,
  ErrorObject,
  maxLength,
  nome,
  required,
  telefone,
} from 'util/validation'

import ProfissionalFormModel from './components/ProfissionalFormModel'

export const createDadosValidator = (possuiLotacaoOrEstagio: boolean) =>
  createValidator<ProfissionalFormModel>(
    {
      nome: [required, nome],
      cpf: [required, cpf],
      cns: [cns],
      email: [email, maxLength(255)],
      telefone: [telefone],
      dataNascimento: [dataNasc],
    },
    (values) => {
      const errors: ErrorObject<ProfissionalFormModel> = {}

      if (possuiLotacaoOrEstagio && !errors.cns) {
        errors.cns = required(values.cns)
      }

      if (values && values.conselhoClasse) {
        errors.numeroConselhoClasse = required(values.numeroConselhoClasse)
        errors.ufEmissoraConselhoClasse = required(values.ufEmissoraConselhoClasse)
      }

      return errors
    }
  )
