import { HFlow, Icon, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import React from 'react'

const TOOLTIP_MESSAGE =
  'Selecione o tipo de período desejado: Mensal, Semanal ou Personalizado (dias). Depois, selecione o período com as opções predefinidas ou marcando manualmente nos campos de início e fim.'

export const RelatorioGerencialPeriodoLabel = () => {
  const theme = useTheme()
  const styles = createStyles(theme)
  return (
    <HFlow hSpacing={0.3} style={styles.periodoTitleContainer}>
      <Text variant='h5'>Período</Text>
      <Tooltip text={TOOLTIP_MESSAGE} style={styles.tooltip}>
        <Icon icon='infoCircleFilled' size={1} />
      </Tooltip>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  tooltip: {
    background: theme.pallete.gray.c20,
  },
  periodoTitleContainer: {
    marginLeft: '1rem',
    marginTop: '1rem',
  },
})
