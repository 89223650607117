import React from 'react'
import { masks, onlyNumbers } from 'util/mask'

import { MaskedField, MaskedFieldProps } from '../final-form/MaskedField'

export interface CpfFieldProps extends MaskedFieldProps {}

export function CpfField(props: CpfFieldProps) {
  return <MaskedField mask={masks.cpf} placeholder='000.000.000-00' parse={onlyNumbers} {...props} />
}
