import React from 'react'
import { memo, useMemo } from 'react'
import { HistoricoPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'

import HistoricoAvaliacaoElegibilidadeADPanel from './app/HistoricoAvaliacaoElegibilidadeADPanel'
import HistoricoAvaliacaoElegibilidadeDwPanel from './dw/HistoricoAvaliacaoElegibilidadeDwPanel'

function HistoricoAvaliacaoElegibilidadePanel(props: HistoricoPanelProps) {
  const { isAtendRecente, idAtend, idadeOrigemAtendimento, uuidRegistro, cpfOuCnsCidadao } = props

  const atendimentoPecProcessado: boolean = !isAtendRecente && idAtend

  const selectContent = useMemo(() => {
    return isAtendRecente || atendimentoPecProcessado ? (
      <HistoricoAvaliacaoElegibilidadeADPanel idAtend={idAtend} idadeOrigemAtendimento={idadeOrigemAtendimento} />
    ) : (
      <HistoricoAvaliacaoElegibilidadeDwPanel
        uuidRegistro={uuidRegistro}
        cpfOuCnsCidadao={cpfOuCnsCidadao}
        idadeOrigemAtendimento={idadeOrigemAtendimento}
      />
    )
  }, [isAtendRecente, atendimentoPecProcessado, idAtend, idadeOrigemAtendimento, uuidRegistro, cpfOuCnsCidadao])

  return selectContent
}

export default memo(HistoricoAvaliacaoElegibilidadePanel)
