import { Alert, AlertProps } from 'bold-ui'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'

export function AlertServerTimezone(props: Partial<AlertProps>) {
  const clientTimezoneOffset = -new Date().getTimezoneOffset()
  const { timezoneOffset: serverTimezoneOffset } = useServerTime()

  return (
    serverTimezoneOffset !== clientTimezoneOffset && (
      <Alert type='warning' {...props}>
        Os horários podem estar sendo apresentados de forma incorreta, pois o seu computador e o servidor estão
        utilizando uma configuração diferente de TimeZone.
        <br />
        Entre em contato com o administrador da instalação para ajustar a configuração de horário.
      </Alert>
    )
  )
}
