import { Heading, HFlow, Icon } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { useAcessoEstagioQuery } from 'graphql/hooks.generated'
import React from 'react'
import { PermissoesEstagiario } from 'view/estagiario/components/PermissoesEstagiario'

import { Card } from './Card'

interface AcessoEstagioCardProps {
  acessoId: ID
  onClick(acessoId: ID): void
}

export function AcessoEstagioCard({ acessoId, onClick }: AcessoEstagioCardProps) {
  const {
    data: { estagio },
  } = useAcessoEstagioQuery({
    variables: { id: acessoId },
  })

  return (
    <Card onClick={onClick}>
      <HFlow hSpacing={0.5} alignItems='center'>
        <Icon icon='mapMarkerFilled' fill='primary' size={1} />
        <Heading level={3}>{estagio?.unidadeSaude.nome.titleCase()}</Heading>
      </HFlow>

      <HLabel titleFontWeight='bold' title='Supervisor '>
        {estagio?.lotacaoSupervisora.profissional.nome}
      </HLabel>

      <PermissoesEstagiario cbo={estagio?.cbo} equipe={estagio?.equipe} />
    </Card>
  )
}
