/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Theme, Tooltip, useTheme } from 'bold-ui'
import { memo, useLayoutEffect, useRef, useState } from 'react'

interface HistoricoHeaderEllipsisProps {
  children: React.ReactNode
  tooltipText: string
}

function HistoricoHeaderEllipsis(props: HistoricoHeaderEllipsisProps) {
  const { children, tooltipText } = props
  const theme = useTheme()
  const { textWrapper, maisInfo } = createStyles(theme)
  const ref = useRef<HTMLDivElement>()

  const [showTooltip, setShowTooltip] = useState<boolean>()

  useLayoutEffect(() => {
    function setOverflow() {
      setShowTooltip(ref.current?.scrollHeight > ref.current?.clientHeight)
    }
    window.addEventListener('resize', setOverflow)
    setOverflow()
    return () => window.removeEventListener('resize', setOverflow)
  }, [])

  return (
    <HFlow
      hSpacing={0.5}
      style={css`
        align-items: center;
      `}
    >
      <div ref={ref} css={textWrapper}>
        {children}
      </div>
      {showTooltip && (
        <Tooltip text={tooltipText}>
          <Icon icon='infoCircleOutline' size={1} style={maisInfo} />
        </Tooltip>
      )}
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  textWrapper: css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  `,
  maisInfo: css`
    color: ${theme.pallete.primary.main};
    display: flex;
    margin-right: 1.25rem;
  `,
})

export default memo(HistoricoHeaderEllipsis)
