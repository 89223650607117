/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import theme from 'config/theme'

interface CuidadoCompartilhadoFooterProps {
  handleCancel: () => void
}

export const CuidadoCompartilhadoFooter = (props: CuidadoCompartilhadoFooterProps) => {
  const { handleCancel } = props

  return (
    <div css={styles.footer}>
      <PageContent type='filled' fluid>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' onClick={handleCancel}>
            Cancelar discussão de caso
          </Button>
        </HFlow>
      </PageContent>
    </div>
  )
}

const styles = {
  footer: css`
    border-top: 1px solid ${theme.pallete.divider};
    height: 6.125rem;
  `,
}
