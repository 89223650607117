import { HFlow, Icon, Text, VFlow } from 'bold-ui'
import { Cpf } from 'components/label'
import React from 'react'
import { EditarAtualizarCidadaoCallback, FamiliaVisualizacaoImovel } from 'view/visualizacaoTerritorial/model'

import { CidadaoCard } from './CidadaoCard'

interface FamiliasImovelListProps {
  imovelId: ID
  familias: ReadonlyArray<FamiliaVisualizacaoImovel>
  onEditarAtualizarCidadaos: EditarAtualizarCidadaoCallback
  getServerTimeNow(): Date
}

export function FamiliasImovelList(props: FamiliasImovelListProps) {
  const { imovelId, familias, onEditarAtualizarCidadaos } = props

  return (
    <VFlow>
      {familias.map((familia) => {
        const isResponsavelPresente = familia.cidadaos.some((cidadao) => {
          return cidadao.cpf === familia.cpfCnsResponsavel || cidadao.cns === familia.cpfCnsResponsavel
        })
        return (
          <VFlow vSpacing={0.25} key={familia.id} style>
            <HFlow hSpacing={0.25} alignItems='center'>
              <Icon icon='multipleUsersOutline' />
              <Text fontWeight='bold'>Prontuário familiar:</Text>
              <Text>{familia.numeroProntuarioFamiliar ?? '-'}</Text>
            </HFlow>
            {!isResponsavelPresente && (
              <HFlow hSpacing={0.25}>
                <Text fontWeight='bold'> {`CPF / CNS do responsável informado:`} </Text>
                {familia.cpfCnsResponsavel.length === 11 ? (
                  <Cpf value={familia.cpfCnsResponsavel} />
                ) : (
                  familia.cpfCnsResponsavel
                )}
              </HFlow>
            )}
            {familia.cidadaos
              .filter(
                (cidadao) =>
                  !cidadao.isFaleceu ||
                  cidadao.cpf === familia.cpfCnsResponsavel ||
                  cidadao.cns === familia.cpfCnsResponsavel
              )
              .map((cidadao) => {
                const isResponsavel =
                  cidadao.cpf === familia.cpfCnsResponsavel || cidadao.cns === familia.cpfCnsResponsavel
                return (
                  <CidadaoCard
                    imovelId={imovelId}
                    isResponsavel={isResponsavel}
                    cidadao={cidadao}
                    onEditarAtualizarCidadaos={onEditarAtualizarCidadaos}
                    key={cidadao.id}
                  />
                )
              })}
          </VFlow>
        )
      })}
    </VFlow>
  )
}
