import { useSolicitacoesExameUnpagedQuery } from 'graphql/hooks.generated'
import React from 'react'
import SolicitacaoExamesTable from 'view/atendimentos/detail/soap/plano/exames/ExamesTable'
import { joinSolicitacoesExame } from 'view/atendimentos/detail/soap/plano/exames/utils'

import { LotacaoFolhaRosto } from '../../model'

interface SolicitacoesExamesFolhaRostoProps {
  prontuarioId: ID
  atendimentoId: ID
  cidadaoId: ID
  dataReferencia: Instant
  lotacao: LotacaoFolhaRosto
}

export const SolicitacoesExamesFolhaRosto = (props: SolicitacoesExamesFolhaRostoProps) => {
  const { prontuarioId, atendimentoId, cidadaoId, dataReferencia, lotacao } = props

  const {
    data: { solicitacoesExame },
    loading,
  } = useSolicitacoesExameUnpagedQuery({
    variables: {
      input: {
        prontuarioId,
      },
    },
  })

  const convertedItems = joinSolicitacoesExame(solicitacoesExame?.content, [])

  return (
    <SolicitacaoExamesTable
      atendimentoId={atendimentoId}
      prontuarioId={prontuarioId}
      dataReferencia={dataReferencia}
      lotacao={lotacao}
      loading={loading}
      cidadaoId={cidadaoId}
      items={convertedItems}
      readOnly
    />
  )
}
