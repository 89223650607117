/** @jsx jsx */
import { jsx } from '@emotion/core'
import { HeadingSection, HFlow, useTheme, VFlow } from 'bold-ui'
import { green, orange, red } from 'bold-ui/lib/styles/colors'
import { StatusInformation } from 'components/StatusInformation'

interface MarcosDesenvolvimentoViewProps {
  children: React.ReactNode
}

export default function MarcosDesenvolvimentoView(props: MarcosDesenvolvimentoViewProps) {
  const { children: marcosSectionAccordion } = props
  const theme = useTheme()

  return (
    <HeadingSection level={4} title='Marcos de desenvolvimento'>
      <VFlow>
        {marcosSectionAccordion}
        <HFlow>
          <StatusInformation
            text='Não avaliado'
            icon='minusCircleFilled'
            color={theme.pallete.gray.c80}
            textColor={theme.pallete.text.main}
          />
          <StatusInformation
            text='Presente'
            icon='checkCircleFilled'
            color={green.c60}
            textColor={theme.pallete.text.main}
          />
          <StatusInformation
            text='Presente com atraso'
            icon='exclamationTriangleFilled'
            color={orange.c60}
            textColor={theme.pallete.text.main}
          />
          <StatusInformation text='Ausente' icon='timesFilled' color={red.c60} textColor={theme.pallete.text.main} />
        </HFlow>
      </VFlow>
    </HeadingSection>
  )
}
