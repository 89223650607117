import { HFlow, useStyles } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { useBuscaDetailCidadaoQuery } from 'graphql/hooks.generated'
import React, { CSSProperties } from 'react'
import { Acoes } from 'view/cidadao/visualizacao/CidadaoCabecalhoSection'
import { CidadaoInformacoesView } from 'view/cidadao/visualizacao/CidadaoInformacoesView'

interface CidadaoDetailContentProps {
  atendimentoId: ID
  cidadaoId: ID
  callbackUrl?: string
}

export function CidadaoDetailContent(props: CidadaoDetailContentProps) {
  const { atendimentoId, callbackUrl, cidadaoId } = props
  const { data } = useBuscaDetailCidadaoQuery({ variables: { id: cidadaoId } })
  const { classes } = useStyles(createStyles)

  return (
    <PageContent fluid type='filled'>
      {data?.cidadao && (
        <>
          <div className={classes.actions}>
            <HFlow hSpacing={0.5} justifyContent='flex-end'>
              <Acoes
                cidadao={data.cidadao}
                sizeBotaoAtualizar='small'
                botaoVerAgendamentos={false}
                callbackUrl={callbackUrl ?? 'cadastro-cidadao'}
                callbackParams={atendimentoId}
              />
            </HFlow>
          </div>
          <div className={classes.detail}>
            <CidadaoInformacoesView cidadao={data.cidadao} />
          </div>
        </>
      )}
    </PageContent>
  )
}

const createStyles = () => ({
  actions: {
    position: 'relative',
    zIndex: 8,
  } as CSSProperties,
  detail: {
    position: 'relative',
    top: '-4rem',
  } as CSSProperties,
})
