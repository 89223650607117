import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoParticipacaoAtendimentoEnum } from 'graphql/types.generated'
import React from 'react'
import { tipoParticipacaoAtendimentoRecord } from 'view/atendimentos/detail/soap/finalizacao/model-finalizacao'

export type TipoParticipacaoAtendimentoSelectModel = TipoParticipacaoAtendimentoEnum

export interface TipoParticipacaoAtendimentoSelectFieldProps
  extends Omit<SelectFieldProps<TipoParticipacaoAtendimentoSelectModel>, 'items' | 'itemToString'> {}

const itemToString = (item: TipoParticipacaoAtendimentoSelectModel) => item && tipoParticipacaoAtendimentoRecord[item]

export function TipoParticipacaoAtendimentoSelectField(props: TipoParticipacaoAtendimentoSelectFieldProps) {
  return (
    <SelectField<TipoParticipacaoAtendimentoSelectModel>
      items={items}
      itemToString={itemToString}
      clearable={false}
      {...props}
    />
  )
}

const items: TipoParticipacaoAtendimentoSelectModel[] = [
  TipoParticipacaoAtendimentoEnum.PRESENCIAL,
  TipoParticipacaoAtendimentoEnum.CHAMADA_DE_VIDEO,
  TipoParticipacaoAtendimentoEnum.CHAMADA_DE_VOZ,
  TipoParticipacaoAtendimentoEnum.EMAIL,
  TipoParticipacaoAtendimentoEnum.MENSAGEM,
  TipoParticipacaoAtendimentoEnum.OUTROS,
]
