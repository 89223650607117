/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text } from 'bold-ui'
import { stringGrouper } from 'util/string/string-grouper'
import { HistoricoEvolucoesOdontoTableRowModel } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTable'
import { renderLocalEvolucao } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTableRow'
import { showDescricaoCodigoProcedimento } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/util-EvolucoesOdontologicasPlano'

interface HistoricoOdontogramaTableProps {
  evolucoes: HistoricoEvolucoesOdontoTableRowModel[]
}

export const HistoricoOdontogramaTable = (props: HistoricoOdontogramaTableProps) => {
  const { evolucoes } = props

  return (
    <Table hovered>
      <TableHead>
        <TableRow>
          <TableHeader>
            <Grid>
              <Cell size={4}>
                <Text fontWeight='bold'>Local</Text>
              </Cell>
              <Cell size={8}>
                <Text fontWeight='bold'>Procedimentos</Text>
              </Cell>
            </Grid>
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {evolucoes.map((evolucao) => (
          <TableRow key={evolucao.id}>
            <TableCell
              style={css`
                padding: 0.5rem 1rem;
              `}
            >
              <Grid>
                <Cell size={4}>
                  <Text>
                    {renderLocalEvolucao({
                      partesBucais: evolucao.partesBucais,
                      descricaoParteBucal: evolucao.descricaoParteBucal,
                    })}
                  </Text>
                </Cell>
                <Cell size={8}>
                  <Text>
                    {stringGrouper(...evolucao.procedimentos.map((item) => showDescricaoCodigoProcedimento(item)))}
                  </Text>
                </Cell>
                {evolucao.observacao?.removeTags() && (
                  <Cell>
                    <HFlow hSpacing={0.2}>
                      <Text fontWeight='bold'>Observações: </Text>
                      <Text
                        style={css`
                          word-break: break-all;
                        `}
                      >
                        <div dangerouslySetInnerHTML={{ __html: evolucao.observacao }} />
                      </Text>
                    </HFlow>
                  </Cell>
                )}
              </Grid>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
