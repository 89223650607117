import { FormControl, HFlow, Radio, VFlow } from 'bold-ui'
import React, { useState } from 'react'
import { MetaPath } from 'util/metaPath'

import { CidadaoAtendimentoSelectFieldModel } from '../CidadaoAtendimentoSelectField'
import { CidadaoAtendimentoPorCnsSelectField } from './CidadaoAtendimentoPorCnsSelectField'
import { CidadaoAtendimentoPorCpfSelectField } from './CidadaoAtendimentoPorCpfSelectField'
import { CidadaoAtendimentoPorDataNascimentoSelectField } from './CidadaoAtendimentoPorDataNascimentoSelectField'
import { CidadaoAtendimentoPorNomeSelectField } from './CidadaoAtendimentoPorNomeSelectField'

enum PropriedadeBuscaCidadao {
  CPF,
  CNS,
  NOME,
  DATA_NASCIMENTO,
}

export interface CidadaoAtendimentoPorPropriedadeSelectFieldProps {
  name: MetaPath<CidadaoAtendimentoSelectFieldModel>
  onChange(item: CidadaoAtendimentoSelectFieldModel): void
}

export function CidadaoAtendimentoPorPropriedadeSelectField(props: CidadaoAtendimentoPorPropriedadeSelectFieldProps) {
  const { name, onChange } = props

  const [propriedadeBuscaCidadao, setPropriedadeBuscaCidadao] = useState<PropriedadeBuscaCidadao>(
    PropriedadeBuscaCidadao.CPF
  )

  return (
    <FormControl label='Cidadão' required>
      <VFlow vSpacing={0.5}>
        <HFlow>
          <Radio
            label='CPF'
            onChange={() => setPropriedadeBuscaCidadao(PropriedadeBuscaCidadao.CPF)}
            checked={propriedadeBuscaCidadao === PropriedadeBuscaCidadao.CPF}
          />
          <Radio
            label='CNS'
            onChange={() => setPropriedadeBuscaCidadao(PropriedadeBuscaCidadao.CNS)}
            checked={propriedadeBuscaCidadao === PropriedadeBuscaCidadao.CNS}
          />
          <Radio
            label='Nome'
            onChange={() => setPropriedadeBuscaCidadao(PropriedadeBuscaCidadao.NOME)}
            checked={propriedadeBuscaCidadao === PropriedadeBuscaCidadao.NOME}
          />
          <Radio
            label='Data de nascimento'
            onChange={() => setPropriedadeBuscaCidadao(PropriedadeBuscaCidadao.DATA_NASCIMENTO)}
            checked={propriedadeBuscaCidadao === PropriedadeBuscaCidadao.DATA_NASCIMENTO}
          />
        </HFlow>
        {createSelectField(propriedadeBuscaCidadao, name, onChange)}
      </VFlow>
    </FormControl>
  )
}

const createSelectField = (
  propriedadeBuscaCidadao: PropriedadeBuscaCidadao,
  name: MetaPath<CidadaoAtendimentoSelectFieldModel>,
  onChange: (item: CidadaoAtendimentoSelectFieldModel) => void
) => {
  const addCidadaoCallbackUrl = '/lista-atendimento'
  switch (propriedadeBuscaCidadao) {
    case PropriedadeBuscaCidadao.CPF:
      return (
        <CidadaoAtendimentoPorCpfSelectField
          name={name}
          addCidadaoCallbackUrl={addCidadaoCallbackUrl}
          onChange={onChange}
        />
      )
    case PropriedadeBuscaCidadao.CNS:
      return (
        <CidadaoAtendimentoPorCnsSelectField
          name={name}
          addCidadaoCallbackUrl={addCidadaoCallbackUrl}
          onChange={onChange}
        />
      )
    case PropriedadeBuscaCidadao.NOME:
      return (
        <CidadaoAtendimentoPorNomeSelectField
          name={name}
          addCidadaoCallbackUrl={addCidadaoCallbackUrl}
          onChange={onChange}
        />
      )
    case PropriedadeBuscaCidadao.DATA_NASCIMENTO:
      return (
        <CidadaoAtendimentoPorDataNascimentoSelectField
          name={name}
          addCidadaoCallbackUrl={addCidadaoCallbackUrl}
          onChange={onChange}
        />
      )
  }
}
