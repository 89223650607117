/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles } from 'bold-ui'
export interface NewsGifProps {
  src: string
  alt: string
  style?: ExternalStyles
}

export const NewsImg = (props: NewsGifProps) => {
  const { alt, src, style } = props

  return (
    <div css={styles.main}>
      <img src={src} alt={alt} css={[styles.image, style]} />
    </div>
  )
}

const styles = {
  main: css`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  `,

  image: css`
    max-width: 100%;
    max-height: 430px;
    border: 1px solid;
    image-rendering: -webkit-optimize-contrast;
  `,
}
