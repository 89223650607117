/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Link, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { Fragment, useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { CookiePreferencesModal } from './CookiePreferencesModal'

interface CookieBannerViewProps {
  onClickAceitarNecessarios: () => void
  onClickAceitarTodos: () => void
}

export const CookieBannerView = (props: CookieBannerViewProps) => {
  const { onClickAceitarNecessarios, onClickAceitarTodos } = props
  const theme = useTheme()
  const styles = createStyles(theme)
  const location = useLocation()
  const history = useHistory()
  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false)

  const handleClickTermosUso = useCallback(() => {
    setIsPreferencesModalOpen(false)
    location.pathname !== '/termosUso' && history.push('/termosUso')
  }, [location.pathname, history])

  return (
    <Fragment>
      <VFlow vSpacing={0.1}>
        <Heading level={4} style={styles.title}>
          Utilizamos cookies para melhorar a sua experiência no sistema e prover serviços personalizados
        </Heading>
        <Text>
          Ao aceitar todos, você terá acesso a todas as funcionalidades do sistema. Ao clicar em "Aceitar apenas os
          necessários", os cookies que não forem estritamente necessários serão desativados. Para escolher quais quer
          autorizar, clique em "Configurar cookies".
        </Text>
        <Text>
          Saiba mais em nossos <Link onClick={handleClickTermosUso}>Termos de uso e Políticas de privacidade.</Link>
        </Text>
      </VFlow>
      <VFlow vSpacing={0.5} style={styles.buttonsArea}>
        <Button kind='primary' size='small' onClick={onClickAceitarTodos} style={styles.buttonAceitarTodos}>
          Aceitar todos
        </Button>
        <Button kind='normal' size='small' onClick={onClickAceitarNecessarios} style={styles.buttonAceitarNecessarios}>
          Aceitar apenas os necessários
        </Button>
        <Button
          kind='normal'
          skin='ghost'
          size='small'
          style={styles.buttonConfigurar}
          onClick={() => setIsPreferencesModalOpen(true)}
        >
          <HFlow hSpacing={0.5}>
            <Icon icon='gearFilled' />
            Configurar cookies
          </HFlow>
        </Button>
      </VFlow>
      {isPreferencesModalOpen && (
        <CookiePreferencesModal
          onClose={() => setIsPreferencesModalOpen(false)}
          onClickAceitarTodos={onClickAceitarTodos}
          onClickAceitarNecessarios={onClickAceitarNecessarios}
          onOpenTermosUso={handleClickTermosUso}
        />
      )}
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  buttonsArea: css`
    justify-content: center;
  `,
  buttonAceitarTodos: css`
    width: 14rem;
  `,
  buttonAceitarNecessarios: css`
    width: 14rem;
    color: ${theme.pallete.primary.c40};
    border-color: ${theme.pallete.primary.c40};
  `,
  buttonConfigurar: css`
    width: 14rem;
    color: ${theme.pallete.primary.c40};
  `,
  title: css`
    color: ${theme.pallete.primary.c40};
  `,
})
