import { Cell, Grid } from 'bold-ui'
import { CheckboxField, Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { FormState } from 'final-form'
import { EquipesQueryInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

interface EquipeTableFilterProps {
  initialFilter?: EquipesQueryInput
  onChange(values: EquipesQueryInput): void
}

export class EquipeTableFilter extends React.Component<EquipeTableFilterProps> {
  render() {
    return (
      <Form<EquipesQueryInput>
        onSubmit={this.props.onChange}
        render={this.renderForm}
        initialValues={this.props.initialFilter}
      />
    )
  }

  private handleChange = (formState: FormState<EquipesQueryInput>) => {
    this.props.onChange(formState.values)
  }

  private renderForm = (props: FormRenderProps) => {
    return (
      <>
        <FormDebouncedValueSpy onChange={this.handleChange} />

        <Grid alignItems='center' gap={1}>
          <Cell size={4}>
            <TextField name='query' placeholder='Pesquise pelo nome, tipo ou INE' />
          </Cell>
          <Cell>
            <CheckboxField name='mostrarInativas' label='Mostrar equipes inativas' />
          </Cell>
        </Grid>
      </>
    )
  }
}
