import { Text, VFlow } from 'bold-ui'
import React from 'react'

import { Box } from './Box'

interface InfoBoxProps {
  title: React.ReactNode | string
  children?: React.ReactNode
  placeholder?: string
}

export function InfoBox(props: InfoBoxProps) {
  const { title, placeholder = '-', children } = props

  return (
    <VFlow vSpacing={0.5}>
      <Text fontWeight='bold'>{title}</Text>
      <Box>{children ?? placeholder}</Box>
    </VFlow>
  )
}
