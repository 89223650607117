import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import React from 'react'

import { EncaminhamentoExternoLotacao } from '../model'

type EncaminhamentoExternoLotacaoProps = EncaminhamentoExternoLotacao

export function EncaminhamentoExternoDetalheLotacao({ nome, cpf, cns, cbo }: EncaminhamentoExternoLotacaoProps) {
  return (
    <HLabel title='Encaminhado por:'>
      {nome.titleCase()} - {cpf ? <Cpf value={cpf} /> : cns} | {cbo.titleCase()}
    </HLabel>
  )
}
