import { Alert, Button, Cell, Grid, HeadingSection, HFlow, Icon, Modal, ModalBody, ModalFooter } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps, SubmitButton, TextField } from 'components/form'
import { RichTextField } from 'components/form/final-form/RichTextField'
import { confirm } from 'components/modals/confirm'
import { useAtestadoModeloDeleteMutation, useAtestadoModeloSaveMutation } from 'graphql/hooks.generated'
import { AtestadoModeloInput } from 'graphql/types.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'

import { atestadoModeloModalValidator } from './validator'

interface AtestadoModeloModalProps {
  initialValuesModelo?: AtestadoModeloModel
  onClose(): void
  onDelete?(): void
  onUpdate?(nomeModelo: string): void
}

export interface AtestadoModeloModel {
  nome?: string
  descricao: string
  id?: ID
}

const name = metaPath<AtestadoModeloModel>()

const descricaoDefault =
  '<p>Atesto, para os devidos fins, que [NOME], [CPF/CNS], paciente sob meus cuidados, foi atendido(a) no dia [DATA] às [HORA].</p>'

const convertModelToInput = (values: AtestadoModeloModel): AtestadoModeloInput => {
  return { id: values.id, nome: values.nome, descricao: values.descricao }
}

export default function AtestadoModeloModal(props: AtestadoModeloModalProps) {
  const { initialValuesModelo, onClose, onDelete, onUpdate } = props
  const alert = useAlert()
  const [save] = useAtestadoModeloSaveMutation()
  const [excluir] = useAtestadoModeloDeleteMutation()
  const handleRejection = useErrorHandler()
  const isEdicao = !!initialValuesModelo
  const initialValues = isEdicao ? initialValuesModelo : { descricao: descricaoDefault }

  const handleSubmit = (values: AtestadoModeloModel) => {
    return save({ variables: { input: convertModelToInput(values) } }).then((ret) => {
      alert('success', 'Modelo de atestado salvo com sucesso')
      onClose()
      onUpdate && onUpdate(values.nome)
    })
  }

  const handleCancel = (dirty: boolean) => {
    const labelAlert = isEdicao ? 'Edição' : 'Cadastro'
    const labelQuestion = isEdicao ? 'edição' : 'inclusão'
    if (dirty) {
      confirm({
        title: `Deseja cancelar a ${labelQuestion}?`,
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: `Não, continuar ${labelQuestion}`,
        confirmLabel: `Sim, cancelar ${labelQuestion}`,
        onConfirm: () => {
          alert('success', `${labelAlert} do modelo de atestado cancelado com sucesso`)
          onClose()
        },
      })()
    } else {
      alert('success', `${labelAlert} do modelo de atestado cancelado com sucesso`)
      onClose()
    }
  }

  const handleDelete = () => {
    confirm({
      title: 'Deseja excluir o modelo de atestado?',
      cancelLabel: 'Cancelar',
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        excluir({ variables: { id: initialValues.id } })
          .then(() => {
            alert('success', 'Modelo de atestado excluído com sucesso')
            onDelete()
          })
          .finally(() => {
            onClose()
          })
          .catch(handleRejection)
      },
    })()
  }

  const renderForm = (formProps: FormRenderProps<AtestadoModeloModel>) => {
    return (
      <Modal size='large' open onClose={() => handleCancel(formProps.dirty)}>
        <ModalBody>
          <HeadingSection level={1} title={isEdicao ? 'Editar modelo de atestado' : 'Cadastrar modelo de atestado'} />
          <Grid>
            <Cell size={12}>
              <Alert type='warning'>
                Utilize apenas informações genéricas no conteúdo do modelo de atestado que possam ser aplicadas à demais
                cidadãos.
              </Alert>
            </Cell>
            <Cell size={12}>
              <Alert type='info' inline>
                Os termos [NOME], [CPF/CNS], [DATA] e [HORA], quando escritos entre colchetes, serão substituídos pelos
                dados do cidadão e do atendimento quando o atestado for emitido.
              </Alert>
            </Cell>
            <Cell size={4}>
              <TextField name={name.nome} label='Nome do modelo' required maxLength={40} placeholder='Nome do modelo' />
            </Cell>
            <Cell size={12}>
              <RichTextField
                name={name.descricao}
                maxLength={4000}
                placeholder='Insira o conteúdo do modelo de atestado.'
                height={6}
                label='Conteúdo'
                required
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='space-between'>
            <HFlow>
              {isEdicao && (
                <Button kind='danger' onClick={handleDelete}>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Icon icon='trashOutline' />
                    Excluir modelo
                  </HFlow>
                </Button>
              )}
            </HFlow>
            <HFlow>
              <Button onClick={() => handleCancel(formProps.dirty)}>Cancelar</Button>
              <SubmitButton title='Salvar' kind='primary' handleSubmit={formProps.handleSubmit}>
                Salvar
              </SubmitButton>
            </HFlow>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<AtestadoModeloModel>
      onSubmit={handleSubmit}
      validate={atestadoModeloModalValidator}
      render={renderForm}
      suppressNotificationError
      initialValues={initialValues}
    />
  )
}
