import { Text, VFlow } from 'bold-ui'
import { SelectDownshiftComponentCustom } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import React from 'react'

export function AppendItemExames(itemsLength: number, maxSize: number) {
  if (itemsLength >= maxSize) {
    return (
      <SelectDownshiftComponentCustom>
        <VFlow vSpacing={0}>
          <Text>Mostrando somente os primeiros {itemsLength} resultados.</Text>
          <Text>Para ver mais resultados refine sua busca.</Text>
        </VFlow>
      </SelectDownshiftComponentCustom>
    )
  }
  return null
}
