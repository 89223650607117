/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente23(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='42' height='122' viewBox='0 0 42 122' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M32.2989 78.0882C32.2989 73.5942 32.1235 58.2253 31.4501 47.372C31.2711 44.4861 31.2202 40.0069 31.1632 34.9788C31.1128 30.5416 31.0576 25.6769 30.9051 21.1025C30.7417 16.2015 30.4672 11.6217 29.9717 8.19692C29.7235 6.48154 29.4232 5.08096 29.064 4.08192C28.8842 3.58197 28.6972 3.20454 28.5095 2.94327C28.3201 2.67953 28.1591 2.57403 28.0417 2.54007C27.7108 2.44426 27.2978 2.51705 26.7717 2.89665C26.2421 3.27876 25.6554 3.93438 25.0324 4.87319C23.7892 6.74637 22.4766 9.63054 21.2143 13.3272C18.6926 20.7117 16.4058 31.2357 15.2573 43.1307C14.1044 55.0722 12.5731 64.8942 11.1858 72.0094C10.4922 75.5666 9.83415 78.4498 9.27665 80.5846C8.77119 82.5201 8.33857 83.8805 8.02638 84.5621C8.11538 84.6104 8.25754 84.671 8.46935 84.7366C9.0762 84.9246 10.0347 85.09 11.2611 85.231C13.7014 85.5115 17.0813 85.6828 20.5329 85.7859C26.4746 85.9633 32.5834 85.9375 34.3811 85.9269C34.3043 85.7274 34.2 85.4823 34.074 85.1863L34.0662 85.1682C33.9369 84.8643 33.7882 84.5143 33.6339 84.1281C33.0186 82.5876 32.2989 80.4328 32.2989 78.0882ZM34.5002 86.3008C34.5001 86.3001 34.5 86.2993 34.4999 86.2985C34.5001 86.307 34.501 86.3085 34.5002 86.3008Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M39.1318 107.258C39.3324 106.867 39.462 106.206 39.4928 105.28C39.5231 104.37 39.4569 103.26 39.3097 102.013C39.0155 99.5203 38.4034 96.5227 37.6325 93.5575C36.8618 90.5931 35.936 87.6754 35.0191 85.3427C34.5604 84.1759 34.1071 83.1634 33.68 82.3678C33.2452 81.558 32.8628 81.0215 32.5603 80.7597C32.2524 80.4932 31.7336 80.2167 31.0114 79.9529C30.2978 79.6922 29.4208 79.4564 28.4265 79.2543C26.4382 78.85 24.0148 78.5871 21.5606 78.5191C19.1055 78.4511 16.6377 78.5788 14.558 78.9477C12.4525 79.3213 10.8408 79.9265 10.0008 80.7406C9.13014 81.5845 8.24443 83.2303 7.39774 85.3812C6.5592 87.5114 5.78505 90.0666 5.10868 92.6628C3.75561 97.8563 2.80582 103.167 2.51592 105.469C2.39717 106.412 2.94693 107.645 4.11734 109.049C5.26655 110.427 6.92897 111.868 8.81385 113.204C12.599 115.886 17.1563 118.054 20.0445 118.45C21.4275 118.639 23.1861 118.303 25.1168 117.587C27.0369 116.874 29.0779 115.804 31.0051 114.58C32.9314 113.356 34.7319 111.987 36.1719 110.684C37.6235 109.371 38.6695 108.16 39.1318 107.258Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M34.7989 78.0882C34.7989 73.576 34.6235 58.1471 33.9454 47.2172C33.7705 44.3981 33.7202 40.0088 33.6633 34.9743L33.6629 34.9342C33.5848 28.022 33.4938 19.966 33.0284 13.5188C32.7961 10.2994 32.4648 7.3917 31.9707 5.19653C31.7256 4.10786 31.4185 3.09117 31.0094 2.2718C30.6437 1.53955 29.9662 0.494569 28.737 0.138701C27.4062 -0.24655 26.1986 0.227407 25.309 0.869251C24.4332 1.50108 23.6507 2.434 22.9494 3.49081C21.5349 5.62219 20.1403 8.73632 18.8484 12.5193C16.2526 20.121 13.9323 30.8407 12.7689 42.8905C10.8918 62.3324 8.01262 76.0424 6.44178 81.4714C4.73918 84.5936 3.28773 89.4866 2.20694 93.9551C1.08806 98.581 0.30405 103.024 0.0355148 105.157C-0.221626 107.199 0.946598 109.15 2.1972 110.65C3.53261 112.251 5.38021 113.835 7.36855 115.244C11.2836 118.018 16.2327 120.451 19.7056 120.927C21.6862 121.197 23.9022 120.704 25.9866 119.931C28.1134 119.141 30.3135 117.981 32.3455 116.69C34.3809 115.397 36.2958 113.943 37.8491 112.538C39.3561 111.174 40.681 109.717 41.3566 108.399C41.8093 107.516 41.952 106.422 41.9893 105.423C42.0289 104.366 41.9561 103.15 41.8091 101.863C41.5145 99.2848 40.9005 96.2409 40.1368 93.2571C39.3722 90.2694 38.4435 87.2878 37.5017 84.8299C37.0312 83.6022 36.5478 82.4796 36.0687 81.541C35.7242 80.866 35.3434 80.2082 34.927 79.6627C34.8456 79.1434 34.7989 78.6165 34.7989 78.0882ZM33.052 80.539C36.0948 83.7158 41.5604 103.617 39.5768 107.486C37.557 111.426 25.987 119.767 19.9767 118.945C13.9665 118.123 1.43415 110.058 2.01985 105.407C2.5348 101.317 5.09456 87.9332 8.29918 82.2458C9.90256 76.8061 12.8503 62.8589 14.7597 43.0827C17.0625 19.2307 23.9336 0.83024 28.1808 2.05982C31.3016 2.96328 31.5067 21.1185 31.6635 34.9968C31.7201 40.0063 31.7704 44.4585 31.9492 47.3411C32.6235 58.2097 32.7989 73.5906 32.7989 78.0882C32.7989 78.9356 32.8971 79.7596 33.052 80.539Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
