import { Theme, useStyles } from 'bold-ui'
import React from 'react'

export type LoadTableFunction = (params: any) => void

export interface TableBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: React.ReactNode
}

export function TableBox(props: TableBoxProps) {
  const { children, header, ...rest } = props
  const { classes } = useStyles(createStyles)

  return (
    <div className={classes.container} {...rest}>
      {header && <div className={classes.header}>{header}</div>}

      {children}
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    background: '#fff',
  },
  header: {
    border: `1px solid ${theme.pallete.divider}`,
    borderBottom: 'none',
    padding: '0.5rem 1rem',
  },
})
