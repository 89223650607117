/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Heading, HFlow, Icon, PagedTable, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { orange } from 'bold-ui/lib/styles/colors'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { isAfter } from 'date-fns'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { TextFilterType } from 'hooks/filter/model'
import { useFilter } from 'hooks/filter/useFilter'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { emptyArray } from 'util/array'
import { formatDate, toDate } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { metaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { useEditableListField } from '../../components/EditableList'
import { Problema } from '../aside/types/ProblemaModel'
import {
  ProblemaCondicaoAntecedenteModel,
  ProblemaCondicaoModel,
} from '../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import {
  AntecedentesProblemasResolvidosPageFilter,
  AntecedentesProblemasResolvidosTableFilter,
} from './AntecedentesProblemasResolvidosTableFilter'
import { convertProblemaToModel } from './converter'

export interface AntecedentesProblemasResolvidosTableProps {
  problemasResolvidos: Problema[]
}

export const AntecedentesProblemasResolvidosTable = (props: AntecedentesProblemasResolvidosTableProps) => {
  const { problemasResolvidos } = props

  const path = metaPath<SoapState>()
  const [tableState, setTableState] = useState<AntecedentesProblemasResolvidosPageFilter>({
    pageParams: { page: 0, size: 5 },
    query: '',
  })

  const {
    cidadao: { dataNascimento },
    tiposAtendimento: { isAtendimentoProcedimentos },
  } = useAtendimentoContext()

  const {
    input: { value: problemasRegistradosAgora = emptyArray },
  } = useEditableListField({ name: path.problemasECondicoes })

  const getTodosProblemasResolvidos = useCallback(
    (
      problemasRegistradosAgora: ProblemaCondicaoModel[],
      problemasResolvidos: ProblemaCondicaoModel[]
    ): ProblemaCondicaoAntecedenteModel[] => {
      const dataNascimentoFormatted = toDate(dataNascimento)
      if (problemasRegistradosAgora) {
        const problemasResolvidosAgora = problemasRegistradosAgora.filter(
          (item) => item.situacaoProblema === 'RESOLVIDO'
        )
        const allProblemas = problemasResolvidos.concat(problemasResolvidosAgora)
        return allProblemas
          ? allProblemas.map((item) => ({
              ...item,
              hasErrorInicio: isAfter(dataNascimentoFormatted, toDate(item.dataInicio?.data)),
              hasErrorFim: isAfter(dataNascimentoFormatted, toDate(item.dataFim?.data)),
            }))
          : []
      }
      return problemasResolvidos
        ? problemasResolvidos.map((item) => ({
            ...item,
            hasErrorInicio: isAfter(dataNascimentoFormatted, toDate(item.dataInicio?.data)),
            hasErrorFim: isAfter(dataNascimentoFormatted, toDate(item.dataFim?.data)),
          }))
        : []
    },
    [dataNascimento]
  )

  const filteredItems = useFilter<ProblemaCondicaoModel, AntecedentesProblemasResolvidosPageFilter>({
    items: getTodosProblemasResolvidos(problemasRegistradosAgora, convertProblemaToModel(problemasResolvidos)),
    filter: tableState,
    filtersType: [filterTextTypeConfig],
  })

  const { tableProps: paginationProps, paginatedItems } = usePagination({ items: filteredItems })

  const { pageSize, ...rest } = useMemo(() => {
    return {
      ...paginationProps,
      pageSize: paginationProps.pageSize,
      size: paginationProps.pageSize,
      rows: paginatedItems,
    }
  }, [paginatedItems, paginationProps])

  const classes = useMemo(() => createStyles(), [])

  const renderInfoProblema = (item: ProblemaCondicaoAntecedenteModel) => {
    const hasErrorInicioAgora = item.hasErrorInicio && item.isRegistradoAgora
    const hasErrorFimAgora = item.hasErrorFim && item.isRegistradoAgora
    return (
      <div css={classes.tableItem}>
        <VFlow vSpacing={0}>
          <Text>
            {item.ciap && (
              <Fragment>
                <Text fontWeight='bolder'>{`${item.ciap.descricao} - ${item.ciap.codigo} `}</Text> (CIAP 2)
              </Fragment>
            )}
            {`${item.cid && item.ciap ? ' | ' : ''}`}
            {item.cid && (
              <Fragment>
                <Text fontWeight='bolder'>{`${item.cid.nome} - ${item.cid.codigo} `}</Text>
                (CID 10)
              </Fragment>
            )}
          </Text>

          <HFlow>
            {item.dataInicio?.data && (
              <HFlow hSpacing={0.25}>
                <Text fontWeight={600}>Início</Text>
                {hasErrorInicioAgora ? (
                  <Tooltip text='Data deve ser posterior ou igual à data de nascimento'>
                    <HFlow hSpacing={0.25}>
                      <Icon style={classes.errorData} icon='exclamationTriangleOutline' size={1} />
                      <Text color='alert'>{formatDate(item.dataInicio?.data)}</Text>
                    </HFlow>
                  </Tooltip>
                ) : (
                  <span>
                    <Text>{formatDate(item.dataInicio?.data)}</Text>
                    {!item.hasErrorInicio && <Text> | {humanizeAge(dataNascimento, item.dataInicio?.data)}</Text>}
                  </span>
                )}
              </HFlow>
            )}
            {item.dataFim?.data && (
              <HFlow hSpacing={0.25}>
                <Text fontWeight={600}>Fim</Text>
                {hasErrorFimAgora ? (
                  <Tooltip text='Data deve ser posterior ou igual à data de nascimento'>
                    <HFlow hSpacing={0.25}>
                      <Icon style={classes.errorData} icon='exclamationTriangleOutline' size={1} />
                      <Text color='alert'>{formatDate(item.dataFim?.data)}</Text>
                    </HFlow>
                  </Tooltip>
                ) : (
                  <span>
                    <Text>{formatDate(item.dataFim?.data)}</Text>
                    {!item.hasErrorFim && <Text> | {humanizeAge(dataNascimento, item.dataFim?.data)}</Text>}
                  </span>
                )}
              </HFlow>
            )}
          </HFlow>
        </VFlow>
      </div>
    )
  }

  const renderTag = () => {
    return <Tag type='success'> Resolvido </Tag>
  }

  const onFilterChange = (query: string) => {
    setTableState({ ...tableState, query: query })
  }

  return (
    <VFlow vSpacing={0.4}>
      <Heading level={3}>Antecedentes resolvidos da Lista de Problemas e Condições</Heading>
      <Alert type='info' inline>
        Esta seção apresenta apenas os problemas resolvidos de forma sucinta. Para informações detalhadas ou cadastro de
        problemas/condições em situação diferente de resolvido, acesse a lista de problemas/condições no menu lateral.
      </Alert>
      <TableBox
        css={css`
          margin-top: 0.6rem;
        `}
        header={
          <AntecedentesProblemasResolvidosTableFilter
            isAtendimentoProcedimentos={isAtendimentoProcedimentos}
            onChange={onFilterChange}
          />
        }
      >
        <PagedTable<ProblemaCondicaoModel>
          {...rest}
          size={pageSize}
          columns={[
            {
              name: 'problemaCondicao',
              header: 'Problema/Condição',
              align: 'left',
              render: renderInfoProblema,
              style: { width: '80%' },
            },
            {
              name: 'situacao',
              header: 'Situação',
              align: 'left',
              render: renderTag,
            },
          ]}
        />
      </TableBox>
    </VFlow>
  )
}

const metaFilter = metaPath<AntecedentesProblemasResolvidosPageFilter>()
const meta = metaPath<ProblemaCondicaoModel>()
const filterTextTypeConfig: TextFilterType = {
  filterField: metaFilter.query,
  searchFields: [meta.ciap.codigo, meta.ciap.descricao, meta.cid.codigo, meta.cid.nome],
  removeTagsOnFilter: true,
}

const createStyles = () => ({
  tableItem: css`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  `,
  errorData: css`
    color: ${orange.c40};
    align-items: center;
  `,
})
