import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { TipoReceitaEnum } from 'graphql/types.generated'
import React from 'react'

export interface TipoReceitaSelectModel {
  tipoReceitaEnum: TipoReceitaEnum
  nome: string
}

export interface TipoReceitaSelectFieldProps
  extends Omit<SelectFieldProps<TipoReceitaSelectModel>, 'items' | 'itemToString'> {}

export function TipoReceitaSelectField(props: TipoReceitaSelectFieldProps) {
  const itemToString = (item: TipoReceitaSelectModel) => item?.nome

  return <SelectField<TipoReceitaSelectModel> items={tiposReceita} itemToString={itemToString} {...props} />
}

export const tiposReceita: TipoReceitaSelectModel[] = [
  { tipoReceitaEnum: TipoReceitaEnum.COMUM, nome: 'Comum' },
  { tipoReceitaEnum: TipoReceitaEnum.ESPECIAL, nome: 'Especial' },
  { tipoReceitaEnum: TipoReceitaEnum.ESPECIAL_BRANCA, nome: 'Especial - Notificação branca' },
  { tipoReceitaEnum: TipoReceitaEnum.ESPECIAL_AZUL, nome: 'Especial - Notificação azul' },
  { tipoReceitaEnum: TipoReceitaEnum.ESPECIAL_AMARELA, nome: 'Especial - Notificação amarela' },
]

export const tiposReceitaRecord: Record<TipoReceitaEnum, TipoReceitaSelectModel> = {
  [TipoReceitaEnum.COMUM]: { tipoReceitaEnum: TipoReceitaEnum.COMUM, nome: 'Comum' },
  [TipoReceitaEnum.ESPECIAL]: { tipoReceitaEnum: TipoReceitaEnum.ESPECIAL, nome: 'Especial' },
  [TipoReceitaEnum.ESPECIAL_BRANCA]: {
    tipoReceitaEnum: TipoReceitaEnum.ESPECIAL_BRANCA,
    nome: 'Especial - Notificação branca',
  },
  [TipoReceitaEnum.ESPECIAL_AZUL]: {
    tipoReceitaEnum: TipoReceitaEnum.ESPECIAL_AZUL,
    nome: 'Especial - Notificação azul',
  },
  [TipoReceitaEnum.ESPECIAL_AMARELA]: {
    tipoReceitaEnum: TipoReceitaEnum.ESPECIAL_AMARELA,
    nome: 'Especial - Notificação amarela',
  },
}
