import { Alert, Button, Cell, FormControl, Grid, Heading, Tag, Text, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { FormRenderProps, PasswordField, TextField } from 'components/form'
import { Cpf } from 'components/label'
import { PedidosEnvioBaseQuery } from 'graphql/types.generated'
import { useCapslockState } from 'hooks/useCapslockState'
import React, { useEffect } from 'react'
import { metaPath } from 'util/metaPath'

import { SolicitacaoEnvioModel } from './EnvioDadosView'
import { SolicitacaoEnvioDadosTable } from './SolicitacaoEnvioDadosTable'

interface EnvioDadosUnificacaoFormProps {
  hasUnificacaoAtiva: boolean
  errorResponseMessage: JSX.Element
  isCamposAtivos: boolean
  loginAtivo: boolean
  errorLoginMessage: JSX.Element
  formProps: FormRenderProps<SolicitacaoEnvioModel>
  onUnificarBase(): void
  onCancelarSolicitacao(solicitacaoId: any): void
  dataPedidosEnvio: PedidosEnvioBaseQuery
  isLoading: boolean
}

export const EnvioDadosUnificacaoForm = (props: EnvioDadosUnificacaoFormProps) => {
  const {
    hasUnificacaoAtiva,
    errorResponseMessage,
    isCamposAtivos,
    loginAtivo,
    errorLoginMessage,
    formProps,
    onUnificarBase,
    onCancelarSolicitacao,
    dataPedidosEnvio,
    isLoading,
  } = props
  const { data: dataUser } = useSession()
  const isCapsOn = useCapslockState()
  const path = metaPath<SolicitacaoEnvioModel>()
  const disabled = !isCamposAtivos || hasUnificacaoAtiva
  const { form, handleSubmit } = formProps

  useEffect(() => {
    if (!loginAtivo) {
      form.blur(path.senhaInstalacao.alias)
    }
  }, [loginAtivo, form, path.senhaInstalacao.alias])

  return (
    <VFlow vSpacing={1}>
      <Grid gap={2}>
        <Cell size={12}>
          <Heading level={2}>Solicitação de envio para a instalação central do município</Heading>
        </Cell>
        {hasUnificacaoAtiva && (
          <Cell size={12}>
            <Alert type='warning' inline>
              Não é possível solicitar uma unificação de bases enquanto possui unificações em andamento. É necessário
              finalizar todos os recebimentos para poder realizar uma solicitação.
            </Alert>
          </Cell>
        )}
        {errorResponseMessage}
        <Cell size={12}>
          <Heading level={3}>Dados de envio</Heading>
        </Cell>
        <Cell size={6}>
          <TextField
            label='Nome da minha instalação'
            name={path.nomeInstalacao}
            required
            clearable
            disabled={disabled}
            maxLength={255}
          />
        </Cell>
        <Cell size={6}>
          <TextField
            label='Link da instalação central'
            name={path.linkInstalacao}
            required
            clearable
            disabled={disabled}
            maxLength={255}
          />
        </Cell>
        <Cell size={12} style={{ marginTop: '0.5rem' }}>
          <Heading level={3}>Dados de segurança</Heading>
        </Cell>
        <Cell size={12}>
          <Text>
            Para fazer a solicitação de unificação é necessário a validação com a senha do login da <br />
            instalação central do município. Você já está conectado com o{' '}
            <b>
              CPF <Cpf value={dataUser.profissional.cpf} />.
            </b>
          </Text>
        </Cell>
        <Cell size={4}>
          <VFlow vSpacing={0.5}>
            <PasswordField
              label='Senha na instalação central'
              name={path.senhaInstalacao}
              required
              clearable
              disabled={disabled && loginAtivo}
              maxLength={255}
            />
            {isCapsOn && <Tag style={{ display: 'inline-block' }}>Caps lock ativado</Tag>}
          </VFlow>
        </Cell>
        <Cell size={2}>
          <FormControl label={<span>&nbsp;</span>}>
            {!loginAtivo ? (
              <Button kind='primary' size='small' onClick={handleSubmit}>
                Continuar unificação
              </Button>
            ) : (
              <Button kind='primary' size='small' onClick={handleSubmit} loading={isLoading} disabled={disabled}>
                Solicitar unificação
              </Button>
            )}
          </FormControl>
        </Cell>
        {errorLoginMessage}
        <Cell size={12} style={{ marginTop: '1rem' }}>
          <Heading level={3}>Processo de envio para a instalação central do município</Heading>
        </Cell>
        <Cell size={12}>
          <Alert type='warning' inline>
            Ao unificar a sua base com a instalação central do município, seu sistema será desativado e o novo link de
            acesso esus APS será repassado pelo administrador da instalação central.
          </Alert>
        </Cell>
        {dataPedidosEnvio.pedidosEnvioBase?.length === 0 ? (
          <Cell size={12}>
            <Text>Nenhuma solicitação de unificação realizada.</Text>
          </Cell>
        ) : (
          <Cell size={12}>
            <SolicitacaoEnvioDadosTable
              data={dataPedidosEnvio}
              hasUnificacaoAtiva={hasUnificacaoAtiva}
              onCancelarSolicitacao={onCancelarSolicitacao}
              onUnificarBase={onUnificarBase}
            />
          </Cell>
        )}
      </Grid>
    </VFlow>
  )
}
