import { CidadaoAtendimentoPorDataNascimentoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CidadaoAtendimentoPorDataNascimentoSelectFieldQuery,
  CidadaoAtendimentoPorDataNascimentoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'
import { DATE_REGEX_DDMMYYYY } from 'util/date/formatDate'

import { CidadaoAtendimentoSelectFieldModel } from '../CidadaoAtendimentoSelectField'
import { CidadaoAtendimentoSelectFieldCommon } from '../CidadaoAtendimentoSelectField/CidadaoAtendimentoSelectFieldCommon'
import { useAsyncQuerySelect } from '../useAsyncQuerySelect'
import { CidadaoAtendimentoPorPropriedadeEmptyItem } from './CidadaoAtendimentoPorPropriedadeEmptyItem'
import { CidadaoAtendimentoPorPropriedadeLoadingItem } from './CidadaoAtendimentoPorPropriedadeLoadingItem'
import { CidadaoAtendimentoPorPropriedadeSelectFieldProps } from './CidadaoAtendimentoPorPropriedadeSelectField'
import { commonCidadaoAtendimentoPorPropriedadeSelectFieldInput } from './util-cidadaoAtendimentoPorPropriedadeSelectField'

interface CidadaoAtendimentoPorDataNascimentoSelectFieldProps extends CidadaoAtendimentoPorPropriedadeSelectFieldProps {
  addCidadaoCallbackUrl: string
}

export function CidadaoAtendimentoPorDataNascimentoSelectField(
  props: CidadaoAtendimentoPorDataNascimentoSelectFieldProps
) {
  const { name, addCidadaoCallbackUrl, onChange } = props

  const { skipping, selectProps } = useAsyncQuerySelect<
    CidadaoAtendimentoSelectFieldModel,
    CidadaoAtendimentoPorDataNascimentoSelectFieldQuery,
    CidadaoAtendimentoPorDataNascimentoSelectFieldQueryVariables
  >({
    query: CidadaoAtendimentoPorDataNascimentoSelectFieldDocument,
    extractItems: (data) => data && data.cidadaosComboByDataNascimento,
    variables: (inputString) => ({
      input: {
        ...commonCidadaoAtendimentoPorPropriedadeSelectFieldInput,
        query: inputString,
      },
    }),
    skip: (inputString) => !DATE_REGEX_DDMMYYYY.test(inputString?.trim()),
    debounceTime: 500,
  })

  return (
    <CidadaoAtendimentoSelectFieldCommon
      name={name}
      addCidadaoCallbackUrl={addCidadaoCallbackUrl}
      placeholder='Digite a data de nascimento do cidadão'
      components={{
        EmptyItem: () => (
          <CidadaoAtendimentoPorPropriedadeEmptyItem
            skipping={skipping}
            skippingText='Informe uma data válida para realizar a busca'
          />
        ),
        LoadingItem: () => <CidadaoAtendimentoPorPropriedadeLoadingItem />,
      }}
      selectProps={selectProps}
      onChange={onChange}
    />
  )
}
