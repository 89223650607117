import { blue } from 'bold-ui/lib/styles/colors'
import React from 'react'

import { SoapSection } from '../SoapSection'
import { ObjetivoFormProps } from '.'
import ObjetivoForm from './ObjetivoForm'

export interface ObjetivoSectionProps extends ObjetivoFormProps {}

export function ObjetivoSection(props: ObjetivoSectionProps) {
  return (
    <SoapSection initial='O' title='Objetivo' color={blue}>
      <ObjetivoForm {...props} />
    </SoapSection>
  )
}
