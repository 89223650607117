import { Button, Cell, Grid, Icon, Link, Text, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import image from 'images/404-notfound.png'
import React from 'react'
import { useHistory } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

export function NotFoundView() {
  const history = useHistory()

  return (
    <PageContent>
      <Grid style={{ paddingTop: '10rem' }} alignItems='center' justifyContent='space-around'>
        <Cell>
          <VFlow>
            <VFlow vSpacing={0.5}>
              <h1>Ops! Página não encontrada.</h1>
              <p>Essa página pode ter sido movida, excluída ou não existe.</p>
            </VFlow>

            <p>
              <Button kind='primary' onClick={history.goBack}>
                <Icon icon='arrowLeft' style={{ marginRight: '0.25rem' }} />
                <Text color='inherit'>Retornar à página anterior</Text>
              </Button>
            </p>

            <p>
              <Link component={RouterLink} to='/'>
                Acessar página inicial
              </Link>
            </p>
          </VFlow>
        </Cell>
        <Cell>
          <img
            src={image}
            alt='Duas pessoas carregando uma tomada desconectada, representando que nada foi encontrado'
          />
        </Cell>
      </Grid>
    </PageContent>
  )
}
