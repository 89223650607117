import { useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

export interface BoxGroupProps extends React.HTMLAttributes<HTMLDivElement> {}

export function BoxGroup(props: BoxGroupProps) {
  const { classes } = useStyles(() => ({
    boxgroup: {
      '& > *': {
        borderRadius: '0px',
      },
      '& > :first-of-type': {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      },
      '& > :last-of-type': {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      },
      '& > :not(:first-of-type)': {
        borderTop: 'none',
      },
    } as CSSProperties,
  }))

  return <div className={classes.boxgroup} {...props} />
}
