export type MultiTabEvent =
    'LOGIN' | 'LOGOUT' | 'PERFIL_SELECIONADO' | 'RESET_SESSION'

export type MultiTabListener = (event: MultiTabEvent) => void

const listeners = []

export const subscribe = (listener: MultiTabListener) => {
    listeners.push(listener)
    return () => {
        listeners.splice(listeners.indexOf(listener), 1)
    }
}

export const notify = (event: MultiTabEvent) => {
    if (window.localStorage) {
        window.localStorage.setItem(event, Date.now().toString())
    }
}

const storageListener = (event: StorageEvent) => {
    listeners.forEach(listener => listener(event.key))
}

if (window.localStorage) {
    window.addEventListener('storage', storageListener, false)
}
