import { AcompanhamentoPuericulturaCardQuery } from 'graphql/types.generated'
import { isEmpty } from 'lodash'

import { initialValueMarcosDesenvolvimento } from '../../objetivo/puericultura/model'
import { medicaoPerimetroCefalicoMaisRecente } from '../../objetivo/puericultura/util'
import { AcompanhamentoPuericulturaModel, initialValueAvaliacaoAcompanhamento } from './model'
import { vacinacaoEmDiaMaisRecente } from './util/vacinacaoEmDiaMaisRecente'

export const convertAcompanhamentoPuericulturaToModel = (
  queryResult: AcompanhamentoPuericulturaCardQuery,
  cidadaoDataNascimento: string
): AcompanhamentoPuericulturaModel =>
  !isEmpty(queryResult) && {
    ...queryResult,
    alteracoesFenotipicas: {
      alteracoesFenotipicas: initialValueAvaliacaoAcompanhamento(queryResult.alteracoesFenotipicas),
    },
    fatoresRisco: { fatoresRisco: initialValueAvaliacaoAcompanhamento(queryResult.fatoresRisco) },
    marcosDesenvolvimento: initialValueMarcosDesenvolvimento(
      queryResult.marcosDesenvolvimento,
      queryResult.ultimoAtendimentoPuericultura?.dataInicio,
      cidadaoDataNascimento
    ),
    medicaoAnteriorPerimetroCefalico: medicaoPerimetroCefalicoMaisRecente(queryResult.medicoesAnteriores, {
      dataMedicao: cidadaoDataNascimento,
      valorPerimetroCefalico: +queryResult.antecedente?.perimetroCefalico,
    }),
    vacinacaoEmDia: vacinacaoEmDiaMaisRecente(queryResult.medicoesAnteriores, cidadaoDataNascimento),
  }
