import { FaixaEtariaConsumoAlimentar, OpcaoTipoPerguntaEnum } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'

interface AccordionForm {
  openedAccordions?: (string | number)[]
}

export type MarcadoresConsumoPanelModel =
  | MCAMenoresDeSeisMesesPanelModel
  | MCASeisAVinteETresMesesPanelModel
  | MCADoisAnosOuMaisPanelModel

export type MarcadoresConsumoAlimentarQuestions =
  | typeof mcaMenoresDeSeisMesesQuestions
  | typeof mcaSeisAVinteETresMesesQuestions
  | typeof mcaDoisAnosOuMaisQuestions

export const CategoriaMarcadoresConsumoAlimentarRecord: Record<FaixaEtariaConsumoAlimentar, string> = {
  MENOR_SEIS_MESES: 'Crianças menores de 6 meses',
  MAIOR_SEIS_MESES_MENOR_DOIS_ANOS: 'Crianças de 6 a 23 meses',
  MAIOR_DOIS_ANOS: 'Crianças com 2 anos ou mais, adolescentes, adultos, gestantes e idosos',
}

export const OpcoesMarcadoresConsumoAlimentarRecord: Record<OpcaoTipoPerguntaEnum, string> = {
  SIM: 'Sim',
  NAO: 'Não',
  NAO_SABE: 'Não sabe',
  UMA_VEZ: 'Uma vez',
  DUAS_VEZES: 'Duas vezes',
  TRES_VEZES_OU_MAIS: 'Três vezes ou mais',
  EM_PEDACOS: 'Em pedaços',
  AMASSADA: 'Amassada',
  PASSADA_NA_PENEIRA: 'Passada na peneira',
  LIQUIDIFICADA: 'Liquidificada',
  SO_O_CALDO: 'Só o caldo',
  CAFE_DA_MANHA: 'Café da manhã',
  LANCHE_DA_MANHA: 'Lanche da manhã',
  ALMOCO: 'Almoço',
  LANCHE_DA_TARDE: 'Lanche da tarde',
  JANTAR: 'Jantar',
  CEIA: 'Ceia',
}

export interface MCAProps<T> {
  name: MetaPath<T>
}

export const mcaMenoresDeSeisMesesQuestions: Record<
  keyof Omit<MCAMenoresDeSeisMesesPanelModel, 'openedAccordions' | 'editingId'>,
  string
> = {
  leiteMaterno: 'A criança ontem tomou leite de peito?',
  mingau: 'Mingau',
  aguaOuCha: 'Água/Chá',
  leiteDeVaca: 'Leite de vaca',
  formulaInfantil: 'Fórmula infantil',
  sucoDeFruta: 'Suco de fruta',
  fruta: 'Fruta',
  comidaDeSal: 'Comida de sal (de panela, papa ou sopa)',
  outros: 'Outros alimentos/bebidas',
}

export const mcaDoisAnosOuMaisQuestions: Record<
  keyof Omit<MCADoisAnosOuMaisPanelModel, 'openedAccordions' | 'editingId'>,
  string
> = {
  refeicoesAssistindoTV:
    'Você tem costume de realizar as refeições assistindo à TV, mexendo no computador e/ou celular',
  refeicoesPorDia: 'Quais refeições você faz ao longo do dia?',
  feijao: 'Feijão',
  frutas: 'Frutas frescas (não considerar suco de frutas)',
  legumes: 'Verduras e/ou legumes (não considerar batata, mandioca, aipim, macaxeira, cará e inhame)',
  embutidos: 'Hambúrguer e/ou embutidos (presunto, mortadela, salame, linguiça, salsicha)',
  bebidasComAcucar:
    'Bebidas adoçadas (refrigerante, suco de caixinha, suco em pó, água de coco de caixinha, xaropes de guaraná/groselha ou suco de fruta com adição de açúcar)',
  macarraoInstantaneo: 'Macarrão instantâneo, salgadinhos de pacote ou biscoitos salgados',
  guloseimas: 'Biscoito recheado, doces ou guloseimas (balas, pirulitos, chiclete, caramelo, gelatina)',
}

export const mcaSeisAVinteETresMesesQuestions: Record<
  keyof Omit<MCASeisAVinteETresMesesPanelModel, 'openedAccordions' | 'editingId'>,
  string
> = {
  leiteMaterno: 'A criança tomou leite do peito?',
  fruta: 'Ontem a criança comeu fruta inteira, em pedaços ou amassada?',
  quantidadeRefeicoesFruta: 'Se sim, quantas vezes? ',
  comidaDeSal: 'Ontem a criança comeu comida de sal (de panela, papa ou sopa)?',
  quantidadeRefeicoesComidaDeSal: 'Se sim, quantas vezes?',
  consistenciaAlimentoComidaDeSal: 'Se sim, essa comida foi oferecida:',
  leiteDeVaca: 'Outro leite que não o leite do peito',
  mingau: 'Mingau com leite',
  iogurte: 'Iogurte',
  legumes: 'Legumes (não considerar os utilizados como temperos, nem batata, mandioca/aipim/macaxeira, cará, inhame)',
  vegetais:
    'Vegetal ou fruta de cor alaranjada (abóbora ou jerimum, cenoura, mamão, manga) ou folhas verde-escuras (couve, caruru, beldrogea, bertalha, espinafre, mostarda)',
  verduraDeFolha: 'Verdura de folha (alface, acelga, repolho)',
  carne: 'Carne (boi, frango, peixe, porco, miúdos, outras) ou ovo',
  figado: 'Fígado',
  feijao: 'Feijão',
  amidos: 'Arroz, batata, inhame, aipim/macaxeira/mandioca, farinha ou macarrão (sem ser instantâneo)',
  embutidos: 'Hambúrguer e/ou embutidos (presunto, mortadela, salame, linguiça, salsicha)',
  bebidasComAcucar:
    'Bebidas adoçadas (refrigerante, suco de caixinha, suco em pó, água de coco de caixinha, xaropes de guaraná/groselha ou suco de fruta com adição de açúcar)',
  macarraoInstantaneo: 'Macarrão instantâneo, salgadinhos de pacote ou biscoitos salgados',
  guloseimas: 'Biscoito recheado, doces ou guloseimas (balas, pirulitos, chiclete, caramelo, gelatina)',
}

export interface MCAMenoresDeSeisMesesPanelModel extends AccordionForm, SoapEditableItem {
  leiteMaterno?: OpcaoTipoPerguntaEnum
  mingau?: OpcaoTipoPerguntaEnum
  aguaOuCha?: OpcaoTipoPerguntaEnum
  leiteDeVaca?: OpcaoTipoPerguntaEnum
  formulaInfantil?: OpcaoTipoPerguntaEnum
  sucoDeFruta?: OpcaoTipoPerguntaEnum
  fruta?: OpcaoTipoPerguntaEnum
  comidaDeSal?: OpcaoTipoPerguntaEnum
  outros?: OpcaoTipoPerguntaEnum
}

export interface MCASeisAVinteETresMesesPanelModel extends AccordionForm, SoapEditableItem {
  leiteMaterno?: OpcaoTipoPerguntaEnum
  fruta?: OpcaoTipoPerguntaEnum
  quantidadeRefeicoesFruta?: OpcaoTipoPerguntaEnum
  comidaDeSal?: OpcaoTipoPerguntaEnum
  quantidadeRefeicoesComidaDeSal?: OpcaoTipoPerguntaEnum
  consistenciaAlimentoComidaDeSal?: OpcaoTipoPerguntaEnum
  leiteDeVaca?: OpcaoTipoPerguntaEnum
  mingau?: OpcaoTipoPerguntaEnum
  iogurte?: OpcaoTipoPerguntaEnum
  legumes?: OpcaoTipoPerguntaEnum
  vegetais?: OpcaoTipoPerguntaEnum
  verduraDeFolha?: OpcaoTipoPerguntaEnum
  carne?: OpcaoTipoPerguntaEnum
  figado?: OpcaoTipoPerguntaEnum
  feijao?: OpcaoTipoPerguntaEnum
  amidos?: OpcaoTipoPerguntaEnum
  embutidos?: OpcaoTipoPerguntaEnum
  bebidasComAcucar?: OpcaoTipoPerguntaEnum
  macarraoInstantaneo?: OpcaoTipoPerguntaEnum
  guloseimas?: OpcaoTipoPerguntaEnum
}

export interface MCADoisAnosOuMaisPanelModel extends AccordionForm, SoapEditableItem {
  refeicoesAssistindoTV?: OpcaoTipoPerguntaEnum
  refeicoesPorDia?: OpcaoTipoPerguntaEnum[]
  feijao?: OpcaoTipoPerguntaEnum
  frutas?: OpcaoTipoPerguntaEnum
  legumes?: OpcaoTipoPerguntaEnum
  embutidos?: OpcaoTipoPerguntaEnum
  bebidasComAcucar?: OpcaoTipoPerguntaEnum
  macarraoInstantaneo?: OpcaoTipoPerguntaEnum
  guloseimas?: OpcaoTipoPerguntaEnum
}
