/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Breakpoint, ExternalStyles, Theme, useTheme } from 'bold-ui'

interface HideOnScreenSizePropsUp {
  /** Esconde o elemento se o tamanho da tela é maior ou igual ao breakpoint */
  up: Breakpoint
}

interface HideOnScreenSizePropsDown {
  /** Esconde o elemento se o tamanho da tela é menor que o breakpoint */
  down: Breakpoint
}

type HideOnScreenSizeProps = (HideOnScreenSizePropsUp | HideOnScreenSizePropsDown) & {
  component?: React.ElementType
  style?: ExternalStyles
}

export function HideOnScreenSize(props: React.PropsWithChildren<HideOnScreenSizeProps>) {
  const { component = 'span', children, style } = props

  const theme = useTheme()
  const Cmp = component

  const breakpointType = 'up' in props ? 'up' : 'down'

  const componentCss = css(
    css`
      ${getMediaExpression(theme, props[breakpointType], breakpointType)} {
        display: none;
      }
    `,
    style
  )

  return <Cmp css={componentCss}>{children}</Cmp>
}

function getMediaExpression(theme: Theme, breakpoint: Breakpoint, type: 'up' | 'down') {
  const px = theme.breakpoints.size[breakpoint] - (type === 'up' ? 0 : 1)
  const limit = type === 'up' ? 'min' : 'max'

  return `@media (${limit}-width: ${px}px)`
}
