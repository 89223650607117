import { css } from '@emotion/core'
import { VFlow } from 'bold-ui'
import React, { memo } from 'react'

import { PeriogramaCompletoQuadrante, PeriogramaCompletoTableModel } from '../model'
import { PipsModel } from '../periogramaCompletoCalculos'
import PeriogramaCompletoTableView from './PeriogramaCompletoTableView'

interface PeriogramaCompletoViewProps {
  values: PeriogramaCompletoTableModel
  pips: PipsModel
}

const PeriogramaCompletoView = (props: PeriogramaCompletoViewProps) => {
  const { values, pips } = props

  return (
    <VFlow
      style={css`
        padding-top: 0.5rem;
      `}
    >
      <PeriogramaCompletoTableView
        quadrante={PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_DIREITA}
        pips={pips}
        values={values}
      />

      <PeriogramaCompletoTableView
        quadrante={PeriogramaCompletoQuadrante.ARCADA_SUPERIOR_ESQUERDA}
        pips={pips}
        values={values}
      />

      <PeriogramaCompletoTableView
        quadrante={PeriogramaCompletoQuadrante.ARCADA_INFERIOR_DIREITA}
        pips={pips}
        values={values}
      />

      <PeriogramaCompletoTableView
        quadrante={PeriogramaCompletoQuadrante.ARCADA_INFERIOR_ESQUERDA}
        pips={pips}
        values={values}
      />
    </VFlow>
  )
}

export default memo(PeriogramaCompletoView)
