import { Alert, DateRange, Text, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable, PivotTableInitialValues } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/tree-builder/useTreeBuilder'
import { useDataUltimoProcessamentoQuery } from 'graphql/hooks.generated'
import { TipoModuloEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback, useEffect, useState } from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'

import {
  NO_ASYNC_LOADING_MESSAGE,
  RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO,
} from '../common/model-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from '../components/form/model-relatorioGerencialFiltrosForm'
import { RelatorioGerencialTableWrapper } from '../components/RelatorioGerencialTableWrapper'
import { cuidadoCompartilhadoKeyMapping } from './keyMapping-relatorioGerencialCuidadoCompartilhado'
import { RelatorioGerencialCuidadoCompartilhadoModel } from './model-relatorioGerencialCuidadoCompartilhado'

const OPERATIONS = {
  build: '/gerenciais/cuidado-compartilhado/build',
  meta: '/gerenciais/cuidado-compartilhado/meta',
}

const NAME = 'Relatório gerencial de cuidado compartilhado'

export function RelatorioGerencialCuidadoCompartilhadoView() {
  const { analytics } = useFirebase()

  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()
  const [pivotTableInitialValues, setPivotTableInitialValues] = useState<
    PivotTableInitialValues<RelatorioGerencialCuidadoCompartilhadoModel>
  >()

  const {
    meta,
    metaState,
    treeBuilderStatus: { isBuilding, isSearchingMeta },
    isMetaEmpty,
    setDateFilter,
    ...rest
  } = useTreeBuilder<RelatorioGerencialCuidadoCompartilhadoModel>({
    keyMapping: cuidadoCompartilhadoKeyMapping,
    operations: OPERATIONS,
  })

  const {
    data: { dataUltimoProcessamento },
  } = useDataUltimoProcessamentoQuery()

  const handleSubmit = useCallback(
    ({ periodo, unidade }: RelatorioGerencialFiltrosFormModel) => {
      meta(periodo, unidade)
      setDateFilter(periodo, unidade)
      setDateRangeFilter(periodo)
    },
    [meta, setDateFilter]
  )

  useEffect(() => {
    analytics.logEvent(RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO, {
      relatorio: NAME,
    })
  }, [analytics])

  const ultimoProcessamento = dataUltimoProcessamento
    ? `Último processamento em ${formatDateAndHoursMinutes(dataUltimoProcessamento)}`
    : 'Ainda não existem dados processados'

  return (
    <>
      <Breadcrumb title={NAME} />
      <PageHeaderSection title={NAME}>
        <Text>{ultimoProcessamento}</Text>
      </PageHeaderSection>
      <PageContent type='filled'>
        <VFlow>
          <Alert inline type='info'>
            O relatório gerencial de cuidado compartilhado apresenta a contagem dos eventos realizados pelo solicitante
            e executante durante as interações no compartilhamento de cuidado.
          </Alert>
          <RelatorioGerencialTableWrapper
            loading={isSearchingMeta}
            isEmpty={isMetaEmpty}
            onSubmit={handleSubmit}
            modulo={TipoModuloEnum.RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO}
            keyMapping={cuidadoCompartilhadoKeyMapping}
            loadingMessage={NO_ASYNC_LOADING_MESSAGE}
            setPivotTableInitialValues={setPivotTableInitialValues}
          >
            {metaState && (
              <PivotTable<RelatorioGerencialCuidadoCompartilhadoModel>
                title={NAME}
                treeMeta={metaState}
                keyMapping={cuidadoCompartilhadoKeyMapping}
                dateRangeFilter={dateRangeFilter}
                isBuilding={isBuilding}
                initialValues={pivotTableInitialValues}
                {...rest}
              />
            )}
          </RelatorioGerencialTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}
