import { Button, HFlow } from 'bold-ui'
import { SubmitButton } from 'components/form'
import React from 'react'
import { EditableListFormRenderProps } from 'view/atendimentos/detail/components/EditableListForm'

import { ProblemaCondicaoModel } from '../model-problemasCondicoes'

interface ProblemasCondicoesFormModalButtonsProps {
  editing: boolean
  onSubmit: EditableListFormRenderProps<ProblemaCondicaoModel>['handleSubmit']
  onCancel(): void
  reset(): void
}

export const ProblemasCondicoesFormModalButtons = ({
  editing,
  onSubmit,
  onCancel,
  reset,
}: ProblemasCondicoesFormModalButtonsProps) => (
  <HFlow justifyContent='flex-end'>
    {editing ? (
      <Button size='small' onClick={onCancel}>
        Cancelar
      </Button>
    ) : (
      <Button size='small' onClick={reset}>
        Limpar campos
      </Button>
    )}

    <SubmitButton handleSubmit={onSubmit} kind='primary' size='small'>
      {editing ? 'Salvar' : 'Adicionar'}
    </SubmitButton>
  </HFlow>
)
