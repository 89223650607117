import { PecSwitch, PrivateRoute } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'

import { TransmissaoEnvioDetail } from './components/envio/TransmissaoEnvioDetail'
import { TransmissaoRecebimentoCnesDetailView } from './components/recebimento/cnesTable/TransmissaoRecebimentoCnesDetailView'

export function TransmissaoDetailRootView() {
  const { match } = useRouter()
  return (
    <PecSwitch>
      <PrivateRoute
        exact
        path={`${match.url}/detail/envio/:idLote(\\d+)`}
        permission={Permissions.transmissaoDeDados.visualizarEnvioDeDados}
        component={TransmissaoEnvioDetail}
      />
      <PrivateRoute
        exact
        permission={Permissions.transmissaoDeDados.visualizarRecebimentoDeDados}
        path={`${match.url}/detail/recebimento/:numCnes/:anoRecebimento(\\d+)/:mesRecebimento(\\d+)`}
        component={TransmissaoRecebimentoCnesDetailView}
      />
    </PecSwitch>
  )
}
