import { Cell, Grid, Tooltip } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { EquipeSelectField } from 'components/form'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import React, { useEffect } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { EncaminhamentoInternoFormModel } from 'view/atendimentos/model'
import { resolveEquipeTooltip, resolveProfissionalTooltip } from 'view/atendimentos/tooltipResolver'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'
import { TipoServicoCheckField } from 'view/configuracoes/municipal/tipo-servico/TipoServicoCheckField'

import { AgendamentosDiaField } from '../../../../agenda/AgendamentosDiaField'

export interface EncaminhamentoFormProps {
  agendamentosDia?: AgendamentoDia[]
  name: MetaPath<EncaminhamentoInternoFormModel>
  isStartOuAtendimentoObservacao?: boolean
}

export function EncaminhamentoForm(props: EncaminhamentoFormProps) {
  const { name, agendamentosDia, isStartOuAtendimentoObservacao = false } = props
  const { acesso, tipoEstabelecimento } = useAcessoLotacaoOrEstagio()

  const { values: formValues } = useFormState({ subscription: { values: true } })
  //Não usar useField pois o onChange é alterado quando o valor do campo muda e isso dispara o useEffect.
  //Alterar quando atualizar o final-form
  const { change, blur } = useForm()
  const values = resolveValue(formValues, name)
  const isCEO = tipoEstabelecimento === TipoEstabelecimentoEnum.CEO
  const unidadeSaudeId = acesso?.unidadeSaude.id

  const firstAgendamentoId =
    !isStartOuAtendimentoObservacao && agendamentosDia?.length ? agendamentosDia[0].id : undefined

  useEffect(() => {
    change(name.agendado.absolutePath(), firstAgendamentoId)
    blur(name.agendado.absolutePath())
  }, [blur, change, firstAgendamentoId, name.agendado])

  return (
    <>
      {!isStartOuAtendimentoObservacao && agendamentosDia && (
        <Cell size={5}>
          <AgendamentosDiaField name={name.agendado} agendamentosDia={agendamentosDia} />
        </Cell>
      )}
      <Cell size={12}>
        <Grid>
          <Cell size={isCEO ? 12 : 6}>
            <Tooltip text={resolveProfissionalTooltip(values)}>
              <LotacaoAndEstagioSelectField
                name={name.responsavel}
                includeEstagios
                label='Profissional'
                unidadeSaudeId={unidadeSaudeId}
                disabled={(values?.equipe && !values?.responsavel) || !!values?.agendado}
                loadItemsOnOpen={false}
              />
            </Tooltip>
          </Cell>
          {!isCEO && (
            <Cell size={6}>
              <Tooltip text={resolveEquipeTooltip(values)}>
                <EquipeSelectField
                  name={name.equipe}
                  label='Equipe'
                  unidadeSaudeId={unidadeSaudeId}
                  disabled={!!values?.responsavel || !!values?.agendado}
                />
              </Tooltip>
            </Cell>
          )}
        </Grid>
      </Cell>
      <Cell size={12}>
        <div>
          <TipoServicoCheckField name={name.tiposServico} />
        </div>
      </Cell>
    </>
  )
}
