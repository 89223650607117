/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Tooltip, VFlow } from 'bold-ui'
import { FormRenderProps } from 'components/form'
import { EditableValueField } from 'components/form'
import {
  CompartilharVideochamadaForm,
  CompartilharVideochamadaModel,
  SMTP_NAO_CONFIGURADO_MESSAGE,
} from 'components/videochamada'
import { useSmtpConfiguradoQuery } from 'graphql/hooks.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'

const meta = metaPath<CompartilharVideochamadaModel>()

interface CompartilharVideochamadaFolhaRostoFormProps {
  videochamadaUuid: string
  emailDestinatario: string
  lotacaoAgendadaId: ID
}

export const CompartilharVideochamadaFolhaRostoForm = React.memo(
  ({ videochamadaUuid, emailDestinatario, lotacaoAgendadaId }: CompartilharVideochamadaFolhaRostoFormProps) => {
    const {
      data: { info: { smtpConfigurado } = {} },
    } = useSmtpConfiguradoQuery({
      fetchPolicy: 'cache-first',
    })

    const render = ({ handleSubmit, submitting }: FormRenderProps<CompartilharVideochamadaModel>) => (
      <form onSubmit={handleSubmit} noValidate>
        <VFlow>
          <EditableValueField
            name={meta.emailDestinatario}
            label='E-mail do convidado'
            placeholder='Adicione o e-mail do convidado'
            aria-label='Input de texto de email'
            disabled={!smtpConfigurado || submitting}
          />
          <div css={styles.buttonContainer}>
            <Tooltip text={!smtpConfigurado && SMTP_NAO_CONFIGURADO_MESSAGE}>
              <Button
                skin='outline'
                kind='primary'
                size='small'
                loading={submitting}
                onClick={handleSubmit}
                disabled={!smtpConfigurado}
              >
                Enviar link para o convidado
              </Button>
            </Tooltip>
          </div>
        </VFlow>
      </form>
    )

    return (
      <CompartilharVideochamadaForm
        videochamadaUuid={videochamadaUuid}
        emailDestinatario={emailDestinatario}
        lotacaoAgendadaId={lotacaoAgendadaId}
        render={render}
        validateOnBlur={false}
        suppressNotificationError
      />
    )
  }
)

const styles = {
  buttonContainer: css`
    display: flex;
    justify-content: end;
  `,
}
