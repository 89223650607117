import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'

export interface PeriodoUltimaVisitaSelectModel {
  ultimaVisitaHaMenosDeUmMes: boolean
  nome: string
}

export interface PeriodoUltimaVisitaSelectFieldProps
  extends Omit<SelectFieldProps<PeriodoUltimaVisitaSelectModel>, 'items' | 'itemToString'> {}

export function PeriodoUltimaVisitaSelectField(props: PeriodoUltimaVisitaSelectFieldProps) {
  const itemToString = (item: PeriodoUltimaVisitaSelectModel) => item && item.nome

  return <SelectField<PeriodoUltimaVisitaSelectModel> items={items} itemToString={itemToString} {...props} />
}

export const VISITA_MENOS_DE_UM_MES: PeriodoUltimaVisitaSelectModel = {
  ultimaVisitaHaMenosDeUmMes: true,
  nome: 'Menos de um mês',
}
export const VISITA_UM_MES_OU_MAIS: PeriodoUltimaVisitaSelectModel = {
  ultimaVisitaHaMenosDeUmMes: false,
  nome: 'Um mês ou mais',
}

const items: PeriodoUltimaVisitaSelectModel[] = [VISITA_MENOS_DE_UM_MES, VISITA_UM_MES_OU_MAIS]
