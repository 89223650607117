import axios, { AxiosPromise } from 'axios'
import { ImpressaoSolicitacaoExamesInput } from 'view/atendimentos/detail/soap/plano/exames/DownloadSolicitacaoExames'

interface ImpressaoSolicitacaoExameInputModel {
  solicitacoes: ImpressaoSolicitacaoExamesInput[]
}

export class ImpressaoSolicitacaoExames {
  imprimirSolicitacaoExames(input: ImpressaoSolicitacaoExameInputModel): AxiosPromise<Blob> {
    return axios.post('/api/atendimento/imprimirSolicitacaoExames', input, { responseType: 'blob' })
  }
}
