import { SubjetivoInput } from 'graphql/types.generated'
import { v4 as uuidv4 } from 'uuid'

import { EvolucaoSubjetivoQueryModel } from './model'
import { SubjetivoFormModel } from './SubjetivoForm'

export const convertSubjetivoFormToInput = (form: SubjetivoFormModel): SubjetivoInput =>
  form && {
    texto: form.texto,
    motivos: form.motivos?.map((motivo) => ({
      id: motivo.editingId,
      ciap: motivo.ciap.id,
      observacao: motivo.observacao,
    })),
  }

export const convertSubjetivoQueryToForm = (
  prevValue: SubjetivoFormModel,
  input: EvolucaoSubjetivoQueryModel
): SubjetivoFormModel => ({
  ...prevValue,
  texto: input?.descricao,
  motivos: input?.ciaps?.map((motivo) => ({
    _id: uuidv4(),
    editingId: motivo.id,
    ciap: motivo.ciap,
    observacao: motivo.nota,
  })),
})
