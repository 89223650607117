import React from 'react'
import { masks, onlyNumbers } from 'util/mask'

import { MaskedField, MaskedFieldProps } from '../final-form/MaskedField'

export interface CepFieldProps extends MaskedFieldProps {}

export function CepField(props: CepFieldProps) {
  return <MaskedField mask={masks.cep} placeholder='00000-000' parse={onlyNumbers} {...props} />
}
