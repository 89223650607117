/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { ComposedFormControl, NumberField } from 'components/form'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { ComposedFormControlProps } from './ComposedFormControl'

export interface IdadeFieldModel {
  anos?: number
  meses?: number
}

export interface IdadeFieldProps extends Omit<ComposedFormControlProps<IdadeFieldModel>, 'name'> {
  name: MetaPath<IdadeFieldModel>
  holderAnos?: string
}

export function IdadeField(props: IdadeFieldProps) {
  const { name, holderAnos, onBlur, ...rest } = props

  const {
    input: { onChange: setMesesIdade, value: mesesIdade },
  } = useField(name.meses.absolutePath())

  return (
    <ComposedFormControl name={name} {...rest}>
      <NumberField
        name={name.anos}
        placeholder={holderAnos}
        maxLength={holderAnos.length}
        clearable={false}
        onBlur={() => {
          setMesesIdade(mesesIdade || 0)
          onBlur && onBlur()
        }}
        style={css`
          width: 1.5rem;
          text-align: right;
        `}
      />
      <Text fontWeight='bold'>anos e</Text>
      <NumberField
        name={name.meses}
        placeholder='00'
        maxLength={2}
        clearable={false}
        onBlur={() => onBlur && onBlur()}
        style={css`
          width: 1rem;
          text-align: right;
        `}
      />
      <Text fontWeight='bold'>meses</Text>
    </ComposedFormControl>
  )
}

IdadeField.defaultProps = {
  clearable: true,
} as IdadeFieldProps
