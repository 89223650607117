import { ColorScale } from 'bold-ui/lib/styles/colors'
import { CardRadioGroupField, CardRadioGroupFieldOption } from 'components/form/field/CardRadioGroupField'
import { Color } from 'csstype'
import { ClassificacaoPrioridadeCuidadoEnum } from 'graphql/types.generated'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { cuidadoCompartilhadoClassificacaoPrioridadeRecord } from '../model-cuidadocompartilhado'

interface ClassificacaoPrioridadeFieldProps {
  name: MetaPath<ClassificacaoPrioridadeCuidadoEnum>
  required?: boolean
}

export function ClassificacaoPrioridadeField(props: ClassificacaoPrioridadeFieldProps) {
  const { name, required } = props

  return <CardRadioGroupField name={name} label='Classificação de prioridade' options={options} required={required} />
}

const items = Object.entries(cuidadoCompartilhadoClassificacaoPrioridadeRecord)

const options: CardRadioGroupFieldOption<ClassificacaoPrioridadeCuidadoEnum>[] = items.map(
  ([key, item]: [ClassificacaoPrioridadeCuidadoEnum, { label: string; mainColor: Color; colorScale: ColorScale }]) => ({
    label: item.label,
    colorScale: item.colorScale,
    value: key,
  })
)
