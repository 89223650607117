import { LOCAL_ATENDIMENTO_UBS_ID } from 'components/form'
import { afterEqualDate, beforeEqualToday, createValidator, ErrorObject, hora, required } from 'util/validation'

import { RegistroTardioFormModel } from './model'

export const validator = (minDate: Date, canAtender: boolean) =>
  createValidator<RegistroTardioFormModel>(
    {
      cidadao: required,
      dataAtendimento: [required, beforeEqualToday, afterEqualDate(minDate)],
      horaAtendimento: [required, hora],
      lotacao: !canAtender && required,
      localAtendimento: required,
    },
    (
      values: RegistroTardioFormModel,
      errors: ErrorObject<RegistroTardioFormModel>
    ): ErrorObject<RegistroTardioFormModel> => {
      if (values.localAtendimento && isLocalAtendimentoUbs(values.localAtendimento.id)) {
        errors.justificativa = required(values.justificativa)
      }
      return errors
    }
  )

export const isLocalAtendimentoUbs = (id: ID) => id === LOCAL_ATENDIMENTO_UBS_ID
