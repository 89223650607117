import { Button, Heading, HFlow, isEqual, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { Form, FormPrompt } from 'components/form'
import clearTypename from 'graphql/clearTypename'
import {
  useConfiguracaoAgendaOnlineLotacaoQuery,
  useSalvarConfiguracaoAgendaOnlineLotacaoMutation,
} from 'graphql/hooks.generated'
import { ConfiguracaoAgendaOnlineLotacaoInput } from 'graphql/types.generated'
import { useRouter } from 'hooks/useRouter'
import React, { useState } from 'react'
import { FormRenderProps, FormSpy } from 'react-final-form'
import { Redirect } from 'react-router'

import { ProfissionalHeaderIdentifier } from '../components/ProfissionalHeaderIdentifier'
import { ConfiguracaoAgendaOnlineLotacaoPanel } from './ConfiguracaoAgendaOnlineLotacaoPanel'

interface UrlParams {
  lotacaoId: string
  profissionalId: string
}

export function ConfiguracaoAgendaOnlineLotacaoView() {
  const { history, match } = useRouter<UrlParams>()

  const { data } = useConfiguracaoAgendaOnlineLotacaoQuery({
    variables: { lotacaoId: match.params.lotacaoId },
    fetchPolicy: 'network-only',
  })

  const [saveMutation] = useSalvarConfiguracaoAgendaOnlineLotacaoMutation()

  const [submitFailedStatus, setSubmitFailedStatus] = useState(false)

  if (!data || !data.lotacao || !data.lotacao.gradeConfiguracaoAgendaOnline) {
    return null
  }

  if (!data.lotacao.actions.permissaoConfigurarAgendaOnline.enabled) {
    return <Redirect to='/403' />
  }

  const { lotacao } = data

  const nomeProfissional = lotacao.profissional.nome

  const handleCancelar = () => {
    history.goBack()
  }

  const handleSubmit = (model: ConfiguracaoAgendaOnlineLotacaoInput) => {
    const variables = {
      configuracaoAgendaOnlineLotacaoInput: {
        lotacaoId: model.lotacaoId,
        configuracoesSemana: model.configuracoesSemana,
      },
    }

    return saveMutation({ variables: clearTypename(variables) }).then((ret) => {
      alert('success', 'Configuração de agenda online salva com sucesso.')
      setTimeout(() => {
        history.goBack()
      })

      return ret
    })
  }

  const submitFailed = () => {
    setSubmitFailedStatus(true)
  }

  let valueControl
  const WarningController = () => (
    <FormSpy
      subscription={{ values: true }}
      onChange={(state) => {
        if (!valueControl) {
          valueControl = state
        }

        if (!isEqual(valueControl, state)) {
          setSubmitFailedStatus(false)
        }

        valueControl = state
      }}
    />
  )

  const renderField = (formRenderProps: FormRenderProps) => {
    return (
      <>
        <WarningController />
        <Breadcrumb title='Configuração de agenda online' />
        <Modal open={true} onClose={handleCancelar} size='large' closeOnBackdropClick={false}>
          <FormPrompt />
          <ModalBody>
            <VFlow>
              <Heading level={1}>
                <div>
                  {lotacao.hasConfiguracaoAgendaOnline
                    ? 'Editar configuração de agenda online'
                    : 'Criar configuração de agenda online'}
                </div>
              </Heading>
              <ProfissionalHeaderIdentifier profissional={{ nomeProfissional, lotacao }} />
              <ConfiguracaoAgendaOnlineLotacaoPanel
                submitFailedStatus={submitFailedStatus}
                cfgHorarioAgendaOnline={data}
                formProps={formRenderProps}
              />
            </VFlow>
          </ModalBody>

          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <Button kind='normal' onClick={handleCancelar}>
                Cancelar
              </Button>
              <Button kind='primary' onClick={formRenderProps.handleSubmit}>
                Salvar
              </Button>
            </HFlow>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  const intialValues = {
    lotacaoId: data.lotacao.id,
    configuracoesSemana: data.lotacao.gradeConfiguracaoAgendaOnline.configuracoesSemana,
  }

  return (
    <Form render={renderField} onSubmit={handleSubmit} initialValues={intialValues} onSubmitFailed={submitFailed} />
  )
}
