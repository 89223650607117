import { Cell, Grid, HeadingSection } from 'bold-ui'
import { RichTextField } from 'components/form'
import { CiapSelectField } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

export interface MotivoConsultaFormModel {
  texto: string
  ciap: {
    id: ID
    codigo: string
    descricao: string
  }
}

export interface MotivoConsultaFormProps {
  name: MetaPath<MotivoConsultaFormModel>
  cidadao: CidadaoAtendimento
}

export function MotivoConsultaForm({ name, cidadao }: MotivoConsultaFormProps) {
  return (
    <HeadingSection level={3} vSpace={8} title='Motivo da consulta'>
      <Grid>
        <Cell size={8}>
          <CiapSelectField
            name={name.ciap}
            label='CIAP 2'
            excluirCIAPsDAB={true}
            sexo={isEmpty(cidadao?.identidadeGeneroDbEnum) ? cidadao?.sexo : undefined}
            required
          />
        </Cell>
        <Cell size={12}>
          <RichTextField
            name={name.texto}
            maxLength={4000}
            placeholder='Insira as impressões subjetivas do profissional e as expressadas pelo cidadão.'
          />
        </Cell>
      </Grid>
    </HeadingSection>
  )
}
