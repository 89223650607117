import { CidadaoAtendimentoPorNomeSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CidadaoAtendimentoPorNomeSelectFieldQuery,
  CidadaoAtendimentoPorNomeSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { CidadaoAtendimentoSelectFieldModel } from '../CidadaoAtendimentoSelectField'
import { CidadaoAtendimentoSelectFieldCommon } from '../CidadaoAtendimentoSelectField/CidadaoAtendimentoSelectFieldCommon'
import { useAsyncQuerySelect } from '../useAsyncQuerySelect'
import { CidadaoAtendimentoPorPropriedadeEmptyItem } from './CidadaoAtendimentoPorPropriedadeEmptyItem'
import { CidadaoAtendimentoPorPropriedadeLoadingItem } from './CidadaoAtendimentoPorPropriedadeLoadingItem'
import { CidadaoAtendimentoPorPropriedadeSelectFieldProps } from './CidadaoAtendimentoPorPropriedadeSelectField'
import {
  commonCidadaoAtendimentoPorPropriedadeSelectFieldInput,
  skipNomeCidadaoAtendimentoSelectField,
} from './util-cidadaoAtendimentoPorPropriedadeSelectField'

interface CidadaoAtendimentoPorNomeSelectFieldProps extends CidadaoAtendimentoPorPropriedadeSelectFieldProps {
  addCidadaoCallbackUrl: string
}

export function CidadaoAtendimentoPorNomeSelectField(props: CidadaoAtendimentoPorNomeSelectFieldProps) {
  const { name, addCidadaoCallbackUrl, onChange } = props

  const { skipping, selectProps } = useAsyncQuerySelect<
    CidadaoAtendimentoSelectFieldModel,
    CidadaoAtendimentoPorNomeSelectFieldQuery,
    CidadaoAtendimentoPorNomeSelectFieldQueryVariables
  >({
    query: CidadaoAtendimentoPorNomeSelectFieldDocument,
    extractItems: (data) => data && data.cidadaosComboByNome,
    variables: (inputString) => ({
      input: {
        ...commonCidadaoAtendimentoPorPropriedadeSelectFieldInput,
        query: inputString,
      },
    }),
    skip: (inputString) => skipNomeCidadaoAtendimentoSelectField(inputString),
    debounceTime: 500,
  })

  const msgNomeCompleto = 'Digite o nome completo do cidadão'

  return (
    <CidadaoAtendimentoSelectFieldCommon
      name={name}
      addCidadaoCallbackUrl={addCidadaoCallbackUrl}
      placeholder={msgNomeCompleto}
      components={{
        EmptyItem: () => (
          <CidadaoAtendimentoPorPropriedadeEmptyItem skipping={skipping} skippingText={msgNomeCompleto} />
        ),
        LoadingItem: () => <CidadaoAtendimentoPorPropriedadeLoadingItem />,
      }}
      selectProps={selectProps}
      onChange={onChange}
    />
  )
}
