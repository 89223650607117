import { createValidator, required, richTextMaxLength } from 'util/validation'

import { IntervencoesProcedimentosPlanoModel } from '../components/IntervencoesProcedimentosView'
import { PlanoFormModel } from '../PlanoForm'

export const intervencoesProcedimentosPlanoValidator = createValidator<IntervencoesProcedimentosPlanoModel>({
  procedimentos: [required],
})

export const planoProcedimentosValidator = () =>
  createValidator<PlanoFormModel>({
    texto: [richTextMaxLength(4000)],
    intervencoesProcedimentos: intervencoesProcedimentosPlanoValidator,
  })
