import { Omit, useStyles } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import React from 'react'

interface ConfiguracaoAgendaOnlineGridContainerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'style'> {
  fimDeSemana: boolean
}

export function ConfiguracaoAgendaOnlineGridContainer(props: ConfiguracaoAgendaOnlineGridContainerProps) {
  const { fimDeSemana } = props
  const width = 108
  const { css, classes } = useStyles(() => ({
    grid: {
      display: 'grid',
      padding: 0,
      margin: '0 auto',
      width: `${fimDeSemana ? 70 + 7 * width + 1 : 70 + 5 * width + 1}px`,
      gridTemplateColumns: `70px repeat(${fimDeSemana ? 7 : 5}, ${width}px)`,
      border: '1px solid' + gray.c80,
      borderRadius: '2px',
    },
  }))

  return <div className={css(classes.grid)} {...props} />
}
