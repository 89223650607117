import { VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { BoxGroup } from 'components/BoxGroup'
import { LoadingIndicator } from 'components/loading'
import { useConexaoQuery } from 'graphql/hooks.generated'
import React from 'react'
import Permissions from 'types/Permissions'

import { ConfiguracoesInternetBox } from './internet/ConfiguracoesInternetBox'

export const ConexaoView = () => {
  const { data, refetch } = useConexaoQuery()

  if (!data?.conexao || !data?.info) {
    return <LoadingIndicator />
  }

  const { internet, cadsus, horus, videochamadas, agendaOnline, prescricaoDigital } = data.conexao
  const { smtpConfigurado } = data.info

  return (
    <VFlow vSpacing={2}>
      <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarConexaoComInternet}>
        <BoxGroup>
          <ConfiguracoesInternetBox
            internet={internet}
            cadsus={cadsus}
            horus={horus}
            videochamada={videochamadas}
            agendaOnline={agendaOnline}
            prescricaoDigital={prescricaoDigital}
            smtpConfigurado={smtpConfigurado}
            refetch={refetch}
          />
        </BoxGroup>
      </CheckPermission>
    </VFlow>
  )
}
