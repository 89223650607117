import { StatusAtendimento } from 'graphql/types.generated'

import { statusAtendimentoStyles, StatusAtendimentoType } from '../../atendimentos/list/listaAtendimentoStyles'

export const listaRegistroTardioStyles: Partial<Record<StatusAtendimento, StatusAtendimentoType>> = {
  [StatusAtendimento.AGUARDANDO_ATENDIMENTO]: {
    styleBox: statusAtendimentoStyles.aguardandoAtendimentoBox,
    styleBorder: statusAtendimentoStyles.aguardandoAtendimentoBorder,
    descricao: 'Aguardando registro',
  },
  [StatusAtendimento.ATENDIMENTO_REALIZADO]: {
    styleBox: statusAtendimentoStyles.atendimentoRealizadoBox,
    styleBorder: statusAtendimentoStyles.atendimentoRealizadoBorder,
    descricao: 'Registro realizado',
  },
  [StatusAtendimento.EM_ATENDIMENTO]: {
    styleBox: statusAtendimentoStyles.emAtendimentoBox,
    styleBorder: statusAtendimentoStyles.emAtendimentoBorder,
    descricao: 'Em registro',
  },
}
