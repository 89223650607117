/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { memo } from 'react'

interface AtestadoContentBoxProps {
  title: string
  content: string
}

export const AtestadoContentBox = memo((props: AtestadoContentBoxProps) => {
  const { title, content } = props
  return (
    <Box>
      <VFlow
        vSpacing={1}
        style={css`
          justify-content: center;
          align-items: center;
          margin: 1rem 1.75rem;
        `}
      >
        <Heading level={3}>{title}</Heading>
        <Text dangerouslySetInnerHTML={{ __html: content }} />
      </VFlow>
    </Box>
  )
})
