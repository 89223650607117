import axios, { AxiosPromise } from 'axios'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'

export class AcompanhamentoSaudeRelatorio {
  downloadRelatorioOperacionalCrianca(filter: AcompanhamentoCondicaoSaudeQueryInput): AxiosPromise {
    return axios.post('/api/acompanhamentoSaude/relatorioOperacionalCrianca', filter, { responseType: 'blob' })
  }

  downloadRelatorioOperacionalGestante(filter: AcompanhamentoCondicaoSaudeQueryInput): AxiosPromise {
    return axios.post('/api/acompanhamentoSaude/relatorioOperacionalGestante', filter, { responseType: 'blob' })
  }

  downloadRelatorioOperacionalRiscoCardiovascular(filter: AcompanhamentoCondicaoSaudeQueryInput): AxiosPromise {
    return axios.post('/api/acompanhamentoSaude/relatorioOperacionalRiscoCardiovascular', filter, {
      responseType: 'blob',
    })
  }
}
