import { useCallback, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export type ContentRect = Pick<DOMRectReadOnly, 'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'>

/**
 * copied from https://github.com/streamich/react-use
 */

const useMeasure = <T>(item: keyof ContentRect): [(instance: T) => void, number] => {
  const [measure, setMeasure] = useState(0)

  const [observer] = useState(
    () =>
      new ResizeObserver((entries) => {
        const entry = entries[0]
        if (entry.contentRect[item] !== measure) {
          setMeasure(entry.contentRect[item])
        }
      })
  )

  const ref = useCallback(
    (node) => {
      observer.disconnect()
      if (node) {
        observer.observe(node)
      }
    },
    [observer]
  )
  return [ref, measure]
}

export const useX = () => useMeasure('x')

export const useY = () => useMeasure('y')

export const useWidth = () => useMeasure('width')

export const useHeight = () => useMeasure('height')

export const useTop = () => useMeasure('top')

export const useLeft = () => useMeasure('left')

export const useBottom = () => useMeasure('bottom')

export const useRight = () => useMeasure('right')
