import { createValidator, required, richTextMaxLength } from 'util/validation'

import { AvaliacaoFormModel } from '../AvaliacaoForm'

export const avaliacaoOdontoValidator = () =>
  createValidator<AvaliacaoFormModel>({
    tiposVigilancia: [required],
    necessidadeProtese: [required],
    problemasECondicoes: [required],
    texto: [richTextMaxLength(4000)],
  })
