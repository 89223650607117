import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import React from 'react'

interface ModalCancelarSolicitacaoProps {
  isModalOpen: boolean
  setIsModalOpen(isOpen: boolean): void
  solicitacaoId: string
  onCancelarSolicitacao(solicitacaoId: string): void
}

export const ModalCancelarSolicitacao = (props: ModalCancelarSolicitacaoProps) => {
  const { isModalOpen, setIsModalOpen, solicitacaoId, onCancelarSolicitacao } = props
  const handleFecharModal = () => setIsModalOpen(false)
  const handleCancelarSolicitacao = () => {
    onCancelarSolicitacao(solicitacaoId)
    handleFecharModal()
  }

  return (
    <Modal open={isModalOpen} onClose={handleFecharModal} size='small' closeOnBackdropClick={true} hasCloseIcon={true}>
      <ModalBody>
        <VFlow>
          <HFlow alignItems='flex-start'>
            <Icon icon='infoCircleOutline' fill='info' size={3} />
            <VFlow>
              <Heading level={2}>Deseja cancelar a solicitação de envio?</Heading>
              <Text>
                Ao cancelar a solicitação de envio, os dados não serão enviados para a instalação central do município.
              </Text>
            </VFlow>
          </HFlow>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' onClick={handleFecharModal}>
            Não, continuar
          </Button>
          <Button kind='primary' onClick={handleCancelarSolicitacao}>
            Sim, cancelar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
