import { NeuroFaixaEtariaEnum, StatusAvaliadoEnum } from 'graphql/types.generated'

import { AvaliacaoDesenvolvimentoModel } from '../model'

export const faixaEtariaAnterior = (
  marco: Partial<Record<NeuroFaixaEtariaEnum, AvaliacaoDesenvolvimentoModel[]>>
): AvaliacaoDesenvolvimentoModel[] =>
  Object.values(marco)
    .reverse()
    .slice(1)
    .find((avaliacoes: AvaliacaoDesenvolvimentoModel[]) =>
      avaliacoes.some((item) => item.statusButton !== StatusAvaliadoEnum.NAO_AVALIADO)
    )
