import { SelectField } from 'components/form'
import { GrupoAtendimentoSelectFieldDocument } from 'graphql/hooks.generated'
import { GrupoAtendimentoSelectFieldQuery, GrupoAtendimentoSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type GrupoAtendimentoSelectFieldModel = GrupoAtendimentoSelectFieldQuery['gruposAtendimento'][0]

export interface GrupoAtendimentoSelectFieldProps extends AsyncSelectFieldProps<GrupoAtendimentoSelectFieldModel> {
  onlyAtivos?: boolean
}

const extractItems = (data: GrupoAtendimentoSelectFieldQuery) => data?.gruposAtendimento

const handleItemToString = (item: GrupoAtendimentoSelectFieldModel) => item?.nome

export function GrupoAtendimentoSelectField(props: GrupoAtendimentoSelectFieldProps) {
  const { onlyAtivos, ...rest } = props
  const { selectProps } = useAsyncQuerySelect<
    GrupoAtendimentoSelectFieldModel,
    GrupoAtendimentoSelectFieldQuery,
    GrupoAtendimentoSelectFieldQueryVariables
  >({
    query: GrupoAtendimentoSelectFieldDocument,
    extractItems,
    variables: (inputQuery: string): GrupoAtendimentoSelectFieldQueryVariables => ({
      inputQuery: inputQuery,
      onlyAtivos: onlyAtivos,
    }),
    fetchPolicy: 'cache-first',
  })

  return <SelectField<GrupoAtendimentoSelectFieldModel> itemToString={handleItemToString} {...selectProps} {...rest} />
}
