import { Tabs } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { TabLink } from 'components/route'
import { PecSwitch } from 'components/route/PecSwitch'
import { PrivateRoute } from 'components/route/PrivateRoute'
import { useFlags } from 'config/useFlagsContext'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { Redirect } from 'react-router'
import Permissions from 'types/Permissions'

import { ConfiguracoesAvancadasView } from './avancado/ConfiguracaoesAvancadasView'
import { ConexaoView } from './conexao/ConexaoView'
import { MunicipiosResponsaveisView } from './municipio-responsavel/MunicipiosResponsaveisView'
import { SegurancaView } from './seguranca/SegurancaView'
import { ServidoresView } from './servidores/ServidoresView'
import { UnificacaoBaseView } from './unificacao/UnificacaoBaseView'

export function ConfiguracoesInstalacaoRootView() {
  const { match } = useRouter()
  const { hasAuthorization } = useSession()
  const { UNIFICACAO_BASE_ENABLED } = useFlags()

  return (
    <>
      <Breadcrumb title='Configurações da instalação' />
      <PageHeaderSection title='Configurações da instalação'>
        <Tabs>
          <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarConexaoComInternet}>
            <TabLink to={`${match.url}/internet`}>Conexão</TabLink>
          </CheckPermission>
          <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarSeguranca}>
            <TabLink to={`${match.url}/seguranca`}>Segurança</TabLink>
          </CheckPermission>
          <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarServidores}>
            <TabLink to={`${match.url}/servidores`}>Servidores</TabLink>
          </CheckPermission>
          <CheckPermission permission={Permissions.listarMunicipiosEResponsaveis}>
            <TabLink to={`${match.url}/municipiosrespo`}>Municípios e responsáveis</TabLink>
          </CheckPermission>
          <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarNumeroDeRequisicoesSimultaneas}>
            <TabLink to={`${match.url}/avancadas`}>Configurações avançadas</TabLink>
          </CheckPermission>
          {UNIFICACAO_BASE_ENABLED && (
            <CheckPermission permission={Permissions.configuracaoDeInstalacao.configurarUnificacaoDeBase}>
              <TabLink to={`${match.url}/unificacaobase`}>Unificação de base</TabLink>
            </CheckPermission>
          )}
        </Tabs>
      </PageHeaderSection>

      <PageContent>
        <PecSwitch>
          {hasAuthorization(Permissions.configuracaoDeInstalacao.configurarConexaoComInternet) && (
            <Redirect exact from={`${match.url}`} to={`${match.url}/internet`} />
          )}
          {hasAuthorization(Permissions.configuracaoDeInstalacao.configurarSeguranca) && (
            <Redirect exact from={`${match.url}`} to={`${match.url}/seguranca`} />
          )}
          {hasAuthorization(Permissions.configuracaoDeInstalacao.configurarServidores) && (
            <Redirect exact from={`${match.url}`} to={`${match.url}/servidores`} />
          )}
          {hasAuthorization(Permissions.listarMunicipiosEResponsaveis) && (
            <Redirect exact from={`${match.url}`} to={`${match.url}/municipiosrespo`} />
          )}
          {hasAuthorization(Permissions.configuracaoDeInstalacao.configurarNumeroDeRequisicoesSimultaneas) && (
            <Redirect exact from={`${match.url}`} to={`${match.url}/avancada`} />
          )}
          {hasAuthorization(Permissions.configuracaoDeInstalacao.configurarUnificacaoDeBase) &&
            UNIFICACAO_BASE_ENABLED && <Redirect exact from={`${match.url}`} to={`$match.url/unificacaobase`} />}
          <PrivateRoute
            exact
            path={`${match.url}/internet`}
            component={ConexaoView}
            permission={Permissions.configuracaoDeInstalacao.configurarConexaoComInternet}
          />
          <PrivateRoute
            exact
            path={`${match.url}/seguranca`}
            component={SegurancaView}
            permission={Permissions.configuracaoDeInstalacao.configurarSeguranca}
          />
          <PrivateRoute
            exact
            path={`${match.url}/servidores`}
            component={ServidoresView}
            permission={Permissions.configuracaoDeInstalacao.configurarServidores}
          />
          <PrivateRoute
            exact
            path={`${match.url}/municipiosrespo`}
            component={MunicipiosResponsaveisView}
            permission={Permissions.listarMunicipiosEResponsaveis}
          />
          <PrivateRoute
            exact
            path={`${match.url}/avancadas`}
            component={ConfiguracoesAvancadasView}
            permission={Permissions.configuracaoDeInstalacao.configurarNumeroDeRequisicoesSimultaneas}
          />
          <PrivateRoute
            exact
            path={`${match.url}/unificacaobase`}
            component={UnificacaoBaseView}
            permission={UNIFICACAO_BASE_ENABLED && Permissions.configuracaoDeInstalacao.configurarUnificacaoDeBase}
          />
        </PecSwitch>
      </PageContent>
    </>
  )
}
