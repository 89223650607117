import { ListaAtendimentoQuery, StatusAtendimento, StatusRevisaoAtendimento } from 'graphql/types.generated'

export const LA_PERIODO_SOMENTE_NAO_FINALIZADOS_IN_DAYS = 7
export const LA_DEFAULT_PERIODO_IN_DAYS = 1
export const LA_UPA_PERIODO_IN_HOURS = 24

export const STATUS_SOMENTE_NAO_FINALIZADOS = [
  StatusAtendimento.AGUARDANDO_ATENDIMENTO,
  StatusAtendimento.EM_ATENDIMENTO,
  StatusAtendimento.EM_ESCUTA_INICIAL,
  StatusAtendimento.AGUARDANDO_OBSERVACAO,
  StatusAtendimento.EM_OBSERVACAO,
]

export const STATUS_ATENDIMENTO_FINALIZADO = [
  StatusAtendimento.NAO_AGUARDOU,
  StatusAtendimento.ATENDIMENTO_REALIZADO,
  StatusAtendimento.EVADIU_OBSERVACAO,
]

export const statusRevisaoAtendimentoDescricaoRecord: Record<StatusRevisaoAtendimento, { descricao: string }> = {
  AGUARDANDO_REVISAO: { descricao: 'Aguardando revisão' },
  EM_REVISAO: { descricao: 'Em revisão' },
  EM_RETIFICACAO: { descricao: 'Em retificação' },
  AGUARDANDO_RETIFICACAO: { descricao: 'Aguardando retificação' },
}

export const OBSERVACAO_STATUS_TO_TOGGLE = [
  [StatusAtendimento.AGUARDANDO_ATENDIMENTO, StatusAtendimento.AGUARDANDO_OBSERVACAO],
  [StatusAtendimento.EM_ATENDIMENTO, StatusAtendimento.EM_OBSERVACAO],
  [StatusAtendimento.NAO_AGUARDOU, StatusAtendimento.EVADIU_OBSERVACAO],
]

export type AtendimentoModel = ListaAtendimentoQuery['atendimentos']['content'][0]
