/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente25(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='36' height='100' viewBox='0 0 36 100' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M30.3415 69.0468C30.3275 68.9991 30.3129 68.9487 30.2976 68.8957C30.156 68.4056 29.9559 67.6883 29.7201 66.7757C29.2488 64.9505 28.6351 62.343 28.0644 59.2092C26.9237 52.9455 25.9511 44.5601 26.6393 36.1079C26.9122 32.7554 27.2499 29.3685 27.5763 26.0952C28.0708 21.1354 28.5393 16.4364 28.7172 12.5136C28.8648 9.25745 28.8075 6.60123 28.4157 4.81413C28.219 3.91715 27.9502 3.2993 27.6313 2.93152C27.3386 2.59385 26.9967 2.45411 26.5351 2.51564C25.4336 2.66249 24.1525 3.42686 22.7671 4.90149C21.3903 6.36704 19.9571 8.48527 18.5466 11.2326C15.7266 16.7251 13.0301 24.6685 11.055 34.7561C9.07873 44.8497 8.41975 52.016 7.9521 57.1793L7.93742 57.3414C7.71075 59.8446 7.52522 61.8936 7.24879 63.5696C6.96647 65.2812 6.58248 66.6529 5.93036 67.756C5.83257 67.9214 5.84794 68.0006 5.86055 68.0376C5.88018 68.0951 5.94979 68.2037 6.16683 68.3403C6.60813 68.618 7.41415 68.8603 8.54508 69.0488C10.7781 69.4209 14.0057 69.5434 17.3411 69.5375C20.6685 69.5315 24.074 69.398 26.6494 69.2657C27.9367 69.1996 29.0156 69.1339 29.7725 69.0847C29.9896 69.0706 30.1801 69.0578 30.3415 69.0468Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M30.8635 69.9607C30.8121 69.7376 30.7625 69.5219 30.7145 69.3133C30.1048 66.6613 29.7587 65.1559 29.2517 64.0457C28.7282 62.8993 28.029 62.1719 26.5492 61.036C25.8926 60.5321 24.6987 60.0909 23.1469 59.812C21.612 59.5362 19.7861 59.4297 17.9159 59.5515C14.1444 59.7972 10.3375 60.9597 8.30974 63.3659C6.23162 65.8319 4.76988 70.0306 3.83113 74.269C2.89642 78.4892 2.50006 82.6534 2.50006 84.9749C2.50006 85.9855 3.07783 87.2264 4.13619 88.5586C5.18257 89.8758 6.64636 91.2162 8.29404 92.4151C9.94038 93.6131 11.7547 94.6584 13.4921 95.3924C15.238 96.1301 16.8672 96.5367 18.1549 96.4992C20.8233 96.4214 23.5979 95.3767 26.1095 94.0117C28.6151 92.65 30.8206 90.9908 32.3422 89.7291C32.9454 89.2289 33.3225 88.2043 33.4507 86.6828C33.5764 85.1899 33.4528 83.3457 33.1861 81.3643C32.7243 77.934 31.8465 74.175 31.1641 71.2534C31.0585 70.801 30.9575 70.3687 30.8635 69.9607Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M33.9379 91.6536L33.9379 91.6536C35.2777 90.5427 35.7388 88.7763 35.9072 87.25C36.0856 85.6329 35.9891 83.7532 35.7682 81.861C35.3276 78.0872 34.3376 73.8486 33.6095 70.7314L33.5979 70.6815C33.4923 70.2294 33.3923 69.8016 33.2996 69.3993C33.2455 69.1646 33.1934 68.9376 33.1428 68.7174C33.1157 68.5997 33.0891 68.4836 33.0628 68.3689C32.4675 65.7747 32.0479 63.9461 31.2715 62.4942C29.8999 56.2194 28.3198 46.275 29.1311 36.3108C29.4005 33.0021 29.7354 29.6419 30.0617 26.3676L30.073 26.2544C30.7231 19.7324 31.3469 13.4737 31.288 8.90845C31.2589 6.64599 31.0643 4.55983 30.496 3.01529C30.208 2.23232 29.7597 1.40722 29.0177 0.805998C28.2079 0.149936 27.2218 -0.0979955 26.2048 0.0375822C24.2749 0.29487 22.4994 1.53536 20.9452 3.18978C19.3568 4.88051 17.7989 7.21548 16.3227 10.0908C13.3661 15.8494 10.6074 24.0314 8.60166 34.2758C6.58179 44.5923 5.92005 51.8999 5.4493 57.0985L5.44909 57.1008C5.12877 60.6382 4.90878 62.9875 4.46865 64.6934C2.82201 67.8397 1.7236 71.8347 1.03028 75.4794C0.318043 79.2235 4.95911e-05 82.7792 4.95911e-05 84.975C4.95911e-05 86.8384 1.00448 88.6357 2.1787 90.1137C3.4008 91.6521 5.0454 93.1431 6.82311 94.4366C8.60622 95.7341 10.5862 96.8787 12.5191 97.6953C14.4177 98.4975 16.4287 99.0506 18.2277 98.9982C21.4537 98.9041 24.6298 97.6612 27.3032 96.2083C30.0005 94.7424 32.3436 92.9756 33.9379 91.6536ZM6.34192 65.4299C3.20608 71.2603 2.00005 80.8799 2.00005 84.975C2.00005 89.6995 12.6097 97.1611 18.1695 96.999C23.7292 96.8369 29.5892 92.6615 32.6613 90.1141C35.5277 87.7372 33.1196 77.427 31.6503 71.1364L31.6502 71.1361C31.5448 70.6847 31.4442 70.254 31.3507 69.8485C31.2965 69.613 31.2442 69.3854 31.1936 69.1653C30.4791 66.0558 30.1079 64.4406 29.3819 63.2134C27.977 56.8698 26.2908 46.551 27.1377 36.1484C27.4089 32.8187 27.7456 29.4404 28.0716 26.1692C29.3891 12.9498 30.5325 1.47834 26.4691 2.02004C21.4003 2.69579 14.5268 14.4221 10.5644 34.6601C8.56433 44.8753 7.90993 52.102 7.44094 57.2812C7.11492 60.8814 6.8785 63.4923 6.34192 65.4299Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
