/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, ModalProps, Text, VFlow } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { FooterButton } from 'components/footer-button'
import { useCancelarAtendimentoMutation } from 'graphql/hooks.generated'
import { ReactComponent as IniciarVideochamadaImage } from 'images/videochamada/iniciar-videochamada.svg'
import { ReactComponent as PreenchimentoInfosVideochamadaImage } from 'images/videochamada/preenchimento-infos-videochamada.svg'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router'

interface ConfirmarNaoAnfitriaoAtenderModalProps extends Omit<ModalProps, 'open'> {
  nomeProfissionalAnfitriao: string
  atendimentoId: ID
  clearCache(updateState?: boolean): void
}

export function ConfirmarNaoAnfitriaoAtenderModal(props: ConfirmarNaoAnfitriaoAtenderModalProps) {
  const { atendimentoId, nomeProfissionalAnfitriao, clearCache, style: externalStyle, ...rest } = props

  const [open, setOpen] = useState(true)

  const closeModal = useCallback(() => setOpen(false), [])
  const history = useHistory()
  const [cancelar] = useCancelarAtendimentoMutation()
  const handleRejection = useErrorHandler()

  const handleVoltarClick = useCallback(
    () =>
      cancelar({ variables: { atendimentoId } })
        .then(() => {
          clearCache(false)
          history.push('/lista-atendimento')
        })
        .catch(handleRejection),
    [atendimentoId, cancelar, clearCache, handleRejection, history]
  )

  return (
    <Modal open={open} onClose={closeModal} style={css(styles.modal, externalStyle)} {...rest}>
      <ModalBody style={styles.modalBody}>
        <Grid gap={2}>
          <Cell size={12}>
            <HFlow>
              <Icon icon='infoCircleOutline' size={3} fill='primary' style={styles.icon} />
              <Heading level={1}>
                Profissional anfitrião deve iniciar o atendimento para acessar os recursos do agendamento entre
                profissionais
              </Heading>
            </HFlow>
          </Cell>
          <Cell size={12}>
            <Text component='p'>
              Esse cidadão tem um agendamento entre profissionais hoje. É necessário que o profissional anfitrião{' '}
              <Text fontWeight='bold'>{nomeProfissionalAnfitriao}</Text> atenda o cidadão para acessar os seguintes
              recursos:
            </Text>
          </Cell>
          <Cell size={6}>
            <VFlow style={styles.column}>
              <PreenchimentoInfosVideochamadaImage css={styles.image} />
              <Heading level={3}>
                Preenchimento automático das informações sobre o agendamento entre profissionais
              </Heading>
              <Text component='p'>
                Os campos de Atendimento compartilhado virão preenchidos com as informações do convidado registradas no
                momento do agendamento.
              </Text>
            </VFlow>
          </Cell>
          <Cell size={6}>
            <VFlow style={styles.column}>
              <IniciarVideochamadaImage css={styles.image} />
              <Heading level={3}>Iniciar a chamada de vídeo pelo Videochamada e-SUS APS</Heading>
              <Text component='p'>
                Caso a consulta seja via Videochamada e-SUS APS, o profissional anfitrião precisa iniciar a chamada e
                autorizar a entrada do profissional convidado.
              </Text>
            </VFlow>
          </Cell>
        </Grid>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <FooterButton onClick={handleVoltarClick}>Voltar</FooterButton>
          <FooterButton kind='primary' onClick={closeModal}>
            Continuar
          </FooterButton>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const styles = {
  image: css`
    height: 10.5rem;
    margin: auto;
    max-width: 100%;
  `,
  modal: css`
    max-width: 50.5rem;
  `,
  modalBody: css`
    padding: 2.5rem 2rem;
  `,
  icon: css`
    margin-top: -0.3rem;
  `,
  column: css`
    align-items: center;
    margin-top: 1rem;
  `,
}
