/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, TableCell, TableRow, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Fragment, useMemo } from 'react'

import { HistoricoPeriogramaSimplificadoModel } from './HistoricoPeriogramaSimplificadoTable'

interface HistoricoPeriogramaSimplificadoTableRowProps {
  item: HistoricoPeriogramaSimplificadoModel
}

export function HistoricoPeriogramaSimplificadoTableRow(props: HistoricoPeriogramaSimplificadoTableRowProps) {
  const { item } = props

  const classes = useMemo(() => createStyles(!!item.observacao), [item.observacao])

  return (
    <Fragment>
      <TableRow>
        <TableCell style={classes.column}>
          <DateTime value={item.atendimentoProfissionalOdonto.atendimentoProfissional.iniciadoEm} format='DD/MM/YYYY' />
        </TableCell>
        <TableCell style={classes.column}>
          <Text>{item.avaliacaoSextante1}</Text>
        </TableCell>
        <TableCell style={classes.column}>
          <Text>{item.avaliacaoSextante2}</Text>
        </TableCell>
        <TableCell style={classes.column}>
          <Text>{item.avaliacaoSextante3}</Text>
        </TableCell>
        <TableCell style={classes.column}>
          <Text>{item.avaliacaoSextante4}</Text>
        </TableCell>
        <TableCell style={classes.column}>
          <Text>{item.avaliacaoSextante5}</Text>
        </TableCell>
        <TableCell style={classes.column}>
          <Text>{item.avaliacaoSextante6}</Text>
        </TableCell>
        <TableCell style={classes.column}>
          <VFlow vSpacing={0}>
            <Text>
              {item.atendimentoProfissionalOdonto.atendimentoProfissional.lotacao.profissional.nome.titleCase()}
            </Text>
            <Text>{item.atendimentoProfissionalOdonto.atendimentoProfissional.lotacao.cbo.nome}</Text>
          </VFlow>
        </TableCell>
      </TableRow>
      {item.observacao && (
        <TableRow>
          <TableCell
            colSpan={8}
            style={css`
              height: auto;
              border-top: 0;
              padding-bottom: 0.375rem;
            `}
          >
            <HFlow hSpacing={0.2}>
              <Text fontWeight='bold'>Observações: </Text>
              <Text
                fontStyle='italic'
                style={css`
                  word-break: break-all;
                `}
              >
                <div dangerouslySetInnerHTML={{ __html: item.observacao }} />
              </Text>
            </HFlow>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}

const createStyles = (removeBorderBottom?: boolean) => ({
  column: css`
    padding: 0.375rem 1rem;
    ${removeBorderBottom && `border-bottom: 0;`}
  `,
})
