/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Cell,
  Grid,
  HFlow,
  InfoLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  VFlow,
} from 'bold-ui'
import theme from 'config/theme'
import { HistoricoSectionType } from 'view/atendimentos/detail/historico/components/HistoricoSection'
import { HistoricoSectionStyles } from 'view/atendimentos/detail/historico/model/model-historico'
import { CaracteristicasLesaoPanel } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/tecidos-moles/components/caracteristicas-lesao/CaracteristicasLesaoPanel'
import { extractCaracteristicasLesaoEnum } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/tecidos-moles/historico-tecidos-moles/HistoricoTecidosMolesTable'
import { HistoricoTecidosMolesQueryModel } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/tecidos-moles/model'
import { showDescricaoCodigoProcedimento } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/util-EvolucoesOdontologicasPlano'

interface HistoricoTecidosMolesTableProps {
  title?: string
  titleStyle?: HistoricoSectionType
  tecidosMoles: HistoricoTecidosMolesQueryModel[]
}

export const HistoricoTecidosMolesTable = (props: HistoricoTecidosMolesTableProps) => {
  const { title, titleStyle, tecidosMoles } = props

  return (
    <Table>
      {title && (
        <TableHead>
          <TableRow>
            <TableHeader>
              <Text
                style={css`
                  color: ${titleStyle && HistoricoSectionStyles[titleStyle].color};
                `}
              >
                {title}
              </Text>
            </TableHeader>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {tecidosMoles.map((tecidoMole) => (
          <TableRow key={tecidoMole.id}>
            <TableCell style={classes.cell}>
              <Grid>
                <Cell size={3}>
                  <InfoLabel title='Local'>{tecidoMole.parteBucal.descricao}</InfoLabel>
                </Cell>
                <Cell size={5}>
                  <InfoLabel title='CID 10'>
                    {tecidoMole.cid10 ? `${tecidoMole.cid10.nome.capitalize()} - ${tecidoMole.cid10.codigo}` : '-'}
                  </InfoLabel>
                </Cell>
                <Cell size={4}>
                  <InfoLabel title='Procedimentos'>
                    {!!tecidoMole.evolucaoProcedimentoOdonto?.procedimentos?.length ? (
                      <VFlow vSpacing={0}>
                        {tecidoMole.evolucaoProcedimentoOdonto.procedimentos.map((proced) => (
                          <Text key={proced.id}>{showDescricaoCodigoProcedimento(proced)}</Text>
                        ))}
                      </VFlow>
                    ) : (
                      <Text>-</Text>
                    )}
                  </InfoLabel>
                </Cell>
                {tecidoMole.evolucaoProcedimentoOdonto?.observacao && (
                  <Cell size={12}>
                    <HFlow hSpacing={0.25}>
                      <Text fontWeight='bold'>Observações: </Text>

                      <Text
                        dangerouslySetInnerHTML={{ __html: tecidoMole.evolucaoProcedimentoOdonto.observacao }}
                        style={classes.observacoes}
                      />
                    </HFlow>
                  </Cell>
                )}
                {(tecidoMole.caracteristicasLesao || tecidoMole.descricaoSintomatologia) && (
                  <Cell size={12}>
                    <CaracteristicasLesaoPanel
                      items={extractCaracteristicasLesaoEnum(tecidoMole.caracteristicasLesao)}
                      descricaoSintomatologia={tecidoMole.descricaoSintomatologia}
                      panelStyle={classes.caracteristicasLesaoPanel}
                    />
                  </Cell>
                )}
              </Grid>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const classes = {
  cell: css`
    padding: 0.5rem 1rem;
    &:hover {
      background-color: ${theme.pallete.surface.background};
    }
  `,
  caracteristicasLesaoPanel: css`
    background: transparent;
    padding: 0;
  `,
  observacoes: css`
    word-break: break-all;
  `,
}
