/**
 * Define um timeout para execução da Promise
 * @param promise Promise a ser executada.
 * @param timeoutMillis Tempo em milissegundos para execução da Promise.
 * @param timeoutResponse Resposta caso o timeout seja ultrapassado. Se não informado, a Promise é rejeitada em caso de timeout.
 */
export function timeout<T>(promise: Promise<T>, timeoutMillis: number, timeoutResponse?: T): Promise<T> {
  const timeoutPromise = new Promise<T>((resolve, reject) =>
    setTimeout(() => (timeoutResponse ? resolve(timeoutResponse) : reject('Promise timeout.')), timeoutMillis)
  )
  return Promise.race([promise, timeoutPromise])
}
