import { Cell, Grid, Heading, useTheme } from 'bold-ui'
import React from 'react'

import { MarcadoresConsumoAlimentarListItem } from '../MarcadoresConsumoAlimentarListItem'
import { MCADoisAnosOuMaisPanelModel, mcaDoisAnosOuMaisQuestions, MCAProps } from '../model'
import { createStyles } from '../utils'
import { SimNaoNaoSabeRadioGroupField } from './SimNaoNaoSabeRadioGroupField'
import { TipoRefeicaoCheckboxGroupField } from './TipoRefeicaoCheckboxGroupField'

const filter = ['refeicoesAssistindoTV', 'refeicoesPorDia']
const filteredQuestions = Object.entries(mcaDoisAnosOuMaisQuestions).filter(([key, _]) => !filter.includes(key))

export function MCADoisAnosOuMais(props: MCAProps<MCADoisAnosOuMaisPanelModel>) {
  const { name } = props
  const theme = useTheme()
  const style = createStyles(theme)

  return (
    <>
      <Grid>
        <Cell size={12} style={style.border}>
          <MarcadoresConsumoAlimentarListItem label={mcaDoisAnosOuMaisQuestions.refeicoesAssistindoTV}>
            <SimNaoNaoSabeRadioGroupField name={name.refeicoesAssistindoTV} showErrorField={false} />
          </MarcadoresConsumoAlimentarListItem>
        </Cell>
        <Cell size={12} style={style.border}>
          <TipoRefeicaoCheckboxGroupField name={name.refeicoesPorDia} showErrorField={false} />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={12} style={style.sectionHeading}>
          <Heading level={6}>Ontem você consumiu:</Heading>
        </Cell>
        {filteredQuestions.map(([key, value]) => (
          <Cell key={key} size={12} style={style.border}>
            <MarcadoresConsumoAlimentarListItem label={value}>
              <SimNaoNaoSabeRadioGroupField name={name[key]} showErrorField={false} />
            </MarcadoresConsumoAlimentarListItem>
          </Cell>
        ))}
      </Grid>
    </>
  )
}
