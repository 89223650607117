import { FaixaEtariaConsumoAlimentar, OpcaoTipoPerguntaEnum } from 'graphql/types.generated'
import { createValidator, ErrorObject, required, ValidatorFunction } from 'util/validation'

import {
  MCADoisAnosOuMaisPanelModel,
  mcaDoisAnosOuMaisQuestions,
  MCAMenoresDeSeisMesesPanelModel,
  mcaMenoresDeSeisMesesQuestions,
  MCASeisAVinteETresMesesPanelModel,
  mcaSeisAVinteETresMesesQuestions,
} from './model'
import { anyFilledValue } from './utils'
import { calculateFaixaEtariaMarcadoresConsumoAlimentar } from './utils'

export const mcaQuestions = {
  menoresDeSeisMeses: Object.keys(mcaMenoresDeSeisMesesQuestions),
  seisAVinteETresMeses: Object.keys(mcaSeisAVinteETresMesesQuestions),
  doisAnosOuMais: Object.keys(mcaDoisAnosOuMaisQuestions),
}

const setAllToRequired = <T>(questions: string[], error: ErrorObject<T>, values: T) =>
  questions.forEach((question) => (error[question] = required(values[question])))

const seisAVinteETresMesesQuestionsFilter: string[] = [
  'quantidadeRefeicoesFruta',
  'quantidadeRefeicoesComidaDeSal',
  'consistenciaAlimentoComidaDeSal',
]

export const mcaMenoresDeSeisMesesValidator = createValidator<MCAMenoresDeSeisMesesPanelModel>({}, (values, error) => {
  if (anyFilledValue(mcaQuestions.menoresDeSeisMeses, values)) {
    setAllToRequired(mcaQuestions.menoresDeSeisMeses, error, values)
  }
  return error
})

export const mcaSeisAVinteETresMesesValidator = createValidator<MCASeisAVinteETresMesesPanelModel>(
  {},
  (values, error) => {
    if (anyFilledValue(mcaQuestions.seisAVinteETresMeses, values)) {
      mcaQuestions.seisAVinteETresMeses.forEach(
        (question) =>
          seisAVinteETresMesesQuestionsFilter.includes(question) || (error[question] = required(values[question]))
      )
      if (values?.fruta === OpcaoTipoPerguntaEnum.SIM) {
        error.quantidadeRefeicoesFruta = required(values.quantidadeRefeicoesFruta)
      }
      if (values?.comidaDeSal === OpcaoTipoPerguntaEnum.SIM) {
        error.quantidadeRefeicoesComidaDeSal = required(values.quantidadeRefeicoesComidaDeSal)
        error.consistenciaAlimentoComidaDeSal = required(values.consistenciaAlimentoComidaDeSal)
      }
    }
    return error
  }
)

export const mcaDoisAnosOuMaisValidator = createValidator<MCADoisAnosOuMaisPanelModel>({}, (values, error) => {
  if (anyFilledValue(mcaQuestions.doisAnosOuMais, values)) {
    setAllToRequired(mcaQuestions.doisAnosOuMais, error, values)
  }
  return error
})

export const marcadoresConsumoAlimentarValidator = (
  dataNascimentoCidadao: Date,
  serverTimeNow: Date
):
  | ValidatorFunction<MCAMenoresDeSeisMesesPanelModel>
  | ValidatorFunction<MCASeisAVinteETresMesesPanelModel>
  | ValidatorFunction<MCADoisAnosOuMaisPanelModel> => {
  switch (calculateFaixaEtariaMarcadoresConsumoAlimentar(dataNascimentoCidadao, serverTimeNow)) {
    case FaixaEtariaConsumoAlimentar.MENOR_SEIS_MESES:
      return mcaMenoresDeSeisMesesValidator
    case FaixaEtariaConsumoAlimentar.MAIOR_SEIS_MESES_MENOR_DOIS_ANOS:
      return mcaSeisAVinteETresMesesValidator
    case FaixaEtariaConsumoAlimentar.MAIOR_DOIS_ANOS:
      return mcaDoisAnosOuMaisValidator
  }
}
