/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { booleanToString, tipoAleitamentoRecord } from 'types/enums'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import PuericulturaAlert from '../../objetivo/puericultura/components/PuericulturaAlert'
import { PuericulturaModel } from '../../objetivo/puericultura/model'
import { ContentCardSection } from '../components/ContentCardSection'
import { AcompanhamentoPuericulturaModel } from './model'
import { instantToDateString } from './util/convertToLabel'

interface AcompanhamentoPuericulturaCardProps {
  vacinacaoEmDiaAtendimento: boolean
  puericultura: PuericulturaModel
  dataAtendimento: Instant
  acompanhamentoPuericultura: AcompanhamentoPuericulturaModel
  loading: boolean
  cidadao: Pick<CidadaoAtendimento, 'dataNascimento' | 'sexo'>
}

export function AcompanhamentoPuericulturaCard(props: AcompanhamentoPuericulturaCardProps) {
  const {
    vacinacaoEmDiaAtendimento,
    puericultura,
    dataAtendimento,
    acompanhamentoPuericultura,
    loading,
    cidadao,
  } = props

  const { tipoAleitamentoMaterno, ultimoAtendimentoPuericultura, vacinacaoEmDia } = acompanhamentoPuericultura

  const { alteracoesFenotipicas, fatoresRisco, marcosDesenvolvimento, medicaoAnteriorPerimetroCefalico } =
    puericultura || acompanhamentoPuericultura

  const atendimentoAtualValues = {
    vacinacaoEmDia: booleanToString[vacinacaoEmDiaAtendimento?.toString()],
    tipoAleitamentoMaterno: tipoAleitamentoRecord[puericultura?.tipoAleitamento],
  }

  const acompanhamentoCardInitialValues = {
    vacinacaoEmDia: booleanToString[vacinacaoEmDia?.valorMedicao?.toString()],
    tipoAleitamentoMaterno: tipoAleitamentoRecord[tipoAleitamentoMaterno?.tipo],
    ultimoAtendimento: instantToDateString(ultimoAtendimentoPuericultura?.dataInicio),
  }

  return (
    <ContentCardSection isLoading={loading} messageLoading='Carregando dados de puericultura'>
      <Grid gapVertical={0.5}>
        <Cell size={12}>
          <HLabel title='Vacinação em dia:' placeholder='Não informado'>
            {atendimentoAtualValues?.vacinacaoEmDia || acompanhamentoCardInitialValues?.vacinacaoEmDia}
          </HLabel>
        </Cell>
        <Cell size={12}>
          <HLabel title='Aleitamento materno:' placeholder='Não informado'>
            {atendimentoAtualValues?.tipoAleitamentoMaterno || acompanhamentoCardInitialValues?.tipoAleitamentoMaterno}
          </HLabel>
        </Cell>
        <Cell size={12}>
          <HLabel title='Última consulta:' placeholder='Não informado'>
            {acompanhamentoCardInitialValues?.ultimoAtendimento}
          </HLabel>
        </Cell>
        <Cell size={12}>
          <PuericulturaAlert
            isAcompanhamentoCard
            cidadao={cidadao}
            alteracoesFenotipicas={alteracoesFenotipicas?.alteracoesFenotipicas}
            fatoresRisco={fatoresRisco?.fatoresRisco}
            marcosDesenvolvimento={marcosDesenvolvimento}
            medicaoAnteriorPerimetroCefalico={medicaoAnteriorPerimetroCefalico}
            dataReferencia={dataAtendimento}
          />
        </Cell>
      </Grid>
    </ContentCardSection>
  )
}
