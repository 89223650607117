import RichTextEditor from 'react-rte'

import { msg } from '..'

export function length(lengthValue: number) {
  return (value: string) => {
    if (value && value.length !== lengthValue) {
      return msg('length', value, lengthValue)
    }
    return null
  }
}

export function minLength(min, trim = false) {
  return (value: string) => {
    let length = trim ? value?.trim().length : value?.length

    if (value && length < min) {
      return msg('minLength', value, min)
    }
    return null
  }
}

export function maxLength(max) {
  return (value) => {
    if (value && value.length > max) {
      return msg('maxLength', value, max)
    }
    return null
  }
}

export function richTextMaxLength(max) {
  return (value) => {
    const richTextValue = RichTextEditor.createValueFromString(value, 'html')
    let texto = ''

    const textBlocks = JSON.parse(richTextValue.toString('raw')).blocks
    textBlocks.forEach((e) => (texto += e.text.toString()))

    if (texto.length > max) {
      return msg('maxLength', value, max)
    }

    return null
  }
}

export function arrayMaxLength(max) {
  return (value) => {
    if (value && value.length > max) {
      return msg('arrayMaxLength', value, max)
    }
    return null
  }
}
