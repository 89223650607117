import { cloneDeep } from 'lodash'

export const LIMITE_TAMANHO_EXPANDIDA = 6
const INDICE_PRIMEIRO_ELEMENTO_ACCORDION = LIMITE_TAMANHO_EXPANDIDA - 1

export default <T>(listaCompleta: T[]) => {
  const deepCopyListaCompleta: T[] = cloneDeep(listaCompleta)
  const totalElementos = deepCopyListaCompleta?.length
  const listaExpandida: T[] = []
  const listaAccordion: T[] = []

  totalElementos > LIMITE_TAMANHO_EXPANDIDA &&
    listaAccordion.push(...deepCopyListaCompleta.splice(INDICE_PRIMEIRO_ELEMENTO_ACCORDION, totalElementos - 1))
  listaExpandida.push(...deepCopyListaCompleta)

  return { listaExpandida, listaAccordion }
}
