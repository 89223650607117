import useSession from 'components/auth/useSession'
import { PageLoading } from 'components/loading'
import { CookiesAuthorizationProvider } from 'hooks/useCookieConfig'
import React from 'react'

import { PrivateRootView } from './PrivateRootView'
import { PublicRootView } from './PublicRootView'
import SessionKeeper from './SessionKeeper'
import { SessionMultitabListener } from './SessionMultitabListener'

export function AuthPortal() {
  const { data, loading } = useSession()

  if (!data && loading) {
    return <PageLoading message='Carregando' />
  }

  return (
    <CookiesAuthorizationProvider>
      <SessionMultitabListener />

      {data ? <PrivateRootWrapper /> : <PublicRootView />}
    </CookiesAuthorizationProvider>
  )
}

const PrivateRootWrapper = () => (
  <>
    <SessionKeeper />
    <PrivateRootView />
  </>
)
