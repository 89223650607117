import { Heading, HFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import clearTypename from 'graphql/clearTypename'
import {
  useCidadaoEditQuery,
  useConfiguracaoCadsusQuery,
  useCreateCidadaoCadsusMutation,
  useSincronizaCidadaoCadsusPollingQuery,
} from 'graphql/hooks.generated'
import { NacionalidadeEnum, SincronizacaoCadsusResultEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { useRouter } from 'hooks/useRouter'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ResultSyncCadsusType } from '../types/CadsusModel'
import CidadaoFormModel from '../types/CidadaoFormModel'
import { convertToForm } from '../types/convertModelToInput'
import { useNeedLoginGovBrToCadsus } from '../useNeedLoginGovBrToCadsus'
import { CadsusAtmosphereResponse } from './CidadaoCreateView'
import { CidadaoDadosForm } from './CidadaoDadosForm'
import { ModalBuscaInformacoesCadsus, ModalBuscaInformacoesContentState } from './ModalBuscaInformacoesCadsus'
import { ModalStatusSincronizacaoCadsus } from './ModalStatusSincronizacaoCadsus'
import { SincronizarCadsusButton } from './SincronizarCadsusButton'

interface UrlParams {
  cidadaoId: string
}

export interface InfosExcluiveisCadsus {
  telefoneResidencial: string
  telefoneCelular: string
  telefoneContato: string
  nomeSocial: string
}

let pollingSincronizacaoCadsus = false
let pollingBuscaCidadaoAtualizadocadsus = true

export function CidadaoEditView() {
  const { match } = useRouter<UrlParams>()
  const cidadaoId = match.params.cidadaoId
  const { data: configCadsus } = useConfiguracaoCadsusQuery()
  const { data: sessao } = useSession()

  const [isModalBuscaCidadaoOpenState, setModalBuscaCidadaoOpenState] = useState<boolean>(true)
  const [resultBuscaInfoCidadaoState, setResultBuscaInfoCidadaoState] = useState<ModalBuscaInformacoesContentState>({
    isLoading: true,
  })
  const [uuidBuscaCidadaoAtualizado] = useState<string>(uuidv4())

  const [saveCadsus] = useCreateCidadaoCadsusMutation()
  const [isModalSincronizacaoLoading, setIsModalSincronizacaoLoading] = useState<boolean>(false)
  const [cadsusSyncResult, setCadsusSyncResult] = useState<ResultSyncCadsusType>({ uuid: '' })
  const [isModalSincronizacaoOpen, setIsModalSincronizacaoOpen] = useState<boolean>(false)
  const [uuidSincronizacaoCadsus, setUuidSincronizacaoCadsus] = useState<string>()
  const handleRejection = useErrorHandler()
  const { needLoginGovBr, hasAllConfigs } = useNeedLoginGovBrToCadsus()

  const [hasCnsState, setHasCnsState] = useState(false)

  const [dadosExcluiveisState, setDadosExcluiveisState] = useState<InfosExcluiveisCadsus>({
    telefoneCelular: null,
    telefoneContato: null,
    telefoneResidencial: null,
    nomeSocial: null,
  })

  const [infoCidadaoState, setInfoCidadaoState] = useState<CidadaoFormModel>({
    nacionalidade: NacionalidadeEnum.BRASILEIRA,
    endereco: { pais: { id: '31', nome: 'BRASIL' } },
    cpf: '00000000000',
    contato: { telefoneCelular: '00000000000', telefoneResidencial: '00000000000', telefoneContato: '00000000000' },
  } as CidadaoFormModel)

  const atualizaResultadoBuscaCidadaoCadsus = (data: ResultSyncCadsusType) => {
    pollingBuscaCidadaoAtualizadocadsus = false
    if (
      data.result === SincronizacaoCadsusResultEnum.SUCESSO ||
      data.result === SincronizacaoCadsusResultEnum.ERRO_CADSUS_DESABILITADO ||
      data.result === SincronizacaoCadsusResultEnum.ERRO_SEM_CONEXAO ||
      data.result === SincronizacaoCadsusResultEnum.ERRO_CERTIFICADO_DESABILITADO
    ) {
      setModalBuscaCidadaoOpenState(false)
    } else {
      setResultBuscaInfoCidadaoState({
        cns: data.cidadaoCadsus?.cns,
        cpf: data.cidadaoCadsus?.cpf,
        errors: data.errors,
        statusSincronizacao: data.result,
        isLoading: false,
      })
    }
    setInfoCidadaoState(convertToForm(clearTypename(data.cidadaoLocal)))
    setDadosExcluiveisState({
      telefoneCelular: data.cidadaoLocal.telefoneCelular,
      telefoneContato: data.cidadaoLocal.telefoneContato,
      telefoneResidencial: data.cidadaoLocal.telefoneResidencial,
      nomeSocial: data.cidadaoLocal.nomeSocial,
    })
  }

  useAtmosphere<CadsusAtmosphereResponse>({
    topic: `cadsus-cidadao-atualizado/${sessao.profissional.usuario.id}`,
    onMessage: (responseBody) => {
      if (responseBody.uuid === uuidBuscaCidadaoAtualizado && pollingBuscaCidadaoAtualizadocadsus) {
        refetchSincronizacaoPolling({ uuid: uuidBuscaCidadaoAtualizado })
          .then(
            ({ data }) =>
              !!data.sincronizacaoCadsusPolling &&
              data.sincronizacaoCadsusPolling.uuid === uuidBuscaCidadaoAtualizado &&
              pollingBuscaCidadaoAtualizadocadsus &&
              atualizaResultadoBuscaCidadaoCadsus(data.sincronizacaoCadsusPolling)
          )
          .catch(handleRejection)
      }
    },
  })

  useCidadaoEditQuery({
    variables: { input: { cidadao: cidadaoId, uuid: uuidBuscaCidadaoAtualizado } },
  })

  const atualizacaoResultatoSincronizacaoCadsus = (data: ResultSyncCadsusType): void => {
    pollingSincronizacaoCadsus = false
    setCadsusSyncResult(data)
    setIsModalSincronizacaoLoading(false)
  }

  useAtmosphere<CadsusAtmosphereResponse>({
    topic: `cadsus-sincronizacao-completa/${sessao.profissional.usuario.id}`,
    onMessage: (responseBody) => {
      if (responseBody.uuid === uuidSincronizacaoCadsus && pollingSincronizacaoCadsus) {
        refetchSincronizacaoPolling({ uuid: uuidSincronizacaoCadsus })
          .then(
            ({ data }) =>
              !!data.sincronizacaoCadsusPolling &&
              data.sincronizacaoCadsusPolling.uuid === uuidSincronizacaoCadsus &&
              pollingSincronizacaoCadsus &&
              atualizacaoResultatoSincronizacaoCadsus(data.sincronizacaoCadsusPolling)
          )
          .catch(handleRejection)
      }
    },
  })

  const { refetch: refetchSincronizacaoPolling } = useSincronizaCidadaoCadsusPollingQuery({ skip: true })

  useEffect(() => {
    let shouldUpdateSincronizacao = true

    const scheduleCadsusSincronizacaoPolling = setInterval(() => {
      if (!!uuidSincronizacaoCadsus && pollingSincronizacaoCadsus && shouldUpdateSincronizacao) {
        shouldUpdateSincronizacao = false
        refetchSincronizacaoPolling({ uuid: uuidSincronizacaoCadsus })
          .then(
            ({ data }) =>
              !!data.sincronizacaoCadsusPolling &&
              data.sincronizacaoCadsusPolling.uuid === uuidSincronizacaoCadsus &&
              pollingSincronizacaoCadsus &&
              atualizacaoResultatoSincronizacaoCadsus(data.sincronizacaoCadsusPolling)
          )
          .finally(() => (shouldUpdateSincronizacao = true))
          .catch(handleRejection)
      }
    }, 1 * 1000)

    return () => clearInterval(scheduleCadsusSincronizacaoPolling)
  }, [handleRejection, refetchSincronizacaoPolling, uuidSincronizacaoCadsus])

  useEffect(() => {
    let shouldUpdateBuscaCidadaoCadsus = true

    const scheduleBuscaCidadaoCadsusPolling = setInterval(() => {
      if (!!uuidBuscaCidadaoAtualizado && pollingBuscaCidadaoAtualizadocadsus && shouldUpdateBuscaCidadaoCadsus) {
        shouldUpdateBuscaCidadaoCadsus = false
        refetchSincronizacaoPolling({ uuid: uuidBuscaCidadaoAtualizado })
          .then(
            ({ data }) =>
              !!data.sincronizacaoCadsusPolling &&
              data.sincronizacaoCadsusPolling.uuid === uuidBuscaCidadaoAtualizado &&
              pollingBuscaCidadaoAtualizadocadsus &&
              atualizaResultadoBuscaCidadaoCadsus(data.sincronizacaoCadsusPolling)
          )
          .finally(() => (shouldUpdateBuscaCidadaoCadsus = true))
          .catch(handleRejection)
      }
    }, 1 * 1000)
    return () => {
      clearInterval(scheduleBuscaCidadaoCadsusPolling)
      pollingBuscaCidadaoAtualizadocadsus = true
    }
  }, [handleRejection, refetchSincronizacaoPolling, uuidBuscaCidadaoAtualizado])

  const syncCadsus = (id: string, cns: string) => {
    setCadsusSyncResult(null)
    const hash = uuidv4()
    setUuidSincronizacaoCadsus(hash)
    pollingSincronizacaoCadsus = true
    saveCadsus({
      variables: {
        input: {
          cidadao: id,
          telefoneCelularAntigo: dadosExcluiveisState.telefoneCelular,
          telefoneContatoAntigo: dadosExcluiveisState.telefoneContato,
          telefoneResidencialAntigo: dadosExcluiveisState.telefoneResidencial,
          nomeSocialAntigo: dadosExcluiveisState.nomeSocial,
          uuid: hash,
        },
      },
    })
    setIsModalSincronizacaoLoading(true)
    setIsModalSincronizacaoOpen(true)
    setHasCnsState(!!cns)
  }

  const showSyncCadsusButton =
    configCadsus?.conexao?.cadsus.habilitado && configCadsus?.conexao?.internet.habilitado && !!infoCidadaoState?.cns

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/cidadao/${cidadaoId}`} />
      <Breadcrumb title='Edição' />
      <HFlow justifyContent='space-between' style={{ marginBottom: '1rem' }}>
        <Heading level={1}>Cadastro do cidadão </Heading>

        {showSyncCadsusButton && (
          <SincronizarCadsusButton
            cns={infoCidadaoState.cns}
            setInfoCidadaoState={setInfoCidadaoState}
            setModalSincronizacaoState={setModalBuscaCidadaoOpenState}
            cidadaoId={cidadaoId}
            needLoginGovbr={needLoginGovBr}
            hasAllConfigs={hasAllConfigs}
          />
        )}
      </HFlow>
      <CidadaoDadosForm
        initialValues={infoCidadaoState}
        sincronizarCadsus={syncCadsus}
        needLoginGovBr={needLoginGovBr}
        hasAllConfigs={hasAllConfigs}
      />
      <ModalBuscaInformacoesCadsus
        result={resultBuscaInfoCidadaoState}
        isModalOpen={isModalBuscaCidadaoOpenState}
        setModalState={setModalBuscaCidadaoOpenState}
        isLoading={resultBuscaInfoCidadaoState.isLoading}
      />
      <ModalStatusSincronizacaoCadsus
        loading={isModalSincronizacaoLoading}
        result={cadsusSyncResult}
        isOpen={isModalSincronizacaoOpen}
        isEdit={true}
        sincronizarCadsus={syncCadsus}
        setSincModalState={setIsModalSincronizacaoOpen}
        hasCns={hasCnsState}
      />
    </PageContent>
  )
}
