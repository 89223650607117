/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text } from 'bold-ui'
import theme from 'config/theme'
import { useMemo } from 'react'
import { Observacao } from 'view/atendimentos/detail/components/Observacao'
import { HistoricoPeriogramaSimplificadoModel } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/periograma/periograma-simplificado/historico-periograma-simplificado/HistoricoPeriogramaSimplificadoTable'

interface HistoricoPeriogramaSimplicadoTableProps {
  periogramaSimplificado: Omit<HistoricoPeriogramaSimplificadoModel, 'atendimentoProfissionalOdonto'>
}

export const HistoricoPeriogramaSimplicadoTable = (props: HistoricoPeriogramaSimplicadoTableProps) => {
  const { periogramaSimplificado } = props

  const classes = useMemo(() => styles(!!periogramaSimplificado.observacao), [periogramaSimplificado.observacao])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>
            <Text fontWeight='bold'>S1</Text>
          </TableHeader>
          <TableHeader>
            <Text fontWeight='bold'>S2</Text>
          </TableHeader>
          <TableHeader>
            <Text fontWeight='bold'>S3</Text>
          </TableHeader>
          <TableHeader>
            <Text fontWeight='bold'>S4</Text>
          </TableHeader>
          <TableHeader>
            <Text fontWeight='bold'>S5</Text>
          </TableHeader>
          <TableHeader>
            <Text fontWeight='bold'>S6</Text>
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody style={classes.bodyHover}>
        <TableRow>
          <TableCell style={classes.cell}>
            <Text>{periogramaSimplificado.avaliacaoSextante1}</Text>
          </TableCell>
          <TableCell style={classes.cell}>
            <Text>{periogramaSimplificado.avaliacaoSextante2}</Text>
          </TableCell>
          <TableCell style={classes.cell}>
            <Text>{periogramaSimplificado.avaliacaoSextante3}</Text>
          </TableCell>
          <TableCell style={classes.cell}>
            <Text>{periogramaSimplificado.avaliacaoSextante4}</Text>
          </TableCell>
          <TableCell style={classes.cell}>
            <Text>{periogramaSimplificado.avaliacaoSextante5}</Text>
          </TableCell>
          <TableCell style={classes.cell}>
            <Text>{periogramaSimplificado.avaliacaoSextante6}</Text>
          </TableCell>
        </TableRow>

        {periogramaSimplificado.observacao && (
          <TableRow>
            <TableCell colSpan={6} style={classes.obsCell}>
              <Observacao observacao={periogramaSimplificado.observacao} italic={false} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

const styles = (hasObs: boolean) => ({
  bodyHover: css`
    &:hover {
      background: ${theme.pallete.gray.c90};
    }
  `,
  cell: css`
    ${hasObs && `border-bottom: 0;`}
  `,
  obsCell: css`
    border-top: 0;
  `,
})
