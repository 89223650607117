import { DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { confirm } from 'components/modals/confirm'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { VisualizarDiscussaoCuidadoCompartilhadoAction } from 'view/atendimentos/detail/historico/components/accordion/header/components/VisualizarDiscussaoCuidadoCompartilhadoAction'

import { CuidadoCompartilhadoPlanoTableItemModel } from '../model-cuidadocompartilhado'

interface CuidadoCompartilhadoTableActionsProps {
  row: CuidadoCompartilhadoPlanoTableItemModel
  viaFolhaRosto: boolean
  onEdit?: (id: ID) => void
  onDelete?: (id: ID) => void
}

export const CuidadoCompartilhadoTableActions = (props: CuidadoCompartilhadoTableActionsProps) => {
  const { row, viaFolhaRosto, onEdit, onDelete } = props

  const alert = useAlert()

  const handleDelete = (id: ID) =>
    confirm({
      title: 'Deseja excluir o cuidado compartilhado?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        onDelete(id)
        alert('success', 'Cuidado compartilhado excluído com sucesso')
      },
    })()

  const onClickEdit = (e: React.MouseEvent) => {
    e.stopPropagation()
    return onEdit(row.cacheId)
  }

  const onClickDelete = (e: React.MouseEvent) => {
    e.stopPropagation()
    return handleDelete(row.cacheId)
  }

  return row.isRegistradoAgora
    ? (onEdit || onDelete) && (
        <HFlow alignItems='center' justifyContent='flex-end'>
          <Tooltip text='Mais opções'>
            <DropdownButton stopPropagationOnClick>
              {onEdit && (
                <DropdownItem type='normal' onClick={onClickEdit}>
                  Editar
                </DropdownItem>
              )}

              {onDelete && (
                <DropdownItem type='danger' onClick={onClickDelete}>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Icon icon='trashOutline' />
                    Excluir
                  </HFlow>
                </DropdownItem>
              )}
            </DropdownButton>
          </Tooltip>
        </HFlow>
      )
    : !isUndefinedOrNull(row.id) && (
        <VisualizarDiscussaoCuidadoCompartilhadoAction cuidadoCompartilhadoId={row.id} viaFolhaRosto={viaFolhaRosto} />
      )
}
