/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, Icon, Text, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import { ReactComponent as LostConnectionImage } from 'images/videochamada/videochamada-perda-conexao.svg'
import { useHistory, useRouteMatch } from 'react-router'

import { VideochamadaUrlParams } from './model-videochamada'

interface VideochamadaPerdaConexaoViewQueryParams {
  owner?: string
}

export function VideochamadaPerdaConexaoView() {
  const { owner } = useUrlQueryParams<VideochamadaPerdaConexaoViewQueryParams>()
  const isOwner = owner === 'true'

  const {
    params: { uuid: videochamadaId },
  } = useRouteMatch<VideochamadaUrlParams>()
  const history = useHistory()

  const handleVoltarClick = () => navigator.onLine && history.push(`/videochamada/${videochamadaId}`)

  return (
    <PageContent type='filled' style={styles.container}>
      <Grid alignItems='center' justifyContent='space-between'>
        <Cell size={5}>
          <VFlow vSpacing={1}>
            <Heading level={1}>Você {isOwner ? 'encerrou a' : 'saiu da'} chamada porque a conexão foi perdida</Heading>
            <Text>
              Verifique sua conexão com a internet e
              {isOwner ? ' crie uma nova chamada no módulo de Videochamadas.' : ' tente novamente.'}
            </Text>
            {!isOwner && (
              <Button kind='primary' onClick={handleVoltarClick}>
                <Icon
                  icon='arrowLeft'
                  style={css`
                    margin-right: 0.5rem;
                  `}
                />
                Voltar para sala
              </Button>
            )}
          </VFlow>
        </Cell>
        <Cell>
          <LostConnectionImage title='Tomada desconectada, representando que a chamada de vídeo foi interrompida.' />
        </Cell>
      </Grid>
    </PageContent>
  )
}

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1024px;
  `,
}
