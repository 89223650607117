export type KeyNavigationDirection = 'left' | 'right'

export enum ItemTypes {
  FILTER = 'filter',
}

export enum DraggableItemsSelectionState {
  NONE = 'NONE',
  SOME = 'SOME',
  ALL = 'ALL',
}

export enum DroppableOrigin {
  FILTRO = 'FILTRO',
  LINHA = 'LINHA',
  COLUNA = 'COLUNA',
}
