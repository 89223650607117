/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { isEmpty, VFlow } from 'bold-ui'
import { toDate } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { OdontogramaInputs } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/components/odontograma-field/components/OdontogramaInputs'
import { PeriodoOdontogramaSelectModel } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/components/odontograma-field/components/PeriodoOdontogramaSelect/PeriodoOdontogramaSelect'
import { OdontogramaViewMode } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/components/odontograma-field/model'
import {
  odontogramaFillColors,
  ViewingOdontogramaParams,
} from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/components/odontograma-field/OdontogramaField'
import { OdontogramaView } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/components/odontograma-field/OdontogramaView'
import { getShowDeciduosByAge } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/components/odontograma-field/util'

interface OdontogramaFolhaRostoViewProps {
  prontuarioId: ID
  dataReferencia: Instant
  cidadaoDataNascimento: LocalDate
}

export const OdontogramaFolhaRostoView = (props: OdontogramaFolhaRostoViewProps) => {
  const { prontuarioId, dataReferencia, cidadaoDataNascimento } = props

  const [showDeciduos, setShowDeciduos] = useState<boolean>(false)
  const [viewMode, setViewMode] = useState<OdontogramaViewMode>(OdontogramaViewMode.DENTES)
  const [viewingOdontogramaParams, setViewingOdontogramaParams] = useState<ViewingOdontogramaParams>()

  const dataAtendimento = useMemo(() => toDate(dataReferencia), [dataReferencia])

  const toggleDeciduos = () => {
    setShowDeciduos((prevValue) => !prevValue)
  }

  const onChangePeriodo = useCallback((periodo: PeriodoOdontogramaSelectModel) => {
    periodo && setViewingOdontogramaParams(periodo)
  }, [])

  useEffect(() => {
    setShowDeciduos(getShowDeciduosByAge(cidadaoDataNascimento, dataAtendimento))
  }, [cidadaoDataNascimento, dataAtendimento])

  return (
    <div
      css={css`
        display: ${!isEmpty(viewingOdontogramaParams) ? 'block' : 'none'};
      `}
    >
      <VFlow>
        <OdontogramaInputs
          prontuarioId={prontuarioId}
          viewMode={viewMode}
          showDeciduos={showDeciduos}
          toggleViewMode={setViewMode}
          toggleShowDeciduos={toggleDeciduos}
          onChangePeriodo={onChangePeriodo}
          hideViewModeOptions
          readOnly
        />

        {viewingOdontogramaParams && (
          <OdontogramaView
            odontogramaId={viewingOdontogramaParams.odontogramaId}
            atendimentoId={viewingOdontogramaParams.atendimentoId}
            prontuarioId={prontuarioId}
            showDeciduos={showDeciduos}
            fillColors={odontogramaFillColors}
            viewMode={viewMode}
            dataReferencia={dateAsYyyyMmDd(dataAtendimento)}
          />
        )}
      </VFlow>
    </div>
  )
}
