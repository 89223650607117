import moment from 'moment'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

export type TipoMedicao = keyof HistoricoMedicaoModel

const format = (data) => moment(data).format('DD/MM/YY')

export function reduceMedicoesIguais(
  medicoes: ReadonlyArray<HistoricoMedicaoModel>,
  key: TipoMedicao
): HistoricoMedicaoModel[] {
  const mapMedicoesUnicas = medicoes?.reduce(
    (acc, curr) => (curr[key] ? acc.set(`${format(curr.dataMedicao)}|${curr[key]}`, curr) : acc),
    new Map<string, HistoricoMedicaoModel>()
  )

  return Array.from(mapMedicoesUnicas.values())
}
