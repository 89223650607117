/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid } from 'bold-ui'
import { Fragment, useState } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { AvaliacoesPresentesDescription } from '../components/AvaliacoesPresentesDescription'
import { AvaliacaoDesenvolvimentoModel } from '../model'
import { FatoresRiscoModal } from './FatoresRiscoModal'

export interface FatoresRiscoModel {
  fatoresRisco: AvaliacaoDesenvolvimentoModel[]
}

interface FatoresRiscoViewProps {
  name: MetaPath<FatoresRiscoModel>
}

export function FatoresRiscoView(props: FatoresRiscoViewProps) {
  const { name } = props
  const [openModal, setOpenModal] = useState(false)

  const {
    input: { value, onChange },
  } = useField<FatoresRiscoModel>(name.absolutePath())

  return (
    <Fragment>
      <Grid alignItems='center'>
        <Cell size={3}>
          <Button
            style={css`
              width: 100%;
            `}
            size='small'
            kind='primary'
            onClick={() => setOpenModal(true)}
          >
            Avaliar fatores de risco
          </Button>
        </Cell>
        <Cell size={9}>
          <AvaliacoesPresentesDescription
            title='Fatores de risco:'
            placeholder='Nenhum fator de risco presente'
            avaliacoes={value.fatoresRisco}
          />
        </Cell>
      </Grid>
      {openModal && (
        <FatoresRiscoModal initialValues={value} closeModal={() => setOpenModal(false)} updateFatoresRisco={onChange} />
      )}
    </Fragment>
  )
}
