import { Text } from 'bold-ui'
import React, { Fragment } from 'react'
import { convertDadosClinicos } from 'view/atendimentos/detail/historico/utils/convertDadosClinicosHistorico'

import HistoricoHeaderEllipsis from '../../../HistoricoHeaderEllipsis'

interface HistoricoDadosClinicosProps {
  isTitleCase?: boolean
  dadosClinicos: string
}

export const HistoricoDadosClinicos = (props: HistoricoDadosClinicosProps) => {
  const { isTitleCase = false, dadosClinicos } = props

  const dadosClinicosConverted = convertDadosClinicos(dadosClinicos)

  return (
    <HistoricoHeaderEllipsis
      tooltipText={dadosClinicosConverted
        .map((dado) => `${dado.descricao.titleCase()} - ${dado.codigo} ${dado.terminologia ?? ''}`)
        .join(' | ')}
    >
      {dadosClinicosConverted.map((dado, index) => (
        <Fragment key={index}>
          <Text fontWeight='bold'>{isTitleCase ? dado.descricao.titleCase() : dado.descricao}</Text>
          <Text fontWeight={!!dado.terminologia ? 'bold' : 'normal'}> - {dado.codigo}</Text>
          {!!dado.terminologia && <Text> {dado.terminologia}</Text>}
          {index < dadosClinicosConverted.length - 1 && <Text> | </Text>}
        </Fragment>
      ))}
    </HistoricoHeaderEllipsis>
  )
}
