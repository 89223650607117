import { Cell, focusBoxShadow, FormControl, Grid, Theme, useStyles, VFlow } from 'bold-ui'
import { useFormControl, UseFormControlProps, useRovingTabIndex } from 'bold-ui/lib/hooks'
import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import RichTextEditor from 'react-rte'

import { ErrorField } from '../ErrorField'
import { UseFieldProps, usePecField } from '../hooks/useField'
import { getFieldError } from '../util'

export interface RichTextProps extends UseFieldProps<string>, UseFormControlProps {
  maxLength: number
  height?: number
  placeholder?: string
  alert?: React.ReactNode
  removeNonBreakingSpaces?: boolean
  autofocus?: boolean
}

export function RichTextField(props: RichTextProps) {
  const { maxLength, height, alert, removeNonBreakingSpaces, autofocus = true, ...rest } = props

  const { input, meta } = usePecField(
    removeNonBreakingSpaces ? { ...rest, parse: (value: string): string => value.removeNonBreakingSpace() } : rest
  )
  const error = getFieldError(meta)
  const { getFormControlProps } = useFormControl(props)
  const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue())

  useEffect(() => {
    !input.value && setEditorValue(RichTextEditor.createEmptyValue())
    input.value && setEditorValue(RichTextEditor.createValueFromString(input.value, 'html'))
  }, [input.value])

  const qntdCharacters = useMemo(() => {
    let texto = ''
    let textBlocks = JSON.parse(editorValue.toString('raw')).blocks
    textBlocks.forEach((e) => (texto += e.text.toString()))
    return texto.length
  }, [editorValue])

  const { classes } = useStyles(createStyles, { height, error, meta, maxLength, qntdCharacters })

  const handleBlur = (e) => {
    input.onChange(editorValue.toString('html'))
    input.onBlur(e)
  }

  const rootRef = useRovingTabIndex({
    getItems: (root) => Array.from(root.querySelectorAll('button')),
  })

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Negrito', style: 'BOLD' },
      { label: 'Itálico', style: 'ITALIC' },
      { label: 'Sublinhado', style: 'UNDERLINE' },
      { label: 'Tachado', style: 'STRIKETHROUGH' },
    ],
    BLOCK_TYPE_BUTTONS: [{ label: 'Citação', style: 'blockquote' }],
    HISTORY_BUTTONS: { undo: { label: 'Desfazer' }, redo: { label: 'Refazer' } },
  }

  return (
    <FormControl {...getFormControlProps()}>
      <VFlow vSpacing={0.5}>
        {alert}
        <RichTextEditor
          {...rest}
          value={editorValue}
          onChange={setEditorValue}
          onBlur={handleBlur}
          onFocus={input.onFocus}
          toolbarConfig={toolbarConfig}
          className={classes.root}
          toolbarClassName={classes.toolbar}
          editorClassName={classes.editor}
          ref={richTextToolbarRef(rootRef)}
          autofocus={autofocus}
        />
        <Grid justifyContent='space-between'>
          <Cell>
            <ErrorField name={props.name} />
          </Cell>
          <Cell>
            <div className={classes.footer}>
              {qntdCharacters}/{maxLength} caracteres
            </div>
          </Cell>
        </Grid>
      </VFlow>
    </FormControl>
  )
}

const createStyles = (theme: Theme, { height, error, meta, maxLength, qntdCharacters }) => ({
  root: {
    backgroundColor: `${theme.pallete.surface.main} !important`,
    color: theme.pallete.text.main,
    border: `0.063rem solid ${
      error ? (meta.active ? theme.pallete.gray.c80 : theme.pallete.status.danger.main) : theme.pallete.gray.c60
    } !important`,
    borderRadius: `${theme.radius.input}px !important`,
    boxShadow: meta.active && focusBoxShadow(theme, error ? 'danger' : 'primary'),
    transitionProperty: 'box-shadow',
    transitionDuration: '.2s',
  },
  toolbar: {
    display: 'flex',
    margin: '0.313rem 0 0.313rem',
    padding: '0.25rem 0 0.125rem !important',
    '&>div': {
      margin: 0,
    },
    '&>div:last-child': {
      width: '100%',
      textAlign: 'end',
    },
    '.ButtonWrap__root___1EO_R': {
      zIndex: 8,
    },
    button: {
      background: 'none',
      border: 0,
      borderRadius: `${theme.radius.button}px !important`,
      margin: '0 0.625rem 0 0 !important',
      paddingTop: '4px',
      '&.IconButton__isActive___2Ey8p': {
        backgroundColor: theme.pallete.gray.c80,
        boxShadow: theme.shadows.inner[10],
      },
      '&:not(:disabled):hover': {
        backgroundColor: theme.pallete.gray.c90,
      },
      '&:focus': {
        boxShadow: `inset 0 0 0 2px ${theme.pallete.primary.main}`,
        outline: 0,
      },
      '&:active': {
        boxShadow: `inset 0 0 0 2px ${theme.pallete.primary.main}`,
      },
    },
  } as CSSProperties,
  editor: {
    minHeight: `${height | 4}rem`,
    margin: '0 0.563rem 0.125rem 0.188rem',
    wordBreak: 'break-all',
    fontFamily: `${theme.typography.fontFamily}`,
    fontSize: `${theme.typography.sizes.text} !important`,
    lineHeight: '1rem',
    '::placeholder': { color: `${theme.pallete.primary.main}` },
    '&>div>div>div': {
      padding: '0.25rem 0.5rem 0.5rem 0.5rem !important',
    },
    '.public-DraftEditor-content': {
      resize: 'vertical',
      minHeight: `${height | 4}rem`,
    },
  } as CSSProperties,
  footer: {
    color: `${qntdCharacters > maxLength ? theme.pallete.status.danger.main : theme.pallete.gray.c40}`,
  } as CSSProperties,
})

function richTextToolbarRef(rootRef: React.MutableRefObject<any>) {
  return (elem) => {
    if (elem) rootRef.current = elem.editor.editorContainer.parentNode.parentNode.previousSibling
    return true
  }
}
