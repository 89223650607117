import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoSanguineoEnum } from 'graphql/types.generated'
import React from 'react'

export interface TipoSanguineoSelectModel {
  identificador: TipoSanguineoEnum
  nome?: string
}

export interface TipoSanguineoSelectFieldProps
  extends Omit<SelectFieldProps<TipoSanguineoSelectModel>, 'items' | 'itemToString'> {}

export function TipoSanguineoSelectField(props: TipoSanguineoSelectFieldProps) {
  const itemToString = (item: TipoSanguineoSelectModel) => item && item.nome

  return <SelectField<TipoSanguineoSelectModel> items={items} itemToString={itemToString} {...props} />
}

const items: TipoSanguineoSelectModel[] = [
  { identificador: TipoSanguineoEnum.A_POSITIVO, nome: 'A+' },
  { identificador: TipoSanguineoEnum.A_NEGATIVO, nome: 'A-' },
  { identificador: TipoSanguineoEnum.B_POSITIVO, nome: 'B+' },
  { identificador: TipoSanguineoEnum.B_NEGATIVO, nome: 'B-' },
  { identificador: TipoSanguineoEnum.AB_POSITIVO, nome: 'AB+' },
  { identificador: TipoSanguineoEnum.AB_NEGATIVO, nome: 'AB-' },
  { identificador: TipoSanguineoEnum.O_POSITIVO, nome: 'O+' },
  { identificador: TipoSanguineoEnum.O_NEGATIVO, nome: 'O-' },
]
