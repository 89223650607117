import {
  Alert,
  Button,
  Cell,
  FormControl,
  Grid,
  Heading,
  HFlow,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  Tooltip,
  VFlow,
} from 'bold-ui'
import { useErrorHandler } from 'components/error'
import {
  BairroSelectField,
  CepField,
  Form,
  FormRenderProps,
  TextField,
  TipoLogradouroSelectField,
} from 'components/form'
import { css } from 'emotion'
import { FormApi } from 'final-form'
import { useEnderecoQuery, useImoveisPermissaoAtualizarQuery } from 'graphql/hooks.generated'
import { isEqual } from 'lodash'
import React, { useState } from 'react'
import { metaPath } from 'util/metaPath'
import { LogradouroEditFormModel, TerritorioModel } from 'view/visualizacaoTerritorial/model'
import { validateLogradouroEditForm } from 'view/visualizacaoTerritorial/validator'

interface LogradouroEditModalProps {
  isModalOpen: boolean
  municipioId: ID
  onClose(): void
  onSubmit(input: LogradouroEditFormModel): void
  initialValues: LogradouroEditFormModel
  territorio: TerritorioModel
  currentLogradouro: LogradouroEditFormModel
}

const meta = metaPath<LogradouroEditFormModel>()

export function LogradouroEditModal(props: LogradouroEditModalProps) {
  const { isModalOpen, municipioId, onClose, onSubmit, initialValues, territorio, currentLogradouro } = props

  const handleRejection = useErrorHandler()
  const { refetch: fetchEnderecoByCep } = useEnderecoQuery({ skip: true })
  const [cepNaoEncontrado, setCepNaoEncontrado] = useState<boolean>(false)

  const {
    data: { imoveisPermissaoAtualizar: { totalImoveis, totalImoveisPermitidos } = {} },
  } = useImoveisPermissaoAtualizarQuery({
    variables: {
      input: {
        ...territorio,
        bairro: currentLogradouro.bairro.nome,
        tipoLogradouroId: currentLogradouro.tipoLogradouro.id,
        logradouro: currentLogradouro.logradouro,
        cep: currentLogradouro.cep,
      },
    },
  })

  const handlePesquisarCep = (cep: string, form: FormApi) => {
    setCepNaoEncontrado(false)
    return fetchEnderecoByCep({ cep })
      .then((response) => {
        if (!!response.data.endereco) {
          const { tipoLogradouro, logradouro, bairro } = response.data?.endereco

          form.batch(() => {
            tipoLogradouro && form.change(meta.tipoLogradouro.absolutePath(), response.data.endereco?.tipoLogradouro)
            logradouro && form.change(meta.logradouro.absolutePath(), response.data.endereco?.logradouro.toUpperCase())
            bairro && form.change(meta.bairro.absolutePath(), response.data.endereco?.bairro)
          })
        } else {
          setCepNaoEncontrado(true)
        }
      })
      .catch(handleRejection)
  }

  const renderForm = (formProps: FormRenderProps<LogradouroEditFormModel>) => {
    const { values } = formProps
    const valuesEqualsToInitial = isEqual(values, initialValues)
    const isPermitidoAtualizar = totalImoveisPermitidos !== 0
    const numeroImoveisSemPermissao = totalImoveis - totalImoveisPermitidos

    return (
      <Modal size='small' open={isModalOpen} closeOnBackdropClick={false} onClose={onClose}>
        <ModalBody>
          <Grid>
            <Cell size={12}>
              <VFlow vSpacing={0}>
                <Heading level={1}> Editar dados do logradouro </Heading>
                <Text>
                  Esta ação criará fichas de atualização para os imóveis. Se houver filtros aplicados, somente os
                  imóveis filtrados serão editados.
                </Text>
                <VFlow
                  style={css`
                    margin-top: 0.5rem;
                  `}
                >
                  {!isPermitidoAtualizar ? (
                    <Alert type='danger'>
                      Edição bloqueada pois todos os imóveis desse logradouro possuem fichas antigas, com recusa ou
                      pendentes de processamento. Atualize-os manualmente.
                    </Alert>
                  ) : numeroImoveisSemPermissao > 0 ? (
                    <Alert type='warning'>{pluralizeEdicaoLogradouroMessage(numeroImoveisSemPermissao)}</Alert>
                  ) : null}
                </VFlow>
              </VFlow>
            </Cell>
            <Cell size={7}>
              <HFlow hSpacing={1}>
                <CepField label='CEP' name='cep' required />
                <FormControl label={<span>&nbsp;</span>}>
                  <Button
                    onClick={() => handlePesquisarCep(values.cep, formProps.form)}
                    size='small'
                    type='button'
                    kind='primary'
                    disabled={values?.cep?.length !== 8}
                  >
                    Pesquisar
                  </Button>
                </FormControl>
              </HFlow>
            </Cell>
            <Cell size={9}>
              <TipoLogradouroSelectField label='Tipo de logradouro' name={meta.tipoLogradouro} required />
            </Cell>
            <Cell size={12}>
              <TextField label='Logradouro' name={meta.logradouro} maxLength={72} required uppercase />
            </Cell>
            <Cell size={12}>
              <BairroSelectField name={meta.bairro} label='Bairro' municipioId={municipioId} required />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button kind='normal' onClick={onClose}>
              Fechar
            </Button>
            <Tooltip text={atualizarEnderecoToolTipMessage(valuesEqualsToInitial, isPermitidoAtualizar)}>
              <Button
                kind='primary'
                onClick={formProps.handleSubmit}
                disabled={valuesEqualsToInitial || !isPermitidoAtualizar}
              >
                Salvar
              </Button>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<LogradouroEditFormModel>
      onSubmit={onSubmit}
      validate={validateLogradouroEditForm(cepNaoEncontrado)}
      render={renderForm}
      initialValues={initialValues}
    />
  )
}

const pluralizeEdicaoLogradouroMessage = (numeroImoveisSemPermissao) => {
  return numeroImoveisSemPermissao > 1
    ? `Existem ${numeroImoveisSemPermissao} imóveis que não serão atualizados pois possuem fichas antigas, com recusa ou pendentes de processamento. Atualize-os manualmente.`
    : `Existe 1 imóvel que não será atualizado pois possui ficha antiga, com recusa ou pendente de processamento. Atualize-o manualmente.`
}

const atualizarEnderecoToolTipMessage = (isInitialValues: boolean, isPermitidoAtualizar: boolean) => {
  if (!isPermitidoAtualizar)
    return 'Edição bloqueada. Todos os imóveis desse logradouro possuem fichas antigas, com recusa ou pendentes de processamento. Atualize-os manualmente.'
  return isInitialValues ? 'Edição bloqueada. Edite ao menos um campo para desbloquear.' : ''
}
