import { SelectField } from 'components/form'
import { EstrategiaVacinacaoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  EstrategiaVacinacaoSelectFieldQuery,
  EstrategiaVacinacaoSelectFieldQueryVariables,
  SexoEnum,
} from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type EstrategiaVacinacaoSelectFieldModel = EstrategiaVacinacaoSelectFieldQuery['estrategiasVacinacao'][0]

export interface EstrategiaVacinacaoSelectFieldProps
  extends AsyncSelectFieldProps<EstrategiaVacinacaoSelectFieldModel> {
  imunobiologicoId?: ID
  doseImunobiologicoId?: ID
  outrosImunobiologicos?: boolean
  sexo?: SexoEnum
}

export function EstrategiaVacinacaoSelectField(props: EstrategiaVacinacaoSelectFieldProps) {
  const { imunobiologicoId, outrosImunobiologicos, doseImunobiologicoId, sexo, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    EstrategiaVacinacaoSelectFieldModel,
    EstrategiaVacinacaoSelectFieldQuery,
    EstrategiaVacinacaoSelectFieldQueryVariables
  >({
    query: EstrategiaVacinacaoSelectFieldDocument,
    extractItems: (data) => data && data.estrategiasVacinacao,
    variables: (inputQuery: string) => ({
      input: {
        imunobiologicoId: imunobiologicoId,
        doseId: doseImunobiologicoId,
        outrosImunobiologicos: outrosImunobiologicos,
        query: inputQuery,
        sexo: sexo,
      },
    }),
    fetchPolicy: 'network-only',
  })

  const itemToString = (item: EstrategiaVacinacaoSelectFieldModel) => item && item.codigo + ' - ' + item.nome

  return <SelectField<EstrategiaVacinacaoSelectFieldModel> itemToString={itemToString} {...selectProps} {...rest} />
}
