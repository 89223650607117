import { DocumentNode } from 'graphql'
import { operationTopLevelFieldNames, operationVariableNames } from 'graphql/util-graphql'
import { isEqual } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'

/**
 * Extracts the content of an unpaged query result.
 * @param queryDocument A document defining only one query operation.
 * @param data The raw query result.
 * @returns The query content.
 */
export const extractItemsUnpaged = (queryDocument?: DocumentNode, data?: Object): Object => {
  if (isUndefinedOrNull(queryDocument) || isUndefinedOrNull(data)) return null

  const operationName = operationTopLevelFieldNames(queryDocument).first()
  return data[operationName]
}

/**
 * Checks whether query variables have the given names.
 * @param queryDocument A document defining only one query operation.
 * @param variableNames The expected query variable names.
 * @returns True if all the query variables have the given names. False otherwise.
 */
export const hasVariables = (queryDocument?: DocumentNode, ...variableNames: string[]): boolean => {
  if (isUndefinedOrNull(queryDocument) || isUndefinedOrNull(variableNames)) return false

  return isEqual(operationVariableNames(queryDocument).first(), variableNames)
}
