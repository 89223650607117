/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Icon, Text, Theme, useTheme } from 'bold-ui'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { Box } from 'components/Box'
import { Z_INDEX_MAIN_HEADER } from 'components/layout/MainHeader'
import { PopperButton } from 'components/popper'
import { Interpolation } from 'emotion'

import LoteImunobiologicoTooltipAddForm from './LoteImunologicoTooltipAddForm'

export interface LoteImunobiologicoFormPopperProps {
  styles?: Interpolation
  reload(): Promise<any>
}

export default function LoteImunobiologicoFormPopper(props: LoteImunobiologicoFormPopperProps) {
  const { reload } = props
  const theme = useTheme()
  const classes = createStyles(theme)

  const renderTarget = ({ close, open }) => {
    return (
      <div css={classes.popperForm}>
        <Box>
          <LoteImunobiologicoTooltipAddForm onCancel={open} onClose={close} onSuccess={composeHandlers(reload, open)} />
        </Box>
      </div>
    )
  }
  return (
    <PopperButton
      renderPopper={renderTarget}
      placement='bottom-end'
      size='small'
      kind='primary'
      zIndex={Z_INDEX_MAIN_HEADER + 1}
    >
      <Icon icon='plus' style={classes.icon} />
      <Text color='inherit'>Cadastrar lote</Text>
    </PopperButton>
  )
}

const createStyles = (theme: Theme) => ({
  popperForm: css`
    height: 13rem;
    width: 31rem;
    background: pink;
    transform: translateX(1.1rem);
    margin-top: 0.5rem;
    text-align: left;
    box-shadow: ${theme.shadows.outer['40']};
  `,
  icon: css`
    margin-right: 0.25rem;
  `,
})
