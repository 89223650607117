import { msg } from '..'
import * as Util from '../Util'

export function range(min: number, max: number) {
  return (value: any) => {
    const parsedValue = parseNumber(value)

    if (!Util.isEmpty(parsedValue) && (parsedValue < min || parsedValue > max)) {
      return msg('range', parsedValue, min, max)
    }
  }
}

export function minRange(min: number) {
  return (value: number) => {
    const parsedValue = parseNumber(value)
    if (!Util.isEmpty(parsedValue) && parsedValue < min) {
      return msg('minRange', parsedValue, min)
    }
  }
}

export function maxRange(max: number) {
  return (value: number) => {
    const parsedValue = parseNumber(value)
    if (!Util.isEmpty(parsedValue) && parsedValue > max) {
      return msg('maxRange', parsedValue, max)
    }
  }
}

export function parseNumber(value: any): number {
  if (typeof value === 'string') {
    return parseFloat(value?.replace(',', '.'))
  }
  return value
}
