import { RegistroVacinacaoInput } from 'graphql/types.generated'

import { RegistroVacinacaoFormModel } from '../model'

export const convertRegistroVacinacaoToInput = (
  listaRegistros: RegistroVacinacaoFormModel[]
): RegistroVacinacaoInput[] =>
  listaRegistros?.map((registro) => {
    return {
      tipoRegistroVacinacao: registro.tipoRegistroVacinacao,
      isRegistroAnterior: registro.isRegistroAnterior ?? false,
      imunobiologicoId: registro.imunobiologicoId || registro.outrosImunosImunobiologico?.id,
      estrategiaVacinacao: registro.estrategiaVacinacao?.estrategiaVacinacaoDbEnum,
      doseImunobiologicoId: registro.doseId || registro.outrosImunosDose?.id,
      dataAprazamento: registro.dataAprazamento,
      dataAplicacao: registro.dataAplicacao,
      loteImunobiologicoId: registro.loteImunobiologico?.id,
      viaAdministracaoId: registro.viaAdministracao?.id,
      localAplicacaoVacinacaoId: registro.localAplicacaoVacinacao?.id,
      observacoes: registro.observacoes,
      grupoAtendimentoId: registro.grupoAtendimento?.id,
      loteImunobiologicoCadastro:
        !registro.loteImunobiologico?.id || (registro.isRegistroAnterior && registro.tipoRegistroVacinacao == null)
          ? {
              loteNome: registro.loteNome,
              fabricanteNome: registro.loteFabricante?.nome,
              fabricanteId: registro.loteFabricante?.id,
              dataValidade: registro.loteDataValidade,
            }
          : null,
      isCadastrarNovoLote: registro.isCadastrarNovoLote ?? false,
      isAplicadoExterior: registro.isAplicadoExterior,
      isPesquisaEstrategia: registro.isPesquisaEstrategia ?? false,
    }
  })
