import { HFlow, Icon, Text, Tooltip, useTheme } from 'bold-ui'
import React from 'react'

export const TransmissaoProcessamentoFichasCmp = ({ existemFichasParaProcessar }) => {
  const theme = useTheme()

  const desejaProcessarComp = (
    <Text>
      Se desejar processar as fichas agora, solicite ao Instalador.
      <Tooltip
        text='Módulos atualizados após o processamento: Relatórios, CDS e Histórico do cidadão.'
        style={{ display: 'flex', alignContent: 'center' }}
      >
        <Icon
          icon='infoCircleFilled'
          size={1}
          style={{
            '&:hover': {
              color: theme.pallete.primary.main,
            },
          }}
        />
      </Tooltip>
    </Text>
  )

  return (
    <>
      <HFlow alignItems='center' hSpacing={0.5}>
        <Icon
          icon={existemFichasParaProcessar ? 'clockOutline' : 'checkCircleOutline'}
          fill={existemFichasParaProcessar ? 'info' : 'success'}
          size={1}
        />
        <Text color={existemFichasParaProcessar ? 'info' : 'success'}>
          {existemFichasParaProcessar
            ? 'Existem fichas a ser processadas.'
            : 'Todas as fichas recebidas já foram processadas.'}
        </Text>
      </HFlow>
      {existemFichasParaProcessar ? desejaProcessarComp : null}
    </>
  )
}
