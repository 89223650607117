import { SelectField } from 'components/form'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { CertificadoSelectDocument } from 'graphql/hooks.generated'
import { CertificadoSelectQuery, CertificadoSelectQueryVariables } from 'graphql/types.generated'
import React from 'react'

export type CertificadoSelectModel = CertificadoSelectQuery['certificados']['content'][0]

export interface CertificadoSelectFieldProps extends AsyncSelectFieldProps<CertificadoSelectModel> {}

export function CertificadoSelectField(props: CertificadoSelectFieldProps) {
  const { selectProps } = useAsyncQuerySelect<
    CertificadoSelectModel,
    CertificadoSelectQuery,
    CertificadoSelectQueryVariables
  >({
    query: CertificadoSelectDocument,
    extractItems: (data) => data && data.certificados && data.certificados.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
      },
    }),
  })

  const itemToString = (option: CertificadoSelectModel) => option && option.nome

  return <SelectField<CertificadoSelectModel> itemToString={itemToString} {...selectProps} {...props} />
}
