import React, { useCallback } from 'react'

import { TextField, TextFieldProps } from '../TextField'

export interface FractionalFieldProps extends TextFieldProps {
  precisaoDecimal?: number
  precisaoFracionaria?: number
}

// regex verifica o que não é número, vírgula ou barra
const pattern = new RegExp(/[^\d,/]+/g)

const parse = (value: string, precisaoDecimal: number = 2, precisaoFracionaria: number = 1) => {
  if (value.startsWith(',') || value.startsWith('/')) value = value.slice(1)

  // remove o que não é número, vírgula ou barra
  value = value.replace(pattern, '')

  // encontra posição da primeira vírgula ou barra, -1 é ausente
  const primeiraVirgulaPos = value.indexOf(',')
  const primeiraBarraPos = value.indexOf('/')
  const primeiroSeparadorPos =
    primeiraVirgulaPos >= 0 && primeiraBarraPos >= 0
      ? Math.min(primeiraVirgulaPos, primeiraBarraPos)
      : Math.max(primeiraVirgulaPos, primeiraBarraPos)

  if (primeiroSeparadorPos >= 0) {
    if (primeiraVirgulaPos === primeiroSeparadorPos) {
      value = formataPrecisao(value, primeiraVirgulaPos, precisaoDecimal)
    } else {
      value = formataPrecisao(value, primeiraBarraPos, precisaoFracionaria)
    }
  }

  return value
}

function formataPrecisao(value: string, primeiroSeparadorPos: number, precisao: number) {
  // remove todas vírgulas e barras depois do primeiro caractere separador
  value =
    value.slice(0, primeiroSeparadorPos + 1) + value.slice(primeiroSeparadorPos, value.length)?.replace(/[,/]/g, '')
  // trunca valor de acordo com a precisão
  return value.substring(0, primeiroSeparadorPos + precisao + 1)
}

export function FractionalField(props: FractionalFieldProps) {
  const { precisaoDecimal, precisaoFracionaria, ...rest } = props

  const internalParse = useCallback((value: string) => parse(value, precisaoDecimal, precisaoFracionaria), [
    precisaoDecimal,
    precisaoFracionaria,
  ])

  return <TextField parse={internalParse} {...rest} />
}
