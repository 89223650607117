import { getStepProps, Paper, Step, Stepper, VFlow } from 'bold-ui'
import { PageContent } from 'components/layout/PageContent'
import * as React from 'react'

import { InstalacaoHeader } from './InstalacaoHeader'

export enum InstalacaoStep {
  IDENTIFICAR,
  CADASTRAR,
  FINALIZAR,
}

export interface InstalacaoLayoutProps {
  step: InstalacaoStep
  children: React.ReactNode
}

export const InstalacaoLayout = (props: InstalacaoLayoutProps) => {
  const { step, children } = props

  return (
    <PageContent>
      <VFlow vSpacing={2}>
        <InstalacaoHeader>
          <Stepper style={{ width: '480px', margin: '0 auto' }}>
            <Step {...getStepProps(0, step)}>Identificar instalação</Step>
            <Step {...getStepProps(1, step)}>Cadastrar instalador</Step>
            <Step {...getStepProps(2, step)}>Finalizar instalação</Step>
          </Stepper>
        </InstalacaoHeader>

        <Paper style={{ backgroundColor: 'white', padding: '2rem 2rem 0 2rem' }}>{children}</Paper>
      </VFlow>
    </PageContent>
  )
}
