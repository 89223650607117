import { useConfiguracaoVideochamadasQuery } from 'graphql/hooks.generated'

export interface VideochamadasConfiguration {
  videochamadasEnabled: boolean
  loading: boolean
}

//TODO (@RNG): Generalizar para hook que dá acesso a mais configurações do sistema (#14543)
export function useVideochamadasConfiguration(): VideochamadasConfiguration {
  const {
    loading: loadingConfiguracoes,
    data: {
      conexao: { videochamadas: { habilitado: isVideochamadaEnabled } } = { videochamadas: { habilitado: false } },
    },
  } = useConfiguracaoVideochamadasQuery({
    fetchPolicy: 'cache-first',
  })

  return {
    videochamadasEnabled: !loadingConfiguracoes && isVideochamadaEnabled,
    loading: loadingConfiguracoes,
  }
}
