import { Cell, Grid, Link, Text, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import React from 'react'

export const LinksUteisView = () => {
  return (
    <PageContent type='filled'>
      <Breadcrumb title='Links úteis' />
      <VFlow vSpacing={2}>
        <PageHeader title='Links úteis' />
        <Grid>
          <Cell size={9}>
            <VFlow vSpacing={1}>
              <div>
                <Text component='p' fontWeight='bold'>
                  Manual do PEC
                </Text>
                <Link target='_blank' href='https://saps-ms.github.io/Manual-eSUS_APS'>
                  https://saps-ms.github.io/Manual-eSUS_APS
                </Link>
              </div>
              <div>
                <Text component='p' fontWeight='bold'>
                  Suporte do PEC
                </Text>
                <Link target='_blank' href='https://esusaps.freshdesk.com'>
                  https://esusaps.freshdesk.com
                </Link>
              </div>
              <div>
                <Text component='p' fontWeight='bold'>
                  Portal Saúde Baseada em Evidências
                </Text>
                <Link target='_blank' href='https://psbe.ufrn.br/'>
                  https://psbe.ufrn.br
                </Link>
              </div>
              <div>
                <Text component='p' fontWeight='bold'>
                  Ministério da Saúde
                </Text>
                <Link target='_blank' href='https://www.gov.br/saude/pt-br'>
                  https://www.gov.br/saude/
                </Link>
              </div>
              <div>
                <Text component='p' fontWeight='bold'>
                  Secretaria de Atenção Primária à Saúde (SAPS)
                </Text>
                <Link target='_blank' href='http://aps.saude.gov.br'>
                  http://aps.saude.gov.br
                </Link>
              </div>
              <div>
                <Text component='p' fontWeight='bold'>
                  Estratégia e-SUS Atenção Primária
                </Text>
                <Link target='_blank' href='https://sisaps.saude.gov.br/esus/'>
                  https://sisaps.saude.gov.br/esus/
                </Link>
              </div>
              <div>
                <Text component='p' fontWeight='bold'>
                  Sugestões para o PEC e-SUS APS
                </Text>
                <Link target='_blank' href='http://sisaps.saude.gov.br/pesquisaesusab/'>
                  http://sisaps.saude.gov.br/pesquisaesusab
                </Link>
              </div>
            </VFlow>
          </Cell>
        </Grid>
      </VFlow>
    </PageContent>
  )
}
