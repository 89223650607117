import { SerializedStyles } from '@emotion/core'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

interface DireitosDoTitularDeDadosPessoaisSection {
  listStyle: SerializedStyles
}

export const DireitosDoTitularDeDadosPessoaisSection = (props: DireitosDoTitularDeDadosPessoaisSection) => {
  const { listStyle } = props

  return (
    <TermosDeUsoHeadingSection
      title='6. Direitos do Titular de Dados Pessoais'
      id='direitos_do_titular_de_dados_pessoais'
    >
      <p>
        <b>Quais são os direitos do titular de dados pessoais?</b>
      </p>
      <br />
      <p>
        O titular de dados pessoais possui os seguintes direitos, conferidos pela Lei Geral de Proteção de Dados
        Pessoais (LGPD):
      </p>
      <br />
      <ul css={listStyle}>
        <li>
          Direito de confirmação e acesso (Art. 18, incisos I e II): é o direito do titular de dados de obter do serviço
          a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o
          caso, o direito de acessar os seus dados pessoais.
        </li>
        <li>
          Direito de retificação (Art. 18, inciso III): é o direito de solicitar a correção de dados incompletos,
          inexatos ou desatualizados.
        </li>
        <li>
          Direito à limitação do tratamento dos dados (Art. 18, inciso IV): é o direito do titular de dados de limitar o
          tratamento de seus dados pessoais, podendo exigir a eliminação de dados desnecessários, excessivos ou tratados
          em desconformidade com o disposto na Lei Geral de Proteção de Dados Pessoais.
        </li>
        <li>
          Direito de oposição (Art. 18, § 2º): é o direito do titular de dados de, a qualquer momento, opor-se ao
          tratamento de dados por motivos relacionados com a sua situação particular, com fundamento em uma das
          hipóteses de dispensa de consentimento ou em caso de descumprimento ao disposto na Lei Geral de Proteção de
          Dados Pessoais.
        </li>
        <li>
          Direito de portabilidade dos dados (Art. 18, inciso V): é o direito do titular de dados de realizar a
          portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com
          a regulamentação da autoridade nacional, observados os segredos comercial e industrial.
        </li>
        <li>
          Direito de não ser submetido a decisões automatizadas (Art. 20): o titular dos dados tem direito a solicitar a
          revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais que afetem seus
          interesses, incluídas as decisões destinadas a definir o seu perfil pessoal, profissional, de consumo e de
          crédito ou os aspectos de sua personalidade.{' '}
        </li>
      </ul>
    </TermosDeUsoHeadingSection>
  )
}
