import { SexoEnum } from 'graphql/types.generated'

import alturaMaior5anos from './parametros/altura5a19anos.json'
import alturaAte5anos from './parametros/alturaAte5anos.json'
import imcMaior5anos from './parametros/imc5a19anos.json'
import imcAte5anos from './parametros/imcAte5anos.json'
import perimetroCefalico from './parametros/perimetroCefalico.json'
import pesoMaior5anos from './parametros/peso5a10anos.json'
import pesoAte5anos from './parametros/pesoAte5anos.json'

/**
 * Fração máxima de anos antes de completar 61 meses (mínimo das referências de mais de 5 anos).
 * Isto é necessário pois valores entre 5 e 5.08 são maiores ou iguais a 5, mas são menores que 61
 * meses (quando multiplicados por 12).
 */
const THRESHOLD_5_ANOS = 5.08

export type TipoMedicao = 'imc' | 'peso' | 'perimetroCefalico' | 'altura'

export enum Percentil {
  /* sd é o equivalente em inglês para: desvio padrão */
  sd3pos = 3,
  sd2pos = 2,
  sd1pos = 1,
  sd2neg = -2,
  sd3neg = -3,
}

interface Parametros {
  sex: 1 | /*Masculino*/ 2 /*Feminino*/
  age: number
  l: number
  m: number
  s: number
}

export const createParameters = (
  sexo: SexoEnum.FEMININO | SexoEnum.MASCULINO,
  idadeEmAnos: number,
  medicao: TipoMedicao,
  roundFunction = Math.floor
) => {
  //Para evitar que imprecisões de ponto flutuante afetem quais parâmetros serão usados
  const idadeRounded = round(idadeEmAnos, 2)
  const json = getJson(idadeRounded, medicao) as Parametros[]
  /**
   * A idade para crianças menores de 5 anos deve estar em dias (1 mês = 30,4375 dias, assim, 12 meses = 365,25).
   * Para maiores de 5 anos e menores de 19 anos deve estar em meses.
   */
  const convertIdade = roundFunction(idadeRounded <= THRESHOLD_5_ANOS ? idadeRounded * 365.25 : idadeRounded * 12)
  const convertSexo = sexo === SexoEnum.FEMININO ? 2 : 1
  const data = json.find((d) => {
    return d.sex === convertSexo && d.age === convertIdade
  })

  const calcularZscoreFinal = (valorMedicao: number) => {
    const Zindex = (Math.pow(valorMedicao / data.m, data.l) - 1) / (data.s * data.l)
    let ZscoreFinal = 0

    if (Zindex > 3) {
      ZscoreFinal = 3 + (valorMedicao - getSd3pos()) / getSd23pos()
    } else if (Zindex < -3) {
      ZscoreFinal = -3 + (valorMedicao - getSd3neg()) / getSd23neg()
    } else if (Math.abs(Zindex) <= 3) {
      ZscoreFinal = Zindex
    }
    return ZscoreFinal
  }

  const calcularPercentil = (percentil: number) => {
    if (data) {
      return round(data.m * Math.pow(1 + data.l * data.s * percentil, 1 / data.l))
    }
  }

  const getSd3pos = () => {
    return calcularPercentil(Percentil.sd3pos)
  }

  const getSd2pos = () => {
    return calcularPercentil(Percentil.sd2pos)
  }

  const getSd1pos = () => {
    return calcularPercentil(Percentil.sd1pos)
  }

  const getSd2neg = () => {
    return calcularPercentil(Percentil.sd2neg)
  }

  const getSd3neg = () => {
    return calcularPercentil(Percentil.sd3neg)
  }

  const getSd23pos = () => {
    return getSd3pos() - getSd2pos()
  }

  const getSd23neg = () => {
    return getSd2neg() - getSd3neg()
  }

  return {
    calcularZscoreFinal,
    calcularPercentil,
    getSd3pos,
    getSd2pos,
    getSd1pos,
    getSd2neg,
    getSd3neg,
    getSd23pos,
    getSd23neg,
  }
}

function getJson(idade: number, medicao: TipoMedicao): any[] {
  if (medicao === 'imc') return idade <= THRESHOLD_5_ANOS ? imcAte5anos : imcMaior5anos
  if (medicao === 'peso') return idade <= THRESHOLD_5_ANOS ? pesoAte5anos : pesoMaior5anos
  if (medicao === 'altura') return idade <= THRESHOLD_5_ANOS ? alturaAte5anos : alturaMaior5anos
  if (medicao === 'perimetroCefalico') return perimetroCefalico
}

function round(num: number, places = 3): number {
  return Math.round(num * Math.pow(10, places)) / Math.pow(10, places)
}
