/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, HFlow, Icon, Tooltip, VFlow } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import CheckPermission from 'components/auth/CheckPermission'
import { HLabel } from 'components/HLabel'
import { confirm } from 'components/modals/confirm'
import { TipoReacaoAlergia } from 'graphql/types.generated'
import { cloneElement, MouseEvent } from 'react'
import { formatDate } from 'util/date/formatDate'
import { Alergia, categoriaAlergiaRecord } from 'view/atendimentos/detail/soap/aside/types/AlergiaModel'
import { grupoCboAlergias } from 'view/atendimentos/detail/soap/avaliacao/acessos'
import { AlergiaReacaoModel } from 'view/atendimentos/detail/soap/avaliacao/components/alergias-reacoes/model'

import { AlergiaTag } from '../../AlergiaTag'
import { AtendimentoAtualStatusMarker } from '../../AtendimentoAtualStatusMarker'
import { MainDetailAccordionHeaderRenderProps } from '../../main-detail-accordion/components/MainDetailAccordionHeader'

export interface HeaderAlergiaModalProps extends MainDetailAccordionHeaderRenderProps<Alergia> {
  showAlteracaoExclusao: boolean
  onRemove(item: AlergiaReacaoModel): void
  onEdit(itemId: ID): void
}

export function HeaderAlergiaModal(props: HeaderAlergiaModalProps) {
  const {
    item: {
      id,
      substanciaEspecifica,
      substanciaEspecificaLegado,
      categoriaSubstanciaEspecificaLegado,
      tipoReacao,
      ultimaEvolucao,
      criticidade,
      grauCerteza,
      isAvaliacao,
      isCriadoAtendimentoAtual,
    },
    showAlteracaoExclusao,
    onRemove,
    onEdit,
    disabled,
  } = props

  const handleExcluirClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    isAvaliacao
      ? confirm({
          title: 'Deseja confirmar a exclusão da alergia/reação adversa?',
          body:
            'Essa atualização foi gerada a partir da Avaliação do SOAP. Se ela for excluída, também será removida da Avaliação.',
          onConfirm: () => onRemove({ _id: ultimaEvolucao.id }),
          confirmLabel: 'Excluír',
          depthLevel: 2,
          manageOverflow: false,
        })()
      : onRemove({ _id: ultimaEvolucao.id })
  }

  const handleEditarClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onEdit(id)
  }

  const showButtons = showAlteracaoExclusao && !ultimaEvolucao.atualizadoEm
  const isButtonDisabled = id !== ultimaEvolucao.id || isAvaliacao

  const categoriaAlergia =
    categoriaAlergiaRecord[substanciaEspecifica?.categoria ?? categoriaSubstanciaEspecificaLegado]

  const classes = createStyles()

  return (
    <HFlow justifyContent='space-between' alignItems='center' hSpacing={0}>
      <div css={classes.iconContainer}>
        <Icon
          name='accordionAngleIcon'
          icon={!disabled ? 'angleDown' : 'angleUp'}
          fill={!disabled ? 'secondary' : 'disabled'}
        />
        <Grid
          gapVertical={0}
          style={css`
            width: 100%;
            padding-left: 1rem;
          `}
          alignItems='flex-start'
        >
          <Cell size={12}>
            <Heading level={4}>{substanciaEspecifica?.nome ?? substanciaEspecificaLegado}</Heading>
          </Cell>
          <VFlow vSpacing={0.5}>
            <Cell size={12}>
              <HFlow hSpacing={0.5} alignItems='center'>
                {cloneElement(categoriaAlergia.icon, {
                  style: { color: `${gray.c40}`, fill: `${gray.c40}`, width: '1.5rem', height: '1.5rem' },
                })}
                <HLabel
                  title={`${
                    tipoReacao
                      ? tipoReacao === TipoReacaoAlergia.ALERGIA
                        ? 'Alergia'
                        : 'Intolerância'
                      : 'Reação adversa'
                  } a ${categoriaAlergia?.descricao}`}
                  placeholder=''
                >
                  {ultimaEvolucao.atualizadoEm && ' | '}
                </HLabel>
                {ultimaEvolucao.atualizadoEm && (
                  <HLabel title='Última avaliação:'>{formatDate(ultimaEvolucao.atualizadoEm)}</HLabel>
                )}
              </HFlow>
            </Cell>
            {isCriadoAtendimentoAtual && (
              <Cell size={12}>
                <AtendimentoAtualStatusMarker isCriadoAvaliacao={isAvaliacao} expanded />
              </Cell>
            )}
          </VFlow>
        </Grid>
      </div>
      <HFlow hSpacing={0.5} alignItems='center'>
        <HFlow hSpacing={0.5}>
          <AlergiaTag type='grauCerteza' enum={grauCerteza} />
          <AlergiaTag type='criticidade' enum={criticidade} />
        </HFlow>
        {showButtons && (
          <CheckPermission permission={grupoCboAlergias.adicionarEditarExcluir}>
            <HFlow hSpacing={0}>
              <Button
                size='small'
                skin='ghost'
                style={classes.closeButton}
                disabled={isButtonDisabled}
                onClick={handleEditarClick}
              >
                <Tooltip
                  text={isButtonDisabled ? 'Avaliações do atendimento atual devem ser editadas no SOAP' : 'Editar'}
                >
                  <Icon icon='penOutline' />
                </Tooltip>
              </Button>

              <Button
                size='small'
                skin='ghost'
                style={classes.closeButton}
                onClick={handleExcluirClick}
                disabled={isButtonDisabled}
              >
                <Tooltip
                  text={isButtonDisabled ? 'Avaliações do atendimento atual devem ser excluídas no SOAP' : 'Excluir'}
                >
                  <Icon icon='trashOutline' />
                </Tooltip>
              </Button>
            </HFlow>
          </CheckPermission>
        )}
      </HFlow>
    </HFlow>
  )
}

const createStyles = () => ({
  tagsContainer: css`
    display: flex;
    justify-content: flex-end;
    & > span {
      margin: 0 0 0.25rem 0.5rem;
    }
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
  `,
  closeButton: css`
    margin: 0 0 0 1rem;
  `,
})
