import { Heading, HFlow, VFlow } from 'bold-ui'
import { endOfMonth, startOfMonth } from 'date-fns'
import { useExistemFichasParaProcessarQuery, useTransmissaoRecebimentoLotesQuery } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import * as React from 'react'
import { useState } from 'react'

import { GerarRelatorioInconsistenciaButton } from '../components/GerarRelatorioInconsistenciaButton'
import { TransmissaoProcessamentoFichasCmp } from '../components/TransmissaoProcessamentoFichasCmp'
import { TransmissaoRecebimentoImportFileBox } from './TransmissaoRecebimentoImportFileBox'
import { TransmissaoRecebimentoLoteFilterModel } from './TransmissaoRecebimentoLoteFilter'
import { TransmissaoRecebimentoLoteTable } from './TransmissaoRecebimentoLoteTable'

const createFilterDefault = (now: Date): TransmissaoRecebimentoLoteFilterModel => ({
  pageParams: {
    sort: ['-dataRecebimento'],
  },
  periodo: {
    startDate: startOfMonth(now),
    endDate: endOfMonth(now),
  },
})

export const TransmissaoRecebimentoLoteBox = () => {
  const { getServerTimeNow } = useServerTime()
  const [filter, setFilter] = useState<TransmissaoRecebimentoLoteFilterModel>(createFilterDefault(getServerTimeNow()))

  const result = useTransmissaoRecebimentoLotesQuery({
    variables: {
      input: {
        ...filter,
        origensLote: filter.origensLote?.map((origemLote) => origemLote.id),
      },
    },
    context: { newRequest: true },
  })

  const {
    data: { existemFichasParaProcessar },
  } = useExistemFichasParaProcessarQuery({ context: { newRequest: true } })

  const resetFilter = () => {
    setFilter(createFilterDefault(getServerTimeNow()))
  }

  return (
    <VFlow>
      <TransmissaoRecebimentoImportFileBox refetchQuery={result.refetch} />

      <VFlow vSpacing={0.5}>
        <HFlow hSpacing={0.5}>
          <Heading level={4}>Lotes recebidos agrupados por lote</Heading>
          <TransmissaoProcessamentoFichasCmp existemFichasParaProcessar={existemFichasParaProcessar} />
        </HFlow>
        <TransmissaoRecebimentoLoteTable
          queryResult={result}
          filter={filter}
          setFilter={setFilter}
          resetFilter={resetFilter}
        />
      </VFlow>

      <HFlow justifyContent='flex-end'>
        <GerarRelatorioInconsistenciaButton />
      </HFlow>
    </VFlow>
  )
}
