import { getFieldLabelGroup } from 'util/fields-labels/getFieldLabelGroup'
import { Label } from 'util/fields-labels/model'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { soapFormLabels } from './soapFormLabels'

export const getSoapFieldLabelGroup = <TFieldModel>(fieldName: MetaPath<TFieldModel>): Label<TFieldModel> => {
  return getFieldLabelGroup<TFieldModel, SoapState>(fieldName, soapFormLabels)
}
