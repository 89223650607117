/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente11(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='42' height='106' viewBox='0 0 42 106' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M5.64195 71.9526C5.68106 71.8277 5.72664 71.6814 5.77822 71.5148C5.97092 70.8924 6.2473 69.9863 6.58251 68.8502C7.25288 66.5782 8.15876 63.3856 9.10112 59.702C10.9841 52.3417 13.0196 42.9949 13.6048 35.112C13.897 31.176 13.9734 27.0363 14.0197 23.0779C14.0266 22.4838 14.0329 21.894 14.0391 21.3096C14.0744 17.9934 14.1078 14.8524 14.2524 12.1028C14.4233 8.85508 14.7474 6.22017 15.3825 4.5128C15.6999 3.65945 16.0729 3.10051 16.4849 2.79637C16.8698 2.51228 17.3393 2.41278 17.9755 2.5895C18.6029 2.76381 19.2818 3.25336 19.9982 4.0882C20.7097 4.91734 21.4241 6.04705 22.1318 7.4292C23.5468 10.1927 24.9017 13.8987 26.1457 18.0562C28.6321 26.3667 30.6522 36.4037 31.8221 44.1241C32.7761 50.4205 33.48 56.436 34.0818 61.5785C34.2184 62.746 34.3498 63.8686 34.4776 64.9393C34.8226 67.829 35.1425 70.3452 35.4708 72.3407C35.7848 74.2489 36.1149 75.7398 36.5062 76.6237C36.4134 76.6621 36.2506 76.7082 35.9895 76.7434C35.2749 76.8397 34.136 76.8185 32.6749 76.6978C29.7696 76.4579 25.7417 75.8395 21.6501 75.1182C17.5626 74.3976 13.4268 73.5771 10.312 72.9365C8.75476 72.6163 7.45325 72.3411 6.54118 72.1461C6.1762 72.068 5.8736 72.0028 5.64195 71.9526Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M3.91558 97.7226C3.37412 96.4872 2.95467 94.4903 2.71745 92.0608C2.48138 89.6431 2.42948 86.8378 2.60144 84.0096C2.77344 81.1805 3.1687 78.3407 3.82165 75.851C4.47696 73.3522 5.38086 71.2491 6.54342 69.8556C7.64382 68.5366 9.65919 67.8167 12.2647 67.5853C14.8499 67.3556 17.9083 67.6166 20.9585 68.149C24.0047 68.6808 27.0201 69.4794 29.511 70.3113C32.0185 71.1488 33.945 72.0039 34.8469 72.6331C35.2293 72.8999 35.6583 73.45 36.0993 74.293C36.5334 75.1231 36.9531 76.1859 37.341 77.4163C38.1165 79.8761 38.751 82.9604 39.127 86.0912C39.5032 89.2233 39.6179 92.3796 39.3645 94.9858C39.2377 96.2891 39.0203 97.4401 38.7052 98.3755C38.388 99.3172 37.9855 99.9998 37.516 100.413C37.0408 100.831 36.2071 101.215 35.0401 101.534C33.8864 101.849 32.4601 102.087 30.8491 102.249C27.6281 102.571 23.7145 102.581 19.862 102.304C16.008 102.027 12.2376 101.465 9.29943 100.654C7.82913 100.248 6.58555 99.7839 5.64903 99.2726C4.69812 98.7535 4.13525 98.2239 3.91558 97.7226Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M40.9612 99.4934C40.5789 100.523 40.019 101.54 39.1677 102.29C38.2587 103.09 36.9784 103.596 35.6992 103.945C34.3667 104.309 32.7926 104.567 31.0981 104.736C27.7044 105.076 23.6439 105.082 19.6828 104.797C15.7278 104.513 11.7824 103.933 8.63388 103.063C7.06456 102.63 5.61984 102.105 4.45112 101.467C3.33993 100.86 2.18081 99.9925 1.62582 98.7262C0.947715 97.1789 0.506433 94.9728 0.256481 92.5734C0.0018945 90.1295 -0.0699637 87.3221 0.0715535 84.4816C0.212978 81.643 0.569572 78.729 1.18934 76.0805C1.77426 73.581 2.62052 71.1993 3.82882 69.3371C5.65195 63.2793 10.2007 47.1982 11.1117 34.9269C11.4406 30.4956 11.4914 25.7808 11.5392 21.2852L11.5409 21.1292C11.5986 15.6943 11.654 10.4747 12.2624 6.76237C12.5635 4.92441 13.0431 3.14188 13.9464 1.87398C14.4239 1.20371 15.0771 0.595994 15.9549 0.261062C16.8455 -0.0787801 17.7696 -0.0623366 18.6446 0.180743C19.953 0.544206 21.0281 1.4495 21.8954 2.46017C22.7823 3.49365 23.6 4.8114 24.357 6.28982C25.8727 9.24993 27.2787 13.1216 28.5407 17.3396C31.0701 25.7934 33.1121 35.9509 34.2938 43.7496C35.2544 50.0887 35.9627 56.1421 36.5639 61.2793L36.5652 61.2909C37.1247 66.0725 37.5846 69.9935 38.0655 72.6812C38.9875 74.2771 39.7595 76.5242 40.3644 78.9398C41.0266 81.5842 41.5313 84.607 41.7951 87.5692C42.0579 90.5211 42.0882 93.486 41.7664 95.9957C41.6055 97.2506 41.3499 98.4469 40.9612 99.4934ZM36.1639 73.4002C39.5358 78.7386 41.9353 97.1894 37.8464 100.788C33.3502 104.746 5.7515 103.157 3.45763 97.9234C1.24447 92.8735 1.23462 76.6353 5.65771 70.1994C7.41716 64.3827 12.1624 47.7888 13.1062 35.075C13.4407 30.5692 13.4915 25.7897 13.5391 21.3064C13.6572 10.1923 13.756 0.898418 18.1093 2.10777C24.2186 3.80495 29.972 28.5771 32.3164 44.0493C33.2719 50.3548 33.977 56.3805 34.5788 61.5232L34.5788 61.5233L34.5788 61.5237C35.1593 66.4845 35.6437 70.6238 36.1639 73.4002Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
