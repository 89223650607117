/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Alert, Button, Cell, Grid, HeadingSection, HFlow, Modal, ModalBody, ModalFooter, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps, SubmitButton } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { StatusAvaliadoEnum } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { useHistory, useRouteMatch } from 'react-router'
import { metaPath } from 'util/metaPath'

import AvaliacaoDesenvolvimentoTable from '../components/AvaliacaoDesenvolvimentoTable'
import { AvaliacaoDesenvolvimentoModel } from '../model'
import { AlteracoesFenotipicasModel } from './AlteracaoFenotipicaView'
import { alteracoesFenotipicasMessages } from './messages'

interface AlteracaoFenotipicaModalProps {
  initialValues: AlteracoesFenotipicasModel
  updateAlteracaoFenotipica(rows: AlteracoesFenotipicasModel): void
}

const name = metaPath<AlteracoesFenotipicasModel>()

export function AlteracaoFenotipicaModal(props: AlteracaoFenotipicaModalProps) {
  const { initialValues, updateAlteracaoFenotipica } = props
  const alert = useAlert()
  const history = useHistory()
  const match = useRouteMatch()

  const handleOnClose = () => history.push(match.url.replace('/puericultura/alteracao-fenotipica', ''))

  const handleCancel = (dirtyForm: boolean) => () => {
    if (dirtyForm) {
      confirm({
        title: 'Deseja sair sem salvar?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar aqui',
        confirmLabel: 'Sim, cancelar',
        onConfirm: () => handleOnClose(),
      })()
    } else handleOnClose()
  }

  const handleSubmit = (formValues: AlteracoesFenotipicasModel, formApi: FormApi<AlteracoesFenotipicasModel>) => {
    const avaliacaoPresenteList = exibirAvaliadoComoPresente(formValues.alteracoesFenotipicas)
    if (formApi.getState().dirty && !isEmpty(avaliacaoPresenteList)) {
      const messages = alteracoesFenotipicasMessages(avaliacaoPresenteList.length)
      confirm({
        title: messages.question,
        body: (
          <Grid justifyContent='flex-start'>
            <Cell size={12}>
              <Alert inline type='info'>
                Após a finalização deste atendimento, esta avaliação não poderá ser modificada.
              </Alert>
            </Cell>
            <Cell size={12}>
              <Text>{messages.headerList}</Text>
            </Cell>
            <Cell size={12}>{avaliacaoPresenteList}</Cell>
          </Grid>
        ),
        cancelLabel: 'Não',
        confirmLabel: 'Sim',
        depthLevel: 2,
        onConfirm: () => {
          updateAlteracaoFenotipica(formValues)
          alert('success', 'Avaliação de alterações fenotípicas salva com sucesso')
          handleOnClose()
        },
      })()
    } else {
      updateAlteracaoFenotipica(formValues)
      alert('success', 'Avaliação de alterações fenotípicas salva com sucesso')
      handleOnClose()
    }
  }

  const renderForm = (formProps: FormRenderProps<AlteracoesFenotipicasModel>) => {
    return (
      <Modal size='large' onClose={handleCancel(formProps.dirty)} open>
        <ModalBody>
          <HeadingSection level={1} title='Avaliar alterações fenotípicas' />
          <Grid>
            <Cell size={12}>
              <Alert type='info'>
                Uma alteração fenotípica deve ser considerada como <strong>Presente</strong> quando esta for
                identificada na criança e foi presente em algum momento. Mesmo que esta seja resolvida por meio
                cirúrgico, irá ser registrada como Presente em seu histórico.
              </Alert>
            </Cell>
            <Cell size={12}>
              <AvaliacaoDesenvolvimentoTable name={name.alteracoesFenotipicas} usePrimaryColors />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button title='Cancelar' onClick={handleCancel(formProps.dirty)}>
              Cancelar
            </Button>
            <SubmitButton title='Salvar' kind='primary' handleSubmit={formProps.handleSubmit}>
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return <Form<AlteracoesFenotipicasModel> render={renderForm} onSubmit={handleSubmit} initialValues={initialValues} />
}

const exibirAvaliadoComoPresente = (avaliacoes: AvaliacaoDesenvolvimentoModel[]) => {
  const avaliadoComoPresenteList =
    avaliacoes &&
    avaliacoes
      .filter(
        (row: AvaliacaoDesenvolvimentoModel) => row.statusButton === StatusAvaliadoEnum.PRESENTE && !row.disableButton
      )
      .sort((a, b) => a.id.localeCompare(b.id))
      .map((row) => <li key={row.id}>{row.descricao}</li>)

  return avaliadoComoPresenteList
}
