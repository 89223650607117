/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, InfoLabel, VFlow } from 'bold-ui'
import { formatDate } from 'util/date/formatDate'
import { stringGrouper } from 'util/string/string-grouper'
import {
  categoriaAlergiaRecord,
  criticidadeAlergiaRecord,
  EvolucaoAlergia,
  grauCertezaAlergiaRecord,
  tipoReacaoAlergiaRecord,
} from 'view/atendimentos/detail/soap/aside/types/AlergiaModel'

import StatusSquare from '../../../../../../StatusSquare'

interface HistoricoAvaliacaoAlergiaTableContentProps {
  evolucaoAlergia: EvolucaoAlergia[]
}

export default function HistoricoAvaliacaoAlergiaTableContent({
  evolucaoAlergia,
}: HistoricoAvaliacaoAlergiaTableContentProps) {
  return (
    <VFlow>
      {evolucaoAlergia.map((evolucao) => {
        const categoriaAlergia =
          categoriaAlergiaRecord[
            evolucao.alergia?.substanciaEspecifica?.categoria ?? evolucao.alergia.categoriaSubstanciaEspecificaLegado
          ]?.descricao

        return (
          <Grid gap={2} key={evolucao.id}>
            <Cell size={3}>
              <InfoLabel title='Tipo'>
                {tipoReacaoAlergiaRecord[evolucao.tipoReacao]?.descricao ?? `Reação adversa a ${categoriaAlergia}`}
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Categoria do agente causador'>{categoriaAlergia}</InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Agente/substância específica'>
                {evolucao.alergia?.substanciaEspecifica?.nome ??
                  evolucao.alergia?.substanciaEspecificaLegado.capitalize()}
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Data de início'>{formatDate(evolucao.dataInicio)}</InfoLabel>
            </Cell>
            <Cell size={6}>
              <InfoLabel title='Manifestações'>
                {stringGrouper(...evolucao.manifestacoes.map((manifestacao) => manifestacao.nome)) ??
                  evolucao.manifestacoesLegado}
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Criticidade'>
                <StatusSquare
                  color={criticidadeAlergiaRecord[evolucao.criticidade].background}
                  description={criticidadeAlergiaRecord[evolucao.criticidade].descricao}
                />
              </InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='Grau de certeza'>{grauCertezaAlergiaRecord[evolucao.grauCerteza].descricao}</InfoLabel>
            </Cell>
            {evolucao.observacao && (
              <Cell size={12}>
                <InfoLabel title='Observações'>{evolucao.observacao}</InfoLabel>
              </Cell>
            )}
          </Grid>
        )
      })}
    </VFlow>
  )
}
