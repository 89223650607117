import { SelectField } from 'components/form'
import { ViaAdministracaoSelectFieldDocument } from 'graphql/hooks.generated'
import { ViaAdministracaoSelectFieldQuery, ViaAdministracaoSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface ViaAdministracaoSelectModel {
  id: ID
  nome: string
}

export interface ViaAdministracaoSelectFieldProps extends AsyncSelectFieldProps<ViaAdministracaoSelectModel> {}

export function ViaAdministracaoSelectField(props: ViaAdministracaoSelectFieldProps) {
  const extractItems = (data: ViaAdministracaoSelectFieldQuery) => {
    return data?.aplicacoesMedicamento?.content
  }

  const handleItemToString = (item: ViaAdministracaoSelectModel) => {
    return item?.nome
  }

  const variables = (inputQuery: string): ViaAdministracaoSelectFieldQueryVariables => ({
    input: {
      query: inputQuery,
      pageParams: DEFAULT_SELECT_PAGE_PARAM,
    },
  })

  const { selectProps } = useAsyncQuerySelect<
    ViaAdministracaoSelectModel,
    ViaAdministracaoSelectFieldQuery,
    ViaAdministracaoSelectFieldQueryVariables
  >({
    query: ViaAdministracaoSelectFieldDocument,
    extractItems,
    variables,
  })

  return <SelectField<ViaAdministracaoSelectModel> itemToString={handleItemToString} {...selectProps} {...props} />
}
