import { ApolloQueryResult } from 'apollo-client'
import { HFlow, Icon, TableCell, TableRow, Text, Tooltip } from 'bold-ui'
import { UnidadeSaude, VinculacoesEstabelecimentosTableQuery } from 'graphql/types.generated'
import React, { Fragment } from 'react'

import { VinculacoesEstabelecimentosEditAction } from './VinculacoesEstabelecimentosEditAction'
import { VinculacoesEstabelecimentosExcludeAction } from './VinculacoesEstabelecimentosExcludeAction'
import { VinculacoesEstabelecimentosItem } from './VinculacoesEstabelecimentosTable'
import { VinculacoesEstabelecimentosTableRowPanel } from './VinculacoesEstabelecimentosTableRowPanel'

interface VinculacoesEstabelecimentosTableRowProps {
  vinculosEstabelecimento: VinculacoesEstabelecimentosItem
  expandedRow: string
  handleClick(equipeEMultiVinculacao: VinculacoesEstabelecimentosItem): any
  refetch(): Promise<ApolloQueryResult<VinculacoesEstabelecimentosTableQuery>>
}

export function VinculacoesEstabelecimentosTableRow(props: VinculacoesEstabelecimentosTableRowProps) {
  const { vinculosEstabelecimento, expandedRow, handleClick, refetch } = props
  const { referencia } = vinculosEstabelecimento
  const estabelecimentosVinculados = vinculosEstabelecimento.vinculacoes as UnidadeSaude[]

  const isExpanded = expandedRow === referencia.id

  return (
    <Fragment key={referencia.id}>
      <Tooltip placement='left-start' text={isExpanded ? 'Minimizar' : 'Expandir'}>
        <TableRow onClick={handleClick(vinculosEstabelecimento)}>
          <TableCell>
            <HFlow alignItems='center' justifyContent='flex-start'>
              <Icon icon={isExpanded ? 'angleUp' : 'angleDown'} />
              <Text>
                <b>{`${referencia.nome.toUpperCase()} - ${referencia.cnes}`}</b> | <b>Unidades de saúde vinculadas: </b>
                {vinculosEstabelecimento.quantidadeVinculacoes}
              </Text>
            </HFlow>
          </TableCell>
          <TableCell>
            <HFlow alignItems='center' justifyContent='flex-end' hSpacing={0}>
              <VinculacoesEstabelecimentosEditAction vinculosEstabelecimento={vinculosEstabelecimento} />
              <VinculacoesEstabelecimentosExcludeAction
                vinculosEstabelecimento={vinculosEstabelecimento}
                refetch={refetch}
              />
            </HFlow>
          </TableCell>
        </TableRow>
      </Tooltip>
      {expandedRow && isExpanded && estabelecimentosVinculados && (
        <TableRow style={{ backgroundColor: '#f0f0f5' }}>
          <TableCell colSpan={2} style={{ padding: 0 }}>
            <VinculacoesEstabelecimentosTableRowPanel estabelecimentosVinculados={estabelecimentosVinculados} />
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}
