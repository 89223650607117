/**  @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  FormLabel,
  Grid,
  Heading,
  HFlow,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  VFlow,
} from 'bold-ui'
import { Form, FormPrompt, FormRenderProps, SubmitButton, TextAreaField } from 'components/form'
import { ReceitaMedicamento, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import moment from 'moment'
import React from 'react'
import { Redirect, useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { metaPath } from 'util/metaPath'
import { createValidator, maxLength, required } from 'util/validation'

export interface MedicamentoInterromperFormModel {
  id: ID
  justificativa: string
}

export interface InterromperTratamentoModalProps {
  receita: ReceitaMedicamento
  onSubmit(medicamentos: MedicamentoInterromperFormModel): void
  onClear(): void
}

const path = metaPath<MedicamentoInterromperFormModel>()

export const validate = createValidator<MedicamentoInterromperFormModel>({
  id: [required],
  justificativa: [required, maxLength(200)],
})

export default function InterromperTratamentoModal(props: InterromperTratamentoModalProps) {
  const { receita, onSubmit, onClear } = props
  const history = useHistory()
  const match = useRouteMatch()

  if (!receita) {
    return <Redirect to='/403' />
  }

  const goBack = () => {
    history.push(match.url.replace('/interromper-tratamento', ''))
  }

  const handleOnSubmitSucceeded = () => {
    history.push(match.url.replace('/interromper-tratamento', ''))
    onClear()
  }

  const renderForm = (renderProps: FormRenderProps<MedicamentoInterromperFormModel>) => {
    return (
      <React.Fragment>
        <ModalBody>
          <VFlow>
            <HFlow>
              <Icon icon='exclamationTriangleOutline' size={3} fill='danger' />
              <VFlow vSpacing={0.5}>
                <Heading level={1}>Deseja interromper o tratamento?</Heading>
              </VFlow>
            </HFlow>
            <FormPrompt />
            <Grid>
              <Cell size={6}>
                <VFlow
                  style={css`
                    word-break: break-all;
                  `}
                  vSpacing={0}
                >
                  <Text data-cy='InterromperTratamento.PrincipioAtivo' fontWeight='bold'>
                    {`${receita.medicamento.principioAtivo} ${receita.medicamento.concentracao ?? ''}`.trim()}
                  </Text>
                  <Text data-cy='InterromperTratamento.Posologia'>{receita.posologia}</Text>
                </VFlow>
              </Cell>
              <Cell size={6}>
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>Período</Text>
                  <Text data-cy='InterromperTratamento.Periodo'>{`${moment(receita.dtInicioTratamento).format(
                    'DD/MM/YYYY'
                  )} 
                - ${
                  receita.medidaTempoTratamento !== UnidadeMedidaTempoEnum.INDETERMINADO
                    ? moment(receita.dtFimTratamento).format('DD/MM/YYYY')
                    : 'Indeterminado'
                } ${receita.usoContinuo ? ' | Uso contínuo' : ''}
              `}</Text>
                </VFlow>
              </Cell>
              <Cell size={12}>
                <VFlow vSpacing={0}>
                  <Heading level={5}>Recomendações</Heading>
                  {receita.recomendacoes ? (
                    <Text
                      style={css`
                        word-break: break-all;
                      `}
                      data-cy='InterromperTratamento.Recomendacoes'
                    >
                      {receita.recomendacoes}
                    </Text>
                  ) : (
                    <Text fontStyle='italic' data-cy='InterromperTratamento.Recomendacoes'>
                      Não há recomendações para o medicamento
                    </Text>
                  )}
                </VFlow>
              </Cell>
              <Cell size={12}>
                <VFlow vSpacing={0.5} data-cy='InterromperTratamento.Justificativa'>
                  <FormLabel label='Justificativa' required />
                  <TextAreaField
                    name={path.justificativa}
                    style={css`
                      resize: none;
                    `}
                    maxLength={200}
                    data-cy='InterromperTratamento.JustificativaTextArea'
                  />
                </VFlow>
              </Cell>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button kind='normal' size='medium' onClick={goBack}>
              Cancelar
            </Button>
            <SubmitButton
              handleSubmit={renderProps.handleSubmit}
              kind='primary'
              skin='default'
              size='medium'
              data-cy='InterromperTratamento.ConfirmarButton'
            >
              Confirmar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </React.Fragment>
    )
  }

  return (
    <Modal
      open={true}
      closeOnBackdropClick={false}
      depthLevel={2}
      manageOverflow={false}
      onClose={goBack}
      style={css`
        width: 40rem;
      `}
    >
      <Form<MedicamentoInterromperFormModel>
        onSubmit={onSubmit}
        onSubmitSucceeded={handleOnSubmitSucceeded}
        subscription={{ values: true, pristine: true, error: true }}
        render={renderForm}
        validate={validate}
        initialValues={{ id: receita.id, justificativa: undefined }}
      />
    </Modal>
  )
}
