/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useAlert } from 'components/alert'
import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { resolveName, UseFieldProps } from 'components/form/final-form/hooks/useField'
import { FormApi } from 'final-form'
import { ParteBucalEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment, useEffect, useState } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { Arcadas } from '../../odontograma/Arcadas'
import { convertArcadasFieldModelToArcadasModel } from '../converter'
import { ODONTOGRAMA_SUBMIT_ALERT_MESSAGE, OdontogramaSetActiveRef } from '../model'
import { OdontogramaFieldModel } from '../OdontogramaField'
import ArcadaFormPopper, { ArcadaFormModel } from './ArcadaFormPopper'

export interface ArcadaFieldModel {
  observacoes?: string[]
  procedimentos?: ProcedimentoOdontoSelectModel[]
  lastUpdate: Instant
}

interface ArcadasFieldProps extends UseFieldProps {
  name: MetaPath<Record<ParteBucalEnum, ArcadaFieldModel>>
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  proteseTotalSuperior?: boolean
  proteseTotalInferior?: boolean
  initialValue?: OdontogramaFieldModel['arcadas']
  activeArcada: ParteBucalEnum
  activeRef: { ref: SVGSVGElement | HTMLButtonElement }
  setActiveArcada(arcada: ParteBucalEnum): void
  setActiveRef: OdontogramaSetActiveRef
}

export default function ArcadasField(props: ArcadasFieldProps) {
  const {
    name,
    proteseTotalSuperior,
    proteseTotalInferior,
    cidadaoDataNascimento,
    dataAtendimento,
    initialValue,
    activeArcada,
    activeRef,
    setActiveArcada,
    setActiveRef,
  } = props

  const alert = useAlert()
  const [popperOpen, setPopperOpen] = useState(false)

  const { getServerTimeNow } = useServerTime()

  useEffect(() => {
    if (activeArcada && activeRef) {
      setPopperOpen(true)
    }
  }, [activeArcada, activeRef])

  const renderField = (fieldProps: FieldRenderProps<OdontogramaFieldModel['arcadas']>) => {
    const {
      input: { value, onChange },
    } = fieldProps

    const fieldValues = Object.entries(value) as [ParteBucalEnum, ArcadaFieldModel][]

    const handleChange = (newValues: [ParteBucalEnum, ArcadaFieldModel][]) => {
      const o = Object.fromEntries(new Map([...fieldValues, ...newValues] as [ParteBucalEnum, ArcadaFieldModel][]))
      onChange(o)
    }

    const handleSubmit = (formValues: ArcadaFormModel, formApi: FormApi<ArcadaFormModel>) => {
      const { replicarItems, ...values } = formValues

      if (values && formApi.getState().dirty) {
        handleChange([
          ...((replicarItems &&
            replicarItems.map((replicarItem) => [replicarItem.value, values] as [ParteBucalEnum, ArcadaFieldModel])) ||
            []),
          [activeArcada, { ...values, lastUpdate: getServerTimeNow().valueOf() }],
        ])
        alert('success', ODONTOGRAMA_SUBMIT_ALERT_MESSAGE)
      }

      setPopperOpen(false)
      setActiveArcada(undefined)
    }

    return (
      <Fragment>
        <Arcadas
          onClick={setActiveArcada}
          activeItem={activeArcada}
          proteseTotalSuperior={proteseTotalSuperior}
          proteseTotalInferior={proteseTotalInferior}
          value={convertArcadasFieldModelToArcadasModel(value)}
          setActiveRef={setActiveRef}
        />

        {activeArcada && (
          <ArcadaFormPopper
            parteBucalEnum={activeArcada}
            cidadaoDataNascimento={cidadaoDataNascimento}
            dataAtendimento={dataAtendimento}
            open={popperOpen}
            placement='top'
            anchorRef={activeRef?.ref}
            initialValues={value[activeArcada]}
            arcadasValues={value}
            onSubmit={handleSubmit}
          />
        )}
      </Fragment>
    )
  }

  return (
    <Field name={resolveName(name)} render={renderField} initialValue={initialValue} subscription={{ value: true }} />
  )
}
