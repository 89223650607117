import createDecorator from 'final-form-calculate'
import { isUndefinedOrNull } from 'util/checks'

import { EstagioFormModel, NivelEstagioEnum, path } from './model'

export const estagioFormCalculator = createDecorator(
  {
    field: path.unidadeSaude.absolutePath(),
    updates: {
      [path.lotacaoSupervisora.absolutePath()]: (
        unidadeSaude,
        allValues: EstagioFormModel,
        prevValues: EstagioFormModel
      ) => {
        return isUndefinedOrNull(unidadeSaude) || prevValues.unidadeSaude?.id !== unidadeSaude.id
          ? null
          : allValues.lotacaoSupervisora
      },
    },
  },
  {
    field: path.lotacaoSupervisora.absolutePath(),
    updates: {
      [path.cboFormacao.absolutePath()]: (
        lotacaoSupervisora,
        allValues: EstagioFormModel,
        prevValues: EstagioFormModel
      ) => {
        return isUndefinedOrNull(lotacaoSupervisora) || prevValues.lotacaoSupervisora?.id !== lotacaoSupervisora.id
          ? null
          : allValues.cboFormacao
      },
    },
  },
  {
    field: path.nivelEstagio.absolutePath(),
    updates: {
      [path.cboFormacao.absolutePath()]: (nivelEstagio, allValues: EstagioFormModel, prevValues) => {
        return nivelEstagio === NivelEstagioEnum.SUPERIOR ? null : allValues.cboFormacao
      },
    },
  }
)
