import { HeadingSection, modal } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import { PageLoading } from 'components/loading'
import { confirm } from 'components/modals/confirm'
import { ModalBody } from 'components/modals/ModalBody'
import clearTypename from 'graphql/clearTypename'
import { useCidadaoAdLoadQuery, useCidadaoAdSaveMutation } from 'graphql/hooks.generated'
import { AtencaoDomiciliarCondutaDesfecho } from 'graphql/types.generated'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router'

import CidadaoADDadosForm from './components/CidadaoADDadosForm'
import CidadaoADFormModel from './components/CidadaoADFormModel'
import convertModelToInput, { convertToForm } from './convertModelToInput'

interface UrlParams {
  cidadaoADId: string
}

export function CidadaoADEditView() {
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const match = useRouteMatch<UrlParams>()
  const history = useHistory()

  const [save] = useCidadaoAdSaveMutation()
  const {
    data: { cidadaoAD },
    loading,
  } = useCidadaoAdLoadQuery({
    variables: { input: match.params.cidadaoADId },
  })

  const initialValues = useMemo(() => cidadaoAD && convertToForm(clearTypename(cidadaoAD)), [cidadaoAD])

  const confirmObito = (values: CidadaoADFormModel, msgPossuiAgendamentos: string) => {
    modal({
      size: 'auto',
      render: () => (
        <ModalBody title='Deseja confirmar óbito para o cidadão?' icon='questionMarkOutline' type='primary'>
          {msgPossuiAgendamentos}
        </ModalBody>
      ),
      actions: [
        {
          label: 'Cancelar',
        },
        {
          label: 'Salvar',
          kind: 'primary',
          onClick: () => onEditSuccess(values),
        },
        {
          label: 'Salvar e registrar pós-óbito',
          kind: 'primary',
          onClick: () => onSalvarRegistrarPosObito(values),
        },
      ],
    })()
  }

  const confirmSaidaADComAgendamentos = (values: CidadaoADFormModel) => {
    confirm({
      title: 'Deseja confirmar a saída do cidadão da atenção domiciliar?',
      body: 'Todas as consultas agendadas para ele serão excluídas.',
      onConfirm: () => {
        onEditSuccess(values)
      },
    })()
  }

  const handleSubmit = (values: CidadaoADFormModel) => {
    if (values.condutaDesfecho === AtencaoDomiciliarCondutaDesfecho.OBITO) {
      confirmObito(
        values,
        moment(values?.dataAgendado).isAfter() ? 'Todas as consultas agendadas para ele serão canceladas.' : ''
      )
    } else if (
      values?.condutaDesfecho !== AtencaoDomiciliarCondutaDesfecho.PERMANENCIA &&
      moment(values?.dataAgendado).isAfter()
    ) {
      confirmSaidaADComAgendamentos(values)
    } else {
      onEditSuccess(values)
    }
  }

  const onEditSuccess = (values: CidadaoADFormModel) => {
    return save({ variables: { input: convertModelToInput(values) } })
      .then(() => {
        alert('success', `Edição de ${cidadaoAD.cidadao.nome} salvo com sucesso.`)
        history.push(`/ad/lista`)
      })
      .catch(handleRejection)
  }

  const onSalvarRegistrarPosObito = (values: CidadaoADFormModel) => {
    return save({ variables: { input: convertModelToInput(values) } })
      .then((res) => {
        alert('success', `Edição de ${cidadaoAD.cidadao.nome} salvo com sucesso.`)
        window.scrollTo(0, 0)
        history.replace(`/ad/lista/cadastro?cidadaoADEmObitoId=${res.data?.salvarCidadaoAtencaoDomiciliar?.id}`)
      })
      .catch(handleRejection)
  }

  if (loading) {
    return <PageLoading message='Carregando' />
  } else if (!cidadaoAD) {
    return <Redirect to='/404' />
  } else if (!cidadaoAD?.actions?.permissaoEditarAD?.enabled) {
    return <Redirect to='/403' />
  }

  return (
    <>
      <Breadcrumb title='Edição' />
      <PageContent type='filled'>
        <HeadingSection level={1} title='Edição de cidadão na atenção domiciliar' />
        <CidadaoADDadosForm onSubmit={handleSubmit} initialValues={initialValues} edit={true} />
      </PageContent>
    </>
  )
}
