import { VFlow } from 'bold-ui'
import { useSugestaoDatasAgendamentoQuery } from 'graphql/hooks.generated'
import React from 'react'
import { safeClone } from 'util/array'
import { compareAscLocalDate } from 'util/date/compare'

import {
  AgendarProximasConsultasSection,
  AgendarProximasConsultasSectionProps,
} from './AgendarProximasConsultasSection'
import {
  convertSugestaoConsultaOdontoToSugestaoProximaConsultaModel,
  convertSugestaoConsultaPreNatalToSugestaoProximaConsultaModel,
} from './converter'
import { SugestaoProximasConsultasSection } from './SugestaoProximasConsultasSection'

export interface ProximasConsultasPanelProps extends AgendarProximasConsultasSectionProps {
  dataAtendimento: LocalDate
  idadeGestacionalEmDias: number
}

export function ProximasConsultasPanel(props: ProximasConsultasPanelProps) {
  const { prontuarioId, dataAtendimento, idadeGestacionalEmDias } = props

  const { data } = useSugestaoDatasAgendamentoQuery({
    variables: {
      input: {
        prontuarioId,
        dataAtendimento,
        idadeGestacionalEmDias,
      },
    },
  })

  // eh necessario clonar o array por causa do shift
  const sugestoesPreNatal = safeClone(data?.sugestoesDatas?.preNatal)
  const sugestoesOdonto = safeClone(data?.sugestoesDatas?.odontologica)

  const proxConsultaPreNatal = sugestoesPreNatal.shift()
  const proxConsultaOdonto = sugestoesOdonto.shift()

  const sugestoesPreNatalAsSugestaoProximaConsultaModel = sugestoesPreNatal.map(
    convertSugestaoConsultaPreNatalToSugestaoProximaConsultaModel
  )
  const sugestoesOdontoAsSugestaoProximaConsultaModel = sugestoesOdonto.map(
    convertSugestaoConsultaOdontoToSugestaoProximaConsultaModel
  )

  const sugestoes = sugestoesPreNatalAsSugestaoProximaConsultaModel
    .concat(sugestoesOdontoAsSugestaoProximaConsultaModel)
    .sort((a, b) => compareAscLocalDate(a.date, b.date))

  return (
    <VFlow>
      <AgendarProximasConsultasSection
        {...props}
        proxConsultaPreNatal={proxConsultaPreNatal}
        proxConsultaOdonto={proxConsultaOdonto}
      />
      {sugestoes.isNotEmpty() && <SugestaoProximasConsultasSection sugestoes={sugestoes} />}
    </VFlow>
  )
}
