import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { useAcessoEditQuery, useSalvarAcessoMutation } from 'graphql/hooks.generated'
import * as React from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router'
import { titleCase } from 'util/strConversor'

import { AcessoForm, AcessoFormModel } from './AcessoForm'
import convertModelToInput from './convertModelToInput'

interface UrlParams {
  profissionalId: ID
  acessoId: ID
}
export const AcessoEditView = (props) => {
  const alert = useAlert()
  const match = useRouteMatch<UrlParams>()
  const history = useHistory()
  const {
    data: { acesso, profissional },
  } = useAcessoEditQuery({
    variables: { acessoId: match.params.acessoId, profissionalId: match.params.profissionalId },
  })
  const [salvar] = useSalvarAcessoMutation()

  if (!profissional) {
    return null
  }

  if (!acesso.actions.permissaoEdicao.enabled) {
    return <Redirect to='/403' />
  }

  const handleSubmit = (values: AcessoFormModel) => {
    const input = convertModelToInput(values)
    return salvar({ variables: { input } })
  }

  const handleSubmitSuccess = () => {
    alert('success', 'Acesso editado com sucesso.')
    history.push(`/profissional/${match.params.profissionalId}/dados`)
  }

  const initialValues: any = acesso && {
    id: match.params.acessoId,
    profissional: match.params.profissionalId,
    municipio: (acesso as any).municipio,
    perfis: acesso.perfis,
    tipo: acesso.tipo,
    uf: (acesso as any).uf,
  }

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/profissional/${match.params.profissionalId}/dados`} />
      <Breadcrumb title='Editar acesso' />

      <VFlow vSpacing={1.5}>
        <PageHeader title='Editar acesso' subtitle={titleCase(profissional?.nome)} />
        <AcessoForm initialValues={initialValues} onSubmit={handleSubmit} onSubmitSucceeded={handleSubmitSuccess} />
      </VFlow>
    </PageContent>
  )
}
