import { Cell, Grid } from 'bold-ui'
import { CheckboxField, Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { FormState } from 'final-form'
import { MunicipioResponsavelFiltroInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

export interface MunicipiosResponsaveisFilterProps {
  initialValues: MunicipioResponsavelFiltroInput
  onChange(values: MunicipioResponsavelFiltroInput): void
}

export const MunicipiosResponsaveisFilter = (props: MunicipiosResponsaveisFilterProps) => {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<MunicipioResponsavelFiltroInput>) => onChange(formState.values)

  const renderFilter = (formProps: FormRenderProps<MunicipioResponsavelFiltroInput>) => (
    <Grid alignItems='center' gap={1}>
      <Cell xs={12} md={6}>
        <TextField
          name='query'
          placeholder='Pesquise por município ou responsável'
          icon='zoomOutline'
          onIconClick={formProps.handleSubmit}
        />
      </Cell>
      <Cell xs={12} md={6}>
        <CheckboxField name='mostrarInativos' label='Mostrar responsáveis inativos' />
      </Cell>
      <FormDebouncedValueSpy onChange={handleChange} />
    </Grid>
  )

  return <Form<MunicipioResponsavelFiltroInput> render={renderFilter} initialValues={initialValues} />
}
