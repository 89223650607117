import { resolveName } from 'components/form/final-form/hooks/useField'
import { ParteBucalEnum, TipoParteBucalEnum } from 'graphql/types.generated'
import React, { Fragment } from 'react'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { meta } from 'view/atendimentos/atendimento-individual/model'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { OdontogramaFieldModel } from '../components/odontograma-field/OdontogramaField'
import { OutrosFieldModel } from '../components/odontograma-field/outros-field/OutrosField'
import { EvolucoesOdontologicasRootView } from '../EvolucoesOdontologicasRootView'
import { EvolucoesOdontoTable } from './EvolucoesOdontoTable'

interface EvolucoesOdontoTableWrapperProps {
  prontuarioId: ID
  cidadaoDataNascimento: LocalDate
  dataInicioAtendimento: LocalDate
  isEditingDenteOrArcada: boolean
  onEditDenteOrArcada(parteBucal: ParteBucalEnum, tipoParteBucal: TipoParteBucalEnum): void
}

export const EvolucoesOdontoTableWrapper = (props: EvolucoesOdontoTableWrapperProps) => {
  const {
    prontuarioId,
    cidadaoDataNascimento,
    dataInicioAtendimento,
    isEditingDenteOrArcada,
    onEditDenteOrArcada,
  } = props

  const {
    input: { value: currentValues },
  } = useField<OdontogramaFieldModel>(resolveName(meta.plano.odontologia.odontograma), {
    subscription: { value: true },
  })

  const {
    fields: { update: updateSupranumerarios, remove: removeSupranumerarios },
  } = useFieldArray(resolveName(meta.plano.odontologia.odontograma.dentesSupranumerarios), {
    subscription: { value: true },
  })

  const { handleRowChanged: updateOutros, removeItem: removeOutros } = useEditableListField<OutrosFieldModel>({
    name: meta.plano.odontologia.odontograma.outros,
  })

  const handleRemove = (id: ID, tipoParteBucal: TipoParteBucalEnum) => {
    if (tipoParteBucal === TipoParteBucalEnum.OUTRO) {
      const itemToRemove = currentValues?.outros?.find((outro) => outro._id === id)
      removeOutros(itemToRemove)
    }

    if (tipoParteBucal === TipoParteBucalEnum.SUPRANUMERARIO) {
      removeSupranumerarios(id)
    }
  }

  return (
    <Fragment>
      <EvolucoesOdontoTable
        prontuarioId={prontuarioId}
        valoresAtuais={currentValues}
        isEditingDenteOrArcada={isEditingDenteOrArcada}
        onEditDenteOrArcada={onEditDenteOrArcada}
        onRemove={handleRemove}
      />

      <EvolucoesOdontologicasRootView
        cidadaoDataNascimento={cidadaoDataNascimento}
        dataInicioAtendimento={dataInicioAtendimento}
        dentesSupranumerariosValues={currentValues.dentesSupranumerarios}
        outrosValues={currentValues.outros}
        onUpdateDentesSupranumerarios={updateSupranumerarios}
        onUpdateOutros={updateOutros}
      />
    </Fragment>
  )
}
