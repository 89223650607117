/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text } from 'bold-ui'
import theme from 'config/theme'

export interface LegendaCalendarioVacinalProps {
  showRegistradaNesteAtendimento?: boolean
}

export const LegendaCalendarioVacinal = ({ showRegistradaNesteAtendimento = false }: LegendaCalendarioVacinalProps) => (
  <HFlow hSpacing={3}>
    {!showRegistradaNesteAtendimento && (
      <HFlow hSpacing={0.3} alignItems='center'>
        <Icon fill='primary' icon='clockOutline' />
        <Text>Registrada neste atendimento</Text>
      </HFlow>
    )}
    <HFlow hSpacing={0.3} alignItems='center'>
      <Icon icon='calendarFilled' />
      <Text>Último aprazamento</Text>
    </HFlow>
    <HFlow hSpacing={0.3} alignItems='center'>
      <Icon icon='needleFilled' />
      <Text>Última aplicação</Text>
    </HFlow>
    <HFlow hSpacing={0.3} alignItems='center'>
      <div css={styles.aplicada} />
      <Text>Aplicada</Text>
    </HFlow>
    <HFlow hSpacing={0.3} alignItems='center'>
      <div css={styles.atrasada} />
      <Text>Atrasada</Text>
    </HFlow>
    <HFlow hSpacing={0.3} alignItems='center'>
      <div css={styles.aAplicar} />
      <Text>Disponível</Text>
    </HFlow>
    <HFlow hSpacing={0.3} alignItems='center'>
      <div css={styles.aAprazar} />
      <Text>Futura</Text>
    </HFlow>
  </HFlow>
)

const styles = {
  aplicada: css`
    width: 1rem;
    height: 1rem;
    border: 1px solid ${theme.pallete.status.success.c40};
    background-color: ${theme.pallete.status.success.c90};
  `,

  atrasada: css`
    width: 1rem;
    height: 1rem;
    border: 1px solid ${theme.pallete.status.danger.c40};
    background-color: ${theme.pallete.status.danger.c90};
  `,

  aAplicar: css`
    width: 1rem;
    height: 1rem;
    border: 1px solid ${theme.pallete.gray.c60};
  `,

  aAprazar: css`
    width: 1rem;
    height: 1rem;
    background-color: ${theme.pallete.gray.c80};
  `,
}
