/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Alert,
  Button,
  ButtonGroup,
  Cell,
  Grid,
  Heading,
  HFlow,
  InfoLabel,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  VFlow,
} from 'bold-ui'
import { useAlert } from 'components/alert'
import {
  DateField,
  ErrorField,
  Form,
  FormRenderProps,
  HorarioAgendaSelectField,
  SubmitButton,
  TextAreaField,
} from 'components/form'
import {
  LotacaoAgendaSelectField,
  LotacaoAgendaSelectFieldModel,
} from 'components/form/field/select/LotacaoAgendaSelectField/LotacaoAgendaSelectField'
import { HLabel } from 'components/HLabel'
import { Cpf, Telefone } from 'components/label'
import { GARANTIA_ACESSO_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { useFlags } from 'config/useFlagsContext'
import { addMonths, parseISO } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import { useAgendarConsultaGarantiaAcessoMutation, useGarantiaAcessoQuery } from 'graphql/hooks.generated'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { isDataAgendamentoInvalida } from 'util/atendimento/isDataAgendamentoInvalida'
import { metaPath } from 'util/metaPath'
import { v4 as uuidv4 } from 'uuid'
import { InserirVideochamadaAgendamentoSection } from 'view/agenda/components/InserirVideochamadaAgendamentoSection'
import { AgendamentoConsultaCompartilhadaModel, TipoAgendamento } from 'view/agenda/model-agenda'
import { AgendarConsultaSectionModel } from 'view/atendimentos/detail/soap/finalizacao/components/AgendarConsultaSection'
import { useVideochamadasConfiguration } from 'view/videochamada/hooks/useVideochamadasConfiguration'

import { createAgendarConsultaCalculations } from './calculator-garantiaAcesso'
import { convertModelToInput } from './converter-garantiaAcesso'
import { agendarConsultaGarantiaAcessoValidator } from './validator-garantiaAcesso'
export interface GarantiaAcessoAgendaModel {
  agendamentoConsultaInput: AgendarConsultaSectionModel
  agendamentoConsultaCompartilhadaInput: Omit<
    AgendamentoConsultaCompartilhadaModel,
    'openedAccordions' | 'cidadaoParticipante'
  >
  lotacaoResponsavel: LotacaoAgendaSelectFieldModel
}

interface UrlParams {
  garantiaAcessoId: ID
}

const name = metaPath<GarantiaAcessoAgendaModel>()

export default function GarantiaAcessoAgendarConsultaModalForm() {
  const { garantiaAcessoId } = useParams<UrlParams>()
  const { getServerTimeNow } = useServerTime()
  const { analytics } = useFirebase()
  const serverTime = getServerTimeNow()
  const history = useHistory()
  const alert = useAlert()
  const [save] = useAgendarConsultaGarantiaAcessoMutation()
  const [tipoAgendamento, setTipoAgendamento] = useState<TipoAgendamento>(TipoAgendamento.CONSULTA)
  const isConsulta = tipoAgendamento === TipoAgendamento.CONSULTA
  const isConsultaCompartilhada = tipoAgendamento === TipoAgendamento.CONSULTA_COMPARTILHADA
  const validator = useMemo(() => agendarConsultaGarantiaAcessoValidator(getServerTimeNow, isConsultaCompartilhada), [
    getServerTimeNow,
    isConsultaCompartilhada,
  ])
  const decorator = useMemo(() => createAgendarConsultaCalculations(name), [])
  const { loading: loadingConfiguracoesVideochamada, videochamadasEnabled } = useVideochamadasConfiguration()
  const [videochamadaUuid, setVideochamadaUuid] = useState<string>()
  const { PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED } = useFlags()

  const {
    data: { garantiaAcessoItemById: garantiaAcesso },
  } = useGarantiaAcessoQuery({
    variables: {
      input: garantiaAcessoId,
    },
  })

  useEffect(() => {
    if (
      garantiaAcesso?.cuidadoCompartilhadoEvolucao?.condutaAgendamento ===
        CondutaCuidadoCompartilhadoEnum.AGENDADO_TELEINTERCONSULTA_COM_CIDADAO &&
      (videochamadasEnabled || loadingConfiguracoesVideochamada)
    ) {
      setVideochamadaUuid(uuidv4())
    }
  }, [garantiaAcesso, loadingConfiguracoesVideochamada, videochamadasEnabled])

  if (!garantiaAcesso?.cidadao) {
    return null
  }

  const goBack = () => history.push(GARANTIA_ACESSO_PATH)

  const handleSubmit = (values: GarantiaAcessoAgendaModel) => {
    return save({
      variables: {
        input: { ...convertModelToInput(values, garantiaAcessoId, videochamadaUuid, isConsultaCompartilhada) },
      },
    }).then(() => {
      goBack()
      alert('success', 'Agendamento de consulta cadastrado com sucesso.')
      analytics.logEvent('agendar_cidadao_GGA')
    })
  }

  const renderForm = (formProps: FormRenderProps<GarantiaAcessoAgendaModel>) => {
    const values = formProps.values
    const lotacaoPreenchidaConsulta = !isEmpty(values?.agendamentoConsultaInput?.lotacao)
    const lotacoesPreenchidasConsultaCompartilhada =
      !isEmpty(values?.lotacaoResponsavel) && !isEmpty(values?.agendamentoConsultaCompartilhadaInput?.lotacaoConvidada)

    return (
      <Modal onClose={goBack} open size='large'>
        <ModalBody>
          <VFlow>
            <Heading level={1}>Agendar consulta</Heading>
            <Alert type='info' inline>
              Ao agendar, o registro é removido da lista.
            </Alert>
            <ErrorField name={FORM_ERROR} type='alert' inline />
            <Grid>
              <Cell size={12}>
                <Heading level={4}>Cidadão</Heading>
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold'>
                    {(garantiaAcesso.cidadao.nomeSocial ?? garantiaAcesso.cidadao.nome).titleCase()}
                  </Text>
                  <HFlow>
                    <HLabel title='CPF'>
                      <Cpf value={garantiaAcesso.cidadao?.cpf} />
                    </HLabel>
                    <HLabel title='CNS'>{garantiaAcesso.cidadao?.cns}</HLabel>
                  </HFlow>
                  <HLabel title='Telefones' style={styles.telefones}>
                    {garantiaAcesso.cidadao.telefoneContato && (
                      <Text>
                        <Telefone value={garantiaAcesso.cidadao.telefoneContato} />
                      </Text>
                    )}
                    {garantiaAcesso.cidadao.telefoneCelular && (
                      <Text>
                        <Telefone value={garantiaAcesso.cidadao.telefoneCelular} />
                      </Text>
                    )}
                    {garantiaAcesso.cidadao.telefoneResidencial && (
                      <Text>
                        <Telefone value={garantiaAcesso.cidadao.telefoneResidencial} />
                      </Text>
                    )}
                  </HLabel>
                </VFlow>
              </Cell>
              {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && (
                <Cell size={12}>
                  <ButtonGroup>
                    <Button
                      kind={isConsulta ? 'primary' : 'normal'}
                      size='small'
                      onClick={() => setTipoAgendamento(TipoAgendamento.CONSULTA)}
                    >
                      Consulta
                    </Button>
                    <Button
                      kind={isConsultaCompartilhada ? 'primary' : 'normal'}
                      size='small'
                      onClick={() => setTipoAgendamento(TipoAgendamento.CONSULTA_COMPARTILHADA)}
                    >
                      Entre profissionais
                    </Button>
                  </ButtonGroup>
                </Cell>
              )}
            </Grid>
            {isConsulta && (
              <Grid>
                <Cell size={6}>
                  <LotacaoAgendaSelectField
                    name={name.agendamentoConsultaInput.lotacao}
                    label='Profissional'
                    isAgendaAd={false}
                    loadItemsOnOpen={false}
                    somenteCadastrarAgendamento
                    required
                    title='Profissional'
                    defaultValue={garantiaAcesso.cuidadoCompartilhadoEvolucao?.lotacaoExecutante}
                  />
                </Cell>
                <Cell size={3}>
                  <DateField
                    name={name.agendamentoConsultaInput.data}
                    label='Data do agendamento'
                    required={lotacaoPreenchidaConsulta}
                    disabled={!lotacaoPreenchidaConsulta}
                    minDate={serverTime}
                    maxDate={addMonths(serverTime, 6)}
                    title='Data do agendamento'
                  />
                </Cell>
                <Cell size={3}>
                  <HorarioAgendaSelectField
                    name={name.agendamentoConsultaInput.horario}
                    lotacaoId={values?.agendamentoConsultaInput?.lotacao?.id}
                    disabled={isDataAgendamentoInvalida(values?.agendamentoConsultaInput?.data, serverTime)}
                    required={!isDataAgendamentoInvalida(values?.agendamentoConsultaInput?.data, serverTime)}
                    labels={{ inicial: 'Horário do agendamento' }}
                    dia={
                      values.agendamentoConsultaInput?.data ? parseISO(values.agendamentoConsultaInput.data) : undefined
                    }
                    title='Horário do agendamento'
                  />
                </Cell>
                <Cell size={12}>
                  <TextAreaField
                    label='Observações'
                    name={name.agendamentoConsultaInput.observacoes}
                    maxLength={200}
                    style={styles.observacao}
                    title='Observações'
                    defaultValue={
                      garantiaAcesso.cuidadoCompartilhadoEvolucao &&
                      'Agendamento originado a partir do Cuidado Compartilhado'
                    }
                  />
                </Cell>
              </Grid>
            )}
            {isConsultaCompartilhada && PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && (
              <Grid>
                <Cell size={6}>
                  <LotacaoAgendaSelectField
                    name={name.lotacaoResponsavel}
                    label='Profissional anfitrião'
                    isAgendaAd={false}
                    loadItemsOnOpen={false}
                    somenteCadastrarAgendamento
                    required
                    title='Profissional anfitrião'
                    defaultValue={garantiaAcesso.cuidadoCompartilhadoEvolucao?.lotacaoExecutante}
                  />
                </Cell>
                <Cell size={6}>
                  <InfoLabel title='E-mail'>{values?.lotacaoResponsavel?.profissional.email}</InfoLabel>
                </Cell>
                <Cell size={6}>
                  <LotacaoAgendaSelectField
                    name={name.agendamentoConsultaCompartilhadaInput.lotacaoConvidada}
                    label='Profissional convidado'
                    isAgendaAd={false}
                    loadItemsOnOpen={false}
                    somenteCadastrarAgendamento
                    required
                    title='Profissional convidado'
                    defaultValue={garantiaAcesso.cuidadoCompartilhadoEvolucao?.cuidadoCompartilhado.lotacaoSolicitante}
                  />
                </Cell>
                <Cell size={6}>
                  <InfoLabel title='E-mail'>
                    {values?.agendamentoConsultaCompartilhadaInput?.lotacaoConvidada?.profissional.email}
                  </InfoLabel>
                </Cell>
                <Cell size={3}>
                  <DateField
                    name={name.agendamentoConsultaCompartilhadaInput.data}
                    label='Data do agendamento'
                    required={lotacoesPreenchidasConsultaCompartilhada}
                    disabled={!lotacoesPreenchidasConsultaCompartilhada}
                    minDate={serverTime}
                    maxDate={addMonths(serverTime, 6)}
                    title='Data do agendamento'
                  />
                </Cell>
                <Cell size={3}>
                  <HorarioAgendaSelectField
                    name={name.agendamentoConsultaCompartilhadaInput.horario}
                    lotacaoId={values?.lotacaoResponsavel?.id}
                    outraLotacaoIdVerificarDisponibilidade={
                      values?.agendamentoConsultaCompartilhadaInput?.lotacaoConvidada?.id
                    }
                    disabled={isDataAgendamentoInvalida(
                      values?.agendamentoConsultaCompartilhadaInput?.data,
                      serverTime
                    )}
                    required={
                      !isDataAgendamentoInvalida(values?.agendamentoConsultaCompartilhadaInput?.data, serverTime)
                    }
                    labels={{ inicial: 'Horário do agendamento' }}
                    dia={
                      values.agendamentoConsultaCompartilhadaInput?.data
                        ? parseISO(values.agendamentoConsultaCompartilhadaInput.data)
                        : undefined
                    }
                    title='Horário do agendamento'
                  />
                </Cell>
                <Cell size={6}>
                  {(videochamadasEnabled || loadingConfiguracoesVideochamada) && (
                    <InserirVideochamadaAgendamentoSection
                      videochamadaUuid={videochamadaUuid}
                      onVideochamadaUuidChange={setVideochamadaUuid}
                      onRemoverVideochamadaClick={() => setVideochamadaUuid(null)}
                    />
                  )}
                </Cell>
                <Cell size={12}>
                  <TextAreaField
                    label='Observações'
                    name={name.agendamentoConsultaCompartilhadaInput.observacoes}
                    maxLength={200}
                    style={styles.observacao}
                    title='Observações'
                    defaultValue={
                      garantiaAcesso.cuidadoCompartilhadoEvolucao &&
                      'Agendamento originado a partir do Cuidado compartilhado'
                    }
                  />
                </Cell>
              </Grid>
            )}
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={goBack} title='Cancelar' size='medium' style={styles.button}>
              Cancelar
            </Button>
            <SubmitButton
              title='Salvar'
              size='medium'
              kind='primary'
              handleSubmit={formProps.handleSubmit}
              style={styles.button}
            >
              Salvar
            </SubmitButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<GarantiaAcessoAgendaModel>
      render={renderForm}
      onSubmit={handleSubmit}
      suppressNotificationError
      validate={validator}
      decorators={[decorator]}
    />
  )
}

const styles = {
  observacao: css`
    height: 5rem;
    resize: none;
  `,
  button: css`
    height: 3rem;
    width: 9rem;
  `,
  telefones: css`
    & > :last-child {
      & > :not(:last-child):after {
        content: ' | ';
      }
    }
  `,
}
