import { Mutator } from 'final-form'
import { MetaPath } from 'util/metaPath'

import { resolveName } from '../final-form/hooks/useField'

type Callback = any | ((prevValue: any) => void)

const changeValue: Mutator<any> = ([name, callback]: [MetaPath<any>, Callback], state, { changeValue }) => {
  const resolveCallback = typeof callback === 'function' ? callback : () => callback

  changeValue(state, resolveName(name), resolveCallback)
}

export default changeValue
