import { Alert, VFlow } from 'bold-ui'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useMunicipiosResponsaveisQuery } from 'graphql/hooks.generated'
import { MunicipioResponsavelFiltroInput } from 'graphql/types.generated'
import { useRouter } from 'hooks/useRouter'
import qs from 'qs'
import React, { useState } from 'react'

import { MunicipioResponsavelInsert } from './insert/MunicipioResponsavelInsert'
import { MunicipiosResponsaveisTable } from './list/MunicipiosResponsaveisTable'

export function MunicipiosResponsaveisView() {
  const [filter, setFilter] = useState<MunicipioResponsavelFiltroInput>({
    mostrarInativos: false,
    pageParams: {
      sort: ['municipio'],
    },
  })

  const { location } = useRouter()
  const urlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    parameterLimit: 5,
  })

  const { data, loading, refetch } = useMunicipiosResponsaveisQuery({
    variables: { input: filter },
  })

  const tableProps = usePagedTableProps({
    result: data && data.municipiosResponsaveis,
    onChange: setFilter,
    loading,
  })

  return (
    <VFlow vSpacing={1}>
      <Alert type='info' inline={true}>
        A partir da versão 4.0 do e-SUS APS PEC, a instalação poderá ser configurada para ser utilizada por mais de um
        município. Para adicionar outros municípios é necessário incluir um responsável municipal, que deverá ativar a
        instalação do seu município.
        <br />
        Os municípios ativos na instalação compartilham apenas os dados de cadastro e prontuário de cidadão que foram
        cadastrados na instalação ou que foram enviados para ela.
      </Alert>

      <MunicipioResponsavelInsert
        profissional={{
          id: urlParams.pId,
          nome: urlParams.pNome,
        }}
        localidade={{
          id: urlParams.localId,
          nome: urlParams.localNome,
          uf: urlParams.uf,
        }}
        onInsertCompleted={refetch}
      />

      <MunicipiosResponsaveisTable
        {...tableProps}
        initialFilter={filter}
        onFilterChange={setFilter}
        refetch={refetch}
      />
    </VFlow>
  )
}
