import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const ForoSection = () => {
  return (
    <TermosDeUsoHeadingSection title='10. Foro' id='foro'>
      <p>
        Este Termo será regido pela legislação brasileira. Qualquer reclamação ou controvérsia com base neste Termo será
        dirimida em Brasília-DF.
      </p>
      <br />
      <p>
        Sem prejuízo de qualquer outra via administrativa ou judicial disponível, todos os titulares de dados pessoais
        têm direito a apresentar reclamação à Autoridade Nacional de Proteção de Dados (ANPD).
      </p>
    </TermosDeUsoHeadingSection>
  )
}
