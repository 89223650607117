import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const DefinicoesDoTermoDeUsoSection = () => {
  return (
    <TermosDeUsoHeadingSection title='2. Definições do Termo de Uso' id='definicoes_do_termo_de_uso'>
      <p>Para os fins deste Termo de Uso, são aplicáveis as seguintes definições:</p>
      <br />
      <p>
        <b>Agente público:</b> Todo aquele que exerce, ainda que transitoriamente ou sem remuneração, por eleição,
        nomeação, designação, contratação ou qualquer outra forma de investidura ou vínculo, mandato, cargo, emprego ou
        função nos órgãos e entidades da Administração Pública, direta ou indireta.
      </p>
      <br />
      <p>
        <b>Agentes de Estado:</b> Inclui órgãos e entidades da Administração pública além dos seus agentes públicos.
      </p>
      <br />
      <p>
        <b>Códigos maliciosos:</b> São qualquer programa de computador, ou parte de um programa, construído com a
        intenção de provocar danos, obter informações não autorizadas ou interromper o funcionamento de sistemas e/ou
        redes de computadores.
      </p>
      <br />
      <p>
        <b>Sítios e aplicativos:</b> Sítios e aplicativos por meio dos quais o usuário acessa os serviços e conteúdos
        disponibilizados.
      </p>
      <br />
      <p>
        <b>Terceiro:</b> Pessoa ou entidade que não participa diretamente em um contrato, em um ato jurídico ou em um
        negócio, ou que, para além das partes envolvidas, pode ter interesse num processo jurídico.
      </p>
      <br />
      <p>
        <b>Internet:</b> Sistema constituído do conjunto de protocolos lógicos, estruturado em escala mundial para uso
        público e irrestrito, com a finalidade de possibilitar a comunicação de dados entre terminais por meio de
        diferentes redes.
      </p>
      <br />
      <p>
        <b>Usuários (ou "Usuário", quando individualmente considerado):</b> Todas as pessoas naturais que utilizarem o
        Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS (Atenção Primária à Saúde).
      </p>
    </TermosDeUsoHeadingSection>
  )
}
