import { Button, Cell, FormControl, Grid, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { CheckboxField, MunicipioSelectField, TipoLogradouroSelectField, UfSelectField } from 'components/form'
import {
  BairroSelectField,
  BairroSelectFieldModel,
  LogradouroSelectField,
  LogradouroSelectModel,
} from 'components/form/field'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { useApolloClient } from 'graphql/hooks'
import { EnderecoDocument } from 'graphql/hooks.generated'
import { EnderecoQuery, EnderecoQueryVariables } from 'graphql/types.generated'
import React, { useRef } from 'react'
import { FormRenderProps } from 'react-final-form'
import { onlyNumbers } from 'util/mask'
import { MetaPath } from 'util/metaPath'
import {
  cep,
  cepRule,
  createValidator,
  ErrorObject,
  maxLength,
  microarea,
  numeroEndereco,
  required,
  RuleMap,
  ValidateFunction,
} from 'util/validation'

import { TextField } from '../../final-form/TextField'
import { CepField } from '../CepField'
import { MunicipioSelectModel } from '../select/MunicipioSelectField'
import { PaisSelectField, PaisSelectFieldModel } from '../select/PaisSelectField'
import { TipoLogradouroSelectModel } from '../select/TipoLogradouroSelectField'
import { UfSelectModel } from '../select/UfSelectField'

export const validate = createValidator<EnderecoFieldGroupModel>(ruleMapFunction(), validateFunction())

export const validateEnderecoCompleto = createValidator<EnderecoFieldGroupModel>(
  ruleMapFunction(),
  enderecoCompletoValidateFunction()
)

function enderecoRequired(tipoLogradouro: boolean, values: EnderecoFieldGroupModel) {
  return (
    values.cep ||
    values.uf ||
    values.municipio ||
    values.bairro ||
    values.logradouro ||
    values.numero ||
    (tipoLogradouro && values.tipoLogradouro)
  )
}

function enderecoCompletoValidateFunction(): ValidateFunction<EnderecoFieldGroupModel> {
  return (values: EnderecoFieldGroupModel, errors: ErrorObject<EnderecoFieldGroupModel>) => {
    const isRequired = enderecoRequired(true, values)

    const validateFuncion = validateFunction()
    const errorsValidate = validateFuncion(values, errors)
    errorsValidate.pais = required(values.pais)
    errorsValidate.tipoLogradouro = isRequired && required(values.tipoLogradouro)

    if (values.pais && values.pais.nome !== 'BRASIL') {
      errorsValidate.municipioResidenciaExterior = required(values.municipioResidenciaExterior)
    }
    return errorsValidate
  }
}

function ruleMapFunction(): RuleMap<EnderecoFieldGroupModel> {
  return {
    cep: [cep],
    numero: [maxLength(7), numeroEndereco],
    complemento: [maxLength(30)],
    pontoReferencia: [maxLength(40)],
    municipioResidenciaExterior: [maxLength(100)],
    area: [maxLength(3)],
    microArea: [microarea],
  }
}

function validateFunction(): ValidateFunction<EnderecoFieldGroupModel> {
  return (values: EnderecoFieldGroupModel, errors: ErrorObject<EnderecoFieldGroupModel>) => {
    if (values == null) {
      return errors
    }

    const isRequired = enderecoRequired(false, values)

    const isNumeroPreenchido = Boolean(values.numero || values.semNumero)
    if (isRequired) {
      errors.uf = required(values.uf)
      errors.municipio = required(values.municipio)
      errors.bairro = required(values.bairro && values.bairro.nome)
      errors.logradouro = required(values.logradouro && values.logradouro.nome)
      if (!isNumeroPreenchido) {
        errors.numero = required(values.numero)
      }
    }
    if (values.logradouro) {
      errors.logradouro = maxLength(72)(values.logradouro.nome)
    }
    if (values.bairro) {
      errors.bairro = maxLength(72)(values.bairro.nome)
    }
    return errors
  }
}

export interface EnderecoFieldGroupModel {
  bairro?: BairroSelectFieldModel
  cep?: string
  complemento?: string
  logradouro: LogradouroSelectModel
  municipio?: MunicipioSelectModel
  numero?: string
  pontoReferencia?: string
  semNumero?: boolean
  tipoLogradouro?: TipoLogradouroSelectModel
  uf?: UfSelectModel
  pais?: PaisSelectFieldModel
  municipioResidenciaExterior?: string
  area?: string
  microArea?: string
  foraArea?: boolean
}

export interface EnderecoFieldGroupProps {
  name: MetaPath<EnderecoFieldGroupModel>
  formProps: FormRenderProps<any>
  formModelValue?: any
  renderPaisAreaMicroArea?: boolean
  renderTipoLogradouro?: boolean
}

export function EnderecoFieldGroup(props: EnderecoFieldGroupProps) {
  const { name, formProps, formModelValue, renderPaisAreaMicroArea, renderTipoLogradouro } = props
  const alert = useAlert()
  const values =
    resolveValue(formModelValue ? formModelValue : formProps.values, name) || ({} as EnderecoFieldGroupModel)

  const ufInputRef = useRef<HTMLInputElement>()
  const bairroInputRef = useRef<HTMLInputElement>()
  const numeroInputRef = useRef<HTMLInputElement>()

  const client = useApolloClient()
  const buscarCep = (variables: EnderecoQueryVariables) => {
    return client
      .query<EnderecoQuery>({ query: EnderecoDocument, variables })
      .then((response) => response.data)
  }

  const clearEstado = () => {
    formProps.form.change(name.municipio.absolutePath(), null)
    formProps.form.change(name.logradouro.absolutePath(), null)
    clearMunicipio()
  }

  const clearMunicipio = () => {
    formProps.form.change(name.bairro.absolutePath(), null)
    formProps.form.change(name.logradouro.absolutePath(), null)
    clearBairro()
  }

  const clearBairro = () => {
    formProps.form.change(name.tipoLogradouro.absolutePath(), null)
    formProps.form.change(name.logradouro.absolutePath(), null)
    formProps.form.change(name.numero.absolutePath(), null)
    formProps.form.change(name.semNumero.absolutePath(), false)
    formProps.form.change(name.complemento.absolutePath(), null)
    formProps.form.change(name.pontoReferencia.absolutePath(), null)
  }

  const handleBuscarCep = async () => {
    if (cepRule(values.cep)) {
      return
    }
    const response: EnderecoQuery = await buscarCep({ cep: values.cep })
    if (response && response.endereco) {
      const municipio: MunicipioSelectModel = { ...response.endereco.municipio, uf: response.endereco.uf }
      const bairro: BairroSelectFieldModel = response.endereco.bairro
      const logradouro = response.endereco && response.endereco.logradouro

      formProps.form.change(name.uf.absolutePath(), response.endereco.uf)
      formProps.form.change(name.municipio.absolutePath(), municipio)
      formProps.form.change(name.bairro.absolutePath(), bairro)
      formProps.form.change(name.tipoLogradouro.absolutePath(), response.endereco.tipoLogradouro)
      formProps.form.change(name.logradouro.absolutePath(), { nome: logradouro })

      if (!response.endereco.bairro) {
        bairroInputRef.current.focus()
      } else {
        numeroInputRef.current.focus()
      }
    } else {
      alert('warning', 'CEP não encontrado.')
      ufInputRef.current.focus()
    }
  }

  const isRequired = Boolean(
    values.cep ||
      values.uf ||
      values.municipio ||
      values.bairro ||
      values.tipoLogradouro ||
      values.logradouro ||
      values.numero ||
      values.semNumero
  )

  const fillLogradouro = () => {
    if (!!values.logradouro?.id) {
      formProps.form.change(name.cep.absolutePath(), values.logradouro.cep)
      formProps.form.change(name.bairro.absolutePath(), values.logradouro.bairro)
      formProps.form.change(name.tipoLogradouro.absolutePath(), values.logradouro.tipoLogradouro)
    }
  }

  return (
    <Grid wrap>
      {renderPaisAreaMicroArea && (
        <Cell size={6}>
          <PaisSelectField name={name.pais} label='País de residência' required />
        </Cell>
      )}
      {renderPaisAreaMicroArea && values.pais?.nome !== 'BRASIL' ? (
        <Cell size={6}>
          <TextField name={name.municipioResidenciaExterior} uppercase label='Município de residência' required />
        </Cell>
      ) : (
        <>
          <Cell size={5}>
            <HFlow>
              <CepField name={name.cep} label='CEP' />
              <FormControl label={<span>&nbsp;</span>}>
                <Button onClick={values.cep ? handleBuscarCep : null} size='small' type='button' kind='primary'>
                  Pesquisar
                </Button>
              </FormControl>
            </HFlow>
          </Cell>
          {!renderPaisAreaMicroArea && <Cell size={6} />}
          <Cell size={3}>
            <UfSelectField
              name={name.uf}
              label='Estado'
              onChange={clearEstado}
              required={isRequired}
              inputRef={ufInputRef}
            />
          </Cell>
          <Cell size={3}>
            <MunicipioSelectField
              name={name.municipio}
              disabled={!values.uf}
              label='Município'
              ufId={values.uf?.id}
              onChange={clearMunicipio}
              required={isRequired}
            />
          </Cell>
          <Cell size={4}>
            <BairroSelectField
              name={name.bairro}
              label='Bairro'
              onChange={clearBairro}
              municipioId={values.municipio?.id}
              ufId={values.uf?.id}
              required={isRequired}
              inputRef={bairroInputRef}
              disabled={!values.municipio}
            />
          </Cell>
          <Cell size={2} />
          {renderTipoLogradouro && (
            <Cell size={2}>
              <TipoLogradouroSelectField
                name={name.tipoLogradouro}
                label='Tipo de logradouro'
                required={isRequired}
                disabled={!values.bairro || !values.bairro.nome}
              />
            </Cell>
          )}
          <Cell size={4}>
            <LogradouroSelectField
              name={name.logradouro}
              label='Logradouro'
              disabled={!values.bairro || !values.bairro.nome}
              required={isRequired}
              municipioId={values.municipio && values.municipio.id}
              bairroId={values.bairro && values.bairro.id}
              tipoLogradouroId={values.tipoLogradouro && values.tipoLogradouro.id}
              onSelect={fillLogradouro}
            />
          </Cell>
          <Cell size={5}>
            <HFlow>
              <TextField
                name={name.numero}
                label='Número'
                disabled={values.semNumero || !values.bairro || !values.bairro.nome}
                inputRef={numeroInputRef}
                maxLength={7}
                required={isRequired}
              />
              <FormControl label={<span>&nbsp;</span>}>
                <CheckboxField
                  name={name.semNumero}
                  label='Sem número'
                  style={{ marginTop: '0.25rem' }}
                  required={isRequired}
                  disabled={!values.bairro || !values.bairro.nome}
                />
              </FormControl>
            </HFlow>
          </Cell>
          <Cell size={6}>
            <TextField
              uppercase
              name={name.complemento}
              label='Complemento'
              disabled={!values.bairro || !values.bairro.nome}
            />
          </Cell>
          <Cell size={6}>
            <TextField
              uppercase
              name={name.pontoReferencia}
              label='Ponto de referência'
              disabled={!values.bairro || !values.bairro.nome}
            />
          </Cell>
          {renderPaisAreaMicroArea && (
            <>
              <Cell size={3}>
                <TextField name={name.area} parse={onlyNumbers} maxLength={3} label='Área' disabled={values.foraArea} />
              </Cell>
              <Cell size={3}>
                <TextField
                  name={name.microArea}
                  parse={onlyNumbers}
                  maxLength={2}
                  label='Microárea'
                  disabled={values.foraArea}
                />
              </Cell>
              <Cell size={3}>
                <FormControl label={<span>&nbsp;</span>}>
                  <CheckboxField name={name.foraArea} label='Fora da área' style={{ marginTop: '0.25rem' }} />
                </FormControl>
              </Cell>
            </>
          )}
        </>
      )}
    </Grid>
  )
}

export const convertLogradouro = (logradouro: LogradouroSelectModel) => {
  const nome = logradouro?.nome
  const tituloPatente = logradouro?.tituloPatente?.nome

  return !!tituloPatente ? `${tituloPatente} ${nome}` : nome
}
