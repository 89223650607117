import { HeadingSection, HFlow, Icon, Tooltip } from 'bold-ui'
import { useHorusListViewQuery } from 'graphql/hooks.generated'
import React from 'react'

import HorusTable from './HorusTable'

export interface HorusListViewProps {
  unidadeSaudeId: ID
}

export function HorusListView(props: HorusListViewProps) {
  const { unidadeSaudeId } = props

  const { data, loading, refetch } = useHorusListViewQuery({
    variables: { unidadeSaudeId },
  })

  return (
    <HeadingSection
      level={2}
      title={
        <HFlow hSpacing={0.5} alignItems='center'>
          Unidades de saúde consultadas no Hórus
          <Tooltip
            text={
              'Os estoques de medicamentos das unidades de saúde selecionadas serão consultados' +
              ' durante a prescrição da receita. A unidade prioritária é a primeira a ser consultada'
            }
          >
            <Icon icon='infoCircleFilled' size={1} />
          </Tooltip>
        </HFlow>
      }
    >
      <HorusTable
        unidadeSaudeId={unidadeSaudeId}
        consultaHorusAtiva={data && data.conexao && data.conexao.horus.habilitado}
        reload={refetch}
        rows={(data && data.unidadesConsultaHorus) || []}
        loading={loading}
        actions={data && data.unidadeSaude && data.unidadeSaude.actions}
      />
    </HeadingSection>
  )
}
