import { FichaNotificacaoSelectModel } from 'components/form/field/select/FichasNotificacaoCasoSuspeitoSelectField'
import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import {
  CondutaEnum,
  RacionalidadeEmSaudeEnum,
  TipoAtendimentoEnum,
  TipoParticipacaoAtendimentoEnum,
} from 'graphql/types.generated'

import { LotacaoAndEstagioSelectFieldModel } from '../../../../../components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'

export interface NotificacaoCasoSuspeitoQueryModel {
  id: ID
  notificacao: FichaNotificacaoSelectModel
}

export interface ProcedimentoAtendimentoQueryModel {
  id: ID
  procedimento: ProcedimentoSelectModel
  automatico?: boolean
}

export interface FinalizacaoAtendimentoQueryModel {
  tipoAtendimento: TipoAtendimentoEnum
  condutas?: CondutaEnum[]
  racionalidadeSaude?: RacionalidadeEmSaudeEnum
  lotacaoAtendimentoCompartilhado?: LotacaoAndEstagioSelectFieldModel
  procedimentosAtendimento?: ProcedimentoAtendimentoQueryModel[]
  notificacoesCasoSuspeito?: NotificacaoCasoSuspeitoQueryModel[]
}

export const MSG_OBSERVACAO_TOOLTIP_TIPO_ATENDIMENTO =
  'Não é possível editar o campo "Tipo de atendimento" dentro de um atendimento de observação.'

export const tipoParticipacaoAtendimentoRecord: Record<TipoParticipacaoAtendimentoEnum, string> = {
  [TipoParticipacaoAtendimentoEnum.PRESENCIAL]: 'Presencial',
  [TipoParticipacaoAtendimentoEnum.CHAMADA_DE_VIDEO]: 'Chamada de vídeo',
  [TipoParticipacaoAtendimentoEnum.CHAMADA_DE_VOZ]: 'Chamada de voz',
  [TipoParticipacaoAtendimentoEnum.EMAIL]: 'E-mail',
  [TipoParticipacaoAtendimentoEnum.MENSAGEM]: 'Mensagem',
  [TipoParticipacaoAtendimentoEnum.OUTROS]: 'Outros',
  [TipoParticipacaoAtendimentoEnum.NAO_PARTICIPOU]: 'Não participou',
}
