import { useConfiguracaoCadsusQuery } from 'graphql/hooks.generated'
import { useLoginGovBr } from 'hooks/useLoginGovBr'

export const useNeedLoginGovBrToCadsus = () => {
  const { isGovBrEnabled, isAccessTokenGovBrAlive } = useLoginGovBr()
  const { data: configCadsus } = useConfiguracaoCadsusQuery()

  const allConfigs =
    configCadsus.conexao?.internet.habilitado &&
    configCadsus.conexao?.cadsus.habilitado &&
    configCadsus.configuracaoCertificadoHabilitado?.cadsusHabilitado &&
    isGovBrEnabled

  return {
    hasAllConfigs: allConfigs,
    needLoginGovBr: allConfigs && !isAccessTokenGovBrAlive,
  }
}
