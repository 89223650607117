/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DataTable, Heading, HFlow, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoAvaliacaoElegibilidadeDwQuery } from 'graphql/hooks.generated'
import { HistoricoAvaliacaoElegibilidadeDwQuery } from 'graphql/types.generated'
import { memo } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import { isEmpty } from 'util/validation/Util'
import { HistoricoDwPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'
import { mountInfosAdministrativasAtendimentoCompartilhado } from 'view/atendimentos/detail/historico/utils/utils-historico'

import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'

type AvaliacaoElegibilidadeTable = HistoricoAvaliacaoElegibilidadeDwQuery['historicoElegibilidadeDetail']['conclusao'][0]

function HistoricoAvaliacaoElegibilidadeDwPanel({
  uuidRegistro,
  cpfOuCnsCidadao,
  idadeOrigemAtendimento,
}: HistoricoDwPanelProps) {
  const {
    data: { historicoElegibilidadeDetail: detalhamento },
    loading,
  } = useHistoricoAvaliacaoElegibilidadeDwQuery({
    variables: {
      input: {
        uuidRegistro,
        cpfOuCnsCidadao,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!detalhamento) return <HistoricoNoDetailAvailable />

  const colunaDestinoConclusao = [
    {
      name: 'conclusao',
      header: detalhamento.isElegivel ? 'Elegível' : 'Inelegível',
      render: (item) => item,
      style: css`
        width: 30%;
      `,
    },
  ]

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentoCompartilhado(detalhamento)}
    >
      <VFlow vSpacing={0.5}>
        <Heading level={3}>Procedência</Heading>
        <Text>{detalhamento.procedenciaOrigem}</Text>
      </VFlow>
      {!isEmpty(detalhamento.condicoesAvaliadas) && (
        <VFlow vSpacing={0.5}>
          <Heading level={3}>Condições avaliadas</Heading>
          <Text>{stringGrouper(...detalhamento.condicoesAvaliadas)}</Text>
        </VFlow>
      )}
      <VFlow vSpacing={0.5}>
        <Heading level={3}>CID 10</Heading>
        <VFlow vSpacing={0.5}>
          <Heading level={5}>Principal</Heading>
          <Box
            style={css`
              padding: 0.8rem 1rem;
            `}
          >
            <HFlow hSpacing={0.5}>
              <Text fontWeight='bold'>
                {`${detalhamento.dimensaoCid10Principal?.nomeCid10} - ${detalhamento.dimensaoCid10Principal?.codigoCid10}`}
              </Text>
              <Text>(CID10)</Text>
            </HFlow>
          </Box>
        </VFlow>
        {detalhamento.dimensaoCid10SecundarioUm && (
          <VFlow vSpacing={0.5}>
            <Heading level={5}>Secundário 1</Heading>
            <Box
              style={css`
                padding: 0.8rem 1rem;
              `}
            >
              <HFlow hSpacing={0.5}>
                <Text fontWeight='bold'>
                  {`${detalhamento.dimensaoCid10SecundarioUm.nomeCid10} - ${detalhamento.dimensaoCid10SecundarioUm.codigoCid10}`}
                </Text>
                <Text>(CID10)</Text>
              </HFlow>
            </Box>
          </VFlow>
        )}
        {detalhamento.dimensaoCid10SecundarioDois && (
          <VFlow vSpacing={0.5}>
            <Heading level={5}>Secundário 2</Heading>
            <Box
              style={css`
                padding: 0.8rem 1rem;
              `}
            >
              <HFlow hSpacing={0.5}>
                <Text fontWeight='bold'>
                  {`${detalhamento.dimensaoCid10SecundarioDois.nomeCid10} - ${detalhamento.dimensaoCid10SecundarioDois.codigoCid10}`}
                </Text>
                <Text>(CID10)</Text>
              </HFlow>
            </Box>
          </VFlow>
        )}
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={3}>Conclusão</Heading>
        <Text>{detalhamento.dimensaoModalidadeAD.descricao}</Text>
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={5}>Destino</Heading>
        {!isEmpty(detalhamento.conclusao) && (
          <DataTable<AvaliacaoElegibilidadeTable> rows={detalhamento.conclusao} columns={colunaDestinoConclusao} />
        )}
      </VFlow>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoAvaliacaoElegibilidadeDwPanel)
