/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Icons, Tag, Theme, useTheme } from 'bold-ui'
import { Interpolation } from 'emotion'
import { useMemo } from 'react'

type TagType = 'normal' | 'danger' | 'info' | 'success' | 'alert'

interface StatusSolicitacaoTagProps {
  type: TagType
  icon: Icons
  children: React.ReactNode
}

export default function StatusSoliticacaoTag(props: StatusSolicitacaoTagProps) {
  const { type, icon, children, ...rest } = props
  const theme = useTheme()
  const typeStyle = useMemo(() => createTypesStyles(theme)[type], [theme, type])

  return (
    <Tag icon={icon} style={typeStyle.tagStyle} {...rest}>
      {children}
    </Tag>
  )
}

export const createTypesStyles = (
  theme: Theme
): {
  [key in TagType]: {
    tagStyle: Interpolation
  }
} => {
  return {
    normal: {
      tagStyle: css`
        background: ${theme.pallete.gray.c90};
        color: ${theme.pallete.gray.c40};
      `,
    },
    danger: {
      tagStyle: css`
        background: ${theme.pallete.status.danger.background};
        color: ${theme.pallete.status.danger.main};
      `,
    },
    info: {
      tagStyle: css`
        background: ${theme.pallete.status.info.background};
        color: ${theme.pallete.status.info.main};
      `,
    },
    success: {
      tagStyle: css`
        background: ${theme.pallete.status.success.background};
        color: ${theme.pallete.status.success.main};
      `,
    },
    alert: {
      tagStyle: css`
        background: ${theme.pallete.status.alert.background};
        color: ${theme.pallete.status.alert.main};
      `,
    },
  }
}
