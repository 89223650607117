import { parseISO } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import { SituacaoProblema } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import {
  afterBirthdateAndBeforeEqualToAtendimentoDate,
  afterEqualTo,
  createValidator,
  ErrorObject,
  range,
  required,
} from 'util/validation'
import { isValid } from 'util/validation/Util'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { Problema } from '../aside/types/ProblemaModel'
import { AvaliacaoFormModel } from '../avaliacao/AvaliacaoForm'
import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import { findProblemaComCiapW78 } from '../avaliacao/components/problemas-condicoes/utils/operations-problemasCondicoes'
import {
  hasProblemaCondicaoDePreNatal,
  hasProblemaNaoEvolucaoEncerraGestacao,
  isEvolucao,
  isProblemaCondicaoComCiapW78,
  isProblemaCondicaoDeGestacaoOrEncerraGestacao,
  isProblemaCondicaoDePreNatal,
  isProblemaCondicaoEncerraGestacao,
  isProblemaCondicaoGravidezAltoRisco,
  isSituacaoProblemaResolvidoDesabilitadaPreNatal,
} from '../avaliacao/components/problemas-condicoes/utils/verifications-problemasCondicoes'
import { ObjetivoFormModel } from '../objetivo'
import { FormAtivoObjetivoEnum } from '../objetivo/components/SwitchButtonObjetivoForm'
import {
  EncerrarGestacaoFormModel,
  LIMITE_DIFERENCA_ENTRE_DUM_DATA_ATENDIMENTO_EM_DIAS,
  PreNatalFormModel,
  TipoPreNatal,
} from './model-preNatal'
import { isDumForaDoLimitePermitido } from './util-preNatal'

export const preNatalValidator = createValidator<PreNatalFormModel>({
  alturaUterina: [range(7, 99)],
  batimentoCardiacoFetal: [range(0, 999)],
})

export function validateEncerrarGestacao(
  values: AvaliacaoFormModel,
  problemasAtivosOuLatentesAntigos: Problema[],
  errors: ErrorObject<EncerrarGestacaoFormModel> = {},
  dataNascimentoCidadao: string,
  dataAtendimento: Instant,
  hasPermissionPreNatal: boolean,
  preNatalAtivoHasTipoGravidez: boolean
): ErrorObject<EncerrarGestacaoFormModel> {
  if (hasPermissionPreNatal && hasProblemaNaoEvolucaoEncerraGestacao(values?.problemasECondicoes)) {
    const dataDesfecho = values?.encerrarGestacao?.dataDesfecho

    if (dataDesfecho) {
      errors.dataDesfecho = afterBirthdateAndBeforeEqualToAtendimentoDate(
        dataDesfecho,
        dataNascimentoCidadao,
        dataAtendimento
      )
      const dataInicioGravidez = findProblemaComCiapW78(problemasAtivosOuLatentesAntigos)?.ultimaEvolucao?.dataInicio
      if (dataInicioGravidez && isValid(errors.dataDesfecho)) {
        errors.dataDesfecho = afterEqualTo(
          dataDesfecho,
          dateAsYyyyMmDd(parseISO(dataInicioGravidez)),
          'data de início da condição de gravidez'
        )
      }
    } else {
      errors.dataDesfecho = required(dataDesfecho)
    }

    if (preNatalAtivoHasTipoGravidez) {
      errors.tipoGravidez = required(values?.encerrarGestacao?.tipoGravidez)
    }
  }
  return errors
}

export function validateDumPreNatal(
  hasPermissionPreNatal: boolean,
  isGestante: boolean,
  ultimaDum: LocalDate,
  hasProblemaComCiapW78AtivoPersistido: boolean,
  value: SoapState,
  dataAtendimento: Instant,
  errors: ErrorObject<ObjetivoFormModel>
): ErrorObject<ObjetivoFormModel> {
  if (hasPermissionPreNatal) {
    const avaliacaoHasProblemaDePreNatal = hasProblemaCondicaoDePreNatal(
      value.avaliacao?.problemasECondicoes,
      hasProblemaComCiapW78AtivoPersistido
    )
    const isPrimeiroAtendimentoPreNatal = avaliacaoHasProblemaDePreNatal && !isGestante
    const isCidadaSemDum = isUndefinedOrNull(ultimaDum)
    const isContinuacaoAtendimentoPreNatal = avaliacaoHasProblemaDePreNatal && isGestante
    const isDumRequired = isPrimeiroAtendimentoPreNatal || (isContinuacaoAtendimentoPreNatal && isCidadaSemDum)

    if (isDumRequired && isValid(errors?.dum)) {
      errors = { ...errors, dum: required(value.objetivo?.dum) }
    }

    if (
      isValid(errors?.dum) &&
      avaliacaoHasProblemaDePreNatal &&
      isDumForaDoLimitePermitido(dataAtendimento, value.objetivo?.dum)
    ) {
      errors = {
        ...errors,
        dum: `Não é possível registrar DUM anterior a ${LIMITE_DIFERENCA_ENTRE_DUM_DATA_ATENDIMENTO_EM_DIAS} dias da data do atendimento`,
      }
    }
  }

  return errors
}

export function validateProblemaCondicaoPreNatal(
  value: ProblemaCondicaoModel,
  errors: ErrorObject<ProblemaCondicaoModel>,
  isEdicao: boolean,
  isMasculinoAndNotHaveIdentidadeGenero: boolean,
  hasProblemaComCiapW78AtivoPersistido: boolean,
  initialValues?: ProblemaCondicaoModel,
  isLPC?: boolean,
  tipoPreNatal?: TipoPreNatal
): ErrorObject<ProblemaCondicaoModel> {
  if (
    isProblemaCondicaoDePreNatal(value, hasProblemaComCiapW78AtivoPersistido) &&
    value?.incluirNaListaProblemas === false
  ) {
    errors[FORM_ERROR] = 'É obrigatorio incluir gravidez na lista de problemas/condições.'
  }

  if (isProblemaCondicaoComCiapW78(value)) {
    if (value?.situacaoProblema === SituacaoProblema.LATENTE) {
      errors.situacaoProblema = 'Não é possível cadastrar uma condição de gravidez com situação latente.'
    } else if (value?.situacaoProblema === SituacaoProblema.RESOLVIDO) {
      if (isUndefinedOrNull(value?.dataFim?.data)) errors.dataFim = { data: required(value?.dataFim?.data) }
      if (isSituacaoProblemaResolvidoDesabilitadaPreNatal(true, isEvolucao(value), isEdicao, isLPC, tipoPreNatal)) {
        errors.situacaoProblema = 'Não é possível cadastrar uma condição de gravidez com a situação resolvido.'
      }
    }
  }

  if (isProblemaCondicaoEncerraGestacao(value) && isProblemaCondicaoGravidezAltoRisco(value) && !isLPC) {
    errors[FORM_ERROR] = 'Não é possível informar condição de desfecho com condição de alto risco.'
  }

  if (isProblemaCondicaoDeGestacaoOrEncerraGestacao(value) && isMasculinoAndNotHaveIdentidadeGenero) {
    errors[FORM_ERROR] =
      'Para inserir condição de gestação em cidadão do sexo masculino é necessário informar a identidade de gênero.'
  }

  errors = validateProblemaCondicaoAltoRiscoPreNatal(value, errors, isEdicao, initialValues, isLPC)

  return errors
}

export function validateProblemaCondicaoAltoRiscoPreNatal(
  value: ProblemaCondicaoModel,
  errors: ErrorObject<ProblemaCondicaoModel>,
  isEdicao: boolean,
  initialValues?: ProblemaCondicaoModel,
  isLPC?: boolean
): ErrorObject<ProblemaCondicaoModel> {
  const isProblemaAltoRisco = isProblemaCondicaoGravidezAltoRisco(value)
  const isProblemaAltoRiscoEmEdicaoNaAvaliacao = isProblemaAltoRisco && isEdicao && !isLPC

  const wasSituacaoProblemaResolvido = initialValues?.situacaoProblema === SituacaoProblema.RESOLVIDO
  const isSituacaoProblemaResolvido = value?.situacaoProblema === SituacaoProblema.RESOLVIDO

  const hasSituacaoProblemaChanged = wasSituacaoProblemaResolvido !== isSituacaoProblemaResolvido

  if (isProblemaAltoRiscoEmEdicaoNaAvaliacao && hasSituacaoProblemaChanged) {
    errors.situacaoProblema = 'Não é possível editar a situação de uma condição alto risco.'
  }

  return errors
}

export function validateTipoGravidezPreNatalForm(
  preNatalAtivoHasTipoGravidez: boolean,
  values: SoapState,
  errors: ErrorObject<PreNatalFormModel>
): ErrorObject<PreNatalFormModel> {
  const isContinuacaoPreNatal = values?.objetivo?.atendimentosEspecificos?.formAtivo === FormAtivoObjetivoEnum.PRE_NATAL
  const isFormEnabled = !isUndefinedOrNull(values?.preNatal)

  if (isContinuacaoPreNatal && preNatalAtivoHasTipoGravidez && isFormEnabled) {
    errors = {
      ...errors,
      tipoGravidez: required(values?.preNatal?.tipoGravidez),
    }
  }

  return errors
}
