import { SideMenu } from 'components/side-menu-navegation/SideMenu'
import { SideMenuSublink } from 'components/side-menu-navegation/SideMenuSublink'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useState } from 'react'
import React from 'react'
import { Accordion } from 'react-accessible-accordion'

import { SideMenuAccordionItem } from '../../../components/side-menu-navegation/SideMenuAccordionItem'
import { SIDE_MENU_ITEMS, SideMenuItem } from './TermosDeUsoSideMenuItens'

const ALTURA_CABECALHO = 310
const ALTURA_CABECALHO_TO_MENU = 340
const MAX_WIDTH = 272

export const TermosDeUsoSideMenu = () => {
  const { analytics } = useFirebase()

  const handleUserClickOnAccordion = () => analytics.logEvent('user_click_on_side_menu_accordion_termos_de_uso')

  const [selectedSession, setSelectedSession] = useState<string>()

  const handleItemClick = (item: SideMenuItem) => {
    setSelectedSession(item.title)
  }

  return (
    <SideMenu alturaCabecalho={ALTURA_CABECALHO} alturaCabecalhoToMenu={ALTURA_CABECALHO_TO_MENU} maxWidth={MAX_WIDTH}>
      <Accordion allowZeroExpanded allowMultipleExpanded>
        {SIDE_MENU_ITEMS.map((item, index) => {
          return (
            <SideMenuAccordionItem
              title={item.title}
              index={index}
              totalItems={SIDE_MENU_ITEMS.length}
              onClickAccordionItem={handleUserClickOnAccordion}
              onClickAccordionButton={() => handleItemClick(item)}
              isMenuActive={selectedSession === item.title}
              key={item.title}
            >
              {item.children.map((subItem) => {
                return <SideMenuSublink to={subItem.to} key={subItem.title} title={subItem.title} />
              })}
            </SideMenuAccordionItem>
          )
        })}
      </Accordion>
    </SideMenu>
  )
}
