import { ButtonProps, IconProps, modal } from 'bold-ui'
import { ModalAutoRenderProps } from 'bold-ui/lib/components/Modal/auto/ModalAuto'
import { ModalDepthLevel, ModalSize } from 'bold-ui/lib/components/Modal/Modal'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { ModalBody } from 'components/modals/ModalBody'
import React from 'react'

interface ConfirmConfig {
  size?: ModalSize
  confirmLabel?: string
  cancelLabel?: string
  onCancel?(e: React.SyntheticEvent): any
  onClose?(): any
  onConfirm?(e: React.SyntheticEvent): any
  depthLevel?: ModalDepthLevel
  manageOverflow?: boolean
}

export interface ConfirmConfigDefaultRender extends ConfirmConfig {
  body?: React.ReactNode
  title: React.ReactNode
  type?: ButtonProps['kind']
  icon?: IconProps['icon']
}

interface ConfirmConfigCustomRender extends ConfirmConfig {
  render(renderProps: ModalAutoRenderProps): React.ReactNode
}

const defaultConfig: ConfirmConfigDefaultRender = {
  body: null,
  title: 'Confirmação',
  size: 'small',
  cancelLabel: 'Cancelar',
  confirmLabel: 'Confirmar',
  type: 'primary',
  onCancel: () => null,
  onConfirm: () => null,
}

const icons: { [key in ButtonProps['kind']]: IconProps['icon'] } = {
  normal: 'questionMarkOutline',
  primary: 'questionMarkOutline',
  danger: 'exclamationTriangleOutline',
}

export const confirm = (config: ConfirmConfigCustomRender | ConfirmConfigDefaultRender) => {
  const {
    title,
    body,
    size,
    onCancel,
    onConfirm,
    confirmLabel,
    cancelLabel,
    type,
    icon = icons[type],
    onClose,
    depthLevel,
    manageOverflow,
    render = () => (
      <ModalBody title={title} icon={icon} type={type}>
        {body}
      </ModalBody>
    ),
  } = {
    ...defaultConfig,
    ...config,
  }

  return () =>
    new Promise<boolean>((resolve) => {
      const handleResolve = (confirm: boolean) => () => resolve(confirm)

      modal({
        size,
        onClose: composeHandlers(onClose, handleResolve(false)),
        render,
        depthLevel,
        manageOverflow,
        actions: [
          { label: cancelLabel, onClick: composeHandlers(onCancel, handleResolve(false)) },
          { label: confirmLabel, kind: type, onClick: composeHandlers(onConfirm, handleResolve(true)) },
        ],
      })()
    })
}
