import { TipoAtendimentoProfissional } from 'graphql/types.generated'

import { TipoAtendimentoFooter } from '../model'

const DEFAULT_BODY_MESSAGE = 'As alterações realizadas serão perdidas.'
const OBSERVACAO_BODY_MESSAGE =
  'Os registros anteriores deste atendimento de observação não serão alterados, somente os registros feitos neste contato com o cidadão serão cancelados.'

interface AtendimentoFooterMessages {
  confirmTitle: string
  labelButton: string
  body: string
}

const atendimentoIndividual: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar o atendimento individual?',
  labelButton: 'Cancelar atendimento individual',
  body: DEFAULT_BODY_MESSAGE,
}

const atendimentoProcedimentos: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar o atendimento de procedimentos?',
  labelButton: 'Cancelar atendimento de procedimentos',
  body: DEFAULT_BODY_MESSAGE,
}

const atendimentoOdontologico: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar o atendimento odontológico?',
  labelButton: 'Cancelar atendimento odontológico',
  body: DEFAULT_BODY_MESSAGE,
}

const escutaInicial: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar a escuta inicial?',
  labelButton: 'Cancelar escuta inicial',
  body: DEFAULT_BODY_MESSAGE,
}
const preAtendimento: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar o pré-atendimento?',
  labelButton: 'Cancelar pré-atendimento',
  body: DEFAULT_BODY_MESSAGE,
}

const atendimentoVacinacao: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar o atendimento de vacinação?',
  labelButton: 'Cancelar atendimento de vacinação',
  body: DEFAULT_BODY_MESSAGE,
}

const atendimentoObservacaoIndividual: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar os registros deste atendimento individual?',
  labelButton: 'Cancelar atendimento individual',
  body: OBSERVACAO_BODY_MESSAGE,
}

const atendimentoObsercavaoProcedimentos: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar os registros deste atendimento de procedimentos?',
  labelButton: 'Cancelar atendimento de procedimentos',
  body: OBSERVACAO_BODY_MESSAGE,
}

export const atendimentoFooterMessage = (
  tipo: TipoAtendimentoFooter,
  isAtendimentoObservacao: boolean = false,
  isResponsavelPelaObservacao: boolean = false
): AtendimentoFooterMessages => {
  switch (tipo) {
    case TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO:
      return atendimentoOdontologico
    case TipoAtendimentoProfissional.ESCUTA_INICIAL:
      return escutaInicial
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS:
      return atendimentoProcedimentos
    case 'PRE_ATENDIMENTO':
      return preAtendimento
    case TipoAtendimentoProfissional.VACINACAO:
      return atendimentoVacinacao
    case TipoAtendimentoProfissional.CONSULTA:
    default: {
      if (isAtendimentoObservacao) {
        if (!isResponsavelPelaObservacao) {
          return atendimentoObsercavaoProcedimentos
        }

        return atendimentoObservacaoIndividual
      }

      return atendimentoIndividual
    }
  }
}
