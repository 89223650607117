import { Icons, useTheme } from 'bold-ui'
import React from 'react'

import { IconLabel } from './IconLabel'

export interface StatusInformationProps {
  icon?: Icons
  color?: string
  text: string
  textColor?: string
}

export function StatusInformation(props: StatusInformationProps) {
  const theme = useTheme()
  const { text, icon = 'infoCircleOutline', color = theme.pallete.primary.c40, textColor } = props
  return <IconLabel text={text} icon={icon} color={color} textColor={textColor} />
}
