import { QueryResult } from '@apollo/react-common'
import { Button, Icon, PagedTable, Text, Tooltip, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { useErrorHandler } from 'components/error'
import { HLabel } from 'components/HLabel'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import * as Types from 'graphql/types.generated'
import { TransmissaoRecebimentoLotesQuery } from 'graphql/types.generated'
import { rest } from 'lodash'
import React, { Dispatch, SetStateAction } from 'react'

import { downloadRelatorioInconsistencia } from '../components/downloadRelatorioInconsistencia'
import {
  TransmissaoRecebimentoLoteFilter,
  TransmissaoRecebimentoLoteFilterModel,
} from './TransmissaoRecebimentoLoteFilter'

type TransmissaoRecebimentoLotesItem = TransmissaoRecebimentoLotesQuery['transmissaoRecebimentoLotes']['content'][0]

interface TransmissaoRecebimentoLoteTableProps {
  queryResult: QueryResult<Types.TransmissaoRecebimentoLotesQuery, Types.TransmissaoRecebimentoLotesQueryVariables>
  filter: TransmissaoRecebimentoLoteFilterModel
  setFilter: Dispatch<SetStateAction<TransmissaoRecebimentoLoteFilterModel>>
  resetFilter: () => void
}

export const TransmissaoRecebimentoLoteTable = (props: TransmissaoRecebimentoLoteTableProps) => {
  const {
    filter,
    setFilter,
    queryResult: { data, loading },
    resetFilter,
  } = props

  const handleRejection = useErrorHandler()

  const pagedTableProps = usePagedTableProps({
    result: data && data.transmissaoRecebimentoLotes,
    loading,
    onChange: setFilter,
  })

  const renderBtnGerarRelatorio = ({ id, possuiInconsistencia }: TransmissaoRecebimentoLotesItem) => {
    const formData = new FormData()
    formData.append('loteId', id)
    const gerarRelatorio = () => downloadRelatorioInconsistencia(formData).catch(handleRejection)
    return (
      <Tooltip text={!possuiInconsistencia && 'Lote não possui inconsistências'}>
        <Button skin='ghost' size='small' disabled={!possuiInconsistencia} onClick={gerarRelatorio}>
          <Icon icon='printerOutline' />
        </Button>
      </Tooltip>
    )
  }

  return (
    <TableBox
      header={<TransmissaoRecebimentoLoteFilter filter={filter} setFilter={setFilter} resetFilter={resetFilter} />}
    >
      <PagedTable<TransmissaoRecebimentoLotesItem>
        {...pagedTableProps}
        columns={[
          { name: 'numLoteOriginadora', header: 'Lote', render: renderLote, sortable: true },
          { name: 'origemLote', header: 'Origem do lote', render: renderOrigemLote },
          {
            name: 'nomeResponsavel',
            header: 'Responsável pelo envio',
            render: renderResponsavel,
            sortable: true,
          },
          { name: 'dataRecebimento', header: 'Data', render: renderData, sortable: true },
          {
            name: 'fichasTipo',
            header: 'Fichas válidas/inválidas',
            render: renderFichasTipo,
            style: { textAlign: 'right' },
          },
          {
            name: 'fichasRepetidas',
            header: 'Fichas repetidas',
            render: renderFichasRepetidas,
            style: { textAlign: 'right' },
          },
          {
            name: 'totalFichas',
            header: 'Total',
            render: renderTotalFichas,
            style: { textAlign: 'right' },
          },
          {
            name: 'btnGerarRelatorio',
            render: renderBtnGerarRelatorio,
            style: { textAlign: 'right' },
          },
        ]}
        {...rest}
      />
    </TableBox>
  )
}

const renderLote = (row: TransmissaoRecebimentoLotesItem) => <Text>{row.numeroLote || '-'}</Text>

const renderOrigemLote = (row: TransmissaoRecebimentoLotesItem) => <Text>{row.origemLote.descricao}</Text>

const renderResponsavel = (row: TransmissaoRecebimentoLotesItem) =>
  row.credencialRecebimento?.id ? (
    <VFlow vSpacing={0}>
      <HLabel title='Credencial:'>{row.credencialRecebimento.nomeResponsavel}</HLabel>
      <HLabel title='Enviado por:'>{row.responsavel}</HLabel>
    </VFlow>
  ) : (
    <HLabel title='Enviado por:'>{row.responsavel}</HLabel>
  )

const renderTotalFichas = (row: TransmissaoRecebimentoLotesItem) => <Text>{row.counts.totalFichas}</Text>
const renderData = (row: TransmissaoRecebimentoLotesItem) => <DateTime value={row.data} />
const renderFichasRepetidas = (row: TransmissaoRecebimentoLotesItem) => <Text>{row.counts.fichasRepetidas ?? '-'}</Text>
const renderFichasTipo = (row: TransmissaoRecebimentoLotesItem) => {
  const validas = row.counts.fichasValidas ?? '-'
  const invalidas = row.counts.fichasInvalidas ?? '-'
  return (
    <Text style={{ textAlign: 'right' }}>
      {validas}/{invalidas}
    </Text>
  )
}
