import { Button, Cell, FormControl, Grid, Heading, Icon, Tooltip, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { Box } from 'components/Box'
import { useErrorHandler } from 'components/error'
import { Form, NumberField } from 'components/form'
import { FormPrompt } from 'components/form/FormPrompt'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import clearTypename from 'graphql/clearTypename'
import { useApolloClient } from 'graphql/hooks'
import {
  CfgLotacaoAgendaOnlineDocument,
  useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation,
} from 'graphql/hooks.generated'
import { CfgLotacaoAgendaOnlineQuery, DuracaoAtendimentoPadraoMunicipioInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, range, required } from 'util/validation'

export const duracaoAtendimentoValidator = createValidator<DuracaoAtendimentoPadraoMunicipioInput>({
  duracaoAtendimentoPadraoMunicipio: [required, range(5, 90)],
})

const duracaoPadraoPath = metaPath<DuracaoAtendimentoPadraoMunicipioInput>()

export interface ConfiguracaoDuracaoAtendimentoPanelProps {
  duracaoAtendimentoPadraoMunicipioInput: DuracaoAtendimentoPadraoMunicipioInput
}

export function ConfiguracaoDuracaoAtendimentoPanel(props: ConfiguracaoDuracaoAtendimentoPanelProps) {
  const [saveCfgDuracaoAtendimentoMutation] = useSalvarConfiguracaoDuracaoAtendimentoMunicipioMutation()

  const apolloClient = useApolloClient()
  const handleRejection = useErrorHandler()

  const handleDuracaoPadraoSubmit = (duracaoInput: DuracaoAtendimentoPadraoMunicipioInput, formApi: FormApi) => {
    const { duracaoAtendimentoPadraoMunicipio: oldDuracao } = formApi.getState()
      .initialValues as DuracaoAtendimentoPadraoMunicipioInput
    if (oldDuracao === +duracaoInput.duracaoAtendimentoPadraoMunicipio) {
      alert('success', 'Duração padrão do atendimento salva com sucesso.')
      return
    }
    apolloClient
      .query<CfgLotacaoAgendaOnlineQuery>({
        query: CfgLotacaoAgendaOnlineDocument,
        fetchPolicy: 'network-only',
      })
      .then((ret) => {
        const qtdLotacoesDuracaoPadrao = ret.data.conexao.lotacoesAgendaOnlineComDuracaoPadrao

        if (qtdLotacoesDuracaoPadrao > 0) {
          const infoMsg =
            qtdLotacoesDuracaoPadrao > 1
              ? `Todas as ` +
                qtdLotacoesDuracaoPadrao +
                ` lotações com configuração de agenda online que utilizam` +
                ` a duração padrão de agendamento terão sua configuração de agenda online excluída.`
              : `A lotação que utiliza a duração padrão de agendamento terá sua configuração de agenda online excluída.`

          const title =
            qtdLotacoesDuracaoPadrao > 1
              ? `Deseja confirmar a exclusão das configurações de agenda online?`
              : `Deseja confirmar a exclusão da configuração de agenda online?`

          confirm({
            title,
            body:
              `Alterar a duração do agendamento pode causar inconsistências nas configurações ` +
              `de horários disponibilizados para agenda online. ` +
              infoMsg,
            confirmLabel: 'Sim',
            cancelLabel: 'Não',
            type: 'primary',
            onConfirm: () => {
              doSaveDuracaoAtendimento(duracaoInput)
            },
          })()
        } else {
          doSaveDuracaoAtendimento(duracaoInput)
        }
      })
      .catch(handleRejection)
  }

  const doSaveDuracaoAtendimento = (duracaoInput: DuracaoAtendimentoPadraoMunicipioInput) => {
    return saveCfgDuracaoAtendimentoMutation({
      variables: {
        duracaoAtendimentoPadraoMunicipioInput: clearTypename(duracaoInput),
      },
    }).then((ret) => {
      alert('success', 'Duração padrão do atendimento salva com sucesso.')
    })
  }

  const renderDuracaoAtendimentoPanel = (formRenderProps: FormRenderProps) => {
    return (
      <VFlow vSpacing={1}>
        <FormPrompt />
        <Heading level={2}>
          Duração do agendamento
          <Tooltip
            text={
              'Lotações com CBOs que possuem tempo de duração do agendamento específicos ' +
              'não utilizam o tempo de duração do agendamento padrão.'
            }
          >
            <Icon icon='infoCircleFilled' size={1} style={{ marginLeft: '4px' }} />
          </Tooltip>
        </Heading>
        <Box>
          <Grid gap={1}>
            <Cell size={3}>
              <NumberField
                clearable={false}
                name={duracaoPadraoPath.duracaoAtendimentoPadraoMunicipio}
                label='Duração do agendamento (min)'
                maxLength={2}
              />
            </Cell>
            <Cell>
              <FormControl label={<span>&nbsp;</span>}>
                <Button
                  style={{ verticalAlign: 'bottom' }}
                  size='small'
                  kind='primary'
                  disabled={
                    (!formRenderProps.submitSucceeded && !formRenderProps.dirty) ||
                    (formRenderProps.submitSucceeded && !formRenderProps.dirtySinceLastSubmit)
                  }
                  onClick={formRenderProps.handleSubmit}
                >
                  Salvar
                </Button>
              </FormControl>
            </Cell>
          </Grid>
        </Box>
      </VFlow>
    )
  }

  return (
    <Form
      render={renderDuracaoAtendimentoPanel}
      initialValues={props.duracaoAtendimentoPadraoMunicipioInput}
      validate={duracaoAtendimentoValidator}
      validateOnBlur={true}
      onSubmit={handleDuracaoPadraoSubmit}
    />
  )
}
