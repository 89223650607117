import { AgendaEvent } from 'components/agenda'
import { AgendaEventProps } from 'components/agenda/AgendaEventWrapper'
import React from 'react'
import { EventoAgendaLotacao, TipoAgendamento } from 'view/agenda/model-agenda'

import { EventoAgendamentoConsulta } from './EventoAgendamentoConsulta'
import { EventoAgendamentoReserva } from './EventoAgendamentoReserva'
import { FechamentoAgenda } from './EventoFechamentoAgenda'

export interface EventoAgendamentoProps extends AgendaEventProps<EventoAgendaLotacao> {
  isAgendaOnlineAtiva: boolean
  getServerTimeNow?: () => Date
  lotacaoAgendadaId: ID
}

export const EventoAgendamento = (props: EventoAgendamentoProps) => {
  switch (props.event.tipo) {
    case TipoAgendamento.FECHAMENTO:
      return <FechamentoAgenda {...props} />
    case TipoAgendamento.CONSULTA:
      return <EventoAgendamentoConsulta {...props} />
    case TipoAgendamento.RESERVA:
      return <EventoAgendamentoReserva {...props} />
    default:
      return <AgendaEvent {...props} />
  }
}
