import { Icons, useStyles } from 'bold-ui'
import { IconMap } from 'bold-ui/lib/components/Icon/generated/types'
import { getIconColor, IconProps } from 'bold-ui/lib/components/Icon/Icon'
import { Set } from 'immutable'
import React from 'react'

// TODO@RNG: Remover esse componente quando a funcionalidade de aceitar svg for implementada no componente Icon do bold
// issue: https://github.com/laboratoriobridge/bold/issues/821

export interface PecIconProps extends Omit<IconProps, 'icon'> {
  icon: Icons | React.ComponentType<React.SVGProps<SVGSVGElement>>
}

const IconKeysMap = Set(Object.keys(IconMap))

export const isIcon = (icon: any): icon is Icons => typeof icon === 'string' && IconKeysMap.contains(icon)

export function PecIcon(props: PecIconProps) {
  const { style, icon, fill, stroke, size = 1.5, ...rest } = props
  const SelectedIcon = isIcon(icon) ? IconMap[icon] : icon

  const { classes, css } = useStyles((theme) => ({
    icon: {
      fill: fill ? getIconColor(theme, fill) : 'currentColor',
      stroke: stroke && getIconColor(theme, stroke),
      fontSize: size && size + 'rem',
    },
  }))

  return !!SelectedIcon ? (
    <SelectedIcon role='img' aria-hidden='true' className={css(classes.icon, style)} {...rest} />
  ) : null
}
