import { Button, Cell, Grid, HFlow, Icon, Tooltip, useStyles } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Form, FormDebouncedValueSpy, SwitchField, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { isEmpty, isEqual } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Permissions from 'types/Permissions'
import { metaPath } from 'util/metaPath'
import { ListaAtendimentoInlineFilterModel } from 'view/atendimentos/list/filter/model'

import { createFilterStyles } from '../../atendimentos/list/listaUtils'
import { ListaRegistroTardioFilterPopperModel } from './ListaRegistroTardioFilterPopperForm'
import { ListaRegistroTardioFilterPopperForm } from './ListaRegistroTardioFilterPopperForm'
import { ListaRegistroTardioFilterTags } from './ListaRegistroTardioFilterTags'
import { ListaRegistroTardioSortDropdown } from './ListaRegistroTardioSortDropdown'

interface ListaRegistroTardioFilterProps {
  filter: ListaRegistroTardioFilterModel
  filterBackup: ListaRegistroTardioFilterPopperModel
  filterDefault: ListaRegistroTardioFilterModel
  onChangeFilter(values: ListaRegistroTardioFilterModel): void
  onClear(): void
  updateFilterBackup(values: ListaRegistroTardioFilterPopperModel): void
  deleteFilterBackup(): void
}

export type ListaRegistroTardioFilterModel = ListaRegistroTardioInlineFilterModel & ListaRegistroTardioFilterPopperModel

interface ListaRegistroTardioInlineFilterModel extends Omit<ListaAtendimentoInlineFilterModel, 'registroTardio'> {
  query?: string
  somenteMeusAtendimentos?: boolean
}

const mountInitialValues = (values: ListaRegistroTardioFilterPopperModel): ListaRegistroTardioFilterModel => {
  return {
    ...values,
    periodo: {
      startDate: values.periodo?.startDate ? new Date(values.periodo?.startDate) : undefined,
      endDate: values.periodo?.endDate ? new Date(values.periodo?.endDate) : undefined,
    },
  }
}

export function ListaRegistroTardioFilter(props: ListaRegistroTardioFilterProps) {
  const { filter, filterDefault, filterBackup, onChangeFilter, updateFilterBackup, deleteFilterBackup, onClear } = props
  const path = metaPath<ListaRegistroTardioInlineFilterModel>()

  const { query, somenteMeusAtendimentos, ...restFilter } = filter
  const { analytics } = useFirebase()

  const { hasAuthorization } = useSession()
  const canAtender = hasAuthorization(Permissions.visualizarListaDeAtendimento.registrarAtendimento)

  const [countFiltrosAplicados, setCountFiltrosAplicados] = useState(0)
  const [isFilterEqual, setIsFilterEqual] = useState(false)

  const { classes } = useStyles(createFilterStyles)

  const initialValues = mountInitialValues(isEmpty(filterBackup) ? restFilter : filterBackup)

  const defaultPeriodo = useMemo(() => filterDefault.periodo, [filterDefault.periodo])

  const handleOnClear = () => {
    onClear()
    analytics.logEvent('voltar_filtro_padrao_RT')
  }

  const handleDebounceChange = (formState: FormState<ListaRegistroTardioInlineFilterModel>) => {
    onChangeFilter({ ...filter, ...formState.values, query: formState.values.query })
  }

  const handlePopperFormSubmit = useCallback(
    (values: ListaRegistroTardioFilterPopperModel) => {
      deleteFilterBackup()
      onChangeFilter({ ...filter, ...values })
    },
    [deleteFilterBackup, filter, onChangeFilter]
  )

  const renderFilterForm = () => {
    return (
      <>
        <FormDebouncedValueSpy onChange={handleDebounceChange} />
        <Grid alignItems='center'>
          <Cell size={5} xl={4} style={classes.cell1}>
            <TextField
              name='query'
              placeholder='Pesquise por nome, CPF, CNS ou data de nascimento'
              icon='zoomOutline'
            />
          </Cell>
          <Cell size={7} xl={8} style={classes.cell2}>
            <HFlow style={classes.hFlow}>
              {canAtender && (
                <SwitchField
                  style={{
                    display: 'grid',
                    gridAutoFlow: 'column',
                    whiteSpace: 'nowrap',
                    justifyContent: 'start',
                    maxWidth: '16.5rem',
                  }}
                  name={path.somenteMeusAtendimentos}
                  label='Ver somente os meus registros'
                />
              )}
              <ListaRegistroTardioSortDropdown filter={filter} onChange={onChangeFilter} />
            </HFlow>
          </Cell>
        </Grid>
      </>
    )
  }

  const renderPopper = (ctrl: PopperControls) => (
    <ListaRegistroTardioFilterPopperForm
      defaultPeriodo={defaultPeriodo}
      initialValues={initialValues}
      onSubmit={handlePopperFormSubmit}
      onFechar={ctrl.close}
      onClear={onClear}
      onChangeFilter={updateFilterBackup}
      isFilterEqual={isFilterEqual}
    />
  )

  useEffect(() => {
    const start =
      filter.periodo?.startDate && moment(filter.periodo.startDate).isSame(filterDefault.periodo.startDate, 'day')
    const end = filter.periodo?.endDate && moment(filter.periodo.endDate).isSame(filterDefault.periodo.endDate, 'day')

    const rest = isEqual(
      {
        ...filter,
        statusAtendimento: (filter?.statusAtendimento || []).sort(),
        periodo: undefined,
        query: filter.query || undefined,
      } as ListaRegistroTardioFilterModel,
      {
        ...filterDefault,
        statusAtendimento: filterDefault.statusAtendimento?.sort(),
        periodo: undefined,
        query: undefined,
      }
    )

    setIsFilterEqual(start && end && rest)
  }, [filter, filterDefault])

  useEffect(() => {
    setCountFiltrosAplicados(
      (filter?.statusAtendimento?.length || 0) +
        (filter?.responsaveis?.length || 0) +
        (filter?.periodo?.endDate && filter?.periodo?.startDate ? 1 : 0)
    )
  }, [filter])

  return (
    <Grid gap={0.5} gapVertical={0.5}>
      <Cell size={12}>
        <HFlow style={classes.hFlow}>
          <Form<ListaRegistroTardioInlineFilterModel>
            onSubmit={onChangeFilter}
            render={renderFilterForm}
            initialValues={{ query, somenteMeusAtendimentos }}
          />
          <PopperButton
            kind='primary'
            size='small'
            skin='default'
            placement='bottom-end'
            renderPopper={renderPopper}
            onClose={deleteFilterBackup}
          >
            <Icon icon='filterOutline' />
            {'Filtros' + (countFiltrosAplicados > 0 ? ` (${countFiltrosAplicados})` : '')}
          </PopperButton>
        </HFlow>
      </Cell>

      <Cell size={12}>
        <HFlow alignItems='center' justifyContent='space-between' style={{ gridAutoColumns: 'minmax(0, auto)' }}>
          <ListaRegistroTardioFilterTags filtros={filter} />

          <Tooltip text='Redefinir filtros para o padrão'>
            <Button kind='primary' skin='outline' size='small' disabled={isFilterEqual} onClick={handleOnClear}>
              <Icon style={{ paddingRight: '0.5rem' }} icon='redo' />
              Voltar para padrão
            </Button>
          </Tooltip>
        </HFlow>
      </Cell>
    </Grid>
  )
}
