/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { SubmitButton } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { FormApi } from 'final-form'
import { useMemo, useState } from 'react'
import { metaPath } from 'util/metaPath'

import { EvolucaoDenteSupranumerarioModel } from './DentesSupranumerariosField'
import { DentesSupranumerariosForm } from './DentesSupranumerariosForm'
import { DentesSupranumerariosFormFields } from './DentesSupranumerariosFormFields'

interface AdicionarDenteSupranumerarioPopupProps {
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  onSubmit(values: EvolucaoDenteSupranumerarioModel): void
}

const path = metaPath<EvolucaoDenteSupranumerarioModel>()

export const AdicionarDenteSupranumerarioPopup = (props: AdicionarDenteSupranumerarioPopupProps) => {
  const { cidadaoDataNascimento, dataAtendimento, onSubmit } = props

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)

  const renderPopper = (controls: PopperControls) => {
    const handleSubmit = (values: EvolucaoDenteSupranumerarioModel, form: FormApi) => {
      setDisableSubmitButton(true)
      onSubmit(values)

      setTimeout(() => {
        resetForm(form)
        controls.close()
      })
    }

    const handleSubmitSuccess = () => {
      setDisableSubmitButton(false)
    }

    const handleCancel = (form: FormApi) => {
      if (form.getState().dirty) resetForm(form)
      controls.close()
    }

    return (
      <div css={classes.popper}>
        <DentesSupranumerariosForm
          onSubmit={handleSubmit}
          onSubmitSucceeded={handleSubmitSuccess}
          render={(formProps) => {
            return (
              <VFlow>
                <DentesSupranumerariosFormFields
                  cidadaoDataNascimento={cidadaoDataNascimento}
                  dataAtendimento={dataAtendimento}
                />

                <HFlow justifyContent='flex-end'>
                  <Button size='small' onClick={() => handleCancel(formProps.form)}>
                    Cancelar
                  </Button>

                  <SubmitButton
                    size='small'
                    kind='primary'
                    handleSubmit={formProps.handleSubmit}
                    disabled={disableSubmitButton}
                  >
                    Adicionar
                  </SubmitButton>
                </HFlow>
              </VFlow>
            )
          }}
        />
      </div>
    )
  }

  return (
    <PopperButton kind='primary' size='small' skin='outline' placement='top-start' renderPopper={renderPopper}>
      <Icon icon='plus' style={{ marginRight: '0.25rem' }} />
      <Text color='inherit'>Adicionar procedimentos em dentes supranumerários</Text>
    </PopperButton>
  )
}

const styles = (theme: Theme) => ({
  popper: css`
    width: 27.625rem;
    max-height: 27.625rem;
    margin: 0.5rem 0;
    border-radius: ${theme.radius.popper}px;
    background: ${theme.pallete.primary.c100};
    box-shadow: ${theme.shadows.outer['40']};
    padding: 1rem;
    overflow-y: auto;
  `,
})

const resetForm = (form: FormApi) => {
  form.reset({})
  form.resetFieldState(path.local.absolutePath())
  form.resetFieldState(path.procedimentos.absolutePath())
  form.resetFieldState(path.observacao.absolutePath())
}
