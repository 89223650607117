import useSession from 'components/auth/useSession'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

export function Home() {
  const session = useSession()
  const history = useHistory()

  useEffect(() => {
    if (session && session.data && session.data.acesso && session.data.acesso.moduloInicial) {
      history.replace(session.data.acesso.moduloInicial)
    }
  }, [session, history])

  return null
}
