import { compareAsc, isAfter as isAfterDateFns, isBefore as isBeforeDateFns } from 'date-fns'

import { toDate } from './formatDate'

export function isAfterOrEqual(date: number | Date, dateToCompare: number | Date): boolean {
  return !isBeforeDateFns(date, dateToCompare)
}

export function isBeforeOrEqual(date: number | Date, dateToCompare: number | Date): boolean {
  return !isAfterDateFns(date, dateToCompare)
}

export function isBefore(date: string, dateToCompare: string) {
  return isBeforeDateFns(toDate(date), toDate(dateToCompare))
}

export function isAfter(date: string, dateToCompare: string) {
  return isAfterDateFns(toDate(date), toDate(dateToCompare))
}

export function compareAscLocalDate(date: LocalDate, dateToCompare: LocalDate): number {
  return compareAsc(toDate(date), toDate(dateToCompare))
}
