import { DropdownItem, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { ExecutionResult } from 'graphql'
import { TestarConexaoLinkTransmissaoMutation } from 'graphql/types.generated'
import React from 'react'

import { TransmissaoLinksItem } from './TransmissaoLinksDestinoTable'

interface TransmissaoLinksDestinoDropdownProps {
  row: TransmissaoLinksItem

  excluir(values): Promise<any>

  alternar(values): Promise<any>

  testarConexao(values): Promise<ExecutionResult<TestarConexaoLinkTransmissaoMutation>>

  refetch?(): void
}

export function TransmissaoLinksDestinoDropdown(props: TransmissaoLinksDestinoDropdownProps) {
  const { row, excluir, alternar, testarConexao } = props
  const alert = useAlert()

  const handleTestarConexaoClick = () =>
    testarConexao({ variables: { id: row.id } }).then(
      (result: ExecutionResult<TestarConexaoLinkTransmissaoMutation>) => {
        !result.data.testarConexaoLinkTransmissao.link.statusConexao &&
          alert('danger', result.data.testarConexaoLinkTransmissao.validationDescription)
        alert('success', 'Teste de conexão com instalação de destino finalizado.')
        props.refetch()
      }
    )

  const handleAlternarClick = (newState: string) => () =>
    alternar({ variables: { id: row.id } }).then(() => {
      alert('success', `Instalação de destino ${row.name} ${newState} com sucesso.`)
      props.refetch()
      !row.ativo && handleTestarConexaoClick()
    })

  const handleRejection = useErrorHandler()

  const handleExcluirClick = () => {
    confirm({
      title: `Deseja excluir a instalação de destino?`,
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () =>
        excluir({ variables: { id: row.id } })
          .then(() => {
            alert('success', `Instalação de destino ${row.name} excluída com sucesso.`)
            props.refetch()
          })
          .catch(handleRejection),
    })()
  }

  const alternarOnClick = row.ativo ? handleAlternarClick('inativada') : handleAlternarClick('ativada')
  const alternarText = row.ativo ? 'Inativar' : 'Ativar'
  const alternarDisable = !row.actions.alternarAtivacao.enabled
  const excluirDisable = !row.actions.excluir.enabled

  return (
    <Tooltip text='Mais opções'>
      <DropdownButton>
        <Tooltip text={!row.ativo && 'Instalação está inativa'}>
          <DropdownItem onClick={handleTestarConexaoClick} disabled={!row.ativo}>
            Testar conexão
          </DropdownItem>
        </Tooltip>

        <Tooltip text={alternarDisable && row.actions.alternarAtivacao.hint}>
          <DropdownItem onClick={alternarOnClick} disabled={alternarDisable}>
            {alternarText}
          </DropdownItem>
        </Tooltip>

        <Tooltip text={excluirDisable && row.actions.excluir.hint}>
          <DropdownItem type='danger' onClick={handleExcluirClick} disabled={excluirDisable}>
            Excluir
          </DropdownItem>
        </Tooltip>
      </DropdownButton>
    </Tooltip>
  )
}
