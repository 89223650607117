/** @jsx jsx */
import { css, jsx } from '@emotion/core'

interface ResultadoExameExpandableContainerProps {
  expanded: boolean
  children: React.ReactNode
}

export const ResultadoExameExpandableContainer = ({ expanded, children }: ResultadoExameExpandableContainerProps) => {
  return (
    <div
      css={css`
        display: ${expanded ? 'block' : 'none'};
      `}
    >
      {children}
    </div>
  )
}
