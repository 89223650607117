/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Heading, InfoLabel, Spinner, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { isLotacaoOrEstagio } from 'components/auth/useSessionUtils'
import { Box } from 'components/Box'
import { CpfField, Form, FormRenderProps, TextField } from 'components/form'
import { PageContainer } from 'components/layout/PageContainer'
import { information } from 'components/modals/information'
import { ExternalUserHeader } from 'components/user/ExternalUserHeader'
import { StatusVideochamadaEnum } from 'graphql/types.generated'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { cpf, createValidator, maxLength, minLength, nome, required } from 'util/validation'

import { TermoModal } from '../../components/TermoModal'
import { CameraPreview } from './componentes/CameraPreview'
import { VideochamadaAlert } from './componentes/VideochamadaAlert'
import { useSolicitarEntradaVideochamada } from './hooks/useSolicitarEntradaVideochamada'
import {
  AtorVideochamada,
  LocalVideocallParticipant,
  StatusSolicitacaoEntradaVideochamadaEnum,
} from './model-videochamada'
import { termosVideochamadaProfissionalVisitante } from './termos'
import { getVideochamadaAlert, isVideochamadaIniciada } from './utils-videochamada'

const meta = metaPath<SalaEsperaFormModel>()

interface SalaEsperaVideochamadaUsuariosExternosViewProps {
  videochamadaUuid: string
  nomeProfissional: string
  cboProfissional: string
  audioEnabled: boolean
  videoEnabled: boolean
  statusVideochamada: StatusVideochamadaEnum
  setAudioEnabled(value: boolean): void
  setVideoEnabled(value: boolean): void
  onEntrar(selfData: LocalVideocallParticipant): void
}

interface SalaEsperaFormModel {
  nomeParticipante: string
  cpf: string
}

const validator = createValidator<SalaEsperaFormModel>({
  nomeParticipante: [required, nome, minLength(5), maxLength(70)],
  cpf: [required, cpf],
})

export function SalaEsperaVideochamadaUsuariosExternosView(props: SalaEsperaVideochamadaUsuariosExternosViewProps) {
  const {
    videochamadaUuid,
    nomeProfissional,
    cboProfissional,
    onEntrar,
    audioEnabled,
    videoEnabled,
    setAudioEnabled,
    setVideoEnabled,
    statusVideochamada,
  } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const alert = useAlert()
  const { data: session, refresh: refreshSession } = useSession()
  const cboProfissionalExterno = session ? isLotacaoOrEstagio(session.acesso) && session.acesso.cbo.nome : null

  const [isTermoResponsabilidadeModalOpen, setIsTermoResponsabilidadeModalOpen] = useState(false)

  const { solicitarEntradaVideochamada, statusSolicitacao } = useSolicitarEntradaVideochamada({
    videochamadaUuid,
    onEntrar,
  })

  const videochamadaLotada = statusVideochamada === StatusVideochamadaEnum.LOTADA
  const videochamadaSemDono = statusVideochamada === StatusVideochamadaEnum.ATIVA_SEM_DONO
  const videochamadaIniciada = isVideochamadaIniciada(statusVideochamada)
  const videochamadaAtiva = statusVideochamada === StatusVideochamadaEnum.ATIVA

  const handleEntrarButtonClick = () => {
    refreshSession() // Garante que o usuário esteja logado/deslogado ao tentar iniciar a videochamada
    setIsTermoResponsabilidadeModalOpen(true)
  }

  const handleSolicitarEntrada = useCallback(
    async (values: SalaEsperaFormModel, autorizacoes: Record<AtorVideochamada.PROFISSIONAL_EXTERNO, boolean>) => {
      if (!autorizacoes[AtorVideochamada.PROFISSIONAL_EXTERNO])
        alert('danger', 'O profissional precisa autorizar a videochamada.')

      setIsTermoResponsabilidadeModalOpen(false)

      await solicitarEntradaVideochamada(
        values.nomeParticipante,
        values.cpf,
        autorizacoes[AtorVideochamada.PROFISSIONAL_EXTERNO]
      )
    },
    [solicitarEntradaVideochamada, alert]
  )

  useEffect(() => {
    switch (statusSolicitacao) {
      case StatusSolicitacaoEntradaVideochamadaEnum.NEGADA:
        alert('danger', `Sua participação na videochamada foi negada por ${nomeProfissional}.`)
        break
      case StatusSolicitacaoEntradaVideochamadaEnum.TIMEOUT:
        alert(
          'danger',
          `Sua solicitação para entrar na chamada não foi respondida por ${nomeProfissional}, tente novamente.`
        )
        break
      case StatusSolicitacaoEntradaVideochamadaEnum.VIDEOCHAMADA_LOTADA:
        information({
          title: 'Número máximo de participantes excedido',
          body: 'No momento, as videochamadas e-SUS APS podem ter apenas dois participantes simultâneos.',
          showCloseButton: true,
        })()
        break
    }
  }, [alert, nomeProfissional, statusSolicitacao])

  const videochamadaAlertModel = useMemo(
    () => getVideochamadaAlert(nomeProfissional, videochamadaIniciada, videochamadaLotada, videochamadaSemDono),
    [nomeProfissional, videochamadaIniciada, videochamadaLotada, videochamadaSemDono]
  )

  const renderForm = ({ values, handleSubmit }: FormRenderProps<SalaEsperaFormModel>) => (
    <form onSubmit={handleSubmit} noValidate>
      {statusSolicitacao === StatusSolicitacaoEntradaVideochamadaEnum.AGUARDANDO_RESPOSTA ? (
        <Box>
          <Grid>
            <Cell size={2} alignSelf='center' style={styles.spinnerContainer}>
              <Spinner size={2} borderWidth={3} style={styles.spinner} />
            </Cell>
            <Cell size={10}>
              <InfoLabel title='Aguardando permissão para ingressar na videochamada'>
                Você será direcionado para a tela da videochamada quando {nomeProfissional} permitir.
              </InfoLabel>
            </Cell>
          </Grid>
        </Box>
      ) : (
        <Box>
          <TermoModal
            onAccept={(autorizacoes) => handleSolicitarEntrada(values, autorizacoes)}
            open={isTermoResponsabilidadeModalOpen}
            onClose={() => setIsTermoResponsabilidadeModalOpen(false)}
            termos={termosVideochamadaProfissionalVisitante}
            confirmText='Iniciar chamada'
          />
          <Grid gap={1} alignItems='center' justifyContent='space-between'>
            {session ? (
              <Fragment>
                <Cell size={8}>
                  <Text>Você está realizando essa chamada como </Text>
                  <Text fontWeight='bold'>
                    {session.profissional.nome}
                    {cboProfissionalExterno ? ` | ${cboProfissionalExterno.capitalize()}` : ''}
                  </Text>
                </Cell>
                <Cell size={4} style={styles.buttonContainer} onClick={handleEntrarButtonClick}>
                  <Tooltip text={videochamadaAlertModel?.tooltip}>
                    <Button type='submit' kind='primary' disabled={!videochamadaAtiva}>
                      Entrar na chamada
                    </Button>
                  </Tooltip>
                </Cell>
                {!!videochamadaAlertModel?.alert && (
                  <VideochamadaAlert>{videochamadaAlertModel.alert}</VideochamadaAlert>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Cell size={6} alignSelf='flex-start'>
                  <TextField
                    label='Nome completo'
                    name={meta.nomeParticipante}
                    placeholder='Digite o nome do participante'
                    maxLength={70}
                    required
                  />
                </Cell>
                <Cell size={6} alignSelf='flex-start'>
                  <CpfField label='CPF' name={meta.cpf} required />
                </Cell>
                <Cell size={12} style={styles.buttonContainer}>
                  <Tooltip text={videochamadaAlertModel?.tooltip}>
                    <Button type='submit' kind='primary' disabled={!videochamadaAtiva}>
                      Entrar na chamada
                    </Button>
                  </Tooltip>
                </Cell>
                {!!videochamadaAlertModel?.alert && (
                  <VideochamadaAlert>{videochamadaAlertModel.alert}</VideochamadaAlert>
                )}
              </Fragment>
            )}
          </Grid>
        </Box>
      )}
    </form>
  )

  return (
    <Fragment>
      <ExternalUserHeader />
      <PageContainer>
        <Grid gap={6} gapVertical={1}>
          <Cell size={12}>
            <Heading level={1} style={styles.heading}>
              Videochamada e-SUS APS
            </Heading>
          </Cell>
          <Cell xs={12} sm={7}>
            <Grid>
              <Cell size={12}>
                <Heading level={2} style={styles.headingName}>
                  Chamada com {nomeProfissional}
                  {cboProfissional ? ` | ${cboProfissional}` : ''}
                </Heading>
              </Cell>
              <Cell size={12}>
                <Form<SalaEsperaFormModel>
                  render={renderForm}
                  onSubmit={handleEntrarButtonClick}
                  validate={validator}
                  suppressNotificationError
                />
              </Cell>
            </Grid>
          </Cell>
          <Cell xs={12} sm={5}>
            <CameraPreview
              audioEnabled={audioEnabled}
              videoEnabled={videoEnabled}
              setAudioEnabled={setAudioEnabled}
              setVideoEnabled={setVideoEnabled}
            />
          </Cell>
        </Grid>
      </PageContainer>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  heading: css`
    margin-top: 2rem;
  `,
  headingName: css`
    padding: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
  `,
  buttonContainer: css`
    display: flex;
    justify-content: flex-end;
  `,
  spinnerContainer: css`
    display: flex;
    justify-content: center;
  `,
  spinner: css`
    color: ${theme.pallete.primary.main};
  `,
})
