import { createValidator, maxLength, range, required } from 'util/validation'

import { MedicoesPanelModel } from './MedicoesPanel'

export const medicoesPanelValidator = createValidator<MedicoesPanelModel>(
  {
    peso: [maxLength(7), range(0.5, 500)],
    altura: [maxLength(5), range(20, 250)],
    perimetroCefalico: [maxLength(5), range(10, 200)],
    circunferenciaAbdominal: [maxLength(6)],
    perimetroPanturrilha: [maxLength(5), range(10, 99)],
    pressaoArterialSistolica: [maxLength(3), range(0, 999)],
    pressaoArterialDiastolica: [maxLength(3), range(0, 999)],
    frequenciaRespiratoria: [maxLength(3), range(0, 200)],
    frequenciaCardiaca: [maxLength(3), range(0, 999)],
    temperatura: [maxLength(4), range(20, 45)],
    saturacaoO2: [maxLength(3), range(0, 100)],
    glicemia: [maxLength(3), range(0, 800)],
  },
  (values, error) => {
    if (
      (values?.pressaoArterialSistolica || values?.pressaoArterialDiastolica) &&
      !error.pressaoArterialDiastolica &&
      !error.pressaoArterialSistolica
    ) {
      error.pressaoArterialDiastolica = required(values.pressaoArterialDiastolica)
      error.pressaoArterialSistolica = required(values.pressaoArterialSistolica)
    }

    if (values?.glicemia && !error?.glicemia) {
      error.tipoGlicemia = required(values.tipoGlicemia)
    }

    return error
  }
)
