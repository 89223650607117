/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import {
  AccordionDataTableRowDefault,
  AccordionDataTableRowDefaultProps,
} from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'

import { HistoricoTecidosMolesQueryModel } from '../model'

export default function HistoricoTecidosMolesTableRow(
  props: AccordionDataTableRowDefaultProps<HistoricoTecidosMolesQueryModel>
) {
  return (
    <VFlow vSpacing={0}>
      <AccordionDataTableRowDefault {...props} />

      {props.row.evolucaoProcedimentoOdonto?.observacao && (
        <HFlow
          hSpacing={0.2}
          style={css`
            padding: 0.5rem 1rem;
            padding-top: 0;
          `}
        >
          <Text fontWeight='bold'>Observações: </Text>
          <Text
            fontStyle='italic'
            style={css`
              word-break: break-all;
            `}
          >
            <div dangerouslySetInnerHTML={{ __html: props.row.evolucaoProcedimentoOdonto.observacao }} />
          </Text>
        </HFlow>
      )}
    </VFlow>
  )
}
