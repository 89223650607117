import { VFlow } from 'bold-ui'
import { HistoricoPlanoPecCuidadoCompartilhadoFragment } from 'graphql/types.generated'
import React from 'react'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'

import { HistoricoPlanoPecCuidadoCompartilhadoPanel } from './HistoricoPlanoPecCuidadoCompartilhadoPanel'

export type HistoricoPlanoPecCuidadoCompartilhado = HistoricoPlanoPecCuidadoCompartilhadoFragment

interface HistoricoPlanoPecCuidadoCompartilhadoListProps {
  cuidadosCompartilhados: HistoricoPlanoPecCuidadoCompartilhado[]
}

export default function HistoricoPlanoPecCuidadoCompartilhadoList({
  cuidadosCompartilhados,
}: HistoricoPlanoPecCuidadoCompartilhadoListProps) {
  return (
    <HistoricoDetailTable title='Cuidado compartilhado' hasLines>
      <VFlow>
        {cuidadosCompartilhados.map((cuidadoCompartilhado) => (
          <HistoricoPlanoPecCuidadoCompartilhadoPanel
            cuidadoCompartilhado={cuidadoCompartilhado}
            key={`box-cc-historico-${cuidadoCompartilhado.id}`}
          />
        ))}
      </VFlow>
    </HistoricoDetailTable>
  )
}
