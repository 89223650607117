import React from 'react'
import { MaskedInputProps } from 'react-text-mask'
import { applyMask, masks } from 'util/mask'

type MaskType = MaskedInputProps['mask']

export interface GenericMaskedLabelProps {
  value: string
}

function createMaskedLabel(displayName: string, mask: MaskType) {
  return class extends React.PureComponent<GenericMaskedLabelProps> {
    static displayName = displayName

    render() {
      const { value } = this.props

      return applyMask(value, mask)
    }
  }
}

export const Telefone = createMaskedLabel('Telefone', masks.telefone)
export const Cpf = createMaskedLabel('Cpf', masks.cpf)
export const Cep = createMaskedLabel('Cep', masks.cep)
export const Cnpj = createMaskedLabel('Cnpj', masks.cnpj)
export const Cns = createMaskedLabel('Cns', masks.cns)
export const NumeroDeclaracaoObito = createMaskedLabel('NumeroDeclaracaoObito', masks.numeroDeclaracaoObito)
