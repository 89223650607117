import { HFlow, Text, TextProps } from 'bold-ui'
import React from 'react'

interface RequiredLabelProps extends TextProps {
  label: string
  inline?: boolean
}

export function RequiredLabel(props: RequiredLabelProps) {
  const { label, inline = false, ...rest } = props

  return inline ? (
    <HFlow hSpacing={0.25} alignItems='center'>
      <Text {...rest}>
        {label}
        <Text color='danger'> *</Text>
      </Text>
    </HFlow>
  ) : (
    <HFlow hSpacing={0.25} alignItems='center'>
      <Text {...rest}>{label}</Text>
      <Text color='danger'>*</Text>
    </HFlow>
  )
}
