/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { StickyContainer } from 'bold-ui'
import { useOffsetAlerta } from 'hooks/useOffsetAlerta'
import React from 'react'

interface AlertStickyContainerProps {
  children: React.ReactNode
}

export function AlertStickyContainer({ children }: AlertStickyContainerProps) {
  const offsetAlerta = useOffsetAlerta()

  return (
    <StickyContainer
      top={offsetAlerta}
      style={css`
        z-index: 15;
      `}
    >
      {children}
    </StickyContainer>
  )
}
