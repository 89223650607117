/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Breakpoint, HFlow, Icon, Icons, Text, Theme, Tooltip, useTheme } from 'bold-ui'

export interface IconLabelProps {
  icon: Icons
  text: string
  color?: string
  textColor?: string
  hideTextBelow?: Breakpoint
}

export function IconLabel(props: IconLabelProps) {
  const theme = useTheme()
  const { text, hideTextBelow, icon, textColor, color = theme.pallete.primary.c40 } = props

  const classes = createStyles(theme, textColor || color, hideTextBelow)

  return (
    <HFlow alignItems='center' hSpacing={0.3}>
      <Tooltip text={text} style={classes.tooltip}>
        <Icon icon={icon} color={color} size={1} />
      </Tooltip>

      <Text style={classes.text}>{text}</Text>
    </HFlow>
  )
}

const createStyles = (theme: Theme, textColor: string, hideTextBelow: Breakpoint) => ({
  tooltip: hideTextBelow
    ? css`
        ${theme.breakpoints.up(hideTextBelow)} {
          display: none;
        }
      `
    : undefined,
  text: hideTextBelow
    ? css`
        color: ${textColor};
        display: none;
        ${theme.breakpoints.up(hideTextBelow)} {
          display: inherit;
        }
      `
    : css`
        color: ${textColor};
      `,
})
