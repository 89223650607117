/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, Grid, HFlow, VFlow } from 'bold-ui'
import { RadioField, RadioFieldProps, TextField } from 'components/form'
import { CaracteristicaLesaoEnum } from 'graphql/types.generated'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { getTecidosMolesCaracteristicaLesao, getTipoCaracteristicaLesao, TipoCaracteristicaLesao } from '../../../model'

export interface SintomatologiaFieldModel {
  value: CaracteristicaLesaoEnum | 'outros'
  descricaoOutro?: string
}

export interface SintomatologiaFieldProps {
  name: MetaPath<SintomatologiaFieldModel>
}

export default function SintomatologiaField(props: SintomatologiaFieldProps) {
  const { name } = props
  const {
    input: {
      value: { value },
    },
  } = useField(name.absolutePath(), { subscription: { value: true } })

  return (
    <FormControl label={getTipoCaracteristicaLesao(TipoCaracteristicaLesao.SINTOMATOLOGIA).title}>
      <Grid gap={1}>
        <Cell size={5}>
          <VFlow vSpacing={0.5}>
            <SintomatologiaRadioField name={name.value} value={CaracteristicaLesaoEnum.SINTOMATOLOGIA_AUSENTE} />
            <SintomatologiaRadioField name={name.value} value={CaracteristicaLesaoEnum.SINTOMATOLOGIA_DOR} />
            <SintomatologiaRadioField name={name.value} value={CaracteristicaLesaoEnum.SINTOMATOLOGIA_ARDENCIA} />
          </VFlow>
        </Cell>
        <Cell size={7}>
          <VFlow vSpacing={0.5}>
            <SintomatologiaRadioField name={name.value} value={CaracteristicaLesaoEnum.SINTOMATOLOGIA_PARESTESIA} />

            <HFlow alignItems='flex-start'>
              <SintomatologiaRadioField name={name.value} label='Outro' value='outros' />

              {value === 'outros' && (
                <TextField
                  name={name.descricaoOutro}
                  maxLength={100}
                  style={css`
                    margin-top: -0.25rem;
                    min-width: 10rem;
                  `}
                />
              )}
            </HFlow>
          </VFlow>
        </Cell>
      </Grid>
    </FormControl>
  )
}

const SintomatologiaRadioField = (props: {
  name: RadioFieldProps['name']
  value: SintomatologiaFieldModel['value']
  label?: string
}) => (
  <RadioField
    style={{ marginLeft: -0.25 }}
    clearable
    label={props.label || getTecidosMolesCaracteristicaLesao(props.value as CaracteristicaLesaoEnum).title}
    {...props}
  />
)
