import { Text, VFlow } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { AlergiaSelectFieldQuery } from 'graphql/types.generated'
import React from 'react'
import { AlergiaTag } from 'view/atendimentos/detail/components/AlergiaTag'
import { categoriaAlergiaRecord, tipoReacaoAlergiaRecord } from 'view/atendimentos/detail/soap/aside/types/AlergiaModel'

export type AlergiaSelectFieldModel = AlergiaSelectFieldQuery['alergias']['content'][number]

export interface AlergiaSelectFieldProps
  extends Omit<SelectFieldProps<AlergiaSelectFieldModel>, 'items' | 'itemToString'> {
  alergias: ReadonlyArray<AlergiaSelectFieldModel>
}

const itemToString = (item: AlergiaSelectFieldModel) =>
  item && (item.substanciaEspecifica?.nome ?? item.substanciaEspecificaLegado)

const renderItem = (item: AlergiaSelectFieldModel) => {
  const categoria =
    categoriaAlergiaRecord[item.substanciaEspecifica?.categoria ?? item.categoriaSubstanciaEspecificaLegado]
  const tipoReacao = tipoReacaoAlergiaRecord[item.tipoReacao]

  return (
    <VFlow vSpacing={0.25}>
      <Text fontWeight='bold'>{item.substanciaEspecifica?.nome ?? item.substanciaEspecificaLegado}</Text>
      <Text>{`${tipoReacao?.descricao ?? 'Reação adversa'} a ${categoria.descricao}`}</Text>
      <AlergiaTag type='criticidade' enum={item.criticidade} />
    </VFlow>
  )
}

export function AlergiaSelectField(props: AlergiaSelectFieldProps) {
  const { alergias, ...rest } = props

  const sortedItems = alergias ? [...alergias].sort((a, b) => itemToString(a).localeCompare(itemToString(b))) : []

  return (
    <SelectField<AlergiaSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      items={sortedItems}
      {...rest}
    />
  )
}
