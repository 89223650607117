import { Checkbox, HFlow, Text, useStyles } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { Box } from 'components/Box'
import {
  ConfiguracaoAgendaOnlineLotacaoInput,
  ConfiguracaoAgendaOnlineLotacaoQuery,
  DiaSemanaEnum,
} from 'graphql/types.generated'
import _ from 'lodash'
import React, { CSSProperties } from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { abreviarDiaSemana } from 'view/configuracao-agenda/component/DiaSemana'

import { ConfiguracaoAgendaOnlineGridColumn } from './components/ConfiguracaoAgendaOnlineGridColumn'
import { ConfiguracaoAgendaOnlineGridContainer } from './components/ConfiguracaoAgendaOnlineGridContainer'
import { ConfiguracaoAgendaOnlineSimpleBox } from './components/ConfiguracaoAgendaOnlineSimpleBox'
import { ConfiguracaoAgendaOnlineToggleButton } from './components/ConfiguracaoAgendaOnlineToggleButton'

export interface ConfiguracaoAgendaOnlineHorariosSectionProps {
  horariosAgendaOnline: ConfiguracaoAgendaOnlineLotacaoQuery['lotacao']['gradeConfiguracaoAgendaOnline']
  formProps: FormRenderProps
}

const cfgHorarioAgendaOnlinePath = metaPath<ConfiguracaoAgendaOnlineLotacaoInput>()

const diaSemanaCheckBoxChanged = (formProps: FormRenderProps, indexCfgSemana: number) => (e) => {
  const innerPath = cfgHorarioAgendaOnlinePath.configuracoesSemana.get(indexCfgSemana).configuracoes

  formProps.values.configuracoesSemana[indexCfgSemana].configuracoes.forEach((a, aindex) => {
    formProps.form.change(innerPath.get(aindex).ativo.absolutePath(), e.target.checked)
  })
}

const buildViewType = (horariosConsolidados: string[], indexCfgDiaSemana: number, configuracoes) => {
  const horarioList = []
  let controllIndex = 0

  horariosConsolidados.forEach((horarioConsolidado) => {
    horarioList.push({
      horario: horarioConsolidado,
      exists: horarioExists(configuracoes, horarioConsolidado),
      index: horarioExists(configuracoes, horarioConsolidado)
        ? controllIndex
        : indexCfgDiaSemana + '-' + horarioConsolidado,
    })
    if (horarioExists(configuracoes, horarioConsolidado)) {
      controllIndex++
    }
  })

  return horarioList
}

const hasFimDeSemana = (cfgSemana) => {
  return cfgSemana.some((cfg) => {
    return cfg.diaSemana === DiaSemanaEnum.DOMINGO || cfg.diaSemana === DiaSemanaEnum.SABADO
  })
}

const horarioExists = (configuracoes, horarioConsolidado) => {
  return _.find(configuracoes, (cfg) => cfg.horario === horarioConsolidado) != null
}

const todosHorariosSelecionados = (formProps: FormRenderProps, indexCfgSemana: number): boolean => {
  for (const configuracao of formProps.values.configuracoesSemana[indexCfgSemana].configuracoes) {
    if (!configuracao.ativo) {
      return false
    }
  }
  return true
}

export function ConfiguracaoAgendaOnlineHorariosSection(props: ConfiguracaoAgendaOnlineHorariosSectionProps) {
  const { formProps, horariosAgendaOnline } = props
  const { classes } = useStyles(createStyles)
  const horariosConsolidados = horariosAgendaOnline.horariosConsolidados
  const configSemana = horariosAgendaOnline.configuracoesSemana
  return (
    <ConfiguracaoAgendaOnlineGridContainer fimDeSemana={hasFimDeSemana(configSemana)}>
      <ConfiguracaoAgendaOnlineGridColumn horarios={horariosConsolidados.length}>
        <Box style={classes.default} />
        {horariosConsolidados &&
          horariosConsolidados.map((horario, indexCfgSemana) => (
            <ConfiguracaoAgendaOnlineSimpleBox key={indexCfgSemana} horario={horario} />
          ))}
      </ConfiguracaoAgendaOnlineGridColumn>
      {configSemana.map((cfgSemana, indexCfgSemana) => (
        <ConfiguracaoAgendaOnlineGridColumn key={indexCfgSemana} horarios={horariosConsolidados.length}>
          <Box style={classes.default}>
            <HFlow>
              <Checkbox
                onChange={diaSemanaCheckBoxChanged(formProps, indexCfgSemana)}
                disabled={cfgSemana.configuracoes.length === 0}
                checked={cfgSemana.configuracoes.length !== 0 && todosHorariosSelecionados(formProps, indexCfgSemana)}
              />
              <Text fontWeight='bold'>{abreviarDiaSemana(cfgSemana.diaSemana)}</Text>
            </HFlow>
          </Box>
          {buildViewType(horariosConsolidados, indexCfgSemana, cfgSemana.configuracoes).map((newType, horarioIndex) => (
            <ConfiguracaoAgendaOnlineToggleButton
              disabled={!newType.exists}
              key={horarioIndex}
              name={cfgHorarioAgendaOnlinePath.configuracoesSemana
                .get(indexCfgSemana)
                .configuracoes.get(newType.index)
                .absolutePath()}
            />
          ))}
        </ConfiguracaoAgendaOnlineGridColumn>
      ))}
    </ConfiguracaoAgendaOnlineGridContainer>
  )
}

const createStyles = () => ({
  default: {
    border: 0,
    borderRadius: 0,
    borderRight: '1px solid' + gray.c80,
    borderBottom: '1px solid' + gray.c80,
  } as CSSProperties,
})
