import { Button, HFlow, Icon, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DateTime } from 'components/date'
import { useTestarConexaoAgendaOnlineMutation } from 'graphql/hooks.generated'
import { ConexaoQuery } from 'graphql/types.generated'
import React from 'react'

type TesteConexao = ConexaoQuery['conexao']['agendaOnline']['testeConexao']

interface ConectionResultProps {
  testeConexao: TesteConexao
  loading: boolean
  refetch(): void
}

export function ConfiguracaoAgendaOnlineTesteConexao(props: ConectionResultProps) {
  const { testeConexao, loading } = props
  const [testarConexao] = useTestarConexaoAgendaOnlineMutation()
  const alert = useAlert()

  const handleTestarConexao = () =>
    testarConexao().then(() => {
      alert('success', 'Teste de conexão com Agenda online finalizado.')
      props.refetch()
    })

  return (
    <HFlow alignItems='center'>
      <Button skin='outline' size='small' onClick={handleTestarConexao} disabled={loading}>
        Testar conexão da agenda online
      </Button>
      {testeConexao && testeConexao.sucesso && (
        <HFlow alignItems='center'>
          <Icon icon='checkCircleOutline' fill='success' size={1} />
          <Text color='success' style={{ margin: -8 }}>
            Conexão bem sucedida.
          </Text>
        </HFlow>
      )}
      {testeConexao && !testeConexao.sucesso && (
        <HFlow alignItems='center'>
          <Icon icon='banOutline' fill='danger' size={1} />
          <Text color='danger' style={{ margin: -8 }}>
            Conexão mal sucedida.
          </Text>
        </HFlow>
      )}
      {testeConexao && (
        <Text>
          Último teste em <DateTime value={testeConexao.dataEvento} />.
        </Text>
      )}
    </HFlow>
  )
}
