/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text } from 'bold-ui'
import { AcessoLotacaoFragment } from 'graphql/types.generated'

import { Card } from './Card'
import { CardHeading } from './CardHeading'

interface AcessoLotacaoCardProps {
  acesso: AcessoLotacaoFragment
  onClick(acessoId: ID): void
}

export function AcessoLotacaoCard({ acesso, onClick }: AcessoLotacaoCardProps) {
  return (
    <Card onClick={onClick}>
      <CardHeading>{acesso.unidadeSaude.nome.titleCase()}</CardHeading>

      <HFlow
        hSpacing={0.5}
        style={css`
          margin-top: 0.5rem;
        `}
      >
        <Text fontWeight='bold'>CBO</Text>
        <Text>
          {acesso.cbo.nome.capitalize()} - {acesso.cbo.cbo2002}
        </Text>
      </HFlow>

      {acesso.equipe && (
        <HFlow hSpacing={0.5}>
          <Text fontWeight='bold'>Equipe</Text>
          <Text>
            {acesso.equipe.nome.capitalize()} - {acesso.equipe.ine}
          </Text>
        </HFlow>
      )}
    </Card>
  )
}
