import axios, { AxiosPromise } from 'axios'
import { AcompanhamentoCidadaosVinculadosFilterInput } from 'graphql/types.generated'

const API_ENDPOINT = '/api/acompanhamentocidadaosvinculados'
const IMPRIMIR_ENDPOINT = API_ENDPOINT + '/imprimir'
const EXPORTAR_CSV_ENDPOINT = API_ENDPOINT + '/exportarCSV'

export class AcompanhamentoCidadaosVinculados {
  imprimirAcompanhamentoCidadaosVinculados(input: AcompanhamentoCidadaosVinculadosFilterInput): AxiosPromise<Blob> {
    return axios.post(IMPRIMIR_ENDPOINT, input, {
      responseType: 'blob',
    })
  }

  exportarCSVAcompanhamentoCidadaosVinculados(input: AcompanhamentoCidadaosVinculadosFilterInput): AxiosPromise<Blob> {
    return axios.post(EXPORTAR_CSV_ENDPOINT, input, {
      responseType: 'blob',
    })
  }
}
