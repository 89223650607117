import { Button, Cell, Grid, HeadingSection, HFlow, Text, VFlow } from 'bold-ui'
import { Form, FormFooter } from 'components/form'
import { RadioBoxField } from 'components/form/field/RadioBoxField'
import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

export enum TipoResetEnum {
  ATUALIZA_ADMIN = 'ATUALIZA_ADMIN',
  NOVO_ADMIN = 'NOVO_ADMIN',
}

export default function OpcaoResetView() {
  const [buttonState, changeButtonState] = useState(true)
  const history = useHistory()
  const { url } = useRouteMatch()

  const onSubmitClick = (action) => {
    history.push(`${url}/chave/${action.tipoReset === TipoResetEnum.ATUALIZA_ADMIN ? 1 : 0}`)
  }

  const onSelectRadio = () => {
    changeButtonState(false)
  }

  const onCancelarClicked = () => history.push('/')

  const renderForm = (props) => {
    return (
      <Grid>
        <Cell size={6}>
          <VFlow>
            <Text>
              <b>Escolha uma das opções abaixo para seguir com o reset</b>
            </Text>
            <HFlow>
              <RadioBoxField
                name='tipoReset'
                label='Redefinir senha do instalador atual'
                onClick={onSelectRadio}
                value={TipoResetEnum.ATUALIZA_ADMIN}
              />
              <RadioBoxField
                name='tipoReset'
                label='Definir novo instalador'
                onClick={onSelectRadio}
                value={TipoResetEnum.NOVO_ADMIN}
              />
            </HFlow>
          </VFlow>
        </Cell>
        <Cell size={12}>
          <FormFooter>
            <Button onClick={onCancelarClicked} data-cy='FormFooter.cancelar'>
              Cancelar
            </Button>
            <Button onClick={props.handleSubmit} kind='primary' unselectable='on' disabled={buttonState}>
              Continuar
            </Button>
          </FormFooter>
        </Cell>
      </Grid>
    )
  }

  return (
    <>
      <HeadingSection level={1} title='Reset do instalador' />
      <Form render={renderForm} onSubmit={onSubmitClick} />
    </>
  )
}
