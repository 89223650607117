import { Text } from 'bold-ui'
import { Ciap, Cid10 } from 'graphql/types.generated'
import React from 'react'

interface CiapCidLabelProps {
  ciap?: Ciap
  cid10?: Cid10
  isBold?: boolean
  isCapitalized?: boolean
}

export default function CiapCidLabel(props: CiapCidLabelProps) {
  const { ciap, cid10, isBold, isCapitalized } = props
  const fontWeight = isBold ? 'bold' : 'normal'
  return (
    <Text>
      {ciap && (
        <Text>
          <Text fontWeight={fontWeight}>{`${isCapitalized ? ciap.descricao.capitalize() : ciap.descricao} - ${
            ciap.codigo
          }`}</Text>
          <Text> (CIAP2)</Text>
        </Text>
      )}
      {ciap && cid10 && ' | '}
      {cid10 && (
        <Text>
          <Text fontWeight={fontWeight}>{`${isCapitalized ? cid10.nome.capitalize() : cid10.nome} - ${
            cid10.codigo
          }`}</Text>
          <Text> (CID10)</Text>
        </Text>
      )}
    </Text>
  )
}
