import SimplePeer, { SignalData } from 'simple-peer'

interface WebRtcPeerOptions<TData> extends SimplePeer.Options {
  onSignal?(data: SignalData): void
  onTrack?(track: MediaStreamTrack, stream: MediaStream): void
  onData?(data: TData): void
  onConnect?(): void
  onClose?(): void
  onError?(error: Error, peer: WebRtcPeer<TData>): void
}

export interface WebRtcPeer<TData> extends SimplePeer.Instance {
  sendData(data: TData): void
  isUsingRelay(): Promise<boolean>
}

export function createWebRtcPeer<TData = any>(options: WebRtcPeerOptions<TData>): WebRtcPeer<TData> {
  const { onSignal, onTrack, onData, onConnect, onClose, onError, ...rest } = options

  const simplePeer = new SimplePeer(rest)
  const peer = (simplePeer as unknown) as WebRtcPeer<TData>

  peer.sendData = (data: TData) => simplePeer.send(JSON.stringify(data))

  peer.isUsingRelay = async () => await isUsingRelay(peer)

  const onDataEnconded = (encodedData: string) => {
    onData?.(JSON.parse(encodedData) as TData)
  }

  onSignal && peer.on('signal', onSignal)
  onTrack && peer.on('track', onTrack)
  onData && peer.on('data', onDataEnconded)
  onConnect && peer.on('connect', onConnect)
  onClose && peer.on('close', onClose)
  onError && peer.on('error', (error) => onError(error, peer))

  return peer
}

/**
 * based on https://stackoverflow.com/questions/49027287/check-whether-webrtc-peer-connection-is-passing-through-turn-server-or-not
 */
const isUsingRelay = async (peer: WebRtcPeer<any>): Promise<boolean> => {
  const stats = await (peer as any)._pc.getStats()
  const selectedLocalCandidateId = [...stats.values()].find(
    ({ type, state, localCandidateId }) => type === 'candidate-pair' && state === 'succeeded' && localCandidateId
  )?.localCandidateId

  return !!selectedLocalCandidateId && stats.get(selectedLocalCandidateId)?.candidateType === 'relay'
}
