import { Heading } from 'bold-ui'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const DadosSaoTratadosSection = () => {
  return (
    <TermosDeUsoHeadingSection title='7. Quais Dados são Tratados' id='quais_dados_sao_tratados'>
      <p>
        A utilização de determinadas funcionalidades do Serviço pelo titular de dados pessoais dependerá do tratamento
        dos seguintes dados pessoais
      </p>
      <br />
      <br />
      <Heading level={4} color='primary'>
        DO CIDADÃO PACIENTE
      </Heading>
      <br />
      <p>
        <b>Identificação: </b>Nome completo, nome social, telefone da residência, telefone de contato, telefone, número
        do prontuário, e-mail, CPF, CNS, Nº NIS (PIS/PASEP).
      </p>
      <br />
      <p>
        <b>Composição familiar: </b>Nome da mãe, Nome do pai, desconhece o nome completo da mãe/pai, possui referência
        familiar?, relação de parentesco com o responsável familiar e ocupação do responsável familiar, CPF do
        responsável familiar.
      </p>
      <br />
      <p>
        <b>Dados financeiros: </b>Renda familiar (salário mínimo), recebe algum benefício.
      </p>
      <br />
      <p>
        <b>Dados residenciais: </b>Endereço, domicílio, área, fora da área, microárea, tipo do imóvel, Situação de
        Moradia/Posse da terra, Localização, Tipo de domicílio, Em caso de área de produção rural: condição de posse e
        uso da terra, Nº de moradores, Nº de cômodos, Tipo de acesso ao domicílio, Disponibilidade de energia elétrica?,
        Material predominante na construção das paredes externas do seu domicílio, Abastecimento de água, Água para
        consumo no domicílio, Forma de escoamento do banheiro ou sanitário, Destino do lixo, Animais no domicílio?,
        Reside desde, Mudou-se, Nome da instituição de permanência, Cidadão é responsável familiar, saída do cidadão do
        cadastro.
      </p>
      <br />
      <p>
        <b>Características pessoais: </b>Idade, sexo, raça/cor, etnia, data de nascimento, local de nascimento,
        nacionalidade, naturalizado, estrangeiro, cidadão faleceu/óbito, data de óbito/Número da DO, portaria de
        naturalização, data de naturalização, país de nascimento, data de entrada no Brasil, município e UF de
        nascimento, estado civil, orientação sexual, identidade de gênero.
      </p>
      <br />
      <p>
        <b>Hábitos pessoais: </b>Está fumante?, Faz uso de álcool?, Faz uso de drogas?, Usa plantas medicinais?, Usa
        outras práticas integrativas e complementares?, Quantas vezes se alimenta ao dia?, Qual a origem da
        alimentação?, Tem acesso a higiene pessoal?, Está em situação de rua?, É acompanhado por outra instituição? Se
        sim, indique qual(is)? Visita algum familiar com frequência? Se sim, qual é o grau de parentesco?, Marcadores de
        consumo alimentar, Tabagismo: Clínico/Auto referido/Não.
      </p>
      <br />
      <p>
        <b>Dados referentes à saúde ou à vida sexual: </b>Peso, altura, IMC, unidade responsável, tipo sanguíneo,
        equipe/INE responsável pelo cidadão, antecedentes, subjetivo (descrição em texto livre), motivo da consulta,
        objetivo (descrição em texto livre), DUM, DPP, antropometria, sinais vitais, glicemia, vacinação em dia?,
        resultados de exames, Aplicação, Transcrição de caderneta de imunobiológico, Aprazamento de imunobiológico,
        Grupo-alvo, status da vacinação, imunobiológico e dose, viajante, Resultados de Exames de Síndrome neurológica
        por Zika / Microcefalia, Avaliação (descrição em texto livre), Problemas e/ou condições avaliados neste
        atendimento, Alergias e reações adversas, Plano (descrição em texto livre), Histórico do plano de cuidado,
        Intervenções e/ou procedimentos clínicos realizados, Classificação de risco/vulnerabilidade, Prescrição de
        medicamentos, Solicitação de Exames, atestados, orientações, encaminhamentos, tipo de atendimento, atendimento
        compartilhado, procedimentos administrativos, ficha de notificação de caso suspeito, racionalidade em saúde,
        conduta, desfecho do atendimento, agendamento de consulta, turno, data do atendimento/cadastro, local de
        atendimento, justificativa, profissional que realizou o atendimento, escuta inicial/orientação?, Realização de
        procedimentos/pequenas cirurgias da Ficha de Procedimentos, Testes rápidos da Ficha de Procedimentos,
        Administração de Medicamentos da Ficha de Procedimentos, Avaliação Antropométrica da FAI, Tipo de aleitamento
        materno (se criança), Gestante, Puérpera, Doenças transmissíveis, Rastreamento de problema/condição avaliada,
        Triagem neonatal, Ficou em observação?, Cidadão em observação, eMulti/Polo, Status de desenvolvimento da
        criança, Pré-natal, parto e nascimento da criança, Alterações fenotípicas da criança, atores de risco da
        criança, Marcos de desenvolvimento da criança, Pré-natal, parto e nascimento (atendimento da gestante), Data de
        atendimento, unidade responsável e turno, Horário de inicio e fim do atendimento, Motivo da visita, Desfecho da
        visita domiciliar, Dados de saúde autorreferidos, Acompanhamento - Visita domiciliar, Controle ambiental /
        vetorial, Busca ativa - Visita domiciliar, Procedência (procedência do encaminhamento para AD), Condição(ões)
        avaliada(s), CID10 (principal), CID10 (secundário 1), CID10 (secundário 2), Conclusão (conclusão da avaliação de
        elegibilidade), Conclusão/Destino (conclusão/Destino da avaliação de elegibilidade), Cuidador, Modalidade
        Atenção Domiciliar, Especifique (Campo para especificar a procedência), Condição(ões) avaliada(s) - CID 10 e
        CIAP2, Procedimentos FAD, Conduta/Desfecho atendimento domiciliar, Data de admissão (data de admissão em AD),
        Vigilância em Saúde Bucal, Necessidade de prótese inferior/superior, Possui aparelho, Possui contenção, Prótese
        total superior, Prótese total inferior, Evoluções odontológicas, Fornecimento, Programa saúde na escola,
        Atividade, Temas para reunião, Público alvo, Temas para saúde, Práticas em saúde, Idade Gestacional, Lembrete,
        Comunicante Hanseníase, Ações (Atendimento Domiciliar), Observações (Escuta Inicial), Registro anterior
        (Antropometria, sinais vitais e glicemia capilar), Registro anterior (Medição anterior da criança), Subtipo de
        atendimento, Relatório operacional de crianças menores de 5 anos, Data do último atendimento, Data último
        atendimento de Pré-Natal, Puerpério, Odontológica e Visita do ACS, Total de gestantes/puérperas na
        microárea/Total de gestantes/puérperas fora do território/Total geral de gestantes/puérperas, Data último
        Atendimento Risco cardiovascular, Data última consulta odontológica, Data última visita ACS, Data último
        rastreamento risco cardiovascular.
      </p>
      <br />
      <p>
        <b>Associações: </b>Participa de algum grupo comunitário?, É membro de povo ou comunidade tradicional?.
      </p>
      <br />
      <p>
        <b>Educação e treinamento: </b>Frequenta escola ou creche, Qual é o curso mais elevado que frequentou?,
        escolaridade.
      </p>
      <br />
      <p>
        <b>Profissão e emprego: </b>Ocupação, Situação no mercado de trabalho.
      </p>
      <br />
      <br />
      <Heading level={4} color='primary'>
        DO CUIDADOR
      </Heading>
      <br />
      <p>
        <b>Identificação: </b>Nome completo, data de nascimento, CPF, CNS.
      </p>
      <br />
      <p>
        <b>Composição familiar: </b>Relação com o cidadão.
      </p>
      <br />
      <br />
      <Heading level={4} color='primary'>
        DO ACOMPANHANTE
      </Heading>
      <br />
      <p>
        <b>Identificação do acompanhante no atendimento: </b>Nome completo.
      </p>
      <br />
      <br />
      <Heading level={4} color='primary'>
        DO RESPONSÁVEL FAMILIAR
      </Heading>
      <br />
      <p>
        <b>Identificação do responsável pela residência: </b>Nome completo, e-mail, telefone, CPF, CNS.
      </p>
      <br />
      <br />
      <Heading level={4} color='primary'>
        DO RESPONSÁVEL PELA RESIDÊNCIA
      </Heading>
      <br />
      <p>
        <b>Profissão e emprego: </b>Cargo na instituição
      </p>
      <br />
      <br />
      <Heading level={4} color='primary'>
        DOS PROFISSIONAIS DE SAÚDE
      </Heading>
      <br />
      <p>
        <b>Identificação do profissional: </b>Nome completo, e-mail, telefone, CPF, CNS, No do conselho regional, sexo,
        data de nascimento, endereço.
      </p>
      <br />
      <p>
        <b>Identificação do profissional da saúde externo à UBS de instalação do PEC: </b>Nome completo, CPF.
      </p>
      <br />
      <p>
        <b>Educação e treinamento do profissional com lotação de estágio: </b>Nível do curso do estágio, Formação
        técnica, Instituição de ensino.
      </p>
      <br />
      <p>
        <b>Profissão e emprego: </b>Lotação profissional, CBO do profissional participante da atividade.
      </p>
    </TermosDeUsoHeadingSection>
  )
}
