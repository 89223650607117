import { HFlow } from 'bold-ui'
import { Form, FormDebouncedValueSpy, MonthField, TextField } from 'components/form'
import { FormState } from 'final-form'
import createDecorator from 'final-form-calculate'
import { ReferenceMonth, TransmissaoRecebimentoCnesFiltro } from 'graphql/types.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'

const meta = metaPath<TransmissaoRecebimentoCnesFilterModel>()

interface TransmissaoRecebimentoCnesFilterProps {
  initialValues: TransmissaoRecebimentoCnesFilterModel
  refetch(): Promise<any>
  onChange(values: TransmissaoRecebimentoCnesFilterModel): void
}

type TransmissaoRecebimentoCnesFilterModel = TransmissaoRecebimentoCnesFiltro

export function TransmissaoRecebimentoCnesFilter(props: TransmissaoRecebimentoCnesFilterProps) {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<TransmissaoRecebimentoCnesFilterModel>) => onChange(formState.values)

  const renderFilter = () => {
    return (
      <HFlow alignItems='center'>
        <FormDebouncedValueSpy onChange={handleChange} />
        <TextField name={meta.query} icon='zoomOutline' placeholder='Pesquise pelo CNES' />
        <MonthField name={meta.competencia} placeholder='MM/AAAA' clearable={false} />
      </HFlow>
    )
  }

  return (
    <Form<TransmissaoRecebimentoCnesFilterModel>
      initialValues={initialValues}
      render={renderFilter}
      decorators={decorators}
    />
  )
}

const decorators = [
  createDecorator({
    field: meta.competencia.absolutePath(),
    updates: {
      [meta.competencia.absolutePath()]: (value: ReferenceMonth) =>
        value ?? { month: new Date().getMonth(), year: new Date().getFullYear() },
    },
  }),
]
