import { cep, composeRules, createValidator, ErrorObject, maxLength, required } from 'util/validation'

import { LogradouroEditFormModel, VisualizacaoTerritorialBuscaFormModel } from './model'

export const validateVisualizacaoTerritorialForm = createValidator<VisualizacaoTerritorialBuscaFormModel>({
  ine: [required],
  cnes: [required],
  cep: [cep],
})

export const validateLogradouroEditForm = (cepNaoEncontrado) =>
  createValidator<LogradouroEditFormModel>(
    {
      cep: [required, cep],
      logradouro: [required, maxLength(72)],
      tipoLogradouro: [required],
    },
    (value, errors): ErrorObject<LogradouroEditFormModel> => {
      if (cepNaoEncontrado) {
        errors.cep = 'CEP não encontrado.'
      }
      errors.bairro = composeRules([required, maxLength(72)])(value.bairro?.nome)
      return errors
    }
  )
