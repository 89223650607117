/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { CheckboxProps, Select, SelectProps, useTheme } from 'bold-ui'
import { Checkbox, HFlow, SelectEmptyItem, SelectHelperMenuItem, Tag, useLocale, VFlow } from 'bold-ui'
import { defaultComponents as selectMenuDefaultComponents } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import {
  DEFAULT_SELECT_PAGE_PARAM,
  DEFAULT_SELECT_SIZE,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { LotacoesAgendaSelectDocument } from 'graphql/hooks.generated'
import { AcessoCbo, LotacoesAgendaSelectQuery, LotacoesAgendaSelectQueryVariables } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { emptyArray } from 'util/array'

export type LotacaoAgendaSelectModel = LotacoesAgendaSelectQuery['lotacoesAgenda']['content'][0]

export interface LotacaoAgendaSelectProps
  extends Omit<SelectProps<LotacaoAgendaSelectModel>, 'items' | 'itemToString'> {
  loadItemsOnOpen?: boolean
  isAgendaAd: boolean
  somenteCadastrarAgendamento?: boolean
  cbosAcesso?: AcessoCbo[]
  excludeProfissionaisIds?: ID[]
  includeEquipesComVinculo?: boolean
}

LotacaoAgendaSelect.defaultProps = {
  loadItemsOnOpen: true,
  excludeProfissionaisIds: emptyArray,
} as Partial<LotacaoAgendaSelectProps>

const extractItems = (data: LotacoesAgendaSelectQuery) => data?.lotacoesAgenda.content
const itemToString = (item: LotacaoAgendaSelectModel) => item?.profissional.nome
const renderItem = (item: LotacaoAgendaSelectModel) => (
  <VFlow vSpacing={0}>
    <b>
      {item.profissional.nome} - <Cpf value={item.profissional.cpf} />
    </b>
    <HLabel title='CBO '>{`${item.cbo.nome} - ${item.cbo.cbo2002}`}</HLabel>
    <HLabel title='Equipe '> {(item.equipe && `${item.equipe.nome} - ${item.equipe.ine}`) || 'Sem equipe'}</HLabel>
    <HFlow>
      {!item.ativo && <Tag>Inativo</Tag>}
      {!item.hasConfiguracaoAgenda && <Tag>Sem agenda</Tag>}
    </HFlow>
  </VFlow>
)
const pageParams = {
  ...DEFAULT_SELECT_PAGE_PARAM,
  size: DEFAULT_SELECT_SIZE + 1,
  sort: ['nome'],
}

export function LotacaoAgendaSelect(props: LotacaoAgendaSelectProps) {
  const {
    loadItemsOnOpen,
    isAgendaAd = false,
    somenteCadastrarAgendamento,
    cbosAcesso,
    excludeProfissionaisIds,
    includeEquipesComVinculo = false,
    ...rest
  } = props

  const locale = useLocale()
  const [incluirInativasComAgendamentosFuturos, setIncluirInativasComAgendamentosFuturos] = useState(false)

  // Adicionado pois a query deve desconsiderar o filtro da combo nos casos em que o checkbox de lotações inativas for alterado
  const [filterByInput, setFilterByInput] = useState(true)

  const { analytics } = useFirebase()
  useEffect(() => {}, [analytics, incluirInativasComAgendamentosFuturos])

  const handleIncluirInativasCheckboxChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const checked = ev.target.checked
      if (checked) analytics.logEvent('incluir_lotacoes_inativas_AG')
      setIncluirInativasComAgendamentosFuturos(checked)
      loadItemsOnOpen && setFilterByInput(false)
    },
    [analytics, loadItemsOnOpen]
  )

  const skip = useCallback((inputString) => !loadItemsOnOpen && inputString?.trim()?.length < 2, [loadItemsOnOpen])
  const variables = useCallback(
    (query: string) => ({
      input: {
        query: filterByInput ? query : null,
        isAgendaAd,
        somenteCadastrarAgendamento,
        incluirInativasComAgendamentosFuturos,
        cbosAcesso,
        excludeProfissionaisIds,
        includeEquipesComVinculo,
        pageParams,
      },
    }),
    [
      filterByInput,
      isAgendaAd,
      somenteCadastrarAgendamento,
      incluirInativasComAgendamentosFuturos,
      cbosAcesso,
      excludeProfissionaisIds,
      includeEquipesComVinculo,
    ]
  )

  const { skipping, selectProps } = useAsyncQuerySelect<
    LotacaoAgendaSelectModel,
    LotacoesAgendaSelectQuery,
    LotacoesAgendaSelectQueryVariables
  >({
    query: LotacoesAgendaSelectDocument,
    extractItems,
    variables,
    skip,
    debounceTime: 500,
    refetchOnVariablesChange: true,
  })

  return (
    <Select<LotacaoAgendaSelectModel>
      renderItem={renderItem}
      itemToString={itemToString}
      placeholder='Busque um profissional pelo seu nome, CNS, equipe ou CBO'
      onInput={() => setFilterByInput(true)}
      components={{
        Item: ({ index, ...rest }) =>
          index < DEFAULT_SELECT_SIZE ? (
            <selectMenuDefaultComponents.Item index={index} {...rest} />
          ) : (
            <MaisItensSelectMenuItem />
          ),
        EmptyItem: () =>
          !loadItemsOnOpen ? (
            <SelectHelperMenuItem>
              {skipping ? 'Digite para buscar um profissional.' : locale.select.emptyItem}
            </SelectHelperMenuItem>
          ) : (
            <SelectEmptyItem />
          ),
        AppendItem: () =>
          !somenteCadastrarAgendamento && (
            <IncluirInativasComAgendamentosFuturosCheckbox
              checked={incluirInativasComAgendamentosFuturos}
              onChange={handleIncluirInativasCheckboxChange}
            />
          ),
      }}
      {...selectProps}
      {...rest}
    />
  )
}

const MaisItensSelectMenuItem = () => (
  <SelectHelperMenuItem
    onClick={(ev) => {
      ev.stopPropagation()
      ev.preventDefault()
    }}
  >
    Mostrando somente os primeiros {DEFAULT_SELECT_SIZE} resultados. Para ver mais resultados refine sua busca.
  </SelectHelperMenuItem>
)

const IncluirInativasComAgendamentosFuturosCheckbox = (props: Pick<CheckboxProps, 'checked' | 'onChange'>) => {
  const theme = useTheme()
  return (
    <div
      data-testid='incluir-inativas-checkbox-container'
      css={css`
        padding: 0.5rem;
        background-color: ${theme.pallete.surface.background};
      `}
    >
      <Checkbox label='Mostrar lotações inativas' {...props} />
    </div>
  )
}
