import { Button, HeadingSection, HFlow, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { CheckboxField, DateField, Form, SubmitButton } from 'components/form'
import { LotesEnvioFiltro, StatusEnvio } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

export interface TransmissaoEnvioPopperProps {
  filter: LotesEnvioFiltro
  onChange(values: LotesEnvioFiltro): void
  onClose(): void
}

export const TransmissaoEnvioPopper = ({ filter, onChange, onClose }: TransmissaoEnvioPopperProps) => {
  const handleSubmit = ({ enviado, naoEnviado, iniciado, dataCriacaoFim, dataCriacaoInicio }: FormDto) => () => {
    const statusEnvio: StatusEnvio[] = []
    enviado && statusEnvio.push(StatusEnvio.TOTALMENTE_ENVIADO)
    iniciado && statusEnvio.push(StatusEnvio.ENVIANDO)
    naoEnviado && statusEnvio.push(StatusEnvio.NAO_ENVIADO)
    onChange({ ...filter, statusEnvio, dataCriacaoInicio, dataCriacaoFim })
  }

  const renderPopperForm = (formProps: FormRenderProps<FormDto>) => (
    <Box style={{ width: 384 / 16 + 'rem', height: 206 / 16 + 'rem' }}>
      <VFlow>
        <HFlow>
          <DateField name='dataCriacaoInicio' label='Data de criação (Início)' />
          <DateField name='dataCriacaoFim' label='Data de criação (Fim)' />
        </HFlow>
        <HeadingSection title='Situação de envio' level={4}>
          <HFlow hSpacing={2}>
            <CheckboxField name='enviado' label='Enviado' />
            <CheckboxField name='naoEnviado' label='Não enviado' />
          </HFlow>
        </HeadingSection>
        <HFlow justifyContent='flex-end'>
          <Button onClick={onClose} size='small' skin='outline' kind='normal'>
            Fechar
          </Button>
          <SubmitButton size='small' handleSubmit={handleSubmit(formProps.values)}>
            Filtrar
          </SubmitButton>
        </HFlow>
      </VFlow>
    </Box>
  )

  return (
    <Form<FormDto>
      render={renderPopperForm}
      initialValues={{
        dataCriacaoInicio: filter.dataCriacaoInicio,
        dataCriacaoFim: filter.dataCriacaoFim,
        iniciado:
          filter.statusEnvio.includes(StatusEnvio.ENVIANDO) ||
          filter.statusEnvio.includes(StatusEnvio.PARCIALMENTE_ENVIADO),
        naoEnviado: filter.statusEnvio.includes(StatusEnvio.NAO_ENVIADO),
        enviado: filter.statusEnvio.includes(StatusEnvio.TOTALMENTE_ENVIADO),
      }}
    />
  )
}

interface FormDto {
  dataCriacaoInicio: string
  dataCriacaoFim: string
  enviado: boolean
  naoEnviado: boolean
  iniciado: boolean
}
