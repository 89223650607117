import { Button, ButtonProps } from 'bold-ui'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

export interface ButtonLinkProps extends ButtonProps, Pick<LinkProps, 'to' | 'replace'> {}

const LinkComponent = (linkProps) => {
  const {
    to,
    replace,
    innerRef,
    type, // remove 'type="button"'
    ...other
  } = linkProps

  if (other.disabled || !to) {
    // Render as a button if disabled because '<a>' does not have a disabled state natively
    return <button ref={innerRef} type={type} {...other} />
  } else {
    return <Link {...other} to={to} replace={replace} />
  }
}

export function ButtonLink(props: ButtonLinkProps) {
  const { style } = props

  const linkStyle = {
    textDecoration: 'none',
  }

  return <Button {...props} style={[linkStyle, style]} component={LinkComponent} />
}
