import useAtmosphere from 'hooks/useAtmosphere'

interface ImportacaoCnesListenerProps {
  municipio: ID
  onImportacaoMessage(): void
}

export const ImportacaoCnesListener = (props: ImportacaoCnesListenerProps) => {
  const { municipio, onImportacaoMessage } = props

  useAtmosphere({ topic: `importar-cnes/${municipio}`, onMessage: onImportacaoMessage })
  return null
}
