import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

interface MudancasNoTermoDeUsoSectionProps {
  versaoAndDataAtualizacao: {
    dataAtualizacao: string
    versao: string
    formattedString: string
  }
}

export const MudancasNoTermoDeUsoSection = (props: MudancasNoTermoDeUsoSectionProps) => {
  const { versaoAndDataAtualizacao } = props

  return (
    <TermosDeUsoHeadingSection title='8. Mudanças no Termo de Uso' id='mudancas_no_termo_de_uso'>
      <p>
        <b>Este Termo de Uso pode ser alterado?</b>
      </p>
      <br />
      <p>
        A presente versão {versaoAndDataAtualizacao.versao} deste Termo de Uso foi atualizada pela última vez em:{' '}
        {versaoAndDataAtualizacao.dataAtualizacao}.
      </p>
      <br />
      <p>
        O editor se reserva o direito de modificar no site, a qualquer momento, as presentes normas, especialmente para
        adaptá-las às evoluções do Prontuário Eletrônico do Cidadão (PEC) do Sistema e-SUS APS, seja pela
        disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.
      </p>
      <br />
      <p>
        Qualquer alteração e/ou atualização do Termos de Uso e da Política de Privacidade passará a vigorar a partir da
        data de sua publicação no sítio do serviço e deverá ser integralmente observada pelos Usuários.
      </p>
    </TermosDeUsoHeadingSection>
  )
}
