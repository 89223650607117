import { useCidadaoDeclaracaoComparecimentoQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useParams } from 'react-router'

import { GerarDeclaracaoComparecimentoModalForm } from './GerarDeclaracaoComparecimentoModalForm'

interface UrlParams {
  atendId: string
}

export function GerarDeclaracaoComparecimentoView() {
  const { atendId } = useParams<UrlParams>()
  const { data } = useCidadaoDeclaracaoComparecimentoQuery({ variables: { id: atendId } })

  if (!data?.atendimento) {
    return null
  }
  return <GerarDeclaracaoComparecimentoModalForm {...data.atendimento} />
}
