import { NeuroFaixaEtariaEnum, SexoEnum } from 'graphql/types.generated'
import moment from 'moment'
import { createParameters, Percentil } from 'util/atendimento/percentis/calculatePercentil'

import { AvaliacaoDesenvolvimentoModel, MedicaoAnteriorPuericulturaModel } from '../model'
import {
  avaliacoesListWithStatusAusente,
  avaliacoesListWithStatusNaoAvaliado,
  avaliacoesListWithStatusPresente,
  avaliacoesListWithStatusPresenteComAtraso,
} from '../util'
import { marcoMessages } from './messages'

export interface AlertR1Props {
  cidadaoDataNascimento: string
  cidadaoSexo: SexoEnum
  medicaoPerimetroCefalico: MedicaoAnteriorPuericulturaModel
}

export const messageR1 = (props: AlertR1Props) => {
  const { cidadaoDataNascimento, cidadaoSexo, medicaoPerimetroCefalico } = props

  const idadeDuranteMedicao = moment
    .duration(moment(medicaoPerimetroCefalico.dataMedicao).diff(cidadaoDataNascimento, 'days'), 'days')
    .asYears()

  if (idadeDuranteMedicao >= 0) {
    const sexoValue = cidadaoSexo === SexoEnum.MASCULINO ? SexoEnum.MASCULINO : SexoEnum.FEMININO
    const parameters = createParameters(sexoValue, idadeDuranteMedicao, 'perimetroCefalico')
    const ZscoreFinal = parameters.calcularZscoreFinal(medicaoPerimetroCefalico.valorMedicao as number)

    if (ZscoreFinal > Percentil.sd2pos) return `Perímetro cefálico maior do que o escore +2Z.`
    if (ZscoreFinal < Percentil.sd2neg) return `Perímetro cefálico menor do que o escore -2Z.`
  }
}

export const messageR2 = (alteracoesFenotipicas: AvaliacaoDesenvolvimentoModel[]) => {
  const alteracoesFenotipicasPresenteSize = avaliacoesListWithStatusPresente(alteracoesFenotipicas)?.length
  if (alteracoesFenotipicasPresenteSize > 2)
    return `${alteracoesFenotipicasPresenteSize} alterações fenotípicas presentes.`
}

export const messageR3 = (
  marcoFaixaEtariaAnterior: AvaliacaoDesenvolvimentoModel[],
  marcoFaixaEtariaAtual: AvaliacaoDesenvolvimentoModel[]
) => {
  let reflexosNeuroMessage: string = undefined
  if (
    marcoFaixaEtariaAnterior?.[0].faixaEtariaEnum === NeuroFaixaEtariaEnum.PRIMEIROS_15_DIAS &&
    marcoFaixaEtariaAtual?.[0].faixaEtariaEnum === NeuroFaixaEtariaEnum.MES_1
  ) {
    reflexosNeuroMessage = fraseR3ComReflexosNeurologicos(marcoFaixaEtariaAnterior)
  } else if (marcoFaixaEtariaAtual?.[0].faixaEtariaEnum === NeuroFaixaEtariaEnum.PRIMEIROS_15_DIAS) {
    reflexosNeuroMessage = fraseR3ComReflexosNeurologicos(marcoFaixaEtariaAtual)
  }

  if (marcoFaixaEtariaAtual?.[0].faixaEtariaEnum === NeuroFaixaEtariaEnum.MES_1) {
    return [reflexosNeuroMessage, montarFraseR3(marcoFaixaEtariaAtual, marcoFaixaEtariaAtual)].join(' ').trim()
  } else if (reflexosNeuroMessage) {
    return reflexosNeuroMessage
  } else if (marcoFaixaEtariaAnterior?.[0].faixaEtariaEnum !== NeuroFaixaEtariaEnum.PRIMEIROS_15_DIAS) {
    return montarFraseR3(marcoFaixaEtariaAnterior, marcoFaixaEtariaAtual)
  }
}

const montarFraseR3 = (
  marcoFaixaEtariaAnterior: AvaliacaoDesenvolvimentoModel[],
  marcoFaixaEtariaAtual: AvaliacaoDesenvolvimentoModel[]
): string => {
  const descricaoFaixaEtariaAnterior =
    marcoFaixaEtariaAnterior?.length && marcoFaixaEtariaAnterior?.[0].descricaoFaixaEtaria

  const marcosPresenteComAtrasoSize = avaliacoesListWithStatusPresenteComAtraso(marcoFaixaEtariaAnterior)?.length
  const marcosAusentesSize = avaliacoesListWithStatusAusente(marcoFaixaEtariaAnterior)?.length
  const validarR3 =
    marcoFaixaEtariaAtual === marcoFaixaEtariaAnterior ||
    avaliacoesListWithStatusNaoAvaliado(marcoFaixaEtariaAtual).length === marcoFaixaEtariaAtual.length

  if (!validarR3) {
    return undefined
  } else if (marcosAusentesSize && marcosPresenteComAtrasoSize) {
    return `${marcoMessages(marcosAusentesSize).desenvolvimentoAusenteR3} e ${
      marcoMessages(marcosPresenteComAtrasoSize).desenvolvimentoPresenteComAtrasoR3
    } ${descricaoFaixaEtariaAnterior}.`
  } else if (marcosAusentesSize) {
    return `${
      marcoMessages(marcosAusentesSize).desenvolvimentoAusenteR3
    } na faixa etária ${descricaoFaixaEtariaAnterior}.`
  } else if (marcosPresenteComAtrasoSize) {
    return `${
      marcoMessages(marcosPresenteComAtrasoSize).desenvolvimentoPresenteComAtrasoR3
    } ${descricaoFaixaEtariaAnterior}.`
  }
}

const fraseR3ComReflexosNeurologicos = (marcoFaixaEtariaAnterior: AvaliacaoDesenvolvimentoModel[]): string => {
  const reflexosAusentesSize = avaliacoesListWithStatusAusente(marcoFaixaEtariaAnterior)?.length
  if (reflexosAusentesSize) return marcoMessages(reflexosAusentesSize).reflexosAusenteR3
}
