import { TipoAtendimentoProfissional } from 'graphql/types.generated'

export const atendimentoName = (
  tipo: TipoAtendimentoProfissional,
  isObservacaoAndAuxiliarDaObservacao: boolean = false
): string => {
  switch (tipo) {
    case TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO:
      return 'odontológico'
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS:
      return 'de procedimentos'
    case TipoAtendimentoProfissional.VACINACAO:
      return 'vacinação'
    default: {
      if (isObservacaoAndAuxiliarDaObservacao) {
        return 'de procedimentos'
      }

      return 'individual'
    }
  }
}
