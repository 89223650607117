import api from 'api'
import { BuscaAtivaVacinacaoInput } from 'graphql/types.generated'
import { defaultHandleError } from 'util/downloadFile'

export const downloadImpressaoBuscaAtivaVacinacao = (input: BuscaAtivaVacinacaoInput) => {
  return api.buscaAtivaVacinacao.imprimir(input).catch(defaultHandleError)
}

export const downloadCSVBuscaAtivaVacinacao = (input: BuscaAtivaVacinacaoInput) => {
  return api.buscaAtivaVacinacao.exportarCSV(input).catch(defaultHandleError)
}
