/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid } from 'bold-ui'
import { ButtonLink } from 'components/route'
import { Fragment } from 'react'
import { useField } from 'react-final-form'
import { useRouteMatch } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { MetaPath } from 'util/metaPath'

import { AvaliacoesPresentesDescription } from '../components/AvaliacoesPresentesDescription'
import { AvaliacaoDesenvolvimentoModel } from '../model'
import { AlteracaoFenotipicaModal } from './AlteracaoFenotipicaModal'

export interface AlteracoesFenotipicasModel {
  alteracoesFenotipicas: AvaliacaoDesenvolvimentoModel[]
}

interface AlteracaoFenotipicaViewProps {
  name: MetaPath<AlteracoesFenotipicasModel>
}

export function AlteracaoFenotipicaView(props: AlteracaoFenotipicaViewProps) {
  const { name } = props
  const match = useRouteMatch()

  const {
    input: { value, onChange },
  } = useField<AlteracoesFenotipicasModel>(name.absolutePath())

  return (
    <Fragment>
      <Grid alignItems='center'>
        <Cell size={3}>
          <ButtonLink
            style={css`
              width: 100%;
              text-align: center;
            `}
            size='small'
            kind='primary'
            to={`${match.url}/puericultura/alteracao-fenotipica`}
          >
            Avaliar alterações fenotípicas
          </ButtonLink>
        </Cell>
        <Cell size={9}>
          <AvaliacoesPresentesDescription
            title='Alterações fenotípicas:'
            placeholder='Nenhuma alteração fenotípica presente'
            avaliacoes={value.alteracoesFenotipicas}
          />
        </Cell>
      </Grid>
      <Switch>
        <Route path={`${match.url}/puericultura/alteracao-fenotipica`}>
          <AlteracaoFenotipicaModal initialValues={value} updateAlteracaoFenotipica={onChange} />
        </Route>
      </Switch>
    </Fragment>
  )
}
