import { Button, DropdownItem, HFlow, Icon, Icons, Text, Tooltip } from 'bold-ui'
import React, { MouseEventHandler } from 'react'

export interface TableRowMenuButtonProps {
  icon: Icons
  text: string
  asDropdownItem?: boolean
  disabled?: boolean
  onClick: MouseEventHandler<HTMLElement>
}

export function TableRowMenuButton(props: TableRowMenuButtonProps) {
  const { icon, text, asDropdownItem: insideDropdown, disabled = false, onClick } = props
  return insideDropdown ? (
    <DropdownItem onClick={onClick} disabled={disabled}>
      <HFlow>
        <Icon icon={icon} /> <Text>{text}</Text>
      </HFlow>
    </DropdownItem>
  ) : (
    <Tooltip text={text}>
      <Button size='small' skin='ghost' onClick={onClick} disabled={disabled}>
        <Icon icon={icon} />
      </Button>
    </Tooltip>
  )
}
