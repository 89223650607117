import { DateRange } from 'bold-ui'
import { CiapCidSelectModel } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField'
import { OpcaoSelecionadaRelatorioGerencialEnum, UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'

import { ModeloPersonalizadoModel } from '../modelo-personalizado/model-modeloPersonalizado'

export interface RelatorioGerencialFiltrosFormModel {
  unidade: UnidadePeriodoRelatorioGerencialEnum
  opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum
  periodo?: DateRange
  ciapsCids: CiapCidSelectModel[]
  gruposCondicoesPrioritarios: GrupoCondicaoSelectModel[]
  modeloPersonalizado?: ModeloPersonalizadoModel
}

export const RELATORIO_GERENCIAL_FILTROS_FORM_DEFAULT_VALUES = {
  opcaoSelecionada: OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA,
  unidade: UnidadePeriodoRelatorioGerencialEnum.MES,
  ciapsCids: [],
  gruposCondicoesPrioritarios: [],
  periodo: null,
  modeloPersonalizado: null,
}
