/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DateRange } from 'bold-ui'
import DateRangeField from 'components/form/field/DateRangeField'
import MonthRangeField from 'components/form/field/MonthRangeField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { lastMonthAsReferenceMonth } from 'util/date/converter'
import { Meta } from 'util/metaPath'

interface RelatorioGerencialRangeFieldProps {
  unidadePeriodoSelecionada: UnidadePeriodoRelatorioGerencialEnum
  name: Meta<DateRange>
  disabled: boolean
  onChange: () => void
}

export function RelatorioGerencialRangeField(props: RelatorioGerencialRangeFieldProps) {
  const { name, unidadePeriodoSelecionada, disabled, onChange } = props

  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()

  const { input } = usePecField({
    name,
    subscription: { value: true },
  })

  const periodo = input.value as DateRange

  if (unidadePeriodoSelecionada === UnidadePeriodoRelatorioGerencialEnum.DIA) {
    return (
      <DateRangeField
        name={name}
        value={periodo}
        disabled={disabled}
        maxDate={now}
        style={styles.field}
        clearable
        onChange={onChange}
      />
    )
  } else if (unidadePeriodoSelecionada === UnidadePeriodoRelatorioGerencialEnum.SEMANA) {
    return (
      <DateRangeField
        name={name}
        value={periodo}
        disabled={disabled}
        maxDate={now}
        style={styles.field}
        clearable
        onlyWeeks
        onChange={onChange}
      />
    )
  } else {
    return (
      <MonthRangeField
        name={name}
        disabled={disabled}
        maxMonth={lastMonthAsReferenceMonth(now)}
        style={styles.field}
        clearable
        onChange={onChange}
      />
    )
  }
}

const styles = {
  field: css`
    max-width: 25rem;
  `,
}
