import { useMemo, useState } from 'react'

/**
 * Based on https://github.com/streamich/react-use
 */
export interface Actions<T extends object> {
  set: <K extends keyof T>(key: K, value: T[K]) => void
  setAll: (newMap: T) => void
  remove: <K extends keyof T>(key: K) => void
  reset: () => void
}

export const useMap = <T extends object = any>(initialMap: T = {} as T): [T, Actions<T>] => {
  const [map, set] = useState<T>(initialMap)

  const actions = useMemo<Actions<T>>(
    () => ({
      set: (key, entry) => {
        set((prevMap) => ({
          ...prevMap,
          [key]: entry,
        }))
      },
      setAll: (newMap: T) => {
        set(newMap)
      },
      remove: (key) => {
        set((prevMap) => {
          const { [key]: omit, ...rest } = prevMap
          return rest as T
        })
      },
      reset: () => set(initialMap),
    }),
    [initialMap, set]
  )

  return [map, actions]
}
