import { VFlow } from 'bold-ui'
import { ParteBucalEnum, TipoParteBucalEnum } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'

import { EvolucoesOdontoTableWrapper } from '../../evolucoes-odonto-table/EvolucoesOdontoTableWrapper'
import { isDenteDeciduo } from '../../util-EvolucoesOdontologicasPlano'
import { OdontogramaUseMode, OdontogramaViewMode } from './model'
import { OdontogramaField, OdontogramaFieldModel } from './OdontogramaField'

interface OdontogramaFieldWrapperProps {
  name: MetaPath<OdontogramaFieldModel>
  prontuarioId: ID
  cidadaoDataNascimento: LocalDate
  dataInicioAtendimento: LocalDate
}

export const OdontogramaFieldWrapper = (props: OdontogramaFieldWrapperProps) => {
  const { name, cidadaoDataNascimento, dataInicioAtendimento, prontuarioId } = props

  const [useMode, setUseMode] = useState<OdontogramaUseMode>(OdontogramaUseMode.INSERTING)
  const [viewMode, setViewMode] = useState<OdontogramaViewMode>(OdontogramaViewMode.DENTES)
  const [showDeciduos, setShowDeciduos] = useState<boolean>(false)
  const [activeParteBucal, setActiveParteBucal] = useState<ParteBucalEnum>()
  const [activeRef, setActiveRef] = useState<{ ref: HTMLButtonElement | SVGSVGElement }>()
  const [isClickFromOutside, setIsClickFromOutside] = useState(false)

  const onEditDenteOrArcada = (parteBucal: ParteBucalEnum, tipoParteBucal: TipoParteBucalEnum) => {
    setUseMode(OdontogramaUseMode.INSERTING)

    if (tipoParteBucal === TipoParteBucalEnum.DENTE) {
      setViewMode(OdontogramaViewMode.DENTES)

      if (isDenteDeciduo(parteBucal)) {
        setShowDeciduos(true)
      }
    } else if (tipoParteBucal === TipoParteBucalEnum.ARCADA) {
      setViewMode(OdontogramaViewMode.ARCADAS)
    }

    setActiveParteBucal(parteBucal)
    setIsClickFromOutside(true)
  }

  const isActiveAndClickFromOutside = activeParteBucal && !isUndefinedOrNull(activeRef?.ref) && isClickFromOutside
  const refOffsetTop = activeRef?.ref?.parentElement?.offsetTop

  useEffect(() => {
    if (isActiveAndClickFromOutside) {
      setIsClickFromOutside(false)
      window.scrollTo({ behavior: 'smooth', top: refOffsetTop - 160 })
    }
  }, [refOffsetTop, isActiveAndClickFromOutside])

  useEffect(() => {
    if (isUndefinedOrNull(activeParteBucal)) {
      setActiveRef(null)
    }
  }, [activeParteBucal])

  return (
    <VFlow>
      <OdontogramaField
        name={name}
        prontuarioId={prontuarioId}
        cidadaoDataNascimento={cidadaoDataNascimento}
        dataInicioAtendimento={dataInicioAtendimento}
        activeParteBucal={activeParteBucal}
        showDeciduos={showDeciduos}
        viewMode={viewMode}
        useMode={useMode}
        activeRef={activeRef}
        setActiveParteBucal={setActiveParteBucal}
        setShowDeciduos={setShowDeciduos}
        setViewMode={setViewMode}
        setUseMode={setUseMode}
        setActiveRef={setActiveRef}
      />

      <EvolucoesOdontoTableWrapper
        prontuarioId={prontuarioId}
        cidadaoDataNascimento={cidadaoDataNascimento}
        dataInicioAtendimento={dataInicioAtendimento}
        isEditingDenteOrArcada={!isUndefinedOrNull(activeParteBucal)}
        onEditDenteOrArcada={onEditDenteOrArcada}
      />
    </VFlow>
  )
}
