import { format } from 'date-fns'
import { consistentPeriod, periodBeforeEqualToday, required } from 'util/validation'
import { createValidator, ErrorObject } from 'util/validation/validator'

import { RelatorioInconsistenciasFormModel } from './model'

export const validator = createValidator<RelatorioInconsistenciasFormModel>(
  {
    periodo: [required, periodBeforeEqualToday],
  },
  (values: RelatorioInconsistenciasFormModel, errors: ErrorObject<RelatorioInconsistenciasFormModel>) => {
    if (!errors.periodo) errors.periodo = required(values?.periodo?.startDate)
    if (!errors.periodo) errors.periodo = required(values?.periodo?.endDate)
    if (!errors.periodo) {
      const dataInicio = values.periodo?.startDate && format(values.periodo.startDate, 'yyyy-MM-dd')
      const dataFim = values.periodo?.endDate && format(values.periodo.endDate, 'yyyy-MM-dd')
      const errorDatePeriodRule = consistentPeriod(dataInicio, dataFim)
      if (errorDatePeriodRule) {
        errors.periodo = errorDatePeriodRule.msgLblDataInicial
      }
    }
    return errors
  }
)
