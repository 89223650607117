/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Text, Tooltip } from 'bold-ui'
import { useEffect, useRef, useState } from 'react'

import { HistoricoAtendimentoAdvancedFilterFormModel } from '../../model/historicoFilterFormModel'
export interface HistoricoAtendimentoFilterItemProps {
  label: string
  text: string
  filters: HistoricoAtendimentoAdvancedFilterFormModel
}

export default function HistoricoAtendimentoFilterItem(props: HistoricoAtendimentoFilterItemProps) {
  const { label, text, filters } = props
  const [isOverflowing, setIsOverflowing] = useState(false)
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    setIsOverflowing(ref?.current?.scrollWidth > ref?.current?.clientWidth)
  }, [ref, text, filters])

  const content = (
    <Cell style={styles.separator}>
      <Text style={styles.label}>{`${label}:`}</Text>
      <div ref={ref} css={styles.text}>
        {text.titleCase()}
      </div>
    </Cell>
  )

  return isOverflowing ? <Tooltip text={text.titleCase()}>{content}</Tooltip> : content
}

const styles = {
  separator: css`
    display: flex;
    align-items: center;
    flex-grow: 0;
    overflow: hidden;
    &:after {
      display: block;
      content: ' | ';
      margin-left: 0.25rem;
    }
    &:last-child:after {
      content: none;
    }
  `,
  label: css`
    font-weight: bold;
    white-space: nowrap;
    margin-right: 0.25rem;
  `,
  text: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
}
