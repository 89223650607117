/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'

import { EvolucaoDenteSupranumerarioModel } from './components/odontograma-field/dentes-supranumerarios/DentesSupranumerariosField'
import { EditarDentesSupranumerariosModal } from './components/odontograma-field/dentes-supranumerarios/EditarDenteSupranumerarioModal'
import { EditarOutrosModal } from './components/odontograma-field/outros-field/EditarOutrosModal'
import { OutrosFieldModel } from './components/odontograma-field/outros-field/OutrosField'
import { URL_EDITAR_OUTROS, URL_EDITAR_SUPRANUMERARIOS } from './evolucoes-odonto-table/model'

interface EvolucoesOdontologicasRootViewProps {
  cidadaoDataNascimento: LocalDate
  dataInicioAtendimento: LocalDate
  outrosValues: OutrosFieldModel[]
  dentesSupranumerariosValues: EvolucaoDenteSupranumerarioModel[]
  onUpdateOutros: (value: OutrosFieldModel) => void
  onUpdateDentesSupranumerarios: (index: number, value: EvolucaoDenteSupranumerarioModel) => void
}

export const EvolucoesOdontologicasRootView = (props: EvolucoesOdontologicasRootViewProps) => {
  const {
    cidadaoDataNascimento,
    dataInicioAtendimento,
    outrosValues,
    dentesSupranumerariosValues,
    onUpdateOutros,
    onUpdateDentesSupranumerarios,
  } = props

  const match = useRouteMatch()
  const history = useHistory()

  return (
    <Fragment>
      <Route path={`${match.url}${URL_EDITAR_OUTROS}:id`}>
        <EditarOutrosModal
          evolucoesAtuais={outrosValues}
          onSubmit={onUpdateOutros}
          onClose={() => {
            history.push(match.url.split(URL_EDITAR_OUTROS)[0])
          }}
        />
      </Route>
      <Route path={`${match.url}${URL_EDITAR_SUPRANUMERARIOS}:id`}>
        <EditarDentesSupranumerariosModal
          cidadaoDataNascimento={cidadaoDataNascimento}
          dataAtendimento={dataInicioAtendimento}
          evolucoesAtuais={dentesSupranumerariosValues}
          onSubmit={onUpdateDentesSupranumerarios}
          onClose={() => {
            history.push(match.url.split(URL_EDITAR_SUPRANUMERARIOS)[0])
          }}
        />
      </Route>
    </Fragment>
  )
}
