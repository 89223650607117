import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import React from 'react'

export interface RelatorioGerencialUnidadePeriodoSelectFieldProps
  extends Omit<SelectFieldProps<UnidadePeriodoRelatorioGerencialEnum>, 'items' | 'itemToString'> {}

const items: UnidadePeriodoRelatorioGerencialEnum[] = Object.values(UnidadePeriodoRelatorioGerencialEnum)

const unidadeMedidaLabel: Record<UnidadePeriodoRelatorioGerencialEnum, string> = {
  [UnidadePeriodoRelatorioGerencialEnum.DIA]: 'Personalizado',
  [UnidadePeriodoRelatorioGerencialEnum.SEMANA]: 'Semanal',
  [UnidadePeriodoRelatorioGerencialEnum.MES]: 'Mensal',
}

const itemToString = (item: UnidadePeriodoRelatorioGerencialEnum) => unidadeMedidaLabel[item]

export function RelatorioGerencialUnidadePeriodoSelectField(props: RelatorioGerencialUnidadePeriodoSelectFieldProps) {
  return (
    <SelectField<UnidadePeriodoRelatorioGerencialEnum>
      items={items}
      itemToString={itemToString}
      clearable={false}
      {...props}
    />
  )
}
