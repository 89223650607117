import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import { FileUploader } from 'bold-ui'
import React from 'react'
export type UploadRequestType = (data: FormData, requestConfig: AxiosRequestConfig) => Promise<any>

export interface FileUploaderProps {
  accept?: string
  disabled?: boolean
  maxSize?: number
  uploadRequest: UploadRequestType
  value?: any
  text?: string
}

interface FileUploaderState {
  error: boolean
  uploadProgress?: number
  selectedFile?: any
  uploading: boolean
}

export class FileUploaderComponent extends React.Component<FileUploaderProps, FileUploaderState> {
  static defaultProps: Partial<FileUploaderProps> = {
    text: 'Clique para importar ou arraste os arquivos',
  }

  private cancelTokenSource: CancelTokenSource

  constructor(props, context?) {
    super(props, context)

    this.state = {
      error: false,
      uploading: false,
      uploadProgress: 0,
    }
  }

  handleUpload = (files: File[]) => {
    const file = files && files[0]

    if (file && file.size > 0) {
      const data = new FormData()
      data.append('file', file)

      this.cancelTokenSource = axios.CancelToken.source()

      this.setState({ selectedFile: file, uploading: true })

      const config: AxiosRequestConfig = {
        cancelToken: this.cancelTokenSource.token,
        onUploadProgress: (progressEvent: ProgressEvent) => {
          this.setState({
            uploadProgress: (progressEvent.loaded / progressEvent.total) * 100,
          })
        },
      }

      this.props
        .uploadRequest(data, config)
        .then(result => {
          this.setState({ uploading: false })
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.setState({ error: false, selectedFile: undefined, uploading: false })
          } else {
            this.setState({ error: true, uploading: false })
          }
        })
    }
  }

  render() {
    const { text, accept, maxSize, disabled } = this.props
    return (
      <FileUploader
        disabled={disabled}
        file={
          this.state.selectedFile
            ? {
                progress: this.state.uploadProgress,
                selectedFile: this.state.selectedFile,
                uploading: this.state.uploading,
                error: this.state.error,
              }
            : undefined
        }
        onDropAccepted={this.handleUpload}
        maxSize={maxSize}
        text={text}
        accept={accept}
      />
    )
  }
}
