import { ParteBucalEnum } from 'graphql/types.generated'
import { compact, groupBy } from 'lodash'
import { isObjectDeepEmpty } from 'util/object'

import {
  getPeriogramaArcadas,
  getPeriogramaCompletoCondicaoDefaultValue,
  getPeriogramaCompletoDente,
  getPeriogramaCompletoDenteFaces,
  getPeriogramaCompletoFace,
  PeriogramaCompletoDenteValuesModel,
  PeriogramaCompletoFace,
  PeriogramaCompletoQuadrante,
  PeriogramaCompletoTableModel,
} from '../model'

const arcadas = getPeriogramaArcadas()

export const getDenteCurrentValue = (
  dente: ParteBucalEnum,
  values: Partial<Record<ParteBucalEnum, PeriogramaCompletoDenteValuesModel>>
): PeriogramaCompletoDenteValuesModel => values?.[dente]

export const getDentesAusentesByQuadrantes = (
  values: PeriogramaCompletoTableModel
): Partial<Record<PeriogramaCompletoQuadrante, ParteBucalEnum[]>> =>
  groupBy(
    values &&
      compact(
        Object.entries(values)?.map(
          ([dente, entries]: [ParteBucalEnum, PeriogramaCompletoDenteValuesModel]) => entries.ausente && dente
        )
      ),
    (dente) => getPeriogramaCompletoDente(dente).quadrante
  )

const getDefaultValuesOfCondicoesByFace = (face: PeriogramaCompletoFace, dente: ParteBucalEnum) =>
  Object.fromEntries(
    compact(
      getPeriogramaCompletoFace(face).condicoes.map((condicao) => {
        const defaultValue = getPeriogramaCompletoCondicaoDefaultValue(condicao, dente)

        return defaultValue && [condicao, defaultValue]
      })
    )
  )

export const populateDenteWithDefaultValue = (denteEnum: ParteBucalEnum): PeriogramaCompletoDenteValuesModel => {
  const faces = getPeriogramaCompletoDenteFaces(denteEnum)

  return {
    ausente: false,
    values: {
      [faces.VESTIBULAR]: getDefaultValuesOfCondicoesByFace(faces.VESTIBULAR, denteEnum),
      [faces.INTERNA]: getDefaultValuesOfCondicoesByFace(faces.INTERNA, denteEnum),
    },
  }
}

export const getDentesAsIntegrosByArcada = (
  arcada: ParteBucalEnum,
  values: Partial<Record<ParteBucalEnum, PeriogramaCompletoDenteValuesModel>>
): PeriogramaCompletoTableModel => {
  const dentes = arcadas[arcada]

  const newValues = Object.fromEntries(
    compact(
      dentes.map(
        (dente) =>
          isObjectDeepEmpty(getDenteCurrentValue(dente, values)) && [dente, populateDenteWithDefaultValue(dente)]
      )
    )
  )

  return !isObjectDeepEmpty(newValues) && { ...values, ...newValues }
}
