import { Alert, Cell, Grid } from 'bold-ui'
import React from 'react'

import { HorusCadastroFormPopper } from './HorusCadastroFormPopper'
import { PermissionToEditHorus } from './HorusTable'

export interface HorusTableHeaderProps {
  unidadeSaudeId?: ID
  consultaHorusAtiva: boolean
  maxCincoUnidadeConsulta: boolean
  permissionToEditHorus: PermissionToEditHorus
  reload(): Promise<any>
}
export function HorusTableHeader(props: HorusTableHeaderProps) {
  const { consultaHorusAtiva, reload } = props

  return (
    <Grid justifyContent='space-between'>
      {!consultaHorusAtiva ? (
        <Cell size={6}>
          <Alert type='info' inline>
            Conexão com Hórus está desabilitada na instalação
          </Alert>
        </Cell>
      ) : (
        <Cell size={6} />
      )}
      <Cell>
        <HorusCadastroFormPopper
          unidadeSaudeId={props.unidadeSaudeId}
          reload={reload}
          consultaHorusAtiva={props.consultaHorusAtiva}
          maxCincoUnidadeConsulta={props.maxCincoUnidadeConsulta}
          permissionToEditHorus={props.permissionToEditHorus}
        />
      </Cell>
    </Grid>
  )
}
