import { Cell, Grid, HeadingSection, InfoLabel, VFlow } from 'bold-ui'
import { EnderecoSection } from 'components/endereco/EnderecoSection'
import { Cnpj, Telefone } from 'components/label'
import { UnidadeSaudeDetailQuery } from 'graphql/types.generated'
import React from 'react'
import { titleCase } from 'util/strConversor'

export interface UnidadeSaudeInformacoesViewProps {
  unidadeSaude: UnidadeSaudeDetailQuery['unidadeSaude']
}

export function UnidadeSaudeInformacoesView(props: UnidadeSaudeInformacoesViewProps) {
  const { unidadeSaude } = props

  if (!unidadeSaude) {
    return null
  }

  return (
    <VFlow>
      <HeadingSection level={2} title='Identificação'>
        <Grid wrap>
          <Cell size={6} data-cy='UnidadeSaudeInformacoesView.nome'>
            <InfoLabel title='Nome'>{titleCase(unidadeSaude.nome)}</InfoLabel>
          </Cell>
          <Cell size={6} data-cy='UnidadeSaudeInformacoesView.ativo'>
            <InfoLabel title='Ativa'>{unidadeSaude.ativo ? 'Sim' : 'Não'}</InfoLabel>
          </Cell>
          <Cell size={6} data-cy='UnidadeSaudeInformacoesView.cnpj'>
            <InfoLabel title='CNPJ'>
              <Cnpj value={unidadeSaude.cnpj} />
            </InfoLabel>
          </Cell>
          <Cell size={6} data-cy='UnidadeSaudeInformacoesView.cnes'>
            <InfoLabel title='CNES'>{unidadeSaude.cnes}</InfoLabel>
          </Cell>
          <Cell size={6} data-cy='UnidadeSaudeInformacoesView.tipoUnidade'>
            <InfoLabel title='Tipo de unidade'>{titleCase(unidadeSaude.tipo.descricao)}</InfoLabel>
          </Cell>
          <Cell size={6} data-cy='UnidadeSaudeInformacoesView.complexidade'>
            <InfoLabel title='Complexidade'>
              {unidadeSaude.complexidades
                .map((comp) => titleCase(comp.nome))
                .reduce((prev, curr) => prev + ', ' + curr)}
            </InfoLabel>
          </Cell>
          <Cell size={6}>
            <InfoLabel title='Subtipo'>{unidadeSaude.subtipo?.descricao || '-'}</InfoLabel>
          </Cell>
          <Cell size={6}>
            <InfoLabel title='Telefone 1' data-cy='UnidadeSaudeInformacoesView.telefone1'>
              <Telefone value={unidadeSaude.telefone1} />
            </InfoLabel>
          </Cell>
          <Cell size={6}>
            <InfoLabel title='Telefone 2' data-cy='UnidadeSaudeInformacoesView.telefone2'>
              <Telefone value={unidadeSaude.telefone2} />
            </InfoLabel>
          </Cell>
          <Cell size={6}>
            <InfoLabel title='Fax' data-cy='UnidadeSaudeInformacoesView.fax'>
              <Telefone value={unidadeSaude.fax} />
            </InfoLabel>
          </Cell>
        </Grid>
      </HeadingSection>
      <EnderecoSection endereco={unidadeSaude.endereco} title='Endereço' />
    </VFlow>
  )
}
