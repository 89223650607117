import { green } from 'bold-ui/lib/styles/colors'
import { useAcompanhamentoIdosoCardQuery } from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'

import { ProntuarioCard } from '../ProntuarioCard'
import { AcompanhamentoIdosoCard } from './AcompanhamentoIdosoCard'

interface AcompanhamentoIdosoSectionProps {
  prontuarioId: ID
  cidadaoId: ID
}

export function AcompanhamentoIdosoSection(props: AcompanhamentoIdosoSectionProps) {
  const { prontuarioId, cidadaoId } = props
  const [canRender, setCanRender] = useState(false)

  const { data, loading } = useAcompanhamentoIdosoCardQuery({
    variables: {
      acompanhamentoIdosoQueryInput: {
        prontuarioId,
        cidadaoId,
      },
    },
  })

  useEffect(() => {
    data?.ultimaAvaliacaoMultidimensional && setCanRender(true)
  }, [data])

  return (
    <ProntuarioCard
      title='Acompanhamento do idoso'
      titleBackgroundColor={green.c90}
      tooltip={!canRender && 'Nenhuma avaliação multidimensional registrada'}
    >
      <AcompanhamentoIdosoCard data={data} loading={loading} canRender={canRender} />
    </ProntuarioCard>
  )
}
