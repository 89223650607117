/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, Grid, HeadingSection, HFlow, Link, VFlow } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { CpfField, Form, FormRenderProps, SubmitButton } from 'components/form'
import { ChaveField } from 'components/form/field/ChaveField'
import { ContraChaveField } from 'components/form/field/ContraChaveField'
import { FormFooter } from 'components/form/FormFooter'
import { useApolloClient } from 'graphql/hooks'
import { ChaveByCpfDocument } from 'graphql/hooks.generated'
import { Fragment } from 'react'
import { useHistory } from 'react-router'
import { onlyNumbers } from 'util/mask'
import { metaPath } from 'util/metaPath'
import { cpf, createValidator, required } from 'util/validation'

export interface ContraChaveFormModel {
  cpf: string
  chave?: string
  contraChave?: string
}

const validator = createValidator<ContraChaveFormModel>({
  cpf: [cpf, required],
  contraChave: required,
})

const path = metaPath<ContraChaveFormModel>()

export interface ContraChaveFormProps {
  isEditAdmin: boolean
  onSubmit(values: ContraChaveFormModel): any
}

export default function ContraChaveForm(props: ContraChaveFormProps) {
  const { isEditAdmin, onSubmit } = props
  const client = useApolloClient()
  const handleRejection = useErrorHandler()
  const history = useHistory()

  const onCancelarClicked = () => history.push('/')

  const onVoltarClicked = () => history.push('/reset')

  const renderForm = (formProps: FormRenderProps<ContraChaveFormModel>) => {
    const calculateChave = (e) => {
      if (!formProps.errors.cpf) {
        client
          .query({
            query: ChaveByCpfDocument,
            variables: { cpf: onlyNumbers(e.target.value) },
          })
          .then((response) => formProps.form.change('chave', response.data.chaveByCpf))
          .catch(handleRejection)
      }
    }

    return (
      <Fragment>
        <form noValidate>
          <VFlow vSpacing={2}>
            <HeadingSection level={2} title='Insira a contra-chave da instalação' vSpace={0.5}>
              <VFlow>
                <Alert inline type='info'>
                  A contra-chave pode ser gerada na{' '}
                  <Link href='http://sisab.saude.gov.br' target='_blank'>
                    plataforma do SISAB
                  </Link>{' '}
                  ou pode ser solicitada no Disque Saúde através do telefone 136 na opção 8.
                </Alert>
                <Grid>
                  <Cell size={3}>
                    <CpfField
                      name={path.cpf}
                      label={isEditAdmin ? 'CPF do instalador' : 'CPF do novo instalador'}
                      onBlur={calculateChave}
                      required
                    />
                  </Cell>

                  <Cell size={9} />

                  <Cell size={3}>
                    <ChaveField name={path.chave} label='Chave' defaultValue='chave' disabled />
                  </Cell>

                  <Cell size={3}>
                    <ContraChaveField name={path.contraChave} label='Contra-chave' required />
                  </Cell>
                </Grid>
              </VFlow>
              <FormFooter
                style={css`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <Button onClick={onVoltarClicked} data-cy='FormFooter.voltar'>
                  Voltar
                </Button>
                <HFlow>
                  <Button onClick={onCancelarClicked} data-cy='FormFooter.cancelar'>
                    Cancelar
                  </Button>
                  <SubmitButton handleSubmit={formProps.handleSubmit} data-cy='FormFooter.salvar'>
                    {isEditAdmin ? 'Salvar' : 'Continuar'}
                  </SubmitButton>
                </HFlow>
              </FormFooter>
            </HeadingSection>
          </VFlow>
        </form>
      </Fragment>
    )
  }
  return <Form<ContraChaveFormModel> render={renderForm} onSubmit={onSubmit} validate={validator} />
}
