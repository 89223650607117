import { Alert, Heading, VFlow } from 'bold-ui'
import React from 'react'

import { EquipesErrorsBox } from './EquipesErrorsBox'
import { EquipesVinculadasErrorsBox } from './EquipesVinculadasErrorBox'
import { LotacoesErrorsBox } from './LotacoesErrorBox'
import { DetalhesImportacaoCnesV1Model } from './model'
import { ProfissionaisErrorsBox } from './ProfissionaisErrorBox'
import { ProfissionaisSemAgendaBox } from './ProfissionaisSemAgendaBox'
import { UnidadesSaudeErrorsBox } from './UnidadesSaudeErrorsBox'

interface DetalhesImportacaoCnesV1Props {
  detalhes: DetalhesImportacaoCnesV1Model
}

export const DetalhesImportacaoCnesV1 = (props: DetalhesImportacaoCnesV1Props) => {
  const { detalhes } = props
  const muitosRegistrosInconsistentes = detalhes.totalRegistrosInconsistentes > detalhes.maximoRegistrosInconsistentes

  return (
    <VFlow vSpacing={2}>
      {detalhes.erro && (
        <VFlow>
          <Heading level={2}>Falha na importação</Heading>
          <Alert type='danger'>{detalhes.erro.mensagem}</Alert>
        </VFlow>
      )}
      <ProfissionaisSemAgendaBox
        profissionais={detalhes.profissionais}
        maximoProfissionaisSemAgendaConfigurada={detalhes.maximoProfissionaisSemAgendaConfigurada}
        totalProfissionaisSemAgendaConfigurada={detalhes.totalProfissionaisSemAgendaConfigurada}
      />
      {detalhes.totalRegistrosInconsistentes > 0 && (
        <VFlow>
          <Heading level={2}>Registros não importados</Heading>
          {muitosRegistrosInconsistentes && (
            <Alert type='warning'>
              O arquivo CNES importado possui {detalhes.totalRegistrosInconsistentes} inconsistências. Os{' '}
              {detalhes.maximoRegistrosInconsistentes} primeiros registros inconsistentes são apresentados abaixo.
            </Alert>
          )}
          <UnidadesSaudeErrorsBox unidadesSaude={detalhes.unidadesSaude} />
          <EquipesErrorsBox unidadesSaude={detalhes.unidadesSaude} />
          <EquipesVinculadasErrorsBox unidadesSaude={detalhes.unidadesSaude} />
          <ProfissionaisErrorsBox profissionais={detalhes.profissionais} />
          <LotacoesErrorsBox profissionais={detalhes.profissionais} />
        </VFlow>
      )}
    </VFlow>
  )
}
