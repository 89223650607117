/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, ModalBody, Text, VFlow } from 'bold-ui'
import theme from 'config/theme'

export const TornarResponsavelModal = () => {
  return (
    <ModalBody style={styles.modalBody}>
      <HFlow>
        <Icon icon='questionMarkOutline' color={theme.pallete.primary.main} size={3} />
        <VFlow>
          <Heading level={1}>Deseja tornar-se o profissional responsável desta observação no sistema?</Heading>
          <VFlow vSpacing={0.5}>
            <Text fontWeight='bold'>Esta ação não poderá ser desfeita.</Text>
            <Text>
              Para o sistema, a responsabilidade está relacionada com a capacidade de prescrever um plano de cuidado,
              avaliar um problema ou condição e liberar o cidadão.
            </Text>
          </VFlow>
        </VFlow>
      </HFlow>
    </ModalBody>
  )
}

const styles = {
  modalBody: css`
    padding: 0;
  `,
}
