import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import React from 'react'

import RelatorioAdForm from './RelatorioAdForm'

export default function RelatorioADView() {
  return (
    <>
      <Breadcrumb title='Relatório de atenção domiciliar' />
      <PageHeaderSection title='Relatório de atenção domiciliar' />
      <PageContent>
        <RelatorioAdForm />
      </PageContent>
    </>
  )
}
