/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente41(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='32' height='100' viewBox='0 0 32 100' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M9.3251 56.6582C8.94436 52.1267 8.23602 46.6583 7.62438 42.0613C7.55793 41.5619 7.49263 41.0729 7.42902 40.5964C7.1995 38.8775 6.99189 37.3225 6.83108 36.0364C6.62937 34.4233 6.50674 33.2724 6.50027 32.7479C6.51193 32.7326 6.53562 32.7056 6.58074 32.6668C6.69806 32.5661 6.89614 32.4431 7.18851 32.305C7.76861 32.031 8.62008 31.7436 9.66911 31.4609C11.7604 30.8974 14.5548 30.3741 17.3342 30.01C20.1162 29.6456 22.8561 29.4444 24.8424 29.5159C25.8442 29.552 26.6098 29.6565 27.0874 29.8214C27.3292 29.905 27.4433 29.9862 27.4868 30.0344C27.4973 30.046 27.5007 30.049 27.5009 30.0522C27.501 30.0549 27.499 30.0577 27.497 30.0656C27.3024 30.8484 26.9577 33.3284 26.5351 36.6953C26.1099 40.0827 25.6005 44.4139 25.0784 48.9371C24.7523 51.7615 24.4213 54.6605 24.1025 57.4519C23.3998 63.6055 22.7568 69.2369 22.3581 72.3964C21.7785 76.9899 20.8802 83.0958 19.5322 88.0461C18.8571 90.5251 18.0778 92.6813 17.187 94.2074C16.2778 95.7653 15.3521 96.5016 14.4381 96.5016C13.454 96.5016 12.6917 95.9976 12.0759 95.0057C11.4473 93.9931 11.0042 92.5146 10.7019 90.6984C10.0982 87.0719 10.086 82.3113 10.086 77.7768C10.086 68.7273 10.0859 65.7135 9.3251 56.6582Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M24.5907 36.6644C23.9339 37.2263 22.6424 37.7162 20.9463 38.0488C19.2743 38.3767 17.2886 38.5369 15.3183 38.495C13.346 38.4531 11.4119 38.2092 9.83701 37.7417C8.23929 37.2674 7.11354 36.5934 6.61529 35.7694C6.06013 34.8512 5.40688 32.9621 4.78318 30.5003C4.16458 28.0587 3.58857 25.1101 3.16977 22.1154C2.7508 19.1196 2.49081 16.0893 2.50025 13.482C2.50977 10.8504 2.79493 8.73143 3.4077 7.49983C3.98646 6.3366 5.48229 5.32746 7.612 4.52347C9.71332 3.7302 12.3173 3.17895 14.9428 2.85459C17.566 2.53051 20.1882 2.43564 22.3179 2.54509C23.3833 2.59985 24.3156 2.70526 25.0582 2.85527C25.8186 3.00888 26.319 3.19783 26.5721 3.38248C27.1217 3.78333 27.6238 4.67735 28.0421 6.03893C28.4524 7.37463 28.7535 9.06163 28.9712 10.9257C29.4062 14.6512 29.5 19.0103 29.5 22.5217C29.5 29.4709 27.4805 34.1916 24.5907 36.6644Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.43162 36.9884C3.67994 35.7069 2.97049 33.5249 2.35975 31.1143C1.71663 28.5759 1.12399 25.5373 0.693861 22.4617C0.264375 19.3906 -0.00973146 16.2354 0.00026448 13.4729C0.00990887 10.8077 0.278793 8.17628 1.16944 6.38619C2.19616 4.32261 4.49257 3.02887 6.72905 2.18458C9.07912 1.2974 11.894 0.712235 14.6363 0.373448C17.3879 0.0335094 20.1563 -0.0693025 22.4462 0.0483868C23.589 0.107119 24.6518 0.222673 25.5532 0.404769C26.3834 0.572473 27.3339 0.84377 28.0453 1.36267C29.2951 2.27425 29.9897 3.86551 30.4319 5.30481C30.9058 6.84765 31.2287 8.70362 31.4543 10.6357C31.9067 14.5104 32 18.9877 32 22.5217C32 27.4759 31.0376 31.5814 29.3123 34.6956C28.6064 40.0463 27.5437 49.3519 26.5869 57.7311L26.5848 57.7497C25.8835 63.8914 25.239 69.5352 24.8384 72.7094C24.2571 77.3162 23.3417 83.5711 21.9443 88.7029C21.2498 91.2534 20.4022 93.6585 19.3462 95.4677C18.3642 97.15 16.7937 99.0016 14.4381 99.0016C12.3633 99.0016 10.8903 97.8357 9.95195 96.3243C9.06505 94.8958 8.55617 93.0335 8.23579 91.1089C7.59108 87.2359 7.58601 82.2474 7.58601 77.7768V77.7657C7.58601 68.7558 7.58601 65.8192 6.83388 56.8675C6.41805 51.9184 5.60279 45.8089 4.95119 40.9287L4.95042 40.9229C4.75806 39.4822 4.57982 38.1472 4.43162 36.9884ZM27.3812 34.0447C29.0095 31.275 30 27.418 30 22.5217C30 15.4899 29.6252 4.99052 26.8668 2.97852C24.1083 0.966515 5.63346 1.9039 2.96005 7.2771C0.286646 12.6503 3.79642 32.0738 6.18742 36.0281C6.2391 36.1135 6.29588 36.197 6.35753 36.2785C6.51527 37.5309 6.71463 39.024 6.9336 40.664C7.58345 45.5311 8.40612 51.6925 8.82686 56.7C9.58601 65.7354 9.58601 68.7335 9.58601 77.7768C9.58601 86.8202 9.62899 97.0016 14.4381 97.0016C19.2473 97.0016 21.6942 81.6514 22.8542 72.459C23.2532 69.2965 23.8969 63.6599 24.5998 57.5043L24.5998 57.5042C25.5789 48.93 26.673 39.3489 27.3812 34.0447Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
