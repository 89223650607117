import { SerializedStyles } from '@emotion/core'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

interface BaseLegalParaTratamentoDeDadosPessoaisSectionProps {
  listStyle: SerializedStyles
}

export const BaseLegalParaTratamentoDeDadosPessoaisSection = (
  props: BaseLegalParaTratamentoDeDadosPessoaisSectionProps
) => {
  const { listStyle } = props

  return (
    <TermosDeUsoHeadingSection
      title='2. Base Legal para Tratamento de Dados Pessoais'
      id='base_legal_para_tratamento_de_dados_pessoais'
    >
      <p>
        Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal nº 12.965 de 23 de abril de 2014
        (Marco Civil da Internet) e com a Lei Federal nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados
        Pessoais).
      </p>
      <br />
      <p>
        O site se compromete a cumprir as normas previstas na Lei Geral de Proteção de Dados Pessoais (LGPD) e a
        respeitar os princípios dispostos no Art. 6º de tal norma:
      </p>
      <br />
      <ol type='I' css={listStyle}>
        <li>
          Finalidade: realização do tratamento para propósitos legítimos, específicos, explícitos e informados ao
          titular, sem possibilidade de tratamento posterior de forma incompatível com essas finalidades;
        </li>
        <li>
          Adequação: compatibilidade do tratamento com as finalidades informadas ao titular, de acordo com o contexto do
          tratamento;
        </li>
        <li>
          Necessidade: limitação do tratamento ao mínimo necessário para a realização de suas finalidades, com
          abrangência dos dados pertinentes, proporcionais e não excessivos em relação às finalidades do tratamento de
          dados;
        </li>
        <li>
          Livre acesso: garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a duração do
          tratamento, bem como sobre a integralidade de seus dados pessoais;
        </li>
        <li>
          Qualidade dos dados: garantia, aos titulares, de exatidão, clareza, relevância e atualização dos dados, de
          acordo com a necessidade e para o cumprimento da finalidade de seu tratamento;
        </li>
        <li>
          Transparência: garantia, aos titulares, de informações claras, precisas e facilmente acessíveis sobre a
          realização do tratamento e os respectivos agentes de tratamento, observados os segredos comercial e
          industrial;
        </li>
        <li>
          Segurança: utilização de medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos não
          autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão;
        </li>
        <li>
          Prevenção: adoção de medidas para prevenir a ocorrência de danos em virtude do tratamento de dados pessoais;
        </li>
        <li>
          Não discriminação: impossibilidade de realização do tratamento para fins discriminatórios ilícitos ou
          abusivos;
        </li>
        <li>
          Responsabilização e prestação de contas: demonstração, pelo agente, da adoção de medidas eficazes e capazes de
          comprovar a observância e o cumprimento das normas de proteção de dados pessoais e, inclusive, da eficácia
          dessas medidas.
        </li>
      </ol>
    </TermosDeUsoHeadingSection>
  )
}
