import { Button, HFlow, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'bold-ui'
import { TableBox } from 'components/table/TableBox'
import { PedidosEnvioBaseQuery } from 'graphql/types.generated'
import React, { useState } from 'react'
import { formatDate } from 'util/date/formatDate'

import { ModalCancelarSolicitacao } from './ModalCancelarSolicitacao'
import { ModalUnificarBases } from './ModalUnificarBases'
import { statusCancelaveis } from './model'
import { StatusUnificacaoBaseTag } from './StatusUnificacaoBaseTag'

interface SolicitacaoEnvioDadosTableProps {
  data: PedidosEnvioBaseQuery
  hasUnificacaoAtiva: boolean
  onCancelarSolicitacao(solicitacaoId: string): void
  onUnificarBase(): void
}

export const SolicitacaoEnvioDadosTable = (props: SolicitacaoEnvioDadosTableProps) => {
  const { data, hasUnificacaoAtiva, onCancelarSolicitacao, onUnificarBase } = props

  const [pedidoEnvioBaseId, setPedidoEnvioBaseId] = useState<string>()
  const [isModalCancelarOpen, setIsModalCancelarOpen] = useState<boolean>()
  const [isModalUnificarOpen, setIsModalUnificarOpen] = useState<boolean>()

  const handleMostrarModalCancelar = (id: string) => () => {
    setIsModalCancelarOpen(true)
    setPedidoEnvioBaseId(id)
  }
  const handleMostrarModalUnificar = () => {
    setIsModalUnificarOpen(true)
  }

  return (
    <>
      <TableBox>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>Link da instalação central</TableHeader>
              <TableHeader>Data de solicitação</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.pedidosEnvioBase?.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{item.linkInstalacao}</TableCell>
                  <TableCell>{formatDate(item.dataSolicitacao)}</TableCell>
                  <TableCell>
                    <StatusUnificacaoBaseTag
                      status={item.status}
                      dataFimUnificacao={item.dataFimUnificacao}
                      isLoginAtivo={item.loginAtivo}
                    />
                  </TableCell>
                  <TableCell>
                    <HFlow hSpacing={1} justifyContent='flex-end'>
                      <Button
                        disabled={!statusCancelaveis.includes(item.status) || !item.loginAtivo}
                        onClick={handleMostrarModalCancelar(item.id)}
                        size='small'
                      >
                        Cancelar
                      </Button>
                      <Button
                        disabled={item.status !== 'APROVADO' || hasUnificacaoAtiva || !item.loginAtivo}
                        onClick={handleMostrarModalUnificar}
                        kind='danger'
                        size='small'
                      >
                        Unificar
                      </Button>
                    </HFlow>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableBox>
      <ModalCancelarSolicitacao
        isModalOpen={isModalCancelarOpen}
        setIsModalOpen={setIsModalCancelarOpen}
        solicitacaoId={pedidoEnvioBaseId}
        onCancelarSolicitacao={onCancelarSolicitacao}
      />
      <ModalUnificarBases
        isModalOpen={isModalUnificarOpen}
        setIsModalOpen={setIsModalUnificarOpen}
        onUnificarBase={onUnificarBase}
      />
    </>
  )
}
