import { useCallback, useEffect, useState } from 'react'

interface MicroareasControlModel {
  currentMicroareaTab: string
  renderCachedMicroarea: boolean
}
interface RenderMicroareaControlModel {
  previusCachedMicroarea: string
  renderMicroareaCache: boolean
}

export const useMicroareasVisualizacao = (
  cachedMicroarea: string,
  microareas: string[],
  renderCache: boolean,
  resetExpandedItems: () => void
) => {
  const [currentMicroareaTab, setCurrentMicroareaTab] = useState<string>(renderCache ? cachedMicroarea : undefined)
  const [renderMicroareaControl, setRenderMicroareaControl] = useState<RenderMicroareaControlModel>({
    previusCachedMicroarea: cachedMicroarea,
    renderMicroareaCache: renderCache ? true : false,
  })

  const handleMicroareaTabClick = useCallback(
    (microarea: string) => {
      resetExpandedItems()
      setCurrentMicroareaTab(microarea)

      if (renderMicroareaControl.renderMicroareaCache === true) {
        setRenderMicroareaControl((state) => ({ ...state, renderMicroareaCache: null }))
      } else if (renderMicroareaControl.renderMicroareaCache === null) {
        setRenderMicroareaControl((state) => ({ ...state, renderMicroareaCache: false }))
      }
    },
    [renderMicroareaControl.renderMicroareaCache, resetExpandedItems]
  )

  useEffect(() => {
    if (!renderCache) {
      if (renderMicroareaControl.renderMicroareaCache === true) {
        setCurrentMicroareaTab(renderMicroareaControl.previusCachedMicroarea)
      } else if (renderMicroareaControl.renderMicroareaCache === false && microareas?.length > 0) {
        setCurrentMicroareaTab(microareas[0])
      }
    }
  }, [microareas, renderCache, renderMicroareaControl])

  return {
    currentMicroareaTab,
    handleMicroareaTabClick,
    setRenderMicroareaControl,
  }
}
