/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme, VFlow } from 'bold-ui'
import { CheckboxField } from 'components/form'
import { TipoParticipacaoAtendimentoSelectField } from 'components/form/field/select/TipoParticipacaoAtendimentoSelectField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { MetaPath } from 'util/metaPath'

import { FinalizacaoAtendimentoFormModel } from '../FinalizacaoAtendimentoForm'

export interface ParticipacaoCidadaoFieldGroupProps {
  name: MetaPath<FinalizacaoAtendimentoFormModel>
}

// TODO @RNG - Criar um componente genérico utilizando <fieldset> e <legend> (#18472)
export function ParticipacaoCidadaoFieldGroup(props: ParticipacaoCidadaoFieldGroupProps) {
  const { name } = props

  const {
    input: { value: cidadaoPresente },
  } = usePecField({ name: name.cidadaoPresente.absolutePath() })

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <VFlow vSpacing={0.5} style={styles.container}>
      <CheckboxField name={name.cidadaoPresente} label='Cidadão participou do atendimento' style={styles.title} />
      <TipoParticipacaoAtendimentoSelectField
        label='Forma de participação'
        name={name.tipoParticipacaoCidadao}
        disabled={!cidadaoPresente}
      />
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    position: relative;
    margin-top: 0.5rem;
    padding: 1rem;
    border: 1px solid ${theme.pallete.gray.c80};
  `,
  title: css`
    font-weight: bold;
    position: absolute;
    top: -0.75rem;
    margin-left: -0.75rem;
    padding: 0 0.5rem;
    background-color: ${theme.pallete.surface.main};
  `,
})
