export enum AggregatorEnum {
  PERCENTUAL = 'PERCENTUAL',
  CONTAGEM = 'CONTAGEM',
  MEDIA = 'MEDIA',
  MAXIMO = 'MAXIMO',
  MINIMO = 'MINIMO',
}

export type AggregatorFunction = (values: number[], total?: number) => number

export type Aggregator = {
  id: AggregatorEnum
  label: string
  value: AggregatorFunction | undefined
  keyDependent: boolean
  chain?: Aggregator[]
  suffix?: string
}
