import { Breadcrumbs, Icon } from 'bold-ui'
import { ButtonLink, RouterLink } from 'components/route'
import React, { useContext, useEffect, useState } from 'react'

import useSession from '../auth/useSession'
import { BreadcrumbContext } from './BreadcrumbContext'
import { BreadcrumbEntry } from './BreadcrumbStore'

export function BreadcrumbNav() {
  const store = useContext(BreadcrumbContext)
  const [entries, setEntries] = useState<BreadcrumbEntry[]>(store.getEntries())
  const session = useSession()
  const moduloInicial = session.data && session.data.acesso && session.data.acesso.moduloInicial

  useEffect(() => {
    return store.subscribe(setEntries)
  }, [store])

  return (
    <Breadcrumbs>
      <ButtonLink
        to={moduloInicial ?? '/'}
        size='small'
        skin='ghost'
        style={{ color: 'inherit', marginRight: '-0.5rem' }}
      >
        <Icon icon='houseFilled' />
      </ButtonLink>

      {entries.map(({ title, to }, idx) => (
        <RouterLink key={idx} to={to} color='inherit' textDecoration='none' data-cy={title + '.Breadcrumb'}>
          {title}
        </RouterLink>
      ))}
    </Breadcrumbs>
  )
}
