import { HFlow, Text } from 'bold-ui'
import { SelectField } from 'components/form'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { UnidadeSaudeCuidadoCompartilhadoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CuidadoCompartilhadoQueryFilterEnum,
  UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery,
  UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'
import { UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryModel } from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/model-cuidadocompartilhado'

interface UnidadeSaudeCuidadaoCompartilhadoSelectFieldProps
  extends AsyncSelectFieldProps<UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryModel> {
  role: CuidadoCompartilhadoQueryFilterEnum
}

const itemToString = (item: UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryModel) => item && item.nome

export const UnidadeSaudeCuidadaoCompartilhadoSelectField = (
  props: UnidadeSaudeCuidadaoCompartilhadoSelectFieldProps
) => {
  const { role, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryModel,
    UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery,
    UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryVariables
  >({
    query: UnidadeSaudeCuidadoCompartilhadoSelectFieldDocument,
    extractItems: (data) => data && data.estabelecimentosListaCuidadoCompartilhado,
    variables: (query) => ({
      input: {
        role,
        query,
      },
    }),
  })

  return (
    <SelectField<UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...selectProps}
      {...rest}
    />
  )
}

const renderItem = (item: UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryModel) => (
  <>
    <Text fontWeight='bold'>{item.nome}</Text>
    <HFlow hSpacing={0.5}>
      <Text fontWeight='bold'>CNES:</Text>
      {item.cnes}
    </HFlow>
  </>
)
