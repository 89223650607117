import { Checkbox, Highlight, Spinner, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useRecursosQuery } from 'graphql/hooks.generated'
import { TipoAcesso } from 'graphql/types.generated'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { emptyArray } from 'util/array'
import { createTree, RecursoNode } from 'view/perfil/components/RecursoNode'

import {
  RECURSO_CUIDADO_COMPARTILHADO_URI,
  RECURSO_REGISTRAR_ATENDIMENTO_URI,
  RECURSO_VISUALIZAR_ATEND_URI,
  RECURSO_VISUALIZAR_DISCUSSAO_URI,
  RECURSO_VISUALIZAR_PRONTUARIO_URI,
} from './model'
import { RecursoTree } from './RecursoTree'

interface RecursoTreeFieldProps {
  value: string[]
  tipoPerfil: TipoAcesso

  onChange(value: string[]): any
}

export const RecursoTreeField = (props: RecursoTreeFieldProps) => {
  const {
    data: { recursos },
    loading,
  } = useRecursosQuery({
    fetchPolicy: 'cache-first',
    variables: { tipoAcesso: props.tipoPerfil },
  })

  const root = useMemo(() => createTree(recursos ?? []), [recursos])

  useEffect(() => {
    root.setTreeValue(props.value)
  }, [props.value, root])

  const alert = useAlert()
  const nodeVisualizarProntuario = useMemo(
    () => root.children.find((item) => item.state.uri === RECURSO_VISUALIZAR_PRONTUARIO_URI),
    [root]
  )
  const nodeRegistrarAtendimento = useMemo(
    () =>
      root.children
        .find((item) => item.state.uri === RECURSO_VISUALIZAR_ATEND_URI)
        ?.children.find((item) => item.state.uri === RECURSO_REGISTRAR_ATENDIMENTO_URI),
    [root]
  )
  const nodeVisualizarDiscussao = useMemo(
    () =>
      root.children
        .find((item) => item.state.uri === RECURSO_CUIDADO_COMPARTILHADO_URI)
        ?.children.find((item) => item.state.uri === RECURSO_VISUALIZAR_DISCUSSAO_URI),
    [root]
  )
  const [visualizarProntDisabled, setVisualizarProntDisabled] = useState<boolean>(false)
  const recursoVisualizarProntAtivadoAuto = useRef<boolean>(false)

  const handleChange = (node: RecursoNode) => (e) => {
    node.changeValue(e.target.checked)

    const isNodeRegistrarAtendimentoClicked = isNodeClicked(node, nodeRegistrarAtendimento, visualizarProntDisabled)
    const isNodeVisualizarDiscussaoClicked = isNodeClicked(node, nodeVisualizarDiscussao, visualizarProntDisabled)

    if (isNodeRegistrarAtendimentoClicked || isNodeVisualizarDiscussaoClicked) {
      if (node.state.checked) {
        if (!nodeVisualizarProntuario.state.checked) {
          nodeVisualizarProntuario.changeValue(e.target.checked)
          recursoVisualizarProntAtivadoAuto.current = true
          alert('info', 'Visualizar prontuário foi adicionado automaticamente')
        }
        setVisualizarProntDisabled(true)
      } else if (
        (isNodeRegistrarAtendimentoClicked && !nodeVisualizarDiscussao.state.checked) ||
        (isNodeVisualizarDiscussaoClicked && !nodeRegistrarAtendimento.state.checked)
      ) {
        if (recursoVisualizarProntAtivadoAuto.current) {
          nodeVisualizarProntuario.changeValue(e.target.checked)
          recursoVisualizarProntAtivadoAuto.current = false
          alert('info', 'Visualizar prontuário foi removido automaticamente')
        }
        setVisualizarProntDisabled(false)
      }
    } else if (
      compareUriOfNodes(node, [nodeVisualizarProntuario]) &&
      nodeVisualizarProntuario?.state.checked &&
      nodeRegistrarAtendimento?.state.checked
    ) {
      node.changeValue(e.target.checked)
      recursoVisualizarProntAtivadoAuto.current = true
      setVisualizarProntDisabled(true)
    }

    const newValue = node.getTreeValue()
    props.onChange(newValue)
  }

  useEffect(() => {
    setVisualizarProntDisabled(
      (nodeRegistrarAtendimento?.state.checked || nodeVisualizarDiscussao?.state.checked) &&
        nodeVisualizarProntuario?.state.checked
    )
  }, [nodeRegistrarAtendimento, nodeVisualizarDiscussao, nodeVisualizarProntuario])

  const renderNode = (node: RecursoNode, search: string) => {
    const renderNodeVisualizarProntuario = compareUriOfNodes(node, [nodeVisualizarProntuario])
    const isDisabled = renderNodeVisualizarProntuario && visualizarProntDisabled

    const tooltipRegistrarAtendimento =
      isDisabled &&
      nodeRegistrarAtendimento.state.checked &&
      'O recurso registrar atendimento depende de visualizar prontuário'

    const tooltipVisualizarCuidadoCompartilhado =
      isDisabled &&
      nodeVisualizarDiscussao.state.checked &&
      'O recurso visualizar discussão depende de visualizar prontuário'

    return (
      <Tooltip text={tooltipRegistrarAtendimento || tooltipVisualizarCuidadoCompartilhado}>
        <div>
          <Checkbox
            value={node.state.uri}
            checked={node.state.checked}
            indeterminate={node.state.checked && node.checkCount() !== node.totalCount()}
            label={<Highlight text={node.state.label} words={[search]} />}
            onChange={handleChange(node)}
            disabled={isDisabled}
          />
        </div>
      </Tooltip>
    )
  }

  if (!root) {
    return null
  }

  if (loading) {
    return <Spinner />
  }

  return <RecursoTree root={root} renderNode={renderNode} />
}

const compareUriOfNodes = (nodeClick: RecursoNode, nodeToBeCompared: RecursoNode[]) => {
  return nodeToBeCompared.some((node) =>
    node?.state.uri && nodeClick?.state.uri ? node.state.uri === nodeClick.state.uri : false
  )
}

const isNodeClicked = (node: RecursoNode, nodeToBeCompared: RecursoNode, state: Boolean) => {
  const nodeArrayParentOrChildren = state ? [nodeToBeCompared?.parent] : nodeToBeCompared?.children
  return compareUriOfNodes(node, [nodeToBeCompared, ...(nodeArrayParentOrChildren || emptyArray)])
}
