import { RadioGroupField, RadioGroupFieldProps, RadioOption } from 'components/form'
import { OpcaoTipoPerguntaEnum } from 'graphql/types.generated'
import React from 'react'

interface SimNaoRadioGroupFieldProps extends Omit<RadioGroupFieldProps<OpcaoTipoPerguntaEnum>, 'options'> {}

export function SimNaoNaoSabeRadioGroupField(props: SimNaoRadioGroupFieldProps) {
  return <RadioGroupField options={options} {...props} />
}

const options: RadioOption<OpcaoTipoPerguntaEnum>[] = [
  { value: OpcaoTipoPerguntaEnum.SIM, label: 'Sim' },
  { value: OpcaoTipoPerguntaEnum.NAO, label: 'Não' },
  { value: OpcaoTipoPerguntaEnum.NAO_SABE, label: 'Não sabe' },
]
