import { ApolloQueryResult } from 'apollo-client'
import { useGrupoExamesQuery } from 'graphql/hooks.generated'
import { GrupoExamesQuery, GrupoExameTableQuery } from 'graphql/types.generated'
import React from 'react'
import { useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import { ProcedimentoGrupoExameFieldModel } from './components/ProcedimentoGrupoExameField'
import { GrupoExamesFormModel, GrupoExamesModalForm } from './GrupoExamesModalForm'

export type Procedimento = GrupoExamesQuery['grupoExame']['procedimentos'][0]
export type GrupoExameItem = GrupoExamesQuery['grupoExame']

interface CreateGrupoExameProps {
  refetch(): Promise<ApolloQueryResult<GrupoExameTableQuery>>
}

interface UrlParams {
  grupoExameId: string
}

export function EditGrupoExame(props: CreateGrupoExameProps) {
  const params = useParams<UrlParams>()

  const { data } = useGrupoExamesQuery({ variables: { id: params.grupoExameId } })
  if (!data || !data.grupoExame) {
    return null
  }

  const initialValues = {
    id: data.grupoExame.id,
    nome: data.grupoExame.nome,
    procedimentos: data.grupoExame.procedimentos.map(
      (procedimento) =>
        ({
          _id: uuidv4(),
          procedimento: procedimento,
        } as ProcedimentoGrupoExameFieldModel)
    ),
  } as GrupoExamesFormModel

  return <GrupoExamesModalForm initialValues={initialValues} refetch={props.refetch} isEdit={true} />
}
