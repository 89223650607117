/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { format } from 'date-fns'
import {
  OrigemAtendimento,
  SubtipoAtendimentoHistoricoClinico,
  TipoRegistroHistoricoClinico,
} from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useMemo, useRef } from 'react'
import { AccordionItem, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion'

import { useHistoricoPrint } from '../../hooks/useHistoricoPrint'
import {
  accordionBloqueadoMensagem,
  CidadaoHistorico,
  HistoricoAtendimentoModel,
  IdadeOrigemAtendimento,
  tipoRegistroHistoricoData,
} from '../../model/model-historico'
import { HistoricoAccordionItemObservacaoWrapper } from './detail/atendimento-observacao/HistoricoAccordionItemObservacaoWrapper'
import HistoricoDetalhesPanel from './detail/HistoricoDetalhesPanel'
import HistoricoAccordionHeader from './header/HistoricoAccordionHeader'

export interface HistoricoAccordionProps {
  historicoAtendimento: HistoricoAtendimentoModel
  cidadao: CidadaoHistorico
  refetchOnListagem(): void
  checked: boolean
  onSelectToPrint?(checked: boolean, historico: HistoricoAtendimentoModel): void
  historicoPrintProps: ReturnType<typeof useHistoricoPrint>
}

export default function HistoricoAccordion(props: HistoricoAccordionProps) {
  const { historicoAtendimento, cidadao, refetchOnListagem, checked, onSelectToPrint, historicoPrintProps } = props
  const { analytics } = useFirebase()
  const isAccordionClosedRef = useRef(true)
  const theme = useTheme()
  const dataAtendimento = format(historicoAtendimento.dataAtendimento, 'yyyy-MM-dd')
  const tipoRegistroEnum = historicoAtendimento.tipoApresentacao ?? historicoAtendimento.tipoAtendProf
  const tipoRegistroData = tipoRegistroHistoricoData[tipoRegistroEnum.toString()]
  const classes = useMemo(() => styles(theme), [theme])
  const origemAtendimento: OrigemAtendimento = historicoAtendimento.origemAtendimento
  const idadeOrigemAtendimento: IdadeOrigemAtendimento = {
    dataAtendimento,
    dataNascimentoCidadao: cidadao.dataNascimento,
    origemAtendimento,
  }

  const subtipoAtendimento = tipoRegistroData?.tags?.find(
    (subtipo) => subtipo.id === historicoAtendimento.coSubtipoAtendimento
  )

  const isVisitaAusenteOuRecusada =
    tipoRegistroEnum === TipoRegistroHistoricoClinico.VISITA_DOMICILIAR &&
    (subtipoAtendimento.nome === SubtipoAtendimentoHistoricoClinico.CIDADAO_AUSENTE ||
      subtipoAtendimento.nome === SubtipoAtendimentoHistoricoClinico.VISITA_RECUSADA)

  return (
    <HistoricoAccordionItemObservacaoWrapper historicoAtendimento={historicoAtendimento}>
      <AccordionItem css={classes.accordionItem} dangerouslySetExpanded={isVisitaAusenteOuRecusada ? false : undefined}>
        <Tooltip
          text={
            isVisitaAusenteOuRecusada &&
            `${
              accordionBloqueadoMensagem[subtipoAtendimento.nome]
            } no momento da visita. Não há detalhes a serem exibidos`
          }
        >
          <span>
            <HistoricoAccordionHeader
              historicoAtendimento={historicoAtendimento}
              subtipoAtendimento={subtipoAtendimento}
              refetchOnListagem={refetchOnListagem}
              cidadao={cidadao}
              checked={checked}
              onSelectToPrint={onSelectToPrint}
              historicoPrintProps={historicoPrintProps}
            />
          </span>
        </Tooltip>
        <AccordionItemState>
          {({ expanded }) => {
            if (expanded) {
              if (isAccordionClosedRef.current) {
                analytics.logEvent('expand_accordion_historico')
                isAccordionClosedRef.current = false
              }
              return (
                <AccordionItemPanel css={classes.accordionItemPanel}>
                  <VFlow vSpacing={1} style={classes.vFlow}>
                    <HistoricoDetalhesPanel
                      historicoAtendimento={historicoAtendimento}
                      idadeOrigemAtendimento={idadeOrigemAtendimento}
                      dataNascimento={cidadao.dataNascimento}
                    />
                  </VFlow>
                </AccordionItemPanel>
              )
            }
            isAccordionClosedRef.current = true
            return null
          }}
        </AccordionItemState>
      </AccordionItem>
    </HistoricoAccordionItemObservacaoWrapper>
  )
}

const styles = (theme: Theme) => {
  return {
    item: css`
      border-radius: 2px;
      border: 1px solid ${theme.pallete.primary.c40};
      background: ${theme.pallete.primary.c90};
      padding: 0.25rem 1rem;

      * {
        color: ${theme.pallete.primary.c40};
      }
    `,
    prependItem: css`
      border-bottom: 0;
    `,
    appendItem: css`
      border-top: 0;
    `,
    accordionItem: css`
      border: 1px solid ${theme.pallete.gray.c60};
      border-radius: 2px;
      &:focus-within {
        outline: none;
        box-shadow: ${focusBoxShadow(theme, 'primary')};
        transition-property: box-shadow;
        transition-duration: 0.2s;
      }
    `,
    accordionItemPanel: css`
      flex: 1;
      background-color: ${theme.pallete.surface.main};
    `,
    vFlow: css`
      padding: 1rem;
      padding-top: 0.5rem;
      overflow-wrap: break-word;
    `,
  }
}
