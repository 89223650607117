/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, VFlow } from 'bold-ui'
import { AlertServerTimezone } from 'components/AlertServerTimezone'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { Fragment } from 'react'

interface AtendimentoListingProps {
  title: string
  heading: React.ReactNode
  filter: React.ReactNode
  footer: React.ReactNode
  children: React.ReactNode
}

export default function AtendimentoListing(props: AtendimentoListingProps) {
  const { title, heading, filter, children, footer } = props

  return (
    <Fragment>
      <PageContainer
        fluid
        style={css`
          padding: 1rem 2rem;
        `}
      >
        <VFlow>
          <AlertServerTimezone />
          <Heading level={1}>{title}</Heading>
          {heading}
        </VFlow>
      </PageContainer>
      <PageContent fluid type='transparent'>
        <VFlow vSpacing={0.5}>
          {filter}
          <VFlow vSpacing={0.25}>
            {children}
            {footer}
          </VFlow>
        </VFlow>
      </PageContent>
    </Fragment>
  )
}
