/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente53(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='40' height='110' viewBox='0 0 40 110' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M6.10291 77.9241C5.93189 77.9362 5.77815 77.9469 5.64313 77.9562C5.68851 77.7432 5.74193 77.4764 5.80154 77.1499C5.9907 76.1138 6.24248 74.4754 6.4978 72.0495C7.00753 67.2064 7.83371 56.0369 8.34839 46.4223C8.86331 36.8032 8.92004 12.6314 8.34684 6.63478C8.21783 5.28513 8.63985 4.39859 9.32704 3.84471C10.0385 3.27129 11.11 2.99379 12.3713 3.04646C14.9 3.15205 17.9239 4.57511 19.5741 6.96634C20.4052 8.17085 21.2054 10.0463 21.9639 12.4524C22.7195 14.8494 23.4229 17.7375 24.0741 20.9423C25.3764 27.3516 26.4635 34.9965 27.3428 42.451C27.4198 43.1034 27.491 43.7523 27.5572 44.397C28.2377 51.0306 28.2299 57.2558 28.2235 62.3145C28.2232 62.6071 28.2228 62.8957 28.2226 63.1803C28.2206 65.777 28.23 68.0491 28.3553 69.8617C28.418 70.7688 28.5105 71.5733 28.6485 72.2537C28.7853 72.9286 28.9729 73.5134 29.2433 73.9606C29.2774 74.017 29.2708 74.0303 29.2602 74.0517C29.2596 74.053 29.2589 74.0543 29.2582 74.0557C29.2276 74.1186 29.1306 74.2364 28.8892 74.39C28.4128 74.6931 27.5935 75.0002 26.4896 75.2968C24.301 75.8847 21.1863 76.3828 17.987 76.7853C14.7939 77.1871 11.5392 77.491 9.08139 77.6946C7.85284 77.7963 6.8242 77.8729 6.10291 77.9241Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M2.73336 95.2046C2.44563 94.145 2.43648 92.5723 2.63825 90.69C2.83848 88.8221 3.23989 86.7028 3.73558 84.5789C4.72722 80.3302 6.08625 76.1052 6.92733 73.9132C7.09186 73.4844 7.46601 73.0415 8.06836 72.6073C8.66667 72.176 9.45682 71.7776 10.395 71.4265C12.2708 70.7246 14.6766 70.235 17.1634 70.0307C19.6498 69.8265 22.1894 69.9098 24.3317 70.3362C26.4957 70.7669 28.1585 71.5285 29.0095 72.5988C30.8853 74.9579 33.006 79.3632 34.6625 83.8599C36.3215 88.3635 37.4717 92.8415 37.4717 95.3356C37.4717 96.4728 36.9126 97.7548 35.8779 99.0766C34.849 100.391 33.3851 101.699 31.6666 102.876C28.2232 105.236 23.8386 107.021 20.0641 107.319C16.3633 107.611 12.3372 105.73 9.0441 103.143C7.40599 101.856 5.97022 100.411 4.87029 99.0096C3.76462 97.601 3.02186 96.2669 2.73336 95.2046Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M29.4009 72.2876C33.2552 77.1352 37.9717 90.1561 37.9717 95.3356C37.9717 100.515 27.8679 107.204 20.1034 107.817C12.3389 108.43 3.50372 99.9493 2.25083 95.3356C1.23667 91.6011 3.50928 82.6603 5.31333 77.04C5.49949 76.017 5.74799 74.3968 6.00054 71.9972C6.50897 67.1664 7.33461 56.0065 7.8491 46.3956C8.36359 36.7846 8.41876 12.642 7.8491 6.68239C7.27944 0.722785 16.4881 1.61409 19.9856 6.68239C23.483 11.7507 26.0794 27.4719 27.8394 42.3925C27.9166 43.0474 27.9882 43.6989 28.0546 44.346C28.7378 51.0062 28.7299 57.2548 28.7235 62.3112C28.7183 66.4797 28.7141 69.838 29.0989 71.9487C29.2072 72.0578 29.3079 72.1707 29.4009 72.2876ZM30.9835 71.0646C30.7221 69.1472 30.7186 66.2043 30.7235 62.3137L30.7236 62.2839C30.7299 57.2391 30.7379 50.9052 30.0441 44.1419C29.9767 43.4851 29.9041 42.8237 29.8256 42.1582C28.9429 34.6746 27.8462 26.952 26.524 20.4446C25.8631 17.1918 25.1396 14.2113 24.3482 11.7008C23.5682 9.22629 22.6787 7.06378 21.6317 5.54646C19.4884 2.44062 15.7014 0.683366 12.4756 0.548668C10.8506 0.480812 9.10174 0.815367 7.75818 1.89829C6.3177 3.05933 5.66213 4.82179 5.85817 6.8727C6.41366 12.684 6.36471 36.7102 5.85196 46.2887C5.33825 55.8851 4.51478 67.0063 4.01152 71.7879C3.77389 74.0457 3.54202 75.581 3.36836 76.5557C2.46055 79.3977 1.43869 83.0587 0.762552 86.5105C0.419565 88.2615 0.158634 89.9932 0.0524074 91.5562C-0.0513806 93.0833 -0.0200054 94.605 0.320732 95.8598C0.732872 97.3774 1.69738 99.0164 2.90374 100.553C4.13304 102.119 5.71241 103.705 7.49987 105.109C11.0072 107.864 15.6526 110.174 20.2607 109.811C24.5737 109.471 29.3708 107.481 33.0799 104.939C34.9468 103.659 36.6196 102.185 37.8465 100.618C39.0504 99.0796 39.9717 97.2612 39.9717 95.3356C39.9717 92.3631 38.6767 87.5246 37.0084 82.9958C35.3344 78.4517 33.111 73.7505 30.9835 71.0646Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
