/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Theme, useTheme } from 'bold-ui'
import { Color } from 'csstype'

interface PanelProps {
  title: string
  icon: JSX.Element
  color: Color
  children: JSX.Element
}

export const Panel = (props: PanelProps) => {
  const { title, icon, color, children } = props

  const theme = useTheme()
  const styles = createStyles(theme, color)

  return (
    <div css={styles.panel}>
      <div css={styles.header}>
        <div css={styles.bar} />
        <HFlow alignItems='center' hSpacing={0.5}>
          {icon}
          <Heading level={3}>{title}</Heading>
        </HFlow>
      </div>
      <div css={styles.body}>{children}</div>
    </div>
  )
}

const createStyles = (theme: Theme, color: Color) => ({
  panel: css`
    border: 1px solid ${theme.pallete.divider};
    border-radius: 2px;
  `,
  bar: css`
    width: 0.5rem;
    margin-right: 0.5rem;
    background-color: ${color};
  `,
  header: css`
    min-height: 2.5rem;
    display: flex;
    background-color: ${theme.pallete.gray.c90};
  `,
  body: css`
    padding: 1rem;
  `,
})
