/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, colors, ExternalStyles, Grid, Heading, InfoLabel, VFlow } from 'bold-ui'
import { CaracteristicaLesaoEnum } from 'graphql/types.generated'

import {
  extractCaracteristicaLesaoByTipo,
  getLesaoFundamentalByLesaoEnvolvida,
  getTecidosMolesCaracteristicaLesao,
  getTipoCaracteristicaLesao,
  getTipoLesaoFundamental,
  TipoCaracteristicaLesao,
} from '../../model'

export interface CaracteristicasLesaoPanelProps {
  items: CaracteristicaLesaoEnum[]
  descricaoSintomatologia?: string
  panelStyle?: ExternalStyles
}

export function CaracteristicasLesaoPanel(props: CaracteristicasLesaoPanelProps) {
  const { items, descricaoSintomatologia, panelStyle } = props

  if (!items?.length && !descricaoSintomatologia) return null

  const lesaoEnvolvida = extractCaracteristicaLesaoByTipo(TipoCaracteristicaLesao.LESOES_ENVOLVIDAS, items)
  const lesaoFundamental = getLesaoFundamentalByLesaoEnvolvida(lesaoEnvolvida)
  const tipoLesaoFundamental = lesaoFundamental && getTipoLesaoFundamental(lesaoFundamental)

  return (
    <div
      css={css(
        css`
          padding: 1rem;
          background: ${colors.gray.c90};
        `,
        panelStyle
      )}
    >
      <VFlow vSpacing={0.5}>
        <Heading level={4}>Características da lesão</Heading>

        <Grid gapVertical={0.5}>
          <Cell size={4}>
            <VFlow vSpacing={0.5}>
              <InfoLabel title='Lesão fundamental'>{tipoLesaoFundamental?.title}</InfoLabel>

              <MountInfoLabel {...{ items, tipo: TipoCaracteristicaLesao.QUANTIDADE }} />

              <MountInfoLabel {...{ items, tipo: TipoCaracteristicaLesao.TAMANHO }} />

              <MountInfoLabel {...{ items, tipo: TipoCaracteristicaLesao.SUPERFICIE }} />
            </VFlow>
          </Cell>

          <Cell size={8}>
            <VFlow vSpacing={0.5}>
              {tipoLesaoFundamental && lesaoEnvolvida && (
                <InfoLabel title={tipoLesaoFundamental.lesaoEnvolvidaTitle}>
                  {getTecidosMolesCaracteristicaLesao(lesaoEnvolvida).title}
                </InfoLabel>
              )}

              <MountInfoLabel {...{ items, tipo: TipoCaracteristicaLesao.BASE }} />

              <MountInfoLabel {...{ items, tipo: TipoCaracteristicaLesao.COR }} />

              <MountInfoLabel {...{ items, tipo: TipoCaracteristicaLesao.CONSISTENCIA }} />
            </VFlow>
          </Cell>

          <Cell size={12}>
            {descricaoSintomatologia ? (
              <InfoLabel title={getTipoCaracteristicaLesao(TipoCaracteristicaLesao.SINTOMATOLOGIA).title}>
                Outro: {descricaoSintomatologia}
              </InfoLabel>
            ) : (
              <MountInfoLabel {...{ items, tipo: TipoCaracteristicaLesao.SINTOMATOLOGIA }} />
            )}
          </Cell>
        </Grid>
      </VFlow>
    </div>
  )
}

const MountInfoLabel = (props: { tipo: TipoCaracteristicaLesao; items: CaracteristicaLesaoEnum[] }) => {
  const tipoCaracteristica = getTipoCaracteristicaLesao(props.tipo)
  const caracteristica = extractCaracteristicaLesaoByTipo(props.tipo, props.items)

  return (
    <InfoLabel title={tipoCaracteristica.title}>{getTecidosMolesCaracteristicaLesao(caracteristica)?.title}</InfoLabel>
  )
}
