import { SelectField, SelectFieldProps } from 'components/form'
import { useTiposOrigemDadoTransporteSelectFieldQuery } from 'graphql/hooks.generated'
import { TipoOrigemDadoTransporte } from 'graphql/types.generated'
import React from 'react'
import { emptyArray } from 'util/array'

export type TiposOrigemDadoTransporteSelectFieldModel = TipoOrigemDadoTransporte

type TiposOrigemDadoTransporteSelectFieldProps = Omit<
  SelectFieldProps<TiposOrigemDadoTransporteSelectFieldModel>,
  'items' | 'itemToString'
>

export const TiposOrigemDadoTransporteSelectField = React.memo((props: TiposOrigemDadoTransporteSelectFieldProps) => {
  const {
    loading,
    data: { tiposOrigemDadoTransporte = emptyArray },
  } = useTiposOrigemDadoTransporteSelectFieldQuery()

  return (
    <SelectField<TiposOrigemDadoTransporteSelectFieldModel>
      label='Origem do lote'
      items={tiposOrigemDadoTransporte as TiposOrigemDadoTransporteSelectFieldModel[]}
      itemToString={itemToString}
      loading={loading}
      {...props}
    />
  )
})

const itemToString = (item: TiposOrigemDadoTransporteSelectFieldModel): string => item?.descricao
