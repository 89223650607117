import { NeuroFaixaEtariaEnum } from 'graphql/types.generated'

import { StatusDesenvolvimentoAlertProps } from '../components/StatusDesenvolvimentoAlert'
import { AvaliacaoDesenvolvimentoModel } from '../model'
import { showAlertR4, showAlertR5, showAlertR6, showAlertR7, showAlertS1 } from './regrasAlertasIndividuais'
import { AlertR1Props } from './regrasMensagensS1'

export const getStatusDesenvolvimentoAlertProps = (
  fatoresRisco: AvaliacaoDesenvolvimentoModel[],
  alteracoesFenotipicas: AvaliacaoDesenvolvimentoModel[],
  marcoFaixaEtariaAnterior: AvaliacaoDesenvolvimentoModel[],
  marcoFaixaEtariaAtual: AvaliacaoDesenvolvimentoModel[],
  alertR1Props: AlertR1Props
): StatusDesenvolvimentoAlertProps =>
  showAlertS1(marcoFaixaEtariaAnterior, alteracoesFenotipicas, marcoFaixaEtariaAtual, alertR1Props) ||
  (marcoFaixaEtariaAtual[0].faixaEtariaEnum !== NeuroFaixaEtariaEnum.PRIMEIROS_15_DIAS &&
    (showAlertR4(marcoFaixaEtariaAtual) ||
      showAlertR7(marcoFaixaEtariaAtual) ||
      showAlertR5(marcoFaixaEtariaAtual, fatoresRisco) ||
      showAlertR6(marcoFaixaEtariaAtual)))
