import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { ConfiguracaoAgendaOnlineLotacaoView } from 'view/profissional/agenda-online/ConfiguracaoAgendaOnlineLotacaoView'
import { ConfiguracaoAgendaLotacaoView } from 'view/profissional/agenda/ConfiguracaoAgendaLotacaoView'

import { AgendaView } from './AgendaView'

export interface AgendaRootViewProps {
  atencaoDomiciliar?: boolean
}

export function AgendaRootView({ atencaoDomiciliar: isAD = false }: AgendaRootViewProps) {
  const match = useRouteMatch()

  return (
    <>
      <Breadcrumb title={isAD ? 'Agenda da atenção domiciliar' : 'Agenda'} />

      <PecSwitch>
        <PrivateRoute
          exact
          path={`${match.url}/:lotacaoId(\\d+)?/:date(\\d{8})?`}
          render={(routeProps) => <AgendaView atencaoDomiciliar={isAD} {...routeProps} />}
          permission={isAD ? Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe : Permissions.visualizarAgenda}
        />
        <PrivateRoute
          path={`${match.url}/:lotacaoId(\\d+)/:date(\\d{8})/configuracaoAgenda`}
          component={ConfiguracaoAgendaLotacaoView}
          permission={Permissions.visualizarProfissionais.visualizarAcessos.configurarAgenda}
        />
        <PrivateRoute
          path={`${match.url}/:lotacaoId(\\d+)/:date(\\d{8})/configuracaoAgendaOnline/`}
          component={ConfiguracaoAgendaOnlineLotacaoView}
          permission={Permissions.visualizarProfissionais.visualizarAcessos.configurarAgendaOnline}
        />
      </PecSwitch>
    </>
  )
}
