import { msg } from '..'
import * as Util from '../Util'
import { length } from './length'

const REGEX = '^[0-9]{8}$'

export function cepRule(value: string) {
  if (!Util.isEmpty(value) && !new RegExp(REGEX).test(value)) {
    return msg('invalid', value)
  }
  return null
}

export const cep = [length(8), cepRule]
