/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, Theme, useTheme, VFlow } from 'bold-ui'
import { MetaPath } from 'util/metaPath'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { AntecedentesFamiliaresCiapField } from './componentes/AntecedentesFamiliaresCiapField'
import { FamiliarAntecedentesModel } from './model'

export interface FamiliarAntecedentesViewProps {
  name: MetaPath<FamiliarAntecedentesModel>
  cidadao?: CidadaoAtendimento
}

export function FamiliarAntecedentesView(props: FamiliarAntecedentesViewProps) {
  const { name, cidadao } = props
  const theme = useTheme()
  const classes = createStyles(theme)

  return (
    <VFlow style={classes.container}>
      <AntecedentesFamiliaresCiapField cidadao={cidadao} name={name.ciaps} />
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    margin-top: 0.5rem;
    padding: 1rem;
    border: 1px ${colors.pink.c60} solid;
    border-radius: ${theme.radius.input}px;
  `,
})
