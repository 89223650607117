import { MonthView, MonthViewProps } from 'bold-ui'
import { Interpolation } from 'emotion'
import React from 'react'

export interface CalendarAgendaLotacaoMonthViewProps extends Omit<MonthViewProps, 'createDateStyles'> {
  visibleDate: Date
  onChange: (day: Date) => void
  createDateStyles: (element: any, props: MonthViewProps) => Interpolation<undefined>
  disabled?: boolean
}

export const CalendarAgendaLotacaoMonthView: React.SFC<CalendarAgendaLotacaoMonthViewProps> = (props) => {
  const { visibleDate, onChange, createDateStyles, disabled, ...rest } = props

  return (
    <div data-testid='month-view' style={disabled ? { pointerEvents: 'none' } : {}}>
      <MonthView visibleDate={visibleDate} onDayClick={onChange} createDateStyles={createDateStyles} {...rest} />
    </div>
  )
}

CalendarAgendaLotacaoMonthView.defaultProps = { disabled: false }
