import React from 'react'

import { ErrorGroup, ErrorItem, ErrorsBox, ValidationError, ValidationErrorsField } from './ErrorsBox'
import { EquipeCnesModel, UnidadeSaudeDetalhesImportacaoModel, ValidacaoModel } from './model'

interface EquipesErrorsBoxProps {
  unidadesSaude: UnidadeSaudeDetalhesImportacaoModel[]
}

export const EquipesErrorsBox = (props: EquipesErrorsBoxProps) => {
  const { unidadesSaude } = props
  if (unidadesSaude.length === 0) {
    return null
  }

  const unidadesSaudeComErroEmEquipe = unidadesSaude.filter(
    (unidadeSaude) => unidadeSaude.equipes.filter((equipe) => equipe.validacoes).length !== 0
  )

  if (unidadesSaudeComErroEmEquipe.length === 0) {
    return null
  }

  return (
    <ErrorsBox title='Equipes'>
      <ErrorGroup title='Faltam dados obrigatórios'>
        {unidadesSaudeComErroEmEquipe.map((unidadeSaude) =>
          unidadeSaude.equipes
            .filter((equipe) => equipe.validacoes.faltamDadosObrigatorios.length > 0)
            .map((equipe, index) => (
              <EquipeErrorItem key={index} equipe={equipe.dados} errors={equipe.validacoes.faltamDadosObrigatorios} />
            ))
        )}
      </ErrorGroup>
      <ErrorGroup title='Campos preenchidos de forma incorreta'>
        {unidadesSaudeComErroEmEquipe.map((unidadeSaude) =>
          unidadeSaude.equipes
            .filter((equipe) => equipe.validacoes.camposPreenchidosIncorretos.length > 0)
            .map((equipe, index) => (
              <EquipeErrorItem
                key={index}
                equipe={equipe.dados}
                errors={equipe.validacoes.camposPreenchidosIncorretos}
              />
            ))
        )}
      </ErrorGroup>
      <ErrorGroup title='Outras inconsistências'>
        {unidadesSaudeComErroEmEquipe.map((unidadeSaude) =>
          unidadeSaude.equipes
            .filter((equipe) => equipe.validacoes.erro)
            .map((equipe, index) => (
              <EquipeErrorItem key={index} equipe={equipe.dados} error={equipe.validacoes.erro} />
            ))
        )}
      </ErrorGroup>
    </ErrorsBox>
  )
}

interface EquipeErrorItemProps {
  equipe: EquipeCnesModel
  error?: string
  errors?: ValidacaoModel[]
}

const EquipeErrorItem = (props: EquipeErrorItemProps) => {
  const { equipe, error, errors } = props
  return (
    <ErrorItem>
      {equipe.nome || 'Nome não informado'}, INE: {equipe.ine || '-'}
      {error && <ValidationError error={error} />}
      {errors && <ValidationErrorsField errors={errors} />}
    </ErrorItem>
  )
}
