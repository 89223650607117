/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SelectHelperMenuItem, Text } from 'bold-ui'

interface CidadaoAtendimentoPorPropriedadeEmptyItemProps {
  skipping: boolean
  skippingText: string
}

export function CidadaoAtendimentoPorPropriedadeEmptyItem(props: CidadaoAtendimentoPorPropriedadeEmptyItemProps) {
  const { skipping, skippingText } = props

  return (
    <SelectHelperMenuItem>
      <div
        css={css`
          padding: 0.5rem 0rem;
        `}
      >
        {skipping ? (
          <Text>{skippingText}</Text>
        ) : (
          <Text fontStyle='italic'>Não foi possível localizar um cidadão, tente buscar utilizando outro dado</Text>
        )}
      </div>
    </SelectHelperMenuItem>
  )
}
