import { DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useCidadaoAdExcluirMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'
import Permissions from 'types/Permissions'

import { CidadaoAdItemType } from './ListaADTable'

type CidadaoJustificativa = CidadaoAdItemType['cidadao']

export interface ListaADTableDropdownProps {
  cidadaoAd: CidadaoAdItemType
  refetch(): void
}

export default function ListaADTableDropdown(props: ListaADTableDropdownProps) {
  const { cidadaoAd, refetch } = props

  const alert = useAlert()
  const history = useHistory()
  const handleRejection = useErrorHandler()
  const [excluirCidadaoAd] = useCidadaoAdExcluirMutation()

  const onExcluirClick = () => {
    confirm({
      title: 'Deseja excluir a atenção domiciliar?',
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return excluirCidadaoAd({ variables: { id: cidadaoAd.id } })
          .then(() => {
            alert('success', 'Atenção domiciliar excluída com sucesso.')
            refetch()
          })
          .catch(handleRejection)
      },
    })()
  }

  const handleClickVisualizarProntuario = () => {
    history.push(`/cidadao/${cidadaoAd.cidadao.id}/folha-rosto`)
  }

  return (
    <Tooltip text='Mais opções'>
      <DropdownButton>
        <Tooltip
          text={
            !cidadaoAd.actions.adEncerrado.enabled
              ? cidadaoAd.actions.adEncerrado.hint
              : cidadaoAd.actions.permissaoExcluirAD.hint
          }
        >
          <DropdownItem onClick={onExcluirClick} type='danger' disabled={!cidadaoAd.actions.permissaoExcluirAD.enabled}>
            <HFlow alignItems='center' hSpacing={0.5}>
              <Icon icon='trashOutline' />
              Excluir
            </HFlow>
          </DropdownItem>
        </Tooltip>

        <CheckPermission permission={Permissions.visualizarProntuario}>
          <DropdownItem disabled={false} onClick={handleClickVisualizarProntuario}>
            Visualizar prontuário
          </DropdownItem>
        </CheckPermission>
      </DropdownButton>
    </Tooltip>
  )
}
