import { ButtonProps, IconProps, modal } from 'bold-ui'
import { IconColor } from 'bold-ui/lib/components/Icon/Icon'
import { ModalDepthLevel } from 'bold-ui/lib/components/Modal/Modal'
import React from 'react'

import { ModalBody } from '../ModalBody'

const icons: { [key in ButtonProps['kind']]: IconProps['icon'] } = {
  normal: 'infoCircleOutline',
  primary: 'infoCircleOutline',
  danger: 'exclamationTriangleOutline',
}

export interface InformationConfig {
  title: React.ReactNode
  body?: React.ReactNode
  type?: ButtonProps['kind']
  iconColor?: IconColor
  onClose?(): any
  depthLevel?: ModalDepthLevel
  manageOverflow?: boolean
  showCloseButton?: boolean
  closeLabel?: string
}

export const information = (config: InformationConfig) => {
  const {
    title = 'Informação',
    body = null,
    type = 'primary',
    iconColor,
    onClose,
    depthLevel,
    manageOverflow,
    showCloseButton = false,
    closeLabel = 'Fechar',
  } = config

  return modal({
    size: 'small',
    onClose,
    render: () => (
      <ModalBody title={title} icon={icons[type]} type={iconColor ?? type}>
        {body}
      </ModalBody>
    ),
    depthLevel,
    actions: showCloseButton ? [{ kind: type, label: closeLabel, onClick: onClose }] : null,
    manageOverflow,
  })
}
