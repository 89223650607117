import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { ParteBucalEnum } from 'graphql/types.generated'
import { RowType } from 'view/atendimentos/detail/components/EditableList'

export const URL_EDITAR_PROCEDIMENTOS_PERIODONTAIS = '/procedimentos-periodontais/editar/'

export interface EvolucoesPeriodontaisFieldModel extends RowType {
  sextante: ParteBucalEnum
  procedimentos?: ProcedimentoOdontoSelectModel[]
  lastUpdate: Instant
  observacao?: string
}

export interface EvolucoesPeriodontaisTableItemModel extends Omit<EvolucoesPeriodontaisFieldModel, '_id' | 'sextante'> {
  local: string
  isRegistradoAgora: boolean
  lotacao: {
    id: ID
    profissional: { id: ID; nome: string }
    cbo: { id: ID; nome: string }
  }
  searchProcedimentos: string
  cacheId?: ID
}
