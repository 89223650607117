import { HFlow, Icon, Icons, PagedTable, Tag, Text, TextColor } from 'bold-ui'
import { DateTime } from 'components/date'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useTransmissaoLinkQuery } from 'graphql/hooks.generated'
import { TransmissaoLinkQueryVariables, TransmissaoLinksPayload } from 'graphql/types.generated'
import React, { useState } from 'react'

import { TransmissaoLinksDestinoBtns } from './TransmissaoLinksDestinoBtns'
import { TransmissaoLinksDestinoFilter } from './TransmissaoLinksDestinoFilter'

export type TransmissaoLinksDestinoQueryInput = TransmissaoLinkQueryVariables['input']
export type TransmissaoLinksItem = TransmissaoLinksPayload['content'][0]

export const TransmissaoLinksDestinoTable = () => {
  const [filter, setFilter] = useState<TransmissaoLinksDestinoQueryInput>({
    mostrarInativos: false,
    pageParams: {
      sort: ['name'],
    },
  })

  const {
    data: { transmissaoLink },
    loading,
    refetch,
  } = useTransmissaoLinkQuery({
    variables: {
      input: filter,
    },
  })

  const renderData = (row: TransmissaoLinksItem) => <DateTime value={row.dataTesteConexao} />

  const renderConexao = (row: TransmissaoLinksItem) => {
    if (row.statusConexao) {
      return <ConexaoStatus size={1.1} icon='checkDefault' status='success' label='Estabelecida' />
    }
    return <ConexaoStatus size={1.1} icon='exclamationTriangleOutline' status='alert' label='Não estabelecida' />
  }

  const renderLink = (row: TransmissaoLinksItem) => <Text>{row.hostname}</Text>

  const renderNome = (row: TransmissaoLinksItem) => (
    <HFlow hSpacing={0.5} alignItems='center'>
      <Text>{row.name}</Text>
      {!row.ativo && <Tag type='normal'>Inativa</Tag>}
    </HFlow>
  )

  const renderButtons = (row) => <TransmissaoLinksDestinoBtns row={row} refetch={refetch} />

  const tableProps = usePagedTableProps({
    result: transmissaoLink,
    loading,
    onChange: setFilter,
  })

  return (
    <TableBox header={<TransmissaoLinksDestinoFilter refetch={refetch} initialValues={filter} load={setFilter} />}>
      <PagedTable<TransmissaoLinksItem>
        {...tableProps}
        columns={[
          { name: 'name', header: 'Nome da instalação', render: renderNome },
          { name: 'hostname', header: 'Endereço do servidor', render: renderLink },
          { name: 'ativo', header: 'Conexão', render: renderConexao },
          { name: 'data', header: 'Último teste', render: renderData },
          { name: 'btn', render: renderButtons },
        ]}
      />
    </TableBox>
  )
}

interface ConexaoStatusProps {
  icon: Icons
  status: TextColor
  label: string
  size: number
}
const ConexaoStatus = (props: ConexaoStatusProps) => {
  return (
    <HFlow style={{ lineHeight: 1 }} alignItems='center' justifyContent='flex-start'>
      <Icon icon={props.icon} fill={props.status} size={props.size} />
      <Text color={props.status}>{props.label}</Text>
    </HFlow>
  )
}
