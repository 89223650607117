import ApolloClient, { ApolloQueryResult } from 'apollo-client'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { information } from 'components/modals/information'
import { differenceInDays } from 'date-fns'
import { CidPrimeiroAtendimentoPreNatalDocument, IdadeGestacionalDocument } from 'graphql/hooks.generated'
import {
  CidPrimeiroAtendimentoPreNatalQuery,
  IdadeGestacionalAcompanhamentoPreNatal,
  IdadeGestacionalQuery,
  ResultadoExameInput,
} from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsDdMmYyyy, dateAsYyyyMmDd, toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { Problema } from '../aside/types/ProblemaModel'
import { AvaliacaoFormModel } from '../avaliacao/AvaliacaoForm'
import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import {
  findProblemaByCiapIdCidId,
  findProblemaById,
  findProblemaComCiapW78,
  findProblemaCondicaoNaoEvolucaoGravidezAltoRisco,
} from '../avaliacao/components/problemas-condicoes/utils/operations-problemasCondicoes'
import {
  hasProblemaComCiapW78Ativo,
  hasProblemaCondicaoComCiapW78Ativo,
  hasProblemaCondicaoComCiapW78Automatico,
} from '../avaliacao/components/problemas-condicoes/utils/verifications-problemasCondicoes'
import {
  CIAP_GRAVIDEZ_ALTO_RISCO,
  CIAPS_ENCERRAMENTO_GESTACAO,
  CIAPS_GESTACAO_CODIGOS,
  CIAPS_PUERPERIO_CODIGOS,
  CID_GRAVIDEZ_ALTO_RISCO,
  CidPreNatalModel,
  CIDS_ENCERRAMENTO_GESTACAO,
  CIDS_GESTACAO_CODIGOS,
  CIDS_PUERPERIO_CODIGOS,
  FAMILIA_CID_Z34_CODIGO,
  IDADE_GESTACIONAL_MAXIMA_EM_DIAS,
  LIMITE_DIFERENCA_ENTRE_DUM_DATA_ATENDIMENTO_EM_DIAS,
  PreNatalCalculatorStatus,
} from './model-preNatal'

export const getCidPrimeiroAtendimentoPreNatal = async (
  prontuarioId: ID,
  apollo: ApolloClient<object>
): Promise<CidPreNatalModel> =>
  apollo
    .query({
      query: CidPrimeiroAtendimentoPreNatalDocument,
      variables: { prontuarioId },
      fetchPolicy: 'network-only',
    })
    .then(
      (response: ApolloQueryResult<CidPrimeiroAtendimentoPreNatalQuery>) => response.data.cidPrimeiroAtendimentoPreNatal
    )

export const getIdadeGestacional = (
  prontuarioId: ID,
  dataAtendimento: Instant,
  apollo: ApolloClient<object>,
  resultadosExamesPreNatalAtendimentoAtual: ResultadoExameInput[],
  dum: string
): Promise<IdadeGestacionalAcompanhamentoPreNatal> =>
  apollo
    .query({
      query: IdadeGestacionalDocument,
      variables: {
        input: {
          prontuarioId,
          dataAtendimento: dateAsYyyyMmDd(dataAtendimento),
          resultadosExamesPreNatalAtendimentoAtual,
          dum,
        },
      },
    })
    .then((response: ApolloQueryResult<IdadeGestacionalQuery>) => response.data.idadeGestacional)

export const formatIdadeGestacional = (igTotalEmDias: number): string => {
  if (!isUndefinedOrNull(igTotalEmDias)) {
    const semanas = Math.trunc(igTotalEmDias / 7)
    const dias = igTotalEmDias % 7
    return `${'semana'.pluralizeAndConcatValue(semanas)} e ${'dia'.pluralizeAndConcatValue(dias)}`
  } else return null
}

function isCiapPuerperio(codigoCiap?: string) {
  return CIAPS_PUERPERIO_CODIGOS.has(codigoCiap)
}

function isCidPuerperio(codigoCid?: string) {
  return CIDS_PUERPERIO_CODIGOS.has(codigoCid)
}

export function isCiapOuCidDePuerperio(codigoCiap?: string, codigoCid?: string) {
  return (codigoCiap && isCiapPuerperio(codigoCiap)) || (codigoCid && isCidPuerperio(codigoCid))
}

export function isCidFromFamiliaZ34(codigoCid?: string) {
  return FAMILIA_CID_Z34_CODIGO.has(codigoCid)
}

export function isCiapOuCidDeEncerramentoGestacao(codigoCiap?: string, codigoCid?: string) {
  return (codigoCiap && isCiapEncerramentoGestacao(codigoCiap)) || (codigoCid && isCidEncerramentoGestacao(codigoCid))
}

export function isCiapEncerramentoGestacao(codigoCiap?: string) {
  return CIAPS_ENCERRAMENTO_GESTACAO.has(codigoCiap)
}

export function isCidEncerramentoGestacao(codigoCid?: string) {
  return CIDS_ENCERRAMENTO_GESTACAO.has(codigoCid)
}

export function isCiapOuCidDeGestacao(codigoCiap?: string, codigoCid?: string) {
  return (codigoCiap && isCiapGestacao(codigoCiap)) || (codigoCid && isCidGestacao(codigoCid))
}

export function isCiapGestacao(codigoCiap?: string) {
  return CIAPS_GESTACAO_CODIGOS.has(codigoCiap)
}

export function isCidGestacao(codigoCid?: string) {
  return CIDS_GESTACAO_CODIGOS.has(codigoCid)
}

export function isCiapGravidezAltoRisco(codigoCiap?: string) {
  return CIAP_GRAVIDEZ_ALTO_RISCO.has(codigoCiap)
}

export function isCidGravidezAltoRisco(codigoCid?: string) {
  return CID_GRAVIDEZ_ALTO_RISCO.has(codigoCid)
}

export function isDumForaDoLimitePermitido(dataAtendimento: Instant, dum: string): boolean {
  return differenceInDays(dataAtendimento, toDate(dum)) > LIMITE_DIFERENCA_ENTRE_DUM_DATA_ATENDIMENTO_EM_DIAS
}

export const informationPreNatalQuandoExisteW78ResolvidoNaAvaliacao = () => {
  information({
    title: 'Não é possível iniciar Pré-natal.',
    body: 'Para iniciar Pré-natal, remova a condição W78 com a situação resolvida adicionada na Avaliação.',
  })()
}

export const informationW78ResolvidoNaAvaliacaoQuandoExistePreNatal = () => {
  information({
    title: 'Não é possível resolver a condição de gravidez.',
    body: 'Para resolver a condição de gravidez, remova a condição de Pré-natal.',
  })()
}

export const informationPreNatalSuperiorA336Dias = (dataInicioGestacao: Date) => {
  information({
    title: 'A gestação da cidadã está ativa desde ' + dateAsDdMmYyyy(dataInicioGestacao),
    body:
      'Não é possível dar continuidade a um pré-natal com período superior a ' +
      IDADE_GESTACIONAL_MAXIMA_EM_DIAS +
      ' dias. Para iniciar uma nova gestação, é necessário encerrar a gestação atual através da avaliação do SOAP.',
  })()
}

interface ResetPreNatalValuesProps {
  resetDum(): void
  resetPreNatal(): void
  resetAgendarProximasConsultas(): void
  isPrimeiroAtendimentoPreNatal: boolean
}

export const resetPreNatalValuesToUndefined = ({
  resetDum,
  resetPreNatal,
  resetAgendarProximasConsultas,
  isPrimeiroAtendimentoPreNatal,
}: ResetPreNatalValuesProps) => {
  if (!isPrimeiroAtendimentoPreNatal) {
    resetDum()
  }
  resetPreNatal()
  resetAgendarProximasConsultas()
}

export function getPreNatalCalculatorStatus(
  avaliacao: MetaPath<AvaliacaoFormModel>,
  prevValues: SoapState,
  problemasCondicoesAvaliados: ProblemaCondicaoModel[],
  problemasCondicoesAnteriores: ProblemaCondicaoModel[],
  problemasCidadao?: Problema[]
): PreNatalCalculatorStatus {
  const problemasCondicoesAvaliadosValoresPrevValues =
    resolveValue<ProblemaCondicaoModel[]>(prevValues, avaliacao.problemasECondicoes) ?? []

  const problemaNaoEvolucaoAltoRisco = findProblemaCondicaoNaoEvolucaoGravidezAltoRisco(problemasCondicoesAvaliados)
  const problemaNaoEvolucaoAltoRiscoPrevValue = findProblemaCondicaoNaoEvolucaoGravidezAltoRisco(
    problemasCondicoesAvaliadosValoresPrevValues
  )

  const isAltoRiscoExcluido =
    isUndefinedOrNull(problemaNaoEvolucaoAltoRisco) && !isUndefinedOrNull(problemaNaoEvolucaoAltoRiscoPrevValue)
  const isAltoRiscoAdicionadoEmPrimeiroAtendimentoPreNatal =
    problemaNaoEvolucaoAltoRisco &&
    !problemaNaoEvolucaoAltoRiscoPrevValue &&
    !hasProblemaCondicaoComCiapW78Ativo(problemasCondicoesAnteriores) &&
    !hasProblemaComCiapW78Ativo(problemasCidadao)

  const mustUpdateDataInicioW78Automatico =
    problemaNaoEvolucaoAltoRisco &&
    problemaNaoEvolucaoAltoRiscoPrevValue &&
    problemaNaoEvolucaoAltoRiscoPrevValue.dataInicio !== problemaNaoEvolucaoAltoRisco.dataInicio &&
    hasProblemaCondicaoComCiapW78Automatico(problemasCondicoesAnteriores)

  const mustRemoveW78Automatico = isAltoRiscoExcluido || mustUpdateDataInicioW78Automatico
  const mustAddW78Automatico = isAltoRiscoAdicionadoEmPrimeiroAtendimentoPreNatal || mustUpdateDataInicioW78Automatico

  return {
    mustRemoveW78NosProblemasCondicoesAnteriores: mustRemoveW78Automatico,
    mustAddW78NosProblemasCondicoesAnteriores: mustAddW78Automatico,
    dataInicioProblemaAltoRisco: problemaNaoEvolucaoAltoRisco?.dataInicio.data,
  }
}

export function getW78(
  problemasCidadao: Problema[],
  problemaCondicaoComW78: ProblemaCondicaoModel,
  somenteCiap: boolean
): Problema {
  var problemaW78 =
    findProblemaById(problemasCidadao, problemaCondicaoComW78.problemaId) ??
    findProblemaByCiapIdCidId(problemasCidadao, problemaCondicaoComW78?.ciap?.id, problemaCondicaoComW78?.cid?.id)

  if (!somenteCiap && !problemaW78) {
    problemaW78 = findProblemaComCiapW78(problemasCidadao)
  }

  return problemaW78
}
