export function concatenarOrigemTagAnalytics(tag: string, url: string) {
  if (url.includes('folha-rosto')) {
    tag += '_FR'
  } else if (url.includes('discussao-caso')) {
    tag += '_CC'
  } else if (url.includes('soap')) {
    tag += '_SOAP'
  }
  return tag
}
