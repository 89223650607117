import api from 'api'
import { defaultHandleError } from 'util/downloadFile'
import { generatePdf } from 'util/generatePdf'

import { ImpressaoMedicamentosInput } from './ImpressaoMedicamentosInput'

export const downloadMedicamentos = async (input: ImpressaoMedicamentosInput) => {
  return await api.prescricaoMedicamento
    .imprimirMedicamentos(input)
    .then((response) => {
      generatePdf(new Blob([response.data], { type: 'application/pdf' }), 'Imprimir prescrição de medicamentos')
      return response
    })
    .catch(defaultHandleError)
}
