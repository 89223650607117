import { Button, HFlow, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DateTime } from 'components/date'
import { Form, FormRenderProps } from 'components/form/final-form/Form'
import { confirm } from 'components/modals/confirm'
import { useDesativarAgendaOnlineMutation } from 'graphql/hooks.generated'
import React from 'react'

import { ConfiguracaoAgendaOnline } from './ConfiguracaoAgendaOnlineBox'
import { ConfiguracaoAgendaOnlineTesteConexao } from './ConfiguracaoAgendaOnlineTesteConexao'

export interface ConfiguracaoAgendaOnlineHabilitadoContentProps {
  agendaOnline: ConfiguracaoAgendaOnline
  refetch(): void
}

export function ConfiguracaoAgendaOnlineHabilitadoContent(props: ConfiguracaoAgendaOnlineHabilitadoContentProps) {
  const { agendaOnline } = props

  const alert = useAlert()
  const [desativarAgendaOnline, { loading }] = useDesativarAgendaOnlineMutation()

  const handleDesativar = (formProps: FormRenderProps) => (e) => {
    return confirm({
      body:
        'Para habilitar este serviço novamente será necessário' +
        ' acessar o Portal do Gestor para gerar uma nova contra-chave.',
      title: `Deseja desabilitar a agenda online?`,
      type: 'primary',
      confirmLabel: 'Desabilitar',
      onConfirm: formProps.form.submit,
    })()
  }

  const handleSubmit = () => {
    return desativarAgendaOnline().then(() => {
      alert('success', 'Conexão com a agenda online foi desabilitada com sucesso.')
      props.refetch()
    })
  }

  const renderForm = (formProps: FormRenderProps) => {
    return (
      <Button kind='primary' size='small' onClick={handleDesativar(formProps)} loading={loading}>
        Desabilitar
      </Button>
    )
  }

  return (
    <VFlow>
      <HFlow alignItems='center' justifyContent='space-between'>
        <Form onSubmit={handleSubmit} render={renderForm} />
        <Text>
          Habilitada por {agendaOnline.detalhesAtivacao.profissional.nome} em{' '}
          <DateTime value={agendaOnline.detalhesAtivacao.dataEvento} />.
        </Text>
      </HFlow>

      <ConfiguracaoAgendaOnlineTesteConexao
        testeConexao={agendaOnline.testeConexao}
        refetch={props.refetch}
        loading={loading}
      />
    </VFlow>
  )
}
