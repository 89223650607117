/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, HFlow, Radio, Switch } from 'bold-ui'
import theme from 'config/theme'

import { OdontogramaUseMode, OdontogramaViewMode } from '../model'
import {
  PeriodoOdontogramaSelect,
  PeriodoOdontogramaSelectModel,
} from './PeriodoOdontogramaSelect/PeriodoOdontogramaSelect'

export interface OdontogramaInputsProps {
  prontuarioId: ID
  viewMode: OdontogramaViewMode
  useMode?: OdontogramaUseMode
  showDeciduos?: boolean
  toggleShowDeciduos(): void
  toggleViewMode(viewMode: OdontogramaViewMode): void
  onChangePeriodo(item: PeriodoOdontogramaSelectModel): void
  readOnly?: boolean
  hideViewModeOptions?: boolean
}

export function OdontogramaInputs(props: OdontogramaInputsProps) {
  const {
    prontuarioId,
    viewMode,
    useMode,
    showDeciduos,
    toggleViewMode,
    toggleShowDeciduos,
    onChangePeriodo,
    readOnly,
    hideViewModeOptions,
  } = props

  return (
    <HFlow
      style={css`
        padding: 0.5rem;
        border: 1px solid ${theme.pallete.divider};
      `}
      hSpacing={1}
      justifyContent='space-between'
    >
      <HFlow>
        <PeriodoOdontogramaSelect
          prontuarioId={prontuarioId}
          useMode={useMode}
          onChange={onChangePeriodo}
          readOnly={readOnly}
        />

        {!hideViewModeOptions && (
          <FormControl
            label={
              <span
                css={css`
                  white-space: nowrap;
                `}
              >
                Visualizar odontograma por
              </span>
            }
          >
            <HFlow
              hSpacing={1}
              style={css`
                margin-left: -0.25rem;
                margin-top: 0.5rem;
              `}
            >
              <Radio
                label='Dentes'
                value='dentes'
                checked={viewMode === OdontogramaViewMode.DENTES}
                onChange={() => toggleViewMode(OdontogramaViewMode.DENTES)}
              />
              <Radio
                label='Arcadas'
                value='arcadas'
                checked={viewMode === OdontogramaViewMode.ARCADAS}
                onChange={() => toggleViewMode(OdontogramaViewMode.ARCADAS)}
              />
              <Radio
                label='Outros'
                value='outros'
                checked={viewMode === OdontogramaViewMode.OUTROS}
                onChange={() => toggleViewMode(OdontogramaViewMode.OUTROS)}
              />
            </HFlow>
          </FormControl>
        )}
      </HFlow>

      {!(viewMode !== 'dentes') && (
        <FormControl
          label={
            <span
              css={css`
                white-space: nowrap;
              `}
            >
              Mostrar dentes decíduos
            </span>
          }
        >
          <Switch
            label={showDeciduos ? 'Sim' : 'Não'}
            onChange={toggleShowDeciduos}
            style={{ marginTop: '0.25rem' }}
            disabled={viewMode !== 'dentes'}
            checked={showDeciduos}
          />
        </FormControl>
      )}
    </HFlow>
  )
}
