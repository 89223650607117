/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente43(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='38' height='117' viewBox='0 0 38 117' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M8.13657 66.8419C7.45809 56.4221 6.33766 43.3505 5.62853 37.9772C9.65466 35.4227 16.7134 33.6723 22.8155 32.9178C25.9238 32.5335 28.7433 32.4127 30.7411 32.5664C31.7473 32.6438 32.5045 32.7882 32.98 32.9807C33.2188 33.0774 33.345 33.17 33.4026 33.2354C33.4442 33.2827 33.4412 33.3008 33.4379 33.3207L33.4377 33.3217C33.2449 34.4797 32.4317 38.1978 31.3956 42.9346C31.1223 44.1842 30.8335 45.5047 30.5365 46.8679C29.117 53.3822 27.5154 60.846 26.5473 66.0968C24.6164 76.5688 20.278 95.2405 16.0208 106.717C14.9674 109.557 13.8205 111.336 12.7487 112.356C11.6819 113.371 10.7251 113.606 9.99469 113.464C9.25463 113.32 8.61755 112.765 8.24278 111.938C7.86914 111.114 7.77577 110.051 8.11426 108.983C8.31844 108.339 8.47354 107.362 8.59407 106.157C8.71557 104.942 8.80484 103.462 8.86722 101.794C8.99202 98.4571 9.00996 94.3475 8.95945 90.0589C8.85842 81.4816 8.48324 72.166 8.13657 66.8419Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M7.19054 39.9801C6.42594 38.7605 5.62562 36.6104 4.9044 33.9792C4.18706 31.362 3.56005 28.3147 3.12621 25.3327C2.69203 22.3484 2.45367 19.4455 2.50748 17.1139C2.5344 15.9471 2.63421 14.9392 2.81279 14.1423C2.99488 13.3297 3.24402 12.8063 3.51642 12.5354C4.99316 11.0663 7.92229 8.54617 11.3917 6.39689C14.8744 4.23938 18.8288 2.50159 22.3747 2.50159C29.4693 2.50159 35.9472 8.85725 35.4757 16.6756L35.4728 16.7241C35.2281 20.7814 34.9047 26.1447 34.0364 30.7283C33.6004 33.0302 33.0317 35.1076 32.281 36.7131C31.5241 38.3318 30.6235 39.3826 29.5747 39.7778C27.152 40.6905 22.2565 41.9654 17.5761 42.3719C15.2357 42.5751 12.9784 42.5584 11.1233 42.1856C9.2569 41.8104 7.88905 41.0943 7.19054 39.9801Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M35.9748 16.7057C36.464 8.59361 29.751 2.00159 22.3747 2.00159C14.9985 2.00159 6.1645 9.19583 3.1638 12.1809C0.563014 14.7681 2.75602 30.091 5.48532 37.4768C5.35068 37.5604 5.21913 37.6449 5.0908 37.7303C5.79435 42.8725 6.94548 56.246 7.63754 66.8744C8.3296 77.5028 9.1371 104.101 7.63754 108.832C6.13798 113.563 12.2176 118.407 16.4895 106.891C20.7614 95.3748 25.106 76.6706 27.0389 66.1875C28.2065 59.8546 30.2987 50.2893 31.8809 43.0556C32.8519 38.6163 33.6309 35.0551 33.8862 33.658C35.2591 28.5749 35.6746 21.6843 35.971 16.7684L35.9748 16.7057ZM35.8385 34.0995C37.2476 28.8275 37.6697 21.8259 37.9608 16.9979L37.9712 16.8261C38.5312 7.53903 30.8775 0.00158691 22.3747 0.00158691C18.1176 0.00158691 13.6724 2.0432 10.0752 4.27165C6.42487 6.53297 3.34809 9.17649 1.75329 10.763C1.06247 11.4502 0.693916 12.3708 0.47385 13.1871C0.24273 14.0443 0.11273 15.0292 0.0492739 16.0638C-0.0779015 18.1374 0.0491157 20.6509 0.338019 23.2503C0.861137 27.9569 1.94533 33.2038 3.2142 37.0387C3.10291 37.3405 3.06394 37.6703 3.10926 38.0014C3.80239 43.0675 4.94993 56.3795 5.64177 67.0043C5.98522 72.279 6.35897 81.55 6.45954 90.0884C6.50982 94.3578 6.4915 98.4218 6.36888 101.7C6.30753 103.341 6.22077 104.764 6.10639 105.908C5.98808 107.091 5.85152 107.848 5.73101 108.228C5.21052 109.87 5.33108 111.57 5.96569 112.97C6.59577 114.36 7.81176 115.586 9.51762 115.918C11.2623 116.257 13.0027 115.565 14.4718 114.167C15.9212 112.788 17.2382 110.623 18.3646 107.587C22.6953 95.9119 27.0647 77.0777 29.0057 66.5501C30.166 60.2573 32.2497 50.7296 33.8348 43.4828L33.8552 43.3893C34.7933 39.1005 35.563 35.5817 35.8385 34.0995Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
