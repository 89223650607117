import { PrintText, PrintTitle, SpaceLine } from 'components/print'
import React from 'react'
import { conformToMask } from 'react-text-mask'
import { masks } from 'util/mask'

import { DeclaracaoComparecimentoModel, Periodo } from './DeclaracaoComparecimentoView'

export interface DeclaracaoComparecimentoProps extends DeclaracaoComparecimentoModel {
  dataPorExtenso: string
  dataDeclaracao: string
}

export const DeclaracaoComparecimento = (props: DeclaracaoComparecimentoProps) => {
  const {
    cidadao,
    unidadeSaude,
    periodo,
    horarioEntrada,
    horarioSaida,
    nomeAcompanhante,
    dataPorExtenso,
    dataDeclaracao,
  } = props

  const cpf = cidadao.cpf ? conformToMask(cidadao.cpf, masks.cpf, { guide: false }).conformedValue : undefined

  return (
    <>
      <PrintTitle title='Declaração de comparecimento' level={3} />
      <SpaceLine />
      <PrintText textStyles={{ marginLeft: '24px', marginRight: '24px' }}>
        {'Declaro que ' +
          (cidadao.nomeSocial ? cidadao.nomeSocial + ' (' + cidadao.nome + ')' : cidadao.nome) +
          (cidadao.cns ? ', CNS ' + cidadao.cns : '') +
          (cpf ? ', CPF ' + cpf : '') +
          ', permaneceu na ' +
          unidadeSaude.nome +
          ' no dia ' +
          dataDeclaracao +
          (periodo === Periodo.PERSONALIZADO
            ? ' das ' + horarioEntrada + ' até às ' + horarioSaida
            : ' no turno ' + periodo) +
          (nomeAcompanhante ? ' com acompanhamento de ' + nomeAcompanhante.toLocaleUpperCase().trimAll() : '') +
          '.'}
      </PrintText>
      <PrintText
        wrapperStyles={{
          marginTop: '100px',
          marginRight: '32px',
          alignSelf: 'flex-end',
        }}
      >
        {unidadeSaude.endereco.municipio.nome.titleCase() +
          ' - ' +
          unidadeSaude.endereco.municipio.uf.sigla +
          ', ' +
          dataPorExtenso +
          '.'}
      </PrintText>
    </>
  )
}
