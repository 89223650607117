/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, InfoLabel, useTheme } from 'bold-ui'
import { MarcadorConsumoAlimentar, PerguntasQuestionarioEnum } from 'graphql/types.generated'
import { mcaDoisAnosOuMaisQuestions } from 'view/atendimentos/detail/soap/objetivo/marcadores-consumo-alimentar/model'

import { convertOpcaoMCAEnumToString } from '../../../utils/utils-historico'
import HistoricoDetailTable from '../../table/HistoricoDetailTable'

interface HistoricoMCADoisAnosOuMaisTableBoxProps {
  marcadorConsumoAlimentar: MarcadorConsumoAlimentar
}

export function HistoricoMCADoisAnosOuMaisTableBox({
  marcadorConsumoAlimentar,
}: HistoricoMCADoisAnosOuMaisTableBoxProps) {
  const respostasMCA = convertOpcaoMCAEnumToString(marcadorConsumoAlimentar)

  const theme = useTheme()
  return (
    <HistoricoDetailTable title='Marcadores de consumo alimentar' sectionStyle='O'>
      <Grid>
        <Cell size={12}>
          <Heading level={4}>Crianças com 2 anos ou mais, adolescentes, adultos, gestantes e idosos</Heading>
        </Cell>

        <Cell size={12}>
          <InfoLabel title={mcaDoisAnosOuMaisQuestions.refeicoesAssistindoTV}>
            {respostasMCA.get(PerguntasQuestionarioEnum.VOCE_COSTUME_REALIZAR_REFEICOES_ASSISTINDO_TV)}
          </InfoLabel>
        </Cell>

        <Cell size={12}>
          <InfoLabel title={mcaDoisAnosOuMaisQuestions.refeicoesPorDia}>
            {respostasMCA.get(PerguntasQuestionarioEnum.QUAIS_REFEICOES_VOCE_FEZ_LONGO_DIA)}
          </InfoLabel>
        </Cell>

        <Cell
          size={12}
          style={css`
            border-bottom: 1px solid ${theme.pallete.divider};
          `}
        >
          <Heading level={4}>Ontem você consumiu</Heading>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaDoisAnosOuMaisQuestions.feijao}>
            {respostasMCA.get(PerguntasQuestionarioEnum.FEIJAO_MAIOR_2ANOS)}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title={mcaDoisAnosOuMaisQuestions.bebidasComAcucar}>
            {respostasMCA.get(PerguntasQuestionarioEnum.BEBIDAS_ADOCADAS_MAIOR_2ANOS)}
          </InfoLabel>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaDoisAnosOuMaisQuestions.frutas}>
            {respostasMCA.get(PerguntasQuestionarioEnum.FRUTAS_FRESCAS)}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title={mcaDoisAnosOuMaisQuestions.macarraoInstantaneo}>
            {respostasMCA.get(PerguntasQuestionarioEnum.MACARRAO_INSTANTANEO_SALGADINHO_BISCOITO_MAIOR_2ANOS)}
          </InfoLabel>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaDoisAnosOuMaisQuestions.legumes}>
            {respostasMCA.get(PerguntasQuestionarioEnum.VERDURAS_LEGUMES)}
          </InfoLabel>
        </Cell>
        <Cell size={6}>
          <InfoLabel title={mcaDoisAnosOuMaisQuestions.guloseimas}>
            {respostasMCA.get(PerguntasQuestionarioEnum.BISCOITO_RECHEADO_DOCES_GULOSEIMAS_MAIOR_2ANOS)}
          </InfoLabel>
        </Cell>

        <Cell size={6}>
          <InfoLabel title={mcaDoisAnosOuMaisQuestions.embutidos}>
            {respostasMCA.get(PerguntasQuestionarioEnum.HAMBURGUER_E_OU_EMBUTIDOS_MAIOR_2ANOS)}
          </InfoLabel>
        </Cell>
      </Grid>
    </HistoricoDetailTable>
  )
}
