import {
  ATUALIZADO_MAIS_DE_UM_ANO,
  ATUALIZADO_MENOS_DE_UM_ANO,
} from 'components/form/field/select/PeriodoUltimaAtualizacaoSelectField'
import {
  VISITA_MENOS_DE_UM_MES,
  VISITA_UM_MES_OU_MAIS,
} from 'components/form/field/select/PeriodoUltimaVisitaSelectField'
import { COMPLETO, INCOMPLETO } from 'components/form/field/select/StatusCadastroSelectField'
import { LogradouroTerritorioDto } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'

import {
  LogradouroEditFormModel,
  VisualizacaoMicroareasFilterModel,
  VisualizacaoTerritorialBuscaFormModel,
  VisualizacaoTerritorialMicroareasFilterModel,
  VisualizacaoTerritorioInput,
} from './model'

export const convertVisualizacaoFormModelToInput = (
  model: VisualizacaoTerritorialBuscaFormModel
): VisualizacaoTerritorioInput => {
  return {
    cnes: model.cnes,
    ine: model.ine,
    bairro: model.bairro,
    cep: model.cep,
    logradouro: model.logradouro,
    tipoLogradouroId: model.tipoLogradouro?.id,
  }
}

export const convertLogradouroDtoToLogradouroEditFormModel = (
  item: LogradouroTerritorioDto
): LogradouroEditFormModel => {
  return {
    id: item.id,
    bairro: {
      nome: item.bairro,
    },
    tipoLogradouro: {
      __typename: 'TipoLogradouro',
      id: item.tipoLogradouroId,
      nome: item.tipoLogradouroNome,
      numeroDne: item.tipoLogradouroDne,
    },
    logradouro: item.logradouro,
    cep: item.cep,
  }
}

export const convertToInitialValuesMicroareasFilter = (
  microareasFilter: VisualizacaoMicroareasFilterModel
): VisualizacaoTerritorialMicroareasFilterModel => {
  let initialValues: VisualizacaoTerritorialMicroareasFilterModel = {
    isCadastroAtualizadoMenosDeUmAno: undefined,
    isCadastroCompleto: undefined,
    isImovelVisitadoMenosDeUmMes: undefined,
  }

  if (!isUndefinedOrNull(microareasFilter?.isCadastroAtualizadoMenosDeUmAno)) {
    initialValues = {
      ...initialValues,
      isCadastroAtualizadoMenosDeUmAno: microareasFilter.isCadastroAtualizadoMenosDeUmAno
        ? ATUALIZADO_MENOS_DE_UM_ANO
        : ATUALIZADO_MAIS_DE_UM_ANO,
    }
  }

  if (!isUndefinedOrNull(microareasFilter?.isCadastroCompleto)) {
    initialValues = {
      ...initialValues,
      isCadastroCompleto: microareasFilter.isCadastroCompleto ? COMPLETO : INCOMPLETO,
    }
  }

  if (!isUndefinedOrNull(microareasFilter?.isImovelVisitadoMenosDeUmMes)) {
    initialValues = {
      ...initialValues,
      isImovelVisitadoMenosDeUmMes: microareasFilter.isImovelVisitadoMenosDeUmMes
        ? VISITA_MENOS_DE_UM_MES
        : VISITA_UM_MES_OU_MAIS,
    }
  }

  return initialValues
}
