/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SerializedStyles } from '@emotion/serialize'
import { Text, useTheme } from 'bold-ui'
import { PivotTableContext } from 'components/pivot-table/PivotTable'
import React, { MouseEvent, useContext } from 'react'

import { GridArea } from './GridArea'

export type PivotTableCellProps = {
  gridArea: GridArea
  type: Array<'empty' | 'header' | 'value' | 'total' | 'grandtotal'>
  children?: string | number
  endColumn?: boolean
  endRow?: boolean
  styles?: SerializedStyles
}

export function PivotTableCell(props: PivotTableCellProps) {
  const { endColumn, endRow, styles, gridArea, type } = props

  const context = useContext(PivotTableContext)

  const theme = useTheme()

  let backgroundColor = theme.pallete.surface.main
  let color = theme.pallete.gray.c10
  if (type.length === 1 && type[0] === 'value') {
    const numberValue = Number(props.children)
    if (numberValue) {
      let colorIndex = 100 - Math.ceil((numberValue * 100) / context.total / 10) * 10 + 10
      colorIndex = colorIndex > 100 ? 100 : colorIndex < 10 ? 10 : colorIndex
      backgroundColor = theme.pallete.primary[`c${colorIndex}`]
      if (colorIndex < 70) {
        color = theme.pallete.gray.c100
      }
    }
  }

  const handleMouseEnter = (evt: MouseEvent<HTMLDivElement>) => {
    if (type.includes('value') || type.includes('empty')) {
      document
        .querySelectorAll(
          `div[data-rownumber~="${gridArea.rowStart}"], div[data-columnnumber~="${gridArea.columnStart}"]`
        )
        .forEach((element) => {
          let rgbColor = window.getComputedStyle(element).getPropertyValue('background-color')
          if (hexToRGB(theme.pallete.surface.main) === rgbColor) {
            element.setAttribute('style', `background-color: rgba(240, 240, 245, 0.5)`)
          } else {
            rgbColor = rgbColor.replace('rgb', 'rgba')
            rgbColor = rgbColor.replace(')', ', 0.5)')
            element.setAttribute('style', `background-color: ${rgbColor}`)
          }
        })
    }
  }

  const handleMouseLeave = () => {
    if (type.includes('value') || type.includes('empty')) {
      document
        .querySelectorAll(
          `div[data-rownumber~="${gridArea.rowStart}"], div[data-columnnumber~="${gridArea.columnStart}"]`
        )
        .forEach((element) => element.removeAttribute('style'))
    }
  }
  const rowNumbers: number[] = []
  for (let i = gridArea.rowStart; i < gridArea.rowEnd; i++) {
    rowNumbers.push(i)
  }

  const columnNumbers: number[] = []
  for (let i = gridArea.columnStart; i < gridArea.columnEnd; i++) {
    columnNumbers.push(i)
  }
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-rownumber={rowNumbers.join(' ')}
      data-columnnumber={columnNumbers.join(' ')}
      key={gridArea.toString()}
      css={css([
        `
      grid-area: ${gridArea.toString()};
      border-top: 1px solid ${theme.pallete.divider};
      border-left: 1px solid ${theme.pallete.divider};
      display: flex;
      background-color : ${backgroundColor};
      color: ${color};
      justify-content: ${type.includes('value') || type.includes('empty') ? 'flex-end' : 'flex-start'};
      font-weight: ${type.includes('total') ? 'bold' : 'normal'};
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0.5rem 1rem;
      `,
        endColumn && `border-right: 1px solid ${theme.pallete.divider};`,
        endRow && `border-bottom: 1px solid ${theme.pallete.divider};`,
        styles,
      ])}
    >
      {type.includes('header') ? (
        <Text fontWeight='bold'>{props.children}</Text>
      ) : (
        <React.Fragment>
          {typeof props.children === 'number' ? numberFormatter(props.children) : props.children}
          {type.includes('value') ? context.suffix : null}
        </React.Fragment>
      )}
    </div>
  )
}

const hexToRGB = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgb(${r}, ${g}, ${b})`
}

export const numberFormatter = (num?: number): string => {
  if (!num) {
    return '0'
  }
  if (num % 1 !== 0) {
    return num.toFixed(2).replace('.', ',')
  } else {
    return num.toString()
  }
}
