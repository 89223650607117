/** @jsx jsx */
import { jsx } from '@emotion/core'

export interface QRCodeProps {
  base64PngImage: string
  alt?: string
}

export const QRCode = ({ alt = 'QRCode', base64PngImage }: QRCodeProps) => (
  <img src={`data:image/png;base64, ${base64PngImage}`} alt={alt} />
)
