import React, { useCallback } from 'react'

import { TextField, TextFieldProps } from '../TextField'

export interface DecimalFieldProps extends TextFieldProps {
  precisao?: number
}

const pattern = new RegExp(/[^\d,]+/g)

const parse = (value: string, precisao: number) => {
  if (value.startsWith(',')) value = value.slice(1)

  // remove o que não é numero e virgula
  value = value.replace(pattern, '')

  const primeiraVirgulaPos = value.indexOf(',')

  if (primeiraVirgulaPos >= 0) {
    // remove todas virgulas depois da primeira
    value = value.slice(0, primeiraVirgulaPos + 1) + value.slice(primeiraVirgulaPos, value.length)?.replace(/,/g, '')
  }

  if (value.indexOf(',') >= 0) value = value.substring(0, value.indexOf(',') + precisao + 1)

  return value
}

export function DecimalField(props: DecimalFieldProps) {
  const { precisao, ...rest } = props

  const internalParse = useCallback((value: string) => parse(value, precisao), [precisao])

  return <TextField parse={internalParse} {...rest} />
}

DecimalField.defaultProps = {
  precisao: 2,
}
