import { isEmpty, Text, VFlow } from 'bold-ui'
import { RegistroVacinacao } from 'graphql/types.generated'
import React from 'react'
import { filterByRegistroAnterior } from 'view/atendimentos/detail/historico/utils/filterByRegistroAnterior'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { hasMedicoes, naoRegistradoNesseAtendimento } from '../../../utils/utils-historico'
import HistoricoMedicoesAnterioresTableBox from './HistoricoMedicoesAnterioresTableBox'
import HistoricoVacinacoesAnterioresTableBox from './HistoricoVacinacoesAnterioresTableBox'

export interface HistoricoRegistrosAnterioresPanelProps {
  medicoes: HistoricoMedicaoModel[]
  registrosVacinacao: RegistroVacinacao[]
  dataNascimentoCidadao: LocalDate
  isAtendimentoPuericultura?: boolean
}

export default function HistoricoRegistrosAnterioresPanel(props: HistoricoRegistrosAnterioresPanelProps) {
  const { medicoes, registrosVacinacao, dataNascimentoCidadao, isAtendimentoPuericultura } = props

  const { registroAnterior: medicoesAnteriores } = filterByRegistroAnterior<HistoricoMedicaoModel>(
    medicoes,
    'medicaoAnterior'
  )

  const { registroAnterior: vacinacoesAnteriores } = filterByRegistroAnterior<RegistroVacinacao>(
    registrosVacinacao,
    'isRegistroAnterior'
  )

  const possuiMedicoesAnteriores = hasMedicoes(medicoesAnteriores)

  const possuiVacinacoesAnteriores = !isEmpty(vacinacoesAnteriores)

  const hasRegistrosAnteriores = possuiMedicoesAnteriores || possuiVacinacoesAnteriores

  return hasRegistrosAnteriores ? (
    <>
      {possuiMedicoesAnteriores && (
        <HistoricoMedicoesAnterioresTableBox
          medicoes={medicoesAnteriores}
          dataNascimentoCidadao={dataNascimentoCidadao}
          sectionStyle='RA'
          isAtendimentoPuericultura={isAtendimentoPuericultura}
        />
      )}
      {possuiVacinacoesAnteriores && (
        <VFlow vSpacing={0.5}>
          <HistoricoVacinacoesAnterioresTableBox registrosVacinacao={vacinacoesAnteriores} />
        </VFlow>
      )}
    </>
  ) : (
    <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
  )
}
