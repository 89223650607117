import { colors } from 'bold-ui'
import { isUndefinedOrNull } from 'util/checks'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { ValidationErrorModalConfig } from 'view/atendimentos/components/validation-error-modal/model'

export const soapValidationErrorModalConfig: ValidationErrorModalConfig<SoapState> = {
  subjetivo: {
    titleColor: colors.pink.c40,
  },
  objetivo: {
    titleColor: colors.blue.c50,
    fields: {
      atendimentosEspecificos: {
        hideLabelOnModal: true,
        fields: {
          puericultura: {
            fields: {
              alteracoesFenotipicas: { hideChildrenOnModal: true },
              fatoresRisco: { hideChildrenOnModal: true },
              marcosDesenvolvimento: { hideChildrenOnModal: true },
            },
          },
        },
      },
      marcadoresConsumoAlimentar: {
        hideChildrenOnModal: true,
      },
    },
  },
  avaliacao: {
    titleColor: colors.yellow.c40,
  },
  plano: {
    titleColor: colors.green.c40,
    fields: {
      intervencoesProcedimentos: {
        fields: {
          procedimentos: { hideChildrenOnModal: true },
        },
      },
      odontologia: {
        fields: {
          periodontia: {
            fields: {
              periogramaCompleto: {
                hideLabelOnModal: (error) => isUndefinedOrNull(error.observacao),
                fields: {
                  dados: {
                    hideChildrenOnModal: true,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  preNatal: {
    titleColor: colors.purple.c60,
  },
}
