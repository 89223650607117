import RichTextEditor from 'react-rte'
import { isUndefinedOrNull } from 'util/checks'
import { isObjectDeepNullOrUndefined } from 'util/object'

import { ErrorObject } from './validator'

export const isEmpty = (value: any) =>
  isUndefinedOrNull(value) || value === '' || value.length === 0 || value.size === 0

export const isValid = (errors: ErrorObject<any> | string): boolean => {
  if (typeof errors === 'string') return isUndefinedOrNull(errors)
  else return isObjectDeepNullOrUndefined(errors)
}

export const isRichTextValueEmpty = (value: any) => {
  const richTextValue = RichTextEditor.createValueFromString(value, 'html')
  let texto = ''
  const textBlocks = JSON.parse(richTextValue.toString('raw')).blocks
  textBlocks.forEach((e) => (texto += e.text?.toString()))
  return isEmpty(value) || isEmpty(texto) || !/\S/.test(texto)
}
