/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DataTable, Heading, VFlow } from 'bold-ui'
import { compareInstantDesc } from 'util/date/compareInstant'
import { formatDate } from 'util/date/formatDate'

import { ExameZikaTableModel, ResultadoExamesModel } from './historicoZikaModel'

interface ResultadosDeExamesZikaContentProps {
  detalhamento: ResultadoExamesModel
}

const colunasTabelaResultadoExames = [
  {
    name: 'nome',
    header: 'Nome',
    render: (item) => item.nome,
    style: css`
      width: 30%;
      padding: 0.5rem 1rem;
    `,
  },
  {
    name: 'resultado',
    header: 'Resultado',
    render: (item) => item.resultado,
    style: css`
      width: 40%;
      padding: 0.5rem 1rem;
    `,
  },
  { name: 'data', header: 'Data de realização', render: (item) => formatDate(item.dataRealizacao) },
]

export function ResultadosDeExamesZikaContent(props: ResultadosDeExamesZikaContentProps) {
  const { detalhamento } = props

  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={3}>Resultados de exames</Heading>
        {detalhamento.outrosExames && (
          <DataTable<ExameZikaTableModel>
            rows={detalhamento.outrosExames.sort((a, b) => compareInstantDesc(a.dataRealizacao, b.dataRealizacao))}
            columns={colunasTabelaResultadoExames}
          />
        )}
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={5}>Exames de imagem</Heading>
        {detalhamento.examesDeImagem && (
          <DataTable<ExameZikaTableModel>
            rows={detalhamento.examesDeImagem.sort((a, b) => compareInstantDesc(a.dataRealizacao, b.dataRealizacao))}
            columns={colunasTabelaResultadoExames}
          />
        )}
      </VFlow>
    </VFlow>
  )
}
