import { HFlow, Icon, VFlow } from 'bold-ui'
import { Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import React from 'react'

import { AtencaoDomiciliarFilterPopper, AtencaoDomiciliarFilterPopperModel } from './AtencaoDomiciliarFilterPopper'

export interface AtencaoDomiciliarTableFilterProps {
  equipeId?: ID
  filter: AdFilterModel
  isCoordenadorAd?: boolean
  localidadeId?: ID
  onChangeFilter(values: AdFilterModel): void
}

export type AdFilterModel = ListaAdQueryFilterModel & AtencaoDomiciliarFilterPopperModel

export interface ListaAdQueryFilterModel {
  query?: string
}

export const AtencaoDomiciliarTableFilter = (props: AtencaoDomiciliarTableFilterProps) => {
  const { filter, onChangeFilter, isCoordenadorAd, equipeId, localidadeId } = props
  const { query, ...rest } = filter
  const initialValues = { ...rest }

  const renderPopper = ({ close }: PopperControls) => (
    <AtencaoDomiciliarFilterPopper
      initialValues={initialValues}
      onSubmit={handlePopperFormSubmit}
      onClose={close}
      isCoordenadorAd={isCoordenadorAd}
      equipeId={equipeId}
      localidadeId={localidadeId}
    />
  )

  const handleDebounceChange = (formState: FormState<ListaAdQueryFilterModel>) => {
    onChangeFilter({ ...filter, ...formState.values, query: formState.values.query })
  }
  const handlePopperFormSubmit = (values: AtencaoDomiciliarFilterPopperModel) => {
    onChangeFilter({ ...filter, ...values })
  }

  const renderForm = () => {
    return (
      <>
        <FormDebouncedValueSpy onChange={handleDebounceChange} />
        <VFlow vSpacing={0.5}>
          <HFlow justifyContent='space-between'>
            <TextField
              name='query'
              placeholder='Pesquise um cidadão por nome, CPF ou CNS'
              icon='zoomOutline'
              style={{ width: '24rem' }}
            />
            <PopperButton kind='primary' size='small' placement='bottom-end' renderPopper={renderPopper}>
              <Icon icon='filterOutline' />
              Filtros
            </PopperButton>
          </HFlow>
        </VFlow>
      </>
    )
  }

  return <Form<AdFilterModel> render={renderForm} />
}
