import { PecSwitch, PrivateRoute } from 'components/route'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import Permissions from 'types/Permissions'
import { EquipeDetailRootView } from 'view/equipe/detail/EquipeDetailRootView'

import { UnidadeSaudeDetailView } from './UnidadeSaudeDetailView'

export interface UrlParams {
  unidadeSaudeId: string
}

export function UnidadeSaudeDetailRootView() {
  const { match } = useRouter<UrlParams>()

  const renderUnidadeSaude = () => <UnidadeSaudeDetailView unidadeSaudeId={match.params.unidadeSaudeId} />
  const renderEquipeDetail = () => <EquipeDetailRootView unidadeSaudeId={match.params.unidadeSaudeId} />

  return (
    <PecSwitch>
      <PrivateRoute
        path={`${match.url}/equipes/:equipeId(\\d+)`}
        render={renderEquipeDetail}
        permission={Permissions.visualizarUnidadesDeSaude}
      />
      <PrivateRoute
        path={`${match.url}`}
        render={renderUnidadeSaude}
        permission={Permissions.visualizarUnidadesDeSaude}
      />
    </PecSwitch>
  )
}
