/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, focusBoxShadow, Theme } from 'bold-ui'
import theme from 'config/theme'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import { OdontogramaSetActiveRef } from '../../odontograma-field/model'
import { SextanteModel } from '../types/Sextante'
import { CounterTooltip } from './CounterTooltip'

interface OdontogramaButtonWrapperProps {
  children: JSX.Element
  active?: boolean
  disabled?: boolean
  value?: SextanteModel
  onClick?(ref?: HTMLButtonElement): void
  setActiveRef?: OdontogramaSetActiveRef
}

export default function OdontogramaButtonWrapper(props: OdontogramaButtonWrapperProps) {
  const { children, active, disabled, value, onClick, setActiveRef } = props
  const [ref, setRef] = useState<HTMLButtonElement>()
  const classes = useMemo(() => createStyles(theme), [])

  const handleClick = () => onClick && onClick(ref)

  useEffect(() => {
    if (active && !isUndefinedOrNull(setActiveRef)) {
      setActiveRef({ ref })
    }
  }, [active, ref, setActiveRef])

  return (
    <Button
      kind='normal'
      skin='outline'
      style={[classes.button, !disabled && active && classes.buttonActive, disabled && classes.buttonDisabled]}
      innerRef={setRef}
      onClick={handleClick}
      disabled={disabled}
    >
      <Fragment>
        {!disabled && (
          <CounterTooltip
            value={value?.tooltipNumber}
            text={value?.tooltipText}
            position={{ top: '0.625rem', right: '0.625rem' }}
          />
        )}

        {children}
      </Fragment>
    </Button>
  )
}

const createStyles = (theme: Theme) => ({
  button: css`
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border-radius: ${theme.radius.button}px;
    position: relative;
    border-color: ${theme.pallete.gray.c50};
    background-color: ${theme.pallete.primary.c100};
    box-shadow: ${theme.shadows.outer[10]};
    &:hover {
      background-color: ${theme.pallete.primary.c100};
      box-shadow: ${theme.shadows.outer[40]};
    }
    &:not(:disabled) {
      &:hover {
        background-color: ${theme.pallete.primary.c100};
        box-shadow: ${theme.shadows.outer[40]};
      }
      &:focus-within {
        outline: none;
        box-shadow: ${focusBoxShadow(theme, 'primary')};
        transition-property: box-shadow;
        transition-duration: 0.2s;
      }
    }
  `,
  buttonActive: css`
    border-color: ${theme.pallete.primary.c40};
    background-color: ${theme.pallete.primary.c90};
    box-shadow: ${theme.shadows.outer[40]};
    &:hover {
      background-color: ${theme.pallete.primary.c90};
      box-shadow: ${theme.shadows.outer[40]};
    }
    &:not(:disabled) {
      &:hover {
        background-color: ${theme.pallete.primary.c90};
        box-shadow: ${theme.shadows.outer[40]};
      }
    }
  `,
  buttonDisabled: css`
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  `,
})
