/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, Text, VFlow } from 'bold-ui'
import { AccordionInformation } from 'components/accordion/AccordionInformation'
import { MetaPath } from 'util/metaPath'

import { HistoricoPeriogramaSimplificadoTable } from './historico-periograma-simplificado/HistoricoPeriogramaSimplificadoTable'
import { PeriogramaSimplificadoField, PeriogramaSimplificadoModel } from './PeriogramaSimplificadoField'

interface PeriogramaSimplificadoViewProps {
  name: MetaPath<PeriogramaSimplificadoModel>
  prontuarioId: ID
}

export default function PeriogramaSimplificadoView(props: PeriogramaSimplificadoViewProps) {
  const { name, prontuarioId } = props

  return (
    <Grid>
      <Cell size={8}>
        <AccordionInformation
          type='normal'
          uuid='periogramaSimplificado-info'
          header='Como preencher o Registro Periodontal Simplificado'
        >
          <VFlow vSpacing={0}>
            <Text>
              <strong>0</strong> - Faixa colorida da sonda visível, ausência de sangramento após sondagem, ausência de
              cálculo dentário e/ou margens de restaurações mal adaptadas.
            </Text>
            <Text>
              <strong>1</strong> - Sangramento após a sondagem. Faixa colorida da sonda visível, ausência de cálculo
              dentário e/ou margens de restaurações mal adaptadas.
            </Text>
            <Text>
              <strong>2</strong> - Presença de cálculo dentário e/ou margens de restaurações mal adaptadas. Pode sangrar
              à sondagem ou não. Faixa colorida da sonda visível.
            </Text>
            <Text>
              <strong>3</strong> - Faixa colorida da sonda parcialmente visível (bolsa periodontal entre 4 e 5mm).
            </Text>
            <Text>
              <strong>4</strong> - Faixa colorida da sonda completamente encoberta (bolsa periodontal {'>'}5mm).
            </Text>
            <Text>
              <strong>*</strong> - Anormalidade clínica (lesão de furca, recessão gengival {'>'}3mm, lesão mucogengival
              ou mobilidade). É obrigatório estar em conjunto com algum dos valores anteriores.
            </Text>
            <Text>
              <strong>x</strong> - Sextantes sem dentes (excluídos)
            </Text>
          </VFlow>
        </AccordionInformation>
      </Cell>
      <Cell size={12}>
        <PeriogramaSimplificadoField name={name} />
      </Cell>
      <Cell size={12}>
        <HistoricoPeriogramaSimplificadoTable prontuarioId={prontuarioId} />
      </Cell>
    </Grid>
  )
}
