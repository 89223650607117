import { Button, Checkbox, HFlow, Icon, Tag, Text, Theme, Tooltip, useStyles, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { useBuscaDetailCidadaoLazyQuery } from 'graphql/hooks.generated'
import React, { CSSProperties, useState } from 'react'
import { titleCase } from 'util/strConversor'
import { CidadaoListingFormModel } from 'view/cidadao/types/CidadaoPesquisarFormModel'

import { CidadaoDadosModal } from './modals/CidadaoDadosModal'
import UnificacaoCidadaoCardInfo from './UnificacaoCidadaoCardInfo'

export interface UnificacaoCadastroSelectedListItem {
  definirButtonTitle?: string
  cidadao: CidadaoListingFormModel
  isPrincipal: boolean
  disableDefinirComoPrincipal: boolean
  onClickPrincipal(cidadao: CidadaoListingFormModel): void
  selectCidadao(cidadao: CidadaoListingFormModel, checked: boolean): void
}

export default function UnificacaoCadastroSelectedListItem(props: UnificacaoCadastroSelectedListItem) {
  const {
    definirButtonTitle,
    cidadao,
    disableDefinirComoPrincipal,
    isPrincipal,
    onClickPrincipal,
    selectCidadao,
  } = props

  const [selecionado, setSelecionado] = useState(true)
  const [openModalCidadaoVisualizeCidadao, setOpenModalCidadaoVisualizeCidadao] = useState<boolean>(false)
  const [executeQuery, { data }] = useBuscaDetailCidadaoLazyQuery()

  const { classes } = useStyles(createStyles)

  const handleOnClickDefinirPrincipal = () => {
    onClickPrincipal(cidadao)
  }

  const handleChangeCidadao = () => {
    setSelecionado(!selecionado)
    selectCidadao(cidadao, !selecionado)
  }

  const handleClickVisualizeCidadao = (cidadaoId) => {
    executeQuery({ variables: { id: cidadaoId } })
    setOpenModalCidadaoVisualizeCidadao(true)
  }

  return (
    <>
      {openModalCidadaoVisualizeCidadao && (
        <CidadaoDadosModal
          openModal={openModalCidadaoVisualizeCidadao}
          cidadao={data?.cidadao}
          closeModal={() => setOpenModalCidadaoVisualizeCidadao(false)}
        />
      )}
      <Box style={classes.box}>
        <HFlow style={{ justifyContent: 'space-between' }}>
          <HFlow>
            <Checkbox
              name='checkboxSelecionado'
              checked={selecionado}
              onChange={handleChangeCidadao}
              data-cy={`CardSelecionado${cidadao.id}.Checkbox`}
            />
            <VFlow vSpacing={0.25}>
              <HFlow hSpacing={0.5} alignItems='baseline' data-cy={`CardSelecionado${cidadao.id}.Nome`}>
                {cidadao.nomeSocial ? (
                  <span>
                    <Text fontSize={1} fontWeight='bold'>
                      {titleCase(cidadao.nomeSocial)}
                    </Text>
                    {cidadao.nomeSocial && <Text fontWeight='bold'> (Nome social)</Text>}
                    {cidadao.nomeSocial && <Text>{' | ' + titleCase(cidadao.nome)}</Text>}
                  </span>
                ) : (
                  <Text fontSize={1} fontWeight='bold'>
                    {titleCase(cidadao.nome)}
                  </Text>
                )}
                {!cidadao.ativo && <Tag>Inativo</Tag>}
                {cidadao.faleceu && <Tag>Óbito</Tag>}
                {cidadao.unificado && <Tag>Cadastro unificado</Tag>}
                {cidadao.unificacaoBase && (
                  <Tooltip text={`Cadastro originado após unificação da Base ${cidadao.unificacaoBase}`}>
                    <Tag>Base {cidadao.unificacaoBase.titleCase()}</Tag>
                  </Tooltip>
                )}
                {isPrincipal && (
                  <Tag type='info' data-cy={`CardSelecionado${cidadao.id}.LabelCidadaoPrincipal`}>
                    Cadastro principal
                  </Tag>
                )}
              </HFlow>
              <HFlow>
                <UnificacaoCidadaoCardInfo cidadao={cidadao} testid='CardSelecionado' />
              </HFlow>
            </VFlow>
          </HFlow>
          <HFlow alignItems='center' hSpacing={0.5}>
            <Button
              kind='primary'
              size='small'
              style={{ height: '2rem', width: '11rem' }}
              onClick={handleOnClickDefinirPrincipal}
              disabled={disableDefinirComoPrincipal || isPrincipal}
              title={definirButtonTitle}
              data-cy={`CardSelecionado${cidadao.id}.DefinirPrincipalButton`}
            >
              Definir como principal
            </Button>
            <Button
              kind='normal'
              skin='ghost'
              size='small'
              onClick={() => handleClickVisualizeCidadao(cidadao.id)}
              data-cy={'CardSelecionado' + cidadao.id + '.VisualizarButton'}
            >
              <Icon icon='zoomOutline' />
            </Button>
          </HFlow>
        </HFlow>
      </Box>
    </>
  )
}

const createStyles = (theme: Theme) => ({
  box: {
    paddingLeft: '1rem',
    marginBottom: '1rem',
    border: `1px solid ${theme.pallete.gray.c70}`,
  } as CSSProperties,
})
