import { Cell, ExternalStyles, Text, useStyles } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import React, { CSSProperties } from 'react'

export interface FilterItemProps {
  label?: string
  value: string
  style?: ExternalStyles
  textStyle?: ExternalStyles
}

export function FilterItem(props: FilterItemProps) {
  const { label, value, style, textStyle } = props
  const { classes, css } = useStyles(createStyles)

  return (
    <Cell style={[classes.separator, style]}>
      {label && <Text style={classes.label}>{label}</Text>}

      <Ellipsis className={css([textStyle])}>{value}</Ellipsis>
    </Cell>
  )
}

const createStyles = () => ({
  separator: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 0,
    overflow: 'hidden',

    '&:after': {
      display: 'block',
      content: '" | "',
      marginLeft: '0.25rem',
    },

    '&:last-child:after': {
      content: 'none',
    },
  } as CSSProperties,

  label: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    marginRight: '0.25rem',
  } as CSSProperties,
})
