import { Alert, Cell, Grid, HFlow, Modal, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { FooterButton } from 'components/footer-button'
import { DateField, Form, FormRenderProps } from 'components/form'
import { DoseImunobiologicoVacinacaoSelectField } from 'components/form/field/select/DoseImunobiologicoVacinacaoSelectField/DoseImunobiologicoVacinacaoSelectField'
import { EstrategiaVacinacaoSelectField } from 'components/form/field/select/EstrategiaVacinacaoSelectField'
import { addDays } from 'date-fns'
import { FormApi } from 'final-form'
import { OrigemAtendimento, SexoEnum, TipoRegistroVacinacaoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { Fragment, useMemo } from 'react'
import { FormSpy } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, required } from 'util/validation'
import { ImunobiologicoSelectField } from 'view/lote-imunobiologico/components/ImunobiologicoSelectField'

import { RegistroVacinacaoFormInput } from '../detail/vacinacao/VacinacaoCalendarioView'
import { createOutrosImunobiologicosCalculator } from './calculator'
import { ImunobiologicoModel, isDtpaAdulto, RegistroVacinacaoFormModel } from './model'
import { sairSemSalvarConfirm } from './VacinacaoModal'
import { VacinacaoModalHeader } from './VacinacaoModalHeader'
import { isBeforeDataAprazamento } from './vacinacaoUtils'

interface AprazamentoVacinaModalProps {
  initialValues?: RegistroVacinacaoFormModel
  imunobiologico?: ImunobiologicoModel
  doseId?: ID
  doseNome?: string
  idadeRecomendada?: string
  modalOpen: boolean
  handleModalClose(): void
  handleOnSubmit(input: RegistroVacinacaoFormInput): Promise<void>
  outrosImunobiologicos?: boolean
  sexo?: SexoEnum
  dataAtendimento: Instant
}

const meta = metaPath<RegistroVacinacaoFormModel>()

const validator = (outrosImunobiologicos: boolean, dataMinimaAprazamento: Date) =>
  createValidator<RegistroVacinacaoFormModel>(
    {
      estrategiaVacinacao: !outrosImunobiologicos && [required],
      dataAprazamento: [required],
      outrosImunosImunobiologico: outrosImunobiologicos && [required],
    },
    (values: RegistroVacinacaoFormModel, errors: ErrorObject<RegistroVacinacaoFormModel>) => {
      if (outrosImunobiologicos) {
        if (values.outrosImunosImunobiologico) errors.estrategiaVacinacao = required(values.estrategiaVacinacao)
        if (values.estrategiaVacinacao) errors.outrosImunosDose = required(values.outrosImunosDose)
      }

      if (isBeforeDataAprazamento(values.dataAprazamento, dataMinimaAprazamento))
        errors.dataAprazamento = 'Deve ser posterior à data atual.'

      return errors
    }
  )
const dataMinimaAprazamento = (dataServidor: Date) => addDays(dataServidor, 1)

export const AprazamentoVacinaModal = (props: AprazamentoVacinaModalProps) => {
  const {
    imunobiologico,
    doseId,
    modalOpen,
    idadeRecomendada,
    doseNome,
    outrosImunobiologicos,
    handleModalClose,
    handleOnSubmit,
    initialValues,
    sexo,
    dataAtendimento,
  } = props

  const { getServerTimeNow } = useServerTime()
  const serverTimeNow = getServerTimeNow()
  const alert = useAlert()

  const handleSubmit = async (formValues: RegistroVacinacaoFormModel, formApi: FormApi) => {
    return handleOnSubmit({
      formValues: {
        ...formValues,
        tipoRegistroVacinacao: TipoRegistroVacinacaoEnum.APRAZAMENTO,
        imunobiologicoId: imunobiologico?.id,
        imunobiologicoNome: imunobiologico?.nome,
        imunobiologicoSigla: imunobiologico?.sigla,
        doseId: doseId,
        dataRegistro: new Date(dataAtendimento).toISOString(),
        isRegistroAnterior: undefined,
        origemDados: OrigemAtendimento.PEC,
        doseNome,
      },
      onSuccess: () => {
        setTimeout(formApi.reset)
        handleModalClose()
        alert('success', 'Registro salvo com sucesso.')
      },
    })
  }

  const onModalClose = (formRenderProps: FormRenderProps<RegistroVacinacaoFormModel>) => {
    if (formRenderProps.dirty) {
      sairSemSalvarConfirm(() => {
        setTimeout(formRenderProps.form.reset)
        handleModalClose()
      })
    } else {
      handleModalClose()
    }
  }

  const renderForm = (formRenderProps: FormRenderProps<RegistroVacinacaoFormModel>) => {
    return (
      <Modal open={modalOpen} onClose={() => onModalClose(formRenderProps)} size='small' closeOnBackdropClick={false}>
        <ModalBody>
          <VFlow>
            <VacinacaoModalHeader
              idadeRecomendada={idadeRecomendada}
              imunobiologicoNome={imunobiologico?.nome}
              imunobiologicoSigla={imunobiologico?.sigla}
              nomeDose={doseNome}
              outrosImunobiologicos={outrosImunobiologicos}
              labelTipoRegistro='Aprazamento'
              isSubModalHeader
            />

            <Grid>
              {isDtpaAdulto(imunobiologico?.id) && (
                <Cell size={12}>
                  <Alert type='info' inline>
                    É recomendado aplicar a {doseNome} do imunobiológico {imunobiologico?.nome} somente a partir da 20ª
                    semana de gestação.
                  </Alert>
                </Cell>
              )}

              {outrosImunobiologicos && (
                <Cell size={12}>
                  <ImunobiologicoSelectField
                    name={meta.outrosImunosImunobiologico}
                    label='Imunobiológico'
                    required
                    outrosImunobiologicos
                    sexo={sexo}
                  />
                </Cell>
              )}
              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <Fragment>
                    <Cell size={outrosImunobiologicos ? 12 : 7}>
                      <EstrategiaVacinacaoSelectField
                        name={meta.estrategiaVacinacao}
                        label='Estratégia'
                        imunobiologicoId={imunobiologico?.id || values.outrosImunosImunobiologico?.id}
                        doseImunobiologicoId={doseId}
                        disabled={outrosImunobiologicos && !values.outrosImunosImunobiologico}
                        required={outrosImunobiologicos ? values.outrosImunosImunobiologico : true}
                        outrosImunobiologicos={outrosImunobiologicos}
                        sexo={sexo}
                      />
                    </Cell>
                    {outrosImunobiologicos && (
                      <Cell size={6}>
                        <DoseImunobiologicoVacinacaoSelectField
                          name={meta.outrosImunosDose}
                          label='Dose'
                          imunobiologicoIds={[values.outrosImunosImunobiologico?.id]}
                          estrategiaId={values.estrategiaVacinacao?.id}
                          sexo={sexo}
                          required={values.estrategiaVacinacao}
                          disabled={!values.estrategiaVacinacao}
                        />
                      </Cell>
                    )}
                    <Cell size={outrosImunobiologicos ? 6 : 5}>
                      <DateField
                        label='Aprazar para'
                        name={meta.dataAprazamento}
                        icon='calendarOutline'
                        minDate={dataMinimaAprazamento(serverTimeNow)}
                        required
                      />
                    </Cell>
                  </Fragment>
                )}
              </FormSpy>
            </Grid>
          </VFlow>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <FooterButton kind='normal' onClick={() => onModalClose(formRenderProps)}>
              Cancelar
            </FooterButton>
            <FooterButton kind='primary' onClick={formRenderProps.handleSubmit}>
              Salvar
            </FooterButton>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  const validatorAprazamento = useMemo(() => validator(outrosImunobiologicos, dataMinimaAprazamento(serverTimeNow)), [
    serverTimeNow,
    outrosImunobiologicos,
  ])
  const decorator = useMemo(() => createOutrosImunobiologicosCalculator(meta), [])

  return (
    <Form<RegistroVacinacaoFormModel>
      render={renderForm}
      subscription={{ submitting: true, dirty: true }}
      validate={validatorAprazamento}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      decorators={outrosImunobiologicos && [decorator]}
      suppressNotificationError
    />
  )
}
