import api from 'api'
import { defaultHandleError } from 'util/downloadFile'
import { generatePdf } from 'util/generatePdf'

import { ImpressaoAcompanhamentoPreNatalInput } from './ImpressaoAcompanhamentoPreNatalInput'

export const downloadAcompanhamentoPreNatal = async (value: ImpressaoAcompanhamentoPreNatalInput) => {
  return await api.acompanhamentoPreNatal
    .imprimirAcompanhamentoPreNatal(value)
    .then((response) => {
      generatePdf(new Blob([response.data], { type: 'application/pdf' }), 'Imprimir acompanhamento de pré-natal')
      return response
    })
    .catch(defaultHandleError)
}
