/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, VFlow } from 'bold-ui'

import { HistoricoSectionStyles } from '../model/model-historico'

export type HistoricoSectionType = 'S' | 'O' | 'A' | 'P' | 'D' | 'AN' | 'I' | 'RA' | 'AD'

export interface HistoricoSectionProps {
  section: HistoricoSectionType
  children: React.ReactNode
}

export default function HistoricoSection(props: HistoricoSectionProps) {
  const { section, children } = props
  const { title, color } = HistoricoSectionStyles[section]

  return (
    <VFlow vSpacing={0.5}>
      <Heading
        level={3}
        style={css`
          color: ${color};
        `}
      >
        {title}
      </Heading>
      {children}
    </VFlow>
  )
}
