import { Theme, Tooltip, useStyles } from 'bold-ui'
import moment from 'moment'
import React from 'react'
import { LabelFormatter } from 'recharts'

import { AxisDomain, isValueRange, TooltipRenderer } from './model'

export interface ChartTooltipProps<XDomain> {
  children: React.ReactElement
  showTooltip: boolean
  seriesName: string
  label: XDomain
  value: number
  labelDomain?: AxisDomain
  renderer?: TooltipRenderer<XDomain>
}

export function ChartTooltip<XDomain>(props: ChartTooltipProps<XDomain>) {
  const { children, label, value, labelDomain, showTooltip, seriesName, renderer } = props

  const { classes } = useStyles(createStyles)
  let tooltip: string
  if (!renderer) {
    const xValue = labelDomain ? getTooltipLabelFormatter(labelDomain)(label as any).toString() : label
    tooltip = `${value} - ${xValue}`
  } else {
    tooltip = renderer([{ seriesName: seriesName, x: label, y: value }]) as string
  }
  return (
    <Tooltip text={showTooltip && tooltip} style={classes.tooltip}>
      {children}
    </Tooltip>
  )
}

ChartTooltip.defaultProps = {
  showTooltip: true,
} as ChartTooltipProps<any>

function getTooltipLabelFormatter(domain: AxisDomain): LabelFormatter {
  if (Array.isArray(domain)) return (x) => x
  if (isValueRange(domain)) return (x) => x
  else
    return (x: number) =>
      typeof domain.format === 'function' ? domain.format(moment(x)) : moment(x).format(domain.format ?? 'MM/YY')
}

const createStyles = (theme: Theme) => ({
  tooltip: {
    fontFamily: theme.typography.fontFamily,
  },
})
