import { Alert, Text, VFlow } from 'bold-ui'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import theme from 'config/theme'
import clearTypename from 'graphql/clearTypename'
import {
  useNomesInstalacoesUnificacaoBaseQuery,
  useUnificacaoBaseCadastrosDuplicadosQuery,
} from 'graphql/hooks.generated'
import { CidadaoUnificacaoBaseInput, NomesInstalacoesUnificacaoDto, PageParams } from 'graphql/types.generated'
import React, { useCallback, useState } from 'react'

import { UnificacaoBaseCadastrosDuplicadosCidadaoFooter } from './components/UnificacaoBaseCadastrosDuplicadosCidadaoFooter'
import { UnificacaoBaseCadastrosDuplicadosCidadaoListItem } from './components/UnificacaoBaseCadastrosDuplicadosCidadaoListItem'
import { CidadaoUnificacao } from './UnificacaoBaseCadastrosDuplicadosModel'

export function UnificacaoBaseCadastrosDuplicadosView() {
  const [cidadaosUnificaveis, setCidadaosUnificaveis] = useState<[CidadaoUnificacao][0][]>()
  const [inputState, setInputState] = useState<CidadaoUnificacaoBaseInput>({ pageParams: { size: 10 } })
  const [nomesInstalacoes, setNomesInstalacao] = useState<NomesInstalacoesUnificacaoDto>()

  const {
    data: { cadastrosDuplicados },
  } = useUnificacaoBaseCadastrosDuplicadosQuery({
    variables: {
      input: inputState,
    },
    onCompleted: () => {
      setCidadaosUnificaveis(clearTypename(cadastrosDuplicados?.content) as [CidadaoUnificacao][0][])
    },
  })

  useNomesInstalacoesUnificacaoBaseQuery({
    onCompleted: (data) => {
      setNomesInstalacao(data.nomesInstalacoes)
    },
  })

  const onChangeFooter = useCallback(
    (pageParams: PageParams) => {
      setInputState((prevState) => ({ ...prevState, pageParams }))
    },
    [setInputState]
  )

  return (
    <>
      <PageHeaderSection title='Unificação de bases - Cadastros duplicados' />
      <VFlow style={{ borderBottom: `1px solid ${theme.pallete.divider}` }}>
        <PageContainer>
          <Alert type='info' inline style={{ marginBottom: '1.5rem', padding: '0.5rem' }}>
            Os cadastros listados abaixo são oriundos do processo de Unificação de Bases. São cidadãos cadastrados na
            instalação que realizou o envio e que já estavam na instalação central do município. Por se tratarem de
            cidadãos duplicados, é recomendável a análise e unificação dos registros. <br />
            <b>Instalação central do município: </b> {nomesInstalacoes?.nomeInstalacaoCentral}
            <br />
            {nomesInstalacoes?.nomesInstalacoesUnificadas.isNotEmpty() ? (
              <>
                <b>
                  {nomesInstalacoes.nomesInstalacoesUnificadas.length > 1
                    ? 'Instalações que realizaram os envios: '
                    : 'Instalação que realizou o envio: '}
                </b>
                {nomesInstalacoes.nomesInstalacoesUnificadas.join(', ')}.
              </>
            ) : (
              'Nenhum envio realizado'
            )}
          </Alert>
        </PageContainer>
      </VFlow>
      <PageContent type='transparent' style={{ textAlign: 'center' }}>
        {cidadaosUnificaveis ? (
          cidadaosUnificaveis.map((cidadaoUnificavel) => {
            return (
              <UnificacaoBaseCadastrosDuplicadosCidadaoListItem
                key={cidadaoUnificavel.id}
                cidadaoUnificacao={cidadaoUnificavel}
              />
            )
          })
        ) : (
          <Text fontStyle='italic'>Não há cadastros duplicados a serem exibidos.</Text>
        )}
        <UnificacaoBaseCadastrosDuplicadosCidadaoFooter
          pageInfo={cadastrosDuplicados?.pageInfo}
          onChange={onChangeFooter}
        />
      </PageContent>
    </>
  )
}
