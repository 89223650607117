import { AlertType } from 'bold-ui'
import { ArquivoStatusEnum } from 'graphql/types.generated'

export const IMPORTAR_CIDADAO_PATH = 'importar-cidadao'

export type ImportarCidadaoMessageModel = { mensagem: string; status: ArquivoStatusEnum }

export const statusArquivoToAlertLevel: Record<ArquivoStatusEnum, AlertType> = {
  [ArquivoStatusEnum.EM_PROCESSAMENTO]: 'info',
  [ArquivoStatusEnum.PRONTO]: 'success',
  [ArquivoStatusEnum.ERRO]: 'danger',
}
