import useSession from 'components/auth/useSession'
import { parseMessage } from 'components/esus/EsusViewMountTarget'
import { requestListener } from 'graphql/client'
import { useApolloClient } from 'graphql/hooks'
import { SessionEventType } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { throttle } from 'lodash'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { MultiTabEvent, notify, subscribe } from 'util/multitab'

export default function SessionKeeper() {
  const client = useApolloClient()
  const { data, afterLogout } = useSession({ fetchPolicy: 'cache-first' })
  const history = useHistory()
  const timeoutRef = useRef<number>()

  useAtmosphere<SessionEventType>({
    topic: `sessao/${data.id}`,
    onMessage: (responseBody) => {
      if (responseBody === SessionEventType.LOGGED_IN_ANOTHER_SESSION) {
        afterLogout()
      }
    },
  })

  useEffect(() => {
    const autologout = async () => {
      await afterLogout()
      history.push({
        pathname: '/',
        search: 'sessionExpired',
      })
    }

    const resetSessionTimer = () => {
      if (data && data.timeout) {
        if (timeoutRef.current) {
          window.clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = window.setTimeout(autologout, data.timeout * 1000)
      }
    }

    const onGraphQLRequest = throttle(() => {
      if (data && data.timeout) {
        notify('RESET_SESSION')
        resetSessionTimer()
      }
    }, 1000)

    const onMultitabEvent = (event: MultiTabEvent) => {
      if (event === 'RESET_SESSION') {
        resetSessionTimer()
      }
    }

    const unsubscribeMultitab = subscribe(onMultitabEvent)
    const unsubscribeGraphQLRequest = requestListener.addListener(onGraphQLRequest)

    // utilizado pelo iframe do esus pra reiniciar o timer
    const messageListener = (e: MessageEvent) => {
      setTimeout(() => {
        const messageData = parseMessage(e)
        if (messageData.type === 'resetTimer') {
          onGraphQLRequest()
        }
      })
    }

    window.addEventListener('message', messageListener)

    return () => {
      unsubscribeMultitab()
      unsubscribeGraphQLRequest()
      window.removeEventListener('message', messageListener)
    }
  }, [client, data, afterLogout, history])

  return null
}
