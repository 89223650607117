import { CidadaoAtendimentoSelectFieldModel, LocalAtendimentoSelectFieldModel } from 'components/form'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { RegistroTardioJustificativaEnum, StatusAtendimento } from 'graphql/types.generated'

export interface RegistroTardioFormModel {
  atendimentoId?: string
  cidadao?: CidadaoAtendimentoSelectFieldModel
  lotacao?: LotacaoAndEstagioSelectFieldModel
  dataAtendimento?: LocalDate
  horaAtendimento?: string
  localAtendimento?: LocalAtendimentoSelectFieldModel
  justificativa?: RegistroTardioJustificativaEnum
}

export interface UltimoAtendimentoProfissionalCidadaoModel {
  finalizadoEm?: Instant
  lotacao: {
    cbo: {
      nome: string
    }
    profissional: {
      nome: string
    }
  }
}

export const STATUS_REGISTRO_FINALIZADO = [
  StatusAtendimento.AGUARDANDO_ATENDIMENTO,
  StatusAtendimento.ATENDIMENTO_REALIZADO,
]
