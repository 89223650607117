import { BoxGroup } from 'components/BoxGroup'
import React from 'react'

import { ConfiguracoesSegurancaBox } from './ConfiguracoesSegurancaBox'

interface SegurancaBoxProps {
  periodoEmMesesParaRedefinir: { redefinicaoSenha?: number }
  periodoEmMinutosInatividade: { periodoInatividade?: number }
  loginNumeroTentativas: { tentativasLogin?: number }
  load(): void
}

export function SegurancaBox(props: SegurancaBoxProps) {
  return (
    <BoxGroup>
      <ConfiguracoesSegurancaBox
        loginNumeroTentativas={props.loginNumeroTentativas.tentativasLogin}
        periodoEmMesesParaRedefinir={props.periodoEmMesesParaRedefinir.redefinicaoSenha}
        periodoEmMinutosInatividade={props.periodoEmMinutosInatividade.periodoInatividade}
        refetch={props.load}
      />
    </BoxGroup>
  )
}
