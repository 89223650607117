/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, TableCell, TableRow, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Procedimento, TipoParteBucalEnum } from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'

import { EvolucaoOdontoTableItemModel } from '../evolucoes-odonto-table/model'
import { getParteBucal, showDescricaoCodigoProcedimento } from '../util-EvolucoesOdontologicasPlano'
import { HistoricoEvolucoesOdontoTableRowModel } from './HistoricoEvolucoesOdontoTable'

interface HistoricoEvolucoesOdontoTableRowProps {
  evolucaoOdonto: HistoricoEvolucoesOdontoTableRowModel
}

export function HistoricoEvolucoesOdontoTableRow(props: HistoricoEvolucoesOdontoTableRowProps) {
  const { evolucaoOdonto } = props

  const classes = useMemo(() => createStyles(!!evolucaoOdonto.observacao), [evolucaoOdonto.observacao])

  return (
    <Fragment>
      <TableRow>
        <TableCell style={classes.column}>
          <DateTime
            value={evolucaoOdonto.atendimentoProfissionalOdonto.atendimentoProfissional.iniciadoEm}
            format='DD/MM/YYYY'
          />
        </TableCell>
        <TableCell style={classes.localEvolucao}>
          {renderLocalEvolucao({
            partesBucais: evolucaoOdonto.partesBucais,
            descricaoParteBucal: evolucaoOdonto.descricaoParteBucal,
          })}
        </TableCell>
        <TableCell style={classes.column}>{renderProcedimentoEvolucao(evolucaoOdonto.procedimentos)}</TableCell>
        <TableCell style={classes.column}>
          <VFlow vSpacing={0}>
            {evolucaoOdonto.atendimentoProfissionalOdonto.atendimentoProfissional.lotacao.profissional.nome.titleCase()}
            {evolucaoOdonto.atendimentoProfissionalOdonto.atendimentoProfissional.lotacao.cbo.nome}
          </VFlow>
        </TableCell>
      </TableRow>
      {evolucaoOdonto.observacao && (
        <TableRow>
          <TableCell
            colSpan={4}
            style={css`
              height: auto;
              border-top: 0;
              padding-bottom: 0.375rem;
            `}
          >
            <HFlow hSpacing={0.2}>
              <Text fontWeight='bold'>Observações: </Text>
              <Text
                fontStyle='italic'
                style={css`
                  word-break: break-all;
                `}
              >
                <div dangerouslySetInnerHTML={{ __html: evolucaoOdonto.observacao }} />
              </Text>
            </HFlow>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}

const createStyles = (removeBorderBottom?: boolean) => ({
  localEvolucao: css`
    padding: 0.375rem 1rem;
    max-width: 33rem;
    ${removeBorderBottom && `border-bottom: 0;`}
  `,
  column: css`
    padding: 0.375rem 1rem;
    ${removeBorderBottom && `border-bottom: 0;`}
  `,
})

export const renderLocalEvolucao = (item: {
  partesBucais: EvolucaoOdontoTableItemModel['partesBucais']
  descricaoParteBucal: string
}) =>
  item.partesBucais?.length > 0 ? (
    <VFlow vSpacing={0}>
      {item.partesBucais.map((parte, key) => (
        <Text
          key={key}
          style={css`
            word-break: break-word;
          `}
        >
          {parte?.tipoParteBucal === TipoParteBucalEnum.SUPRANUMERARIO ||
          parte?.tipoParteBucal === TipoParteBucalEnum.OUTRO
            ? item.descricaoParteBucal
            : getParteBucal(parte.parteBucalEnum).title}
        </Text>
      ))}
    </VFlow>
  ) : (
    <Text
      style={css`
        word-break: break-word;
      `}
    >
      {item.descricaoParteBucal}
    </Text>
  )

export const renderProcedimentoEvolucao = (procedimentos: Procedimento[]) =>
  procedimentos?.length > 0 && (
    <VFlow vSpacing={0}>
      {procedimentos.map((proced) => (
        <Text key={proced.id}>{showDescricaoCodigoProcedimento(proced)}</Text>
      ))}
    </VFlow>
  )
