import { Cell, Grid, Heading, Tag, Text, useStyles, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeader } from 'components/PageHeader'
import { useSobreQuery } from 'graphql/hooks.generated'
import React from 'react'
import { SobreFooterView } from 'view/sobre/SobreFooterView'

export function SobreView() {
  const { classes } = useStyles(() => ({
    info: {
      p: {
        lineHeight: 2,
      },
      lineHeight: 2,
      marginBottom: '2rem',
    },
  }))

  const {
    data: { info },
  } = useSobreQuery({ fetchPolicy: 'cache-first' })

  if (!info) {
    return null
  }

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Sobre' />
      <VFlow vSpacing={2}>
        <PageHeader title='Sobre' />
        <Grid>
          <Cell size={9}>
            <div className={classes.info}>
              <Heading level={2}>PEC e-SUS APS - Versão {info.versao}</Heading>
            </div>
            <VFlow vSpacing={0.8}>
              <p>
                <Text fontWeight='bold'>Compilação:</Text> {info.commit}
              </p>
              <p>
                <Text fontWeight='bold'>Banco de dados:</Text> {info.databaseVendor}
              </p>
              <p>
                <Text fontWeight='bold'>Versão do banco de dados:</Text> {info.databaseSchemaVersion}
              </p>
              <p>
                <Text fontWeight='bold'>Competência do SIGTAP:</Text> {info.competenciaSigtap}
              </p>
              <p>
                <Text fontWeight='bold'>Recursos da instalação:</Text> {info.recursosDisponiveis}
              </p>
              <p>
                <Tag>Software desenvolvido pelo Laboratório Bridge</Tag>
              </p>
            </VFlow>
          </Cell>
        </Grid>
        <SobreFooterView />
      </VFlow>
    </PageContent>
  )
}
