import { DataTable, Heading, HFlow, VFlow } from 'bold-ui'
import { TransmissaoRecebimentoCnesDetailItemsQuery } from 'graphql/types.generated'
import React from 'react'

interface TransmissaoRecebimentoCnesDetailTableProps {
  itens: TransmissaoRecebimentoCnesDetailItem[]
}

export type TransmissaoRecebimentoCnesDetailItem = TransmissaoRecebimentoCnesDetailItemsQuery['transmissaoRecebimentoCnesDetailItems'][0]

export function TransmissaoRecebimentoCnesDetailTable(props: TransmissaoRecebimentoCnesDetailTableProps) {
  const { itens } = props

  const renderTipoFicha = (row: TransmissaoRecebimentoCnesDetailItem) => <p>{row.tipoFicha}</p>

  const renderQtd = (row: TransmissaoRecebimentoCnesDetailItem) => <p>{row.quantidade}</p>

  const total = itens.reduce((curr, prev) => curr + prev.quantidade, 0)

  return (
    <VFlow vSpacing={1}>
      <DataTable<TransmissaoRecebimentoCnesDetailItem>
        rows={itens}
        columns={[
          {
            name: 'tipoFicha',
            header: 'Tipo de ficha',
            render: renderTipoFicha,
          },
          {
            name: 'quantidade',
            header: 'Quantidade',
            render: renderQtd,
          },
        ]}
      />
      <Heading level={4}>
        <HFlow hSpacing={0.5} alignItems='center'>
          Total de fichas válidas recebidas: {total}
        </HFlow>
      </Heading>
    </VFlow>
  )
}
