import { Cbo, CondutaEnum, ConselhoClasse, TipoAtendimentoProfissional, Uf } from 'graphql/types.generated'

export type AtendimentoProfissional = {
  id: ID
  tipo: TipoAtendimentoProfissional
  iniciadoEm: Instant
  finalizadoEm?: Instant
  lotacao: LotacaoAtendimento
}

export type LotacaoAtendimento = {
  id: ID
  profissional: ProfissionalAtendimento
  cbo: Cbo
  unidadeSaude: { id: ID; nome?: string; cnes?: string }
  municipio: { id: ID; nome: string; uf: Uf }
}

export type ProfissionalAtendimento = {
  id: ID
  nome: string
  cns?: string
  cpf?: string
  numeroConselhoClasse?: string
  conselhoClasse?: ConselhoClasse
  ufEmissoraConselhoClasse?: Uf
}

export const condutaDesfecho: Record<CondutaEnum, string> = {
  [CondutaEnum.AGENDAMENTO_PARA_GRUPOS]: 'Agendamento para grupos',
  [CondutaEnum.AGENDAMENTO_PARA_NASF]: 'Agendamento para NASF',
  [CondutaEnum.AGENDAMENTO_PARA_EMULTI]: 'Agendamento para eMulti',
  [CondutaEnum.ALTA_DO_EPISODIO]: 'Alta do episódio',
  [CondutaEnum.RETORNO_PARA_CONSULTA_AGENDADA]: 'Retorno para consulta agendada',
  [CondutaEnum.RETORNO_PARA_CUIDADO_CONTINUADO_PROGRAMADO]: 'Retorno para consulta programada / cuidado continuado',
  [CondutaEnum.MANTER_EM_OBSERVACAO]: 'Manter em observação',
}
