import { Button, Dropdown, HFlow, Icon, IconProps, useStyles } from 'bold-ui'
import { ExposedDropdownItem } from 'components/dropdown/ExposedDropdownItem'
import React, { CSSProperties, useState } from 'react'

export interface ExposedDropdownProps<T> {
  selectedItem: T
  items: T[]
  label?: string
  itemToString: (item: T | null) => string
  onChange?(item: T): void
}

export function ExposedDropdown<T>(props: ExposedDropdownProps<T>) {
  const { selectedItem, items, label, itemToString, onChange } = props
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement>()

  const [open, setOpen] = useState(false)

  const { classes } = useStyles(createStyles)

  const icon: IconProps['icon'] = open ? 'angleUp' : 'angleDown'

  const handleClick = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    buttonRef.focus()
  }

  return (
    <>
      <HFlow hSpacing={0} alignItems='center' style={classes.hFlow}>
        <span className={classes.label}>{label}</span>
        <Button style={classes.button} innerRef={setButtonRef} size='small' skin='ghost' onClick={handleClick}>
          <span className={classes.buttonItem}>{itemToString(selectedItem)}</span>
          <Icon icon={icon} style={{ paddingLeft: '0.25rem' }} />
        </Button>
      </HFlow>

      <Dropdown anchorRef={buttonRef} open={open} onClose={handleClose}>
        {items.map((item, i) => (
          <ExposedDropdownItem key={i} onClick={onChange} value={item}>
            {itemToString(item)}
          </ExposedDropdownItem>
        ))}
      </Dropdown>
    </>
  )
}

const createStyles = () => ({
  hFlow: {
    gridAutoColumns: 'initial',
  } as CSSProperties,

  button: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    '& span': { maxWidth: '100%' },
  } as CSSProperties,

  label: {
    whiteSpace: 'nowrap',
  } as CSSProperties,

  buttonItem: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  } as CSSProperties,
})
