/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Icon } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { AgendaTimeSlotProps } from 'components/agenda/AgendaTimeSlotWrapper'
import { PopperButton, PopperControls } from 'components/popper'
import { ReactNode } from 'react'

export interface HoverSlotDisponivelProps extends AgendaTimeSlotProps {
  renderAgendamentoForm(controls: PopperControls, start: number, end: number): ReactNode
  onPopperOpen?(): void
  onPopperClose?(): void
}

export function HoverSlotDisponivel(props: HoverSlotDisponivelProps) {
  const { start, end, onPopperClose, onPopperOpen, renderAgendamentoForm } = props

  return (
    <PopperButton
      kind='primary'
      type='button'
      skin='outline'
      positioningStrategy='absolute'
      style={styles.wrapper}
      onOpen={onPopperOpen}
      onClose={onPopperClose}
      renderPopper={(controls) => renderAgendamentoForm(controls, start, end)}
      placement='bottom-start'
    >
      <Icon icon='plus' style={styles.icon} />
      Adicionar agendamento
    </PopperButton>
  )
}

const styles = {
  wrapper: css`
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    border-radius: 2px;
    padding-top: 0.9rem;
    background-color: ${gray.c100} !important;
  `,
  icon: css`
    margin-right: 0.7rem;
  `,
}
