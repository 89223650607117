/** @jsx jsx */
import { jsx } from '@emotion/core'

import { PathWithCondicoesDente } from '../components/PathWithCondicoesDente'
import { DenteProps } from '../types/Dente'
import { DenteWrapper } from './DenteWrapper'
import { useOdontogramaDente } from './util'

export default function Dente63(props: DenteProps) {
  const { active, placement, value, disabled, style, readonly } = props

  const { classes, ref, denteEvents, setRef } = useOdontogramaDente(props)

  return (
    <DenteWrapper
      active={active}
      disabled={disabled}
      readonly={readonly}
      placement={placement}
      value={value}
      style={style}
    >
      <svg width='40' height='111' viewBox='0 0 40 111' fill='none' css={classes.dente} ref={setRef} {...denteEvents}>
        <PathWithCondicoesDente
          parentRef={ref}
          placement={placement}
          denteActive={active}
          value={value?.condicoes}
          readonly={readonly}
          d='M33.9115 78.8891C34.0886 78.9073 34.2471 78.9234 34.3856 78.9373C34.3315 78.6329 34.262 78.2247 34.1819 77.7144C33.9869 76.4729 33.7284 74.6272 33.4733 72.2031C32.9636 67.3599 32.1374 56.1904 31.6227 46.5758C31.1078 36.9568 31.0511 12.785 31.6243 6.78834C31.7533 5.43869 31.3313 4.55216 30.6441 3.99827C29.9326 3.42486 28.8611 3.14735 27.5998 3.20002C25.0711 3.30561 22.0471 4.72868 20.397 7.1199C19.5658 8.32442 18.7656 10.1999 18.0072 12.6059C17.2516 15.003 16.5482 17.891 15.897 21.0959C14.5947 27.5052 13.5076 35.1501 12.6283 42.6046C12.5513 43.2569 12.4801 43.9058 12.4139 44.5506C11.7334 51.1841 11.7412 57.4093 11.7475 62.4681C11.7479 62.7606 11.7483 63.0493 11.7485 63.3339C11.7505 65.9306 11.7411 68.2027 11.6158 70.0153C11.5531 70.9224 11.4606 71.7269 11.3226 72.4073C11.1858 73.0822 10.9982 73.6669 10.7278 74.1141C10.6935 74.1708 10.6987 74.1879 10.7157 74.2241C10.7501 74.297 10.8527 74.4235 11.0952 74.5879C11.5748 74.9129 12.3968 75.2513 13.5016 75.5871C15.6932 76.2532 18.8117 76.8562 22.0144 77.3644C25.2111 77.8716 28.4693 78.2811 30.9298 78.5639C32.1597 78.7053 33.1894 78.8148 33.9115 78.8891Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceOne, classes.faceCondicoes]}
        />
        <path
          d='M37.2383 95.3581C37.5261 94.2986 37.5352 92.7258 37.3334 90.8435C37.1332 88.9756 36.7318 86.8563 36.2361 84.7325C35.2445 80.4838 33.8854 76.2587 33.0443 74.0668C32.8798 73.638 32.5057 73.1951 31.9033 72.7609C31.305 72.3296 30.5149 71.9311 29.5767 71.5801C27.7009 70.8782 25.2951 70.3886 22.8083 70.1843C20.3219 69.9801 17.7823 70.0634 15.6399 70.4898C13.476 70.9204 11.8131 71.682 10.9622 72.7523C9.08634 75.1115 6.96568 79.5168 5.30917 84.0135C3.65011 88.5171 2.49996 92.9951 2.49996 95.4892C2.49996 96.6264 3.05901 97.9083 4.09372 99.2302C5.12268 100.545 6.58652 101.852 8.3051 103.03C11.7485 105.39 16.1331 107.174 19.9076 107.472C23.6084 107.764 27.6344 105.883 30.9276 103.297C32.5657 102.01 34.0015 100.565 35.1014 99.1632C36.2071 97.7546 36.9498 96.4205 37.2383 95.3581Z'
          strokeLinecap='round'
          strokeLinejoin='round'
          css={[classes.face, classes.faceTwo]}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.5708 72.4412C6.71643 77.2887 1.99997 90.3097 1.99997 95.4892C1.99997 100.669 12.1038 107.358 19.8683 107.971C27.6328 108.583 36.468 100.103 37.7208 95.4892C38.7528 91.6892 36.3816 82.4985 34.5638 76.9009C34.3883 75.7131 34.1785 74.1251 33.9707 72.1508C33.4623 67.3199 32.6366 56.1601 32.1221 46.5491C31.6076 36.9382 31.5525 12.7956 32.1221 6.83595C32.6918 0.876349 23.4831 1.76766 19.9856 6.83595C16.4882 11.9042 13.8918 27.6254 12.1319 42.546C12.0546 43.201 11.983 43.8524 11.9166 44.4996C11.2334 51.1597 11.2413 57.4083 11.2477 62.4647C11.2529 66.6336 11.2571 69.9921 10.8722 72.1028C10.7642 72.2117 10.6635 72.3245 10.5708 72.4412ZM8.98757 71.2188C9.24911 69.3015 9.25256 66.3584 9.24766 62.4673L9.24762 62.4374C9.24127 57.3926 9.23329 51.0588 9.92709 44.2955C9.99446 43.6387 10.0671 42.9772 10.1456 42.3117C11.0283 34.8282 12.125 27.1055 13.4472 20.5981C14.1081 17.3453 14.8316 14.3648 15.623 11.8544C16.403 9.37985 17.2925 7.21734 18.3395 5.70003C20.4828 2.59418 24.2698 0.836931 27.4956 0.702232C29.1206 0.634377 30.8695 0.968932 32.213 2.05185C33.6535 3.2129 34.3091 4.97535 34.1131 7.02626C33.5576 12.8376 33.6065 36.8638 34.1193 46.4422C34.633 56.0387 35.4565 67.1598 35.9597 71.9414C36.155 73.7971 36.3517 75.3022 36.5183 76.4445C37.4314 79.274 38.4753 82.9751 39.1725 86.4788C39.5277 88.2634 39.8 90.0316 39.9135 91.6268C40.0244 93.1847 39.9973 94.7379 39.6509 96.0133C39.2388 97.531 38.2743 99.17 37.0679 100.707C35.8386 102.273 34.2593 103.859 32.4718 105.263C28.9645 108.017 24.3191 110.328 19.711 109.964C15.3979 109.624 10.6008 107.634 6.89179 105.092C5.0248 103.813 3.35206 102.339 2.12513 100.771C0.921227 99.2332 -3.43323e-05 97.4148 -3.43323e-05 95.4892C-3.43323e-05 92.5167 1.29494 87.6781 2.96329 83.1493C4.63712 78.6056 6.8603 73.9048 8.98757 71.2188Z'
          css={classes.focusPath}
        />
      </svg>
    </DenteWrapper>
  )
}
