/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Tag, Text, Tooltip } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { Box } from 'components/Box'
import { HLabel } from 'components/HLabel'
import LabelItemInativo from 'components/LabelItemInativo'
import { ButtonLink } from 'components/route'
import Permissions from 'types/Permissions'
import { formatDate } from 'util/date/formatDate'
import { EstagioCardDropdown } from 'view/estagiario/components/EstagioCardDropdown'
import { PermissoesEstagiario } from 'view/estagiario/components/PermissoesEstagiario'

import { EstagioModel } from '../model'

export interface EstagioCardProps {
  estagio: EstagioModel
  refetch(): void
}

export function EstagioCard(props: EstagioCardProps) {
  const { estagio, refetch } = props
  const { permissaoEdicao } = estagio.actions

  return (
    <Box style={styles.box}>
      <div css={styles.content}>
        <CheckPermission
          permission={Permissions.visualizarProfissionais.visualizarAcessos.cadastrarEditarEExcluirLotacao}
        >
          <Tooltip text={permissaoEdicao.enabled ? 'Editar' : permissaoEdicao.hint}>
            <ButtonLink
              style={styles.noPaddingTop}
              size='small'
              skin='ghost'
              to={`lotacoesEstagio/${estagio.id}/edit`}
              disabled={!permissaoEdicao.enabled}
            >
              <Icon icon='penOutline' />
            </ButtonLink>
          </Tooltip>
          <EstagioCardDropdown style={styles.noPaddingTop} estagio={estagio} refetch={refetch} />
        </CheckPermission>
      </div>

      <HFlow hSpacing={0.5}>
        <Heading level={3} style={styles.unidadeSaude}>
          {estagio.unidadeSaude.nome.titleCase()}
        </Heading>
        {!estagio.ativo && <Tag>Inativo</Tag>}
      </HFlow>

      <HLabel title='Supervisor'>{estagio.lotacaoSupervisora.profissional.nome.titleCase()}</HLabel>

      <PermissoesEstagiario cbo={estagio.cbo} equipe={estagio.equipe} />

      <HLabel title='Perfis'>
        {estagio.perfis
          ? estagio.perfis.map((perfil, index) => (
              <Text key={perfil.id}>
                {index === 0 ? '' : '; '}
                {perfil.nome}
                <LabelItemInativo ativo={perfil.ativo} />
              </Text>
            ))
          : ''}
      </HLabel>

      {estagio.nivelTecnico && <HLabel title='Formação técnica'>{estagio.cbo.nome.titleCase()}</HLabel>}

      <HLabel title='Período do estágio'>
        {formatDate(estagio.dataInicioVigencia)} até {formatDate(estagio.dataFimVigencia)}
      </HLabel>

      <HLabel title='Instituição de ensino'>{estagio.instituicaoEnsino?.titleCase()}</HLabel>
    </Box>
  )
}

const styles = {
  box: css`
    height: 100%;
  `,
  content: css`
    float: right;
  `,
  noPaddingTop: css`
    padding-top: 0;
  `,
  unidadeSaude: css`
    margin-bottom: 0.5rem;
  `,
}
