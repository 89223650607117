import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import CidadaoADCreateView from './CidadaoADCreateView'
import { CidadaoADEditView } from './CidadaoADEditView'
import ListaADView from './ListaADView'

export function ListaADRootView() {
  const match = useRouteMatch()

  return (
    <>
      <Breadcrumb title='Lista de atenção domiciliar' />

      <PecSwitch>
        <PrivateRoute
          exact
          path={`${match.url}`}
          component={ListaADView}
          permission={Permissions.atencaoDomiciliar.visualizarListaDeAdDaEquipe}
        />
        <PrivateRoute
          exact
          path={`${match.url}/cadastro`}
          component={CidadaoADCreateView}
          permission={Permissions.atencaoDomiciliar.visualizarListaDeAdDaEquipe.cadastrar}
        />
        <PrivateRoute
          exact
          path={`${match.url}/:cidadaoADId(\\d+)/edit`}
          component={CidadaoADEditView}
          permission={Permissions.atencaoDomiciliar.visualizarListaDeAdDaEquipe}
        />
      </PecSwitch>
    </>
  )
}
