import { Omit, PagedTable, PagedTableProps } from 'bold-ui'
import { DateTime } from 'components/date'
import { TableBox } from 'components/table/TableBox'
import { LoteHistoricoExportacao } from 'graphql/types.generated'
import React, { ReactNode } from 'react'
import { titleCase } from 'util/strConversor'

type Item = LoteHistoricoExportacao

export interface TransmissaoEnvioHistoricoExportTableProps extends Omit<PagedTableProps<Item>, 'columns'> {}

export const TransmissaoEnvioHistoricoExportTable = (props: TransmissaoEnvioHistoricoExportTableProps) => {
  const renderNomeProfissional: (row: Item) => ReactNode = (row) => titleCase(row.nomeProfissional)
  const renderSalvoEm: (row: Item) => ReactNode = (row) => <DateTime value={row.dataExportacao} />

  return (
    <TableBox>
      <PagedTable<Item>
        columns={[
          { name: 'nomeProfissional', header: 'Salvo por', render: renderNomeProfissional },
          { name: 'salvoEm', header: 'Salvo em', render: renderSalvoEm },
        ]}
        {...props}
      />
    </TableBox>
  )
}
