import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, Theme, useStyles, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Age } from 'components/date/Age'
import { Cpf } from 'components/label'
import React, { CSSProperties } from 'react'
import { identidadeGenero, sexo } from 'types/enums'
import { titleCase } from 'util/strConversor'
import { CidadaoListingFormModel } from 'view/cidadao/types/CidadaoPesquisarFormModel'
interface UnificacaoCadastroConfirmacaoModalProps {
  openConfirmModal: boolean
  cancelarConfirmacao(): void
  unificarCidadaos(): void
  cadastroResultante: CidadaoListingFormModel
  cnsCadastroResultante: string
  cpfCadastroResultante: string
  unidadeResponsavelResultante: string
}

export function UnificacaoCadastroConfirmacaoModal(props: UnificacaoCadastroConfirmacaoModalProps) {
  const { classes } = useStyles(createStyles)
  const {
    openConfirmModal,
    cancelarConfirmacao,
    unificarCidadaos,
    cadastroResultante,
    cnsCadastroResultante,
    cpfCadastroResultante,
    unidadeResponsavelResultante,
  } = props
  return (
    <Modal
      open={openConfirmModal}
      onClose={cancelarConfirmacao}
      closeOnBackdropClick={false}
      style={{ width: '42.5rem' }}
    >
      <ModalBody>
        <HFlow alignItems='center'>
          <Icon icon='questionMarkFilled' style={{ marginRight: '0.5rem' }} size={3} fill='info' />
          <VFlow vSpacing={0.5}>
            <Heading level={1}>Deseja confirmar a unificação de cadastros?</Heading>
            <Text>O cadastro do cidadão ficará com os seguintes dados:</Text>
          </VFlow>
        </HFlow>
        <VFlow style={classes.container} vSpacing={1}>
          <HFlow hSpacing={0.5} alignItems='baseline'>
            {cadastroResultante.nomeSocial ? (
              <span>
                <Text fontSize={1} fontWeight='bold'>
                  {cadastroResultante.nomeSocial}
                </Text>
                {cadastroResultante.nomeSocial && <Text fontWeight='bold'> (Nome social)</Text>}
                {cadastroResultante.nomeSocial && <Text>{' | ' + titleCase(cadastroResultante.nome)}</Text>}
              </span>
            ) : (
              <Text fontSize={1} fontWeight='bold'>
                {titleCase(cadastroResultante.nome)}
              </Text>
            )}
          </HFlow>
          <VFlow vSpacing={0}>
            <HFlow>
              <HFlow hSpacing={0.5} data-cy='ModalConfirmar.CPF'>
                <Text fontWeight='bold'>CPF</Text>
                {cpfCadastroResultante ? <Cpf value={cpfCadastroResultante} /> : '-'}
              </HFlow>
              <HFlow hSpacing={0.5} data-cy='ModalConfirmar.CNS'>
                <Text fontWeight='bold'>CNS</Text>
                {cnsCadastroResultante ? <Text>{cnsCadastroResultante}</Text> : '-'}
              </HFlow>
            </HFlow>
            <HFlow hSpacing={0.5} data-cy='ModalConfirmar.DataNascimento'>
              <Text fontWeight='bold'>Data de nascimento</Text>
              <DateTime format='DD/MM/YYYY' value={cadastroResultante?.dataNascimento} />
              <Age dateOfBirth={cadastroResultante?.dataNascimento} />
            </HFlow>
            <HFlow hSpacing={0.5}>
              <Text fontWeight='bold'>Sexo</Text>
              <Text data-cy='ModalConfirmar.Sexo'> {sexo[cadastroResultante?.sexo]}</Text>
              <Text fontWeight='bold'>{cadastroResultante?.identidadeGeneroDbEnum ? 'Id. de gênero' : null}</Text>
              <Text data-cy='ModalConfirmar.IdGenero'>
                {cadastroResultante?.identidadeGeneroDbEnum &&
                  identidadeGenero[cadastroResultante?.identidadeGeneroDbEnum]}
              </Text>
            </HFlow>
          </VFlow>
          <VFlow vSpacing={0}>
            <HFlow data-cy='ModalConfirmar.NomeMae'>
              <Text fontWeight='bold'>Nome da mãe</Text>
              <Text>{cadastroResultante?.nomeMae ? titleCase(cadastroResultante?.nomeMae) : '-'}</Text>
            </HFlow>
            <HFlow data-cy='ModalConfirmar.UnidadeResponsavel'>
              <Text fontWeight='bold'>Unidade responsável</Text>
              <Text>{titleCase(unidadeResponsavelResultante) ?? '-'}</Text>
            </HFlow>
            <HFlow data-cy='ModalConfirmar.MunicipioNascimento'>
              <Text fontWeight='bold'>Município de nascimento</Text>
              <Text>
                {cadastroResultante?.localidadeNascimento
                  ? `${titleCase(cadastroResultante?.localidadeNascimento?.nome)} - ${
                      cadastroResultante?.localidadeNascimento?.uf?.sigla
                    }`
                  : '-'}
              </Text>
            </HFlow>
          </VFlow>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' size='medium' onClick={cancelarConfirmacao} data-cy='CancelarButton'>
            Cancelar
          </Button>
          <Button kind='primary' skin='default' size='medium' onClick={unificarCidadaos}>
            Confirmar unificação
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    border: `1px solid ${theme.pallete.divider}`,
    padding: '1rem',
    textAlign: 'left',
    margin: '2.35rem 0 0 0',
  } as CSSProperties,
  nomeCidadao: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  } as CSSProperties,
})
