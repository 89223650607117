export interface InconsistenciasAgendaOnlineLotacaoModel {
  id: ID
  ativo: boolean
  importada: boolean
  hasConfiguracaoAgenda: boolean
  equipe: {
    id: string
    ine: string
    nome: string
    tipoEquipe: {
      descricao?: string
    }
  }
}

export function getInconsistenciasLotacaoAgendaOnline(lotacao: InconsistenciasAgendaOnlineLotacaoModel) {
  const inconsistencias = !lotacao.ativo
    ? 'Lotação inativa'
    : !lotacao.importada
    ? 'Lotação não registrada no CNES'
    : !lotacao.hasConfiguracaoAgenda
    ? 'Lotação não possui uma configuração de agenda'
    : lotacao.equipe &&
      (lotacao.equipe.tipoEquipe.descricao === 'EMAD' || lotacao.equipe.tipoEquipe.descricao === 'EMAP')
    ? 'Lotação de Atenção Domiciliar não permite agendamento online'
    : !lotacao.equipe || lotacao.equipe.tipoEquipe.descricao !== 'AB'
    ? 'Lotação não possui INE cadastrado'
    : ''

  return inconsistencias
}
