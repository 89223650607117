import { useEffect, useMemo, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import { DenteProps } from '../types/Dente'
import { getDenteEvents } from './DenteWrapper'
import { useDenteStyles } from './styles'

export const useOdontogramaDente = (props: DenteProps) => {
  const {
    value,
    active,
    protese,
    error,
    isDeciduo,
    disableStateEffects,
    disabled,
    readonly,
    onClick,
    setActiveRef,
  } = props

  const [ref, setRef] = useState<SVGSVGElement>()
  const classes = useDenteStyles(value, active, protese, !!error, isDeciduo, disabled, disableStateEffects, readonly)

  const denteEvents = useMemo(
    () =>
      getDenteEvents({
        onClick: () => onClick?.(ref),
        disableStateEffects,
        disabled,
      }),
    [disableStateEffects, disabled, onClick, ref]
  )

  useEffect(() => {
    if (active && !isUndefinedOrNull(setActiveRef)) {
      setActiveRef({ ref })
    }
  }, [active, ref, setActiveRef])

  return { classes, denteEvents, ref, setRef }
}
