import { Icon } from 'bold-ui'
import { TipoMedicaoEnum } from 'graphql/types.generated'
import { ReactComponent as CircAbdominal } from 'images/medical/circunferencia-abdominal-filled.svg'
import { ReactComponent as Glicemia } from 'images/medical/glucometer.svg'
import { ReactComponent as Height } from 'images/medical/height.svg'
import { ReactComponent as Lungs } from 'images/medical/lungs.svg'
import { ReactComponent as PerCefalico } from 'images/medical/perimetro-cefalico-filled.svg'
import { ReactComponent as PerPanturrilha } from 'images/medical/perimetro-panturrilha-filled.svg'
import { ReactComponent as Thermometer } from 'images/medical/thermometer-filled.svg'
import { ReactComponent as Weight } from 'images/medical/weight.svg'
import React from 'react'

export type GrupoMedicoesTipoModel = {
  tipo: TipoMedicaoEnum
  medicoes: MedicaoProntuarioModel[]
}

export type MedicaoProntuarioModel = {
  key: ID
  dataMedicao?: Instant
  valor?: string
  observacao?: string
}

export type TipoMedicaoAtendimento = {
  label?: string
  unidadeMedida?: string
  order?: number
  icon?: React.ReactElement
  colorScale?: string
}

export const tipoMedicaoRecord: Record<TipoMedicaoEnum, TipoMedicaoAtendimento> = {
  [TipoMedicaoEnum.PESO]: {
    order: 1,
    label: 'Peso',
    unidadeMedida: 'kg',
    icon: <Weight />,
  },
  [TipoMedicaoEnum.ALTURA]: {
    order: 2,
    label: 'Altura',
    unidadeMedida: 'cm',
    icon: <Height />,
  },
  [TipoMedicaoEnum.IMC]: {
    order: 3,
    label: 'IMC',
    unidadeMedida: 'kg/m²',
    icon: <Icon icon='imcFilled' />,
  },
  [TipoMedicaoEnum.PRESSAO_ARTERIAL]: {
    order: 4,
    label: 'Pressão arterial',
    unidadeMedida: 'mmHg',
    icon: <Icon icon='bloodPressureFilled' />,
  },
  [TipoMedicaoEnum.FREQUENCIA_RESPIRATORIA]: {
    order: 5,
    label: 'Freq. respiratória',
    unidadeMedida: 'mpm',
    icon: <Lungs />,
  },
  [TipoMedicaoEnum.FREQUENCIA_CARDIACA]: {
    order: 6,
    label: 'Freq. cardíaca',
    unidadeMedida: 'bpm',
    icon: <Icon icon='heartRateFilled' />,
  },
  [TipoMedicaoEnum.TEMPERATURA]: {
    order: 7,
    label: 'Temperatura',
    unidadeMedida: 'ºC',
    icon: <Thermometer />,
  },
  [TipoMedicaoEnum.SATURACAO_O2]: {
    order: 8,
    label: 'Saturação de O2',
    unidadeMedida: '%',
    icon: <Icon icon='o2Filled' />,
  },
  [TipoMedicaoEnum.CIRCUNFERENCIA_ABDOMINAL]: {
    order: 9,
    label: 'Circ. abdominal',
    unidadeMedida: 'cm',
    icon: <CircAbdominal />,
  },
  [TipoMedicaoEnum.PERIMETRO_CEFALICO]: {
    order: 10,
    label: 'Per. cefálico',
    unidadeMedida: 'cm',
    icon: <PerCefalico />,
  },
  [TipoMedicaoEnum.PERIMETRO_PANTURRILHA]: {
    order: 11,
    label: 'Per. da panturrilha',
    unidadeMedida: 'cm',
    icon: <PerPanturrilha />,
  },
  [TipoMedicaoEnum.GLICEMIA]: {
    order: 12,
    label: 'Glicemia capilar',
    unidadeMedida: 'mg/dL',
    icon: <Glicemia />,
  },
  [TipoMedicaoEnum.DUM]: {}, // Essa informação ainda não é apresentada na lateral do atendimento
  [TipoMedicaoEnum.ALTURA_UTERINA]: {}, // Essa informação ainda não é apresentada na lateral do atendimento
}
