import { Cell, Grid, Text } from 'bold-ui'
import { CondicaoAutorreferida } from 'graphql/types.generated'
import React from 'react'
import { emptyArray } from 'util/array'
import { stringGrouper } from 'util/string/string-grouper'

import { ContentCardSection } from '../components/ContentCardSection'

export interface CondicoesAutorreferidasCardProps {
  condicoesAutorreferidas?: CondicaoAutorreferida[]
  loading: boolean
  canRender: boolean
}

export function CondicoesAutorreferidasCard(props: CondicoesAutorreferidasCardProps) {
  const { condicoesAutorreferidas = emptyArray, loading, canRender } = props
  return (
    <ContentCardSection isLoading={loading} messageLoading='Carregando dados das condições' canRender={canRender}>
      <Grid gap={0.5} gapVertical={0.6}>
        <Cell style={{ maxWidth: '100%' }}>
          <Text type='normal' style={{ maxWidth: '100%' }}>
            {stringGrouper(...condicoesAutorreferidas.map((e) => e.descricao).sort((a, b) => a.localeCompare(b)))}
          </Text>
        </Cell>
      </Grid>
    </ContentCardSection>
  )
}
