import { SerializedStyles } from '@emotion/core'
import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

interface ResponsabilidadesDoUsuarioSectionProps {
  listStyle: SerializedStyles
}

export const ResponsabilidadesDoUsuarioSection = (props: ResponsabilidadesDoUsuarioSectionProps) => {
  const { listStyle } = props

  return (
    <TermosDeUsoHeadingSection title='6. Responsabilidades do Usuário' id='responsabilidades_do_usuario'>
      <p>
        <b>Quais são as obrigações dos usuários que utilizam o serviço?</b>
      </p>
      <br />
      <p>
        O usuário se responsabiliza pela precisão e pela veracidade dos dados informados e reconhece que a
        inconsistência deles poderá implicar a impossibilidade de se utilizar o Prontuário Eletrônico do Cidadão (PEC)
        do Sistema e-SUS APS.
      </p>
      <br />
      <p>
        Durante a utilização do serviço, a fim de resguardar e proteger os direitos de terceiros, o usuário se
        compromete a fornecer somente seus dados pessoais, e não os de terceiros.
      </p>
      <br />
      <p>
        O login e senha só poderão ser utilizados pelo usuário cadastrado. Ele se compromete em manter o sigilo da
        senha, que é pessoal e intransferível, não sendo possível, em qualquer hipótese, a alegação de uso indevido após
        o ato de compartilhamento.
      </p>
      <br />
      <p>
        O usuário do serviço é responsável pela atualização dos seus dados pessoais e pelas consequências em caso de
        omissão ou erros nos dados fornecidos.
      </p>
      <br />
      <p>
        O Usuário é responsável pela reparação de todos e quaisquer danos, diretos ou indiretos (inclusive decorrentes
        de violação de quaisquer direitos de outros usuários; de terceiros, inclusive direitos de propriedade
        intelectual;de sigilo; e de personalidade), que sejam causados à Administração Pública, a qualquer outro
        Usuário, ou ainda a qualquer terceiro, inclusive em virtude do descumprimento do disposto nestes Termos de Uso e
        Política de Privacidade ou de qualquer ato praticado a partir de seu acesso ao serviço.
      </p>
      <br />
      <p>O Ministério da Saúde não poderá ser responsabilizado pelos seguintes fatos:</p>
      <br />
      <ol type='a' css={listStyle}>
        <li>Equipamento infectado ou invadido por atacantes;</li>
        <li>Equipamento avariado no momento do consumo de serviços;</li>
        <li>Proteção do computador;</li>
        <li>Proteção das informações baseadas nos computadores dos usuários;</li>
        <li>Abuso de uso dos computadores dos usuários;</li>
        <li>Monitoração clandestina do computador dos usuários;</li>
        <li>Vulnerabilidades ou instabilidades existentes nos sistemas dos usuários;</li>
        <li>Perímetro inseguro.</li>
      </ol>
      <br />
      <p>
        Em nenhuma hipótese, a Administração Pública Federal será responsável pela instalação, no equipamento do Usuário
        ou de terceiros, de códigos maliciosos (vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de
        quaisquer outros que venham a ser criados), em decorrência da navegação na Internet pelo Usuário
      </p>
    </TermosDeUsoHeadingSection>
  )
}
