import { Heading, Theme, useStyles } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { useConfiguracaoAgendaLotacaoQuery } from 'graphql/hooks.generated'
import React, { CSSProperties } from 'react'
import { Redirect, useRouteMatch } from 'react-router'
import { ProfissionalHeaderIdentifier } from 'view/profissional/components/ProfissionalHeaderIdentifier'
import ProfissionalHeaderIdentifierDto from 'view/profissional/components/ProfissionalHeaderIdentifierDto'

import FechamentoAgendaTable from './FechamentoAgendaTable'

interface UrlParams {
  lotacaoId: string
  profissionalId: string
}

export function FechamentoAgendaLotacaoView() {
  const match = useRouteMatch<UrlParams>()
  const { classes } = useStyles(createStyles)

  const cfgAgendaLotacaoQuery = useConfiguracaoAgendaLotacaoQuery({
    variables: { lotacaoId: match.params.lotacaoId },
    fetchPolicy: 'network-only',
  })

  const { lotacao } = cfgAgendaLotacaoQuery.data

  if (!lotacao) {
    return null
  }

  if (!lotacao.actions.permissaoFecharAgenda.enabled) {
    return <Redirect to='/403' />
  }

  const nomeProfissional = lotacao.profissional?.nome
  const profissionalHeaderIdentifierDto = {
    nomeProfissional,
    lotacao,
  } as ProfissionalHeaderIdentifierDto

  return (
    <PageContent type='filled' style={classes.container}>
      <Breadcrumb title='Visualização' to={`/profissional/${match.params.profissionalId}`} />
      <Breadcrumb title='Configuração de fechamentos da agenda' />

      <Heading level={1} style={classes.heading}>
        Configuração de fechamentos da agenda
      </Heading>
      <ProfissionalHeaderIdentifier profissional={profissionalHeaderIdentifierDto} />
      <FechamentoAgendaTable idLotacao={match.params.lotacaoId} />
    </PageContent>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    backgroundColor: theme.pallete.surface.main,
  } as CSSProperties,
  heading: {
    marginBottom: '2rem',
  } as CSSProperties,
})
