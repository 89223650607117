import { colors, focusBoxShadow, Theme, useStyles } from 'bold-ui'
import React, { CSSProperties } from 'react'

import { RadioField, RadioFieldProps } from '../final-form'
import { usePecField } from '../final-form/hooks/useField'

export function RadioBoxField(props: RadioFieldProps) {
  const { onClick, value } = props
  const { classes, css } = useStyles(createStyles)
  const { input } = usePecField({ type: 'radio', ...props })

  return (
    <div className={css(classes.container, input.checked && classes.selected)}>
      <RadioField name={props.name} label={props.label} onClick={onClick} value={value} />
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    background: theme.pallete.surface.main,
    border: 'solid 1px',
    borderRadius: 2,
    borderColor: theme.pallete.gray.c60,
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    transition: 'box-shadow .2s ease',
    '&:focus-within': {
      boxShadow: focusBoxShadow(theme, 'primary'),
    },
    'input:focus + span': {
      boxShadow: 'none !important',
    },
  } as CSSProperties,
  selected: {
    borderColor: colors.blue.c40,
  } as CSSProperties,
})
