/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { PageLoading } from 'components/loading'
import { useMemo } from 'react'
import { AccordionItemPanel } from 'react-accessible-accordion'

import { MainDetailAccordionEvolucaoType, MainDetailAccordionType } from '../model'

export interface MainDetailAccordionBodyRenderProps<
  E extends MainDetailAccordionEvolucaoType,
  T extends MainDetailAccordionType<E>
> {
  item?: T
  nextEvolucao?: E
  evolucao?: E
}

interface BodyFormProps<T> {
  isEditing(itemId: ID): boolean
  renderFormBody(item: T): JSX.Element
}

export interface BodyWithDetailAndFormProps<
  E extends MainDetailAccordionEvolucaoType,
  T extends MainDetailAccordionType<E>
> {
  item: T
  loading?: boolean
  formProps?: BodyFormProps<T>
  renderDetailBody(renderDetailProps: MainDetailAccordionBodyRenderProps<E, T>): JSX.Element
}

export function BodyWithDetailAndForm<E extends MainDetailAccordionEvolucaoType, T extends MainDetailAccordionType<E>>(
  props: BodyWithDetailAndFormProps<E, T>
) {
  const { item, loading, renderDetailBody, formProps } = props

  const theme = useTheme()
  const { accordionItemPanelStyles } = useMemo(() => createStyles(theme, !formProps?.isEditing(item.id)), [
    formProps,
    item.id,
    theme,
  ])

  return (
    <AccordionItemPanel css={accordionItemPanelStyles}>
      {loading ? (
        <PageLoading message='Carregando evoluções...' />
      ) : formProps?.isEditing(item.id) ? (
        formProps?.renderFormBody(item)
      ) : (
        item.evolucoes?.map((evolucao, index, array) => (
          <div
            key={evolucao.id}
            css={css`
              margin: 0.5rem 1rem;
            `}
          >
            {renderDetailBody({
              item,
              evolucao,
              nextEvolucao: index + 1 < array.length ? array[index + 1] : null,
            })}
          </div>
        ))
      )}
    </AccordionItemPanel>
  )
}

const createStyles = (theme: Theme, willShowDetailBody: boolean) => ({
  accordionItemPanelStyles: css`
    flex: 1;
    background-color: ${theme.pallete.surface.main};
    ${willShowDetailBody && 'max-height: 20rem;'}
    overflow: auto;
    & > :not(:last-child) {
      border-bottom: 1px solid ${theme.pallete.gray.c80};
    }
  `,
})
