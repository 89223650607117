import { LembreteModel } from 'view/atendimentos/detail/soap/aside/types/LembreteModel'

import { LembreteFormModel } from './components/LembreteForm'

export function convertLembreteModel(lembreteModel: LembreteModel): LembreteFormModel {
  const ultimaEvolucao = lembreteModel.historicoLembrete.first()
  const lembrete: LembreteFormModel = {
    _id: lembreteModel._id,
    lembreteId: lembreteModel.id,
    visibilidadeLembrete: ultimaEvolucao.visibilidadeLembrete,
    descricao: ultimaEvolucao.descricao,
    data: ultimaEvolucao.data,
    ativo: ultimaEvolucao.ativo,
  }
  return lembrete
}
