import useSession from 'components/auth/useSession'
import { useSessionStorageState } from 'hooks/useStorage'
import { Dispatch, SetStateAction, useCallback } from 'react'

export interface JustificativaVerProntuario {
  id: ID
  basePath: string
  cidadaoId: ID
}

export function useJustificativaVerProntuarioId(
  basePath: string,
  cidadaoId: ID
): [ID | null, Dispatch<SetStateAction<ID>>, Dispatch<void>] {
  const {
    data: { acesso },
  } = useSession()

  const [justificativa, setStorageJustificativa, deleteStorageJustificativa] = useSessionStorageState<
    JustificativaVerProntuario
  >(`${acesso.id}/justificativa-ver-prontuario`)

  const setJustificativaId = useCallback((id: ID) => setStorageJustificativa({ id, basePath, cidadaoId }), [
    basePath,
    cidadaoId,
    setStorageJustificativa,
  ])

  const deleteJustificativa = useCallback(() => deleteStorageJustificativa(), [deleteStorageJustificativa])

  return [justificativa?.id, setJustificativaId, deleteJustificativa]
}
