/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, ButtonProps, Tooltip } from 'bold-ui'

interface GerarPrescricaoDigitalButtonProps extends Omit<ButtonProps, 'disabled'> {
  isGerandoPrescricao: boolean
  hasEmail: boolean
  isEditingEmail: boolean
  medicamentosSelectedLength: number
}

export function GerarPrescricaoDigitalButton(props: GerarPrescricaoDigitalButtonProps) {
  const { isEditingEmail, hasEmail, medicamentosSelectedLength, isGerandoPrescricao, ...rest } = props

  const { disabled, tooltip } = getStatusGerarButton(hasEmail, isEditingEmail, medicamentosSelectedLength)

  return (
    <Tooltip text={tooltip}>
      <Button
        kind='primary'
        loading={isGerandoPrescricao}
        disabled={disabled}
        {...rest}
        data-cy='GerarPrescricaoButton'
      >
        Gerar
      </Button>
    </Tooltip>
  )
}

const getStatusGerarButton = (
  hasEmail: boolean,
  isEditingEmail: boolean,
  medicamentosSelectedLength: number
): {
  disabled: boolean
  tooltip: string
} => {
  if (medicamentosSelectedLength === 0)
    return {
      disabled: true,
      tooltip: 'Nenhum medicamento selecionado',
    }
  if (!hasEmail)
    return {
      disabled: true,
      tooltip: 'E-mail não cadastrado',
    }
  if (isEditingEmail)
    return {
      disabled: true,
      tooltip: 'E-mail não atualizado',
    }

  return { disabled: false, tooltip: '' }
}
