import { gray, green, red } from 'bold-ui/lib/styles/colors'
import {
  Chart,
  ChartBody,
  ChartContainer,
  ChartFooter,
  DateRange,
  getDomainPoints,
  ReferenceArea,
  ValueRange,
} from 'components/chart'
import moment, { Moment } from 'moment'
import React from 'react'
import { calculateIdadeGestacionalParaGraficos } from 'util/atendimento/gestante/calculateIdadeGestacional'
import { calculatePercentilAlturaUterina } from 'util/atendimento/gestante/calculatePercentilAlturaUterina'
import { reduceMedicoesIguais } from 'util/atendimento/reduceMedicoesIguais'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { LinhaTooltipGrafico } from '../../../LinhaTooltipGrafico'
import { PeriodoGestacaoModel } from '../../types/PeriodoGestacaoModel'
import { TickSemanaGestacional } from './TickSemanaGestacional'

export interface GraficoAlturaUterinaProps {
  medicoes: HistoricoMedicaoModel[]
  gestacao: PeriodoGestacaoModel
}

export function GraficoAlturaUterinaView(props: GraficoAlturaUterinaProps) {
  const { medicoes, gestacao } = props

  const medicoesComAlturaUterina =
    reduceMedicoesIguais(
      medicoes.filter((m) => m.valorAlturaUterina),
      'valorAlturaUterina'
    ) ?? []
  const seriesData = medicoesComAlturaUterina.map((m) => ({
    x: m.dataMedicao,
    y: m.valorAlturaUterina,
  }))
  const yRange: ValueRange = { init: 7, end: 39, step: 2 }
  const xRange: DateRange = {
    init: moment(gestacao.inicio).add(13, 'weeks'),
    end: moment(gestacao.inicio).add(41, 'weeks'),
    step: { amount: 2, unit: 'weeks' },
  }

  return (
    <ChartContainer>
      <ChartBody height={500}>
        <Chart<Moment>
          series={[{ name: 'Altura uterina', color: gray.c20, data: seriesData }]}
          referenceAreas={getReferenceAreas(xRange, gestacao)}
          xAxis={{
            title: 'Idade gestacional (semanas)',
            domain: xRange,
            tickRenderer: (props) => <TickSemanaGestacional {...props} dataInicioGestacao={moment(gestacao.inicio)} />,
          }}
          yAxis={{ title: 'Altura uterina', unit: 'cm', domain: yRange }}
          showLegend={false}
          tooltip={{
            type: 'point',
            render: (points) => {
              if (!points?.length) return
              const momentX = moment(points[0].x)
              const { idadeGestacional, hasGestacaoPeriodo } = calculateIdadeGestacionalParaGraficos(
                [gestacao],
                momentX
              )
              return (
                <>
                  {points?.map((p) => (
                    <LinhaTooltipGrafico key={p.seriesName}>{`AU: ${p.y} cm`}</LinhaTooltipGrafico>
                  ))}
                  {hasGestacaoPeriodo && (
                    <LinhaTooltipGrafico>
                      {`IG: ${idadeGestacional} ${idadeGestacional === 1 ? 'semana' : 'semanas'}`}
                    </LinhaTooltipGrafico>
                  )}
                  <LinhaTooltipGrafico>{`Data: ${momentX.format('DD/MM/YYYY')}`}</LinhaTooltipGrafico>
                </>
              )
            },
          }}
        />
      </ChartBody>
      <ChartFooter>Fonte: Caderneta da Gestante - 2018</ChartFooter>
    </ChartContainer>
  )
}

function getReferenceAreas(xRange: DateRange, gestacao: PeriodoGestacaoModel): ReferenceArea<Moment>[] {
  return [
    getReferenceAreaAbaixoEsperado(xRange, gestacao),
    getReferenceAreaDentroEsperado(xRange, gestacao),
    getReferenceAreaAcimaEsperado(xRange, gestacao),
  ]
}

function getReferenceAreaAbaixoEsperado(xRange: DateRange, gestacao: PeriodoGestacaoModel) {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit: getReferenceAreaUpperLimit('baixo', date, moment(gestacao.inicio)),
    })),
    name: 'Abaixo do esperado',
    color: red.c90,
    strokeColor: red.c60,
    tickColor: red.c60,
  }
}

function getReferenceAreaDentroEsperado(xRange: DateRange, gestacao: PeriodoGestacaoModel) {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit: getReferenceAreaUpperLimit('adequado', date, moment(gestacao.inicio)),
    })),
    name: 'Dentro do esperado',
    color: green.c90,
    strokeColor: red.c60,
    tickColor: green.c60,
  }
}

function getReferenceAreaAcimaEsperado(xRange: DateRange, gestacao: PeriodoGestacaoModel) {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit: getReferenceAreaUpperLimit('alto', date, moment(gestacao.inicio)),
    })),
    name: 'Acima do esperado',
    color: red.c90,
    stroke: false,
    tickColor: red.c60,
  }
}

function getReferenceAreaUpperLimit(
  tipo: 'baixo' | 'adequado' | 'alto',
  date: Moment,
  dataInicioGestacao: Moment
): number {
  const semanasGestacao = Math.round(moment(date).diff(moment(dataInicioGestacao), 'week'))
  if (tipo === 'baixo') return calculatePercentilAlturaUterina(semanasGestacao, 10)
  else if (tipo === 'adequado') return calculatePercentilAlturaUterina(semanasGestacao, 90)
}
