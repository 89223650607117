import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { RelacaoCidadaoCuidador } from 'graphql/types.generated'
import React from 'react'

export interface RelacaoCidadaoCuidadorSelectFieldProps
  extends Omit<SelectFieldProps<string>, 'items' | 'itemToString'> {}

export type RelacaoCidadaoCuidadorModel = string

export function RelacaoCidadaoCuidadorSelectField(props: RelacaoCidadaoCuidadorSelectFieldProps) {
  const itemToString = (item: RelacaoCidadaoCuidador) => item && descriptions[item]

  return <SelectField<RelacaoCidadaoCuidadorModel> items={items} itemToString={itemToString} {...props} />
}

export const relacaoCuidadorDescriptionByEnumType = (item) => descriptions[item]

const items = [
  RelacaoCidadaoCuidador.NAO_POSSUI,
  RelacaoCidadaoCuidador.CONJUGE_COMPANHEIRO,
  RelacaoCidadaoCuidador.FILHO_ENTEADO,
  RelacaoCidadaoCuidador.PAI_MAE,
  RelacaoCidadaoCuidador.AVO,
  RelacaoCidadaoCuidador.NETO,
  RelacaoCidadaoCuidador.IRMAO,
  RelacaoCidadaoCuidador.OUTRO,
]

const descriptions = {
  NAO_POSSUI: 'Não possui',
  CONJUGE_COMPANHEIRO: 'Cônjuge / Companheiro(a)',
  FILHO_ENTEADO: 'Filho(a) / Enteado(a)',
  PAI_MAE: 'Pai / Mãe',
  AVO: 'Avô / Avó',
  NETO: 'Neto(a)',
  IRMAO: 'Irmão(ã)',
  OUTRO: 'Outro',
}
