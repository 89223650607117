import React from 'react'

import { ErrorGroup, ErrorItem, ErrorsBox, ValidationErrorsField } from './ErrorsBox'
import { ProfissionalCnesModel, ProfissionalDetalhesImportacaoModel, ValidacaoModel } from './model'

interface ProfissionaisErrorsBoxProps {
  profissionais: ProfissionalDetalhesImportacaoModel[]
}

export const ProfissionaisErrorsBox = (props: ProfissionaisErrorsBoxProps) => {
  const { profissionais } = props

  const profissionaisComErro = profissionais.filter((profissional) => profissional.validacoes)

  if (profissionaisComErro.length === 0) {
    return null
  }
  return (
    <ErrorsBox title='Profissionais'>
      <ErrorGroup title='Faltam dados obrigatórios'>
        {profissionaisComErro
          .filter((profissional) => profissional.validacoes.faltamDadosObrigatorios.length > 0)
          .map((profissional, index) => (
            <ProfissionalErrorItem
              key={index}
              profissional={profissional.dados}
              errors={profissional.validacoes.faltamDadosObrigatorios}
            />
          ))}
      </ErrorGroup>
      <ErrorGroup title='Campos preenchidos de forma incorreta'>
        {profissionaisComErro
          .filter((profissional) => profissional.validacoes.camposPreenchidosIncorretos.length > 0)
          .map((profissional, index) => (
            <ProfissionalErrorItem
              key={index}
              profissional={profissional.dados}
              errors={profissional.validacoes.camposPreenchidosIncorretos}
            />
          ))}
      </ErrorGroup>
    </ErrorsBox>
  )
}

interface ProfissionalErrorItemProps {
  profissional: ProfissionalCnesModel
  errors: ValidacaoModel[]
}

const ProfissionalErrorItem = (props: ProfissionalErrorItemProps) => {
  const { profissional, errors } = props
  return (
    <ErrorItem>
      {profissional.nome || 'Nome não informado'}, CPF: {profissional.cpf || '-'}, CNS: {profissional.cns || '-'}
      {errors && <ValidationErrorsField errors={errors} />}
    </ErrorItem>
  )
}
