import { ExternalStyles } from 'bold-ui'
import { Agendado, OrigemAgendamentoEnum } from 'graphql/types.generated'
import React from 'react'

import { situacaoSincronizacaoAgendaOnlineRecord } from '../../model-agenda'
import { StatusSincronizacaoAgendaOnline } from '../listagem/StatusSincronizacaoAgendaOnline'
import { AgendamentoCompartilhadoMarker } from './AgendamentoCompartilhadoMarker'
import { AgendaOnlineMarker } from './AgendaOnlineMarker'

export interface AgendamentoMarkerProps extends Pick<Agendado, 'origem' | 'situacaoSincronizacao'> {
  isAgendaOnlineAtiva: boolean
  isAgendamentoCompartilhado?: boolean
  styles?: ExternalStyles
  defaultIcon?: JSX.Element
}

export function AgendamentoMarker(props: AgendamentoMarkerProps) {
  const {
    origem,
    situacaoSincronizacao,
    isAgendaOnlineAtiva,
    isAgendamentoCompartilhado = false,
    styles,
    defaultIcon = null,
  } = props

  const isAgendadoPeloCidadao = origem === OrigemAgendamentoEnum.MEUDIGISUS

  const { mostrarMinimizado, ...syncStatusProps } = situacaoSincronizacaoAgendaOnlineRecord[situacaoSincronizacao] || {}

  const showIconeStatusSincronizacao = isAgendaOnlineAtiva && mostrarMinimizado

  return isAgendadoPeloCidadao ? (
    <AgendaOnlineMarker styles={styles} />
  ) : showIconeStatusSincronizacao ? (
    <StatusSincronizacaoAgendaOnline {...syncStatusProps} styles={styles} />
  ) : isAgendamentoCompartilhado ? (
    <AgendamentoCompartilhadoMarker styles={styles} />
  ) : (
    defaultIcon
  )
}
