/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text, VFlow } from 'bold-ui'
import {
  AntecedenteCirurgiasInternacoes,
  AntecedenteInformacoesObstetricasDto,
  AntecedentePuericulturaDto,
} from 'graphql/types.generated'

import { HistoricoAntecedentesCirurgiasInternacoesTable } from './HistoricoAntecedentesCirurgiasInternacoesTable'
import { HistoricoAntecedentesInformacoesObstetricasTableBox } from './HistoricoAntecedentesInformacoesObstetricasTableBox'
import { HistoricoAntecedentesPuericulturaTableBox } from './HistoricoAntecedentesPuericulturaTableBox'

interface HistoricoAntecedentesPecPanelProps {
  observacoes: string
  puericultura: AntecedentePuericulturaDto
  informacoesObstetricas: AntecedenteInformacoesObstetricasDto
  cirurgiasInternacoes: AntecedenteCirurgiasInternacoes[]
  dataNascimento: LocalDate
}

export default function HistoricoAntecedentesPecPanel(props: HistoricoAntecedentesPecPanelProps) {
  const { observacoes, puericultura, informacoesObstetricas, cirurgiasInternacoes, dataNascimento } = props

  return (
    <VFlow>
      {observacoes && <Text>{observacoes}</Text>}

      {puericultura && <HistoricoAntecedentesPuericulturaTableBox puericultura={puericultura} />}

      {informacoesObstetricas && (
        <HistoricoAntecedentesInformacoesObstetricasTableBox informacoesObstetricas={informacoesObstetricas} />
      )}

      {cirurgiasInternacoes && (
        <HistoricoAntecedentesCirurgiasInternacoesTable
          cirurgiasInternacoes={cirurgiasInternacoes}
          dataNascimento={dataNascimento}
        />
      )}
    </VFlow>
  )
}
