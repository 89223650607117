import api from 'api'
import { AxiosRequestConfig } from 'axios'
import { Button, Heading, Link, Text, Tooltip, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { Form, FormFooter } from 'components/form'
import { ButtonLink } from 'components/route'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { FileUploaderComponent } from 'components/upload/FileUploaderComponent'
import { useConcluirConfiguracaoMunicipioMutation, useImportacoesCnesLazyQuery } from 'graphql/hooks.generated'
import { AutorizarMunicipioQuery, ImportacoesCnesQueryInput } from 'graphql/types.generated'
import React, { useEffect, useState } from 'react'
import { ImportacaoCnesListener } from 'view/cnes/ImportacaoCnesListener'
import { ImportacaoCnesTable } from 'view/cnes/ImportacaoCnesTable'
import { DownloadInfo } from 'view/cnes/ImportacaoCnesView'

export interface AutorizarMunicipioImportarCnesFormProps {
  isTreinamento: boolean
  municipio: AutorizarMunicipioQuery['acesso']['municipio']
  isProntuario: boolean
}

export const AutorizarMunicipioImportarCnesForm = (props: AutorizarMunicipioImportarCnesFormProps) => {
  const { municipio, isProntuario } = props
  const [concluirConfiguracao] = useConcluirConfiguracaoMunicipioMutation()
  const handleRejection = useErrorHandler()

  const [filter, setFilter] = useState<ImportacoesCnesQueryInput>({
    pageParams: {
      page: 0,
      sort: ['-data'],
    },
  })
  const [executeQuery, { data, loading, refetch }] = useImportacoesCnesLazyQuery()

  useEffect(() => {
    if (municipio) {
      executeQuery({
        variables: {
          input: {
            ...filter,
            municipioId: parseInt(municipio.id),
          },
        },
      })
    }
  }, [filter, executeQuery, municipio])

  const importacoesCnes = data && data.importacoesCnes
  const tableProps = usePagedTableProps({
    loading,
    onChange: setFilter,
    result: municipio && importacoesCnes,
  })

  const upload = (formData: FormData, config?: AxiosRequestConfig) => {
    return api.cnes.importar(formData, municipio && municipio.id, config).catch(handleRejection)
  }

  const handleSubmit = () => {
    return concluirConfiguracao()
      .then((success) => {
        alert('success', 'Ativação da instalação no município foi finalizada com sucesso.')
      })
      .catch(handleRejection)
  }

  const downloadInfo = (
    <Text>
      Prontuário Eletrônico do Cidadão - Versão 3.0.00. Busque o arquivo XML mais atual a ser obtido no{' '}
      <Link href='http://cnes.datasus.gov.br/' target='_blank'>
        Portal do CNES
      </Link>
      {'. '}
      Para saber como obter o arquivo compatível com essa versão, acesse o{' '}
      <Link target='_blank' href='https://sisaps.saude.gov.br/esus/'>
        Portal da SAPS
      </Link>
      .
    </Text>
  )

  const formRender = (formProps) => (
    <>
      <VFlow>
        <Heading level={2}>
          {municipio && <ImportacaoCnesListener onImportacaoMessage={refetch} municipio={municipio.id} />}
          {municipio.nome}-{municipio.uf.sigla}
        </Heading>
        <VFlow vSpacing={0}>
          <Tooltip text={municipio ? '' : 'Selecione um município para importar o CNES'} placement='auto'>
            <div>
              <FileUploaderComponent uploadRequest={upload} disabled={!municipio} />
            </div>
          </Tooltip>
          <DownloadInfo text={downloadInfo} />
        </VFlow>
        <Heading level={2} style={{ marginTop: '2rem' }}>
          Histórico de importações
        </Heading>
        <ImportacaoCnesTable {...tableProps} filter={filter} onFilterChange={setFilter} wizardInstalacao={true} />
      </VFlow>
      <FormFooter>
        <ButtonLink to={isProntuario ? '/autorizar/agenda' : '/autorizar/ativar'}>Voltar</ButtonLink>
        <Button kind='primary' onClick={formProps.handleSubmit}>
          Finalizar
        </Button>
      </FormFooter>
    </>
  )

  return <Form render={formRender} onSubmit={handleSubmit} />
}
