import { Text, VFlow } from 'bold-ui'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb'
import { SEM_EQUIPE_ITEM } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { useTerritorioMicroareaQuery, useVisualizacaoTerritorialQuery } from 'graphql/hooks.generated'
import { PageParams } from 'graphql/types.generated'
import React, { useRef, useState } from 'react'
import { v4 } from 'uuid'

import { MicroareaLogradouroPagedList } from './components/list/MicroareaLogradouroPagedList'
import VisualizacaoTerritorialMicroareaTabs from './components/VisualizacaoTerritorialMicroareaTabs'
import { convertVisualizacaoFormModelToInput } from './converter'
import { useMicroareasVisualizacao } from './hooks/useMicroareasVisualizacao'
import { useVisualizacaoCache } from './hooks/useVisualizacaoCache'
import {
  TerritorioModel,
  VisualizacaoFilterModel,
  VisualizacaoTerritorialBuscaFormModel,
  VisualizacaoTerritorialMicroareasFilterModel,
} from './model'
import { VisualizacaoTerritorialBuscaForm } from './VisualizacaoTerritorialBuscaForm'

export function VisualizacaoTerritorialView() {
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const unidadeSaude = acesso?.unidadeSaude
  const equipe = acesso?.equipe

  const territorioEquipe = {
    cnes: unidadeSaude.cnes,
    ine: (equipe ?? SEM_EQUIPE_ITEM)?.ine,
  }

  const progressToken = useRef(v4())

  const {
    visualizacaoCacheState,
    setVisualizacaoCacheState,
    deleteVisualizacaoCacheState,
    renderCache,
  } = useVisualizacaoCache(acesso?.id)

  const [visualizacaoFilter, setVisualizacaoFilter] = useState<VisualizacaoFilterModel>(
    renderCache ? visualizacaoCacheState.visualizacaoFilter : { territorioEquipeFilter: territorioEquipe }
  )

  const { data } = useVisualizacaoTerritorialQuery({
    variables: {
      input: {
        ...convertVisualizacaoFormModelToInput(visualizacaoFilter.territorioEquipeFilter),
      },
    },
  })

  const hasLogradouros = data.territorioEquipe?.microareas.isNotEmpty()

  const { handleAccordionItemClick, isExpanded, resetExpandedItems, expandedItems } = useAccordionControl({
    allowMultipleExpanded: true,
    initialExpandedItems: renderCache ? visualizacaoCacheState?.expandedItems : [],
  })

  const { currentMicroareaTab, handleMicroareaTabClick, setRenderMicroareaControl } = useMicroareasVisualizacao(
    visualizacaoCacheState?.currentMicroareaTab,
    data?.territorioEquipe?.microareas,
    renderCache,
    resetExpandedItems
  )
  const [pageParams, setPageParams] = useState<{ pageParams: PageParams }>(
    renderCache ? { pageParams: visualizacaoCacheState?.pageParams } : initialPageParams
  )

  const handleStopRenderMicroareaCache = () => {
    resetExpandedItems()
    setRenderMicroareaControl((state) => ({ ...state, renderMicroareaCache: false }))
  }

  const onFiltrarMicroareas = (formValues: VisualizacaoTerritorialMicroareasFilterModel) => {
    handleStopRenderMicroareaCache()
    setVisualizacaoFilter((state) => ({
      ...state,
      territorioMicroareasFilter: {
        ...formValues,
        isCadastroAtualizadoMenosDeUmAno: formValues?.isCadastroAtualizadoMenosDeUmAno?.atualizadoHaMenosDeUmAno,
        isCadastroCompleto: formValues?.isCadastroCompleto?.isCadastroCompleto,
        isImovelVisitadoMenosDeUmMes: formValues?.isImovelVisitadoMenosDeUmMes?.ultimaVisitaHaMenosDeUmMes,
      },
    }))
  }

  const onBuscarTerritorioEquipe = (formValues: VisualizacaoTerritorialBuscaFormModel) => {
    handleStopRenderMicroareaCache()
    setVisualizacaoFilter((state) => ({ ...state, territorioEquipeFilter: { ...formValues } }))
  }

  const saveVisualizacaoCacheState = (modalImovelId?: ID) =>
    setVisualizacaoCacheState({
      visualizacaoFilter,
      currentMicroareaTab,
      pageParams: pageParams.pageParams,
      modalImovelId,
      expandedItems,
    })

  const microareaInput = {
    cnes: unidadeSaude.cnes,
    ine: equipe?.ine,
    ...convertVisualizacaoFormModelToInput(visualizacaoFilter.territorioEquipeFilter),
    ...visualizacaoFilter?.territorioMicroareasFilter,
    microarea: currentMicroareaTab,
    pageParams: pageParams.pageParams,
  }

  const { data: territorioMicroareaData, loading, refetch } = useTerritorioMicroareaQuery({
    variables: {
      input: microareaInput,
    },
    skip: currentMicroareaTab === undefined,
    fetchPolicy: 'network-only',
  })

  const handleRefreshMicroarea = () => {
    refetch({ input: microareaInput })
  }

  const territorioModel: TerritorioModel = {
    cnes: unidadeSaude.cnes,
    ine: equipe?.ine,
    microarea: currentMicroareaTab,
    ...visualizacaoFilter?.territorioMicroareasFilter,
    ...convertVisualizacaoFormModelToInput(visualizacaoFilter.territorioEquipeFilter),
  }

  return (
    <>
      <Breadcrumb title='Território' />

      <PageHeaderSection title='Território' />

      <PageContent type='filled'>
        <VFlow vSpacing={3}>
          <VisualizacaoTerritorialBuscaForm
            onSubmit={onBuscarTerritorioEquipe}
            unidadeSaude={unidadeSaude}
            equipe={equipe}
            initialValues={visualizacaoFilter.territorioEquipeFilter}
          />

          {hasLogradouros ? (
            <VisualizacaoTerritorialMicroareaTabs
              microareas={data?.territorioEquipe.microareas}
              currentMicroareaTab={currentMicroareaTab}
              onTabClick={handleMicroareaTabClick}
            />
          ) : (
            <Text fontWeight='normal' variant='h4' fontStyle='italic'>
              Nenhum imóvel encontrado. Tente novamente alterando os filtros ou parâmetros de busca.
            </Text>
          )}
        </VFlow>
        {loading ? (
          <LoadingIndicator />
        ) : (
          currentMicroareaTab &&
          hasLogradouros && (
            <MicroareaLogradouroPagedList
              progressToken={progressToken.current}
              logradouros={territorioMicroareaData?.territorioMicroarea}
              territorio={territorioModel}
              onAccordionItemClick={handleAccordionItemClick}
              isExpanded={isExpanded}
              resetExpandedItems={resetExpandedItems}
              onFiltrarMicroareas={onFiltrarMicroareas}
              microareasFilter={visualizacaoFilter?.territorioMicroareasFilter}
              lotacaoId={acesso?.id}
              municipioId={acesso?.municipio.id}
              profissionalId={profissional?.id}
              saveVisualizacaoCacheState={saveVisualizacaoCacheState}
              cachedImovelId={renderCache ? visualizacaoCacheState?.modalImovelId : null}
              onChangePageParams={setPageParams}
              deleteVisualizacaoCacheState={deleteVisualizacaoCacheState}
              onRefreshMicroarea={handleRefreshMicroarea}
            />
          )
        )}
      </PageContent>
    </>
  )
}

const initialPageParams = { pageParams: { page: 0, size: 10 } }
