import { PrintText, PrintTitle, SpaceLine } from 'components/print'
import { PrintRichText } from 'components/print/components/PrintRichText'
import React from 'react'
import { humanizeAge } from 'util/date/humanize-age'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { OrientacaoRowModel } from '../types/OrientacoesModel'

export interface ImpressaoOrientacoesProps extends OrientacaoRowModel {
  cidadao: CidadaoAtendimento
}

export const ImpressaoOrientacoes = (props: ImpressaoOrientacoesProps) => {
  const cidadaoNome =
    '<b>' +
    (props.cidadao.nomeSocial ? props.cidadao.nomeSocial + ' (' + props.cidadao.nome + ')' : props.cidadao.nome) +
    '</b>'
  const cpf = props.cidadao.cpf ? '<b>CPF: </b>' + props.cidadao.cpf + '    ' : ''
  const cns = props.cidadao.cns ? '<b>CNS: </b>' + props.cidadao.cns : ''
  const idade = humanizeAge(props.cidadao.dataNascimento)
  return (
    <>
      <PrintTitle title='ORIENTAÇÕES' level={3} />

      <PrintTitle title='CIDADÃO' level={4} underline align='left' />
      <PrintRichText content={cidadaoNome} />
      {(props.cidadao.cpf || props.cidadao.cns) && <PrintRichText content={cpf + cns}></PrintRichText>}
      <PrintText>{idade} no dia deste atendimento</PrintText>
      <SpaceLine />

      <PrintTitle title='ORIENTAÇÕES' level={4} underline align='left' />
      <SpaceLine />
      <PrintRichText content={props.descricao} />
    </>
  )
}
