import { HFlow, Text } from 'bold-ui'
import { SelectField } from 'components/form/final-form'
import { ProfissionaisRelatorioInconsistenciaDocument } from 'graphql/hooks.generated'
import {
  ProfissionaisRelatorioInconsistenciaQuery,
  ProfissionaisRelatorioInconsistenciaQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { Cpf } from '../../../../label'
import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type ProfissionaisRelatorioInconsistenciaSelectModel = ProfissionaisRelatorioInconsistenciaQuery['profissionaisRelatorioInconsistencia']['content'][0]

const extractItems = (data: ProfissionaisRelatorioInconsistenciaQuery) =>
  data && data.profissionaisRelatorioInconsistencia && data.profissionaisRelatorioInconsistencia.content
const itemToString = (item: ProfissionaisRelatorioInconsistenciaSelectModel) => item && item.responsavel
const renderItem = (item: ProfissionaisRelatorioInconsistenciaSelectModel) => (
  <>
    <Text component='p' fontWeight='bold'>
      {item.responsavel}
    </Text>
    <HFlow hSpacing={0.25}>
      <Text fontWeight='bold'>CPF</Text>
      <Text>
        <Cpf value={item.cpfResponsavel} />
      </Text>
    </HFlow>
  </>
)

const pageParams = {
  ...DEFAULT_SELECT_PAGE_PARAM,
  size: 10,
  sort: ['nome'],
}

export interface ProfissionalSelectFieldProps
  extends AsyncSelectFieldProps<ProfissionaisRelatorioInconsistenciaSelectModel> {
  dataInicio: string
  dataFim: string
}
export function ProfissionaisRelatorioInconsistenciaSelectField(props: ProfissionalSelectFieldProps) {
  const { dataInicio, dataFim, ...rest } = props

  const variables = (inputQuery: string): ProfissionaisRelatorioInconsistenciaQueryVariables => ({
    filtro: {
      dataInicio,
      dataFim,
      nomeProfissional: inputQuery,
      pageParams,
    },
  })

  const { selectProps } = useAsyncQuerySelect<
    ProfissionaisRelatorioInconsistenciaSelectModel,
    ProfissionaisRelatorioInconsistenciaQuery,
    ProfissionaisRelatorioInconsistenciaQueryVariables
  >({
    query: ProfissionaisRelatorioInconsistenciaDocument,
    extractItems,
    variables,
    debounceTime: 500,
  })

  return <SelectField itemToString={itemToString} renderItem={renderItem} {...selectProps} {...rest} />
}
