import { HFlow, Text, VFlow } from 'bold-ui'
import React from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { LabelEvolucaoCuidadoCompartilhadoRecord } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CuidadoCompartilhadoEvolucaoDetalhes } from '../HistoricoCuidadoCompartilhadoPanel'

interface HistoricoCuidadoCompartilhadoAgendamentoProps
  extends Pick<CuidadoCompartilhadoEvolucaoDetalhes, 'agenda' | 'conduta'> {}

export function HistoricoCuidadoCompartilhadoAgendamento(props: HistoricoCuidadoCompartilhadoAgendamentoProps) {
  const { conduta, agenda } = props

  return (
    <VFlow vSpacing={0.1}>
      <Text fontWeight='bold'>{LabelEvolucaoCuidadoCompartilhadoRecord[conduta]}</Text>
      <Text>Agendado para {formatDateAndHoursMinutes(agenda?.horarioInicial)}.</Text>
      {agenda?.observacao && (
        <HFlow hSpacing={0.25}>
          <Text fontWeight='bold'>Observações: </Text>
          <Text dangerouslySetInnerHTML={{ __html: agenda?.observacao }} />
        </HFlow>
      )}
    </VFlow>
  )
}
