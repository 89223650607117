/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tag } from 'bold-ui'
import { SituacaoProblema } from 'graphql/types.generated'

import { situacaoProblemaRecord } from '../soap/aside/types/ProblemaModel'

export function SituacaoProblemaTag(props: { situacao: SituacaoProblema }) {
  const situacaoProblema = situacaoProblemaRecord[props.situacao]
  return (
    <Tag
      style={css`
        color: ${situacaoProblema.color};
        background: ${situacaoProblema.background};
      `}
    >
      {situacaoProblema.descricao}
    </Tag>
  )
}
