/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Table, TableBody, TableCell, TableRow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { green } from 'bold-ui/lib/styles/colors'
import { Box } from 'components/Box'
import { InfoBox } from 'components/InfoBox'
import { LoadingIndicator } from 'components/loading'
import { TableBox } from 'components/table'
import { useHistoricoAtendimentoDomiciliarDwQuery } from 'graphql/hooks.generated'
import { memo, useMemo } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import { isEmpty } from 'util/validation/Util'
import { HistoricoDwPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'
import {
  mountInfosAdministrativasAtendimentoCompartilhado,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/utils-historico'

import HistoricoSection from '../../../../HistoricoSection'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'

interface HistoricoAtendimentoDomiciliarDwPanelProps extends HistoricoDwPanelProps {}

function HistoricoAtendimentoDomiciliarDwPanel({
  uuidRegistro,
  cpfOuCnsCidadao,
  idadeOrigemAtendimento,
}: HistoricoAtendimentoDomiciliarDwPanelProps) {
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])
  const {
    data: { historicoAtendimentoDomiciliar: detalhamento },
    loading,
  } = useHistoricoAtendimentoDomiciliarDwQuery({
    variables: {
      input: {
        uuidRegistro,
        cpfOuCnsCidadao,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!detalhamento) return <HistoricoNoDetailAvailable />

  const condicoesAvaliadasCiaps = detalhamento.fatosAtendDomCondicaoAval
    ?.map((condAval) => condAval.dimensaoCiap2)
    .filterNotNull()

  const condicoesAvaliadasCids = detalhamento.fatosAtendDomCondicaoAval
    ?.map((condAval) => condAval.dimensaoCid10)
    .filterNotNull()

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentoCompartilhado(detalhamento)}
    >
      <VFlow>
        <VFlow vSpacing={0.5}>
          <Heading level={3}>Modalidade</Heading>
          <Text>{detalhamento.dimensaoModalidadeAD.descricao}</Text>
        </VFlow>
        {!isEmpty(condicoesAvaliadasCiaps) && (
          <VFlow vSpacing={0.5}>
            <InfoBox title='CIAP 2'>
              {condicoesAvaliadasCiaps.map((condicaoAvaliada) => (
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>{`${condicaoAvaliada?.nomeCiap} - ${condicaoAvaliada?.codigoCiap}`}</Text>
                  <Text>(CIAP 2)</Text>
                </HFlow>
              ))}
            </InfoBox>
          </VFlow>
        )}
        {!isEmpty(condicoesAvaliadasCids) && (
          <VFlow vSpacing={0.5}>
            <Heading level={3}>CID 10</Heading>
            <Box>
              {condicoesAvaliadasCids.map((condicaoAvaliada) => (
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>{`${condicaoAvaliada?.nomeCid10} - ${condicaoAvaliada?.codigoCid10}`}</Text>
                  <Text>(CID 10)</Text>
                </HFlow>
              ))}
            </Box>
          </VFlow>
        )}
        {!isEmpty(detalhamento.condicoesAvaliadas) && (
          <VFlow vSpacing={0.5}>
            <Heading level={3}>Condições avaliadas</Heading>
            <Text>{stringGrouper(...detalhamento.condicoesAvaliadas)}</Text>
          </VFlow>
        )}
        <VFlow vSpacing={0.5}>
          <Heading level={3}>Conduta/Desfecho</Heading>
          <Text>{detalhamento.dimensaoCondutaAD.conduta}</Text>
        </VFlow>
        <HistoricoSection section='P'>
          {detalhamento.procedimentos ? (
            <VFlow vSpacing={0.5}>
              <Heading level={5} style={styles.procedimentoHeading}>
                Procedimentos
              </Heading>
              <TableBox>
                <Table>
                  <TableBody>
                    {detalhamento.procedimentos?.map((procedimento) => (
                      <TableRow key={procedimento.id} css={styles.tableRow}>
                        <TableCell>
                          <Text>{`${procedimento.dimensaoProcedimento.descricaoProcedimento} - ${procedimento.dimensaoProcedimento.codigoProcedimento}`}</Text>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableBox>
            </VFlow>
          ) : (
            <Text>{naoRegistradoNesseAtendimento}</Text>
          )}
        </HistoricoSection>
      </VFlow>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoAtendimentoDomiciliarDwPanel)

const createStyles = (theme: Theme) => ({
  tableRow: css`
    padding: 0.5rem 1rem;
    &:hover {
      background-color: ${theme.pallete.surface.background};
    }
  `,
  procedimentoHeading: css`
    color: ${green.c40};
  `,
})
