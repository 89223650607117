import { HFlow } from 'bold-ui'
import { Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { FormState } from 'final-form'
import { VinculacaoServicosInput } from 'graphql/types.generated'
import React from 'react'
import { metaPath } from 'util/metaPath'

interface VinculacoesEstabelecimentosTableFilterProps {
  initialValues?: VinculacaoServicosInput
  onChange(values: VinculacaoServicosInput): void
}

const meta = metaPath<VinculacaoServicosInput>()

export function VinculacoesEstabelecimentosTableFilter(props: VinculacoesEstabelecimentosTableFilterProps) {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<VinculacaoServicosInput>) => {
    onChange(formState.values)
  }

  const renderForm = () => (
    <HFlow justifyContent='space-between'>
      <FormDebouncedValueSpy onChange={handleChange} />
      <TextField
        name={meta.query}
        icon='zoomOutline'
        placeholder='Pesquise por unidades de saúde'
        style={{ width: '19rem' }}
      />
    </HFlow>
  )

  return <Form<VinculacaoServicosInput> onSubmit={onChange} render={renderForm} initialValues={initialValues} />
}
