import { useEffect, useState } from 'react'

/**
 * Returns whether the capslock button is active or not.
 * It must have occurred a keydown event to set the state correctly.
 */
export const useCapslockState = () => {
  const [isCapsOn, setIsCapsOn] = useState(false)

  useEffect(() => {
    const functionEvent = (e: KeyboardEvent) => {
      if (e.which === 20) {
        setIsCapsOn(!isCapsOn)
        return
      }
      setIsCapsOn(e.getModifierState && e.getModifierState('CapsLock'))
    }

    window.addEventListener('keydown', functionEvent)
    return () => window.removeEventListener('keydown', functionEvent)
  }, [isCapsOn])

  return isCapsOn
}
