import { Alert, Checkbox, FormControl, HFlow, Tag, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Interpolation } from 'emotion'
import { useCadsusHabilitadoInstalacaoQuery } from 'graphql/hooks.generated'
import React from 'react'

interface ConfiguracaoCertificadosDadosViewProps {
  nome: string
  dataHabilitacao: Date
  dataInclusao: Date
  dataValidadeCertificado: Date
  rndsHabilitado: boolean
  cadsusHabilitado: boolean
  error?: string[]
}

export function ConfiguracaoCertificadosDadosView({
  nome,
  dataHabilitacao,
  dataInclusao,
  dataValidadeCertificado,
  rndsHabilitado,
  cadsusHabilitado,
  error,
}: ConfiguracaoCertificadosDadosViewProps) {
  const wrapperStyles: Interpolation = {
    border: 'none',
    borderRadius: '0',
    background: 'none',
    padding: '0',
    minHeight: '0',
  }

  const { data } = useCadsusHabilitadoInstalacaoQuery()

  const statusTag = (isHabilitado: boolean) => {
    return isHabilitado ? (
      <Tag type='success' icon='checkCircleFilled'>
        Habilitado
      </Tag>
    ) : (
      <Tag type='normal' icon='banOutline'>
        Desabilitado
      </Tag>
    )
  }

  return (
    <VFlow vSpacing={2}>
      <HFlow alignItems='center' style={{ display: 'flex' }}>
        <Alert type='info' inline>
          Ao desabilitar o certificado digital, os serviços habilitados serão desativados. <br />A Rede Nacional de
          Dados em Saúde não será apresentada no sistema e o CADSUS será desabilitado.
        </Alert>

        <Text style={{ flexGrow: 1, textAlign: 'right' }}>
          {`Habilitado por ${nome} em `}
          <DateTime value={dataHabilitacao} />
        </Text>
      </HFlow>
      <FormControl label='Serviços'>
        <HFlow>
          <VFlow>
            <Checkbox label='RNDS' disabled={true} checked={rndsHabilitado} />
            <Checkbox label='CADSUS' disabled={true} checked={cadsusHabilitado} />
          </VFlow>
          <VFlow>
            {statusTag(rndsHabilitado)}
            {statusTag(cadsusHabilitado)}
          </VFlow>
        </HFlow>
      </FormControl>
      <VFlow>
        {!data.conexao?.cadsus.habilitado && cadsusHabilitado && (
          <Alert type='warning' inline>
            Atenção: O serviço do CADSUS está habilitado no certificado mas <b>desabilitado na instalação.</b> Entre em
            contato com o Administrador da Instalação.
          </Alert>
        )}
        {error && (
          <Alert type='danger' inline>
            {error.map((item, index) => {
              return (
                <span key={index}>
                  {item} <br />
                </span>
              )
            })}
          </Alert>
        )}
        <Text>
          {`Certificado incluído no servidor em `}
          <DateTime value={dataInclusao} />
        </Text>
        <Alert
          type='warning'
          inline
          styles={{
            wrapper: wrapperStyles,
          }}
        >
          {`Certificado válido até `}
          <DateTime value={dataValidadeCertificado} />
        </Alert>
      </VFlow>
    </VFlow>
  )
}
