import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import React from 'react'

import LoteImunobiologicoTable from './list/LoteImunobiologicoTable'

export default function LoteImunobiologicoView() {
  return (
    <>
      <Breadcrumb title='Lotes de imunobiológico' />
      <PageHeaderSection title='Lotes de imunobiológico' />
      <PageContent>
        <LoteImunobiologicoTable />
      </PageContent>
    </>
  )
}
