import { HeadingSection, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { DateTime } from 'components/date'
import { PageContent } from 'components/layout/PageContent'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useLoteEnvioQuery } from 'graphql/hooks.generated'
import { HistoricoExportacaoFiltro } from 'graphql/types.generated'
import { useRouter } from 'hooks/useRouter'
import React, { useState } from 'react'

import { TransmissaoEnvioHistoricoExportTable } from './detail/TransmissaoEnvioHistoricoExportTable'
import { TransmissaoEnvioItensDetailTable } from './detail/TransmissaoEnvioItensDetailTable'

export interface TransmissaoEnvioDetailProps {}
interface RouteParams {
  idLote: ID
}

export const TransmissaoEnvioDetail = (props: TransmissaoEnvioDetailProps) => {
  const [filterHistorico, setFilterHistorico] = useState<HistoricoExportacaoFiltro>({
    pageParams: {},
  } as HistoricoExportacaoFiltro)

  const {
    match: { params },
  } = useRouter<RouteParams>()

  const {
    loading,
    data: { loteEnvio },
  } = useLoteEnvioQuery({ variables: { idLote: params.idLote, filtroHistorico: filterHistorico } })

  const pagedHistoricoTableProps = usePagedTableProps({
    result: loteEnvio && loteEnvio.historicoExportacao,
    loading,
    onChange: setFilterHistorico,
  })

  return (
    <PageContent type='filled'>
      <VFlow style={{ marginTop: `2rem`, marginBottom: '2rem' }}>
        <Breadcrumb title='Envio' to='/transmissao/envio' />
        <Breadcrumb title='Visualizar lote' />
        {loteEnvio && (
          <VFlow vSpacing={2}>
            <HeadingSection level={1} title={`Lote ${loteEnvio.id}`}>
              <DateTime format='DD/MM/YYYY' value={loteEnvio.dataCriacao} /> - {loteEnvio.quantidadeFichas} fichas
            </HeadingSection>
            <HeadingSection level={2} title='Envio Online'>
              <TransmissaoEnvioItensDetailTable rows={loteEnvio.nodos} loading={loading} />
            </HeadingSection>
            <HeadingSection level={2} title='Arquivo salvo'>
              <TransmissaoEnvioHistoricoExportTable
                rows={loteEnvio.historicoExportacao.content}
                {...pagedHistoricoTableProps}
              />
            </HeadingSection>
          </VFlow>
        )}
      </VFlow>
    </PageContent>
  )
}
