import React from 'react'

import { TermosDeUsoHeadingSection } from '../../components/TermosDeUsoHeadingSection'

export const ResponsabilidadeDaAdministracaoPublicaSection = () => {
  return (
    <TermosDeUsoHeadingSection
      title='7. Responsabilidade da Administração Pública'
      id='responsabilidade_da_administracao_publica'
    >
      <p>
        <b>Quais são as responsabilidades da Administração Pública com os meus dados?</b>
      </p>
      <br />
      <p>
        A Administração Pública se compromete a cumprir todas as legislações inerentes ao uso correto dos dados pessoais
        do cidadão de forma a preservar a privacidade dos dados utilizados no serviço, bem como a garantir todos os
        direitos e garantias legais dos titulares dos dados. Ela também se obriga a promover, independentemente de
        requerimentos, a divulgação em local de fácil acesso, no âmbito de suas competências, de informações de
        interesse coletivo ou geral por eles produzidas ou custodiadas. É de responsabilidade da Administração Pública
        implementar controles de segurança para proteção dos dados pessoais dos titulares.
      </p>
      <br />
      <p>
        A Administração Pública poderá, quanto às ordens judiciais de pedido das informações, compartilhar informações
        necessárias para investigações ou tomar medidas relacionadas a atividades ilegais, suspeitas de fraude ou
        ameaças potenciais contra pessoas, bens ou sistemas que sustentam o Serviço ou de outra forma necessárias para
        cumprir com obrigações legais. Caso ocorra, a Administração Pública notificará os titulares dos dados, salvo
        quando o processo estiver em segredo de justiça.
      </p>
    </TermosDeUsoHeadingSection>
  )
}
