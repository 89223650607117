import { HFlow, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { CidadaoAtendimentoSelectFieldModel } from 'components/form/field/select/CidadaoAtendimentoSelectField'
import { Cpf } from 'components/label'
import React from 'react'

import { CidadaoSelectFieldModel } from './CidadaoSelectField'

export type CidadaoSelectFieldItemProps = {
  item: CidadaoSelectFieldModel | CidadaoAtendimentoSelectFieldModel
}

export function CidadaoSelectFieldItem(props: CidadaoSelectFieldItemProps) {
  const { item } = props

  return (
    <VFlow vSpacing={0}>
      <b>{item.nomeSocial ? `${item.nomeSocial} (${item.nome})` : item.nome}</b>
      <HFlow>
        <HFlow hSpacing={0.25}>
          <Text fontWeight='bold'>CPF</Text>
          <Text>{item.cpf ? <Cpf value={item.cpf} /> : '  —'}</Text>
        </HFlow>
        <HFlow hSpacing={0.25}>
          <Text fontWeight='bold'>CNS</Text>
          <Text>{item.cns ? item.cns : '  —'}</Text>
        </HFlow>
      </HFlow>
      <>
        <b>Nascimento </b> {item.dataNascimento ? <DateTime value={item.dataNascimento} format='DD/MM/YYYY' /> : '-'}
      </>
      <>
        <b>Nome da mãe </b> {item.nomeMae || 'Desconhece a informação'}
      </>
    </VFlow>
  )
}
