import { useAlert } from 'components/alert'
import useAtmosphere from 'hooks/useAtmosphere'

import { ImportarCidadaoMessageModel, statusArquivoToAlertLevel } from './model-importarCidadao'

interface ImportacaoCidadaoListenerProps {
  municipio: ID
  onImportacaoMessage(): void
}

export const ImportacaoCidadaoListener = (props: ImportacaoCidadaoListenerProps) => {
  const { municipio, onImportacaoMessage } = props
  const alert = useAlert()

  useAtmosphere<ImportarCidadaoMessageModel>({
    topic: `importar-cidadao/${municipio}`,
    onMessage: ({ mensagem, status }) => {
      alert(statusArquivoToAlertLevel[status], mensagem)
      onImportacaoMessage()
    },
  })
  return null
}
