import { Alert, Button, Cell, Grid, Heading, InfoLabel, Tooltip, VFlow } from 'bold-ui'
import { LogoutLinkWrapper } from 'components/auth/LogoutLinkWrapper'
import { Form, FormFooter, FormPrompt, FormRenderProps } from 'components/form'
import { ChaveField } from 'components/form/field/ChaveField'
import { ContraChaveField } from 'components/form/field/ContraChaveField'
import { Cpf } from 'components/label'
import { useSaveAutorizarMunicipioMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'
import { createValidator, required } from 'util/validation'

export interface AutorizarAtivarInstalacaoForm {
  chave: string
  contraChave: string
}

const validator = createValidator<AutorizarAtivarInstalacaoForm>({
  contraChave: [required],
})

export interface AutorizarMunicipioViewProps {
  acesso: any
  isTreinamento: boolean
  isProntuario: boolean
}

export function AutorizarMunicipioAtivarInstalacaoForm(props: AutorizarMunicipioViewProps) {
  const { acesso, isTreinamento } = props
  const history = useHistory()
  const [autorizarMunicipio] = useSaveAutorizarMunicipioMutation()

  const handleSubmit = (values: AutorizarAtivarInstalacaoForm) => {
    if (acesso.autorizado) {
      return Promise.resolve()
    }
    return autorizarMunicipio({
      variables: { input: { contraChave: !props.isTreinamento ? values.contraChave : 'CONTRA_CHAVE' } },
    })
  }

  const handleSubmitSucceeded = () => {
    props.isProntuario ? history.push('/autorizar/agenda') : history.push('/autorizar/cnes')
  }

  const renderForm = (formProps: FormRenderProps<AutorizarAtivarInstalacaoForm>) => (
    <>
      <FormPrompt />
      <VFlow vSpacing={2}>
        <VFlow>
          <Heading level={2}>Informações do responsável pelo município</Heading>
          <InfoLabel title='Município'>
            {acesso.municipio.nome}/{acesso.municipio.uf.sigla}
          </InfoLabel>
          <Grid>
            <Cell size={3}>
              <InfoLabel title='Nome do responsável'>{acesso.profissional.nome}</InfoLabel>
            </Cell>
            <Cell size={3}>
              <InfoLabel title='CPF do responsável'>
                <Cpf value={acesso.profissional.cpf} />
              </InfoLabel>
            </Cell>
          </Grid>
        </VFlow>
        {!acesso.autorizado && (
          <VFlow>
            <Heading level={2}>Insira a contra-chave para ativar a instalação</Heading>
            <Alert type='info' inline>
              Para ativar essa instalação o gestor ou técnico responsável deve acessar o{' '}
              <strong style={{ textDecoration: 'underline' }}>sistema de controle de uso do e-SUS APS</strong> para
              gerar a contra-chave.
              <br /> Se preferir ou não houver conectividade, ligue para o Disque Saúde através do telefone 136 na opção
              8 e solicite a ativação.
            </Alert>
            <Grid>
              <Cell xs={12} sm={6} md={4}>
                <ChaveField label='Chave' name='chave' disabled />
              </Cell>
              <Cell xs={12} sm={6} md={4}>
                <Tooltip text={isTreinamento ? 'Instalação de treinamento não precisa de contra-chave' : null}>
                  <ContraChaveField
                    name='contraChave'
                    label='Contra-chave'
                    required={!isTreinamento}
                    disabled={isTreinamento}
                  />
                </Tooltip>
              </Cell>
            </Grid>
          </VFlow>
        )}
        {acesso.autorizado && (
          <Alert type='success' inline>
            Município já autorizado.
          </Alert>
        )}
      </VFlow>
      <FormFooter>
        <LogoutLinkWrapper>
          <Button>Cancelar</Button>
        </LogoutLinkWrapper>
        <Button onClick={formProps.handleSubmit} kind='primary'>
          Avançar
        </Button>
      </FormFooter>
    </>
  )

  return (
    <Form<AutorizarAtivarInstalacaoForm>
      initialValues={{ chave: acesso.chaveMunicipio } as any}
      render={renderForm}
      onSubmit={handleSubmit}
      onSubmitSucceeded={handleSubmitSucceeded}
      validate={!isTreinamento && !acesso.autorizado ? validator : undefined}
    />
  )
}
