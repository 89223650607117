/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TextInput, TextInputProps } from 'bold-ui'
import { useAlert } from 'components/alert'
import React from 'react'

interface VideochamadaUrlTextInput extends TextInputProps {
  videochamadaUuid: string
}

export const VideochamadaUrlTextInput = React.memo((props: VideochamadaUrlTextInput) => {
  const { videochamadaUuid, onIconClick, ...rest } = props

  const alert = useAlert()
  const url = `${window.location.origin}/videochamada/${videochamadaUuid}`

  return (
    <TextInput
      value={url}
      disabled
      iconDisabled={false}
      aria-hidden
      icon='copyOutline'
      onIconClick={() => {
        navigator.clipboard.writeText(url)
        alert('success', 'Link da chamada copiado com sucesso.')
      }}
      {...rest}
    />
  )
})
