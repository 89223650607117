/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  useEncerrarMinhasVideochamadasMutation,
  useRemoverTodosProcessosAssincronosMutation,
} from 'graphql/hooks.generated'
import React from 'react'

import useSession from './useSession'

interface LogoutLinkWrapperProps {
  children: React.ReactNode
}

export const LogoutLinkWrapper = (props: LogoutLinkWrapperProps) => {
  const { data: session } = useSession()
  const [encerrarMinhasVideochamadas] = useEncerrarMinhasVideochamadasMutation()
  const [removerTodosProcessosAssincronos] = useRemoverTodosProcessosAssincronosMutation()

  const handleBeforeLogout = async () => {
    session.acesso && (await Promise.all([encerrarMinhasVideochamadas(), removerTodosProcessosAssincronos()]))
    window.location.href = '/logout'
  }

  return (
    <span
      onClick={handleBeforeLogout}
      css={css`
        text-decoration: underline;
      `}
      role='button'
    >
      {props.children}
    </span>
  )
}
