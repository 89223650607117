/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Alert,
  Heading,
  HFlow,
  InfoLabel,
  isEmpty,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  VFlow,
} from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { TableBox } from 'components/table'
import { useHistoricoAtividadeColetivaQuery } from 'graphql/hooks.generated'
import { stringGrouper } from 'util/string/string-grouper'
import { HistoricoDwPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'
import { mountInfosAdministrativas } from 'view/atendimentos/detail/historico/utils/utils-historico'

import HistoricoGapPanel from '../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../HistoricoNoDetailAvailable'

interface HistoricoAtividadeColetivaPanelProps extends HistoricoDwPanelProps {
  cpfOuCnsCidadao: string
}

export default function HistoricoAtividadeColetivaPanel({
  uuidRegistro,
  cpfOuCnsCidadao,
  idadeOrigemAtendimento,
}: HistoricoAtividadeColetivaPanelProps) {
  const {
    data: { historicoAtividadeColetiva: detalheAtividadeColetiva },
    loading,
  } = useHistoricoAtividadeColetivaQuery({
    variables: {
      input: {
        uuidRegistro,
        cpfOuCnsCidadao,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!detalheAtividadeColetiva) return <HistoricoNoDetailAvailable />

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativas(detalheAtividadeColetiva.fatoAtividadeColetiva)}
    >
      <VFlow vSpacing={0.5}>
        {detalheAtividadeColetiva.fatoAtividadeColetiva.stPseEducacao &&
          !detalheAtividadeColetiva.fatoAtividadeColetiva.stPseSaude && (
            <Alert type='info' inline>
              <Text color='primary' fontWeight='bold'>
                Atividade não acompanhada por um profissional de saúde
              </Text>
            </Alert>
          )}
        {(detalheAtividadeColetiva.nuAlturaParticipante ||
          detalheAtividadeColetiva.nuPesoParticipante ||
          detalheAtividadeColetiva.nuImcParticipante) && (
          <TableBox>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>
                    <Text fontWeight='bold'>Medições</Text>
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <HFlow
                    style={css`
                      padding: 0.8rem;
                    `}
                    hSpacing={2}
                  >
                    {detalheAtividadeColetiva.nuPesoParticipante && (
                      <InfoLabel title='Peso:'>{`${detalheAtividadeColetiva?.nuPesoParticipante} kg`}</InfoLabel>
                    )}
                    {detalheAtividadeColetiva.nuAlturaParticipante && (
                      <InfoLabel title='Altura:'>{`${detalheAtividadeColetiva?.nuAlturaParticipante} cm`}</InfoLabel>
                    )}
                    {detalheAtividadeColetiva.nuImcParticipante && (
                      <InfoLabel title='IMC:'>{`${detalheAtividadeColetiva?.nuImcParticipante.toFixed(
                        2
                      )} kg/m²`}</InfoLabel>
                    )}
                  </HFlow>
                </TableRow>
              </TableBody>
            </Table>
          </TableBox>
        )}
        <Heading level={3}>Atividade</Heading>
        <Text>{detalheAtividadeColetiva.fatoAtividadeColetiva.dimensaoTipoAtividade.descricaoTipoAtividade}</Text>
        {!isEmpty(detalheAtividadeColetiva.fatoAtividadeColetiva.publicoAlvo) && (
          <VFlow vSpacing={0.1}>
            <Heading level={5}>Público Alvo</Heading>
            <Text>{stringGrouper(...detalheAtividadeColetiva.fatoAtividadeColetiva.publicoAlvo)}</Text>
          </VFlow>
        )}
        {!isEmpty(detalheAtividadeColetiva.fatoAtividadeColetiva.temasSaude) && (
          <VFlow vSpacing={0.1}>
            <Heading level={5}>Temas para saúde</Heading>
            <Text>{stringGrouper(...detalheAtividadeColetiva.fatoAtividadeColetiva.temasSaude)}</Text>
          </VFlow>
        )}
        {!isEmpty(detalheAtividadeColetiva.fatoAtividadeColetiva.praticasSaude) && (
          <VFlow vSpacing={0.1}>
            <Heading level={5}>Prática em saúde</Heading>
            {detalheAtividadeColetiva.stAvaliacaoAlterada && (
              <div
                css={css`
                  margin: 0.5rem 0;
                `}
              >
                <Alert type='info' inline>
                  <Text color='primary' fontWeight='bold'>
                    Avaliação alterada
                  </Text>
                </Alert>
              </div>
            )}
            <Text>{stringGrouper(...detalheAtividadeColetiva.fatoAtividadeColetiva.praticasSaude)}</Text>
          </VFlow>
        )}
        {detalheAtividadeColetiva.fatoAtividadeColetiva.dimensaoProcedimento?.descricaoProcedimento && (
          <TableBox
            css={css`
              margin-bottom: 0.5rem;
            `}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>
                    <Text fontWeight='bold'>Outro procedimento coletivo</Text>
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <HFlow
                    hSpacing={0.5}
                    style={css`
                      padding: 0.5rem 1rem;
                    `}
                  >
                    <Text>
                      {`${detalheAtividadeColetiva.fatoAtividadeColetiva.dimensaoProcedimento.descricaoProcedimento} - ${detalheAtividadeColetiva.fatoAtividadeColetiva.dimensaoProcedimento.codigoProcedimento}`}
                    </Text>
                  </HFlow>
                </TableRow>
              </TableBody>
            </Table>
          </TableBox>
        )}
        {!isEmpty(detalheAtividadeColetiva.pnctParticipante) && (
          <VFlow vSpacing={0.1}>
            <Heading level={5}>Programa Nacional de Controle de Tabagismo</Heading>
            <Text>{stringGrouper(...detalheAtividadeColetiva.pnctParticipante)}</Text>
          </VFlow>
        )}
      </VFlow>
    </HistoricoGapPanel>
  )
}
