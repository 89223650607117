/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Form, FormProps } from 'components/form'
import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { FormApi } from 'final-form'
import { useServerTime } from 'hooks/useServerTime'
import { compact } from 'lodash'
import { createValidator, maxLength, required } from 'util/validation'

import { MAX_LENGTH_LOCAL_ODONTO, MAX_LENGTH_OBS_ODONTO } from '../../../model'
import { OutrosFieldModel } from './OutrosField'

export interface OutrosFormModel extends Omit<OutrosFieldModel, '_id'> {}

interface OutrosFormProps extends Omit<FormProps<OutrosFormModel>, 'validate' | 'keepDirtyOnReinitialize'> {
  editing?: boolean
  procedimentosSelecionados: ProcedimentoOdontoSelectModel[]
}

const validator = (currentProcedimentosIds: ID[]) =>
  createValidator<OutrosFormModel>(
    {
      local: [required, maxLength(MAX_LENGTH_LOCAL_ODONTO)],
      procedimentos: [required],
      observacao: [maxLength(MAX_LENGTH_OBS_ODONTO)],
    },
    (values, errors) => {
      const hasProcedimentoDuplicado =
        compact(values.procedimentos?.map((proced) => currentProcedimentosIds?.includes(proced?.id))).length > 0

      if (hasProcedimentoDuplicado) {
        errors.procedimentos = 'Não é possível registrar procedimentos duplicados.'
      }

      return errors
    }
  )

export function OutrosForm(props: OutrosFormProps) {
  const { editing, procedimentosSelecionados, onSubmit, ...rest } = props

  const { getServerTimeNow } = useServerTime()

  const handleSubmit = (values: OutrosFormModel, formApi: FormApi<OutrosFormModel>) => {
    onSubmit({ ...trimOutrosObservacao(values), lastUpdate: getServerTimeNow().valueOf() }, formApi)
    setTimeout(formApi.reset)
  }

  return (
    <Form<OutrosFormModel>
      onSubmit={handleSubmit}
      validate={validator(procedimentosSelecionados?.map((proced) => proced?.id))}
      keepDirtyOnReinitialize={editing}
      {...rest}
    />
  )
}

const trimOutrosObservacao = (values: OutrosFormModel): OutrosFormModel => ({
  ...values,
  observacao: values?.observacao?.trim() ?? null,
})
