import { Button, Icon, Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Text, Tooltip } from 'bold-ui'
import { ConfiguracaoHorarioAgendaInput, DiaSemanaEnum } from 'graphql/types.generated'
import { useCheckBreakpoint } from 'hooks/useCheckBreakpoint'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { abreviarDiaSemana } from './DiaSemana'
import { DoubleTimeField } from './DoubleTimeField'

export interface ConfiguracaoAgendaFormType {
  id: string
  configuracaoHorarioAgenda: ConfiguracaoHorarioAgendaInput
  mostrarFinalDeSemana?: boolean
}

export const meta = metaPath<ConfiguracaoAgendaFormType>()

export interface ConfiguracaoAgendaProps {
  configuracaoSemana: ConfiguracaoAgendaFormType
  formProps: FormRenderProps<ConfiguracaoAgendaFormType>
}

const doReplicar = (formProps: FormRenderProps<any>) => (e) => {
  const diaReferencia = formProps.values.configuracaoHorarioAgenda.configuracaoDiasSemana[0]

  formProps.values.configuracaoHorarioAgenda.configuracaoDiasSemana.forEach((diaSemanaPadraoInput, diaIndex) => {
    if (!formProps.values.mostrarFinalDeSemana && (diaIndex === 5 || diaIndex === 6)) {
      return
    }

    diaSemanaPadraoInput.periodos.forEach((periodoPadraoInput, periodoIndex) => {
      const innerPath = meta.configuracaoHorarioAgenda.configuracaoDiasSemana.get(diaIndex).periodos.get(periodoIndex)

      formProps.form.change(
        innerPath.horarioInicial.absolutePath(),
        diaReferencia.periodos[periodoIndex].horarioInicial
      )

      formProps.form.change(innerPath.horarioFinal.absolutePath(), diaReferencia.periodos[periodoIndex].horarioFinal)
    })
  })
}

export function ConfiguracaoAgendaPanel(props: ConfiguracaoAgendaProps) {
  const { configuracaoSemana, formProps } = props

  const isUnderLg = useCheckBreakpoint('lg', 'down')

  return (
    <Table hovered={false}>
      <TableHead>
        <TableRow>
          <TableHeader />
          {[1, 2, 3, 4].map((index) => (
            <React.Fragment key={index}>
              <TableHeader style={{ width: 80 }}>Entrada</TableHeader>
              <TableHeader style={{ width: 104, paddingLeft: '0.5rem' }}>Saída</TableHeader>
            </React.Fragment>
          ))}
          <TableHeader />
        </TableRow>
      </TableHead>
      <TableBody>
        {configuracaoSemana &&
          configuracaoSemana.configuracaoHorarioAgenda.configuracaoDiasSemana &&
          configuracaoSemana.configuracaoHorarioAgenda.configuracaoDiasSemana.map((diaSemanaInput, diaIndex) => (
            <React.Fragment key={diaIndex}>
              {!(
                !formProps.values.mostrarFinalDeSemana &&
                (diaSemanaInput.diaSemana === DiaSemanaEnum.SABADO ||
                  diaSemanaInput.diaSemana === DiaSemanaEnum.DOMINGO)
              ) && (
                <TableRow key={diaIndex}>
                  <TableCell colSpan={1} style={{ width: 62 }}>
                    <Text fontWeight='bold'>{abreviarDiaSemana(diaSemanaInput.diaSemana)}</Text>
                  </TableCell>
                  {[0, 1, 2, 3].map((periodoIndex) => {
                    return (
                      <TableCell
                        key={periodoIndex}
                        colSpan={2}
                        style={{ verticalAlign: 'top', padding: `0.5rem ${isUnderLg ? 1 : 1.5}rem 0.5rem 0` }}
                      >
                        <div>
                          <DoubleTimeField
                            name={meta.configuracaoHorarioAgenda.configuracaoDiasSemana
                              .get(diaIndex)
                              .periodos.get(periodoIndex)
                              .absolutePath()}
                            isUnderLg={isUnderLg}
                          />
                        </div>
                      </TableCell>
                    )
                  })}
                  <TableCell
                    colSpan={1}
                    style={{ verticalAlign: 'top', width: 1, paddingLeft: '0rem', paddingTop: '0.5rem' }}
                  >
                    {diaIndex === 0 && (
                      <Tooltip
                        text={
                          formProps.errors.configuracaoHorarioAgenda
                            ? 'Corrija as inconsistências para replicar os horários'
                            : 'Replicar horários para todos os dias'
                        }
                      >
                        <Tooltip text='Replicar horários para todos os dias'>
                          <Button
                            disabled={formProps.errors.configuracaoHorarioAgenda}
                            style={{ margin: '0rem' }}
                            size='small'
                            kind='normal'
                            skin='outline'
                            onClick={doReplicar(formProps)}
                          >
                            {isUnderLg ? <Icon icon='copyOutline' /> : 'Replicar'}
                          </Button>
                        </Tooltip>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
      </TableBody>
    </Table>
  )
}
