import { Cell, FormControl, Grid } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormPrompt, SubmitButton, TextField } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { FormApi } from 'final-form'
import { useSalvarFechamentoAgendaMutation } from 'graphql/hooks.generated'
import { MotivoFechamentoEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { FormRenderProps } from 'react-final-form'

import { createFechamentoDecorator } from './calculator'
import convert from './convert'
import { FechamentoFormModel, name } from './model'
import { MotivoFechamentoSelectField } from './MotivoFechamentoSelectField'
import { validateFechamento } from './validator'

interface FechamentoFormProps {
  idLotacao: ID
  onUpdated(): void
}

export default function FechamentoAgendaForm({ idLotacao, onUpdated }: FechamentoFormProps) {
  const [mutationSalvarFechamento] = useSalvarFechamentoAgendaMutation()
  const alert = useAlert()
  const decorator = useMemo(() => createFechamentoDecorator(), [])

  const handleSubmit = (values: FechamentoFormModel, form: FormApi) => {
    return mutationSalvarFechamento({ variables: { input: convert(values, idLotacao) } }).then(() => {
      alert('success', 'Fechamento salvo com sucesso.')
      form.getRegisteredFields().forEach((field) => form.resetFieldState(field))
      setTimeout(form.reset)
      onUpdated()
    })
  }

  const renderForm = (formProps: FormRenderProps<FechamentoFormModel>) => {
    const { values, handleSubmit } = formProps

    const outroSelected = values.motivo === MotivoFechamentoEnum.OUTRO

    return (
      <form onSubmit={handleSubmit} noValidate>
        <FormPrompt />
        <Grid wrap gap={1}>
          <Cell size={4}>
            <DateRangeField name={name.periodo} label='Período' required minDate={new Date()} />
          </Cell>

          <Cell size={3}>
            <MotivoFechamentoSelectField name={name.motivo} label='Motivo' required />
          </Cell>

          <Cell size={3}>
            <TextField
              name={name.especifique}
              label='Especifique'
              required={outroSelected}
              disabled={!outroSelected}
              maxLength={300}
            />
          </Cell>

          <Cell size={2}>
            <FormControl label={<span>&nbsp;</span>}>
              <SubmitButton handleSubmit={handleSubmit} size='small'>
                Adicionar
              </SubmitButton>
            </FormControl>
          </Cell>
        </Grid>
      </form>
    )
  }

  return (
    <Form<FechamentoFormModel>
      onSubmit={handleSubmit}
      validate={validateFechamento}
      render={renderForm}
      decorators={decorator}
    />
  )
}
