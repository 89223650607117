import { EncaminhamentoInternoFormModel } from './model'

const agendamentoSelecionadoMessage = 'Campos não podem ser editados pois há um agendamento selecionado'

export const resolveEquipeTooltip = (values: EncaminhamentoInternoFormModel): string => {
  if (values?.agendado) {
    return agendamentoSelecionadoMessage
  }

  if (!!values?.responsavel) {
    if (!!values.responsavel?.equipe) {
      return 'Profissional responsável selecionado'
    } else {
      return 'Profissional responsável sem equipe associada'
    }
  }
}

export const resolveProfissionalTooltip = (values: EncaminhamentoInternoFormModel): string => {
  if (values?.agendado) {
    return agendamentoSelecionadoMessage
  }
  if (!!values?.equipe && !values?.responsavel) {
    return 'Equipe responsável selecionada'
  }
}

export const resolveTiposServicoTooltip = (values: EncaminhamentoInternoFormModel): string =>
  !!values?.agendado && agendamentoSelecionadoMessage
