import { differenceInMinutes } from 'date-fns'

export function getProximoHorarioIndisponivel(
  horarioInicial: Date,
  horariosAgenda: { horario: Date; duracao: number; isOcupado: boolean }[]
): Date {
  return (
    horarioInicial &&
    horariosAgenda
      ?.sort((a, b) => Number(a.horario) - Number(b.horario))
      .find(
        (h, i, arr) =>
          Number(h.horario) > Number(horarioInicial) &&
          (h.isOcupado || (i > 0 && differenceInMinutes(h.horario, arr[i - 1].horario) > arr[i - 1].duracao))
      )?.horario
  )
}
