/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme, VFlow } from 'bold-ui'
import { ModalBody } from 'components/modals/ModalBody'
import { LabelMap } from 'util/fields-labels/model'
import { MetaRoot } from 'util/metaPath'
import { ErrorObject } from 'util/validation'

import { convertErrorsToModalItems } from './convertErrorsToModalItems'
import { FormErrorModel, ValidationErrorModalConfig } from './model'
import { ValidationErrorsModalSection } from './ValidationErrorsModalSection'

interface ValidationErrorsModalProps<TFormModel> {
  labels: LabelMap<TFormModel>
  errors: ErrorObject<TFormModel> & FormErrorModel
  meta: MetaRoot<TFormModel>
  config?: ValidationErrorModalConfig<TFormModel>
}

export function ValidationErrorsModal<TFormModel extends object>(props: ValidationErrorsModalProps<TFormModel>) {
  const { labels, errors, config, meta } = props

  const theme = useTheme()
  const classes = createStyles(theme)

  const items = convertErrorsToModalItems<TFormModel>(errors, labels, config, meta)

  return (
    <ModalBody
      type='danger'
      icon='banOutline'
      iconSize={4}
      title='Para finalizar o atendimento é necessário corrigir o(s) registro(s):'
      titleLevel={1}
      style={classes.modalBody}
    >
      <VFlow>
        {items.map((item, key) => (
          <ValidationErrorsModalSection section={item} key={key} />
        ))}
      </VFlow>
    </ModalBody>
  )
}

const createStyles = (theme: Theme) => ({
  modalBody: css`
    width: 40rem;

    ${theme.breakpoints.down('lg')} {
      width: 32.5rem;
    }
  `,
})
