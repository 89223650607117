/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'
import { TextAreaField, TextField } from 'components/form'
import { ProcedimentoOdontoSelectField } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { ParteBucalEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

import { MAX_LENGTH_LOCAL_ODONTO, MAX_LENGTH_OBS_ODONTO } from '../../../model'
import { EvolucaoDenteSupranumerarioModel } from './DentesSupranumerariosField'

interface DentesSupranumerariosFormFieldsProps {
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
}

const path = metaPath<EvolucaoDenteSupranumerarioModel>()

export const DentesSupranumerariosFormFields = (props: DentesSupranumerariosFormFieldsProps) => {
  const { cidadaoDataNascimento, dataAtendimento } = props

  return (
    <VFlow>
      <TextField name={path.local} label='Local' maxLength={MAX_LENGTH_LOCAL_ODONTO} required />

      <ProcedimentoOdontoSelectField
        name={path.procedimentos}
        label='Procedimentos (Evolução odontológica)'
        multiple
        dataNascimento={cidadaoDataNascimento}
        dataAtendimento={dataAtendimento}
        parteBucal={ParteBucalEnum.DENTE_SUPRANUMERARIO}
        required
      />

      <TextAreaField
        name={path.observacao}
        label='Observações'
        maxLength={MAX_LENGTH_OBS_ODONTO}
        style={styles.textArea}
      />
    </VFlow>
  )
}

const styles = {
  textArea: css`
    resize: none;
  `,
}
