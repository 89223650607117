import { Breadcrumb } from 'components/breadcrumb'
import { PageLoading } from 'components/loading'
import { PecSwitch } from 'components/route'
import { useEscutaInicialVisualizarQuery } from 'graphql/hooks.generated'
import React from 'react'
import { Route, useParams, useRouteMatch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { CidadaoDetailContent } from 'view/atendimentos/detail/cadastro-cidadao/CidadaoDetailContent'
import { AtendimentoHeader, AtendimentoHeaderProps } from 'view/atendimentos/detail/header/AtendimentoHeader'

import { EscutaInicialVisualizarDetail } from './EscutaInicialVisualizarDetail'

interface EscutaInicialVisualizarRootViewProps {
  atendimentoId: ID
}

export function EscutaInicialVisualizarRootView() {
  const { atendimentoId } = useParams<EscutaInicialVisualizarRootViewProps>()
  const match = useRouteMatch()

  const {
    data: { atendimento },
    loading,
  } = useEscutaInicialVisualizarQuery({
    fetchPolicy: 'cache-and-network',
    variables: { atendimentoId },
  })

  if (loading) {
    return <PageLoading message='Carregando...' />
  }

  const { isAgendado, cidadao, escutaInicialAtendProf } = atendimento || {}

  const renderEscutaMainView = () => (
    <EscutaInicialVisualizarDetail
      atendimentoProfissionalId={escutaInicialAtendProf.id}
      isPreAtendimento={isAgendado}
    />
  )

  const renderCidadaoDetail = () => (
    <CidadaoDetailContent atendimentoId={atendimentoId} cidadaoId={cidadao.id} callbackUrl='visualizar-escuta' />
  )

  const headerProps: AtendimentoHeaderProps = {
    cidadaoId: cidadao?.id,
    renderFolhaRosto: false,
    renderSoap: false,
    renderVacinacao: false,
    renderHistorico: false,
    renderCadastroCidadao: true,
    renderEscutaInicial: true,
    renderAgendamentos: false,
    dataAtendimento: escutaInicialAtendProf.iniciadoEm,
  }

  const labelEscutaPreAtendimento = isAgendado ? 'Pré-atendimento' : 'Escuta inicial'

  return (
    <>
      <Breadcrumb title={`Visualizar ${labelEscutaPreAtendimento.toLocaleLowerCase()}`} />

      <AtendimentoHeader labelEscutaPreAtendimento={labelEscutaPreAtendimento} {...headerProps} />

      <PecSwitch>
        <Redirect exact path={match.url} to={`${match.url}/escuta-inicial`} />
        <Route path={`${match.url}/escuta-inicial`} render={renderEscutaMainView} />
        <Route path={`${match.url}/cadastro-cidadao`} render={renderCidadaoDetail} />
      </PecSwitch>
    </>
  )
}
