import { SelectField } from 'components/form/final-form'
import { ConselhoClasseSelectDocument } from 'graphql/hooks.generated'
import { ConselhoClasseSelectQuery, ConselhoClasseSelectQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type ConselhoClasseSelectModel = ConselhoClasseSelectQuery['conselhosClasse']['content'][0]

export interface ConselhoClasseSelectFieldProps extends AsyncSelectFieldProps<ConselhoClasseSelectModel> {}

export const ConselhoClasseSelectField = (props: ConselhoClasseSelectFieldProps) => {
  const { selectProps } = useAsyncQuerySelect<
    ConselhoClasseSelectModel,
    ConselhoClasseSelectQuery,
    ConselhoClasseSelectQueryVariables
  >({
    query: ConselhoClasseSelectDocument,
    extractItems: (data) => data && data.conselhosClasse && data.conselhosClasse.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        pageParams: { ...DEFAULT_SELECT_PAGE_PARAM, sort: ['nome'] },
      },
    }),
  })

  const itemToString = (option: ConselhoClasseSelectModel) => option && option.descricao

  return <SelectField<ConselhoClasseSelectModel> itemToString={itemToString} {...selectProps} {...props} />
}
