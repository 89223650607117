import { SelectField } from 'components/form'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_PAGE_PARAM,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { ProcedimentoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  GrupoProcedimentoEnum,
  ProcedimentoSelectFieldQuery,
  ProcedimentoSelectFieldQueryVariables,
  SexoEnum,
  TipoExameEnum,
  TipoProcedimentoEnum,
} from 'graphql/types.generated'
import React from 'react'

import { ProcedimentoSelectFieldEmptyItem } from './ProcedimentoSelectFieldEmptyItem'
import { ProcedimentoSelectFieldItem } from './ProcedimentoSelectFieldItem'

export type ProcedimentoSelectModel = ProcedimentoSelectFieldQuery['procedimentos']['content'][0]

export interface ProcedimentoSelectFieldProps extends AsyncSelectFieldProps<ProcedimentoSelectModel> {
  tipoExame?: TipoExameEnum
  tipoProcedimento?: TipoProcedimentoEnum
  gruposProcedimento?: GrupoProcedimentoEnum[]
  filtroPadrao?: boolean
  sexo?: SexoEnum
  dataNascimento?: LocalDate
  dataAtendimento: LocalDate
  cboIds?: ID[]
  filtrarProcedimentosOdonto?: boolean
  loadItemsOnOpen?: boolean
}

ProcedimentoSelectField.defaultProps = {
  loadItemsOnOpen: true,
} as Partial<ProcedimentoSelectFieldProps>

export function ProcedimentoSelectField(props: ProcedimentoSelectFieldProps) {
  const {
    loadItemsOnOpen,
    tipoExame,
    tipoProcedimento,
    gruposProcedimento,
    filtroPadrao,
    sexo,
    dataNascimento,
    dataAtendimento,
    cboIds,
    filtrarProcedimentosOdonto,
    components,
    ...rest
  } = props

  const { skipping, selectProps } = useAsyncQuerySelect<
    ProcedimentoSelectModel,
    ProcedimentoSelectFieldQuery,
    ProcedimentoSelectFieldQueryVariables
  >({
    query: ProcedimentoSelectFieldDocument,
    extractItems: (data) => data?.procedimentos?.content,
    variables: (inputString) => ({
      input: {
        query: inputString,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
        filtro: {
          tipoExame,
          tipoProcedimento,
          gruposProcedimento,
          filtroPadrao,
          sexo,
          dataNascimento,
          dataAtendimento,
          cboIds,
          filtrarProcedimentosOdonto,
        },
      },
    }),
    skip: (inputString) => (!loadItemsOnOpen ? inputString?.trim()?.length < 2 : undefined),
    debounceTime: 500,
  })

  return (
    <SelectField<ProcedimentoSelectModel>
      itemToString={itemToString}
      renderItem={ProcedimentoSelectFieldItem}
      components={{
        EmptyItem: () => ProcedimentoSelectFieldEmptyItem(skipping, loadItemsOnOpen),
        ...components,
      }}
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (item: ProcedimentoSelectModel) => item?.descricao
