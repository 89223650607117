import { Omit } from 'bold-ui'
import React from 'react'

import { SelectField, SelectFieldProps } from '../../final-form/SelectField'

export interface UfSelectModel {
  id: ID
  nome: string
  sigla: string
}

export interface UfSelectFieldProps extends Omit<SelectFieldProps<UfSelectModel>, 'items' | 'itemToString'> {}

export function UfSelectField(props: UfSelectFieldProps) {
  const itemToString = (item: UfSelectModel) => item && item.nome

  return <SelectField<UfSelectModel> items={items} itemToString={itemToString} {...props} />
}

const items: UfSelectModel[] = [
  { id: 1, nome: 'ACRE', sigla: 'AC' },
  { id: 2, nome: 'ALAGOAS', sigla: 'AL' },
  { id: 3, nome: 'AMAPÁ', sigla: 'AP' },
  { id: 4, nome: 'AMAZONAS', sigla: 'AM' },
  { id: 5, nome: 'BAHIA', sigla: 'BA' },
  { id: 6, nome: 'CEARÁ', sigla: 'CE' },
  { id: 7, nome: 'DISTRITO FEDERAL', sigla: 'DF' },
  { id: 8, nome: 'ESPÍRITO SANTO', sigla: 'ES' },
  { id: 9, nome: 'GOIÁS', sigla: 'GO' },
  { id: 10, nome: 'MARANHÃO', sigla: 'MA' },
  { id: 11, nome: 'MATO GROSSO', sigla: 'MT' },
  { id: 12, nome: 'MATO GROSSO DO SUL', sigla: 'MS' },
  { id: 13, nome: 'MINAS GERAIS', sigla: 'MG' },
  { id: 14, nome: 'PARÁ', sigla: 'PA' },
  { id: 15, nome: 'PARAÍBA', sigla: 'PB' },
  { id: 16, nome: 'PARANÁ', sigla: 'PR' },
  { id: 17, nome: 'PERNAMBUCO', sigla: 'PE' },
  { id: 18, nome: 'PIAUÍ', sigla: 'PI' },
  { id: 19, nome: 'RIO DE JANEIRO', sigla: 'RJ' },
  { id: 20, nome: 'RIO GRANDE DO NORTE', sigla: 'RN' },
  { id: 21, nome: 'RIO GRANDE DO SUL', sigla: 'RS' },
  { id: 22, nome: 'RONDÔNIA', sigla: 'RO' },
  { id: 23, nome: 'RORAIMA', sigla: 'RR' },
  { id: 24, nome: 'SANTA CATARINA', sigla: 'SC' },
  { id: 25, nome: 'SÃO PAULO', sigla: 'SP' },
  { id: 26, nome: 'SERGIPE', sigla: 'SE' },
  { id: 27, nome: 'TOCANTINS', sigla: 'TO' },
]
