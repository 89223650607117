import { VFlow } from 'bold-ui'
import { useCuidadoCompartilhadoEvolucoesQuery } from 'graphql/hooks.generated'
import React from 'react'

import { CuidadoCompartilhadoEvolucaoItem } from './CuidadoCompartilhadoEvolucaoItem'

interface CuidadoCompartilhadoEvolucoesProps {
  cuidadoCompartilhadoId: ID
}

export const CuidadoCompartilhadoEvolucoes = (props: CuidadoCompartilhadoEvolucoesProps) => {
  const { cuidadoCompartilhadoId } = props

  const {
    data: { cuidadoCompartilhadoEvolucoes },
  } = useCuidadoCompartilhadoEvolucoesQuery({
    variables: {
      input: {
        cuidadoCompartilhadoId: cuidadoCompartilhadoId,
      },
    },
  })

  const lastLineNumber = cuidadoCompartilhadoEvolucoes?.content?.length - 1

  return (
    <VFlow vSpacing={0}>
      {cuidadoCompartilhadoEvolucoes?.content?.map((evolucao, index) => (
        <CuidadoCompartilhadoEvolucaoItem
          evolucao={evolucao}
          key={evolucao.id}
          isFirstEvolucao={index === 0}
          isLastEvolucao={index === lastLineNumber}
        />
      ))}
    </VFlow>
  )
}
