import { Cell, Grid, InfoLabel } from 'bold-ui'
import useSession from 'components/auth/useSession'
import {
  DefaultFormFooter,
  Form,
  FormPrompt,
  FormProps,
  MunicipioSelectModel,
  PerfilSelectField,
  PerfilSelectModel,
  TipoAcessoSelectField,
  UfSelectField,
  UfSelectModel,
} from 'components/form'
import { TipoAcesso } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, required } from 'util/validation'

export interface AcessoFormModel {
  id: ID
  profissional: ID
  tipo: TipoAcesso
  perfis: PerfilSelectModel[]
  uf: UfSelectModel
  municipio: MunicipioSelectModel
}

export const validator = createValidator<AcessoFormModel>(
  {
    tipo: [required],
    perfis: [required],
  },
  (values: AcessoFormModel) => {
    const errors: ErrorObject<AcessoFormModel> = {}

    if (values.tipo === TipoAcesso.GESTOR_ESTADUAL) {
      errors.uf = required(values.uf)
    } else if (values.tipo === TipoAcesso.GESTOR_MUNICIPAL) {
      errors.municipio = required(values.municipio)
    }

    return errors
  }
)

export interface AcessoFormProps extends FormProps<AcessoFormModel> {}

const path = metaPath<AcessoFormModel>()

export function AcessoForm(props: AcessoFormProps) {
  const { data: sessao } = useSession()

  const renderForm = (formProps: FormRenderProps<AcessoFormModel>) => {
    const values = formProps.values

    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <FormPrompt />
        <Grid wrap>
          <Cell xs={12} md={6}>
            <TipoAcessoSelectField
              label='Tipo de acesso'
              name={path.tipo}
              items={createTipoAcessoItems(sessao.acesso.tipo)}
              required
            />
          </Cell>

          <Cell xs={12} md={6}>
            <PerfilSelectField
              label='Perfis'
              name={path.perfis}
              tipoAcesso={values.tipo}
              disabled={!values.tipo}
              multiple
              required
            />
          </Cell>

          {values.tipo === TipoAcesso.GESTOR_ESTADUAL && (
            <Cell xs={12} md={6}>
              <UfSelectField label='UF' name={path.uf} required />
            </Cell>
          )}
          {values.tipo === TipoAcesso.GESTOR_MUNICIPAL && (
            <Cell xs={12} md={6}>
              <InfoLabel title='Município'>{values.municipio.nome}</InfoLabel>
            </Cell>
          )}
        </Grid>

        <DefaultFormFooter handleSubmit={formProps.handleSubmit} />
      </form>
    )
  }

  return <Form<AcessoFormModel> render={renderForm} validate={validator} {...props} />
}

const createTipoAcessoItems = (tipo: TipoAcesso) => {
  switch (tipo) {
    case TipoAcesso.ADMINISTRADOR_GERAL:
      return [TipoAcesso.GESTOR_ESTADUAL]
    case TipoAcesso.ADMINISTRADOR_MUNICIPAL:
      return [TipoAcesso.GESTOR_MUNICIPAL]
  }

  return []
}
