import { HFlow, Text } from 'bold-ui'
import React from 'react'

interface EquipeLabelProps {
  nome: string
  ine: string
}

export const EquipeLabel = React.memo((props: EquipeLabelProps) => {
  const { nome, ine } = props

  return (
    <HFlow hSpacing={0.25}>
      <Text fontWeight='bold'>Equipe:</Text>
      <Text>
        {nome} {ine && `| ${ine}`}
      </Text>
    </HFlow>
  )
})
