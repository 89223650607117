/** @jsx jsx */
import { jsx } from '@emotion/core'
import { SelectField } from 'components/form/final-form'
import { EspecialidadeSisregSelectFieldDocument } from 'graphql/hooks.generated'
import {
  EspecialidadeSisregSelectFieldQuery,
  EspecialidadeSisregSelectFieldQueryVariables,
} from 'graphql/types.generated'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type EspecialidadeSisregSelectFieldModel = EspecialidadeSisregSelectFieldQuery['especialidadesSisreg']['content'][0]

export interface EspecialidadeSisregSelectFieldProps
  extends AsyncSelectFieldProps<EspecialidadeSisregSelectFieldModel> {
  isAtendimentoOdonto: boolean
}

export default function EspecialidadeSisregSelectField(props: EspecialidadeSisregSelectFieldProps) {
  const { isAtendimentoOdonto, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    EspecialidadeSisregSelectFieldModel,
    EspecialidadeSisregSelectFieldQuery,
    EspecialidadeSisregSelectFieldQueryVariables
  >({
    query: EspecialidadeSisregSelectFieldDocument,
    extractItems: (data) => data?.especialidadesSisreg.content,
    variables: (inputQuery: string): EspecialidadeSisregSelectFieldQueryVariables => ({
      input: {
        query: inputQuery,
        isAtendimentoOdonto,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
      },
    }),
  })

  const itemToString = (item: EspecialidadeSisregSelectFieldModel) => item && item.descricao

  return <SelectField<EspecialidadeSisregSelectFieldModel> itemToString={itemToString} {...selectProps} {...rest} />
}
